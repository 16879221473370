// React
import { useCallback, useState } from "react";
// Style
import { CancelOrderStyled } from "./style";
// Libs
import { Modal } from "@escolamais-ui-v2/react";
import { useTranslation } from "react-i18next";
import HTTP_STATUS from "http-status-codes";
// Interfaces
import { ICancelOrder } from "./interface";
// Services
import { cancelFoodOrder } from "services/meals.service";
// Components
import { toastError, toastSuccess, toastWarning } from "components/Toast";

export const CancelOrder = ({
  open,
  setOpen,
  order,
  setDrawer,
  setOrder,
  setForm,
  endpoint,
}: ICancelOrder) => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.consultFoodOrdes.components.cancelOrder";

  const [loading, setLoading] = useState(false);

  const cancelOrder = useCallback(() => {
    (async () => {
      setLoading(true);
      const response = await cancelFoodOrder({ order });
      if (response?.status === HTTP_STATUS.OK) {
        toastSuccess(t(`${TRANSLATE}.putSuccess`));
        setOpen(false);
        setDrawer(false);
        setOrder(null);
        endpoint();
      } else if (response?.status === HTTP_STATUS.BAD_REQUEST) {
        toastWarning(response.data.detail);
      } else {
        toastError(t(`${TRANSLATE}.putError`));
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CancelOrderStyled loading={loading}>
      <Modal
        handleOnClickCancel={() => {
          setOpen(false);
        }}
        handleOnClickConfirm={() => {
          !loading && cancelOrder();
        }}
        size="small"
        title={t(`${TRANSLATE}.cancelOrder`)}
        open={open}
        buttonCancelText={t(`${TRANSLATE}.back`)}
        buttonConfirmText={t(`${TRANSLATE}.cancelOrder`)}
      >
        <span>{t(`${TRANSLATE}.informativeText`)}</span>
      </Modal>
    </CancelOrderStyled>
  );
};
