import { useState } from "react";

import Icon from "@mdi/react";
import { mdiMenuDown, mdiMenuUp } from "@mdi/js";

import { IStudentSubjectAttendance } from "interfaces/gradeGroupInterfaces";

import * as S from "./styles";

interface IDrawerRowCollapseProps {
  student: IStudentSubjectAttendance;
}

export const DrawerRowCollapse = ({ student }: IDrawerRowCollapseProps) => {
  const [collapseIsOpen, setCollapseIsOpen] = useState(false);

  const iconPath = collapseIsOpen ? mdiMenuUp : mdiMenuDown;

  const handleCollapse = () => {
    setCollapseIsOpen((prevState) => !prevState);
  };

  return (
    <S.Container onClick={handleCollapse}>
      <div className="titles">
        <p>
          <Icon
            path={iconPath}
            size={1}
            color={student.details.length > 0 ? "" : "white"}
          />
          {student?.name}
        </p>
        <p>{student?.attendance_display}</p>
      </div>

      {collapseIsOpen && (
        <ul>
          {student?.details.map((item, index) => (
            <li key={index}>
              <p>{item.weekday}</p>
              <span>{item.date}</span>
            </li>
          ))}
        </ul>
      )}
    </S.Container>
  );
};
