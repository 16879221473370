import styled from "styled-components";

export const AppVersionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
`;

export const Version = styled.small`
  color: #aeaeae;
  font-size: 0.6rem;
  letter-spacing: 0.8px;
`;
