import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: auto;
`;

export const SectionHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

interface SectionContentStyleProps {
  justifyContent?: string;
}

export const SectionContent = styled.div<SectionContentStyleProps>`
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "flex-start"};

  h1 {
    margin-bottom: 0;
  }
`;
