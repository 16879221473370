import styled from "styled-components";

import * as colors from "styles/colors";

interface SelectStyleInterface {
  label?: boolean;
  fullBorder?: boolean;
  gayScale?: boolean;
  width?: string;
  height?: string;
}

export const Label = styled.div`
  color: ${colors.NEW_GRAY_TWO};
  pointer-events: none;
  font-size: 12px;
  text-transform: uppercase;
`;

export const SelectStyled = styled.select<SelectStyleInterface>`
  font-size: 14px;
  font-weight: normal;
  background-color: white;
  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "38px")};
  color: ${(props) =>
    props.gayScale ? `${colors.GRAY_SCALE}` : `${colors.NEW_GRAY_TWO}`};
  border: none;
  border-bottom: 2px solid ${colors.NEW_GRAY_ONE};
  padding: 0.25rem;
  margin-top: ${(props) => (props.label ? "0" : "2em")};
  cursor: pointer;

  // FullBorder
  ${(props) => props.fullBorder && `border: 2px solid ${colors.GRAY_SCALE}`}
`;

interface SelectStateContainerProps {
  maxWidth?: string;
}
export const SelectStateContainer = styled.div<SelectStateContainerProps>`
  display: inline-block;

  width: 100%;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
  height: 2.313rem;

  select {
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};

    width: 100%;
    height: 2.313rem;

    background: ${(props) => colors.WHITE};

    border: 2px solid #999999;

    &:focus,
    &:focus-visible {
      outline: none;
    }
  }
`;

export const SelectStateText = styled.div`
  height: 2.313rem;
  line-height: 2.313rem;

  padding-left: 0.5rem;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    right: 6px;
    top: 12px;
    border: 7px solid transparent;
    border-color: black transparent transparent transparent;
  }
`;

export const SelectStateOption = styled.option`
  width: 100%;

  margin-top: 5px;
  padding: 6px 20px;

  cursor: pointer;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: #d9d9d9;
  }
`;
