// React
import { useContext, useEffect, useState } from "react";
// Styles
import * as S from "./styles";
// Context
import { FoodContext } from "context/foodContext";
// Libs
import Icon from "@mdi/react";
import { mdiCheckCircleOutline } from "@mdi/js";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HTTP_STATUS from "http-status-codes";
import moment from "moment";
// Components
import { Text } from "components";
import { Button, Postite } from "components/atoms";
// Interfaces
import { ILocationSelectMeals } from "../../type";
import { SingleFoodTransactionsInterface } from "interfaces/mealInterfaces";
// Services
import { getConsultSingleFoodTransactions } from "services/meals.service";
import { IFoodContextType } from "context/foodContext/type";
import { Pix } from "../StepThree/components/Pix";

type StepFourType = {
  transaction?: SingleFoodTransactionsInterface;
  paymentType: string;
  setStep: (step: number) => void;
  next: () => void;
  setTransaction: (transaction: SingleFoodTransactionsInterface) => void;
};

export const StepFour = ({ next, setTransaction }: StepFourType) => {
  const history = useHistory();
  const { t } = useTranslation();
  const i18_prefix = "pages.EDF.selectMeals.components.stepFour";

  const { state } = useLocation<ILocationSelectMeals>();
  const { paymentData } = useContext(FoodContext) as IFoodContextType;

  const [singleFood, setSingleFood] =
    useState<SingleFoodTransactionsInterface>();

  useEffect(() => {
    (async () => {
      const response = await getConsultSingleFoodTransactions({
        guardianId: state.guardianUser.id,
        date: moment(new Date()).format("YYYY-MM-DD"),
      });

      if (response && response.status === HTTP_STATUS.OK) {
        setSingleFood(response.data.results[response.data.results.length - 1]);
      }
    })();
  }, [state.guardianUser.id, state.payment, state.purchaseId]);

  return (
    <S.Container>
      <div className="student-info">
        <Text gray>{state.studentUser.name}</Text>
      </div>

      <div className="paymentSuccess">
        <Icon size={3} path={mdiCheckCircleOutline} />
        <strong>{t(`${i18_prefix}.orderSuccess`)}</strong>
        <p className="how-it-works">{t(`${i18_prefix}.howItWorks`)}</p>

        <Pix
          paymentData={paymentData}
          state={state}
          next={next}
          setTransaction={setTransaction!}
        />

        <p className="totalPix">
          {t(`${i18_prefix}.orderTotal`)}{" "}
          <span>R${paymentData?.valor_pago}</span>
        </p>

        <Postite
          type="warning"
          title={t(`${i18_prefix}.qrTimeout`)}
          content={t(`${i18_prefix}.qrContent`)}
          titleSize="14px"
          contentSize="14px"
        />

        <Button onClick={() => history.push("/home")}>
          {t(`${i18_prefix}.buttonBackToHome`)}
        </Button>
      </div>
    </S.Container>
  );
};
