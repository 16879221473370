import styled from "styled-components";
import * as colors from "styles/colors";

export const StudentFoodCreditStyle = styled.div`
  .title {
    font-size: 24px;
    font-weight: bold;
    color: ${colors.BLUE};
  }

  .subtitle {
    font-size: 16px;
    color: ${colors.BLACK_ONE};
  }
`;
