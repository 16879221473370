/* eslint-disable no-sequences */
/* eslint-disable array-callback-return */
import * as S from "./style";
import * as I from "./type";

import { Title } from "components/atoms";
import { useBulletin } from "context/Bulletin";
import { toastSuccess, toastError } from "components/Toast";
import { MultiNotasProps } from "pages/AP/Lancamentos/Lancamento/types";
import { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ItemCard } from "pages/CORE/Home/components/Student/style";

const prefix = "pages.AP.boletim.bulletin.modal";

export const ModalBulletin = ({
  type,
  data,
  onClose,
  evaluationsType,
  subject,
}: I.ModalBulletinProps) => {
  const {
    handleApproveBulletin,
    reqTable,
    handleDisapproveBulletin,
    userLogon,
    handleReleaseBulletin,
    quarter,
  } = useBulletin();
  const [valueEdit, setValueEdit] = useState<string>("");
  const [commentEdit, setCommentEdit] = useState<string>(data?.comment);
  const [sendEditInfo, setSendEditInfo] = useState<MultiNotasProps[]>([]);
  const [sendSubject] = useState<number>(subject);
  const [comment, setComment] = useState<string>();
  const [justification, setJustification] = useState<string>();
  const { t } = useTranslation();

  const formatNote = (value: string) => {
    if (value === "10" || value.length === 1) {
      return value.replace(/\D/g, "");
    } else {
      return value.replace(/\D/g, "").replace(/(\d{0,2})$/, ".$1");
    }
  };

  const submitApproveBulletin = useCallback(async () => {
    try {
      handleApproveBulletin(data.student_id, reqTable.quarterId);
      toastSuccess(`${t(`${prefix}.submitApproveBulletinSucesso`)}`);
      if (onClose) {
        onClose();
      }
    } catch (error) {
      toastError(`${t(`${prefix}.submitApproveBulletinError`)}`);
    }
  }, [data.student_id, handleApproveBulletin, onClose, reqTable.quarterId, t]);

  const submitDisapproveBulletin = useCallback(async () => {
    try {
      handleDisapproveBulletin(data.student_id, reqTable.quarterId);
      toastSuccess(`${t(`${prefix}.submitDisapproveBulletinSucesso`)}`);
      if (onClose) {
        onClose();
      }
    } catch (error) {
      toastError(`${t(`${prefix}.submitDisapproveBulletinError`)}`);
    }
  }, [data, handleDisapproveBulletin, onClose, reqTable.quarterId, t]);

  const submitReleaseBulletin = useCallback(
    async (studentId) => {
      try {
        handleReleaseBulletin(sendEditInfo, studentId);

        toastSuccess(`${t(`${prefix}.submitReleaseBulletinSucesso`)}`);
      } catch (error) {
        toastError(`${t(`${prefix}.submitReleaseBulletinError`)}`);
        throw new Error("error", error);
      }
    },
    [handleReleaseBulletin, sendEditInfo, t]
  );

  useEffect(() => {
    if (data && evaluationsType === "Avaliação de Conhecimentos") {
      data.subjects.map((subjects) => {
        if (subjects.subject_id === subject) {
          subjects.evaluations_type.map((evaluation) => {
            if (evaluation.name === evaluationsType) {
              setComment(evaluation.comment);
            }
          });
        }
      });
    }

    if (data && subject && quarter.selected && evaluationsType) {
      if (evaluationsType === "Avaliação de Habilidades e Atitudes") {
        setJustification(data.release_comment);
      } else {
        data.subjects.filter(
          (item) =>
            item.subject_id === subject &&
            item.evaluations_type.filter(
              (evaluation) =>
                evaluation.name === evaluationsType &&
                setJustification(evaluation.release_comment)
            )
        );
      }
    }
  }, [data, evaluationsType, quarter.selected, subject]);

  const handleType = (type: string, data: I.BulletinDataProps) => {
    switch (type) {
      case "aprovar":
        return (
          <S.ContainerModal>
            <S.IconTitle>
              <img src="/icons/edit.svg" alt="" />
            </S.IconTitle>
            <Title
              darkGray
              fontSize="24px"
              fontWeight="700"
              lineHeight="1"
              center
            >
              {t(`${prefix}.approved`)}
            </Title>
            <S.MessageModal>
              <Title
                darkGray
                fontSize="14px"
                fontWeight="700"
                lineHeight="1"
                center
              >
                {t(`${prefix}.messageModalApproved`)} {data?.name} ?
              </Title>
            </S.MessageModal>
            <S.ActionModal>
              <S.ButtonModal onClick={onClose}>
                {t(`${prefix}.cancel`)}
              </S.ButtonModal>
              <S.ButtonModal onClick={() => submitApproveBulletin()}>
                <img src="/icons/save.svg" alt="" /> {t(`${prefix}.save`)}
              </S.ButtonModal>
            </S.ActionModal>
          </S.ContainerModal>
        );
      case "desaprovar":
        return (
          <S.ContainerModal>
            <S.IconTitle>
              <img src="/icons/edit.svg" alt="" />
            </S.IconTitle>
            <Title
              darkGray
              fontSize="20px"
              fontWeight="700"
              lineHeight="1"
              center
            >
              {t(`${prefix}.disapproved`)}
            </Title>
            <S.MessageModal>
              {t(`${prefix}.messageModalDisapproved`)} {data?.name} ?
            </S.MessageModal>
            <S.ActionModal>
              <S.ButtonModal onClick={onClose}>
                {t(`${prefix}.cancel`)}
              </S.ButtonModal>
              <S.ButtonModal onClick={() => submitDisapproveBulletin()}>
                <img src="/icons/save.svg" alt="" /> {t(`${prefix}.save`)}
              </S.ButtonModal>
            </S.ActionModal>
          </S.ContainerModal>
        );
      case "notas":
        return (
          <S.ContainerModal>
            <S.IconTitle>
              <img src="/icons/edit.svg" alt="" />
            </S.IconTitle>
            <Title
              darkGray
              fontSize="16px"
              fontWeight="700"
              lineHeight="1"
              center
            >
              {t(`${prefix}.changeDrade`)}
            </Title>
            <S.SectionModal direction="column">
              <S.SectionModal direction="row">
                <S.SectionModal direction="column">
                  <Title
                    darkGray
                    fontSize="16px"
                    fontWeight="700"
                    lineHeight="1"
                    left
                  >
                    {t(`${prefix}.currentGrade`)}
                  </Title>
                  {data.subjects.map(
                    (subject) =>
                      subject.subject_id === sendSubject &&
                      subject.evaluations_type.map(
                        (evaluations) =>
                          evaluations.name === evaluationsType && (
                            <Title
                              key={`${evaluations.name}-${evaluations.value}`}
                              darkGray
                              fontSize="14px"
                              fontWeight="400"
                              lineHeight="1"
                              left
                            >
                              {evaluations.value}
                            </Title>
                          )
                      )
                  )}
                </S.SectionModal>
                <S.SectionModal direction="column">
                  <Title
                    darkGray
                    fontSize="16px"
                    fontWeight="700"
                    lineHeight="1"
                    left
                  >
                    {t(`${prefix}.newGrade`)}
                  </Title>
                  <S.InputModal
                    name="Nota"
                    value={valueEdit}
                    maxLength={4}
                    onChange={(e) =>
                      setValueEdit(
                        formatNote(String(e.target.value).toLocaleString())
                      )
                    }
                  />
                </S.SectionModal>
              </S.SectionModal>
              <S.SectionModal direction="column">
                <S.SectionModal direction="column">
                  <Title
                    darkGray
                    fontSize="14px"
                    fontWeight="400"
                    lineHeight="1"
                    left
                  >
                    {t(`${prefix}.justification`)}
                  </Title>
                </S.SectionModal>
                <S.SectionModal direction="column">
                  <S.InputModal
                    name="Nota"
                    value={justification || ""}
                    maxLength={800}
                    onChange={(e) => setJustification(String(e.target.value))}
                  />
                </S.SectionModal>
              </S.SectionModal>
            </S.SectionModal>
            <S.ActionModal>
              <S.ButtonModal onClick={onClose}>
                {t(`${prefix}.cancel`)}
              </S.ButtonModal>
              <S.ButtonModal
                onClick={() => submitReleaseBulletin(data.student_id)}
              >
                <img src="/icons/save.svg" alt="" /> {t(`${prefix}.save`)}
              </S.ButtonModal>
            </S.ActionModal>
          </S.ContainerModal>
        );
      case "faltas":
        return (
          <S.ContainerModal>
            <S.IconTitle>
              <img src="/icons/edit.svg" alt="" />
            </S.IconTitle>
            <Title
              darkGray
              fontSize="20px"
              fontWeight="700"
              lineHeight="1"
              center
            >
              {t(`${prefix}.changeAbsence`)}
            </Title>
            <S.SectionModal direction="column">
              <S.SectionModal direction="row">
                <S.SectionModal direction="column">
                  <Title
                    darkGray
                    fontSize="14px"
                    fontWeight="700"
                    lineHeight="1"
                    left
                  >
                    {t(`${prefix}.changeAbsence`)}
                  </Title>
                  {data.subjects.map(
                    (subject) =>
                      subject.subject_id === sendSubject && (
                        <Title
                          key={`${subject.subject_short_name}-${subject.absences}`}
                          darkGray
                          fontSize="14px"
                          fontWeight="400"
                          lineHeight="1"
                          left
                        >
                          {subject.absences}
                        </Title>
                      )
                  )}
                </S.SectionModal>
                <S.SectionModal direction="column">
                  <S.SectionModal direction="column">
                    <Title
                      darkGray
                      fontSize="14px"
                      fontWeight="700"
                      lineHeight="1"
                      left
                    >
                      {t(`${prefix}.newAbsences`)}
                    </Title>
                    <S.InputModal
                      name="Faltas"
                      placeholder={valueEdit}
                      maxLength={4}
                      onChange={(e) => setValueEdit(e.target.value)}
                    />
                  </S.SectionModal>
                </S.SectionModal>
              </S.SectionModal>

              <S.SectionModal direction="column">
                <S.SectionModal direction="column">
                  <Title
                    darkGray
                    fontSize="14px"
                    fontWeight="400"
                    lineHeight="1"
                    left
                  >
                    {t(`${prefix}.justification`)}
                  </Title>
                </S.SectionModal>
                <S.InputModal
                  name="Nota"
                  value={justification}
                  maxLength={800}
                  onChange={(e) => setJustification(String(e.target.value))}
                />
              </S.SectionModal>
            </S.SectionModal>
            <S.ActionModal>
              <S.ButtonModal onClick={onClose}>
                {t(`${prefix}.cancel`)}
              </S.ButtonModal>
              <S.ButtonModal
                onClick={() => submitReleaseBulletin(data.student_id)}
              >
                <img src="/icons/save.svg" alt="" /> {t(`${prefix}.save`)}
              </S.ButtonModal>
            </S.ActionModal>
          </S.ContainerModal>
        );
      case "habilidade":
        return (
          <S.ContainerModal>
            <S.IconTitle>
              <img src="/icons/edit.svg" alt="" />
            </S.IconTitle>
            <Title
              darkGray
              fontSize="20px"
              fontWeight="700"
              lineHeight="1"
              center
            >
              Alterar Avaliação de Habilidades e Atitudes
            </Title>
            <S.SectionModal direction="column">
              <Title
                darkGray
                fontSize="14px"
                fontWeight="400"
                lineHeight="1"
                left
                textUpperCase
              >
                Observações
              </Title>
              <S.TextAreaModal
                name="note"
                value={commentEdit}
                maxLength={1600}
                onChange={(e) => setCommentEdit(String(e.currentTarget.value))}
              />
            </S.SectionModal>
            <S.ActionModal>
              <S.ButtonModal onClick={onClose}>
                {t(`${prefix}.cancel`)}
              </S.ButtonModal>
              <S.ButtonModal
                onClick={() => submitReleaseBulletin(data.student_id)}
              >
                <img src="/icons/save.svg" alt="" /> Salvar
              </S.ButtonModal>
            </S.ActionModal>
          </S.ContainerModal>
        );
      case "entenda":
        return (
          <S.ContainerModal>
            <S.IconTitle>
              <img src="/icons/edit.svg" alt="" />
            </S.IconTitle>
            <Title
              darkGray
              fontSize="20px"
              fontWeight="700"
              lineHeight="1"
              center
            >
              Entenda a Nota
            </Title>
            <S.SectionModal direction="column">
              {comment && comment.length > 0
                ? comment
                : "Nenhum comentário sobre essa nota foi encontrado."}
            </S.SectionModal>
            <S.ActionModal>
              <S.ButtonModal onClick={onClose}>
                {t(`${prefix}.cancel`)}
              </S.ButtonModal>
            </S.ActionModal>
          </S.ContainerModal>
        );
      case "componente":
        return (
          <S.ContainerModal>
            <S.IconTitle>
              <img src="/icons/info-purple.svg" alt="" />
            </S.IconTitle>
            <Title
              darkGray
              fontSize="20px"
              fontWeight="700"
              lineHeight="1"
              center
            >
              Componentes
            </Title>
            <S.SectionModal direction="column">
              {data.tooltips.map(
                (tooltip) =>
                  tooltip.title === "Componentes" && (
                    <div key={tooltip.title}>
                      {tooltip.description}
                      <ul>
                        {tooltip.items.map((item) => (
                          <li key={item.name}>
                            <strong>{item.name}</strong> - {item.value}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )
              )}
            </S.SectionModal>
            <S.ActionModal>
              <S.ButtonModal onClick={onClose}>
                {t(`${prefix}.cancel`)}
              </S.ButtonModal>
            </S.ActionModal>
          </S.ContainerModal>
        );
      case "recuperacao":
        return (
          <S.ContainerModal>
            <S.IconTitle>
              <img src="/icons/info-purple.svg" alt="" />
            </S.IconTitle>
            <Title
              darkGray
              fontSize="20px"
              fontWeight="700"
              lineHeight="1"
              center
            >
              Recuperação
            </Title>
            <S.SectionModal direction="column">
              {data.tooltips.map(
                (tooltip) =>
                  tooltip.title === "Recuperação" && (
                    <div key={tooltip.title}>
                      {tooltip.description}
                      <ul>
                        {tooltip.items.map((item) => (
                          <li key={item.name}>
                            <strong>{item.name}</strong> - {item.value}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )
              )}
            </S.SectionModal>
            <S.ActionModal>
              <S.ButtonModal onClick={onClose}>
                {t(`${prefix}.cancel`)}
              </S.ButtonModal>
            </S.ActionModal>
          </S.ContainerModal>
        );
    }
  };

  useEffect(() => {
    if (valueEdit === "" && evaluationsType !== "Faltas") {
      data.subjects.map((subject) => {
        if (subject.subject_id === sendSubject) {
          subject.evaluations_type.map((evaluations) => {
            if (evaluations.name === evaluationsType) {
              const value = evaluations.value;
              setValueEdit(`${value}`);
            }
          });
        }
      });
    } else if (valueEdit === "" && evaluationsType === "Faltas") {
      data.subjects.map((subject) => {
        if (subject.subject_id === sendSubject) {
          const value = subject.absences;
          setValueEdit(`${value}`);
        }
      });
    }
  }, [data, evaluationsType, sendSubject, valueEdit]);

  useEffect(() => {
    const info = {
      value: valueEdit,
      enrollment: data?.student_id,
      user_name: userLogon?.name,
      gradegroup: reqTable.gradegroupId,
      quarter: reqTable.quarterId,
      subject: sendSubject,
      type: evaluationsType,
      user_id: userLogon?.id,
      comment: commentEdit,
      release_comment: justification,
      deactivate_previous: true,
    };
    setSendEditInfo([info]);
  }, [
    sendSubject,
    commentEdit,
    data?.student_id,
    evaluationsType,
    justification,
    reqTable.gradegroupId,
    reqTable.quarterId,
    userLogon?.id,
    userLogon?.name,
    valueEdit,
    data.subjects,
  ]);

  return <>{handleType(type, data)}</>;
};
