// Components
import { Modal } from "@escolamais-ui-v2/react";
// Helpers
import { formatCPF, formatPhone } from "helpers/helpers";
// Libs
import { useTranslation } from "react-i18next";
// Interfaces
import { IModal } from "./interface";
// Styles
import { ModalStyled } from "./styled";

enum ExitPermitModalTypes {
  register = 1,
  edit = 2,
}

export const ModalExitReport = ({
  modal,
  modalOption,
  modalForm,
  setModalFormProps,
  setModal,
  handleRegisterEscort,
  handleEditEscort,
  setModalForm,
}: IModal) => {
  const { t } = useTranslation();
  const prefix = "pages.EDF.exitReport.components.modal";

  return (
    <Modal
      handleOnClickCancel={() => {
        setModalFormProps();
        setModal(false);
      }}
      handleOnClickConfirm={() => {
        modalOption === ExitPermitModalTypes.register
          ? handleRegisterEscort()
          : handleEditEscort();
      }}
      size="medium"
      title={t(`${prefix}.modalTitle`)}
      open={modal}
      buttonCancelText={t(`${prefix}.modalCancel`)}
      buttonConfirmText={t(`${prefix}.modalSave`)}
    >
      <ModalStyled>
        <div className="firstLine">
          <div>
            <label>{t(`${prefix}.name`)}</label>
            <input
              onChange={(e) => {
                setModalForm({
                  ...modalForm,
                  name: e.target.value,
                });
              }}
              type="text"
              id="name"
              placeholder={t(`${prefix}.inputPlaceHolderName`)}
              name="name"
              value={modalForm.name}
            />
          </div>
          <div>
            <label>{t(`${prefix}.kinship`)}</label>
            <input
              onChange={(e) => {
                setModalForm({
                  ...modalForm,
                  kinship: e.target.value,
                });
              }}
              type="text"
              id="kinship"
              placeholder={t(`${prefix}.inputPlaceHolderKinship`)}
              name="kinship"
              value={modalForm.kinship}
            />
          </div>
        </div>
        <div>
          <div>
            <label>{t(`${prefix}.cpf`)}</label>
            <input
              onChange={(e) => {
                setModalForm({
                  ...modalForm,
                  cpf: formatCPF(e.target.value),
                });
              }}
              type="text"
              id="cpf"
              placeholder="000.000.000-00"
              name="cpf"
              value={
                modalForm.escort > 0 ? formatCPF(modalForm.cpf) : modalForm.cpf
              }
              maxLength={14}
            />
          </div>
          <div>
            <label>{t(`${prefix}.phone`)}</label>
            <input
              onChange={(e) => {
                setModalForm({
                  ...modalForm,
                  phone: formatPhone(e.target.value),
                });
              }}
              type="text"
              id="phone"
              placeholder="(00) 00000-0000"
              name="phone"
              value={
                modalForm.escort > 0
                  ? formatPhone(modalForm.phone)
                  : modalForm.phone
              }
              maxLength={15}
            />
          </div>
        </div>
        <p>{t(`${prefix}.modalText`)}</p>
      </ModalStyled>
    </Modal>
  );
};
