import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button } from "components";

export const Hyperlink = styled(Link)`
  color: #6d28d9;
  text-decoration: underline;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.313rem;
  align-self: flex-end;
`;

export const LoginContainer = styled.div`
  display: flex;
  height: 100vh;
  background: #b7ff00;
`;

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const ImageContainer = styled.div`
  display: flex;
  min-width: 680px;

  @media only screen and (max-width: 1440px) {
    min-width: auto;
  }

  @media only screen and (max-width: 1110px) {
    display: none;
  }
`;

export const Image = styled.img`
  @media only screen and (max-width: 1110px) {
    display: none;
  }
`;

export const Content = styled.div`
  background: #fff;
  border: 2px solid #6d28d9;
  padding: 48px 32px;
  width: 486px;
  display: flex;
  flex-direction: column;

  gap: 16px;

  @media only screen and (max-width: 512px) {
    width: 328px;
  }

  @media only screen and (max-width: 328px) {
    width: auto;
  }
`;

export const LogoImg = styled.img`
  width: 240px;
  align-self: center;
`;

export const GreetingText = styled.h1`
  font-size: 2.25rem;
  line-height: 54px;
  font-weight: 700;
  color: #6d28d9;
  text-align: center;
  display: block;

  margin: 0px;
`;

export const CustomButton = styled(Button)`
  margin: 0px;
  height: 37px;

  background: #6d28d9;
  border-radius: 0%;
  font-weight: 700;
  font-size: 0.875rem;
  font-style: normal;
  line-height: 21px;
  letter-spacing: 1px;

  &:hover {
    background: #8a53e1;
  }
`;

export const LoginField = styled.input`
  width: 100%;
`;

export const SModalStyled = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
