/* eslint-disable array-callback-return */
import { useContext, useRef, useState } from "react";
import { Button, Checkbox } from "components";
import { ModalDiv } from "components/UseTerms/styles";
import Modal from "components/Modal";
import { Field, Form } from "react-final-form";
import { UnitInterface } from "services/units.service";
import SelectInput from "components/Select";
import loadingGif from "static/img/loading.gif";
import { exportMatriculas } from "services/enrollment.service";
import { CSVLink } from "react-csv";
import { toastWarning, toastSuccess, toastError } from "components/Toast";

interface ModalExportEnrollmentsInterface {
  setToggleModal: (toggleModal: boolean) => void;
  isOpen: boolean;
  units: Array<UnitInterface>;
}

type valuesFormExport = {
  enrollment?: boolean;
  re_enrollment?: boolean;
  unit_id: number;
};

export const ModalExportEnrollments = ({
  setToggleModal,
  isOpen,
  units,
}: ModalExportEnrollmentsInterface) => {
  const [csvData, setCsvData] = useState<any>();
  const [loadingExport, setLoadingExport] = useState(false);
  const csvInstance = useRef<any | null>(null);

  const onSubmit = async ({
    enrollment,
    re_enrollment,
    unit_id,
  }: valuesFormExport) => {
    if (enrollment || re_enrollment) {
      let query = "";
      if (enrollment && !re_enrollment) {
        query += "&enrollment=true";
      } else if (enrollment && re_enrollment) {
        query += "&enrollment=true&reenrollment=true";
      } else {
        query += "&reenrollment=true";
      }
      if (unit_id) {
        query += `&unit_id=${unit_id}`;
      }
      setLoadingExport(true);
      const newCsvData = await exportMatriculas(query);
      if (newCsvData) {
        setCsvData(newCsvData);
        setTimeout(() => {
          csvInstance.current.link.click();
          setCsvData(null);
          setLoadingExport(false);
        });
        toastSuccess("Export concluído com sucesso.");
      } else {
        toastError("Não foi possível concluir o export");
        setLoadingExport(false);
      }
    } else {
      toastWarning(
        "Você precisa selecionar se o export será de matrículas ou rematrículas"
      );
    }
  };

  return (
    <Modal setToggleModal={setToggleModal} isOpen={isOpen}>
      {csvData ? (
        <CSVLink
          data={csvData.data}
          headers={csvData.headers}
          filename="export.csv"
          ref={csvInstance}
        />
      ) : undefined}
      <div className="container">
        <ModalDiv>
          <div className="border rounded p-3">
            <div className="text-center">
              <strong className="title">Export Matrículas</strong>
            </div>
            <Form onSubmit={onSubmit}>
              {({ form, handleSubmit, submitting, values }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row justify-content-center">
                    <div className="col-6">
                      <div className="row border rounded p-1">
                        <strong className="col-12 text-center">
                          Tipo de Export*
                        </strong>
                        <div className="col-6">
                          <Field component={Checkbox} name="enrollment" />
                          Matrículas
                        </div>
                        <div className="col-6">
                          <Field component={Checkbox} name="re_enrollment" />
                          Rematrículas
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="row border rounded p-1">
                        <div className="col-12 text-center">
                          <strong>Unidade *</strong>
                          <Field
                            component={SelectInput}
                            name="unit_id"
                            className="mt-0"
                            required
                          >
                            <option value="">Selecione uma Unidade</option>
                            {units.map((unit) => {
                              if (unit.actived) {
                                return (
                                  <option value={unit.id} key={unit.id}>
                                    {unit.name}
                                  </option>
                                );
                              }
                            })}
                          </Field>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 text-center">
                      <Button type="submit" disabled={submitting}>
                        {(submitting || loadingExport) && (
                          <img height="25" src={loadingGif} alt="loading" />
                        )}
                        {!submitting && "Exportar"}
                      </Button>
                    </div>
                  </div>
                </form>
              )}
            </Form>
            <div className="text-right">
              <strong>* Obrigatório</strong>
            </div>
          </div>
        </ModalDiv>
      </div>
    </Modal>
  );
};

export default ModalExportEnrollments;
