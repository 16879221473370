// React
import { useCallback, useEffect, useState } from "react";
// Libs
import HTTP_STATUS from "http-status-codes";
import { useTranslation } from "react-i18next";
// Interfaces
import {
  ICreateError,
  IDrawerDynamicHelper,
  IDynamics,
  IForm,
} from "./interface";
import { EventType } from "interfaces/constants";
// Helpers
import { formatMoneyInput } from "helpers/helpers";
// Services
import { newDynamic } from "services/meals.service";
// Components
import { toastError, toastSuccess, toastWarning } from "components/Toast";

enum ECutName {
  MorumbiOne = "Morumbi 1 - Vila Andrade",
  MorumbiTwo = "Morumbi 2 - Vila Sônia",
}

export const DynamicDrawerHelper = ({
  units,
  dynamic,
  item,
  setDrawer,
  setDynamic,
  searchDynamics,
}: IDrawerDynamicHelper) => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.manageFoodItems.components.dynamicDrawer";
  // Diferencia uma dinamica nova para uma que ja existe
  const [isNewDynamic, setIsNewDynamic] = useState(true);

  const [form, setForm] = useState<IForm>({
    name: "",
    startDate: "",
    finalDate: "",
    units: [],
    allUnits: false,
    item,
  });
  const [dynamics, setDynamics] = useState<Array<IDynamics>>([
    {
      quantidade_minima: 1,
      valor_unitario: "",
    },
  ]);

  // Caso seja uma dinamica que ja existente
  useEffect(() => {
    if (dynamic) {
      const unitsIds: Array<number> = [];
      dynamic.unidades_ids.forEach((unit) => {
        unitsIds.push(unit.id);
      });

      const dynamics: Array<IDynamics> = [];
      dynamic.valor_dinamica.forEach((value) => {
        dynamics.push({
          quantidade_minima: value.quantidade_minima,
          valor_unitario: value.valor_unitario,
        });
      });

      setForm({
        ...form,
        name: dynamic.nome,
        startDate: dynamic.vigencia_inicio,
        finalDate: dynamic.vigencia_fim,
        units: unitsIds,
        allUnits: dynamic.todas_unidades,
        item: dynamic.item.toString(),
      });

      setDynamics(dynamics);
      setIsNewDynamic(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dynamic]);

  const cutName = (name: string) => {
    switch (name) {
      case ECutName.MorumbiOne:
        return name.substring(0, 7);
      case ECutName.MorumbiTwo:
        return name.substring(0, 9);
      default:
        return name;
    }
  };

  const updateField = (index: number, event: EventType, amount?: boolean) => {
    let newValues = [...dynamics];
    const fullStop = true;

    if (amount) {
      newValues[index] = {
        ...dynamics[index],
        quantidade_minima: parseInt(event.target.value),
      };
    } else {
      newValues[index] = {
        ...dynamics[index],
        valor_unitario: formatMoneyInput(event.target.value, fullStop),
      };
    }

    setDynamics(newValues);
  };

  const searchUnitAndRemove = (value: number) => {
    let units = form.units;
    const unit = units.indexOf(value);
    units.splice(unit, 1);

    return setForm({ ...form, units, allUnits: false });
  };

  const allUnitsPage = () => {
    const unitsIds: Array<number> = [];

    if (!form.allUnits) {
      units.forEach((unit) => {
        unitsIds.push(unit.id);
      });
      setForm({
        ...form,
        units: unitsIds,
        allUnits: true,
      });
    } else {
      setForm({
        ...form,
        units: unitsIds,
        allUnits: false,
      });
    }
  };

  const closeDrawer = useCallback(() => {
    setDrawer(false);
    setDynamic(null);
    setForm({
      name: "",
      startDate: "",
      finalDate: "",
      units: [],
      allUnits: false,
      item: "",
    });
    setDynamics([
      {
        quantidade_minima: 1,
        valor_unitario: "",
      },
    ]);
    setIsNewDynamic(true);
  }, [setDrawer, setDynamic, setDynamics, setForm, setIsNewDynamic]);

  const errorMessages = (error: ICreateError) => {
    if (error.vigencia) {
      return toastWarning(t(`${TRANSLATE}.createToastError`));
    } else if (error.vigencia_fim?.vigencia) {
      return toastWarning(t(`${TRANSLATE}.createToastError2`));
    } else {
      return toastWarning(t(`${TRANSLATE}.createToastError3`));
    }
  };

  const createDynamic = useCallback(async () => {
    const payload = {
      nome: form.name,
      vigencia_inicio: form.startDate,
      vigencia_fim: form.finalDate,
      valor_dinamica: dynamics,
      unidades_ids: form.units,
      todas_unidades: form.allUnits,
      item,
    };

    const response = await newDynamic({ payload });
    if (response && response.status === HTTP_STATUS.CREATED) {
      toastSuccess(t(`${TRANSLATE}.createSuccess`));
      searchDynamics();
      closeDrawer();
    } else if (response && response.status === HTTP_STATUS.BAD_REQUEST) {
      errorMessages(response.data);
    } else {
      toastError(t(`${TRANSLATE}.createErrorDefault`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, dynamics, item]);

  return {
    isNewDynamic,
    setIsNewDynamic,
    form,
    setForm,
    dynamics,
    setDynamics,
    cutName,
    updateField,
    searchUnitAndRemove,
    allUnitsPage,
    createDynamic,
    closeDrawer,
  };
};
