import { Button, RadioButton, TextInput } from "components";
import { toastError, toastSuccess } from "components/Toast";
import formatString from "format-string-by-pattern";
import {
  composeValidators,
  CPFvalidation,
  required,
} from "helpers/fieldValidators";
import { getError, onlyNumbersFromString } from "helpers/helpers";
import HTTP_STATUS from "http-status-codes";
import { FilterGuardianResponse } from "interfaces/constants";
import { useContext, useState } from "react";
import { Field, Form } from "react-final-form";
import { useHistory } from "react-router-dom";
import { bondStudentToGuardian, searchGuardian } from "services/users.service";
import loadingGif from "static/img/loading.gif";
import { SearchGuardianByCPFStyled } from "./style";

interface SearchGuardianByCPFInterface {
  filter_text: string;
  guard_type: string;
}

export const SearchGuardianByCPF = () => {
  const [guardians, setGuardians] =
    useState<null | Array<FilterGuardianResponse>>(null);
  const [cpf, setCpf] = useState<string | undefined>(undefined);
  const [error, setError] = useState(false);
  const [submittingGuard, setSubmittingGuard] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const studentId = urlParams.get("studentId");
  const outOfApp = urlParams.get("outofapp");

  const history = useHistory();

  const setGuard = (values: any, guardian: any) => {
    setSubmittingGuard(true);
    bondStudentToGuardian({
      guardianId: guardian.guardian_id,
      studentId: parseInt(studentId!),
      guard_type: values.guard_type,
    })
      .then((response) => {
        if (response) {
          if (response.status === HTTP_STATUS.CREATED) {
            toastSuccess("Responsável vinculado com sucesso");
            history.goBack();
          } else {
            if (
              getError(response.data) ===
              "The fields student, guardian must make a unique set."
            ) {
              toastError("Responsável já está vinculado a este aluno");
            } else {
              toastError(
                "Erro ao vincular responsável a este aluno. Tente novamente mais tarde."
              );
            }
          }
        }
      })
      .finally(() => {
        setSubmittingGuard(false);
      });
  };

  const onSubmit = async (values: SearchGuardianByCPFInterface) => {
    setCpf(values.filter_text);
    await searchGuardian(
      onlyNumbersFromString(values.filter_text),
      "guardian",
      1
    ).then((response) => {
      if (response) {
        if (response.status === HTTP_STATUS.OK) {
          // @ts-ignore
          if (response.data.results.length === 0)
            // @ts-ignore
            setGuardians(response.data.results);
          else {
            if (!studentId) {
              // @ts-ignore
              const guardian = response.data.results[0];
              if (outOfApp) {
                history.push(
                  `/responsavel/editar?guardianAuthId=${guardian.id}&guardianId=${guardian.guardian_id}&outofapp=true`
                );
              } else {
                history.push(
                  `/responsavel/editar?guardianAuthId=${guardian.id}&guardianId=${guardian.guardian_id}`
                );
              }
            } else {
              // @ts-ignore
              setGuardians(response.data.results);
            }
          }
        } else {
          setError(true);
        }
      }
    });
  };

  return (
    <SearchGuardianByCPFStyled className="container">
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting, values }) => (
          <form id="search-form" onSubmit={handleSubmit}>
            {(!guardians || studentId) && (
              <div className="row">
                <div className="col-sm-9 col-12">
                  <Field
                    component={TextInput}
                    name="filter_text"
                    type="text"
                    placeholder=" "
                    label="Digite o CPF do responsável"
                    parse={formatString("999.999.999-99")}
                    validate={composeValidators(required, CPFvalidation)}
                    required
                    hideCheck
                  />
                </div>
                <div className="col-sm-3 col-12">
                  <Button disabled={submitting} type="submit">
                    {!submitting && "Buscar"}
                    {submitting && (
                      <img height="25" src={loadingGif} alt="loading" />
                    )}
                  </Button>
                </div>
              </div>
            )}
            {guardians && guardians.length === 0 && (
              <div className="text-center">
                CPF não encontrado.{" "}
                <p>Prossiga para cadastrar o responsável.</p>
                <div className="row justify-content-center">
                  <div className="col-sm-3 col-12">
                    <Button
                      onClick={() => {
                        if (outOfApp) {
                          history.push(
                            `/responsavel/cadastro?cpf=${cpf}${
                              studentId ? `&studentId=${studentId}` : ""
                            }&outofapp=true`
                          );
                        } else {
                          history.push(
                            `/responsavel/cadastro?cpf=${cpf}${
                              studentId ? `&studentId=${studentId}` : ""
                            }`
                          );
                        }
                      }}
                      type="button"
                    >
                      Prosseguir
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {guardians && guardians.length > 0 && (
              <>
                <table>
                  <thead>
                    <tr className="row">
                      <th className="col-3">Nome</th>
                      <th className="col-2">Tipo</th>
                      <th className="col-3">CPF</th>
                      <th className="col-4">E-mail</th>
                    </tr>
                  </thead>
                  <tbody>
                    {guardians.map((guardian, key) => {
                      return [
                        <tr key={key} className="row">
                          <td className="col-3">{guardian.name}</td>
                          <td className="col-2">
                            {!guardian.role && "Sem role (usuario invalido)"}
                            {guardian.role &&
                              guardian.role.find(
                                (r) => r.label === "Student"
                              ) &&
                              "Aluno"}
                            {guardian.role &&
                              guardian.role.find(
                                (r) => r.label === "Guardian"
                              ) &&
                              "Resp."}
                          </td>
                          <td className="col-3">{guardian.cpf}</td>
                          <td className="d-block d-sm-none col-4">
                            {guardian.email
                              ? guardian.email.split("@")[0] +
                                " @" +
                                guardian.email.split("@")[1]
                              : ""}
                          </td>
                          <td className="d-none d-sm-block col-4">
                            {guardian.email}
                          </td>
                        </tr>,
                        <tr className="row">
                          <td className="col-3">Tipo de responsável</td>
                          <td className="col-3">
                            <Field
                              component={RadioButton}
                              name="guard_type"
                              type="radio"
                              value="2"
                              required
                            />
                            <strong>Financeiro e Pedagógico</strong>
                          </td>
                          <td className="col-3">
                            <Field
                              component={RadioButton}
                              name="guard_type"
                              type="radio"
                              value="1"
                              required
                            />
                            <strong>Pedagógico</strong>
                          </td>
                          <td className="col-3">
                            <Button
                              onClick={() => setGuard(values, guardian)}
                              type="button"
                              disabled={!values.guard_type || submittingGuard}
                            >
                              {!submittingGuard && "Salvar"}
                              {submittingGuard && (
                                <img
                                  height="25"
                                  src={loadingGif}
                                  alt="loading"
                                />
                              )}
                            </Button>
                          </td>
                        </tr>,
                      ];
                    })}
                  </tbody>
                </table>
              </>
            )}
            {error && <div>Erro ao carregar dados da busca.</div>}
          </form>
        )}
      </Form>
    </SearchGuardianByCPFStyled>
  );
};
