// Styles
import { Step5Styled } from "./style";
// Constants
import {
  GetCXUserJson,
  MonthInterface,
  StudentInterface,
} from "interfaces/constants";
// Interfaces
import { FoodPackage } from "interfaces/mealInterfaces";
// Helpers
import { formatMoney, standardDate, standardHour } from "helpers/helpers";
// Libs
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
// Components
import { CheckboxInput, Text } from "components";
import { Button } from "components/atoms";
// Icons
import successIcon from "static/img/success_icon_two.svg";

type Step5Type = {
  foodPackage: FoodPackage;
  user: GetCXUserJson;
  smart?: boolean;
};

export const Step5 = ({ foodPackage, user, smart }: Step5Type) => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.foodOption.components.step5";
  const history = useHistory();
  return (
    <Step5Styled>
      <div className="successImg">
        <img src={successIcon} height={62} alt="successIcon" />
      </div>
      <div className="text">
        <Text fontSize="14px" fontWeight="700" lineHeight="21px">
          {t(`${TRANSLATE}.title`)}
        </Text>
        <Text fontSize="14px" fontWeight="400" lineHeight="21px">
          {t(`${TRANSLATE}.subTitle`)}
        </Text>
      </div>
      <div className="packageInfos">
        <Text purple fontSize="18px" fontWeight="600" lineHeight="27px">
          {t(`${TRANSLATE}.monthlyPackage`)}
          <span className="monthName">{foodPackage.month_name}</span>
        </Text>
        <div className="foodItems">
          {foodPackage.package.map((foodItem) => {
            return (
              <div className="packageItems">
                <Text fontSize="12px" fontWeight="400" lineHeight="18px">
                  {foodItem.food_item_name}
                </Text>
                <Text fontSize="12px" fontWeight="400" lineHeight="18px">
                  {formatMoney(foodItem.food_item_value)}
                </Text>
              </div>
            );
          })}
        </div>
        <div className="workingDays">
          <Text
            fontSize="12px"
            fontWeight="400"
            lineHeight="18px"
            className="workingDaysText"
          >
            x{foodPackage.working_days} {t(`${TRANSLATE}.workingDays`)}
          </Text>
        </div>
        <div className="spaceInLine">
          <Text fontSize="18px" fontWeight="700" lineHeight="27px">
            {t(`${TRANSLATE}.total`)}
          </Text>
          <Text fontSize="18px" fontWeight="700" lineHeight="27px">
            {smart
              ? "R$00.00"
              : foodPackage.food_package_value_month_after_balance_formatted}
          </Text>
        </div>
        {!smart && (
          <div className="spaceInLine marginTop">
            <Text fontSize="12px" fontWeight="400" lineHeight="18px">
              {t(`${TRANSLATE}.paymentMethod`)}
            </Text>
            <Text fontSize="12px" fontWeight="400" lineHeight="18px">
              {foodPackage.payment_method_display}
            </Text>
          </div>
        )}
        <div className="spaceInLine">
          <Text
            fontSize="12px"
            fontWeight="400"
            lineHeight="18px"
            className="paymentDate"
          >
            {t(`${TRANSLATE}.paymentDate`)}
          </Text>
          <Text
            fontSize="12px"
            fontWeight="400"
            lineHeight="18px"
            className="paymentDate"
          >
            {t(`${TRANSLATE}.paymentDay`, {
              day: standardDate(foodPackage.date_transaction),
              hour: standardHour(foodPackage.date_transaction),
            })}
          </Text>
        </div>
      </div>
      {foodPackage.saved_as_favorite && (
        <div className="saveFoodPackage">
          <CheckboxInput disabled value="credit" name="credit" checked />
          <Text
            fontWeight="400"
            fontSize="14px"
            lineHeight="21px"
            className="savePackageText"
          >
            {t(`${TRANSLATE}.savePackage`)}
          </Text>
        </div>
      )}
      <div className="button">
        <Button
          onClick={() =>
            history.push({
              pathname: `/home`,
              state: {
                user,
              },
            })
          }
          type="button"
        >
          {t(`${TRANSLATE}.buttonBackToHome`)}
        </Button>
      </div>
    </Step5Styled>
  );
};
