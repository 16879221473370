import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: ${({ theme }) => theme.space[8]};

  & > div {
    width: 100%;
  }

  .modal-children {
    display: flex;
    flex-direction: column;

    strong {
      font-size: 14px; // não possui ds-token
    }

    button {
      background: none;
      border: none;
      outline: none;
      width: fit-content;
      font-weight: 700;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.primaryDefault};
      margin-left: auto;
      padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[4]};

      &:hover {
        background-color: ${({ theme }) => theme.colors.primaryLight90};
      }
    }
  }

  .btn-group {
    margin-top: 50px;
    display: flex;
    gap: 16px;
  }

  @media (max-width: 1024px) {
    margin: 0 16px;
  }
`;
