import { Dispatch, SetStateAction, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HTTP_STATUS from "http-status-codes";
import Icon from "@mdi/react";

import { SpinLoading } from "components/atoms/SpinLoading";
import { DocumentStatus } from "components/atoms/DocumentStatus";
import { toastError, toastSuccess } from "components/Toast";

import { mdiTrayArrowUp, mdiCloseCircle } from "@mdi/js";

import { DocumentStatusOptions, IDocumentProps } from "interfaces/documents";
import { ILocationProps, IUserDocumentsData } from "../../types";
import { IHandleOnChangeProps, IHandleUploadFiles } from "./types";

import {
  createStudentDocument,
  deleteStudentDocument,
  getUserDocuments,
  updateStudentDocument,
} from "services/documents.service";
import { readFileAsBase64 } from "helpers/helpers";

import * as S from "./styles";
import { Tag } from "@escolamais-ui-v2/react";

interface IDocumentCardProps {
  prefixo: string;
  documento: IDocumentProps;
  userId: number;
  setUpdateDocuments: Dispatch<SetStateAction<IUserDocumentsData>>;
}

export const DocumentCard = ({
  documento,
  prefixo,
  userId,
  setUpdateDocuments,
}: IDocumentCardProps) => {
  const { t } = useTranslation();
  const i18n_prefix = "pages.EDF.studentDocuments.components.documentCard";

  const MAX_FILE_SIZE = 1024 * 1024 * 15;
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [isLoadingUploadingFiles, setIsLoadingUploadingFiles] = useState(false);

  const documentIsPending = "pendente";

  const { state } = useLocation<ILocationProps>();

  const handleUserDocuments = async () => {
    const userDocumentsData = await getUserDocuments(state.student.id);

    if (userDocumentsData?.status === HTTP_STATUS.OK) {
      setUpdateDocuments(userDocumentsData.data);
    } else {
      toastError(t(`${i18n_prefix}.handleUserDocumentsError`));
    }
  };

  const handleUploadFiles = async (props: IHandleUploadFiles) => {
    if (props.arquivos.length > 0) {
      if (props.documento_id && props.status) {
        const body = {
          tipo: props.tipo,
          prefixo: props.prefixo,
          arquivos: props.arquivos,
          status: props.status,
          id: props.id,
        };
        const studentDocuments = await updateStudentDocument({
          documento_id: props.documento_id,
          body,
        });
        if (studentDocuments?.status === HTTP_STATUS.OK) {
          await handleUserDocuments();
          toastSuccess(t(`${i18n_prefix}.handleUploadFilesSuccess`));
        } else {
          toastError(t(`${i18n_prefix}`));
        }

        setIsLoadingUploadingFiles(false);
      } else {
        const studentDocuments = await createStudentDocument(props);

        if (studentDocuments?.status === HTTP_STATUS.OK) {
          await handleUserDocuments();
          toastSuccess(t(`${i18n_prefix}.handleUploadFilesSuccess`));
        } else {
          toastError(t(`${i18n_prefix}`));
        }

        setIsLoadingUploadingFiles(false);
      }
    } else {
      toastError("Selecione os documentos.");
      setIsLoadingUploadingFiles(false);
    }
  };

  const handleOnChange = async ({
    id,
    tipo,
    prefixo,
    eventFiles,
    documento_id,
  }: IHandleOnChangeProps) => {
    setIsLoadingUploadingFiles(true);

    const files = Array.prototype.slice.call(eventFiles.target.files);

    if (files.length > 2) {
      setIsLoadingUploadingFiles(false);
      toastError(t(`${i18n_prefix}.filesLengthExceded`));
      return;
    }

    if (!files.every((file: File) => file.size < MAX_FILE_SIZE)) {
      setIsLoadingUploadingFiles(false);
      toastError(t(`${i18n_prefix}.filesSizeExceded`));
      return;
    }

    if (files) {
      const filesFormatted = [];

      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        // eslint-disable-next-line no-await-in-loop
        const base64Data = await readFileAsBase64(file);

        filesFormatted.push({
          nome: file.name,
          arquivo: `${base64Data}`,
        });
      }

      if (id) {
        handleUploadFiles({
          id,
          tipo,
          prefixo,
          status: "analise",
          arquivos: filesFormatted,
          documento_id,
        });
      }
    }
  };

  const handleDeleteFile = async (fileId: number) => {
    setIsLoadingAction(true);

    const studentDocuments = await deleteStudentDocument(fileId);

    if (studentDocuments?.status === HTTP_STATUS.NO_CONTENT) {
      await handleUserDocuments();
      toastSuccess(t(`${i18n_prefix}.handleDeleteFileSuccess`));
    } else {
      toastError(t(`${i18n_prefix}.handleDeleteFileError`));
    }

    setIsLoadingAction(false);
  };

  const archives = documento.arquivos;
  const documentStatus = documento.status as DocumentStatusOptions;

  const requiredDocuments = ["9", "3", "6", "11", "7", "1"];

  const filterRequiredDocument = (document: string) => {
    if (requiredDocuments.includes(document)) {
      return true;
    }
    return false;
  };

  return (
    <S.Container disabled>
      <div className="documentName">
        <strong>{documento.nome}</strong>
        {filterRequiredDocument(documento.tipo) && (
          <Tag appearance="error">Obrigatório</Tag>
        )}
      </div>

      {documento.tipo === "6" && (
        <span className="custodyDeclaration">
          Obrigatório quando aplicável*
        </span>
      )}

      {documentStatus === documentIsPending && !isLoadingUploadingFiles && (
        <S.PendingDocument>
          {t(`${i18n_prefix}.pendingDocuments`)}
        </S.PendingDocument>
      )}

      {documento.motivo_reprovacao && (
        <S.RepprovalReason>
          <strong>{t(`${i18n_prefix}.disapprovalReason`)}</strong>{" "}
          {documento.motivo_reprovacao}
        </S.RepprovalReason>
      )}

      {documentStatus !== documentIsPending && !isLoadingUploadingFiles && (
        <DocumentStatus variant={documentStatus} />
      )}

      {isLoadingUploadingFiles && (
        <S.LoadingSpin>
          <SpinLoading />
          {t(`${i18n_prefix}.loadingDocument`)}
        </S.LoadingSpin>
      )}

      {archives.length > 0 && !isLoadingUploadingFiles && (
        <S.FilesList>
          {archives.map((file) => (
            <S.FilesListItem>
              <a href={file?.arquivo} target="_blank" rel="noopener noreferrer">
                {file?.nome}
              </a>
              <S.FilesListDeleteButton
                type="button"
                onClick={() => handleDeleteFile(file.id)}
              >
                <Icon path={mdiCloseCircle} />
              </S.FilesListDeleteButton>
            </S.FilesListItem>
          ))}
        </S.FilesList>
      )}

      <S.UploadDocumentsGroup>
        <S.UploadDocument disabled={isLoadingUploadingFiles || isLoadingAction}>
          <input
            type="file"
            id="file-input"
            name="file-input"
            onChange={(e) => {
              let body: IHandleOnChangeProps = {
                id: userId,
                tipo: documento.tipo,
                prefixo,
                eventFiles: e,
              };

              if (documento.id_documento) {
                body.documento_id = documento.id_documento;
              }

              handleOnChange(body);
            }}
            accept=".pdf, .jpeg, .png, .jpg, .jfif, .heic"
            multiple
          />
          <Icon path={mdiTrayArrowUp} />
          Upload
        </S.UploadDocument>
      </S.UploadDocumentsGroup>
    </S.Container>
  );
};
