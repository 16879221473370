/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
import {
  StudentInterface,
  StudentDataInterface,
  EnrollmentInterface,
} from "interfaces/constants";
import { Personal } from "interfaces/students";
import { useEffect, useMemo, useState } from "react";
import { GetUserKanataResponse, getUserKanata } from "services/grades.service";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isBetween from "dayjs/plugin/isBetween";
import "dayjs/locale/pt-br";
import { toastError } from "components/Toast";
import { getDateByDayAndMonthLong } from "helpers/dayjs";
import { IItinerarieComponent, IItinerary } from "types/study-itinerary";
import {
  getOverdueStudyItinerary,
  getStudyItinerary,
  getStudyItineraryStatus,
} from "services/roteiros.service";

interface IUseStudyItinerariesOutput {
  studyItinerary?: IItinerary;
  overdueItineraries?: IItinerary[];
  studentPersonal: StudentInterface | Personal | undefined;
  studentEnrollment: EnrollmentInterface | undefined;
  isToday: boolean;
  fetchLastWeek?: () => void;
  fetchNextWeek?: () => void;
  fetchCurrentWeek: () => void;
  isFetching: boolean;
}

interface IStudyItinerariesStudentData {
  selectedStudent?: StudentInterface;
  userStudent?: StudentDataInterface;
  userKanata?: GetUserKanataResponse;
  role?: string;
}

export const useStudyItineraries = ({
  selectedStudent,
  userStudent,
  userKanata,
  role,
}: IStudyItinerariesStudentData): IUseStudyItinerariesOutput => {
  dayjs.locale("pt-br");
  const todayFormattedDate = dayjs().format("DD/MM/YYYY");
  dayjs.extend(isToday);
  dayjs.extend(isBetween);

  const [studyItinerary, setStudyItinerary] = useState<IItinerary | undefined>(
    undefined
  );
  const [overdueItineraries, setOverdueItineraries] = useState<
    IItinerary[] | undefined
  >(undefined);
  const [itinerariesDate, setItinerariesDate] =
    useState<string>(todayFormattedDate);
  const [isTodayBtn, setIsTodayBtn] = useState<boolean>(true);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const augmentComponentWithLoadingState = (
    itinerary: IItinerary,
    components: IItinerarieComponent[]
  ) => {
    return {
      ...itinerary,
      components: components.map((c) => ({
        ...c,
        loading: true,
      })),
    };
  };

  const augmentItineraryComponentsWithErrors = (
    itinerary: IItinerary,
    component: IItinerarieComponent,
    components: IItinerarieComponent[]
  ) => {
    const componentWithError = {
      ...component,
      loading: false,
      error: true,
    };

    const indexComp = components.findIndex(
      (comp) => comp.course_id === component.course_id
    );
    components[indexComp] = componentWithError;

    const newStudyItineraries = {
      ...itinerary,
      components: [...components],
    };
    setStudyItinerary(newStudyItineraries);
  };

  const augmentItineraryComponentsWithStatusAndGrades = async (
    itinerary: IItinerary,
    module_title: string,
    components: IItinerarieComponent[],
    studentId: number
  ) => {
    for (const component of components) {
      try {
        setIsFetching(true);
        if (component.id && module_title) {
          const statusResponse = await getStudyItineraryStatus({
            course_id: component.course_id,
            assignment_name: module_title,
            component_id: component.id,
            canvas_student_id: studentId,
          });

          const augmentedComponent = {
            ...component,
            assignment_grade: statusResponse.data.nota,
            assignment_status: statusResponse.data.status,
            loading: false,
          };

          const indexComp = components.findIndex(
            (comp) => comp.id === component.id
          );
          components[indexComp] = augmentedComponent;

          const newStudyItineraries = {
            ...itinerary,
            components: [...components],
          };
          setStudyItinerary(newStudyItineraries);
        } else {
          augmentItineraryComponentsWithErrors(
            itinerary,
            component,
            components
          );
        }
      } catch (error) {
        if (error) {
          augmentItineraryComponentsWithErrors(
            itinerary,
            component,
            components
          );
        }
      }
    }
    setIsFetching(false);
  };

  const showNoticeSpecialWeek = (itinerary: IItinerary) => {
    const weekHasNoComponents =
      !itinerary?.components && !itinerary?.module_title && itinerary?.message;

    if (weekHasNoComponents) {
      setStudyItinerary(itinerary);
    }
  };

  const getStudentItineraryForGuardian = async (studentId: number) => {
    try {
      const response = await getUserKanata(studentId);
      if (response?.student?.id) {
        const studentIdKanata = response?.student?.id;

        const payload = {
          canvas_student_id: studentIdKanata,
          unit_id: response.gradegroup.school_unit,
          date: itinerariesDate,
        };

        const studentItineraryResponse = await getStudyItinerary(payload);
        if (
          studentItineraryResponse?.data?.components &&
          studentItineraryResponse?.data?.module_title
        ) {
          const fetchedItinerary = studentItineraryResponse.data;
          const fetchedComponents = studentItineraryResponse.data.components;
          const fetchedModuleTitle = studentItineraryResponse.data.module_title;

          const loadingItineraries = augmentComponentWithLoadingState(
            fetchedItinerary,
            fetchedComponents
          );

          setStudyItinerary(loadingItineraries);

          await augmentItineraryComponentsWithStatusAndGrades(
            fetchedItinerary,
            fetchedModuleTitle,
            loadingItineraries.components,
            studentIdKanata
          );
        } else {
          showNoticeSpecialWeek(studentItineraryResponse.data);
        }
      }
    } catch (error) {
      toastError("Erro ao buscar itinerários");
    }
  };

  const getStudentItineraryForStudent = async (user: GetUserKanataResponse) => {
    try {
      setIsFetching(true);
      const payload = {
        canvas_student_id: user.student.id,
        unit_id: user.gradegroup.school_unit,
        date: itinerariesDate,
      };
      const response = await getStudyItinerary(payload);
      if (response?.data?.components && response?.data?.module_title) {
        const fetchedItinerary = response.data;
        const fetchedComponents = response.data.components;
        const fetchedModuleTitle = response.data.module_title;

        const loadingItineraries = augmentComponentWithLoadingState(
          fetchedItinerary,
          fetchedComponents
        );

        setStudyItinerary(loadingItineraries);

        await augmentItineraryComponentsWithStatusAndGrades(
          fetchedItinerary,
          fetchedModuleTitle,
          loadingItineraries.components,
          user.student.id
        );
      } else {
        showNoticeSpecialWeek(response.data);
      }
    } catch (error) {
      toastError("Erro ao buscar itinerários");
    } finally {
      setIsFetching(false);
    }
  };

  const isValidGuardian = () => {
    return role === "Guardian" && selectedStudent ? selectedStudent : undefined;
  };

  const isValidStudent = () => {
    return userKanata;
  };

  const getCorrectStudentInfoBasedOnRole = () => {
    const guardian = isValidGuardian();
    if (guardian) {
      return getStudentItineraryForGuardian(guardian.enrollment?.student_id);
    }

    const student = isValidStudent();
    if (student) {
      return getStudentItineraryForStudent(student);
    }
  };

  const checkIfTodaysDateIsBetweenGivenDates = (itinerary: IItinerary) => {
    if (
      itinerary?.start_weekday &&
      itinerary?.start_month &&
      itinerary?.end_weekday &&
      itinerary?.end_month
    ) {
      const today = dayjs();
      const startDate = getDateByDayAndMonthLong(
        itinerary.start_weekday,
        itinerary.start_month
      );
      const finalDate = getDateByDayAndMonthLong(
        itinerary.end_weekday,
        itinerary.end_month
      );

      const isDateBetween = dayjs(today).isBetween(startDate, dayjs(finalDate));
      setIsTodayBtn(isDateBetween);
    }
  };

  const getOverdueItineraryForGuardian = async (studentId: number) => {
    try {
      const response = await getUserKanata(studentId);
      if (response?.student?.id) {
        try {
          const payload = {
            canvas_student_id: response.student.id,
            unit_id: response.gradegroup.school_unit,
          };

          const overdueItineraries = await getOverdueStudyItinerary(payload);
          if (overdueItineraries?.data) {
            setOverdueItineraries(overdueItineraries.data);
          }
        } catch (error) {
          toastError("Erro ao buscar componentes da seção coloque em dia");
        }
      }
    } catch (error) {
      toastError("Erro ao buscar estudante");
    }
  };

  const getOverdueItineraryForStudent = async (
    student: GetUserKanataResponse
  ) => {
    try {
      const payload = {
        canvas_student_id: student.student.id,
        unit_id: student.gradegroup.school_unit,
      };
      const response = await getOverdueStudyItinerary(payload);
      if (response?.data) {
        setOverdueItineraries(response.data);
      }
    } catch (error) {
      toastError("Erro ao buscar componentes da seção coloque em dia");
    }
  };

  const getOverdueStudentItinerary = async () => {
    const student = isValidStudent();
    if (student) {
      return getOverdueItineraryForStudent(student);
    }

    const guardian = isValidGuardian();
    if (guardian) {
      return getOverdueItineraryForGuardian(guardian.enrollment?.student_id);
    }
  };

  useEffect(() => {
    getCorrectStudentInfoBasedOnRole();
  }, [itinerariesDate]);

  useEffect(() => {
    getOverdueStudentItinerary();
  }, []);

  useEffect(() => {
    if (studyItinerary) {
      checkIfTodaysDateIsBetweenGivenDates(studyItinerary);
    }
  }, [studyItinerary?.start_weekday]);

  const fetchLastWeek = () => {
    if (studyItinerary?.start_weekday && studyItinerary?.start_month) {
      const date = getDateByDayAndMonthLong(
        studyItinerary.start_weekday,
        studyItinerary.start_month
      );
      if (date) {
        const lastWeekDate = dayjs(date)
          .subtract(7, "day")
          .format("DD/MM/YYYY");
        return setItinerariesDate(lastWeekDate);
      }
    }
  };

  const fetchNextWeek = () => {
    if (studyItinerary?.end_weekday && studyItinerary?.end_month) {
      const date = getDateByDayAndMonthLong(
        studyItinerary.end_weekday,
        studyItinerary.end_month
      );
      if (date) {
        const nextWeekDate = dayjs(date).add(7, "day").format("DD/MM/YYYY");
        return setItinerariesDate(nextWeekDate);
      }
    }
  };

  const fetchCurrentWeek = () => {
    setItinerariesDate(todayFormattedDate);
  };

  const studentPersonal = useMemo(() => {
    return userStudent ? userStudent.personal : selectedStudent;
  }, [selectedStudent, userStudent]);

  const studentEnrollment = useMemo(() => {
    return userStudent
      ? userStudent?.enrollment[0]
      : selectedStudent?.enrollment;
  }, [selectedStudent?.enrollment, userStudent]);

  return {
    studyItinerary,
    overdueItineraries,
    studentEnrollment,
    studentPersonal,
    isToday: isTodayBtn,
    fetchLastWeek,
    fetchNextWeek,
    fetchCurrentWeek,
    isFetching,
  };
};
