import { LinearProgress } from "@material-ui/core";
import { Button } from "components";
import HTTP_STATUS from "http-status-codes";
import { GetCXUserJson } from "interfaces/constants";
import { useAtomValue } from "jotai";
import { authUserAtom } from "jotai/authUser";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getCXUser } from "services/users.service";

interface GuardianFirstLoginInterface {
  setChangePasswordLater: (changePasswordLater: boolean) => void;
}

type GetCXUserJsonType = {
  data: GetCXUserJson;
  status: number;
};

export const GuardianFirstLogin = ({
  setChangePasswordLater,
}: GuardianFirstLoginInterface) => {
  const [guardian] = useState<GetCXUserJson | null>(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const authUser = useAtomValue(authUserAtom);

  const history = useHistory();

  useEffect(() => {
    getCXUser({ id: authUser.id })
      .then(({ data, status }: GetCXUserJsonType) => {
        if (status === HTTP_STATUS.OK) {
          history.push(
            `/responsavel/editar?guardianAuthId=${data.user_id}&guardianId=${data.id}`
          );
        } else {
          setError(true);
        }
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      {error && <div>Erro ao carregar dados do usuário</div>}
      {loading && <LinearProgress className="mt-3 mb-3" color="secondary" />}
      {guardian && (
        <>
          <div>
            Para maior segurança dos seus dados, recomendamos que faça a
            alteração da sua senha para uma senha de sua escolha.
          </div>
          <div className="row">
            <div className="col-12">
              <Button
                onClick={() =>
                  history.push(
                    `/alterar-senha?guardianAuthId=${guardian.user_id}&guardianId=${guardian.id}`
                  )
                }
                type="button"
              >
                Quero alterar minha senha
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Button
                onClick={() =>
                  history.push(
                    `/responsavel/editar?guardianAuthId=${guardian.user_id}&guardianId=${guardian.id}`
                  )
                }
                className="blue outline"
                type="button"
              >
                Vou alterar minha senha depois
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
