import styled, { css } from "styled-components";
import { ITextStyle } from "./interface";

export const DrawerContent = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.space[4]};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  div.drawerstudentInfo {
    display: flex;
    justify-content: center;

    gap: 0 ${({ theme }) => theme.space[3]};

    label {
      height: 27px;
    }
  }

  div.calendar {
    width: 360px;
    margin-top: 32px;
  }

  div.foodContainer {
    margin-top: ${({ theme }) => theme.space[4]};
    padding: ${({ theme }) => theme.space[6]} ${({ theme }) => theme.space[4]};
    gap: ${({ theme }) => theme.space[2]};

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 329px;

    background: rgba(109, 40, 217, 0.05);
    border-radius: 29px;
  }

  hr {
    width: 100%;

    margin: ${({ theme }) => theme.space[2]} 0;
  }

  button.button {
    width: 195px;
    margin-top: ${({ theme }) => theme.space[8]};
    margin-left: auto;

    background-color: ${({ theme }) => theme.colors.statusErrorDefault};
    color: ${({ theme }) => theme.colors.white};

    cursor: pointer;
    height: 53px; // nao possui na lib

    border: none;

    font-size: ${({ theme }) => theme.fontSizes.web.sm};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    text-transform: uppercase;

    &:hover {
      background-color: ${({ theme }) => theme.colors.statusErrorLight50};
    }

    &:disabled {
      background-color: ${({ theme }) => theme.colors.greyScale10};
      color: ${({ theme }) => theme.colors.greyScale40};
      cursor: not-allowed;

      :hover {
        background-color: none;
      }
    }
  }
`;

export const H4 = styled.h4<ITextStyle>`
  font-size: 18px; //nao possui na lib
  line-height: 27px; //nao possui na lib

  ${({ textStyle }) =>
    textStyle === "primary" &&
    css`
      font-weight: 600; //nao possui na lib
      color: ${({ theme }) => theme.colors.primaryDefault};
    `}

  ${({ textStyle }) =>
    textStyle === "secondary" &&
    css`
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      color: ${({ theme }) => theme.colors.greyScale95};
    `}
`;

export const SameLineText = styled.div<ITextStyle>`
  width: 100%;
  display: flex;
  justify-content: space-between;

  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: ${({ theme }) => theme.fontSizes.web.xsm};
  line-height: 18px; //nao possui na lib

  color: ${({ theme }) => theme.colors.greyScale95};

  ${({ textStyle }) =>
    textStyle === "grey" &&
    css`
      color: ${({ theme }) => theme.colors.greyScale40};
    `}
`;

export const LoadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-top: ${({ theme }) => theme.space[4]};
  width: 100%;

  h4 {
    ${({ theme }) => theme.fontSizes.web.h4}
    text-align: center;
  }

  div {
    margin: ${({ theme }) => theme.space[4]};
  }
`;
