import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 48px 97px;

  display: flex;
  flex-direction: column;

  margin: 0 auto;

  > strong {
    margin-top: ${({ theme }) => theme.space[5]};
    font-size: ${({ theme }) => theme.space[5]};
    font-weight: 600; //TODO: nao tem na lib
  }
`;

export const DataEmpty = styled.h1`
  font-size: ${({ theme }) => theme.space[6]};
  margin-top: ${({ theme }) => theme.space[5]};
`;
