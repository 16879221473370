import { KanataAPI } from "./api";
import { ErrorHandlerFunction } from "./service-helpers";

interface IContractResponse {
  chave_contrato_assinatura: string;
}
interface IGenerateContract {
  enrollmentId: number;
}

export const generateContract = async ({ enrollmentId }: IGenerateContract) => {
  const response = await KanataAPI.get<IContractResponse>(
    `contratos/chave_contrato/${enrollmentId}/`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};
