import styled from "styled-components";
import * as colors from "styles/colors";

export const Container = styled.div`
  border-radius: 29px;
  background: rgba(109, 40, 217, 0.05);
  padding: 24px 16px;

  width: 100%;
  max-width: 720px;

  .foodInfo {
    display: flex;
    flex-direction: column;
    margin-top: 12px;

    p,
    strong {
      margin: 0;
      display: flex;
      justify-content: space-between;
    }

    p + p {
      margin-top: 4px;
    }

    .totalValue {
      font-size: 18px;
    }

    strong {
      margin-top: 8px;
      padding-top: 8px;
      border-top: 1px solid ${colors.NEW_GRAY_THREE};
    }

    .creditAmount {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: ${({ theme }) => theme.space[4]};
      gap: 8px;

      .handleCredit {
        display: flex;
        align-items: center;
        width: 100%;

        & > div {
          display: flex;
          align-items: center;
          gap: ${({ theme }) => theme.space[2]};
          flex: 1;
        }

        label {
          margin-bottom: 0;
        }
      }
    }

    .paymentMethod {
      margin-top: 1em;
      margin-bottom: 0;
      font-size: 12px;
    }

    div.paymentDate {
      margin-top: ${({ theme }) => theme.space[2]};
      color: ${({ theme }) => theme.colors.greyScale40};

      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-size: 12px;
      }
    }
  }
`;
