import { useTranslation } from "react-i18next";

import Icon from "@mdi/react";
import { mdiChevronRight, mdiChevronLeft, mdiKeyboardReturn } from "@mdi/js";

import { Postite } from "components/atoms";

import * as S from "./styles";
import { ItinerarieCard } from "components/molecules";
import { IItinerarieComponent, IItinerary } from "types/study-itinerary";

const prefix = "components.organisms.itinerariesGroup";

interface IItinerariesGroupProps {
  type: "current" | "past";
  itinerary?: IItinerary;
  isToday?: boolean;
  onFetchLastWeek?: () => void;
  onFetchNextWeek?: () => void;
  onFetchCurrentWeek?: () => void;
  isFetching?: boolean;
}

interface IItineraryWithUiStates extends IItinerarieComponent {
  loading: boolean;
  error: boolean;
}

export const ItinerariesGroup: React.FC<IItinerariesGroupProps> = ({
  type,
  itinerary,
  isToday,
  onFetchLastWeek,
  onFetchNextWeek,
  onFetchCurrentWeek,
  isFetching,
}) => {
  const { t } = useTranslation();

  const handleGetCurrentWeekItineraries = () => {
    if (onFetchCurrentWeek) {
      onFetchCurrentWeek();
    }
  };

  const handleGetLastWeekItineraries = () => {
    if (onFetchLastWeek) {
      onFetchLastWeek();
    }
  };

  const handleGetNextWeekItineraries = () => {
    if (onFetchNextWeek) {
      onFetchNextWeek();
    }
  };

  const renderProperMonthAndYear = (itinerary: IItinerary) => {
    if (itinerary.start_month !== itinerary.end_month) {
      return `${itinerary.start_month} ${itinerary.year} - ${itinerary.end_month} ${itinerary.year}`;
    }
    return `${itinerary.start_month} ${itinerary.year}`;
  };

  return (
    <S.Container type={type}>
      <div className="content">
        {type === "current" && (
          <S.Today
            current={!isToday}
            disabled={isFetching}
            onClick={handleGetCurrentWeekItineraries}
          >
            {!isToday && <Icon path={mdiKeyboardReturn} size={1} />}
            {t(`${prefix}.currentWeek`)}
          </S.Today>
        )}

        {itinerary && (
          <div className="data-info">
            <p>{renderProperMonthAndYear(itinerary)}</p>
            <p className="daysOfWeek">
              {t(`${prefix}.daysOfWeek`)} {itinerary.start_weekday} a{" "}
              {itinerary.end_weekday}{" "}
              {itinerary.module_title ? `(${itinerary.module_title})` : ""}
            </p>
          </div>
        )}

        {type === "current" && (
          <div className="next-prev">
            <button
              onClick={handleGetLastWeekItineraries}
              disabled={isFetching}
            >
              <Icon path={mdiChevronLeft} />
            </button>
            <button
              onClick={handleGetNextWeekItineraries}
              disabled={isFetching}
            >
              <Icon path={mdiChevronRight} />
            </button>
          </div>
        )}
      </div>

      {itinerary?.components && itinerary?.components?.length > 0 && (
        <>
          {itinerary?.components?.map((item: IItineraryWithUiStates, index) => (
            <ItinerarieCard
              key={`${item.course_name}-${index}-${item.course_id}`}
              itinerary={item}
              loading={item.loading}
              error={item.error}
            />
          ))}
        </>
      )}

      {itinerary?.message?.title && (
        <div className="postite-info">
          <Postite
            type="info"
            title={itinerary?.message?.title || "Erro"}
            content={
              itinerary?.message?.description.split(".").join(". ") || "Erro"
            }
            titleSize="14px"
            contentSize="12px"
          />
        </div>
      )}
    </S.Container>
  );
};
