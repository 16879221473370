import styled from "styled-components";
import * as colors from "styles/colors";

export const SchoolGradeFormStyled = styled.div`
  span {
    &.pink {
      background-color: ${colors.PINK};
    }
  }

  .start_month {
    border-bottom: 1px solid #cccccc;
    margin-top: 2em;
    padding: 10px;
    color: #42474a;
  }

  select {
    margin-top: 1em;
  }
`;
