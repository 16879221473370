import { AlertIcon } from "./AlertIcon";
import {
  AlertRootContainer,
  AlertRootContentContainer,
  AlertRootIconContainer,
} from "./style";
import {
  InfoOutlined,
  ErrorOutline,
  WarningOutlined,
  CheckOutlined,
} from "@material-ui/icons";

export type AlertSeverity = "error" | "info" | "success" | "warning";

interface IAlertRootProps {
  children: React.ReactNode;
  severity: AlertSeverity;
}

const iconsPerSeverity = {
  error: ErrorOutline,
  info: InfoOutlined,
  success: CheckOutlined,
  warning: WarningOutlined,
};

export const AlertRoot = ({ children, severity }: IAlertRootProps) => {
  return (
    <AlertRootContainer severity={severity}>
      <AlertRootIconContainer severity={severity}>
        <AlertIcon icon={iconsPerSeverity[severity]} />
      </AlertRootIconContainer>
      <AlertRootContentContainer>{children}</AlertRootContentContainer>
    </AlertRootContainer>
  );
};
