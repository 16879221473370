import { useTranslation } from "react-i18next";

import { UnitInterface } from "interfaces/unit";
import { YearsInterface } from "interfaces/constants";

import { Container, InputSelect } from "./styles";

interface IFiltersProps {
  units: [UnitInterface] | [];
  years: [YearsInterface] | [];
  onChangeUnit: (value: string) => void;
  onChangeYear: (value: string) => void;
  selectedUnit: string;
  selectedYear: string;
}

export const Filters = ({
  units,
  years,
  onChangeUnit,
  onChangeYear,
  selectedUnit,
  selectedYear,
}: IFiltersProps) => {
  const { t } = useTranslation();
  const i18n_prefix = "pages.EDF.manageVacancies.components.filters";

  return (
    <Container>
      <InputSelect>
        <label htmlFor="select-unit">{t(`${i18n_prefix}.unit`)}</label>
        <select
          id="select-unit"
          onChange={(e) => {
            onChangeUnit(e.target.value);
          }}
          name="unit"
          value={selectedUnit}
          required
        >
          <option value="">{t(`${i18n_prefix}.selectUnitDefaultValue`)}</option>
          {units?.map((unit, index) => {
            return (
              <option value={unit.id} key={index}>
                {unit.name}
              </option>
            );
          })}
        </select>
      </InputSelect>

      <InputSelect>
        <label htmlFor="select-year">{t(`${i18n_prefix}.year`)}</label>
        <select
          id="select-year"
          onChange={(e) => {
            onChangeYear(e.target.value);
          }}
          name="year"
          value={selectedYear}
          required
        >
          <option value="">{t(`${i18n_prefix}.selectYearDefaultValue`)}</option>
          {years?.map((year, index) => {
            return (
              <option value={year.value} key={index}>
                {year.label}
              </option>
            );
          })}
        </select>
      </InputSelect>
    </Container>
  );
};
