import { useEffect, useState } from "react";
import { LinearProgress } from "@material-ui/core";
import { Field, Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { OnChange } from "react-final-form-listeners";
import { FormApi } from "final-form";
import { useLocation } from "react-router";
import arrayMutators from "final-form-arrays";
import HTTP_STATUS from "http-status-codes";
import { deepCopy } from "helpers/helpers"; //F
import { Button, Checkbox } from "components";
import { CalendarComponent } from "components/Calendar";
import SelectInput from "components/Select";
import { toastError, toastSuccess } from "components/Toast";
import { StudentPresence } from "./components/StudentPresence";
import { StudentPresenceDoubleClass } from "./components/StudentPresenceDoubleClass";
import { DiarioDeClasseStyled } from "./style";
import {
  ClassDiaryInterface,
  StudentPresenceInterface,
} from "interfaces/TeacherInterfaces";
import {
  getTeacherStudentsPresences,
  setTeacherStudentsPresences,
} from "services/classDiary.service";
import {
  formatGetEnrollments,
  formatSetEnrollments,
  formatSetEnrollmentsSecondClass,
} from "./helpers";
import { getAssignClass, AssignClassResponse } from "services/grades.service";
import { isMonitor, isTeacher } from "helpers/constants";
import { Storage } from "Storage";
import { useAtomValue } from "jotai";
import { authUserAtom } from "jotai/authUser";

type ValuesType = {
  subject: number;
  gradegroup: string;
  date: Date;
  enrollments: Array<StudentPresenceInterface>;
  two_presences: boolean;
};

type StateType = {
  classDiary: ClassDiaryInterface;
};

function uniqBy<T>(a: Array<any>, key: (a: any) => number): T[] {
  let seen: any = {};
  return a.filter(function (item) {
    let k = key(item);
    return seen.hasOwnProperty(k) ? false : (seen[k] = true);
  });
}

export const DiarioDeClasse = () => {
  const role = Storage.props.role.get();
  const [enrollments, setEnrollments] =
    useState<Array<StudentPresenceInterface>>();
  const [loading, setLoading] = useState(false);
  const [loadingStudents, setLoadingStudents] = useState(false);
  const [error, setError] = useState(false);
  const authUser = useAtomValue(authUserAtom);
  const IS_DOUBLE_CLASS =
    enrollments && !!enrollments.find((e) => e.class_order === 2);
  const [assignClass, setAssignClass] = useState<AssignClassResponse[]>();
  const [subjectsSingle, setSubjectsSingle] = useState<AssignClassResponse[]>();
  const [uniqueTurmas, setUniqueTurmas] = useState<AssignClassResponse[]>();

  const location = useLocation<StateType>();
  const PODE_EDITAR = isTeacher(role) || isMonitor(role);

  const setPresenceOrFaultAll = (
    form: FormApi<any, Partial<any>>,
    values: ValuesType,
    presenceOrFault: string,
    param: string
  ) => {
    const enrollments = deepCopy(values.enrollments);
    enrollments.forEach((e: StudentPresenceInterface, i: number) => {
      // @ts-ignore
      e[param] = presenceOrFault;
    });
    form.change(`enrollments`, enrollments);
  };

  const getStudents = async ({
    form,
    date,
    subject,
    gradegroup,
  }: {
    form: FormApi<any, Partial<any>>;
    date: Date;
    subject: number;
    gradegroup: number;
  }) => {
    setLoadingStudents(true);
    const response = await getTeacherStudentsPresences({
      gradegroup,
      subject,
      date: typeof date === "string" ? date : date.toISOString().split("T")[0],
    });
    if (response && response.status === HTTP_STATUS.OK) {
      const enrollments_copy = deepCopy(response.data);
      form.change("enrollments", null);
      form.change(
        "two_presences",
        !!response.data.enrollments.find(
          (e: StudentPresenceInterface) => e.class_order === 2
        )
      );
      setEnrollments(formatGetEnrollments(response.data).enrollments);
      form.change(
        "enrollments",
        formatGetEnrollments(enrollments_copy).enrollments
      );
    }
    setLoadingStudents(false);
  };

  const onSubmit = async (values: ValuesType) => {
    const response = await setTeacherStudentsPresences({
      date:
        typeof values.date === "string"
          ? values.date
          : values.date.toISOString().split("T")[0],
      enrollments: formatSetEnrollments(values.enrollments),
      subject: values.subject,
      gradegroup: parseInt(values.gradegroup),
      user_id: authUser.id,
      class_order: 1,
    });
    if (response && response.status === HTTP_STATUS.OK) {
      toastSuccess("Diário de Classe da primeira aula salvo com sucesso");
      if (values.two_presences) {
        const responseTwoPresences = await setTeacherStudentsPresences({
          date:
            typeof values.date === "string"
              ? values.date
              : values.date.toISOString().split("T")[0],
          enrollments: formatSetEnrollmentsSecondClass(values.enrollments),
          subject: values.subject,
          gradegroup: parseInt(values.gradegroup),
          user_id: authUser.id,
          class_order: 2,
        });
        if (
          responseTwoPresences &&
          responseTwoPresences.status === HTTP_STATUS.OK
        ) {
          toastSuccess("Diário de Classe da segunda aula salvo com sucesso");
        } else {
          toastError("Erro ao salvar diário de classe da segunda aula");
        }
      }
    } else {
      toastError("Erro ao salvar diário de classe da primeira aula");
    }
  };

  useEffect(() => {
    const fetchSubjects = async () => {
      setLoading(true);
      const response = await getAssignClass({
        unit: authUser.grade_unit_id,
        user_id: authUser.id,
      });
      if (response && response.status === HTTP_STATUS.OK) {
        setAssignClass(response.data);
        setSubjectsSingle(
          uniqBy<AssignClassResponse>(
            response.data,
            (a: AssignClassResponse) => a.subject
          )
        );
        setUniqueTurmas(
          uniqBy<AssignClassResponse>(
            response.data,
            (a: AssignClassResponse) => a.gradegroup
          )
        );
        setLoading(false);
      }
    };

    const fetchUsers = async () => {
      if (location.state?.classDiary) {
        const response = await getTeacherStudentsPresences({
          gradegroup: location.state.classDiary.gradegroup,
          subject: location.state.classDiary.subject_id,
          date: location.state.classDiary.presence_date,
        });
        if (response && response.status === HTTP_STATUS.OK) {
          setEnrollments(formatGetEnrollments(response.data).enrollments);
        }
      }
    };

    try {
      fetchSubjects();
      fetchUsers();
    } catch (err) {
      setError(true);
      setLoading(false);
    }
  }, [authUser.grade_unit_id, authUser.id, location.state]);

  const renderTitleName = (): string => {
    let title = "Diário de Classe";

    if (location.state?.classDiary) {
      title += `${location.state.classDiary.user_name} - 
      ${location.state.classDiary.grade}º${location.state.classDiary.letter} 
      ${location.state.classDiary?.educational_level_name}`;
    }

    return title;
  };

  return (
    <DiarioDeClasseStyled className="container">
      <div className="title">{renderTitleName()}</div>
      {loading && <LinearProgress className="mt-3 mb-3" color="secondary" />}
      {error && <div>Erro ao carregar turmas do professor.</div>}
      {assignClass && assignClass.length === 0 && (
        <div>Você não possui disciplinas ministradas.</div>
      )}
      {assignClass &&
        assignClass.length > 0 &&
        !loading &&
        subjectsSingle &&
        uniqueTurmas && (
          <Form
            initialValues={
              location.state?.classDiary && {
                subject: location.state.classDiary.subject_id,
                gradegroup: location.state.classDiary.gradegroup,
                date: new Date(
                  location.state.classDiary.presence_date
                ).toLocaleDateString("pt-BR"),
                two_presences: IS_DOUBLE_CLASS,
                enrollments,
              }
            }
            mutators={{
              ...arrayMutators,
            }}
            onSubmit={onSubmit}
          >
            {({ form, handleSubmit, submitting, values }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className="row mb-5 ml-2">
                    <Field<string>
                      component={SelectInput}
                      className="col-4 mr-4"
                      name="subject"
                      required
                      disabled={!PODE_EDITAR}
                    >
                      <option value={0}>Selecione uma disciplina</option>
                      {subjectsSingle.sort().map((subject, key) => {
                        return (
                          <option value={subject.subject} key={key}>
                            {subject.subject_name}
                          </option>
                        );
                      })}
                    </Field>
                    {values.subject && (
                      <>
                        <Field<string>
                          component={SelectInput}
                          className="col-4"
                          name="gradegroup"
                          required
                          disabled={!PODE_EDITAR}
                        >
                          <option value={0}>Selecione uma turma</option>
                          {location.state
                            ? assignClass
                                .filter(
                                  (item) =>
                                    item.gradegroup ===
                                      location.state.classDiary?.gradegroup &&
                                    item.user_id ===
                                      location.state.classDiary?.user_id
                                )
                                .sort((a, b) => a.grade - b.grade)
                                .map((tsg, key) => {
                                  return (
                                    <option value={tsg.gradegroup} key={key}>
                                      {tsg.grade}º {tsg.letter} -{" "}
                                      {tsg.educational_level_name}
                                    </option>
                                  );
                                })
                            : assignClass
                                .filter(
                                  (item) =>
                                    item.subject === parseInt(values.subject)
                                )
                                .sort((a, b) => a.grade - b.grade)
                                .map((tsg, key) => {
                                  return (
                                    <option value={tsg.gradegroup} key={key}>
                                      {tsg.grade}º {tsg.letter} -{" "}
                                      {tsg.educational_level_name}
                                    </option>
                                  );
                                })}
                        </Field>
                        <OnChange name="gradegroup">
                          {async (value, previous) => {
                            form.change("date", null);
                            form.change("enrollments", null);
                          }}
                        </OnChange>
                      </>
                    )}
                    {values.subject && values.gradegroup && (
                      <div className="col-3">
                        <Field
                          component={CalendarComponent}
                          name="date"
                          placeholder="Data"
                          start="Month"
                          min={new Date("2021-01-02")}
                          max={new Date()}
                          showTodayButton
                          valueCalendar={values.date}
                          required
                          disabled={!PODE_EDITAR}
                        />
                        <OnChange name="date">
                          {async (value) => {
                            if (value) {
                              await getStudents({
                                form,
                                subject: values.subject,
                                gradegroup: values.gradegroup,
                                date: value,
                              });
                            }
                          }}
                        </OnChange>
                      </div>
                    )}
                  </div>

                  {loadingStudents && (
                    <LinearProgress className="mt-3 mb-3" color="secondary" />
                  )}
                  {values.enrollments && values.date && (
                    <>
                      <Field
                        component={Checkbox}
                        name="two_presences"
                        checked={values.two_presences}
                        disabled={!PODE_EDITAR}
                      />
                      Considerar essa chamada como aula dupla
                      {values.two_presences && (
                        <div>
                          <div className="row mt-3">
                            <div className="col-12">1ª aula:</div>
                          </div>
                          <div className="row">
                            <Button
                              type="button"
                              className="blue col-2"
                              onClick={() =>
                                setPresenceOrFaultAll(
                                  form,
                                  values,
                                  "3",
                                  "presence"
                                )
                              }
                              disabled={!PODE_EDITAR}
                            >
                              Dar presença a todos os alunos
                            </Button>
                            <Button
                              className="blue outline col-2 ml-3"
                              type="button"
                              onClick={() =>
                                setPresenceOrFaultAll(
                                  form,
                                  values,
                                  "2",
                                  "presence"
                                )
                              }
                              disabled={!PODE_EDITAR}
                            >
                              Dar falta a todos os alunos
                            </Button>
                          </div>
                          2ª aula:
                          <div className="row">
                            <Button
                              type="button"
                              className="blue col-2"
                              onClick={() =>
                                setPresenceOrFaultAll(
                                  form,
                                  values,
                                  "3",
                                  "presence_2"
                                )
                              }
                              disabled={!PODE_EDITAR}
                            >
                              Dar presença a todos os alunos
                            </Button>
                            <Button
                              className="blue outline col-2 ml-3"
                              type="button"
                              onClick={() =>
                                setPresenceOrFaultAll(
                                  form,
                                  values,
                                  "2",
                                  "presence_2"
                                )
                              }
                              disabled={!PODE_EDITAR}
                            >
                              Dar falta a todos os alunos
                            </Button>
                          </div>
                        </div>
                      )}
                      {!values.two_presences && (
                        <div className="row">
                          <Button
                            type="button"
                            className="blue col-2"
                            onClick={() =>
                              setPresenceOrFaultAll(
                                form,
                                values,
                                "3",
                                "presence"
                              )
                            }
                            disabled={!PODE_EDITAR}
                          >
                            Dar presença a todos os alunos
                          </Button>
                          <Button
                            className="blue outline col-2 ml-3"
                            type="button"
                            onClick={() =>
                              setPresenceOrFaultAll(
                                form,
                                values,
                                "2",
                                "presence"
                              )
                            }
                            disabled={!PODE_EDITAR}
                          >
                            Dar falta a todos os alunos
                          </Button>
                        </div>
                      )}
                      {values.two_presences && (
                        <div>
                          <div className="row mt-3 mb-3">
                            <div className="col-4 offset-4 text-center">
                              1ª aula
                            </div>
                            <div className="col-4 text-center">2ª aula</div>
                          </div>
                          <FieldArray name="enrollments">
                            {({ fields }) =>
                              fields.map(
                                (name, index) =>
                                  values.enrollments[index] && (
                                    <div key={name}>
                                      <StudentPresenceDoubleClass
                                        name={name}
                                        studentPresence={
                                          values.enrollments[index]
                                        }
                                        disabled={PODE_EDITAR}
                                      />
                                    </div>
                                  )
                              )
                            }
                          </FieldArray>
                        </div>
                      )}
                      {!values.two_presences && (
                        <FieldArray name="enrollments">
                          {({ fields }) =>
                            fields.map(
                              (name, index) =>
                                values.enrollments[index] && (
                                  <div key={name}>
                                    <StudentPresence
                                      name={name}
                                      studentPresence={
                                        values.enrollments[index]
                                      }
                                      disabled={PODE_EDITAR}
                                    />
                                  </div>
                                )
                            )
                          }
                        </FieldArray>
                      )}
                    </>
                  )}
                  {values.enrollments &&
                    values.date &&
                    values.gradegroup &&
                    values.subject && (
                      <div className="row">
                        <Button
                          type="submit"
                          className="offset-9 col-3"
                          disabled={!PODE_EDITAR}
                        >
                          Salvar
                        </Button>
                      </div>
                    )}
                </form>
              );
            }}
          </Form>
        )}
    </DiarioDeClasseStyled>
  );
};
