import { DocumentStatusOptions } from "interfaces/documents";
import styled, { css, DefaultTheme } from "styled-components";

const DocumentStatusStyles = (theme: DefaultTheme, variant = "aprovado") =>
  ({
    reprovado: css`
      border: none;
      background-color: ${({ theme }) => theme.colors.statusErrorDefault};
      color: ${({ theme }) => theme.colors.white};
    `,
    analise: css`
      border: none;
      background-color: ${({ theme }) => theme.colors.statusWarningDefault};
      color: ${({ theme }) => theme.colors.black};
    `,
    aprovado: css`
      border: 2px solid ${({ theme }) => theme.colors.statusSuccessDefault};
      color: ${({ theme }) => theme.colors.statusSuccessDefault};
    `,
  }[variant]);

interface IDocumentStatusStyleProps {
  variant: DocumentStatusOptions;
}

export const Container = styled.span<IDocumentStatusStyleProps>`
  font-size: ${({ theme }) => theme.fontSizes.web.sm};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  margin-top: ${({ theme }) => theme.space[1]};
  width: fit-content;
  padding: ${({ theme }) => theme.space[1]} ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => theme.radii.xs};

  ${({ theme, variant }) => DocumentStatusStyles(theme, variant)};
`;
