// Libs
import { useContext } from "react";
import { Modal as ModalMUI } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HTTP_STATUS from "http-status-codes";

// Components
import { Button } from "components";
import { toastSuccess, toastError } from "components/Toast";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

// Styles
import { ModalDiv, ModalHeader } from "./style";
import { postStudentFoodCredit } from "services/meals.service";
import { FormApi } from "final-form";
import { LastCreditType } from "../StudentFoodCreditCard";
import { formatMoneyToFloat } from "helpers/helpers";

interface ModalCardCreditFoodProps {
  setToggleModal: (toggleModal: boolean) => void;
  isOpen: boolean;
  enrollment: number;
  creditFoodValues: {
    credit: string;
    reason: string;
  };
  form: FormApi<any, Partial<any>>;
  resetForm: (form: FormApi) => void;
  setLastCredit: ({ credit, name, date }: LastCreditType) => void;
}

const useStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const ModalCardCreditFood = ({
  isOpen,
  setToggleModal,
  enrollment,
  creditFoodValues,
  form,
  resetForm,
  setLastCredit,
}: ModalCardCreditFoodProps) => {
  const classes = useStyles();

  const handlePostStudentFoodCredit = async () => {
    const response = await postStudentFoodCredit({
      payload: {
        enrollment,
        credit: parseFloat(formatMoneyToFloat(creditFoodValues.credit)),
        reason: creditFoodValues.reason,
      },
    });

    if (response && response.status === HTTP_STATUS.CREATED) {
      setLastCredit({
        credit: response.data.credit,
        name: response.data.user_name,
        date: new Date().toISOString(),
      });

      toastSuccess("O crédito foi lançado para o aluno");
      resetForm(form);
    } else {
      toastError("Não foi possível realizar o lançamento do crédito!");
    }

    setToggleModal(false);
  };

  return (
    <ModalMUI className={classes.modalStyle} open={isOpen}>
      <ModalHeader className="col-xl-3 col-md-6 col-11 bg-white">
        <FontAwesomeIcon
          className="close"
          onClick={() => setToggleModal(false)}
          size="lg"
          icon={faTimes as IconProp}
        />
        <ModalDiv>
          <strong className="title mb-3">
            Deseja confirmar o lançamento de {`R$ ${creditFoodValues.credit}`}{" "}
            de crédito?
          </strong>
          <p className="text mb-3 mt-3">
            Ao confirmar, o responsável poderá utilizar o crédito na compra de
            alimentação.
          </p>
          <div className="d-flex align-items-center justify-content-between">
            <Button
              className="pink outline widthButton"
              onClick={() => setToggleModal(false)}
            >
              Voltar
            </Button>
            <Button
              className="widthButton"
              onClick={handlePostStudentFoodCredit}
            >
              Confirmar
            </Button>
          </div>
        </ModalDiv>
      </ModalHeader>
    </ModalMUI>
  );
};
