import styled, { css } from "styled-components";
import * as colors from "styles/colors";
import { IPostiteStyles } from "./interface";

const postiteOptions = {
  info: colors.NEW_BLUE,
  success: colors.NEW_GREEN,
  warning: colors.NEW_YELLOW,
  danger: colors.NEW_RED,
};

export const Container = styled.div<IPostiteStyles>`
  margin: ${(props) => (props.margin ? props.margin : "24px 0 0 0")};
  padding: 8px 16px;

  max-width: 450px;
  height: ${(props) => (props.height ? props.height : "none")};
  width: ${(props) => (props.width ? props.width : "none")};

  display: flex;
  align-items: center;
  gap: 18px;

  svg {
    max-width: 60px;
    max-height: 60px;
  }

  ${({ type }) =>
    type &&
    css`
      border: 2px solid ${postiteOptions[type]};

      svg {
        color: ${postiteOptions[type]};
      }
    `}

  .description {
    display: flex;
    flex-direction: column;

    h5 {
      font-size: ${(props) => (props.titleSize ? props.titleSize : "10px")};
      font-weight: ${(props) =>
        props.titleWeight ? props.titleWeight : "bold"};
      line-height: ${(props) =>
        props.titleLineHeigth ? props.titleLineHeigth : "15px"};
    }

    p {
      font-size: ${(props) => (props.contentSize ? props.contentSize : "12px")};
      font-weight: ${(props) =>
        props.contentWeight ? props.contentWeight : "none"};
      line-height: ${(props) =>
        props.contentLineHeight ? props.contentLineHeight : "18px"};
      text-align: left;
    }
  }

  @media (min-width: 1024px) {
    .description {
      h5 {
        ${({ theme }) => theme.fontSizes.web.default};
      }

      p {
        font-size: 14px;
      }
    }
  }
`;
