import styled from "styled-components";

export const HoverActionsContainer = styled.div`
  display: none;
`;

export const ButtonStyled = styled.button`
  background-color: #f0e9fb;
  height: 100px;
  width: 228px;
  border: none;
  padding: 1rem;
  border-radius: 20px;

  display: flex;
  flex-direction: column;

  text-transform: uppercase;
  text-align: left;
  font-size: 0.875rem;
  font-weight: 700;
  color: #0d0d0d;

  &:hover ${HoverActionsContainer} {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
    flex: 1;
    gap: 5px;
  }

  &:hover {
    background-color: #8a53e1;
    color: #fff;
  }
`;
