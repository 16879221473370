import { EnrollmentsFilterResultsInterface } from "interfaces/enrollment";

import { leaveTime } from "interfaces/exitPermit";

import {
  education,
  Personal,
  Contact,
  educationLevel,
  guardians,
  health,
  location,
} from "interfaces/students";

import { Role } from "interfaces/role";

export interface SchoolReportFinalDataProps {
  "1º Trimestre": ComponentQuarterProps[];
  "2º Trimestre": ComponentQuarterProps[];
  "3º Trimestre": ComponentQuarterProps[];
  Final_averages: FinalAveragesProps[];
}

export enum SchoolReportColuns {
  M1 = "1º Trimestre",
  M2 = "2º Trimestre",
  M3 = "3º Trimestre",
  MF = "Final_averages",
}
export interface GuardianSchoolReportFinalProps {
  tbody: SchoolReportFinalDataProps;
  year: string;
}

export interface GuardianStudents {
  school_enrollment_payment: boolean;
  educational_level: educationLevel;
  enrollment: EnrollmentsFilterResultsInterface;
  last_enrollment: EnrollmentsFilterResultsInterface;
  old_enrollments: EnrollmentsFilterResultsInterface[];
  enrollment_years: number[];
  user_id: number;
  id: number;
  name: string;
  cpf: string;
  rg: string;
  rg_digitalized: string;
  cpf_digitalized: string;
  birth_certificate: string;
  scholarship_transfer_certificate: string;
  scholarship_historic: string;
  role: Role[];
  next_year: {
    grade: number;
    educational_level_name: string;
  };
  photo_url: null | string;
  guard_type: number;
  food_options: {
    lunch: boolean;
    first_snack: boolean;
    second_snack: boolean;
    third_snack: boolean;
    last_modified: string;
  };
  leave_time: {
    mon: string;
    tue: string;
    wed: string;
    thu: string;
    fri: string;
    transportation: number;
    last_modified: string;
  };
  address: string;
  same_address_as_guardian: number;
  number: string;
  neighborhood: string;
  complement: string;
  zip_code: string;
  city: string;
  birth_date: null | string;
  is_deleted: boolean;
  deleted_at: null | string;
  has_financial_guardian: boolean;
  guard_info: {
    guard_id: number;
    kinship: string;
    contract_digitalized: string;
    enrollment_value: number;
    enrollment_discount: null | string;
    enrollment_status: number;
    school_place_status: number;
    payment_status: number;
    financial_status: number;
    documents_status: number;
    contract_status: number;
    enrollment_type: string;
  };
}

export interface GuardianReturn {
  user_id: number;
  external_uuid: string;
  cfo: string;
  id: number;
  role: Role[];
  personal: Personal;
  contact: Contact;
  location: location;
  students: GuardianStudents[];
  is_deleted: boolean;
  deleted_at: null | string;
  last_modified: string;
  info_viewed: true;
}

export interface StudentReturn {
  ra: string;
  enrollment: EnrollmentsFilterResultsInterface[];
  last_enrollment: EnrollmentsFilterResultsInterface;
  old_enrollments: EnrollmentsFilterResultsInterface[];
  external_uuid: string;
  enrollment_years: number[];
  user_id: number;
  id: number;
  role: Role[];
  educational_level: education;
  gender_type: null | number;
  contact: Contact;
  personal: Personal;
  education: educationLevel;
  health: health;
  location: location;
  leave_time: {
    mon: string;
    tue: string;
    wed: string;
    thu: string;
    fri: string;
    transportation: number;
    last_modified: string;
  };
  guardians: guardians[];
  last_modified: string;
  is_deleted: boolean;
  deleted_at: null | string;
  info_viewed: boolean;
}

export interface BulletinLocationProps<T> {
  student: T;
  role: Role;
  indice?: number;
}

export interface ComponentQuarterProps {
  subject: string;
  short_name: string;
  quarter_avg: number;
  quarter_absences: number;
}
export interface FinalAveragesProps {
  subject: string;
  short_name: string;
  final_average: number;
  final_average_status: string;
}
export interface GetSchoolReportFinalProps {
  year: string;
  student_id?: string;
  kanata_id?: number;
}

export interface ItemDataSection {
  id: number;
  letter: string;
  school_unit: number;
}

export interface ItemData {
  id: number;
  grade: string;
  year: string;
  level: number;
  level_name: string;
  sections: ItemDataSection[];
}
export interface SchoolReportContextData {
  schoolReportFinal: SchoolReportFinalDataProps | undefined;
  loaded: boolean;
  getSchoolReportFinal: ({
    year,
    student_id,
  }: GetSchoolReportFinalProps) => Promise<void>;
  handleLoadData: () => void;
}
