// Styles
import { BoletoStyled } from "./style";
// Icons
import FilterNoneIcon from "@material-ui/icons/FilterNone";
// Interfaces
import { FoodPackage } from "interfaces/mealInterfaces";
import { FoodPackagePaymentInterface } from "interfaces/paymentInterfaces";
// Helpers
import { formatIpteString } from "helpers/helpers";
import { Button } from "components";

type BoletoType = {
  loading: boolean;
  error: boolean;
  foodPackage: FoodPackage;
  paymentData: FoodPackagePaymentInterface;
  setActiveStep: (activeStep: number) => void;
};

export const Boleto = ({
  foodPackage,
  paymentData,
  setActiveStep,
}: BoletoType) => {
  return (
    <div>
      {paymentData && (
        <BoletoStyled className="mt-3 col-12">
          <p className="mt-2 mb-3">
            Copie a linha digitável abaixo ou realize o pagamento através do
            Boleto!
          </p>
          <p className="mb-1 font-weight-bold text-secondary">
            Total do pacote: R${" "}
            {foodPackage.food_package_value_month_after_balance_formatted}
          </p>
          <div
            className="bankPaymentSlipCode text-secondary text-center"
            onClick={() => navigator.clipboard.writeText(`${paymentData.code}`)}
          >
            {`${formatIpteString(paymentData.code)}`}
            <FilterNoneIcon />
          </div>
          <a
            href={`${paymentData.pay_ticket}`}
            target="_blank"
            className="font-weight-bold mt-2"
            rel="noreferrer"
          >
            Baixar boleto
          </a>
        </BoletoStyled>
      )}
      <div className="row col-12">
        <div className="col-sm-3 col-6">
          <Button
            type="button"
            className="pink outline"
            onClick={() => setActiveStep(2)}
          >
            Voltar
          </Button>
        </div>
        <div className="offset-sm-6 col-sm-3 col-6">
          <Button
            type="button"
            className="mb-0"
            onClick={() => setActiveStep(4)}
          >
            Avançar
          </Button>
        </div>
      </div>
    </div>
  );
};
