import styled from "styled-components";
import * as colors from "styles/colors";

export const ConsultSinglePurchaseStyled = styled.div`
  font-family: "Poppins", Montserrat, sans-serif !important;

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 1em 3em;

  div.inputContainer {
    display: flex;
    margin-top: 1em;
    flex-wrap: wrap;

    div.inputBeyond {
      margin-right: 1.3em;
    }
  }

  div.tableContainer {
    margin-top: 3em;

    td.firstChield {
      padding-left: 1.281em;
    }
    tr.bordeBottom {
      border-bottom: 1px solid ${colors.NEW_GRAY_THREE};
    }

    div.pagination {
      margin-top: 1.5em;
    }
  }

  div.noStudentsFound {
    display: flex;
    justify-content: center;

    margin-top: 4em;
  }
`;
