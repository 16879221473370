import { SchoolReportResponse } from "components/BoletimV2/types";

interface UnitDropDown {
  id: number;
  name: string;
  unit_id_saf: number;
}

type Status = {
  id: string;
  statusName: string;
};

interface ReleaseCheck {
  name: string;
  value: boolean;
}

export type Section = {
  id: number;
  letter: string;
  school_unit: number;
};

export interface ButtonGroupProps {
  id: string | number;
  buttonText: string;
  onClick: () => void;
}

export interface AprovacaoPreviaFilterProps {
  buttonsList: Array<ButtonGroupProps>;
  activeId: string | number;
  label?: string;
}

export type DataItem = {
  id: number;
  grade: string;
  year: string;
  level: number;
  level_name: string;
  sections: Section[];
};

export interface Student {
  enrollment_id: number;
  student_id: number;
  name: string;
  ra: string;
  release_status: string;
  deleted: boolean;
  release_check: ReleaseCheck[];
}

export const enum EnumReleaseStatusTypes {
  Pendente = "Pendente",
  Aprovado = "Aprovado",
}

export interface DataTableProps {
  availableReleases: Array<string>;
  students: Array<Student>;
}

export interface Quarter {
  id: number;
  name: string;
  actived: boolean;
  begin_date: string;
  end_date: string;
  quarter: number;
  current: boolean;
  year: string;
  quarter_display: string;
}

export interface ReleaseCheckProps {
  results: [
    {
      id: number;
      name: string;
      available_releases: Array<string>;
      students: Array<Student>;
    }
  ];
}

export const enum QuarterEnum {
  PRIMEIRO_TRIMESTRE = 11,
  SEGUNDO_TRIMESTRE = 12,
  TERCEIRO_TRIMESTRE = 13,
}

export interface AprovacaoPreviaState {
  isCxView: boolean;
  originalDataTable: DataTableProps;
  dataTable: DataTableProps;
  currentClass: string;
  selectedStudent: Student;
  studentSchoolReport: SchoolReportResponse;
  errors: {
    dataTableError: boolean;
    quarterDropDownListError: boolean;
    loadingUnitDropDownListError: boolean;
  };
  loadings: {
    loadingDataTable: boolean;
    loadingUnitDropDownList: boolean;
    loadingBoletimV2Drawer: boolean;
  };
  filter: FilterProps;
  drawer: {
    isOpen: boolean;
  };
}

export interface FilterProps {
  levelButtons: Array<ButtonGroupProps> | null;
  gradeButtons: Array<ButtonGroupProps> | null;
  sectionButtons: Array<ButtonGroupProps> | null;
  levelButtonActiveId: string;
  gradeActiveId: string;
  sectionActiveId: string;
  unitDropdownList: Array<UnitDropDown> | null;
  unitDropdownActiveId: string;
  quarterDropDownList: Array<Quarter> | null;
  statusDropDownList: Array<Status> | null;
  quarterActiveId: string;
  statusActiveId: string;
  areAllActiveStudents: boolean;
}

export const enum AprovacaoPreviaActionTypes {
  OPEN_DRAWER = "OPEN_DRAWER",
  CLOSE_DRAWER = "CLOSE_DRAWER",
  GET_DATA_TABLE = "GET_DATA_TABLE",
  SET_DATA_TABLE_SUCCESS = "SET_DATA_TABLE_SUCCESS",
  SET_DATA_TABLE_ERROR = "SET_DATA_TABLE_ERROR",
  SET_DATA_TABLE_FILTERED = "SET_DATA_TABLE_FILTERED",
  DATA_TABLE_LOADING = "DATA_TABLE_LOADING",
  SET_FILTER_LEVEL_BUTTONS = "SET_FILTER_LEVEL_BUTTONS",
  SET_LEVEL_BUTTON_ACTIVE_ID = "SET_LEVEL_BUTTON_ACTIVE_ID",
  SET_FILTER_GRADE_BUTTONS = "SET_FILTER_GRADE_BUTTONS",
  SET_GRADE_BUTTON_ACTIVE_ID = "SET_GRADE_BUTTON_ACTIVE_ID",
  SET_FILTER_SECTION_BUTTONS = "SET_FILTER_SECTION_BUTTONS",
  SET_SECTION_BUTTON_ACTIVE_ID = "SET_SECTION_BUTTON_ACTIVE_ID",
  SET_CURRENT_CLASS_NAME = "SET_CURRENT_CLASS_NAME",
  SET_QUARTERS_DROPDOWN_SUCCESS = "SET_QUARTERS_DROPDOWN_SUCCESS",
  SET_QUARTERS_DROPDOWN_ERROR = "SET_QUARTERS_DROPDOWN_ERROR",
  SET_QUARTER_ACTIVE_ID = "SET_QUARTER_ACTIVE_ID",
  SET_STATUS_DROPDOWN_SUCCESS = "SET_STATUS_DROPDOWN_SUCCESS",
  SET_STATUS_DROPDOWN_ACTIVE_ID = "SET_STATUS_DROPDOWN_ACTIVE_ID",
  SET_CX_VIEW = "SET_CX_VIEW",
  GET_UNIT_DROPDOWN = "GET_UNIT_DROPDOWN",
  GET_UNIT_DROPDOWN_SUCCESS = "SET_UNIT_DROPDOWN_SUCCESS",
  GET_UNIT_DROPDOWN_ERROR = "SET_UNIT_DROPDOWN_ERROR",
  SET_UNIT_ACTIVE_ID = "SET_UNIT_ACTIVE_ID",
  FILTER_ONLY_ACTIVE_STUDENTS = "FILTER_ONLY_ACTIVE_STUDENTS",
  FILTER_ONLY_INACTIVE_STUDENTS = "FILTER_ONLY_INACTIVE_STUDENTS",
  SET_SELECTED_STUDENT = "SET_SELECTED_STUDENT",
  BOLETIMV2_DRAWER_LOADING = "BOLETIMV2_DRAWER_LOADING",
  SET_STUDENT_SCHOOL_REPORT = "SET_STUDENT_SCHOOL_REPORT",
}

export type AprovacaoPreviaActions =
  | { type: AprovacaoPreviaActionTypes.OPEN_DRAWER }
  | { type: AprovacaoPreviaActionTypes.CLOSE_DRAWER }
  | {
      type: AprovacaoPreviaActionTypes.SET_DATA_TABLE_SUCCESS;
      payload: DataTableProps;
    }
  | {
      type: AprovacaoPreviaActionTypes.SET_DATA_TABLE_ERROR;
      payload: boolean;
    }
  | {
      type: AprovacaoPreviaActionTypes.SET_FILTER_LEVEL_BUTTONS;
      payload: Array<ButtonGroupProps>;
    }
  | {
      type: AprovacaoPreviaActionTypes.SET_FILTER_GRADE_BUTTONS;
      payload: Array<ButtonGroupProps>;
    }
  | {
      type: AprovacaoPreviaActionTypes.SET_FILTER_SECTION_BUTTONS;
      payload: Array<ButtonGroupProps>;
    }
  | {
      type: AprovacaoPreviaActionTypes.SET_LEVEL_BUTTON_ACTIVE_ID;
      payload: string;
    }
  | {
      type: AprovacaoPreviaActionTypes.SET_GRADE_BUTTON_ACTIVE_ID;
      payload: string;
    }
  | {
      type: AprovacaoPreviaActionTypes.SET_SECTION_BUTTON_ACTIVE_ID;
      payload: string;
    }
  | {
      type: AprovacaoPreviaActionTypes.DATA_TABLE_LOADING;
      payload: boolean;
    }
  | {
      type: AprovacaoPreviaActionTypes.SET_QUARTERS_DROPDOWN_SUCCESS;
      payload: Array<Quarter>;
    }
  | {
      type: AprovacaoPreviaActionTypes.SET_QUARTERS_DROPDOWN_ERROR;
      payload: boolean;
    }
  | {
      type: AprovacaoPreviaActionTypes.SET_QUARTER_ACTIVE_ID;
      payload: string | number;
    }
  | {
      type: AprovacaoPreviaActionTypes.SET_CURRENT_CLASS_NAME;
      payload: string;
    }
  | {
      type: AprovacaoPreviaActionTypes.SET_STATUS_DROPDOWN_SUCCESS;
      payload: Array<Status>;
    }
  | {
      type: AprovacaoPreviaActionTypes.SET_STATUS_DROPDOWN_ACTIVE_ID;
      payload: string;
    }
  | {
      type: AprovacaoPreviaActionTypes.SET_DATA_TABLE_FILTERED;
      payload: DataTableProps;
    }
  | {
      type: AprovacaoPreviaActionTypes.SET_CX_VIEW;
    }
  | {
      type: AprovacaoPreviaActionTypes.GET_UNIT_DROPDOWN;
    }
  | {
      type: AprovacaoPreviaActionTypes.GET_UNIT_DROPDOWN_SUCCESS;
      payload: Array<UnitDropDown>;
    }
  | {
      type: AprovacaoPreviaActionTypes.GET_UNIT_DROPDOWN_ERROR;
    }
  | {
      type: AprovacaoPreviaActionTypes.SET_UNIT_ACTIVE_ID;
      payload: string;
    }
  | {
      type: AprovacaoPreviaActionTypes.FILTER_ONLY_ACTIVE_STUDENTS;
    }
  | {
      type: AprovacaoPreviaActionTypes.FILTER_ONLY_INACTIVE_STUDENTS;
    }
  | {
      type: AprovacaoPreviaActionTypes.SET_SELECTED_STUDENT;
      payload: Student;
    }
  | {
      type: AprovacaoPreviaActionTypes.BOLETIMV2_DRAWER_LOADING;
      payload: boolean;
    }
  | {
      type: AprovacaoPreviaActionTypes.SET_STUDENT_SCHOOL_REPORT;
      payload: SchoolReportResponse;
    };
