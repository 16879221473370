import styled from "styled-components";

export const Container = styled.div`
  .modal-content {
    display: flex;
    border: none;

    input {
      border: 2px solid ${({ theme }) => theme.colors.greyScale40};
      padding: ${({ theme }) => theme.space[2]};
      font-size: ${({ theme }) => theme.fontSizes.web.sm};

      ::placeholder {
        color: ${({ theme }) => theme.colors.greyScale40};
      }
    }

    .modal-buttons {
      margin-left: auto;
      margin-top: ${({ theme }) => theme.space[2]};

      button {
        border: none;
        background-color: transparent;
        font-size: ${({ theme }) => theme.fontSizes.web.sm};
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        color: ${({ theme }) => theme.colors.primaryDefault};
        padding: 8px 16px;

        &:hover {
          background-color: ${({ theme }) => theme.colors.primaryLight90};
          transition: all 0.2s ease-in;
        }
      }

      button + button {
        margin-left: ${({ theme }) => theme.space[1]};
      }

      .submit-btn {
        color: ${({ theme }) => theme.colors.white};
        background-color: ${({ theme }) => theme.colors.primaryDefault};

        &:hover {
          background-color: ${({ theme }) => theme.colors.primaryLight50};
          transition: all 0.2s ease-in;
        }
      }
    }
  }
`;
