import { StudentInterface } from "interfaces/students";
import { IFormStudentValues } from "pages/EDF/EditStudentData/interface";
import { KanataAPI } from "./api";
import { ErrorHandlerFunction, Headers } from "./service-helpers";
import { createStudentFormData } from "pages/EDF/NewStudentSignup/schema";

export interface LookupStudentsResponse {
  id: number;
  name: string;
  cpf: string | null;
  email: string | null;
  enrollment_id: number;
  birth_date: string;
  rg: string | null;
  previous_school: string | null;
  gender_type: number | null;
  unit: number | null;
  grade: number | null;
  guardians: [
    {
      guard_id: number;
      id: number;
      name: string;
      cpf: string;
      email: string;
      type: string;
    }
  ];
  employees: [{ employee_name: string; employee_user_id: number }];
  non_field_errors: string[];
  students?: object[];
}

type LookupStudentsType = {
  params: { name: string; cpf?: string; birth_date: string };
};

export const lookupStudents = async ({ params }: LookupStudentsType) => {
  const response = await KanataAPI.get<LookupStudentsResponse>(
    `cx/lookup_student`,
    Headers({
      params,
    })
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export interface LinkEnrollmentToEmployeeInterface {
  enrollment: number;
  employee: number;
}

export type LinkEnrollmentToEmployeeType = {
  body: LinkEnrollmentToEmployeeInterface;
};

export const linkEnrollmentToEmployee = async ({
  body,
}: LinkEnrollmentToEmployeeType) => {
  const response = await KanataAPI.post<LookupStudentsResponse>(
    `cx/enrollment_employees`,
    body
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

// Novos endpoints daqui para baixo
interface IGetEtudentData {
  studentId: number;
}

export const getStudentData = async ({ studentId }: IGetEtudentData) => {
  const response = await KanataAPI.get<StudentInterface>(
    `cx/new/student/${studentId}/`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface IUpdateGuardianData extends IGetEtudentData {
  formValues: IFormStudentValues;
}

export const updateStudentData = async ({
  studentId,
  formValues,
}: IUpdateGuardianData) => {
  const response = await KanataAPI.put(
    `cx/new/student/${studentId}/`,
    formValues
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface IUpdateStudent<T extends unknown> {
  studentId: number;
  body: T;
}

export const updateStudent = async <T extends unknown>({
  studentId,
  body,
}: IUpdateStudent<T>) => {
  const response = await KanataAPI.patch(
    `cx/new/student/${studentId}/`,
    body
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export interface IStudentDocumentsSignup {
  nome: string;
  arquivo: string;
}

interface ICreateStudent {
  nome: string;
  data_nascimento: string;
  cpf: string;
  rg: string;
  genero: "masculino" | "feminino";
  arquivos_documento_identificacao: IStudentDocumentsSignup[];
}

export interface ICreateStudentResponse {
  user_id: number | null;
  id: number;
  nome: string;
  rg: string;
  cpf: string;
  ra: string;
  usuario: number | null;
  email: string | null;
  telefone: string;
  data_nascimento: string;
  nacionalidade: string | null;
  foto_estudante: string | null;
  arquivos_documento_identificacao: IStudentDocumentsSignup[];
}

export const createStudent = async (studentBody: ICreateStudent) => {
  const response = await KanataAPI.post(`cx/new/student/`, studentBody).catch(
    ErrorHandlerFunction
  );
  if (response) {
    const data = {
      data: response.data as ICreateStudentResponse,
      status: response.status,
    };
    return data;
  }
};
