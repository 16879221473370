import {
  MONTHS,
  NEXT_YEAR_ENROLLMENT,
  OLD_ENROLLMENT_STATUS,
} from "helpers/constants";
import { GetCXUserJson, StudentInterface } from "interfaces/constants";
import { DOMAIN } from "./constants";
import { toastError } from "components/Toast";
import moment from "moment";

import { IGradeGroupUnitClasses } from "interfaces/gradeGroupInterfaces";
import { IOffset } from "interfaces/utils";

const formatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL",
});

export const formatIpteString = (ipte: string) => {
  return ipte.replace(
    /(\d{5})(\d{5})(\d{5})(\d{6})(\d{5})(\d{6})(\d{1})(\d{14})/,
    "$1.$2 $3.$4 $5.$6 $7 $8"
  );
};

export const formatDate = (dateStr: string | any) => {
  return dateStr.split("-").reverse().join("/");
};

export const formatIsoStringToDate = (date: string | undefined) => {
  const newDate = formatDate(date?.split("T")[0]).split("/");

  return `${newDate[0]}/${newDate[1]}`;
};
export const formatStringToDate = (date: string | undefined) => {
  const newDate = formatDate(date?.split("T")[0]).split("/");

  return `${newDate[0]}/${newDate[1]}/${newDate[2]}`;
};

export const formatCPF = (cpf: string) => {
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
};

export const formatRG = (value: string) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{1,2})(\d{3})(\d{3})(\d{1})$/, "$1.$2.$3-$4");
};

export const formatMoney = (value: number, withoutSpace?: boolean) => {
  const formatedMoney = formatter.format(value);
  return withoutSpace ? formatedMoney.replace(/\s/g, "") : formatedMoney;
};

export const removeFormatMoney = (value: string) => {
  return value.replace(/[^0-9,]*/g, "").replace(",", "");
};

export const formatNota = (value: any) => {
  return value.replace(/^\d{1}|[1-9]\d{1}$/, "").replace(",", "");
};

export const formatPhone = (phone: string) =>
  phone
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)(\d{4})(\d{4})$/, "($1) $2 $3-$4");

export const formatCEP = (cep: string) =>
  cep.replace(/\D/g, "").replace(/(\d{5})(\d{3})$/, "$1-$2");

export const onlyNumbersFromString = (string: string) => {
  const numbers = string.match(/\d/g);
  return numbers ? numbers.join("") : "";
};

export const range = (start: number, end: number) => {
  return Array.from({ length: end - start + 1 }, (_, i) => i);
};

export const EIGHTEEN_YEARS_AGO = () => {
  let eighteenYearsAgo = new Date();
  return eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
};

export const MARCH31_NEXT_YEAR = () => {
  let march31NextYear = new Date();
  march31NextYear.setFullYear(march31NextYear.getFullYear() - 5);
  march31NextYear.setMonth(2);
  march31NextYear.setDate(31);
  return march31NextYear;
};

export const deepCopy = (obj: Object) => {
  return JSON.parse(JSON.stringify(obj));
};

export const stringToMaskCPF = (cpf: string) => {
  return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
};

export const stringToMaskCEP = (cpf: string) => {
  return cpf.replace(/^(\d{5})(\d{3})/, "$1-$2");
};

export const getKey = (obj: any) => {
  return Object.keys(obj)[0];
};

export const getError = (obj: any) => {
  let result = "Erro";
  if (typeof obj === "string") {
    return obj;
  } else if (!obj[getKey(obj)]) {
    return "Erro";
  } else if (
    (obj[getKey(obj)][0] !== undefined &&
      typeof obj[getKey(obj)][0] !== "string") ||
    typeof obj[getKey(obj)] !== "string"
  ) {
    result = getError(obj[getKey(obj)]);
  } else {
    if (typeof obj[getKey(obj)] === "string") return obj[getKey(obj)];
    else return obj[getKey(obj)][0];
  }
  return result;
};

export const addDays = (date: string, days: number) => {
  let result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const getDomain = () => {
  return DOMAIN[window.location.origin];
};

export const capSentence = (text: string) => {
  const wordsArray = text.toLowerCase().split(" ");
  const capsArray: Array<string> = [];

  wordsArray.forEach((word) => {
    if (word[0]) {
      capsArray.push(word[0].toUpperCase() + word.slice(1));
    }
  });

  return capsArray.join(" ");
};

export const jsonToQuerystring = (json: {}) => {
  return (
    Object.keys(json)
      // @ts-ignore
      .map((key) => key + "=" + json[key])
      .join("&")
  );
};

export const nextMonth = () => {
  const nextMonth = new Date();

  return {
    day_number: nextMonth.getDate(),
    current_month: new Date().getMonth() + 1,
    current_month_name: MONTHS.find((m) => m.id === new Date().getMonth() + 1)!
      .name,
    month_number: nextMonth.getMonth() + 2,
    month: MONTHS.find((m) => m.id === new Date().getMonth() + 2)!.name,
    year: nextMonth.getFullYear(),
  };
};

export const onlyNumberMask = (value: any) => {
  return value.replace(/\D/g, "");
};

export const formatMoneyInput = (value: string, fullStop?: boolean) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{1,2})$/, `${fullStop ? "." : ","}$1`)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
};

export const formatMoneyToFloat = (value: string) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{1,2})$/, ".$1")
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1");
};

export const dateMask = (value: string) => {
  return value
    .replace(/\D/g, "")
    .replace(/^(\d{2})(\d)/, "$1/$2")
    .replace(/(\d{2})(\d)/, "$1/$2");
};

export const numDaysInMonth = (month: string, year: string) => {
  const numMonth = Number(month);
  const numYear = Number(year);

  const days = new Date(numYear, numMonth, 0).getDate();

  return days.toString();
};

export const getDaysArray = (start: string, end: string) => {
  const arr = [];
  for (
    const dt = new Date(start);
    dt <= new Date(end);
    dt.setDate(dt.getDate() + 1)
  ) {
    arr.push(new Date(dt));
  }
  return arr;
};

export const getErrorMessage = async (response: any) => {
  typeof response === "object"
    ? Object.keys(response).map((key) => {
        return toastError(`${response[key as keyof typeof response]}`);
      })
    : toastError(response);
};

export const checkDevice = () => {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true; // Navegando pelo celular
  } else {
    return false; // Navegando pelo PC
  }
};

export const IsDevelopmentEnvironment = () => {
  if (process.env.NODE_ENV !== "development") {
    if (window.location.href.indexOf("homolog") > -1) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

export const padTo2Digits = (num: number) => {
  return num.toString().padStart(2, "0");
};

export const formatFullDate = (date: Date) => {
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join("/");
};

export const getBuildDate = (epoch: number | string) => {
  const buildDate = moment(epoch).format("DD-MM-YYY HH:MM");
  return buildDate;
};

export const finishEnrollment = (user: GetCXUserJson, enrollmentId: number) => {
  const finish = user.students.find(
    (student) => student.enrollment?.id === enrollmentId
  );

  return (
    finish?.enrollment.enrollment_status === OLD_ENROLLMENT_STATUS.MATRICULADO
  );
};

export const cellPhoneMask = (value: string) => {
  return value
    .replace(/\D/g, "")
    .replace(/^(\d{2})(\d)/g, "($1) $2")
    .replace(/(\d)(\d{4})$/, "$1-$2");
};

export const formatClass = (classes: IGradeGroupUnitClasses[]) => {
  // Fazendo filtragem e validação dos anos que possuem turmas
  const fundOne = classes.filter(
    (classe) => classe.sections.length > 0 && classe.level === 1
  );
  const fundTwo = classes.filter(
    (classe) => classe.sections.length > 0 && classe.level === 2
  );
  const medio = classes.filter(
    (classe) => classe.sections.length > 0 && classe.level === 3
  );

  // Construindo objeto de retorno
  const objData = [
    {
      level: "EF I",
      levelValue: 1,
      years: [...fundOne],
    },
    {
      level: "EF II",
      levelValue: 2,
      years: [...fundTwo],
    },
    {
      level: "médio",
      levelValue: 3,
      years: [...medio],
    },
  ];

  // Retornando o objeto sem os níveis que n possuem anos
  return objData.filter((item) => item.years?.length > 0);
};

export const clearString = (str: string) => {
  return str
    .toLowerCase()
    .replace(/[áãà]/g, "a")
    .replace(/é/g, "e")
    .replace(/í/g, "i")
    .replace(/[óõô]/g, "o")
    .replace(/[úü]/g, "u")
    .replace(/ç/g, "c");
};

export const orderBy = <T extends Record<string, any>>(
  field: string,
  data: T,
  order: string
) => {
  const orderedData =
    order === "asc"
      ? data.sort((a: T, b: T) =>
          clearString(a[field]) < clearString(b[field])
            ? 1
            : clearString(a[field]) > clearString(b[field])
            ? -1
            : 0
        )
      : data.sort((a: T, b: T) =>
          clearString(a[field]) > clearString(b[field])
            ? 1
            : clearString(a[field]) < clearString(b[field])
            ? -1
            : 0
        );

  return { orderedData };
};

export const stringToNumber = (str: string) => {
  return Number(str.split("%")[0]);
};

export const orderByNumber = <T extends Record<string, any>>(
  field: string,
  data: T,
  order: string
) => {
  const orderedData =
    order === "asc"
      ? data.sort((a: T, b: T) => {
          if (stringToNumber(a[field]) > stringToNumber(b[field])) {
            return 1;
          }
          if (stringToNumber(a[field]) < stringToNumber(b[field])) {
            return -1;
          }
          return 0;
        })
      : data.sort((a: T, b: T) => {
          if (stringToNumber(a[field]) < stringToNumber(b[field])) {
            return 1;
          }
          if (stringToNumber(a[field]) > stringToNumber(b[field])) {
            return -1;
          }
          return 0;
        });

  return { orderedData };
};

export const standardDate = (value: string) => {
  const date = value?.substring(0, 10).split("-").reverse().join("/");
  return date;
};

export const standardHour = (value: string) => {
  const date = value?.substring(11, 16);
  return date;
};

export const splitString = (value: string) => {
  return {
    first: value.split(" ")[0],
    last: value.split(" ")[1],
  };
};

export const gradesValidation = (
  students: StudentInterface[],
  educational_level: string,
  enrollment_grade: number
) => {
  function checkValidation(student: StudentInterface) {
    if (
      student.enrollment?.educational_level_name !== educational_level &&
      student.enrollment?.grade !== enrollment_grade &&
      student.enrollment?.get_enrollment_status_display === "Matriculado"
    ) {
      return student;
    }
  }

  const studentsFilter = students.filter(checkValidation);

  return studentsFilter;
};

export const deviceUsed = (value: string) => {
  const regex = /\(([^)]+)\)/g;
  const array = [...value.matchAll(regex)];

  return array[0];
};

/**
 * Esta função concatena reticências "..." ao final de uma string, dado um comprimento máximo
 * @text Texto a ser trabalhado
 * @maxLength Tamanho máximo do texto para aplicar reticências
 */
export const concatenateEllipsis = (
  text: string,
  maxLength: number
): string => {
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength).concat("...");
};

export const handleDuplicateItems = (orderItems: any) => {
  const uniqueIds: any[] = [];

  const unique = orderItems.filter((element: any) => {
    const isDuplicate = uniqueIds.includes(element.item_name);

    if (!isDuplicate) {
      uniqueIds.push(element.item_name);

      return true;
    }

    return false;
  });

  return unique.sort((a: any, b: any) => a.item - b.item);
};

export const checkPaymentStatus = (status: string) => {
  switch (status) {
    case "Pago":
      return "success";
    case "Expirada":
      return "error";
    case "Cancelada":
      return "error";
    default:
      return "warning";
  }
};

export const splitPunctuation = (value: string) => {
  return {
    fullStop: value.split(",").join("."),
    comma: value.split(".").join(","),
  };
};

export const upperCase = (value: string) => {
  return value[0].toUpperCase() + value.substring(1);
};

// Logica para a paginacao do component dentro da atoms
export const paginationLogic = <T extends unknown>({
  direction,
  page,
  paginate,
  data,
  oldData,
  setPage,
  setPaginate,
}: IOffset<T>) => {
  if (direction) {
    setPaginate({
      ...paginate,
      initialValue: paginate.initialValue + oldData.results.length,
      lastValue:
        data.count <= paginate.lastValue + data.results.length + 1
          ? data.count
          : paginate.lastValue + data.results.length,
    });
    setPage(page + 1);
  } else {
    setPaginate({
      ...paginate,
      initialValue:
        page === 2 ? 1 : paginate.initialValue - data.results.length,
      lastValue: page === 2 ? 10 : paginate.lastValue - data.results.length,
    });
    setPage(page - 1);
  }
};

export const compareDates = (value: string) => {
  const dateDifference = moment(value).valueOf() - moment().valueOf();
  return dateDifference;
};

export const stringToBoolean = (value: string) => {
  if (value === "false") {
    return false;
  }

  return true;
};

export const booleanToString = (value: boolean) => {
  if (value === false) {
    return "false";
  }

  return "true";
};

export const readFileAsBase64 = async (file: File): Promise<string> => {
  return new Promise<string>((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const base64Data = e.target?.result?.toString().split(",")[1];
      resolve(base64Data || "");
    };
    reader.readAsDataURL(file);
  });
};

export const validateInputBetween0And100 = (value: string) =>
  value.match(/^(?:100|[1-9]\d|\d)$/);
