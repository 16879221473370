import axios, { AxiosRequestConfig } from "axios";
import { toastError } from "components/Toast";
import { Storage } from "Storage";
import { getRefreshToken, tokenExpired } from "./service-helpers";
import { TOnSuccess } from "interfaces/refreshToken";
/**
 * TO-DO
 * Env vars pra controlar as APIs
 */

const augmentAxiosClientWithJwtToken = (config: AxiosRequestConfig) => {
  const token = Storage.token.get()?.access;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
};

const HeimdallAPI = axios.create({
  baseURL:
    process.env.REACT_APP_HEIMDALL ||
    "https://heimdall-homolog.escolamais.com/api/",
});

HeimdallAPI.interceptors.request.use((config) => {
  return augmentAxiosClientWithJwtToken(config);
});

HeimdallAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      if (window.location.pathname === "/") {
        toastError("Usuário ou senha inválidos");
        throw new Error(error.message);
      }

      Storage.remove();
      window.location.href = "/";
    }
  }
);

const KanataAPI = axios.create({
  baseURL:
    process.env.REACT_APP_KANATA ||
    "https://kanata-homolog.escolamais.com/api/",
});

KanataAPI.interceptors.request.use((config) => {
  return augmentAxiosClientWithJwtToken(config);
});

KanataAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      const token = Storage.token.get()?.refresh;

      if (token) {
        const previousRequestConfig = error.config;
        const failedRequestsQueue: Array<TOnSuccess> = [];

        getRefreshToken({ apiClient: KanataAPI, token, failedRequestsQueue });

        return new Promise((resolve) => {
          failedRequestsQueue.push({
            onSuccess: () => {
              previousRequestConfig.headers.Authorization = `Bearer ${
                Storage.token.get()?.access
              }`;
              resolve(KanataAPI(previousRequestConfig));
            },
          });
        });
      } else {
        tokenExpired();
      }
    }

    return Promise.reject(error);
  }
);

const GradeAPI = axios.create({
  baseURL:
    process.env.REACT_APP_GRADE || "https://grade-homolog.escolamais.com/api/",
});

GradeAPI.interceptors.request.use((config) => {
  config.headers.Authorization = `Token ${process.env.REACT_APP_GRADE_TOKEN}`;
  return config;
});

GradeAPI.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      // window.location.href = "/";
    }
  }
);

const RoteirosAPI = axios.create({
  baseURL:
    process.env.REACT_APP_ROTEIROS ||
    "https://roteiros.homolog.escolamais.com/api/",
});

RoteirosAPI.interceptors.request.use((config) => {
  config.headers.Authorization = `Token ${process.env.REACT_APP_ROTEIROS_TOKEN}`;
  return config;
});

RoteirosAPI.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      window.location.href = "/";
    }
  }
);

const GradeAPIDownload = axios.create({
  responseType: "arraybuffer",
  baseURL:
    process.env.REACT_APP_GRADE || "https://grade-homolog.escolamais.com/api/",
});

GradeAPIDownload.interceptors.request.use((config) => {
  config.headers.Authorization = `Token ${process.env.REACT_APP_GRADE_TOKEN}`;
  return config;
});

GradeAPIDownload.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      window.location.href = "/";
    }
  }
);

const UpsideDown = axios.create({
  baseURL:
    process.env.REACT_APP_UPSIDE_DOWN ||
    "https://upside-down-homolog.escolamais.com/api/",
});

UpsideDown.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      window.location.href = "/";
    }
  }
);

const Financeiro = axios.create({
  baseURL:
    process.env.REACT_APP_FINANCEIRO ||
    "https://financeiro.homolog.escolamais.com/api/",
});

Financeiro.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      window.location.href = "/";
    }
  }
);

const CalendarioAPI = axios.create({
  baseURL:
    process.env.REACT_APP_CALENDARIO ||
    "https://calendario.homolog.escolamais.com/api",
});

CalendarioAPI.interceptors.request.use((config) => {
  config.headers.Authorization = `Token ${process.env.REACT_APP_CALENDARIO_TOKEN}`;
  return config;
});

CalendarioAPI.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      toastError("Erro ao carregar o calendário");
      window.location.href = "/";
    }
  }
);

export {
  HeimdallAPI,
  KanataAPI,
  GradeAPI,
  GradeAPIDownload,
  UpsideDown,
  Financeiro,
  RoteirosAPI,
  CalendarioAPI,
};
