import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { PublicStyledHeader } from "./styles";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface PublicNavHeaderProps {
  children: React.ReactNode;
  outOfApp: boolean;
}

function PublicNavHeader(Props: PublicNavHeaderProps) {
  const history = useHistory();
  const { children, outOfApp } = Props;
  return (
    <>
      <PublicStyledHeader
        style={{ backgroundColor: outOfApp ? "#ec008b" : "#333333" }}
      >
        <FontAwesomeIcon
          onClick={() => history.goBack()}
          size="lg"
          icon={faChevronLeft as IconProp}
        />
        {children}
      </PublicStyledHeader>
      <hr />
    </>
  );
}

export default PublicNavHeader;
