import { Title } from "components/atoms";

import * as S from "./style";
import * as I from "./type";

import close from "static/img/close-icon.svg";

export const Drawer = ({
  isOpen,
  onClose,
  side = "right",
  children,
  title,
  width,
  height,
  titleFontSize = "16px",
}: I.DrawerProps) => {
  return (
    <S.Overlay isOpen={isOpen}>
      <S.Drawer side={side} isOpen={isOpen} width={width} height={height}>
        <S.HeaderDrawer>
          <Title
            darkGray
            fontSize={titleFontSize}
            fontWeight="700"
            marginBottom="0px"
          >
            {title}
          </Title>
          <S.ButtonClose onClick={() => onClose()}>
            <img src={close} alt="close-icon" />
          </S.ButtonClose>
        </S.HeaderDrawer>
        <S.DrawerBody height={height}>{children}</S.DrawerBody>
      </S.Drawer>
    </S.Overlay>
  );
};
