import { useContext, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Toolbar, IconButton, AppBar } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import ResponsiveDrawer from "components/Drawer";
import { anyDrawerItem } from "components/Drawer/constants";
import logoEscolaMaisHorizontal from "static/img/logo-escola-horizontal.png";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Logo22Icon, Logo22Text } from "static/img";
import { Storage } from "Storage";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: "#cccccc",
    },
    menuButton: {
      textAlign: "center",
    },
    title: {
      flexGrow: 1,
    },
    subtitleToolbar: {
      alignItems: "center",
      justifyContent: "flex-start",
    },
    subtitleText: {
      flexGrow: 1,
      textAlign: "center",
      marginRight: theme.spacing(1),
    },
  })
);

const Header = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const role = Storage.props.role.get();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleGoBack = () => {
    if (location.pathname !== "/home") {
      history.goBack();
    }
  };

  return (
    <div className={classes.root}>
      {role.id !== 0 && anyDrawerItem(role) && (
        <AppBar
          position="static"
          style={{ background: "#FFFFFF" }}
          className="mb-4"
        >
          <Toolbar>
            <div className="row w-100 m-0 p-0">
              <div className="col-4">
                <IconButton
                  className={`${"d-sm-none"} ${classes.menuButton}`}
                  color="primary"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </div>
              <div className="col-4 d-flex align-items-center justify-content-center">
                <picture>
                  <source srcSet={`${Logo22Text}`} media="(min-width: 576px)" />
                  <source srcSet={`${Logo22Icon}`} media="(max-width: 575px)" />
                  <img src={Logo22Icon} alt="Logo Escola Mais" />
                </picture>
              </div>
              <div className="col-4 d-flex align-items-center justify-content-end">
                <IconButton
                  color="secondary"
                  aria-label="notifications"
                  edge="end"
                  onClick={() => {}}
                  style={{ alignSelf: "" }}
                >
                  <NotificationsIcon />
                </IconButton>
              </div>
            </div>
          </Toolbar>
          <Toolbar className={classes.subtitleToolbar}>
            <IconButton
              color="primary"
              aria-label="open drawer"
              edge="start"
              onClick={handleGoBack}
            >
              <FontAwesomeIcon icon={faChevronLeft as IconProp} />
            </IconButton>
          </Toolbar>
          <ResponsiveDrawer
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
          />
        </AppBar>
      )}
    </div>
  );
};

export default Header;
