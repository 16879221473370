import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { ButtonFilterBulletin } from "components/atoms/Button/style";
import { GroupButtons } from "components/molecules/GroupButtons";
import { StudentView } from "./components/StudentView";
import { Loading } from "components/atoms/Loading";

import { formatClass } from "helpers/helpers";
import { ROLE } from "helpers/constants";
import { IGradeGroupFormatedClasses } from "interfaces/gradeGroupInterfaces";
import { getUnitClasses } from "services/grades.service";

import * as S from "./styles";
import { useAtomValue } from "jotai";
import { authUserAtom } from "jotai/authUser";

export const SearchClassDiary = () => {
  const { t } = useTranslation();
  const authUser = useAtomValue(authUserAtom);
  const [classes, setClasses] = useState<IGradeGroupFormatedClasses[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [userView, setUserView] = useState("student");
  const [noData, setNoData] = useState<null | string>(null);

  const prefix = "pages.AP.researchCall";

  useEffect(() => {
    const handleGetClasses = async () => {
      setIsLoading(true);

      const isTeacher = authUser.role.some((role) => role.id === ROLE.TEACHER);

      const res = await getUnitClasses({
        gradeUnitId: authUser.grade_unit_id,
        isTeacher,
        userId: authUser.id,
      });

      if (res?.data.length === 0 && !isTeacher) {
        setNoData("Ainda não existem turmas atribuídas para o ano letivo!");
      }

      if (res?.data.length === 0 && isTeacher) {
        setNoData("O perfil não possui turmas designadas!");
      }

      setClasses(formatClass(res?.data));
      setIsLoading(false);
    };

    handleGetClasses();
  }, [authUser.grade_unit_id, authUser.id, authUser.role]);

  if (isLoading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <S.Container>
      {noData ? (
        <div className="noData">
          <h3>{noData}</h3>
        </div>
      ) : (
        <>
          <h1>{t(`${prefix}.title`)}</h1>
          <GroupButtons quarter>
            <ButtonFilterBulletin
              active={userView === "student"}
              onClick={() => setUserView("student")}
              quarter
            >
              {t(`${prefix}.student`)}
            </ButtonFilterBulletin>

            {/* <ButtonFilterBulletin
          active={userView === "teacher"}
          onClick={() => setUserView("teacher")}
          quarter
        >
          {t(`${prefix}.teacher`)}
        </ButtonFilterBulletin> */}
          </GroupButtons>

          {userView === "student" && <StudentView grade={classes} />}
          {/* {userView === "teacher" && <h1>teacher</h1>} */}
        </>
      )}
    </S.Container>
  );
};
