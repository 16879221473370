import { Slide, toast } from "react-toastify";

let baseConfig = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  transition: Slide,
};

export const toastSuccess = (message: string) => {
  toast.dismiss();
  toast.success(message, baseConfig);
};

export const toastError = (message: string) => {
  toast.dismiss();
  toast.error(message, baseConfig);
};

export const toastWarning = (message: string) => {
  toast.dismiss();
  toast.warn(message, baseConfig);
};

export const toastInfo = (message: string) => {
  toast.dismiss();
  toast.info(message, baseConfig);
};

export const toastDefault = (message: string) => {
  toast.dismiss();
  toast(message, baseConfig);
};
