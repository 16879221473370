import styled from "styled-components";
import * as colors from "styles/colors";

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  cursor: pointer;

  p {
    margin: 0;
  }

  .titles {
    display: grid;
    grid-template-columns: 80% 20%;
    border-bottom: 1px solid ${colors.NEW_GRAY_THREE};

    p {
      display: flex;
      align-items: center;
      padding: 8px 16px;
      gap: 8px;
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: grid;
      grid-template-columns: 80% 20%;
      align-items: center;
      border-bottom: 1px solid ${colors.NEW_GRAY_THREE};

      p {
        padding: 8px 0 8px 46px;
      }

      span {
        padding: 8px 16px;
      }
    }
  }
`;
