import styled from "styled-components";

export const TableHead = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  border-bottom: 1px solid ${({ theme }) => theme.colors.greyScale40};
  margin-top: ${({ theme }) => theme.space[5]};

  ul {
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;

    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    li {
      flex: 1;
      max-width: 104px;
      font-size: ${({ theme }) => theme.fontSizes.mobile.sm};
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      padding: ${({ theme }) => theme.space[2]};
    }

    li:first-child {
      padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[4]};
    }
  }
`;

export const TableBody = styled.div`
  display: flex;
  flex-direction: column;

  margin: 0;

  ul {
    list-style: none;

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0;
    margin-bottom: 0;

    border-bottom: 1px solid ${({ theme }) => theme.colors.greyScale10};

    li {
      display: flex;
      align-items: flex-start;

      padding: ${({ theme }) => theme.space[2]};
      width: 104px;

      button {
        border: none;
        background: transparent;
        margin-left: auto;
        margin-right: 10px; // TODO: não tem na lib

        svg {
          width: 21px;
          height: 21px;
          color: ${({ theme }) => theme.colors.primaryDefault};
        }
      }
    }

    li:first-child {
      padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[4]};
    }
  }
`;
