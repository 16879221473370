import {
  EnrollmentSingleFood,
  FoodItemInterface,
} from "interfaces/mealInterfaces";

type FormatSingleFoodsType = {
  singleFoods: Array<EnrollmentSingleFood>;
  foodItems: Array<FoodItemInterface>;
};

export const formatSingleFoods = ({
  singleFoods,
  foodItems,
}: FormatSingleFoodsType) => {
  const singleFoodsFormated: any[] = [];
  singleFoods.forEach((singleFood) => {
    if (!singleFoodsFormated.find((sff) => sff.date === singleFood.date)) {
      const newSingleFoodFormated = {
        date: singleFood.date,
      };
      foodItems.forEach((foodItem) => {
        // @ts-ignore
        newSingleFoodFormated[foodItem.name] = !!singleFoods.find(
          (s) => s.item_name === foodItem.name && s.date === singleFood.date
        );
      });
      singleFoodsFormated.push(newSingleFoodFormated);
    }
  });
  return singleFoodsFormated;
};

export const getExtraFoodTotalValue = (
  dates: Array<Date>,
  foodItems: Array<FoodItemInterface>,
  singleFoods: any,
  singleFoods2: any
) => {
  let total = 0.0;
  dates.forEach((date) => {
    if (
      singleFoods.find(
        (sf: any) => sf.date === date.toISOString().split(`T`)[0]
      )
    ) {
      singleFoods
        .filter((sf: any) => sf.date === date.toISOString().split(`T`)[0])
        .forEach((sf: any) => {
          total += parseFloat(
            foodItems.find((fi) => fi.id === sf.item)!.value_single
          );
        });
    } else if (
      singleFoods2.find(
        (sf: any) => sf.date === date.toISOString().split(`T`)[0]
      )
    ) {
      singleFoods2
        .filter((sf: any) => sf.date === date.toISOString().split(`T`)[0])
        .forEach((sf: any) => {
          total += parseFloat(
            foodItems.find((fi) => fi.id === sf.item)!.value_single
          );
        });
    }
  });
  return total;
};
