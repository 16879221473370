// Styles
import { CreditCardStyled } from "./style";
// Components
import {
  LoadingBox,
  SelectInputFinalForm,
  TextInputFinalForm,
} from "components";
// Libs
import { Field, Form } from "react-final-form";
import formatString from "format-string-by-pattern";
// Helpers
import * as validator from "helpers/fieldValidators";
// Interfaces
import { Button } from "components/atoms/Button";

type CreditCardType = {
  loadCreditCard: boolean;
  failed: boolean;
  approved: boolean;
  errorText: Array<string>;
  onSubmit: () => void;
  setFailed: (failed: boolean) => void;
  setCardInput: (cardInput: any) => void;
};

export const CreditCard = ({
  loadCreditCard,
  failed,
  approved,
  errorText,
  onSubmit,
  setFailed,
  setCardInput,
}: CreditCardType) => {
  return (
    <CreditCardStyled>
      {loadCreditCard && <LoadingBox height={50} />}
      {!failed && !approved && !loadCreditCard && (
        <Form onSubmit={onSubmit}>
          {({ form, handleSubmit, submitting, values }) => (
            <form onSubmit={handleSubmit}>
              <Field
                component={TextInputFinalForm}
                name="card_number"
                type="text"
                placeholder="0000.0000.0000.0000"
                label="NÚMERO DO CARTÃO"
                parse={formatString("9999 9999 9999 9999")}
                maxLength={20}
                validate={validator.composeValidators(
                  validator.validateCreditCardIugu
                )}
                required
              />
              <Field
                component={TextInputFinalForm}
                name="name"
                type="text"
                placeholder="Nome impresso no cartão"
                label="NOME IMPRESSO NO CARTÃO"
                validate={validator.composeValidators(
                  validator.required,
                  validator.noSpecialCharactersAndNumbers
                )}
                required
              />
              <div className="d-flex">
                <Field
                  component={TextInputFinalForm}
                  name="due_date"
                  type="text"
                  placeholder="mm/aa"
                  label="DATA DE VENCIMENTO"
                  parse={formatString("99/99")}
                  validate={validator.composeValidators(validator.required)}
                  required
                  width="95%"
                />
                <Field
                  component={TextInputFinalForm}
                  name="cvv"
                  type="text"
                  placeholder="CVV"
                  label="CÓDIGO DE SEGURANÇA"
                  maxLength={4}
                  validate={validator.composeValidators(validator.required)}
                  required
                  width="100%"
                />
              </div>
              <Field
                component={SelectInputFinalForm}
                name="installments"
                label="PARCELAS"
                validate={validator.composeValidators(validator.required)}
                required
              >
                <option value="1">À vista</option>
              </Field>
              {values && setCardInput(values)}
            </form>
          )}
        </Form>
      )}
      {failed && !loadCreditCard && (
        <div>
          {errorText ? (
            <div className="text-center">
              Mensagem de Erro:
              {errorText.map((message) => {
                return (
                  <div className="mb-1 mt-2 text-danger" key={message}>
                    {message}
                  </div>
                );
              })}
            </div>
          ) : (
            <div>Verifique os dados do cartão e tente novamente</div>
          )}
          <Button
            onClick={() => {
              setFailed(false);
            }}
          >
            Voltar ao cartão
          </Button>
        </div>
      )}
    </CreditCardStyled>
  );
};
