import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Steps } from "components";
import { StudentSignUpForm } from "components/Forms";
import HTTP_STATUS from "http-status-codes";
import { GetCXUserJson } from "interfaces/constants";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getCXUser } from "services/users.service";
import { SchoolGradeForm } from "./components/SchoolGradeForm";
import { SignupCompleted } from "./components/SignupCompleted";
import { Header } from "./style";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { useAtomValue } from "jotai";
import { authUserAtom } from "jotai/authUser";

export const StudentSignup = () => {
  const history = useHistory();

  const urlParams = new URLSearchParams(window.location.search);
  const step_ = urlParams.get("step") ? parseInt(urlParams.get("step")!) : 1;
  const studentId_ = urlParams.get("studentId")
    ? parseInt(urlParams.get("studentId")!)
    : null;
  const [step, setStep] = useState(step_);
  const [user, setUser] = useState<GetCXUserJson | undefined>(undefined);
  const [studentId, setStudentId] = useState<number | null>(studentId_);
  const [enrollmentStatus, setEnrollmentStatus] = useState(-1);
  const authUser = useAtomValue(authUserAtom);

  const guardianId = urlParams.get("guardianId");
  const guardianAuthId = urlParams.get("guardianAuthId");
  const name = urlParams.get("name");
  const outOfApp = urlParams.get("outofapp");

  type GetCXUserJsonType = {
    data: GetCXUserJson;
    status: number;
  };

  useEffect(() => {
    if (!guardianId) {
      getCXUser({ id: authUser.id }).then(
        ({ data, status }: GetCXUserJsonType) => {
          if (status === HTTP_STATUS.OK) {
            setUser(data);
          }
        }
      );
    }
  }, [guardianId, authUser]);

  return (
    <>
      <Header style={{ backgroundColor: outOfApp ? "#ec008b" : "#333333" }}>
        <FontAwesomeIcon
          onClick={() => (step === 1 ? history.goBack() : setStep(step - 1))}
          size="lg"
          icon={faChevronLeft as IconProp}
        />
        {outOfApp
          ? "CADASTRO DE ALUNO FORA DO APLICATIVO"
          : "Cadastro de Aluno"}
        {name ? ` de ${name}` : ""}
      </Header>
      <Steps step={step} length={3}>
        {step === 1 && (user || guardianId) && (
          <StudentSignUpForm
            guardianId={guardianId ? parseInt(guardianId) : user!.id}
            guardianAuthId={guardianAuthId}
            name={name}
            setStep={setStep}
            setStudentId={setStudentId}
          />
        )}

        {step === 2 && studentId && (guardianId || user) && (
          <SchoolGradeForm
            guardianAuthId={guardianAuthId}
            guardianId={guardianId ? parseInt(guardianId) : user!.id}
            studentId={studentId}
            setStep={setStep}
            setEnrollmentStatus={setEnrollmentStatus}
            onlyUpdate={urlParams.get("step") !== null}
            outOfApp={outOfApp ? true : undefined}
            reserva={urlParams.get("reserva") !== null}
          />
        )}
        {step === 3 && studentId && guardianId && guardianAuthId && (
          <SignupCompleted
            setStep={setStep}
            guardianId={guardianId}
            guardianAuthId={guardianAuthId}
            studentId={studentId}
            enrollmentStatus={enrollmentStatus}
          />
        )}
      </Steps>
    </>
  );
};
