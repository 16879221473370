import { QuarterPublicationStatus } from "interfaces/boletim";
import styled, { css } from "styled-components";

interface IPublishCardBodyProps {
  status: QuarterPublicationStatus;
}

export const PublishCardBody = styled.div<IPublishCardBodyProps>`
  width: 306px;
  max-width: 306px;
  height: 191px;
  max-height: 191px;
  padding: ${({ theme }) => theme.space[4]};
  border: 2px solid ${({ theme }) => theme.colors.primaryDefault};

  p:first-child {
    font-style: normal;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: ${({ theme }) => theme.fontSizes.web.default};
    line-height: ${({ theme }) => theme.lineHeights.taller};
    color: ${({ theme }) => theme.colors.greyScale95};
  }

  transition: all 0.2s ease-in-out;

  &:hover {
    background: ${({ theme }) => theme.colors.primaryLight90};
  }

  ${({ status }) =>
    status === "Indisponível" &&
    css`
      color: ${({ theme }) => theme.colors.greyScale40};
      border: 2px solid ${({ theme }) => theme.colors.greyScale40};
      p:first-child {
        color: ${({ theme }) => theme.colors.greyScale40};
      }
    `}

  .tag_disabled {
    color: ${({ theme }) => theme.colors.greyScale40};
    border: 2px solid ${({ theme }) => theme.colors.greyScale40};
  }
`;

export const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
`;
