import { ReactNode } from "react";

interface IFeatureEnablerProps {
  enablerCallback: () => boolean;
  children: ReactNode;
}

export const FeatureEnabler = ({
  children,
  enablerCallback,
}: IFeatureEnablerProps) => {
  const returnOptions = {
    allowChildren: <>{children}</>,
    nullable: <></>,
  };

  const renderChildren = () => {
    const isFeatureEnabled = enablerCallback();

    return isFeatureEnabled
      ? returnOptions.allowChildren
      : returnOptions.nullable;
  };

  return renderChildren();
};
