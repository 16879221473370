import styled from "styled-components";
import * as colors from "styles/colors";

export const TwoCreditCardsStyled = styled.div`
  margin-left: -0.3em;
  div.cardOne {
    margin-top: 2em;
    padding-bottom: 2em;

    border-bottom: 1px solid ${colors.NEW_GRAY_THREE};
  }

  div.cardTwo {
    margin-top: 1.5em;
  }
`;
