import styled from "styled-components";

export const VacancyDataContainer = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: ${({ theme }) => theme.space[4]};

  display: flex;
  flex-direction: column;

  h1 {
    font-size: 40px; // TODO: nao tem na lib
    font-weight: 600; // TODO: nao tem na lib
    line-height: normal;
    color: ${({ theme }) => theme.colors.primaryDefault};
    margin-bottom: ${({ theme }) => theme.space[4]};
  }

  h2 {
    font-size: ${({ theme }) => theme.fontSizes.web.h3};
    font-weight: 600;
    line-height: normal;
    color: ${({ theme }) => theme.colors.brandingOrange};
    margin: ${({ theme }) => theme.space[6]} 0;
  }
`;

export const SelectGroup = styled.div`
  display: grid;
  grid-template-columns: 2fr repeat(3, 1fr);
  margin: ${({ theme }) => theme.space[4]} 0;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const SelectContainer = styled.div`
  width: auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin: 16px 0.3rem 0 0;

  select {
    border: 2px solid ${({ theme }) => theme.colors.greyScale40};
    background: transparent;
    display: flex;

    height: 38px;
  }
`;

export const SelectTitle = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: ${({ theme }) => theme.fontSizes.web.sm};
  line-height: ${({ theme }) => theme.lineHeights.normal};
  color: ${({ theme }) => theme.colors.greyScale95};

  text-transform: uppercase;
`;

export const PlanConfigurationOptions = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${({ theme }) => theme.space[8]};
  margin: ${({ theme }) => theme.space[10]} 0;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const ScholarshipDiscount = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    font-size: ${({ theme }) => theme.fontSizes.web.h4};
    font-weight: 600;
    color: ${({ theme }) => theme.colors.primaryDefault};
  }
`;

export const DiscountsSection = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${({ theme }) => theme.space[2]};
  margin: ${({ theme }) => theme.space[4]} 0 ${({ theme }) => theme.space[2]};
`;

export const DiscountOption = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DiscountPercentageContainer = styled.div`
  display: flex;
  align-items: center;
  width: 65px;
  height: 37px;
  color: ${({ theme }) => theme.colors.greyScale40};
  background: ${({ theme }) => theme.colors.greyScale10};
  padding: ${({ theme }) => theme.space[2]};

  input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    color: inherit;
    background: inherit;
    border: none;

    &:focus {
      outline: none;
    }
  }

  span {
    font-size: ${({ theme }) => theme.fontSizes.web.default};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }
`;

export const PlanConfiguration = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    font-size: ${({ theme }) => theme.fontSizes.web.h4};
    font-weight: 600;
    color: ${({ theme }) => theme.colors.primaryDefault};
  }
`;

export const PaymentPlanSection = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${({ theme }) => theme.space[2]};
  margin: ${({ theme }) => theme.space[4]} 0 ${({ theme }) => theme.space[2]};
`;

export const PaymentPlanOptions = styled.div`
  display: flex;
  justify-content: space-between;

  gap: ${({ theme }) => theme.space[4]};
`;

export const PaymentPlanOption = styled.div`
  display: flex;
  flex-direction: column;

  border: 2px solid ${({ theme }) => theme.colors.primaryDefault};
  padding: ${({ theme }) => theme.space[4]};
  width: 50%;
  min-height: 123px;

  strong {
    font-size: ${({ theme }) => theme.fontSizes.web.default};
    text-transform: uppercase;
  }
`;

export const PlanConfigurationTotal = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 18px; // TODO: nao tem na lib
    font-weight: 600;
  }
`;

export const PriceTotalSection = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: ${({ theme }) => theme.space[3]};
`;

export const PriceTotalSectionDivider = styled.div`
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.greyScale10}`};
  margin: ${({ theme }) => theme.space[2]} 0;
`;

export const PriceTotalLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonGroupContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space[4]};
  align-self: flex-end;

  margin: ${({ theme }) => theme.space[12]} 0;

  @media screen and (max-width: 640px) {
    align-self: center;
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: ${({ theme }) => theme.space[8]} 0;
`;
