import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  createStyles,
  LinearProgress,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { Button, Modal } from "components";
import { StudentFrame } from "components/Frames/StudentFrame";
import { toastError, toastSuccess } from "components/Toast";
import {
  isEnrollmentCoordinator,
  isFirstAccess,
  isGuardian,
  isVendor,
  isVendorManager,
  isEnrollmentManager,
  isDirector,
  isStudyRoomEducator,
  isOperationalDirector,
} from "helpers/constants";
import { formatCEP, formatDate, formatPhone, getDomain } from "helpers/helpers";
import HTTP_STATUS from "http-status-codes";
import { GetCXUserJson } from "interfaces/constants";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  getCXUser,
  sendNotificationsFirstAccessGuardian,
} from "services/users.service";
import Placeholder from "static/img/placeholder.png";
import { BLUE, PINK } from "styles/colors";
import { validateCPF } from "validations-br";
import { ModalDataValidation } from "./components/ModalDataValidation";
import {
  BoldBigger,
  BoldBiggerBlue,
  BoldBiggerPink,
  EditGuardianStyled,
  GuardianDataBigBox,
  GuardianDataBox,
} from "./style";
import { ModalTotvsSent } from "components/Modals";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Storage } from "Storage";
import { useAtomValue } from "jotai";
import { authUserAtom } from "jotai/authUser";
import { EnrollmentTypes } from "enums/enrollment";

const defaultSpacing = 15;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      width: theme.spacing(defaultSpacing),
      height: theme.spacing(defaultSpacing),
    },
    guardianName: {
      fontWeight: "bold",
      textTransform: "uppercase",
      color: BLUE,
    },
    dataSubtitle: {
      textAlign: "center",
      fontWeight: "bold",

      // textTransform: "uppercase",
    },
  })
);

type GetCXUserJsonType = {
  data: GetCXUserJson;
  status: number;
};

export const EditGuardian = () => {
  const classes = useStyles();
  const [guardian, setGuardian] = useState<GetCXUserJson | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [collapseGuardian, setCollapseGuardian] = useState(true);
  const [collapseStudents, setCollapseStudents] = useState(true);
  const [user, setUser] = useState<GetCXUserJson | null>(null);
  const role = Storage.props.role.get();
  const authUser = useAtomValue(authUserAtom);

  const [guardianAuthIdError, setGuardianAuthIdError] = useState<boolean>();
  const [userError, setUserError] = useState<boolean>();
  const [totvsSent, setTotvsSent] = useState(false);
  const [openModalTotvsSent, setOpenModalTotvsSent] = useState(false);

  const [openModalDataValidation, setOpenModalDataValidation] = useState(false);
  const [dataValidation, setDataValidation] = useState(false);
  const [cpf, setCpf] = useState(true);
  const [cep, setCep] = useState(true);

  const [reenrollment, setReenrollment] = useState(false);
  const [modifiedDate, setModifiedDate] = useState<string>();
  const [currentDate, setCurrentDate] = useState<string>();

  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const guardianId = urlParams.get("guardianId");
  const guardianAuthId = urlParams.get("guardianAuthId");
  const outOfApp = urlParams.get("outofapp");
  const dataConfirm = urlParams.get("dataConfirm");

  useEffect(() => {
    if (guardianAuthId) {
      getCXUser({
        id: parseInt(guardianAuthId),
      })
        .then((response) => {
          if (response) {
            if (response.status === HTTP_STATUS.OK) {
              setGuardian(response.data);
            } else {
              setGuardianAuthIdError(true);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
    if (guardianAuthIdError && userError) {
      setError(true);
    }
  }, [guardianAuthId, guardianAuthIdError, userError]);

  useEffect(() => {
    if (guardian) {
      guardian.students.find((e) => e.last_enrollment?.totvs_sent) &&
        setTotvsSent(!isEnrollmentManager(role));

      if (
        guardian.students.find(
          (student) =>
            student.last_enrollment.type === EnrollmentTypes.Reenrollment
        )
      ) {
        setReenrollment(true);
      }

      if (guardian.last_modified) {
        setModifiedDate(guardian.last_modified.substring(0, 10));
      }

      const newDate = new Date();
      setCurrentDate(
        `${newDate.getFullYear()}-${
          newDate.getMonth() - 1
        }-${newDate.getDate()}`
      );
    }
  }, [guardian, role]);

  useEffect(() => {
    getCXUser({ id: authUser.id })
      .then(({ data, status }: GetCXUserJsonType) => {
        if (status === HTTP_STATUS.OK) {
          setUser(data);
        } else {
          setUserError(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [authUser]);

  useEffect(() => {
    if (guardian) {
      const cpfValidation =
        guardian.personal.cpf.length === 11
          ? validateCPF(guardian.personal.cpf)
          : false;
      setCpf(!cpfValidation);

      const cepValidation =
        guardian.location.zip_code.length === 8 ? true : false;
      setCep(!cepValidation);

      if (
        !guardian.personal.birth_date ||
        guardian.personal.birth_date.length !== 10 ||
        !guardian.location.address ||
        !guardian.location.neighborhood ||
        !guardian.location.number
      ) {
        setDataValidation(true);
      }
    }
  }, [guardian]);

  const sendFirstAccess = () => {
    if (
      window.confirm(
        "Deseja enviar o e-mail/SMS de primeiro acesso a este responsável?"
      )
    ) {
      sendNotificationsFirstAccessGuardian({
        body: {
          guardian_id: guardian?.user_id,
          domain: getDomain(),
          bypass_children_count: true,
        },
      }).then((response__) => {
        if (response__) {
          if (response__.status === HTTP_STATUS.OK) {
            toastSuccess(
              "E-mail e SMS de primeiro acesso reenviados com sucesso"
            );
          } else if (
            response__.data.detail &&
            response__.data.detail === "User already logged in"
          ) {
            toastError(
              "Esse responsável já cadastrou uma senha. Agora, é só acessar app.escolamais.com e efetuar o login com e-mail e senha cadastrada"
            );
          } else {
            toastError(
              "Erro ao reenviar e-mail e SMS de primeiro acesso ao responsável"
            );
          }
        }
      });
    }
  };

  const totvsSentIsTrue = () => {
    setOpenModalTotvsSent(true);
  };

  return (
    <EditGuardianStyled className="container">
      {loading && <LinearProgress className="mt-3 mb-3" color="secondary" />}
      {error && <div>Erro ao carregar responsável.</div>}
      {!loading && guardian && (
        <>
          {(isFirstAccess(authUser, role) || dataConfirm) && (
            <div className="row">
              <div className="col-12 text-center">
                <BoldBiggerPink>Confirmação de Dados</BoldBiggerPink>
              </div>
              <div className="col-12 text-center mb-3">
                <strong style={{ color: "#000000" }}>
                  Confira se seus dados e os dados dos alunos estão corretos.
                </strong>
              </div>
            </div>
          )}
          <div
            onClick={() => setCollapseGuardian(!collapseGuardian)}
            className={`collapse-box ${collapseGuardian ? "collapsed " : ""}`}
          >
            {(isVendor(role) ||
              isEnrollmentCoordinator(role) ||
              isVendorManager(role) ||
              isEnrollmentManager(role)) &&
              `Dados de ${guardian.personal.name}`}
            {isGuardian(role) && `Meus Dados`}
            <FontAwesomeIcon
              className="ml-2"
              icon={
                collapseGuardian
                  ? (faChevronUp as IconProp)
                  : (faChevronDown as IconProp)
              }
            />
          </div>
          {collapseGuardian && (
            <div className="collapse-content">
              <GuardianDataBigBox>
                <div className="container">
                  <div className="row justify-content-center">
                    <Avatar
                      alt="guardianPicture"
                      src={Placeholder}
                      className={classes.avatar}
                    />
                  </div>
                  <div className="row justify-content-center mt-2">
                    <BoldBiggerBlue>{guardian.personal.name}</BoldBiggerBlue>
                  </div>
                  <div className="row justify-content-center mt-3">
                    <GuardianDataBox>
                      <div className="col-12 text-center">
                        <BoldBigger>Informações de Cadastro</BoldBigger>
                      </div>
                      <div className="col-12">
                        <span className="info">Email: </span>
                        {guardian.contact.email || "Não possui e-mail"}
                      </div>
                      <div className="col-12">
                        <span className="info">Data de Nascimento: </span>
                        {guardian.personal.birth_date
                          ? formatDate(guardian.personal?.birth_date)
                          : "Não possui data de nascimento"}
                      </div>
                      <div className="col-12">
                        <span className="info">RG: </span>
                        {guardian.personal.rg || "Não possui RG"}
                      </div>
                      <div className="col-12">
                        <span className="info">CPF: </span>
                        {guardian.personal.cpf || "Não possui CPF"}
                      </div>
                      <div className="col-12">
                        <span className="info">Telefone: </span>
                        {guardian.contact.phone || "Não possui telefone"}
                      </div>
                      <div className="col-12 mt-3 text-center">
                        <BoldBigger>Endereço</BoldBigger>
                      </div>
                      <div className="col-12">
                        <span className="info">CEP: </span>
                        {guardian.location.zip_code
                          ? formatCEP(guardian.location.zip_code)
                          : "Não possui CEP"}
                      </div>
                      <div className="col-12">
                        <span className="info">Endereço: </span>
                        {guardian.location.address}, nº{" "}
                        {guardian.location.number}
                      </div>
                      <div className="col-12">
                        <span className="info">Bairro: </span>
                        {guardian.location.neighborhood || "Não possui bairro"}
                      </div>
                      <div className="col-12">
                        <span className="info">Cidade: </span>
                        {guardian.location.city || "Não possui cidade"}{" "}
                      </div>
                      <div className="col-12">
                        <span className="info">UF: </span>
                        {guardian.location.uf || "Não possui UF"}
                      </div>
                      {!isDirector(role) ||
                        !isStudyRoomEducator(role) ||
                        (!isOperationalDirector(role) && (
                          <>
                            {totvsSent ? (
                              <Button
                                className="green"
                                onClick={() => totvsSentIsTrue()}
                              >
                                Responsável já enviado para totvs
                              </Button>
                            ) : (
                              <Button
                                className="col-12 text-center"
                                style={{ backgroundColor: PINK }}
                                onClick={() =>
                                  history.push(
                                    `/responsavel/editar-cadastro?guardianId=${guardianAuthId}`
                                  )
                                }
                              >
                                Editar
                              </Button>
                            )}
                          </>
                        ))}

                      {(isVendor(role) ||
                        isEnrollmentCoordinator(role) ||
                        isVendorManager(role) ||
                        isEnrollmentManager(role)) && (
                        <Button
                          className="col-12 text-center"
                          type="button"
                          style={{ backgroundColor: PINK }}
                          onClick={() => sendFirstAccess()}
                        >
                          Reenviar e-mail/SMS de primeiro acesso
                        </Button>
                      )}
                    </GuardianDataBox>
                  </div>
                </div>
              </GuardianDataBigBox>
            </div>
          )}
          <div
            onClick={() => setCollapseStudents(!collapseStudents)}
            className={`collapse-box mt-3 ${
              collapseStudents ? "collapsed " : ""
            }`}
          >
            {(isVendor(role) ||
              isEnrollmentCoordinator(role) ||
              isVendorManager(role) ||
              isEnrollmentManager(role)) &&
              `Alunos(as) de ${guardian.personal.name}`}
            {isGuardian(role) && "Alunos que você é responsável"}
            <FontAwesomeIcon
              className="ml-2"
              icon={
                collapseStudents
                  ? (faChevronUp as IconProp)
                  : (faChevronDown as IconProp)
              }
            />
          </div>
          {collapseStudents && (
            <div className="collapse-content">
              {guardian.students.map((student, key) => {
                return (
                  <div className="mb-3" key={key}>
                    <StudentFrame student={student} />
                  </div>
                );
              })}
            </div>
          )}
          {(isFirstAccess(authUser, role) || dataConfirm) && (
            <Button
              className="mb-5"
              onClick={() =>
                dataValidation || cpf || cep
                  ? setOpenModalDataValidation(true)
                  : history.push({
                      pathname: `/responsavel/meus-alunos`,
                      state: {
                        user,
                      },
                    })
              }
              type="button"
            >
              Confirmar dados
            </Button>
          )}
          {(isVendor(role) ||
            isEnrollmentCoordinator(role) ||
            isVendorManager(role)) && (
            <div className="row">
              <div className="col-12">
                <Button
                  style={{ backgroundColor: PINK }}
                  onClick={() => {
                    if (outOfApp) {
                      history.push(
                        `/responsavel/cadastro-aluno?guardianId=${guardianId}&guardianAuthId=${guardianAuthId}&name=${guardian.personal.name}&outofapp=true`
                      );
                    } else {
                      history.push(
                        `/responsavel/cadastro-aluno?guardianId=${guardianId}&guardianAuthId=${guardianAuthId}&name=${guardian.personal.name}`
                      );
                    }
                  }}
                  type="button"
                >
                  Cadastrar novo aluno
                </Button>
              </div>
            </div>
          )}
        </>
      )}
      <Modal
        setToggleModal={setOpenModalDataValidation}
        isOpen={openModalDataValidation}
      >
        <ModalDataValidation
          setToggleModal={setOpenModalDataValidation}
          guardian={guardian!}
          outOfDate={false}
          cep={cep}
          cpf={cpf}
        />
      </Modal>
      <Modal setToggleModal={setOpenModalTotvsSent} isOpen={openModalTotvsSent}>
        <ModalTotvsSent setToggleModal={setOpenModalTotvsSent} />
      </Modal>
    </EditGuardianStyled>
  );
};

export default EditGuardian;
