import styled from "styled-components";
import { BLACK, BLUE, GRAY_BORDER_THREE } from "styles/colors";

export const PaymentPlanContainer = styled.div`
  font-family: "Montserrat";

  div.enrollment-title {
    font-size: 20px;
    font-weight: bold;
    color: #ed008c;

    div.subtitle {
      font-weight: 500;
      font-size: 16px;
      color: black;
    }
  }
  div.subdiv {
    display: flex;
  }
`;
export const CentralizedDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
`;

export const PaymentBox = styled.div`
  padding: 1em;
  margin-top: 1em;
  width: 100%;

  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  border-color: #00b0f2;
  box-shadow: 0px 4px 4px 0px #00000040;

  @media (max-width: 320px) {
    h5 {
      font-size: 15px;
      margin-bottom: 0;
    }
  }
`;

export const FormBox = styled.div`
  width: 350px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  border-width: 2px;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  border-color: #c4c4c4;
  padding: 0.1em;
  margin: 5px 0;
  color: ${BLACK};
  p {
    height: fit-content;
    margin: 0;
  }
  box-shadow: 0px 4px 4px 0px #00000040;
  &.selectedDay {
    border-color: ${BLUE};
  }

  @media (max-width: 320px) {
    width: 300px;
  }
`;

export const TextHighlight = styled.div`
  margin: 2em;
  p {
    font-weight: 500;
    font-size: 18px;
    text-align: center;
  }
`;
