import styled from "styled-components";

export const UserFormDiv = styled.div`
  padding: 1em;
  p {
    color: #333333;
  }
`;

export const BirthDate = styled.p`
  font-size: 16px;
  font-weight: normal;
  background-color: white;
  width: 100%;
  height: 40px;
  color: #42474a;
  border: none;
  background-color: #d3d3d3;
  padding-left: 0.5em;
  padding-top: 10px;
`;

export const DivName = styled.p`
  margin-top: 8px;
  margin-bottom: 0;
  color: #666666;
  font-size: 12px;
`;
