import { Button, RadioButton, TextInput, Modal } from "components";
import { CalendarComponent } from "components/Calendar";
import SelectInput from "components/Select";
import { toastError, toastSuccess } from "components/Toast";
import {
  default as formatString,
  default as formatStringByPattern,
} from "format-string-by-pattern";
import {
  isCX,
  isEnrollmentCoordinator,
  isEnrollmentManager,
  isVendor,
  PAYMENT_STATUS,
  SCHOOL_PLACES_STATUS,
} from "helpers/constants";
import {
  composeValidators,
  CPFstudent,
  hasToBe6UntilJuneNextYear,
  noSpecialCharactersAndNumbers,
  required,
} from "helpers/fieldValidators";
import { deepCopy, stringToMaskCEP } from "helpers/helpers";
import HTTP_STATUS from "http-status-codes";
import {
  GetCXUserJson,
  GuardianInterface,
  UnitDetailELInterface,
} from "interfaces/constants";
import { LinkStyled } from "../../styles";
import { useContext, useState } from "react";
import { Field, Form } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { useHistory } from "react-router-dom";
import { getAddressByCEP } from "services/cep.service";
import { updateUser } from "services/users.service";
import loadingGif from "static/img/loading.gif";
import {
  formatStudentInitialData,
  formatUpdateStudentPayload,
  setStudentAfterUpdate,
} from "./helpers";
import { ModalTotvsSent } from "components/Modals";
import { Storage } from "Storage";

interface StudentFormInterface {
  student: GetCXUserJson;
  setEdit: (edit: boolean) => void;
  setStudent: (student: GetCXUserJson | undefined) => void;
  units: Array<UnitDetailELInterface>;
  guardian: GuardianInterface;
}

export const StudentForm = ({
  setStudent,
  student,
  setEdit,
  units,
  guardian,
}: StudentFormInterface) => {
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const outOfApp = urlParams.get("outofapp") === "true";
  const role = Storage.props.role.get();
  const [newDate, setDate] = useState<string>();
  const [openModalTotvsSent, setOpenModalTotvsSent] = useState(false);

  const onSubmit = async (values: any) => {
    const values_ = deepCopy(values);
    if (newDate) {
      const TheDate = new Date(values_.birth_date);
      values_.birth_date = `${TheDate.getFullYear()}-${
        TheDate.getMonth() + 1
      }-${TheDate.getDate()}`;
    }
    await updateUser({
      id: student.id,
      role: "student",
      body: formatUpdateStudentPayload(values_),
    }).then((response) => {
      if (response) {
        if (response.status === HTTP_STATUS.OK) {
          toastSuccess("Dados atualizados com sucesso");
          setStudent(setStudentAfterUpdate(student, response.data));
          setEdit(false);
        } else {
          toastError(
            "Erro ao atualizar seus dados. Tente novamente mais tarde."
          );
        }
      }
    });
  };

  const totvsSentIsTrue = () => {
    setOpenModalTotvsSent(true);
  };

  return (
    <>
      <Form
        initialValues={formatStudentInitialData(student, units)}
        onSubmit={onSubmit}
      >
        {({ form, handleSubmit, submitting, values }) =>
          student &&
          guardian && (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-sm-6 col-12">
                  <div className="border border-black rounded p-4 m-1">
                    <p className="font-weight-bold mt-3">Dados pessoais</p>
                    <div className="row">
                      <div className="col-sm-6 col-12">
                        <Field
                          component={TextInput}
                          name="name"
                          type="text"
                          placeholder=" "
                          label="Nome Completo"
                          maxLength="100"
                          validate={composeValidators(
                            required,
                            noSpecialCharactersAndNumbers
                          )}
                          required
                        />
                      </div>
                      <div className="col-sm-6 col-12">
                        <Field
                          component={CalendarComponent}
                          name="birth_date"
                          placeholder=" "
                          validate={composeValidators(
                            required,
                            hasToBe6UntilJuneNextYear
                          )}
                          required
                        />
                        <OnChange name="birth_date">
                          {async (value) => {
                            if (value) {
                              setDate(value);
                            }
                          }}
                        </OnChange>
                      </div>
                      <div className="col-sm-6 col-12">
                        <Field
                          component={TextInput}
                          name="cpf"
                          type="text"
                          placeholder=" "
                          label="CPF"
                          parse={formatString("999.999.999-99")}
                          validate={composeValidators(CPFstudent)}
                        />
                      </div>
                      <div className="col-sm-6 col-12">
                        <Field
                          component={TextInput}
                          name="rg"
                          type="text"
                          placeholder=" "
                          label="RG"
                          maxLength="15"
                        />
                      </div>
                      <div className="col-sm-6 col-12">
                        <Field
                          component={TextInput}
                          name="previous_school"
                          type="text"
                          placeholder=" "
                          label="Escola Anterior"
                          maxLength="100"
                          validate={required}
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="border border-black rounded p-4 m-1">
                    <div className="row align-itens-center justify-content-center">
                      <p className="font-weight-bold col-sm-12 col-12 m-2">
                        Gênero do Aluno:
                      </p>
                      <div className="col-sm-2 col-2">
                        <Field
                          component={RadioButton}
                          name="gender_type"
                          type="radio"
                          value="1"
                          required
                        />
                      </div>
                      <div className="col-sm-10 col-10 pl-0 my-auto">
                        <strong>Masculino</strong>
                      </div>
                      <div className="col-sm-2 col-2">
                        <Field
                          component={RadioButton}
                          name="gender_type"
                          type="radio"
                          value="2"
                          required
                        />
                      </div>
                      <div className="col-sm-10 col-10 pl-0 my-auto">
                        <strong>Feminino</strong>
                      </div>
                      {/**
                       * Pseudo Feature Flag abaixo
                       */}
                      {false && (
                        <>
                          <div className="col-sm-1 col-2">
                            <Field
                              component={RadioButton}
                              name="gender_type"
                              type="radio"
                              value="3"
                              required
                            />
                          </div>
                          <div className="col-sm-11 col-10 pl-0 my-auto">
                            <strong>Não informado.</strong>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="border border-black rounded p-4 m-1">
                    <p className="font-weight-bold mt-3">Localização</p>
                    <div className="row">
                      <div className="col-12">
                        <label>Utilizar endereço de algum responsável</label>
                        <Field<string>
                          component={SelectInput}
                          className="col-12"
                          name="same_address_as_guardian"
                        >
                          <option value="">Selecione...</option>
                          {student.guardians.map((guardian, key) => {
                            return (
                              <option value={guardian.id.toString()} key={key}>
                                Endereço de {guardian.name}
                              </option>
                            );
                          })}
                        </Field>
                        <OnChange name="same_address_as_guardian">
                          {async (value, previous) => {
                            if (value) {
                              const guardian = student.guardians.find(
                                (g) => g.id.toString() === value
                              );
                              if (guardian) {
                                form.change(
                                  "zip_code",
                                  guardian.zip_code
                                    ? stringToMaskCEP(guardian.zip_code)
                                    : ""
                                );
                                form.change("address", guardian.address);
                                form.change("number", guardian.number);
                                form.change("complement", guardian.complement);
                                form.change(
                                  "neighborhood",
                                  guardian.neighborhood
                                );
                                form.change("city", guardian.city);
                                form.change("uf", guardian.uf);
                              }
                            } else {
                              form.change("zip_code", "");
                              form.change("address", "");
                              form.change("number", "");
                              form.change("complement", "");
                              form.change("neighborhood", "");
                              form.change("city", "");
                              form.change("uf", "");
                            }
                          }}
                        </OnChange>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-3 col-12">
                        <Field
                          component={TextInput}
                          name="zip_code"
                          type="text"
                          placeholder=" "
                          label="CEP"
                          parse={formatStringByPattern("99999-999")}
                          disabled
                        />
                        <OnChange name="zip_code">
                          {async (value, previous) => {
                            if (value.length === 9) {
                              const response = await getAddressByCEP(value);
                              if (response) {
                                if (response.status === HTTP_STATUS.OK) {
                                  if (response.data.resultado === "0") {
                                    toastError("CEP não encontrado");
                                  } else {
                                    form.change(
                                      "address",
                                      response.data.tipo_logradouro +
                                        " " +
                                        response.data.logradouro
                                    );
                                    form.change(
                                      "neighborhood",
                                      response.data.bairro
                                    );
                                    form.change("city", response.data.cidade);
                                    form.change("uf", response.data.uf);
                                  }
                                }
                              }
                            }
                          }}
                        </OnChange>
                      </div>
                      <div className="col-sm-9 col-12">
                        <Field
                          component={TextInput}
                          label="Endereço"
                          name="address"
                          placeholder="Endereço"
                          type="text"
                          maxLength="140"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-2 col-12">
                        <Field
                          component={TextInput}
                          name="number"
                          placeholder=" "
                          label="Número"
                          maxLength="8"
                          disabled
                        />
                      </div>
                      <div className="col-sm-4 col-12">
                        <Field
                          component={TextInput}
                          label="Complemento"
                          name="complement"
                          placeholder=" "
                          type="text"
                          maxLength="60"
                          disabled
                        />
                      </div>
                      <div className="col-sm-6 col-12">
                        <Field
                          component={TextInput}
                          name="neighborhood"
                          placeholder=" "
                          type="text"
                          label="Bairro"
                          maxLength="80"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-10 col-12">
                        <Field
                          component={TextInput}
                          name="city"
                          placeholder=" "
                          type="text"
                          label="Cidade"
                          maxLength="32"
                          disabled
                        />
                      </div>
                      <div className="col-sm-2 col-12">
                        <Field
                          component={TextInput}
                          name="uf"
                          placeholder=" "
                          label="UF"
                          maxLength="2"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  {(isVendor(role) ||
                    isEnrollmentCoordinator(role) ||
                    (isCX(role) &&
                      (student.last_enrollment?.school_place_status ===
                        SCHOOL_PLACES_STATUS.VAGA_LIBERADA ||
                        !student.last_enrollment))) && (
                    <div className="border border-black rounded p-4 m-1">
                      <div className="row align-itens-center justify-content-start">
                        <div className="col-sm-12 col-12 m-2">
                          <p className="font-weight-bold">Unidade e Ano: </p>
                          {student && (
                            <p>
                              <div>
                                <strong>Escola: </strong>{" "}
                                {student.last_enrollment?.unit_name ||
                                  "Não possui Escola"}
                              </div>
                              {student.last_enrollment && (
                                <div>
                                  <strong>Ano: </strong>{" "}
                                  {student.last_enrollment.grade || "Sem ano"}
                                  {student.last_enrollment.grade && "º ano do "}
                                  {
                                    student.last_enrollment
                                      .educational_level_name
                                  }
                                </div>
                              )}
                            </p>
                          )}
                          {[
                            PAYMENT_STATUS.NAO_INICIADO,
                            PAYMENT_STATUS.AGUARDANDO_PAGAMENTO_DA_RESERVA,
                            PAYMENT_STATUS.AGUARDANDO_REEMBOLSO_DA_ESPERA,
                            PAYMENT_STATUS.PAGAMENTO_DA_ESPERA_EM_ATRASO,
                            PAYMENT_STATUS.PAGAMENTO_DA_RESERVA_EM_ATRASO,
                          ].includes(
                            student.last_enrollment?.payment_status
                          ) && (
                            <LinkStyled
                              onClick={() =>
                                history.push(
                                  `/responsavel/cadastro-aluno?studentId=${
                                    student.id
                                  }&guardianAuthId=${
                                    guardian.user_id
                                  }&guardianId=${guardian.id}&step=2${
                                    outOfApp ? "&outofapp=true" : ""
                                  }`
                                )
                              }
                            >
                              Editar unidade e/ou ano
                            </LinkStyled>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>{" "}
              {/** essa aqui */}
              <div className="row">
                <div className="col-sm-3 offset-sm-6 col-12">
                  <Button
                    className="blue outline"
                    onClick={() => setEdit(false)}
                    type="button"
                  >
                    Cancelar
                  </Button>
                </div>
                <div className="col-sm-3 col-12">
                  {student.last_enrollment?.totvs_sent &&
                    !isEnrollmentManager(role) && (
                      <Button
                        type="button"
                        className="disabled"
                        onClick={() => totvsSentIsTrue()}
                      >
                        {!submitting && "Salvar"}
                        {submitting && (
                          <img height="25" src={loadingGif} alt="loading" />
                        )}
                      </Button>
                    )}
                  {(!student.last_enrollment?.totvs_sent ||
                    isEnrollmentManager(role)) && (
                    <Button disabled={submitting} type="submit">
                      {!submitting && "Salvar"}
                      {submitting && (
                        <img height="25" src={loadingGif} alt="loading" />
                      )}
                    </Button>
                  )}
                </div>
              </div>
            </form>
          )
        }
      </Form>
      <Modal setToggleModal={setOpenModalTotvsSent} isOpen={openModalTotvsSent}>
        <ModalTotvsSent setToggleModal={setOpenModalTotvsSent} />
      </Modal>
    </>
  );
};
