import styled from "styled-components";

export const SearchContainer = styled.div`
  padding: 48px 160px 0 88px; // TODO: nao tem na lib

  h1 {
    font-size: 40px; // TODO: nao tem na lib
    font-weight: 600; // TODO: nao tem na lib
    line-height: normal;
    color: ${({ theme }) => theme.colors.primaryDefault};
  }

  h4 {
    margin-top: ${({ theme }) => theme.space[8]};
    font-size: ${({ theme }) => theme.fontSizes.web.h4};
    font-weight: 600; // TODO: nao tem na lib
    line-height: normal;
  }

  @media (max-width: 360px) {
    padding: ${({ theme }) => theme.space[2]};

    h1 {
      font-size: ${({ theme }) => theme.fontSizes.web.h1};
    }

    h4 {
      font-size: ${({ theme }) => theme.fontSizes.web.h4};
    }
  }
`;

export const InLine = styled.div`
  margin-top: ${({ theme }) => theme.space[4]};
  display: flex;
  align-items: center;
  gap: 0 ${({ theme }) => theme.space[4]};

  @media (max-width: 360px) {
    flex-direction: column;
    gap: ${({ theme }) => theme.space[4]} 0;
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.space[4]};

  width: 967px; // TODO: nao tem na lib
  gap: 0 267px; // TODO: nao tem na lib

  button {
    padding: 0;
  }

  button:last-of-type {
    width: 167.667px; // TODO: nao tem na lib
    height: 37px; // TODO: nao tem na lib

    display: flex;
    justify-content: center;
  }

  @media (max-width: 360px) {
    width: 100%;

    flex-direction: column;
    gap: ${({ theme }) => theme.space[4]};
  }
`;
