import { pxToRem } from "helpers/style";
import styled from "styled-components";

interface WarningStyleProps {
  type?: string;
}

export const Container = styled.div<WarningStyleProps>`
  width: 100%;
  height: auto;

  background: rgba(255, 255, 255, 0.9);
  border: ${(props) =>
    props.type === "Bulettin"
      ? "2px solid #00A1ED"
      : props.type === "Default" && "2px solid #f9c339;"};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  padding: 1rem;

  background-image: ${(props) =>
    props.type === "Bulettin"
      ? `url("/icons/info.svg")`
      : props.type === "Default" && `url("/icons/warning.svg")`};
  background-size: ${pxToRem(25)} ${pxToRem(25)};
  background-repeat: no-repeat;
  background-position: ${pxToRem(10)} center;
`;

export const Title = styled.p`
  width: calc(100% - 50px);
  height: auto;
  font-style: normal;
  font-weight: 700;
  font-size: ${pxToRem(16)};
  margin: 0;
`;

export const Content = styled.div`
  width: calc(100% - 50px);

  font-size: ${pxToRem(14)};
`;
