import { ClasseListProps, DisciplinaProps } from "./types";

export const SortDisciplinas = (x: DisciplinaProps, y: DisciplinaProps) => {
  return x.subject_name.localeCompare(y.subject_name);
};

export const SortTurmas = (x: ClasseListProps, y: ClasseListProps) => {
  return x.value.localeCompare(y.value);
};

export const maskNotas = (value: string) => {
  return value.replace(/\D+/g, "").replace(/(\d{1})(\d)/, "$1,$2");
};
