import * as S from "./styles";

interface ISubtitleProps {
  content: string;
}

export const Subtitle = ({ content }: ISubtitleProps) => {
  return (
    <S.Container>
      <h2>{content}</h2>
    </S.Container>
  );
};
