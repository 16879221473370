import axios from "axios";
import { ErrorHandlerFunction } from "./service-helpers";

export const getAddressByCEP = async (cep: string) => {
  const response = await axios
    .get(`https://republicavirtual.com.br/web_cep.php?cep=${cep}&formato=jsonp`)
    .catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};
