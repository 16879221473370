import { getTokens, verifyUser } from "services/auth.service";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  LoginImg01,
  LoginImg02,
  LoginImg03,
  LoginImg04,
  LoginImg05,
  LoginImg06,
} from "static/img";
import { useHistory } from "react-router-dom";
import { Storage } from "Storage";

import { useSetAtom } from "jotai";
import { authUserAtom } from "jotai/authUser";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

export type LoginValuesType = {
  email: string;
  password: string;
};

const loginImages = [
  LoginImg01,
  LoginImg02,
  LoginImg03,
  LoginImg04,
  LoginImg05,
  LoginImg06,
];

const pickRandomImage = (): string => {
  return loginImages[Math.floor(Math.random() * loginImages.length)];
};

const loginSchema = z.object({
  email: z
    .string()
    .nonempty("Insira o endereço de e-mail")
    .email("Formato de e-mail inválido")
    .toLowerCase(),
  password: z.string().nonempty("Insira a senha"),
});

export type LoginFormData = z.infer<typeof loginSchema>;

export const useLogin = () => {
  const randomImg = useRef(pickRandomImage());
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const setAuthUserAtom = useSetAtom(authUserAtom);

  const userStorageData = Storage.props.authUser.get();
  const tokenStorageData = Storage.token.get();
  const roleStorageData = Storage.props.role.get();

  useEffect(() => {
    if (userStorageData && tokenStorageData && roleStorageData) {
      setAuthUserAtom(userStorageData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = useCallback(async (loginValues: LoginValuesType) => {
    try {
      setLoading(true);

      const tokensResponse = await getTokens(loginValues);
      if (!tokensResponse) {
        setLoading(false);
        return;
      }

      Storage.token.set(tokensResponse);

      const userResponse = await verifyUser(loginValues);
      if (!userResponse) {
        setLoading(false);
        return;
      }

      setAuthUserAtom(userResponse.data);
      Storage.props.authUser.set(userResponse.data);

      if (userResponse.data?.role?.length === 1) {
        Storage.props.role.set(userResponse.data.role[0]);
      }

      setLoading(false);
      window.location.href = "/home";
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors, dirtyFields, isValid },
  } = useForm<LoginFormData>({
    resolver: zodResolver(loginSchema),
  });

  return {
    onSubmit,
    register,
    handleSubmit,
    trigger,
    errors,
    dirtyFields,
    isValid,
    loading,
    randomImg: randomImg.current,
  };
};
