/* eslint-disable jsx-a11y/aria-role */
/* eslint-disable no-sequences */
/* eslint-disable array-callback-return */
import * as S from "./style";
import * as I from "./type";

import { useBulletin } from "context/Bulletin";
import useModal from "hooks/useModal";
import { Modal } from "components/molecules/Modal";
import { Loading } from "components/atoms/Loading";
import {
  Title,
  Button,
  Warning,
  Collapse,
  ButtonShare,
} from "components/atoms";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { mdiShareVariantOutline, mdiInformationOutline } from "@mdi/js";
import { isDirector, isGuardian, isGradesManager } from "helpers/constants";
import { ModalBulletin } from "./modal";
import { BulletinFinal } from "./BulletinFinal";
import { useHistory, useLocation } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { BoletimPDF } from "pages/AP/Boletim/components/BoletimPDF";
import { Storage } from "Storage";
import { useAtomValue } from "jotai";
import { authUserAtom } from "jotai/authUser";
import { selectedStudentAprovacaoPreviaDrawerAtom } from "jotai/selectedStudentAprovacaoPreviaDrawer/atoms";
import { FeatureEnabler } from "components/FeatureEnabler";
import { InvalidGradeAlert } from "pages/AP/Boletim/components/InvalidGradeAlert";
import { Alert } from "components/Alert";
import {
  alertContentConfig,
  shouldDisableApproveBtnInCaseOfInvalidGrade,
  showInvalidGradeTooltip,
} from "pages/AP/Boletim/components/InvalidGradeAlert/alert-content-helper";
import { FilterProps } from "pages/AP/AprovacaoPrevia/types";
import { ReportCard } from "./ReportCard";

const prefix = "pages.AP.boletim.bulletin";

export const Bulletin = ({ filter }: { filter?: FilterProps }) => {
  const selectedStudentAprovacaoPreviaDrawer = useAtomValue(
    selectedStudentAprovacaoPreviaDrawerAtom
  );

  const deactiveFeaturesForInactiveStudents = () => {
    if (selectedStudentAprovacaoPreviaDrawer?.deleted) {
      return false;
    }
    return true;
  };

  const {
    bulletin,
    loadingBulletin,
    quarter,
    dataTable,
    reqTable,
    handleDataBulletin,
    handleSelectQuater,
    levelName,
    years,
    quarters,
    selectRole,
    setSelectRole,
    edit,
    handleToggleSelectedRole,
  } = useBulletin();
  const role = Storage.props.role.get();
  const authUser = useAtomValue(authUserAtom);
  const [data, setData] = useState<I.BulletinDataProps>();
  const [media, setMedia] = useState<any>();
  const [listMedia, setListMedia] = useState<any>();
  const [load, setLoad] = useState<boolean>(false);
  const [quarterList, setQuarterList] = useState<string[]>();
  const [nextStudent, setNextStudent] = useState<number>(0);
  const [prevStudent, setPrevStudent] = useState<number>(0);
  const history = useHistory();

  const location = useLocation<I.BulletinLocationProps>();

  const { isOpen, toggle } = useModal();
  const [typeModal, setTypeModal] = useState<string>("aprovar");
  const [evaluationsType, setEvaluationsType] = useState<string>(
    "Avaliação de Habilidades e Atitudes"
  );
  const [subject, setSubject] = useState<number>(1);
  const [open, setOpen] = useState<boolean>(false);
  const [bulletinFinal, setBulletinFinal] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (loadingBulletin === false && bulletin !== undefined) {
      setData(bulletin);
    }
  }, [bulletin, loadingBulletin, quarter]);

  useEffect(() => {
    setLoad(false);
    if (data) {
      const listMedia: any = {
        "1º Trimestre": [],
        "2º Trimestre": [],
        "3º Trimestre": [],
      };

      const quarters = Object.keys(listMedia);
      setQuarterList([...quarters]);

      Object.entries(data.final_schoolreport).find(([keyFinal, index]) => {
        Object.entries(listMedia).find(([keyMedia]) => {
          if (keyMedia === keyFinal) {
            data.final_schoolreport[keyMedia as keyof typeof keyMedia].map(
              (item: any) => {
                data.subjects.map((info) => {
                  if (item.subject === info.subject) {
                    listMedia[keyMedia as keyof typeof keyMedia].push({
                      name: item.subject,
                      value: item.quarter_avg,
                    });
                  }
                });
              }
            );
          }
        });
      });

      const selectedQuarterText = filter?.quarterDropDownList?.find(
        (quarter) => quarter.id === +filter.quarterActiveId
      )?.name;

      listMedia[`${quarter.selected || selectedQuarterText}`].sort(
        (a: any, b: any) => {
          return a?.name > b?.name ? 1 : b?.name > a?.name ? -1 : 0;
        }
      );

      setMedia(listMedia);
      setLoad(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, quarter.selected]);

  useEffect(() => {
    if (quarterList) {
      quarterList.map((item, index) => {
        if (item === quarter.selected) {
          setListMedia(media[item]);
        }
      });
    }
  }, [media, quarterList, quarter.selected]);

  useEffect(() => {
    if (dataTable && bulletin) {
      dataTable.students.map((student, index) => {
        if (student?.student_id === bulletin.student_id) {
          if (dataTable.students[index + 1]?.student_id === undefined) {
            setNextStudent(0);
          } else {
            setNextStudent(dataTable.students[index + 1].student_id);
          }

          if (dataTable.students[index - 1]?.student_id === undefined) {
            setPrevStudent(0);
          } else {
            setPrevStudent(dataTable.students[index - 1].student_id);
          }
        }
      });
    }
  }, [dataTable, bulletin, nextStudent, prevStudent]);

  const infoAha: undefined | I.EvalluationsTypeProps[] =
    bulletin &&
    bulletin.subjects[0].evaluations_type.filter(
      (evaluation) => evaluation.name === "Avaliação de Habilidades e Atitudes"
    );

  return (
    <S.Container>
      {loadingBulletin !== false ? (
        <S.MessageModal>
          <Loading />
        </S.MessageModal>
      ) : bulletin === undefined ? (
        <S.Section direction="row" padding>
          <Warning
            message={`${t(`${prefix}.warning`)} ${quarter.selected}`}
            type="Bulettin"
          />
        </S.Section>
      ) : (
        <>
          {location.pathname === "/boletim/" ||
          location.pathname === "/aprovacao-previa" ? (
            <>
              <Modal isOpen={isOpen} toggle={toggle} path={location.pathname}>
                <ModalBulletin
                  type={typeModal}
                  evaluationsType={evaluationsType}
                  data={bulletin}
                  subject={subject}
                  onClose={() => toggle()}
                />
              </Modal>

              <S.Section direction="column" padding={false}>
                {selectRole === "internal" ? (
                  <>
                    <S.Section direction="row" padding>
                      <FeatureEnabler
                        enablerCallback={deactiveFeaturesForInactiveStudents}
                      >
                        <S.ButtonIcon
                          disabled={prevStudent === 0 ? true : false}
                          value={prevStudent ? prevStudent : 0}
                          icon="prev"
                          onClick={() =>
                            handleDataBulletin({
                              id: prevStudent,
                              quarter: reqTable.quarterId,
                            })
                          }
                        />
                      </FeatureEnabler>
                      <S.SectionHead>
                        <Title
                          darkGray
                          fontSize="16px"
                          fontWeight="700"
                          lineHeight="1"
                          center
                          marginBottom="0"
                          responsive
                        >
                          {bulletin?.name}
                        </Title>
                      </S.SectionHead>
                      <FeatureEnabler
                        enablerCallback={deactiveFeaturesForInactiveStudents}
                      >
                        <S.ButtonIcon
                          disabled={nextStudent === 0 ? true : false}
                          value={nextStudent ? nextStudent : 0}
                          icon="next"
                          onClick={() =>
                            handleDataBulletin({
                              id: nextStudent,
                              quarter: reqTable.quarterId,
                            })
                          }
                        />
                      </FeatureEnabler>
                    </S.Section>
                  </>
                ) : (
                  selectRole === "external" && (
                    <S.ContainerHead>
                      {selectRole === "external" && (
                        <S.SectionHead
                          onClick={() =>
                            location.pathname === "/home/boletim"
                              ? history.goBack()
                              : setSelectRole("internal")
                          }
                        >
                          <S.Section direction="row" padding={false}>
                            <img src="/icons/arrow-left.svg" alt="" />
                            <Title
                              width="100%"
                              fontSize="24px"
                              fontWeight="700"
                              left
                              marginBottom="0"
                              pointer
                            >
                              {t(`${prefix}.bulletin`)}
                            </Title>
                          </S.Section>
                          <S.Section direction="row" padding={false}>
                            <S.SectionHead>
                              <Title
                                width="100%"
                                darkGray
                                fontSize="20px"
                                fontWeight="700"
                                left
                                marginBottom="0"
                                orange
                                responsive
                              >
                                {bulletin?.name.split(" ")[0]}
                              </Title>
                              <Title
                                width="100%"
                                darkGray
                                fontSize="14px"
                                fontWeight="400"
                                left
                                marginBottom="0"
                                responsive
                              >
                                {levelName && levelName.selected}{" "}
                                {years && years.selected}{" "}
                                {bulletin && bulletin.gradegroup}
                              </Title>
                            </S.SectionHead>
                          </S.Section>
                        </S.SectionHead>
                      )}
                    </S.ContainerHead>
                  )
                )}
                {selectRole === "internal" &&
                  !isGuardian(role) &&
                  bulletin.missing_alert?.length > 0 && (
                    <S.Section direction="column" padding>
                      <Alert.Root severity="warning">
                        <Alert.Title text="Nota" />
                        <Alert.Content text={`${bulletin.missing_alert}`} />
                      </Alert.Root>
                      <InvalidGradeAlert
                        invalidGradeNotice={bulletin.nota_invalida}
                        title={alertContentConfig.title}
                        content={alertContentConfig.augmentContent(
                          bulletin.nota_invalida
                        )}
                      />
                    </S.Section>
                  )}
                <S.ContentBulletin role={selectRole}>
                  {selectRole === "internal" && (
                    <S.Section direction="column" padding>
                      <Title
                        width="100%"
                        darkGray
                        fontSize="16px"
                        fontWeight="700"
                        center
                        marginBottom="0"
                        responsive
                      >
                        {t(`${prefix}.title`)}
                      </Title>
                    </S.Section>
                  )}
                  <FeatureEnabler
                    enablerCallback={deactiveFeaturesForInactiveStudents}
                  >
                    {(isGradesManager(role) || isDirector(role)) && (
                      <>
                        {selectRole === "internal" && (
                          <S.Section direction="column" padding>
                            <S.Content>
                              <S.ButtonBulletinSaf
                                onClick={handleToggleSelectedRole}
                              >
                                {t(`${prefix}.saf`)}
                              </S.ButtonBulletinSaf>

                              {bulletin?.approval === null ? (
                                <Button
                                  width="200px"
                                  onClick={() => {
                                    toggle();
                                    setTypeModal("aprovar");
                                  }}
                                  padding="0px"
                                  margin={false}
                                  disabled={shouldDisableApproveBtnInCaseOfInvalidGrade(
                                    bulletin?.nota_invalida
                                  )}
                                >
                                  <img src="/icons/check.svg" alt="" />{" "}
                                  {t(`${prefix}.bulletinApproved`)}
                                </Button>
                              ) : (
                                <>
                                  <S.SpanBulletin>{`Aprovação realizada ${bulletin.approval?.date_approved} as ${bulletin.approval?.hour_approved} - ${bulletin.approval?.user_name_approved}`}</S.SpanBulletin>
                                  <S.ButtonDisapprove
                                    onClick={() => {
                                      toggle();
                                      setTypeModal("desaprovar");
                                    }}
                                    disabled={shouldDisableApproveBtnInCaseOfInvalidGrade(
                                      bulletin.nota_invalida
                                    )}
                                  >
                                    <img src="/icons/close.svg" alt="" />{" "}
                                    {t(`${prefix}.bulletinDisapprove`)}
                                  </S.ButtonDisapprove>
                                </>
                              )}
                            </S.Content>
                          </S.Section>
                        )}
                      </>
                    )}
                  </FeatureEnabler>
                  {selectRole === "external" && quarters && (
                    <S.Section direction="row" padding>
                      <S.FilterBulletinQuarter>
                        <S.FilterBulletinQuarterItem
                          active={
                            bulletinFinal === false &&
                            quarter.selected === quarters[0]?.name
                              ? true
                              : false
                          }
                          onClick={() => (
                            handleSelectQuater(quarters[0]?.name),
                            handleDataBulletin({
                              id: bulletin.student_id,
                              quarter: quarters[0]?.id,
                            }),
                            setBulletinFinal(false)
                          )}
                        >
                          {quarters[0]?.name?.slice(0, 2)}
                        </S.FilterBulletinQuarterItem>
                        <S.FilterBulletinQuarterItem
                          active={
                            bulletinFinal === false &&
                            quarter.selected === quarters[1]?.name
                              ? true
                              : false
                          }
                          onClick={() => (
                            handleSelectQuater(quarters[1]?.name),
                            handleDataBulletin({
                              id: bulletin.student_id,
                              quarter: quarters[1]?.id,
                            }),
                            setBulletinFinal(false)
                          )}
                        >
                          {quarters[1]?.name?.slice(0, 2)}
                        </S.FilterBulletinQuarterItem>
                        <S.FilterBulletinQuarterItem
                          active={
                            bulletinFinal === false &&
                            quarter.selected === quarters[2]?.name
                              ? true
                              : false
                          }
                          onClick={() => (
                            handleSelectQuater(quarters[2]?.name),
                            handleDataBulletin({
                              id: bulletin.student_id,
                              quarter: quarters[2]?.id,
                            }),
                            setBulletinFinal(false)
                          )}
                        >
                          {quarters[2]?.name?.slice(0, 2)}
                        </S.FilterBulletinQuarterItem>
                        <S.FilterBulletinQuarterItem
                          active={bulletinFinal}
                          onClick={() => setBulletinFinal(true)}
                        >
                          final
                        </S.FilterBulletinQuarterItem>
                      </S.FilterBulletinQuarter>
                    </S.Section>
                  )}
                  {bulletinFinal ? (
                    <>
                      <S.Section direction="column" padding>
                        <S.ContentBulletin role="internal">
                          <S.Section direction="column" padding>
                            {data && <BulletinFinal data={data} />}
                          </S.Section>
                        </S.ContentBulletin>
                        <S.Section direction="column" padding={false}>
                          <S.SectionShare>
                            <PDFDownloadLink
                              document={
                                <BoletimPDF
                                  username={authUser.name}
                                  bulletin={bulletin}
                                  quarter={quarter.selected}
                                />
                              }
                            >
                              <ButtonShare>
                                <Icon path={mdiShareVariantOutline} size={1} />
                                {t(`${prefix}.share`)}
                              </ButtonShare>
                            </PDFDownloadLink>
                          </S.SectionShare>
                        </S.Section>
                      </S.Section>
                    </>
                  ) : (
                    <>
                      <S.Section direction="column" padding>
                        {infoAha &&
                          (bulletin?.comment || bulletin?.comment === "") && (
                            <S.ContainerResponsaveis>
                              {selectRole === "internal" ? (
                                <S.BoxSubjects role={selectRole}>
                                  <S.ItemSubjects role={selectRole}>
                                    <Title
                                      width="calc(100% - 130px)"
                                      darkGray
                                      fontSize="14px"
                                      fontWeight="700"
                                      lineHeight="1"
                                      center={false}
                                      responsive
                                    >
                                      Avaliação de Habilidades e Atitudes
                                    </Title>
                                    <S.MediaSubjects role={selectRole}>
                                      <FeatureEnabler
                                        enablerCallback={
                                          deactiveFeaturesForInactiveStudents
                                        }
                                      >
                                        {edit &&
                                          bulletin?.approval === null && (
                                            <S.ButtonEdit
                                              onClick={() => (
                                                setTypeModal("notas"),
                                                setEvaluationsType(
                                                  "Avaliação de Habilidades e Atitudes"
                                                ),
                                                toggle()
                                              )}
                                            />
                                          )}
                                      </FeatureEnabler>
                                      {infoAha[0]?.value}
                                    </S.MediaSubjects>
                                  </S.ItemSubjects>
                                  <FeatureEnabler
                                    enablerCallback={
                                      deactiveFeaturesForInactiveStudents
                                    }
                                  >
                                    <S.Text>
                                      {edit && bulletin?.approval === null && (
                                        <S.ButtonEdit
                                          onClick={() => (
                                            setTypeModal("habilidade"),
                                            setEvaluationsType(
                                              "Avaliação de Habilidades e Atitudes"
                                            ),
                                            toggle()
                                          )}
                                        />
                                      )}
                                      {bulletin?.comment}
                                    </S.Text>
                                  </FeatureEnabler>
                                </S.BoxSubjects>
                              ) : (
                                <S.ContainerResponsaveis>
                                  <S.ResponsavelNota>
                                    <S.TitleNota>Nota</S.TitleNota>
                                    <Title
                                      width="100%"
                                      darkGray
                                      fontSize="22px"
                                      fontWeight="700"
                                      lineHeight="0"
                                      marginBottom="0"
                                      center
                                    >
                                      {infoAha[0]?.value}
                                    </Title>
                                  </S.ResponsavelNota>
                                  <S.Section direction="column" padding={false}>
                                    <div
                                      style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                      }}
                                    >
                                      <Title
                                        width="100%"
                                        darkGray
                                        fontSize="16px"
                                        fontWeight="700"
                                        lineHeight="1"
                                        marginBottom="0"
                                        left
                                        center={false}
                                      >
                                        {infoAha[0]?.value}
                                      </Title>
                                      {bulletin.comment && (
                                        <S.Text>{bulletin?.comment}</S.Text>
                                      )}
                                    </div>
                                  </S.Section>
                                </S.ContainerResponsaveis>
                              )}
                            </S.ContainerResponsaveis>
                          )}

                        {selectRole === "external" && (
                          <S.ComponenteLegenda
                            onClick={() => (
                              setTypeModal("componente"), toggle()
                            )}
                          >
                            {t(`${prefix}.component`)}
                            <img src="/icons/info-purple.svg" alt="" />
                          </S.ComponenteLegenda>
                        )}

                        {bulletin?.subjects.map(
                          (subject, index) =>
                            subject && (
                              <S.BoxSubjects
                                role={selectRole}
                                key={`${subject}-${index}`}
                              >
                                <S.ItemSubjects
                                  role={
                                    selectRole === "internal"
                                      ? selectRole
                                      : "internal"
                                  }
                                >
                                  <Title
                                    width="calc(100% - 150px)"
                                    darkGray
                                    fontSize="14px"
                                    fontWeight="700"
                                    lineHeight="1"
                                    center={false}
                                    marginBottom="0px"
                                    responsive
                                  >
                                    {subject?.subject}
                                  </Title>
                                  <S.TagSubjects
                                    status={`${subject?.status} - tag`}
                                  >
                                    {subject?.status}
                                  </S.TagSubjects>
                                </S.ItemSubjects>
                                {(subject.status === "Recuperado" ||
                                  subject.status === "Não recuperado") &&
                                selectRole === "external" ? (
                                  <>
                                    <Collapse
                                      title="ver notas anteriores"
                                      open={open}
                                    >
                                      {subject.evaluations_type.map(
                                        (evaluation, index) =>
                                          evaluation && (
                                            <div key={`${evaluation}-${index}`}>
                                              <S.ItemSubjects role={selectRole}>
                                                <S.TitleSubJects
                                                  role={selectRole}
                                                >
                                                  {evaluation?.name}
                                                </S.TitleSubJects>
                                                {selectRole === "external" && (
                                                  <S.MediaSubjects
                                                    role={selectRole}
                                                  >
                                                    {evaluation?.proportion}%
                                                  </S.MediaSubjects>
                                                )}
                                                <S.MediaSubjects
                                                  role={selectRole}
                                                >
                                                  {edit &&
                                                    bulletin?.approval ===
                                                      null &&
                                                    selectRole !==
                                                      "external" && (
                                                      <S.ButtonEdit
                                                        onClick={() => (
                                                          setTypeModal("notas"),
                                                          setEvaluationsType(
                                                            `${evaluation?.name}`
                                                          ),
                                                          setSubject(
                                                            subject?.subject_id
                                                          ),
                                                          toggle()
                                                        )}
                                                      />
                                                    )}
                                                  {String(evaluation?.value) ===
                                                  "Subs"
                                                    ? "-"
                                                    : evaluation?.value}
                                                </S.MediaSubjects>
                                              </S.ItemSubjects>
                                              {index ===
                                                subject.evaluations_type
                                                  .length -
                                                  1 && (
                                                <>
                                                  <S.ItemSubjects className="px-0">
                                                    <S.TitleSubJects
                                                      role={selectRole}
                                                    >
                                                      {" "}
                                                    </S.TitleSubJects>
                                                    <S.Media
                                                      role={selectRole}
                                                      label="Média"
                                                    >
                                                      <S.Nota
                                                        role={selectRole}
                                                        status={`${subject?.status} - media`}
                                                      >
                                                        {subject?.average}
                                                      </S.Nota>
                                                    </S.Media>
                                                  </S.ItemSubjects>
                                                </>
                                              )}
                                            </div>
                                          )
                                      )}
                                    </Collapse>
                                    {typeof subject?.recovery === "number" && (
                                      <S.ItemSubjects role={selectRole}>
                                        <S.TitleSubJects role={selectRole}>
                                          {t(`${prefix}.recovery`)}
                                        </S.TitleSubJects>
                                        <S.MediaAlt
                                          role={selectRole}
                                          label="Média"
                                        >
                                          <S.Nota status={`${subject?.status}`}>
                                            {subject?.recovery}
                                          </S.Nota>
                                        </S.MediaAlt>
                                      </S.ItemSubjects>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {subject?.evaluations_type &&
                                      subject.evaluations_type.map(
                                        (evaluation, index) =>
                                          evaluation && (
                                            <div key={`${evaluation}-${index}`}>
                                              <S.ItemSubjects role={selectRole}>
                                                <S.TitleSubJects
                                                  role={selectRole}
                                                >
                                                  {evaluation?.name}
                                                  {showInvalidGradeTooltip(
                                                    evaluation.value
                                                  )}
                                                </S.TitleSubJects>

                                                {selectRole === "external" && (
                                                  <S.MediaSubjects
                                                    role={selectRole}
                                                  >
                                                    {evaluation?.proportion}%
                                                  </S.MediaSubjects>
                                                )}
                                                <S.MediaSubjects
                                                  role={selectRole}
                                                >
                                                  <FeatureEnabler
                                                    enablerCallback={
                                                      deactiveFeaturesForInactiveStudents
                                                    }
                                                  >
                                                    {edit &&
                                                      bulletin?.approval ===
                                                        null &&
                                                      selectRole !==
                                                        "external" && (
                                                        <S.ButtonEdit
                                                          onClick={() => (
                                                            setTypeModal(
                                                              "notas"
                                                            ),
                                                            setEvaluationsType(
                                                              `${evaluation?.name}`
                                                            ),
                                                            setSubject(
                                                              subject?.subject_id
                                                            ),
                                                            toggle()
                                                          )}
                                                        />
                                                      )}
                                                  </FeatureEnabler>
                                                  {String(evaluation?.value) ===
                                                  "Subs"
                                                    ? "-"
                                                    : evaluation?.value}
                                                </S.MediaSubjects>
                                              </S.ItemSubjects>
                                              {index ===
                                                subject.evaluations_type
                                                  .length -
                                                  1 && (
                                                <>
                                                  {typeof subject?.recovery ===
                                                    "number" && (
                                                    <S.ItemSubjects className="px-0">
                                                      <S.TitleSubJects
                                                        role={selectRole}
                                                      >
                                                        {" "}
                                                      </S.TitleSubJects>
                                                      <S.Media
                                                        role={selectRole}
                                                        label="Média 3"
                                                      >
                                                        <S.Nota
                                                          role={selectRole}
                                                          status={`${subject?.status} - media`}
                                                        >
                                                          {subject?.average}
                                                        </S.Nota>
                                                      </S.Media>
                                                    </S.ItemSubjects>
                                                  )}
                                                </>
                                              )}
                                            </div>
                                          )
                                      )}
                                  </>
                                )}
                                <S.ItemSubjects className="px-0">
                                  <S.Absence role={selectRole} label="Faltas">
                                    <S.Absences>{subject?.absences}</S.Absences>
                                    <FeatureEnabler
                                      enablerCallback={
                                        deactiveFeaturesForInactiveStudents
                                      }
                                    >
                                      {edit &&
                                        bulletin?.approval === null &&
                                        selectRole !== "external" && (
                                          <S.ButtonEdit
                                            onClick={() => (
                                              setTypeModal("faltas"),
                                              setEvaluationsType("Faltas"),
                                              setSubject(subject?.subject_id),
                                              toggle()
                                            )}
                                          />
                                        )}
                                    </FeatureEnabler>
                                    Freq. {subject?.attendance}%
                                  </S.Absence>
                                  <S.Media role={selectRole} label="Média 1">
                                    <S.Nota
                                      role={selectRole}
                                      status={`${subject?.status} - media`}
                                    >
                                      {subject?.average !== "Subs"
                                        ? subject?.average
                                        : "-"}

                                      {(subject?.status === "Recuperado" ||
                                        subject?.status === "Não recuperado") &&
                                        selectRole === "external" && (
                                          <S.IconRecovery
                                            onClick={() => (
                                              setTypeModal("recuperacao"),
                                              toggle()
                                            )}
                                          />
                                        )}
                                    </S.Nota>
                                  </S.Media>
                                </S.ItemSubjects>
                                {selectRole === "external" &&
                                  levelName?.selected === "Fundamental 1" && (
                                    <S.BoxEntenda>
                                      <S.EntendaNota
                                        onClick={() => (
                                          setTypeModal("entenda"),
                                          setEvaluationsType(
                                            "Avaliação de Conhecimentos"
                                          ),
                                          setSubject(subject?.subject_id),
                                          toggle()
                                        )}
                                      >
                                        <Icon
                                          path={mdiInformationOutline}
                                          size={1}
                                        />
                                        {t(`${prefix}.noteInfo`)}
                                      </S.EntendaNota>
                                    </S.BoxEntenda>
                                  )}
                              </S.BoxSubjects>
                            )
                        )}
                        {selectRole === "external" && (
                          <S.Section direction="column" padding={false}>
                            <S.SectionShare>
                              <PDFDownloadLink
                                document={
                                  <BoletimPDF
                                    username={authUser.name}
                                    bulletin={bulletin}
                                    quarter={quarter.selected}
                                  />
                                }
                              >
                                ;
                                <ButtonShare>
                                  <Icon
                                    path={mdiShareVariantOutline}
                                    size={1}
                                  />
                                  {t(`${prefix}.share`)}
                                </ButtonShare>
                              </PDFDownloadLink>
                            </S.SectionShare>
                          </S.Section>
                        )}
                      </S.Section>
                      <FeatureEnabler
                        enablerCallback={deactiveFeaturesForInactiveStudents}
                      >
                        <S.Section direction="column" padding={false}>
                          {isGradesManager(role) || isDirector(role) ? (
                            <>
                              <S.Section direction="column" padding>
                                {selectRole === "internal" && (
                                  <S.Content>
                                    {bulletin?.approval === null ? (
                                      <Button
                                        width="200px"
                                        onClick={() => {
                                          setTypeModal("aprovar");
                                          toggle();
                                        }}
                                        margin={false}
                                        disabled={shouldDisableApproveBtnInCaseOfInvalidGrade(
                                          bulletin.nota_invalida
                                        )}
                                      >
                                        <img src="/icons/check.svg" alt="" />{" "}
                                        {t(`${prefix}.bulletinApproved`)}
                                      </Button>
                                    ) : (
                                      <>
                                        <S.SpanBulletin>{`Aprovação realizada ${bulletin?.approval?.date_approved} as ${bulletin?.approval?.hour_approved} - ${bulletin.approval?.user_name_approved}`}</S.SpanBulletin>
                                        <S.ButtonDisapprove
                                          onClick={() => {
                                            setTypeModal("desaprovar");
                                            toggle();
                                          }}
                                          disabled={shouldDisableApproveBtnInCaseOfInvalidGrade(
                                            bulletin.nota_invalida
                                          )}
                                        >
                                          <img src="/icons/close.svg" alt="" />{" "}
                                          {t(`${prefix}.bulletinDisapprove`)}
                                        </S.ButtonDisapprove>
                                      </>
                                    )}
                                  </S.Content>
                                )}
                              </S.Section>
                            </>
                          ) : (
                            <></>
                          )}
                        </S.Section>
                      </FeatureEnabler>
                    </>
                  )}
                </S.ContentBulletin>
              </S.Section>
              {selectRole === "internal" && (
                <S.Section direction="column" padding>
                  <S.ContentBulletin role={selectRole}>
                    <S.Section direction="column" padding>
                      {data && <BulletinFinal data={data} />}
                    </S.Section>
                  </S.ContentBulletin>
                  <FeatureEnabler
                    enablerCallback={deactiveFeaturesForInactiveStudents}
                  >
                    <S.Section direction="column" padding={false}>
                      <S.SectionShare>
                        <PDFDownloadLink
                          document={
                            <BoletimPDF
                              username={authUser.name}
                              bulletin={bulletin}
                              quarter={quarter.selected}
                            />
                          }
                        >
                          <ButtonShare>
                            <Icon path={mdiShareVariantOutline} size={1} />
                            {t(`${prefix}.share`)}
                          </ButtonShare>
                        </PDFDownloadLink>
                      </S.SectionShare>
                    </S.Section>
                  </FeatureEnabler>
                </S.Section>
              )}
            </>
          ) : (
            <>
              <Modal isOpen={isOpen} toggle={toggle} path={location.pathname}>
                <ModalBulletin
                  type={typeModal}
                  evaluationsType={evaluationsType}
                  data={bulletin}
                  subject={subject}
                  onClose={() => toggle()}
                />
              </Modal>
              <S.Section direction="column" padding={false}>
                <S.ContainerHead>
                  <S.SectionHead>
                    <S.Section
                      direction="row"
                      padding={false}
                      onClick={() => history.goBack()}
                    >
                      <img src="/icons/arrow-left.svg" alt="" />
                      <Title
                        width="100%"
                        fontSize="24px"
                        fontWeight="700"
                        left
                        marginBottom="0"
                        pointer
                      >
                        {t(`${prefix}.bulletin`)}
                      </Title>
                    </S.Section>
                    <S.Section direction="row" padding={false}>
                      <S.SectionHeadInfo>
                        <S.StudentPhoto>
                          {/* {location.pathname === "/home/boletim" &&
                            location.state?.student?.photo_url && (
                              <img
                                src={location.state?.student?.photo_url}
                                alt={location.state?.student?.name}
                              />
                            )} */}
                        </S.StudentPhoto>
                        <div>
                          <Title
                            width="100%"
                            darkGray
                            fontSize="20px"
                            fontWeight="700"
                            left
                            marginBottom="0"
                            orange
                            responsive
                          >
                            {bulletin?.name.split(" ")[0]}
                          </Title>
                          <Title
                            width="100%"
                            darkGray
                            fontSize="14px"
                            fontWeight="400"
                            left
                            marginBottom="0"
                            responsive
                          >
                            {levelName && levelName.selected}{" "}
                            {years && years.selected}{" "}
                            {bulletin && bulletin.gradegroup}
                          </Title>
                        </div>
                      </S.SectionHeadInfo>
                    </S.Section>
                  </S.SectionHead>
                </S.ContainerHead>
                <S.ContentBulletin role={selectRole}>
                  {quarters && (
                    <S.Section direction="row" padding>
                      <S.FilterBulletinQuarter>
                        <S.FilterBulletinQuarterItem
                          active={
                            bulletinFinal === false &&
                            quarter.selected === quarters[0]?.name
                              ? true
                              : false
                          }
                          onClick={() => (
                            handleSelectQuater(quarters[0]?.name),
                            handleDataBulletin({
                              id: bulletin.student_id,
                              quarter: quarters[0]?.id,
                            }),
                            setBulletinFinal(false)
                          )}
                        >
                          {quarters[0]?.name?.slice(0, 2)}
                        </S.FilterBulletinQuarterItem>
                        <S.FilterBulletinQuarterItem
                          active={
                            bulletinFinal === false &&
                            quarter.selected === quarters[1]?.name
                              ? true
                              : false
                          }
                          onClick={() => (
                            handleSelectQuater(quarters[1]?.name),
                            handleDataBulletin({
                              id: bulletin.student_id,
                              quarter: quarters[1]?.id,
                            }),
                            setBulletinFinal(false)
                          )}
                        >
                          {quarters[1]?.name?.slice(0, 2)}
                        </S.FilterBulletinQuarterItem>
                        <S.FilterBulletinQuarterItem
                          active={
                            bulletinFinal === false &&
                            quarter.selected === quarters[2]?.name
                              ? true
                              : false
                          }
                          onClick={() => (
                            handleSelectQuater(quarters[2]?.name),
                            handleDataBulletin({
                              id: bulletin.student_id,
                              quarter: quarters[2]?.id,
                            }),
                            setBulletinFinal(false)
                          )}
                        >
                          {quarters[2]?.name?.slice(0, 2)}
                        </S.FilterBulletinQuarterItem>
                        <S.FilterBulletinQuarterItem
                          active={bulletinFinal}
                          onClick={() => (
                            handleSelectQuater(quarters[0]?.name),
                            handleDataBulletin({
                              id: bulletin.student_id,
                              quarter: quarters[0]?.id,
                            }),
                            setBulletinFinal(true)
                          )}
                        >
                          final
                        </S.FilterBulletinQuarterItem>
                      </S.FilterBulletinQuarter>
                    </S.Section>
                  )}

                  {bulletinFinal && (
                    <>
                      <S.Section direction="column" padding>
                        <S.ContentBulletin role="internal">
                          <S.Section direction="column" padding>
                            {data?.approval && data?.published && (
                              <ReportCard data={data} />
                            )}
                          </S.Section>
                        </S.ContentBulletin>
                        {data?.approval && data?.published && (
                          <S.Section direction="column" padding={false}>
                            <S.SectionShare>
                              <PDFDownloadLink
                                document={
                                  <BoletimPDF
                                    username={authUser.name}
                                    bulletin={bulletin}
                                    quarter={quarter.selected}
                                  />
                                }
                              >
                                <ButtonShare>
                                  <Icon
                                    path={mdiShareVariantOutline}
                                    size={1}
                                  />
                                  Compartilhar
                                </ButtonShare>
                              </PDFDownloadLink>
                            </S.SectionShare>
                          </S.Section>
                        )}
                      </S.Section>
                    </>
                  )}

                  {!bulletin.approval &&
                  !bulletin.published &&
                  location.pathname === "/home/boletim" ? (
                    <S.Section direction="column" padding>
                      {!bulletinFinal && (
                        <Warning
                          message={`${t(`${prefix}.warning`)} ${
                            quarter?.selected
                          }`}
                          title={t(`${prefix}.unavailable`)}
                          type="Bulettin"
                        />
                      )}
                    </S.Section>
                  ) : (
                    <>
                      {bulletin.published ? (
                        <S.Section direction="column" padding>
                          {infoAha && bulletin?.comment && (
                            <S.ContainerResponsaveis
                              key={`${infoAha[0]?.name}-${0}`}
                            >
                              <S.ContainerResponsaveis>
                                <S.ResponsavelNota>
                                  <S.TitleNota>Nota</S.TitleNota>
                                  <Title
                                    width="100%"
                                    darkGray
                                    fontSize="22px"
                                    fontWeight="700"
                                    lineHeight="0"
                                    marginBottom="0"
                                    center
                                  >
                                    {infoAha[0]?.value}
                                  </Title>
                                </S.ResponsavelNota>
                                <S.Section direction="column" padding={false}>
                                  <div
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "flex-start",
                                    }}
                                  >
                                    <Title
                                      width="100%"
                                      darkGray
                                      fontSize="16px"
                                      fontWeight="700"
                                      lineHeight="1"
                                      marginBottom="0"
                                      left
                                      center={false}
                                    >
                                      {infoAha[0]?.name}
                                    </Title>
                                    {bulletin?.comment && (
                                      <S.Text>{bulletin.comment}</S.Text>
                                    )}
                                  </div>
                                </S.Section>
                              </S.ContainerResponsaveis>
                            </S.ContainerResponsaveis>
                          )}
                          {!bulletinFinal && (
                            <S.ComponenteLegenda
                              onClick={() => (
                                setTypeModal("componente"), toggle()
                              )}
                            >
                              {t(`${prefix}.component`)}
                              <img src="/icons/info-purple.svg" alt="" />
                            </S.ComponenteLegenda>
                          )}
                          {!bulletinFinal &&
                            bulletin?.subjects.map(
                              (subject, index) =>
                                subject && (
                                  <S.BoxSubjects
                                    role={selectRole}
                                    key={`${subject}-${index}`}
                                  >
                                    <S.ItemSubjects
                                      role={
                                        selectRole === "internal"
                                          ? selectRole
                                          : "internal"
                                      }
                                    >
                                      <Title
                                        width="calc(100% - 150px)"
                                        darkGray
                                        fontSize="14px"
                                        fontWeight="700"
                                        lineHeight="1"
                                        center={false}
                                        marginBottom="0px"
                                        responsive
                                      >
                                        {subject?.subject}
                                      </Title>
                                      <S.TagSubjects
                                        status={`${subject?.status} - tag`}
                                      >
                                        {subject?.status}
                                      </S.TagSubjects>
                                    </S.ItemSubjects>
                                    {(subject?.status === "Recuperado" ||
                                      subject?.status === "Não recuperado") &&
                                    selectRole === "external" ? (
                                      <>
                                        <Collapse
                                          title="ver notas anteriores"
                                          open={open}
                                        >
                                          {subject?.evaluations_type?.map(
                                            (evaluation, index) =>
                                              evaluation && (
                                                <div
                                                  key={`${evaluation}-${index}`}
                                                >
                                                  <S.ItemSubjects
                                                    role={selectRole}
                                                  >
                                                    <S.TitleSubJects
                                                      role={selectRole}
                                                    >
                                                      {evaluation?.name}
                                                    </S.TitleSubJects>
                                                    {selectRole ===
                                                      "external" && (
                                                      <S.MediaSubjects
                                                        role={selectRole}
                                                      >
                                                        {evaluation?.proportion}
                                                        %
                                                      </S.MediaSubjects>
                                                    )}
                                                    <S.MediaSubjects
                                                      role={selectRole}
                                                    >
                                                      {edit &&
                                                        bulletin?.approval ===
                                                          null &&
                                                        selectRole !==
                                                          "external" && (
                                                          <S.ButtonEdit
                                                            onClick={() => (
                                                              setTypeModal(
                                                                "notas"
                                                              ),
                                                              setEvaluationsType(
                                                                `${evaluation?.name}`
                                                              ),
                                                              setSubject(
                                                                subject.subject_id
                                                              ),
                                                              toggle()
                                                            )}
                                                          />
                                                        )}
                                                      {String(
                                                        evaluation?.value
                                                      ) === "Subs"
                                                        ? "-"
                                                        : evaluation?.value}
                                                    </S.MediaSubjects>
                                                  </S.ItemSubjects>
                                                  {index ===
                                                    subject.evaluations_type
                                                      .length -
                                                      1 && (
                                                    <>
                                                      {typeof subject?.recovery ===
                                                        "number" && (
                                                        <S.ItemSubjects className="px-0">
                                                          <S.TitleSubJects
                                                            role={selectRole}
                                                          >
                                                            {" "}
                                                          </S.TitleSubJects>
                                                          <S.Media
                                                            role={selectRole}
                                                            label="Média 22"
                                                          >
                                                            <S.Nota
                                                              role={selectRole}
                                                              status={`${subject?.status} - media`}
                                                            >
                                                              {
                                                                subject?.recovery
                                                              }
                                                            </S.Nota>
                                                          </S.Media>
                                                        </S.ItemSubjects>
                                                      )}
                                                    </>
                                                  )}
                                                </div>
                                              )
                                          )}
                                        </Collapse>
                                        {typeof subject?.recovery ===
                                          "number" && (
                                          <S.ItemSubjects role={selectRole}>
                                            <S.TitleSubJects role={selectRole}>
                                              {t(`${prefix}.recovery`)}
                                            </S.TitleSubJects>
                                            <S.MediaAlt
                                              role={selectRole}
                                              label="Média 33"
                                            >
                                              <S.Nota
                                                status={`${subject?.status}`}
                                              >
                                                {subject.recovery}
                                              </S.Nota>
                                            </S.MediaAlt>
                                          </S.ItemSubjects>
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {subject?.evaluations_type &&
                                          subject?.evaluations_type?.map(
                                            (evaluation, index) =>
                                              evaluation && (
                                                <div
                                                  key={`${evaluation}-${index}`}
                                                >
                                                  <S.ItemSubjects
                                                    role={selectRole}
                                                  >
                                                    <S.TitleSubJects
                                                      role={selectRole}
                                                    >
                                                      {evaluation?.name}
                                                    </S.TitleSubJects>
                                                    {selectRole ===
                                                      "external" && (
                                                      <S.MediaSubjects
                                                        role={selectRole}
                                                      >
                                                        <span>
                                                          {
                                                            evaluation?.proportion
                                                          }
                                                          %
                                                        </span>
                                                      </S.MediaSubjects>
                                                    )}
                                                    <S.MediaSubjects
                                                      role={selectRole}
                                                    >
                                                      {edit &&
                                                        bulletin?.approval ===
                                                          null &&
                                                        selectRole !==
                                                          "external" && (
                                                          <S.ButtonEdit
                                                            onClick={() => (
                                                              setTypeModal(
                                                                "notas"
                                                              ),
                                                              setEvaluationsType(
                                                                `${evaluation?.name}`
                                                              ),
                                                              setSubject(
                                                                subject?.subject_id
                                                              ),
                                                              toggle()
                                                            )}
                                                          />
                                                        )}
                                                      {String(
                                                        evaluation?.value
                                                      ) === "Subs"
                                                        ? "-"
                                                        : evaluation?.value}
                                                    </S.MediaSubjects>
                                                  </S.ItemSubjects>
                                                  {index === 2 && (
                                                    <>
                                                      {subject?.recovery && (
                                                        <S.ItemSubjects>
                                                          <S.TitleSubJects
                                                            role={selectRole}
                                                          >
                                                            {" "}
                                                          </S.TitleSubJects>
                                                          <S.Media
                                                            role={selectRole}
                                                            label="Média 44"
                                                          >
                                                            <S.Nota
                                                              role={selectRole}
                                                              status={`${subject?.status} - media`}
                                                            >
                                                              {
                                                                subject?.recovery
                                                              }
                                                            </S.Nota>
                                                          </S.Media>
                                                        </S.ItemSubjects>
                                                      )}
                                                    </>
                                                  )}
                                                </div>
                                              )
                                          )}
                                      </>
                                    )}
                                    <S.ItemSubjects className="m-0 py-2 px-0">
                                      <S.Absence
                                        role={selectRole}
                                        label="Faltas"
                                      >
                                        <S.Absences>
                                          {subject?.absences}
                                        </S.Absences>
                                        {edit &&
                                          bulletin?.approval === null &&
                                          selectRole !== "external" && (
                                            <S.ButtonEdit
                                              onClick={() => (
                                                setTypeModal("faltas"),
                                                setEvaluationsType("Faltas"),
                                                setSubject(subject.subject_id),
                                                toggle()
                                              )}
                                            />
                                          )}
                                        Freq. {subject?.attendance}%
                                      </S.Absence>
                                      <S.Media
                                        role={selectRole}
                                        label="Média 55"
                                      >
                                        <S.Nota
                                          role={selectRole}
                                          status={`${subject?.status} - media`}
                                          className="d-flex align-items-center justify-content-center"
                                        >
                                          {subject?.average !== "Subs"
                                            ? subject?.average
                                            : "-"}
                                          {typeof subject?.recovery ===
                                            "number" && (
                                            <S.IconRecovery
                                              onClick={() => (
                                                setTypeModal("recuperacao"),
                                                toggle()
                                              )}
                                            />
                                          )}
                                        </S.Nota>
                                      </S.Media>
                                    </S.ItemSubjects>
                                    {selectRole === "external" &&
                                      levelName?.selected ===
                                        "Fundamental 1" && (
                                        <S.BoxEntenda>
                                          <S.EntendaNota
                                            onClick={() => (
                                              setTypeModal("entenda"),
                                              setEvaluationsType(
                                                "Avaliação de Conhecimentos"
                                              ),
                                              setSubject(subject?.subject_id),
                                              toggle()
                                            )}
                                          >
                                            {t(`${prefix}.noteInfo`)}
                                          </S.EntendaNota>
                                        </S.BoxEntenda>
                                      )}
                                  </S.BoxSubjects>
                                )
                            )}
                          {!bulletinFinal && data?.approval && data?.published && (
                            <S.Section direction="column" padding={false}>
                              <S.SectionShare>
                                <PDFDownloadLink
                                  document={
                                    <BoletimPDF
                                      username={authUser.name}
                                      bulletin={bulletin}
                                      quarter={quarter.selected}
                                    />
                                  }
                                >
                                  <ButtonShare>
                                    <Icon
                                      path={mdiShareVariantOutline}
                                      size={1}
                                    />
                                    Compartilhar
                                  </ButtonShare>
                                </PDFDownloadLink>
                              </S.SectionShare>
                            </S.Section>
                          )}
                        </S.Section>
                      ) : (
                        <S.Section direction="column" padding>
                          <Warning
                            message={`${t(`${prefix}.warning`)} ${
                              quarter?.selected
                            }`}
                            title={t(`${prefix}.unavailable`)}
                            type="Bulettin"
                          />
                        </S.Section>
                      )}
                    </>
                  )}
                </S.ContentBulletin>
              </S.Section>
            </>
          )}
        </>
      )}
    </S.Container>
  );
};
