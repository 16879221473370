// React
import { useContext } from "react";
// Style
import * as S from "./styles";
// Components
import { Button, ProfilePicture, Title } from "components/atoms";
import { EnrollmentStepCircle } from "components/molecules/EnrollmentStepCircle";
import { toastError, toastSuccess } from "components/Toast";
// Libs
import { Field, Form } from "react-final-form";
import { useLocation, useHistory } from "react-router-dom";
import formatStringByPattern from "format-string-by-pattern";
import { useTranslation } from "react-i18next";
import HTTP_STATUS from "http-status-codes";
// Interfaces
import { IFormStudentValues, IStudentDataEdit } from "./interface";
// Services
import { updateStudentData } from "services/student.service";
// Helpers
import {
  composeValidators,
  CPFvalidation,
  required,
} from "helpers/fieldValidators";
import { onlyNumberMask } from "helpers/helpers";

export const EditStudentData = () => {
  const { state } = useLocation<IStudentDataEdit>();
  const history = useHistory();

  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.studentDataEditing";

  const onSubmit = async (values: IFormStudentValues) => {
    const response = await updateStudentData({
      studentId: state.student.id,
      formValues: {
        name: values.name,
        birth_date: values.birth_date,
        rg: values.rg && onlyNumberMask(values.rg),
        cpf: values.cpf && onlyNumberMask(values.cpf),
      },
    });
    if (response && response.status === HTTP_STATUS.OK) {
      toastSuccess(t(`${TRANSLATE}.toastSuccess`));
      history.goBack();
    } else {
      toastError(t(`${TRANSLATE}.toastError`));
    }
  };

  return (
    <S.Container>
      <EnrollmentStepCircle
        studentName={state.student.nome}
        nextStep={state.enrollment.steps[2].name}
        step={2}
        totalSteps={7}
        currentStepName={state.enrollment.steps[1].name}
        subtitle={t(`${TRANSLATE}.enrollment`, { year: state.enrollment.year })}
        detail
      />

      <div className="studentDataHeader">
        <Title className="m-0" fontSize="18px" fontWeight="600">
          {t(`${TRANSLATE}.personalData`)}
        </Title>
        <span>{t(`${TRANSLATE}.requiredFields`)}</span>
        {/* <ProfilePicture canChangePhoto /> */}
      </div>

      <Form
        onSubmit={onSubmit}
        initialValues={{
          name: state.student.nome,
          birth_date: formatStringByPattern(
            "YYYY-MM-DD",
            state.student.birth_date
          ),
          rg: formatStringByPattern("99.999.999-1", state.student.rg),
          cpf: formatStringByPattern("999.999.999-99", state.student.cpf),
        }}
      >
        {({ form, handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <label>
                {t(`${TRANSLATE}.fullName`)}
                <Field
                  name="name"
                  component="input"
                  type="text"
                  placeholder="[Nome do estudante]"
                />
              </label>
            </div>
            <div className="birthdate">
              <label>
                {t(`${TRANSLATE}.birthDate`)}
                <Field
                  name="birth_date"
                  component="input"
                  type="date"
                  placeholder="dd/mm/aaaa"
                />
              </label>
            </div>
            <div className="fields">
              <label>
                {t(`${TRANSLATE}.rg`)}
                <Field
                  name="rg"
                  component="input"
                  type="string"
                  placeholder="00.000.000-1"
                  maxLength="12"
                  minLength="12"
                />
              </label>

              <label>
                {t(`${TRANSLATE}.cpf`)}
                <Field
                  name="cpf"
                  component="input"
                  type="string"
                  placeholder="000.000.000-00"
                  parse={formatStringByPattern("999.999.999-99")}
                  validate={composeValidators(required, CPFvalidation)}
                  minLength="14"
                  maxLength="14"
                  required
                  hideCheck
                />
              </label>
            </div>

            <div className="buttons">
              <Button type="button" outline onClick={() => history.goBack()}>
                {t(`${TRANSLATE}.buttonBack`)}
              </Button>
              <Button type="submit">{t(`${TRANSLATE}.buttonAdvance`)}</Button>
            </div>
          </form>
        )}
      </Form>
    </S.Container>
  );
};
