import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { AuthUserInterface, GetCXUserJson } from "interfaces/constants";
import { Storage } from "Storage";
import { StorageKeysEnum } from "Storage/keys";

const initialAuthUser: AuthUserInterface = {
  cpf: "",
  email: "",
  grade_unit_id: 0,
  id: 0,
  last_login: "",
  name: "",
  permissions: [],
  request_pass_change: false,
  role: [],
  unit: 0,
};

const initialUserInfo = {} as GetCXUserJson;

const initialAtomValueChoice = Storage.props.authUser.get() ?? initialAuthUser;

export const authUserAtom = atomWithStorage<AuthUserInterface>(
  StorageKeysEnum.AUTH_USER_STORAGE_KEY,
  initialAtomValueChoice
);

export const userInfoAtom = atom(initialUserInfo);
