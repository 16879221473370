import { ErrorHandlerFunction, Headers } from "./service-helpers";
import { KanataAPI } from "./api";

export const ExportTotvsSuccess = async ({ query }: { query: string }) => {
  const response = await KanataAPI.get<any>(
    `/totvs/success_integration?date=${query}`,
    Headers({
      timeout: 1000000,
    })
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const ExportTotvsError = async ({ query }: { query: string }) => {
  const response = await KanataAPI.get<any>(
    `/totvs/error_integration?date=${query}`,
    Headers({
      timeout: 1000000,
    })
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};
