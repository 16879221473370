import * as S from "./style";
import * as I from "./type";

export const Warning = ({
  message,
  title,
  type = "Default",
}: I.WarningProps) => {
  return (
    <S.Container type={type}>
      <S.Title>{title}</S.Title>
      <S.Content>{message}</S.Content>
    </S.Container>
  );
};
