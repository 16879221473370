import { Loading } from "components/atoms";
import { LoadContainer } from "./style";
import { TLoading } from "./type";

export const LoadingPage = ({ text }: TLoading) => {
  return (
    <LoadContainer>
      <h4>{text}</h4>
      <Loading />
    </LoadContainer>
  );
};
