import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 0 72px;
`;

export const Title = styled.h1`
  color: #6d28d9;
  font-size: 40px;
  font-weight: 600;
`;

export const HistoricoBoletins = styled.button`
  border: none;
  background: transparent;
  color: #6d28d9;
  text-decoration: underline;
  display: flex;
  align-items: center;
  gap: 6px;
  font-weight: 700;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
