import styled from "styled-components";

import * as colors from "styles/colors";

export const ListaDeEsperaFrameBox = styled.div`
  border-width: 1px;
  border-color: #fdf002;
  border-radius: 13px;
  background-color: #fdf002;
  padding: 1em;
  color: ${colors.BLACK};
`;

export const StudentFrameBox = styled.div`
  border-top-right-radius: 13px;
  border-top-left-radius: 13px;
  border-width: 1px;
  border-color: ${colors.BLUE};
  background-color: ${colors.BLUE};
  border-style: solid;
  padding: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  svg {
    color: #ffffff;
  }

  &.collapsed {
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
    border-color: ${colors.BLACK};
  }
`;

export const StudentName = styled.p`
  font-weight: bold;
  color: white;
  font-size: 14px;

  span {
    color: ${({ theme }) => theme.colors.primaryDark50};
  }
`;

export const StudentExpandContentBox = styled.div`
  border-width: 1px;
  border-color: ${colors.BLUE};
  border-style: solid;
  padding: 1em;
`;

export const Avatar = styled.img`
  vertical-align: middle;
  width: 70px;
  height: 70px;
  border-radius: 50%;
`;

export const ContrastedText = styled.span`
  font-weight: 600;
`;

export const BoldBiggerPink = styled.p`
  font-weight: bold;
  font-size: 24px;
  color: ${colors.PINK};
`;

export const ClickableText = styled.p`
  font-weight: 600;
  &:hover {
    cursor: pointer;
    color: ${colors.BLUE};
  }
`;
