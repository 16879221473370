import { LinearProgress } from "@material-ui/core";
import { Button, RadioButton, Modal } from "components";
import { ReducedHorizontalStepper } from "components/Timeline";
import { toastError, toastSuccess } from "components/Toast";
import { FINANCIAL_STATUS } from "helpers/constants";
import HTTP_STATUS from "http-status-codes";
import {
  StudentInterface,
  StudentDataInterface,
  GetCXUserJson,
} from "interfaces/constants";
import { useContext, useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useHistory, useLocation } from "react-router-dom";
import { updateEnrollment } from "services/enrollment.service";
import { checkingSerasa, getStudentData } from "services/users.service";
import loadingGif from "static/img/loading.gif";
import { FinancialResponsibleStyled } from "./style";
import { ModalRedirect } from "./Modal";

type StateType = {
  student: StudentInterface;
  guardian: GetCXUserJson;
  checkSerasaFalse?: boolean;
};

export const FinancialResponsible = () => {
  const location = useLocation<StateType>();
  const [checkSerasaFalse] = useState(location.state.checkSerasaFalse);
  const [newFinancial] = useState(true);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [timelineStep] = useState(1);
  const [guardSelected, setGuardSelected] = useState(1);
  const [selectedGuardianName, setSelectedGuardianName] = useState<string>();
  const [cpfNumber, setCpfNumber] = useState("");
  const [enrollmentNumber, setEnrollmentNumber] = useState(0);

  const [enrollmentId, setEnrollmentId] = useState<number>();
  const [studentInfos, setStudentInfos] = useState<StudentDataInterface>();
  const [openModal, setOpenModal] = useState(false);

  const [errorSelectGuardian, setErrorSelectGuardian] = useState<string>();
  const [statusCodeError, setStatusCodeError] = useState<number>();

  const history = useHistory();

  const throwErroSelectGuardian = () => {
    throw new Error(
      `error: ${errorSelectGuardian}, status: ${statusCodeError}, values: ${guardSelected}`
    );
  };

  useEffect(() => {
    if (location.state.student && location.state.guardian) {
      const getTheDocuments = async () => {
        const getStudentLocation = await getStudentData({
          id: location.state.student.id,
        });

        if (getStudentLocation) {
          setStudentInfos(getStudentLocation.data);
          setLoading(false);
        }
      };
      getTheDocuments();

      if (location.state.student.last_enrollment) {
        setEnrollmentId(location.state.student.last_enrollment.id);
      }
    } else {
      setError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goToScreen = () => {
    if (location.state.student.guard_info.guard_id === guardSelected) {
      history.push({
        pathname: `/financeiro/plano-pagamento`,
        state: {
          student: location.state.student,
          guardian: location.state.guardian,
        },
      });
    } else {
      setOpenModal(true);
    }
  };

  const onSubmitFinancial = async () => {
    const financialGuardian = await updateEnrollment({
      enrollment_id: enrollmentId,
      body: {
        guard_id: guardSelected,
      },
    });
    if (financialGuardian) {
      if (financialGuardian.status !== HTTP_STATUS.OK) {
        toastError(
          "Erro ao atualizar responsável financeiro. Tente novamente mais tarde."
        );
        setErrorSelectGuardian(
          financialGuardian && JSON.stringify(financialGuardian.data)
        );
        setStatusCodeError(financialGuardian && financialGuardian.status);
      }
    }
    if (checkSerasaFalse) {
      const updateStatus = await updateEnrollment({
        enrollment_id: enrollmentId,
        body: {
          financial_status: FINANCIAL_STATUS.AGUARDANDO_PLANO_PAGAMENTO,
          user_id: location.state.guardian.user_id,
          user_name: location.state.guardian.personal.name,
        },
      });
      if (updateStatus) {
        if (updateStatus.status === HTTP_STATUS.OK) {
          toastSuccess("Status da matrícula atualizado com sucesso!");
          goToScreen();
        } else {
          toastError("Erro! Tente novamente mais tarde.");
        }
      }
    }
    if (!checkSerasaFalse) {
      const checkSerasa = await checkingSerasa({
        body: {
          document: cpfNumber.toString(),
          enrollment: enrollmentNumber,
        },
      });
      if (checkSerasa) {
        if (checkSerasa.status === HTTP_STATUS.OK) {
          if (checkSerasa.data.approved) {
            const updateStatus = await updateEnrollment({
              enrollment_id: enrollmentId,
              body: {
                financial_status: FINANCIAL_STATUS.AGUARDANDO_PLANO_PAGAMENTO,
                user_id: location.state.guardian.user_id,
                user_name: location.state.guardian.personal.name,
              },
            });
            if (updateStatus) {
              if (updateStatus.status === HTTP_STATUS.OK) {
                toastSuccess("Responsável financeiro atualizado com sucesso!");
                toastSuccess("Status da matrícula atualizado com sucesso!");
                goToScreen();
              } else {
                toastError("Erro! Tente novamente mais tarde.");
              }
            }
          } else {
            history.push({
              pathname: `/responsavel/meus-alunos/carta`,
              state: {
                student: location.state.student,
                guardian: location.state.guardian,
              },
            });
          }
        }
      } else {
        toastError("Erro! Entre em contato conosco (11) 5555-3725");
      }
    }
  };

  return (
    <FinancialResponsibleStyled className="container">
      {loading && <LinearProgress className="mt-3 mb-3" color="secondary" />}
      {error && !loading && <div>Erro ao carregar estudante.</div>}
      {!error && !loading && location.state.student && studentInfos && (
        <div>
          <div className="enrollment-title text-center">
            Matrícula de {location.state.student.name}
            <div className="subtitle">
              {location.state.student.last_enrollment?.grade || "Sem ano"}
              {location.state.student.last_enrollment?.grade && "º ano do "}
              {location.state.student.last_enrollment?.educational_level_name} -
              Unidade {location.state.student.last_enrollment?.unit_name}
            </div>
          </div>
          <div className="row">
            <div className="offset-sm-4 col-sm-4 col-12">
              <ReducedHorizontalStepper step={timelineStep} />
            </div>
          </div>
          <Form
            initialValues={{
              guard_type: location.state.guardian.id,
            }}
            onSubmit={onSubmitFinancial}
          >
            {({ form, handleSubmit, submitting, values }) => (
              <form onSubmit={handleSubmit}>
                {errorSelectGuardian &&
                  statusCodeError &&
                  throwErroSelectGuardian()}
                <p>
                  Selecione quem será o responsável financeiro pela matricula de{" "}
                  {location.state.student.name}:
                </p>
                {studentInfos &&
                  studentInfos.guardians.map((guardian) => {
                    return (
                      <div className="row mb-3">
                        <div className="col-sm-1 col-2">
                          <Field
                            component={RadioButton}
                            name="guard_id"
                            type="radio"
                            value={guardian.id.toString()}
                            required
                            onClick={() => {
                              setGuardSelected(guardian.guard_info.guard_id);
                              setSelectedGuardianName(guardian.name);
                              setCpfNumber(guardian.cpf);
                              setEnrollmentNumber(
                                studentInfos.last_enrollment.id
                              );
                            }}
                          />
                        </div>
                        <div className="col-sm-11 col-10 pl-0 my-auto">
                          <div className="font-weight-bold">
                            {guardian.name}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <Button
                      onClick={() =>
                        history.push({
                          pathname: `/responsavel/cadastro`,
                          state: {
                            student: location.state.student,
                            guardian: location.state.guardian,
                            newFinancial,
                          },
                        })
                      }
                      className="blue outline"
                      type="button"
                    >
                      Cadastrar um novo responsável financeiro
                    </Button>
                  </div>
                </div>
                <div className="col-sm-3 offset-sm-9 col-12">
                  <Button disabled={guardSelected === 1}>
                    {!submitting && "Avançar"}
                    {submitting && (
                      <img height="25" src={loadingGif} alt="loading" />
                    )}
                  </Button>
                </div>
                <Modal setToggleModal={setOpenModal} isOpen={openModal}>
                  <ModalRedirect
                    setToggleModal={setOpenModal}
                    user={location.state.guardian}
                    guardianName={selectedGuardianName!}
                  />
                </Modal>
              </form>
            )}
          </Form>
        </div>
      )}
    </FinancialResponsibleStyled>
  );
};
