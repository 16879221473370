// React
import { useContext, useEffect, useState } from "react";
// Libs
import { useLocation } from "react-router-dom";
import HTTP_STATUS from "http-status-codes";
import { useTranslation, Trans } from "react-i18next";
import dayjs, { Dayjs } from "dayjs";
// Components
import { Button, Loading, ModalInfo } from "components/atoms";
import { EnrollmentStepCircle } from "components";
import { Calendar } from "components/atoms/Calendar";
import { CardFoodInfo } from "pages/EDF/SinglePurchase/components/CardFoodInfo";
import { LoadContainer } from "../StepOne/styles";
// Context
import { FoodContext } from "context/foodContext";
// Interfaces
import { ILocationSelectMeals, IStep } from "../../type";
// Services
import { getAvaliableDates, getFoodInfoByDay } from "services/meals.service";
// Styles
import * as S from "./styles";
import { IFoodContextType } from "context/foodContext/type";
import { IAvaliableItems, IHistoryItemsByDays } from "./type";

export const StepTwo = ({ next, previous }: IStep) => {
  const { t } = useTranslation();
  const { state } = useLocation<ILocationSelectMeals>();
  const i18_prefix = "pages.EDF.selectMeals.components.stepTwo";

  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activedDates, setActivedDates] = useState<string[]>([""]);
  const [itemsHistoryByDays, setItemsHistoryByDays] = useState<
    IHistoryItemsByDays[]
  >([]);
  const [isNotAvaliable, setIsNotAvaliable] = useState<string[]>([]);
  const [isProcessResume, setIsProcessResume] = useState(false);

  const {
    selectedFoodItems,
    setSelectedFoodItems,
    itemsTotalValue,
    clearSelectedFoodItems,
    selectedDates,
    setSelectedDates,
    clearSelectedDates,
  } = useContext(FoodContext) as IFoodContextType;

  const handleReturn = () => {
    clearSelectedFoodItems();
    clearSelectedDates();
    previous();
  };

  const handleCountResumeOrder = (
    selectFormatedDate: string,
    avaliableItems: IAvaliableItems[],
    incrementQuantity: boolean
  ) => {
    const itensAvaliablesId = avaliableItems.map(
      (itemAvaliable) => itemAvaliable.id
    );

    setSelectedFoodItems((prevState) =>
      prevState.map((foodItem) => {
        if (itensAvaliablesId.includes(foodItem.id)) {
          return {
            ...foodItem,
            qtd: incrementQuantity ? foodItem.qtd + 1 : foodItem.qtd - 1,
          };
        }

        if (!selectedDates.includes(selectFormatedDate)) {
          setIsNotAvaliable((prevState) => [...prevState, foodItem.name]);
        }

        return foodItem;
      })
    );
  };

  const handleSelectDate = async (date: Dayjs) => {
    const formatedDate = dayjs(date).format("DD/MM");
    setIsProcessResume(true);

    // verificando se a data já existe, caso sim, remover dos selecionados
    if (selectedDates.includes(formatedDate)) {
      setSelectedDates((prevState) =>
        prevState.filter((prevDate) => prevDate !== formatedDate)
      );

      const findInItemsHistoryByDays = itemsHistoryByDays.find(
        (item) => item.day === formatedDate
      );

      findInItemsHistoryByDays &&
        handleCountResumeOrder(
          formatedDate,
          findInItemsHistoryByDays.items,
          false
        );
      setIsProcessResume(false);
      return;
    }

    setSelectedDates((prevState) => [...prevState, formatedDate]);

    const res = await getFoodInfoByDay({
      unidade: state.studentUser.enrollment.unit_id,
      data: date.format("YYYY-MM-DD"),
      matricula: state.studentUser.enrollment.id,
    });

    if (res?.data && res?.status === HTTP_STATUS.OK) {
      setItemsHistoryByDays((prevState) => [
        ...prevState,
        {
          day: formatedDate,
          items: res.data.disponivel,
        },
      ]);

      handleCountResumeOrder(formatedDate, res.data.disponivel, true);
      setIsProcessResume(false);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const res = await getAvaliableDates();

      if (res?.status === HTTP_STATUS.OK) {
        setActivedDates(res?.data.datas_disponiveis);
      } else {
        setError(true);
      }

      setIsLoading(false);
    })();
  }, []);

  if (error) {
    return (
      <LoadContainer>
        <h4>{t(`${i18_prefix}.errorLoadingFoodInfo`)}</h4>
      </LoadContainer>
    );
  }

  if (isLoading) {
    return (
      <LoadContainer>
        <Loading />
      </LoadContainer>
    );
  }

  return (
    <S.Container>
      <EnrollmentStepCircle
        studentName={state.studentUser.name}
        nextStep={t(`${i18_prefix}.nextStep`)}
        step={2}
        totalSteps={3}
        currentStepName={t(`${i18_prefix}.currentStep`)}
        width="720px"
        subtitle={t(`${i18_prefix}.headerSubTitle`)}
      />

      {isNotAvaliable.length > 0 && (
        <ModalInfo isOpen={!!isNotAvaliable} title="Refeição já adquirida">
          <div className="modal-children">
            <p>
              <Trans
                i18nKey="isNotAvaliable"
                values={{ itens: isNotAvaliable.join(", ") }}
              >
                Já existe uma compra realizada de{" "}
                <strong>{isNotAvaliable.join(", ")}</strong> para a data
                selecionada. Adicionaremos ao seu pedido somente os itens que
                ainda não foram comprados.
              </Trans>
            </p>
            <button onClick={() => setIsNotAvaliable([])}>
              {t(`${i18_prefix}.closeModal`)}
            </button>
          </div>
        </ModalInfo>
      )}

      <Calendar
        selectedDates={selectedDates}
        activeDates={activedDates}
        handleSelectedDate={handleSelectDate}
        noActions={false}
      />

      <CardFoodInfo
        foodList={selectedFoodItems}
        total={itemsTotalValue}
        step={2}
        selectedDaysLength={selectedDates.length}
      />

      <div className="btn-group">
        <Button outline onClick={handleReturn}>
          {t(`${i18_prefix}.buttonBack`)}
        </Button>
        <Button
          onClick={() => next()}
          disabled={isProcessResume || itemsTotalValue === 0}
        >
          {t(`${i18_prefix}.buttonAdvance`)}
        </Button>
      </div>
    </S.Container>
  );
};
