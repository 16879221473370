import styled, { css } from "styled-components";
import {
  ICalendarBodyStyleProps,
  ICalendarDayStyleProps,
  ICalendarStyleProps,
} from "./type";

export const Container = styled.div<ICalendarStyleProps>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ marginTop }) => marginTop && marginTop};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  h1 {
    text-transform: capitalize;
  }
`;

export const Actions = styled.div`
  button {
    border: none;
    background-color: transparent;
    transition: color 0.2s ease;

    svg {
      color: ${({ theme }) => theme.colors.primaryDefault};
    }

    &:hover {
      svg {
        color: ${({ theme }) => theme.colors.primaryLight80};
      }
    }

    &:disabled {
      svg {
        color: ${({ theme }) => theme.colors.greyScale40};
      }
    }
  }
`;

export const Body = styled.table<ICalendarBodyStyleProps>`
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: ${({ theme, borderSpacing }) =>
    borderSpacing ? borderSpacing : theme.space[6]};

  thead th {
    text-align: center;
    font-weight: ${({ theme }) => theme.fontWeights.regular};
  }

  tbody tr td {
    text-align: center;
  }
`;

export const CalendarDay = styled.button<ICalendarDayStyleProps>`
  width: 25px; // não possui ds-token
  height: 25px; // não possui ds-token
  text-align: center;
  cursor: pointer;

  border-radius: ${({ theme }) => theme.radii.full};
  border: none;
  background-color: transparent;

  padding: 0;

  transition: background-color 0.2s ease;

  &:disabled {
    font-weight: ${({ theme }) => theme.fontWeights.medium};
  }

  font-weight: ${({ theme }) => theme.fontWeights.bold};

  ${({ selected, theme }) =>
    selected &&
    css`
      background-color: ${selected && theme.colors.primaryDefault};
      color: ${theme.colors.white};
    `}
`;
