import { LinearProgress } from "@material-ui/core";
import { Button } from "components";
import SelectInput from "components/Select";
import { MONTHS, YEARS } from "helpers/constants";
import { getDaysArray, numDaysInMonth } from "helpers/helpers";
import HTTP_STATUS from "http-status-codes";
import { GetCXUserJson } from "interfaces/constants";
import { FoodItemInterface, Package } from "interfaces/mealInterfaces";
import { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import {
  EnrollmentSingleFoodParamsType,
  getEnrollmentSingleFoods,
  getFoodItems,
  getFoodPackage,
} from "services/meals.service";
import { getCXUser } from "services/users.service";
import { getExtraFoodTotalValue } from "./helper";
import { ConsultaAlimentacaoResponsavelStyled } from "./style";
import { useAtomValue } from "jotai";
import { authUserAtom } from "jotai/authUser";

export const ConsultaAlimentacaoResponsavel = () => {
  const [guardian, setGuardian] = useState<GetCXUserJson>();
  const [foodItems, setFoodItems] = useState<Array<FoodItemInterface>>();
  const [singleFoods, setSingleFoods] = useState<Array<any>>();
  const [loadingSingleFoods, setLoadingSingleFoods] = useState(false);
  const [errorSingleFoods, setErrorSingleFoods] = useState(false);
  const [singleFoods2, setSingleFoods2] = useState<Array<any>>();
  const [package1, setPackage1] = useState<Array<Package>>();
  const [package2, setPackage2] = useState<Array<Package>>();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const authUser = useAtomValue(authUserAtom);

  useEffect(() => {
    const getCXUserData = async () => {
      const response = await getCXUser({
        id: authUser.id,
      });
      if (response && response.status === HTTP_STATUS.OK) {
        setGuardian(response.data);
      } else {
        setError(true);
      }
      setLoading(false);
    };

    try {
      getCXUserData();
    } catch {
      setError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values: EnrollmentSingleFoodParamsType) => {
    if (guardian) {
      setLoadingSingleFoods(true);
      setSingleFoods(undefined);
      setSingleFoods2(undefined);
      setPackage1(undefined);
      setPackage2(undefined);
      const unit = guardian.students
        .find((s) => s.enrollment.id.toString() === values.enrollment_id)!
        .enrollment.unit_id.toString();
      const responseFoodItems = await getFoodItems({
        unit,
      });
      if (responseFoodItems && responseFoodItems.status === HTTP_STATUS.OK) {
        setFoodItems(responseFoodItems.data);
      }
      const response = await getEnrollmentSingleFoods({
        params: values,
      });
      const responseSingleFoods2 = await getEnrollmentSingleFoods({
        params: {
          enrollment_id: values.enrollment_id,
          month: (parseInt(values.month) - 1).toString(),
          year: values.year,
        },
      });
      const responsePackage1 = await getFoodPackage({
        params: {
          enrollment: values.enrollment_id,
        },
      });
      const responsePackage2 = await getFoodPackage({
        params: {
          enrollment: values.enrollment_id,
        },
      });
      if (
        responseFoodItems &&
        responseFoodItems.data &&
        responseFoodItems.status === HTTP_STATUS.OK
      ) {
        if (response && response.data && response.status === HTTP_STATUS.OK) {
          setSingleFoods(response.data);
        }
        if (
          responseSingleFoods2 &&
          responseSingleFoods2.data &&
          responseSingleFoods2.status === HTTP_STATUS.OK
        ) {
          setSingleFoods2(responseSingleFoods2.data);
        }
        if (
          responsePackage1 &&
          responsePackage1.data &&
          responsePackage1.data.length > 0 &&
          responsePackage1.data[0].is_active === true &&
          responsePackage1.status === HTTP_STATUS.OK
        ) {
          setPackage1(responsePackage1.data[0].package);
        } else {
          setPackage1([]);
        }
        if (
          responsePackage2 &&
          responsePackage2.data &&
          responsePackage2.data.length > 0 &&
          responsePackage2.data[0].is_active === true &&
          responsePackage2.status === HTTP_STATUS.OK
        ) {
          setPackage2(responsePackage2.data[0].package);
        } else {
          setPackage2([]);
        }
      } else {
        setErrorSingleFoods(true);
      }
      setLoadingSingleFoods(false);
    }
  };

  return (
    <ConsultaAlimentacaoResponsavelStyled className="container">
      <div className="title">Consumo de alimentação</div>
      <p className="mt-3 font-weight-500">
        Aqui você pode acompanhar o consumo de alimentação dos alunos
        matriculados por você.
      </p>
      {loading && <LinearProgress className="mt-3 mb-3" color="secondary" />}
      {error && <div>Erro ao carregar dados do responsável.</div>}
      {guardian && (
        <Form onSubmit={onSubmit}>
          {({ form, handleSubmit, submitting, values }) => (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-sm-3 col-12">
                  <Field<string>
                    component={SelectInput}
                    name="enrollment_id"
                    required
                  >
                    <option value="">Selecione um aluno</option>
                    {guardian.students
                      .filter((s) => s.enrollment)
                      .map((student, key) => {
                        return (
                          <option value={student.enrollment.id} key={key}>
                            {student.name}
                          </option>
                        );
                      })}
                  </Field>
                  <OnChange name="enrollment_id">
                    {async (value) => {
                      setPackage1(undefined);
                      setPackage2(undefined);
                      setSingleFoods(undefined);
                      setSingleFoods2(undefined);
                    }}
                  </OnChange>
                </div>
                <div className="col-sm-3 col-12">
                  <Field<string> component={SelectInput} name="month" required>
                    <option value="">Selecione um mês</option>
                    {MONTHS.map((month, key) => {
                      return (
                        <option value={month.id} key={key}>
                          {month.name}
                        </option>
                      );
                    })}
                  </Field>
                  <OnChange name="month">
                    {async (value) => {
                      setPackage1(undefined);
                      setPackage2(undefined);
                      setSingleFoods(undefined);
                      setSingleFoods2(undefined);
                    }}
                  </OnChange>
                </div>
                <div className="col-sm-3 col-12">
                  <Field<string> component={SelectInput} name="year" required>
                    <option value="">Selecione um ano</option>
                    {YEARS.map((year, key) => {
                      return (
                        <option value={year} key={key}>
                          {year}
                        </option>
                      );
                    })}
                    <OnChange name="year">
                      {async (value) => {
                        setPackage1(undefined);
                        setPackage2(undefined);
                        setSingleFoods(undefined);
                        setSingleFoods2(undefined);
                      }}
                    </OnChange>
                  </Field>
                </div>
                <div className="col-sm-3 col-12">
                  <Button type="submit">Consultar</Button>
                </div>
              </div>
              {loadingSingleFoods && (
                <LinearProgress className="mt-3 mb-3" color="secondary" />
              )}
              {errorSingleFoods && (
                <div>
                  Erro ao carregar dados das alimentações. Tente novamente mais
                  tarde.
                </div>
              )}
              {singleFoods &&
                singleFoods.length === 0 &&
                singleFoods2 &&
                singleFoods2.length === 0 &&
                package1 &&
                package1.length === 0 &&
                package2 &&
                package2.length === 0 && (
                  <div>Nenhuma alimentação nesse período.</div>
                )}
              {foodItems &&
                ((singleFoods && singleFoods.length > 0) ||
                  (singleFoods2 && singleFoods2.length > 0) ||
                  (package1 && package1.length > 0) ||
                  (package2 && package2.length > 0)) && (
                  <div className="row">
                    <div className="col-sm-9 col-12">
                      <table className="mt-5 mb-5">
                        <thead>
                          <tr>
                            <th>Data</th>
                            {foodItems.map((foodItem, key) => {
                              return (
                                <th key={key}>
                                  {foodItem.name} <br />
                                  <span>
                                    Valor Unitário: R${" "}
                                    {foodItem.value_single.replace(".", ",")}
                                  </span>
                                </th>
                              );
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {getDaysArray(
                            `${values.year}-${parseInt(
                              values.month
                            ).toString()}-01`,
                            `${values.year}-${values.month}-
                             ${numDaysInMonth(values.month, values.year)}`
                          )
                            .filter((date) => ![0, 6].includes(date.getDay())) // remove finais de semana
                            .map((date, key) => {
                              return (
                                <tr key={key}>
                                  <td>
                                    {date
                                      .toISOString()
                                      .split(`T`)[0]
                                      .split(`${values.year}-`)[1]
                                      .split("-")
                                      .reverse()
                                      .join("/")}
                                  </td>
                                  {foodItems.map((foodItem, key) => {
                                    return (
                                      <td key={key}>
                                        {(date.getMonth() + 2).toString() ===
                                          values.month &&
                                          package1 &&
                                          package1.find(
                                            (p) =>
                                              p.food_item_name === foodItem.name
                                          ) && <span className="circle" />}
                                        {(date.getMonth() + 1).toString() ===
                                          values.month &&
                                          package2 &&
                                          package2.find(
                                            (p) =>
                                              p.food_item_name === foodItem.name
                                          ) && <span className="circle" />}
                                        {singleFoods &&
                                          singleFoods.find(
                                            (sf) =>
                                              sf.date ===
                                                date
                                                  .toISOString()
                                                  .split(`T`)[0] &&
                                              sf.item === foodItem.id
                                          ) && <span className="circle blue" />}
                                        {singleFoods2 &&
                                          singleFoods2.find(
                                            (sf) =>
                                              sf.date ===
                                                date
                                                  .toISOString()
                                                  .split(`T`)[0] &&
                                              sf.item === foodItem.id
                                          ) && <span className="circle blue" />}
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                    <div className="col-sm-3 col-12">
                      <p className="font-weight-bold">Legenda:</p>
                      <p>
                        <span className="circle" /> Incluso no pacote contratado
                      </p>
                      <p>
                        <span className="circle blue" /> Refeição fora do pacote
                      </p>
                      <p className="font-weight-bold">Descritivo:</p>
                      <p className="font-weight-500">
                        Alimentação extra: R${" "}
                        {(foodItems &&
                          singleFoods &&
                          singleFoods2 &&
                          getExtraFoodTotalValue(
                            getDaysArray(
                              `${values.year}-${parseInt(
                                values.month
                              ).toString()}-01`,
                              `${values.year}-${values.month}-
                              ${numDaysInMonth(values.month, values.year)}`
                            ).filter((date) => ![0, 6].includes(date.getDay())),
                            foodItems,
                            singleFoods,
                            singleFoods2
                          )
                            .toFixed(2)
                            .toString()
                            .replace(".", ",")) ||
                          0}
                      </p>
                      <p className="font-size-10 mt-4">
                        *O valor da <b>alimentação extra</b> será sempre cobrado
                        no boleto do mês seguinte ao consumo do aluno.
                      </p>
                    </div>
                  </div>
                )}
            </form>
          )}
        </Form>
      )}
    </ConsultaAlimentacaoResponsavelStyled>
  );
};
