import styled from "styled-components";

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.secondaryDefault};

  h2 {
    font-weight: 600; // TODO: Não tem na lib
    line-height: 30px; // TODO: Não tem na lib
    margin-top: ${({ theme }) => theme.space[6]};
    margin-bottom: ${({ theme }) => theme.space[4]};
    font-size: ${({ theme }) => theme.fontSizes.web.h4};
    padding: ${({ theme }) => theme.space[1]} ${({ theme }) => theme.space[2]};
  }
`;
