import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  page: {
    display: "flex",
    backgroundColor: "#ffffff",
    padding: 50,
    paddingTop: 5,
    paddingBottom: 10,
    fontFamily: "Montserrat",
    textAlign: "center",
  },

  image: {
    height: "auto",
    width: 80,
    marginRight: -60,
  },

  subtitle: {
    textAlign: "center",
    width: "100%",
    backgroundColor: "#dbdbdb",
    paddingTop: 2,
    paddingBottom: 3,
    marginTop: 8,
    marginBottom: 8,
    fontSize: 15,
    fontWeight: 700,
    fontFamily: "Montserrat",
  },

  header: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 14,
    paddingTop: 2,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#B3B3B3",
  },

  timestamp: {
    fontSize: "8px",
  },

  line: {
    display: "flex",
    flexDirection: "row",
    fontSize: 14,
    paddingTop: 2,
    paddingBottom: 2,
    borderBottomWidth: 1,
    borderBottomColor: "#B3B3B3",
    alignItems: "center",
  },

  table: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#dbdbdb",
    fontSize: 12,
    paddingTop: 2,
    paddingBottom: 2,
    paddingRight: 10,
    borderBottomWidth: 1,
    marginTop: 15,
    borderBottomColor: "#B3B3B3",
  },

  tablePage2: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#dbdbdb",
    fontSize: 10,
    paddingTop: 2,
    paddingBottom: 2,
    borderBottomWidth: 1,
    marginTop: 15,
    borderBottomColor: "#B3B3B3",
    alignItems: "center",
  },

  name: {
    display: "flex",
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#B3B3B3",
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 14,
    textAlign: "left",
  },

  bold: {
    fontWeight: 700,
  },

  column1: {
    width: 280,
    paddingRight: 5,
    fontWeight: 600,
  },

  column1NoBold: {
    width: 280,
    paddingRight: 5,
    paddingLeft: 5,
    textAlign: "left",
    fontSize: 12,
  },

  columnAuxiliary: {
    width: 80,
    textAlign: "center",
  },

  columnObs: {
    fontSize: 8,
    width: 90,
    textAlign: "center",
  },

  columnAuxiliaryBold: {
    width: 80,
    textAlign: "center",
    fontWeight: 600,
  },

  columnObsBold: {
    width: 90,
    textAlign: "center",
    fontWeight: 600,
  },

  detailsFooter: {
    display: "flex",
    flexDirection: "row",
  },

  title: {
    fontSize: "24",
  },

  titlePage2: {
    fontSize: "18",
    marginTop: 35,
    fontWeight: 600,
  },

  info: {
    fontSize: 10,
    marginTop: 10,
  },

  columnAuxiliaryPage2: {
    width: 160,
    textAlign: "center",
    fontSize: 12,
  },

  columnObsPage2: {
    fontSize: 10,
    width: 170,
    textAlign: "center",
  },

  columnAuxiliaryBoldPage2: {
    width: 160,
    textAlign: "center",
    fontWeight: 600,
  },

  columnAuxiliaryObsBoldPage2: {
    width: 170,
    textAlign: "center",
    fontWeight: 600,
  },

  aha: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 20,
    marginBottom: 10,
  },

  legenda: {
    textAlign: "justify",
    fontSize: 8,
  },

  noData: {
    fontSize: "14px",
    textAlign: "left",
    paddingVertical: 6,
  },

  smallColumn: {
    width: 80,
  },

  mediumColumn: {
    width: 140,
  },

  largeColumn: {
    width: 250,
    textAlign: "left",
  },

  scoreBox: {
    display: "flex",
    padding: 5,
  },

  textDetails: {
    fontSize: 12,
    marginRight: 10,
    textAlign: "left",
  },

  ahaColumn3Text: {
    fontSize: 12,
    marginRight: 10,
    textAlign: "left",
    width: 450,
  },

  titleDetails: {
    fontSize: 12,
    marginRight: 10,
    fontWeight: 700,
    marginBottom: 5,
    textAlign: "left",
  },

  detailsHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#dbdbdb",
  },

  detailsBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
