import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  padding: 1rem 0;

  table {
    table-layout: fixed;
  }
`;

interface ContentStyle {
  variant: string;
}

const handleColor = (value: string) => {
  switch (value) {
    case "outline":
      return css`
        width: 20%;
        height: 10rem;
        background: ${({ theme }) => theme.colors.white};
        border: 2px solid ${({ theme }) => theme.colors.secondaryDefault};
        margin-right: 0.5rem;
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
      `;

    case "solid":
      return css`
        width: 80%;
        height: 10rem;
        padding: 1rem;
        background: ${({ theme }) => theme.colors.primaryLight90};
      `;
  }
};

export const Content = styled.div<ContentStyle>`
  border-radius: 20px;
  ${(props) => props.variant && handleColor(props.variant)}
`;

export const TitleCard = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.black};
`;

export const TitleClass = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.black};
`;

const handleTitleColor = (value: string) => {
  switch (value) {
    case "enturmados":
      return css`
        color: ${({ theme }) => theme.colors.statusSuccessDefault};
      `;

    case "semturma":
      return css`
        color: ${({ theme }) => theme.colors.statusErrorDefault};
      `;

    case "total":
      return css`
        color: ${({ theme }) => theme.colors.black};
      `;
  }
};

interface SpanCardStyleProps {
  variant: string;
}

export const SpanCard = styled.span<SpanCardStyleProps>`
  ${(props) => props.variant && handleTitleColor(props.variant)};
  font-weight: bold;
  padding-right: 0.5rem;
`;

export const Teste = styled.div``;
