import styled from "styled-components";

export const StyledButton = styled.button`
  font-size: 14px;
  font-style: normal;
  width: 15em;
  height: 15em;
  padding: 0;
  text-transform: uppercase;
  text-align: center;
  @media only screen and (max-width: 415px) {
    margin-bottom: 2em;
  }
  box-shadow: inset 0px 1px 0px 0px #54a3f7;
  background: linear-gradient(
    to top,
    rgba(0, 174, 255, 1) 0%,
    rgba(12, 220, 232, 1) 100%
  );
  // text-shadow:0px 1px 0px #154682;
  // background-color: #00aeff;
  border-radius: 0.5em;
  border: none;
  color: #fff;
  &:hover {
    cursor: pointer;
    background: linear-gradient(
      to bottom,
      rgba(0, 174, 255, 1) 0%,
      rgba(12, 220, 232, 1) 100%
    );
  }
  &:disabled {
    background-color: #dadada;
    color: #a8a8a8;
    border: 1px solid #dadada;
  }
  &.margin-10 {
    margin: 10em;
  }

  &.blue {
    background-color: #00aeefff;
    &.outline {
      border: 1px solid #00aeefff;
      color: #00aeefff;
    }
  }

  &.outline {
    background-color: white;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  svg {
    flex: 1;
    margin-bottom: 1em;
  }
`;
