import { Loading } from "components/atoms/Loading";
import * as S from "./style";
import * as I from "./types";

export const GroupButtons: React.FC<I.GradeButtonsProps> = ({
  label,
  maxWidth,
  options,
  children,
  loading,
  quarter = false,
}) => {
  return (
    <S.Wrapper maxWidth={maxWidth}>
      {loading ? (
        <>
          <S.Label>{label}</S.Label>
          <S.Container>
            <Loading />
          </S.Container>
        </>
      ) : (
        <>
          <S.Label>{label}</S.Label>
          <S.Container options={options} quarter={quarter}>
            {children}
          </S.Container>
        </>
      )}
    </S.Wrapper>
  );
};
