import styled from "styled-components";
import * as colors from "styles/colors";

export const CardFoodCreditBox = styled.div`
  background-color: rgba(226, 226, 226, 0.322);

  padding: 16px;

  border: 1px solid;
  border-radius: 9px;
  border-color: rgba(70, 70, 70, 0.322);

  .studentName {
    font-size: 16px;
    color: ${colors.BLUE};
  }

  .downloadIcon {
    color: ${colors.BLACK_ONE};
  }

  .studentInfo {
    font-size: 12px;
    color: ${colors.GRAY_TWO};
  }

  .guardianName {
    color: ${colors.GRAY_TWO};
  }

  .latestRelease {
    color: ${colors.GRAY_TWO};
  }

  .latestReleaseDetails {
    font-size: 12px;
    color: ${colors.GRAY_TWO};
  }

  .subtitle {
    color: ${colors.BLUE};
  }

  .subtitleCredit {
    font-size: 16px;
    color: ${colors.BLUE};
  }

  .selectTitle {
    color: ${colors.GRAY_TWO};
  }

  .typesCheckbox {
    font-size: 12px;
    color: ${colors.GRAY_TWO};
  }

  .creditValue {
    h6 {
      font-size: 12px;
      color: ${colors.GRAY_TWO};
    }

    .creditValuesFields {
      div {
        margin-top: 0;
        padding-top: 0;
      }

      input {
        font-size: 18px;
        color: ${colors.GRAY_TWO};
        border-radius: 4px;
        padding: 15px;
        border: none;
        max-width: 162px;

        opacity: 60%;

        &::placeholder {
          font-weight: bold;
          color: ${colors.GRAY_TWO};
        }
      }

      button {
        color: ${colors.GRAY_TWO};
      }
    }
  }
`;
