import { SvgIconTypeMap } from "@material-ui/core";
import { OverridableComponent } from "@material-ui/core/OverridableComponent";
import { ButtonHTMLAttributes } from "react";
import { StyledButton } from "./styles";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  Icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
}

export default function ButtonSquared({
  children,
  Icon,
  ...rest
}: ButtonProps) {
  return (
    <StyledButton {...rest}>
      <div>
        {Icon && <Icon fontSize="large" />}
        {children}
      </div>
    </StyledButton>
  );
}
