import { Button } from "components";
import { ModalDiv } from "components/UseTerms/styles";

interface ModalAddressInterface {
  setToggleModal: (toggleModal: boolean) => void;
}

function ModalAddress({ setToggleModal }: ModalAddressInterface) {
  return (
    <div className="container">
      <ModalDiv>
        <div className="text-center">
          <strong className="title">O aluno mora com outro responsável?</strong>
          <p>
            Por favor, entre em contato conosco: atendimento@escolamais.com (11)
            5555-3725
          </p>
        </div>
        <Button onClick={() => setToggleModal(false)} className="blue outline">
          Voltar
        </Button>
      </ModalDiv>
    </div>
  );
}

export default ModalAddress;
