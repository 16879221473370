import styled, { css } from "styled-components";

interface INewTableContainer {
  width: number;
}
export const NewTableContainer = styled.div`
  width: 100%;

  @media (max-width: 360px) {
    overflow-x: scroll;
  }
`;

export const NewTableStyle = styled.div<INewTableContainer>`
  width: ${({ width }) => `${width}px`};
`;

interface INewTbodyTheadStyle {
  thead?: boolean;
}

export const NewTbodyTheadStyle = styled.div<INewTbodyTheadStyle>`
  display: flex;
  width: 100%;

  font-size: ${({ theme }) => theme.fontSizes.web.sm};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  line-height: normal;

  padding-left: ${({ theme }) => theme.space[2]};

  border-bottom: 2px solid ${({ theme }) => theme.colors.greyScale10};

  ${({ thead }) =>
    thead &&
    css`
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      border-bottom: 1px solid ${({ theme }) => theme.colors.greyScale40};
    `}
`;

interface INewTrStyle {
  height: number;
  width: number;
}

export const NewThStyle = styled.span<INewTrStyle>`
  height: ${({ height }) => `${height}px`};
  width: ${({ width }) => `${width}px`};

  display: flex;
  align-items: center;

  gap: 0 ${({ theme }) => theme.space[2]};

  img {
    cursor: pointer;
  }
`;

export const NewTdStyle = styled.span<INewTrStyle>`
  height: ${({ height }) => `${height}px`};
  width: ${({ width }) => `${width}px`};

  display: flex;
  align-items: center;
`;
