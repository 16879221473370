import { useEffect, useState } from "react";
import HTTP_STATUS from "http-status-codes";
import { useTranslation } from "react-i18next";

import { Loading, PaginationByIndex } from "components";
import { Drawer } from "components/organisms/Drawer";
import { toastError, toastSuccess } from "components/Toast";
import { Subtitle } from "../Subtitle";

import { saveUnitManageVacanciesInfo } from "services/units.service";
import { IVacanciesReenrollment, IVacanciesReenrollmentForm } from "./type";

import * as S from "./styles";

import { getReenrollmentList } from "services/enrollment.service";
import { IPagination } from "interfaces/utils";

enum EEducationalCycle {
  EF1 = 1,
  EF2 = 2,
  EM = 3,
}

export const VacanciesReenrollment = ({
  visible,
  onClose,
  classesData,
  setClassesData,
  selectedClassesIndex,
  setSelectedClassesIndex,
  selectedClassesData,
  syncDataAfterSubmit,
}: IVacanciesReenrollment) => {
  const { t } = useTranslation();
  const i18n_prefix =
    "pages.EDF.manageVacancies.components.vacanciesReenrollment";

  const nullToZero = (number: number | string) =>
    number === null ? "" : number;

  const [form, setForm] = useState<IVacanciesReenrollmentForm>({
    qtd_vagas_rematriculas_liberadas_para_venda: nullToZero(
      selectedClassesData?.qtd_vagas_rematriculas_liberadas_para_venda ?? 0
    ),
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [reenrollmentList, setReenrollmentList] = useState<IPagination<{
    nome: string;
    ra: string;
  }> | null>(null);

  const reenrollmentListSearch = reenrollmentList === null ? true : false;

  const educationalCycleToNumber = (value: string) => {
    switch (value) {
      case "EM":
        return EEducationalCycle.EM;
      case "EF2":
        return EEducationalCycle.EF2;
      default:
        return EEducationalCycle.EF1;
    }
  };

  useEffect(() => {
    setForm({
      qtd_vagas_rematriculas_liberadas_para_venda:
        selectedClassesData?.qtd_vagas_rematriculas_liberadas_para_venda ?? 0,
    });

    if (selectedClassesData) {
      (async () => {
        setReenrollmentList(null);
        const res = await getReenrollmentList({
          segmento: selectedClassesData.segmento,
          ciclo: educationalCycleToNumber(selectedClassesData.ciclo),
          unidade: selectedClassesData.unidade,
          ano: selectedClassesData.ano,
        });

        if (res && res.status === HTTP_STATUS.OK) {
          setReenrollmentList(res.data);
        } else {
          toastError(t(`${i18n_prefix}.reenrollmentListError`));
        }
      })();
    }
  }, [selectedClassesData, t]);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    const res = await saveUnitManageVacanciesInfo({
      unit: selectedClassesData?.id,
      values: form,
    });

    if (res?.status !== HTTP_STATUS.OK) {
      toastError(t(`${i18n_prefix}.submittingError`));
      setIsSubmitting(false);
      return;
    }

    toastSuccess(t(`${i18n_prefix}.submittingSuccess`));
    setIsSubmitting(false);
    onClose(false);
    syncDataAfterSubmit();
  };

  return (
    <Drawer
      title={t(`${i18n_prefix}.drawerTitle`)}
      isOpen={visible}
      onClose={() => onClose(false)}
      side="right"
      width="796px"
    >
      <PaginationByIndex
        results={classesData}
        index={selectedClassesIndex}
        setIndex={setSelectedClassesIndex}
        name={t(`${i18n_prefix}.paginationByIndexName`, {
          ciclo: selectedClassesData?.ciclo,
          segmento: selectedClassesData?.segmento,
        })}
      />

      <S.Container onSubmit={handleSubmit}>
        <div className="content">
          <p className="text-description">{t(`${i18n_prefix}.title`)}</p>
          <div className="vacancies">
            <label>
              {t(`${i18n_prefix}.qtd_vacancies`)}
              <input
                name="vagas_rematricula"
                type="number"
                onChange={(e) =>
                  setForm({
                    ...form,
                    qtd_vagas_rematriculas_liberadas_para_venda: nullToZero(
                      e.target.value
                    ),
                  })
                }
                value={form.qtd_vagas_rematriculas_liberadas_para_venda}
              />
            </label>
            <label>
              {t(`${i18n_prefix}.qtd_vacancies_reenrollment`)}
              <span aria-label={t(`${i18n_prefix}.qtd_vacancies_reenrollment`)}>
                {selectedClassesData?.qtd_vagas_rematricula}
              </span>
            </label>
          </div>

          <button type="button" disabled={isSubmitting} onClick={handleSubmit}>
            {t(`${i18n_prefix}.btnSubmit`)}
          </button>
        </div>

        {reenrollmentListSearch && <Loading />}
        {!reenrollmentListSearch && reenrollmentList && (
          <S.TableContent>
            <Subtitle
              title="Lista de estudantes para rematrícula"
              value={reenrollmentList.count}
            />

            <div className="table-head">
              <p>{t(`${i18n_prefix}.studentName`)}</p>
              <p>{t(`${i18n_prefix}.studentRecord`)}</p>
            </div>
            <div className="table-data">
              <ul>
                {reenrollmentList.results.length > 0 ? (
                  reenrollmentList.results.map((student, index) => {
                    return (
                      <li key={index}>
                        <p>{student.nome}</p>
                        <p>{student.ra}</p>
                      </li>
                    );
                  })
                ) : (
                  <h4>{t(`${i18n_prefix}.notFound`)}</h4>
                )}
              </ul>
            </div>
          </S.TableContent>
        )}
      </S.Container>
    </Drawer>
  );
};
