import styled from "styled-components";
import { PINK, BLUE, BLACK } from "styles/colors";

export const EditButton = styled.button`
  width: 5em;
  height: 5em;
  text-align: center;
  padding: 0;
`;

export const Container = styled.div`
  .feature-border {
    padding: 0.7em;
    margin: 0.5em;
    border-width: 1px;
    border-radius: 8px;
    border-color: #b8b8b8;
    box-shadow: 0px 2px 2px 0px #00000040;
    user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
  }

  .user-card {
    border-style: solid;
    padding: 0.7em;
    margin: 0.5em;
    border-width: 1px;
    border-radius: 12px;
    border-color: #c9c9c9;
    box-shadow: 0px 2px 2px 0px #00000040;
  }

  .pink-bold {
    font-size: 16px;
    font-weight: bold;
    color: ${PINK};
  }

  .blue-bold {
    font-size: 16px;
    font-weight: bold;
    color: ${BLUE};
  }

  .black-bold {
    font-size: 16px;
    font-weight: bold;
    color: ${BLACK};
  }

  .user-role {
    font-size: 16px;
    color: #878787;
  }

  .title {
    font-size: 18px;
    font-weight: bold;
    color: ${BLACK};
  }

  .rounded {
    padding: 0.7em;
    margin: 0.5em;
    border-width: 1px;
    border-radius: 15px;
    border-color: #c4c4c4;
    box-shadow: 0px 4px 4px 0px #00000040;

    &:hover {
      border-style: solid;
      border-color: #c4c4c4;
    }
  }

  .cursor-add {
    cursor: pointer;
  }

  .vertical-divider {
    border-left: 6px solid #c4c4c4;
    border-radius: 15px;
  }
`;

export const SelectStyled = styled.select`
  font-size: 14px;
  font-weight: normal;
  background-color: white;
  width: 100%;
  height: 38px;
  color: #42474a;
  border: none;
  border-bottom: 1px solid #cccccc;
  padding-left: 0.5em;
  margin-top: 2em;
`;

export const InputField = styled.input`
  font-size: 14px;
  font-weight: normal;
  background-color: white;
  width: 100%;
  height: 38px;
  color: #42474a;
  border: 1px solid #cccccc;
  border-radius: 15px;
  // border-bottom: 1px solid #cccccc;
  padding-left: 0.5em;
  :focus {
    outline: none;
    border-bottom: 2px solid #1a1a1a;
  }
  ::placeholder {
    color: transparent;
  }
  &.invalid {
    border-bottom: 2px solid #b00020;
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  :[type=checkbox]+label  {
    display: block;
    margin: 0.2em;
    cursor: pointer;
    padding: 0.2em;
  }
`;
