import styled from "styled-components";
import * as colors from "styles/colors";

export const SelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  div.select {
    width: 49%;
  }
`;

export const StyledTable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  table {
    border-radius: 15px;
    border: 2px solid #000;
    thead {
      background-color: ${colors.BLUE};
      color: #fff;
      justify-content: center;
      align-items: center;
      text-align: center;
      border: 2px solid #000;
      border-radius: 15px;
    }
    th {
      border-right: 2px solid #000;
      border-bottom: none;
      padding: 1em;
    }
  }
  tbody {
    tr {
      border: 1px solid #000;

      td {
        text-align: center;
        padding: 0.5em;
        border-right: 1px solid #000;
      }
    }
  }
`;
