// Style
import { FormContainer, PlanContainer } from "../../style";
// Components
import { MenuBar } from "components";
import { InputText } from "components/atoms/InputText";
import { SelectInput } from "components/atoms/SelectInput";
// Icons
import percent from "static/img/percent.svg";
// Helpers
import {
  formatMoneyInput,
  onlyNumberMask,
  stringToBoolean,
} from "helpers/helpers";
// Interfaces
import { IPlanPricesForm } from "./interface";
// Libs
import { useTranslation } from "react-i18next";

export const PlanPrices = ({
  register,
  errors,
  watch,
  edit,
}: IPlanPricesForm) => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.paymentPlan.components.prices";

  return (
    <PlanContainer>
      <MenuBar title={t(`${TRANSLATE}.titlePlanPrices`)} />
      <FormContainer>
        <h4>{t(`${TRANSLATE}.educationalServices`)}</h4>
        <div className="inLine">
          <InputText
            label={t(`${TRANSLATE}.labelPriceVacancyReservation`)}
            placeholder="R$00,00"
            id="priceVacancyReservation"
            width={228}
            register={register}
            error={errors?.priceVacancyReservation?.message}
            handleChange={(e) => {
              const { value } = e.target;
              e.target.value = formatMoneyInput(value);
            }}
            disabled={edit}
          />

          <InputText
            label={t(`${TRANSLATE}.labelMonthlyPrice`)}
            placeholder="R$00,00"
            id="monthlyPrice"
            width={228}
            register={register}
            error={errors?.monthlyPrice?.message}
            handleChange={(e) => {
              const { value } = e.target;
              e.target.value = formatMoneyInput(value);
            }}
            disabled={edit}
          />

          <InputText
            label={t(`${TRANSLATE}.labelNumberInstallments`)}
            placeholder={t(`${TRANSLATE}.placeHolderPlanName`)}
            id="numberInstallments"
            width={228}
            register={register}
            error={errors?.numberInstallments?.message}
            handleChange={(e) => {
              const { value } = e.target;
              e.target.value = onlyNumberMask(value);
            }}
            disabled={edit}
          />

          <SelectInput
            label={t(`${TRANSLATE}.discount`)}
            id="discount"
            width={228}
            register={register}
            disabled={edit}
          >
            <option value="false">{t(`${TRANSLATE}.noDiscount`)}</option>
            <option value="true">{t(`${TRANSLATE}.hasDiscount`)}</option>
          </SelectInput>
        </div>

        <InputText
          label={t(`${TRANSLATE}.inputDiscount`)}
          placeholder="0"
          id="discountPercentage"
          width={147}
          disabled={!stringToBoolean(watch("discount").toString())}
          icon={<img height={21} width={21} src={percent} alt="percentIcon" />}
          register={register}
          error={errors?.discountPercentage?.message}
          maxLength={4}
        />

        <h4>{t(`${TRANSLATE}.supply`)}</h4>

        <div className="inLine">
          <InputText
            label={t(`${TRANSLATE}.labelMonthlyPrice`)}
            placeholder="R$00,00"
            id="suplyMonthlyPrice"
            width={228}
            register={register}
            error={errors?.suplyMonthlyPrice?.message}
            handleChange={(e) => {
              const { value } = e.target;
              e.target.value = formatMoneyInput(value);
            }}
            disabled={edit}
          />

          <InputText
            label={t(`${TRANSLATE}.labelNumberInstallments`)}
            placeholder={t(`${TRANSLATE}.placeHolderPlanName`)}
            id="suplyNumberInstallments"
            width={228}
            register={register}
            error={errors?.suplyNumberInstallments?.message}
            handleChange={(e) => {
              const { value } = e.target;
              e.target.value = onlyNumberMask(value);
            }}
            disabled={edit}
          />

          <SelectInput
            label={t(`${TRANSLATE}.discount`)}
            id="suplyDiscount"
            width={228}
            register={register}
            disabled={edit}
          >
            <option value="false">{t(`${TRANSLATE}.noDiscount`)}</option>
            <option value="true">{t(`${TRANSLATE}.hasDiscount`)}</option>
          </SelectInput>

          <InputText
            label={t(`${TRANSLATE}.inputDiscount`)}
            placeholder="0"
            id="suplyDiscountPercentage"
            width={147}
            disabled={!stringToBoolean(watch("suplyDiscount").toString())}
            icon={
              <img height={21} width={21} src={percent} alt="percentIcon" />
            }
            register={register}
            error={errors?.suplyDiscountPercentage?.message}
          />
        </div>
      </FormContainer>
    </PlanContainer>
  );
};
