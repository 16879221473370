import styled from "styled-components";
import * as colors from "styles/colors";

export const Step5Styled = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1em;

  div.successImg {
    display: flex;
    justify-content: space-around;
  }

  div.text {
    text-align: center;
    margin-top: 1.4em;
  }

  div.packageInfos {
    margin-top: 1em;
    padding: 1em;
    background: rgba(109, 40, 217, 0.05);
    border-radius: 29px;

    span.monthName {
      text-transform: lowercase;
    }

    div.foodItems {
      margin-top: 1em;
      div.packageItems {
        display: flex;
        justify-content: space-between;
      }
    }

    div.workingDays {
      margin-top: 0.25em;
      display: flex;
      justify-content: flex-end;

      border-bottom: 1px solid ${colors.NEW_GRAY_THREE};

      p.workingDaysText {
        margin-bottom: 1em;
      }
    }

    div.spaceInLine {
      display: flex;
      justify-content: space-between;

      &.marginTop {
        margin-top: ${({ theme }) => theme.space[4]};
      }

      p.paymentDate {
        margin-top: ${({ theme }) => theme.space[2]};
        color: ${({ theme }) => theme.colors.greyScale40};
      }
    }
  }

  div.saveFoodPackage {
    display: flex;
    margin-top: 1em;

    gap: 0 4px;
  }

  div.button {
    margin-top: 7.94em;
  }
`;

// export const StudentBox = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;

//   border-top-right-radius: 13px;
//   border-top-left-radius: 13px;
//   border: 1px solid;
//   border-color: ${colors.BLUE};
//   background-color: ${colors.BLUE};
//   border-bottom: none;

//   padding: 1em;
//   width: 100%;

//   p.studentName {
//     font-weight: bold;
//     color: white;
//     font-size: 14px;
//     padding-top: 10px;
//   }

//   cursor: pointer;
// `;

// export const StudentContentBox = styled.div`
//   border-width: 1px;
//   border-color: ${colors.BLUE};
//   border-style: solid;
//   border-top: none;
//   border-bottom-left-radius: 10px;
//   border-bottom-right-radius: 10px;

//   color: ${colors.GRAY_TWO};
//   padding: 1em;
//   font-size: 11px;

//   div.textBold {
//     font-weight: bold;
//   }

//   div.pendingPayment {
//     padding: 2px 3px 0px 3px;
//     height: 20px;
//     background-color: ${colors.YELLOW};
//     border-radius: 4px;
//   }

//   div.paymentConfirmed {
//     padding: 2px 3px 0px 3px;
//     height: 20px;
//     background-color: ${colors.LIGHT_GREEN};
//     color: ${colors.WHITE};
//     border-radius: 4px;
//   }

//   div.warningBackground {
//     background-color: ${colors.LIGHT_YELLOW};
//   }

//   div.textLarge {
//     font-size: 14px;

//     &.bold {
//       font-weight: bold;
//     }
//   }

//   div.savePackage {
//     border-radius: 5px;
//     background-color: ${colors.LIGHT_GRAY};
//     font-size: 12px;
//     height: 2em;
//   }
// `;
