import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${({ theme }) => theme.space[2]};
  margin-top: ${({ theme }) => theme.space[2]};
  padding-bottom: 9.5px;

  p {
    margin: 0;
  }

  border-bottom: 1px solid ${({ theme }) => theme.colors.greyScale20};

  &:last-child {
    border-bottom: none;
  }

  img {
    max-width: 60px;
    max-height: 60px;
    width: 100%;
  }

  .itinerarie-info {
    width: 100%;

    > div {
      display: flex;
      justify-content: space-between;
      flex: 1;
    }

    p {
      font-size: ${({ theme }) => theme.fontSizes.web.default};
      margin-top: 4px;
    }

    span {
      width: 100%;
      display: inline-block;
      text-align: end;
    }
  }

  .itinerarie-info_course-name {
    strong {
      font-size: ${({ theme }) => theme.fontSizes.web.sm};
    }
  }

  .itinerarie-info_grade {
    font-size: ${({ theme }) => theme.fontSizes.web.sm};

    strong {
      font-size: ${({ theme }) => theme.fontSizes.web.default};
    }
  }

  @media (max-width: 768px) {
    .itinerarie-info {
      p,
      span > strong {
        font-size: ${({ theme }) => theme.fontSizes.mobile.default};
      }
    }
  }
`;
