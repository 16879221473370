import styled from "styled-components";
import Drawer from "@material-ui/core/Drawer";

/**
 * Faltou tipar o props passado pra dentro do styled
 */

export const DrawerStyled = styled(({ ...otherProps }) => (
  <Drawer {...otherProps} />
))`
  .MuiDrawer-paper {
    width: ${(props) => props.width}px !important;
  }
`;
