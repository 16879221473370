import { useHistory } from "react-router-dom";
import { CircleProgressBar, StepStatus } from "components/atoms";
import { EnrollmentInterface } from "interfaces/enrollment";
import { GetCXUserJson } from "interfaces/constants";

import * as S from "./style";

interface StudentStatusProps {
  student: EnrollmentInterface;
  user: GetCXUserJson;
}

export const StudentStatus = ({ student, user }: StudentStatusProps) => {
  const history = useHistory();

  const currentStepNumber = student.steps.find(
    (step) => step.name === student.current_step
  );

  return (
    <S.StudentStatus
      onClick={() =>
        history.push({
          pathname: "/matricula/etapas",
          state: {
            student,
            user,
          },
        })
      }
    >
      <S.StudentStatusProgress>
        <CircleProgressBar
          stepsNumbers={7}
          currentStep={currentStepNumber?.step || 1}
        />
      </S.StudentStatusProgress>

      <S.StudentStatusInfo>
        <strong>{student.guard.student.nome}</strong>
        <div className="details">
          <StepStatus type="concluído" />
          <span>Etapa {currentStepNumber?.step}</span>
        </div>
        <p className="m-0 mt-1">{student.current_step}</p>
      </S.StudentStatusInfo>
    </S.StudentStatus>
  );
};
