import dayjs from "dayjs";
import { ZERO_INDEXED_MONTHS } from "./constants";

export const getDateByDayAndMonthLong = (day: number, month: string) => {
  const retrievedMonth = ZERO_INDEXED_MONTHS?.find((m) => m.name === month)?.id;
  if (retrievedMonth) {
    const monthOut = dayjs().month(retrievedMonth).month();

    const date = dayjs().date(day).month(monthOut);
    return date;
  }
};
