import styled from "styled-components";
import * as colors from "styles/colors";

// não foi utilizado o font-size da lib por conta do app esta com 14px definido como padrao
export const BusinessDayStyle = styled.div`
  padding: 1em;

  h1 {
    font-size: 32px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    color: ${({ theme }) => theme.colors.primaryDefault};
  }

  form {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;

    // Todos campos referentes a input vão ser um component da lib
    div {
      margin-top: ${({ theme }) => theme.space[4]};
      display: flex;
      flex-direction: column;
      margin-right: ${({ theme }) => theme.space[8]};
      width: 13%;

      label {
        float: left;
        font-weight: ${({ theme }) => theme.fontWeights.regular};
        font-size: 14px;
        line-height: ${({ theme }) => theme.lineHeights.normal};
        color: ${colors.NEW_GRAY_SCALE}; // Não possui na lib
      }

      select {
        margin-top: -5px;
        box-sizing: border-box;
        height: ${({ theme }) => theme.space[10]};
        background: ${({ theme }) => theme.colors.white};
        border: 2px solid ${({ theme }) => theme.colors.greyScale40};
        color: ${({ theme }) => theme.colors.greyScale40};

        &.hasValue {
          color: ${colors.NEW_GRAY_SCALE}; // Não possui na lib
        }
      }

      input {
        margin-top: -5px;
        padding-left: ${({ theme }) => theme.space[1]};
        box-sizing: border-box;
        height: ${({ theme }) => theme.space[10]};
        background: ${({ theme }) => theme.colors.white};
        border: 2px solid ${({ theme }) => theme.colors.greyScale40};

        &::placeholder {
          color: ${({ theme }) => theme.colors.greyScale40};
        }
      }
    }

    button {
      margin-top: 2.4em;
    }
  }

  div.reloading {
    margin-top: 2em;
  }

  div.nofoundDiv {
    width: 100%;
    display: flex;
    justify-content: center;

    strong.nofound {
      text-align: center;
      margin: 64px 0;
      color: ${({ theme }) => theme.colors.greyScale40};
    }
  }

  @media (max-width: 360px) {
    h1 {
      font-size: 24px;
    }

    form {
      div {
        width: 100%;
        margin-right: 0;
      }

      button {
        width: 100%;
      }
    }
  }
`;

export const LoadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h4 {
    ${({ theme }) => theme.fontSizes.web.h4}
    text-align: center;
  }

  div {
    margin: ${({ theme }) => theme.space[4]};
  }
`;
