import { Circle } from "../../style";

interface Props {
  enrollment_documents: [
    {
      document_id: number;
      document_type_name: string;
      status: number;
      status_display: string;
    }
  ];
  document_name: string;
}

export const DocumentStatusDisplay = (props: Props) => {
  const { enrollment_documents, document_name } = props;
  if (!enrollment_documents) {
    return <Circle fillColor="#aaa" className="mt-3" />;
  }
  const document_to_display = enrollment_documents.find(
    (document) => document.document_type_name === document_name
  );
  return (
    <div>
      {document_to_display ? (
        <div>
          {document_to_display.status === 1 && (
            <Circle fillColor="#009688" className="mt-3" />
          )}
          {document_to_display.status === 2 && (
            <Circle fillColor="#f1233f" className="mt-3" />
          )}
          {document_to_display.status === 3 && (
            <Circle fillColor="#ffd000" className="mt-3" />
          )}
          {document_to_display.status === 4 && (
            <Circle fillColor="#009688" className="mt-3" />
          )}
        </div>
      ) : (
        <Circle fillColor="#aaa" className="mt-3" />
      )}
    </div>
  );
};
