// Libs
import { useTranslation } from "react-i18next";
import HTTP_STATUS from "http-status-codes";
// Interfaces
import { IEnrollment, TableHelperInterface } from "./interface";
import { EnrollmentInterface } from "interfaces/enrollment";
// Services
import {
  removeSchoolPlace,
  reserveSchoolPlace,
} from "services/enrollment.service";
import { toastError, toastSuccess } from "components/Toast";
import { Storage } from "Storage";

export const TableHelpers = ({ setNewSearch }: TableHelperInterface) => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.waitingList.components.table.tableHelper";
  const token = Storage.token.get();

  const changeLevelName = (name: string) => {
    switch (name) {
      case t(`${TRANSLATE}.caseFundamentalOne`):
        return t(`${TRANSLATE}.returnFundamentalOne`);
      case t(`${TRANSLATE}.caseFundamentalTwo`):
        return t(`${TRANSLATE}.returnFundamentalTwo`);
      case t(`${TRANSLATE}.caseHighSchool`):
        return t(`${TRANSLATE}.returnHighSchool`);
      default:
        return "";
    }
  };

  const handleAssign = async (student: IEnrollment) => {
    const response = await reserveSchoolPlace({
      enrollmentId: student.id,
      token: token?.access ?? "",
    });

    if (response && response.status === HTTP_STATUS.OK) {
      toastSuccess(t(`${TRANSLATE}.toastSuccess`));
      setNewSearch(true);
    } else {
      toastError(t(`${TRANSLATE}.toastError`));
    }
  };

  const handleRemove = async (student: IEnrollment) => {
    const response = await removeSchoolPlace({
      enrollmentId: student.id,
      token: token?.access ?? "",
    });

    if (response && response.status === HTTP_STATUS.OK) {
      toastSuccess(t(`${TRANSLATE}.removeToastSuccess`));
      setNewSearch(true);
    } else {
      toastError(t(`${TRANSLATE}.removeToastError`));
    }
  };

  return {
    changeLevelName,
    handleAssign,
    handleRemove,
  };
};
