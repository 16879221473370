import styled from "styled-components";
import * as colors from "styles/colors";

interface EnrollmentStepCircle {
  width?: string;
}

export const Container = styled.div<EnrollmentStepCircle>`
  width: ${(props) => (props.width ? props.width : "329px")};
  margin-top: 0.5em;
  margin-bottom: 2em;

  div.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  div.circleProgress {
    margin-top: 1.5em;
    margin-right: 0.5em;
    width: 60px;
    height: 60px;
  }

  div.flex {
    display: flex;
    width: 100%;
  }

  div.stage {
    display: flex;
    padding-left: 2em;
    /* background-color: yellow; */

    width: 45%;
  }

  div.currentStep {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    /* justify-content: end; */
    /* background-color: blue; */
    margin-left: 1em;
    width: 50%;

    p {
      text-align: end;
    }
  }
`;

export const TextStepStyled = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;

  color: ${colors.NEW_PURPLE};

  div.stageText {
    margin-top: 1.2em;
  }

  div.giantText {
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
  }

  div.numberOfSteps {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  div.stageNumber {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.nextStep {
    margin-left: 1em;
    color: ${colors.NEW_GRAY_TWO};
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
  }
`;
