// React
import { useCallback, useState } from "react";
// Styles
import * as S from "./style";
// Libs
import { Tag, TextField } from "@escolamais-ui-v2/react";
import { useTranslation } from "react-i18next";
// Components
import { Loading } from "components";
import { Table } from "components/organisms/Table";
import { OrderDrawer } from "./components/OrderDrawer";
// Interfaces
import { IForm } from "./interface";
// Helpers
import { ConsultFoodHelper } from "./helper";
import { Pagination } from "components/atoms/Pagination";
import { CancelOrder } from "./components/Modal";
import { checkPaymentStatus, splitPunctuation } from "helpers/helpers";
import { Button } from "components/atoms";
import { Debounce } from "components/Debounce";

export const ConsultFoodOrders = () => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.consultFoodOrdes";

  const [form, setForm] = useState<IForm>({
    order_or_name: "",
    unit: "",
    paymentStatus: "",
  });

  const [modal, setModal] = useState(false);
  const [drawer, setDrawer] = useState(false);
  const [order, setOrder] = useState<number | null>(null);

  const tableHead = [
    "Estudante",
    "Nº do pedido",
    "Realizado em",
    "Total",
    "Status",
  ];

  const {
    loading,
    error,
    studentList,
    units,
    paymentStatus,
    offset,
    paginate,
    getList,
    newSearch,
  } = ConsultFoodHelper(form);

  if (loading) {
    return (
      <S.LoadContainer>
        <h4>{t(`${TRANSLATE}.loading`)}</h4>
        <Loading />
      </S.LoadContainer>
    );
  }

  if (error && !loading) {
    return (
      <S.LoadContainer>
        <div>{t(`${TRANSLATE}.error`)}</div>
      </S.LoadContainer>
    );
  }

  if (!loading && units) {
    return (
      <S.ConsultFoodContainer>
        <h2 className="title">{t(`${TRANSLATE}.title`)}</h2>
        <S.H4 textStyle="primary">{t(`${TRANSLATE}.params`)}</S.H4>

        <TextField
          appearance="default"
          backgroundColor="#FFF"
          borderColor="#999999"
          id="inputPrimary"
          labelColor="#0D0D0D"
          leftIconColor="#999999"
          rightIconColor="#fff"
          value={form.order_or_name}
          onChange={(e) => {
            setForm({ ...form, order_or_name: e.target.value });
          }}
          onChangeText={() => {}}
          placeholder={t(`${TRANSLATE}.inputTextPlaceholder`)}
          textColor="#0D0D0D"
          width={400}
          withLeftIcon
        />

        <S.FormLine>
          <S.SelectContainer>
            <label>{t(`${TRANSLATE}.unitLabel`)}</label>
            <select
              onChange={(e) => {
                setForm({ ...form, unit: e.target.value });
              }}
              name="enrollmentType"
              value={form.unit}
            >
              <option value="">{t(`${TRANSLATE}.selectInitialValue`)}</option>
              {units?.map((unit, index) => {
                return (
                  <option value={unit.id} key={index}>
                    {unit.name}
                  </option>
                );
              })}
            </select>
          </S.SelectContainer>

          <S.SelectContainer>
            <label>{t(`${TRANSLATE}.statusLabel`)}</label>
            <select
              onChange={(e) => {
                setForm({ ...form, paymentStatus: e.target.value });
              }}
              name="enrollmentType"
              value={form.paymentStatus}
            >
              <option value="">{t(`${TRANSLATE}.selectInitialValue`)}</option>
              {paymentStatus?.results.map((payment, index) => {
                return (
                  <option value={payment.value} key={index}>
                    {payment.label}
                  </option>
                );
              })}
            </select>
          </S.SelectContainer>
        </S.FormLine>

        <Button type="button" className="search" onClick={() => getList()}>
          Pesquisar
        </Button>

        <S.H4 textStyle="primary" marginTop>
          {t(`${TRANSLATE}.results`)}
        </S.H4>

        {studentList && !newSearch && (
          <>
            <Table tableHead={tableHead}>
              {studentList.results && studentList.results.length > 0 ? (
                studentList.results.map((student, index) => {
                  return (
                    <S.TR
                      onClick={() => {
                        setOrder(student.id);
                        setDrawer(true);
                      }}
                      key={index}
                    >
                      <td>{student.matricula.estudante.nome}</td>
                      <td>{student.id}</td>
                      <td>{student.criado_em.substring(0, 10)}</td>
                      <td>R$ {splitPunctuation(student.valor_pago).comma}</td>
                      <td>
                        <Tag appearance={checkPaymentStatus(student.status)}>
                          {student.status}
                        </Tag>
                      </td>
                    </S.TR>
                  );
                })
              ) : (
                <S.H4 textStyle="notFound">{t(`${TRANSLATE}.notFound`)}</S.H4>
              )}
            </Table>
            {studentList.count > 10 ? (
              <Pagination
                data={studentList}
                offset={offset}
                values={paginate}
              />
            ) : null}
          </>
        )}

        {newSearch && <Loading />}

        {order && (
          <>
            <OrderDrawer
              drawer={drawer}
              setDrawer={setDrawer}
              order={order}
              setOrder={setOrder}
              setModal={setModal}
            />

            <CancelOrder
              open={modal}
              setOpen={setModal}
              order={order}
              setDrawer={setDrawer}
              setOrder={setOrder}
              setForm={setForm}
              endpoint={getList}
            />
          </>
        )}
      </S.ConsultFoodContainer>
    );
  }
};
