import styled from "styled-components";

export const RadioGroupContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  @media (max-width: 535px) {
    align-items: center;
    justify-content: center;
  }
`;

export const RadioOption = styled.label<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 16px;
  color: ${({ selected, theme }) =>
    selected ? `${theme.colors.white}` : `${theme.colors.black}`};
  background-color: ${({ selected, theme }) =>
    selected ? `${theme.colors.primaryDefault}` : `${theme.colors.white}`};
  padding: 8px;
  border: ${({ theme }) => `2px solid ${theme.colors.primaryDefault}`};
  width: 228px;
  height: 78px;

  &:hover {
    background-color: ${({ selected, theme }) =>
      !selected ? `${theme.colors.primaryLight90}` : ""};
  }
`;

export const RadioInput = styled.input`
  display: none;

  &:checked + span {
    color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.primaryDefault};
  }

  &:checked + span::before {
    border-color: ${({ theme }) => theme.colors.white};
  }

  & + span::before {
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    border-radius: ${({ theme }) => theme.radii.full};
    margin-left: 1px;
    margin-top: 1px;
  }

  &:checked + span::before {
    content: "";
    display: inline-block;
    border: ${({ theme }) => `2px solid ${theme.colors.primaryDefault}`};
    background: ${({ theme }) => theme.colors.white};
    width: 14px;
    height: 14px;
    border-radius: ${({ theme }) => theme.radii.full};
    margin-bottom: 2px;
  }
`;

export const RadioIcon = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: ${({ theme }) => theme.radii.full};
  border: 2px solid ${({ theme }) => theme.colors.primaryDefault};
  margin: 0px 5px 25px;
`;

export const RadioLabel = styled.span`
  margin-left: 10px;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;
  width: 150px;
`;

/* *** */
