import * as S from "./style";
import { useBulletin } from "context/Bulletin";
import { useTranslation } from "react-i18next";
import {
  ButtonFilterBulletin,
  SelectBulletin,
  Title,
  TableRow,
  TableColumn,
  Loading,
  Warning,
} from "components/atoms";
import { TableBulletin } from "components/molecules";
import { GroupButtons } from "components/molecules/GroupButtons";
import { useEffect } from "react";
import { useAtomValue } from "jotai";
import { authUserAtom } from "jotai/authUser";
import { changeLevelName, useDashboard } from "./dashboard.helper";

const prefix = "pages.AP.dashboard";

export const DashboardPage = () => {
  const { t } = useTranslation();

  const authUser = useAtomValue(authUserAtom);
  const {
    getStudentEvaluations,
    evaluationTypes,
    evaluationLoaded,
    selectedEvaluation,
    setSelectedEvaluation,
    studentList,
    studentEvaluations,
    loadingSchoolEvaluationTypes,
    loadingStudentEvaluations,
  } = useDashboard();
  const {
    quarter,
    loadingQuaters,
    levelName,
    loadingLevelName,
    grades,
    loadingCatchGrades,
    loadingSections,
    sections,
    handleSelectGrades,
    handleSelectLevelName,
    handleSelectSections,
    handleSelectQuater,
    handleDataGrades,
    reqTable,
  } = useBulletin();

  const dataAtual = new Date();
  const anoAtual = dataAtual.getFullYear();

  const studentSubjectValueExhibitionRule = (value: string) => {
    if (!value) {
      return "-";
    }

    if (value === "0.00") {
      return "0";
    }

    return value;
  };

  useEffect(() => {
    handleDataGrades({ unit: authUser.grade_unit_id, year: `${anoAtual}` });
  }, [anoAtual, authUser.grade_unit_id, handleDataGrades]);

  useEffect(() => {
    if (selectedEvaluation) {
      getStudentEvaluations({
        gradeGroup: reqTable.gradegroupId,
        quarterId: reqTable.quarterId,
        evaluationType: selectedEvaluation as number,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEvaluation]);

  const studentListFiltered =
    studentList &&
    studentList.students.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

  return (
    <S.Container>
      <S.Wrapper>
        <S.Section align="center" direction="row" justify="space-between">
          <Title
            fontWeight="700"
            lineHeight="1"
            center
            marginBottom="0"
            responsive
          >
            {t(`${prefix}.title`)}
          </Title>

          {/* SELECT DE TRIMESTRE */}
          {quarter.values.length > 0 && loadingQuaters === false && (
            <SelectBulletin
              maxWidth="146px"
              defaultValue={quarter.selected}
              placeholder="Trimestre"
              options={quarter?.values}
              onAction={(e) => handleSelectQuater(e)}
            />
          )}
        </S.Section>

        <S.Section align="center" direction="row" justify="space-between">
          <GroupButtons
            label="Ciclo"
            options={levelName.values && levelName.values}
            loading={loadingLevelName}
            quarter={false}
          >
            {levelName && // LEVEL NAME VEM DE USEBULLETIN
              levelName.values.map(
                (item: string, index) =>
                  item && (
                    <ButtonFilterBulletin
                      key={`${item}+${index}`}
                      active={levelName.selected === item ? true : false}
                      options={levelName.values}
                      onClick={() => {
                        handleSelectLevelName(item);
                        setSelectedEvaluation(0);
                      }}
                    >
                      {changeLevelName(item)}
                    </ButtonFilterBulletin>
                  )
              )}
          </GroupButtons>

          {/* ANO */}
          <GroupButtons
            label="Ano"
            options={grades.values && grades.values}
            loading={loadingCatchGrades}
            quarter={false}
          >
            {grades &&
              grades.values.map(
                (item: any, index) =>
                  item && (
                    <ButtonFilterBulletin
                      key={`${item}+${index}`}
                      active={grades.selected === item ? true : false}
                      options={grades.values}
                      onClick={() => {
                        handleSelectGrades(item);
                        setSelectedEvaluation(0);
                      }}
                    >
                      {item.concat("º")}
                    </ButtonFilterBulletin>
                  )
              )}
          </GroupButtons>

          {/* TURMA OU NADA ENCONTRADO */}
          {sections.values.length > 0 ? (
            <GroupButtons
              label="Turma"
              options={levelName.values && levelName.values}
              loading={loadingSections}
              quarter={false}
            >
              {sections.values.map(
                (item: any, index) =>
                  item && (
                    <ButtonFilterBulletin
                      key={`${item}+${index}`}
                      active={
                        sections?.selected?.letter === item.letter
                          ? true
                          : false
                      }
                      options={sections?.values}
                      onClick={() => {
                        handleSelectSections(item);
                        setSelectedEvaluation(0);
                      }}
                    >
                      {item.letter}
                    </ButtonFilterBulletin>
                  )
              )}
            </GroupButtons>
          ) : (
            <GroupButtons
              label="Turma"
              options={[]}
              loading={loadingSections}
              quarter
            >
              <ButtonFilterBulletin active={false} note>
                Nada encontrado
              </ButtonFilterBulletin>
            </GroupButtons>
          )}
        </S.Section>
        {/* DISCIPLINAS OU NADA ENCONTRADO */}

        {loadingSchoolEvaluationTypes ? (
          <Loading />
        ) : (
          <S.Section align="center" direction="row" justify="center">
            {evaluationTypes ? (
              <GroupButtons label="" loading={!evaluationLoaded} quarter>
                {evaluationTypes.map(
                  (item, index) =>
                    item && (
                      <ButtonFilterBulletin
                        key={`${item}+${index}`}
                        options={evaluationTypes}
                        active={selectedEvaluation === item.id ? true : false}
                        onClick={() => {
                          setSelectedEvaluation(item.id);
                        }}
                        quarter
                      >
                        {item.name}
                      </ButtonFilterBulletin>
                    )
                )}
              </GroupButtons>
            ) : (
              <Warning message="Nada encontrado" />
            )}
          </S.Section>
        )}

        {loadingStudentEvaluations ? (
          <Loading />
        ) : (
          <S.Section align="center" direction="column" justify="flex-start">
            {!studentEvaluations ? (
              <>
                {studentList === undefined ? (
                  <Warning message="Nada encontrado" />
                ) : (
                  <Loading />
                )}
              </>
            ) : (
              <>
                <S.Section
                  align="flex-start"
                  direction="column"
                  justify="flex-start"
                >
                  <Title
                    darkGray
                    fontSize="20px"
                    fontWeight="700"
                    lineHeight="1"
                    center
                    marginBottom="1rem"
                    responsive
                  >
                    {studentList && studentList.classe.name}
                  </Title>
                </S.Section>
                <TableBulletin
                  theadChildren={
                    <>
                      <TableRow
                        key="Estudantes"
                        item="Estudantes"
                        visible={{ mobile: true, desktop: true }}
                      />
                      {studentList &&
                        studentList.classe.subjects.map(
                          (item, index) =>
                            item && (
                              <TableRow
                                key={item}
                                item={item}
                                visible={{ mobile: true, desktop: true }}
                              />
                            )
                        )}
                    </>
                  }
                  tbodyChildren={
                    <>
                      {studentListFiltered &&
                        studentListFiltered.map((student) => (
                          <tr key={student.name}>
                            <TableColumn
                              item={student.name}
                              visible={{ mobile: true, desktop: true }}
                            />
                            {student.studentSubjects.map((subject) => (
                              <TableColumn
                                key={`${subject.name}-${subject.value}`}
                                item={studentSubjectValueExhibitionRule(
                                  subject.value
                                )}
                                visible={{ mobile: true, desktop: true }}
                              />
                            ))}
                          </tr>
                        ))}
                    </>
                  }
                />
              </>
            )}
          </S.Section>
        )}
      </S.Wrapper>
    </S.Container>
  );
};
