import { Alert } from "components/Alert";

interface IInvalidGradeAlertProps {
  invalidGradeNotice: string;
  title: string;
  content: string;
}
export const InvalidGradeAlert = ({
  invalidGradeNotice,
  title,
  content,
}: IInvalidGradeAlertProps) => {
  if (invalidGradeNotice === null) {
    return <></>;
  }

  return (
    <Alert.Root severity="error">
      <Alert.Title text={title} />
      <Alert.Content text={content} />
    </Alert.Root>
  );
};
