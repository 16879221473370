import styled from "styled-components";

export const CreditCardStyle = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1em;

  div.title {
    margin-top: 1.5em;
  }

  div.formContainer {
    div.firstInput {
      margin-top: 1.5em;
    }
    div.othersInputs {
      margin-top: 0.5em;
    }
    div.sameLineInput {
      display: flex;
      justify-content: space-between;
      margin-top: 0.5em;

      div.inputInLine {
        width: 48%;
      }
    }

    div.valueTotal {
      margin-top: 3em;

      display: flex;
      justify-content: space-between;
    }

    div.buttons {
      margin-top: 5em;
      display: flex;
      justify-content: space-between;
    }
  }
`;
