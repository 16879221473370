import styled from "styled-components";

export const GuardianBoxStyled = styled.div`
  padding: 1em;
  background-color: #f2f2f2;
  border-radius: 10px;

  div.title {
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    padding-bottom: 1em;

    span {
      margin-left: 0.5em;
      font-size: 12px;
      color: green;
      border: 1px solid green;
      padding: 0.3em;
      border-radius: 4px;
      &.didactic {
        color: #4285f4;
        border: 1px solid #4285f4;
      }
    }
  }

  div.link {
    cursor: pointer;
    color: #4285f4;
    &:hover {
      text-decoration: underline;
    }
  }
`;
