import styled from "styled-components";
import * as colors from "styles/colors";

export const Container = styled.div`
  width: 100%;
  margin-top: 16px;

  display: flex;
  flex-direction: column;

  label {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    color: ${colors.NEW_GRAY_SCALE};

    input {
      border: none;
      border-bottom: 2px solid ${colors.GRAY_SCALE};
      outline: none;
      padding: 8px;
    }
  }

  label.errorLabel {
    margin-bottom: 5px;
  }

  .inputGroup {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  div + div {
    margin-top: 8px;
  }
`;
