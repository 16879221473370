import { FieldValues } from "react-hook-form";
import { IInputText } from "./interface";
import * as S from "./style";
import ReactInputMask from "react-input-mask";

// TODO: input criado para utilizar com react-hook-form no primeiro momento
// TODO: melhorar tipagem depois para ser utilizado tambem sem o hook-form
// TODO: enviar para a lib ou melhorar o que contem na lib
export const InputText = <T extends FieldValues>({
  label,
  width = 309,
  icon,
  id,
  register,
  error,
  handleChange,
  mask,
  ...props
}: IInputText<T>) => {
  return (
    <S.InputTextContainer width={width} disabled={props.disabled}>
      <label htmlFor={id}>{label}</label>
      <div>
        {handleChange ? (
          <input
            type="text"
            {...props}
            {...register(id)}
            onChange={handleChange}
          />
        ) : mask ? (
          <ReactInputMask mask={mask} {...props} {...register(id)} />
        ) : (
          <input type="text" {...props} {...register(id)} />
        )}

        {icon ? icon : null}
      </div>
      {error && <span>{error}</span>}
    </S.InputTextContainer>
  );
};
