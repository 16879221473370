import { FieldRenderProps } from "react-final-form";
import { SelectStyled } from "./style";

type Props = FieldRenderProps<string, any>;

const SelectInput: React.FC<Props> = ({ input, meta, ...rest }: Props) => (
  <SelectStyled {...input} {...rest} />
);

export default SelectInput;
