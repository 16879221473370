import styled from "styled-components";
import { BLUE } from "styles/colors";

export const FoodOptionStyle = styled.div`
  font-family: "Poppins";
  font-style: normal;

  display: flex;
  justify-content: center;
`;
