import { Button, Modal } from "components";
import {
  isVendor,
  isCX,
  isEnrollmentManager,
  isDirector,
  isOperationalDirector,
  isStudyRoomEducator,
} from "helpers/constants";
import { formatCEP, formatCPF, formatDate } from "helpers/helpers";
import { StudentInterface } from "interfaces/constants";
import { ReactElement, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Placeholder from "static/img/placeholder.png";
import { Avatar, AvatarContainer, BoldBigger, BorderContainer } from "./styles";
import { ModalTotvsSent } from "components/Modals";
import { Storage } from "Storage";
import { EnrollmentTypes } from "enums/enrollment";

interface Props {
  student: StudentInterface;
  reduced?: boolean;
}

export const StudentFrame = ({ student, reduced }: Props): ReactElement => {
  const role = Storage.props.role.get();

  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const guardianId = urlParams.get("guardianId");
  const outOfApp = urlParams.get("outofapp") === "true";

  const [disabledEditButton, setDisabledEditButton] = useState(true);
  const [totvsSent, setTotvsSent] = useState(false);
  const [openModalTotvsSent, setOpenModalTotvsSent] = useState(false);

  const reenrollment =
    student.last_enrollment.type === EnrollmentTypes.Reenrollment;

  useEffect(() => {
    if (isVendor(role) && reenrollment) {
      setDisabledEditButton(false);
    }
    if (isCX(role) && !reenrollment) {
      setDisabledEditButton(false);
    }

    if (student) {
      if (student.last_enrollment) {
        if (student.last_enrollment.totvs_sent) {
          setTotvsSent(!isEnrollmentManager(role));
        }
      }
    }
  }, [student, role, reenrollment]);

  const totvsSentIsTrue = () => {
    setOpenModalTotvsSent(true);
  };

  return (
    <BorderContainer className="container">
      <div className="row align-items-center">
        <AvatarContainer>
          <div>
            <Avatar src={Placeholder} />
          </div>
          <div className="text-center">
            <strong>{student.name}</strong>
          </div>
        </AvatarContainer>
      </div>
      <div className="row mt-3">
        <div className="col-12 text-center">
          <BoldBigger>Informações de Cadastro</BoldBigger>
        </div>
        {!reduced && (
          <>
            <div className="col-12">
              <strong>Data de Nascimento: </strong>{" "}
              {student.birth_date
                ? formatDate(student.birth_date)
                : "Não possui data de nascimento"}
            </div>
            <div className="col-12">
              <strong>RG: </strong> {student.rg || "Não possui RG"}
            </div>
            <div className="col-12">
              <strong>CPF: </strong>{" "}
              {student.cpf ? formatCPF(student.cpf) : "Não possui CPF"}
            </div>
          </>
        )}
        <div className="col-12">
          <strong>Escola: </strong>{" "}
          {student.last_enrollment?.unit_name || "Não possui Escola"}
        </div>
        {student.last_enrollment && (
          <div className={`col-12 ${reduced ? `mb-4` : ``}`}>
            <strong>Ano: </strong> {student.last_enrollment.grade || "Sem ano"}
            {student.last_enrollment.grade && "º ano do "}
            {student.last_enrollment.educational_level_name}
          </div>
        )}
      </div>
      {!reduced && (
        <>
          <div className="row mt-3 mb-3 ">
            <div className="col-12 text-center">
              <BoldBigger>Endereço</BoldBigger>
            </div>
            <div className="col-12">
              <strong>CEP: </strong>
              {student.zip_code
                ? formatCEP(student.zip_code)
                : "Não possui CEP"}
            </div>
            <div className="col-12">
              <strong>Endereço: </strong>
              {student.address
                ? `${student.address}, nº ${student.number}`
                : `Endereço não informado`}
            </div>
            <div className="col-12">
              <strong>Complemento: </strong>
              {student.complement || "Não possui complemento"}
            </div>
            <div className="col-12">
              <strong>Bairro: </strong>
              {student.neighborhood || "Não possui bairro"}
            </div>
            <div className="col-12">
              <strong>Cidade: </strong>
              {student.city || "Não possui cidade"}
            </div>
            <div className="col-12">
              <strong>UF: </strong>
              {student.uf || "Não possui UF"}
            </div>
          </div>
        </>
      )}
      <div className="row justify-content-center">
        {!isDirector(role) &&
          !isOperationalDirector(role) &&
          !isStudyRoomEducator(role) && (
            <div className="col-10">
              {isVendor(role) && reenrollment && (
                <Button className="green">
                  Aluno matriculado no ano corrente
                </Button>
              )}
              {isCX(role) && !reenrollment && (
                <Button className="green">
                  Aluno de matrícula para o proximo ano
                </Button>
              )}
              {totvsSent && (
                <Button className="green" onClick={() => totvsSentIsTrue()}>
                  Aluno já enviado para totvs
                </Button>
              )}
              {disabledEditButton && !totvsSent && (
                <Button
                  onClick={() => {
                    history.push(
                      `/responsavel/meus-alunos/aluno?studentId=${
                        student.id
                      }&guardianId=${guardianId}${
                        outOfApp ? "&outofapp=true" : ""
                      }`
                    );
                  }}
                >
                  Editar
                </Button>
              )}
            </div>
          )}
      </div>
      <Modal setToggleModal={setOpenModalTotvsSent} isOpen={openModalTotvsSent}>
        <ModalTotvsSent setToggleModal={setOpenModalTotvsSent} />
      </Modal>
    </BorderContainer>
  );
};
