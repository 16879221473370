import { HubspotPostInterface } from "interfaces/hubspot";
import { KanataAPI } from "./api";
import { ErrorHandlerFunction } from "./service-helpers";

interface HubspotLeadPost {
  body: {
    student_name: string;
    guardian_first_name: string;
    guardian_surname: string;
    phone: string;
    email: string;
    unit: number;
    grade: number;
    educational_level?: number;
    eula?: boolean;
  };
}

export const hubspotLead = async ({ body }: HubspotLeadPost) => {
  const response = await KanataAPI.post<HubspotPostInterface>(
    `financial/hubspot/lead/`,
    body
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const checkValidReferenceCode = async (reference_code: string) => {
  const response = await KanataAPI.get(
    `financial/check_reference_code/${reference_code}`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};
