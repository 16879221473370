// React
import { useState } from "react";
// Style
import * as S from "./style";
// Components
import { Loading } from "components";
import { TableWorkingDays } from "./components/Table";
import { Button } from "components/atoms";
// Helpers
import { WorkingDaysHelpers } from "./helpers";
import { MONTHS } from "helpers/constants";
// Interfaces
import { ISelect } from "./interface";
// Libs
import { useTranslation } from "react-i18next";

export const WorkingDaysRegister = () => {
  const { t } = useTranslation();
  const prefix = "pages.EDF.workingDaysRegister";

  const [select, setSelect] = useState<ISelect>({
    unit: "",
    year: "",
    month: "",
  });

  const {
    loading,
    error,
    units,
    years,
    workingDays,
    reloading,
    handleSubmit,
    setWorkingDays,
  } = WorkingDaysHelpers({
    select,
  });

  if (loading) {
    return (
      <S.LoadContainer>
        <h4>{t(`${prefix}.initialLoading`)}</h4>
        <Loading />
      </S.LoadContainer>
    );
  }

  if (error && !loading) {
    return (
      <S.LoadContainer>
        <div>{t(`${prefix}.error`)}</div>
      </S.LoadContainer>
    );
  }

  if (units && years) {
    return (
      <S.BusinessDayStyle>
        <h1>{t(`${prefix}.title`)}</h1>
        <form onSubmit={handleSubmit}>
          <div>
            <label>{t(`${prefix}.unitLabel`)}</label>
            <select
              onChange={(e) => {
                setSelect({ ...select, unit: e.target.value });
                setWorkingDays(null);
              }}
              required
              name="unit"
              value={select.unit}
              className={select.unit && `hasValue`}
            >
              <option value="">{t(`${prefix}.defaultSelect`)}</option>
              {units.map((unit, index) => {
                return (
                  <option value={unit.id} key={index}>
                    {unit.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div>
            <label>{t(`${prefix}.yearLabel`)}</label>
            <select
              onChange={(e) => {
                setSelect({ ...select, year: e.target.value });
                setWorkingDays(null);
              }}
              required
              name="year"
              value={select.year}
              className={select.year && `hasValue`}
            >
              <option value="">{t(`${prefix}.defaultSelect`)}</option>
              {years.results.map((year, index) => {
                return (
                  <option value={year.value} key={index}>
                    {year.label}
                  </option>
                );
              })}
            </select>
          </div>
          <div>
            <label>{t(`${prefix}.monthLabel`)}</label>
            <select
              onChange={(e) => {
                setSelect({ ...select, month: e.target.value });
              }}
              required
              name="month"
              value={select.month}
              className={select.month && `hasValue`}
            >
              <option value="">{t(`${prefix}.defaultSelect`)}</option>
              {MONTHS.map((months, index) => {
                return (
                  <option value={months.id} key={index}>
                    {months.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div>
            <label>{t(`${prefix}.daysLabel`)}</label>
            <input
              type="number"
              id="days"
              placeholder="Dias úteis"
              name="days"
              required
              maxLength={2}
            />
          </div>
          <Button height="2.5rem" width="15%" type="submit">
            {t(`${prefix}.buttonSave`)}
          </Button>
        </form>

        {workingDays && (
          <>
            {workingDays.length > 0 ? (
              <TableWorkingDays workingDays={workingDays} />
            ) : (
              <div className="nofoundDiv">
                <strong className="nofound">
                  {t(`${prefix}.noDataFound`)}
                </strong>
              </div>
            )}
          </>
        )}

        {reloading && (
          <div className="reloading">
            <Loading />
          </div>
        )}
      </S.BusinessDayStyle>
    );
  }
};
