import { InputFileRoot } from "./InputFileRoot";
import { InputFileLabel } from "./InputFileLabel";
import { InputFile } from "./InputFile";
import { InputFileText } from "./InputFileText";
import { InputFileIcon } from "./InputFileIcon";
import { InputFileTextInformation } from "./InputFileTextInformation";
import { InputFileError } from "./InputFileError";

export const NewInputFile = {
  Root: InputFileRoot,
  Label: InputFileLabel,
  Input: InputFile,
  Text: InputFileText,
  Icon: InputFileIcon,
  TextInformation: InputFileTextInformation,
  Error: InputFileError,
};
