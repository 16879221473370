import styled from "styled-components";
import { size } from "styles/breakpoints";
import * as colors from "styles/colors";

export const Container = styled.div`
  max-width: 992px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;

  padding: ${({ theme }) => theme.space[6]} ${({ theme }) => theme.space[4]};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Heading = styled.h1`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600; // TODO add to lib
  font-size: 40px; // TODO add to lib
  line-height: ${({ theme }) => theme.lineHeights.tallest};
  color: ${({ theme }) => theme.colors.primaryDefault};

  @media (max-width: ${size.sm}) {
    font-size: ${({ theme }) => theme.fontSizes.mobile.h1};
  }
`;

export const YearSelector = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[2]};

  strong {
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    font-size: ${({ theme }) => theme.fontSizes.web.sm};
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.greyScale95};
  }

  select {
    width: 147px;
    height: 37px;
    border: 2px solid ${({ theme }) => theme.colors.greyScale40};
  }
`;

export const LoadingTableContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ theme }) => theme.space[6]} 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin: ${({ theme }) => theme.space[6]} 0;
`;
