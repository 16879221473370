// React
import { useRef, useState } from "react";
// Style
import * as Style from "./style";
// Components
import { Text } from "components/atoms";
// Hooks
import { useClickOutside } from "hooks/useClickOutside";
// Interfaces
import { FloatingMenuInterface } from "./interface";
// Icon
import OptionSvg from "static/img/options.svg";
import OptionVerticalSvg from "static/img/optionsVertical.svg";

export const FloatingMenu = ({
  open,
  options,
  onChangeOption,
  withVacancy,
  vertical,

  width,
  top,
  left,
  right,
  marginLeft,

  ...rest
}: FloatingMenuInterface) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleToggleMenu = () => {
    setIsMenuOpen(true);
  };

  const onClickOutside = () => {
    setIsMenuOpen(false);
  };

  useClickOutside(menuRef, onClickOutside);

  return (
    <div ref={menuRef}>
      <Style.ButtonStyled onClick={handleToggleMenu} disabled={withVacancy}>
        {vertical ? (
          <img src={OptionVerticalSvg} height={13} alt="option-icon" />
        ) : (
          <img src={OptionSvg} height={4} alt="option-icon" />
        )}
      </Style.ButtonStyled>
      {isMenuOpen && (
        <Style.FloatingMenuStyled
          width={width}
          top={top}
          left={left}
          right={right}
          marginLeft={marginLeft}
          {...rest}
        >
          <div>
            {options &&
              options.map((choice, index) => {
                return (
                  <Text
                    onClick={(e) => {
                      onChangeOption(choice.value);
                    }}
                    id={choice.label}
                    key={index}
                  >
                    {choice.label}
                  </Text>
                );
              })}
          </div>
        </Style.FloatingMenuStyled>
      )}
    </div>
  );
};
