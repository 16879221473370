import { formatCEP, formatCPF, formatDate, formatPhone } from "helpers/helpers";
import { GetCXUserJson } from "interfaces/constants";
import { UserDataDiv } from "./style";

type GetCXUserJsonType = {
  user?: GetCXUserJson;
};

export const UserData = ({ user }: GetCXUserJsonType) => {
  return user ? (
    <div className="row w-100">
      <UserDataDiv className="col-md-6 col-12">
        <p className="title">Dados pessoais</p>
        <p>
          <span>Nome: </span>
          {user.personal?.name}
        </p>
        <p>
          <span>E-mail: </span>
          {user.contact?.email || "Não possui e-mail"}
        </p>
        <p>
          <span>Data de nascimento: </span>
          {user.personal?.birth_date
            ? formatDate(user.personal?.birth_date)
            : "Não possui data de nascimento"}
        </p>
        <div className="d-flex">
          <p>
            <span>RG: </span>
            {user.personal?.rg || "Não possui RG"}
          </p>
          <p className="ml-3">
            <span>CPF: </span>
            {user.personal?.cpf
              ? formatCPF(user.personal?.cpf)
              : "Não possui CPF"}
          </p>
        </div>
        <p>
          <span>Telefone: </span>
          {user.contact?.phone
            ? formatPhone(user.contact?.phone)
            : "Não possui telefone"}
        </p>
      </UserDataDiv>
      <UserDataDiv className="col-md-6 col-12">
        <p className="title">Localização</p>
        <p>
          <span>CEP: </span>
          {user.location?.zip_code
            ? formatCEP(user.location?.zip_code)
            : "Não possui CEP"}
        </p>
        <p>
          <span>Endereço: </span>
          {user.location?.address}, nº {user.location?.number}
        </p>
        <p>
          <span>Bairro: </span>
          {user.location?.neighborhood || "Não possui bairro"}
        </p>
        <div className="d-flex">
          <p>
            <span>Cidade: </span>
            {user.location?.city || "Não possui cidade"}
          </p>
          <p className="ml-3">
            <span>UF: </span>
            {user.location?.uf || "Não possui UF"}
          </p>
        </div>
        <p>
          <span>Complemento: </span>
          {user.location?.complement || "Não possui complemento"}
        </p>
      </UserDataDiv>
    </div>
  ) : (
    <div className="ml-4">Carregando dados do usuário...</div>
  );
};
