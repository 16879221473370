import { useState } from "react";
import { PublicNavHeader, TextInput, Button } from "components";
import { Field, Form } from "react-final-form";
import {
  composeValidators,
  emailValidation,
  required,
} from "helpers/fieldValidators";
import { toastSuccess } from "components/Toast";
import loadingGif from "static/img/loading.gif";
import { forgottenPassword } from "services/auth.service";

interface FormData {
  email: string;
}

export const PublicPasswordRecovery = () => {
  const [loading, setLoading] = useState(false);
  const onSubmit = async (data: FormData) => {
    setLoading(true);
    await forgottenPassword(data.email);
    toastSuccess(
      `Pronto! Se seu email estiver cadastrado no portal, você receberá uma nova senha!`
    );
    setLoading(false);
  };

  return (
    <div>
      <PublicNavHeader outOfApp={false}>Esqueci minha senha</PublicNavHeader>
      <hr />
      <div className="mt-5" />
      <div className="container">
        <h1 className="text-center">Recuperação de Senha</h1>
        <p className="text-center">
          Informe o seu e-mail cadastrado no portal que enviaremos uma nova
          senha para você
        </p>
        <Form onSubmit={onSubmit}>
          {({ form, handleSubmit, submitting, values }) => (
            <form onSubmit={handleSubmit}>
              <div className="row mb-2 justify-content-center">
                <div className="col-6 text-center">
                  <Field
                    component={TextInput}
                    name="email"
                    type="text"
                    placeholder=" "
                    label="Email"
                    maxLength="255"
                    validate={composeValidators(required, emailValidation)}
                    required
                  />
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-6">
                  <Button
                    className="text-center"
                    type="submit"
                    disabled={submitting || loading}
                  >
                    {(!submitting || !loading) && "Enviar nova Senha"}
                    {(submitting || loading) && (
                      <img height="25" src={loadingGif} alt="loading" />
                    )}
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  );
};
