import { AlertContent } from "./AlertContent";
import { AlertIcon } from "./AlertIcon";
import { AlertRoot } from "./AlertRoot";
import { AlertTitle } from "./AlertTitle";

export const Alert = {
  Root: AlertRoot,
  Title: AlertTitle,
  Content: AlertContent,
  Icon: AlertIcon,
};
