import { Loading } from "components";
import {
  Container,
  Header,
  Heading,
  LoadingTableContainer,
  YearSelector,
} from "./styles";
import { useTranslation } from "react-i18next";
import { useClassCouncil } from "./ClassCouncil.helper";

import { StudentsTable } from "./components/StudentsTable";
import { NoStudentsEmptyState } from "./components/NoStudentsEmptyState";

export const ClassCouncil = () => {
  const prefix = "pages.AP.classCouncil";
  const { t } = useTranslation();
  const {
    isloadingPage,
    isloadingStudents,
    years,
    handleSelectYear,
    selectedYear,
    students,
  } = useClassCouncil();

  const hasStudentList = students && students?.length > 0;

  if (isloadingPage) {
    return <Loading />;
  }

  return (
    <Container>
      <Header>
        <Heading>{t(`${prefix}.heading`)}</Heading>
        <YearSelector>
          <select
            onChange={handleSelectYear}
            name="publication-year"
            value={selectedYear}
          >
            {years?.map((year) => (
              <option key={`year-${year}`} value={String(year)}>
                {year}
              </option>
            ))}
          </select>
        </YearSelector>
      </Header>

      {isloadingStudents && (
        <LoadingTableContainer>
          <Loading />
        </LoadingTableContainer>
      )}

      {!isloadingStudents && hasStudentList && (
        <StudentsTable students={students} />
      )}

      {!isloadingStudents && !hasStudentList && <NoStudentsEmptyState />}
    </Container>
  );
};
