import styled from "styled-components";
import { device } from "../../../styles/breakpoints";

export const Container = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  @media ${device.xss} {
    padding: 24px 12px;
  }
  @media ${device.md} {
    padding: 24px 100px;
  }
`;
export const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  border-radius: 30px;
  position: relative;
  min-height: 650px;
  &:last-child {
    justify-content: flex-start;
  }
`;

export const TituloContainer = styled.div`
  font-family: "Poppins", serif;
  font-style: normal;
  font-weight: 700;
  line-height: 54px;
  color: #6d28d9 !important;
  width: 100%;

  .MuiIconButton-label {
    color: #6d28d9 !important;
  }

  .MuiIconButton-root {
    color: #6d28d9 !important;
  }

  @media ${device.xss} {
    font-size: 24px;
  }
  @media ${device.md} {
    font-size: 36px;
  }
`;

export const SubtituloContainer = styled.div`
  font-family: "Poppins", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  color: #fd8420;
  width: 100%;
  @media ${device.xss} {
    font-size: 20px;
  }
  @media ${device.md} {
    font-size: 24px;
  }
`;

export const SelectAnoBoleto = styled.div`
  .select {
    box-sizing: border-box;
    width: 100px;
    height: 37px;
    background: #ffffff;
    border: 2px solid #999999;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    @media ${device.lg} {
      width: 130px;
    }
  }
`;
export const Lista = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
`;
export const Localizador = styled.div`
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  height: 18px;
  font-family: "Poppins", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #999999;
  margin-bottom: 2rem;
  svg {
    width: 18px;
    height: 18px;
  }
  span {
    color: #6d28d9;
    font-weight: 700;
  }
  @media ${device.xss} {
    display: none;
  }
  @media ${device.xs} {
    display: none;
  }
  @media ${device.md} {
    display: flex;
  }
`;
export const TituloContainerBoletos = styled.div`
  font-family: "Poppins", serif;
  font-style: normal;
  font-weight: 700;
  display: flex;
  align-items: center;
  color: #6d28d9;
  span {
    margin-left: 0.5rem;
  }
  @media ${device.xss} {
    font-size: 24px;
    line-height: 36px;
  }
  @media ${device.xs} {
    display: flex;
  }
  @media ${device.md} {
    font-size: 36px;
    line-height: 54px;
  }
`;

export const ContainerStu = styled.div`
  font-family: "Poppins", serif;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 1rem;

  @media ${device.xss} {
    img {
      width: 70px;
      height: 70px;
      border-radius: 50%;
    }

    strong {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 30px;
      display: flex;
      align-items: center;
      color: #fd8420;
      width: 100%;
      height: 30px;
      margin: ${({ theme }) => theme.space[4]} 0;
    }
  }

  @media ${device.md} {
    img {
      width: 81px;
      height: 81px;
      border-radius: 50%;
    }

    strong {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 48px;
      display: flex;
      align-items: center;
      color: #fd8420;
    }

    p {
      font-family: "Poppins", serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0d0d0d;

      span {
        margin-left: 20px;
      }
    }
  }
`;
export const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  @media ${device.xss} {
    flex-wrap: wrap;
  }
  margin-top: 2rem;
`;
export const ListaTitulo = styled.div`
  height: 30px;
  font-family: "Poppins", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #0d0d0d;
  display: flex;
  justify-content: flex-start;
  @media ${device.xss} {
    margin: 1rem 0.5rem;
    width: 100%;
    flex: 1;
  }

  @media ${device.md} {
    margin: 1rem;
    width: 404px;
    flex: none;
    order: 0;
    flex-grow: 1;
  }
`;
export const Filtros = styled.div`
  font-family: "Poppins", serif;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  @media ${device.xss} {
    label {
      font-weight: 400 !important;
    }
    .radioMobileFiltro {
      display: flex;
      gap: 8px;
    }
    .radioFiltro {
      display: none;
    }
    .flex-1 {
      width: 50%;
      font-weight: 700;
      font-size: 20px;
    }
    .flex-2 {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 400;
      margin-top: ${({ theme }) => theme.space[8]};
    }
    .flex-3 {
      width: 50%;
      justify-content: end;
      align-items: center;
      display: none;
    }
    .flex-4 {
      width: 50%;
      justify-content: end;
      align-items: center;
      display: flex;
    }
  }
  @media ${device.md} {
    label {
      font-weight: normal !important;
    }
    .radioMobileFiltro {
      display: none;
    }
    .radioFiltro {
      display: flex;
      gap: 8px;
    }
    .flex-1 {
      width: 30%;
    }
    .flex-2 {
      width: 50%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: normal;
    }
    .flex-3 {
      width: 20%;
      display: flex;
      justify-content: end;
      align-items: center;
    }
    .flex-4 {
      width: 50%;
      justify-content: end;
      align-items: center;
      display: none;
    }
  }
`;

export const SemDados = styled.div`
  font-family: "Poppins", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #999999;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
  @media ${device.xss} {
    display: none;
  }
  @media ${device.md} {
    display: flex;
  }
`;

export const SemDadosMobile = styled.div`
  font-family: "Poppins", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #999999;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh;
  @media ${device.xss} {
    display: flex;
  }
  @media ${device.md} {
    display: none;
  }
`;
export const Filtro = styled.div`
  font-family: "Poppins", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #0d0d0d;
  flex: none;
  order: 1;
  flex-grow: 0;
  @media ${device.xss} {
    margin: 1rem 0.5rem;
    width: 90px;
  }
  @media ${device.xs} {
    display: flex;
  }
  @media ${device.md} {
    display: flex;
    width: 130px;
    height: 21px;
  }
`;
export const ListaBoleto = styled.div`
  font-family: "Poppins", serif;
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media ${device.xss} {
    margin: 1rem 0;
    display: none;
  }
  @media ${device.md} {
    display: flex;
  }
`;
export const ListaBoletoItemHead = styled.div`
  font-family: "Poppins", serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: #0d0d0d;
  width: 100%;
  flex: 1;
  margin-left: 1rem;
`;
export const Sparador = styled.div`
  width: 100%;
  background: #838282;
  @media ${device.xss} {
    height: 0;
  }
  @media ${device.md} {
    height: 1px;
  }
`;
export const ListaBoletoItem = styled.div`
  font-family: "Poppins", serif;
  font-style: normal;
  box-sizing: border-box;
  background: #ffffff;
  border-bottom: 1px solid #b694ec;
  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  height: 64px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 4px;
  @media ${device.xss} {
    display: none;
  }
  @media ${device.xs} {
    display: none;
  }
  @media ${device.md} {
    display: flex;
  }

  .loadingPdf {
    background-color: rgba(62, 81, 101, 0.66);
    z-index: 100;
    cursor: move;
    width: 100%;
    height: auto;
  }
`;
export const ListaBoletoItemMobile = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 200px;
  left: 10px;
  top: 358px;
  background: #ffffff;
  border: 1px solid #b694ec;
  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  @media ${device.md} {
    display: none;
  }
`;
export const ListaBoletoItemBodyMobile = styled.div`
  display: flex;
  margin-left: 1rem;
  align-items: center;
  gap: ${({ theme }) => theme.space[2]};

  @media ${device.md} {
    display: none;
  }

  div {
    margin-top: 0.5rem;
  }

  p {
    font-size: 23px;
    line-height: 24px;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
  }
`;
export const ListaBoletoItemBody = styled.div`
  font-family: "Poppins", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  color: #0d0d0d;
  width: 100%;
  flex: 1;
  margin-left: 1rem;
`;

export const Pago = styled.div`
  font-family: "Poppins", serif;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 8px;
  gap: 4px;
  width: 53px;
  height: 29px;
  left: 562px;
  top: 18px;
  background: #3ba550;
  border-radius: 4px;

  span {
    width: 37px;
    height: 21px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
`;
export const Cancelado = styled.div`
  font-family: "Poppins", serif;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 8px;
  gap: 4px;
  width: 95px;
  height: 32px;
  left: 562px;
  top: 18px;
  background: #afacac;
  border-radius: 4px;

  span {
    width: 74px;
    height: 21px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #0d0d0d;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
`;
export const Atrasado = styled.div`
  font-family: "Poppins", serif;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 8px;
  gap: 4px;

  width: 84px;
  height: 29px;
  background: #b50303;
  border-radius: 4px;

  span {
    width: 74px;
    height: 21px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
`;
export const EmAberto = styled.div`
  font-family: "Poppins", serif;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 8px;
  gap: 4px;
  width: 90px;
  height: 29px;
  left: 562px;
  top: 18px;
  background: #f9c339;
  border-radius: 4px;

  span {
    width: 74px;
    height: 21px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    color: #0d0d0d;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
`;
export const CopiarMobile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  span {
    font-family: "Poppins", serif;
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    text-decoration-line: underline;
    text-align: right;
    color: #6d28d9;
  }

  button {
    padding: 6px;
  }
`;
export const Copiar = styled.div`
  height: 50px;
  width: 120px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-family: "Poppins", serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #0d0d0d;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }

  button {
    padding: 6px;
  }
  .MuiIconButton-root:hover {
    background: none !important;
  }
  @media ${device.xss} {
    margin: 1rem 0.5rem;
  }
`;
export const Paginator = styled.div`
  display: flex;
  width: 100%;
  position: absolute;
  left: 0px;

  .MuiSelect-root,
  .MuiInputBase-root {
    display: none;
  }

  .MuiTablePagination-selectRoot {
    margin-right: 0rem;
  }

  .MuiTablePagination-actions {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  @media ${device.xss} {
    justify-content: center;
    bottom: -45px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }
  @media ${device.xs} {
    justify-content: center;
    bottom: -45px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }
  @media ${device.md} {
    justify-content: flex-end;
    bottom: 0px;
  }
`;

export const AlertContainer = styled.div`
  margin: ${({ theme }) => theme.space[10]} 0;
`;
