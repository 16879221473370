import styled from "styled-components";
import * as colors from "styles/colors";

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1360px;

  padding: 0.5rem;
  margin: 0.5rem;
`;

interface SectionProps {
  direction: string;
  justify: string;
  align: string;
}

export const Section = styled.div<SectionProps>`
  width: 100%;

  margin: 1rem 0;

  display: flex;
  flex-direction: ${(props) => props.direction};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.align};
`;
