// React
import { useState } from "react";
// Styles
import * as S from "./style";
// Libs
import { Button } from "@escolamais-ui-v2/react";
import { useTranslation } from "react-i18next";
// Components
import { Drawer } from "components/organisms/Drawer";
import { InputDate } from "components";
// Interfaces
import { IDrawerRegistrationSalesPeriod, IPeriod } from "./interface";
// Helpers
import { HDrawerRegistrationSalesPeriod } from "./helper";

export const DrawerRegistrationSalesPeriod = ({
  drawer,
  setDrawer,
  unitInfo,
}: IDrawerRegistrationSalesPeriod) => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.periodEnrollmentReenrollment.components.drawer";

  const [period, setPeriod] = useState<IPeriod | undefined>(undefined);

  const { loading, handleUpdate } = HDrawerRegistrationSalesPeriod({
    id: unitInfo.id,
    period,
    setPeriod,
    setDrawer,
  });

  return (
    <Drawer
      isOpen={drawer}
      onClose={() => {
        setDrawer(false);
        setPeriod(undefined);
      }}
      title={t(`${TRANSLATE}.drawerTitle`, { unit: unitInfo.unidade_nome })}
      side="right"
      width="632px"
    >
      <S.DrawerRegistrationSalesPeriodStyled>
        <S.PeriodInput>
          <h4>{t(`${TRANSLATE}.periodEnrollment`, { year: unitInfo.ano })}</h4>
          <div className="calendar">
            <InputDate
              label={t(`${TRANSLATE}.inputStartDate`)}
              marginTop={17}
              value={
                period?.inicio_vendas_matricula ||
                unitInfo.inicio_vendas_matricula ||
                ""
              }
              onChange={(e) => {
                setPeriod({
                  ...period,
                  inicio_vendas_matricula: e.target.value,
                });
              }}
            />
            <InputDate
              label={t(`${TRANSLATE}.inputFinalDate`)}
              marginTop={17}
              value={
                period?.termino_vendas_matricula ||
                unitInfo.termino_vendas_matricula ||
                ""
              }
              onChange={(e) => {
                setPeriod({
                  ...period,
                  termino_vendas_matricula: e.target.value,
                });
              }}
            />
          </div>
        </S.PeriodInput>

        <S.PeriodInput>
          <h4>{t(`${TRANSLATE}.periodRenrollment`, { year: unitInfo.ano })}</h4>
          <div className="calendar">
            <InputDate
              label={t(`${TRANSLATE}.inputStartDate`)}
              marginTop={17}
              value={
                period?.inicio_vendas_rematricula ||
                unitInfo.inicio_vendas_rematricula ||
                ""
              }
              onChange={(e) => {
                setPeriod({
                  ...period,
                  inicio_vendas_rematricula: e.target.value,
                });
              }}
            />
            <InputDate
              label={t(`${TRANSLATE}.inputFinalDate`)}
              marginTop={17}
              value={
                period?.termino_vendas_rematricula ||
                unitInfo.termino_vendas_rematricula ||
                ""
              }
              onChange={(e) => {
                setPeriod({
                  ...period,
                  termino_vendas_rematricula: e.target.value,
                });
              }}
            />
          </div>
        </S.PeriodInput>

        <S.ButtonContainer>
          <Button
            appearance="secondary"
            buttonText={t(`${TRANSLATE}.buttonCancel`)}
            onClick={() => {
              setDrawer(false);
              setPeriod(undefined);
            }}
            size="large"
          />
          <Button
            appearance="primary"
            buttonText={t(`${TRANSLATE}.buttonSave`)}
            onClick={() => {
              handleUpdate();
            }}
            disabled={loading}
            size="large"
          />
        </S.ButtonContainer>
      </S.DrawerRegistrationSalesPeriodStyled>
    </Drawer>
  );
};
