import { LinearProgress } from "@material-ui/core";
import { Button, Modal, RadioButton, TextInput, LoadingBox } from "components";
import { CalendarComponent } from "components/Calendar";
import SelectInput from "components/Select";
import { toastError, toastSuccess } from "components/Toast";
import { isCX, PAYMENT_STATUS, SCHOOL_PLACES_STATUS } from "helpers/constants";
import { formatMoney, getDomain } from "helpers/helpers";
import HTTP_STATUS from "http-status-codes";
import {
  EnrollmentYearsInterface,
  GetCXUserJson,
  UnitDetailELInterface,
} from "interfaces/constants";
import { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { useHistory } from "react-router-dom";
import {
  createEnrollmentReserve,
  updateEnrollment,
} from "services/enrollment.service";
import {
  getIuguIDStatus,
  updatePayStatusOutOfApp,
} from "services/payment.service";
import { GetIuguIDStatusResponse } from "interfaces/paymentInterfaces";
import {
  linkEnrollmentToEmployee,
  LinkEnrollmentToEmployeeInterface,
} from "services/student.service";
import { getUnitsGradesSchoolPlaces } from "services/units.service";
import {
  getStudentData,
  getVendorList,
  sendNotificationsFirstAccessGuardian,
  VendorListResponse,
} from "services/users.service";
import loadingGif from "static/img/loading.gif";
import negative from "static/img/negative.png";
import ModalConfirmVacancy from "../ModalConfirmVacancy";
import { formatUnitsSchoolPlaces } from "./helper";
import { SchoolGradeFormStyled } from "./style";
import {
  GetAvailableStartMonths,
  getAvailableStartMonths,
} from "services/plan.service";
import Loading from "components/Loading";
import { getYear } from "services/multipurpose";
import { Storage } from "Storage";
import { useAtomValue } from "jotai";
import { authUserAtom } from "jotai/authUser";

interface SchoolGradeInterface {
  studentId: number | null;
  guardianAuthId: string | null;
  guardianId: number;
  setStep: (step: number) => void;
  setEnrollmentStatus: (enrollmentStatus: number) => void;
  onlyUpdate: boolean;
  outOfApp?: boolean;
  reserva: boolean;
}

type GetCXUserJsonType = {
  data: GetCXUserJson;
  status: number;
};

export const SchoolGradeForm = ({
  studentId,
  guardianAuthId,
  setEnrollmentStatus,
  setStep,
  onlyUpdate,
  outOfApp,
  guardianId,
  reserva,
}: SchoolGradeInterface) => {
  const history = useHistory();
  const [years, setYears] = useState<EnrollmentYearsInterface>();
  const [units, setUnits] = useState<null | Array<UnitDetailELInterface>>(null);
  const [loading, setLoading] = useState(true);
  const [toggleModal, setToggleModal] = useState(false);
  const [student, setStudent] = useState<GetCXUserJson | undefined>(undefined);
  const [error, setError] = useState(false);
  const [firstIuguIDResponse, setFirstIuguIDResponse] =
    useState<GetIuguIDStatusResponse | null>();
  const [firstIuguLoading, setFirstIuguLoading] = useState(false);
  const [secondIuguIDResponse, setSecondIuguIDResponse] =
    useState<GetIuguIDStatusResponse | null>();
  const [secondIuguLoading, setSecondIuguLoading] = useState(false);
  const [vendorList, setVendorList] = useState<Array<VendorListResponse>>();
  const [enrollmentVendor, setEnrollmentVendor] =
    useState<VendorListResponse>();
  const [vendorId, setVendorId] = useState<number>();
  const [employeeId, setEmployeeId] = useState<number>();
  const authUser = useAtomValue(authUserAtom);
  const role = Storage.props.role.get();
  const [monthPlans, setMonthPlans] = useState<GetAvailableStartMonths[]>();
  const [loadingMonthPlans, setLoadingMonthPlans] = useState(false);
  const [unit, setUnit] = useState();
  const [loadingYears, setLoadingYears] = useState(false);
  const [initialYear, setInitialYear] = useState<string>();

  useEffect(() => {
    if (vendorList) {
      vendorList.find(
        (vendor) => vendor.user === authUser.id && setEnrollmentVendor(vendor)
      );
    }

    if (!vendorId && enrollmentVendor) {
      setVendorId(enrollmentVendor.user);
    }

    if (vendorId && vendorList) {
      vendorList.find(
        (employeeId) =>
          employeeId.user === vendorId && setEmployeeId(employeeId.id)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendorList, vendorId, enrollmentVendor]);

  const updateOutOfApp = async (values: any) => {
    if (outOfApp) {
      const reserveDate = new Date(values.reserve_date);
      const responsePaymentUpdate = await updatePayStatusOutOfApp({
        guardian_id: guardianId,
        data_reserva:
          reserveDate.getFullYear() +
          "-" +
          (reserveDate.getMonth() + 1) +
          "-" +
          reserveDate.getDate(),
        student_id: studentId!,
        id_iugu_1: values.id_iugu_1,
        id_iugu_2: values.id_iugu_2,
        vendor: employeeId!,
        vendor_justify: values.justify_reason,
      });
      if (
        responsePaymentUpdate &&
        responsePaymentUpdate.status === HTTP_STATUS.OK
      ) {
        toastSuccess("Pagamento Atualizado com Sucesso");
        setStep(3);
      } else {
        toastError(
          "Ocorreu um erro ao atualizar os dados do pagamento, verifique o ID da Iugu."
        );
      }
    }
  };

  const updateEnrollmentStatus = (values: any) => {
    const grade = units!
      .find((u) => u.id === values.unit)!
      .grades.find(
        (g) => g.id_ed_lvl_grade.toString() === values.grade.toString()
      );
    if (grade && student) {
      const status_ =
        grade.school_places && grade.school_places > 0
          ? SCHOOL_PLACES_STATUS.VAGA_RESERVADA
          : SCHOOL_PLACES_STATUS.LISTA_DE_ESPERA;
      setEnrollmentStatus(status_);

      let body = {
        unit_id: parseInt(values.unit),
        guardian_id: guardianAuthId,
        grade: grade.grade,
        educational_level: grade.id_ed_lvl,
        start_month: values.start_month.split("-")[0].trim(),
        year: initialYear,
        school_place_status: status_,
        user_id: authUser.id,
        user_name: authUser.name,
        hubspot_id: values.hubspot_id,
      };

      updateEnrollment({
        body,
        enrollment_id: student.last_enrollment.id,
      }).then((response) => {
        if (response) {
          if (response.status !== HTTP_STATUS.OK) {
            toastError("Erro ao atualizar status da matrícula");
          } else if (status_ === SCHOOL_PLACES_STATUS.VAGA_RESERVADA) {
            body = {
              // @ts-ignore
              payment_status: PAYMENT_STATUS.AGUARDANDO_PAGAMENTO_DA_RESERVA,
              user_id: authUser.id,
              user_name: authUser.name,
            };
            updateEnrollment({
              body,
              enrollment_id: student.last_enrollment.id,
            })
              .then(async (response) => {
                if (response) {
                  if (response.status !== HTTP_STATUS.OK) {
                    toastError("Erro ao atualizar status da matrícula");
                  }
                  updateOutOfApp(values);
                }
              })
              .finally(() => {
                if (onlyUpdate) {
                  history.push(
                    `/responsavel/meus-alunos/aluno?studentId=${studentId}`
                  );
                } else {
                  if (!outOfApp) {
                    setStep(3);
                  }
                }
              });
          } else {
            updateOutOfApp(values);
            if (onlyUpdate) {
              history.push(
                `/responsavel/meus-alunos/aluno?studentId=${studentId}`
              );
            } else {
              if (!outOfApp) {
                setStep(3);
              }
            }
          }
        }
      });
    }
  };

  const linkVendor = (enrollmentReserve?: number) => {
    const body: LinkEnrollmentToEmployeeInterface = {
      enrollment: enrollmentReserve
        ? enrollmentReserve
        : student!.last_enrollment.id,
      employee: vendorId!,
    };

    linkEnrollmentToEmployee({ body })
      .then((response) => {
        if (response) {
          if (response.status === HTTP_STATUS.CREATED) {
            toastSuccess("Vendedor vinculado com sucesso!");
          } else {
            if (
              response.data.non_field_errors &&
              response.data.non_field_errors.includes(
                "The fields employee, enrollment must make a unique set."
              )
            ) {
              toastError("Erro ao vincular vendedor à matrícula do aluno");
            } else {
              toastError("Erro! Tente novamente mais tarde");
            }
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmit = async (values: any) => {
    if (
      outOfApp &&
      student &&
      student.last_enrollment.school_place_status !==
        SCHOOL_PLACES_STATUS.PRE_CADASTRO
    ) {
      let continueFlow = true;
      let valorMatricula;
      if (
        units?.find(
          (unit) =>
            unit.id.toString() === student.last_enrollment.unit_id.toString()
        )?.name === "Vila Andrade"
      ) {
        valorMatricula = 1090;
      } else {
        if (
          units!
            .find(
              (u) =>
                u.id.toString() === student.last_enrollment.unit_id.toString()
            )!
            .grades.find(
              (g) =>
                g.id_ed_lvl_grade.toString() ===
                student.last_enrollment.grade.toString()
            )
            ?.name.includes("Fundamental 2")
        ) {
          valorMatricula = 840;
        } else if (
          units!
            .find(
              (u) =>
                u.id.toString() === student.last_enrollment.unit_id.toString()
            )!
            .grades.find(
              (g) =>
                g.id_ed_lvl_grade.toString() ===
                student.last_enrollment.grade.toString()
            )
            ?.name.includes("Fundamental 1")
        ) {
          valorMatricula = 940;
        } else {
          valorMatricula = 940;
        }
        let totalValue = 0;
        if (firstIuguIDResponse) {
          totalValue = firstIuguIDResponse.value / 100 + totalValue;
        } else {
          toastError("ID Iugu Inválido, valor não encontrado.");
          return;
        }
        if (secondIuguIDResponse) {
          totalValue = secondIuguIDResponse.value / 100 + totalValue;
        }
        if (valorMatricula > totalValue) {
          if (
            window.confirm(
              `O valor da transação é menor que o valor da reserva de vaga de ano e unidade. Deseja continuar?`
            )
          ) {
            continueFlow = true;
          } else {
            continueFlow = false;
          }
        }
      }
      if (continueFlow) {
        updateOutOfApp(values);
      }
    } else {
      const grade = units!
        .find((u) => u.id === values.unit)!
        .grades.find(
          (g) => g.id_ed_lvl_grade.toString() === values.grade.toString()
        );

      if (grade) {
        if (
          (grade.school_places && grade.school_places > 0) ||
          window.confirm(
            `Incluir ${student?.personal.name} na lista de espera para ${
              values.grade
            }º ano da ${
              units?.find((unit) => unit.id.toString() === values.unit)?.name
            }?`
          )
        ) {
          let valorMatricula;
          if (
            units?.find((unit) => unit.id.toString() === values.unit)?.name ===
            "Vila Andrade"
          ) {
            valorMatricula = 1090;
          } else {
            if (
              units!
                .find((u) => u.id === values.unit)!
                .grades.find(
                  (g) =>
                    g.id_ed_lvl_grade.toString() === values.grade.toString()
                )
                ?.name.includes("Fundamental 2")
            ) {
              valorMatricula = 840;
            } else if (
              units!
                .find((u) => u.id === values.unit)!
                .grades.find(
                  (g) =>
                    g.id_ed_lvl_grade.toString() === values.grade.toString()
                )
                ?.name.includes("Fundamental 1")
            ) {
              valorMatricula = 940;
            } else {
              valorMatricula = 940;
            }
          }

          let continueFlow = true;
          let totalValue = 0;
          if (outOfApp) {
            if (firstIuguIDResponse) {
              totalValue = firstIuguIDResponse.value / 100 + totalValue;
            } else {
              toastError("ID Iugu Inválido, valor não encontrado.");
              return;
            }
            if (secondIuguIDResponse) {
              totalValue = secondIuguIDResponse.value / 100 + totalValue;
            }
            if (valorMatricula > totalValue) {
              if (
                window.confirm(
                  `O valor da transação é menor que o valor da reserva de vaga de ano e unidade. Deseja continuar?`
                )
              ) {
                continueFlow = true;
              } else {
                continueFlow = false;
              }
            }
          }

          if (reserva && studentId) {
            const enrollmentData = {
              responsavel_id: guardianId,
              estudante_id: studentId,
              ano: Number(values.start_month.split("-")[1].trim()),
              ciclo: grade.id_ed_lvl,
              segmento: grade.grade,
              mes_primeira_cobranca: Number(
                values.start_month.split("-")[0].trim()
              ),
              unidade_id: values.unit,
              hubspot_id: values.hubspot_id,
            };

            const status_ =
              grade.school_places && grade.school_places > 0
                ? SCHOOL_PLACES_STATUS.VAGA_RESERVADA
                : SCHOOL_PLACES_STATUS.LISTA_DE_ESPERA;
            setEnrollmentStatus(status_);

            const res = await createEnrollmentReserve(enrollmentData);
            !isCX(role) && linkVendor(res?.data.id);

            if (res?.status === HTTP_STATUS.CREATED) {
              toastSuccess("A matricula foi reservada com sucesso!");

              setStep(3);
            } else {
              toastError("Não foi possível concluir a reserva da matricula!");
            }

            return;
          }

          if (continueFlow) {
            updateEnrollmentStatus(values);
            if (!onlyUpdate) {
              const responseNotification =
                await sendNotificationsFirstAccessGuardian({
                  body: { guardian_id: guardianAuthId, domain: getDomain() },
                });
              if (
                responseNotification &&
                responseNotification.status === HTTP_STATUS.OK
              ) {
                toastSuccess(
                  "E-mail e SMS de primeiro acesso enviados com sucesso"
                );
              } else {
                if (
                  responseNotification &&
                  (!responseNotification.data.detail ||
                    responseNotification?.data.detail !==
                      "guardian already has 2 or more children")
                ) {
                  toastError("Erro ao enviar e-mail e SMS de primeiro acesso");
                }
              }
            }
            if (!isCX(role)) {
              linkVendor();
            }
          }
        }
      }
    }
  };

  useEffect(() => {
    if (studentId) {
      getStudentData({ id: studentId })
        .then(({ data, status }: GetCXUserJsonType) => {
          if (status === HTTP_STATUS.OK) {
            setStudent(data);
          } else {
            setError(true);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [studentId]);

  useEffect(() => {
    if (studentId) {
      (async () => {
        const getYears = await getYear({
          more_years: 1,
          studentId: reserva ? studentId : 0,
        }); // Quantidade de anos adicionais e estudante ID

        if (getYears && getYears.status === HTTP_STATUS.OK) {
          setYears(getYears.data);
        } else {
          setLoading(false);
          setError(true);
        }
      })();
    }
  }, [reserva, studentId]);

  const submitByModal = () => {
    document.getElementById("vacancy-form")!.dispatchEvent(
      new Event("submit", {
        cancelable: true,
        bubbles: true,
      })
    );
  };

  const handleSubmit = async (values: any) => {
    const periodoVendasFilter = true;

    const response = await getUnitsGradesSchoolPlaces(
      values.year,
      periodoVendasFilter
    );

    if (response && response.status === HTTP_STATUS.OK) {
      setUnits(formatUnitsSchoolPlaces(response.data));
    } else {
      setLoading(false);
      setError(true);
      setLoadingYears(false);
    }

    const responseVendor = await getVendorList();
    if (responseVendor && responseVendor.status === HTTP_STATUS.OK) {
      setVendorList(responseVendor.data);
      setLoadingYears(false);
    } else {
      setLoading(false);
      setError(true);
      setLoadingYears(false);
    }
  };

  const checkIugu = async (iuguId: string) => {
    try {
      const response = await getIuguIDStatus({
        iugu_id: iuguId,
      });
      if (response && response.status === HTTP_STATUS.OK) {
        return response.data;
      } else {
        return null;
      }
    } catch (err) {
      return null;
    }
  };

  const handleAvailableStartMonths = async (educational_level: number) => {
    setLoadingMonthPlans(true);

    if (initialYear) {
      const res = await getAvailableStartMonths({
        unit,
        year: parseInt(initialYear),
        educational_level,
      });
      setMonthPlans(res?.data);
      setLoadingMonthPlans(false);
    }
  };

  const filterUnit = (unitId: string) => {
    if (unitId === "2" || unitId === "6") {
      return false;
    }
    return true;
  };

  return (
    <SchoolGradeFormStyled className="container">
      {loading && <LinearProgress className="mt-3 mb-3" color="secondary" />}
      {error && (
        <div>Erro ao carregar os dados. Tente novamente mais tarde</div>
      )}
      {years && (
        <Form onSubmit={handleSubmit}>
          {({ handleSubmit, form, submitting, values }) => (
            <form id="vacancy-form" onSubmit={handleSubmit} className="mt-3">
              <Field component={SelectInput} name="year" required>
                <option value="">Selecione o ano da matrícula</option>
                {years.results.map((year) => {
                  return (
                    <option value={year.value} key={year.value}>
                      {year.label}
                    </option>
                  );
                })}
              </Field>
              <OnChange name="year">
                {async (value, previous) => {
                  if (value) {
                    handleSubmit(value);
                    setLoadingYears(true);
                    setInitialYear(value);
                  }
                }}
              </OnChange>
            </form>
          )}
        </Form>
      )}
      {loadingYears && (
        <div className="mt-3">
          <LoadingBox height={50} />
        </div>
      )}
      {units && !loadingYears && initialYear && (
        <Form
          initialValues={{
            unit: student?.education.unit?.id?.toString(),
            grade:
              student?.education.unit?.id &&
              student?.grade &&
              units
                .find((u) => u.id === student?.education.unit?.id?.toString())!
                .grades.find((g) => g.grade === student?.grade)
                ?.id_ed_lvl_grade.toString(),
            vendor_id: enrollmentVendor && enrollmentVendor.user,
          }}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, form, submitting, values }) => (
            <form id="vacancy-form" onSubmit={handleSubmit}>
              {((student &&
                student.last_enrollment?.school_place_status ===
                  SCHOOL_PLACES_STATUS.PRE_CADASTRO) ||
                !outOfApp) && (
                <>
                  <div className="mt-3 mb-3">
                    Verifique a disponibilidade de vagas:
                  </div>
                  <Field<string>
                    component={SelectInput}
                    className="col-12"
                    name="unit"
                    required
                  >
                    <option value="">Selecione uma unidade</option>
                    {units.map((unit, key) => {
                      return (
                        filterUnit(unit.id) && (
                          <option value={unit.id} key={key}>
                            {unit.name}
                          </option>
                        )
                      );
                    })}
                  </Field>
                  <OnChange name="unit">
                    {async (value, previous) => {
                      if (value) {
                        setUnit(value);
                        form.change("grade", null);
                        form.change("start_month", null);
                      }
                    }}
                  </OnChange>
                  {values.unit && (
                    <>
                      <div className="mt-3 mb-3">Selecione o ano:</div>
                      {units
                        .find((u) => u.id === values.unit)!
                        .grades.map((grade, index) => {
                          return grade.school_places !== null ? (
                            <div className="row" key={index}>
                              <div className="col-sm-1 col-2">
                                <Field
                                  component={RadioButton}
                                  name="grade"
                                  type="radio"
                                  value={grade.id_ed_lvl_grade.toString()}
                                  onClick={() =>
                                    handleAvailableStartMonths(grade.id_ed_lvl)
                                  }
                                  required
                                />
                                <OnChange name="grade">
                                  {async (value) => {
                                    form.change("start_month", null);
                                  }}
                                </OnChange>
                              </div>
                              <div className="col-sm-11 col-10 pl-0 my-auto">
                                <strong>
                                  {grade.name}{" "}
                                  {grade.school_places <= 0 ||
                                  !grade.school_places
                                    ? " - Lista de espera"
                                    : ""}
                                </strong>
                              </div>
                            </div>
                          ) : (
                            <></>
                          );
                        })}
                    </>
                  )}

                  {/* Inicio do plano */}
                  {loadingMonthPlans ? (
                    <div className="d-flex align-items-center justify-content-center m-4">
                      <Loading />
                    </div>
                  ) : (
                    <>
                      {monthPlans && (
                        <>
                          <div className="mb-3 pb-2 start_month">
                            Cobrança da 1ª parcela:
                          </div>
                          {monthPlans.length > 0 ? (
                            monthPlans.map((monthPlan, index) => (
                              <>
                                {monthPlan.year === parseInt(initialYear) && (
                                  <div className="row" key={index}>
                                    <div className="col-sm-1 col-2">
                                      <Field
                                        component={RadioButton}
                                        name="start_month"
                                        type="radio"
                                        value={`${monthPlan.start_month} - ${monthPlan.year}`}
                                        required
                                      />
                                    </div>
                                    <div className="col-sm-11 col-10 pl-0 my-auto">
                                      <strong>
                                        {monthPlan.start_month_display} de{" "}
                                        {monthPlan.year}
                                      </strong>
                                    </div>
                                  </div>
                                )}
                              </>
                            ))
                          ) : (
                            <div>
                              {values.unit ? (
                                <p className="m-0 p-0">
                                  Não há planos disponíveis para esta
                                  unidade/ano.
                                </p>
                              ) : (
                                <p className="m-0 p-0">
                                  Selecione uma unidade.
                                </p>
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}

              {!isCX(role) && (
                <div className="row mt-1">
                  <div className="col-12">
                    <Field component={SelectInput} name="vendor_id" required>
                      <option value="">Selecione o vendedor</option>
                      {vendorList &&
                        vendorList.map((vendor) => {
                          return (
                            <option value={vendor.user} key={vendor.id}>
                              {vendor.name}
                            </option>
                          );
                        })}
                    </Field>
                    <OnChange name="vendor_id">
                      {async (value) => {
                        if (value) {
                          setVendorId(parseInt(value));
                        }
                      }}
                    </OnChange>
                  </div>
                </div>
              )}

              <div className="col-12 p-0">
                <Field
                  component={TextInput}
                  name="hubspot_id"
                  type="text"
                  placeholder=" "
                  label="ID DO HUBSPOT"
                  hideCheck
                  required
                />
              </div>
              {outOfApp && (
                <>
                  <p className="mt-5">Informe os dados de pagamento:</p>
                  <div className="p-2 border border-3 rounded">
                    <div className="row mt-3">
                      <div className="col-8">
                        <Field
                          component={CalendarComponent}
                          name="reserve_date"
                          type="text"
                          placeholder="Data da Reserva *"
                          hideCheck
                          required
                        />
                      </div>
                    </div>
                    <div className="row mt-1 align-items-end">
                      <div className="col-8">
                        <Field
                          component={TextInput}
                          name="id_iugu_1"
                          type="text"
                          placeholder=" "
                          label="Id Iugu da transação *"
                          maxLength="32"
                          hideCheck
                          required
                        />
                      </div>
                      <div className="col-4 text-left">
                        {firstIuguLoading && (
                          <img height="25" src={loadingGif} alt="loading" />
                        )}
                        {!firstIuguLoading && firstIuguIDResponse && (
                          <span>
                            {formatMoney(firstIuguIDResponse.value / 100)}
                          </span>
                        )}
                        {firstIuguIDResponse === null && !firstIuguLoading && (
                          <>
                            <img height="25" src={negative} alt="failed" />
                            <span className="ml-1">Código Inválido</span>
                          </>
                        )}
                      </div>
                      <OnChange name="id_iugu_1">
                        {async (value) => {
                          if (value) {
                            setFirstIuguLoading(true);
                            const response = await checkIugu(value);
                            setFirstIuguIDResponse(response);
                            setFirstIuguLoading(false);
                          }
                        }}
                      </OnChange>
                    </div>
                    <div className="row mt-1 align-items-end">
                      <div className="col-8">
                        <Field
                          component={TextInput}
                          name="id_iugu_2"
                          type="text"
                          placeholder=" "
                          label="Id Iugu da transação "
                          maxLength="32"
                          hideCheck
                        />
                      </div>
                      <div className="col-4 text-left">
                        {secondIuguLoading && (
                          <img height="25" src={loadingGif} alt="loading" />
                        )}
                        {!secondIuguLoading && secondIuguIDResponse && (
                          <span>
                            {formatMoney(secondIuguIDResponse.value / 100)}
                          </span>
                        )}
                        {secondIuguIDResponse === null && !secondIuguLoading && (
                          <>
                            <img height="25" src={negative} alt="failed" />
                            <span className="ml-1">Código Inválido</span>
                          </>
                        )}
                      </div>
                      <OnChange name="id_iugu_2">
                        {async (value) => {
                          if (value) {
                            setSecondIuguLoading(true);
                            const response = await checkIugu(value);
                            setSecondIuguIDResponse(response);
                            setSecondIuguLoading(false);
                          }
                        }}
                      </OnChange>
                    </div>
                    <div className="row mt-1 mb-5">
                      <div className="col-12">
                        <Field
                          component={TextInput}
                          name="justify_reason"
                          type="text"
                          placeholder=" "
                          label="Reserva de vaga feita fora do app pelo motivo: *"
                          required
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="row">
                <div className="col-sm-3 offset-sm-9 col-12">
                  {outOfApp &&
                  student &&
                  student.last_enrollment?.school_place_status !==
                    SCHOOL_PLACES_STATUS.PRE_CADASTRO ? (
                    <Button type="submit">
                      Salvar informações do pagamento
                    </Button>
                  ) : (
                    <Button
                      className={`${
                        values.unit &&
                        values.grade &&
                        !units
                          .find((u) => u.id === values.unit)!
                          .grades.find(
                            (g) =>
                              g.id_ed_lvl_grade.toString() ===
                              values.grade.toString()
                          )?.school_places &&
                        "pink"
                      }`}
                      type="submit"
                      disabled={
                        submitting ||
                        !values.unit ||
                        !values.grade ||
                        !values.start_month
                      }
                    >
                      {(!values.unit || !values.grade) &&
                        "Selecione a unidade e o ano"}
                      {values.unit && values.grade && (
                        <span>
                          {units
                            .find((u) => u.id === values.unit)!
                            .grades.find(
                              (g) =>
                                g.id_ed_lvl_grade.toString() ===
                                values.grade.toString()
                            )?.school_places &&
                          units!
                            .find((u) => u.id === values.unit)!
                            .grades.find(
                              (g) =>
                                g.id_ed_lvl_grade.toString() ===
                                values.grade.toString()
                            )!.school_places! >= 0
                            ? "Reservar vaga"
                            : "Ir para lista de espera"}
                        </span>
                      )}
                      {submitting && (
                        <img height="25" src={loadingGif} alt="loading" />
                      )}
                    </Button>
                  )}
                </div>
              </div>
              <Modal setToggleModal={setToggleModal} isOpen={toggleModal}>
                <ModalConfirmVacancy
                  submitByModal={submitByModal}
                  setToggleModal={setToggleModal}
                  submitting={submitting}
                />
              </Modal>
            </form>
          )}
        </Form>
      )}
    </SchoolGradeFormStyled>
  );
};
