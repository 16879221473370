/* eslint-disable react-hooks/exhaustive-deps */
//React Stuff
import { useState, useEffect, useContext } from "react";
//Libs Externas
import HTTP_STATUS from "http-status-codes";
import { Field, Form } from "react-final-form";
import { LinearProgress } from "@material-ui/core";
import moment from "moment";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import { useHistory } from "react-router-dom";
//Components
import { Button } from "components";
import SelectInput from "components/Select";
import loadingGif from "static/img/loading.gif";
//Helpers
import { formatMoney } from "helpers/helpers";
//Services
import { getUnits, UnitInterface } from "services/units.service";
import { getPaymentPlansData } from "services/plan.service";
import { getYear } from "services/multipurpose";
// Styles
import { ConsultaPlanosContainer } from "./style";
import { EnrollmentYearsInterface } from "interfaces/constants";
import { IPlanRegistration } from "interfaces/plan";
import { IPagination } from "interfaces/utils";

interface FormValuesInterface {
  unit_id: string;
  educational_level: string;
  year: string;
}

export const ConsultaPlanoPagamento = () => {
  //Hooks
  const history = useHistory();
  //States pre carregamento
  const [unitList, setUnitList] = useState<UnitInterface[]>();
  const [years, setYears] = useState<EnrollmentYearsInterface>();
  //States de Retorno da Consulta
  const [tableData, setTableData] = useState<
    IPagination<IPlanRegistration> | undefined
  >(undefined);
  const [count, setCount] = useState<number>();
  const [next, setNext] = useState<string>();
  const [previous, setPrevious] = useState<string>();
  const [page, setPage] = useState(1);
  //Error Handling
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState([""]);
  //Controle de Loading
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  // Salva form
  const [form, setForm] = useState<FormValuesInterface>();

  const fetchUnitAndYear = async () => {
    setLoadingPage(true);
    const response = await getUnits();
    if (response && response.status === HTTP_STATUS.OK) {
      setUnitList(response.data);
      setLoadingPage(false);
    } else {
      setErrorMessage([
        ...errorMessage,
        "Ocorreu um erro ao carregar a lista de unidades.",
      ]);
      setError(true);
    }

    const moreYears = 1;
    const getYears = await getYear({ more_years: moreYears });
    if (getYears && getYears.status === HTTP_STATUS.OK) {
      setYears(getYears.data);
    } else {
      setError(true);
    }
  };

  //Effect para capturar os dados iniciais da tela e alimentar os filtros.
  useEffect(() => {
    try {
      fetchUnitAndYear();
    } catch (err) {
      setError(true);
      setErrorMessage([
        ...errorMessage,
        "Ocorreu um erro ao carregar os dados para os filtros",
      ]);
    }
  }, []);

  //On Submit do Formulário
  const onSubmit = async (values: FormValuesInterface) => {
    setForm(values);
    const { unit_id, educational_level, year } = values;
    setLoadingData(true);
    let filtros = "";
    if (unit_id) {
      filtros += `&unit=${unit_id}`;
    }
    if (educational_level) {
      filtros += `&educational_level=${educational_level}`;
    }
    if (year) {
      filtros += `&year=${year}`;
    }

    try {
      const response = await getPaymentPlansData({
        filtro: filtros,
      });
      if (response && response.status === HTTP_STATUS.OK) {
        setTableData(response.data);
        setNext(response.data.next);
        setPrevious(response.data.previous);
        setCount(response.data.count);
        setPage(1);
        setLoadingData(false);
      }
    } catch (err) {
      setError(true);
      setErrorMessage([
        ...errorMessage,
        "Ocorreu um erro durante a consulta dos dados.",
      ]);
    }
  };

  //Paginação do Retorno
  const Paginar = async (direction: string) => {
    let filtros = "";
    if (form) {
      if (form.unit_id) {
        filtros += `&unit=${form.unit_id}`;
      }
      if (form.educational_level) {
        filtros += `&educational_level=${form.educational_level}`;
      }
      if (form.year) {
        filtros += `&year=${form.year}`;
      }
    }

    setLoadingData(true);
    const urlDirection = direction === "foward" ? next : previous;

    const response = await getPaymentPlansData({
      filtro: filtros,
      page: direction === "foward" ? page + 1 : page - 1,
    });
    if (response && response.status === HTTP_STATUS.OK) {
      setTableData(response.data);
      setCount(response.data.count);
      setPrevious(response.data.previous);
      setNext(response.data.next);
      setLoadingData(false);
      setPage(direction === "foward" ? page + 1 : page - 1);
    } else {
      setError(true);
      setErrorMessage([...errorMessage, "Ocorreu um erro ao realizar a busca"]);
    }
  };

  return (
    <ConsultaPlanosContainer className="container">
      {!error && !loadingPage && unitList && years && (
        <>
          <div className="title text-center">
            Consulta de Planos de Pagamento
          </div>
          <Form
            onSubmit={onSubmit}
            initialValues={{ year: years?.results[0].value }}
          >
            {({ form, handleSubmit, submitting, values }) => (
              <form onSubmit={handleSubmit}>
                <div className="row mt-5 justify-content-center">
                  <div className="col-8">
                    <div className="mt-2">
                      <strong>Unidade</strong>
                      <Field
                        component={SelectInput}
                        name="unit_id"
                        className="mt-0"
                      >
                        <option value=""> Selecione uma Unidade </option>
                        {unitList.map((unit) => {
                          if (unit.actived) {
                            return (
                              <option value={unit.id} key={unit.id}>
                                {unit.name}
                              </option>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </Field>
                    </div>
                    <div className="mt-2">
                      <strong>Ciclo</strong>
                      <Field
                        component={SelectInput}
                        name="educational_level"
                        className="mt-0"
                      >
                        <option value="">Selecione um Ciclo</option>
                        <option value="1">Fundamental 1</option>
                        <option value="2">Fundamental 2</option>
                        <option value="3">Médio</option>
                      </Field>
                    </div>
                    <div className="mt-2">
                      <strong>Ano</strong>
                      <Field
                        component={SelectInput}
                        name="year"
                        className="mt-0"
                      >
                        <option value="">Selecione um Ano</option>
                        {years?.results.map((year, index) => {
                          return (
                            <option value={year.value} key={index}>
                              {year.label}
                            </option>
                          );
                        })}
                      </Field>
                    </div>
                  </div>
                </div>

                <div className="row mt-3 justify-content-center">
                  <div className="col-8">
                    <div className="row">
                      <div className="col-3">
                        <Button
                          type="button"
                          className="pink outline"
                          onClick={() => {
                            form.reset();
                            setTableData(undefined);
                          }}
                        >
                          {" "}
                          Limpar Busca
                        </Button>
                      </div>
                      <div className="col-4">
                        <Button type="submit" disabled={submitting}>
                          {submitting && (
                            <img height="25" src={loadingGif} alt="loading" />
                          )}
                          {!submitting && "Buscar"}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Form>
        </>
      )}
      {(loadingPage || loadingData) && (
        <LinearProgress className="mt-3 mb-3" color="secondary" />
      )}
      {!loadingData && tableData && count === 0 && (
        <div className="mt-5 text-center">
          A pesquisa não retornou nenhum resultado.
        </div>
      )}
      {tableData && !loadingData && count && count !== 0 && (
        <>
          <div className="row mt-2 ml-3 align-items-center justify-content-center">
            {count
              ? count > 10
                ? `Exibindo 10 de ${count} itens `
                : `Exibindo um total de ${count} ite${count > 1 ? "ns" : "m"}`
              : ``}
          </div>
          <div className="row justify-content-center mt-5">
            {tableData.results.map((paymentPlan) => {
              return (
                <div className="card col-5" key={paymentPlan.id}>
                  <div className="mt-1 d-flex">
                    <div>
                      <strong>Nome de referência: </strong>{" "}
                      {paymentPlan.nome_referencia}
                    </div>
                    <div
                      className="ml-auto"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        history.push({
                          pathname: "/plano-de-pagamento",
                          state: {
                            id: paymentPlan.id,
                          },
                        });
                      }}
                    >
                      <strong>Editar </strong>
                      <BorderColorIcon />
                    </div>
                  </div>
                  <div className="mt-1">
                    <strong>Nome do plano: </strong> {paymentPlan.tipo}
                  </div>
                  <div className="mt-1">
                    <strong>
                      Plano ativo?: {paymentPlan.ativo ? "Sim" : "Não"}
                    </strong>
                  </div>
                  <div className="mt-1">
                    <strong>Unidade: </strong>
                    {paymentPlan.nome_unidade}
                  </div>
                  <div className="mt-1">
                    <strong>Nível educacional: </strong>
                    {paymentPlan.ciclo}
                  </div>
                  <div className="mt-1">
                    <strong>Código do plano na TOTVS: </strong>
                    {paymentPlan.codigo_plano_totvs}
                  </div>

                  <div className="mt-1">
                    <strong>Data de inicio da vigência: </strong>
                    {moment(paymentPlan.data_inicio_vigencia).format(
                      "DD/MM/YYYY"
                    )}
                  </div>
                  <div className="mt-1">
                    <strong>Data de término da vigência: </strong>
                    {moment(paymentPlan.data_final_vigencia).format(
                      "DD/MM/YYYY"
                    )}
                  </div>

                  <div className="mt-1">
                    <strong>Data de inicio da venda: </strong>
                    {paymentPlan.data_inicio_venda
                      ? moment(paymentPlan.data_inicio_venda).format(
                          "DD/MM/YYYY"
                        )
                      : "Não possui"}
                  </div>
                  <div className="mt-1">
                    <strong>Data de término da venda: </strong>
                    {paymentPlan.data_final_venda
                      ? moment(paymentPlan.data_final_venda).format(
                          "DD/MM/YYYY"
                        )
                      : "Não possui"}
                  </div>

                  <div className="mt-1">
                    <strong>Possui desconto?: </strong>
                    <strong>
                      {paymentPlan.possui_desconto ? "Sim" : "Não"}
                    </strong>
                  </div>
                  <div className="mt-1">
                    <strong>Valor do desconto: </strong>
                    {paymentPlan.possui_desconto
                      ? `${paymentPlan.porcentagem_desconto_plano}%`
                      : "Não possui desconto"}
                  </div>
                  <div className="mt-1">
                    <strong>Valor do plano: </strong>
                    {formatMoney(paymentPlan.valor_reserva)}
                  </div>
                  <div className="mt-1">
                    <strong>Quantidade de parcelas: </strong>

                    {`${paymentPlan.quantidade_mensalidades}x`}
                  </div>
                  <div className="mt-1">
                    <strong>Valor das parcelas: </strong>
                    {formatMoney(paymentPlan.valor_mensalidades)}
                  </div>
                  <div className="mt-1">
                    <strong>Valor do material: </strong>
                    {formatMoney(paymentPlan.valor_parcela_material)}
                  </div>
                  <div className="mt-1">
                    <strong>Quantidade de parcelas do material: </strong>
                    {`${paymentPlan.quantidade_parcelas_material}x`}
                  </div>
                  <div className="mt-1">
                    <strong>Valor do desconto do material: </strong>
                    {paymentPlan.porcentagem_desconto_material &&
                    paymentPlan.porcentagem_desconto_material !== "0.00"
                      ? `${paymentPlan.porcentagem_desconto_material}%`
                      : "Não possui desconto"}
                  </div>
                  {paymentPlan.mes_primeira_cobranca_display && (
                    <div className="mt-1">
                      <strong>Cobrança da 1ª parcela: </strong>
                      {paymentPlan.mes_primeira_cobranca_display}
                    </div>
                  )}
                  <div className="mt-1">
                    <strong>Ano: </strong> {paymentPlan.ano}
                  </div>
                  <div className="mt-1">
                    <strong>Plano criado em: </strong>
                    {moment(paymentPlan.criado_em).format("DD/MM/YYYY")}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="row justify-content-center">
            <div className="col-5">
              {previous && (
                <Button type="button" onClick={() => Paginar("backwards")}>
                  ANTERIOR
                </Button>
              )}
            </div>
            <div className="col-2 text-center mt-4">
              <strong>
                Página {page} de {Math.ceil(count / 10)}
              </strong>
            </div>
            <div className="col-5">
              {next && (
                <Button type="button" onClick={() => Paginar("foward")}>
                  PROXIMA
                </Button>
              )}
            </div>
          </div>
        </>
      )}
    </ConsultaPlanosContainer>
  );
};
