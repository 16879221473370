import styled from "styled-components";

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
  min-height: 100%;

  padding: 1rem;

  display: flex;
  flex-direction: column;

  div.loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  div.error {
    margin: 1em;
  }
`;

export const HeaderPage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  button + button {
    margin-left: 1rem;
  }
`;

export const ItemCard = styled.div`
  width: calc((100% / 6) - 1rem);
  height: 100px;

  background: #f0e9fb;

  padding: 1rem;
  margin: 0 1rem;

  border-radius: 10px;
`;

export const LinkCard = styled.button`
  width: 100%;

  text-align: right;
  font-size: 14px;
  font-weight: 700;
  line-height: 36px;
  color: #6d28d9;
  text-align: right;

  background: transparent;
  border: none;

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const SpecializationsContainer = styled.div`
  padding: 1rem;
  margin: 1rem 0;
  width: 391px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid ${({ theme }) => theme.colors.statusErrorDefault};
  cursor: pointer;

  @media only screen and (max-width: 420px) {
    width: auto;
  }
`;

export const SpecializationsContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.web.default};
  line-height: ${({ theme }) => theme.lineHeights.taller};
  color: "#0D0D0D";
`;

export const SubTitle = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #999;
`;

export const SpecializationsSelectedContainer = styled.div`
  display: flex;
  margin: 1rem 0;
  padding: 1rem;
  width: 391px;
  border: 2px solid ${({ theme }) => theme.colors.primaryDefault};
  align-items: center;
`;
export const SpecializationsSelectedContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;
