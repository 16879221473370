import styled from "styled-components";
import { BLUE, GRAY_DISABLED, GREEN, RED } from "styles/colors";

export const ConsultaAlimentacaoResponsavelStyled = styled.div`
  div.title {
    font-size: 24px;
    font-weight: bold;
    color: ${BLUE};
  }

  p.font-weight-500 {
    font-weight: 500;
  }

  p.font-size-10 {
    font-size: 12px;
  }

  table {
    width: 100%;
    text-align: center;
    @media only screen and (max-width: 414px) {
      font-size: 12px;
    }
    thead {
      tr {
        border: 1px solid ${GRAY_DISABLED};
        border-bottom: none;
        th {
          padding: 1em;
          border-right: 1px solid ${GRAY_DISABLED};
          span {
            font-weight: normal;
          }
        }
      }
    }
    tbody {
      tr {
        border: 1px solid ${GRAY_DISABLED};
        td {
          padding: 1em;
          border-right: 1px solid ${GRAY_DISABLED};
          span {
            svg {
              fill: ${BLUE};
              cursor: pointer;
            }
          }
          &.green {
            color: ${GREEN};
          }
          &.red {
            color: ${RED};
          }
        }
      }
    }
  }
  span.circle {
    height: 15px;
    width: 15px;
    background-color: ${GREEN};
    border-radius: 50%;
    display: inline-block;

    &.blue {
      background-color: ${BLUE};
    }

    &.red {
      background-color: ${RED};
    }
  }
`;
