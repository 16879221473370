import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { Loading, Title } from "components";
import { Subtitle } from "./components/Subtitle";
import { DocumentCard } from "./components/DocumentCard";

import { useUserDocuments } from "./useUserDocuments";
import { ILocaltionProps } from "./types";

import * as S from "./styles";

export const UserDocuments = () => {
  const { t } = useTranslation();
  const i18n_prefix = "pages.EDF.userDocuments";

  const { state } = useLocation<ILocaltionProps>();
  const { isLoadingDocuments, userDocuments, setUserDocuments, year, setYear } =
    useUserDocuments();

  const returnGuardian = () => {
    const financialGuardian = state.student.guardians.find(
      (guardian) =>
        guardian.guard_info.guard_id === state.student.last_enrollment.guard_id
    );

    if (financialGuardian) {
      return financialGuardian.name;
    } else {
      return "";
    }
  };

  if (isLoadingDocuments) {
    return <Loading />;
  }

  return (
    <S.Container>
      <Title fontSize="40px">{t(`${i18n_prefix}.documents`)}</Title>
      <Subtitle content={state.student?.personal?.name} />
      <S.DocumentsCards>
        {userDocuments?.documentos_estudante?.map((data, index) => (
          <DocumentCard
            key={index}
            documento={data}
            prefixo="estudante"
            userId={state.student.id}
            setUpdateDocuments={setUserDocuments}
            year={year}
          />
        ))}
      </S.DocumentsCards>

      <Subtitle content={returnGuardian()} />
      <S.EnrollmentYears onChange={(e) => setYear(Number(e.target.value))}>
        {state?.student.enrollment_years
          .sort((a, b) => b - a)
          ?.map((option, i) => (
            <option key={i} value={option} selected={option === year}>
              {option}
            </option>
          ))}
      </S.EnrollmentYears>

      <S.DocumentsCards>
        {userDocuments?.documentos_responsavel?.map((data, index) => (
          <DocumentCard
            key={index}
            documento={data}
            prefixo="responsavel"
            userId={state.student.guardians[0].id}
            setUpdateDocuments={setUserDocuments}
            year={year}
          />
        ))}
      </S.DocumentsCards>
    </S.Container>
  );
};
