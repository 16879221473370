// Style
import * as Style from "./style";
// Helpers
import {
  formatCEP,
  formatCPF,
  formatDate,
  formatPhone,
  formatRG,
} from "helpers/helpers";
import { ENROLLMENT_STATUS } from "helpers/constants";
// Interfaces
import { GetCXUserJson } from "interfaces/constants";
import { GuardianData } from "interfaces/guardian";
import { EnrollmentInterface } from "interfaces/enrollment";
// Libs
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HTTP_STATUS from "http-status-codes";
// Components
import {
  BorderlessButton,
  EnrollmentStepCircle,
  ProfilePicture,
  Text,
  Title,
} from "components";
import { Button, Loading } from "components/atoms";
import { toastError } from "components/Toast";
// Service
import { getGuardianData } from "services/guardian.service";
import { updateEnrollmentToNextStep } from "services/enrollment.service";
import { Storage } from "Storage";

type ConfirmDataType = {
  user: GetCXUserJson;
  enrollment: EnrollmentInterface;
};

export const ConfirmGuardianData = () => {
  const history = useHistory();
  const { state } = useLocation<ConfirmDataType>();
  const [guardianData, setGuardianData] = useState<GuardianData>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.confirmGuardianData";
  const token = Storage.token.get();

  useEffect(() => {
    (async () => {
      const response = await getGuardianData({
        guardian_id: state.user.id,
      });

      if (response && response.status === HTTP_STATUS.OK) {
        setGuardianData(response.data);
        setIsLoading(false);
      } else {
        setError(true);
        setIsLoading(false);
      }
    })();
  }, [state]);

  const nextStep = async () => {
    setIsLoading(true);
    if (
      state.enrollment.status ===
      ENROLLMENT_STATUS.CONFIRMACAO_DADOS_RESPONSAVEL
    ) {
      const response = await updateEnrollmentToNextStep({
        enrollmentId: state.enrollment.id,
        step: "step1",
        token: token?.access ?? "",
      });

      if (response && response.status === HTTP_STATUS.OK) {
        history.push({
          pathname: "/matricula/confirmar-dados-do-estudante",
          state: {
            user: guardianData,
            enrollment: state.enrollment,
            editStudent: true,
          },
        });
      } else {
        toastError(t(`${TRANSLATE}.toastError`));
        setIsLoading(false);
      }
    } else {
      history.push({
        pathname: "/matricula/confirmar-dados-do-estudante",
        state: {
          user: guardianData,
          enrollment: state.enrollment,
        },
      });
    }
  };

  if (isLoading && !guardianData) {
    return (
      <Style.LoadContainer>
        <h4>{t(`${TRANSLATE}.loadingGuardianData`)}</h4>
        <Loading />
      </Style.LoadContainer>
    );
  }
  if (error && !isLoading) {
    return <div>{t(`${TRANSLATE}.errorGuardianData`)}</div>;
  }

  if (guardianData) {
    return (
      <Style.ConfirmGuardianDataStyled>
        <EnrollmentStepCircle
          studentName={state.enrollment.guard.student.nome}
          nextStep={state.enrollment.steps[1].name}
          step={1}
          totalSteps={7}
          currentStepName={state.enrollment.steps[0].name}
          subtitle={t(`${TRANSLATE}.enrollment`, {
            year: state.enrollment.year,
          })}
        />
        <div className="cardData">
          <div className="top">
            <ProfilePicture />
            <Title className="ml-3 mt-1" darkGray fontSize="18px">
              {guardianData?.name}
            </Title>
          </div>
          <div className="editData">
            <Title fontSize="18px" fontWeight="600">
              {t(`${TRANSLATE}.personalData`)}:
            </Title>
            {state.enrollment.status ===
              ENROLLMENT_STATUS.CONFIRMACAO_DADOS_RESPONSAVEL && (
              <div>
                <BorderlessButton
                  onClick={() =>
                    history.push({
                      pathname: "/matricula/editar-dados-responsavel",
                      state: {
                        user: guardianData,
                        enrollment: state.enrollment,
                      },
                    })
                  }
                  type="button"
                >
                  {t(`${TRANSLATE}.buttonDataEdit`)}
                </BorderlessButton>
              </div>
            )}
          </div>
          <div className="border-bottom">
            <div className="wrapper">
              <div>
                <Text>{t(`${TRANSLATE}.birth`)}</Text>
                <div className="data">
                  {formatDate(guardianData?.birth_date)}
                </div>
              </div>
              {/* <div>
                <Text>{t(`${TRANSLATE}.kinshipDegree`)}</Text>
                <div className="data">Pai</div>
              </div>
              <div>
                <Text>{t(`${TRANSLATE}.maritalStatus`)}</Text>
                <div className="data">Solteiro</div>
              </div>
              <div>
                <Text>{t(`${TRANSLATE}.profession`)}</Text>
                <div className="data">Analista de dados</div>
              </div> */}
              {/* Remover RG e CPF quando tiver os dados assima no back */}
              <div>
                <Text>{t(`${TRANSLATE}.generalRecord`)}</Text>
                <div className="data">
                  {guardianData.rg && formatRG(guardianData.rg)}
                </div>
              </div>
              <div>
                <Text>{t(`${TRANSLATE}.individualRegistration`)}</Text>
                <div className="data">
                  {guardianData.cpf && formatCPF(guardianData.cpf)}
                </div>
              </div>
            </div>
            {/* Remover Comentario quando for remover os documentos da parte de cima */}
            {/* <div className="wrapper documents">
              <div>
                <Text>{t(`${TRANSLATE}.generalRecord`)}</Text>
                <div className="data">
                  {guardianData.rg && formatRG(guardianData.rg)}
                </div>
              </div>
              <div>
                <Text>{t(`${TRANSLATE}.individualRegistration`)}</Text>
                <div className="data">
                  {guardianData.cpf && formatCPF(guardianData.cpf)}
                </div>
              </div>
            </div> */}

            <div className="mb-4">
              <div className="mt-4">
                <Text>{t(`${TRANSLATE}.email`)}</Text>
                <div className="data">{guardianData.email || "Não possui"}</div>
              </div>
              <div className="mt-4">
                <Text>{t(`${TRANSLATE}.phone`)}</Text>
                <div className="data">
                  {guardianData.phone && formatPhone(guardianData.phone)}
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3 w-100">
            <div className="editData">
              <Text>{t(`${TRANSLATE}.address`)}</Text>
              {state.enrollment.status ===
                ENROLLMENT_STATUS.CONFIRMACAO_DADOS_RESPONSAVEL && (
                <div className="ml-5 pl-5">
                  <BorderlessButton
                    className="pl-4"
                    onClick={() =>
                      history.push({
                        pathname: "/matricula/editar-dados-responsavel",
                        state: {
                          user: guardianData,
                          enrollment: state.enrollment,
                        },
                      })
                    }
                    type="button"
                  >
                    {t(`${TRANSLATE}.buttonAddressEdit`)}
                  </BorderlessButton>
                </div>
              )}
            </div>
            <div className="data mt-0">
              <div>
                {guardianData?.address}, {guardianData?.number},
                {guardianData?.neighborhood}
              </div>
              <div></div>
              <div>{guardianData?.complement}</div>
              <div>
                {guardianData?.city}, {guardianData?.uf}
              </div>
              <div>
                {guardianData?.zip_code && formatCEP(guardianData.zip_code)}
              </div>
            </div>
          </div>
        </div>
        <div className="button">
          <Button width="156px" outline onClick={() => history.goBack()}>
            {t(`${TRANSLATE}.buttonGoBack`)}
          </Button>
          <Button width="156px" disabled={isLoading} onClick={() => nextStep()}>
            {t(`${TRANSLATE}.buttonAdvance`)}
          </Button>
        </div>
      </Style.ConfirmGuardianDataStyled>
    );
  }
};
