import { useHistory } from "react-router-dom";
import { DirectorContainer } from "./style";
import { ButtonCard } from "components/atoms/ButtonCard";
import { useTranslation } from "react-i18next";
import { IsDevelopmentEnvironment } from "helpers/helpers";

const TRANSLATION_PREFIX = "pages.CORE.home.director";

export const DirectorHome = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <DirectorContainer>
      <ButtonCard
        onClick={() => history.push("/dashboard")}
        text={t(`${TRANSLATION_PREFIX}.dashboard`)}
      />
      <ButtonCard
        onClick={() => history.push("/consulta-diario-de-classe")}
        text={t(`${TRANSLATION_PREFIX}.rollSearch`)}
      />
      <ButtonCard
        onClick={() => history.push("/gerenciar/matriculas")}
        text={t(`${TRANSLATION_PREFIX}.manageEnrollmentProcessQuery`)}
      />
      <ButtonCard
        onClick={() => history.push("/gerenciar/turmas")}
        text={t(`${TRANSLATION_PREFIX}.classAssignment`)}
      />
      <ButtonCard
        onClick={() => history.push("/enturmamento")}
        text={t(`${TRANSLATION_PREFIX}.classesManagement`)}
      />
      <ButtonCard
        onClick={() => history.push("/autorizacao-de-saida")}
        text={t(`${TRANSLATION_PREFIX}.exitReport`)}
      />
      <ButtonCard
        onClick={() => history.push("/consultar-pedidos-alimentacao")}
        text={t(`${TRANSLATION_PREFIX}.consultFoods`)}
      />
      <ButtonCard
        onClick={() => history.push("/publicacao-boletim")}
        text={t(`${TRANSLATION_PREFIX}.publishBoletim`)}
      />
      <ButtonCard
        onClick={() => history.push("/roteiros-de-estudo/estudante")}
        text={t(`${TRANSLATION_PREFIX}.studyItinerary`)}
      />
      <ButtonCard
        onClick={() => history.push("/responsavel/busca")}
        text={t(`${TRANSLATION_PREFIX}.searchGuardianOrStudent`)}
      />
      {IsDevelopmentEnvironment() && (
        <ButtonCard
          onClick={() => history.push("/conselho-classe")}
          text={t(`${TRANSLATION_PREFIX}.classCouncil`)}
        />
      )}
    </DirectorContainer>
  );
};
