import { useCallback, useEffect, useState } from "react";
import HTTP_STATUS from "http-status-codes";
import { useLocation } from "react-router-dom";
import { toastError } from "components/Toast";
import { getUserDocuments } from "services/documents.service";
import { ILocaltionProps, IUserDocumentsData } from "./types";

export const useUserDocuments = () => {
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(true);
  const [userDocuments, setUserDocuments] = useState<IUserDocumentsData | null>(
    null
  );
  const [year, setYear] = useState<number | null>(null);

  const { state } = useLocation<ILocaltionProps>();

  const handleUserDocuments = useCallback(
    async (year) => {
      const userDocumentsData = await getUserDocuments(state.student.id, year);

      if (userDocumentsData?.status === HTTP_STATUS.OK) {
        setUserDocuments(userDocumentsData.data);
      } else {
        toastError("Erro ao carregar os documentos do aluno.");
      }
    },
    [state.student.id]
  );

  useEffect(() => {
    (async () => {
      setIsLoadingDocuments(true);

      await handleUserDocuments(year);

      setIsLoadingDocuments(false);
    })();
  }, [handleUserDocuments, year]);

  return {
    isLoadingDocuments,
    userDocuments,
    setUserDocuments,
    setYear,
    year,
  };
};
