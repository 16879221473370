import { useCallback, useState, useContext } from "react";
import { CheckboxInput, InputCheckbox, Checkbox, Text } from "components/atoms";
import { FoodContext } from "context/foodContext";
import { IFoodOption, IFoodOptionPackage } from "./interface";
import { Field } from "react-final-form";
import * as S from "./styles";
import { formatMoney } from "helpers/helpers";
import { IFoodContextType } from "context/foodContext/type";

export const FoodOption = ({
  name,
  value,
  price,
  hasPlan,
  ...rest
}: IFoodOption) => {
  const [isSelected, setIsSelected] = useState(false);
  const { selectedFoodItems, setSelectedFoodItems } = useContext(
    FoodContext
  ) as IFoodContextType;

  const handleSelectedCard = useCallback(() => {
    setIsSelected((prevState) => !prevState);
  }, []);

  const handleSetSelectedFoodItems = () => {
    const newItem = {
      id: value,
      name,
      price,
      qtd: 0,
    };

    handleSelectedCard();

    if (selectedFoodItems?.find((item: any) => item.id === value)) {
      setSelectedFoodItems((prevState: any) =>
        prevState.filter((item: any) => item.id !== value)
      );
    } else {
      setSelectedFoodItems((prevState: any) => [...prevState, newItem]);
    }
  };

  return (
    <S.Container selected={isSelected} hasPlan={hasPlan}>
      <label htmlFor={name}>
        <strong>{name}</strong>
        <p className="m-0">
          {hasPlan
            ? "Item já adquirido"
            : `Valor unitário: ${formatMoney(Number(price), true)}`}
        </p>
      </label>

      <InputCheckbox
        name={name}
        value={value}
        onClick={handleSetSelectedFoodItems}
        hasPlan={hasPlan}
        {...rest}
      />
    </S.Container>
  );
};

export const FoodOptionPackage = ({
  name,
  value,
  valueMonth,
  disabled,
  checked,
  onChange,
  ...rest
}: IFoodOptionPackage) => {
  const [isSelected, setIsSelected] = useState(false);

  const handleSelectedCard = useCallback(() => {
    setIsSelected((prevState) => !prevState);
  }, []);

  return (
    <S.FoodOptionPackageStyle
      selected={isSelected || checked}
      disabled={disabled}
    >
      <label htmlFor={name}>
        <div className="sameLine">
          <div>
            <Text fontSize="16px" fontWeight="700" lineHeight="24px">
              {name}
            </Text>
          </div>
          <div onChange={handleSelectedCard}>
            <CheckboxInput
              name={name}
              value={value}
              defaultChecked={checked}
              disabled={disabled}
              {...rest}
              onChange={onChange}
            />
          </div>
        </div>
        <Text fontSize="14px" fontWeight="400" lineHeight="21px">
          {formatMoney(valueMonth)}
        </Text>
        <Text fontSize="14px" fontWeight="400" lineHeight="21px">
          Valor unitário: R${value}
        </Text>
      </label>
    </S.FoodOptionPackageStyle>
  );
};
