import styled from "styled-components";

export const LabelFloat = styled.div`
  position: relative;
  padding-top: 13px;
  margin-top: 1em;

  &.invalid {
    margin-bottom: 2em;
  }

  &.searchInput {
    margin-top: 0px;
  }

  input {
    outline: none;
    font-size: 16px;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -webkit-appearance: none;
    border-radius: 0;
    &:disabled {
      background-color: #d3d3d3;
    }
  }

  label {
    color: #666666;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0;
    font-size: 14px;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;

    &.invalid {
      color: #b00020;
    }
  }

  input:focus + span + label,
  input:focus + label,
  input:not(:placeholder-shown) + label,
  input:not(:placeholder-shown) + span + label,
  input:-webkit-autofill + label,
  input:-webkit-autofill + span + label {
    font-size: 12px;
    margin-top: -2em;
  }
`;

export const InputField = styled.input`
  font-size: 14px;
  font-weight: normal;
  background-color: white;
  width: 100%;
  height: 38px;
  color: #42474a;
  border: none;
  border-bottom: 1px solid #cccccc;
  padding-left: 0.5em;
  :focus {
    outline: none;
    border-bottom: 2px solid #1a1a1a;
  }
  ::placeholder {
    color: transparent;
  }
  &.invalid {
    border-bottom: 2px solid #b00020;
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  :[type=checkbox]+label  {
    display: block;
    margin: 0.2em;
    cursor: pointer;
    padding: 0.2em;
  }

  &.fullBorder {
    border: 1px solid;
    border-radius: 6px;
  }

  &.searchInput {
    color: #000;

    height: 40px;

    border: none;
    border-radius: 5px;

    background: rgb(250, 250, 250);
  }
`;

export const EyeIcon = styled.span`
  cursor: pointer;
  width: 17.5px;
  border-bottom: 1px solid #cccccc;
`;

export const Label = styled.label`
  padding-top: 20px;
  color: #000;
  font-size: 14px;

  &.fullBorder {
    padding-left: 5px;
  }

  &.searchInput {
    display: flex;
    padding-top: 23px;
    padding-left: 5px;
  }
`;

export const ErrorLabel = styled.label`
  font-size: 12px !important;
  font-weight: normal;
  color: #b00020 !important;
  width: 100%;
  top: 4.5em !important;
`;
