import styled, { css } from "styled-components";
import { IBoxDynamicStyled } from "./interface";

export const BoxDinamycContainer = styled.div<IBoxDynamicStyled>`
  width: 472px;
  height: 156px;
  border: 1px solid ${({ theme }) => theme.colors.primaryDefault};

  padding: ${({ theme }) => theme.space[6]} ${({ theme }) => theme.space[4]};

  .dinamyc {
    display: flex;
    align-items: center;

    justify-content: space-between;

    margin: 0;
    padding: 0;

    span {
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      font-size: ${({ theme }) => theme.fontSizes.web.default};
      line-height: ${({ theme }) => theme.lineHeights.taller};
      color: ${({ theme }) => theme.colors.greyScale95};
    }

    .label {
      display: flex;
      align-items: center;
    }
  }

  .data {
    display: flex;
    flex-direction: column;

    gap: ${({ theme }) => theme.space[1]} 0;

    div {
      span:last-child {
        margin-left: ${({ theme }) => theme.space[2]};
      }
    }

    span {
      font-size: ${({ theme }) => theme.fontSizes.mobile.sm};
      line-height: 18px; // nao tem na lib
      color: ${({ theme }) => theme.colors.greyScale95};
    }

    .units {
      display: flex;
      align-items: center;

      span {
        font-weight: ${({ theme }) => theme.fontWeights.regular};
        font-size: ${({ theme }) => theme.fontSizes.mobile.sm};
        line-height: 18px; // nao tem na lib

        color: #999999; // nao tem na lib
        word-wrap: break-word;
      }
    }
  }

  ${({ editPermission }) =>
    editPermission &&
    css`
      cursor: pointer;
    `}

  @media (max-width: 360px) {
    margin-top: ${({ theme }) => theme.space[4]};
    width: 100%;
    height: 200px;
  }
`;
