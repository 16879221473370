// React
import { useEffect, useRef, useState } from "react";
// Styles
import * as S from "./style";
// Component
import { FeatureEnabler, Title } from "components";
import { DRAWER_LIST } from "components/Drawer/constants";
import { ButtonCard } from "components/atoms/ButtonCard";
import { Loading } from "components/atoms/Loading";
// Libs
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
// Context
import { useBulletin } from "context/Bulletin";
import { getCXUser } from "services/users.service";
import HTTP_STATUS from "http-status-codes";
import { GetCXUserJson } from "interfaces/constants";
import {
  Componente,
  GetUserKanataResponse,
  getSpecializations,
  getUserKanata,
} from "services/grades.service";
import { ArrowCircleRight, CheckCircle } from "@escolamais-ui-v2/icons";
import { theme } from "@escolamais-ui-v2/tokens/src";
import { useAllowedUnits } from "hooks/useAllowedUnits";
import { Storage } from "Storage";
import { useAtomValue, useSetAtom } from "jotai";
import { authUserAtom, userInfoAtom } from "jotai/authUser";

type GetCXUserJsonType = {
  data: GetCXUserJson;
  status: number;
};

export const StudentHome = () => {
  const setUserInfo = useSetAtom(userInfoAtom);
  const authUser = useAtomValue(authUserAtom);
  const {
    handleKanataStudentId,
    handleStudentId,
    studentOn,
    userKatana,
    error,
    handleDataBulletin,
    setQuarter,
    quarter,
    quarters,
  } = useBulletin();
  const { t } = useTranslation();
  const history = useHistory();
  const role = Storage.props.role.get();
  const [user, setUser] = useState<GetCXUserJson | null>(null);
  const userKanataRef = useRef<GetUserKanataResponse | null>(null);
  const { verifyUserPermissionToAccessFeature } = useAllowedUnits(
    "roteiros-de-estudo-unidades"
  );
  const [component, setComponent] = useState<Componente | undefined>(undefined);
  const [isUserAllowedToAccessFeature, setIsUserAllowedToAccessFeature] =
    useState<boolean>(false);
  const loading = error || userKatana ? false : true;

  const prefix = "pages.CORE.home.student";

  const handleClick = (route: string, text: string) => {
    history.push(route!);
  };

  const shouldRenderStudentItineraryFeature = async () => {
    const isUserAllowed = await verifyUserPermissionToAccessFeature(
      user?.enrollment[0]?.unit ?? 0
    );

    if (isUserAllowed) {
      return setIsUserAllowedToAccessFeature(
        isUserAllowed &&
          user?.enrollment[0]?.educational_level_name !== "Fundamental 1" &&
          user?.enrollment[0]?.grade !== 3
      );
    }

    return setIsUserAllowedToAccessFeature(isUserAllowed);
  };

  useEffect(() => {
    shouldRenderStudentItineraryFeature();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (authUser) {
      handleKanataStudentId(authUser.id);
    }
  }, [handleKanataStudentId, authUser]);

  useEffect(() => {
    getCXUser({ id: authUser.id }).then(
      ({ data, status }: GetCXUserJsonType) => {
        if (status === HTTP_STATUS.OK) {
          setUser(data);
          setUserInfo(data);

          getUserKanata(data?.enrollment[0]?.student_id).then((userKanata) => {
            if (!userKanata) return;

            userKanataRef.current = userKanata;

            getSpecializations(userKanata.student.enrollment_id).then(
              (component) => {
                if (!component) return;

                setComponent({
                  ...component,
                  enrollment_id: userKanata.student.enrollment_id,
                });
              }
            );
          });
        }
      }
    );
  }, [authUser, setUserInfo]);

  useEffect(() => {
    if (studentOn) {
      handleStudentId(studentOn.last_enrollment?.student_id);
    }
  }, [handleStudentId, studentOn]);

  const handleGoToChooseProjectPage = () => {
    history.push({
      pathname: `/escolher-projeto`,
      state: component,
    });
  };

  const menu = DRAWER_LIST.filter((item) => item.permissions.includes(role.id));

  return (
    <S.Container>
      {loading && (
        <div className="loading">
          <Loading />
        </div>
      )}
      {error && !loading && <div className="error">{t(`${prefix}.error`)}</div>}
      {user && !loading && !error && (
        <>
          <S.HeaderPage>
            <Title>{t(`${prefix}.title`)}</Title>
            <Title orange>
              {t(`${prefix}.subtitle`)} {authUser.name}
            </Title>
          </S.HeaderPage>

          <>
            {component && !component.selecionado && (
              <S.SpecializationsContainer onClick={handleGoToChooseProjectPage}>
                <S.SpecializationsContent>
                  <S.Title>{t(`${prefix}.chooseProjectTitle`)}</S.Title>
                  <S.SubTitle>
                    {t(`${prefix}.chooseProjectSubTitle`)}
                  </S.SubTitle>
                </S.SpecializationsContent>
                <ArrowCircleRight
                  width={20}
                  height={20}
                  fill={theme.colors.statusErrorDefault}
                />
              </S.SpecializationsContainer>
            )}

            {component && component.selecionado && (
              <S.SpecializationsSelectedContainer>
                <CheckCircle
                  width={20}
                  height={20}
                  fill={theme.colors.primaryDefault}
                />
                <S.SpecializationsSelectedContent>
                  <S.Title>{t(`${prefix}.project`)}</S.Title>
                  <S.SubTitle>
                    {component.selecionado[0].especializacao__nome}
                  </S.SubTitle>
                </S.SpecializationsSelectedContent>
              </S.SpecializationsSelectedContainer>
            )}
          </>

          <Title darkGray fontSize="20px" lineHeight="30px" fontWeight="700">
            {t(`${prefix}.bookmark`)}
          </Title>

          <S.Content>
            {menu &&
              quarters &&
              userKatana &&
              menu.map(
                (item) =>
                  item.text !== "Home" &&
                  item.text !== "Logout" && (
                    <ButtonCard
                      key={`${item.text}-${item.route}`}
                      onClick={() => {
                        handleDataBulletin({
                          id: userKatana.id,
                          quarter: quarters[0].id,
                        });
                        setQuarter({
                          values: [...quarter.values],
                          selected: quarters[0].name,
                        });
                        handleClick(item.route, item.text);
                      }}
                      text={item.text}
                    />
                  )
              )}

            <FeatureEnabler
              enablerCallback={() => isUserAllowedToAccessFeature}
            >
              <ButtonCard
                onClick={() =>
                  history.push({
                    pathname: "/roteiros-de-estudos",
                    state: {
                      userStudent: user,
                      userKanata: userKanataRef.current,
                    },
                  })
                }
                text={t(`${prefix}.itineraries`)}
              />
            </FeatureEnabler>
          </S.Content>
        </>
      )}
    </S.Container>
  );
};
