/* eslint-disable array-callback-return */
/* eslint-disable no-sequences */
/* eslint-disable no-restricted-syntax */
import * as Style from "./EditClassesManagement.style";

import { useTranslation } from "react-i18next";

import { Title, TableRow, TableColumn, Loading } from "components/atoms";
import { TableBulletin, Header } from "components/molecules";
import { PainelClassesManagement } from "components";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { KanataAPI } from "services/api";
import { toastError, toastSuccess } from "components/Toast";

import HTTP_STATUS from "http-status-codes";
import { useAtomValue } from "jotai";
import { authUserAtom } from "jotai/authUser";
import {
  DataProps,
  ItemPayload,
  handleEditClassProps,
} from "./EditClassesManagement.type";
import { useClassesManagement } from "./EditClassesManagement.helper";

const dataAtual = new Date();
const anoAtual = dataAtual.getFullYear();

interface LocationInterface {
  id: number;
}

export const EditClassesManagement = () => {
  const { t } = useTranslation();
  const authUser = useAtomValue(authUserAtom);
  const location = useLocation<LocationInterface>();
  const [saveList, setSaveList] = useState<ItemPayload[]>([]);
  const [selected, setSelected] = useState("Selecione");
  const { handleLoadData, loaded, data, panel, setPanel } =
    useClassesManagement();

  const infoSection = data !== undefined && data.opcoes_secao;
  const studantsList = data !== undefined && data.estudantes;
  const opcoesGenero = data !== undefined && data.opcoes_genero;

  const [edited, setEdited] = useState<Number[]>([]);

  const infoThead = infoSection && [
    "Estudante",
    `Turma ${anoAtual - 1}`,
    "Gênero",
    "Idade",
    ...infoSection,
  ];

  const handleEditClass = useCallback(
    ({ id, value }: handleEditClassProps) => {
      if (data) {
        const list = data;
        let newList: ItemPayload[] = [];

        const estudante = list.estudantes.find(
          (estudante) => estudante.enrollment_id === id
        );

        if (estudante) {
          estudante.nova_turma = value;

          if (saveList.length === 0) {
            newList.push({
              enrollment_id: estudante?.enrollment_id,
              secao: estudante?.nova_turma,
              genero: estudante?.genero,
            });
          } else {
            newList = [...saveList];
            newList.push({
              enrollment_id: estudante?.enrollment_id,
              secao: estudante?.nova_turma,
              genero: estudante?.genero,
            });
          }
        }
        setSaveList(newList);
        setPanel(list);
      }
    },
    [data, setPanel, saveList]
  );

  const handleEditGender = useCallback(
    (id: number, value: number) => {
      setEdited((prevState) => [...prevState, id]);

      if (data) {
        const list = data;
        const estudante = list.estudantes.find(
          (estudante) => estudante.enrollment_id === id
        );
        if (estudante) {
          estudante.genero = value;
        }

        setPanel(list);
      }
    },
    [data, setPanel]
  );

  const handleGender = (value: number, data: DataProps) => {
    const { opcoes_genero = [] } = data;
    for (const genero of opcoes_genero) {
      if (value === genero.value) {
        return genero.label;
      }
    }
  };

  const handleSaveData = useCallback(
    (payload: ItemPayload[]) => {
      if (payload) {
        KanataAPI.post(`/cx/enturmamento/save/`, [...payload])
          .then((res) => {
            if (res.status === HTTP_STATUS.OK) {
              toastSuccess("Dados salvos com sucesso");
              setEdited([]);
              handleLoadData({
                id: location.state.id,
                year: anoAtual,
                unit: authUser.unit,
              });
            }
          })
          .catch((err) => {
            toastError("Ocorreu algum erro ao salvar os dados");
            throw err?.response;
          })
          .finally(() => {});
      }
    },
    [authUser.unit, handleLoadData, location.state.id]
  );

  const haderInfo = {
    type: "",
    text: "Salvar",
    icon: "/icons/save-white.svg",
    onAction: () => handleSaveData([...saveList]),
  };

  useEffect(() => {
    handleLoadData({
      id: location.state.id,
      year: anoAtual,
      unit: authUser.unit,
    });
  }, [authUser.unit, handleLoadData, location.state.id]);

  if (!loaded) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <Style.Container>
      <Style.Wrapper>
        <Header title="Enturmamento" action={haderInfo} />
        <Title orange>{data?.name}</Title>
        <PainelClassesManagement data={data} />
        <TableBulletin
          scroll
          theadChildren={
            <>
              {infoThead &&
                infoThead.map((item) => (
                  <TableRow
                    key={item}
                    item={item}
                    visible={{ mobile: true, desktop: true }}
                  />
                ))}
            </>
          }
          tbodyChildren={
            <>
              {studantsList &&
                studantsList.map(
                  (item, indice) =>
                    item && (
                      <tr key={item.name}>
                        <TableColumn
                          item={item.name}
                          visible={{ mobile: true, desktop: true }}
                        />
                        <TableColumn
                          item={
                            item.turma_anterior === "NOVO" ? (
                              <Style.Tag>NOVO</Style.Tag>
                            ) : (
                              <p>{item.turma_anterior}</p>
                            )
                          }
                          visible={{ mobile: true, desktop: true }}
                        />
                        <TableColumn
                          item={
                            data &&
                            (item.genero === null ||
                              edited.includes(item.enrollment_id)) ? (
                              <>
                                <select
                                  onChange={(e) => (
                                    setSelected(e.currentTarget.value),
                                    handleEditGender(
                                      item.enrollment_id,
                                      Number(e.currentTarget.value)
                                    )
                                  )}
                                >
                                  <option value="" disabled selected>
                                    Selecionar
                                  </option>
                                  {opcoesGenero &&
                                    opcoesGenero.map((item) => (
                                      <option
                                        key={item.value}
                                        value={item.value}
                                      >
                                        {item.label}
                                      </option>
                                    ))}
                                </select>
                              </>
                            ) : (
                              data && handleGender(item.genero, data)
                            )
                          }
                          visible={{ mobile: true, desktop: true }}
                        />
                        <TableColumn
                          item={item.idade}
                          visible={{ mobile: true, desktop: true }}
                        />
                        {infoSection &&
                          infoSection.map((info, index) => (
                            <TableColumn
                              key={`${info}-${index}`}
                              item={
                                <>
                                  {item.genero !== null && (
                                    <Style.RadioButton
                                      type="checkbox"
                                      value={info}
                                      checked={item.nova_turma === info}
                                      onClick={(e) =>
                                        handleEditClass({
                                          id: item.enrollment_id,
                                          value: e.currentTarget.value,
                                        })
                                      }
                                    />
                                  )}
                                </>
                              }
                              visible={{ mobile: true, desktop: true }}
                            />
                          ))}
                      </tr>
                    )
                )}
            </>
          }
        />
      </Style.Wrapper>
    </Style.Container>
  );
};
