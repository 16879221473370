import { NewTdStyle } from "./style";

interface INewTable {
  name: string;
  height?: number;
  width?: number;
}

export const NewTd = ({ name, height = 37, width = 147 }: INewTable) => {
  return (
    <NewTdStyle height={height} width={width}>
      {name}
    </NewTdStyle>
  );
};
