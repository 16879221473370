import { z } from "zod";
import { readFileAsBase64 } from "helpers/helpers";
import { IStudentDocumentsSignup } from "services/student.service";

export const genderEnum = ["masculino", "feminino"];

const MAX_FILE_SIZE = 15 * 1024 * 1024;

export const createStudentSchema = z
  .object({
    nome: z
      .string()
      .nonempty("O nome é obrigatório!")
      .transform((name) => {
        return name
          .trim()
          .split(" ")
          .map((word) => word[0].toLocaleUpperCase().concat(word.substring(1)))
          .join(" ");
      }),
    cpf: z
      .string()
      .min(11, "Informe os 11 dígitos do CPF corretamente")
      .transform((value) => value.replace(/[^a-zA-Z0-9 ]/g, "")),
    rg: z
      .string()
      .min(9, "Informe os 9 dígitos do RG corretamente")
      .transform((value) => value.replace(/[^a-zA-Z0-9 ]/g, "")),
    data_nascimento: z.string().min(1, "Informe a data de nascimento"),
    genero: z.enum(["masculino", "feminino"], {
      errorMap: () => {
        return { message: "Informe o gênero do estudante" };
      },
    }),
    arquivos_documento_identificacao: z
      .instanceof(FileList)
      .refine(
        (files) => files?.length <= 2,
        "O número máximo de arquivos (2) foi excedido."
      )
      .refine(
        (files) =>
          Array.prototype.slice
            .call(files)
            .every((file: File) => file.size < MAX_FILE_SIZE),
        "O tamanho máximo do arquivo (15mb) foi excedido."
      )
      .transform(async (files) => {
        const fileList: IStudentDocumentsSignup[] = [];

        for (let i = 0; i < files.length; i++) {
          const file = files[i];

          // eslint-disable-next-line no-await-in-loop
          const base64Data = await readFileAsBase64(file);

          fileList.push({
            nome: file.name,
            arquivo: `${base64Data}`,
          });
        }

        return fileList;
      }),
  })
  .transform((field) => ({
    nome: field.nome,
    data_nascimento: field.data_nascimento,
    cpf: field.cpf,
    rg: field.rg,
    genero: field.genero,
    arquivos_documento_identificacao: field.arquivos_documento_identificacao,
  }));

export type createStudentFormData = z.infer<typeof createStudentSchema>;
