import styled from "styled-components";
import * as colors from "styles/colors";

export const Container = styled.div`
  max-width: 1120px;
  margin: 0 auto;

  h1 {
    text-align: center;

    @media (max-width: 576px) {
      text-align: left;
    }
  }

  .foodOptions {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 24px;

    .option {
      display: flex;
      align-items: center;
      border: 2px solid ${colors.NEW_PURPLE};
      padding: 16px;

      .infos {
        display: flex;
        flex-direction: column;

        strong {
          font-size: 16px;
        }
      }

      button {
        max-width: 104px;
        margin-left: auto;
        height: 37px;
      }

      @media (max-width: 1440px) {
        flex-direction: column;
      }
    }

    @media (max-width: 1440px) {
      flex-direction: column;
    }
  }

  @media (max-width: 1440px) {
    margin: 0 16px;
  }
`;
