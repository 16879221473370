import styled from "styled-components";
import * as colors from "styles/colors";
import { IInputCheckBoxStyles } from "./interface";

interface CheckboxStyledInterface {
  disabled?: boolean;
}

export const CheckboxLabelStyled = styled.label<CheckboxStyledInterface>`
  width: 20px;
  height: 20px;
  label.container {
    ${(props) =>
      props.disabled ? "cursor: not-allowed;" : "cursor : pointer;"}
  }

  .container input.checkboxBrowser {
    ${(props) =>
      props.disabled ? "cursor: not-allowed;" : "cursor : pointer;"}
    position: absolute;
    display: none;
  }

  div.check {
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: 2px solid;
    border-color: ${(props) =>
      props.disabled ? `${colors.GRAY_SCALE}` : `${colors.NEW_PURPLE}`};
    margin-left: -14px;
    ${(props) =>
      props.disabled ? "cursor: not-allowed;" : "cursor : pointer;"}
  }

  .container input.checkboxBrowser:checked ~ .check {
    ${(props) =>
      props.disabled ? "cursor: not-allowed;" : "cursor : pointer;"}
  }

  .container .check:after {
    width: 6px;
    height: 11px;
    border: solid 2px;
    border-color: ${(props) =>
      props.disabled ? `${colors.GRAY_SCALE}` : `${colors.NEW_PURPLE}`};
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .check::after {
    content: "";
    margin-left: 4.5px;
    margin-top: 1px;
    display: none;
  }

  .container input:checked ~ .check:after {
    display: block;
  }
`;

export const Container = styled.div<IInputCheckBoxStyles>`
  input[type="checkbox"] {
    appearance: none;
    height: 25px;
    width: 25px;
    cursor: pointer;
    border: 2px solid
      ${({ hasPlan }) => (hasPlan ? colors.GRAY_SCALE : colors.NEW_PURPLE)};
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  input[type="checkbox"]:before,
  input[type="checkbox"]:disabled:before {
    content: "";
    position: absolute;
    height: 15px;
    width: 10px;
    background: none;

    border-right: 4px solid
      ${({ hasPlan }) => (hasPlan ? colors.GRAY_SCALE : colors.NEW_PURPLE)};
    border-bottom: 4px solid
      ${({ hasPlan }) => (hasPlan ? colors.GRAY_SCALE : colors.NEW_PURPLE)};

    opacity: 0;
    transition: all 200ms ease-in-out;
    top: 1px;

    transform: rotate(45deg);
  }

  input[type="checkbox"]:checked:before,
  input[type="checkbox"]:disabled:before {
    opacity: 1;
  }
`;
