import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: none;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1360px;
  display: flex;
  flex-direction: column;

  padding: 0 16px;
`;

export const Tag = styled.div`
  width: 59px;
  height: 29px;
  background: ${({ theme }) => theme.colors.primaryDefault};
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
  text-align: center;
  border-radius: 8px;
  line-height: 29px;
  font-weight: bold;
`;

export const RadioButton = styled.input`
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  width: 1.25em;
  height: 1.25em;
  border: 2px solid ${({ theme }) => theme.colors.primaryDefault};
  border-radius: 50%;
  ::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 0.75em;
    height: 0.75em;
    margin: 1px;
  }
  :hover {
    ::after {
      background-color: ${({ theme }) => theme.colors.greyScale10};
    }
  }
  :focus {
    outline: 2px solid ${({ theme }) => theme.colors.primaryDefault};
  }
  :checked {
    ::after {
      background-color: ${({ theme }) => theme.colors.primaryDefault};
    }
    :hover {
      background-color: ${({ theme }) => theme.colors.white};
      border: 2px solid ${({ theme }) => theme.colors.primaryDefault};
      ::after {
        background-color: ${({ theme }) => theme.colors.primaryDefault};
      }
    }
  }
`;
