// React
import { useEffect, useState, useContext } from "react";
// Style
import { Step2Styled } from "./style";
// Interfaces
import { FoodItemInterface, FoodPackage } from "interfaces/mealInterfaces";
// Helpers
import { formatMoney } from "helpers/helpers";
// Components
import { Text } from "components";
import { Button, CheckboxInput, Postite } from "components/atoms";
import { toastError, toastSuccess } from "components/Toast";
// Services
import { addFoodPackage } from "services/meals.service";
// Libs
import HTTP_STATUS from "http-status-codes";
import { Switch } from "@material-ui/core";
import { useTranslation } from "react-i18next";

type Step2Type = {
  packageItems: Array<FoodItemInterface>;
  packageValue: number;
  foodPackage: FoodPackage;
  smart?: boolean;
  useCreditValue: boolean;
  setActiveStep: (activeStep: number) => void;
  setPackageValue: (packageValue: number) => void;
  setNewPackage: (newPackage: boolean) => void;
  setUseCreditValue: (useCreditValue: boolean) => void;
};

export const Step2 = ({
  packageItems,
  packageValue,
  foodPackage,
  smart,
  useCreditValue,
  setActiveStep,
  setPackageValue,
  setNewPackage,
  setUseCreditValue,
}: Step2Type) => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.foodOption.components.step2";

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [foodItemsId, setFoodItemsId] = useState<Array<number>>([]);
  const [savedAsFavorite, setSavedAsFavorite] = useState(false);

  //Credit
  const [totalValue, setTotalValue] = useState<number>();
  const [packageValueBelowCredit, setPackageValueBelowCredit] = useState(false);
  const [creditValue, setCreditValue] = useState<number>();

  useEffect(() => {
    if (packageItems.length > 0) {
      const itemsId = (food_items: Array<FoodItemInterface>) => {
        const foodItems: Array<number> = [];
        food_items.forEach((foodItem) => foodItems.push(foodItem.id));
        return setFoodItemsId(foodItems);
      };
      itemsId(packageItems);

      setTotalValue(packageValue * foodPackage.working_days);

      if (packageValue * foodPackage.working_days < foodPackage.food_balance) {
        setPackageValueBelowCredit(true);
        setCreditValue(
          foodPackage.food_balance - packageValue * foodPackage.working_days
        );
      }
    } else {
      setError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foodPackage.working_days, packageItems, packageValue]);

  const onSubmit = (values: boolean) => {
    if (values) {
      setLoading(true);
      const reserveFoodPackage = async () => {
        const response = await addFoodPackage({
          payload: {
            food_items: foodItemsId,
            enrollment_id: foodPackage.enrollment_id,
            saved_as_favorite: savedAsFavorite,
            used_available_balance: useCreditValue,
          },
        });
        if (
          response &&
          (response.status === HTTP_STATUS.CREATED ||
            response.status === HTTP_STATUS.OK)
        ) {
          toastSuccess(t(`${TRANSLATE}.toastSuccess`));
          setLoading(false);
          // Manter regra para aluno com bolsa smart
          setNewPackage(true);
          setActiveStep(totalValue && totalValue <= 0 ? 4 : 2);
        } else {
          toastError(t(`${TRANSLATE}.toastError`));
          setLoading(false);
        }
      };
      reserveFoodPackage();
    } else {
      setActiveStep(0);
      setPackageValue(0);
    }
  };
  const setCredits = () => {
    if (useCreditValue) {
      setUseCreditValue(false);
      setTotalValue(packageValue * foodPackage.working_days);
    } else {
      setUseCreditValue(true);
      setTotalValue(
        packageValue * foodPackage.working_days - foodPackage.food_balance
      );
    }
  };

  return (
    <Step2Styled>
      {error && <div>{t(`${TRANSLATE}.packageError`)}</div>}
      {!error && packageItems && (
        <>
          <div className="packageInfos">
            <Text purple fontSize="18px" fontWeight="600" lineHeight="27px">
              {t(`${TRANSLATE}.monthlyPackage`)}
              <span className="monthName">{foodPackage.month_display}</span>
            </Text>
            <div className="foodItems">
              {packageItems.map((foodItem) => {
                return (
                  <div className="packageItems" key={foodItem.id}>
                    <Text fontSize="12px" fontWeight="400" lineHeight="18px">
                      {foodItem.name}
                    </Text>
                    <Text fontSize="12px" fontWeight="400" lineHeight="18px">
                      {formatMoney(parseInt(foodItem.value_package))}
                    </Text>
                  </div>
                );
              })}
            </div>
            <div className="workingDays">
              <Text
                fontSize="12px"
                fontWeight="400"
                lineHeight="18px"
                className="workingDaysText"
              >
                x{foodPackage.working_days} {t(`${TRANSLATE}.workingDays`)}
              </Text>
            </div>
            <div className="valueTotal">
              <Text fontSize="18px" fontWeight="700" lineHeight="27px">
                {foodPackage.food_balance > 0
                  ? t(`${TRANSLATE}.subTotal`)
                  : t(`${TRANSLATE}.total`)}
              </Text>
              <Text fontSize="18px" fontWeight="700" lineHeight="27px">
                {foodPackage.food_balance > 0
                  ? formatMoney(packageValue * foodPackage.working_days)
                  : totalValue
                  ? formatMoney(totalValue)
                  : "R$ 0,00"}
              </Text>
            </div>
          </div>
          {foodPackage.food_balance > 0 && (
            <div className="credit">
              <div className="switchInfos">
                <div className="switch">
                  <Switch onChange={() => setCredits()} />{" "}
                  <Text fontSize="14px" fontWeight="400" lineHeight="21px">
                    {t(`${TRANSLATE}.credit`)}
                  </Text>
                </div>
                <div className="textCreditValue">
                  <Text fontSize="14px" fontWeight="400" lineHeight="21px">
                    R${foodPackage.food_balance_formatted}
                  </Text>
                </div>
              </div>
              <div className="creditTotal">
                <div className="totalCredit">
                  <Text
                    purple
                    fontSize="18px"
                    fontWeight="700"
                    lineHeight="27px"
                  >
                    {t(`${TRANSLATE}.total`)}
                  </Text>
                  <Text
                    purple
                    fontSize="18px"
                    fontWeight="700"
                    lineHeight="27px"
                  >
                    {!smart && totalValue
                      ? formatMoney(totalValue <= 0 ? 0 : totalValue)
                      : "R$ 0,00"}
                  </Text>
                </div>
                {packageValueBelowCredit && (
                  <div className="totalCredit remainingCredit">
                    <Text fontSize="12px" fontWeight="400" lineHeight="18px">
                      {t(`${TRANSLATE}.remainingCredit`)}
                    </Text>
                    <Text fontSize="12px" fontWeight="400" lineHeight="18px">
                      {creditValue && formatMoney(creditValue)}
                    </Text>
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="centerDiv">
            <div className="saveFoodPackage">
              <CheckboxInput
                onChange={() =>
                  setSavedAsFavorite(savedAsFavorite ? false : true)
                }
                value="credit"
                name="credit"
              />
              <Text
                fontWeight="400"
                fontSize="14px"
                lineHeight="21px"
                className="savePackageText"
              >
                {t(`${TRANSLATE}.savePackage`)}
              </Text>
            </div>
          </div>
          <div className="centerDiv">
            <Postite
              type="warning"
              title={t(`${TRANSLATE}.postiteWarning`)}
              content={t(`${TRANSLATE}.postiteContent`)}
              size={1}
              titleSize="14px"
              titleWeight="700"
              titleLineHeigth="21px"
              contentSize="14px"
              contentLineHeight="21px"
              contentWeight="400"
            />
          </div>
          <div className="buttons">
            <Button
              width="45%"
              type="button"
              outline
              onClick={() => onSubmit(false)}
            >
              {t(`${TRANSLATE}.buttonBack`)}
            </Button>
            <Button
              width="45%"
              onClick={() => onSubmit(true)}
              disabled={loading}
            >
              {t(`${TRANSLATE}.buttonAdvance`)}
            </Button>
          </div>
        </>
      )}
    </Step2Styled>
  );
};
