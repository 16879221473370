// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";

// Your web app's Firebase configuration
export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: `${
    process.env.REACT_APP_FIREBASE_PROJECT_ID as string
  }.firebaseapp.com`,
  databaseURL: `https://${
    process.env.REACT_APP_FIREBASE_DATABASE_NAME as string
  }.firebaseio.com`,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: `${
    process.env.REACT_APP_FIREBASE_PROJECT_ID as string
  }.appspot.com`,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
export const fireBaseApp = initializeApp(firebaseConfig);
export const firestoreInstance = getFirestore(fireBaseApp);
