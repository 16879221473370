import { ReactNode } from "react";

import * as S from "./style";

interface ModalType {
  children?: ReactNode;
  isOpen: boolean;
  toggle: () => void;
  path: string;
  size?: string;
  radious?: string;
}

export const Modal = (props: ModalType) => {
  return (
    <S.Container>
      {props.isOpen && (
        <S.Overlay onClick={props.toggle} path={props.path}>
          <S.Modal
            radious={props.radious}
            size={props.size}
            onClick={(e) => e.stopPropagation()}
          >
            {props.children}
          </S.Modal>
        </S.Overlay>
      )}
    </S.Container>
  );
};
