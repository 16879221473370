import { Button, TextInput } from "components";
import { CalendarComponent } from "components/Calendar";
import SelectInput from "components/Select";
import { toastError, toastSuccess } from "components/Toast";
import { YESTERDAY } from "helpers/constants";
import {
  composeValidators,
  greaterOrEqualToday,
  required,
} from "helpers/fieldValidators";
import { deepCopy, getError } from "helpers/helpers";
import HTTP_STATUS from "http-status-codes";
import { FoodItemInterface } from "interfaces/mealInterfaces";
import { useContext } from "react";
import { Field, Form } from "react-final-form";
import { useHistory, useLocation } from "react-router-dom";
import { createFoodItem, updateFoodItem } from "services/meals.service";
import { UnitInterface } from "services/units.service";
import loadingGif from "static/img/loading.gif";
import { FormItemDeAlimentacaoStyled } from "./style";

type StateType = {
  foodItems: Array<FoodItemInterface>;
  units: Array<UnitInterface>;
  foodItem?: FoodItemInterface;
};

type ValuesType = {
  unit: string;
  name: string;
  initial_date: string;
  final_date: string;
  value_single: number;
  value_package: number;
};

export const FormItemDeAlimentacao = () => {
  const location = useLocation<StateType>();
  const history = useHistory();

  const EXISTS_AND_ALREADY_STARTED =
    location.state?.foodItem &&
    new Date() > new Date(location.state?.foodItem.initial_date);

  const EXISTS_AND_ENDED =
    location.state?.foodItem &&
    YESTERDAY > new Date(location.state?.foodItem.final_date);

  const onSubmit = async (values: ValuesType) => {
    const values_ = deepCopy(values);
    if (
      typeof values_.final_date === "string" &&
      values_.final_date.includes("T")
    ) {
      values_.final_date = values_.final_date.split("T")[0];
    }
    if (
      typeof values_.initial_date === "string" &&
      values_.initial_date.includes("T")
    ) {
      values_.initial_date = values_.initial_date.split("T")[0];
    }
    if (location.state?.foodItem) {
      const response = await updateFoodItem({
        id: location.state?.foodItem.id,
        body: {
          unit: values_.unit,
          name: values_.name,
          value_single: values_.value_single,
          value_package: values_.value_package,
          initial_date: values_.initial_date,
          final_date: values_.final_date,
        },
      });
      if (response && response.status === HTTP_STATUS.OK) {
        toastSuccess("Item de alimentação atualizado com sucesso!");
        history.goBack();
      } else {
        toastError(getError(response?.data));
      }
    } else {
      const response = await createFoodItem({
        body: values_,
      });
      if (response && response.status === HTTP_STATUS.CREATED) {
        toastSuccess("Item de alimentação criado com sucesso!");
        history.goBack();
      } else {
        toastError(getError(response?.data));
      }
    }
  };

  return (
    <FormItemDeAlimentacaoStyled className="container">
      <div className="title">Formulário de itens de alimentação</div>
      {!location.state && (
        <div>Erro ao carregar nomes de itens de alimentação.</div>
      )}
      {location.state?.foodItems && location.state?.units && (
        <Form
          initialValues={
            location.state?.foodItem && { ...location.state?.foodItem }
          }
          onSubmit={onSubmit}
        >
          {({ form, handleSubmit, submitting, values }) => (
            <form onSubmit={handleSubmit}>
              <Field
                component={SelectInput}
                name="name"
                disabled={location.state?.foodItem}
                required
              >
                <option value="">
                  Selecione um nome de item de alimentação
                </option>
                {[
                  ...new Set(
                    location.state.foodItems.map((foodItem) => foodItem.name)
                  ),
                ].map((foodItemName, index) => {
                  return (
                    <option key={index} value={foodItemName}>
                      {foodItemName}
                    </option>
                  );
                })}
              </Field>
              <Field<string>
                component={SelectInput}
                name="unit"
                disabled={EXISTS_AND_ALREADY_STARTED}
                required
              >
                <option value="">Selecione uma unidade</option>
                {location.state.units.map((unit, key) => {
                  return (
                    <option key={key} value={unit.id}>
                      {unit.name}
                    </option>
                  );
                })}
              </Field>
              <Field
                component={TextInput}
                type="number"
                name="value_single"
                placeholder=" "
                label="Preço avulso"
                validate={required}
                disabled={EXISTS_AND_ALREADY_STARTED}
                required
              />
              <Field
                component={TextInput}
                type="number"
                name="value_package"
                placeholder=" "
                label="Preço no pacote"
                validate={required}
                disabled={EXISTS_AND_ALREADY_STARTED}
                required
              />
              {EXISTS_AND_ALREADY_STARTED ? (
                <Field
                  component={TextInput}
                  type="text"
                  name="initial_date"
                  placeholder=" "
                  label="Data de início da vigência"
                  validate={required}
                  disabled={EXISTS_AND_ALREADY_STARTED}
                  required
                />
              ) : (
                <Field
                  component={CalendarComponent}
                  name="initial_date"
                  placeholder="Data de início da vigência"
                  validate={required}
                  required
                  disabled={EXISTS_AND_ALREADY_STARTED}
                />
              )}
              {EXISTS_AND_ENDED ? (
                <Field
                  component={TextInput}
                  type="text"
                  name="final_date"
                  placeholder=" "
                  label="Data de fim da vigência"
                  validate={required}
                  disabled
                  required
                />
              ) : (
                <Field
                  component={CalendarComponent}
                  name="final_date"
                  placeholder="Data de fim da vigência"
                  validate={composeValidators(required, greaterOrEqualToday)}
                  disabled={EXISTS_AND_ENDED}
                  required
                />
              )}
              <div className="row">
                <div className="col-sm-4 offset-sm-8 ol-12">
                  <Button disabled={submitting} type="submit">
                    {submitting && (
                      <img height="25" src={loadingGif} alt="loading" />
                    )}
                    {!submitting && "Salvar"}
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Form>
      )}
    </FormItemDeAlimentacaoStyled>
  );
};
