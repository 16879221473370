import styled, { css } from "styled-components";
import { ISectionTitleStyles } from "./interface";
import * as colors from "styles/colors";

export const Title = styled.h5<ISectionTitleStyles>`
  margin-top: 4px;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  color: ${colors.NEW_GRAY_ONE};

  ${({ sectionState }) =>
    (sectionState === "Concluído" || sectionState === "Em Andamento") &&
    css`
      color: ${colors.NEW_GRAY_TWO};
    `}
`;
