import styled from "styled-components";

export const UserDataDiv = styled.div`
  @media only screen and (max-width: 414px) {
    margin-top: 2em;
    margin-left: 1em;
  }
  p {
    font-size: 0.875rem;
    span {
      font-weight: bold;
    }
    &.title {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.5;
      margin-bottom: 1rem;
    }
  }
`;
