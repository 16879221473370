import styled from "styled-components";
import * as colors from "styles/colors";

export const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 343px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px;
  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    svg {
      width: 30px;
      height: 30px;
    }
    h2 {
      font-size: 24px;
      font-weight: 700;
      margin-top: 7px;
    }
  }
  .content {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    align-items: left;
    ul {
      /* list-style: none; */
    }
  }
  button {
    background: none;
    border: none;
    outline: none;
    width: fit-content;
    font-weight: 700;
    text-transform: uppercase;
    color: ${colors.NEW_PURPLE};
    margin-left: auto;
    padding: 8px 16px;
  }
`;
