import styled from "styled-components";
import * as colors from "styles/colors";

export const Step2Styled = styled.div`
  div.step2Container {
    padding: 1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  div.packageInfos {
    padding: 1em;
    background: rgba(109, 40, 217, 0.05);
    border-radius: 29px;

    span.monthName {
      text-transform: lowercase;
    }

    div.foodItems {
      margin-top: 1em;
      div.packageItems {
        display: flex;
        justify-content: space-between;
      }
    }

    div.workingDays {
      margin-top: 0.25em;
      display: flex;
      justify-content: flex-end;

      border-bottom: 1px solid ${colors.NEW_GRAY_THREE};

      p.workingDaysText {
        margin-bottom: 1em;
      }
    }

    div.valueTotal {
      display: flex;
      justify-content: space-between;

      margin-top: 1em;

      &.paymentMethod {
        margin-bottom: 0.5em;
      }
    }
  }

  div.credit {
    margin-top: 1em;
    display: flex;
    flex-direction: column;

    div.switchInfos {
      display: flex;
      justify-content: space-between;
      align-items: center;

      div.switch {
        display: flex;
        align-items: center;
      }

      div.textCreditValue {
        margin-right: 1em;
      }
    }

    div.creditTotal {
      background: rgba(109, 40, 217, 0.05);
      border-radius: 29px;

      padding: 1em;
      margin-top: 1em;

      div.totalCredit {
        &.remainingCredit {
          margin-top: 8px;
        }
        display: flex;
        justify-content: space-between;
      }
    }
  }
  div.centerDiv {
    display: flex;
    justify-content: space-around;

    div.saveFoodPackage {
      display: flex;
      margin-top: 1em;

      gap: 0 4px;
    }
  }

  div.buttons {
    margin-top: 7em;
    display: flex;
    justify-content: space-between;
  }
`;
