import styled, { css } from "styled-components";

export const InputContainer = styled.div`
  display: flex;
  flex: 1;
  border: 2px solid #999;
  height: 37px;
`;
export const InputElement = styled.input`
  padding: 8px 0px;
  width: 100%;
  border: none;
  outline: none;
  background: transparent;
`;
export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
`;

interface IconImageProps {
  hasAction?: boolean;
}
export const IconImage = styled.img<IconImageProps>`
  width: 22px;
  ${({ hasAction }) =>
    hasAction &&
    css`
      cursor: pointer;
    `}
`;

export const InputLabel = styled.label`
  text-transform: uppercase;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 21px;
  margin-bottom: 4px;
`;
