import { useFirebase } from "hooks/useFirebase";

const enum CollectionConfigEnum {
  collectionPath = "app-config",
}

interface IAllowedUnits {
  unidades_permitidas: number[];
}

export const useAllowedUnits = (docRef: string) => {
  const { getUniqueDoc } = useFirebase();

  const getAllowedUnits = async () => {
    const givenAllowedUnits = await getUniqueDoc({
      collectionPath: CollectionConfigEnum.collectionPath,
      docRef,
    });
    return givenAllowedUnits as IAllowedUnits;
  };

  const verifyUserPermissionToAccessFeature = async (unit: number) => {
    const allowedUnits = await getAllowedUnits();
    return allowedUnits?.unidades_permitidas.includes(unit);
  };

  return {
    verifyUserPermissionToAccessFeature,
  };
};
