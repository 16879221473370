/* eslint-disable no-sequences */
import {
  createStyles,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
  Typography,
  useTheme,
  Collapse,
} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { ChangeEvent, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { anyDrawerItem, DRAWER_LIST, DRAWER_WIDTH } from "./constants";
import { DrawerStyled } from "./style";
import { getUnitsGrades, UnitGradesInteface } from "services/grades.service";
import { ROLE, isGuardian, isStudent } from "helpers/constants";
import HTTP_STATUS from "http-status-codes";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { caching } from "helpers/clearCache";
import { Logo22Icon } from "static/img";
import { AppVersion } from "components/AppVersion";
import { getCXUser } from "services/users.service";
import { GetCXUserJson } from "interfaces/constants";
import { Storage } from "Storage";
import { useAtom } from "jotai";
import { authUserAtom } from "jotai/authUser";
import { RESET } from "jotai/utils";

type GetCXUserJsonType = {
  data: GetCXUserJson;
  status: number;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: DRAWER_WIDTH,
        flexShrink: 0,
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    avatarSize: {
      width: theme.spacing(15),
      height: theme.spacing(15),
      // marginLeft: theme.spacing(8),
      marginTop: theme.spacing(1),
    },
    divider: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    userName: {
      marginTop: theme.spacing(2),
      textAlign: "center",
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  })
);

interface ResponsiveDrawerInterface {
  mobileOpen: boolean;
  handleDrawerToggle: (mobileOpen: boolean) => void;
}

export const ResponsiveDrawer = ({
  mobileOpen,
  handleDrawerToggle,
}: ResponsiveDrawerInterface) => {
  const [authUser, setAuthUser] = useAtom(authUserAtom);
  const role = Storage.props.role.get();

  const [user, setUser] = useState<GetCXUserJson | null>(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [gradesUnits, setGradesUnits] = useState<Array<UnitGradesInteface>>();

  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const logout = () => {
    setAuthUser(RESET);
    Storage.remove();
    caching();
    history.replace("/");
  };

  useEffect(() => {
    if (isGuardian(role) || isStudent(role)) {
      const token = Storage.token.get();

      if (!token) {
        return;
      }

      getCXUser({ id: authUser.id })
        .then(({ data, status }: GetCXUserJsonType) => {
          if (status === HTTP_STATUS.OK) {
            setUser(data);
          } else {
            setError(true);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (route: string | undefined, text: string | undefined) => {
    // TODO: foi necessária a implementação desta validação até que a drawer seja refatorada (bug #TI-1559)
    if (route === "/diario-de-classe") {
      history.push("/diario-de-classe");
    } else {
      history.push({
        pathname: route as string,
        state: {
          student: user,
          role,
        },
      });
    }

    DRAWER_LIST.forEach((item) => {
      if (item.text === text) {
        item.open = !item.open;
      }
    });
  };

  const fetchGradesUnit = async () => {
    const response = await getUnitsGrades();
    if (response && response.status === HTTP_STATUS.OK) {
      setGradesUnits(response.data);
    }
  };

  useEffect(() => {
    if (role.id === ROLE.GRADES_MANAGER || role.id === ROLE.SYSTEM_ADMIN) {
      fetchGradesUnit();
    }
  }, [role.id]);

  const handleSelectRole = (e: ChangeEvent<HTMLSelectElement>) => {
    const retrievedUserRole = authUser.role.find(
      (r) => r.id.toString() === e.target.value
    );
    if (retrievedUserRole) {
      Storage.props.role.set(retrievedUserRole);
      window.location.href = "/home";
    }
  };

  const drawer = (
    <>
      <div>
        <div className="d-flex flex-column align-items-center justify-content-center">
          <Avatar
            alt="AvatarPlaceholder"
            src={Logo22Icon}
            className={`border ${classes.avatarSize}`}
          />
          <Typography className={classes.userName} variant="h6">
            {authUser.name}
          </Typography>
        </div>
        {authUser.role && authUser.role.length > 1 && (
          <div className="mt-3 text-center">
            <select
              style={{ fontSize: "0.875rem" }}
              className="col-11"
              onChange={(e) => handleSelectRole(e)}
              defaultValue={role.id}
            >
              {authUser.role.map((role_, key) => {
                return (
                  <option value={role_.id} key={key}>
                    {role_.label_pt}
                  </option>
                );
              })}
            </select>
          </div>
        )}
        {(role.id === ROLE.GRADES_MANAGER || role.id === ROLE.SYSTEM_ADMIN) && (
          <div className="mt-3 text-center">
            <select
              style={{ fontSize: "0.875rem" }}
              className="col-11"
              onChange={(e) => {
                let newUser = authUser;
                newUser.grade_unit_id = parseInt(e.target.value);
                Storage.props.authUser.set(newUser);
                setAuthUser((prevState) => {
                  const newAuthData = {
                    ...prevState,
                    unit:
                      gradesUnits?.find(
                        (unit) => unit.id === parseInt(e.target.value)
                      )?.unit_id_saf || 1,
                  };
                  return newAuthData;
                });
                history.push("/home");
              }}
              defaultValue={authUser.grade_unit_id}
              value={authUser.grade_unit_id}
            >
              {gradesUnits &&
                gradesUnits.map((unit) => {
                  return (
                    <option value={unit.id} key={unit.id}>
                      {unit.name}
                    </option>
                  );
                })}
            </select>
          </div>
        )}
        <Divider className={classes.divider} />
        {anyDrawerItem(role) &&
          DRAWER_LIST.filter((item) => item.permissions.includes(role.id)).map(
            (item) =>
              item.isCollapse ? (
                <List key={item.text}>
                  <ListItem
                    button
                    key={item.text}
                    onClick={() => handleClick(item.route, item.text)}
                  >
                    <ListItemIcon>
                      <item.Icon />
                    </ListItemIcon>
                    <ListItemText primary={item.text} />
                    {item.open ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={item.open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {item.options.map((item) => (
                        <ListItem
                          button
                          key={item.text}
                          onClick={() => handleClick(item.route, item.text)}
                        >
                          <ListItemIcon>
                            {item.text && <item.Icon />}
                          </ListItemIcon>
                          <ListItemText primary={item.text} />
                        </ListItem>
                      ))}
                    </List>
                  </Collapse>
                </List>
              ) : (
                <List key={item.text}>
                  <ListItem
                    button
                    key={item.text}
                    onClick={() =>
                      item.route === "/logout"
                        ? logout()
                        : handleClick(item.route, item.text)
                    }
                  >
                    <ListItemIcon>
                      <item.Icon />
                    </ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItem>
                </List>
              )
          )}
      </div>
      <AppVersion />
    </>
  );

  return (
    <>
      {/* Essa é a drawer no mobile */}
      <nav className={classes.drawer} aria-label="mailbox folders">
        <DrawerStyled
          className="dd-block d-sm-none h-100"
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          width={DRAWER_WIDTH}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </DrawerStyled>
        {/* Essa é a drawer no desktop */}
        <DrawerStyled
          className="d-none d-sm-block"
          width={DRAWER_WIDTH}
          variant="permanent"
          open
        >
          {drawer}
        </DrawerStyled>
      </nav>
    </>
  );
};

export default ResponsiveDrawer;
