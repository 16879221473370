import { useTranslation } from "react-i18next";

import { PaginationByIndex } from "components";
import { Drawer } from "components/organisms/Drawer";
import { Subtitle } from "../Subtitle";

import { IVacanciesReenrollment } from "./type";

import * as S from "./styles";

export const VacanciesReserved = ({
  visible,
  onClose,
  classesData,
  setClassesData,
  selectedClassesIndex,
  setSelectedClassesIndex,
  selectedClassesData,
}: IVacanciesReenrollment) => {
  const { t } = useTranslation();
  const i18n_prefix = "pages.EDF.manageVacancies.components.vacanciesReserved";

  return (
    <Drawer
      title={t(`${i18n_prefix}.drawerTitle`)}
      isOpen={visible}
      onClose={() => onClose(false)}
      side="right"
      width="796px"
    >
      <PaginationByIndex
        results={classesData}
        index={selectedClassesIndex}
        setIndex={setSelectedClassesIndex}
        name={t(`${i18n_prefix}.paginationByIndexName`, {
          ciclo: selectedClassesData?.ciclo,
          segmento: selectedClassesData?.segmento,
        })}
      />

      <S.Container>
        {/* ESSE TRECHO DE CÓDIGO É PARTE DE UMA OUTRA ESTÓRIA QUE ENTRARÁ EM SPRINS SEGUINTES */}
        {/* <Subtitle title="Lista de vagas reservadas" value={40} />

        <S.TableContent>
          <div className="table-head">
            <p>Nome do estudante</p>
            <p>RA</p>
            <p>Tipo</p>
            <p>Status</p>
          </div>
          <div className="table-data">
            <ul>
              <li>
                <p>[Nome do estudante]</p>
                <p>X-XXXXX-XXXX</p>
                <p>Aluno</p>
                <p>Concluído</p>
              </li>
            </ul>
          </div>
        </S.TableContent>

        <Subtitle title="Vagas reservadas para rematricula" value={20} /> */}
      </S.Container>
    </Drawer>
  );
};
