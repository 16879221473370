// React
import { useCallback } from "react";
// Libs
import { useTranslation } from "react-i18next";
import { Button } from "@escolamais-ui-v2/react";
// Styles
import * as S from "./style";
// Components
import { CheckboxInput } from "components/atoms";
import { Drawer } from "components/organisms/Drawer";
import { DynamicFirstForm } from "./components/DynamicFirstForm";
import { DynamicDetails } from "./components/DynamicDetails";
// Interfaces
import { IDrawerDynamic } from "./interface";
// Helpers
import { DynamicDrawerHelper } from "./helper";

export const DrawerDynamic = ({
  drawer,
  setDrawer,
  units,
  dynamic,
  setDynamic,
  item,
  searchDynamics,
}: IDrawerDynamic) => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.manageFoodItems.components.dynamicDrawer";

  const {
    isNewDynamic,
    setIsNewDynamic,
    form,
    setForm,
    dynamics,
    setDynamics,
    cutName,
    updateField,
    searchUnitAndRemove,
    allUnitsPage,
    createDynamic,
    closeDrawer,
  } = DynamicDrawerHelper({
    units,
    dynamic,
    setDynamic,
    item,
    setDrawer,
    searchDynamics,
  });

  return (
    <Drawer
      isOpen={drawer}
      onClose={() => {
        closeDrawer();
      }}
      title={dynamic ? dynamic.nome : t(`${TRANSLATE}.title`)}
      side="right"
      width="632px"
    >
      <S.DrawerDinamycContainer>
        <S.InputContainer>
          {isNewDynamic ? (
            <DynamicFirstForm
              translate={TRANSLATE}
              dynamics={dynamics}
              form={form}
              setForm={setForm}
              setDynamics={setDynamics}
              updateField={updateField}
            />
          ) : (
            <DynamicDetails
              translate={TRANSLATE}
              form={form}
              dynamics={dynamics}
            />
          )}

          <form>
            <label>{t(`${TRANSLATE}.units`)}</label>
            <div>
              <CheckboxInput
                value=""
                name="all-units"
                onChange={() => {
                  allUnitsPage();
                }}
                disabled={!isNewDynamic}
                checked={form.allUnits}
              />{" "}
              <span>{t(`${TRANSLATE}.allUnits`)}</span>
            </div>
            {units.map((unit, index) => {
              return (
                <div key={index}>
                  <CheckboxInput
                    value={unit.id}
                    name={unit.name}
                    onChange={() => {
                      !form.units.includes(unit.id)
                        ? setForm({
                            ...form,
                            units: [...form.units, unit.id],
                          })
                        : searchUnitAndRemove(unit.id);
                    }}
                    checked={form.units.includes(unit.id) || form.allUnits}
                    disabled={!isNewDynamic}
                  />{" "}
                  <span>{cutName(unit.name)}</span>
                </div>
              );
            })}
          </form>
        </S.InputContainer>

        {isNewDynamic && (
          <S.ButtonsContainer>
            <Button
              appearance="secondary"
              buttonText="Cancelar"
              onClick={() => {
                closeDrawer();
              }}
              size="large"
            />
            <Button
              appearance="primary"
              buttonText="Criar Dinâmica"
              onClick={() => {
                createDynamic();
              }}
              size="large"
            />
          </S.ButtonsContainer>
        )}
      </S.DrawerDinamycContainer>
    </Drawer>
  );
};
