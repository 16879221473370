import { TextInformation } from "./style";

interface IInputFileText {
  description?: string;
  text: string;
  color?: string;
}

export const InputFileTextInformation = ({
  description,
  text,
  color = "#999999",
}: IInputFileText) => {
  return (
    <TextInformation color={color}>
      {description && <p>{description}</p>}
      {text}
    </TextInformation>
  );
};
