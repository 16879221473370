import { useHistory } from "react-router-dom";
import { EMContainer } from "./style";
import { useTranslation } from "react-i18next";
// import { ButtonCard } from "components/atoms/ButtonCard";
import { ButtonCard } from "@escolamais-ui-v2/react";
import { IsDevelopmentEnvironment } from "helpers/helpers";

const TRANSLATE_PREFIX = "pages.CORE.home.enrollmentManager";

export const EMHome = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <EMContainer>
      <ButtonCard
        onClick={() => history.push("/responsavel/busca")}
        text={t(`${TRANSLATE_PREFIX}.searchGuardianOrStudent`)}
      />
      <ButtonCard
        onClick={() => history.push("/gerenciar/matriculas")}
        text={t(`${TRANSLATE_PREFIX}.manageEnrollmentProcessQuery`)}
      />
      <ButtonCard
        onClick={() => history.push("/consulta-plano-pagamento")}
        text={t(`${TRANSLATE_PREFIX}.paymentPlanInquiry`)}
      />
      <ButtonCard
        onClick={() => history.push("/exportar-totvs")}
        text={t(`${TRANSLATE_PREFIX}.exportTotvsData`)}
      />
      <ButtonCard
        onClick={() => history.push("/alimentacao/cancelar-pacote-alimentacao")}
        text={t(`${TRANSLATE_PREFIX}.cancelFoodPackage`)}
      />
      <ButtonCard
        onClick={() => history.push("/alimentacao/consultar-alimentacao")}
        text={t(`${TRANSLATE_PREFIX}.consultSinglePurchase`)}
      />
      <ButtonCard
        onClick={() => history.push("/lista-espera")}
        text={t(`${TRANSLATE_PREFIX}.waitingList`)}
      />
      <ButtonCard
        onClick={() => history.push("/gerar-arquivo-para-cracha")}
        text={t(`${TRANSLATE_PREFIX}.generateBadgeFile`)}
      />
      <ButtonCard
        onClick={() => history.push("/gerenciar-vagas")}
        text={t(`${TRANSLATE_PREFIX}.manageVacancies`)}
      />
      <ButtonCard
        onClick={() => history.push("/cadastrar-periodo-matricula-rematricula")}
        text={t(`${TRANSLATE_PREFIX}.periodEnrollmentReenrollment`)}
      />
      <ButtonCard
        onClick={() => history.push("/plano-de-pagamento")}
        text={t(`${TRANSLATE_PREFIX}.paymentPlan`)}
      />
      {IsDevelopmentEnvironment() && (
        <ButtonCard
          onClick={() => history.push("/buscar-aluno-responsavel")}
          text={t(`${TRANSLATE_PREFIX}.searchGuardianOrStudent`)}
        />
      )}
    </EMContainer>
  );
};
