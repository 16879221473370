import { ReactNode } from "react";
import * as S from "./styles";

interface IStudentBox {
  children: ReactNode;
}

export const StudentBox = ({ children }: IStudentBox) => {
  return <S.Container>{children}</S.Container>;
};
