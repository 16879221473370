import { Button, LoadingBox } from "components";
import { ModalDiv } from "components/UseTerms/styles";
import { formatMoney } from "helpers/helpers";
import { StudentInterface } from "interfaces/constants";
import { useContext, useEffect, useState } from "react";
import {
  getSelectedPlan,
  GetSelectedPlanInterface,
} from "services/plan.service";
import HTTP_STATUS from "http-status-codes";

interface ModalPaymentPlanInterface {
  setToggleModal: (toggleModal: boolean) => void;
  student: StudentInterface;
}

export const ModalPaymentPlan = ({
  setToggleModal,
  student,
}: ModalPaymentPlanInterface) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<GetSelectedPlanInterface>();

  useEffect(() => {
    if (student) {
      const getEnrollmentPlan = async () => {
        const response = await getSelectedPlan({
          enrollmentId: student.last_enrollment.id,
        });
        if (response && response.status === HTTP_STATUS.OK) {
          setSelectedPlan(response.data);
          setLoading(false);
        } else {
          setError(true);
          setLoading(false);
        }
      };
      getEnrollmentPlan();
    }
  }, [student]);

  return (
    <div className="container">
      <ModalDiv>
        <div className="text-center">
          <strong className="title">
            Plano de Pagamento Para Matrícula de {student.name}
          </strong>
        </div>
        {loading && <LoadingBox height={50} />}
        {error && !loading && (
          <div className="text-center">Erro ao carregar plano de pagamento</div>
        )}
        {!error && !loading && selectedPlan && (
          <div>
            <div className="text-center">
              Reserva de vaga: R$ {selectedPlan.reservation_value_formated} (já
              pago) <br />
              Mensalidade(s): {selectedPlan.installments_quantity}x de{" "}
              {formatMoney(selectedPlan.active_monthly_price)} <br />
              Material: {selectedPlan.installments_quantity_material}x de{" "}
              {formatMoney(selectedPlan.active_courseware_price)} <br />
              Data de Pagamento: todo dia {selectedPlan.payment_date} <br />
            </div>
            <div className="text-center">
              No caso de dúvidas, não deixe de entrar em contato com a gente!
              <br />
              Equipe Escola Mais (11)5555-3725
            </div>
          </div>
        )}
        {!loading && (
          <Button onClick={() => setToggleModal(false)} type="button">
            Voltar
          </Button>
        )}
      </ModalDiv>
    </div>
  );
};

export default ModalPaymentPlan;
