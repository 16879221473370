import { LinearProgress } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import { Button, Checkbox, RadioButton } from "components";
import SelectInput from "components/Select";
import { toastError, toastSuccess } from "components/Toast";
import HTTP_STATUS from "http-status-codes";
import { FoodItemInterface } from "interfaces/mealInterfaces";
import { useContext, useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useHistory } from "react-router";
import { deleteFoodItem, getFoodItems } from "services/meals.service";
import { getUnits, UnitInterface } from "services/units.service";
import loadingGif from "static/img/loading.gif";
import { GestaoItensDeAlimentacaoStyled } from "./style";

export * from "./FormItemDeAlimentacao";

export const GestaoItensDeAlimentacao = () => {
  const [units, setUnits] = useState<UnitInterface[]>();
  const [loadingUnits, setLoadingUnits] = useState(true);
  const [foodItems, setFoodItems] = useState<Array<FoodItemInterface>>();
  const [loadingFoodItems, setLoadingFoodItems] = useState(true);
  const [foodItemsTable, setFoodItemsTable] =
    useState<Array<FoodItemInterface>>();
  const [loadingFoodItemsTable, setLoadingFoodItemsTable] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [deletingItem, setDeletingItem] = useState(false);

  const history = useHistory();

  type ValuesType = {
    unit: string;
    type_item: string;
    in_force_true: boolean;
    in_force_false: boolean;
  };

  const onSubmit = async (values: ValuesType) => {
    setLoadingFoodItemsTable(true);
    const response = await getFoodItems({
      unit: values.unit,
      type_item: values.type_item,
      in_force:
        (values.in_force_true === true || values.in_force_false === true) &&
        values.in_force_true !== values.in_force_false
          ? values.in_force_true
            ? true
            : false
          : undefined,
    });
    if (response && response.status === HTTP_STATUS.OK) {
      setFoodItemsTable(response.data);
      setLoadingFoodItemsTable(false);
    } else {
      setErrorMessage(
        "Ocorreu um erro ao carregar a lista de itens de alimentação."
      );
    }
  };

  const onDeleteFoodItem = async (foodItem: FoodItemInterface) => {
    if (window.confirm("Deseja realmente excluir este item de alimentação?")) {
      setDeletingItem(true);
      const response = await deleteFoodItem({
        id: foodItem.id,
      });
      if (response && response.status === HTTP_STATUS.NO_CONTENT) {
        setDeletingItem(false);
        foodItemsTable &&
          setFoodItemsTable(
            foodItemsTable.filter((foodItem_) => foodItem_ !== foodItem)
          );
        toastSuccess("Item de alimentação excluído com sucesso");
      } else {
        toastError(
          response?.data || "Erro ao tentar excluir item de alimentação"
        );
        setDeletingItem(false);
      }
    }
  };

  useEffect(() => {
    const fetchUnidades = async () => {
      const response = await getUnits();
      if (response && response.status === HTTP_STATUS.OK) {
        setUnits(response.data);
        setLoadingUnits(false);
      } else {
        setErrorMessage("Ocorreu um erro ao carregar a lista de unidades.");
      }
    };

    const fetchFoodItems = async () => {
      const response = await getFoodItems({});
      if (response && response.status === HTTP_STATUS.OK) {
        setFoodItems(response.data);
        setLoadingFoodItems(false);
      } else {
        setErrorMessage(
          "Ocorreu um erro ao carregar a lista de itens de alimentação."
        );
      }
    };

    try {
      fetchUnidades();
      fetchFoodItems();
    } catch (err) {
      setErrorMessage("Ocorreu um erro ao carregar os dados para os filtros");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GestaoItensDeAlimentacaoStyled className="container">
      <div className="row">
        <div className="col-6">
          <div className="title">Gestão de itens de alimentação</div>
        </div>
        {foodItems && units && (
          <div className="col-6 text-right mt-auto">
            <span
              onClick={() =>
                history.push({
                  pathname: `/gestao-itens-alimentacao/form`,
                  state: {
                    foodItems,
                    units,
                  },
                })
              }
              className="link"
            >
              Cadastrar novo item de alimentação
            </span>
          </div>
        )}
      </div>
      {loadingUnits && loadingFoodItems && (
        <LinearProgress className="mt-3 mb-3" color="secondary" />
      )}
      {!errorMessage && units && foodItems && (
        <Form onSubmit={onSubmit}>
          {({ form, handleSubmit, submitting, values }) => (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12">
                  <Field<string> component={SelectInput} name="unit" required>
                    <option value="">Selecione uma unidade</option>
                    {units.map((unit, key) => {
                      return (
                        <option key={key} value={unit.id}>
                          {unit.name}
                        </option>
                      );
                    })}
                  </Field>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-3 col-12 my-auto">
                  Itens ativos ou inativos:
                </div>
                <div className="col-md-3 col-12">
                  <Field name="in_force_true" component={Checkbox} />
                  Itens em vigência
                </div>
                <div className="col-md-3 col-12">
                  <Field name="in_force_false" component={Checkbox} />
                  Itens inativos
                </div>
              </div>
              <p className="d-md-none">Itens de alimentação:</p>
              <div className="row mt-3">
                {[...new Set(foodItems.map((foodItem) => foodItem.name))].map(
                  (foodItemName, index) => {
                    return (
                      <div className="col-md-3">
                        <Field
                          name="type_item"
                          component={RadioButton}
                          value={foodItemName}
                          type="radio"
                        />
                        {foodItemName}
                      </div>
                    );
                  }
                )}
              </div>
              <div className="row">
                <Button
                  type="button"
                  onClick={() => form.reset()}
                  className="blue outline col-md-4 col-12"
                >
                  limpar campos
                </Button>
                <Button
                  type="submit"
                  className="offset-md-4 col-md-4 col-12"
                  disabled={submitting}
                >
                  Filtrar
                </Button>
              </div>
              {loadingFoodItemsTable && (
                <LinearProgress className="mt-3 mb-3" color="secondary" />
              )}
              {foodItemsTable && foodItemsTable.length > 0 && (
                <table>
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>Unidade</th>
                      <th>Valor avulso</th>
                      <th>Valor no pacote</th>
                      <th>Início da vigência</th>
                      <th>Fim da vigência</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {foodItemsTable.map((foodItem) => {
                      return (
                        <tr>
                          <td>{foodItem.name}</td>
                          <td>{foodItem.unit_name}</td>
                          <td>
                            {foodItem.value_single &&
                              `R$ ${foodItem.value_single.replace(".", ",")}`}
                          </td>
                          <td>
                            {foodItem.value_package &&
                              `R$ ${foodItem.value_package.replace(".", ",")}`}
                          </td>
                          <td>
                            {foodItem.initial_date &&
                              foodItem.initial_date
                                .split("-")
                                .reverse()
                                .join("/")}
                          </td>
                          <td>
                            {foodItem.final_date &&
                              foodItem.final_date
                                .split("-")
                                .reverse()
                                .join("/")}
                          </td>
                          <td>
                            <Button
                              onClick={() =>
                                history.push({
                                  pathname: `/gestao-itens-alimentacao/form`,
                                  state: {
                                    foodItems,
                                    units,
                                    foodItem,
                                  },
                                })
                              }
                              className="blue"
                            >
                              <Edit />
                            </Button>
                            {new Date() < new Date(foodItem.initial_date) && (
                              <Button
                                type="button"
                                disabled={deletingItem}
                                onClick={() => onDeleteFoodItem(foodItem)}
                                className="blue"
                              >
                                {deletingItem && (
                                  <img
                                    height="25"
                                    src={loadingGif}
                                    alt="loading"
                                  />
                                )}
                                {!deletingItem && <Delete />}
                              </Button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </form>
          )}
        </Form>
      )}
    </GestaoItensDeAlimentacaoStyled>
  );
};
