import { IPagination } from "interfaces/utils";
import { KanataAPI } from "./api";
import { ErrorHandlerFunction } from "./service-helpers";
import { ISalesPeriod } from "interfaces/sales";

interface IGetSalesPeriod {
  ano: number;
  unidade: number;
}

export const getSalesPeriod = async ({ ano, unidade }: IGetSalesPeriod) => {
  const response = await KanataAPI.get<IPagination<ISalesPeriod>>(
    "cx/gerenciamento_periodo_vendas/",
    {
      params: { ano, unidade: unidade > 0 ? unidade : "" },
    }
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface IUpdateSalesPeriod {
  id: number;
  payload: {
    inicio_vendas_matricula?: string;
    termino_vendas_matricula?: string;
    inicio_vendas_rematricula?: string;
    termino_vendas_rematricula?: string;
  };
}

export const updateSalesPeriod = async ({
  id,
  payload,
}: IUpdateSalesPeriod) => {
  const response = await KanataAPI.patch(
    `cx/gerenciamento_periodo_vendas/${id}/`,
    payload
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};
