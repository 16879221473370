import styled from "styled-components";
import { BLUE, GRAY_DISABLED, GREEN } from "styles/colors";

export const GestaoItensDeAlimentacaoStyled = styled.div`
  div.title {
    font-size: 24px;
    font-weight: bold;
    color: ${BLUE};
  }

  span.link {
    color: ${BLUE};
    cursor: pointer;
    text-decoration: underline;
  }

  table {
    width: 100%;
    text-align: center;
    @media only screen and (max-width: 414px) {
      font-size: 12px;
    }
    thead {
      tr {
        border: 1px solid ${GRAY_DISABLED};
        border-bottom: none;
        th {
          padding: 1em;
          border-right: 1px solid ${GRAY_DISABLED};
          span {
            font-weight: normal;
          }
        }
      }
    }
    tbody {
      tr {
        border: 1px solid ${GRAY_DISABLED};
        td {
          padding: 1em;
          border-right: 1px solid ${GRAY_DISABLED};
          span {
            svg {
              fill: ${BLUE};
              cursor: pointer;
            }
          }
          &.green {
            color: ${GREEN};
          }
          &.red {
            color: red;
          }
        }
      }
    }
  }
`;
