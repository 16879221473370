// React
import { useState, useEffect, useContext } from "react";
// Style
import * as S from "./style";
// Libs
import { Field, Form } from "react-final-form";
import HTTP_STATUS from "http-status-codes";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
// Components
import SelectInput from "components/Select";
import { Button } from "components";
// Services
import { getUnitsGradesSchoolPlaces } from "services/units.service";
// Interfaces
import { EnrollmentYearsInterface } from "interfaces/constants";
import { GetUnitsGradesResponse } from "interfaces/unit";
// Img
import loadingGif from "static/img/loading.gif";
import { getYear } from "services/multipurpose";

interface FormInterface {
  unit_id: string;
  year: string;
}

export const SearchForVacancies = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.searchForVacancies";

  // States
  const [units, setUnits] = useState<Array<GetUnitsGradesResponse>>();
  const [years, setYears] = useState<EnrollmentYearsInterface>();
  const [tableData, setTableData] = useState<GetUnitsGradesResponse>();
  const [unitName, setUnitName] = useState<any>();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getInfos = async () => {
      const unitsGrade = await getUnitsGradesSchoolPlaces();
      if (unitsGrade && unitsGrade.status === HTTP_STATUS.OK) {
        setUnits(unitsGrade.data);
      } else {
        setError(true);
      }

      const getYears = await getYear({});
      if (getYears && getYears.status === HTTP_STATUS.OK) {
        setYears(getYears.data);
      } else {
        setError(true);
      }
    };
    getInfos();
  }, []);

  const onSubmit = async (values: FormInterface) => {
    setLoading(true);
    if (units) {
      const unitsGrade = await getUnitsGradesSchoolPlaces(values.year);
      if (unitsGrade && unitsGrade.status === HTTP_STATUS.OK) {
        setTableData(
          unitsGrade.data.find(
            (unit: GetUnitsGradesResponse) =>
              unit.unit.id === parseInt(values.unit_id)
          )
        );
      } else {
        setError(true);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (tableData) {
      setUnitName(tableData.unit.name);
      setLoading(false);
    }
  }, [tableData]);

  return (
    <div className="container">
      {error && !loading && <div>{t(`${TRANSLATE}.searchError`)}</div>}
      {units && years && !error && (
        <div>
          <div className="text-center mb-5">
            <h2>{t(`${TRANSLATE}.title`)}</h2>
            <p>{t(`${TRANSLATE}.subtitle`)}</p>
          </div>
          <Form onSubmit={onSubmit}>
            {({ form, handleSubmit, values, submitting }) => (
              <form onSubmit={handleSubmit}>
                <S.SelectContainer className="selectContainer">
                  <div className="select">
                    <Field component={SelectInput} name="unit_id" required>
                      <option value="">{t(`${TRANSLATE}.unitSelect`)}</option>
                      {units.map((unit) => {
                        return (
                          <option value={unit.unit.id} key={unit.unit.id}>
                            {unit.unit.name}
                          </option>
                        );
                      })}
                    </Field>
                  </div>
                  <div className="select">
                    <Field component={SelectInput} name="year" required>
                      <option value="">{t(`${TRANSLATE}.yearSelect`)}</option>
                      {years.results.map((year) => {
                        return (
                          <option value={year.value} key={year.value}>
                            {year.label}
                          </option>
                        );
                      })}
                    </Field>
                  </div>
                </S.SelectContainer>
                <div className="col-sm-3 offset-sm-9 col-12 mt-3">
                  <Button type="submit" disabled={submitting}>
                    {!submitting && "Pesquisar"}
                    {submitting && (
                      <img height="25" src={loadingGif} alt="loading" />
                    )}
                  </Button>
                </div>
              </form>
            )}
          </Form>
        </div>
      )}
      <div>
        {tableData && !loading && (
          <>
            <h3 className="text-center mt-3 mb-3">
              {t(`${TRANSLATE}.unitVacancies`, { unitName })}
            </h3>
            <S.StyledTable className="mb-4">
              <table>
                <thead>
                  <tr>
                    <th>{t(`${TRANSLATE}.tableYear`)}</th>
                    <th>{t(`${TRANSLATE}.tableEducationalLevel`)}</th>
                    <th>{t(`${TRANSLATE}.tableAvailableVacancies`)}</th>
                    <th>{t(`${TRANSLATE}.tableOccupiedVacancies`)}</th>
                    <th>{t(`${TRANSLATE}.tableTaitingList`)}</th>
                    <th>{t(`${TRANSLATE}.tableTotalVacancies`)}</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.unit.educational_level.map((unit: any) => {
                    return (
                      <>
                        {unit.grades.map((grade: any) => {
                          return (
                            <tr key={grade.id} className="text-center">
                              <th>{grade.grade}º</th>
                              <th>{unit.name}</th>
                              <th>{grade.school_places}</th>
                              <th>{grade.enrolled_list_count}</th>
                              <th>{grade.wait_list_count}</th>
                              <th>{grade.total_school_places}</th>
                            </tr>
                          );
                        })}
                      </>
                    );
                  })}
                </tbody>
              </table>
            </S.StyledTable>
            <div className="row justify-content-center">
              <Button
                className="col-2 text-center"
                onClick={() => history.push("/responsavel/busca-por-cpf")}
              >
                {t(`${TRANSLATE}.buttonReservationVacancy`)}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
