import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${({ theme }) => theme.space[2]};
  margin-top: ${({ theme }) => theme.space[2]};
  padding-bottom: 9.5px;

  p {
    margin: 0;
  }

  border-bottom: 1px solid ${({ theme }) => theme.colors.greyScale20};

  &:last-child {
    border-bottom: none;
  }

  img {
    max-width: 60px;
    max-height: 60px;
    width: 100%;
  }

  .itinerarie-info {
    width: 100%;

    > div {
      display: flex;
      flex: 1;
    }

    strong {
      font-size: ${({ theme }) => theme.fontSizes.web.sm};
    }
  }

  .itinerarie-error {
    display: flex;
    justify-content: baseline;
    margin: 12px 0;

    svg {
      margin-right: 4px;
    }

    p {
      font-size: ${({ theme }) => theme.fontSizes.web.default};
    }
  }
`;
