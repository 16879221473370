import { AxiosInstance, ResponseType } from "axios";
import { PortalError } from "interfaces";
import { Storage } from "Storage";
import { refreshToken } from "./auth.service";
import { IRefreshToken, TOnSuccess } from "interfaces/refreshToken";
import { toastError } from "components/Toast";

export const ErrorHandlerFunction = (err: any) => {
  if (err instanceof Error) {
    let data;
    const responseError: PortalError = err;
    if (responseError.response) {
      data = {
        data: responseError.response.data,
        status: responseError.response.status,
      };
    } else {
      data = { data: "Error during request", status: 500 };
    }
    return data;
  }
};

export const ErrorNoResponse = (err: any) => {
  if (err instanceof Error) {
    let data;
    const responseError: PortalError = err;
    if (responseError.response) {
      data = {
        status: responseError.response.status,
      };
    } else {
      data = { data: "Error during request", status: 500 };
    }
    return data;
  }
};

interface HeadersConfigsProps {
  timeout?: number;
  params?: object;
  blob?: boolean;
  responseType?: ResponseType;
}

export const Headers = (headersConfig: HeadersConfigsProps) => {
  return {
    ...(headersConfig?.blob && { "Content-Type": "blob" }),
    ...(headersConfig?.timeout && { timeout: headersConfig.timeout }),
    ...(headersConfig?.params && { params: headersConfig.params }),
    ...(headersConfig?.responseType && {
      responseType: headersConfig.responseType,
    }),
  };
};

export const getRefreshToken = async ({
  apiClient,
  token,
  failedRequestsQueue,
}: IRefreshToken<AxiosInstance>) => {
  const response = await refreshToken(token);
  if (response) {
    Storage.token.set(response);

    const newAccessToken = Storage.token.get()?.access;

    apiClient.defaults.headers.common.Authorization = `Bearer ${
      Storage.token.get()?.access
    }`;

    if (newAccessToken) {
      failedRequestsQueue.forEach((successReq: TOnSuccess) =>
        successReq.onSuccess(newAccessToken)
      );
    }
  }
};

export const tokenExpired = () => {
  toastError("O token de acesso expirou. Redirecionando para o login.");
  setTimeout(() => {
    Storage.remove();
    window.location.href = "/";
  }, 3000);
};
