import { Button } from "components";
import { ModalDiv } from "components/UseTerms/styles";
import { useHistory } from "react-router-dom";
import { GetCXUserJson } from "interfaces/constants";

interface ModalAddressInterface {
  setToggleModal: (toggleModal: boolean) => void;
  user: GetCXUserJson;
}

export const ModalCheckbox = ({
  setToggleModal,
  user,
}: ModalAddressInterface) => {
  const history = useHistory();
  return (
    <div className="container">
      <ModalDiv>
        <div className="text-center">
          <strong className="title">Seu status foi atualizado!</strong>
          <p>
            Aguarde um novo contato da Escola Mais e em breve nosso time te
            auxiliará com os próximos passos para a matrícula.
          </p>
        </div>
        <Button
          onClick={() => {
            history.push({
              pathname: `/responsavel/meus-alunos`,
              state: {
                user,
              },
            });
            setToggleModal(false);
          }}
          className="blue outline"
        >
          Avançar
        </Button>
      </ModalDiv>
    </div>
  );
};
