import styled from "styled-components";

export const Container = styled.div`
  margin: ${({ theme }) => theme.space[12]} 93px;

  @media (max-width: 768px) {
    margin: ${({ theme }) => theme.space[12]} ${({ theme }) => theme.space[4]};
  }
`;

export const EnrollmentYears = styled.select`
  display: flex;
  margin-left: auto;
  padding: ${({ theme }) => theme.space[2]};
  width: 168px;

  border: 2px solid ${({ theme }) => theme.colors.greyScale40};
  background-color: transparent;
  margin-bottom: ${({ theme }) => theme.space[2]};
`;

export const DocumentsCards = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: ${({ theme }) => theme.space[4]};

  @media (max-width: 1400px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
