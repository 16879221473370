import { ButtonHTMLAttributes } from "react";
import styled from "styled-components";

export const Container = styled.div<{ type: "current" | "past" }>`
  width: 100%;
  max-width: 992px;

  border-radius: 20px;
  background-color: ${({ theme }) => theme.colors.primaryLight90};

  padding: 24px 16px;
  margin-bottom: 24px;

  display: flex;
  flex-direction: column;

  .content {
    display: flex;
    justify-content: ${({ type }) =>
      type === "current" ? "space-between" : "center"};
    align-items: center;
    margin-bottom: 16px;
  }

  .data-info {
    p {
      margin: 0;
      font-weight: bold;
      text-align: center;
    }

    .daysOfWeek {
      font-size: 16px;
    }
  }

  .next-prev {
    display: flex;
    align-items: center;

    button {
      border: none;
      background: transparent;
      color: ${({ theme }) => theme.colors.primaryDefault};
      padding: 0;

      svg {
        width: 24px;
        height: 24px;
      }

      &:hover {
        color: ${({ theme }) => theme.colors.primaryLight80};
      }
    }
  }

  .postite-info {
    > div {
      max-width: 100%;
      background-color: white;
    }
  }

  @media (max-width: 768px) {
    .data-info {
      p {
        font-size: 12px;
      }

      .week {
        font-size: 14px;
      }
    }
  }
`;

interface IToday extends ButtonHTMLAttributes<HTMLButtonElement> {
  current: boolean;
}

export const Today = styled.button<IToday>`
  height: 37px;
  padding: 4px 8px;
  background: transparent;
  border: 2px solid ${({ theme }) => theme.colors.primaryDefault};

  display: flex;
  align-items: center;
  gap: 6px;

  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme, current }) =>
    current ? theme.colors.white : theme.colors.primaryDefault};

  background-color: ${({ theme, current }) =>
    current && theme.colors.primaryDefault};
`;
