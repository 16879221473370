import LinearProgress from "@material-ui/core/LinearProgress";
import Pagination from "@material-ui/lab/Pagination";
import { Button, TextInput } from "components";
import SelectInput from "components/Select";
import { composeValidators, required } from "helpers/fieldValidators";
import HTTP_STATUS from "http-status-codes";
import { FilterGuardianResponse } from "interfaces/constants";
import { useContext, useState } from "react";
import { Field, Form } from "react-final-form";
import { useHistory } from "react-router-dom";
import { searchGuardian } from "services/users.service";
import loadingGif from "static/img/loading.gif";
import { SearchGuardianDiv } from "./style";

interface SearchGuardianInterface {
  filter_text: string;
  user_role: string;
}

export const SearchGuardian = () => {
  const [users, setGuardians] = useState<null | Array<FilterGuardianResponse>>(
    null
  );
  const [count, setCount] = useState(0);
  const [error, setError] = useState(false);
  const [page, setPage] = useState(1);
  const [tableViewResults, setTableViewResults] = useState("guardian");

  const history = useHistory();

  const onSubmit = async (values: SearchGuardianInterface) => {
    await searchGuardian(
      encodeURIComponent(values.filter_text),
      values.user_role,
      page
    ).then((response) => {
      if (response) {
        if (response.status === HTTP_STATUS.OK) {
          // @ts-ignore
          setTableViewResults(values.user_role);
          setGuardians(response.data.results);
          setError(false);
          // @ts-ignore
          setCount(Math.ceil(response.data.count / 10));
        } else {
          setError(true);
        }
      }
    });
  };

  return (
    <SearchGuardianDiv className="container">
      <Form initialValues={{ user_role: "guardian" }} onSubmit={onSubmit}>
        {({ handleSubmit, submitting }) => (
          <form id="search-form" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-6 col-12">
                <Field
                  component={TextInput}
                  name="filter_text"
                  type="text"
                  placeholder=" "
                  label="Nome, CPF ou E-mail"
                  maxLength="100"
                  validate={composeValidators(required)}
                  required
                  hideCheck
                />
              </div>
              <div className="col-sm-3 col-12">
                <Field<string> component={SelectInput} name="user_role">
                  <option value="guardian">Responsável</option>
                  <option value="student">Aluno</option>
                </Field>
              </div>
              <div className="col-sm-3 col-12">
                <Button disabled={submitting} type="submit">
                  {!submitting && "Buscar"}
                  {submitting && (
                    <img height="25" src={loadingGif} alt="loading" />
                  )}
                </Button>
              </div>
            </div>
            {users && users.length === 0 && (
              <div>Nenhum responsável encontrado</div>
            )}
            {users && users.length > 0 && (
              <>
                <div className="w-100">
                  <table className="table-responsive w-auto">
                    <thead>
                      <tr>
                        <th>Nome</th>
                        <th>CPF</th>
                        <th>Email</th>
                        {tableViewResults === "student" && (
                          <>
                            <th>Unidade</th>
                            <th className="text-center">Ciclo</th>
                            <th>Ano</th>
                            <th>Turma</th>
                          </>
                        )}
                      </tr>
                    </thead>

                    <tbody>
                      {users.map((guardian, key) => (
                        <tr
                          key={key}
                          onClick={() =>
                            guardian.role &&
                            history.push(
                              guardian.role.find((r) => r.label === "Guardian")
                                ? `/responsavel/editar?guardianAuthId=${guardian.id}&guardianId=${guardian.guardian_id}`
                                : `/aluno/editar?studentId=${guardian.student_id}`
                            )
                          }
                        >
                          <td className="col text-start">{guardian.name}</td>
                          <td className="col text-start">
                            {guardian.cpf ?? "-"}
                          </td>
                          <td className="col text-wrap text-start">
                            {guardian.email ?? "-"}
                          </td>

                          {tableViewResults === "student" && (
                            <>
                              <td className="col text-center">
                                {guardian.unit ?? "-"}
                              </td>
                              <td className="col text-center">
                                {guardian.educational_level_name ?? "-"}
                              </td>
                              <td className="col text-center">
                                {guardian.grade ?? "-"}
                              </td>
                              <td className="col text-center">
                                {guardian.section ?? "-"}
                              </td>
                            </>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {users && submitting && (
                  <LinearProgress className="mt-3 mb-3" color="secondary" />
                )}
                <div className="my-3 d-flex justify-content-center">
                  <Pagination
                    variant="outlined"
                    color="primary"
                    page={page}
                    onChange={async (_, value) => {
                      await setPage(value);
                      // @ts-ignore: Object is possibly 'null'.
                      document.getElementById("search-form").dispatchEvent(
                        new Event("submit", {
                          cancelable: true,
                          bubbles: true,
                        })
                      );
                    }}
                    count={count}
                  />
                </div>
              </>
            )}
            {error && (
              <div>
                Erro ao buscar responsáveis. Tente novamente mais tarde.
              </div>
            )}
          </form>
        )}
      </Form>
    </SearchGuardianDiv>
  );
};
