import { ContainerProps } from "./types";
import styled from "styled-components";

export const Container = styled.div<ContainerProps>`
  display: ${(props) => (props.display === "true" ? "flex" : "block")};
  flex-direction: ${(props) =>
    props.direction === "row"
      ? props.direction
      : props.direction === "column" && props.direction};
  align-items: ${(props) =>
    props.align === "center"
      ? "center"
      : props.align === "left"
      ? "flex-start"
      : props.align === "right" && "flex-end"};
`;
