import { useTranslation } from "react-i18next";
import { ISubtitleProps } from "./type";
import * as S from "./styles";

export const Subtitle = ({ title, value }: ISubtitleProps) => {
  const { t } = useTranslation();
  const i18n_prefix = "pages.EDF.manageVacancies.components.subtitle";

  return (
    <S.Container>
      <strong>{t(`${i18n_prefix}.title`, { title })}</strong>
      <div className="dotted-border"></div>
      <span data-testid="span-value">{value}</span>
    </S.Container>
  );
};
