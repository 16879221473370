import { useTranslation } from "react-i18next";
import { IInputDate } from "./interface";
import { FormError, InputDateContainer } from "./style";
import calendarIcon from "static/img/calendar.svg";
import { FieldValues } from "react-hook-form";
import { useState } from "react";

// TODO: Mandar esse cara pra lib
// TODO: quando mandar para lib, usar o pattern de composicao
export const InputDate = <T extends FieldValues>({
  label,
  value,
  onChange,
  fontSize = 14,
  boxWidth = 165,
  boxHeight = 37,
  lineHeight = 21,
  marginTop = 0,
  register,
  id,
  error,
  ...props
}: IInputDate<T>) => {
  const hasValue = value ? true : false;

  const [hookFormValue, setHookFormValue] = useState<string>(
    value ? value : ""
  );

  const { t } = useTranslation();

  return (
    <InputDateContainer
      fontSize={fontSize}
      boxWidth={boxWidth}
      boxHeight={boxHeight}
      lineHeight={lineHeight}
      marginTop={marginTop}
      value={hasValue || hookFormValue ? true : false}
      errorText={register ? true : false}
    >
      <label>{label}</label>
      <div>
        <img src={calendarIcon} height={18} alt="calendar" />
        {register ? (
          <span>
            {!hookFormValue
              ? t("components.atoms.inputDate.select")
              : hookFormValue.split("-").reverse().join("/")}
          </span>
        ) : (
          <span>
            {!value
              ? t("components.atoms.inputDate.select")
              : value.split("-").reverse().join("/")}
          </span>
        )}

        {register && id ? (
          <input
            type="date"
            {...props}
            {...register(id, {
              onChange: (e) => setHookFormValue(e.target.value),
            })}
          />
        ) : (
          <input
            type="date"
            name="date"
            onChange={onChange}
            value={value}
            {...props}
          />
        )}
      </div>
      {error && <FormError>{error}</FormError>}
    </InputDateContainer>
  );
};
