import styled from "styled-components";

export const SelectStyled = styled.select`
  font-size: 14px;
  font-weight: normal;
  background-color: white;
  width: 100%;
  height: 38px;
  color: #42474a;
  border: none;
  border-bottom: 1px solid #cccccc;
  padding-left: 0.5em;
  margin-top: 2em;
  cursor: pointer;

  &.fullBorder {
    border: 1px solid;
    border-radius: 6px;
    padding-left: 5px;
  }
`;
