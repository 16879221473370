// React
import { useEffect, useState } from "react";
// Styles
import * as S from "./style";
// Libs
import { useLocation } from "react-router-dom";
// Components
import { ChooseStudent } from "./components/ChooseStudent";
import { ExitPermitScreen } from "./components/ExitPermitScreen";
// Interfaces
import { IExitPermit } from "./interface";
import { StudentInterface } from "interfaces/constants";

export const ExitPermit = () => {
  const location = useLocation<IExitPermit>();

  const [student, setStudent] = useState<StudentInterface | null>(null);

  useEffect(() => {
    location.state.user.students.length === 1 &&
      setStudent(location.state.user.students[0]);
  }, [location]);

  return (
    <S.ExitPermitStyled>
      {!student && (
        <ChooseStudent user={location.state.user} setStudent={setStudent} />
      )}
      {student && <ExitPermitScreen student={student} />}
    </S.ExitPermitStyled>
  );
};
