import { toastSuccess } from "components/Toast";
import { useMemo, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { Componente, setChosenProject } from "services/grades.service";

const TRANSLATE_PREFIX = "pages.AP.chooseProject";

export interface RadioGroupOption {
  label: string;
  value: number;
}

interface IUseChooseProjectOutput {
  radioOptions: RadioGroupOption[];
  selectedOption: string | undefined;
  goBack: () => void;
  handleRadioChange: (value: string) => void;
  handleSelectProject: () => void;
  t: TFunction<"translation", undefined>;
}

export const useChooseProject = (): IUseChooseProjectOutput => {
  const { state } = useLocation<Componente>();
  const { goBack, replace } = useHistory();
  const { t } = useTranslation();

  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    undefined
  );

  const radioOptions: RadioGroupOption[] = useMemo(() => {
    if (!state) return [];

    return state.especializacoes.map((x) => ({
      label: x.nome,
      value: x.id,
    }));
  }, [state]);

  const handleRadioChange = (value: string) => {
    setSelectedOption(value);
  };

  const handleSelectProject = () => {
    setChosenProject(
      state?.enrollment_id as number,
      +(selectedOption as string)
    ).then((res) => {
      if (res.status === 200 || res.status === 201) {
        toastSuccess(t(`${TRANSLATE_PREFIX}.projectSelectedSuccess`));
        replace("/home");
      }
    });
  };

  if (!state) {
    goBack();
  }

  return {
    radioOptions,
    selectedOption,
    goBack,
    handleRadioChange,
    handleSelectProject,
    t,
  };
};
