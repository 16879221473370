import React from "react";
import { Link } from "react-router-dom";
import { TabDiv } from "./style";

type TabType = {
  href: string;
  title: string;
  isActive: boolean;
  children: JSX.Element;
};

export const Tab = ({ href, title, isActive, children }: TabType) => {
  return (
    <TabDiv isActive={isActive}>
      <Link className="d-flex" to={href}>
        {children}
        <span className="d-none d-sm-block">{title}</span>
      </Link>
    </TabDiv>
  );
};
