export const BLUE = "#00aeefff";
export const WHITE = "#FFFFFF";
export const PURPLE = "#800080";

export const PINK = "#ec008b";
export const PINK_TWO = "#ec038c";
export const LIGHT_PINK = "#ddadaf";

export const BLACK = "#000000";
export const BLACK_ONE = "#141414";
export const LIGHT_BLACK = "#42474a";

export const YELLOW = "#FAED28";
export const LIGHT_YELLOW = "#FFFFE0";
export const LIGHT_YELLOW_TWO = "#FFFAAF";

export const GREEN = "#00A651";
export const LIGHT_GREEN = "#16D51C";

export const RED = "#ed1c24";
export const RED_TWO = "#D81E1E";

export const GRAY = "#707070";
export const GRAY_TWO = "#717171";

export const GRAY_BORDER = "#cccccc";
export const GRAY_BORDER_TWO = "#d9d9d9";
export const GRAY_BORDER_THREE = "#c4c4c4";
export const GRAY_DISABLED = "#dadada";
export const LIGHT_GRAY = "#EEEEEE";
export const LIGHT_GRAY_ONE = "#E8E8E8";
export const LIGHT_GRAY_TWO = "#E6E6E6";

// TODO: Cores referentes a nova interface
// Retirar o NEW quando tiver tempo e botar o nome da cor
// E quando for matar as telas que possuem as cores acima
export const NEW_ORANGE = "#FD8420";
export const NEW_GREEN_TWO = "#DBFF80";
export const NEW_PURPLE = "#6D28D9";
export const NEW_PURPLE_ONE = "#275ed40";
export const NEW_PURPLE_TWO = "#F0E9FB";
export const NEW_GREEN = "#3BA550";
export const NEW_BLUE = "#00A1ED";
export const NEW_YELLOW = "#F9C339";
export const NEW_RED = "#B50303";
export const NEW_GRAY = "#DBDBDB";
export const NEW_GRAY_ONE = "#A0A0A0";
export const NEW_GRAY_TWO = "#2D2D2D";

export const GRAY_SCALE = "#999999";
export const GRAY_THREE = "#D9D9D9";

// TODO: a cor #0D0D0D está repetida com nomes diferentes:
export const NEW_GRAY_SCALE = "#0D0D0D";
export const GRAY_SCALE_THREE = "#0D0D0D";
export const NEW_GREY_95 = "#0D0D0D";

// TODO: a cor #E5E5E5 está repetida com nomes diferentes:
export const NEW_GRAY_THREE = "#E5E5E5";
export const GRAY_SCALE_TWO = "#E5E5E5";
export const GRAY_SCALE_FOUR = "#E5E5E5";

export const NEW_GREY_SCALE_FIVE = "#F2F2F2";
