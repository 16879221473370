import styled from "styled-components";
import * as colors from "styles/colors";
import { ICardData } from "./interface";

const CardDataTypes = {
  success: colors.NEW_GREEN,
  warning: colors.NEW_RED,
};

export const Container = styled.div`
  max-width: 1360px;
  width: 100%;
  margin: 0 auto;

  padding: 24px;

  .statsCards {
    margin-top: 16px;

    display: flex;
    align-items: center;
    gap: 16px;
  }

  .listTable {
    margin-top: 16px;
  }

  .listHeader {
    display: grid;
    grid-template-columns: 20% 10% 25% 25% 20%;
    border-bottom: 1px solid ${colors.GRAY_SCALE};

    strong {
      padding: 8px 4px;
    }
  }

  .listItem {
    display: grid;
    grid-template-columns: 20% 10% 25% 25% 20%;
    border-bottom: 1px solid ${colors.NEW_GRAY_THREE};

    p {
      padding: 8px 4px;
      margin: 0;
    }

    button {
      border: none;
      background-color: transparent;
      width: fit-content;

      font-weight: 700;
      color: ${colors.NEW_PURPLE};
      text-transform: uppercase;

      svg {
        margin-right: 6px;
      }
    }
  }

  @media (max-width: 600px) {
    .statsCards {
      flex-direction: column;
      align-items: flex-start;
    }

    .listHeader {
      strong {
        word-wrap: break-word;
      }
    }
  }
`;

export const CardData = styled.div<ICardData>`
  background: ${colors.NEW_PURPLE_TWO};
  height: 108px;
  width: 196px;
  border-radius: 20px;

  padding: 16px;

  strong {
    font-size: 12px;
    text-transform: uppercase;
  }

  h2 {
    font-weight: 700;
    font-size: 36px;
    margin-top: 4px;
    color: ${({ type }) =>
      type ? CardDataTypes[type] : colors.NEW_GRAY_SCALE};
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;
