// Styles
import * as S from "./styles";
// Components
import {
  Button,
  ModalInfo,
  StepPaymentStatus,
  StudentBox,
} from "components/atoms";
// Interfaces
import { GetCXUserJson, StudentInterface } from "interfaces/constants";
import { SingleFoodTransactionsInterface } from "interfaces/mealInterfaces";
// Libs
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { StudentHeader } from "components";
import {
  handleDuplicateItems,
  standardDate,
  standardHour,
} from "helpers/helpers";
import { useState } from "react";
import { Calendar } from "components/atoms/Calendar";

type StudentFoodPurchaseType = {
  orderHistory: SingleFoodTransactionsInterface;
  user: GetCXUserJson;
  student: StudentInterface | undefined;
};

enum InvoiceEnum {
  PendingTransaction = "Transação Pendente",
  CanceledTransaction = "Transação Cancelada",
  Approved = "Transação Aprovada",
  Expired = "Transação Expirada",
}

export const StudentFoodPurchase = ({
  orderHistory,
  user,
  student,
}: StudentFoodPurchaseType) => {
  const history = useHistory();

  const { t } = useTranslation();
  const i18_prefix = "pages.EDF.singlePurchase.components.studentFoodPurchase";
  const [isModalCalendarOpen, setIsModalCalendarOpen] = useState(false);

  const countOrderItems = (foodItem: any) =>
    orderHistory?.items.filter((item) => item.item_name === foodItem.item_name)
      .length;

  return (
    <S.Container>
      <StudentBox>
        <ModalInfo
          isOpen={isModalCalendarOpen}
          title="Dias contratados"
          width="351px"
        >
          <Calendar
            selectedDates={orderHistory.dias_selecionados}
            noActions
            borderSpacing="0 14px"
            marginTop="16px"
          />
          <button
            className="modal-btn"
            onClick={() => setIsModalCalendarOpen(false)}
          >
            {t(`${i18_prefix}.closeModal`)}
          </button>
        </ModalInfo>

        {/* {student && <StudentHeader student={student} />}  */}
        <div className="orderStatus">
          <StepPaymentStatus
            type={
              orderHistory.invoice_status === InvoiceEnum.PendingTransaction
                ? "progress"
                : orderHistory.invoice_status ===
                  InvoiceEnum.CanceledTransaction
                ? "canceled"
                : orderHistory.invoice_status === InvoiceEnum.Expired
                ? "canceled"
                : "success"
            }
          />

          <strong>Nº {orderHistory.id}</strong>
        </div>

        {orderHistory.invoice_status === "Transação Pendente" && (
          <small>{t(`${i18_prefix}.makePayment`)}</small>
        )}

        <div className="foodInfo">
          {handleDuplicateItems(orderHistory.items).map((foodOrder: any) => (
            <>
              <p>
                {countOrderItems(foodOrder)}x {foodOrder.item_name}
                <span>R${foodOrder.value_single_formatted}</span>
              </p>
            </>
          ))}

          <button
            onClick={() => setIsModalCalendarOpen((prevState) => !prevState)}
          >
            {t(`${i18_prefix}.foodDays`)}
          </button>

          <strong>
            {t(`${i18_prefix}.orderTotal`)}:{" "}
            <span>R$ {orderHistory.valor_total}</span>
          </strong>
        </div>

        {orderHistory.invoice_status === InvoiceEnum.Approved && (
          <div className="paymentDate">
            <p>{t(`${i18_prefix}.paymentDate`)}</p>
            <p>
              {t(`${i18_prefix}.paymentDay`, {
                day: standardDate(orderHistory.date_transaction),
                hour: standardHour(orderHistory.date_transaction),
              })}
            </p>
          </div>
        )}

        {orderHistory.invoice_status === "Transação Pendente" && (
          <Button
            margin
            onClick={() =>
              history.push({
                pathname: "/alimentacao/compra-avulsa/refeicoes",
                state: {
                  studentUser: student,
                  guardianUser: user,
                  payment: true,
                  purchaseId: orderHistory.id,
                  order: orderHistory,
                },
              })
            }
          >
            {t(`${i18_prefix}.payButton`)}
          </Button>
        )}
      </StudentBox>
    </S.Container>
  );
};
