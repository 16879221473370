import styled from "styled-components";
import { size } from "styles/breakpoints";
import * as colors from "styles/colors";

export const Container = styled.div`
  max-width: 992px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;

  padding: 24px 16px;
`;

export const Heading = styled.h1`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSizes.web.h1};
  line-height: 54px;
  color: ${({ theme }) => theme.colors.primaryDefault};

  @media (max-width: ${size.sm}) {
    font-size: ${({ theme }) => theme.fontSizes.mobile.h1};
  }
`;

export const YearSelector = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  strong {
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    font-size: ${({ theme }) => theme.fontSizes.web.sm};
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.greyScale95};
  }

  select {
    width: 147px;
    height: 37px;
    border: 2px solid ${({ theme }) => theme.colors.greyScale40};
  }
`;

export const PublishCardContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space[4]};
  margin-top: ${({ theme }) => theme.space[6]};

  @media (max-width: ${size.sm}) {
    flex-direction: column;
  }
`;
