// React
import { useContext, useEffect, useState } from "react";
import HTTP_STATUS from "http-status-codes";
// Styles
import * as S from "./styles";
// Libs
import { useHistory, useLocation } from "react-router-dom";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
// Components
import { Loading, Button } from "components/atoms";
import { EnrollmentStepCircle, FoodOption } from "components/molecules";
// Interfaces
import { ILocationSelectMeals, IStep } from "../../type";
import { FoodItemInterface } from "interfaces/mealInterfaces";
// Context
import { FoodContext } from "context/foodContext";
// Services
import { getFoodItems } from "services/meals.service";
import { IFoodContextType } from "context/foodContext/type";

export const StepOne = ({ next }: IStep) => {
  const history = useHistory();
  const { state } = useLocation<ILocationSelectMeals>();

  const { t } = useTranslation();
  const i18_prefix = "pages.EDF.selectMeals.components.stepOne";

  const { selectedFoodItems } = useContext(FoodContext) as IFoodContextType;
  const [foodItems, setFoodItems] = useState<FoodItemInterface[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState(false);

  const onSubmit = () => {
    next();
  };

  useEffect(() => {
    const getFoodItemsList = async () => {
      const response = await getFoodItems({
        unit: String(state.studentUser.enrollment.unit_id),
        in_force: true,
      });

      if (response?.status === HTTP_STATUS.OK) {
        setFoodItems(response?.data);
        setIsLoading(false);
      } else {
        setError(true);
        setIsLoading(false);
      }
    };

    getFoodItemsList();
  }, [state.studentUser]);

  if (error) {
    return (
      <S.LoadContainer>
        <h4>{t(`${i18_prefix}.errorLoadingItens`)}</h4>
      </S.LoadContainer>
    );
  }

  if (isLoading && !error) {
    return (
      <S.LoadContainer>
        <h4>{t(`${i18_prefix}.loadingPackageItems`)}</h4>
        <Loading />
      </S.LoadContainer>
    );
  }

  return (
    <S.Container>
      <EnrollmentStepCircle
        studentName={state.studentUser.name}
        nextStep="Confirmação"
        step={1}
        totalSteps={3}
        currentStepName="Selecionar as refeições"
        width="720px"
        subtitle="Refeição avulsa"
      />

      <p>{t(`${i18_prefix}.selectItems`)}</p>

      <div className="foodOptions">
        <Form onSubmit={onSubmit}>
          {({ form, handleSubmit, submitting, values }) => (
            <form onSubmit={handleSubmit}>
              {foodItems?.map((item) => (
                <FoodOption
                  key={item.id}
                  name={item.name}
                  value={item.id}
                  price={item.value_single}
                />
              ))}

              <div className="btn-group">
                <Button
                  outline
                  onClick={() =>
                    history.push({
                      pathname: "/alimentacao/compra-avulsa",
                      state: {
                        user: state.guardianUser,
                      },
                    })
                  }
                >
                  {t(`${i18_prefix}.buttonBack`)}
                </Button>
                <Button
                  type="submit"
                  onClick={onSubmit}
                  disabled={selectedFoodItems.length < 1}
                >
                  {t(`${i18_prefix}.buttonAdvance`)}
                </Button>
              </div>
            </form>
          )}
        </Form>
      </div>
    </S.Container>
  );
};
