import styled, { css } from "styled-components";
import * as colors from "styles/colors";
import { ITBodyStyles, ITHeadStyles } from "./interface";

export const TableContainer = styled.table`
  width: 100%;
  margin-top: ${({ theme }) => theme.space[5]};
  margin-bottom: ${({ theme }) => theme.space[4]};
`;

export const Thead = styled.thead<ITHeadStyles>`
  width: 100%;
  tr {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: ${({ theme }) => theme.fontSizes.web.sm};
    border-bottom: 1px solid ${colors.GRAY_SCALE}; // nao tem na lib

    display: flex;

    th {
      width: 18.75%;
      margin-bottom: ${({ theme }) => theme.space[2]};
      margin-right: ${({ theme }) => theme.space[4]};
    }

    th:first-child {
      width: 25%;
      ${({ headEqualSpacing }) =>
        headEqualSpacing &&
        css`
          width: 18.75%;
        `}

      margin-left: ${({ theme }) => theme.space[4]};
      margin-right: ${({ theme }) => theme.space[8]};
    }
  }
`;

export const Tbody = styled.tbody<ITBodyStyles>`
  tr {
    border-bottom: 2px solid ${colors.NEW_GRAY_THREE}; // nao tem na lib
    font-size: ${({ theme }) => theme.fontSizes.web.sm};

    display: flex;
    align-items: center;
    padding: 7px 0; // nao tem na lib

    td {
      width: 18.75%;
      margin-right: ${({ theme }) => theme.space[4]};
    }

    td:first-child {
      width: 25%;
      ${({ bodyEqualSpacing }) =>
        bodyEqualSpacing &&
        css`
          width: 18.75%;
        `}

      margin-left: ${({ theme }) => theme.space[4]};
      margin-right: ${({ theme }) => theme.space[8]};
    }
  }
`;
