import styled, { DefaultTheme } from "styled-components";
import { AlertSeverity } from "./AlertRoot";

const getAlertSeverityColor = (
  severity: AlertSeverity,
  theme: DefaultTheme
) => {
  switch (severity) {
    case "info":
      return theme.colors.statusInfoDefault;
    case "success":
      return theme.colors.statusSuccessDefault;
    case "warning":
      return theme.colors.statusWarningDefault;
    case "error":
      return theme.colors.statusErrorDefault;
  }
};

interface IAlertRootContainerProps {
  severity: AlertSeverity;
}

// AlertRoot
export const AlertRootContainer = styled.div<IAlertRootContainerProps>`
  width: 100%;
  height: auto;

  background: rgba(255, 255, 255, 0.9);
  border: 2px solid
    ${(props) => getAlertSeverityColor(props.severity, props.theme)};

  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space[1]};
  padding: ${({ theme }) => theme.space[2]};
`;

interface IAlertRootIconContainerProps {
  severity: AlertSeverity;
}

export const AlertRootIconContainer = styled.div<IAlertRootIconContainerProps>`
  margin: 0;
  padding: 0;
  color: ${(props) => getAlertSeverityColor(props.severity, props.theme)};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AlertRootContentContainer = styled.div``;

// AlertTitle
export const Title = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.mobile.sm};
  color: ${({ theme }) => theme.colors.greyScale95};
  margin: 0;
`;

// AlertContent
export const Content = styled.p`
  font-size: ${({ theme }) => theme.fontSizes.mobile.sm};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  margin: 0;
`;
