import styled, { css, keyframes } from "styled-components";
import * as colors from "styles/colors";

export const Container = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    width: 10px;
    height: 10px;
    margin: 1rem;
    background: ${colors.NEW_PURPLE};
    border-radius: 50%;
    animation: 0.9s bounce infinite alternate;

    &:nth-child(2) {
      animation-delay: 0.3s;
    }

    &:nth-child(3) {
      animation-delay: 0.6s;
    }
  }

  @keyframes bounce {
    to {
      opacity: 0.3;
      transform: translate3d(0, -1rem, 0);
    }
  }
`;

interface LoadingWrapperProps {
  visible: boolean;
  animationDelay: number;
  overlayOpacity: number;
}

export const LoadingFadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const LoadingFadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const LoadingWrapper = styled.div<LoadingWrapperProps>`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, ${({ overlayOpacity }) => overlayOpacity});

  transition: opacity ${({ animationDelay }) => (animationDelay ?? 0) / 1000}s
    ease;

  animation: ${({ visible }) => (visible ? LoadingFadeIn : LoadingFadeOut)}
    ${({ animationDelay }) => (animationDelay ?? 0) / 1000}s ease;

  ${({ visible }) =>
    !visible
      ? css`
          opacity: 0;
          pointer-events: none;
        `
      : ""}
`;
