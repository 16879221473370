import styled from "styled-components";

export const SignedContractStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div.enrollment-title {
    font-size: 20px;
    font-weight: bold;
    color: #ed008c;

    div.subtitle {
      font-weight: 500;
      font-size: 16px;
      color: black;
    }
  }
`;
