import * as Interface from "./header.types";
import * as Style from "./header.style";

import { useHistory } from "react-router-dom";

import { Title, Icons, Button } from "components/atoms";

export const Header: React.FC<Interface.HeaderProps> = ({
  title,
  fontSize,
  back,
  action,
}) => {
  const history = useHistory();

  const backAction = (back: string) =>
    back ? history.push(back) : history.goBack();

  const actionButton = action && (
    <Button width="228px" color="primary" onClick={action.onAction}>
      <Style.SectionContent justifyContent="center">
        <Icons icon={action.icon} padding title={title} />
        {action.text}
      </Style.SectionContent>
    </Button>
  );

  return (
    <Style.Container>
      <Style.SectionHeader>
        <Style.SectionContent>
          <Icons
            icon="/icons/arrow-left.svg"
            onClick={() => backAction(back || "")}
            padding
          />
          <Title
            fontWeight="700"
            fontSize={fontSize}
            lineHeight="1"
            marginBottom="0"
            responsive
          >
            {title}
          </Title>
        </Style.SectionContent>
        {action && <Style.SectionContent>{actionButton}</Style.SectionContent>}
      </Style.SectionHeader>
    </Style.Container>
  );
};
