import { IconType } from "types/constants";

export const IconStudents = ({ isActive }: IconType) => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      height="15"
      width="15"
      viewBox="0 0 512 512"
      fill={isActive ? "#00aeef" : "#5e5e5e"}
    >
      <g>
        <g>
          <path d="M256,0c-49.629,0-90,41.371-90,91c0,49.629,40.371,90,90,90c49.629,0,90-40.371,90-90C346,41.371,305.629,0,256,0z" />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M286,181h-30h-30c-21.158,0-41.603,5.469-60.19,14.773c31.569,8.518,61.293,20.786,90.19,37.372
			c28.898-16.586,58.621-28.854,90.19-37.372C327.603,186.469,307.158,181,286,181z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M482,271c-16.569,0-30,13.431-30,30v60c0,16.567,13.431,30,30,30c16.569,0,30-13.433,30-30v-60
			C512,284.431,498.569,271,482,271z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M30,271c-16.569,0-30,13.431-30,30v60c0,16.567,13.431,30,30,30c16.569,0,30-13.433,30-30v-60
			C60,284.431,46.569,271,30,271z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M45,211c-8.291,0-15,6.709-15,15v15c33.091,0,60,26.909,60,60v60c0,33.091-26.909,60-60,60v15c0,8.291,6.709,15,15,15
			c76.901,0,138.39,24.286,196,61V259.594C183.13,226.414,120.903,211,45,211z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M482,241v-15c0-8.291-6.709-15-15-15c-75.903,0-138.13,15.414-196,48.594V512c57.61-36.714,119.099-61,196-61
			c8.291,0,15-6.709,15-15v-15c-33.091,0-60-26.909-60-60v-60C422,267.909,448.909,241,482,241z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};

export default IconStudents;
