import { useState } from "react";
import {
  RadioGroupContainer,
  RadioIcon,
  RadioInput,
  RadioLabel,
  RadioOption,
} from "./styles";

export type RadioOptionProps = {
  label: string;
  value: number;
};

export type RadioGroupProps = {
  options: RadioOptionProps[];
  name: string;
  onChange: (value: string) => void;
  defaultValue?: string;
};

export const RadioGroup: React.FC<RadioGroupProps> = ({
  options,
  name,
  onChange,
  defaultValue = "",
}) => {
  const [selectedValue, setSelectedValue] = useState<string>(defaultValue);

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSelectedValue(value);
    onChange(value);
  };

  return (
    <RadioGroupContainer>
      {options.map((option) => (
        <RadioOption
          key={option.value}
          selected={+selectedValue === option.value}
        >
          <RadioLabel>{option.label}</RadioLabel>
          <RadioInput
            type="radio"
            name={name}
            value={option.value}
            checked={+selectedValue === option.value}
            onChange={handleRadioChange}
          />
          <RadioIcon />
        </RadioOption>
      ))}
    </RadioGroupContainer>
  );
};
