import styled from "styled-components";
import * as colors from "styles/colors";

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space[1]};

  div:first-child {
    display: flex;
    align-items: center;

    label {
      margin: 0;
    }
  }
`;

export const SwitchLabel = styled.p`
  margin: 0;
  cursor: pointer;
`;
