import { NewTableContainer, NewTableStyle } from "./style";

interface INewTable {
  children: React.ReactNode;
  width?: number;
}

export const NewTableRoot = ({ children, width = 967 }: INewTable) => {
  return (
    <NewTableContainer>
      <NewTableStyle width={width}>{children}</NewTableStyle>
    </NewTableContainer>
  );
};
