import { useContext, useState, useEffect } from "react";
import { UploadLetterStyle, IconStyle, InputLabel } from "./style";
import { LinearProgress, Tooltip } from "@material-ui/core";
import { ReducedHorizontalStepper } from "components/Timeline";
import { useLocation } from "react-router-dom";
import HTTP_STATUS from "http-status-codes";
import { Field, Form } from "react-final-form";
import { Button, Checkbox, Modal } from "components";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { getStudentData } from "services/users.service";
import {
  uploadDocuments,
  getDocumentsTypes,
  GetDocumentsResponse,
} from "services/documents.service";
import {
  StudentDataInterface,
  StudentInterface,
  GetCXUserJson,
} from "interfaces/constants";
import { updateEnrollment } from "services/enrollment.service";
import { toastError, toastSuccess } from "components/Toast";
import { ModalLetter } from "./components/ModalLetter";
import { ModalCheckbox } from "./components/ModalCheckbox";
import { FINANCIAL_STATUS } from "helpers/constants";
import loadingGif from "static/img/loading.gif";

type StateType = {
  student: StudentInterface;
  guardian: GetCXUserJson;
};

interface DocumentInfosInterface {
  lastModified: number;
  name: string;
  size: number;
  type: string;
}

export const UploadLetter = () => {
  const location = useLocation<StateType>();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [timelineStep] = useState(1);
  const [studentInfos, setStudentInfos] =
    useState<StudentDataInterface | null>();
  const [enrollmentId, setEnrollmentId] = useState<number>();
  const [arrayDocuments, setArrayDocuments] =
    useState<Array<GetDocumentsResponse>>();

  const [openModalLetter, setOpenModalLetter] = useState(false);
  const [openModalCheckBox, setOpenModalCheckBox] = useState(false);

  const [file, setFile] = useState<string | null>(null);
  const [infosFiles, setInfosFiles] = useState<DocumentInfosInterface | null>(
    null
  );

  const [hasDocument, setHasDocument] = useState<boolean>(false);

  const [theCheckbox, setTheCheckbox] = useState<boolean>(false);
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [documentType, setDocumentType] = useState<number>();

  const onChange = (e: any) => {
    const file = e.target.files[0];
    setInfosFiles(e.target.files[0]);
    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const binaryString = e.target.result;
        setFile(btoa(binaryString));
      };
      reader.readAsBinaryString(file);
    }
  };

  useEffect(() => {
    const getTheDocuments = async () => {
      const getType = await getDocumentsTypes();
      if (getType && getType.status === HTTP_STATUS.OK) {
        setArrayDocuments(getType.data);
      } else {
        setError(true);
      }

      const getStudentLocation = await getStudentData({
        id: location.state.student.id,
      });
      if (getStudentLocation && getStudentLocation.status === HTTP_STATUS.OK) {
        setStudentInfos(getStudentLocation.data);
      } else {
        setError(true);
      }
      setLoading(false);
      if (!getType || !getStudentLocation) {
        setLoading(false);
      }
    };

    try {
      if (location.state.student && location.state.guardian) {
        getTheDocuments();
      } else {
        setError(true);
      }

      if (location.state.student.last_enrollment) {
        setEnrollmentId(location.state.student.last_enrollment.id);
      } else {
        setError(true);
      }
    } catch (err) {
      setError(true);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async () => {
    if (hasDocument && !theCheckbox) {
      if (infosFiles && arrayDocuments) {
        const uploadDocument = await uploadDocuments({
          enrollment: location.state.student.last_enrollment!.id,
          document_type: documentType,
          file_name: infosFiles.name,
          user_id: location.state.guardian.user_id,
          user_name: location.state.guardian.personal.name.toString(),
          document_file: `data:${infosFiles.type};base64,${file}`,
        });

        if (uploadDocument) {
          if (uploadDocument.status === HTTP_STATUS.CREATED) {
            toastSuccess("Documento enviado com sucesso!");
            setOpenModalLetter(true);
          } else {
            toastError("Erro! Tente novamente mais tarde.");
          }
        }
      }
    }
    if (theCheckbox) {
      const updateStatus = await updateEnrollment({
        enrollment_id: enrollmentId,
        body: {
          financial_status: FINANCIAL_STATUS.EM_ATENDIMENTO_SAF,
          user_id: location.state.guardian.user_id,
          user_name: location.state.guardian.personal.name,
        },
      });

      if (updateStatus) {
        if (updateStatus.status === HTTP_STATUS.OK) {
          toastSuccess("Status da matrícula atualizado com sucesso!");
          setOpenModalCheckBox(true);
        } else {
          toastError("Erro! Tente novamente mais tarde.");
        }
      }
    }
  };

  const handleClick = () => {
    if (!theCheckbox) {
      setTheCheckbox(true);
      setInfosFiles(null);
      setHasDocument(false);
    } else {
      setTheCheckbox(false);
    }
  };

  return (
    <UploadLetterStyle className="container">
      {loading && <LinearProgress className="mt-3 mb-3" color="secondary" />}
      {!loading && error && <div>Erro ao carregar estudante.</div>}
      {location.state.student &&
        studentInfos &&
        arrayDocuments &&
        !error &&
        !loading && (
          <div>
            <div className="enrollment-title text-center">
              Matrícula de {location.state.student.name}
              {location.state.student.last_enrollment && (
                <div className="subtitle">
                  {location.state.student.last_enrollment.grade || "Sem ano"}
                  {location.state.student.last_enrollment.grade && "º ano do "}
                  {
                    location.state.student.last_enrollment
                      .educational_level_name
                  }{" "}
                  - Unidade {location.state.student.last_enrollment.unit_name}
                </div>
              )}
            </div>
            <div className="row">
              <div className="offset-sm-4 col-sm-4 col-12">
                <ReducedHorizontalStepper step={timelineStep} />
              </div>
            </div>
            <Form onSubmit={onSubmit}>
              {({ form, handleSubmit, submitting, values }) => (
                <form onSubmit={handleSubmit}>
                  <p>
                    Olá! Nosso financeiro precisa de uma declaração de
                    adimplência da escola anterior. <br />
                    Anexe o documento abaixo para que possamos seguir com a
                    matrícula, por favor.
                  </p>
                  <div className="row col-sm-5">
                    <p>Declaração de adimplência da escola anterior.</p>
                    <Tooltip
                      title="Documento que comprova a insenção de dívidas na escola
                      particular anterior do aluno. solicite esse documento na
                      escola anterior, caso o aluno venha de escola particular."
                      arrow
                      className="mt-0"
                      open={tooltipIsOpen}
                      onOpen={() => setTooltipIsOpen(true)}
                      onClose={() => setTooltipIsOpen(false)}
                    >
                      <IconStyle>
                        <HelpOutlineIcon
                          onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
                        />
                      </IconStyle>
                    </Tooltip>
                  </div>
                  {arrayDocuments.map((documents) => {
                    return (
                      <div>
                        {documents.name === "Carta de quitação de débitos" && (
                          <div>
                            <input
                              accept=".pdf, .jpeg, .png, .jpg, .jfif, .heic"
                              style={{ display: "none" }}
                              id="button-file"
                              multiple
                              type="file"
                              onChange={onChange}
                              disabled={theCheckbox}
                              name="inputFile"
                              value=""
                            />
                            <div className="row">
                              <div className="col-sm-6 col-12">
                                <InputLabel
                                  className={`${
                                    theCheckbox ? "disabled" : "outline"
                                  } d-flex justify-content-center align-items-center`}
                                  htmlFor="button-file"
                                  onClick={() => {
                                    setHasDocument(true);
                                    setDocumentType(documents.id);
                                  }}
                                >
                                  {infosFiles
                                    ? infosFiles.name
                                    : documents.name}
                                </InputLabel>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  })}
                  <div className="row flex-nowrap">
                    <Field
                      component={Checkbox}
                      name="dont-have-letter"
                      onClick={() => {
                        handleClick();
                      }}
                      required={hasDocument ? false : true}
                    />
                    <p className="mt-3">
                      Não tenho como obter a declaração de adimplência da escola
                      anterior .
                    </p>
                  </div>
                  <div className="col-sm-3 offset-sm-9 col-12">
                    <Button>
                      {!submitting && "Avançar"}
                      {submitting && (
                        <img height="25" src={loadingGif} alt="loading" />
                      )}
                    </Button>
                  </div>
                  <Modal
                    setToggleModal={setOpenModalLetter}
                    isOpen={openModalLetter}
                  >
                    <ModalLetter
                      setToggleModal={setOpenModalLetter}
                      user={location.state.guardian}
                    />
                  </Modal>
                  <Modal
                    setToggleModal={setOpenModalCheckBox}
                    isOpen={openModalCheckBox}
                  >
                    <ModalCheckbox
                      setToggleModal={setOpenModalCheckBox}
                      user={location.state.guardian}
                    />
                  </Modal>
                </form>
              )}
            </Form>
          </div>
        )}
    </UploadLetterStyle>
  );
};
