// React
import { useContext, useEffect, useState } from "react";
// Style
import { FoodOptionStyle } from "./style";
// Libs
import { LinearProgress } from "@material-ui/core";
import HTTP_STATUS from "http-status-codes";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
// Interfaces
import {
  GetCXUserJson,
  MonthInterface,
  StudentInterface,
} from "interfaces/constants";
import {
  FoodItemAvailableRangeInterface,
  FoodItemInterface,
  FoodPackage,
} from "interfaces/mealInterfaces";
// Helpers
import { nextMonth } from "helpers/helpers";
import { MONTHS } from "helpers/constants";
// Services
import { getFoodPackage, getFoodItems } from "services/meals.service";
//Components
import { Step1 } from "./components/Step1";
import { Step2 } from "./components/Step2";
import { Step3 } from "./components/Step3";
import { Step4 } from "./components/Step4";
import { Step5 } from "./components/Step5";
import { EnrollmentStepCircle } from "components";

interface LocationInterface {
  user: GetCXUserJson;
  student: StudentInterface;
  foodItemsAvailableRanges: Array<FoodItemAvailableRangeInterface>;
  guardianFoodPackage: Array<FoodPackage>;
  step?: boolean;
  smart?: boolean;
}

const STEPS = {
  STEP_ONE: 0,
  STEP_TWO: 1,
  STEP_THREE: 2,
  STEP_FOUR: 3,
  STEP_FIVE: 4,
};

export const FoodOption = () => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.foodOption";

  const location = useLocation<LocationInterface>();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [getAllData, setGetAllData] = useState(false);

  // Guardian e Student
  const [user] = useState<GetCXUserJson>(location.state.user);
  const [student] = useState<StudentInterface>(location.state.student);

  // Steps
  const [activeStep, setActiveStep] = useState<number>(0);

  // Datas
  const newDate = new Date();
  const nextMonthPackage = newDate.getMonth() + 2;
  const [month] = useState<MonthInterface>(
    MONTHS.find(
      (packageMonth) => packageMonth.value === nextMonthPackage.toString()
    )!
  );

  // FoodItems da unidade do aluno e dados do pacote do aluno
  const [foodItems, setFoodItems] = useState<Array<FoodItemInterface>>();
  const [foodPackage, setFoodPackageState] = useState<FoodPackage>();
  // Faz uma nova requisição ao salvar op pacote
  const [newPackage, setNewPackage] = useState(true);
  // Metodo de pagamento
  const [paymentMethod, setPaymentMethod] = useState<number>();
  // Itens selecionados
  const [packageItems, setPackageItems] = useState<Array<FoodItemInterface>>(
    []
  );
  // Valor do pacote
  const [packageValue, setPackageValue] = useState<number>(0);

  // Credit
  const [useCreditValue, setUseCreditValue] = useState(false);

  useEffect(() => {
    if (user) {
      const getFoodPackageData = async () => {
        const response = await getFoodPackage({
          params: {
            enrollment: student.enrollment.id.toString(),
          },
        });
        if (
          response &&
          response.data &&
          response.data.length > 0 &&
          response.status === HTTP_STATUS.OK
        ) {
          if (response.data[0].id) {
            setActiveStep(
              response.data[0].food_package_value_month <= 0 ||
                location.state.smart
                ? 4
                : 2
            );
          }

          setFoodPackageState(response.data[0]);
          setNewPackage(false);
        } else {
          setLoading(false);
          setError(true);
        }

        const getFoodItemsResponse = await getFoodItems({
          unit: student.enrollment.unit_id.toString(),
          enrollment: student.enrollment.id,
          in_force: true,
        });
        if (
          getFoodItemsResponse &&
          getFoodItemsResponse.data &&
          getFoodItemsResponse.status === HTTP_STATUS.OK
        ) {
          setFoodItems(getFoodItemsResponse.data);
          setLoading(false);
        } else {
          setLoading(false);
          setError(true);
        }
      };
      getFoodPackageData();

      if (location.state.step) {
        setActiveStep(2);
      }
    } else {
      setError(true);
      setLoading(false);
    }
    setGetAllData(true);
  }, [nextMonthPackage, location, student, user, newPackage]);

  const takeCurrentStep = (step: number) => {
    if (!location.state.smart) {
      switch (step) {
        case 0:
          return t(`${TRANSLATE}.step0`);
        case 1:
          return t(`${TRANSLATE}.step1`);
        case 2:
          return t(`${TRANSLATE}.step2`);
        case 3:
          return t(`${TRANSLATE}.step3`);
        default:
          return "";
      }
    } else {
      switch (step) {
        case 0:
          return t(`${TRANSLATE}.step0`);
        case 1:
          return t(`${TRANSLATE}.step1`);
        default:
          return "";
      }
    }
  };

  return (
    <FoodOptionStyle>
      {loading && <LinearProgress className="mt-3 mb-3" color="secondary" />}
      {error && <div>{t(`${TRANSLATE}.studentDataError`)}</div>}
      <div className="col-sm-6">
        {user &&
          foodPackage &&
          location.state.foodItemsAvailableRanges &&
          getAllData &&
          !error &&
          !loading && (
            <div>
              {activeStep !== STEPS.STEP_FIVE && (
                <EnrollmentStepCircle
                  studentName={student.name}
                  nextStep={takeCurrentStep(activeStep + 1)}
                  step={activeStep + 1}
                  totalSteps={location.state.smart ? 2 : 4}
                  currentStepName={takeCurrentStep(activeStep)}
                  subtitle={t(`${TRANSLATE}.componentSubTitle`)}
                  width="100%"
                />
              )}
              {activeStep === STEPS.STEP_FIVE && (
                <EnrollmentStepCircle
                  studentName={student.name}
                  subtitle={t(`${TRANSLATE}.componentSubTitle`)}
                  width="100%"
                />
              )}
              {activeStep === STEPS.STEP_ONE &&
                student &&
                foodItems &&
                foodPackage && (
                  <Step1
                    foodItems={foodItems}
                    smart={location.state.smart}
                    student={student}
                    foodPackage={foodPackage}
                    packageValue={packageValue}
                    setActiveStep={setActiveStep}
                    setPackageItems={setPackageItems}
                    setPackageValue={setPackageValue}
                  />
                )}
              {activeStep === STEPS.STEP_TWO &&
                student &&
                foodItems &&
                month &&
                packageItems && (
                  <Step2
                    packageItems={packageItems}
                    packageValue={packageValue}
                    foodPackage={foodPackage}
                    setActiveStep={setActiveStep}
                    setPackageValue={setPackageValue}
                    smart={location.state.smart}
                    setNewPackage={setNewPackage}
                    useCreditValue={useCreditValue}
                    setUseCreditValue={setUseCreditValue}
                  />
                )}
              {activeStep === STEPS.STEP_THREE &&
                foodItems &&
                foodPackage &&
                !location.state.smart && (
                  <Step3
                    setActiveStep={setActiveStep}
                    activeStep={activeStep}
                    foodPackage={foodPackage}
                    setPaymentMethod={setPaymentMethod}
                    paymentMethod={paymentMethod}
                  />
                )}
              {activeStep === STEPS.STEP_FOUR &&
                paymentMethod &&
                foodPackage &&
                !location.state.smart && (
                  <Step4
                    paymentMethod={paymentMethod}
                    foodPackage={foodPackage}
                    setActiveStep={setActiveStep}
                    setNewPackage={setNewPackage}
                    student={student}
                  />
                )}
              {activeStep === STEPS.STEP_FIVE && foodPackage && (
                <Step5
                  foodPackage={foodPackage}
                  user={user}
                  smart={location.state.smart}
                />
              )}
            </div>
          )}
      </div>
    </FoodOptionStyle>
  );
};
