import styled from "styled-components";

export const ModalDiv = styled.div`
  font-size: 14px;
  text-align: justify;
  color: #666666;

  @media only screen and (max-width: 414px) {
    margin-right: 1em;
    margin-left: 1em;
  }

  strong {
    color: #333333;
    font-size: 16px;
    &.title {
      font-size: 20px;
    }
  }

  div {
    margin: 1em 0;
  }

  b {
    color: #333333;
  }
`;
