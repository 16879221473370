// React
import { useEffect, useState } from "react";
// Style
import * as S from "./styled";
// Components
import { Table } from "components/organisms/Table";
import { Loading, LoadingPage } from "components";
import { DrawerRegistrationSalesPeriod } from "./components/Drawer";
// Helpers
import { HPeriodEnrollmentReenrollment } from "./helper";
// Interfaces
import { IForm } from "./interface";
import { ISalesPeriod } from "interfaces/sales";
// Libs
import { useTranslation } from "react-i18next";

export const PeriodEnrollmentReenrollment = () => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.periodEnrollmentReenrollment";

  const [form, setForm] = useState<IForm>({
    year: 0,
    unit: 0,
  });
  const [selectedUnit, setSelectedUnit] = useState<ISalesPeriod>();
  const [drawer, setDrawer] = useState(false);

  const { loading, error, years, units, salesPeriod, checkValue } =
    HPeriodEnrollmentReenrollment({ form, drawer });

  const tableHead = [
    t(`${TRANSLATE}.thUnit`),
    t(`${TRANSLATE}.thEnrollmentStartSales`),
    t(`${TRANSLATE}.thEnrollmentEndSales`),
    t(`${TRANSLATE}.thRenrollmentStartSales`),
    t(`${TRANSLATE}.thRenrollmentEndSales`),
  ];

  useEffect(() => {
    if (years) {
      setForm({ ...form, year: years.results[0].value });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [years]);

  if (loading) {
    return <LoadingPage text={t(`${TRANSLATE}.loadingMessage`)} />;
  }

  if (error) {
    return (
      <S.ErrorContainer>
        <div>{t(`${TRANSLATE}.errorMessage`)}</div>
      </S.ErrorContainer>
    );
  }

  return (
    <S.PeriodEnrollmentReenrollmentStyled>
      <h2>{t(`${TRANSLATE}.title`)}</h2>

      <S.Form>
        <S.SelectContainer>
          <label>{t(`${TRANSLATE}.inputYear`)}</label>
          <select
            onChange={(e) => {
              setForm({ ...form, year: parseInt(e.target.value) });
            }}
            name="enrollmentType"
            value={form.year}
            required
          >
            <option value={0}>{t(`${TRANSLATE}.defaultSelect`)}</option>
            {years?.results.map((year, index) => {
              return (
                <option value={year.value} key={index}>
                  {year.label}
                </option>
              );
            })}
          </select>
        </S.SelectContainer>

        <S.SelectContainer>
          <label>{t(`${TRANSLATE}.inputUnit`)}</label>
          <select
            onChange={(e) => {
              setForm({ ...form, unit: parseInt(e.target.value) });
            }}
            name="enrollmentType"
            value={form.unit}
          >
            <option value={0}>{t(`${TRANSLATE}.allUnits`)}</option>
            {units?.map((unit, index) => {
              return (
                <option value={unit.id} key={index}>
                  {unit.name}
                </option>
              );
            })}
          </select>
        </S.SelectContainer>
      </S.Form>

      <S.TableContainer>
        <h4>{t(`${TRANSLATE}.results`)}</h4>

        {salesPeriod && (
          <Table tableHead={tableHead}>
            {salesPeriod.results.map((unit, index) => {
              return (
                <S.TR
                  onClick={() => {
                    setSelectedUnit(unit);
                    setDrawer(true);
                  }}
                  key={index}
                >
                  <td>{unit.unidade_nome}</td>
                  <td>{checkValue(unit.inicio_vendas_matricula)}</td>
                  <td>{checkValue(unit.termino_vendas_matricula)}</td>
                  <td>{checkValue(unit.inicio_vendas_rematricula)}</td>
                  <td>{checkValue(unit.termino_vendas_rematricula)}</td>
                </S.TR>
              );
            })}
          </Table>
        )}
        {!salesPeriod && <Loading />}
      </S.TableContainer>

      {selectedUnit && (
        <DrawerRegistrationSalesPeriod
          drawer={drawer}
          setDrawer={setDrawer}
          unitInfo={selectedUnit}
        />
      )}
    </S.PeriodEnrollmentReenrollmentStyled>
  );
};
