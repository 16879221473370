// React
import { useState } from "react";
// Style
import { StudentCardBox } from "./style";
// Interfaces
import { ActiveFoodPackageResultsInterface } from "interfaces/mealInterfaces";
// Icons
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
// Helpers
import { FOOD_PAYMENT_STATUS, MONTHS } from "helpers/constants";
import { formatMoney } from "helpers/helpers";
import { ModalCancelFoodPackage } from "../Modal";

type CardFoodPackageType = {
  foodPackage: ActiveFoodPackageResultsInterface;
};

export const CardFoodPackage = ({ foodPackage }: CardFoodPackageType) => {
  const [packageInfos, setPackageInfos] = useState(false);
  const [isActive, setIsActive] = useState(true);

  const [toggleModal, setToggleModal] = useState(false);

  const newDate = new Date();

  return (
    <StudentCardBox>
      <div className="d-flex w-100">
        <div className="studentName mb-0">{foodPackage.student_name}</div>
        {!packageInfos && (
          <div
            className="viewPackageInfos"
            onClick={() => {
              setPackageInfos(true);
            }}
          >
            <KeyboardArrowDownIcon />
            ver mais
          </div>
        )}
        {packageInfos && (
          <div
            className="viewPackageInfos "
            onClick={() => {
              setPackageInfos(false);
            }}
          >
            <KeyboardArrowUpIcon />
            ver menos
          </div>
        )}
      </div>
      <div className="studentInfo">
        {foodPackage.unit_name} - {foodPackage.grade}º{foodPackage.section}{" "}
      </div>
      <div className="mt-3 border-bottom">
        <div className="mb-0 guardianName">
          {foodPackage.financial_guardian_name}
        </div>
        <div className="studentInfo">Responsável Financeiro</div>
      </div>
      <div className="mt-2 d-flex">
        <div className="packageMonth mb-0 mr-2">
          Pacote de {foodPackage.month_name}
        </div>
        {isActive ? (
          <>
            {foodPackage.payment_status ===
            FOOD_PAYMENT_STATUS.PAGAMENTO_CONFIRMADO ? (
              <div className="activePackage">Ativo</div>
            ) : (
              <div className="pendingPayment">Aguardando Pagamento</div>
            )}
          </>
        ) : (
          <div className="disabledPackage">Cancelado</div>
        )}
      </div>
      {packageInfos && (
        <div className="viewMoreInfos">
          {foodPackage.package.map((fp) => {
            return (
              <div className="w-100 d-flex">
                <div className="text-dark w-50">{fp.food_item_name}</div>
                <div className="w-50 d-flex justify-content-end">
                  {formatMoney(fp.food_item_value)}
                </div>
              </div>
            );
          })}
          <div className="w-100 d-flex mt-1">
            <div className="w-50">Total da refeição</div>
            <div className="w-50 d-flex justify-content-end">
              R$ {foodPackage.food_package_value_day_formated}
            </div>
          </div>
          <div className="d-flex w-100 border-bottom justify-content-end">
            <div className="mb-2 mt-1">
              x{foodPackage.working_days} dias letivos
            </div>
          </div>
          <div className="w-100 d-flex mt-2 totalValue">
            <div className="w-50">Total do Pacote:</div>
            <div className="w-50 d-flex justify-content-end">
              R$ {foodPackage.food_package_value_month_formated}
            </div>
          </div>
          <div className="d-flex w-100 justify-content-end">
            <div
              className="mb-2 mt-3 cancelFoodPackageButton"
              onClick={() => {
                setToggleModal(true);
              }}
            >
              Cancelar Pacote
            </div>
          </div>
        </div>
      )}
      <ModalCancelFoodPackage
        setIsActive={setIsActive}
        setToggleModal={setToggleModal}
        isOpen={toggleModal}
        foodPackage={foodPackage.id}
      />
    </StudentCardBox>
  );
};
