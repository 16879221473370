import styled from "styled-components";

export const Container = styled.div`
  strong {
    color: #00aeef;
  }

  span {
    display: flex;
    justify-content: flex-start;

    font-size: 12px;
    color: #717171;
  }

  .buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 0 4px;

    width: 295px;

    margin-top: 16px;

    button {
      width: 104px;
      height: 37px;
    }
  }
`;

export const SelectContainer = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;

  label {
    color: #666666;
    font-size: 12px;

    height: 17px;

    padding: 0;
    margin-right: auto;
  }

  select {
    margin-top: 0;
  }
`;
