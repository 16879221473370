import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: ${({ theme }) => theme.space[7]};
  }
`;
