import { useMemo, useState } from "react";
import Icon from "@mdi/react";
import dayjs from "dayjs";

import { Title } from "components/atoms";

import { mdiChevronLeft, mdiChevronRight } from "@mdi/js";

import { getWeekDays } from "helpers/get-week-days";
import { ICalendarProps, CalendarWeeks } from "./type";

import * as S from "./style";

export const Calendar = ({
  selectedDates,
  activeDates,
  handleSelectedDate,
  noActions = false,
  borderSpacing,
  marginTop,
}: ICalendarProps) => {
  const initialSelectedMonth =
    noActions && selectedDates
      ? selectedDates[0].split("/")[1]
      : dayjs().get("month") + 1;

  const [currentDate, setCurrentDate] = useState(() => {
    return dayjs()
      .set("date", 1)
      .set("month", Number(initialSelectedMonth) - 1);
  });

  const currentMonthMemo = useMemo(
    () => dayjs().set("date", 1).format("MMMM"),
    []
  );

  const currentMonth = currentDate.format("MMMM");
  const shortWeekDays = getWeekDays({ short: true });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const calendarWeeks = useMemo(() => {
    // todos os dias do mês
    const daysInMonthArray = Array.from({
      length: currentDate.daysInMonth(),
    }).map((_, i) => {
      return currentDate.set("date", i + 1);
    });

    // primeiro dia da semana (dia da semana)
    const firstWeekDay = currentDate.get("day");

    // ultimos dias do mês anterior
    const previousMonthFillArray = Array.from({
      length: firstWeekDay,
    })
      .map((_, i) => {
        return currentDate.subtract(i + 1, "day");
      })
      .reverse();

    // último dia do mês
    const lastDayInCurrentMonth = currentDate.set(
      "date",
      currentDate.daysInMonth()
    );

    // último dia da semana atual
    const lastWeekDay = lastDayInCurrentMonth.get("day");

    const nextMonthFillArray = Array.from({
      length: 7 - (lastWeekDay + 1),
    }).map((_, i) => {
      return lastDayInCurrentMonth.add(i + 1, "day");
    });

    // dias do calendário atual
    const calendarDays = [
      ...previousMonthFillArray.map((date) => {
        return {
          date,
          disabled: activeDates?.includes(date.format("DD/MM")) ? false : true,
        };
      }),
      ...daysInMonthArray.map((date) => {
        return {
          date,
          disabled: activeDates?.includes(date.format("DD/MM")) ? false : true,
        };
      }),
      ...nextMonthFillArray.map((date) => {
        return {
          date,
          disabled: activeDates?.includes(date.format("DD/MM")) ? false : true,
        };
      }),
    ];

    // retorna as semanas com os dias que são desabilitados
    const calendarWeeks = calendarDays.reduce<CalendarWeeks>(
      (weeks, _, i, original) => {
        const isNewWeek = i % 7 === 0;

        if (isNewWeek) {
          weeks.push({
            week: i / 7 + 1,
            days: original.slice(i, i + 7),
          });
        }

        return weeks;
      },
      []
    );

    return calendarWeeks;
  }, [currentDate, activeDates]);

  const handlePreviousMonth = () => {
    const previousMonthDate = currentDate.subtract(1, "month");

    setCurrentDate(previousMonthDate);
  };

  const handleNextMonth = () => {
    const nextMonthDate = currentDate.add(1, "month");

    setCurrentDate(nextMonthDate);
  };

  return (
    <S.Container marginTop={marginTop}>
      <S.Header>
        <Title fontSize="24px" className="m-0">
          {currentMonth}
        </Title>

        <S.Actions>
          <button
            onClick={handlePreviousMonth}
            disabled={currentMonth === currentMonthMemo}
          >
            <Icon path={mdiChevronLeft} size={1} />
          </button>
          <button
            onClick={handleNextMonth}
            disabled={currentMonth === "dezembro"}
          >
            <Icon path={mdiChevronRight} size={1} />
          </button>
        </S.Actions>
      </S.Header>

      <S.Body borderSpacing={borderSpacing}>
        <thead>
          <tr>
            {shortWeekDays.map((weekDay, index) => (
              <th key={`${weekDay}-${index}`}>{weekDay}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {calendarWeeks.map(({ week, days }, index) => {
            return (
              <tr key={`${week}-${index}`}>
                {days.map(({ date, disabled }) => {
                  return (
                    <td key={date.toString()}>
                      <S.CalendarDay
                        onClick={() =>
                          handleSelectedDate && handleSelectedDate(date)
                        }
                        disabled={disabled}
                        selected={selectedDates?.includes(date.format("DD/MM"))}
                      >
                        {date.get("date")}
                      </S.CalendarDay>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </S.Body>
    </S.Container>
  );
};
