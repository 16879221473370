import styled from "styled-components";

import * as colors from "styles/colors";

export const Container = styled.div`
  ul {
    color: ${colors.NEW_GRAY_ONE};

    li {
      font-size: 12px;
      line-height: 18px;
      text-align: left;
    }
  }
`;
