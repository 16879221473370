import { LinearProgress } from "@material-ui/core";
import { Button } from "components";
import { CalendarComponent } from "components/Calendar";
import HTTP_STATUS from "http-status-codes";
import SelectInput from "components/Select";
import { toastError, toastSuccess, toastWarning } from "components/Toast";
import { MONTHS, YEARS } from "helpers/constants";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { Field, Form } from "react-final-form";
import { exportFoodPackages, exportSingleFoods } from "services/meals.service";
import { getUnits, UnitInterface } from "services/units.service";
import loadingGif from "static/img/loading.gif";
import { RelatorioDeAlimentacaoStyled } from "./style";
import { MultiSelect } from "react-multi-select-component";

export const RelatorioDeAlimentacaoAvulsa = () => {
  const [units, setUnits] = useState<Array<UnitInterface>>();
  const [loading, setLoading] = useState(true);
  const [csvData, setCsvData] = useState<any>();
  const [error, setError] = useState(false);
  const [fileName, setFileName] = useState<string>();
  const csvInstance = useRef<any | null>(null);
  const [months, setMonths] = useState([]);

  type ValuesType = {
    unit: string;
    de: string;
    ate: string;
    report_type: string;
    year: string;
  };

  useEffect(() => {
    setLoading(true);
    const getUnitList = async () => {
      const response = await getUnits();
      if (response && response.data) {
        setUnits(response.data);
        setLoading(false);
      } else {
        setError(true);
      }
    };
    getUnitList();
  }, []);

  const onSubmit = async (values: ValuesType) => {
    const { unit, de, ate, report_type, year } = values;
    if (report_type === "package") {
      if (months.length === 0) {
        toastWarning("Selecione ao menos um mês de consumo");
      } else {
        const response = await exportFoodPackages({
          params: {
            unit,
            year,
            months: JSON.stringify(
              months.map((m: any) => {
                return m.id;
              })
            ),
          },
        });
        if (response && response.status === HTTP_STATUS.OK) {
          setFileName(
            `Unidade ${units?.find((u) => u.id.toString() === unit)?.name}"
            )}`
          );
          setCsvData(response);
          setTimeout(() => {
            csvInstance.current.link.click();
            setCsvData(null);
          });
          toastSuccess("A consulta foi concluida com sucesso.");
        } else {
          toastError("A consulta não foi possível ser realizada.");
        }
      }
    } else {
      if (unit) {
        const dataInicio = moment(new Date(de));
        const dataFim = moment(new Date(ate));
        const diferenca = moment.duration(dataFim.diff(dataInicio));
        if (diferenca.asDays() < 31) {
          const response = await exportSingleFoods({
            params: {
              unit,
              init_date: dataInicio.format("DD/MM/YYYY"),
              end_date: dataFim.format("DD/MM/YYYY"),
            },
          });
          if (response) {
            setFileName(
              `Unidade ${
                units?.find((u) => u.id.toString() === unit)?.name
              } - De ${dataInicio.format("DD/MM/YYYY")} - Até ${dataFim.format(
                "DD/MM/YYYY"
              )}`
            );
            setCsvData(response);
            setTimeout(() => {
              csvInstance.current.link.click();
              setCsvData(null);
            });
            toastSuccess("A consulta foi concluida com sucesso.");
          } else {
            toastError("A consulta não foi possível ser realizada.");
          }
        } else {
          if (diferenca.asDays() > 31) {
            toastWarning(
              "A diferença entre os dias selecionados é maior que 31 dias."
            );
          }
        }
      }
    }
  };

  const customValueRenderer = (selected: any, _options: any) => {
    return selected.length
      ? selected.map(({ label }: any) => label + ", ")
      : "Mes(es) de consumo";
  };

  return (
    <RelatorioDeAlimentacaoStyled className="container">
      {csvData && (
        <CSVLink
          ref={csvInstance}
          filename={fileName}
          target="_blank"
          data={csvData.data}
          headers={csvData.headers}
        />
      )}
      <div className="title">Relatórios de alimentação</div>
      {loading && <LinearProgress className="mt-3 mb-3" color="secondary" />}
      {error && <div>Erro ao carregar unidades.</div>}
      {units && (
        <Form onSubmit={onSubmit}>
          {({ form, handleSubmit, submitting, values }) => (
            <form onSubmit={handleSubmit}>
              <Field<string>
                component={SelectInput}
                name="report_type"
                required
              >
                <option value="">Selecione um tipo de relatório</option>
                <option value="single">Relatório de cobrança pós-paga</option>
                <option value="package">
                  Relatório de pacote de alimentação
                </option>
              </Field>
              {values.report_type && (
                <>
                  <div className="row">
                    <div className="col-3">
                      <Field<string>
                        component={SelectInput}
                        name="unit"
                        required
                      >
                        <option value="">Selecione uma unidade</option>
                        {units.map((unit, key) => {
                          return (
                            <option value={unit.id} key={key}>
                              {unit.name}
                            </option>
                          );
                        })}
                      </Field>
                    </div>
                    {values.report_type === "single" && (
                      <>
                        <div className="col-3">
                          <Field
                            component={CalendarComponent}
                            name="de"
                            placeholder="Selecione a data de início"
                            required
                          />
                        </div>
                        <div className="col-3">
                          <Field
                            component={CalendarComponent}
                            name="ate"
                            placeholder="Selecione a data de fim"
                            required
                          />
                        </div>
                      </>
                    )}
                    {values.report_type === "package" && (
                      <>
                        <div className="col-3">
                          <Field<string>
                            component={SelectInput}
                            name="year"
                            required
                          >
                            <option value="">Selecione um ano</option>
                            {YEARS.map((year, key) => {
                              return (
                                <option value={year} key={key}>
                                  {year}
                                </option>
                              );
                            })}
                          </Field>
                        </div>
                        <div className="col-3 my-auto">
                          <MultiSelect
                            options={MONTHS}
                            value={months}
                            onChange={setMonths}
                            labelledBy="Selecione mes(es) de consumo"
                            disableSearch
                            valueRenderer={customValueRenderer}
                          />
                        </div>
                      </>
                    )}
                    <div className="col-3">
                      <Button disabled={submitting} type="submit">
                        {!submitting && "Exportar"}
                        {submitting && (
                          <img height="25" src={loadingGif} alt="loading" />
                        )}
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </form>
          )}
        </Form>
      )}
    </RelatorioDeAlimentacaoStyled>
  );
};
