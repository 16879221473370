import { ROLE } from "helpers/constants";

interface PermissionsByPage {
  [key: string]: Array<number>;
}

export const PERMISSIONS_BY_PAGE: PermissionsByPage = {
  "/home": [
    ROLE.GUARDIAN,
    ROLE.VENDOR,
    ROLE.ENROLLMENT_COORDINATOR,
    ROLE.TEACHER,
    ROLE.MANAGER,
    ROLE.CX,
    ROLE.DIRECTOR,
    ROLE.VENDOR_MANAGER,
    ROLE.STUDY_ROOM_EDUCATOR,
    ROLE.OPERATIONAL_DIRECTOR,
    ROLE.GRADES_MANAGER,
    ROLE.SYSTEM_ADMIN,
  ],
  "/alterar-senha": [
    ROLE.GUARDIAN,
    ROLE.VENDOR,
    ROLE.ENROLLMENT_COORDINATOR,
    ROLE.TEACHER,
    ROLE.DIRECTOR,
    ROLE.MANAGER,
    ROLE.VENDOR_MANAGER,
    ROLE.STUDY_ROOM_EDUCATOR,
    ROLE.OPERATIONAL_DIRECTOR,
    ROLE.SECRETARY,
    ROLE.GRADES_MANAGER,
    ROLE.SYSTEM_ADMIN,
  ],
  "/gerenciar/matriculas": [
    ROLE.MANAGER,
    ROLE.DIRECTOR,
    ROLE.ENROLLMENT_COORDINATOR,
    ROLE.VENDOR_MANAGER,
    ROLE.VENDOR,
    ROLE.CX,
    ROLE.OPERATIONAL_DIRECTOR,
    ROLE.GRADES_MANAGER,
    ROLE.SYSTEM_ADMIN,
  ],
  "/gerenciar/turmas": [ROLE.DIRECTOR, ROLE.GRADES_MANAGER, ROLE.SYSTEM_ADMIN],
  "/gerenciar/turmas/editar": [ROLE.DIRECTOR, ROLE.GRADES_MANAGER],
  "/meu-perfil": [ROLE.GUARDIAN],
  "/usuarios/servicos": [ROLE.GUARDIAN],
  "/vendedor": [ROLE.VENDOR, ROLE.VENDOR_MANAGER, ROLE.ENROLLMENT_COORDINATOR],
  "/cadastro-vendedor": [
    ROLE.VENDOR,
    ROLE.VENDOR_MANAGER,
    ROLE.ENROLLMENT_COORDINATOR,
  ],
  "/pagamento/reserva-de-vaga": [ROLE.GUARDIAN],
  "/pagamento/reserva-de-vaga/boleto": [ROLE.GUARDIAN],
  "/pagamento/reserva-de-vaga/cartao": [ROLE.GUARDIAN],
  "/pagamento/reserva-de-vaga/pix": [ROLE.GUARDIAN],
  "/pagamento/reserva-de-vaga/multipay": [ROLE.GUARDIAN],
  "/pagamento/reserva-de-vaga/segundo-cartao": [ROLE.GUARDIAN],
  "/responsavel/editar": [
    ROLE.VENDOR,
    ROLE.VENDOR_MANAGER,
    ROLE.GUARDIAN,
    ROLE.ENROLLMENT_COORDINATOR,
    ROLE.CX,
    ROLE.SECRETARY,
    ROLE.MANAGER,
    ROLE.SYSTEM_ADMIN,
    ROLE.DIRECTOR,
    ROLE.STUDY_ROOM_EDUCATOR,
    ROLE.OPERATIONAL_DIRECTOR,
  ],
  "/responsavel/editar-cadastro": [
    ROLE.VENDOR,
    ROLE.VENDOR_MANAGER,
    ROLE.GUARDIAN,
    ROLE.ENROLLMENT_COORDINATOR,
    ROLE.CX,
    ROLE.SECRETARY,
    ROLE.MANAGER,
  ],
  "/responsavel/busca": [
    ROLE.VENDOR,
    ROLE.VENDOR_MANAGER,
    ROLE.ENROLLMENT_COORDINATOR,
    ROLE.CX,
    ROLE.SECRETARY,
    ROLE.MANAGER,
    ROLE.SYSTEM_ADMIN,
    ROLE.DIRECTOR,
    ROLE.STUDY_ROOM_EDUCATOR,
    ROLE.OPERATIONAL_DIRECTOR,
  ],
  "/responsavel/busca-por-cpf": [
    ROLE.VENDOR,
    ROLE.VENDOR_MANAGER,
    ROLE.ENROLLMENT_COORDINATOR,
    ROLE.SECRETARY,
    ROLE.SYSTEM_ADMIN,
  ],
  "/responsavel/cadastro-aluno": [
    ROLE.GUARDIAN,
    ROLE.VENDOR,
    ROLE.VENDOR_MANAGER,
    ROLE.ENROLLMENT_COORDINATOR,
    ROLE.CX,
    ROLE.MANAGER,
    ROLE.SYSTEM_ADMIN,
  ],
  "/responsavel/meus-alunos": [
    ROLE.GUARDIAN,
    ROLE.VENDOR,
    ROLE.VENDOR_MANAGER,
    ROLE.ENROLLMENT_COORDINATOR,
    ROLE.SYSTEM_ADMIN,
  ],
  "/responsavel/boletim": [
    ROLE.GUARDIAN,
    ROLE.VENDOR,
    ROLE.VENDOR_MANAGER,
    ROLE.ENROLLMENT_COORDINATOR,
    ROLE.SYSTEM_ADMIN,
    ROLE.STUDENT,
  ],
  "/responsavel/meus-alunos/aluno": [
    ROLE.GUARDIAN,
    ROLE.VENDOR,
    ROLE.VENDOR_MANAGER,
    ROLE.ENROLLMENT_COORDINATOR,
    ROLE.CX,
    ROLE.SECRETARY,
    ROLE.MANAGER,
  ],
  "/buscar-vagas": [
    ROLE.VENDOR,
    ROLE.VENDOR_MANAGER,
    ROLE.ENROLLMENT_COORDINATOR,
    ROLE.SYSTEM_ADMIN,
  ],
  "/exportar-totvs": [ROLE.MANAGER, ROLE.SYSTEM_ADMIN],
  "/responsavel/meus-alunos/carta": [ROLE.GUARDIAN],
  "/contrato/confirmacao-dados": [ROLE.GUARDIAN],
  "/contrato/contrato-assinado": [ROLE.GUARDIAN],
  "/responsavel/meus-alunos/documentacao": [ROLE.GUARDIAN],
  "/responsavel/meus-alunos/documentos-matricula": [
    ROLE.GUARDIAN,
    ROLE.ENROLLMENT_COORDINATOR,
    ROLE.SECRETARY,
    ROLE.MANAGER,
    ROLE.CX,
  ],
  "/responsavel/meus-alunos/responsavel-financeiro": [ROLE.GUARDIAN],
  "/responsavel/meus-alunos/manter-responsavel-financeiro": [ROLE.GUARDIAN],
  "/responsavel/alimentacao": [ROLE.GUARDIAN],
  "/responsavel/primeiro-acesso": [ROLE.GUARDIAN],
  "/aluno/editar": [
    ROLE.VENDOR,
    ROLE.VENDOR_MANAGER,
    ROLE.ENROLLMENT_COORDINATOR,
    ROLE.CX,
    ROLE.SECRETARY,
    ROLE.MANAGER,
    ROLE.SYSTEM_ADMIN,
    ROLE.DIRECTOR,
    ROLE.STUDY_ROOM_EDUCATOR,
    ROLE.OPERATIONAL_DIRECTOR,
  ],
  "/diario-de-classe": [
    ROLE.TEACHER,
    ROLE.DIRECTOR,
    ROLE.GRADES_MANAGER,
    ROLE.STUDY_ROOM_EDUCATOR,
    ROLE.SYSTEM_ADMIN,
  ],
  "/consulta-diario-de-classe": [
    ROLE.DIRECTOR,
    ROLE.TEACHER,
    ROLE.GRADES_MANAGER,
    ROLE.SYSTEM_ADMIN,
  ],
  "/financeiro/plano-pagamento": [ROLE.GUARDIAN],
  "/contrato/assinatura-contrato": [ROLE.GUARDIAN],
  "/responsavel/Consultar/documentos": [
    ROLE.ENROLLMENT_COORDINATOR,
    ROLE.SECRETARY,
    ROLE.SYSTEM_ADMIN,
  ],
  "/registro-de-alimentacao": [
    ROLE.STUDY_ROOM_EDUCATOR,
    ROLE.SECRETARY,
    ROLE.SYSTEM_ADMIN,
  ],
  // "/responsavel/consulta-alimentacao": [ROLE.GUARDIAN],
  "/relatorio-de-alimentacao": [
    ROLE.STUDY_ROOM_EDUCATOR,
    ROLE.SECRETARY,
    ROLE.SYSTEM_ADMIN,
  ],
  "/cadastro-dias-uteis": [ROLE.OPERATIONAL_DIRECTOR, ROLE.SYSTEM_ADMIN],
  "/consulta-plano-pagamento": [ROLE.MANAGER, ROLE.SYSTEM_ADMIN],
  "/gestao-itens-alimentacao/form": [
    ROLE.OPERATIONAL_DIRECTOR,
    ROLE.SYSTEM_ADMIN,
  ],
  "/gestao-itens-alimentacao": [ROLE.OPERATIONAL_DIRECTOR, ROLE.SYSTEM_ADMIN],
  "/cadastro-vigencia-pacote-de-alimentacao": [
    ROLE.OPERATIONAL_DIRECTOR,
    ROLE.SYSTEM_ADMIN,
  ],
  "/lancamento/componentes": [
    ROLE.DIRECTOR,
    ROLE.TEACHER,
    ROLE.GRADES_MANAGER,
    ROLE.STUDY_ROOM_EDUCATOR,
    ROLE.GRADES_MANAGER,
    ROLE.SYSTEM_ADMIN,
  ],
  "/lancamento/atribuir": [
    ROLE.DIRECTOR,
    ROLE.TEACHER,
    ROLE.GRADES_MANAGER,
    ROLE.STUDY_ROOM_EDUCATOR,
    ROLE.GRADES_MANAGER,
    ROLE.SYSTEM_ADMIN,
  ],
  // aprovação prévia
  "/boletim": [
    ROLE.DIRECTOR,
    ROLE.TEACHER,
    ROLE.GRADES_MANAGER,
    ROLE.STUDY_ROOM_EDUCATOR,
    ROLE.ENROLLMENT_COORDINATOR,
    ROLE.SECRETARY,
    ROLE.CX,
    ROLE.SYSTEM_ADMIN,
  ],
  "/aprovacao-previa": [
    ROLE.DIRECTOR,
    ROLE.TEACHER,
    ROLE.GRADES_MANAGER,
    ROLE.STUDY_ROOM_EDUCATOR,
    ROLE.ENROLLMENT_COORDINATOR,
    ROLE.SECRETARY,
    ROLE.CX,
    ROLE.SYSTEM_ADMIN,
  ],
  "/publicacao-boletim": [ROLE.DIRECTOR, ROLE.SYSTEM_ADMIN],
  "/dashboard": [
    ROLE.DIRECTOR,
    ROLE.TEACHER,
    ROLE.GRADES_MANAGER,
    ROLE.STUDY_ROOM_EDUCATOR,
    ROLE.ENROLLMENT_COORDINATOR,
    ROLE.SYSTEM_ADMIN,
  ],
  "/historico-dos-boletins": [
    ROLE.DIRECTOR,
    ROLE.TEACHER,
    ROLE.GRADES_MANAGER,
    ROLE.STUDY_ROOM_EDUCATOR,
    ROLE.ENROLLMENT_COORDINATOR,
    ROLE.SECRETARY,
    ROLE.SYSTEM_ADMIN,
  ],
  "/home/boletim": [
    ROLE.DIRECTOR,
    ROLE.TEACHER,
    ROLE.GRADES_MANAGER,
    ROLE.STUDY_ROOM_EDUCATOR,
    ROLE.GUARDIAN,
    ROLE.STUDENT,
    ROLE.SYSTEM_ADMIN,
  ],
  "/selecao_estudante": [ROLE.GUARDIAN],
  "/responsavel/alimentacao/pacote-alimentacao": [ROLE.GUARDIAN],
  "/responsavel/alimentacao/comprar-pacote-alimentacao": [ROLE.GUARDIAN],
  "/alimentacao/cancelar-pacote-alimentacao": [
    ROLE.CX,
    ROLE.MANAGER,
    ROLE.SYSTEM_ADMIN,
  ],
  "/alimentacao/lancar-credito-alimentacao": [
    ROLE.STUDY_ROOM_EDUCATOR,
    ROLE.CX,
    ROLE.SYSTEM_ADMIN,
  ],
  "/matricula/editar-dados-responsavel": [ROLE.GUARDIAN],
  "/matricula/confirmar-dados-responsavel": [ROLE.GUARDIAN],
  "/matricula/pagamento-da-reserva": [ROLE.GUARDIAN],
  "/matricula/documentos-para-contrato": [ROLE.GUARDIAN],
  "/matricula/confirmar-dados-do-estudante": [ROLE.GUARDIAN],
  "/matricula/editar-dados-do-estudante": [ROLE.GUARDIAN],
  "/alimentacao/consultar-alimentacao": [
    ROLE.OPERATIONAL_DIRECTOR,
    ROLE.MANAGER,
    ROLE.STUDY_ROOM_EDUCATOR,
    ROLE.CX,
    ROLE.SYSTEM_ADMIN,
  ],
  "/alimentacao/": [ROLE.GUARDIAN],
  "/alimentacao/compra-avulsa": [ROLE.GUARDIAN],
  "/alimentacao/compra-avulsa/refeicoes": [ROLE.GUARDIAN],
  "/responsavel/alunos": [ROLE.GUARDIAN],
  "/matricula/etapas": [ROLE.GUARDIAN],
  "/indicacao": [ROLE.GUARDIAN],
  "/lista-espera": [
    ROLE.MANAGER,
    ROLE.VENDOR_MANAGER,
    ROLE.VENDOR,
    ROLE.ENROLLMENT_COORDINATOR,
    ROLE.CX,
    ROLE.OPERATIONAL_DIRECTOR,
    ROLE.SYSTEM_ADMIN,
  ],
  "/enturmamento": [ROLE.GRADES_MANAGER, ROLE.DIRECTOR, ROLE.SYSTEM_ADMIN],
  "/regularizar-pagamento": [ROLE.SYSTEM_ADMIN],
  "/enturmamento/editar": [
    ROLE.DIRECTOR,
    ROLE.TEACHER,
    ROLE.GRADES_MANAGER,
    ROLE.STUDY_ROOM_EDUCATOR,
    ROLE.ENROLLMENT_COORDINATOR,
  ],
  "/gerar-arquivo-para-cracha": [
    ROLE.MANAGER,
    ROLE.STUDY_ROOM_EDUCATOR,
    ROLE.SYSTEM_ADMIN,
  ],
  "/roteiros-de-estudos": [ROLE.GUARDIAN, ROLE.STUDENT, ROLE.SYSTEM_ADMIN],
  "/roteiros-de-estudo/estudante": [
    ROLE.DIRECTOR,
    ROLE.SYSTEM_ADMIN,
    ROLE.SECRETARY,
    ROLE.CX,
  ],
  "/saida-do-estudante": [ROLE.GUARDIAN],
  "/autorizacao-de-saida": [
    ROLE.SECRETARY,
    ROLE.OPERATIONAL_DIRECTOR,
    ROLE.SYSTEM_ADMIN,
    ROLE.DIRECTOR,
  ],
  "/selecao-de-estudante": [ROLE.GUARDIAN],
  "/boletos-liste-estudantes": [ROLE.GUARDIAN],
  "/boletos-liste-estudantes/boleto": [ROLE.GUARDIAN],
  "/escolher-projeto": [ROLE.STUDENT],
  // "/calendario-escolar": [ROLE.GRADES_MANAGER, ROLE.SYSTEM_ADMIN],
  "/consultar-pedidos-alimentacao": [
    ROLE.CX,
    ROLE.SECRETARY,
    ROLE.OPERATIONAL_DIRECTOR,
    ROLE.STUDY_ROOM_EDUCATOR,
    ROLE.DIRECTOR,
    ROLE.SYSTEM_ADMIN,
  ],
  "/gerenciar-itens-alimentacao": [
    ROLE.CX,
    ROLE.SECRETARY,
    ROLE.OPERATIONAL_DIRECTOR,
    ROLE.SYSTEM_ADMIN,
  ],
  "/gerenciar-vagas": [
    ROLE.VENDOR,
    ROLE.VENDOR_MANAGER,
    ROLE.MANAGER,
    ROLE.ENROLLMENT_COORDINATOR,
    ROLE.OPERATIONAL_DIRECTOR,
    ROLE.CX,
    ROLE.SECRETARY,
    ROLE.SYSTEM_ADMIN,
  ],
  "/cadastrar-periodo-matricula-rematricula": [
    ROLE.MANAGER,
    ROLE.SYSTEM_ADMIN,
    ROLE.OPERATIONAL_DIRECTOR,
    ROLE.ENROLLMENT_COORDINATOR,
  ],
  "/conselho-classe": [ROLE.DIRECTOR, ROLE.SYSTEM_ADMIN],
  "/plano-de-pagamento": [ROLE.MANAGER],
  "/cadastro-responsavel": [
    ROLE.VENDOR,
    ROLE.VENDOR_MANAGER,
    ROLE.SYSTEM_ADMIN,
  ],
  "/dados-vaga": [ROLE.VENDOR, ROLE.VENDOR_MANAGER, ROLE.SYSTEM_ADMIN],
  "/aprovacao-descontos": [ROLE.VENDOR, ROLE.VENDOR_MANAGER, ROLE.SYSTEM_ADMIN],
  "/buscar-aluno-responsavel": [
    ROLE.VENDOR,
    ROLE.VENDOR_MANAGER,
    ROLE.ENROLLMENT_COORDINATOR,
    ROLE.CX,
    ROLE.SECRETARY,
    ROLE.MANAGER,
    ROLE.SYSTEM_ADMIN,
    ROLE.DIRECTOR,
    ROLE.STUDY_ROOM_EDUCATOR,
    ROLE.OPERATIONAL_DIRECTOR,
  ],
  "/cadastro-estudante": [ROLE.VENDOR, ROLE.VENDOR_MANAGER, ROLE.SYSTEM_ADMIN],
  "/envio-de-documentos": [ROLE.MANAGER],
  "/usuario/envio-de-documentos": [
    ROLE.ENROLLMENT_COORDINATOR,
    ROLE.SECRETARY,
    ROLE.MANAGER,
    ROLE.CX,
  ],
};
