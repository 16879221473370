import styled, { css } from "styled-components";
import { TableHeadInterface, ContainerTableProps } from "./type";

export const Container = styled.div<ContainerTableProps>`
  width: 100%;
  display: block;
  border: none;
  ${(props) =>
    props.scroll &&
    props.overflowY !== null &&
    css`
      max-height: ${`calc((100vh - 2rem) - ${props.overflowY})`};
      overflow-y: auto;
      overflow-x: hidden;
    `}
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 1px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }
`;

export const ContainerTable = styled.table<ContainerTableProps>`
  width: 100%;
  height: 100%;
  text-align: left;
  position: relative;
  border-collapse: collapse;
`;

export const TableHead = styled.thead<TableHeadInterface>`
  width: 100%;
  border-bottom: ${(props) =>
    props.border === true
      ? `${props.borderLarge ? "2px" : "1px"} solid #999999`
      : "none"};
  ${(props) =>
    props.scroll &&
    css`
      position: sticky;
      top: 0;
      background: ${({ theme }) => theme.colors.white};
    `}
`;

export const TableRow = styled.tr`
  width: 100%;
  border-right: none;
`;

export const TableBody = styled.tbody`
  width: 100%;
`;
