import { ErrorHandlerFunction } from "./service-helpers";
import { KanataAPI } from "./api";
import {
  FoodPackagePaymentInterface,
  PaymentMethodsPayload,
  BoletoDataInterface,
  BoletoInfoInterface,
  BoletoIugoResponse,
  PixPaymentIuguResponse,
  GetIuguIDStatusResponse,
  UpdatePayStatusOutOfAppInterface,
  IGetPaymentCheck,
  IPaymentStatus,
} from "interfaces/paymentInterfaces";

export const getPaymentCheck = async ({
  enrollment_id,
  payment_type,
}: {
  enrollment_id: number;
  payment_type: number;
}) => {
  const response = await KanataAPI.get<IGetPaymentCheck>(
    `mamon/payment_check?enrollment_id=${enrollment_id}&payment_type=${payment_type}`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const getMatriculaValue = async ({
  student_id,
}: {
  student_id: number;
}) => {
  const response = await KanataAPI.get<number>(
    `mamon/charge/payment_value/${student_id}`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const getIuguIDStatus = async ({ iugu_id }: { iugu_id: string }) => {
  const response = await KanataAPI.get<GetIuguIDStatusResponse>(
    `mamon/charge/invoice/${iugu_id}`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const pixPaymentIugu = async ({
  value,
  guardian_id,
  student_id,
}: {
  value: number;
  guardian_id: number;
  student_id: number;
}) => {
  const response = await KanataAPI.post<PixPaymentIuguResponse>(
    `mamon/charge/pix/`,
    { value, guardian_id, student_id }
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const creditCardPaymentIugu = async ({
  value,
  guardian_id,
  charge_token,
  student_id,
  brand,
  installments,
}: {
  value: number;
  guardian_id: number;
  charge_token: string;
  student_id: number;
  brand: string;
  installments: number;
}) => {
  const response = await KanataAPI.post<BoletoIugoResponse>(`mamon/charge/`, {
    value,
    guardian_id,
    charge_token,
    student_id,
    brand,
    installments,
  }).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const getBoletoIugo = async ({
  value,
  guardian_id,
  student_id,
}: {
  value: number;
  guardian_id: number;
  student_id: number;
}) => {
  const response = await KanataAPI.post<BoletoIugoResponse>(
    `mamon/charge/billet/`,
    { value, guardian_id, student_id }
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const getPaymentMethods = async ({
  student_id,
}: {
  student_id: number;
}) => {
  const response = await KanataAPI.post<Array<PaymentMethodsPayload>>(
    `totvs/payment_plan`,
    { student_id }
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const getBoleto = async ({
  student_id,
  guardian_id,
}: {
  student_id: number;
  guardian_id: number;
}) => {
  const response = await KanataAPI.post<Array<BoletoDataInterface>>(
    `totvs/payment_slip`,
    { student_id, guardian_id }
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const getBoletoInfo = async ({ student_id }: { student_id: number }) => {
  const response = await KanataAPI.get<BoletoInfoInterface>(
    `totvs/payment_detail/${student_id}`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const updatePayStatusOutOfApp = async ({
  data_reserva,
  id_iugu_1,
  id_iugu_2,
  vendor,
  vendor_justify,
  guardian_id,
  student_id,
}: UpdatePayStatusOutOfAppInterface) => {
  const response = await KanataAPI.post(`mamon/out_enrollment`, {
    data_reserva,
    id_iugu_1,
    id_iugu_2,
    vendor,
    vendor_justify,
    guardian_id,
    student_id,
  }).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const foodPackagePayment = async ({
  enrollment_id,
  value,
  package_id,
  type_payment,
  charge_token,
  acquisition_type,
  single_food_id,
  date,
}: {
  enrollment_id: number;
  value: number;
  package_id?: number;
  type_payment: string | number;
  charge_token?: string;
  acquisition_type?: string;
  date?: string;
  single_food_id?: number[];
}) => {
  const response = await KanataAPI.post<FoodPackagePaymentInterface>(
    `school/food_payment_package/`,
    {
      enrollment_id,
      value,
      package_id,
      type_payment,
      charge_token,
      acquisition_type,
      single_food_id,
      date,
    }
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const regularizerPayment = async ({
  gateway_pagamento,
  enrollment_id,
  invoice_id,
}: {
  gateway_pagamento: number;
  enrollment_id: number;
  invoice_id?: string;
}) => {
  const response = await KanataAPI.post(`mamon/payment_regularizer/`, {
    gateway_pagamento,
    enrollment_id,
    invoice_id,
  }).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface IConfirmarSelecaoAlimentacao {
  matricula: number;
  tipo_pagamento: "pix" | "credit_card" | "credito";
  dias_selecionados: string[];
  itens_selecionados: number[];
  charge_token?: number;
  utiliza_credito?: boolean;
  orderId: number;
}

export const confirmarSelecaoAlimentacao = async ({
  matricula,
  tipo_pagamento,
  dias_selecionados,
  itens_selecionados,
  charge_token,
  utiliza_credito,
}: IConfirmarSelecaoAlimentacao) => {
  const response = await KanataAPI.post(
    "/food/confirmar_selecao_alimentacao/",
    {
      matricula,
      tipo_pagamento,
      dias_selecionados,
      itens_selecionados,
      charge_token,
      utiliza_credito,
    }
  ).catch(ErrorHandlerFunction);

  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const updateConfirmarSelecaoAlimentacao = async ({
  matricula,
  tipo_pagamento,
  dias_selecionados,
  itens_selecionados,
  charge_token,
  utiliza_credito,
  orderId,
}: IConfirmarSelecaoAlimentacao) => {
  const response = await KanataAPI.put(
    `/food/confirmar_selecao_alimentacao/${orderId}/`,
    {
      matricula,
      tipo_pagamento,
      dias_selecionados,
      itens_selecionados,
      charge_token,
      utiliza_credito,
    }
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const paymentStatusList = async () => {
  const response = await KanataAPI.get<IPaymentStatus>(
    "food/status_pagamento/"
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export interface IGetScholarships {
  id: number;
  name: string;
  scholarship_percentage: number;
  scholarship_percentage_monthly: number;
  scholarship_percentage_material: number;
  totvs_code: number;
  active: boolean;
}
export const getScholarships = async () => {
  const response = await KanataAPI.get<IGetScholarships[]>(
    "financial/scholarship/?active=true"
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = {
      data: response.data.results as IGetScholarships[],
      status: response.status,
    };
    return data;
  }
};
