import { Tooltip } from "react-tooltip";
import { InfoOutlined } from "@material-ui/icons";

export const alertContentConfig = {
  title: "Notas acima de 10",
  tooltipId: "invalid-grade",
  tooltipContent: "Nota acima de 10",
  augmentContent: (receivedPhrase: string) => {
    return `
    ${receivedPhrase}.
    Verifique a nota com a sinalização e corrija para prosseguir com a aprovação deste boletim.
    `;
  },
};

export const shouldDisableApproveBtnInCaseOfInvalidGrade = (
  invalidGradeNotice: string
) => {
  return invalidGradeNotice !== null && invalidGradeNotice !== undefined;
};

export const showInvalidGradeTooltip = (grade: number) => {
  const maxAllowedGrade = 10;
  if (grade > maxAllowedGrade) {
    return (
      <>
        <InfoOutlined
          id={alertContentConfig.tooltipId}
          data-tooltip-id={alertContentConfig.tooltipId}
          data-tooltip-content={alertContentConfig.tooltipContent}
          color="error"
        />
        <Tooltip id={alertContentConfig.tooltipId} />
      </>
    );
  }
};
