import { getDocs, collection, getDoc, doc } from "@firebase/firestore";
import { firestoreInstance } from "lib/firebase/config";

interface IFireBaseUniqueDoc {
  collectionPath: string;
  docRef: string;
}

export const useFirebase = () => {
  const getDocsByCollection = async (collectionPath: string) => {
    const colReference = collection(firestoreInstance, collectionPath);
    const querySnapshot = await getDocs(colReference);
    const docs = querySnapshot.docs.map((doc) => {
      return doc.data();
    });
    return docs;
  };

  const getUniqueDoc = async (config: IFireBaseUniqueDoc) => {
    const { collectionPath, docRef } = config;
    const fetchedDocument = doc(firestoreInstance, collectionPath, docRef);
    const querySnapshot = await getDoc(fetchedDocument);
    const data = querySnapshot.data();
    return data;
  };

  return {
    getDocsByCollection,
    getUniqueDoc,
  };
};
