// SVG
import arrowCircleRight from "./arrow_circle_right.svg";
import logo2022icon from "./logo2022_icon.svg";
import logo2022text from "./logo2022_text.svg";
import accountCircle from "./account_circle.svg";
import lockLogin from "./lock-login.svg";
import eyeShow from "./eye-show.svg";
import eyeHidden from "./eye-hidden.svg";
import leftArrow from "./left_arrow.svg";
import rightArrow from "./right_arrow.svg";

// JPG
import loginImg01 from "./login01.jpg";
import loginImg02 from "./login02.jpg";
import loginImg03 from "./login03.jpg";
import loginImg04 from "./login04.jpg";
import loginImg05 from "./login05.jpg";
import loginImg06 from "./login06.jpg";

export const ArrowCircleRightIcon = arrowCircleRight;
export const AccountCircleIcon = accountCircle;
export const LockIcon = lockLogin;
export const EyeShowIcon = eyeShow;
export const EyeHiddenIcon = eyeHidden;

export const Logo22Icon = logo2022icon;
export const Logo22Text = logo2022text;
export const LoginImg01 = loginImg01;
export const LoginImg02 = loginImg02;
export const LoginImg03 = loginImg03;
export const LoginImg04 = loginImg04;
export const LoginImg05 = loginImg05;
export const LoginImg06 = loginImg06;

export const leftArrowSvg = leftArrow;
export const rightArrowSvg = rightArrow;
