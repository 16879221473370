import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${({ theme }) => theme.space[4]} 0;

  .student-documents-group {
    margin-top: ${({ theme }) => theme.space[6]};
  }

  @media (max-width: 965px) {
    margin: ${({ theme }) => theme.space[4]};
  }
`;

export const Subtitle = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes.web.h3};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.brandingOrange};
`;

export const Description = styled.small`
  color: ${({ theme }) => theme.colors.greyScale40};
`;

export const DocumentsCards = styled.div`
  width: 100%;
  max-width: 634px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  flex-wrap: wrap;
  margin: ${({ theme }) => theme.space[4]} auto 0;
  gap: 10px;

  @media (max-width: 965px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const DocumentsSection = styled.div`
  width: 100%;
  max-width: 634px;

  & + & {
    margin-top: ${({ theme }) => theme.space[6]};
  }
`;

export const ButtonsGroup = styled.div`
  width: 100%;
  max-width: 634px;
  margin-top: ${({ theme }) => theme.space[12]};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space[4]};
  max-width: 200px;

  margin-left: auto;

  button {
    flex: 1;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
