import { useContext, useState } from "react";
import { Button, TextInput, RadioButton, Modal } from "components";
import {
  hasToBe6UntilJuneNextYear,
  composeValidators,
  CPFstudent,
  required,
  noSpecialCharactersAndNumbers,
} from "helpers/fieldValidators";
import { Field, Form } from "react-final-form";
import { TextStrong } from "./style";
import formatString from "format-string-by-pattern";
import loadingGif from "static/img/loading.gif";
import { deepCopy, onlyNumbersFromString } from "helpers/helpers";
import { toastError, toastSuccess } from "components/Toast";
import HTTP_STATUS from "http-status-codes";
import {
  bondStudentToGuardian,
  registerNewStudentPreSignUp,
  updateUser,
} from "services/users.service";
import {
  isVendor,
  isEnrollmentCoordinator,
  isVendorManager,
} from "helpers/constants";
import {
  lookupStudents,
  LookupStudentsResponse,
} from "services/student.service";
import ModalSimilarStudents from "./ModalSimilarStudents";
import { formatUpdateStudentPayload } from "pages/EDF/EditStudent/components/StudentForm/helpers";
import { CalendarComponent } from "components/Calendar";
import { Storage } from "Storage";

interface StudentSignUpFormInterface {
  guardianId: number;
  guardianAuthId: string | null;
  name: string | null;
  setStep: (step: number) => void;
  setStudentId: (studentID: number) => void;
}

interface StudentSignUpInterface {
  name: string;
  birth_date: string;
  cpf: string;
  email: string;
  password: string;
  confirm_password: string;
  phone: string;
  rg: string;
  previous_school: string;
}

export const StudentSignUpForm = ({
  guardianId,
  guardianAuthId,
  setStudentId,
  name,
  setStep,
}: StudentSignUpFormInterface) => {
  const [students, setStudents] =
    useState<Array<LookupStudentsResponse> | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const [student, setStudent] = useState<LookupStudentsResponse | undefined>(
    undefined
  );
  const [keepGoing, setKeepGoing] = useState(true);
  const [loading, setLoading] = useState(false);
  const role = Storage.props.role.get();

  const setStudentData = (values: any, form: any) => {
    const student_ = students?.find(
      (student) => student.id.toString() === values.student_id
    )!;
    setStudent(student_);
    form.change("name", student_.name);
    form.change("previous_school", student_.previous_school);
    form.change("birth_date", student_.birth_date);
    form.change("cpf", student_.cpf);
    form.change("rg", student_.rg);
    setOpenModal(false);
    setKeepGoing(true);
  };

  const createGuard = async (response: any, data_: any) => {
    if (
      response.status === HTTP_STATUS.CREATED ||
      response.status === HTTP_STATUS.OK
    ) {
      setStudentId(response.data.id);
      const boundResponse = await bondStudentToGuardian({
        guardianId,
        studentId: response.data.id,
        guard_type: 2,
      });
      if (boundResponse) {
        if (boundResponse.status === HTTP_STATUS.CREATED) {
          toastSuccess("Cadastro realizado com sucesso");
          setStep(2);
        } else {
          if (
            boundResponse.data.non_field_errors &&
            boundResponse.data.non_field_errors.includes(
              "The fields student, guardian must make a unique set."
            )
          ) {
            toastError("Este estudante já é vinculado a este responsável");
          } else {
            toastError("Ocorreu um erro ao vincular o estudante ao pai");
          }
          setLoading(false);
        }
      }
    } else if (response.status === HTTP_STATUS.BAD_REQUEST) {
      if (response.data.cpf) {
        toastError(
          "Esse CPF já está cadastrado. Por favor, verifique os dados novamente"
        );
      } else {
        toastError(
          "Erro ao tentar efetuar cadastro, tente novamente mais tarde"
        );
      }
      setLoading(false);
    } else {
      toastError(
        "Ocorreu um erro ao efetuar o cadastro, verifique as informações fornecidas e tente novamente"
      );
      setLoading(false);
    }
  };

  const createStudent = async (data: any) => {
    let data_ = deepCopy(data);
    if (data_.cpf) {
      data_.cpf = onlyNumbersFromString(data_.cpf);
    } else {
      delete data_.cpf;
    }

    if (data_.rg) data_.rg = onlyNumbersFromString(data_.rg);
    if (data_.financeiro) {
      data_.perfil = 2;
    }
    const response = await registerNewStudentPreSignUp({
      name: data_.name,
      rg: data_.rg,
      birth_date: data_.birth_date,
      cpf: data_.cpf,
      previous_school: data_.previous_school,
      gender_type: data_.gender_type,
    });
    createGuard(response, data_);
  };

  const updateStudent = async (values: any) => {
    const values_ = deepCopy(values);
    await updateUser({
      id: student?.id,
      role: "student",
      body: formatUpdateStudentPayload(values_),
    }).then((response) => {
      if (response) {
        if (response.status === HTTP_STATUS.OK) {
          createGuard(response, values);
        } else {
          toastError(
            "Erro ao atualizar seus dados. Tente novamente mais tarde."
          );
          setLoading(false);
        }
      }
    });
  };

  const onSubmit = async (data: StudentSignUpInterface) => {
    const dateAux = new Date(data.birth_date);
    data.birth_date =
      dateAux.getFullYear() +
      "-" +
      (dateAux.getMonth() + 1) +
      "-" +
      dateAux.getDate();

    if (!keepGoing) {
      await lookupStudents({ params: data }).then((response) => {
        if (response) {
          if (
            response.status === HTTP_STATUS.OK &&
            response.data.students.length > 0
          ) {
            setStudents(response.data.students);
            setOpenModal(true);
          } else {
            setLoading(true);
            createStudent(data);
          }
        }
      });
    } else {
      setLoading(true);
      if (student) {
        updateStudent(data);
      } else {
        createStudent(data);
      }
    }
  };

  return (
    <div className="container">
      <TextStrong> Dados do(a) Aluno(a) </TextStrong>
      <p className="mb-0">
        Precisamos de alguns dados do(a) aluno(a) para iniciar o cadastro
      </p>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, form, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-6 col-12">
                <Field
                  component={TextInput}
                  name="name"
                  type="text"
                  placeholder=" "
                  label="Nome Completo *"
                  maxLength="100"
                  validate={composeValidators(
                    required,
                    noSpecialCharactersAndNumbers
                  )}
                  required
                />
              </div>
              <div className="col-sm-6 col-12">
                <Field
                  component={CalendarComponent}
                  name="birth_date"
                  placeholder="Data de nascimento *"
                  validate={composeValidators(
                    required,
                    hasToBe6UntilJuneNextYear
                  )}
                />
              </div>
              <div className="col-sm-6 col-12">
                <Field
                  component={TextInput}
                  name="cpf"
                  type="text"
                  placeholder=" "
                  label="CPF"
                  parse={formatString("999.999.999-99")}
                  validate={composeValidators(CPFstudent)}
                />
              </div>
              <div className="col-sm-6 col-12">
                <Field
                  component={TextInput}
                  name="rg"
                  type="text"
                  placeholder=" "
                  label="RG"
                  maxLength="15"
                />
              </div>
              <div className="col-sm-6 col-12">
                <Field
                  component={TextInput}
                  name="previous_school"
                  type="text"
                  placeholder=" "
                  label="Escola Anterior *"
                  maxLength="100"
                  validate={required}
                  required
                />
              </div>
            </div>
            <div className="row align-itens-center justify-content-center">
              <TextStrong className="col-sm-12 col-12 m-4">
                Gênero do Aluno: *
              </TextStrong>
              <div className="col-sm-1 col-2">
                <Field
                  component={RadioButton}
                  name="gender_type"
                  type="radio"
                  value="1"
                  required
                />
              </div>
              <div className="col-sm-11 col-10 pl-0 my-auto">
                <strong>Masculino</strong>
              </div>
              <div className="col-sm-1 col-2">
                <Field
                  component={RadioButton}
                  name="gender_type"
                  type="radio"
                  value="2"
                  required
                />
              </div>
              <div className="col-sm-11 col-10 pl-0 my-auto">
                <strong>Feminino</strong>
              </div>
              {/**
               * Pseudo Feature Flag abaixo
               */}
              {false && (
                <>
                  <div className="col-sm-1 col-2">
                    <Field
                      component={RadioButton}
                      name="gender_type"
                      type="radio"
                      value="3"
                      required
                    />
                  </div>
                  <div className="col-sm-11 col-10 pl-0 my-auto">
                    <strong>Não informado.</strong>
                  </div>
                </>
              )}
            </div>
            {false && (
              <div className="row align-itens-center justify-content-center">
                <TextStrong className="col-sm-12 col-12 m-4">
                  {isVendor(role) ||
                  isEnrollmentCoordinator(role) ||
                  isVendorManager(role)
                    ? name
                    : "Você"}{" "}
                  é um responsável: *
                </TextStrong>
                <div className="col-sm-1 col-2">
                  <Field
                    component={RadioButton}
                    name="guard_type"
                    type="radio"
                    value="2"
                    required
                  />
                </div>
                <div className="col-sm-11 col-10 pl-0 my-auto">
                  <strong>Financeiro e Pedagógico</strong>
                </div>
                <div className="col-sm-1 col-2">
                  <Field
                    component={RadioButton}
                    name="guard_type"
                    type="radio"
                    value="1"
                    required
                  />
                </div>
                <div className="col-sm-11 col-10 pl-0 my-auto">
                  <strong>Pedagógico</strong>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-sm-3 offset-sm-9 col-12">
                <Button disabled={loading || submitting} type="submit">
                  {!submitting && !loading && "Próximo"}
                  {(submitting || loading) && (
                    <img height="25" src={loadingGif} alt="loading" />
                  )}
                </Button>
              </div>
            </div>
            {students && (
              <Modal setToggleModal={setOpenModal} isOpen={openModal}>
                <ModalSimilarStudents
                  setToggleModal={setOpenModal}
                  students={students}
                  setStudentData={setStudentData}
                  values={values}
                  form={form}
                  setKeepGoing={setKeepGoing}
                />
              </Modal>
            )}
          </form>
        )}
      </Form>
    </div>
  );
};

export default StudentSignUpForm;
