// Libs
import { Tag } from "@escolamais-ui-v2/react";
import { useTranslation } from "react-i18next";
// Components
import { EnrollmentHeader, Loading } from "components";
import { Drawer } from "components/organisms/Drawer";
import { Calendar } from "components/atoms/Calendar";
// Interfaces
import { IDrawer } from "./interface";
// Styles
import * as S from "./style";
// Helpers
import { OrderDrawerHelper } from "./helper";
import {
  checkPaymentStatus,
  splitPunctuation,
  splitString,
} from "helpers/helpers";

export const OrderDrawer = ({
  order,
  drawer,
  setDrawer,
  setModal,
  setOrder,
}: IDrawer) => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.consultFoodOrdes.components.orderDrawer";

  const { loading, error, orderDetails, setOrderDetails } =
    OrderDrawerHelper(order);

  const cancelOrder = (status: string) => {
    switch (status) {
      case "Cancelada":
        return true;
      case "Expirada":
        return true;
      case "Estornada":
        return true;
      case "Aguardando Estorno":
        return true;
      default:
        return false;
    }
  };

  return (
    <Drawer
      isOpen={drawer}
      onClose={() => {
        setDrawer(false);
        setOrder(null);
        setOrderDetails(null);
      }}
      title={t(`${TRANSLATE}.orderNumber`, { number: order })}
      side="right"
      width="470px"
    >
      {loading && (
        <S.LoadContainer>
          <h4>{t(`${TRANSLATE}.loading`)}</h4>
          <Loading />
        </S.LoadContainer>
      )}
      {error && !loading && (
        <S.LoadContainer>
          <div>{t(`${TRANSLATE}.error`)}</div>
        </S.LoadContainer>
      )}
      {orderDetails && !loading && (
        <S.DrawerContent>
          <div className="drawerstudentInfo">
            <EnrollmentHeader
              name={orderDetails.matricula.estudante.nome}
              educational_level_name={orderDetails.matricula.ciclo.nome}
              grade={orderDetails.matricula.segmento}
              section={orderDetails.matricula.turma}
            />
            <Tag
              appearance={checkPaymentStatus(orderDetails.status)}
              fontSize="xsm"
              className="tag"
            >
              {orderDetails.status}
            </Tag>
          </div>

          <div className="calendar">
            <Calendar
              selectedDates={orderDetails.data}
              noActions
              borderSpacing="0 15px"
            />
          </div>

          <div className="foodContainer">
            <S.H4 textStyle="primary">{t(`${TRANSLATE}.order`)}</S.H4>
            <span>
              <strong>
                {t(`${TRANSLATE}.numberOfDays`, {
                  days: orderDetails.data.length,
                })}
              </strong>{" "}
              {t(`${TRANSLATE}.selected`)}
            </span>

            {orderDetails.itens.map((item, index) => {
              return (
                <S.SameLineText key={index}>
                  <span>
                    {item.quantidade}x {item.nome} - R$
                    {splitPunctuation(item.valor_unitario).comma}
                  </span>
                  <span>R${splitPunctuation(item.valor_total).comma}</span>
                </S.SameLineText>
              );
            })}

            <hr />

            <S.SameLineText>
              <S.H4 textStyle="secondary">{t(`${TRANSLATE}.total`)}</S.H4>
              <S.H4 textStyle="secondary">
                R${splitPunctuation(orderDetails.valor_pago).comma}
              </S.H4>
            </S.SameLineText>

            <S.SameLineText>
              <span>{t(`${TRANSLATE}.paymentMethod`)}</span>
              <span>{orderDetails.forma_pagamento}</span>
            </S.SameLineText>

            <S.SameLineText textStyle="grey">
              <span>{t(`${TRANSLATE}.purchaseMade`)}</span>
              <span>
                {t(`${TRANSLATE}.purchaseDate`, {
                  date: splitString(orderDetails.criado_em).first,
                  hour: splitString(orderDetails.criado_em).last,
                })}
              </span>
            </S.SameLineText>
          </div>
          <button
            className="button"
            onClick={() => {
              setModal(true);
            }}
            disabled={orderDetails.vigencia || cancelOrder(orderDetails.status)}
          >
            {t(`${TRANSLATE}.cancellButton`)}
          </button>
        </S.DrawerContent>
      )}
    </Drawer>
  );
};
