import styled from "styled-components";
import * as colors from "styles/colors";

export const TableContainer = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.space[16]};
  margin-bottom: ${({ theme }) => theme.space[4]};
`;

export const Thead = styled.div`
  div.tr {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 14px;
    border-bottom: 1px solid ${colors.GRAY_SCALE}; // cor nao tem na lib

    display: flex;

    div.th {
      width: 18.75%;
      margin-bottom: ${({ theme }) => theme.space[2]};
      margin-right: ${({ theme }) => theme.space[4]};
    }
    div.th:first-child {
      width: 25%;
      margin-left: ${({ theme }) => theme.space[4]};
      margin-right: ${({ theme }) => theme.space[8]};
    }
  }
`;

export const Tbody = styled.div`
  div.tr {
    line-height: 40px; // nao tem na lib
    border-bottom: 2px solid ${colors.NEW_GRAY_THREE}; // nao tem na lib
    font-size: ${({ theme }) => theme.fontSizes.web.sm};

    display: flex;

    div.td {
      width: 18.75%;
      margin-right: ${({ theme }) => theme.space[4]};

      padding: 15.5px 0;

      select {
        margin-top: -5px;
        box-sizing: border-box;
        background: ${({ theme }) => theme.colors.white};
        border: 2px solid ${({ theme }) => theme.colors.greyScale40};

        height: 37px;
        width: 184px;

        padding-left: ${({ theme }) => theme.space[2]};
        font-size: ${({ theme }) => theme.fontSizes.web.sm};

        cursor: pointer;
      }

      div {
        cursor: pointer;
      }
    }
    div.td:first-child {
      width: 25%;
      margin-left: 16px;
      margin-right: 32px;
    }
    div.td:last-child {
      display: flex;
      justify-content: flex-end;
      padding-right: ${({ theme }) => theme.space[12]};
    }
  }

  div.escort {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    margin-top: ${({ theme }) => theme.space[2]};
  }
`;

export const NoDataStyled = styled.div`
  margin-top: 75px;
  display: flex;
  justify-content: center;

  h3 {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 20px;
    line-height: 30px; // nao tem na lib
    color: ${({ theme }) => theme.colors.greyScale40};
  }
`;
