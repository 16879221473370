// React
import { useContext, useEffect, useState } from "react";
// Styles
import { FoodPackageStyled } from "./style";
// Components
import { LinearProgress } from "@material-ui/core";
import { Modal, Title, Text } from "components";
import { ModalStatusPayment } from "./components/Modal/Modal";
import { StudentFoodCard } from "./components/StudentFoodCard";
// Helpers
import { finishEnrollment, nextMonth } from "helpers/helpers";
// Interfaces
import { GetCXUserJson } from "interfaces/constants";
import {
  FoodItemAvailableRangeInterface,
  FoodPackage,
} from "interfaces/mealInterfaces";
// Libs
import { useLocation } from "react-router-dom";
import HTTP_STATUS from "http-status-codes";
import { useTranslation } from "react-i18next";
// Services
import {
  getFoodPackage,
  getFoodItemsAvailableRanges,
} from "services/meals.service";

interface LocationInterface {
  user: GetCXUserJson;
}

export const StudentsFoodPackage = () => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.foodPackage";

  const location = useLocation<LocationInterface>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [updateInfos, setUpdateInfos] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const [guardianFoodPackage, setGuardianFoodPackage] =
    useState<Array<FoodPackage>>();
  const [foodItemsAvailableRanges, setFoodItemsAvailableRanges] =
    useState<Array<FoodItemAvailableRangeInterface>>();

  useEffect(() => {
    const newDate = new Date();
    const nextMonthPackage = newDate.getMonth() + 2;
    if (location.state.user) {
      const getFoodPackageData = async () => {
        const response = await getFoodPackage({
          params: {
            guardian_id: location.state.user.id,
          },
        });

        if (response && response.data && response.status === HTTP_STATUS.OK) {
          setGuardianFoodPackage(response.data);
          setLoading(false);
        } else {
          setLoading(false);
          setError(true);
        }

        const getFoodItems = await getFoodItemsAvailableRanges({});
        if (
          getFoodItems &&
          getFoodItems.status === HTTP_STATUS.OK &&
          getFoodItems.data.length > 0
        ) {
          setFoodItemsAvailableRanges(getFoodItems.data);
          setLoading(false);
        } else {
          setLoading(false);
          setError(true);
        }
      };
      getFoodPackageData();
    } else {
      setError(true);
      setLoading(false);
    }
    setUpdateInfos(false);
  }, [location.state.user, updateInfos]);

  return (
    <>
      {loading && <LinearProgress className="mt-3 mb-3" color="secondary" />}
      {error && <div>Erro ao carregar estudantes.</div>}
      {!loading && guardianFoodPackage && foodItemsAvailableRanges && (
        <>
          <FoodPackageStyled>
            <div>
              <Title
                fontSize="20px"
                fontWeight="700"
                lineHeight="30px"
                className="mb-0"
                marginBottom="8px"
              >
                {t(`${TRANSLATE}.choiceStudent`)}
              </Title>
              <Title
                className="subtitle"
                scaleGray
                fontSize="18px"
                fontWeight="500"
                marginBottom="4px"
              >
                {t(`${TRANSLATE}.buyMonthlyPackage`)}
                <span>
                  {guardianFoodPackage[0].month_name ||
                    guardianFoodPackage[0].month_display}
                </span>
              </Title>
              <Text
                fontSize="14px"
                scaleGray
                fontWeight="400"
                lineHeight="21px"
                marginTop="-8px"
              >
                {t(`${TRANSLATE}.descriptiveText`)} <br />
                {t(`${TRANSLATE}.descriptiveTextPt2`)} <br />
              </Text>
            </div>
            <div className="cards">
              {guardianFoodPackage.map((student, index) => {
                return (
                  <div className="studentInfo" key={index}>
                    {finishEnrollment(
                      location.state.user,
                      student.enrollment_id
                    ) && (
                      <StudentFoodCard
                        student={student}
                        user={location.state.user}
                        foodItemsAvailableRanges={foodItemsAvailableRanges}
                        guardianFoodPackage={guardianFoodPackage}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </FoodPackageStyled>
        </>
      )}
      <Modal setToggleModal={setOpenModal} isOpen={openModal}>
        <ModalStatusPayment setToggleModal={setOpenModal} />
      </Modal>
    </>
  );
};
