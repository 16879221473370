import styled from "styled-components";
import * as colors from "styles/colors";

export const ContainerAlert = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5em;
`;

export const CardAlert = styled.div`
  width: 100%;
  background-color: ${colors.LIGHT_YELLOW_TWO};
  color: ${colors.GRAY_TWO};
  font-size: 14px;
  text-align: center;
  margin: 1em;
  padding: 1em 0.5em;

  strong {
    margin-bottom: 8px;
    font-size: 16px;
  }

  @media (max-width: 998px) {
    font-size: 12.5px;
    padding: 2px;
    margin: 1em;
  }
`;
