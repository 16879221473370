import * as Styles from "./style";
import { useTranslation } from "react-i18next";
import { BorderlessButton, CircleProgressBar, Text } from "components/atoms";

interface EnrollmentStepInterface {
  studentName: string | undefined;
  nextStep?: string;
  step?: number;
  totalSteps?: number;
  currentStepName?: string;
  width?: string;
  subtitle: string;
  detail?: boolean;
}

export const EnrollmentStepCircle = ({
  studentName,
  nextStep,
  step,
  totalSteps,
  currentStepName,
  width,
  subtitle,
  detail,
}: EnrollmentStepInterface) => {
  const { t } = useTranslation();
  const TRANSLATE = "components.molecules.enrollmentStepCircle";

  return (
    <Styles.Container width={width}>
      <div className="title">
        <Text gray>{studentName}</Text>
        <Text gray textUpperCase fontWeight="400px">
          {t(`${TRANSLATE}.subtitle`, { subtitle })}
        </Text>
      </div>
      <div className="flex">
        <div className="stage">
          {step && totalSteps && (
            <div className="circleProgress">
              <CircleProgressBar currentStep={step} stepsNumbers={totalSteps} />
            </div>
          )}
          {totalSteps && step && (
            <Styles.TextStepStyled>
              <div className="stageText">{t(`${TRANSLATE}.step`)}</div>
              <div className="stageNumber">
                <div className="giantText">{step}</div>
                <div className="numberOfSteps">/{totalSteps}</div>
              </div>
            </Styles.TextStepStyled>
          )}
        </div>
        <div className="currentStep">
          <Text fontSize="14px" purple>
            {currentStepName}
          </Text>
          {nextStep && (
            <Styles.TextStepStyled className="nextStep">
              {nextStep && t(`${TRANSLATE}.nextStep`, { nextStep })}
            </Styles.TextStepStyled>
          )}
          {detail && (
            <BorderlessButton fontWeight="400" fontSize="12px">
              {t(`${TRANSLATE}.details`)}
            </BorderlessButton>
          )}
        </div>
      </div>
    </Styles.Container>
  );
};
