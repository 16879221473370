import styled from "styled-components";

export const Step1Div = styled.div`
  h1 {
    font-weight: bold;
    font-size: 20px;
    color: #333333;
  }
`;

export const HyperLinkModal = styled.button`
  background: none;
  border: none;
  color: #4285f4;
  text-decoration: none;
  font-weight: 500;
  padding: 0;
  display: contents;
`;
