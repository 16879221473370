// React
import { useState } from "react";
// Styles
import * as S from "./style";
// Libs
import { Modal, RadioGroupV2 } from "@escolamais-ui-v2/react";
import { useTranslation } from "react-i18next";
// Components
import { StudentHeaderSecondary } from "components";
import { AddGuardian } from "./components/AddGuardian";
import { GuardianExitCard } from "./components/GuardianExitCard";
import { Button, Loading } from "components/atoms";
// Interfaces
import { IExitPermitScreen, IForm, ITransportationForm } from "./interface";
// Helpers
import { HExitPermit } from "./helpers";
import { formatCPF, formatPhone } from "helpers/helpers";

export enum ExitPermitModalTypes {
  register = 1,
  edit = 2,
}

export const ExitPermitScreen = ({ student }: IExitPermitScreen) => {
  const { t } = useTranslation();
  const prefix = "pages.EDF.exitPermit.components.exitPermitScreen";

  const [modal, setModal] = useState(false);
  const [modalOption, setModalOption] = useState<number>();

  const [form, setForm] = useState<IForm>({
    name: "",
    kinship: "",
    cpf: "",
    phone: "",
    escort: 0,
  });

  const {
    error,
    loading,
    newSearch,
    exitPermission,
    transportation,
    setStudentExitPermit,
    handleRegisterEscort,
    handleEditEscort,
    handleDeletEscort,
    handleSaveChanges,
    setFormProps,
    isSendingData,
  } = HExitPermit({
    student,
    form,
    setModal,
    setForm,
  });

  return (
    <div>
      {loading && (
        <S.LoadContainer>
          <h4>{t(`${prefix}.loading`)}</h4>
          <Loading />
        </S.LoadContainer>
      )}
      {error && !loading && (
        <S.LoadContainer>
          <div>{t(`${prefix}.error`)}</div>
        </S.LoadContainer>
      )}
      {exitPermission && transportation && !loading && (
        <S.ExitPermitScreenStyled isSendingData={isSendingData}>
          <StudentHeaderSecondary student={student} />
          <div className="body">
            <h4>{t(`${prefix}.exitPerm`)}</h4>
            <div className="form">
              <div>
                <RadioGroupV2
                  appearance="square"
                  defaultCheckedValue={exitPermission.transportation.value}
                  id="exitPermit"
                  labelColorChecked="#FFF"
                  labelColorUnchecked="#6D28D9"
                  name="exitPermit"
                  onChangeOption={(e: ITransportationForm) => {
                    setStudentExitPermit(e);
                  }}
                  options={transportation.results}
                  radioCircleColorChecked="#FFF"
                  radioCircleColorUnchecked="#6D28D9"
                  radioCirclePositionLeft={8.8}
                  radioCirclePositionTop={11}
                />
              </div>
            </div>

            {exitPermission.transportation.value === 1 && (
              <>
                <p className="guardianDescription">
                  {t(`${prefix}.guardiansDescription`)}
                </p>

                {newSearch ? (
                  <S.LoadContainer>
                    <h4>{t(`${prefix}.loadingEscorts`)}</h4>
                    <Loading />
                  </S.LoadContainer>
                ) : (
                  <>
                    <div className="guardians">
                      {exitPermission?.responsaveis.map((guardian, index) => {
                        return (
                          <GuardianExitCard
                            guardian={guardian}
                            key={index}
                            form={form}
                            remove={handleDeletEscort}
                            setModal={setModal}
                            setForm={setForm}
                            setModalOption={setModalOption}
                          />
                        );
                      })}

                      {exitPermission?.responsaveis_saida.map(
                        (escort, index) => {
                          return (
                            <GuardianExitCard
                              escort={escort}
                              key={index}
                              form={form}
                              remove={handleDeletEscort}
                              setModal={setModal}
                              setForm={setForm}
                              setModalOption={setModalOption}
                            />
                          );
                        }
                      )}

                      <AddGuardian
                        handleClick={setModal}
                        variable={modal}
                        handleOption={setModalOption}
                      />
                      <Modal
                        handleOnClickCancel={() => {
                          setFormProps();
                          setModal((modal) => !modal);
                        }}
                        handleOnClickConfirm={() => {
                          modalOption === ExitPermitModalTypes.register
                            ? handleRegisterEscort()
                            : handleEditEscort();
                        }}
                        size="medium"
                        title={t(`${prefix}.modalTitle`)}
                        open={modal}
                        buttonCancelText={t(`${prefix}.modalCancel`)}
                        buttonConfirmText={t(`${prefix}.modalSave`)}
                      >
                        <S.ModalStyled>
                          <div className="firstLine">
                            <div>
                              <label>{t(`${prefix}.name`)}</label>
                              <input
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    name: e.target.value,
                                  });
                                }}
                                type="text"
                                id="name"
                                placeholder={t(
                                  `${prefix}.inputPlaceHolderName`
                                )}
                                name="name"
                                value={form.name}
                              />
                            </div>
                            <div>
                              <label>{t(`${prefix}.kinship`)}</label>
                              <input
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    kinship: e.target.value,
                                  });
                                }}
                                type="text"
                                id="kinship"
                                placeholder={t(
                                  `${prefix}.inputPlaceHolderKinship`
                                )}
                                name="kinship"
                                value={form.kinship}
                              />
                            </div>
                          </div>
                          <div>
                            <div>
                              <label>{t(`${prefix}.cpf`)}</label>
                              <input
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    cpf: formatCPF(e.target.value),
                                  });
                                }}
                                type="text"
                                id="cpf"
                                placeholder="000.000.000-00"
                                name="cpf"
                                value={
                                  form.escort > 0
                                    ? formatCPF(form.cpf)
                                    : form.cpf
                                }
                                maxLength={14}
                              />
                            </div>
                            <div>
                              <label>{t(`${prefix}.phone`)}</label>
                              <input
                                onChange={(e) => {
                                  setForm({
                                    ...form,
                                    phone: formatPhone(e.target.value),
                                  });
                                }}
                                type="text"
                                id="phone"
                                placeholder="(00) 00000-0000"
                                name="phone"
                                value={
                                  form.escort > 0
                                    ? formatPhone(form.phone)
                                    : form.phone
                                }
                                maxLength={15}
                              />
                            </div>
                          </div>
                          <p>{t(`${prefix}.modalText`)}</p>
                        </S.ModalStyled>
                      </Modal>
                    </div>
                  </>
                )}
              </>
            )}

            <div className="button">
              <Button
                type="button"
                onClick={() => {
                  handleSaveChanges();
                }}
              >
                {t(`${prefix}.button`)}
              </Button>
            </div>
          </div>
        </S.ExitPermitScreenStyled>
      )}
    </div>
  );
};
