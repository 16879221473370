// React
import { FormEvent, useContext, useEffect, useState } from "react";
// Libs
import HTTP_STATUS from "http-status-codes";
import { useTranslation } from "react-i18next";
// Services
import {
  getWorkingDays,
  getUnits,
  workingDaysRegister,
} from "services/units.service";
import { getYear } from "services/multipurpose";
// Interfaces
import { BusinessDayInterface, UnitInterface } from "interfaces/unit";
import { EnrollmentYearsInterface } from "interfaces/constants";
import { ITargetInterface, IWorkingDaysHelpers } from "./interface";
// Components
import { toastError, toastSuccess } from "components/Toast";

export const WorkingDaysHelpers = ({ select }: IWorkingDaysHelpers) => {
  const { t } = useTranslation();
  const prefix = "pages.EDF.workingDaysRegister.workingDaysHelpers";
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [units, setUnits] = useState<Array<UnitInterface>>();
  const [years, setYears] = useState<EnrollmentYearsInterface>();

  const [workingDays, setWorkingDays] =
    useState<Array<BusinessDayInterface> | null>();

  const reloading = select.unit && select.year && !workingDays ? true : false;

  //useEffect para chamadas iniciais
  useEffect(() => {
    (async () => {
      try {
        const units = await getUnits();
        if (units && units.status === HTTP_STATUS.OK) {
          setUnits(units.data);
        }

        const years = await getYear({});
        if (years && years.status === HTTP_STATUS.OK) {
          setYears(years.data);
          setLoading((loading) => !loading);
        }
      } catch (error) {
        setLoading((loading) => !loading);
        setError((error) => !error);
        toastError(t(`${prefix}.firstUseEffectError`));
      }
    })();
  }, [t]);

  if (workingDays) {
    workingDays.sort((firstMonth, lastMonth) => {
      return firstMonth.month - lastMonth.month;
    });
  }

  //useEffect que tras lista de unidade e ano
  useEffect(() => {
    if (select.unit && select.year && !workingDays) {
      (async () => {
        const response = await getWorkingDays({
          unit: parseInt(select.unit),
          year: parseInt(select.year),
        });
        if (response && response.status === HTTP_STATUS.OK) {
          setWorkingDays(response.data);
        } else {
          toastError(t(`${prefix}.secondUseEffectError`));
        }
      })();
    }
  }, [select, workingDays, t]);

  const handleSubmit = async (
    event: FormEvent<HTMLFormElement> & {
      target: ITargetInterface;
    }
  ) => {
    const { target } = event;
    event.preventDefault();

    if (target) {
      const response = await workingDaysRegister({
        days: parseInt(target.days.value),
        month: parseInt(target.month.value),
        year: parseInt(target.year.value),
        unit: parseInt(target.unit.value),
      });

      if (response && response.status === HTTP_STATUS.OK) {
        toastSuccess(t(`${prefix}.updateData`));
        setWorkingDays(null);
      } else if (response && response.status === HTTP_STATUS.CREATED) {
        toastSuccess(t(`${prefix}.createData`));
        setWorkingDays(null);
      } else {
        toastError(t(`${prefix}.postError`));
      }
    }
  };

  return {
    loading,
    error,
    units,
    years,
    workingDays,
    reloading,
    handleSubmit,
    setWorkingDays,
  };
};
