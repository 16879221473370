import { useEffect, useState } from "react";
import HTTP_STATUS from "http-status-codes";
import { formatCEP, formatCPF, formatDate, formatPhone } from "helpers/helpers";
import {
  GetCXUserJson,
  GuardianInterface,
  YearsInterface,
} from "interfaces/constants";
import { getStudentData } from "services/users.service";
import { EditGuardianStyled } from "pages/EDF/EditGuardian/style";
import { LinearProgress } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { GuardianBox } from "./GuardianBox";
import { Button } from "components";
import {
  CONTRACT_STATUS,
  isEnrollmentCoordinator,
  isSecretary,
  isSystemAdmin,
  isEnrollmentManager,
  isCX,
  isVendor,
  isVendorManager,
  isDirector,
  isOperationalDirector,
  isStudyRoomEducator,
} from "helpers/constants";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Storage } from "Storage";
import * as S from "./styles";
import { getYear } from "services/multipurpose";
// import { ReserveVacancyButton } from "./components/ReserveVacancyButton";
// import { useSetAtom } from "jotai";
// import { selectedStudentReserveVacancyAtom } from "jotai/selectedStudentReserveVacancy/atom";

type GetCXUserJsonType = {
  data: GetCXUserJson;
  status: number;
};

export const StudentSummary = () => {
  const [student, setStudent] = useState<GetCXUserJson | undefined>(undefined);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [collapseStudent, setCollapseStudent] = useState(true);
  const [collapseGuardians, setCollapseGuardians] = useState(true);
  const [showAddressStudent, setShowAddressStudent] = useState(false);
  const [signedContract, setSignedContract] = useState(false);
  const [user, setUser] = useState<GetCXUserJson | null>();
  const [studentAddress, setStudentAddress] = useState<GuardianInterface>();
  const role = Storage.props.role.get();
  const [enrollmentYears, setEnrollmentYears] = useState<[YearsInterface]>();

  // const setSelectedStudentReserveVacancy = useSetAtom(
  //   selectedStudentReserveVacancyAtom
  // );

  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const studentId = urlParams.get("studentId");

  const paymentStatus = [
    "Aguardando pagamento da Reserva",
    "Aguardando Compensação do Pagamento",
  ];

  useEffect(() => {
    if (studentId) {
      getStudentData({ id: parseInt(studentId) })
        .then(({ data, status }: GetCXUserJsonType) => {
          if (status === HTTP_STATUS.OK) {
            setStudent(data);
          } else {
            setError(true);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [studentId]);

  useEffect(() => {
    if (student) {
      student.guardians.find(
        (g) =>
          g.id === student.location.same_address_as_guardian &&
          setStudentAddress(g)
      );
    }
  }, [student]);

  useEffect(() => {
    if (student) {
      if (
        student.last_enrollment?.contract_status ===
        CONTRACT_STATUS.CONTRATO_ASSINADO
      ) {
        setSignedContract(true);
      }
    }
  }, [student]);

  useEffect(() => {
    const hasStoredUser = Storage.props.authUser.get();
    if (hasStoredUser) {
      setUser(hasStoredUser);
    }
  }, []);

  useEffect(() => {
    if (studentId) {
      (async () => {
        const getYears = await getYear({
          more_years: 1,
          studentId: parseInt(studentId),
        }); // Quantidade de anos adicionais e estudante ID

        if (getYears && getYears.status === HTTP_STATUS.OK) {
          setEnrollmentYears(getYears.data.results);
        } else {
          setLoading(false);
          setError(true);
        }
      })();
    }
  }, [studentId]);

  // const handleReserveVacancyForStudent = (student: GetCXUserJson) => {
  //   setSelectedStudentReserveVacancy(student);
  //   // TODO - handle student vacancy reservation
  // };

  return (
    <EditGuardianStyled className="container">
      {loading && <LinearProgress className="mt-3 mb-3" color="secondary" />}
      {error && <div>Erro ao carregar estudante.</div>}
      {!loading && student && (
        <>
          <div
            onClick={() => setCollapseStudent(!collapseStudent)}
            className={`collapse-box ${collapseStudent ? "collapsed " : ""}`}
          >
            {`Dados de ${student.personal.name}`}
            <FontAwesomeIcon
              className="ml-2"
              icon={
                collapseStudent
                  ? (faChevronUp as IconProp)
                  : (faChevronDown as IconProp)
              }
            />
          </div>
          {collapseStudent && (
            <div className="collapse-content">
              <div className="row">
                <div className="col-6">
                  <p>
                    <span>Nome: </span>
                    {student.personal?.name}
                  </p>
                </div>

                <div className="col-6">
                  <p>
                    <span>RA: </span>
                    {student?.ra ?? "Não possui RA"}
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <p>
                    <span>Unidade: </span>
                    {student.enrollment
                      ? student.enrollment[0].unit_name
                      : "Não possui unidade"}
                  </p>
                </div>

                <div className="col-6">
                  <p>
                    <span>Ciclo:</span>{" "}
                    {student.enrollment
                      ? student.enrollment[0].educational_level_name
                      : "Não possui ciclo"}
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <p>
                    <span>Ano: </span>
                    {student.enrollment ? student.enrollment[0].grade : "-"}
                  </p>
                </div>

                <div className="col-6">
                  <p>
                    <span>Turma: </span>{" "}
                    {student.enrollment
                      ? student.enrollment[0].section
                      : "Não possui turma"}
                  </p>
                </div>
              </div>

              <div className="row ">
                <div className="col-6 flex">
                  <p className="d-inline">
                    <span>RG:</span> {student.personal?.rg || "Não possui RG"}
                  </p>

                  <p className="d-inline ml-3">
                    <span>CPF: </span>
                    {student.personal?.cpf
                      ? formatCPF(student.personal?.cpf)
                      : "Não possui CPF"}
                  </p>
                </div>

                <div className="col-6">
                  <p>
                    <span>E-mail: </span>
                    {student.contact?.email || "Não possui e-mail"}
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <p>
                    <span>Telefone: </span>
                    {student.contact?.phone
                      ? formatPhone(student.contact?.phone)
                      : "Não possui telefone"}
                  </p>
                </div>

                <div className="col-6">
                  <p>
                    <span>Data de nascimento: </span>
                    {student.personal?.birth_date
                      ? formatDate(student.personal?.birth_date)
                      : "Não possui data de nascimento"}
                  </p>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <p
                    onClick={() => setShowAddressStudent(!showAddressStudent)}
                    className="link"
                  >
                    Ver endereço
                  </p>
                </div>

                <div className="col-6 flex">
                  {!signedContract &&
                    !isDirector(role) &&
                    !isOperationalDirector(role) &&
                    !isStudyRoomEducator(role) && (
                      <div className="flex">
                        <p
                          onClick={() =>
                            history.push(
                              `/responsavel/meus-alunos/aluno?studentId=${student.id}`
                            )
                          }
                          className="link"
                        >
                          Editar
                        </p>
                      </div>
                    )}

                  {isSystemAdmin(role) &&
                    paymentStatus.includes(
                      student.last_enrollment.current_status
                    ) && (
                      <div className="mb-3 flex">
                        <S.ConfirmPayment>
                          <button
                            onClick={() =>
                              history.push({
                                pathname: `/regularizar-pagamento`,
                                state: { student },
                              })
                            }
                          >
                            Confirmar Pagamento
                          </button>
                        </S.ConfirmPayment>
                      </div>
                    )}
                </div>
              </div>

              {(isCX(role) ||
                isEnrollmentCoordinator(role) ||
                isSecretary(role) ||
                isEnrollmentManager(role)) && (
                <div className="col-sm-3 offset-sm-9 col-12 mt-3">
                  <Button
                    type="button"
                    onClick={() =>
                      history.push({
                        pathname: `/usuario/envio-de-documentos`,
                        state: { student },
                      })
                    }
                  >
                    Upload documentos
                  </Button>
                </div>
              )}
              {showAddressStudent && (
                <>
                  <p>
                    <span>CEP: </span>
                    {studentAddress?.zip_code
                      ? formatCEP(studentAddress.zip_code)
                      : "Não possui CEP"}
                  </p>
                  <p>
                    <span>Endereço: </span>
                    {studentAddress?.address}, nº {studentAddress?.number}
                  </p>
                  <p>
                    <span>Bairro: </span>
                    {studentAddress?.neighborhood || "Não possui bairro"}
                  </p>
                  <div className="d-flex">
                    <p>
                      <span>Cidade: </span>
                      {studentAddress?.city || "Não possui cidade"}
                    </p>
                    <p className="ml-3">
                      <span>UF: </span>
                      {studentAddress?.uf || "Não possui UF"}
                    </p>
                  </div>
                  <p>
                    <span>Complemento: </span>
                    {studentAddress?.complement || "Não possui complemento"}
                  </p>
                </>
              )}
            </div>
          )}
          <div
            onClick={() => setCollapseGuardians(!collapseGuardians)}
            className={`collapse-box mt-3 ${
              collapseGuardians ? "collapsed " : ""
            }`}
          >
            {`Responsáveis de ${student.personal.name}`}
            <FontAwesomeIcon
              className="ml-2"
              icon={
                collapseGuardians
                  ? (faChevronUp as IconProp)
                  : (faChevronDown as IconProp)
              }
            />
          </div>
          {collapseGuardians && (
            <div className="collapse-content">
              {student.guardians.map((guardian, index) => {
                return (
                  <div className="mb-3" key={index}>
                    <GuardianBox guardian={guardian} student={student} />
                  </div>
                );
              })}
            </div>
          )}
          <div className="row">
            <div className="col-12">
              {(isVendor(role) ||
                isVendorManager(role) ||
                isSecretary(role) ||
                isEnrollmentCoordinator(role) ||
                isSystemAdmin(role)) && (
                <Button
                  onClick={() =>
                    history.push(
                      `/responsavel/busca-por-cpf?studentId=${student.id}`
                    )
                  }
                  type="button"
                >
                  Cadastrar novo responsável
                </Button>
              )}
            </div>

            {(isVendor(role) || isVendorManager(role)) &&
              enrollmentYears &&
              enrollmentYears.length > 0 && (
                <div className="col-12">
                  <Button
                    onClick={() =>
                      history.push(
                        `/responsavel/cadastro-aluno?guardianId=${student.guardians[0].id}&studentId=${student.id}&name=${student.guardians[0].name}&guardianAuthId=${student.guardians[0].user_id}&step=2&reserva=true`
                      )
                    }
                    type="button"
                    className="m-0"
                  >
                    Reservar Vaga
                  </Button>
                  {/* <ReserveVacancyButton
                    onReserveVacancy={() =>
                      handleReserveVacancyForStudent(student)
                    }
                  /> */}
                </div>
              )}
          </div>
        </>
      )}
    </EditGuardianStyled>
  );
};
