import { validateCPF, validatePhone } from "validations-br";
import { ENROLLMENT_YEAR, YESTERDAY } from "./constants";
import {
  EIGHTEEN_YEARS_AGO,
  MARCH31_NEXT_YEAR,
  onlyNumberMask,
} from "./helpers";

/**
 * To-Do
 * tipar isso tudo
 */

export const required = (value: any) =>
  value !== undefined ? undefined : "Campo obrigatório";

export const emailValidation = (value: any) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Email inválido"
    : undefined;

export const composeValidators =
  (...validators: any) =>
  (value: any) =>
    validators.reduce(
      (error: any, validator: any) => error || validator(value),
      undefined
    );

export const CPFvalidation = (value: any) => {
  const cpfValido =
    value && (value.length === 14 || value.length === 11)
      ? validateCPF(value)
      : false;
  return cpfValido || value === "999.999.999-99" ? undefined : "CPF inválido";
};

export const CPFstudent = (value: any) => {
  const cpfValido = value ? validateCPF(value) : true;
  return cpfValido || value === "999.999.999-99" ? undefined : "CPF inválido";
};

export const CPFGuardianRegister = (value: any) => {
  const cpfValido = value ? validateCPF(value) : false;
  return cpfValido ? undefined : "CPF inválido";
};

export const CEPValidation = (value: any) => {
  const cepValido = value && value.length === 9 ? true : false;
  return cepValido ? undefined : "CEP inválido";
};

export const greaterThanZeroLessThan31 = (value: any) => {
  return value && value > 0 && value < 32
    ? undefined
    : "Valor precisa ser entre 1 e 31";
};

export const finalGreaterThanInitial = (initial: any) => (value: any) => {
  return value && initial < value
    ? undefined
    : "Dia final deve ser maior que o dia inicial";
};

export const UFValidation = (value: any) => {
  const ufValido = value && value.length === 2 ? true : false;
  return ufValido ? undefined : "UF inválido";
};

export const atLeast18YearsOld = (value: any) => {
  return value && new Date(value) > new Date(EIGHTEEN_YEARS_AGO())
    ? `Você precisa ter ao menos 18 anos`
    : undefined;
};

export const greaterOrEqualToday = (value: any) => {
  return value && new Date(value) < YESTERDAY // yesterday
    ? `A data não pode ser no passado`
    : undefined;
};

export const hasToBe6UntilJuneNextYear = (value: any) => {
  return value && new Date(value) > MARCH31_NEXT_YEAR()
    ? `O Aluno precisa fazer 6 anos até o dia 31 de Março de ${ENROLLMENT_YEAR}`
    : undefined;
};

export const samePassword = (password: any) => (value: any) => {
  return value && value === password
    ? undefined
    : "Senha e confirmar senha devem ser iguais";
};

export const phoneValidation = (value: any) => {
  let validPhone = validatePhone(value);
  return validPhone ? undefined : "Telefone inválido";
};

export const noSpecialCharactersAndNumbers = (value: any) =>
  value && !/^[A-zÀ-ú ']+$/i.test(value)
    ? `Não permite caracteres especiais e números`
    : undefined;

export const numberValidation = (value: number) =>
  value && !onlyNumberMask(value) ? `Insira somente números` : undefined;

export const businessDaysValidate = (value: any) => {
  return value && value > 25
    ? `A quantidade de dias tem que ser menor que 25`
    : undefined;
};

export const noTextInput = (value: string) => {
  return value && /^[a-z()]+$/i.test(value) ? `Somente números` : undefined;
};

export const validateCreditCardIugu = (value: any) => {
  try {
    const newWindow = window as any;
    const Iugu = newWindow.Iugu;
    return Iugu.utils.validateCreditCardNumber(value)
      ? undefined
      : "Cartão de Crédito Inválido";
  } catch (err) {
    return "Cartão de Crédito Inválido";
  }
};

export const validateCVVIugu = (card: string) => (value: string) => {
  try {
    const newWindow = window as any;
    const Iugu = newWindow.Iugu;
    const cardBrand = Iugu.utils.getBrandByCreditCardNumber(card);
    return Iugu.utils.validateCVV(value, cardBrand)
      ? undefined
      : "O número do CVV não corresponde à bandeira do cartão";
  } catch (err) {
    return "CVV Inválido";
  }
};

export const validateExpirationIugu = (value: string) => {
  try {
    const newWindow = window as any;
    const Iugu = newWindow.Iugu;
    return Iugu.utils.validateExpirationString(value)
      ? undefined
      : "Data de expiração Inválida";
  } catch (err) {
    return "Data de expiração Inválida";
  }
};
