import { Fragment } from "react";
import { StudentCard } from "../StudentCard";
import { IStudentList } from "./interface";
import * as S from "./styles";

export const StudentsList = ({ students }: IStudentList) => {
  return (
    <S.Container>
      {students.map(
        (student) =>
          student.enrollment && (
            <Fragment key={student.enrollment.id}>
              <StudentCard studentInfo={student} />
              <S.Separador />
            </Fragment>
          )
      )}
    </S.Container>
  );
};
