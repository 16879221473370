import styled from "styled-components";
import * as colors from "styles/colors";

import { ISectionHeaderStyles } from "./interface";

export const Container = styled.div<ISectionHeaderStyles>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .content {
    display: flex;
    align-items: center;
    gap: 8px;

    > span {
      color: ${({ sectionState }) =>
        sectionState === "Concluído" || sectionState === "Em Andamento"
          ? colors.NEW_GRAY_TWO
          : colors.NEW_GRAY_ONE};
      line-height: 21px;
    }
  }

  svg {
    color: ${colors.NEW_GREEN};
  }
`;
