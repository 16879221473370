import { Button, TextInput, Modal } from "components";
import { CalendarComponent } from "components/Calendar";
import { toastError, toastSuccess } from "components/Toast";
import {
  default as formatString,
  default as formatStringByPattern,
} from "format-string-by-pattern";
import {
  atLeast18YearsOld,
  CEPValidation,
  composeValidators,
  CPFvalidation,
  emailValidation,
  noSpecialCharactersAndNumbers,
  phoneValidation,
  required,
  UFValidation,
} from "helpers/fieldValidators";
import HTTP_STATUS from "http-status-codes";
import { GetCXUserJson } from "interfaces/constants";
import { useContext, useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { getAddressByCEP } from "services/cep.service";
import { updateUser, UserFormDataInterface } from "services/users.service";
import loadingGif from "static/img/loading.gif";
import {
  formatUpdateGuardianPayload,
  formatUserFormInitialData,
  maskBuilder,
  setUserAfterUpdate,
} from "./helpers";
import { UserFormDiv } from "./style";
import { ModalTotvsSent } from "components/Modals";
import { isGuardian, isEnrollmentManager } from "helpers/constants";
import { Storage } from "Storage";

type UserFormType = {
  user?: GetCXUserJson;
  setEdit: (edit: boolean) => void;
  setUser: (user: GetCXUserJson | undefined) => void;
};

export const UserForm = ({ user, setEdit, setUser }: UserFormType) => {
  const [newDate, setDate] = useState<string>();
  const [totvsSent, setTotvsSent] = useState(false);
  const role = Storage.props.role.get();

  const [openModalTotvsSent, setOpenModalTotvsSent] = useState(false);
  const [guardianBirthDate, setGuardianBirthDate] = useState<string>();

  useEffect(() => {
    if (user && !isEnrollmentManager(role)) {
      user.students.find((e) => e.last_enrollment?.totvs_sent) &&
        setTotvsSent(true);

      user.personal.birth_date &&
        setGuardianBirthDate(
          user.personal.birth_date.split("-").reverse().join("/")
        );
    }
  }, [user, role]);

  const onSubmit = async (values: UserFormDataInterface) => {
    if (newDate) {
      const TheDate = new Date(values.birth_date);
      values.birth_date = `${TheDate.getFullYear()}-${
        TheDate.getMonth() + 1
      }-${TheDate.getDate()}`;
    }
    await updateUser({
      id: user?.id,
      role: "guardian",
      body: formatUpdateGuardianPayload(values),
    }).then((response) => {
      if (response) {
        if (response.status === HTTP_STATUS.OK) {
          toastSuccess("Dados atualizados com sucesso");
          setUser(setUserAfterUpdate(user, response.data));
          setEdit(false);
        } else {
          toastError(
            "Erro ao atualizar seus dados. Tente novamente mais tarde."
          );
        }
      }
    });
  };

  const totvsSentIsTrue = () => {
    setOpenModalTotvsSent(true);
  };

  return (
    <UserFormDiv>
      {totvsSent && (
        <div>
          {isGuardian(role) ? (
            <p>
              Para alteração de dados entre em contato com a equipe da Escola
              Mais pelo número (11) 5555-3725
            </p>
          ) : (
            <p>
              Essa matrícula já foi enviada para Totvs. Necessário abrir um
              chamado para alteração de dados cadastrais
            </p>
          )}
        </div>
      )}
      <Form initialValues={formatUserFormInitialData(user)} onSubmit={onSubmit}>
        {({ form, handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-sm-6 col-12">
                <p className="font-weight-bold mt-3">Dados pessoais</p>
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <Field
                      component={TextInput}
                      name="name"
                      type="text"
                      placeholder=" "
                      label="Nome completo"
                      maxLength="100"
                      validate={composeValidators(
                        required,
                        noSpecialCharactersAndNumbers
                      )}
                      required
                      disabled={totvsSent}
                    />
                  </div>
                  <div className="col-sm-6 col-12">
                    <Field
                      component={TextInput}
                      name="email"
                      type="email"
                      placeholder=" "
                      label="E-mail"
                      maxLength="255"
                      validate={composeValidators(required, emailValidation)}
                      required
                      disabled={totvsSent}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <Field
                      component={TextInput}
                      name="phone"
                      type="text"
                      parse={formatString(maskBuilder(values.phone))}
                      placeholder=" "
                      label="Celular"
                      validate={composeValidators(required, phoneValidation)}
                      required
                      disabled={totvsSent}
                    />
                  </div>
                  <div className="col-sm-6 col-12">
                    <Field
                      component={TextInput}
                      name="cpf"
                      type="text"
                      placeholder=" "
                      label="CPF"
                      parse={formatStringByPattern("999.999.999-99")}
                      validate={composeValidators(required, CPFvalidation)}
                      minLength="14"
                      required
                      disabled={totvsSent}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <Field
                      component={TextInput}
                      name="rg"
                      type="text"
                      placeholder=" "
                      label="RG"
                      maxLength="15"
                      disabled={totvsSent}
                    />
                  </div>
                  <div className="col-sm-6 col-12">
                    <>
                      <Field
                        component={CalendarComponent}
                        name="birth_date"
                        placeholder="Data de nascimento"
                        //label="Data de nascimento"
                        validate={composeValidators(
                          required,
                          atLeast18YearsOld
                        )}
                        required
                        inputLabel="Data de nascimento"
                        valueCalendar={guardianBirthDate}
                        disabled={totvsSent}
                      />
                      <OnChange name="birth_date">
                        {async (value) => {
                          if (value) {
                            setDate(value);
                          }
                        }}
                      </OnChange>
                    </>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-12">
                <p className="font-weight-bold mt-3">Localização</p>
                <div className="row">
                  <div className="col-sm-3 col-12">
                    <Field
                      component={TextInput}
                      name="zip_code"
                      type="text"
                      placeholder=" "
                      label="CEP"
                      parse={formatStringByPattern("99999-999")}
                      validate={composeValidators(required, CEPValidation)}
                      disabled={totvsSent}
                    />
                    <OnChange name="zip_code">
                      {async (value, previous) => {
                        if (value.length === 9) {
                          const response = await getAddressByCEP(value);
                          if (response) {
                            if (response.status === HTTP_STATUS.OK) {
                              if (response.data.resultado === "0") {
                                toastError("CEP não encontrado");
                              } else {
                                form.change(
                                  "address",
                                  response.data.tipo_logradouro +
                                    " " +
                                    response.data.logradouro
                                );
                                form.change(
                                  "neighborhood",
                                  response.data.bairro
                                );
                                form.change("city", response.data.cidade);
                                form.change("uf", response.data.uf);
                              }
                            }
                          }
                        }
                      }}
                    </OnChange>
                  </div>
                  <div className="col-sm-9 col-12">
                    <Field
                      component={TextInput}
                      label="Endereço"
                      name="address"
                      placeholder="Endereço"
                      type="text"
                      maxLength="100"
                      disabled={totvsSent}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-2 col-12">
                    <Field
                      component={TextInput}
                      name="number"
                      placeholder=" "
                      label="Número"
                      maxLength="8"
                      disabled={totvsSent}
                    />
                  </div>
                  <div className="col-sm-3 col-12">
                    <Field
                      component={TextInput}
                      label="Complemento"
                      name="complement"
                      placeholder=" "
                      type="text"
                      maxLength="60"
                      disabled={totvsSent}
                    />
                  </div>
                  <div className="col-sm-7 col-12">
                    <Field
                      component={TextInput}
                      name="neighborhood"
                      placeholder=" "
                      type="text"
                      label="Bairro"
                      maxLength="80"
                      disabled={totvsSent}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-10 col-12">
                    <Field
                      component={TextInput}
                      name="city"
                      placeholder=" "
                      type="text"
                      label="Cidade"
                      maxLength="32"
                      disabled={totvsSent}
                    />
                  </div>
                  <div className="col-sm-2 col-12">
                    <Field
                      component={TextInput}
                      name="uf"
                      placeholder=" "
                      label="UF"
                      maxLength="2"
                      validate={composeValidators(required, UFValidation)}
                      disabled={totvsSent}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="offset-sm-6 col-sm-3 col-6">
                <Button
                  className="blue outline text-uppercase col-12"
                  type="button"
                  onClick={() => setEdit(false)}
                >
                  Cancelar
                </Button>
              </div>
              <div className="col-sm-3 col-6">
                {!totvsSent && (
                  <Button
                    disabled={submitting}
                    className="blue text-uppercase col-12"
                    type="submit"
                  >
                    {!submitting && "Salvar"}
                    {submitting && (
                      <img height="25" src={loadingGif} alt="loading" />
                    )}
                  </Button>
                )}
                {totvsSent && (
                  <Button
                    className="disabled text-uppercase col-12"
                    type="button"
                    onClick={() => totvsSentIsTrue()}
                  >
                    Salvar
                  </Button>
                )}
              </div>
            </div>
          </form>
        )}
      </Form>
      <Modal setToggleModal={setOpenModalTotvsSent} isOpen={openModalTotvsSent}>
        <ModalTotvsSent setToggleModal={setOpenModalTotvsSent} />
      </Modal>
    </UserFormDiv>
  );
};
