import * as S from "./styles";
import { ItinerarieGradeIcon } from "components/atoms";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { mdiInformationOutline } from "@mdi/js";
import { IItinerarieComponent } from "types/study-itinerary";

interface IItinerarieCardErrorProps {
  itinerary: IItinerarieComponent;
}
export const ItinerarieCardError = ({
  itinerary,
}: IItinerarieCardErrorProps) => {
  const { t } = useTranslation();
  const TRANSLATION_PREFIX = "components.organisms.itinerarieCardError";

  return (
    <S.Container>
      <ItinerarieGradeIcon course={itinerary.course_name} />
      <div className="itinerarie-info">
        <strong>{itinerary.course_name}</strong>
        <div className="itinerarie-error">
          <Icon path={mdiInformationOutline} size={1} />
          <p>{t(`${TRANSLATION_PREFIX}.message`)}</p>
        </div>
      </div>
    </S.Container>
  );
};
