import styled from "styled-components";
import * as colors from "styles/colors";

export const Container = styled.div`
  display: flex;
  flex: 1;
  border: 2px solid ${colors.NEW_PURPLE};
  height: fit-content;

  padding: 1em;

  .orderStatus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: ${({ theme }) => theme.space[5]};
  }

  & > button {
    margin-bottom: auto;
    margin-left: auto;
    max-width: 120px;
    height: 37px;
  }
`;
