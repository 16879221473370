import {
  isCX,
  isDirector,
  isEnrollmentCoordinator,
  isEnrollmentManager,
  isFirstAccess,
  isGuardian,
  isOperationalDirector,
  isStudyRoomEducator,
  isTeacher,
  isVendor,
  isVendorManager,
  isSecretary,
  isGradesManager,
  isStudent,
  isSystemAdmin,
} from "helpers/constants";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { CXHome } from "./components/CX";
import { DirectorHome } from "./components/Director";
import { EnrollmentCoordinatorHome } from "./components/EnrollmentCoordinator";
import { EMHome } from "./components/EnrollmentManager";
import { GuardianHome } from "./components/Guardian";
import { GuardianFirstLogin } from "../GuardianFirstLogin";
import { OperationalDiretorHome } from "./components/OperationalDirector";
import { SecretaryHome } from "./components/Secretary";
import { StudyRoomEducatorHome } from "./components/StudyRoomEducator";
import { TeacherHome } from "./components/Teacher";
import { VendorHome } from "./components/Vendor";
import { VendorManagerHome } from "./components/VendorManager";
import { StudentHome } from "./components/Student";
import { ButtonCard } from "components/atoms/ButtonCard";
import { SystemAdminHome } from "./components/SystemAdmin";
import { GradesManagerHome } from "./components/GradesManager";
import { Storage } from "Storage";
import { RoleInterface } from "interfaces/constants";
import { useAtomValue } from "jotai";
import { authUserAtom } from "jotai/authUser";

export const Home = () => {
  const location = useLocation();
  // @ts-ignore
  const passwordChanged = location?.state?.passwordChanged || false;
  const [changePasswordLater, setChangePasswordLater] =
    useState(passwordChanged);
  const authUser = useAtomValue(authUserAtom);
  const role = Storage.props.role.get();
  const history = useHistory();

  const handleSelectRole = (role: RoleInterface) => {
    Storage.props.role.set(role);
    history.go(0);
  };

  return (
    <div>
      {role.id !== 0 && (
        <div>
          {isGuardian(role) &&
            (!isFirstAccess(authUser, role) || changePasswordLater) && (
              <GuardianHome />
            )}
          {isGuardian(role) &&
            isFirstAccess(authUser, role) &&
            !changePasswordLater && (
              <GuardianFirstLogin
                setChangePasswordLater={setChangePasswordLater}
              />
            )}
          {isVendor(role) && <VendorHome />}
          {isVendorManager(role) && <VendorManagerHome />}
          {isEnrollmentCoordinator(role) && <EnrollmentCoordinatorHome />}
          {isTeacher(role) && <TeacherHome />}
          {isCX(role) && <CXHome />}
          {isDirector(role) && <DirectorHome />}
          {isGradesManager(role) && <GradesManagerHome />}
          {isStudyRoomEducator(role) && <StudyRoomEducatorHome />}
          {isEnrollmentManager(role) && <EMHome />}
          {isOperationalDirector(role) && <OperationalDiretorHome />}
          {isSecretary(role) && <SecretaryHome />}
          {isStudent(role) && <StudentHome />}
          {isSystemAdmin(role) && <SystemAdminHome />}
        </div>
      )}
      {role.id === 0 && (
        <div className="container">
          <div className="mt-5 font-weight-bold text-center">
            ESCOLHA SEU PERFIL DE ACESSO
          </div>
          <div className="d-flex flex-wrap">
            {authUser.role.map((role_, index) => {
              return (
                <ButtonCard
                  className="m-3"
                  onClick={() => handleSelectRole(role_)}
                  text={role_.label_pt}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};
