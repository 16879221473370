import { IconType } from "types/constants";

export const IconDocumentation = ({ isActive }: IconType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.666"
      height="16"
      viewBox="0 0 14.666 16"
      fill={isActive ? "#00aeef" : "#5e5e5e"}
    >
      <g transform="translate(-1)">
        <path
          className="a"
          d="M3,4.5A3.17,3.17,0,0,1,6.167,1.333h6.089A1.83,1.83,0,0,0,10.5,0H2.833A1.835,1.835,0,0,0,1,1.833V12.166A1.835,1.835,0,0,0,2.833,14H3Z"
        />
        <path
          className="a"
          d="M15.5,4H7.833A1.835,1.835,0,0,0,6,5.833V15.5a1.835,1.835,0,0,0,1.833,1.833H15.5A1.835,1.835,0,0,0,17.333,15.5V5.833A1.835,1.835,0,0,0,15.5,4ZM14.167,15.333h-5a.5.5,0,0,1,0-1h5a.5.5,0,0,1,0,1Zm0-2.667h-5a.5.5,0,0,1,0-1h5a.5.5,0,0,1,0,1Zm0-2.333h-5a.5.5,0,0,1,0-1h5a.5.5,0,1,1,0,1Zm0-2.667h-5a.5.5,0,0,1,0-1h5a.5.5,0,1,1,0,1Z"
          transform="translate(-1.667 -1.333)"
        />
      </g>
    </svg>
  );
};

export default IconDocumentation;
