// React
import React, { useContext, useEffect, useState } from "react";
// Styles
import { ConsultSinglePurchaseStyled } from "./style";
// Components
import {
  SelectInputFinalForm,
  TableBulletin,
  TableColumn,
  TableRow,
  TextInputFinalForm,
  Title,
  Text,
  LoadingBox,
} from "components";
import { toastError } from "components/Toast";
import { Pagination } from "components/atoms/Pagination";
// Services
import { getUnitsGradesSchoolPlaces } from "services/units.service";
import { getConsultFood, getMealTypes } from "services/meals.service";
// Interfaces
import { GetUnitsGradesResponse } from "interfaces/unit";
import { ConsultFoodPagination, IMealTypes } from "interfaces/mealInterfaces";
import { IInitialData } from "interfaces/utils";
// Libs
import { Field, Form } from "react-final-form";
import HTTP_STATUS from "http-status-codes";
import { OnChange } from "react-final-form-listeners";
import { LinearProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import moment from "moment";
// Helpers
import { paginationLogic } from "helpers/helpers";

interface FormInterface {
  student_name: string;
  date: string;
  unit: string;
  type: string;
}

export const ConsultFood = () => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.consultFood";

  // Initial
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [units, setUnits] = useState<Array<GetUnitsGradesResponse>>();

  // Options
  const [foodOptions, setFoodOptions] = useState<IMealTypes>();

  // Loading table infos
  const [loadingTable, setLoadingTable] = useState(false);
  const [loadingNewTable, setLoadingNewTable] = useState(false);

  // formulario salvo
  const [formData, setFormData] = useState<FormInterface>();

  // Pagination
  const [page, setPage] = useState<number>(1);
  const [data, setData] = useState<ConsultFoodPagination>();
  const [paginate, setPaginate] = useState<IInitialData>({
    initialValue: 1,
    lastValue: 0,
  });

  useEffect(() => {
    const getInitialData = async () => {
      const unitsGrade = await getUnitsGradesSchoolPlaces();
      if (unitsGrade && unitsGrade.status === HTTP_STATUS.OK) {
        setUnits(unitsGrade.data);
      } else {
        setError(true);
        setLoading(false);
      }
      const mealOptions = await getMealTypes();
      if (mealOptions && mealOptions.status === HTTP_STATUS.OK) {
        setFoodOptions(mealOptions.data);
        setLoading(false);
      } else {
        setError(true);
        setLoading(false);
      }
    };
    getInitialData();
  }, []);

  const Offset = async (direction: string) => {
    if (data && formData) {
      setLoadingNewTable(true);
      const response = await getConsultFood({
        page: direction === "foward" ? page + 1 : page - 1,
        name: formData.student_name,
        date: moment(formData.date).format("DD/MM/YYYY"),
        type: formData.type,
        unit_id: formData.unit,
      });
      if (response && response.status === HTTP_STATUS.OK) {
        paginationLogic({
          direction: direction === "foward" ? true : false,
          page,
          paginate,
          data: response.data,
          oldData: data,
          setPage,
          setPaginate,
        });

        setData(response.data);
        setLoadingNewTable(false);
      } else {
        toastError(t(`${TRANSLATE}.toastError`));
        setLoadingNewTable(false);
      }
    }
  };

  const onSubmit = async (values: FormInterface) => {
    setFormData(values);
    setLoadingTable(true);

    const consultFood = await getConsultFood({
      name: values.student_name,
      date: moment(values.date).format("DD/MM/YYYY"),
      // unit_id: formData?.unit ? formData?.unit : values.unit,
      unit_id: values.unit,
      type: values.type,
      page: 1,
    });

    if (consultFood && consultFood.status === HTTP_STATUS.OK) {
      setData(consultFood.data);
      setLoadingTable(false);
      setPaginate({
        initialValue: 1,
        lastValue: consultFood.data.results.length,
      });
      setPage(1);
    } else {
      toastError(t(`${TRANSLATE}.toastError`));
      setLoadingTable(false);
    }
  };

  return (
    <div>
      {loading && (
        <div className="pl-4 pr-4">
          <LinearProgress className="mt-3 mb-3" color="secondary" />
        </div>
      )}
      {error && !loading && <Text>{t(`${TRANSLATE}.errorText`)}</Text>}
      {units && foodOptions && !error && !loading && (
        <ConsultSinglePurchaseStyled>
          <Title fontSize="32px" fontWeight="700" lineHeight="48px">
            {t(`${TRANSLATE}.consultFoods`)}
          </Title>
          <Title orange fontSize="24px" lineHeight="36px" fontWeight="700">
            {t(`${TRANSLATE}.searchStudents`)}
          </Title>
          <Form
            initialValues={{
              date: moment().format("YYYY-MM-DD"),
            }}
            onSubmit={onSubmit}
          >
            {({ form, handleSubmit, submitting, values }) => (
              <form onSubmit={handleSubmit}>
                <div className="inputContainer">
                  <div className="inputBeyond">
                    <Field
                      component={TextInputFinalForm}
                      name="date"
                      type="date"
                      placeholder="dd/mm/aaaa"
                      parse={(value) => moment(value).format("YYYY-MM-DD")}
                      label={t(`${TRANSLATE}.date`)}
                      maxLength={10}
                      required
                      fullBorder
                      width="146px"
                      height="37px"
                      fontSize="14px"
                    />
                    <OnChange name="date">
                      {async (value, previous) => {
                        if (
                          value &&
                          values.unit &&
                          values.type &&
                          value.length === 10
                        ) {
                          onSubmit(values);
                        }
                      }}
                    </OnChange>
                  </div>
                  <div className="inputBeyond">
                    <Field
                      component={SelectInputFinalForm}
                      name="unit"
                      label={t(`${TRANSLATE}.unit`)}
                      required
                      fullBorder
                      width="146px"
                      height="37px"
                      gayScale={!values.unit}
                    >
                      <option value="">{t(`${TRANSLATE}.select`)}</option>
                      {units.map((unit) => {
                        return (
                          <option value={unit.unit.id} key={unit.unit.id}>
                            {unit.unit.name}
                          </option>
                        );
                      })}
                    </Field>
                    <OnChange name="unit">
                      {async (value, previous) => {
                        if (value && values.date) {
                          onSubmit(values);
                        }
                      }}
                    </OnChange>
                  </div>

                  <div className="inputBeyond">
                    <Field
                      component={SelectInputFinalForm}
                      name="type"
                      label={t(`${TRANSLATE}.type`)}
                      required
                      fullBorder
                      width="146px"
                      height="37px"
                      gayScale={!values.type}
                      defaultValue="all_food"
                    >
                      {foodOptions.results.map((foodOption, index) => {
                        return (
                          <React.Fragment key={index}>
                            <option value={foodOption.name}>
                              {foodOption.label}
                            </option>
                          </React.Fragment>
                        );
                      })}
                    </Field>
                    <OnChange name="type">
                      {async (value, previous) => {
                        if (value && values.date && values.unit) {
                          onSubmit(values);
                        }
                      }}
                    </OnChange>
                  </div>

                  <div className="inputBeyond">
                    <Field
                      component={TextInputFinalForm}
                      name="student_name"
                      type="text"
                      placeholder="Nome completo do estudante"
                      label={t(`${TRANSLATE}.studentName`)}
                      fullBorder
                      width="150%"
                      height="37px"
                      fontSize="14px"
                    />
                    <OnChange name="student_name">
                      {async (value, previous) => {
                        if (
                          value &&
                          values.date &&
                          values.unit &&
                          values.type
                        ) {
                          onSubmit(values);
                        } else if (
                          !value &&
                          values.date &&
                          values.unit &&
                          values.type
                        ) {
                          onSubmit(values);
                        }
                      }}
                    </OnChange>
                  </div>

                  {/* <Debounce
                    query={values.student_name}
                    onSearch={() => {
                      onSubmit(values);
                    }}
                  >
                    <Field
                      component={TextInputFinalForm}
                      name="student_name"
                      type="text"
                      placeholder="Nome completo do estudante"
                      label={t(`${TRANSLATE}.studentName`)}
                      fullBorder
                      width="150%"
                      height="37px"
                      fontSize="14px"
                    />
                  </Debounce> */}
                </div>
              </form>
            )}
          </Form>
          {loadingTable && (
            <div className="mt-3">
              <LoadingBox height={50} />
            </div>
          )}
          {data && !loadingTable && (
            <div className="tableContainer">
              <TableBulletin
                theadChildren={
                  <>
                    <TableRow
                      className="firstChield"
                      item={t(`${TRANSLATE}.tableStudent`)}
                      visible={{ mobile: true, desktop: true }}
                    />
                    <TableRow
                      item={t(`${TRANSLATE}.tableGrade`)}
                      visible={{ mobile: true, desktop: true }}
                    />
                    <TableRow
                      item={t(`${TRANSLATE}.tableLunch1`)}
                      visible={{ mobile: true, desktop: true }}
                    />
                    <TableRow
                      item={t(`${TRANSLATE}.tableLunch2`)}
                      visible={{ mobile: true, desktop: true }}
                    />
                    <TableRow
                      item={t(`${TRANSLATE}.tableLunch`)}
                      visible={{ mobile: true, desktop: true }}
                    />
                    <TableRow
                      item={t(`${TRANSLATE}.tableExtraLunch`)}
                      visible={{ mobile: true, desktop: true }}
                    />
                  </>
                }
                tbodyChildren={
                  <>
                    {!loadingNewTable &&
                      data.results.map((studentInfo, index) => {
                        return (
                          <tr className="bordeBottom" key={index}>
                            <TableColumn
                              className="firstChield"
                              item={studentInfo.name}
                              visible={{ mobile: false, desktop: true }}
                            />
                            <TableColumn
                              item={
                                studentInfo.gradegroup.includes("None")
                                  ? t(`${TRANSLATE}.noSection`)
                                  : studentInfo.gradegroup
                              }
                              visible={{ mobile: false, desktop: true }}
                            />
                            <TableColumn
                              check={`${studentInfo.food_info.Lanche1}`}
                              visible={{ mobile: false, desktop: true }}
                            />
                            <TableColumn
                              check={`${studentInfo.food_info.Lanche2}`}
                              visible={{ mobile: false, desktop: true }}
                            />
                            <TableColumn
                              check={`${studentInfo.food_info.Almoço}`}
                              visible={{ mobile: false, desktop: true }}
                            />
                            <TableColumn
                              check={`${studentInfo.food_info.LancheExtra}`}
                              visible={{ mobile: false, desktop: true }}
                            />
                          </tr>
                        );
                      })}
                  </>
                }
              />

              {loadingNewTable && (
                <div className="mt-3">
                  <LoadingBox height={50} />
                </div>
              )}

              {data.results.length === 0 && (
                <div className="noStudentsFound">
                  <Title
                    scaleGrayTwo
                    fontSize="20px"
                    lineHeight="30px"
                    fontWeight="700"
                  >
                    {t(`${TRANSLATE}.noStudentsFound`)}
                  </Title>
                </div>
              )}
            </div>
          )}
          {!loadingNewTable && !loadingTable && data && data.count > 10 ? (
            <Pagination data={data} offset={Offset} values={paginate} />
          ) : null}
        </ConsultSinglePurchaseStyled>
      )}
    </div>
  );
};
