import styled from "styled-components";
import * as colors from "styles/colors";

export const StyledButton = styled.button`
  font-size: 14px;
  font-style: normal;
  width: 100%;
  height: 50px;
  padding: 0;
  text-transform: uppercase;
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: center;
  background-color: ${colors.PINK};
  border-radius: 0.5em;
  border: none;
  color: #fff;
  justify-content: space-between;
  &:hover {
    cursor: pointer;
    background-color: ${colors.PINK};
  }
  &:disabled {
    background-color: ${colors.GRAY_DISABLED};
    color: #a8a8a8;
    border: 1px solid ${colors.GRAY_DISABLED};
    cursor: not-allowed;
  }

  &.blue {
    background-color: ${colors.BLUE};
    &.outline {
      border: 1px solid ${colors.BLUE};
      color: ${colors.BLUE};
    }
  }

  &.pink {
    background-color: ${colors.PINK};
    &.outline {
      border: 1px solid ${colors.PINK};
      color: ${colors.PINK};
    }
    &:disabled {
      background-color: ${colors.GRAY_DISABLED};
      color: #a8a8a8;
      border: 1px solid ${colors.GRAY_DISABLED};
      cursor: not-allowed;
    }
  }

  &.outline {
    background-color: white;
  }

  &.green {
    background-color: ${colors.GREEN};
  }

  &.disabled {
    background-color: ${colors.GRAY_DISABLED};
    color: #a8a8a8;
    border: 1px solid ${colors.GRAY_DISABLED};
  }

  &.sizeFoodPackage {
    width: 47%;
  }
`;
