import { useTranslation } from "react-i18next";
import * as S from "./style";
import { Button, Checkbox } from "@escolamais-ui-v2/react";
import { DoneOutline, SearchOutlined } from "@material-ui/icons";
import { Pagination } from "components/atoms/Pagination";
import { DiscountApprovalDrawer } from "./components/DiscountApprovalDrawer";

export const DiscountApproval = () => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.discountApproval";

  return (
    <S.DiscountApprovalContainer>
      <h1 className="title">{t(`${TRANSLATE}.title`)}</h1>
      <h2 className="subtitle">{t(`${TRANSLATE}.subtitle`)}</h2>
      <S.FiltersContainer>
        <S.InputContainer>
          <S.InputLabel>{t(`${TRANSLATE}.searchLabel`)}</S.InputLabel>
          <S.SearchBarInput>
            <SearchOutlined />
            <input type="text" placeholder="Pesquisar estudante" />
          </S.SearchBarInput>
        </S.InputContainer>

        <S.InputContainer>
          <S.InputLabel>{t(`${TRANSLATE}.selects.vendor`)}*</S.InputLabel>
          <S.VendorSelect name="vendor" id="vendor">
            <option>Selecione o vendedor</option>
          </S.VendorSelect>
        </S.InputContainer>
      </S.FiltersContainer>

      <S.ResultsContainer>
        <h3>{t(`${TRANSLATE}.result`)}</h3>
        <Button
          buttonText={t(`${TRANSLATE}.aproveRequests`)}
          appearance="primary"
          size="small"
          disabled
          icon={<DoneOutline />}
        />
      </S.ResultsContainer>

      <S.TableContainer>
        <table>
          <thead>
            <tr>
              <th>{t(`${TRANSLATE}.table.student`)}</th>
              <th>{t(`${TRANSLATE}.table.unit`)}</th>
              <th>{t(`${TRANSLATE}.table.yearCycle`)}</th>
              <th>{t(`${TRANSLATE}.table.vendor`)}</th>
              <th>{t(`${TRANSLATE}.table.type`)}</th>
              <th>{t(`${TRANSLATE}.table.reservation`)}</th>
              <th>{t(`${TRANSLATE}.table.monthlyFee`)}</th>
              <th>{t(`${TRANSLATE}.table.material`)}</th>
            </tr>
          </thead>
          <tbody>
            <S.StudentDiscountRow>
              <tr>
                <td>
                  <Checkbox labelText="Beatriz Oliveira Vieira" />
                </td>
                <td>Vila Sonia</td>
                <td>3ª EM</td>
                <td>Taisa Manoel</td>
                <td>Parcial</td>
                <td>80%</td>
                <td>50%</td>
                <td>10%</td>
              </tr>
            </S.StudentDiscountRow>
            <S.StudentDiscountRow>
              <tr>
                <td>
                  <Checkbox labelText="Beatriz Oliveira Vieira" />
                </td>
                <td>Vila Sonia</td>
                <td>3ª EM</td>
                <td>Taisa Manoel</td>
                <td>Parcial</td>
                <td>80%</td>
                <td>50%</td>
                <td>10%</td>
              </tr>
            </S.StudentDiscountRow>
            <S.StudentDiscountRow>
              <tr>
                <td>
                  <Checkbox labelText="Beatriz Oliveira Vieira" />
                </td>
                <td>Vila Sonia</td>
                <td>3ª EM</td>
                <td>Taisa Manoel</td>
                <td>Parcial</td>
                <td>80%</td>
                <td>50%</td>
                <td>10%</td>
              </tr>
            </S.StudentDiscountRow>
          </tbody>
        </table>
      </S.TableContainer>
      <S.PaginationContainer>
        <Pagination
          data={[]}
          offset={() => {}}
          values={{
            initialValue: 1,
            lastValue: 6,
          }}
        />
      </S.PaginationContainer>

      <DiscountApprovalDrawer visible onClose={() => {}} />
    </S.DiscountApprovalContainer>
  );
};
