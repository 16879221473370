import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Loading, StudentStatus, Title } from "components";
import { IStudentdsLocation } from "./interface";
import { getEnrollmentSteps } from "services/enrollment.service";

import * as S from "./styles";
import { EnrollmentGuardianResultsInterface } from "interfaces/enrollment";

export const Students = () => {
  const { t } = useTranslation();
  const { state } = useLocation<IStudentdsLocation>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [studentsData, setStudentsData] =
    useState<EnrollmentGuardianResultsInterface>();

  useEffect(() => {
    (async () => {
      const response = await getEnrollmentSteps({
        enrollment_id: state?.user.id,
        guardian: true,
        in_progress: true,
      });

      const data = await response?.data;

      setStudentsData(data);
      setIsLoading(false);
    })();
  }, [state]);

  if (isLoading) {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <h4 className="text-center">Carregando alunos do responsável...</h4>
        <Loading />
      </div>
    );
  }

  return (
    <S.Container>
      <Title>{t("pages.CORE.home.guardian.title")}</Title>
      {studentsData && (
        <S.Student>
          {studentsData.results.map(
            (student, index) =>
              studentsData && (
                <div key={index}>
                  <StudentStatus
                    student={student}
                    key={student.id}
                    user={state.user}
                  />
                </div>
              )
          )}
        </S.Student>
      )}
    </S.Container>
  );
};
