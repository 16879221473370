import { Button } from "@escolamais-ui-v2/react";
import { useTranslation } from "react-i18next";

import { InputDate, Title } from "components/atoms";
import { InputText } from "components/atoms/InputText";
import { NewInputFile } from "components/atoms/NewInputFile";

import { useStudentSignup } from "./useStudentSignup";

import uploadIcon from "static/img/upload.svg";

import * as S from "./styles";

export const genderEnum = ["masculino", "feminino"];

export const NewStudentSignup = () => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.studentSignup";

  const { handleSubmit, handleSubmitForm, isSubmitting, register, errors } =
    useStudentSignup();

  return (
    <S.Container>
      <Title fontSize="40px" fontWeight="600" lineHeight="60px">
        {t(`${TRANSLATE}.title`)}
      </Title>
      <h2>{t(`${TRANSLATE}.subtitle`)}</h2>

      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <NewInputFile.Root>
          <NewInputFile.Label label={t(`${TRANSLATE}.studentDocumentLabel`)} />
          <NewInputFile.Input
            id="arquivos_documento_identificacao"
            register={register}
            error={errors?.arquivos_documento_identificacao?.message}
          >
            <NewInputFile.Icon
              icon={uploadIcon}
              alt="document-identification"
            />
            <NewInputFile.Text
              text={t(`${TRANSLATE}.studentDocumentInputText`)}
            />
          </NewInputFile.Input>
          <NewInputFile.TextInformation
            text={t(`${TRANSLATE}.studentDocumentTextInformation`)}
            description={t(
              `${TRANSLATE}.studentDocumentDescriptionInformation`
            )}
          />
        </NewInputFile.Root>

        <S.DividerFormRow className="primmaryDivider">
          <InputText
            label={t(`${TRANSLATE}.nameInput`)}
            placeholder={t(`${TRANSLATE}.nameInputPlaceholder`)}
            id="nome"
            register={register}
            error={errors?.nome?.message}
            width={472}
          />

          <InputText
            label={t(`${TRANSLATE}.cpfInput`)}
            placeholder={t(`${TRANSLATE}.cpfInputPlaceholder`)}
            id="cpf"
            register={register}
            error={errors?.cpf?.message}
            width={228}
            mask="999999999-99"
          />

          <InputText
            label={t(`${TRANSLATE}.rgInput`)}
            placeholder={t(`${TRANSLATE}.rgInputPlaceholder`)}
            id="rg"
            register={register}
            error={errors?.rg?.message}
            width={228}
            mask="99.999.999-9"
          />
        </S.DividerFormRow>

        <S.DividerFormRow className="secondDivider">
          <InputDate
            label={t(`${TRANSLATE}.birthdateInput`)}
            boxWidth={228}
            id="data_nascimento"
            register={register}
            error={errors?.data_nascimento?.message}
          />

          <S.SelectGender>
            <label>{t(`${TRANSLATE}.selectGenderLabel`)}</label>
            <select {...register("genero")}>
              <option>{t(`${TRANSLATE}.selectGenderDefault`)}</option>
              {genderEnum.map((gender, index) => (
                <option key={`${index}-${gender}`} value={gender}>
                  {gender}
                </option>
              ))}
            </select>
            {errors?.genero?.message && <span>{errors.genero.message}</span>}
          </S.SelectGender>
        </S.DividerFormRow>

        <S.ButtonGroupActions>
          <Button
            type="button"
            appearance="text"
            buttonText={t(`${TRANSLATE}.returnButton`)}
            onClick={() => {}}
            size="large"
          />
          <Button
            appearance="primary"
            buttonText={t(`${TRANSLATE}.submitButton`)}
            size="large"
            disabled={isSubmitting}
          />
        </S.ButtonGroupActions>
      </form>
    </S.Container>
  );
};
