// Components
import { Title } from "components/atoms";
// Interfaces
import { ICardFoodInfo } from "./type";
// Styles
import * as S from "./styles";
// Libs
import { useTranslation } from "react-i18next";
import {
  formatMoney,
  handleDuplicateItems,
  standardDate,
  standardHour,
} from "helpers/helpers";
import { Switch } from "@escolamais-ui-v2/react";
import { ItemsSingleFoodInterface } from "interfaces/mealInterfaces";

enum EStep {
  StepFour = 4,
}

export const CardFoodInfo = ({
  paymentType,
  foodList,
  total,
  transaction,
  step,
  selectedDaysLength,
  isUsedCredit,
  setIsUsedCredit,
  creditAmount,
}: ICardFoodInfo) => {
  const { t } = useTranslation();
  const i18_prefix = "pages.EDF.singlePurchase.components.cardFoodInfo";
  const singleOrPlural = selectedDaysLength > 1;
  const transactionValue = transaction?.valor_pago ?? transaction?.total;

  const countTransactions = (foodItem: ItemsSingleFoodInterface) => {
    const countLength = transaction?.items.filter(
      (item) => item.item_name === foodItem.item_name
    ).length;

    return countLength ?? 0;
  };

  const orderTotal = transaction ? transactionValue : Number(total).toFixed(2);

  const handleCreditAmount = () => {
    if (orderTotal && creditAmount) {
      const creditRest =
        Number(orderTotal) > creditAmount
          ? 0
          : creditAmount - Number(orderTotal);

      return {
        totalRestante: Number(creditRest.toFixed(2)),
        valorUsado: ((creditRest - creditAmount) * -1).toFixed(2),
        valorPedido: (
          Number(orderTotal) -
          (creditRest - creditAmount) * -1
        ).toFixed(2),
      };
    }

    return {
      totalRestante: 0,
      valorUsado: 0,
      valorPedido: 0,
    };
  };

  return (
    <S.Container>
      <Title font-size="18px">{t(`${i18_prefix}.inThisOrder`)}</Title>
      {selectedDaysLength !== 0 && (
        <p>
          <strong>
            {selectedDaysLength}{" "}
            {singleOrPlural
              ? t(`${i18_prefix}.pluralDay`)
              : t(`${i18_prefix}.singularDay`)}
          </strong>{" "}
          {singleOrPlural
            ? t(`${i18_prefix}.pluralSelect`)
            : t(`${i18_prefix}.singularSelect`)}
        </p>
      )}

      <div className="foodInfo">
        {foodList.length > 0
          ? foodList.map(
              (foodItem, index) =>
                foodItem.qtd !== 0 && (
                  <p key={index}>
                    {foodItem.qtd}x {foodItem.name} -{" "}
                    {formatMoney(foodItem.price, true)}
                    <span>
                      {formatMoney(foodItem.qtd * foodItem.price, true)}
                    </span>
                  </p>
                )
            )
          : transaction?.items &&
            handleDuplicateItems(transaction.items).map(
              (foodItem: any, index: any) => (
                <p key={index}>
                  {countTransactions(foodItem)}x {foodItem.item_name} - R$
                  {foodItem.value_single_formatted}
                  <span>
                    {formatMoney(
                      foodItem.value_single * countTransactions(foodItem),
                      true
                    )}
                  </span>
                </p>
              )
            )}

        {isUsedCredit && (
          <p>
            Créditos
            <span>
              -R$
              {handleCreditAmount().valorUsado}
            </span>
          </p>
        )}

        <strong className="totalValue">
          {t(`${i18_prefix}.total`)}{" "}
          <span>
            {isUsedCredit
              ? handleCreditAmount().valorPedido
              : formatMoney(Number(orderTotal), true)}
          </span>
        </strong>

        <div className="creditAmount">
          {Number(creditAmount) > 0 && (
            <div className="handleCredit">
              <div>
                <Switch
                  checked={isUsedCredit}
                  onChange={() =>
                    setIsUsedCredit &&
                    setIsUsedCredit((prevState) => !prevState)
                  }
                />
                <p>Utilizar crédito:</p>
              </div>
              <p>{formatMoney(Number(creditAmount), true)}</p>
            </div>
          )}
          {isUsedCredit && (
            <div className="">
              <p>
                Crédito restante:{" "}
                <span>
                  {formatMoney(handleCreditAmount().totalRestante, true)}
                </span>
              </p>
            </div>
          )}
        </div>

        {paymentType && (
          <p className="paymentMethod">
            {t(`${i18_prefix}.paymentMethod`)}
            <span>{paymentType}</span>
          </p>
        )}
        {step === EStep.StepFour && transaction && (
          <div className="paymentDate">
            <p>{t(`${i18_prefix}.paymentDate`)}</p>
            <p>
              {t(`${i18_prefix}.paymentDay`, {
                day: standardDate(transaction.date_transaction),
                hour: standardHour(transaction.date_transaction),
              })}
            </p>
          </div>
        )}
      </div>
    </S.Container>
  );
};
