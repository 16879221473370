import { pxToRem } from "helpers/style";
import styled, { css } from "styled-components";
import { device } from "styles/breakpoints";

interface ContainerProps {
  maxWidth?: string;
  check?: string;
  className?: string;
  item?: string | any;
  type?: string;
  border?: boolean;
  fontSize?: string;
  visible: {
    mobile: boolean;
    desktop: boolean;
  };
}

export const Container = styled.td<ContainerProps>`
  width: auto;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "auto")};

  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: ${pxToRem(16)};
  font-size: 100%;
  text-align: left;
  border-right: none;
  line-height: 21px;

  @media ${device.xs} {
    display: ${(props) =>
      props.visible.mobile === false ? "none" : "table-cell"};
  }

  @media ${device.md} {
    display: ${(props) =>
      props.visible.desktop === false ? "none" : "table-cell"};
  }
`;

export const TableRow = styled.td<ContainerProps>`
  width: auto;
  max-width: 180px;

  text-align: left;

  font-size: ${(props) => (props.fontSize ? props.fontSize : "1rem")};
  border-right: none;
  border-bottom: ${(props) =>
    props.border === true ? `1px solid #c1c2c3` : "none"};

  padding: 0.5rem 0;

  ${(props) =>
    props.check === "true"
      ? css`
          font-size: 0;
          background-image: url("/icons/check.svg");
          background-size: ${pxToRem(25)} ${pxToRem(25)};
          background-repeat: no-repeat;
          background-position: left center;
          filter: brightness(0) saturate(100%) invert(52%) sepia(87%)
            saturate(357%) hue-rotate(80deg) brightness(87%) contrast(84%);
        `
      : props.check === "false"
      ? css`
          font-size: 0;
          background-image: url("/icons/remove.svg");
          background-size: ${pxToRem(25)} ${pxToRem(25)};
          background-repeat: no-repeat;
          background-position: left center;
          filter: brightness(0) saturate(100%) invert(0%) sepia(13%)
            saturate(6205%) hue-rotate(337deg) brightness(93%) contrast(90%);
        `
      : props.check === "text" &&
        css`
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: ${pxToRem(14)};
        `}

  @media ${device.xs} {
    display: ${(props) =>
      props.visible.mobile === false ? "none" : "table-cell"};
  }

  @media ${device.md} {
    max-width: 100px;
    display: ${(props) =>
      props.visible.desktop === false ? "none" : "table-cell"};
  }
`;

export const Tag = styled.span<ContainerProps>`
  ${(props) =>
    props.type === "tag" &&
    css`
      width: auto;
      height: 21px;
      background: ${props.check === "Aprovado"
        ? "#3BA550"
        : props.check === "Pendente"
        ? "#F9C339"
        : ""};
      padding: ${pxToRem(8)};
      font-size: ${pxToRem(14)};
      color: ${props.check === "Aprovado"
        ? "#fff"
        : props.check === "Pendente"
        ? "#000"
        : ""};
      border-radius: 4px;
      font-weight: 700;
    `}

  &:first-child {
    text-align: left;
  }
  @media ${device.xs} {
    display: ${(props) => (props.visible.mobile === false ? "none" : "inline")};
  }

  @media ${device.md} {
    display: ${(props) =>
      props.visible.desktop === false ? "none" : "inline"};
  }
`;

export const TableTr = styled.tr`
  width: 100%;
  height: 55px;
  border-right: none;
`;
