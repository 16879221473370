import styled from "styled-components";
import { device } from "../../../../../styles/breakpoints";

export const Container = styled.div`
  display: flex;
  justify-content: start;
  align-items: flex-start;
  width: 100%;
  margin-top: 24px;
  flex-wrap: wrap;
  & > div {
    margin-top: 1rem;
  }

  @media (max-width: 1200px) {
    flex-direction: column;
    grid-template-columns: 1fr;
    & > div {
      flex-direction: column;
      height: fit-content;
    }
  }
`;
export const Separador = styled.div`
  @media ${device.xss} {
    display: none;
  }
  @media ${device.md} {
    display: block;
    width: 25px;
  }
`;
export const Localizador = styled.div`
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  height: 18px;
  font-family: "Poppins", serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #999999;
  margin-bottom: 2rem;
  svg {
    width: 18px;
    height: 18px;
  }
  span {
    color: #6d28d9;
    font-weight: 700;
  }
  @media ${device.xss} {
    display: none;
  }
  @media ${device.xs} {
    display: none;
  }
  @media ${device.md} {
    display: flex;
  }
`;
