import { ButtonCard } from "components/atoms/ButtonCard";
import { IsDevelopmentEnvironment } from "helpers/helpers";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { OperationalDirectorHomeStyled } from "./style";

const TRANSLATE_PREFIX = "pages.CORE.home.operationalDirector";

export const OperationalDiretorHome = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <OperationalDirectorHomeStyled>
      <ButtonCard
        onClick={() => history.push("/gerenciar/matriculas")}
        text={t(`${TRANSLATE_PREFIX}.manageEnrollmentProcessQuery`)}
      />

      <ButtonCard
        onClick={() => history.push("/cadastro-dias-uteis")}
        text={t(`${TRANSLATE_PREFIX}.businessDaysRegistration`)}
      />

      <ButtonCard
        onClick={() => history.push("/cadastro-vigencia-pacote-de-alimentacao")}
        text={t(`${TRANSLATE_PREFIX}.registrationValidityFeedingDays`)}
      />

      <ButtonCard
        onClick={() => history.push("/gestao-itens-alimentacao")}
        text={t(`${TRANSLATE_PREFIX}.manageFoodItems`)}
      />

      <ButtonCard
        onClick={() => history.push("/alimentacao/consultar-alimentacao")}
        text={t(`${TRANSLATE_PREFIX}.consultSinglePurchase`)}
      />
      <ButtonCard
        onClick={() => history.push("/lista-espera")}
        text={t(`${TRANSLATE_PREFIX}.waitingList`)}
      />
      <ButtonCard
        onClick={() => history.push("/autorizacao-de-saida")}
        text={t(`${TRANSLATE_PREFIX}.exitReport`)}
      />
      <ButtonCard
        onClick={() => history.push("/consultar-pedidos-alimentacao")}
        text={t(`${TRANSLATE_PREFIX}.consultFoods`)}
      />
      {IsDevelopmentEnvironment() && (
        <ButtonCard
          onClick={() => history.push("/gerenciar-itens-alimentacao")}
          text={t(`${TRANSLATE_PREFIX}.manageDynamicFoodItems`)}
        />
      )}
      <ButtonCard
        onClick={() => history.push("/gerenciar-vagas")}
        text={t(`${TRANSLATE_PREFIX}.manageVacancies`)}
      />
      <ButtonCard
        onClick={() => history.push("/responsavel/busca")}
        text={t(`${TRANSLATE_PREFIX}.searchGuardianOrStudent`)}
      />
      <ButtonCard
        onClick={() => history.push("/cadastrar-periodo-matricula-rematricula")}
        text={t(`${TRANSLATE_PREFIX}.periodEnrollmentReenrollment`)}
      />
    </OperationalDirectorHomeStyled>
  );
};
