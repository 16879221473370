import * as S from "./style";
import * as I from "./types";

export const TableColumn = ({
  item,
  check,
  className,
  type,
  visible,
  border = true,
}: I.TableHeadProps) => {
  return (
    <S.TableRow
      visible={visible}
      className={className}
      check={check || "text"}
      item={item}
      border={border}
    >
      <S.Tag visible={visible} check={check} type={type}>
        {item}
      </S.Tag>
    </S.TableRow>
  );
};

export const TableRow = ({ item, maxWidth, visible }: I.TableHeadProps) => {
  return (
    <S.Container visible={visible} maxWidth={maxWidth}>
      {item}
    </S.Container>
  );
};

export const TableWithChildren = ({
  children,
  visible,
}: I.TableCheckboxProps) => {
  return <S.TableRow visible={visible}>{children}</S.TableRow>;
};
