import styled from "styled-components";

export const ExportDeEnviosBox = styled.div`
  h1 {
    font-size: 26px;
  }
  h2 {
    font-size: 20px;
    text-align: center;
  }
`;
