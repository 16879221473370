// React
import { useState } from "react";
// Styles
import * as S from "./style";
// Libs
import { Button } from "@escolamais-ui-v2/react";
import { useTranslation } from "react-i18next";
// Icons
import arrowDown from "static/img/arrowDown.svg";
// Components
import { InputText } from "components/atoms/InputText";
import { SelectInput } from "components/atoms/SelectInput";
import { NewTable } from "components/molecules/NewTable";
// Helpers
import { SearchStudentOrGuardianHelper } from "./helper";

enum EFilters {
  plus = "Mais",
  less = "Menos",
}

export const SearchStudentOrGuardian = () => {
  const [moreFilters, setMoreFilters] = useState(false);

  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.searchStudentOrGuardian";

  const { onSubmit, register, handleSubmit, watch, errors } =
    SearchStudentOrGuardianHelper();

  return (
    <S.SearchContainer>
      <h1>{t(`${TRANSLATE}.title`)}</h1>
      <form onSubmit={handleSubmit(onSubmit)}>
        <S.InLine>
          <InputText
            label={t(`${TRANSLATE}.labelName`)}
            placeholder={t(`${TRANSLATE}.placeHolderName`)}
            id="nome"
            register={register}
            error={errors?.nome?.message}
            width={469}
          />

          <SelectInput
            label={t(`${TRANSLATE}.labelUnidade`)}
            id="unidade"
            register={register}
            error={errors?.unidade?.message}
            watch={watch}
            width={310}
          >
            <option value="">{t(`${TRANSLATE}.selectDefault`)}</option>
          </SelectInput>
        </S.InLine>

        {moreFilters && (
          <S.InLine>
            <SelectInput
              label={t(`${TRANSLATE}.labelEducationalLevel`)}
              id="ciclo"
              register={register}
              watch={watch}
              width={227}
            >
              <option value="">{t(`${TRANSLATE}.selectAllValues`)}</option>
            </SelectInput>

            <SelectInput
              label={t(`${TRANSLATE}.labelYear`)}
              id="serie"
              register={register}
              watch={watch}
              width={229}
            >
              <option value="">{t(`${TRANSLATE}.selectAllValues`)}</option>
            </SelectInput>

            <SelectInput
              label={t(`${TRANSLATE}.labelClass`)}
              id="turma"
              register={register}
              watch={watch}
              width={227}
            >
              <option value="">{t(`${TRANSLATE}.selectAllValues`)}</option>
            </SelectInput>
          </S.InLine>
        )}

        <S.Buttons>
          <Button
            appearance="link"
            buttonText={t(`${TRANSLATE}.buttonFilters`, {
              value: moreFilters ? EFilters.less : EFilters.plus,
            })}
            type="button"
            onClick={() => {
              setMoreFilters((moreFilters) => !moreFilters);
            }}
          />
          <Button
            appearance="primary"
            buttonText={t(`${TRANSLATE}.buttonSubmit`)}
            type="submit"
            size="large"
          />
        </S.Buttons>
      </form>

      <h4>{t(`${TRANSLATE}.results`)}</h4>
      <NewTable.Root>
        <NewTable.Thead thead>
          <NewTable.Th name={t(`${TRANSLATE}.thUnit`)} />
          <NewTable.Th
            name={t(`${TRANSLATE}.thEducationalLevel`)}
            width={271}
            icon={<img height="4.38" src={arrowDown} alt="indicationLogo" />}
          />
          <NewTable.Th
            name={t(`${TRANSLATE}.thStudents`)}
            width={271}
            icon={<img height="4.38" src={arrowDown} alt="indicationLogo" />}
          />
          <NewTable.Th name={t(`${TRANSLATE}.thGuardians`)} width={271} />
        </NewTable.Thead>
        <NewTable.Tbody>
          <NewTable.Td name="Vila Sonia" />
          <NewTable.Td name="EF I - 1º Ano" width={271} />
          <NewTable.Td name="[Nome do estudante]" width={271} />
          <NewTable.Td name="[Nome do responsável]" width={271} />
        </NewTable.Tbody>
      </NewTable.Root>
    </S.SearchContainer>
  );
};
