// React
import { useState } from "react";
// Style
import * as S from "./style";
// Components
import { ButtonGroup, Icons, Loading, LoadingPage } from "components";
import { BoxDynamic } from "./components/BoxDynamic";
import { DrawerDynamic } from "./components/DynamicDrawer";
// Helpers
import { ManageFoodItemsHelper } from "./helper";
import { isOperationalDirector } from "helpers/constants";
// Interfaces
import { IForm } from "./interface";
import { IFeedingDynamics } from "interfaces/mealInterfaces";
// Libs
import { Button } from "@escolamais-ui-v2/react";
import { useTranslation } from "react-i18next";
// Img
import plusIcon from "static/img/plusPurple.svg";
// Storage
import { Storage } from "Storage";

export const ManageFoodItems = () => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.manageFoodItems";
  const role = Storage.props.role.get();

  const [form, setForm] = useState<IForm>({});
  const [drawer, setDrawer] = useState(false);
  const [dynamic, setDynamic] = useState<IFeedingDynamics | null>(null);

  const {
    loading,
    error,
    units,
    dynamicsStatus,
    dynamicsList,
    newResearch,
    items,
    selectedItem,
    setSelectedItem,
    getList,
  } = ManageFoodItemsHelper(form);

  if (loading) {
    return <LoadingPage text={t(`${TRANSLATE}.loadingText`)} />;
  }

  if (error && !loading) {
    return <S.ErrorContainer>{t(`${TRANSLATE}.error`)}</S.ErrorContainer>;
  }

  if (units && dynamicsStatus && items && !loading) {
    return (
      <S.ManageFoodItemsContainer>
        <h2>{t(`${TRANSLATE}.title`)}</h2>
        <form className="filters">
          <div>
            <S.SelectContainer>
              <label>{t(`${TRANSLATE}.selectUnit`)}</label>
              <select
                onChange={(e) => {
                  setForm({ ...form, unit: parseInt(e.target.value) });
                }}
                name="unit"
                value={form.unit}
              >
                <option value={0}>{t(`${TRANSLATE}.selectDefault`)}</option>
                {units.map((unit, index) => {
                  return (
                    <option value={unit.id} key={index}>
                      {unit.name}
                    </option>
                  );
                })}
              </select>
            </S.SelectContainer>

            <S.SelectContainer>
              <label>{t(`${TRANSLATE}.selectStatus`)}</label>
              <select
                onChange={(e) => {
                  setForm({ ...form, status: e.target.value });
                }}
                name="status"
                value={form.status}
              >
                <option value="">{t(`${TRANSLATE}.selectDefault`)}</option>
                {dynamicsStatus.results.map((status, index) => {
                  return (
                    <option value={status.value} key={index}>
                      {status.label}
                    </option>
                  );
                })}
              </select>
            </S.SelectContainer>
          </div>

          <ButtonGroup
            options={items.results}
            value={selectedItem}
            setValue={setSelectedItem}
          />
        </form>

        <div className="dynamics">
          <S.H4>
            {t(`${TRANSLATE}.dynamicsText`, {
              item: items.results.find((item) => item.value === selectedItem)
                ?.label,
            })}
          </S.H4>
          {isOperationalDirector(role) && (
            <Button
              type="button"
              appearance="text"
              buttonText={t(`${TRANSLATE}.buttonText`)}
              icon={
                <Icons icon={plusIcon} title={t(`${TRANSLATE}.buttonText`)} />
              }
              onClick={() => {
                setDrawer(true);
              }}
            />
          )}
        </div>

        {dynamicsList && !newResearch && (
          <div className="dynamicsList">
            {dynamicsList.results.length > 0 ? (
              dynamicsList.results.map((iten, index) => {
                return (
                  <BoxDynamic
                    dynamic={iten}
                    key={index}
                    setDynamic={setDynamic}
                    setDrawer={setDrawer}
                  />
                );
              })
            ) : (
              <S.H4 textStyle="notFound">{t(`${TRANSLATE}.notFound`)}</S.H4>
            )}
          </div>
        )}

        {newResearch && (
          <div className="loading">
            <Loading />
          </div>
        )}

        <DrawerDynamic
          drawer={drawer}
          setDrawer={setDrawer}
          units={units}
          dynamic={dynamic}
          setDynamic={setDynamic}
          item={selectedItem}
          searchDynamics={getList}
        />
      </S.ManageFoodItemsContainer>
    );
  }
};
