import styled from "styled-components";
import * as colors from "styles/colors";

export const Table = styled.table`
  width: 100%;
  margin-right: 1em;
  margin-top: 4em;

  thead {
    tr {
      font-weight: 700;
      font-size: 14px;
      border-bottom: 1px solid ${colors.GRAY_SCALE};
    }
  }

  tbody {
    tr {
      line-height: 40px;
      border-bottom: 2px solid ${colors.NEW_GRAY_THREE};
      td.latestTypes {
        padding-left: 1.6em;
      }
      label {
        padding-top: 0.5em;
        height: 20px;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    width: 95%;
  }
`;
