import { pxToRem } from "helpers/style";
import styled, { css } from "styled-components";
import * as colors from "styles/colors";
import { device } from "styles/breakpoints";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.default};
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1360px;
  padding: ${({ theme }) => theme.space[2]};
  margin: ${({ theme }) => theme.space[2]};
`;

export const Header = styled.header`
  width: 100%;
  height: auto;
  padding: ${({ theme }) => theme.space[2]} 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

interface SectionProps {
  display: string;
  flexDirection: string;
  justifyContent: string;
  alignItems: string;
}

export const Section = styled.section<SectionProps>`
  width: 100%;
  display: ${(props) => (props.display ? props.display : "flex")};
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : "row"};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : "flex-start"};
  align-items: ${(props) => (props.alignItems ? props.alignItems : "center")};
  padding: ${({ theme }) => theme.space[2]} 0;
`;

interface StudentPhotoStyleProps {
  src: string;
}

export const StudentPhoto = styled.div<StudentPhotoStyleProps>`
  width: ${({ theme }) => theme.space[16]};
  height: ${({ theme }) => theme.space[16]};
  border-radius: 50%;
  margin-right: ${({ theme }) => theme.space[4]};
  display: block;
  background-image: ${(props) => props.src && `url(${props.src})`};
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const ItemSection = styled.div`
  width: 100%;
`;

export const BoxTag = styled.div`
  min-width: ${({ theme }) => theme.space[20]};
  max-width: ${({ theme }) => theme.space[40]};
  height: ${({ theme }) => theme.space[7]};
`;

interface TagSubjectsStyleProps {
  status: string;
}
const handleColorStatus = (status: string) => {
  switch (status) {
    case "Conselho - tag":
      return css`
        background: ${({ theme }) => theme.colors.statusWarningDefault};
        color: ${({ theme }) => theme.colors.brandingBlack};
      `;
    case "Recuperação - tag":
      return css`
        background: ${({ theme }) => theme.colors.statusErrorDefault};
        color: ${({ theme }) => theme.colors.white};
      `;
    case "Aprovado - tag":
      return css`
        background: ${({ theme }) => theme.colors.statusSuccessDefault};
        color: ${({ theme }) => theme.colors.white};
      `;
    case "Arredondado - tag":
      return css`
        border: 2px solid ${({ theme }) => theme.colors.statusSuccessDefault};
        color: ${({ theme }) => theme.colors.statusSuccessDefault};
      `;
    case "Retido - tag":
      return css`
        border: 2px solid ${({ theme }) => theme.colors.statusErrorDefault};
        color: ${({ theme }) => theme.colors.statusErrorDefault};
      `;
    case "Aprovado pelo Conselho - tag":
      return css`
        border: 2px solid ${({ theme }) => theme.colors.statusSuccessDefault};
        color: ${({ theme }) => theme.colors.statusSuccessDefault};
      `;
    case "Não recuperado - tag":
      return css`
        background: ${({ theme }) => theme.colors.statusErrorDefault};
        color: ${({ theme }) => theme.colors.white};
      `;
    case "Recuperado - tag":
      return css`
        background: ${({ theme }) => theme.colors.statusSuccessDefault};
        color: ${({ theme }) => theme.colors.white};
      `;
    case "Em Recuperação - tag":
      return css`
        background: ${({ theme }) => theme.colors.statusErrorDefault};
        color: ${({ theme }) => theme.colors.white};
      `;
    //------------------------//
    case "Em Recuperação - media":
      return css`
        color: ${({ theme }) => theme.colors.statusErrorDefault};
      `;
    case "Aprovado - media":
      return css`
        color: ${({ theme }) => theme.colors.statusSuccessDefault};
      `;
    case "Arredondado - media":
      return css`
        color: ${({ theme }) => theme.colors.statusSuccessDefault};
      `;
    case "Não recuperado - media":
      return css`
        color: ${({ theme }) => theme.colors.statusErrorDefault};
      `;
    case "Recuperado - media":
      return css`
        color: ${({ theme }) => theme.colors.statusSuccessDefault};
      `;
    case "Conselho - media":
      return css`
        background: ${({ theme }) => theme.colors.statusWarningDefault};
        color: ${({ theme }) => theme.colors.statusWarningDefault};
      `;
    case "Recuperação - media":
      return css`
        background: ${({ theme }) => theme.colors.statusErrorDefault};
        color: ${({ theme }) => theme.colors.white};
      `;
    case "Retido - media":
      return css`
        border: 2px solid ${({ theme }) => theme.colors.statusErrorDefault};
        color: ${({ theme }) => theme.colors.statusErrorDefault};
      `;
    case "Aprovado pelo Conselho - media":
      return css`
        border: 2px solid ${({ theme }) => theme.colors.statusSuccessDefault};
        color: ${({ theme }) => theme.colors.statusSuccessDefault};
      `;
    case "Nota acima de 10":
      return css`
        border: 2px solid ${({ theme }) => theme.colors.statusErrorDefault};
        color: ${({ theme }) => theme.colors.statusErrorDefault};
      `;
  }
};

export const TagSubjects = styled.div<TagSubjectsStyleProps>`
  width: auto;
  padding: ${({ theme }) => theme.space[1]} ${({ theme }) => theme.space[2]};
  ${(props) => props.status && handleColorStatus(props.status)}
  border-radius: ${({ theme }) => theme.space[1]};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.web.default};
  text-align: center;
`;

export const Legend = styled.div`
  border: none;
  background: none;
  outline: none;
  color: ${({ theme }) => theme.colors.primaryDefault};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space[1]};
  padding: ${({ theme }) => theme.space[1]} 0px;
  margin-top: ${({ theme }) => theme.space[4]};
  cursor: pointer;
  svg {
    color: ${({ theme }) => theme.colors.primaryDefault};
  }
`;

export const SectionShare = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.space[6]};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  a {
    max-width: 306px;
    width: 100%;
  }
  button {
    svg {
      color: ${({ theme }) => theme.colors.primaryDefault};
      margin-right: ${({ theme }) => theme.space[1]};
    }
  }
`;
