import Icon from "@mdi/react";
import {
  mdiInformationOutline as infoIcon,
  mdiCheckCircleOutline as successIcon,
  mdiAlertOutline as warningIcon,
  mdiAlertCircleOutline as errorIcon,
} from "@mdi/js";

import * as S from "./styles";
import { IPostite } from "./interface";

const icons = {
  info: infoIcon,
  success: successIcon,
  warning: warningIcon,
  danger: errorIcon,
};

export const Postite = ({
  type,
  title,
  content,
  width,
  margin,
  height,
  size,
  titleSize,
  titleWeight,
  titleLineHeigth,
  contentSize,
  contentWeight,
  contentLineHeight,
}: IPostite) => {
  return (
    <S.Container
      type={type}
      width={width}
      margin={margin}
      height={height}
      titleSize={titleSize}
      titleWeight={titleWeight}
      titleLineHeigth={titleLineHeigth}
      contentSize={contentSize}
      contentWeight={contentWeight}
      contentLineHeight={contentLineHeight}
    >
      <Icon size={size ? size : 5} path={icons[type]} />
      <div className="description">
        <h5 className="m-0">{title}</h5>
        <p className="m-0">{content}</p>
      </div>
    </S.Container>
  );
};
