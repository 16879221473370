import { faUserTie } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toastError, toastSuccess } from "components/Toast";
import { formatCEP, formatCPF, formatPhone } from "helpers/helpers";
import HTTP_STATUS from "http-status-codes";
import { GetCXUserJson, GuardianInterface } from "interfaces/constants";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { updateEnrollment } from "services/enrollment.service";
import { GuardianBoxStyled } from "./style";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  isDirector,
  isOperationalDirector,
  isStudyRoomEducator,
} from "helpers/constants";
import { Storage } from "Storage";

interface GuardianBoxInterface {
  guardian: GuardianInterface;
  student: GetCXUserJson | undefined;
}

export const GuardianBox = ({ guardian, student }: GuardianBoxInterface) => {
  const [showAddress, setShowAddress] = useState(false);
  const history = useHistory();
  const role = Storage.props.role.get();

  const makeGuardianFinancial = () => {
    if (
      window.confirm(
        `Deseja tornar ${guardian.name} o responsável financeiro deste aluno?`
      )
    ) {
      updateEnrollment({
        enrollment_id: student?.last_enrollment.id,
        body: { guard_id: guardian.guard_info.guard_id.toString() },
      }).then((response) => {
        if (response) {
          if (response.status === HTTP_STATUS.OK) {
            toastSuccess("Responsável atualizado com sucesso");
          } else {
            toastError(
              "Erro ao atualizar responsável. Tente novamente mais tarde."
            );
          }
        }
      });
    }
  };

  return (
    <GuardianBoxStyled>
      {student && (
        <>
          <div className="title">
            <FontAwesomeIcon className="mr-2" icon={faUserTie as IconProp} />
            {guardian.name}
            <span
              className={`${
                student.last_enrollment &&
                guardian.guard_info.guard_id ===
                  student.last_enrollment.guard_id
                  ? "financial"
                  : "didactic"
              }`}
            >
              {student.last_enrollment &&
              guardian.guard_info.guard_id === student.last_enrollment.guard_id
                ? "Financeiro"
                : "Didático"}
            </span>
          </div>
          <>
            <div className="d-flex">
              <p>
                <span>RG: </span>
                {guardian.rg || "Não possui RG"}
              </p>
              <p className="ml-3">
                <span>CPF: </span>
                {guardian.cpf ? formatCPF(guardian.cpf) : "Não possui CPF"}
              </p>
            </div>
            <p>
              <span>E-mail: </span>
              {guardian.email}
            </p>
            <p>
              <span>Telefone: </span>
              {formatPhone(guardian.phone)}
            </p>
            <p>
              <span>Data de nascimento: </span>
              {guardian.birth_date
                ? guardian.birth_date.split("-").reverse().join("/")
                : "Sem data de nascimento"}
            </p>
            <p onClick={() => setShowAddress(!showAddress)} className="link">
              Ver endereço
            </p>
            {showAddress && (
              <>
                <p>
                  <span>CEP: </span>
                  {guardian.zip_code
                    ? formatCEP(guardian.zip_code)
                    : "Não possui CEP"}
                </p>
                <p>
                  <span>Endereço: </span>
                  {guardian.address}, nº {guardian.number}
                </p>
                <p>
                  <span>Bairro: </span>
                  {guardian.neighborhood || "Não possui bairro"}
                </p>
                <div className="d-flex">
                  <p>
                    <span>Cidade: </span>
                    {guardian.city || "Não possui cidade"}
                  </p>
                  <p className="ml-3">
                    <span>UF: </span>
                    {guardian.uf || "Não possui UF"}
                  </p>
                </div>
                <p>
                  <span>Complemento: </span>
                  {guardian.complement || "Não possui complemento"}
                </p>
              </>
            )}
          </>
          {!isDirector(role) &&
            !isStudyRoomEducator(role) &&
            !isOperationalDirector(role) && (
              <div className="d-flex mt-2 mb-2">
                <div
                  onClick={() =>
                    history.push(
                      `/responsavel/editar-cadastro?guardianId=${guardian.user_id}`
                    )
                  }
                  className="link mr-3"
                >
                  Visualizar dados
                </div>
                {student.last_enrollment &&
                  guardian.guard_info.guard_id !==
                    student.last_enrollment.guard_id && (
                    <div
                      onClick={() => makeGuardianFinancial()}
                      className="link"
                    >
                      Tornar responsável financeiro
                    </div>
                  )}
              </div>
            )}
        </>
      )}
    </GuardianBoxStyled>
  );
};
