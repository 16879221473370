// React
import { useContext, useEffect, useState } from "react";
// Styles
import * as Style from "./style";
// Components
import { EnrollmentStepCircle, Text, Title, Postite } from "components";
import { Button, Radio } from "components/atoms";
// Paymento Components
import { Pix } from "./components/Pix";
import { CreditCard } from "./components/CreditCard";
import { BankSlip } from "./components/BankSlip";
import { TwoCreditCards } from "./components/TwoCreditCards";
// Helpers
import { IsDevelopmentEnvironment } from "helpers/helpers";
// Libs
import { useHistory, useLocation } from "react-router-dom";
import HTTP_STATUS from "http-status-codes";
import { useTranslation } from "react-i18next";
// Interfaces
import { GetCXUserJson } from "interfaces/constants";
// Services
import { creditCardPaymentIugu } from "services/payment.service";

type EnrollmentPaymentType = {
  user: GetCXUserJson;
};

const PAYMENT_SELECTED = {
  PIX: 1,
  CARTAO: 2,
  BOLETO: 3,
  DOIS_CARTOES: 4,
};

export const EnrollmentPayment = () => {
  const history = useHistory();
  const location = useLocation<EnrollmentPaymentType>();

  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.enrollmentPayment";
  const TRANSLATE_PAYMENT_METHODS =
    "pages.EDF.enrollmentPayment.paymentMethods";
  const TRANSLATE_CREDIT_CARD_ERRORS =
    "pages.EDF.enrollmentPayment.creditCardErrors";

  const ENROLLMENT_PAYMENT_METHODS = [
    { name: t(`${TRANSLATE_PAYMENT_METHODS}.pix`), value: 1 },
    { name: t(`${TRANSLATE_PAYMENT_METHODS}.creditCard`), value: 2 },
    { name: t(`${TRANSLATE_PAYMENT_METHODS}.bankSlip`), value: 3 },
    { name: t(`${TRANSLATE_PAYMENT_METHODS}.twoCreditCards`), value: 4 },
  ];

  const [selectedMethod, setSelectedMethod] = useState<number>();
  const [enrollmentValue, setEnrollmentValue] = useState<number>();

  useEffect(() => {
    // Importar api que pega o valor referente a reserva de matricula e setar aqui
    setEnrollmentValue(100);
  }, []);

  // Cartão
  const [errorText, setErrorText] = useState<Array<string>>([]);
  const [approved, setApproved] = useState(false);
  const [failed, setFailed] = useState(false);
  const [loadCreditCard, setLoadCreditCard] = useState(false);
  const [cardInput, setCardInput] = useState<any>();

  const newWindow = window as any;
  const Iugu = newWindow.Iugu;

  // Para não importa 2x o mesmo codigo referente ao cartão

  useEffect(() => {
    //Seta o AccountID da IUGU
    Iugu.setAccountID("8EF5BA8442B546CC85103E3D73C1FB55");

    //Verifica o ambiente e seta o modo teste da IUGU de acordo.
    if (IsDevelopmentEnvironment()) {
      Iugu.setTestMode(true);
    } else {
      Iugu.setTestMode(false);
    }
  }, [Iugu]);

  const onSubmit = () => {
    setLoadCreditCard(true);

    const nameSplit = cardInput.name.split(" ");
    const firstName = nameSplit[0];
    const lastName = nameSplit.splice(1).join(" ");
    const month = cardInput.due_date.split("/")[0];
    const year = cardInput.due_date.split("/")[1];

    const CreditCard = Iugu.CreditCard(
      cardInput.card_number,
      month,
      year,
      firstName,
      lastName,
      cardInput.cvv
    );

    if (!CreditCard.valid()) {
      setFailed(true);
      setErrorText([
        ...errorText,
        t(`${TRANSLATE_CREDIT_CARD_ERRORS}.invalidCard`),
      ]);
      setLoadCreditCard(false);
    }

    const cardBrand = Iugu.utils.getBrandByCreditCardNumber(
      cardInput.card_number
    );

    Iugu.createPaymentToken(CreditCard, (response: any) => {
      if (response.errors) {
        Object.keys(response.errors).forEach((key) => {
          switch (key) {
            case "number":
              setErrorText([
                ...errorText,
                t(`${TRANSLATE_CREDIT_CARD_ERRORS}.number`),
              ]);
              break;
            case "verification_value":
              setErrorText([
                ...errorText,
                t(`${TRANSLATE_CREDIT_CARD_ERRORS}.verificationValue`),
              ]);
              break;
            case "expiration":
              setErrorText([
                ...errorText,
                t(`${TRANSLATE_CREDIT_CARD_ERRORS}.expiration`),
              ]);
              break;
            case "first_name":
              setErrorText([
                ...errorText,
                t(`${TRANSLATE_CREDIT_CARD_ERRORS}.firstName`),
              ]);
              break;
            case "last_name":
              setErrorText([
                ...errorText,
                t(`${TRANSLATE_CREDIT_CARD_ERRORS}.lastName`),
              ]);
              break;
            case "full_name":
              setErrorText([
                ...errorText,
                t(`${TRANSLATE_CREDIT_CARD_ERRORS}.fullName`),
              ]);
              break;
          }
        });
        setFailed(true);
        setLoadCreditCard(false);
      } else if (enrollmentValue) {
        creditCardPaymentIugu({
          charge_token: response.id,
          guardian_id: location.state.user.user_id,
          value: Math.round(enrollmentValue * 100),
          student_id: location.state.user.students[0].user_id,
          brand: cardBrand,
          installments: parseInt(cardInput.parcelas, 10),
        }).then((response) => {
          if (response) {
            if (response.status === HTTP_STATUS.OK) {
              setApproved(true);
              setLoadCreditCard(false);
            } else {
              setFailed(true);
              setLoadCreditCard(false);
              setErrorText([
                ...errorText,
                `Erro ${response.status} - ${response.data}`,
              ]);
            }
          }
        });
      } else {
        setFailed(true);
        setLoadCreditCard(false);
        setErrorText([
          ...errorText,
          t(`${TRANSLATE_CREDIT_CARD_ERRORS}.genericError`),
        ]);
      }
    });
  };

  return (
    <Style.EnrollmentPaymentStyled>
      {enrollmentValue && (
        <>
          <EnrollmentStepCircle
            studentName="[Nome do aluno]"
            nextStep="Resnponsável Financeiro"
            step={3}
            totalSteps={7}
            currentStepName="Pagar Reserva"
            subtitle="Matricula 2023"
          />
          <Postite
            content={t(`${TRANSLATE}.postite.content`)}
            title={t(`${TRANSLATE}.postite.title`)}
            type="success"
            width="20.5em"
            margin="0 0 2em 0"
            height="3.625em"
            size={1}
            titleSize="14px"
            titleLineHeigth="21px"
            titleWeight="700"
            contentSize="14px"
            contentLineHeight="21px"
            contentWeight="400"
          />
          <div className="container">
            <Title fontSize="18px" fontWeight="600" lineHeight="27px">
              {t(`${TRANSLATE}.reservationOfVacancy`)}
            </Title>
            <div className="sameLine">
              <div className="textPrimary">
                <Text fontWeight="400">
                  {t(`${TRANSLATE}.reservationValue`)}
                </Text>
              </div>
              <div className="textSecondary">
                <Text fontWeight="400">R$00.000,00</Text>
              </div>
            </div>
            <div className="sameLine">
              <div className="textPrimary">
                <Text fontWeight="400">{t(`${TRANSLATE}.discount`)}</Text>
              </div>
              <div className="textSecondary">
                <Text fontWeight="400">-R$00.000,00</Text>
              </div>
            </div>
            <div className="sameLine totalValue">
              <div className="textPrimary">
                <Title fontSize="18px" lineHeight="27px" darkGray>
                  {t(`${TRANSLATE}.totalValue`)}
                </Title>
              </div>
              <div className="textSecondary">
                <Title fontSize="18px" lineHeight="27px" darkGray>
                  R$000,00
                </Title>
              </div>
            </div>
          </div>
          <div className="paymentMethods">
            <Text fontWeight="400">
              {t(`${TRANSLATE}.selectPaymentMethod`)}:
            </Text>
            {ENROLLMENT_PAYMENT_METHODS.map((paymentMethod, index) => {
              return (
                <div key={index}>
                  <div className="radioButtons">
                    <Radio
                      value={paymentMethod.value}
                      name="Metodo de pagamento"
                      required
                      // disabled
                      // Quando for setar essa regra com os dados vindos do back
                      // lembrar que tendo essa regra o "accordion" que abre ao selecionar
                      // o radio, não pode abrir, somente pode haver o input com disabled
                      onChange={() => {
                        setSelectedMethod(paymentMethod.value);
                      }}
                    />
                    <div className="radioText">
                      <Text fontSize="14px" lineHeight="21px" fontWeight="400">
                        {paymentMethod.name}
                      </Text>
                    </div>
                  </div>
                  {paymentMethod.name === "PIX" &&
                    selectedMethod === PAYMENT_SELECTED.PIX && (
                      <Pix
                      // user={location.state.user}
                      />
                    )}
                  {paymentMethod.name === "Cartão" &&
                    selectedMethod === PAYMENT_SELECTED.CARTAO && (
                      <CreditCard
                        loadCreditCard={loadCreditCard}
                        failed={failed}
                        approved={approved}
                        errorText={errorText}
                        onSubmit={onSubmit}
                        setFailed={setFailed}
                        setCardInput={setCardInput}
                      />
                    )}
                  {paymentMethod.name === "Boleto" &&
                    selectedMethod === PAYMENT_SELECTED.BOLETO && (
                      <BankSlip
                      // user={location.state.user}
                      />
                    )}
                  {paymentMethod.name === "Dois cartões de crédito" &&
                    selectedMethod === PAYMENT_SELECTED.DOIS_CARTOES && (
                      <TwoCreditCards
                        loadCreditCard={loadCreditCard}
                        failed={failed}
                        approved={approved}
                        errorText={errorText}
                        onSubmit={onSubmit}
                        setFailed={setFailed}
                        setCardInput={setCardInput}
                      />
                    )}
                </div>
              );
            })}
          </div>
          <div className="buttons">
            <Button outline width="45%" onClick={() => history.goBack()}>
              {t(`${TRANSLATE}.buttonGoBack`)}
            </Button>
            <Button
              width="45%"
              disabled={loadCreditCard}
              onClick={() => {
                selectedMethod === PAYMENT_SELECTED.CARTAO && onSubmit();
              }}
            >
              {t(`${TRANSLATE}.buttonAdvance`)}
            </Button>
          </div>
        </>
      )}
    </Style.EnrollmentPaymentStyled>
  );
};
