import { useTranslation } from "react-i18next";
import * as S from "./styles";

interface ISectionContent {
  topics?: string[];
}

export const SectionContent = ({ topics }: ISectionContent) => {
  const { t } = useTranslation();

  return (
    <S.Container>
      {topics && (
        <ul>
          {topics.map((topic, index) => (
            <li key={index}>
              {t("components.molecules.sectionContent.topic", { topic })}
            </li>
          ))}
        </ul>
      )}
    </S.Container>
  );
};
