import { LinearProgress } from "@material-ui/core";
import { StudentStatusFrame } from "components/Frames/StudentStatusFrame";
import HTTP_STATUS from "http-status-codes";
import { GetCXUserJson } from "interfaces/constants";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getCXUser } from "services/users.service";

type StateType = {
  user: GetCXUserJson;
};

export const MyStudents = () => {
  const location = useLocation<StateType>();
  const [user] = useState(location.state.user);
  const [guardian, setGuardian] = useState<GetCXUserJson>();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (user) {
      getCXUser({
        id: user.user_id,
      })
        .then((response) => {
          if (response) {
            if (response.status === HTTP_STATUS.OK) {
              setGuardian(response.data);
            } else {
              setError(true);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [user]);

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">Alunos</div>
      </div>
      {loading && <LinearProgress className="mt-3 mb-3" color="secondary" />}
      {error && <div>Erro ao carregar responsável.</div>}
      {guardian && guardian.students.length === 0 && (
        <div>Este responsável não possui alunos.</div>
      )}
      {guardian &&
        guardian.students.map((student) => {
          return (
            <div className="mb-3" key={student.id}>
              <StudentStatusFrame student={student} guardian={guardian} />
            </div>
          );
        })}
    </div>
  );
};
