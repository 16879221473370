import styled from "styled-components";

export const SearchGuardianByCPFStyled = styled.div`
  table {
    width: 100%;
    thead {
      tr {
        border-bottom: 1px solid #333333;
        th {
          padding: 1em;
        }
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid #333333;
        td {
          padding: 1em;
        }
      }
    }
  }
`;
