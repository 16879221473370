import { EmptyStateUi } from "./styles";
import classCouncilEmpty from "../../../../../static/img/empty-states/class-council-page.svg";
import { useTranslation } from "react-i18next";

export const NoStudentsEmptyState = () => {
  const { t } = useTranslation();
  const prefix = "pages.AP.classCouncil.noStudentsEmptyState";

  return (
    <EmptyStateUi>
      <img
        src={classCouncilEmpty}
        alt="Contorno de 3 estrelas cadentes em posição vertical"
      />
      <p>
        {t(`${prefix}.title`)}
        <br />
        {t(`${prefix}.subtitle`)}
      </p>
    </EmptyStateUi>
  );
};
