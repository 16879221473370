// Styles
import * as S from "./style";
// Imgs
import addIcon from "static/img/plusPurple.svg";
// Libs
import { useTranslation } from "react-i18next";
// Interface
import { IAddGuardian } from "./interface";

export enum ExitPermitModalTypes {
  register = 1,
  edit = 2,
}

export const AddGuardian = ({ handleClick, handleOption }: IAddGuardian) => {
  const { t } = useTranslation();
  const prefix = "pages.EDF.exitReport.components.addGuardianGuardians";

  return (
    <S.AddGuardianStyled
      onClick={() => {
        handleClick(true);
        handleOption(ExitPermitModalTypes.register);
      }}
    >
      <img src={addIcon} height={14} alt="add-icon" />
      <label className="text">{t(`${prefix}.add`)}</label>
    </S.AddGuardianStyled>
  );
};
