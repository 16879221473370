import { useEffect, useState } from "react";
import HTTP_STATUS from "http-status-codes";
import { useTranslation } from "react-i18next";

import { PaginationByIndex } from "components";
import { Drawer } from "components/organisms/Drawer";
import { toastError, toastSuccess } from "components/Toast";

import { saveUnitManageVacanciesInfo } from "services/units.service";
import { IClassesAndVacanciesProps, iFormValue } from "./type";

import { Container } from "./styles";

export const ClassesAndVacancies = ({
  visible,
  onClose,
  classesData,
  setClassesData,
  selectedClassesIndex,
  setSelectedClassesIndex,
  selectedClassesData,
  syncDataAfterSubmit,
}: IClassesAndVacanciesProps) => {
  const { t } = useTranslation();
  const i18n_prefix =
    "pages.EDF.manageVacancies.components.classesAndVacanciesDrawer";

  const nullToZero = (number: number | string) =>
    number === null ? "" : number;

  const [form, setForm] = useState<iFormValue>({
    qtd_turmas: nullToZero(selectedClassesData?.qtd_turmas ?? 0),
    total_vagas: nullToZero(selectedClassesData?.total_vagas ?? 0),
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setForm({
      qtd_turmas: selectedClassesData?.qtd_turmas ?? 0,
      total_vagas: selectedClassesData?.total_vagas ?? 0,
    });
  }, [selectedClassesData]);

  const handleSubmit = async () => {
    setIsSubmitting(true);

    const res = await saveUnitManageVacanciesInfo({
      unit: selectedClassesData?.id,
      values: form,
    });

    if (res?.status !== HTTP_STATUS.OK) {
      toastError(t(`${i18n_prefix}.submittingError`));
      setIsSubmitting(false);
      return;
    }

    toastSuccess(t(`${i18n_prefix}.submittingSuccess`));
    setIsSubmitting(false);
    onClose(false);
    syncDataAfterSubmit();
  };

  return (
    <Drawer
      title={t(`${i18n_prefix}.drawerTitle`)}
      isOpen={visible}
      onClose={() => onClose(false)}
      side="right"
      width="469px"
    >
      <PaginationByIndex
        results={classesData}
        index={selectedClassesIndex}
        setIndex={setSelectedClassesIndex}
        name={t(`${i18n_prefix}.paginationByIndexName`, {
          ciclo: selectedClassesData?.ciclo,
          segmento: selectedClassesData?.segmento,
        })}
      />

      <Container onSubmit={handleSubmit}>
        <div>
          <p>{t(`${i18n_prefix}.qtdClassesTitle`)}</p>
          <label>
            {t(`${i18n_prefix}.qtdClasses`)}
            <input
              name="qtd_turmas"
              type="number"
              onChange={(e) =>
                setForm({ ...form, qtd_turmas: nullToZero(e.target.value) })
              }
              value={form.qtd_turmas}
            />
          </label>
        </div>

        <div>
          <p>{t(`${i18n_prefix}.qtdVacanciesTitle`)}</p>
          <label>
            {t(`${i18n_prefix}.qtdVacancies`)}
            <input
              name="total_vagas"
              type="number"
              onChange={(e) =>
                setForm({ ...form, total_vagas: nullToZero(e.target.value) })
              }
              value={form.total_vagas}
            />
          </label>
        </div>

        <button type="button" disabled={isSubmitting} onClick={handleSubmit}>
          {t(`${i18n_prefix}.btnSubmit`)}
        </button>
      </Container>
    </Drawer>
  );
};
