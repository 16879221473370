import { jsonToQuerystring } from "helpers/helpers";
import {
  ActiveFoodPackageInterface,
  ConsultFoodPagination,
  EnrollmentSingleFood,
  FoodItemAvailableRangeInterface,
  FoodItemInterface,
  FoodItemsByEnrollmentInterface,
  FoodItemsScholarshipInterface,
  FoodPackage,
  GetEnrollmentsFoodItemsResponse,
  SchoolConsumptionDay,
  SingleFoodInterface,
  StudentFoodCreditHistory,
  StudentFoodCreditInterface,
  FoodItemsByEnrollment,
  IMealTypes,
  IConsultFoodList,
  IGetFoodOrder,
  IFeedingDynamicsStatus,
  IFeedingDynamics,
  IFoodItems,
} from "interfaces/mealInterfaces";
import { type } from "os";
import { KanataAPI } from "./api";
import { ErrorHandlerFunction, ErrorNoResponse } from "./service-helpers";
import { IPagination } from "interfaces/utils";

export const getFoodItems = async ({
  unit,
  type_item,
  in_force,
  date,
  enrollment,
}: {
  unit?: undefined | string;
  type_item?: string;
  in_force?: boolean;
  date?: Date | undefined;
  enrollment?: number;
}) => {
  const response = await KanataAPI.get<Array<FoodItemInterface>>(
    `school/food_items?${unit ? `unit=${unit}` : ""}${
      type_item ? `&type_item=${type_item}` : ""
    }${in_force ? `&in_force=${in_force}` : ""}${
      date !== undefined ? `&date=${date}` : ""
    }${enrollment ? `&enrollment=${enrollment}` : ""}`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export type SingleFoodParamsType = {
  unit: string;
  educational_level: string;
  grade: string;
  gradegroup: string;
  date: string;
};

export const getSingleFoods = async (params: SingleFoodParamsType) => {
  const response = await KanataAPI.get<GetEnrollmentsFoodItemsResponse>(
    `school/single_food?${jsonToQuerystring(params)}`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const setSingleFoods = async (
  payload: GetEnrollmentsFoodItemsResponse
) => {
  const response = await KanataAPI.post<GetEnrollmentsFoodItemsResponse>(
    `school/single_food`,
    payload
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export type EnrollmentSingleFoodParamsType = {
  enrollment_id: string;
  month: string;
  year: string;
};

type GetEnrollmentSingleFoods = {
  params: { enrollment_id: string; month: string; year: string };
};

export const getEnrollmentSingleFoods = async ({
  params,
}: GetEnrollmentSingleFoods) => {
  const response = await KanataAPI.get<Array<EnrollmentSingleFood>>(
    `school/enrollment_single_food?${jsonToQuerystring(params)}`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export type ExportSingleFoodParamsType = {
  params: { unit: string; init_date: string; end_date: string };
};

export const exportSingleFoods = async ({
  params,
}: ExportSingleFoodParamsType) => {
  const response = await KanataAPI.get<any>(
    `school/single_food_export?${jsonToQuerystring(params)}`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

type GetSchoolConsumptionDaysType = {
  unit: number;
  token: string;
};

export const getSchoolConsumptionDays = async ({
  unit,
}: GetSchoolConsumptionDaysType) => {
  const response = await KanataAPI.get<Array<SchoolConsumptionDay>>(
    `school/school_consumption_days?unit=${unit}`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

type SetFoodPackageType = {
  payload: {
    month?: number;
    year?: number;
    food_items: Array<number>;
    user_id?: number;
    enrollment_id?: number;
    saved_as_favorite?: boolean;
    used_available_balance?: boolean;
  };
};

export const addFoodPackage = async ({ payload }: SetFoodPackageType) => {
  const response = await KanataAPI.post<any>(
    `school/food_packages_reserve`,
    payload
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const updateFoodPackage = async ({
  id,
  body,
}: {
  id: number;
  body: {
    is_active?: boolean;
    saved_as_favorite?: boolean;
  };
}) => {
  const response = await KanataAPI.patch<any>(
    `school/food_packages_reserve/${id}`,
    body
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const getFoodPackage = async ({
  params,
}: {
  params: {
    guardian_id?: number;
    month?: string;
    year?: string;
    enrollment?: string;
  };
}) => {
  const response = await KanataAPI.get<Array<FoodPackage>>(
    `school/food_packages_reserve?${jsonToQuerystring(params)}`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export type ExportFoodPackagesParamsType = {
  params: { unit: string; year: string; months: string };
};

export const exportFoodPackages = async ({
  params,
}: ExportFoodPackagesParamsType) => {
  const response = await KanataAPI.get<any>(
    `school/food_package_export?${jsonToQuerystring(params)}`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

type GetFoodItemsScholarshipsType = {
  scholarship_type: number;
  unit: number;
  token: string;
};

export const getFoodItemsScholarships = async ({
  scholarship_type,
  unit,
}: GetFoodItemsScholarshipsType) => {
  const response = await KanataAPI.get<Array<FoodItemsScholarshipInterface>>(
    `school/food_items_scholarship?scholarship_type=${scholarship_type}&unit=${unit}`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

type CreateFoodItemType = {
  body: {
    unit: string;
    name: string;
    initial_date: string;
    final_date: string;
    value_single: number;
    value_package: number;
  };
};

export const createFoodItem = async ({ body }: CreateFoodItemType) => {
  const response = await KanataAPI.post<any>(`school/food_items`, body).catch(
    ErrorHandlerFunction
  );
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

type UpdateFoodItemType = {
  body: {
    unit: string;
    name: string;
    initial_date: string;
    final_date: string;
    value_single: number;
    value_package: number;
  };
  id: number;
};

export const updateFoodItem = async ({ body, id }: UpdateFoodItemType) => {
  const response = await KanataAPI.patch<any>(
    `school/food_items/${id}`,
    body
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

type DeleteFoodItemType = {
  id: number;
};

export const deleteFoodItem = async ({ id }: DeleteFoodItemType) => {
  const response = await KanataAPI.delete<any>(`school/food_items/${id}`).catch(
    ErrorHandlerFunction
  );
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

type GetFoodItemsAvailableRangesType = {
  unit?: number;
};

export const getFoodItemsAvailableRanges = async ({
  unit,
}: GetFoodItemsAvailableRangesType) => {
  const response = await KanataAPI.get<Array<FoodItemAvailableRangeInterface>>(
    `school/food_items_available_range${unit ? `?unit=${unit}` : ""}`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

type CreateFoodItemsAvailableRangesType = {
  payload: FoodItemAvailableRangeInterface;
};

export const createFoodItemsAvailableRanges = async ({
  payload,
}: CreateFoodItemsAvailableRangesType) => {
  const response = await KanataAPI.post<Array<FoodItemAvailableRangeInterface>>(
    `school/food_items_available_range`,
    payload
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

type UpdateFoodItemsAvailableRangesType = {
  id: number;
  payload: FoodItemAvailableRangeInterface;
};

export const updateFoodItemsAvailableRanges = async ({
  id,
  payload,
}: UpdateFoodItemsAvailableRangesType) => {
  const response = await KanataAPI.patch<
    Array<FoodItemAvailableRangeInterface>
  >(`school/food_items_available_range/${id}`, payload).catch(
    ErrorHandlerFunction
  );
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

type GetActiveFoodPackagesType = {
  student_name?: string;
  unit?: number;
  educational_level?: number;
  guardian_cpf?: number;
  page?: number;
  offset?: number;
};

export const getActiveFoodPackage = async ({
  student_name,
  unit,
  educational_level,
  guardian_cpf,
  page,
}: GetActiveFoodPackagesType) => {
  const response = await KanataAPI.get<ActiveFoodPackageInterface>(
    `/school/food_packages_reserve/active/?${
      student_name
        ? `student_name=${student_name}&unit=${unit}&educational_level=${educational_level}`
        : guardian_cpf
        ? `guardian_cpf=${guardian_cpf}${
            page && page > 1 ? `&page=${page}` : ""
          }`
        : ""
    }`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

type CancelActiveFoodPackagesType = {
  id: number;
};

export const cancelFoodPackage = async ({
  id,
}: CancelActiveFoodPackagesType) => {
  const response = await KanataAPI.patch<any>(
    `school/food_packages_reserve/cancel/${id}/`,
    id
  ).catch(ErrorNoResponse);
  if (response) {
    const data = { status: response.status };
    return data;
  }
};

type GetStudentFoodCredit = {
  student_name: string;
  unit: string;
  educational_level: string;
};

export const getStudentFoodCredit = async ({
  student_name,
  unit,
  educational_level,
}: GetStudentFoodCredit) => {
  const response = await KanataAPI.get<StudentFoodCreditInterface>(
    `/school/enrollment_food_packages_credit?${`unit=${unit}&student_name=${student_name}&educational_level=${educational_level}`}`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

type PostStudentFoodCreditType = {
  payload: {
    enrollment: number;
    credit: number;
    reason: string;
  };
};

export const postStudentFoodCredit = async ({
  payload,
}: PostStudentFoodCreditType) => {
  const response = await KanataAPI.post(
    "/school/food_packages_credit/",
    payload
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

type GetStudentFoodCreditHistory = {
  id: number;
  page?: number;
};

export const getStudentFoodCreditHistory = async ({
  id,
  page,
}: GetStudentFoodCreditHistory) => {
  const response = await KanataAPI.get<StudentFoodCreditHistory>(
    `/school/food_packages_credit/?enrollment=${id}${
      page && page > 1 ? `&page=${page}` : ""
    }`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

type GetConsultFoodType = {
  name?: string;
  date: string;
  unit_id: string | number;
  student_id?: number;
  type?: string;
  page?: number;
};

export const getConsultFood = async ({
  name,
  date,
  unit_id,
  student_id,
  page,
  type,
}: GetConsultFoodType) => {
  const response = await KanataAPI.get<ConsultFoodPagination>(
    "/school/single_food_enrollment",
    {
      params: { student_id, date, unit_id, type, name, page },
    }
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

type PostSingleFood = {
  user_id: number;
  date: string;
  enrollment_id: number;
  items_id: number[];
};

export const postSingleFood = async ({
  user_id,
  date,
  enrollment_id,
  items_id,
}: PostSingleFood) => {
  const params = {
    user_id,
    date,
    enrollment_id,
    items_id,
  };

  const response = await KanataAPI.post(
    `/school/single_food_enrollment`,
    params
  ).catch(ErrorHandlerFunction);

  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

type GetConsultSingleFoodTransactionsType = {
  guardianId: number;
  date?: string;
};

export const getConsultSingleFoodTransactions = async ({
  guardianId,
  date,
}: GetConsultSingleFoodTransactionsType) => {
  const response = await KanataAPI.get<SingleFoodInterface>(
    `/school/transaction_single_food?guardian=${guardianId}&page_size=100`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface FoodItemByEnrollment {
  unit: string;
  grade: string;
  educational_level: string;
  section: string;
  date: string;
}
export const getFoodItemsByEnrollment = async ({
  unit,
  grade,
  educational_level,
  section,
  date,
}: FoodItemByEnrollment) => {
  const response = await KanataAPI.get<FoodItemsByEnrollmentInterface>(
    `food/item-by-enrollment/?unit=${unit}&grade=${grade}&educational_level=${educational_level}&section=${section}&date=${date}&page_size=100`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface UpdateFoodItemByEnrollment {
  id: number;
  name: string;
  available: boolean;
  contracted: boolean;
}
interface UpdateFoodItemsByEnrollment {
  body: {
    food_items: Array<UpdateFoodItemByEnrollment>;
    date: string;
  };
  enrollmentId: number;
}

export const updateFoodItemsByEnrollment = async ({
  body,
  enrollmentId,
}: UpdateFoodItemsByEnrollment) => {
  const response = await KanataAPI.put(
    `food/item-by-enrollment/${enrollmentId}/`,
    body
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const getMealTypes = async () => {
  const response = await KanataAPI.get<IMealTypes>(
    `school/food_package_type`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const getAvaliableDates = async () => {
  const response = await KanataAPI.get("food/datas_disponiveis").catch(
    ErrorHandlerFunction
  );

  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface IGetFoodInfoByDay {
  unidade: number;
  data: string;
  matricula: number;
}

export const getFoodInfoByDay = async ({
  unidade,
  data,
  matricula,
}: IGetFoodInfoByDay) => {
  const response = await KanataAPI.get(
    `food/informacoes_alimentacao_dia?unidade=${unidade}&data=${data}&matricula=${matricula}`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface GetConsultFood {
  estudante_ou_numero_pedido?: string;
  unidade?: string;
  page?: number;
  status?: string; // Parametro que procurar por status de pagamento
}

export const getConsultFoodOrders = async ({
  estudante_ou_numero_pedido,
  unidade,
  page,
  status,
}: GetConsultFood) => {
  const response = await KanataAPI.get<IConsultFoodList>("food/alimentacao", {
    params: page
      ? { estudante_ou_numero_pedido, unidade, status, page }
      : { estudante_ou_numero_pedido, unidade, status },
  }).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

type TGetFoodOrder = {
  order: number;
};

// TODO: consulta alimentacao
export const getFoodOrder = async ({ order }: TGetFoodOrder) => {
  const response = await KanataAPI.get<IGetFoodOrder>(
    `food/alimentacao/${order}`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const cancelFoodOrder = async ({ order }: TGetFoodOrder) => {
  const response = await KanataAPI.put(
    `food/cancela_alimentacao_avulsa/${order}/`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

// TODO: Gerenciar dinamicas
export const getFeedingDynamicsStatus = async () => {
  const response = await KanataAPI.get<IFeedingDynamicsStatus>(
    "food/status_dinamicas_alimentacao/"
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface IGetFeedingDynamics {
  unidade?: number;
  status?: string;
  item: string;
}

export const getFeedingDynamics = async ({
  unidade,
  status,
  item,
}: IGetFeedingDynamics) => {
  const response = await KanataAPI.get<IPagination<IFeedingDynamics>>(
    "food/dinamica_alimentacao/",
    {
      params: { unidade: unidade !== 0 ? unidade : "", status, item },
    }
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const getFoodItemsList = async () => {
  const response = await KanataAPI.get<IFoodItems>(
    "food/item_alimentacao/"
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface INewDynamic {
  payload: {
    nome: string;
    vigencia_inicio: string;
    vigencia_fim: string;
    valor_dinamica: Array<{
      quantidade_minima: number;
      valor_unitario: string;
    }>;
    unidades_ids: Array<number>;
    todas_unidades: boolean;
    item: string;
  };
}

export const newDynamic = async ({ payload }: INewDynamic) => {
  const response = await KanataAPI.post(
    "food/dinamica_alimentacao/",
    payload
  ).catch(ErrorHandlerFunction);

  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};
