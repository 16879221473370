import { InputFileStyled } from "./style";
import Icon from "@mdi/react";
import { InputHTMLAttributes } from "react";

interface InputFileInterface extends InputHTMLAttributes<HTMLInputElement> {
  fileName: string;
  icon?: string;
}

export const InputFile = ({ fileName, onChange, icon }: InputFileInterface) => {
  return (
    <InputFileStyled>
      <input
        accept=".pdf, .jpeg, .png, .jpg, .jfif, .heic"
        style={{ display: "none" }}
        id="button-file"
        multiple
        type="file"
        onChange={onChange}
        name="inputFile"
        value=""
      />
      <div>
        <label htmlFor="button-file" className="inputName">
          {icon && (
            <div className="iconStyle">
              <Icon path={icon} />
            </div>
          )}
          {fileName}
        </label>
      </div>
    </InputFileStyled>
  );
};
