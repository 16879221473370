import { Link, useHistory } from "react-router-dom";

export const NotAllowedPage = () => {
  const history = useHistory();

  return (
    <div className="text-center">
      <div className="error mx-auto" data-text="403">
        403
      </div>
      <p className="lead text-gray-800 mb-5">
        Você não tem permissão para acessar essa página.
      </p>
      <Link to="#" onClick={() => history.goBack()}>
        ← Voltar
      </Link>
    </div>
  );
};

export default NotAllowedPage;
