/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from "@material-ui/core";
import { Button, TextInput } from "components";
import SelectInput from "components/Select";
import formatString from "format-string-by-pattern";
import {
  composeValidators,
  noSpecialCharactersAndNumbers,
  required,
  validateCVVIugu,
  validateCreditCardIugu,
  validateExpirationIugu,
} from "helpers/fieldValidators";
import { formatMoney } from "helpers/helpers";
import HTTP_STATUS from "http-status-codes";
import { GetCXUserJson, StudentInterface } from "interfaces/constants";
import { CreditPaymentBox } from "pages/EDF/PagamentoReserva/styles";
import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Field, Form } from "react-final-form";
import { useHistory, useLocation } from "react-router-dom";
import {
  creditCardPaymentIugu,
  getMatriculaValue,
} from "services/payment.service";
import loadingGif from "static/img/loading.gif";
import { BLUE } from "styles/colors";
import { BoldBiggerBlue, BoldBiggerPink } from "../../styles";
import { useAllowedUnits } from "hooks/useAllowedUnits";

type StateType = {
  studentId: number;
  guardianId: number;
  student: StudentInterface;
  user: GetCXUserJson;
};

export const CartaoCreditoSegundo = (): ReactElement => {
  const location = useLocation<StateType>();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [approved, setApproved] = useState(false);
  const [failed, setFailed] = useState(false);
  const [errorText, setErrorText] = useState<Array<string>>([]);
  const [user] = useState(location.state.user);
  const [valorMatricula, setValorMatricula] = useState<number>();
  const [alowEnrollmentFlow, setAlowEnrollmentFlow] = useState(false);

  //import iugu thought CDN
  const newWindow = window as any;
  const Iugu = newWindow.Iugu;

  const { verifyUserPermissionToAccessFeature } =
    useAllowedUnits("fluxo-matricula");

  const continueFlow = useCallback(
    async (unit: number) => {
      const isUserAllowed = await verifyUserPermissionToAccessFeature(unit);

      setAlowEnrollmentFlow(isUserAllowed);
    },
    [verifyUserPermissionToAccessFeature]
  );

  useEffect(() => {
    if (location.state.student.last_enrollment) {
      continueFlow(location.state.student.last_enrollment.unit_id);
    }

    const fetchValorMatricula = async () => {
      const response = await getMatriculaValue({
        student_id: location.state.studentId,
      });
      if (response && response.status === HTTP_STATUS.OK) {
        setValorMatricula(response.data / 100);
      } else {
        setFailed(true);
        setErrorText([
          ...errorText,
          "Erro ao Carregar o valor da matrícula para o pagamento",
        ]);
        setLoading(false);
      }
    };

    try {
      setLoading(true);
      fetchValorMatricula();
      setLoading(false);
    } catch (err) {
      setFailed(true);
      setErrorText([
        ...errorText,
        "Erro ao Carregar o valor da matrícula para o pagamento",
      ]);
      setLoading(false);
    }
  }, []);

  const onSubmit = async (data: any) => {
    setLoading(true);

    const nameSplit = data.name.split(" ");
    const firstName = nameSplit[0];
    const lastName = nameSplit.splice(1).join(" ");
    const month = data.due_date.split("/")[0];
    const year = data.due_date.split("/")[1];

    const CreditCard = Iugu.CreditCard(
      data.card_number,
      month,
      year,
      firstName,
      lastName,
      data.cvv
    );

    const cardBrand = Iugu.utils.getBrandByCreditCardNumber(data.card_number);

    //O Objeto CreditCard da IUGU possuí o metodo valid() que retorna
    //True ou false dependendo se o cartão é válido.
    if (!CreditCard.valid()) {
      setFailed(true);
      setErrorText([
        ...errorText,
        "Cartão inválido, verifique os dados do mesmo",
      ]);
      setLoading(false);
    }

    Iugu.createPaymentToken(CreditCard, (response: any) => {
      if (response.errors) {
        Object.keys(response.errors).forEach((key) => {
          switch (key) {
            case "number":
              setErrorText([...errorText, "Número do cartão inválido"]);
              break;
            case "verification_value":
              setErrorText([...errorText, "CVV inválido"]);
              break;
            case "expiration":
              setErrorText([...errorText, "Data de vencimento inválida"]);
              break;
            case "first_name":
              setErrorText([
                ...errorText,
                "Primeiro nome inválido ou não informado",
              ]);
              break;
            case "last_name":
              setErrorText([
                ...errorText,
                "Sobrenome inválido ou não informado",
              ]);
              break;
            case "full_name":
              setErrorText([
                ...errorText,
                "Nome completo inválido ou não informado",
              ]);
              break;
          }
        });
        setFailed(true);
        setLoading(false);
      } else if (valorMatricula) {
        creditCardPaymentIugu({
          charge_token: response.id,
          guardian_id: location.state.guardianId,
          value: Math.round(valorMatricula * 100),
          student_id: location.state.studentId,
          brand: cardBrand,
          installments: parseInt(data.parcelas, 10),
        }).then((response) => {
          if (response) {
            if (response.status === HTTP_STATUS.OK) {
              setApproved(true);
              setLoading(false);
            } else {
              setFailed(true);
              setLoading(false);
              setErrorText([
                ...errorText,
                `Erro ${response.status} - ${response.data}`,
              ]);
            }
          }
        });
      }
    });
  };

  useEffect(() => {
    //Seta o AccountID da IUGU
    Iugu.setAccountID("8EF5BA8442B546CC85103E3D73C1FB55");

    //Verifica o ambiente e seta o modo teste da IUGU de acordo.
    if (process.env.NODE_ENV !== "development") {
      if (window.location.href.indexOf("homolog") > -1) {
        Iugu.setTestMode(true);
      } else {
        Iugu.setTestMode(false);
      }
    } else {
      Iugu.setTestMode(true);
    }
  }, []);

  return (
    <div className="container">
      <>
        {failed && (
          <>
            <div className="row justify-content-center">
              <div className="col-12 text-center mt-3">
                <BoldBiggerPink>
                  Ocorreu um erro ao realizar o pagamento
                </BoldBiggerPink>
                {errorText ? (
                  <BoldBiggerPink>
                    Mensagem de Erro:{" "}
                    {errorText.map((errorMessage) => {
                      return (
                        <div style={{ color: "#000000" }}>{errorMessage}</div>
                      );
                    })}
                  </BoldBiggerPink>
                ) : (
                  <BoldBiggerPink>
                    Verifique os dados do cartão e tente novamente
                  </BoldBiggerPink>
                )}
              </div>
              <div className="col-lg-6 col-12">
                <Button
                  style={{ backgroundColor: BLUE }}
                  onClick={() => {
                    setApproved(false);
                    setFailed(false);
                  }}
                >
                  Voltar
                </Button>
              </div>
            </div>
          </>
        )}
        {approved && (
          <>
            <div className="row justify-content-center">
              <div className="col-10 text-center mt-3">
                <BoldBiggerBlue>
                  Pagamento concluído com sucesso!
                </BoldBiggerBlue>
              </div>
              <div className="row mt-3 justify-content-center">
                <span style={{ fontSize: "20px" }}>
                  Agora continue clicando no botão abaixo para a próxima etapa.
                </span>
              </div>
              <div className="col-lg-6 col-12">
                <Button
                  onClick={() => {
                    history.push({
                      pathname: `/responsavel/meus-alunos/responsavel-financeiro`,
                      state: {
                        student: location.state.student,
                        guardian: user,
                      },
                    });
                  }}
                >
                  Financeiro
                </Button>
              </div>
            </div>
          </>
        )}
        {!failed && !approved && valorMatricula && (
          <CreditPaymentBox>
            <Form onSubmit={onSubmit}>
              {({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <p className="mt-2">
                      Resta o valor de :{" "}
                      <strong>{formatMoney(valorMatricula)}</strong> para o 2º
                      cartão
                    </p>
                    <div className="col-12 text-center">
                      <Typography variant="h4">
                        Digite os Dados do 2º Cartão
                      </Typography>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Field
                        component={TextInput}
                        name="card_number"
                        type="text"
                        placeholder=" "
                        label="Número do cartão"
                        parse={formatString("9999 9999 9999 9999")}
                        maxLength={20}
                        validate={composeValidators(validateCreditCardIugu)}
                        required
                      />
                    </div>
                    <div className="col-12">
                      <Field
                        component={TextInput}
                        name="name"
                        type="text"
                        placeholder=" "
                        label="Nome Titular"
                        validate={composeValidators(
                          required,
                          noSpecialCharactersAndNumbers
                        )}
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-9">
                      <Field
                        component={TextInput}
                        name="due_date"
                        type="text"
                        placeholder="MM/AA"
                        parse={formatString("99/99")}
                        label="Data de Vencimento"
                        validate={composeValidators(
                          required,
                          validateExpirationIugu
                        )}
                        required
                      />
                    </div>
                    <div className="col-9">
                      <Field
                        component={TextInput}
                        name="cvv"
                        type="text"
                        placeholder=" "
                        label="CVV"
                        maxLength="4"
                        validate={composeValidators(
                          required,
                          validateCVVIugu(values.card_number)
                        )}
                        required
                      />
                    </div>
                    <div className="col-9">
                      <Field
                        component={SelectInput}
                        name="parcelas"
                        required
                        validate={composeValidators(required)}
                        defaultValue="1"
                      >
                        {/* 6 é o numero de parcelas */}
                        {[...Array(6).keys()].map((parcela) => {
                          return (
                            <option value={parcela + 1} key={parcela + 1}>
                              {`${parcela + 1}x de ${formatMoney(
                                valorMatricula / (parcela + 1)
                              )}`}{" "}
                            </option>
                          );
                        })}
                      </Field>
                    </div>
                  </div>
                  <div className="row justify-content-center mt-5">
                    <div className="col-12 mt-2">
                      <Button type="submit" disabled={loading}>
                        {!loading && "Confirmar Pagamento"}
                        {loading && (
                          <img height="25" src={loadingGif} alt="loading" />
                        )}
                      </Button>
                    </div>
                    <div className="col-lg-6 col-12">
                      <Button
                        type="button"
                        style={{ backgroundColor: BLUE }}
                        onClick={() => {
                          history.push({
                            pathname: `/responsavel/meus-alunos`,
                            state: { user },
                          });
                        }}
                      >
                        Voltar
                      </Button>
                    </div>
                  </div>
                </form>
              )}
            </Form>
          </CreditPaymentBox>
        )}
      </>
    </div>
  );
};
