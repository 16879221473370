import leftArrow from "static/img/left_arrow.svg";
import rightArrow from "static/img/right_arrow.svg";
import { IPagination } from "./interface";
import * as S from "./style";

export const Pagination = ({ data, offset, values }: IPagination) => {
  return (
    <S.PaginationContainer>
      <div>
        {values.initialValue} - {values.lastValue} de {data.count}
      </div>
      <S.Icons>
        <button
          type="button"
          onClick={() => {
            offset("backwards");
          }}
          disabled={!data.previous}
        >
          <S.Img src={leftArrow} alt="left-arrow" enabled={data.previous} />
        </button>
        <button
          type="button"
          onClick={() => {
            offset("foward");
          }}
          disabled={!data.next}
        >
          <S.Img src={rightArrow} alt="right-arrow" enabled={data.next} />
        </button>
      </S.Icons>
    </S.PaginationContainer>
  );
};
