import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Container, SelectStyled } from "../style";
import {
  AssignClassUsersResponse,
  AssignClassResponse,
  getSubjectList,
  SubjectList,
  getGradeGroup,
  GradeGroups,
  atribuirDisciplina,
  getAssignClass,
  removeAtribuicaoDisciplina,
} from "services/grades.service";
import HTTP_STATUS from "http-status-codes";
import Tooltip from "@material-ui/core/Tooltip";
import { Button } from "components";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import { makeStyles } from "@material-ui/core/styles";
import { toastWarning, toastSuccess, toastError } from "components/Toast";
import { ROLE } from "helpers/constants";
import { useAtomValue } from "jotai";
import { authUserAtom } from "jotai/authUser";

type StateType = {
  assignUser: AssignClassUsersResponse;
  userClasses?: AssignClassResponse[];
};

const useStyles = makeStyles((theme) => ({
  customHoverFocus: {
    color: "#00aeefff",
    "&:hover, &.Mui-focusVisible": {
      backgroundColor: "#c4c4c4",
      borderRadius: "50%",
      cursor: "pointer",
    },
  },
}));

export const EditarAtribuicoes = () => {
  const classes = useStyles();
  const authUser = useAtomValue(authUserAtom);
  const location = useLocation<StateType>();
  const { assignUser } = location.state;
  const [userClasses, setUserClasses] = useState(location.state.userClasses);
  const [subjects, setSubjects] = useState<SubjectList[]>();
  const [gradeGroups, setGradeGroups] = useState<GradeGroups[]>();
  const [loading, setLoading] = useState(true);
  const [selectedComponent, setSelectedComponent] = useState("0");
  const [selectedGradeGroup, setSelectedGradeGroup] = useState("0");
  const [submitting, setSubmitting] = useState(false);

  const RemoverTurmaAtribuida = async (userClass: AssignClassResponse) => {
    setSubmitting(true);

    let payload: any = {};
    payload[`${assignUser.role_id === ROLE.TEACHER ? `teacher` : `educator`}`] =
      assignUser.id;
    payload.assign_id = userClass.id;
    payload.year = userClass.year;

    const response = await removeAtribuicaoDisciplina(payload);

    if (response && response.status === HTTP_STATUS.OK) {
      toastSuccess("Atribuição removida com sucesso.");
      const responseClasses = await getAssignClass({
        unit: authUser.grade_unit_id,
        role: assignUser.role_id,
        id: assignUser.id,
      });

      if (responseClasses && responseClasses.status === HTTP_STATUS.OK) {
        setUserClasses(responseClasses.data);
      } else {
        toastError("Ocorreum um erro ao atualizar a lista de componentes ");
      }
    } else {
      toastError("Ocorreu um erro ao remover a atribuição da turma.");
    }
    setSubmitting(false);
  };

  const AtribuirTurma = async () => {
    setSubmitting(true);
    if (selectedComponent !== "0" && selectedGradeGroup !== "0") {
      //Formata o payload para atribuir a key correta de acordo com a role
      //caso seja educador a key pro id se chama "educator"
      //caso seja professor a key se chama "teacher"
      let payload: any = {};
      payload[
        `${assignUser.role_id === ROLE.TEACHER ? `teacher` : `educator`}`
      ] = assignUser.id;
      payload.subject = parseInt(selectedComponent);
      payload.gradegroup = parseInt(selectedGradeGroup);
      payload.year = new Date().getFullYear();

      //a entrada dos dados no endpoint estão tipados.
      const response = await atribuirDisciplina(payload);

      if (response && response.status === HTTP_STATUS.CREATED) {
        toastSuccess("Turma e componente atribuído ao educador com sucesso");
        const responseClasses = await getAssignClass({
          unit: authUser.grade_unit_id,
          role: assignUser.role_id,
          id: assignUser.id,
        });

        if (responseClasses && responseClasses.status === HTTP_STATUS.OK) {
          setUserClasses(responseClasses.data);
        }
      } else {
        toastError(
          "Ocorreu um erro ao atribuír a turma e o componente ao educador"
        );
      }
    } else {
      toastWarning(
        "Você precisa selecionar um componente e uma turma para atribuir ao educador."
      );
    }
    setSubmitting(false);
  };

  useEffect(() => {
    const fetchSubjects = async () => {
      const responseSubjectList = await getSubjectList();
      if (
        responseSubjectList &&
        responseSubjectList.status === HTTP_STATUS.OK
      ) {
        setSubjects(responseSubjectList.data);
      }
      const responseGradeGroups = await getGradeGroup(
        authUser.grade_unit_id,
        new Date().getFullYear()
      );
      if (
        responseGradeGroups &&
        responseGradeGroups.status === HTTP_STATUS.OK
      ) {
        setGradeGroups(responseGradeGroups.data);
        setLoading(false);
      }
    };
    fetchSubjects();
  }, [authUser.grade_unit_id]);

  return loading ? (
    <div className="mt-3">
      <div className="row">
        <div className="col-12 text-center title">Carregando...</div>
      </div>
    </div>
  ) : (
    <Container className="container">
      {subjects && gradeGroups && (
        <div className="user-card">
          <div className="row mt-3">
            <div className="col-4">
              <div className="blue-bold">{assignUser.full_name}</div>
              <div className="user-role">{assignUser.role}</div>
              <div className="mt-5 feature-border">
                <div className="title">Adicionar Componente</div>
                <SelectStyled
                  onChange={(e: any) => setSelectedComponent(e.target.value)}
                >
                  <option value={0}>Selecione um Componente</option>
                  {subjects.map((subject) => {
                    return (
                      <option value={subject.id} key={subject.id}>
                        {`${subject.short_name} - ${subject.name}`}
                      </option>
                    );
                  })}
                </SelectStyled>
                <SelectStyled
                  onChange={(e: any) => setSelectedGradeGroup(e.target.value)}
                >
                  <option value={0}>Selecione uma turma</option>
                  {gradeGroups
                    .sort(
                      (a, b) =>
                        a.klass_level - b.klass_level ||
                        a.klass_grade - b.klass_grade
                    )
                    .map((gradeGroup) => {
                      return (
                        <option value={gradeGroup.id} key={gradeGroup.id}>
                          {`${gradeGroup.klass_grade}º Ano ${gradeGroup.letter} - ${gradeGroup.klass_level_name}`}
                        </option>
                      );
                    })}
                </SelectStyled>
                <Button onClick={AtribuirTurma} disabled={submitting}>
                  Atribuir Componente
                </Button>
              </div>
            </div>
            <div className="col-8">
              <div className="pink-bold">Componentes Curriculares</div>
              <div className="vertical-divider d-flex flex-wrap mt-4">
                {userClasses && userClasses.length ? (
                  userClasses.map((userClass) => {
                    return (
                      <div key={`${assignUser.id}-${userClass.id}-classes`}>
                        <div className="rounded">
                          <Tooltip
                            title={`${userClass.subject_name} - ${userClass.grade}º 
                                    ${userClass.letter} do Ensino 
                                    ${userClass.educational_level_name}`}
                            placement="top-start"
                          >
                            <span>
                              <span className="pink-bold mr-1">
                                {userClass.subject_short_name}
                              </span>
                              <span>
                                {`${userClass.educational_level_name} - 
                                        ${userClass.grade}º 
                                        ${userClass.letter}`}
                              </span>
                            </span>
                          </Tooltip>
                          <Tooltip title="Excluir" placement="left">
                            <span
                              className="ml-1"
                              onClick={() => {
                                RemoverTurmaAtribuida(userClass);
                              }}
                            >
                              <HighlightOffOutlinedIcon
                                className={classes.customHoverFocus}
                              />
                            </span>
                          </Tooltip>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="ml-1">Sem componentes atribuídos</div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};
