import { UNITS_ENUM } from "helpers/constants";
import {
  UnitDetailInterface,
  GradeInterface,
  GradeEdLevelInterface,
  UnitDetailELInterface,
} from "interfaces/constants";

export const formatUnits = (data: any) => {
  let units: UnitDetailInterface[] = [];
  Object.keys(data).forEach((unitName) => {
    let unit: UnitDetailInterface = { name: "", id: "", grades: [] };
    unit.name = unitName;
    unit.id = UNITS_ENUM[unitName];
    data[unitName].forEach((data_: GradeInterface) => {
      let grade: GradeInterface = { grade: 0, year: 0 };
      grade.grade = data_.grade;
      grade.year = data_.year;
      unit.grades.push(grade);
    });
    units.push(unit);
  });
  return units;
};

interface GradeEducationalLevelInterface {
  educational_level_grade_id: number;
  grade: number;
  school_places: number | null;
}

interface EducationLevelInterface {
  id: number;
  name: string;
  grades: Array<GradeEducationalLevelInterface>;
}

interface UnitSchoolPlaceInterface {
  id: number;
  name: string;
  educational_level: Array<EducationLevelInterface>;
}

interface UnitSchoolPlaces {
  unit: UnitSchoolPlaceInterface;
}

export const formatUnitsSchoolPlaces = (data: any) => {
  let units: UnitDetailELInterface[] = [];
  data.forEach((unit_: UnitSchoolPlaces) => {
    let unit: UnitDetailELInterface = { name: "", id: "", grades: [] };
    unit.name = unit_.unit.name;
    unit.id = unit_.unit.id.toString();
    unit_.unit.educational_level.forEach((ed_lvl) => {
      ed_lvl.grades.forEach((grade_) => {
        let grade: GradeEdLevelInterface = {
          name: "",
          grade: 0,
          id_ed_lvl_grade: 0,
          id_ed_lvl: 0,
          school_places: null,
        };
        grade.name = `${ed_lvl.name} - ${grade_.grade}º ano`;
        grade.id_ed_lvl_grade = grade_.educational_level_grade_id;
        grade.id_ed_lvl = ed_lvl.id;
        grade.grade = grade_.grade;
        grade.school_places = grade_.school_places;
        unit.grades.push(grade);
      });
    });
    units.push(unit);
  });
  return units;
};
