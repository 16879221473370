// React
import { useContext, useEffect, useState } from "react";
// Style
import * as S from "./styles";
// Libs
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HTTP_STATUS from "http-status-codes";
import moment from "moment";
// Interfaces
import { ILocationFoodPage } from "../FoodPage/interface";
import { SingleFoodInterface } from "interfaces/mealInterfaces";
// Services
import { getConsultSingleFoodTransactions } from "services/meals.service";
// Components
import { Title, Loading } from "components/atoms";
import { StudentsList } from "components/organisms";
import { StudentFoodPurchase } from "./components/StudentFoodPurchase";
import { IsDevelopmentEnvironment } from "helpers/helpers";

export const SinglePurchase = () => {
  const { state } = useLocation<ILocationFoodPage>();

  const { t } = useTranslation();
  const i18_prefix = "pages.EDF.singlePurchase";

  const [transactions, setTransactions] = useState<SingleFoodInterface>();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (state.user) {
      const getSingleFoodTransactions = async () => {
        const response = await getConsultSingleFoodTransactions({
          guardianId: state.user.id,
          date: moment(new Date()).format("YYYY-MM-DD"),
        });

        if (response && response.status === HTTP_STATUS.OK) {
          setTransactions(response.data);
          setLoading(false);
        } else {
          setError(true);
          setLoading(false);
        }
      };

      getSingleFoodTransactions();
    }
  }, [state]);

  return (
    <S.Container className="mb-5">
      {loading && <Loading />}
      {error && !loading && <div>{t(`${i18_prefix}.errorText`)}</div>}
      {transactions && !error && !loading && (
        <div>
          <Title>{t(`${i18_prefix}.title`)}</Title>
          <div className="content">
            <h5>{t(`${i18_prefix}.singlePurchase`)}</h5>
            <p>{t(`${i18_prefix}.content`)}</p>
            <StudentsList students={state.user.students} />
          </div>
          <Title>{t(`${i18_prefix}.singleItem`)}</Title>
          {transactions.results.length > 0 ? (
            <div className="orderHistory">
              {transactions.results.map((order) => (
                <StudentFoodPurchase
                  key={order.id}
                  orderHistory={order}
                  user={state.user}
                  student={state.user.students.find(
                    (student) => student.name === order.enrollment.student_name
                  )}
                />
              ))}
            </div>
          ) : (
            <div className="nofoundDiv">
              <strong className="nofound">
                {t(`${i18_prefix}.noItemsFound`)}
              </strong>
            </div>
          )}
        </div>
      )}
    </S.Container>
  );
};
