import styled, { css } from "styled-components";
import * as colors from "styles/colors";

export const Container = styled.div`
  .group-buttons {
    display: flex;

    > div {
      margin-top: 20px;
    }
  }

  .table-data {
    margin-top: 20px;

    h2 {
      font-weight: 700;
      font-size: 20px;
      color: ${colors.NEW_GRAY_SCALE};
    }

    table {
      margin-top: 19px;
      display: flex;
      flex-direction: column;

      tr,
      td,
      th {
        display: flex;
        flex: 1;

        p {
          margin: 0;
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 9px;
        }
      }

      thead {
        border-bottom: 1px solid ${colors.GRAY_SCALE};
      }

      tr {
        & > th:first-child,
        & > td:first-child {
          padding: 8px 16px;
        }
      }

      td {
        border-bottom: 1px solid ${colors.NEW_GRAY_THREE};
      }

      td,
      th {
        padding: 8px;
      }

      tr {
        cursor: pointer;
      }
    }
  }
`;

export const LoadingContent = styled.div`
  margin-top: 40px;
`;

interface DataBoldI {
  bold: boolean;
}

export const DataBold = styled.td<DataBoldI>`
  font-weight: ${({ bold }) => (bold ? "700" : "500")};
`;
