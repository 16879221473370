import Icon from "@mdi/react";
import { mdiPencilOutline, mdiEyeOutline } from "@mdi/js";
import { TableBody, TableHead } from "./styles";
import { Storage } from "Storage";
import { isEnrollmentManager, isOperationalDirector } from "helpers/constants";
import { IClassesAndVacancies } from "../../types";

interface ITableProps {
  data: IClassesAndVacancies[];
  handleSelectClassesAndVacancies: (index: number) => void;
  handleSelectVacanciesReenrollment: (index: number) => void;
  handleSelectVacanciesReserved: (index: number) => void;
}

export const Table = ({
  data,
  handleSelectClassesAndVacancies,
  handleSelectVacanciesReenrollment,
  handleSelectVacanciesReserved,
}: ITableProps) => {
  const tableHead = [
    "Ciclo",
    "Ano/Série",
    "Quantidade de turmas",
    "Total de vagas",
    "Vagas para rematrícula",
    "Vagas reservadas matrícula",
    "Vagas reservadas rematrícula",
    "Disponíveis para venda",
  ];

  const role = Storage.props.role.get();

  return (
    <>
      <TableHead>
        <ul>
          {tableHead.map((head, index) => (
            <li key={index}>{head}</li>
          ))}
        </ul>
      </TableHead>

      <TableBody>
        {data.map((item, index) => (
          <ul key={item.id}>
            <li>{item.ciclo}</li>
            <li>{item.segmento}º</li>
            <li>
              {item.qtd_turmas ?? "-"}
              {isOperationalDirector(role) && (
                <button onClick={() => handleSelectClassesAndVacancies(index)}>
                  <Icon path={mdiPencilOutline} />
                </button>
              )}
            </li>
            <li>
              {item.total_vagas ?? "-"}
              {isOperationalDirector(role) && (
                <button onClick={() => handleSelectClassesAndVacancies(index)}>
                  <Icon path={mdiPencilOutline} />
                </button>
              )}
            </li>
            <li>
              {item.qtd_vagas_rematricula ?? "-"}
              {(isOperationalDirector(role) || isEnrollmentManager(role)) && (
                <button
                  onClick={() => handleSelectVacanciesReenrollment(index)}
                >
                  <Icon path={mdiPencilOutline} />
                </button>
              )}
            </li>
            <li>{item.qtd_vagas_reservadas_matriculas ?? "-"}</li>
            <li>{item.qtd_vagas_reservadas_rematriculas ?? "-"}</li>
            <li>{item.qtd_vagas_disponiveis_venda ?? "-"}</li>
            {/* <li>{item.listaEspera ?? "-"}</li> */}
          </ul>
        ))}
      </TableBody>
    </>
  );
};
