import { Button, RadioButton, TextInput } from "components";
import { CalendarComponent } from "components/Calendar";
import Checkbox from "components/Checkbox";
import Modal from "components/Modal";
import PoliticaPrivacidade from "components/PoliticaPrivacidade";
import { toastError, toastSuccess } from "components/Toast";
import UseTerms from "components/UseTerms";
import {
  default as formatString,
  default as formatStringByPattern,
} from "format-string-by-pattern";
import { isEnrollmentCoordinator, isVendor } from "helpers/constants";
import {
  atLeast18YearsOld,
  composeValidators,
  CPFGuardianRegister,
  emailValidation,
  noTextInput,
  noSpecialCharactersAndNumbers,
  phoneValidation,
  required,
  samePassword,
} from "helpers/fieldValidators";
import { deepCopy, getDomain, onlyNumbersFromString } from "helpers/helpers";
import HTTP_STATUS from "http-status-codes";
import { GuardianInterface, StudentInterface } from "interfaces/constants";
import { maskBuilder } from "pages/CORE/Profile/components/UserForm/helpers";
import { useState } from "react";
import { Field, Form } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { useHistory, useLocation } from "react-router-dom";
import { getToken, verifyUser } from "services/auth.service";
import { getAddressByCEP } from "services/cep.service";
import { checkValidReferenceCode } from "services/hubspot.service";
import {
  bondStudentToGuardian,
  RegisterNewGuardianType,
  sendNotificationsFirstAccessGuardian,
} from "services/users.service";
import loadingGif from "static/img/loading.gif";
import { HyperLinkModal, Step1Div } from "./style";
import { Storage } from "Storage";
import { useSetAtom } from "jotai";
import { authUserAtom } from "jotai/authUser";

interface GuardianSignUpFormInterface {
  SellerReference?: string;
  endpointSaveGuardian: (body: {
    body: {
      bairro: string;
      cep: string;
      cidade: string;
      complemento: string;
      data_nascimento: string;
      endereco: string;
      estado: string;
      nome: string;
      numero: string;
      celular: string;
    };
  }) => any;
  cpf?: string | undefined | null;
}

interface PreSignupInterface {
  nome: string;
  data_nascimento: string;
  cpf: string;
  rg: string;
  email: string;
  celular: string;
  cep: string;
  cidade: string;
  endereco: string;
  numero: string;
  estado: string;
  complemento: string;
  bairro: string;
  password?: string;
  confirm_password?: string;
  codigo_indicador?: string;
}

type StateType = {
  student: StudentInterface;
  guardian: GuardianInterface;
  newFinancial: boolean;
  newAddress: boolean;
};

export const GuardianSignUpForm = ({
  cpf,
  SellerReference,
  endpointSaveGuardian,
}: GuardianSignUpFormInterface) => {
  const location = useLocation<StateType>();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [toggleModalTerms, setToggleModalTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const setAuthUser = useSetAtom(authUserAtom);
  const role = Storage.props.role.get();

  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const studentId = urlParams.get("studentId");
  const outOfApp = urlParams.get("outofapp");

  const createGuard = async (response: any, data_: any) => {
    if (
      response.status === HTTP_STATUS.CREATED ||
      response.status === HTTP_STATUS.OK
    ) {
      const boundResponse = await bondStudentToGuardian({
        guardianId: response.data.id,
        studentId: location.state?.student.id || parseInt(studentId!),
        guard_type: data_.guard_type,
      });
      if (boundResponse) {
        if (boundResponse.status === HTTP_STATUS.CREATED) {
          if (boundResponse.data.guard_relation.length > 1) {
            toastSuccess("Responsável cadastrado com sucesso!");
          } else {
            sendNotificationsFirstAccessGuardian({
              body: { guardian_id: response.data.user_id, domain: getDomain() },
            })
              .then((response__) => {
                if (response__!.status === HTTP_STATUS.OK) {
                  toastSuccess(
                    "E-mail e SMS de primeiro acesso reenviados com sucesso"
                  );
                } else {
                  toastError(
                    "Erro ao reenviar e-mail e SMS de primeiro acesso ao responsável"
                  );
                }
              })
              .finally(() => {
                if (location.state?.newAddress) {
                  history.push({
                    pathname: `/responsavel/meus-alunos/documentacao`,
                    state: {
                      student: location.state?.student,
                      guardian: location.state?.guardian,
                      newGuardian: response.data.id,
                    },
                  });
                } else if (location.state?.newFinancial) {
                  history.push({
                    pathname: `/responsavel/meus-alunos/responsavel-financeiro`,
                    state: {
                      student: location.state?.student,
                      guardian: location.state?.guardian,
                    },
                  });
                } else {
                  history.push(`/aluno/editar?studentId=${studentId}`);
                }
              });
          }
        } else {
          if (
            response.data.non_field_errors &&
            response.data.non_field_errors.includes(
              "The fields student, guardian must make a unique set."
            )
          ) {
            toastError("Estudante já está vinculado a este responsável");
          } else {
            toastError("Ocorreu um erro ao vincular o estudante ao pai");
          }
          setLoading(false);
        }
      }
    } else if (response.status === HTTP_STATUS.BAD_REQUEST) {
      if (response.data.cpf) {
        toastError(
          "Esse CPF já está cadastrado. Por favor, verifique os dados novamente"
        );
      } else {
        toastError(
          "Erro ao tentar efetuar cadastro, tente novamente mais tarde"
        );
      }
      setLoading(false);
    } else {
      toastError(
        "Ocorreu um erro ao efetuar o cadastro, verifique as informações fornecidas e tente novamente"
      );
      setLoading(false);
    }
  };

  const onSubmit = async (data: PreSignupInterface) => {
    let data_ = deepCopy(data);
    const dateAux = new Date(data_.data_nascimento);
    data_.data_nascimento =
      dateAux.getFullYear() +
      "-" +
      (dateAux.getMonth() + 1) +
      "-" +
      dateAux.getDate();

    data_.email = data_.email.toLowerCase();
    data_.cpf = onlyNumbersFromString(data_.cpf);
    data_.celular = onlyNumbersFromString(data_.celular);
    if (data_.rg) data_.rg = onlyNumbersFromString(data_.rg);
    if (SellerReference) data_.seller_ref = SellerReference;
    if (data_.cep) {
      data_.cep = onlyNumbersFromString(data_.cep);
    }

    const response = await endpointSaveGuardian({
      body: data_,
    });

    if (response.status === HTTP_STATUS.CREATED) {
      toastSuccess("Cadastro realizado com sucesso");
      if (!role.id) {
        const userToken = await getToken({
          email: data_.email,
          password: data_.password,
        });
        if (userToken) {
          if (userToken.status === HTTP_STATUS.OK) {
            Storage.token.set(userToken.data);
            const userVerify = await verifyUser(data);
            if (userVerify) {
              if (userVerify.data && userVerify.status === HTTP_STATUS.OK) {
                setAuthUser(userVerify.data);
                Storage.props.authUser.set(userVerify.data);
                if (
                  userVerify.data &&
                  userVerify.data.role &&
                  userVerify.data.role.length === 1
                ) {
                  Storage.props.role.set(userVerify.data.role[0]);
                }
                history.push("/home");
              } else {
                toastError("Ocorreu um erro ao obter os dados do usuário");
              }
            }
          } else {
            if (userToken.status === HTTP_STATUS.UNAUTHORIZED) {
              toastError("E-mail e/ou senha inválidos");
            } else {
              toastError(
                "Ocorreu um erro ao obter seu acesso. Tente novamente mais tarde."
              );
            }
          }
        }
      } else {
        if (location.state?.student.id || studentId) {
          setLoading(true);
          createGuard(response, data_);
        } else {
          if (outOfApp) {
            history.push(
              `/responsavel/cadastro-aluno?guardianId=${response.data.id}&name=${response.data.nome}&guardianAuthId=${response.data.user_id}&outofapp=true`
            );
          } else {
            history.push(
              `/responsavel/cadastro-aluno?guardianId=${response.data.id}&name=${response.data.nome}&guardianAuthId=${response.data.user_id}`
            );
          }
        }
      }
    } else if (response.status === HTTP_STATUS.BAD_REQUEST) {
      if (response.data.cpf && response.data.email) {
        toastError(
          "CPF e e-mail já estão cadastrados na nossa base. Por favor, verifique os dados novamente."
        );
      } else if (response.data.cpf) {
        toastError(
          "CPF já está cadastrado na nossa base. Por favor, verifique os dados novamente."
        );
      } else if (response.data.email) {
        toastError(
          "E-mail já está cadastrado na nossa base. Por favor, verifique os dados novamente."
        );
      } else {
        toastError(
          "Ocorreu um erro ao efetuar o cadastro, verifique as informações fornecidas e tente novamente"
        );
      }
    } else {
      toastError(
        "Ocorreu um erro ao efetuar o cadastro, verifique as informações fornecidas e tente novamente"
      );
    }
  };

  return (
    <Step1Div>
      <hr />
      <div className="container">
        <h1>Dados pessoais do responsável</h1>
        <p className="mb-0">
          Precisamos de alguns dados do responsável para iniciar o cadastro
        </p>
        <Form
          initialValues={{
            cpf,
            guard_type: location.state?.newAddress
              ? "1"
              : location.state?.newFinancial
              ? "2"
              : undefined,
          }}
          onSubmit={onSubmit}
        >
          {({ form, handleSubmit, submitting, values }) => (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-sm-6 col-12">
                  <Field
                    component={TextInput}
                    name="nome"
                    type="text"
                    placeholder=" "
                    label="Nome completo *"
                    maxLength="100"
                    validate={composeValidators(
                      required,
                      noSpecialCharactersAndNumbers
                    )}
                    required
                  />
                </div>
                <div className="col-sm-6 col-12">
                  <Field
                    component={TextInput}
                    name="email"
                    type="email"
                    placeholder=" "
                    label="E-mail *"
                    maxLength="255"
                    validate={composeValidators(required, emailValidation)}
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-12">
                  <Field
                    component={TextInput}
                    name="celular"
                    type="text"
                    placeholder=" "
                    label="Celular *"
                    validate={composeValidators(required, phoneValidation)}
                    required
                  />
                </div>
                <div className="col-sm-6 col-12">
                  <Field
                    component={TextInput}
                    name="cpf"
                    type="text"
                    placeholder=" "
                    label="CPF *"
                    parse={formatString("999.999.999-99")}
                    validate={composeValidators(required, CPFGuardianRegister)}
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6 col-12">
                  <Field
                    component={TextInput}
                    name="rg"
                    type="text"
                    placeholder=" "
                    label="RG"
                    maxLength="15"
                  />
                </div>
                <div className="col-sm-3 col-12">
                  <Field
                    component={CalendarComponent}
                    name="data_nascimento"
                    placeholder="Data de nascimento *"
                    validate={composeValidators(required, atLeast18YearsOld)}
                    required
                  />
                </div>
                <div className="col-sm-3 col-12">
                  <Field
                    component={TextInput}
                    name="codigo_indicador"
                    type="text"
                    label="Código do indicador"
                    validate={composeValidators(noTextInput)}
                    minLength="5"
                    maxLength="10"
                  />
                  <OnChange name="codigo_indicador">
                    {async (referenceCode) => {
                      if (referenceCode.length >= 5) {
                        const response = await checkValidReferenceCode(
                          referenceCode
                        );
                        if (response) {
                          if (response.status === HTTP_STATUS.OK) {
                            toastSuccess("Código do indicador encontrado!");
                          }
                          if (response.status === HTTP_STATUS.BAD_REQUEST) {
                            toastError("Não encontramos o código do indicador");
                          }
                        }
                      }
                    }}
                  </OnChange>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-3 col-12">
                  <Field
                    component={TextInput}
                    name="cep"
                    type="text"
                    placeholder=" "
                    label="CEP *"
                    parse={formatStringByPattern("99999-999")}
                    validate={composeValidators(required)}
                  />
                  <OnChange name="cep">
                    {async (value, previous) => {
                      if (value.length === 9) {
                        const response = await getAddressByCEP(value);
                        if (response) {
                          if (response.status === HTTP_STATUS.OK) {
                            if (response.data.resultado === "0") {
                              toastError("CEP não encontrado");
                            } else {
                              form.change(
                                "endereco",
                                response.data.tipo_logradouro +
                                  " " +
                                  response.data.logradouro
                              );
                              form.change("bairro", response.data.bairro);
                              form.change("cidade", response.data.cidade);
                              form.change("estado", response.data.uf);
                            }
                          }
                        }
                      }
                    }}
                  </OnChange>
                </div>
                <div className="col-sm-9 col-12">
                  <Field
                    component={TextInput}
                    label="Endereço *"
                    name="endereco"
                    placeholder="Endereço *"
                    type="text"
                    maxLength="100"
                    validate={required}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-2 col-12">
                  <Field
                    component={TextInput}
                    name="numero"
                    placeholder=" "
                    label="Número *"
                    maxLength="8"
                    validate={required}
                  />
                </div>
                <div className="col-sm-3 col-12">
                  <Field
                    component={TextInput}
                    label="Complemento "
                    name="complemento"
                    placeholder=" "
                    type="text"
                    maxLength="60"
                  />
                </div>
                <div className="col-sm-7 col-12">
                  <Field
                    component={TextInput}
                    name="bairro"
                    placeholder=" "
                    type="text"
                    label="Bairro *"
                    maxLength="80"
                    validate={required}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-10 col-12">
                  <Field
                    component={TextInput}
                    name="cidade"
                    placeholder=" "
                    type="text"
                    label="Cidade *"
                    maxLength="32"
                    validate={required}
                  />
                </div>
                <div className="col-sm-2 col-12">
                  <Field
                    component={TextInput}
                    name="estado"
                    placeholder=" "
                    label="UF *"
                    maxLength="2"
                    validate={required}
                  />
                </div>
              </div>

              {false && (
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <Field
                      component={TextInput}
                      name="password"
                      placeholder=" "
                      type={showPassword ? "text" : "password"}
                      label="Senha"
                      showPassword={showPassword}
                      setShowPassword={setShowPassword}
                      validate={required}
                      required
                    />
                  </div>
                  <div className="col-sm-6 col-12">
                    <Field
                      component={TextInput}
                      name="confirm_password"
                      placeholder=" "
                      type={showConfirmPassword ? "text" : "password"}
                      label="Confirmar senha"
                      showPassword={showConfirmPassword}
                      setShowPassword={setShowConfirmPassword}
                      validate={composeValidators(
                        required,
                        samePassword(values.password)
                      )}
                      required
                    />
                  </div>
                </div>
              )}
              {/**
               * Pseudo feature-flag abaixo.
               */}
              {false && (
                <>
                  <div className="row mt-5 mb-4">
                    <div className="col-sm-1 col-2">
                      <Field
                        component={Checkbox}
                        name="allow_other_guardians_to_edit"
                      />
                    </div>
                    <div className="col-sm-11 col-10 pl-0 my-auto">
                      Permitir que outros responsáveis da matrícula administre
                      minhas informações pessoais
                    </div>
                  </div>
                  <div className="row mb-5">
                    <div className="col-sm-1 col-2">
                      <Field
                        component={Checkbox}
                        name="policy-agree"
                        required
                      />
                    </div>
                    <div className="col-sm-11 col-10 pl-0 my-auto">
                      Eu li e aceito os{" "}
                      <HyperLinkModal
                        type="button"
                        onClick={() => setToggleModalTerms(!toggleModalTerms)}
                      >
                        Termos de uso
                      </HyperLinkModal>{" "}
                      e{" "}
                      <HyperLinkModal
                        type="button"
                        onClick={() => setToggleModal(!toggleModal)}
                      >
                        Política de Privacidade
                      </HyperLinkModal>{" "}
                      da Escola Mais
                    </div>
                  </div>
                  <Modal setToggleModal={setToggleModal} isOpen={toggleModal}>
                    <PoliticaPrivacidade />
                  </Modal>
                  <Modal
                    setToggleModal={setToggleModalTerms}
                    isOpen={toggleModalTerms}
                  >
                    <UseTerms />
                  </Modal>
                </>
              )}
              {((location.state?.student.id && !location.state?.newAddress) ||
                studentId ||
                (location.state?.student.id &&
                  !location.state?.newFinancial)) && (
                <>
                  <p className="mt-3">Este é um responsável:</p>
                  <div className="row align-itens-center justify-content-center">
                    <div className="col-sm-1 col-2">
                      <Field
                        component={RadioButton}
                        name="guard_type"
                        type="radio"
                        value="2"
                        required
                        disabled={
                          location.state?.newAddress ||
                          location.state?.newFinancial
                        }
                      />
                    </div>
                    <div className="col-sm-11 col-10 pl-0 my-auto">
                      <strong>Financeiro e Pedagógico</strong>
                    </div>
                  </div>
                  <div className="row align-itens-center justify-content-center">
                    <div className="col-sm-1 col-2">
                      <Field
                        component={RadioButton}
                        name="guard_type"
                        type="radio"
                        value="1"
                        required
                        disabled={
                          location.state?.newAddress ||
                          location.state?.newFinancial
                        }
                      />
                    </div>
                    <div className="col-sm-11 col-10 pl-0 my-auto">
                      <strong>Pedagógico</strong>
                    </div>
                  </div>
                </>
              )}
              <div className="row">
                <div className="col-sm-3 offset-sm-9 col-12">
                  <Button type="submit" disabled={submitting || loading}>
                    {(!submitting || !loading) &&
                      !isVendor(role) &&
                      !isEnrollmentCoordinator(role) &&
                      "Próximo"}
                    {(!submitting || !loading) &&
                      (isVendor(role) || isEnrollmentCoordinator(role)) &&
                      "Cadastrar"}
                    {(submitting || loading) && (
                      <img height="25" src={loadingGif} alt="loading" />
                    )}
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Form>
      </div>
    </Step1Div>
  );
};
