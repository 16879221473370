import styled from "styled-components";
import * as colors from "styles/colors";

interface InputStyledProps {
  width?: string;
  height?: string;
  marginLeftInput?: string;
  marginInputRight?: string;
  error?: boolean;
  tableInput?: boolean;
  fontSize?: string;
  fullBorder?: boolean;
}

export const InputContainerStyled = styled.div<InputStyledProps>`
  position: relative;
  ${(props) => !props.tableInput && "padding-top: 13px"}
  ${(props) => !props.tableInput && "margin-top: 0.5em"}
  margin-bottom: ${(props) => (props.error ? "1.5em" : "0")};

  div.inputLabel {
    display: flex;
    flex-direction: column;
  }

  input {
    outline: none;
    font-size: 14px;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -webkit-appearance: none;
    border-radius: 0;
    &:disabled {
      background-color: #d3d3d3;
    }
  }

  ${(props) => !props.fullBorder && ``}

  label {
    color: ${colors.NEW_GRAY_TWO};
    margin-bottom: 0;
    font-size: 14px;
  }

  // Caso um dia queiram usar para efeito
  // para que a primeira label suba ao clicar no input

  /* input:focus + span + label,
  input:focus + label,
  input:not(:placeholder-shown) + label,
  input:not(:placeholder-shown) + span + label,
  input:-webkit-autofill + label,
  input:-webkit-autofill + span + label {
    font-size: 10px;
    margin-top: -2em;
  } */

  input.inputField {
    margin-left: ${(props) =>
      props.marginLeftInput ? props.marginLeftInput : "0"};

    margin-right: ${(props) =>
      props.marginInputRight ? props.marginInputRight : "0"};

    /* margin-top: 0; */
    padding-left: 0.7em;
    ${(props) => !props.tableInput && "padding-top: 20px"}
    font-size: ${(props) => (props.fontSize ? props.fontSize : "12px")};

    font-weight: normal;
    background-color: white;
    width: ${(props) => (props.width ? props.width : "100%")};
    height: ${(props) =>
      props.height ? props.height : props.tableInput ? "" : "50px"};

    border: none;
    border-bottom: 2px solid ${colors.NEW_GRAY_ONE};

    ${(props) => props.fullBorder && `border: 2px solid ${colors.GRAY_SCALE};`}

    ::placeholder {
      color: ${colors.NEW_GRAY_ONE};
    }
  }

  label.label {
    color: ${colors.NEW_GRAY_TWO};
    font-size: 12px;
  }

  label.errorLabel {
    font-size: 12px;
    font-weight: normal;
    color: #b00020;
    width: 100%;
    top: 5.2em;
  }
`;
