// React
import { useEffect, useState } from "react";
// Style
import * as S from "./style";
// Libs
import { Field, Form } from "react-final-form";
import { useHistory } from "react-router-dom";
import HTTP_STATUS from "http-status-codes";
// Components
import { Button, ModalInfo, SelectInput, TextInput } from "components";
import { toastError, toastSuccess } from "components/Toast";
// Helpers
import {
  composeValidators,
  noSpecialCharactersAndNumbers,
  required,
} from "helpers/fieldValidators";
// Img
import loadingGif from "static/img/loading.gif";
// Interfaces
import { ICivilStatus, IKinship } from "interfaces/guardian";
import {
  IFormData,
  IModalGuardianData,
  IPatchGuardianData,
  IPatchStudentData,
} from "./interface";
// Services
import {
  getCivilStatus,
  getKinship,
  updateGuardian,
} from "services/guardian.service";
import { updateStudent } from "services/student.service";

export const ModalGuardianData = ({
  isOpen,
  setIsClose,
  student,
  guardian,
}: IModalGuardianData) => {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [kinship, setKinship] = useState<IKinship>();
  const [civilStatus, setCivilStatus] = useState<ICivilStatus>();

  useEffect(() => {
    (async () => {
      try {
        const fetchKinship = await getKinship();
        if (fetchKinship && fetchKinship.status === HTTP_STATUS.OK) {
          setKinship(fetchKinship.data);
        }

        const fetchCivilStatus = await getCivilStatus();
        if (fetchCivilStatus && fetchCivilStatus.status === HTTP_STATUS.OK) {
          setCivilStatus(fetchCivilStatus.data);
          setLoading(false);
        }
      } catch (err) {
        setError(true);
        setLoading(false);
      }
    })();
  }, []);

  const checkSelectOption = (value: string) => {
    if (value === "outro") {
      return true;
    }
    return false;
  };

  const onSubmit = async (values: IFormData) => {
    const guardianBody: IPatchGuardianData = {
      estado_civil: values.civilStatus,
      profissao: values.occupation,
      nacionalidade: checkSelectOption(values.citizenshipGuardianSelect)
        ? values.citizenshipGuardian!
        : values.citizenshipGuardianSelect,
      graus_parentesco: [
        {
          grau_parentesco: checkSelectOption(values.kinshipSelect)
            ? values.kinship
            : values.kinshipSelect,
          estudante_id: student.id,
        },
      ],
    };

    const studentBody: IPatchStudentData = {
      nacionalidade: checkSelectOption(values.citizenshipStudentSelect)
        ? values.citizenshipStudent!
        : values.citizenshipStudentSelect,
    };

    try {
      const setGuardianData = await updateGuardian({
        guardianId: guardian.id,
        body: guardianBody,
      });
      if (setGuardianData && setGuardianData.status === HTTP_STATUS.OK) {
        toastSuccess("Dados do responsável atualizados com sucesso!");
      }

      const setStudentData = await updateStudent({
        studentId: student.id,
        body: studentBody,
      });

      if (setStudentData && setStudentData.status === HTTP_STATUS.OK) {
        toastSuccess("Dados do estudante atualizados com sucesso!");
      }

      history.push({
        pathname: `/contrato/assinatura-contrato`,
        state: {
          student,
          guardian,
        },
      });
    } catch (err) {
      toastError("Erro ao atualizar os dados.");
    }
  };

  if (loading) {
    return (
      <div className="d-flex p-1">
        Carregando dados <img height="25" src={loadingGif} alt="loading" />
      </div>
    );
  }

  if (error && !loading) {
    return <div className="p-1">Erro ao recuperar dados do banco.</div>;
  }

  return (
    <S.Container>
      <ModalInfo isOpen={isOpen} title="Dados complementares" width="376px">
        <Form
          initialValues={{
            citizenshipGuardianSelect: "brasileiro(a)",
            citizenshipStudentSelect: "brasileiro(a)",
          }}
          onSubmit={onSubmit}
        >
          {({ form, handleSubmit, submitting, values }) => (
            <form onSubmit={handleSubmit}>
              <Field
                component={TextInput}
                name="occupation"
                type="text"
                label="Profissão*"
                placeholder=" "
                validate={composeValidators(
                  required,
                  noSpecialCharactersAndNumbers
                )}
                required
                hideCheck
              />
              <S.SelectContainer>
                <label>Estado Civil*</label>
                <Field component={SelectInput} name="civilStatus" required>
                  <option value="">Selecione</option>
                  {civilStatus?.results.map((status, index) => {
                    return (
                      <option value={status.value} key={index}>
                        {status.label}
                      </option>
                    );
                  })}
                </Field>
              </S.SelectContainer>

              <S.SelectContainer>
                <label>Grau de parentesco*</label>
                <Field component={SelectInput} name="kinshipSelect" required>
                  <option value="">Selecione</option>
                  {kinship?.results.map((kinship, index) => {
                    return (
                      <option value={kinship.value} key={index}>
                        {kinship.label}
                      </option>
                    );
                  })}
                </Field>
              </S.SelectContainer>

              {values.kinshipSelect === "outro" && (
                <>
                  <Field
                    component={TextInput}
                    name="kinship"
                    type="text"
                    label=""
                    placeholder=" "
                    maxLength="100"
                    hideCheck
                    required
                  />
                  <span>Insira o grau de parentesco</span>
                </>
              )}

              <S.SelectContainer>
                <label>Nacionalidade responsável*</label>
                <Field
                  component={SelectInput}
                  name="citizenshipGuardianSelect"
                  required
                >
                  <option value="brasileiro(a)">Brasileiro(a)</option>
                  <option value="outro">Outro</option>
                </Field>
              </S.SelectContainer>

              {values.citizenshipGuardianSelect === "outro" && (
                <>
                  <Field
                    component={TextInput}
                    name="citizenshipGuardian"
                    type="text"
                    label=""
                    placeholder=" "
                    maxLength="100"
                    hideCheck
                    required
                  />
                  <span>Insira a nacionalidade do estudante</span>
                </>
              )}

              <S.SelectContainer>
                <label>Nacionalidade estudante*</label>
                <Field
                  component={SelectInput}
                  name="citizenshipStudentSelect"
                  required
                >
                  <option value="brasileiro(a)">Brasileiro(a)</option>
                  <option value="outro">Outros</option>
                </Field>
              </S.SelectContainer>

              {values.citizenshipStudentSelect === "outro" && (
                <>
                  <Field
                    component={TextInput}
                    name="citizenshipStudent"
                    type="text"
                    label=""
                    placeholder=" "
                    maxLength="100"
                    hideCheck
                    required
                  />
                  <span>Insira a nacionalidade do estudante</span>
                </>
              )}

              <div className="buttons">
                <Button
                  type="button"
                  onClick={() => {
                    setIsClose(false);
                  }}
                  className="pink outline"
                >
                  Voltar
                </Button>
                <Button type="submit">
                  {!submitting && "Salvar"}
                  {submitting && (
                    <img height="25" src={loadingGif} alt="loading" />
                  )}
                </Button>
              </div>
            </form>
          )}
        </Form>
      </ModalInfo>
    </S.Container>
  );
};
