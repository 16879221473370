/* eslint-disable no-sequences */
/* eslint-disable react-hooks/rules-of-hooks */
import { useRef, useState, useEffect, ChangeEvent } from "react";
import { useForm, SubmitHandler } from "react-hook-form";

import * as S from "./style";
import * as I from "./types";

type Inputs = {
  search: string;
};

export const InputSearch: React.FC<I.InputSearchProps> = ({
  label,
  onSubmit,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();
  const onFormSubmit: SubmitHandler<Inputs> = (data) => onSubmit(data);

  return (
    <S.Container>
      <S.Form onSubmit={handleSubmit(onFormSubmit)}>
        <S.Label>{label}</S.Label>
        <S.InputSearch {...register("search", { required: true })} />
        {errors.search && <S.Error>Este campo é obrigatório</S.Error>}
      </S.Form>
    </S.Container>
  );
};

export const InputOnBlur: React.FC<I.InputProps> = ({
  label,
  onBlur,
  onClear,
  onChange,
  width,
}) => {
  const [focus, setFocus] = useState<boolean>(false);

  const refSearch = useRef<HTMLInputElement | null>(
    null
  ) as React.MutableRefObject<HTMLInputElement>; // eslint-disable-line no-eval

  const handleClick = () => {
    onClear();
    refSearch.current.value = "";
  };

  return (
    <S.Container width={width}>
      <S.Form>
        <S.Label>{label}</S.Label>
        <S.Wrapper focus={focus} className="iconSearch">
          <S.Input
            ref={refSearch}
            onBlur={(e) =>
              e.target.value.length > 2 && onBlur
                ? (onBlur(e), setFocus(false))
                : e.target.value.length === 0 && (onClear(), setFocus(false))
            }
            onChange={onChange}
            autoFocus
            onFocus={() => setFocus(true)}
          />
          {refSearch?.current?.value?.length > 2 && (
            <S.ButtonClear onClick={() => handleClick()} />
          )}
        </S.Wrapper>
      </S.Form>
    </S.Container>
  );
};
