import { Button } from "components";
import { ExportDeEnviosBox } from "./style";
import { CalendarComponent } from "components/Calendar";
import { Field, Form } from "react-final-form";
import {
  ExportTotvsSuccess,
  ExportTotvsError,
} from "services/totvsExport.service";
import { useContext, useState, useRef } from "react";
import HTTP_STATUS from "http-status-codes";
import { CSVLink } from "react-csv";
import loadingGif from "static/img/loading.gif";
import { toastError, toastSuccess, toastWarning } from "components/Toast";
import { composeValidators, required } from "helpers/fieldValidators";
import moment from "moment";

export const ExportDeEnviosParaTotvs = () => {
  const [exportData, setExportData] = useState<any>();
  const csvInstance = useRef<any | null>(null);

  const onSubmitSuccess = async (values: any) => {
    if (moment(values.exportSuccess).isSameOrBefore(new Date())) {
      const getExportSuccess = await ExportTotvsSuccess({
        query: moment(values.exportSuccess).format("YYYY-MM-DD"),
      });
      if (getExportSuccess && getExportSuccess.status === HTTP_STATUS.OK) {
        setExportData(getExportSuccess);
        setTimeout(() => {
          csvInstance.current.link.click();
          setExportData(null);
        });
        toastSuccess("O Export de Dados Enviados foi realizado com sucesso");
      } else if (
        getExportSuccess &&
        getExportSuccess.status === HTTP_STATUS.NOT_FOUND
      ) {
        setExportData(getExportSuccess);
        toastWarning("Não foi encontrado nenhum export nesta data");
      } else {
        toastError(
          "Ocorreu um erro ao recuperar os dados. Tente novamente mais tarde!"
        );
      }
    } else {
      toastWarning("A data escolhida não pode ser maior que o dia de hoje.");
    }
  };

  const onSubmitError = async (values: any) => {
    if (moment(values.exportError).isSameOrBefore(new Date())) {
      const getExportError = await ExportTotvsError({
        query: moment(values.exportError).format("YYYY-MM-DD"),
      });
      if (getExportError && getExportError.status === HTTP_STATUS.OK) {
        setExportData(getExportError);
        setTimeout(() => {
          csvInstance.current.link.click();
          setExportData(null);
        });
        toastSuccess("O Export de Erros foi realizado com sucesso");
      } else if (
        getExportError &&
        getExportError.status === HTTP_STATUS.NOT_FOUND
      ) {
        setExportData(getExportError);
        toastWarning("Não foi encontrado nenhum export nesta data");
      } else {
        toastError(
          "Ocorreu um erro ao recuperar os dados. Tente novamente mais tarde!"
        );
      }
    } else {
      toastWarning("A data escolhida não pode ser maior que o dia de hoje.");
    }
  };

  return (
    <ExportDeEnviosBox className="container">
      <div>
        <div className="text-center mb-5">
          <h1>Exportar TOTVS</h1>
          <p>
            Selecione abaixo os botões e a data para realizar o export dos
            relatórios Totvs
          </p>
        </div>
        {exportData ? (
          <CSVLink
            data={exportData.data}
            headers={exportData.headers}
            filename="export.csv"
            ref={csvInstance}
          />
        ) : undefined}
        <div className="row justify-content-center">
          <div className="col-sm-3 col-12 mt-3">
            <h2>Exportar dados enviados</h2>
            <Form onSubmit={onSubmitSuccess}>
              {({ form, handleSubmit, submitting, values }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    component={CalendarComponent}
                    name="exportSuccess"
                    placeholder="Insira a data"
                    validate={composeValidators(required)}
                  />
                  <Button disabled={submitting}>
                    {!submitting && "Export Dados"}
                    {submitting && (
                      <img height="25" src={loadingGif} alt="loading" />
                    )}
                  </Button>
                </form>
              )}
            </Form>
          </div>
          <div className="col-sm-3 col-12 mt-3">
            <h2>Exportar erros</h2>
            <Form onSubmit={onSubmitError}>
              {({ form, handleSubmit, submitting, values }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    component={CalendarComponent}
                    name="exportError"
                    placeholder="Insira a data"
                    validate={composeValidators(required)}
                  />
                  <Button disabled={submitting}>
                    {!submitting && "Export Erros"}
                    {submitting && (
                      <img height="25" src={loadingGif} alt="loading" />
                    )}
                  </Button>
                </form>
              )}
            </Form>
          </div>
        </div>
      </div>
    </ExportDeEnviosBox>
  );
};

export default ExportDeEnviosParaTotvs;
