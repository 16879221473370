import {
  onlyNumbersFromString,
  stringToMaskCEP,
  stringToMaskCPF,
} from "helpers/helpers";
import { GetCXUserJson } from "interfaces/constants";
import { UserFormDataInterface } from "services/users.service";

export const formatUserFormInitialData = (data: GetCXUserJson | undefined) => {
  let dataFormatted = {
    name: "",
    birth_date: "",
    cpf: "",
    rg: "",
    email: "",
    phone: "",
    zip_code: "",
    city: "",
    address: "",
    number: "",
    uf: "",
    complement: "",
    neighborhood: "",
  };
  dataFormatted.name = data?.personal.name || "";
  dataFormatted.birth_date = data?.personal.birth_date || "";
  dataFormatted.cpf = data?.personal.cpf
    ? stringToMaskCPF(data.personal.cpf)
    : "";
  dataFormatted.rg = data?.personal.rg || "";
  dataFormatted.email = data?.contact.email || "";
  dataFormatted.phone = data?.contact.phone || "";
  dataFormatted.zip_code = data?.location.zip_code
    ? stringToMaskCEP(data?.location.zip_code)
    : "";
  dataFormatted.city = data?.location.city || "";
  dataFormatted.address = data?.location.address || "";
  dataFormatted.complement = data?.location.complement || "";
  dataFormatted.number = data?.location.number || "";
  dataFormatted.uf = data?.location.uf || "";
  dataFormatted.neighborhood = data?.location.neighborhood || "";
  return dataFormatted;
};

export const formatUpdateGuardianPayload = (payload: UserFormDataInterface) => {
  payload.cpf = onlyNumbersFromString(payload.cpf);
  payload.phone = onlyNumbersFromString(payload.phone);
  payload.zip_code = onlyNumbersFromString(payload.zip_code);
  return payload;
};

export const setUserAfterUpdate = (
  user: GetCXUserJson | undefined,
  data: UserFormDataInterface
) => {
  if (user) {
    user.personal.name = data.name;
    user.personal.birth_date = data.birth_date;
    user.personal.cpf = data.cpf;
    user.personal.rg = data.rg;
    user.contact.email = data.email;
    user.contact.phone = data.phone;
    user.location.zip_code = data.zip_code;
    user.location.address = data.address;
    user.location.number = data.number;
    user.location.neighborhood = data.neighborhood;
    user.location.uf = data.uf;
    user.location.city = data.city;
  }
  return user;
};

const ajusta = (v: string) => {
  const digitos = !v ? "" : v.replace(/[^\d]/g, "");
  if (!digitos || digitos.length < 10) return v;
  const corte = digitos.length === 10 ? 6 : 7;
  const max = digitos.length > 11 ? 11 : digitos.length;
  return `(${digitos.substring(0, 2)}) ${digitos.substring(
    2,
    corte
  )}-${digitos.substring(corte, max)}`;
};

export const maskBuilder = (v: string) => {
  if (!v || v.length === 0) return "(99) 99999-9999";
  const a = ajusta(v);
  return a.length >= 6 && a[5] === "9" ? "(99) 99999-9999" : "(99) 9999-9999";
};
