import styled from "styled-components";

export const Calendar = styled.div`
  /* .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
  .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left),
  .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
  .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap,
  .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error)
    .e-input-in-wrap {
    height: 65px;
    border-color: rgb(204 204 204);
  }

  input.e-input,
  textarea.e-input,
  .e-input-group,
  .e-input-group.e-control-wrapper {
    font-size: inherit !important;
    font-weight: inherit;
    font-family: inherit;
    padding: 30px 0 0 0;
  } */
`;
export const LabelFloat = styled.div`
  position: relative;
  padding-top: 13px;
  margin-top: 1em;

  &.invalid {
    margin-bottom: 2em;
  }

  span.e-input-group {
    input {
      outline: none !important;
      font-size: 16px !important;
      transition: all 0.3s ease-out !important;
      -webkit-transition: all 0.3s ease-out !important;
      -moz-transition: all 0.3s ease-out !important;
      -webkit-appearance: none !important;
      border-radius: 0 !important;
      &:disabled {
        background-color: #d3d3d3 !important;
      }
    }
  }

  label {
    color: #666666;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0;
    font-size: 16px;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;

    &.invalid {
      color: #b00020;
    }
  }

  input:focus + span + label,
  input:focus + label,
  input:not(:placeholder-shown) + label,
  input:not(:placeholder-shown) + span + label,
  input:-webkit-autofill + label,
  input:-webkit-autofill + span + label {
    font-size: 12px;
    margin-top: -2em;
  }
`;

export const InputField = styled.input`
  font-size: 14px;
  font-weight: normal;
  background-color: white;
  width: 100%;
  height: 38px;
  color: #42474a;
  border: none;
  border-bottom: 1px solid #cccccc;
  padding-left: 0.5em;
  :focus {
    outline: none;
    border-bottom: 2px solid #1a1a1a;
  }
  ::placeholder {
    color: transparent;
  }
  &.invalid {
    border-bottom: 2px solid #b00020;
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  :[type=checkbox]+label  {
    display: block;
    margin: 0.2em;
    cursor: pointer;
    padding: 0.2em;
  }
`;

export const EyeIcon = styled.span`
  cursor: pointer;
  width: 17.5px;
  border-bottom: 1px solid #cccccc;
`;

export const Label = styled.label`
  padding-top: 20px;
  color: #000;
  font-size: 14px;
`;

export const ErrorLabel = styled.label`
  font-size: 12px !important;
  font-weight: normal;
  color: #b00020 !important;
  width: 100%;
  top: 4.5em !important;
`;

export const CalendarDisabledDiv = styled.div`
  margin-top: 8px;
  margin-bottom: 0;
  color: #000000;
  font-size: 12px;
  p.subContent {
    font-size: 16px;
    font-weight: normal;
    background-color: white;
    width: 100%;
    height: 40px;
    color: #000000;
    border: none;
    background-color: #d3d3d3;
    padding-left: 0.5em;
    padding-top: 10px;
  }
`;
