import styled from "styled-components";

export const StepsDiv = styled.div`
  div.step-text {
    font-size: 12px;
    text-transform: uppercase;
  }

  .progress-segment {
    display: flex;
    width: 90%;
    margin-left: 5%;
  }

  .item {
    width: 100%;
    background-color: #cccccc;
    margin-right: 8px;
    height: 5px;
    border-radius: 5%;

    &.active {
      background-color: #ec008c;
    }
  }
`;
