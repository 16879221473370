import styled from "styled-components";

export const PlaymentPlanContainer = styled.div`
  padding: ${({ theme }) => theme.space[12]} 97px; // TODO: nao tem na lib

  h2 {
    font-size: ${({ theme }) => theme.fontSizes.web.h2};
    font-weight: 600; // TODO: nao tem na lib
    line-height: 48px; // TODO: nao tem na lib
    color: ${({ theme }) => theme.colors.primaryDefault};

    margin-bottom: ${({ theme }) => theme.space[6]};
  }

  form {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.space[6]} 0;

    button.transitionButton {
      width: 300px;
      height: 50px; // TODO: nao tem na lib

      padding: 0;
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: ${({ theme }) => theme.space[8]};

      gap: 0 ${({ theme }) => theme.space[2]};

      button:last-of-type {
        width: 309px;
        div {
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  @media (max-width: 360px) {
    padding: ${({ theme }) => theme.space[2]};

    form {
      button.transitionButton {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }

      .buttons {
        button {
          width: 50%;
        }
      }
    }
  }
`;

export const PlanContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;

export const FormContainer = styled.div`
  h4 {
    font-size: ${({ theme }) => theme.fontSizes.web.h4};
    font-weight: 600; // TODO: nao tem na lib
    line-height: 30px; // TODO: nao tem na lib
    margin-top: ${({ theme }) => theme.space[4]};

    display: flex;
    align-items: center;
    gap: 0 ${({ theme }) => theme.space[1]};

    img {
      cursor: pointer;
    }
  }

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[2]} 0;

  .inLine {
    display: flex;
    align-items: center;
    gap: 0 ${({ theme }) => theme.space[4]};
  }

  @media (max-width: 360px) {
    .inLine {
      flex-wrap: wrap;
      gap: ${({ theme }) => theme.space[2]};
    }
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h4 {
    ${({ theme }) => theme.fontSizes.web.h4}
    text-align: center;
  }

  div {
    margin: ${({ theme }) => theme.space[4]};
  }
`;
