// React
import { useContext, useEffect, useState } from "react";
// Style
import { SendDocumentsStyle } from "./style";
// Type
import { SendDocumentsInterface } from "./type";
// Components
import { ModalTakeAPicture } from "./components/modal";
import Modal from "components/Modal";
import {
  BorderlessButtonIcon,
  CheckboxInput,
  InputFile,
  Text,
  Title,
} from "components/atoms";
// Libs
import { mdiPlus } from "@mdi/js";
import { useTranslation } from "react-i18next";
import HTTP_STATUS from "http-status-codes";
import { toastError, toastSuccess, toastWarning } from "components/Toast";
// Services
// ATUALIZAR PARA NOVO ENDPOINT DEPOIS QUE PRONTO
import { uploadDocuments } from "services/documents.service";

export const SendDocuments = ({
  type,
  documentName,
  documentDescription,
  rg,
  enrollment,
  documentType,
  userId,
  userName,
}: SendDocumentsInterface) => {
  const [toggleModal, setToggleModal] = useState(false);
  const [file, setFile] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [uploadFile, setUploadFile] = useState(false);
  const { t } = useTranslation();
  const TRANSLATE = "components.organisms.sendDocuments";

  const onChange = (event: any) => {
    const file = event.target.files[0];
    if (file.size < 15000000) {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const binaryString = e.target.result;
          setFile(`data:${file.type};base64,${btoa(binaryString)}`);
          setFileName(file.name);
          setUploadFile(true);
        };
        reader.readAsBinaryString(file);
      }
    } else {
      toastWarning(t(`${TRANSLATE}.toastWarningFile`));
    }
  };

  const onSubmit = async () => {
    if (file) {
      const uploadDocument = await uploadDocuments({
        enrollment,
        document_type: documentType,
        file_name: fileName ? fileName : "captura.jpg",
        user_id: userId,
        user_name: userName,
        document_file: file,
      });
      if (uploadDocument) {
        if (uploadDocument.status === HTTP_STATUS.CREATED) {
          toastSuccess(t(`${TRANSLATE}.toastUploadSuccess`));
        } else if (uploadDocument.status === HTTP_STATUS.BAD_REQUEST) {
          toastError(uploadDocument.data.detail);
        } else {
          toastError(t(`${TRANSLATE}.toastUploadError`));
        }
        setFileName(null);
        setFile(null);
        setUploadFile(false);
      }
    }
  };

  useEffect(() => {
    if (file && uploadFile) {
      onSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file, uploadFile]);

  return (
    <SendDocumentsStyle type={type} rg={rg}>
      <Title scaleGray fontSize="14px" className="title">
        {documentName}
      </Title>
      <Text
        scaleGray
        fontSize="10px"
        fontWeight="400"
        lineHeight="15px"
        className="subtitle"
      >
        {documentDescription}
      </Text>
      {rg && (
        <div className="checkbox">
          <CheckboxInput value="alow" name="checkbox" />
          <Text
            scaleGray
            fontSize="12px"
            lineHeight="18px"
            fontWeight="400"
            className="checkboxText"
          >
            {t(`${TRANSLATE}.checkBoxText`)}
          </Text>
        </div>
      )}
      <div className="buttons">
        <BorderlessButtonIcon
          icon={mdiPlus}
          width="55%"
          onClick={() => setToggleModal(true)}
        >
          {t(`${TRANSLATE}.takeAPicture`)}
        </BorderlessButtonIcon>
        <InputFile
          icon={mdiPlus}
          fileName={t(`${TRANSLATE}.upload`)}
          onChange={onChange}
        />
      </div>
      <Modal setToggleModal={setToggleModal} isOpen={toggleModal}>
        <ModalTakeAPicture
          setFile={setFile}
          file={file}
          setToggleModal={setToggleModal}
          onSubmit={onSubmit}
        />
      </Modal>
    </SendDocumentsStyle>
  );
};
