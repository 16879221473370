import styled from "styled-components";
import * as colors from "styles/colors";

interface RadioButtonInterface {
  disabled?: boolean;
}

export const RadioStyled = styled.div<RadioButtonInterface>`
  label.container {
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    padding: 0;
    margin-top: 1em;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .container input.RadioBrowser {
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    display: none;
  }

  div.check {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    width: 20px;
    height: 20px;

    border-radius: 50%;
    background-color: ${(props) =>
      props.disabled ? `${colors.GRAY_SCALE_TWO}` : "#FFFFFF"};

    border: 2px solid
      ${(props) =>
        props.disabled ? `${colors.GRAY_SCALE}` : `${colors.NEW_PURPLE}`};
  }

  .container input.RadioBrowser:checked ~ .check {
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }

  .container .check:after {
    padding: 3px;
    border: solid 2px;
    background-color: ${(props) =>
      props.disabled ? `${colors.GRAY_SCALE}` : `${colors.NEW_PURPLE}`};
    border-radius: 100%;
    border-color: ${(props) =>
      props.disabled ? `${colors.GRAY_SCALE}` : `${colors.NEW_PURPLE}`};
  }

  .check::after {
    content: "";
    display: none;
  }

  .container input:checked ~ .check:after {
    display: block;
  }
`;

export const Container = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;

  & > input {
    margin-right: 8px;
  }

  input[type="radio"] {
    appearance: none;
    height: 20px;
    width: 20px;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid ${colors.NEW_PURPLE};
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  input[type="radio"]:before {
    content: "";
    position: absolute;
    height: 10px;
    width: 10px;
    background: ${colors.NEW_PURPLE};
    border-radius: 50%;
    opacity: 0;
    transition: all 200ms ease-in-out;
  }

  input[type="radio"]:checked:before {
    opacity: 1;
  }

  input[type="radio"]:focus {
    box-shadow: ${colors.NEW_PURPLE};
  }
`;
