import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 30px; // não possui ds-token

  margin-top: ${({ theme }) => theme.space[6]};

  & > div {
    height: 105px;
  }

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;

    & > div {
      flex-direction: column;
      height: fit-content;
    }
  }
`;
