import styled from "styled-components";
import * as colors from "styles/colors";
import * as I from "./type";

export const Text = styled.p<I.TextInterface>`
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "0")};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "0")};

  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "700")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "12px")};
  font-style: normal;

  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "18px")};
  color: ${colors.NEW_GRAY_TWO};

  // Text Transform
  ${(props) => props.textUpperCase && "text-transform: uppercase;"}

  // Text Color
  ${(props) => props.purple && `color: ${colors.NEW_PURPLE}`}
  ${(props) => props.gray && `color: ${colors.NEW_GRAY_ONE}`}
  ${(props) => props.scaleGray && `color: ${colors.GRAY_SCALE_THREE};`}
`;
