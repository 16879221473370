// React
import { useContext, useState, useEffect } from "react";
// Style
import * as S from "./styles";
// Libs
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
// Components
import { Loading, Postite, Title } from "components/atoms";
import { StepsProgress } from "components/templates/StepsProgress";
// Services
import { getEnrollmentSteps } from "services/enrollment.service";
// Interface
import { EnrollmentInterface } from "interfaces/enrollment";
import { IEnrollmentStatusLocation } from "./interface";

export const EnrollmentStatus = () => {
  const { t } = useTranslation();
  const { state } = useLocation<IEnrollmentStatusLocation>();
  const i18_prefix = "components.atoms.postite";
  const [enrollmentData, setEnrollmentData] = useState<EnrollmentInterface>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      const response = await getEnrollmentSteps({
        enrollment_id: state.student.id,
      });

      const data = await response?.data;

      setEnrollmentData(data);
      setIsLoading(false);
    })();
  }, [state]);

  if (isLoading) {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <h4 className="text-center">Carregando etapas da matricula...</h4>
        <Loading />
      </div>
    );
  }

  return (
    <S.Container>
      <Title>
        {t("pages.EDF.enrollmentStatus.studentTitle", {
          studentName: enrollmentData?.guard.student.nome,
        })}
      </Title>

      {enrollmentData && (
        <>
          <StepsProgress
            steps={enrollmentData.steps}
            user={state.user}
            enrollment={enrollmentData}
          />
          {enrollmentData.payment_plan?.plan?.final_date && (
            <Postite
              margin="0 0 24px 0"
              type="info"
              title={t(`${i18_prefix}.infoTitle`, {
                dateMonth: enrollmentData.payment_plan.plan.final_date,
              })}
              content={t(`${i18_prefix}.infoContent`, {
                dateMonth: enrollmentData.payment_plan.plan.final_date,
              })}
            />
          )}
        </>
      )}
    </S.Container>
  );
};
