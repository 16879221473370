import styled from "styled-components";

export const Container = styled.div`
  padding: ${({ theme }) => theme.space[12]} 97px; // TODO: nao tem na lib

  h2 {
    margin-top: ${({ theme }) => theme.space[2]};
    font-weight: 600; // TODO: nao tem na lib
    font-size: ${({ theme }) => theme.fontSizes.web.h3};
    color: ${({ theme }) => theme.colors.brandingOrange};
  }

  @media (max-width: 1024px) {
    form {
      div {
        width: 100%;
      }
    }
  }
`;

export const DividerFormRow = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space[4]};

  &.primmaryDivider {
    margin-top: ${({ theme }) => theme.space[4]};
  }

  &.secondDivider {
    margin-top: ${({ theme }) => theme.space[2]};
  }

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const SelectGender = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 228px;

  label {
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    font-size: ${({ theme }) => theme.fontSizes.web.sm};
    line-height: ${({ theme }) => theme.lineHeights.normal};
    text-transform: uppercase;
    margin-bottom: 0;
  }

  select {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.greyScale40};
    margin-top: ${({ theme }) => theme.space[2]};
    height: 37px;
    padding: 0 ${({ theme }) => theme.space[2]};
  }

  span {
    font-size: ${({ theme }) => theme.fontSizes.web.sm};
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    line-height: normal;
    color: ${({ theme }) => theme.colors.statusErrorDefault};
  }
`;

export const ButtonGroupActions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.space[12]};
  gap: ${({ theme }) => theme.space[4]};

  button:nth-child(2) {
    padding: ${({ theme }) => theme.space[4]} 118px;
  }
`;
