import styled from "styled-components";

export const SigningContractStyle = styled.div`
  div.enrollment-title {
    font-size: 20px;
    font-weight: bold;
    color: #ed008c;

    div.subtitle {
      font-weight: 500;
      font-size: 16px;
      color: black;
    }
  }
  div.subdiv {
    display: flex;
  }
`;

export const Contract = styled.div`
  height: 65vh;

  iframe {
    border: 1px solid #c4c4c4;
    box-shadow: 0px 4px 4px 0px #00000040;
    border-radius: 5px;

    body {
      background-color: red;
    }
  }
`;
