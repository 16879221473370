// React
import { useContext, useEffect, useState } from "react";
// Styles
import * as S from "./styles";
// Context
import { FoodContext } from "context/foodContext";
// Libs
import Icon from "@mdi/react";
import { mdiCheckCircleOutline } from "@mdi/js";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HTTP_STATUS from "http-status-codes";
import moment from "moment";
// Components
import { Text } from "components";
import { Button } from "components/atoms";
import { CardFoodInfo } from "pages/EDF/SinglePurchase/components/CardFoodInfo";
// Interfaces
import { ILocationSelectMeals } from "../../type";
import { SingleFoodTransactionsInterface } from "interfaces/mealInterfaces";
// Services
import { getConsultSingleFoodTransactions } from "services/meals.service";
import { IFoodContextType } from "context/foodContext/type";

type StepFourType = {
  transaction?: SingleFoodTransactionsInterface;
  paymentType: string;
  setStep: (step: number) => void;
};

export const StepFive = ({
  transaction,
  paymentType,
  setStep,
}: StepFourType) => {
  const history = useHistory();
  const { t } = useTranslation();
  const i18_prefix = "pages.EDF.selectMeals.components.stepFive";
  const { state } = useLocation<ILocationSelectMeals>();
  const {
    selectedFoodItems,
    clearSelectedFoodItems,
    itemsTotalValue,
    selectedDates,
    clearSelectedDates,
  } = useContext(FoodContext) as IFoodContextType;

  const [singleFood, setSingleFood] =
    useState<SingleFoodTransactionsInterface>();

  const studentSize = () => {
    state.guardianUser.students.length > 1
      ? (history.push({
          pathname: "/alimentacao/compra-avulsa",
          state: {
            user: state.guardianUser,
          },
        }),
        clearSelectedDates(),
        clearSelectedFoodItems())
      : (setStep(0), clearSelectedDates(), clearSelectedFoodItems());
  };

  useEffect(() => {
    (async () => {
      const response = await getConsultSingleFoodTransactions({
        guardianId: state.guardianUser.id,
        date: moment(new Date()).format("YYYY-MM-DD"),
      });

      if (response && response.status === HTTP_STATUS.OK) {
        setSingleFood(response.data.results[response.data.results.length - 1]);
      }
    })();
  }, [state.guardianUser.id, state.payment, state.purchaseId]);

  return (
    <S.Container>
      <div className="student-info">
        <Text gray>{state.studentUser.name}</Text>
        <Text gray textUpperCase fontWeight="400px">
          {t(`${i18_prefix}.singleFood`)}
        </Text>
      </div>

      <div className="paymentSuccess">
        <Icon size={3} path={mdiCheckCircleOutline} />
        <strong>{t(`${i18_prefix}.concluded`)}</strong>
        <p>{t(`${i18_prefix}.concludedText`)}</p>

        <CardFoodInfo
          transaction={transaction || singleFood}
          foodList={selectedFoodItems}
          paymentType={paymentType}
          total={itemsTotalValue}
          step={4}
          selectedDaysLength={selectedDates.length}
        />

        <Button onClick={() => history.push("/home")}>
          {t(`${i18_prefix}.buttonBackToHome`)}
        </Button>
        <button className="resetSteps" onClick={studentSize}>
          {t(`${i18_prefix}.newOrder`)}
        </button>
      </div>
    </S.Container>
  );
};
