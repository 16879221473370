import { ButtonFilterBulletin } from "components/atoms/Button/style";
import { GroupButtons } from "components/molecules/GroupButtons";
import {
  Container,
  GroupButtonsContainer,
  ContentContainer,
  LoadingContainer,
} from "./styles";
import { Loading } from "components";
import { useTranslation } from "react-i18next";
import { StudyItinerariesInternalDrawer } from "./components/StudyItinerariesInternalDrawer";
import { Drawer } from "components/organisms/Drawer";
import { useStudyItinerariesInternal } from "./StudyItinerariesInternal.helper";
import { StudentsTable } from "./components/StudentsTable";
import { Alert } from "components/Alert";
import {
  IGradeGroupFormatedClasses,
  IStudentsByGradegroupResponse,
} from "interfaces/gradeGroupInterfaces";
import { isCX } from "helpers/constants";
import { Storage } from "Storage";
import { UnitsSelect } from "./components/UnitsSelect";
import { StudyItinerariesInternalActionTypes } from "./types";

export const StudyItinerariesInternal = () => {
  const role = Storage.props.role.get();

  const { dispatch, state } = useStudyItinerariesInternal();

  const { t } = useTranslation();
  const prefix = "pages.AP.studyItineraryInternal";

  if (state?.loaders.isLoadingClasses) {
    return <Loading />;
  }

  const handleSelectCycle = (item: IGradeGroupFormatedClasses) => {
    const { levelValue, years } = item;

    dispatch({
      type: StudyItinerariesInternalActionTypes.SET_SELECTED_PERIOD,
      payload: levelValue,
    });
    dispatch({
      type: StudyItinerariesInternalActionTypes.SET_SELECTED_GRADE,
      payload: years[0].id,
    });
    dispatch({
      type: StudyItinerariesInternalActionTypes.SET_SELECTED_CLASS,
      payload: years[0].sections[0].id,
    });
  };

  const handleSelectYear = (item: any) => {
    dispatch({
      type: StudyItinerariesInternalActionTypes.SET_SELECTED_GRADE,
      payload: item?.id,
    });
    dispatch({
      type: StudyItinerariesInternalActionTypes.SET_SELECTED_CLASS,
      payload: item?.sections[0]?.id,
    });
  };

  const handleDrawerOpen = () => {
    dispatch({
      type: StudyItinerariesInternalActionTypes.SET_DRAWER_IS_OPEN,
      payload: true,
    });
  };

  const handleDrawerClose = () => {
    dispatch({
      type: StudyItinerariesInternalActionTypes.SET_DRAWER_IS_OPEN,
      payload: false,
    });
    dispatch({
      type: StudyItinerariesInternalActionTypes.SET_CURRENT_STUDENT,
      payload: undefined,
    });

    dispatch({
      type: StudyItinerariesInternalActionTypes.SET_CURRENT_KANATA_STUDENT,
      payload: undefined,
    });
  };

  const handleSelectStudent = async (
    student: IStudentsByGradegroupResponse
  ) => {
    dispatch({
      type: StudyItinerariesInternalActionTypes.SET_SELECTED_STUDENT,
      payload: student,
    });
    handleDrawerOpen();
  };

  const handleSelectClass = (classId: number) => {
    dispatch({
      type: StudyItinerariesInternalActionTypes.SET_SELECTED_CLASS,
      payload: classId,
    });
  };

  const handleChangeUnit = (unitId: number) => {
    dispatch({
      type: StudyItinerariesInternalActionTypes.SET_SELECTED_UNIT,
      payload: unitId,
    });
  };

  const shouldShowUnitsSelect = isCX(role) && state?.units && (
    <UnitsSelect
      onChangeUnit={handleChangeUnit}
      units={state?.units}
      selectedUnit={state?.filters.selectedUnit}
    />
  );

  return (
    <>
      <Container>
        <h1>{t(`${prefix}.title`)}</h1>
        <GroupButtonsContainer>
          {shouldShowUnitsSelect}
          {state?.classes && (
            <>
              <GroupButtons label={t(`${prefix}.cycle`)} quarter={false}>
                {state?.classes?.map((item) => (
                  <ButtonFilterBulletin
                    key={item?.levelValue}
                    active={item?.levelValue === state?.filters.selectedPeriod}
                    onClick={() => handleSelectCycle(item)}
                    disabled={state?.loaders.isLoadingClasses}
                  >
                    {item?.level}
                  </ButtonFilterBulletin>
                ))}
              </GroupButtons>

              <GroupButtons label={t(`${prefix}.year`)} quarter={false}>
                {state?.classes
                  ?.find(
                    (item) => item?.levelValue === state?.filters.selectedPeriod
                  )
                  ?.years.map((item) => (
                    <ButtonFilterBulletin
                      key={item?.id}
                      active={item?.id === state?.filters.selectedGrade}
                      onClick={() => handleSelectYear(item)}
                      disabled={state?.loaders.isLoadingClasses}
                    >
                      {item?.grade}
                    </ButtonFilterBulletin>
                  ))}
              </GroupButtons>

              <GroupButtons label={t(`${prefix}.grade`)} quarter={false}>
                {state?.classes
                  ?.find(
                    (item) => item?.levelValue === state?.filters.selectedPeriod
                  )
                  ?.years.find(
                    (item) => item?.id === state?.filters.selectedGrade
                  )
                  ?.sections.map((item) => (
                    <ButtonFilterBulletin
                      key={item?.id}
                      active={item?.id === state?.filters.selectedClass}
                      onClick={() => handleSelectClass(item?.id)}
                      disabled={state?.loaders.isLoadingClasses}
                    >
                      {item?.letter}
                    </ButtonFilterBulletin>
                  ))}
              </GroupButtons>
            </>
          )}
        </GroupButtonsContainer>

        <ContentContainer>
          {!state?.loaders.isLoadingList &&
            state?.students &&
            state?.classes && (
              <StudentsTable
                students={state?.students}
                onSelectStudent={handleSelectStudent}
              />
            )}

          {state?.loaders.isLoadingList && <Loading />}

          {!state?.loaders.isLoadingList && !state?.classes && (
            <Alert.Root severity="warning">
              <Alert.Title text="Unidade sem roteiros" />
              <Alert.Content text="Esta unidade não possui roteiros de estudos" />
            </Alert.Root>
          )}
        </ContentContainer>
      </Container>

      {state?.currentStudent && state?.currentKanataStudent ? (
        <StudyItinerariesInternalDrawer
          open={state?.drawerIsOpen}
          onClose={handleDrawerClose}
          userStudent={state?.currentStudent}
          userKanata={state?.currentKanataStudent}
        />
      ) : (
        <Drawer
          isOpen={state?.loaders.isLoadingSelectedStudent}
          onClose={handleDrawerClose}
          title={t(`${prefix}.loading`)}
          side="right"
        >
          <LoadingContainer>
            <Loading />
          </LoadingContainer>
        </Drawer>
      )}
    </>
  );
};
