import { IGetClassCouncilStudentsResponse } from "services/grades.service";
import { RadioGroupV2 } from "@escolamais-ui-v2/react";
import {
  IChangeStudentApprovedStatus,
  IStudentStatus,
  StudentStatusEnum,
} from "../../ClassCouncil.helper";

interface IStudentsRowProps {
  student: IGetClassCouncilStudentsResponse;
  onChangeStudentApprovedStatus: (
    student: IChangeStudentApprovedStatus
  ) => void;
}

export const StudentsRow = ({
  student,
  onChangeStudentApprovedStatus,
}: IStudentsRowProps) => {
  const handleChangeStudentStatus = (status: IStudentStatus) => {
    onChangeStudentApprovedStatus({ student, status });
  };

  const studentStatusRadioOptions = [
    {
      label: "Aprovado",
      value: StudentStatusEnum.APROVADO,
    },
    {
      label: "Retido",
      value: StudentStatusEnum.RETIDO,
    },
  ];

  const initialStudentStatus = () => {
    if (student.aprovado === null) {
      return "";
    }

    if (student.aprovado) {
      return StudentStatusEnum.APROVADO;
    }

    if (!student.aprovado) {
      return StudentStatusEnum.RETIDO;
    }
  };

  return (
    <tr>
      <td>{student.ciclo}</td>
      <td>{student.estudante_nome}</td>
      <td>{student.ra}</td>
      <td>
        <RadioGroupV2
          id={`input-approved-${student.ra}`}
          name={`input-approved-${student.ra}`}
          onChangeOption={handleChangeStudentStatus}
          appearance="default"
          options={studentStatusRadioOptions}
          defaultCheckedValue={initialStudentStatus()}
        />
      </td>
    </tr>
  );
};
