import styled from "styled-components";
import * as colors from "styles/colors";
import * as Props from "./interface";

export const Container = styled.div<Props.ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  padding: 16px;
  border: 2px solid
    ${({ hasPlan }) => (hasPlan ? colors.GRAY_SCALE : colors.NEW_PURPLE)};

  background: ${({ selected }) => (selected ? colors.NEW_PURPLE_TWO : "white")};
  background: ${({ hasPlan }) => hasPlan && colors.GRAY_SCALE_TWO};

  transition: 0.2s ease-in;

  label {
    cursor: pointer;
  }
`;

export const FoodOptionPackageStyle = styled.div<Props.FoodOptionPackageStyleProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  padding: 1em;
  border: 2px solid
    ${(props) => (props.disabled ? colors.GRAY_SCALE : colors.NEW_PURPLE)};

  background: ${(props) => (props.selected ? colors.NEW_PURPLE_TWO : "white")};
  background: ${(props) => props.disabled && colors.GRAY_SCALE_TWO};

  transition: 0.2s ease-in;

  label {
    background: ${(props) =>
      props.disabled ? "cursor: not-allowed" : "cursor : pointer"};

    width: 100%;
  }

  div.sameLine {
    display: flex;
    justify-content: space-between;
  }
`;
