import styled from "styled-components";

export const BoletoStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding: 5px;

  div.bankPaymentSlipCode {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    width: 75%;
    padding: 5px;

    border-radius: 8px;
    border: 1px solid;
    border-color: #c4c4c4;

    cursor: pointer;
  }
`;
