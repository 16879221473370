// React
import { useEffect, useState } from "react";
// Libs
import HTTP_STATUS from "http-status-codes";
// Services
import { getFoodOrder } from "services/meals.service";
// Interfaces
import { IGetFoodOrder } from "interfaces/mealInterfaces";

export const OrderDrawerHelper = (order: number) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [orderDetails, setOrderDetails] = useState<IGetFoodOrder | null>(null);

  useEffect(() => {
    (async () => {
      setLoading(true);

      const response = await getFoodOrder({
        order,
      });

      if (response && response.status === HTTP_STATUS.OK) {
        setOrderDetails(response.data);
        setLoading(false);
      } else {
        setError(true);
        setLoading(false);
      }
    })();
  }, [order]);

  return {
    loading,
    error,
    orderDetails,
    setOrderDetails,
  };
};
