import styled from "styled-components";

export const Container = styled.div`
  max-width: 768px;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding: 0 ${({ theme }) => theme.space[6]};

  p {
    font-size: ${({ theme }) => theme.fontSizes.web.h4};
    text-align: center;
    width: 100%;
  }

  .pageLogo {
    background-color: ${({ theme }) => theme.colors.primaryLight90};
    padding: ${({ theme }) => theme.space[4]};
    border-radius: ${({ theme }) => theme.radii.full};
    margin-bottom: ${({ theme }) => theme.space[4]};
  }

  .studentInfo {
    margin-top: ${({ theme }) => theme.space[8]};
    display: flex;
    align-items: flex-start;
    justify-content: space-around;

    width: 100%;

    background-color: ${({ theme }) => theme.colors.primaryLight90};
    padding: ${({ theme }) => theme.space[4]};
    border-radius: ${({ theme }) => theme.radii.sm};

    p {
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .formValue {
    width: 100%;
    margin-top: ${({ theme }) => theme.space[8]};

    .paymentOptions {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .radiosInputs {
        display: flex;
        gap: ${({ theme }) => theme.space[5]};

        margin-top: ${({ theme }) => theme.space[2]};

        label {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: ${({ theme }) => theme.fontSizes.web.h4};
          gap: ${({ theme }) => theme.space[1]};
        }

        input[type="radio"] {
          margin: 0;
        }
      }
    }

    h2 {
      font-size: ${({ theme }) => theme.fontSizes.web.h4};
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      text-transform: uppercase;
      margin: 0;
      text-align: center;
    }

    input {
      margin-top: ${({ theme }) => theme.space[4]};
      width: 100%;
      border: 1px solid ${({ theme }) => theme.colors.greyScale20};
      border-radius: ${({ theme }) => theme.radii.xs};
      padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[4]};
      font-size: ${({ theme }) => theme.fontSizes.web.h4};
      outline: none;
    }

    button {
      margin-top: ${({ theme }) => theme.space[4]};
      margin-bottom: ${({ theme }) => theme.space[8]};
      width: 100%;

      border: none;
      border-radius: ${({ theme }) => theme.radii.xs};
      padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[4]};

      background-color: ${({ theme }) => theme.colors.statusWarningLight80};
      color: ${({ theme }) => theme.colors.statusWarningDark50};
      font-size: ${({ theme }) => theme.fontSizes.web.h3};
      font-weight: ${({ theme }) => theme.fontWeights.bold};

      transition: 0.2s all ease;

      &:hover {
        background-color: ${({ theme }) => theme.colors.statusWarningDefault};
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }
`;
