import styled from "styled-components";

export const StyledTableHeader = styled.th<{ textAlign?: string }>`
  text-align: ${({ textAlign }) => textAlign};
  padding: 0.75rem 0.5rem;
  font-size: 14px;
  border-bottom: 1px solid #999999;
`;

export const StyledTableRow = styled.tr`
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;
`;

export const StyledTableData = styled.td<{ textAlign?: string }>`
  min-width: 160px;
  padding: 0.75rem 0.5rem;
  text-align: ${({ textAlign }) => textAlign};
`;
