import { StudentCard } from "components/molecules";
import { CONTRACT_STATUS } from "helpers/constants";
import { IStudentList } from "./interface";

import * as S from "./styles";

export const StudentsList = ({ students }: IStudentList) => {
  return (
    <S.Container>
      {students.map(
        (student) =>
          student.enrollment && (
            <StudentCard key={student.enrollment.id} studentInfo={student} />
          )
      )}
    </S.Container>
  );
};
