import styled from "styled-components";

export const ModalDiv = styled.div`
  font-size: 16px;
  .title {
    font-size: 24px;
  }

  .characterCount {
    font-size: 14px;
    color: #00adef;
  }

  .roundedBorder {
    border-radius: 24px;
  }
`;

export const ModalHeader = styled.div`
  position: fixed;
  color: black;
  background-color: #ffffff;
  max-height: 100vh;
  width: 584px;
  height: 409px;
  border-radius: 21px;
  svg.close {
    cursor: pointer;
    position: fixed;
    right: 0.65em;
    top: 0.5em;

    @media only screen and (min-width: 415px) {
      right: 15%;
    }
  }
`;

export const ModalInput = styled.textarea`
  width: 520px;
  height: 106px;
  border: 1px solid #00adef;
  border-radius: 7px;
`;
