// React
import { useEffect, useState, useContext } from "react";
// Libs
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";
import HTTP_STATUS from "http-status-codes";
// Components
import { CreditCard } from "./components/CreditCard";
import { EnrollmentStepCircle } from "components";
import { Button, InputRadio, Loading, Postite } from "components/atoms";
// Services
import {
  confirmarSelecaoAlimentacao,
  updateConfirmarSelecaoAlimentacao,
} from "services/payment.service";
import { getEnrollmentCredit } from "services/enrollment.service";
// Context
import { FoodContext } from "context/foodContext";
// Helpers
import { IsDevelopmentEnvironment } from "helpers/helpers";
// Interfaces
import { ILocationSelectMeals, IStep } from "../../type";
import { IFoodContextType } from "context/foodContext/type";
import { CardFoodInfo } from "pages/EDF/SinglePurchase/components/CardFoodInfo";
// Styles
import * as S from "./styles";
import { IHandleConfirmPayment } from "./type";

export const StepThree = ({
  next,
  setTransaction,
  setPaymentType,
  setStep,
}: IStep) => {
  const { t } = useTranslation();
  const i18_prefix = "pages.EDF.selectMeals.components.stepThree";
  const TRANSLATE_CREDIT_CARD_ERRORS =
    "pages.EDF.enrollmentPayment.creditCardErrors";

  const { state } = useLocation<ILocationSelectMeals>();
  const history = useHistory();

  const [error, setError] = useState<boolean>(false);
  const [errorText, setErrorText] = useState<Array<string>>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [enrollmentCredit, setEnrollmentCredit] = useState(0);
  const [isUsedCredit, setIsUsedCredit] = useState(false);

  const {
    selectedFoodItems,
    itemsTotalValue,
    setPaymentMethod,
    selectedDates,
    setPaymentData,
  } = useContext(FoodContext) as IFoodContextType;

  const newWindow = window as any;
  const Iugu = newWindow.Iugu;

  const selectedDays = state.payment
    ? state.order.dias_selecionados
    : selectedDates;

  useEffect(() => {
    //Seta o AccountID da IUGU
    Iugu.setAccountID("8EF5BA8442B546CC85103E3D73C1FB55");

    //Verifica o ambiente e seta o modo teste da IUGU de acordo.
    if (IsDevelopmentEnvironment()) {
      Iugu.setTestMode(true);
    } else {
      Iugu.setTestMode(false);
    }
  }, [Iugu]);

  useEffect(() => {
    setIsLoading(true);

    (async () => {
      const res = await getEnrollmentCredit({
        enrollmentId: state.studentUser.enrollment.id,
      });

      if (res?.status === HTTP_STATUS.OK) {
        setEnrollmentCredit(res.data.valor_total_credito);
        setIsLoading(false);
      }
    })();
  }, [state.studentUser.enrollment.id]);

  if (isLoading) {
    return <Loading />;
  }

  const handleConfirmPayment = async ({
    paymentType,
    selectedItens,
    chargerToken,
  }: IHandleConfirmPayment) => {
    const reqBody = {
      matricula: state.studentUser.enrollment.id,
      tipo_pagamento: paymentType,
      dias_selecionados: selectedDays,
      itens_selecionados: selectedItens,
      charge_token: chargerToken,
      utiliza_credito: isUsedCredit,
      orderId: state?.order?.id,
    };

    if (state.order) {
      const res = await updateConfirmarSelecaoAlimentacao(reqBody);
      return res;
    }

    const res = await confirmarSelecaoAlimentacao(reqBody);
    return res;
  };

  const onSubmit = async (values: any) => {
    setIsSubmitting(true);

    const totalValueOrder = state.order ? state.order.total : itemsTotalValue;
    const itemsArr: number[] = [];

    if (state.payment) {
      state.order.items.map((foodItem) => itemsArr.push(foodItem.item));
    } else {
      selectedFoodItems.map((foodItem) => itemsArr.push(foodItem.id));
    }

    if (enrollmentCredit > totalValueOrder && isUsedCredit) {
      setIsLoading(true);

      const response = await handleConfirmPayment({
        paymentType: "credito",
        selectedItens: itemsArr,
      });

      if (response && response.status === HTTP_STATUS.OK) {
        setPaymentData(response.data);
        setPaymentType("Crédito da Matricula");
        setIsLoading(false);
        setTransaction(response?.data);

        setStep && response.data.valor_pago === "0.00" ? setStep(4) : next();
        return;
      } else {
        setIsLoading(false);
        setError(true);
      }
    }

    if (values.payment_method === "pix") {
      setIsLoading(true);

      const response = await handleConfirmPayment({
        paymentType: "pix",
        selectedItens: itemsArr,
      });

      if (response && response.status === HTTP_STATUS.OK) {
        setPaymentData(response.data);
        setPaymentType("PIX");
        setIsLoading(false);
        setTransaction(response?.data);

        setStep && response.data.valor_pago === "0.00" ? setStep(4) : next();
        return;
      } else {
        setIsLoading(false);
        setError(true);
      }
    }

    if (values.payment_method === "credit_card") {
      const nameSplit = values.name.split(" ");
      const firstName = nameSplit[0];
      const lastName = nameSplit.splice(1).join(" ");
      const month = values.due_date.split("/")[0];
      const year = values.due_date.split("/")[1];

      const CreditCard = Iugu.CreditCard(
        values.card_number,
        month,
        year,
        firstName,
        lastName,
        values.cvv
      );

      if (!CreditCard.valid()) {
        setError(true);
        setErrorText([
          ...errorText,
          t(`${TRANSLATE_CREDIT_CARD_ERRORS}.invalidCard`),
        ]);
        setIsLoading(false);
        setIsSubmitting(false);
      }

      await Iugu.createPaymentToken(CreditCard, async (response: any) => {
        if (response.errors) {
          Object.keys(response.errors).forEach((key) => {
            switch (key) {
              case "number":
                setErrorText([
                  ...errorText,
                  t(`${TRANSLATE_CREDIT_CARD_ERRORS}.number`),
                ]);
                break;
              case "verification_value":
                setErrorText([
                  ...errorText,
                  t(`${TRANSLATE_CREDIT_CARD_ERRORS}.verificationValue`),
                ]);
                break;
              case "expiration":
                setErrorText([
                  ...errorText,
                  t(`${TRANSLATE_CREDIT_CARD_ERRORS}.expiration`),
                ]);
                break;
              case "first_name":
                setErrorText([
                  ...errorText,
                  t(`${TRANSLATE_CREDIT_CARD_ERRORS}.firstName`),
                ]);
                break;
              case "last_name":
                setErrorText([
                  ...errorText,
                  t(`${TRANSLATE_CREDIT_CARD_ERRORS}.lastName`),
                ]);
                break;
              case "full_name":
                setErrorText([
                  ...errorText,
                  t(`${TRANSLATE_CREDIT_CARD_ERRORS}.fullName`),
                ]);
                break;
            }
          });
          setError(true);
          setIsLoading(false);
          setIsSubmitting(false);
        } else if (itemsTotalValue || state.order.total) {
          const res = await handleConfirmPayment({
            paymentType: "credit_card",
            selectedItens: itemsArr,
            chargerToken: response.id,
          });

          if (res) {
            if (res.status === HTTP_STATUS.OK) {
              setIsLoading(false);
              setPaymentType("Cartão de Crédito");
              setTransaction(res.data);
              setStep && setStep(4);
            } else {
              setError(true);
              setIsLoading(false);
              setIsSubmitting(false);
              setErrorText([
                ...errorText,
                `Erro ${res?.status} - ${res?.data}`,
              ]);
            }
          }
        } else {
          setError(true);
          setIsLoading(false);
          setIsSubmitting(false);
          setErrorText([
            ...errorText,
            t(`${TRANSLATE_CREDIT_CARD_ERRORS}.genericError`),
          ]);
        }
      });
    }

    setPaymentMethod(values.payment_method);
    setIsLoading(false);
  };

  return (
    <S.Container>
      <EnrollmentStepCircle
        studentName={state.studentUser.name}
        step={3}
        totalSteps={3}
        currentStepName={t(`${i18_prefix}.currentStep`)}
        width="720px"
        subtitle={t(`${i18_prefix}.headerSubTitle`)}
      />

      <CardFoodInfo
        foodList={selectedFoodItems}
        total={itemsTotalValue}
        step={2}
        selectedDaysLength={selectedDates.length}
        transaction={state.order}
        isUsedCredit={isUsedCredit}
        setIsUsedCredit={setIsUsedCredit}
        creditAmount={enrollmentCredit}
      />

      {!(enrollmentCredit > itemsTotalValue && isUsedCredit) && (
        <strong className="mb-0">
          {t(`${i18_prefix}.selectPaymentMethod`)}
        </strong>
      )}

      <Form onSubmit={onSubmit}>
        {({ form, handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            {!(enrollmentCredit > itemsTotalValue && isUsedCredit) && (
              <>
                <div className="paymentOption">
                  <InputRadio
                    name="payment_method"
                    value="pix"
                    label={t(`${i18_prefix}.inputLabelPix`)}
                  />
                </div>
                <div className="paymentOption ">
                  <InputRadio
                    name="payment_method"
                    value="credit_card"
                    label={t(`${i18_prefix}.inputLabelCard`)}
                  />
                </div>
              </>
            )}

            {values.payment_method === "credit_card" && <CreditCard />}

            {error && (
              <div className="errorWarning">
                <Postite
                  type="danger"
                  title={t(`${i18_prefix}.postiteTitle`)}
                  content={t(`${i18_prefix}.postiteContent`)}
                  titleSize="14px"
                />
              </div>
            )}

            <div className="btn-group">
              <Button
                outline
                type="button"
                onClick={() => history.goBack()}
                width="50%"
              >
                {t(`${i18_prefix}.buttonBack`)}
              </Button>

              <Button
                type="submit"
                disabled={
                  (!(enrollmentCredit > itemsTotalValue && isUsedCredit) &&
                    !values.payment_method) ||
                  isSubmitting
                }
                width="50%"
              >
                {t(`${i18_prefix}.buttonAdvance`)}
              </Button>
            </div>
          </form>
        )}
      </Form>
    </S.Container>
  );
};
