import {
  IQuarterPublicationsByUnitResponse,
  QuarterPublicationStatusEnum,
} from "interfaces/boletim";
import { PublishCardBody, ButtonBox } from "./styles";
import { Button, Tag } from "@escolamais-ui-v2/react";
import PublicIcon from "@material-ui/icons/Public";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

interface IPublishCardProps {
  publication: IQuarterPublicationsByUnitResponse;
  onUndoQuarterPublication: (
    publication: IQuarterPublicationsByUnitResponse
  ) => void;
  onPublishQuarter: (publication: IQuarterPublicationsByUnitResponse) => void;
}

type IActionsBasedOnPublishedStatus = {
  [key in QuarterPublicationStatusEnum]: {
    tag: ReactNode;
    button: ReactNode;
  };
};

export const PublishCard = ({
  publication,
  onUndoQuarterPublication,
  onPublishQuarter,
}: IPublishCardProps) => {
  const prefix = "pages.AP.publishBoletim.publishCard";
  const { t } = useTranslation();

  const actionsBasedOnPublishedStatus: IActionsBasedOnPublishedStatus = {
    Publicado: {
      tag: (
        <Tag fontSize="sm" size="medium" appearance="successOutline">
          {t(`${prefix}.published`)}
        </Tag>
      ),
      button: (
        <Button
          size="medium"
          buttonText="Desfazer"
          appearance="text"
          onClick={() => onUndoQuarterPublication(publication)}
        />
      ),
    },
    Disponível: {
      tag: (
        <Tag fontSize="sm" size="medium" appearance="primary">
          {t(`${prefix}.available`)}
        </Tag>
      ),
      button: (
        <Button
          size="medium"
          buttonText="Publicar"
          appearance="primary"
          icon={<PublicIcon width={24} height={24} />}
          onClick={() => onPublishQuarter(publication)}
        />
      ),
    },
    Indisponível: {
      tag: (
        <Tag
          fontSize="sm"
          size="medium"
          appearance="primaryOutline"
          className="tag_disabled"
        >
          {t(`${prefix}.unavailable`)}
        </Tag>
      ),
      button: (
        <Button
          size="medium"
          buttonText="Publicar"
          disabled
          icon={<PublicIcon width={24} height={24} />}
        />
      ),
    },
  };

  return (
    <>
      <PublishCardBody status={publication.status}>
        <p>{publication.publicacao.quarter_name}</p>
        {actionsBasedOnPublishedStatus[publication.status].tag}
        <p>{`${t(`${prefix}.publishedIn`)}: ${
          publication.publicacao.publicado_em
        }`}</p>
        <ButtonBox>
          {actionsBasedOnPublishedStatus[publication.status].button}
        </ButtonBox>
      </PublishCardBody>
    </>
  );
};
