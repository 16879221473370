import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";
import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import * as dotenv from "dotenv";
import * as Sentry from "@sentry/react";
import { Replay } from "@sentry/replay";
import { App } from "./App";
import { toast } from "react-toastify";
import reportWebVitals from "./reportWebVitals";
// @ts-ignore
import ErrorBoundary from "helpers/ErrorBoundary";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "styled-components";
import { theme } from "@escolamais-ui-v2/tokens/src";
import "./i18n";
import { IsDevelopmentEnvironment } from "helpers/helpers";
import ReactGA from "react-ga4";

ReactGA.initialize("G-Y4MMWS068S");

const sendAnalytics = () => {
  if (process.env.NODE_ENV === "production") {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
    });
  }
};

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: IsDevelopmentEnvironment() ? 0.1 : 1.0,
  integrations: [new Replay({ maskAllText: false, maskAllInputs: false })],
});

toast.configure();
dotenv.config();
const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </QueryClientProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorkerRegistration.unregister();

reportWebVitals(sendAnalytics);
