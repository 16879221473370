import { Typography } from "@material-ui/core";
import { ReactElement } from "react";
import loadingGif from "static/img/loading.gif";

interface Props {
  height: number;
}

function LoadingBox({ height }: Props): ReactElement {
  return (
    <div className="row justify-content-center">
      <div className="col-7 text-center">
        <Typography variant="h6">Aguarde...</Typography>
        <img height={height} src={loadingGif} alt="loading" />
      </div>
    </div>
  );
}

export default LoadingBox;
