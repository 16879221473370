// Style
import { NoDataStyled, TableContainer, Tbody, Thead } from "./style";
// Interface
import { ITable } from "./interface";
// Components
import { GuardianExitCard } from "../GuardianExitCard";
import { AddGuardian } from "../AddGuardian";
// Img
import EyeShow from "static/img/eye-show-purple.svg";
import EyeHidden from "static/img/eye-hidden-purple.svg";
// Helpers
import { TableHelper } from "./helper";
// Libs
import { useTranslation } from "react-i18next";

export const Table = ({
  exitPermit,
  studentList,
  modal,
  modalForm,
  studentSelected,
  setModalForm,
  setModal,
  setModalOption,
  remove,
  setStudentSelected,
  studentListResults,
}: ITable) => {
  const { t } = useTranslation();
  const prefix = "pages.EDF.exitReport.components.table";

  const { handleChange, handleSaveChanges } = TableHelper({
    setStudentSelected,
    studentListResults,
  });

  return (
    <div>
      <TableContainer>
        <Thead>
          <div className="tr">
            <div className="th">{t(`${prefix}.student`)}</div>
            <div className="th">{t(`${prefix}.year`)}</div>
            <div className="th">{t(`${prefix}.unit`)}</div>
            <div className="th width">{t(`${prefix}.exitPermit`)}</div>
            <div className="th"></div>
          </div>
        </Thead>
        {studentList.results.map((student, index) => {
          return (
            <Tbody key={index}>
              <div className="tr">
                <div className="td">{student.student.nome}</div>
                <div className="td">{student.turma_formatada}</div>
                <div className="td">{student.unit.name}</div>
                <div className="td">
                  <select
                    onChange={(e) => {
                      handleSaveChanges(
                        parseInt(e.target.value),
                        student.student.id
                      );
                    }}
                    name="exitPermit"
                    value={student.horario_saida.transportation.value}
                  >
                    {exitPermit.results.map((exit, index) => {
                      return (
                        <option value={exit.value} key={index}>
                          {exit.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {student.horario_saida.transportation.value === 1 ? (
                  <div className="td">
                    {studentSelected?.id === student.student.id ? (
                      <div onClick={() => handleChange(null)}>
                        <img src={EyeHidden} alt="eye-hidden" />
                      </div>
                    ) : (
                      <div onClick={() => handleChange(student.student)}>
                        <img src={EyeShow} alt="eye-show" />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="td"></div>
                )}
              </div>
              {studentSelected?.id === student.student.id && (
                <div className="escort">
                  {student.horario_saida.responsaveis.map((guardian) => {
                    return (
                      <GuardianExitCard
                        guardian={guardian}
                        key={index}
                        form={modalForm}
                        remove={remove}
                        setModal={setModal}
                        setForm={setModalForm}
                        setModalOption={setModalOption}
                      />
                    );
                  })}
                  {student.horario_saida.responsaveis_saida.map((escort) => {
                    return (
                      <GuardianExitCard
                        escort={escort}
                        key={index}
                        form={modalForm}
                        remove={remove}
                        setModal={setModal}
                        setForm={setModalForm}
                        setModalOption={setModalOption}
                      />
                    );
                  })}
                  <AddGuardian
                    handleClick={setModal}
                    variable={modal}
                    handleOption={setModalOption}
                  />
                </div>
              )}
            </Tbody>
          );
        })}
      </TableContainer>
      {studentList.count <= 0 ? (
        <NoDataStyled>
          <h3>{t(`${prefix}.noData`)}</h3>
        </NoDataStyled>
      ) : null}
    </div>
  );
};
