// React
import { useEffect, useState } from "react";
// Libs
import HTTP_STATUS from "http-status-codes";
import { useTranslation } from "react-i18next";
// Services
import { getYear } from "services/multipurpose";
import { getUnits } from "services/units.service";
import { getSalesPeriod } from "services/sales";
// Interfaces
import { UnitInterface } from "interfaces/unit";
import { EnrollmentYearsInterface } from "interfaces/constants";
import { IHelper } from "./interface";
import { IPagination } from "interfaces/utils";
import { ISalesPeriod } from "interfaces/sales";
// Helpers
import { standardDate } from "helpers/helpers";

export const HPeriodEnrollmentReenrollment = ({ form, drawer }: IHelper) => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.periodEnrollmentReenrollment";

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [years, setYears] = useState<EnrollmentYearsInterface>();
  const [units, setUnits] = useState<Array<UnitInterface>>();
  const [salesPeriod, setSalesPeriod] = useState<IPagination<ISalesPeriod>>();

  useEffect(() => {
    (async () => {
      try {
        const moreYears = 1;
        const allUnits = true;

        const getYears = await getYear({ more_years: moreYears });
        if (getYears && getYears.status === HTTP_STATUS.OK) {
          setYears(getYears.data);
        }

        const units = await getUnits(allUnits);
        if (units && units.status === HTTP_STATUS.OK) {
          setUnits(units.data);
          setLoading(false);
        }
      } catch (err) {
        setError(true);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (form.year && !drawer) {
        const units = await getSalesPeriod({
          ano: form.year,
          unidade: form.unit,
        });
        if (units && units.status === HTTP_STATUS.OK) {
          setSalesPeriod(units.data);
          setLoading(false);
        } else {
          setError(true);
          setLoading(false);
        }
      }
    })();
  }, [form, drawer]);

  const checkValue = (value: string | null) => {
    if (value) {
      return standardDate(value);
    } else {
      return t(`${TRANSLATE}.notRegistered`);
    }
  };

  return { loading, error, years, units, salesPeriod, checkValue };
};
