import styled from "styled-components";
import { BLACK, BLUE, GRAY_BORDER } from "styles/colors";

export const RelatorioDeAlimentacaoStyled = styled.div`
  div.title {
    font-size: 24px;
    font-weight: bold;
    color: ${BLUE};
  }

  .rmsc .dropdown-container {
    margin-top: 1em;
    border: none;
    border-bottom: 1px solid ${GRAY_BORDER};
    span.gray {
      color: ${BLACK};
    }
  }
`;
