// Libs
import { Button, TextField } from "@escolamais-ui-v2/react";
import { useTranslation } from "react-i18next";
// Styles
import * as S from "./style";
// Components
import { Icons, InputDate } from "components";
// Helpers
import { formatMoneyInput } from "helpers/helpers";
// Icons
import plusIcon from "static/img/plusPurple.svg";
// Interfaces
import { IDynamicForm } from "./interface";

export const DynamicFirstForm = ({
  translate,
  dynamics,
  form,
  setForm,
  setDynamics,
  updateField,
}: IDynamicForm) => {
  const { t } = useTranslation();

  return (
    <S.DynamicFirstFormContainer>
      <TextField
        appearance="default"
        backgroundColor="#FFF"
        borderColor="#999999"
        id="inputPrimary"
        labelColor="#0D0D0D"
        leftIconColor="#999999"
        rightIconColor="#fff"
        value={form.name}
        onChange={(e) => {
          setForm({ ...form, name: e.target.value });
        }}
        onChangeText={() => {}}
        placeholder={t(`${translate}.nameInputPlaceholder`)}
        textColor="#0D0D0D"
        width={346}
        height={37}
        withLeftIcon
        label={t(`${translate}.dynamicName`)}
        required
      />

      <div className="sameLine">
        <InputDate
          label={t(`${translate}.startDate`)}
          marginTop={5}
          value={form.startDate}
          onChange={(e) => setForm({ ...form, startDate: e.target.value })}
        />
        <InputDate
          label={t(`${translate}.finalDate`)}
          marginTop={5}
          value={form.finalDate}
          onChange={(e) => setForm({ ...form, finalDate: e.target.value })}
        />
      </div>

      <div>
        {dynamics.map((dynamic, index) => {
          return (
            <div className="sameLine" key={index}>
              <S.InputText>
                <label>{t(`${translate}.amount`)}</label>
                <input
                  type="number"
                  name="amount"
                  onChange={(event) => {
                    const isAmount = true;
                    updateField(index, event, isAmount);
                  }}
                  value={dynamics[index].quantidade_minima}
                  required
                />
              </S.InputText>
              <S.InputText>
                <label>{t(`${translate}.value`)}</label>
                <input
                  type="text"
                  placeholder="R$0,00"
                  name="value"
                  onChange={(event) => {
                    updateField(index, event);
                  }}
                  value={formatMoneyInput(dynamics[index].valor_unitario)}
                  required
                />
              </S.InputText>
            </div>
          );
        })}
      </div>
      <Button
        type="button"
        appearance="text"
        buttonText={t(`${translate}.add`)}
        icon={<Icons icon={plusIcon} title="add" />}
        onClick={() => {
          setDynamics([
            ...dynamics,
            {
              quantidade_minima: 1,
              valor_unitario: "",
            },
          ]);
        }}
      />
    </S.DynamicFirstFormContainer>
  );
};
