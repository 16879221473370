import styled from "styled-components";

export const EmptyStateUi = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: ${({ theme }) => theme.space[16]} auto;
  color: ${({ theme }) => theme.colors.greyScale40};

  img {
    width: 81px;
    height: 81px;
  }

  p {
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    font-size: ${({ theme }) => theme.fontSizes.web.default};
    text-align: center;
    margin-top: ${({ theme }) => theme.space[1]};
  }
`;
