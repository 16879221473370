import { SendDocuments } from "components/organisms/SendDocuments";

export const DocumentsForContract = () => {
  return (
    // Aqui será a tela de upload de documentos para contrato
    <div>
      <SendDocuments
        type="pending"
        documentName="Documento de identificação*"
        documentDescription="Envie uma foto (frente e verso) ou arquivo de um documento que conste o número do RG."
        rg
        enrollment={9764}
        documentType={1}
        userId={14578}
        userName="Rodrigo pai novamente"
      />
    </div>
  );
};
