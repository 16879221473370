import styled from "styled-components";
import * as colors from "styles/colors";

export const Container = styled.div`
  max-width: 992px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;

  .page-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      background: none;
      border: none;
      color: ${({ theme }) => theme.colors.primaryDefault};
      padding: 5px;
      border-radius: 99px;
      transition: all 0.2s ease;

      &:hover {
        background-color: ${({ theme }) => theme.colors.primaryLight90};
      }
    }
  }

  .modal-children {
    margin-left: auto;

    button {
      background: none;
      border: none;
      outline: none;
      width: fit-content;
      font-weight: 700;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.primaryDefault};
      margin-left: auto;
      padding: 8px 16px;

      &:hover {
        background-color: ${({ theme }) => theme.colors.primaryLight90};
      }
    }
  }

  .student-info {
    display: flex;
    margin-top: 20px;
    margin-bottom: 26px;

    img {
      width: 65px;
      height: 65px;
      border-radius: 99px;
      margin-right: 16px;
    }

    .details {
      strong {
        font-size: 32px;
        color: ${({ theme }) => theme.colors.brandingOrange};
      }

      p {
        font-size: 16px;
      }

      a {
        width: fit-content;
        font-size: 12px;
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        color: ${({ theme }) => theme.colors.primaryDefault};
        display: flex;
        align-items: center;
        gap: 6px;
        margin-top: 6px;
        text-decoration: underline;

        &:hover {
          color: ${({ theme }) => theme.colors.primaryLight80};
        }
      }
    }
  }

  .details-note {
    margin: 24px 8px;
  }

  h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 24px;
  }

  @media (max-width: 768px) {
    margin: 0 4px;

    h1 {
      font-size: 24px;
    }

    .student-info {
      .details {
        strong {
          font-size: 20px;
        }

        p {
          font-size: 14px;
        }
      }
    }
  }
`;
