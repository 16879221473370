import { LinearProgress } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { Button } from "components";
import { CalendarComponent } from "components/Calendar";
import { OnChange } from "react-final-form-listeners";
import SelectInput from "components/Select";
import HTTP_STATUS from "http-status-codes";
import { ClassDiaryInterface } from "interfaces/TeacherInterfaces";
import { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useHistory } from "react-router-dom";
import { getClassDiaries } from "services/classDiary.service";
import { PesquisaDeChamadaStyled } from "./style";
import { getAssignClass, AssignClassResponse } from "services/grades.service";
import { toastError } from "components/Toast";
import { authUserAtom } from "jotai/authUser";
import { useAtomValue } from "jotai";

export type GetClassDiaryParamsType = {
  user: number;
  gradegroup: number;
  date: string | Date;
};

function uniqBy<T>(a: Array<any>, key: (a: any) => number): T[] {
  let seen: any = {};
  return a.filter(function (item) {
    let k = key(item);
    return seen.hasOwnProperty(k) ? false : (seen[k] = true);
  });
}

export const PesquisaDeChamada = () => {
  const [uniqueClassDiaryUser, setUniqueClassDiaryUser] =
    useState<AssignClassResponse[]>();
  const [uniqueClassDiaryClasses, setUniqueClassDiaryClasses] =
    useState<AssignClassResponse[]>();

  const [loadingClassDiaries, setLoadingClassDiaries] = useState(false);
  const [loadingTeachers, setLoadingTeachers] = useState(false);
  const [classDiaries, setClassDiaries] =
    useState<Array<ClassDiaryInterface>>();
  const [error, setError] = useState(false);
  const [errorClassDiaries, setErrorClassDiaries] = useState(false);
  const authUser = useAtomValue(authUserAtom);
  const [filteredTurmas, setFilteredTurmas] = useState<AssignClassResponse[]>();
  const history = useHistory();

  useEffect(() => {
    const fetchTeachersData = async () => {
      setLoadingTeachers(true);
      const response = await getAssignClass({
        unit: authUser.grade_unit_id,
        user_id: authUser.id,
      });

      if (response && response.status === HTTP_STATUS.OK) {
        setLoadingTeachers(false);
        setUniqueClassDiaryUser(
          uniqBy<AssignClassResponse>(
            response.data,
            (a: AssignClassResponse) => a.user_id
          )
        );
        setUniqueClassDiaryClasses(response.data);
      } else {
        setError(true);
        setLoadingTeachers(false);
      }
    };

    try {
      fetchTeachersData();
    } catch (err) {
      setError(true);
      setLoadingTeachers(false);
    }
  }, [authUser.grade_unit_id, authUser.id]);

  const onSubmit = async (values: GetClassDiaryParamsType) => {
    setLoadingClassDiaries(true);
    setClassDiaries(undefined);
    const response = await getClassDiaries({
      user_id: values.user,
      gradegroup: values.gradegroup,
      date:
        typeof values.date === "string"
          ? values.date.split("T")[0]
          : values.date.toISOString().split("T")[0],
    });
    if (response && response.status === HTTP_STATUS.OK) {
      setClassDiaries(response.data);
    } else {
      toastError("Erro ao carregar os diários de classe");
      setErrorClassDiaries(true);
    }
    setLoadingClassDiaries(false);
  };

  return (
    <PesquisaDeChamadaStyled className="container">
      <div className="title">Pesquisa de chamada</div>
      {loadingTeachers && (
        <LinearProgress className="mt-3 mb-3" color="secondary" />
      )}
      {error && !loadingTeachers && (
        <div>Erro ao carregar professores e/ou turmas</div>
      )}
      {uniqueClassDiaryUser && uniqueClassDiaryClasses && !loadingTeachers && (
        <Form onSubmit={onSubmit}>
          {({ handleSubmit, form, submitting, values }) => (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-4">
                  <Field<string> component={SelectInput} name="user">
                    <option value="">Selecione um educador</option>
                    {uniqueClassDiaryUser
                      .sort((a, b) => (a.user_name > b.user_name ? 1 : -1))
                      .map((user, key) => {
                        return (
                          <option value={user.user_id} key={key}>
                            {user.user_name}
                          </option>
                        );
                      })}
                    );
                  </Field>

                  <OnChange name="user">
                    {async (value) => {
                      if (value) {
                        const filteredList = uniqueClassDiaryClasses.filter(
                          (item) => item.user_id.toString() === value.toString()
                        );
                        setFilteredTurmas(
                          uniqBy<AssignClassResponse>(
                            filteredList,
                            (a: AssignClassResponse) => a.gradegroup
                          )
                        );
                      }
                    }}
                  </OnChange>
                </div>

                <div className="col-4">
                  {values.user &&
                  filteredTurmas &&
                  filteredTurmas.length !== 0 ? (
                    <Field<string> component={SelectInput} name="gradegroup">
                      <option value="">Selecione uma turma</option>
                      {filteredTurmas
                        .sort((a, b) => a.grade - b.grade)
                        .map((tsg, key) => {
                          return (
                            <option value={tsg.gradegroup} key={key}>
                              {tsg.grade}º {tsg.letter} -{" "}
                              {tsg.educational_level_name}
                            </option>
                          );
                        })}
                    </Field>
                  ) : (
                    <div className="my-5">Este professor não possui turmas</div>
                  )}
                </div>

                <div className="col-4">
                  <Field
                    component={CalendarComponent}
                    name="date"
                    placeholder="Data"
                    start="Month"
                    min={new Date("2021-01-02")}
                    max={new Date()}
                    showTodayButton
                  />
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-12 text-right">
                  <Button
                    onClick={() => {
                      form.reset();
                      setError(false);
                    }}
                    type="button"
                    className="pink outline col-4 mr-3"
                  >
                    Limpar filtros
                  </Button>
                  <Button
                    type="submit"
                    className="col-4"
                    disabled={
                      (!values.date && !values.gradegroup && !values.user) ||
                      submitting
                    }
                  >
                    Filtrar
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Form>
      )}
      {loadingClassDiaries && (
        <LinearProgress className="mt-3 mb-3" color="secondary" />
      )}
      {errorClassDiaries && <div>Não há chamadas para estes filtros.</div>}
      {classDiaries && (
        <table className="mt-5 mb-5">
          <thead>
            <tr className="row">
              <th className="col-3">Turma</th>
              <th className="col-3">Disciplina</th>
              <th className="col-3">Professor</th>
              <th className="col-3">Data</th>
            </tr>
          </thead>
          <tbody>
            {classDiaries.map((classDiary, key) => {
              return (
                <tr className="row" key={key}>
                  <td className="col-3">
                    {`${classDiary.grade}º ${classDiary.letter} - ${classDiary.educational_level_name}`}
                  </td>
                  <td className="col-3">{classDiary.subject}</td>
                  <td className="col-3">{classDiary.user_name}</td>
                  <td className="col-3">
                    {classDiary.presence_date.split("-").reverse().join("/")}
                    <span className="float-right">
                      <EditIcon
                        onClick={() =>
                          history.push({
                            pathname: "/diario-de-classe",
                            state: {
                              classDiary,
                            },
                          })
                        }
                      />
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </PesquisaDeChamadaStyled>
  );
};
