//react
import { useContext, useEffect, useState } from "react";
// styles
import * as Style from "./style";
// libs
import { useTranslation } from "react-i18next";
import HTTP_STATUS from "http-status-codes";
// service
import { getUnitsGradesSchoolPlaces } from "services/units.service";
import { GetUnitsGradesResponse } from "interfaces/unit";
// components
import { WaitingListForm } from "./components";
import { Loading } from "components/atoms";

export const WaitingList = () => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.waitingList";

  const [units, setUnits] = useState<Array<GetUnitsGradesResponse>>();

  useEffect(() => {
    (async () => {
      const unitsGrade = await getUnitsGradesSchoolPlaces();
      if (unitsGrade && unitsGrade.status === HTTP_STATUS.OK) {
        setUnits(unitsGrade.data);
      }
    })();
  }, []);

  if (units) {
    return (
      <Style.ContainerWaitingList>
        <Style.Title>{t(`${TRANSLATE}.title`)}</Style.Title>
        <Style.Subtitle>{t(`${TRANSLATE}.subtitle`)}</Style.Subtitle>
        <WaitingListForm units={units} />
      </Style.ContainerWaitingList>
    );
  }
  return <Loading />;
};
