import { Modal } from "@escolamais-ui-v2/react";
import { CancelOutlined } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { ModalContainer } from "./styles";

interface IUndoQuarterPublicationModalProps {
  open: boolean;
  onCancel: () => void;
  onAction: () => void;
}

export const UndoQuarterPublicationModal = ({
  open,
  onCancel,
  onAction,
}: IUndoQuarterPublicationModalProps) => {
  const prefix = "pages.AP.publishBoletim.undoQuarterPublicationModal";
  const { t } = useTranslation();
  return (
    <ModalContainer>
      <Modal
        open={open}
        handleOnClickOutside={onCancel}
        handleOnClickCancel={onCancel}
        handleOnClickConfirm={onAction}
        buttonConfirmText={t(`${prefix}.confirmText`)}
        buttonCancelText={t(`${prefix}.cancelText`)}
        title={t(`${prefix}.title`)}
        icon={<CancelOutlined width={24} height={24} />}
      >
        <div>
          <p>{t(`${prefix}.description`)}</p>
        </div>
      </Modal>
    </ModalContainer>
  );
};
