import styled from "styled-components";
import * as colors from "styles/colors";

export const StudentFoodCardStyled = styled.div`
  width: 328px;
  padding: 1em;
  margin-bottom: 1em;

  border: 2px solid ${colors.NEW_PURPLE};

  margin-right: 1em;

  div.loading {
    display: flex;
    justify-content: center;
  }

  div.studentInfo {
    display: flex;
    width: 100%;

    div.info {
      margin-left: 0.5em;
      div.subText {
        display: flex;

        p.grade {
          margin-left: 1.5em;
        }
      }
    }
  }

  div.noPackage {
    margin-top: 1.5em;
    div.button {
      margin-bottom: 1em;
      height: 37px;
      display: flex;
      justify-content: flex-end;
    }
  }

  div.selectedItems {
    div.removeThis {
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      width: 171px;
      height: 26px;
      padding: 4px 8px;
      background-color: orange;
      border-radius: 4px;

      margin-top: 28px;
      margin-bottom: 8px;
    }

    div.packageItems {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    div.schoolDays {
      display: flex;
      justify-content: flex-end;

      border-bottom: 1px solid ${colors.GRAY_SCALE_TWO};
    }

    div.totalValue {
      margin-top: 0.5em;
      display: flex;
      justify-content: space-between;
      height: 27px;
    }

    div.spaceInLine {
      display: flex;
      justify-content: space-between;

      &.marginTop {
        margin-top: ${({ theme }) => theme.space[4]};
      }

      p.paymentDate {
        margin-top: ${({ theme }) => theme.space[2]};
        color: ${({ theme }) => theme.colors.greyScale40};
      }
    }

    div.button {
      button.editButton {
        margin-right: 4px;
      }
      margin-bottom: 1em;
      height: 37px;
      display: flex;
      justify-content: flex-end;
    }
  }
`;
