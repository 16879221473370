import styled from "styled-components";
import * as colors from "styles/colors";

export const StudentCardBox = styled.div`
  display: flex;
  flex-direction: column;

  background-color: rgba(226, 226, 226, 0.322);

  width: 100%;
  margin-bottom: 10px;
  padding: 10px;

  border: 1px solid;
  border-radius: 9px;
  border-color: rgba(70, 70, 70, 0.322);

  div.studentName {
    width: 70%;
    font-weight: bold;
    color: ${colors.BLUE};
  }

  div.viewPackageInfos {
    width: 30%;
    display: flex;
    justify-content: end;
    color: ${colors.PINK};
    cursor: pointer;
  }

  div.studentInfo {
    font-weight: 400;
    font-size: 12px;
    opacity: 60%;
  }

  div.guardianName {
    font-weight: 500;
    color: ${colors.GRAY};
  }

  div.packageMonth {
    font-weight: bold;
    color: ${colors.GRAY};
  }

  div.activePackage {
    font-size: 12px;
    padding: 1px 3px 0 3px;
    background-color: ${colors.LIGHT_GREEN};
    color: ${colors.WHITE};
    border-radius: 4px;
  }

  div.pendingPayment {
    font-size: 12px;
    padding: 1px 3px 0 3px;
    background-color: ${colors.YELLOW};
    color: ${colors.GRAY_TWO};
    border-radius: 4px;
  }

  div.disabledPackage {
    font-size: 12px;
    padding: 1px 3px 0 3px;
    background-color: ${colors.RED_TWO};
    color: ${colors.WHITE};
    border-radius: 4px;
  }

  div.viewMoreInfos {
    font-size: 12px;
    color: ${colors.GRAY_TWO};
  }

  div.totalValue {
    font-weight: 600;
  }

  div.cancelFoodPackageButton {
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    color: ${colors.PINK};
    &:hover {
      cursor: pointer;
      color: ${colors.PURPLE};
    }
  }
`;
