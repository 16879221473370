import { LinearProgress } from "@material-ui/core";
import { Button } from "components";
import { anyDrawerItem } from "components/Drawer/constants";
import { isFirstAccess } from "helpers/constants";
import HTTP_STATUS from "http-status-codes";
import { GetCXUserJson } from "interfaces/constants";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { getCXUser } from "services/users.service";
import { UserData } from "./components/UserData";
import { UserForm } from "./components/UserForm";
import { LinkStyled, Subheading } from "./style";
import { Storage } from "Storage";
import { useAtomValue } from "jotai";
import { authUserAtom } from "jotai/authUser";

export const Profile = () => {
  const [user, setUser] = useState<GetCXUserJson | undefined>(undefined);
  const [error, setError] = useState(false);
  const [edit, setEdit] = useState(true);
  const [loading, setLoading] = useState(true);
  const urlParams = new URLSearchParams(window.location.search);
  const guardianId = urlParams.get("guardianId");
  const authUser = useAtomValue(authUserAtom);
  const role = Storage.props.role.get();

  const history = useHistory();

  type GetCXUserJsonType = {
    data: GetCXUserJson;
    status: number;
  };

  useEffect(() => {
    getCXUser({
      id: (guardianId && parseInt(guardianId)) || authUser.id,
    })
      .then(({ data, status }: GetCXUserJsonType) => {
        if (status === HTTP_STATUS.OK) {
          setUser(data);
        } else {
          setError(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [guardianId, authUser]);

  return (
    <div className={`${!anyDrawerItem(role) && "container"}`}>
      <div className="row">
        <div className="col-sm-2 col-6">
          <Subheading>Meus dados</Subheading>
        </div>
        {loading && (
          <>
            <LinearProgress className="mt-3 mb-3" color="secondary" />
            <div>Carregando...</div>
          </>
        )}
        <div className={`col-6 pr-5 ${isMobile && "text-right"}`}>
          {loading === false && !edit && (
            <LinkStyled onClick={() => setEdit(!edit)}>Editar</LinkStyled>
          )}
        </div>
      </div>
      {!error && !edit && <UserData user={user} />}
      {!error && edit && !loading && user && (
        <UserForm setUser={setUser} user={user} setEdit={setEdit} />
      )}
      {isFirstAccess(authUser, role) && (
        <Button onClick={() => history.goBack()} type="button">
          Voltar à tela para confirmar dados
        </Button>
      )}
      {error && <div>Erro ao carregar os dados do usuário.</div>}
    </div>
  );
};
