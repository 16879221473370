import * as Style from "./icons.style";
import { IconProps } from "./icons.type";

export const Icons = ({
  icon,
  title,
  onClick,
  width,
  height,
  unity,
  padding,
}: IconProps) => {
  return (
    <Style.IconSvg
      width={width}
      height={height}
      unity={unity}
      src={icon}
      padding={padding}
      alt="Icone"
      title={`Icone indicativo da pagina ${title}`}
      onClick={() => onClick && onClick()}
    />
  );
};
