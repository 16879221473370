import { Radio as MaterialUiRadio } from "@material-ui/core";
import { FieldRenderProps } from "react-final-form";

type Props = FieldRenderProps<string, any>;

const Radio: React.FC<Props> = ({ input, meta, ...rest }) => (
  <MaterialUiRadio type="radio" {...input} {...rest} />
);

export default Radio;
