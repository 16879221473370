// React
import { useCallback, useContext, useEffect, useState } from "react";
// Libs
import HTTP_STATUS from "http-status-codes";
import { useTranslation } from "react-i18next";
// Services
import { getUnits } from "services/units.service";
import {
  exitReportDownload,
  getExitPermitReport,
  getTransportation,
  postRegisterEscort,
  removeEscort,
  updateEscort,
} from "services/exitPermit.services";
// Interfaces
import { UnitInterface } from "interfaces/unit";
import { IExitPermitList, ITransportation } from "interfaces/exitPermit";
import { IHelper, ILocation, ILocationData, IPagination } from "./interface";
// Components
import { toastError, toastSuccess, toastWarning } from "components/Toast";
import { deviceUsed, onlyNumberMask } from "helpers/helpers";
import axios from "axios";

export const ExitReportHelper = ({
  form,
  modalForm,
  studentSelected,
  setModalForm,
  setModal,
}: IHelper) => {
  const { t } = useTranslation();
  const prefix = "pages.EDF.exitReport.helper";
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // Dados iniciais
  const [units, setUnits] = useState<Array<UnitInterface>>();
  const [exitPermit, setExitPermit] = useState<ITransportation>();

  // dados da tabela
  const [studentList, setStudentList] = useState<IExitPermitList | null>();
  // Loadings após montagem da tela
  const [submit, setSubmit] = useState(false);

  // Dados de localização/dispositivo --- fazer um context com base nesse cara
  const [userLocation, setUserLocation] = useState<ILocationData>({
    location: null,
    dispositivo: deviceUsed(navigator.userAgent)[0],
  });

  // Nova procura pela localização
  const [newSearch, setNewSearch] = useState(true);

  // Paginação
  const [pagination, setPagination] = useState<IPagination>({
    count: 0,
    previous: "",
    next: "",
    page: 1,
    firstStudent: 1,
    lastStudent: 0,
  });

  const setModalFormProps = useCallback(() => {
    setModalForm({
      ...modalForm,
      name: "",
      kinship: "",
      cpf: "",
      phone: "",
      escort: 0,
    });
  }, [modalForm, setModalForm]);

  const location = useCallback(() => {
    (async () => {
      const deviceData = await axios.get<ILocation>(
        "https://geolocation-db.com/json/"
      );
      if (deviceData?.status === HTTP_STATUS.OK) {
        setUserLocation({ ...userLocation, location: deviceData.data });
      }
    })();
  }, [userLocation]);

  useEffect(() => {
    if (newSearch) {
      location();
      setNewSearch(false);
    } else {
      setTimeout(() => !userLocation.location && setNewSearch(true), 10000);
    }
  }, [location, newSearch, userLocation.location]);

  useEffect(() => {
    (async () => {
      try {
        const units = await getUnits();
        if (units && units.status === HTTP_STATUS.OK) {
          setUnits(units.data);
        }
        const transportation = await getTransportation();
        if (transportation && transportation.status === HTTP_STATUS.OK) {
          setExitPermit(transportation.data);
        }

        location();

        setLoading(false);
      } catch (error) {
        setLoading((loading) => !loading);
        setError((error) => !error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const studentListResults = useCallback(async () => {
    const response = await getExitPermitReport({
      student_name: form.student_name,
      unit: form.unit,
      exit_permit: form.exit_authorization,
    });
    if (response && response.status === HTTP_STATUS.OK) {
      setStudentList(response.data);
      setSubmit(false);
      setPagination({
        ...pagination,
        count: response.data.count,
        previous: response.data.previous,
        next: response.data.next,
        lastStudent: response.data.results.length,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    if (form.unit) {
      (async () => {
        setStudentList(null);
        setSubmit(true);

        studentListResults();
      })();
    }
  }, [form, studentListResults]);

  const download = async () => {
    if (form.unit) {
      setSubmit(true);

      const response = await exitReportDownload({
        student_name: form.student_name,
        exit_authorization: form.exit_authorization,
        unit: form.unit,
      });
      if (response && response.status === HTTP_STATUS.OK) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement("a");
        a.href = url;
        a.download = `relatorio-de-saida.xlsx`;
        a.click();

        setSubmit(false);
      } else {
        toastError(t(`${prefix}.toastError`));
        setSubmit(false);
      }
    } else {
      toastWarning(t(`${prefix}.toastWarning`));
    }
  };

  const researchProps = useCallback(async () => {
    const response = await getExitPermitReport({
      page: pagination.page,
      student_name: form.student_name,
      unit: form.unit,
      exit_permit: form.exit_authorization,
    });
    setStudentList(response?.data);
  }, [form, pagination.page]);

  const handleRegisterEscort = async () => {
    if (userLocation.location && studentSelected) {
      const response = await postRegisterEscort({
        payload: {
          nome_completo: modalForm.name,
          grau_parentesco: modalForm.kinship,
          cpf: onlyNumberMask(modalForm.cpf),
          telefone: onlyNumberMask(modalForm.phone),
          estudante: studentSelected.id,
          localizacao: {
            ipv4: userLocation.location.IPv4,
            cidade: userLocation.location.city,
            estado: userLocation.location.state,
            pais: userLocation.location.country_name,
            cep: userLocation.location.postal,
            latitude: userLocation.location.latitude,
            longitude: userLocation.location.longitude,
            dispositivo: userLocation.dispositivo,
          },
        },
      });
      if (response?.status === HTTP_STATUS.CREATED) {
        toastSuccess(t(`${prefix}.toastSuccessAddEscort`));
        setModalFormProps();
        setModal(false);
      } else if (response?.status === HTTP_STATUS.BAD_REQUEST) {
        // Só existe cpf na tratativa de erro que o back retorna
        toastError(response.data.cpf[0]);
      } else {
        toastError(t(`${prefix}.toastErrorAddEscort`));
      }
      researchProps();
    }
  };

  const handleEditEscort = async () => {
    if (userLocation.location) {
      const response = await updateEscort({
        payload: {
          nome_completo: modalForm.name,
          grau_parentesco: modalForm.kinship,
          cpf: onlyNumberMask(modalForm.cpf),
          telefone: onlyNumberMask(modalForm.phone),
          localizacao: {
            ipv4: userLocation.location.IPv4,
            cidade: userLocation.location.city,
            estado: userLocation.location.state,
            pais: userLocation.location.country_name,
            cep: userLocation.location.postal,
            latitude: userLocation.location.latitude,
            longitude: userLocation.location.longitude,
            dispositivo: userLocation.dispositivo,
          },
        },
        escort_id: modalForm.escort,
      });
      if (response?.status === HTTP_STATUS.OK) {
        toastSuccess(t(`${prefix}.toastSuccessEditEscort`));
        setModalFormProps();
        setModal(false);
        researchProps();
      } else {
        toastError(t(`${prefix}.toastErrorEditEscort`));
      }
    }
  };

  const handleDeletEscort = async (escort_id: number) => {
    const response = await removeEscort({
      escort_id,
    });
    if (response?.status === HTTP_STATUS.OK) {
      toastSuccess(t(`${prefix}.toastSuccessRemoveEscort`));
      setModalFormProps();
      researchProps();
    } else {
      toastError(t(`${prefix}.toastErrorRemoveEscort`));
    }
  };

  const setPaginationProps = useCallback(
    (value: string, response: IExitPermitList) => {
      if (value === "foward") {
        setPagination({
          ...pagination,
          firstStudent:
            pagination.page + 1 === 2
              ? pagination.firstStudent + response.results.length - 1
              : pagination.firstStudent + response.results.length,
          lastStudent: pagination.lastStudent + response.results.length,
          page: pagination.page + 1,
          previous: response.previous,
          next: response.next,
        });
      } else {
        setPagination({
          ...pagination,
          firstStudent:
            pagination.page - 1 === 1
              ? 1
              : pagination.firstStudent - response.results.length,
          lastStudent: pagination.lastStudent - response.results.length,
          page: pagination.page - 1,
          previous: response.previous,
          next: response.next,
        });
      }
      setStudentList(response);
    },
    [pagination]
  );

  // Pagination
  const Offset = async (direction: string) => {
    setStudentList(null);
    const response = await getExitPermitReport({
      page: direction === "foward" ? pagination.page + 1 : pagination.page - 1,
      student_name: form.student_name,
      unit: form.unit,
      exit_permit: form.exit_authorization,
    });
    if (response && response.status === HTTP_STATUS.OK) {
      setPaginationProps(direction, response.data);
    } else {
      toastError(t(`${prefix}.pagnationError`));
    }
  };

  return {
    pagination,
    loading,
    error,
    units,
    exitPermit,
    submit,
    studentList,
    setModalFormProps,
    studentListResults,
    download,
    handleDeletEscort,
    handleRegisterEscort,
    handleEditEscort,
    Offset,
    setPagination,
  };
};
