import { LinearProgress } from "@material-ui/core";
import { Button } from "components";
import { ReducedHorizontalStepper } from "components/Timeline";
import { toastError, toastSuccess } from "components/Toast";
import { FINANCIAL_STATUS } from "helpers/constants";
import HTTP_STATUS from "http-status-codes";
import {
  GuardianInterface,
  StudentInterface,
  StudentDataInterface,
} from "interfaces/constants";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { updateEnrollment } from "services/enrollment.service";
import { getStudentData } from "services/users.service";

type StateType = {
  student: StudentInterface;
  guardian: GuardianInterface;
};

export const KeepOrChangeFinancialResponsible = () => {
  const location = useLocation<StateType>();
  const [student] = useState(location.state.student);
  const [guardian] = useState(location.state.guardian);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [timelineStep] = useState(1);

  const [studentInfos, setStudentInfos] = useState<StudentDataInterface>();
  const [enrollmentId, setEnrollmentId] = useState<number>();
  const [checkSerasaFalse] = useState<boolean>(true);

  const history = useHistory();

  useEffect(() => {
    if (student && guardian) {
      const getTheDocuments = async () => {
        const getStudentLocation = await getStudentData({
          id: student.id,
        });

        if (getStudentLocation) {
          setStudentInfos(getStudentLocation.data);
          setLoading(false);
        }
      };
      getTheDocuments();

      if (student.last_enrollment) {
        setEnrollmentId(student.last_enrollment.id);
      }
    } else {
      setError(true);
    }
  }, [guardian, student]);

  const onSubmit = async () => {
    const updateStatus = await updateEnrollment({
      enrollment_id: enrollmentId,
      body: {
        financial_status: FINANCIAL_STATUS.AGUARDANDO_PLANO_PAGAMENTO,
        user_id: guardian.user_id,
        user_name: guardian.personal.name,
      },
    });

    if (updateStatus) {
      if (HTTP_STATUS.OK) {
        toastSuccess("Status da matrícula atualizado com sucesso!");
        history.push({
          pathname: `/financeiro/plano-pagamento`,
          state: { student, guardian },
        });
      } else {
        toastError("Erro! Tente novamente mais tarde.");
      }
    }
  };

  return (
    <div className="container">
      {loading && <LinearProgress className="mt-3 mb-3" color="secondary" />}
      {error && <div>Erro ao carregar estudante.</div>}
      {student && studentInfos && (
        <div>
          <div className="enrollment-title text-center">
            Matrícula de {student.name}
            <div className="subtitle">
              {student.last_enrollment?.grade || "Sem ano"}
              {student.last_enrollment?.grade && "º ano do "}
              {student.last_enrollment?.educational_level_name} - Unidade{" "}
              {student.last_enrollment?.unit_name}
            </div>
          </div>
          <div className="row">
            <div className="offset-sm-4 col-sm-4 col-12">
              <ReducedHorizontalStepper step={timelineStep} />
            </div>
          </div>

          <p className="text-center">
            Precisamos confirmar quem será o responsável financeiro da
            rematrícula de {student.name}:
          </p>
          <div className="row">
            <div className="offset-sm-3 col-sm-6 col-12">
              <Button onClick={() => onSubmit()} className="blue" type="button">
                Continuar com{" "}
                {
                  studentInfos.guardians.find(
                    (g: any) =>
                      g.guard_info.guard_id ===
                      studentInfos.last_enrollment.guard_id
                  )?.name
                }
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="offset-sm-3 col-sm-6 col-12">
              <Button
                onClick={() =>
                  history.push({
                    pathname: `/responsavel/meus-alunos/responsavel-financeiro`,
                    state: {
                      student,
                      guardian,
                      checkSerasaFalse,
                    },
                  })
                }
                className="blue outline"
                type="button"
              >
                Trocar responsável financeiro
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
