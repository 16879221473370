import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ModalInfo } from "components";
import * as S from "./styles";

export const DisapprovalModal = ({
  isOpen,
  closeModal,
  documentoId,
  handleDisapproveFile,
}: any) => {
  const { t } = useTranslation();
  const i18n_prefix = "pages.EDF.userDocuments.components.disapprovalModal";
  const [disapprovalReason, setDisapprovalReason] = useState("");

  const handleSubmit = () => {
    handleDisapproveFile(documentoId, disapprovalReason);

    closeModal(false);
  };

  return (
    <S.Container>
      <ModalInfo
        isOpen={isOpen}
        title={t(`${i18n_prefix}.modalTitle`)}
        content={t(`${i18n_prefix}.modalContent`)}
        width="343px"
      >
        <div className="modal-content">
          <input
            type="text"
            placeholder={t(`${i18n_prefix}.reasonDisapproval`)}
            value={disapprovalReason}
            onChange={(e) => setDisapprovalReason(e.target.value)}
          />
          <div className="modal-buttons">
            <button onClick={() => closeModal(false)}>
              {t(`${i18n_prefix}.cancelButton`)}
            </button>
            <button className="submit-btn" onClick={handleSubmit}>
              {t(`${i18n_prefix}.submitButton`)}
            </button>
          </div>
        </div>
      </ModalInfo>
    </S.Container>
  );
};
