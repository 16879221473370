import { useState, useEffect } from "react";
import { GuardianSignUpForm } from "components/Forms";
import { PublicNavHeader } from "components";
import { registerNewGuardianUser } from "services/guardian.service";

export const GuardianSignup = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const cpf = urlParams.get("cpf");
  const outOfApp = urlParams.get("outofapp");

  const [step, setStep] = useState(1);
  const [isCompleted, setCompleted] = useState(false);

  //TO-DO: melhor lógica pra controlar o estado dos forms em relação aos steps;
  // Talvez um useReducer aqui.

  useEffect(() => {
    if (isCompleted) {
      setStep((s) => s + 1);
      setCompleted(false);
    }
  }, [isCompleted]);

  return (
    <>
      <PublicNavHeader outOfApp={outOfApp ? true : false}>
        {step === 1 && outOfApp
          ? "Cadastro de Responsável FORA DO APLICATIVO"
          : "Cadastro de Responsável"}
        {step === 2 && "Cadastro de Aluno"}
      </PublicNavHeader>
      <div className="pt-5" />
      <GuardianSignUpForm
        cpf={cpf}
        endpointSaveGuardian={registerNewGuardianUser}
      />
    </>
  );
};
