import { useState } from "react";
import HTTP_STATUS from "http-status-codes";
import { Button, TextInput } from "components";
import { Field, Form } from "react-final-form";
import {
  composeValidators,
  required,
  samePassword,
} from "helpers/fieldValidators";
import loadingGif from "static/img/loading.gif";
import { changePassword, changeUserPassword } from "services/auth.service";
import { toastError, toastSuccess } from "components/Toast";
import { useHistory, useLocation } from "react-router-dom";
import { useAtomValue } from "jotai";
import { authUserAtom } from "jotai/authUser";

export const GuardianUpdatePassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const authUser = useAtomValue(authUserAtom);
  const urlParams = new URLSearchParams(window.location.search);
  const guardianId = urlParams.get("guardianId");
  const guardianAuthId = urlParams.get("guardianAuthId");

  const history = useHistory();
  const location: any = useLocation();

  const onSubmit = async (values: any) => {
    if (location.state?.guardianId) {
      await changeUserPassword({
        email: location.state.guardianEmail,
        new_password: values.new_password,
      }).then((response: any) => {
        if (response.status === HTTP_STATUS.OK) {
          toastSuccess("Senha atualizada com sucesso");
          history.goBack();
        } else {
          toastError("Erro ao atualizar a senha. Tente novamente mais tarde");
        }
      });
    } else {
      values.email = authUser.email;
      await changePassword(values).then((response) => {
        if (response!.status === HTTP_STATUS.OK) {
          toastSuccess("Senha atualizada com sucesso");
          if (guardianId) {
            history.push(
              `/responsavel/editar?guardianAuthId=${guardianAuthId}&guardianId=${guardianId}`,
              { passwordChanged: true }
            );
          } else {
            history.push("/home");
          }
        } else {
          toastError("Erro ao atualizar a senha. Tente novamente mais tarde");
        }
      });
    }
  };

  return (
    <div className="container">
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            {!location.state?.guardianId && (
              <Field
                component={TextInput}
                name="password"
                placeholder=" "
                type={showConfirmPassword ? "text" : "password"}
                label="Senha atual"
                showPassword={showConfirmPassword}
                setShowPassword={setShowConfirmPassword}
                validate={composeValidators(required)}
                required
              />
            )}

            <div className="row">
              <div className="col-sm-6 col-12">
                <Field
                  component={TextInput}
                  name="new_password"
                  placeholder=" "
                  type={showPassword ? "text" : "password"}
                  label="Nova senha"
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                  validate={required}
                  required
                />
              </div>
              <div className="col-sm-6 col-12">
                <Field
                  component={TextInput}
                  name="confirm_password"
                  placeholder=" "
                  type={showConfirmPassword ? "text" : "password"}
                  label="Confirmar senha"
                  showPassword={showConfirmPassword}
                  setShowPassword={setShowConfirmPassword}
                  validate={composeValidators(
                    required,
                    samePassword(values.new_password)
                  )}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-3 offset-sm-9 col-12">
                <Button type="submit" disabled={submitting}>
                  {!submitting && "Salvar"}
                  {submitting && (
                    <img height="25" src={loadingGif} alt="loading" />
                  )}
                </Button>
              </div>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};
