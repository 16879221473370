import { Login, PublicPasswordRecovery, PoliticaPrivacidade } from "pages/CORE";
import {
  ResetSenhaToken,
  ContractFinalization,
  GuardianSignup,
} from "pages/EDF";
import { FirstAccessPassword } from "pages/EDF/FirstAccessPassword";

interface RouteType {
  path: string;
  component: React.FC;
  exact: boolean;
}

interface PublicRoutesType extends Array<RouteType> {}

export const publicRoutes: PublicRoutesType = [
  {
    component: Login,
    exact: true,
    path: "/",
  },
  {
    component: GuardianSignup,
    exact: true,
    path: "/responsavel/cadastro",
  },
  {
    component: PublicPasswordRecovery,
    exact: true,
    path: "/esqueci-minha-senha",
  },
  {
    component: ResetSenhaToken,
    exact: true,
    path: "/resetar-senha",
  },
  // {
  //   component: FirstAccessPassword,
  //   exact: true,
  //   path: "/resetar-senha",
  // },
  {
    component: PoliticaPrivacidade,
    exact: true,
    path: "/politica-privacidade",
  },
];
