import { useHistory } from "react-router-dom";
import { SecretaryContainer } from "./style";
import { ButtonCard } from "components/atoms/ButtonCard";
import { useTranslation } from "react-i18next";
import { IsDevelopmentEnvironment } from "helpers/helpers";

const TRANSLATE_PREFIX = "pages.CORE.home.secretary";

export const SecretaryHome = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <SecretaryContainer>
      <ButtonCard
        onClick={() => history.push("/responsavel/Consultar/documentos")}
        text={t(`${TRANSLATE_PREFIX}.consultDocuments`)}
      />
      <ButtonCard
        onClick={() => history.push("/responsavel/busca")}
        text={t(`${TRANSLATE_PREFIX}.searchGuardianOrStudent`)}
      />
      <ButtonCard
        onClick={() => history.push("/registro-de-alimentacao")}
        text={t(`${TRANSLATE_PREFIX}.singleFoodRegistration`)}
      />
      <ButtonCard
        onClick={() => history.push("/relatorio-de-alimentacao")}
        text={t(`${TRANSLATE_PREFIX}.feedReport`)}
      />
      <ButtonCard
        onClick={() => history.push("/historico-dos-boletins")}
        text={t(`${TRANSLATE_PREFIX}.boletinsHistory`)}
      />
      <ButtonCard
        onClick={() => history.push("/autorizacao-de-saida")}
        text={t(`${TRANSLATE_PREFIX}.exitReport`)}
      />
      <ButtonCard
        onClick={() => history.push("/consultar-pedidos-alimentacao")}
        text={t(`${TRANSLATE_PREFIX}.consultFoods`)}
      />
      {IsDevelopmentEnvironment() && (
        <ButtonCard
          onClick={() => history.push("/gerenciar-itens-alimentacao")}
          text={t(`${TRANSLATE_PREFIX}.manageDynamicFoodItems`)}
        />
      )}
      <ButtonCard
        onClick={() => history.push("/gerenciar-vagas")}
        text={t(`${TRANSLATE_PREFIX}.manageVacancies`)}
      />
      <ButtonCard
        onClick={() => history.push("/roteiros-de-estudo/estudante")}
        text={t(`${TRANSLATE_PREFIX}.studyItinerary`)}
      />
    </SecretaryContainer>
  );
};
