import * as Style from "./style";

import { Title, InputOnBlur, Loading, Icons } from "components/atoms";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useBulletin } from "context/Bulletin";
import { TableHistorySchoolReport } from "./Table/";
import { ChangeEvent, FocusEvent } from "react";

const prefix = "pages.AP.history";

export const HistorySchoolReportPage = () => {
  const {
    handleSearchHistorySchoolReport,
    loadingHistorySchoolReport,
    historySchoolReport,
  } = useBulletin();
  const history = useHistory();
  const { t } = useTranslation();

  const handleSearch = (value: string) => {
    if (value.length > 2) {
      switch (true) {
        case value.startsWith("A-"):
          handleSearchHistorySchoolReport(
            `classes/student_previous_reports?name&ra=${value}&page_size=20`
          );
          break;
        default:
          handleSearchHistorySchoolReport(
            `classes/student_previous_reports?name=${value}&page_size=20`
          );
          break;
      }
    }
  };

  const handleClearSearch = () => {
    handleSearchHistorySchoolReport(
      `classes/student_previous_reports?name&ra=0`
    );
  };

  const displayTable = (loading: boolean) => {
    switch (loading) {
      case true:
        return <Loading />;
      case false:
        return historySchoolReport && historySchoolReport.results.length > 0 ? (
          <>
            <TableHistorySchoolReport data={historySchoolReport} />
          </>
        ) : (
          <>{t(`${prefix}.default`)}</>
        );
      case undefined:
        return <>{t(`${prefix}.default`)}</>;
    }
  };

  return (
    <Style.Container>
      <Style.Wrapper>
        <Style.Section align="center" direction="row" justify="flex-start">
          <Icons
            icon="/icons/arrow-left.svg"
            padding
            onClick={() => history.goBack()}
          />
          <Title
            fontWeight="700"
            lineHeight="1"
            marginBottom="0"
            responsive
            pointer
          >
            {t(`${prefix}.title`)}
          </Title>
        </Style.Section>
        <Style.Section align="center" direction="row" justify="flex-start">
          <InputOnBlur
            width="40%"
            label={t(`${prefix}.search`)}
            onChange={(e: FocusEvent<HTMLInputElement>) =>
              handleSearch(e.target.value)
            }
            onClear={() => handleClearSearch()}
          />
        </Style.Section>
        <Style.Section
          align="flex-start"
          direction="column"
          justify="flex-start"
        >
          <Title darkGray fontSize="20px">
            {t(`${prefix}.result`)}
          </Title>
          <Style.Section align="center" direction="row" justify="flex-start">
            {displayTable(loadingHistorySchoolReport)}
          </Style.Section>
        </Style.Section>
      </Style.Wrapper>
    </Style.Container>
  );
};
