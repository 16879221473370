import { Button } from "components";
import { ModalDiv } from "components/UseTerms/styles";
import { GetCXUserJson } from "interfaces/constants";

interface ModalDataInterface {
  setToggleModal: (toggleModal: boolean) => void;
  guardian: GetCXUserJson;
  cpf?: boolean;
  cep?: boolean;
  outOfDate?: boolean;
}

export const ModalDataValidation = ({
  setToggleModal,
  guardian,
  cpf,
  cep,
  outOfDate,
}: ModalDataInterface) => {
  return (
    <div className="container">
      <ModalDiv>
        <div className="text-center">
          <strong className="title">
            Por favor, verifique os seguintes dados do responsável:
          </strong>
          <p>
            {(!guardian.personal.birth_date ||
              guardian.personal.birth_date.length !== 10) &&
              "Data de nascimento,"}{" "}
            {cpf && "CPF,"} {cep && "CEP,"}{" "}
            {!guardian.location.address && "Endereço,"}{" "}
            {!guardian.location.number && "Número,"}{" "}
            {!guardian.location.neighborhood && "Bairro,"}{" "}
            {outOfDate && "Telefone,"} {!guardian.location.uf && "Estado"}
          </p>
        </div>
        <Button onClick={() => setToggleModal(false)} className="blue outline">
          Voltar
        </Button>
      </ModalDiv>
    </div>
  );
};
