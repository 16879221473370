import styled from "styled-components";

export const SignupCompletedStyled = styled.div`
  div.title {
    color: #333333;
    font-size: 20px;
    font-weight: bold;
  }

  p {
    color: #666666
    font-size: 14px;
    
    &.subtitle {
      color: #333333;
      font-size: 16px;
      font-weight: 500;
    }

    span {
      color: #333333;
    }
  }

  div.edit-box {
    padding: 1em;
    border: 1px solid #333333;
    span.link {
      color: #4285F4;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  div.await-list {
    p {
      font-size: 20px;
      font-weight: bold;

      &.position {
        color: #EC008C;
      }
    }
  }
`;
