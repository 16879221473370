import styled from "styled-components";

export const ModalSimilarStudentsStyled = styled.div`
  font-size: 14px;
  text-align: justify;
  color: #666666;

  @media only screen and (max-width: 414px) {
    div.col-1 {
      padding: 0;
    }
  }

  strong {
    color: #333333;
    font-size: 16px;
    &.title {
      font-size: 20px;
    }
  }

  b {
    color: #333333;
  }
`;
