import { ContainerProps } from "./types";
import * as S from "./style";

export const Container = ({
  display,
  direction,
  align,
  children,
}: ContainerProps) => {
  return (
    <S.Container display={display} direction={direction} align={align}>
      {children}
    </S.Container>
  );
};
