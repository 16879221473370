import { UnitInterface } from "interfaces/unit";

interface CalendarUnit {
  unidade_id: number;
  unidade_nome: string;
}

export const enum ICalendarDaySubtitle {
  "Dia letivo" = "Dia letivo",
  "Dia sem aula" = "Dia sem aula",
  "Feriado" = "Feriado",
  "Marco Pedagógico" = "Marco Pedagógico",
}
interface CalendarEventSubType {
  id: number;
  nome: string;
  tipo: number;
  tem_input: boolean;
}

interface CalendarEvent {
  id: number;
  nome: string;
  calendario: number;
  data_ini: string;
  data_fim: string;
  tipo: number;
  subtipo: CalendarEventSubType | null;
}
export interface CalendarDay {
  id: number;
  data: string;
  legenda: ICalendarDaySubtitle;
  eventos: CalendarEvent[];
}

export interface ICalendar {
  id: number;
  nome: string;
  ano: number;
  unidades: CalendarUnit[];
}

export interface IGetUniqueCalendarResponse {
  id: number;
  nome: string;
  ano: number;
  total_dias_letivos: number;
  unidades: CalendarUnit[];
  dias: CalendarDay[];
}

export interface CalendarListProps {
  calendars: ICalendar[];
  activeId: number;
  onSelectCalendar: (calendar: ICalendar) => void;
}

export interface ICreateNewCalendarData {
  name: string;
  unitsList: UnitInterface[];
}

export interface ICalendarMonthWeeksReduceArray {
  week: number;
  days: CalendarDay[];
}

export interface IMonth {
  month: string;
  days: CalendarDay[];
  weeks: ICalendarMonthWeeksReduceArray[];
}
export interface ICalendarWithMonths extends IGetUniqueCalendarResponse {
  months: IMonth[];
}

export interface SchoolCalendarState {
  loaders: {
    calendars: boolean;
    uniqueCalendar: boolean;
    newCalendarCreated: boolean;
  };
  drawers: {
    isOpenNewCalendar: boolean;
    isOpenNewSchoolDays: boolean;
    isOpenNewDaysWithoutSchool: boolean;
    isOpenNewHolidays: boolean;
  };
  calendars: {
    data: ICalendar[] | undefined;
    error: boolean;
    success: boolean;
  };
  uniqueCalendar: {
    data: ICalendarWithMonths | undefined;
    error: boolean;
    success: boolean;
  };
  selectedCalendar: number | undefined;
  newCalendarCreated: {
    data: ICreateNewCalendarData | undefined;
    error: boolean;
    success: boolean;
  };
  unitsWithCalendar: number[];
  calendarSubtitles: ICalendarDaySubtitle[];
}

export const enum CalendarActionTypes {
  OPEN_NEW_CALENDAR_DRAWER = "OPEN_NEW_CALENDAR_DRAWER",
  CLOSE_NEW_CALENDAR_DRAWER = "CLOSE_NEW_CALENDAR_DRAWER",
  GET_CALENDARS = "GET_CALENDARS",
  GET_CALENDARS_SUCCESS = "GET_CALENDARS_SUCCESS",
  GET_CALENDARS_ERROR = "GET_CALENDARS_ERROR",
  GET_UNIQUE_CALENDAR = "GET_UNIQUE_CALENDAR",
  GET_UNIQUE_CALENDAR_SUCCESS = "GET_UNIQUE_CALENDAR_SUCCESS",
  GET_UNIQUE_CALENDAR_ERROR = "GET_UNIQUE_CALENDAR_ERROR",
  GET_UNITS_WITH_CALENDAR = "GET_UNITS_WITH_CALENDAR",
  GET_CALENDAR_SUBTITLES = "GET_CALENDAR_SUBTITLES",
  CREATE_NEW_CALENDAR = "CREATE_NEW_CALENDAR",
  CREATE_NEW_CALENDAR_SUCCESS = "CREATE_NEW_CALENDAR_SUCCESS",
  CREATE_NEW_CALENDAR_ERROR = "CREATE_NEW_CALENDAR_ERROR",
}

export type CalendarActions =
  | { type: CalendarActionTypes.OPEN_NEW_CALENDAR_DRAWER }
  | { type: CalendarActionTypes.CLOSE_NEW_CALENDAR_DRAWER }
  | { type: CalendarActionTypes.GET_CALENDARS }
  | { type: CalendarActionTypes.GET_CALENDARS_SUCCESS; payload: ICalendar[] }
  | { type: CalendarActionTypes.GET_CALENDARS_ERROR }
  | { type: CalendarActionTypes.GET_UNIQUE_CALENDAR; payload: number }
  | {
      type: CalendarActionTypes.GET_UNIQUE_CALENDAR_SUCCESS;
      payload: ICalendarWithMonths;
    }
  | { type: CalendarActionTypes.GET_UNIQUE_CALENDAR_ERROR }
  | {
      type: CalendarActionTypes.CREATE_NEW_CALENDAR;
      payload: ICreateNewCalendarData;
    }
  | {
      type: CalendarActionTypes.CREATE_NEW_CALENDAR_SUCCESS;
    }
  | {
      type: CalendarActionTypes.CREATE_NEW_CALENDAR_ERROR;
    }
  | {
      type: CalendarActionTypes.GET_UNITS_WITH_CALENDAR;
      payload: number[];
    }
  | {
      type: CalendarActionTypes.GET_CALENDAR_SUBTITLES;
      payload: ICalendarDaySubtitle[];
    };

export interface ICalendarMonthsReduceObject {
  [month: string]: CalendarDay[];
}

export interface ICreateNewCalendarRequest {
  nome: string;
  ano: number;
  unidades: {
    nome: string;
    id: number;
  }[];
}

export interface ICreateNewCalendarResponse {
  id: number;
  nome: string;
  ano: number;
  unidades: CalendarUnit[];
}
