import styled from "styled-components";
import * as colors from "styles/colors";

export const Loader = styled.div`
  opacity: 0;
  display: flex;
  position: relative;
  top: 30px;
  transition: opacity 0.3s ease-in;
  &.show {
    opacity: 1;
  }
`;

export const Circle = styled.div`
  background-color: ${colors.PINK};
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 5px;
  animation: bounce 0.5s ease-in infinite;
  &:nth-of-type(2) {
    animation-delay: 0.1s;
  }
  &:nth-of-type(3) {
    animation-delay: 0.2s;
  }
  @keyframes bounce {
    0%,
    100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
`;
