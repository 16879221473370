import styled from "styled-components";
import * as colors from "styles/colors";

export const Container = styled.div`
  font-family: "Montserrat", sans-serif;

  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  div.topInfo {
    background-color: ${colors.BLUE};
    border-radius: 12px 12px 0px 0px;

    padding: 10px 0;
    text-align: center;

    h3 {
      margin-top: 5px;
      color: ${colors.WHITE};
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 27px;
    }
  }

  div.content {
    display: flex;
    flex-direction: column;
    align-items: center;

    border: 1px solid #00b0f2;
    border-radius: 0 0 15px 15px;
    box-shadow: 0px 4px 4px 0px #00000040;

    padding: 0 20px;

    div.paymentDetails {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 1em;
      width: 100%;

      div.nameInfos {
        p {
          font-weight: 600;
          font-size: 15px;
          color: ${colors.GRAY_TWO};
          margin: 2.5px;
          line-height: 22px;
        }
      }

      div.valuesInfos {
        margin-top: 4px;
        P {
          font-size: 15px;
          font-weight: 500;
          color: ${colors.GRAY_TWO};
          margin-bottom: 3.5px;
        }
      }
    }

    div.total {
      display: flex;
      justify-content: space-between;
      align-items: center;

      width: 100%;

      border-top: 1px solid #eeeeee;

      p {
        margin-top: 0.5em;
        margin-bottom: 0;
        font-weight: 500;
        font-size: 15px;
        color: ${colors.PINK_TWO};
      }
    }
  }

  @media (max-width: 320px) {
    div.content {
      padding: 0 7px;
      div.paymentDetails {
        div.nameInfos {
          p {
            font-size: 14px;
          }
        }
        div.valuesInfos {
          p {
            font-size: 14px;
          }
          p:last-child {
            flex-wrap: nowrap;
            justify-content: flex-end;
          }
        }
      }
      div.total {
        p {
          font-size: 14px;
        }
      }
    }
  }
`;
