import styled from "styled-components";
import * as colors from "styles/colors";

export const UploadDocumentsStyle = styled.div`
  div.enrollment-title {
    font-size: 20px;
    font-weight: bold;
    color: #ed008c;

    div.subtitle {
      font-weight: 500;
      font-size: 16px;
      color: black;
    }
  }
  div.subdiv {
    display: flex;
  }
`;

export const StyledLink = styled.a`
  color: ${colors.WHITE};
  &:hover {
    cursor: pointer;
  }
`;

export const InputLabel = styled.label`
  vertical-align: middle;
  font-size: 14px;
  font-style: normal;
  width: 100%;
  height: 50px;
  padding: 0;
  text-transform: uppercase;
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: center;
  background-color: ${colors.BLUE};
  &.outline {
    border: 1px solid ${colors.BLUE};
    color: ${colors.BLUE};
    background-color: white;
  }
  border-radius: 0.5em;
  border: none;
  color: #fff;
  &:hover {
    cursor: pointer;
  }
  &.disabled {
    background-color: ${colors.GRAY_DISABLED};
    color: #a8a8a8;
    border: 1px solid ${colors.GRAY_DISABLED};
  }
`;
