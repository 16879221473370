import styled, { keyframes } from "styled-components";

const skeletonAnimation = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
`;

interface ISkeletonColorConfig {
  color: string;
  trackEffectConstrast?: number;
}

export interface ISkeletonShapeProps {
  width?: number;
  height?: number;
  margin?: number;
  borderRadius?: number;
  colorConfig?: ISkeletonColorConfig;
}

const getCustomizedColor = (config: ISkeletonColorConfig) => {
  const _r = parseInt(config.color.slice(1, 3), 16);
  const _g = parseInt(config.color.slice(3, 5), 16);
  const _b = parseInt(config.color.slice(5, 7), 16);
  const _defaultConstrast = 1.15;

  const trackEffectRgb = [_r, _g, _b].map((value) =>
    Math.min(
      Math.round(value * (config?.trackEffectConstrast ?? _defaultConstrast)),
      255
    )
  );

  const hexColor = `#${trackEffectRgb.map((rgb) => rgb.toString(16)).join("")}`;
  return `linear-gradient(90deg, ${config.color}, ${hexColor}, ${config.color});`;
};

export const SkeletonShape = styled.div<ISkeletonShapeProps>`
  display: inline-block;
  height: ${(props) => (props.height ? `${props.height}px` : "16px")};
  width: ${(props) => (props.width ? `${props.width}px` : "100%")};
  animation: ${skeletonAnimation} 1400ms ease-in-out infinite;
  background-color: ${(props) => props.colorConfig?.color || "#eee"};
  background-image: ${(props) =>
    props?.colorConfig
      ? getCustomizedColor(props.colorConfig)
      : "linear-gradient(90deg, #eee, #f5f5f5, #eee);"}
  background-size: 150px 100%;
  background-repeat: no-repeat;
  border-radius: ${(props) => `${props.borderRadius}px` || "16px"};
  margin: ${(props) => `${props.margin}px` || "0"};
`;
