import { Field } from "react-final-form";
import * as Style from "./style";
import * as Props from "./type";

// Input para o react-final-form
export const TextInputFinalForm: React.FC<Props.FinalFormProps> = ({
  width,
  height,
  marginLeftInput,
  marginInputRight,
  input,
  meta,
  label,
  fullBorder,
  ...rest
}: Props.FinalFormProps) => {
  return (
    <Style.InputContainerStyled
      width={width}
      height={height}
      marginLeftInput={marginLeftInput}
      marginInputRight={marginInputRight}
      error={meta.error && meta.touched}
      fullBorder={fullBorder}
    >
      <div className="inputLabel">
        {label && (
          <label className="label" {...rest} htmlFor={input.name}>
            {label}
          </label>
        )}
        <input {...input} {...rest} className="inputField" />
      </div>

      {meta.touched && meta.error && (
        <label className="errorLabel">{meta.error}*</label>
      )}
    </Style.InputContainerStyled>
  );
};

export const TextInput = ({
  width,
  height,
  marginLeftInput,
  marginInputRight,
  tableInput,
  fontSize,
  required,
  value,
  name,
  onChange,
  disabled,
}: Props.TextInputInterface) => {
  return (
    <Style.InputContainerStyled
      width={width}
      height={height}
      marginLeftInput={marginLeftInput}
      marginInputRight={marginInputRight}
      tableInput={tableInput}
      fontSize={fontSize}
    >
      <div className="container">
        <input
          disabled={disabled}
          type="text"
          className="inputField"
          id={value}
          onChange={onChange}
          value={value}
          name={name}
          required={required}
        />
      </div>
    </Style.InputContainerStyled>
  );
};

export const InputField = ({
  name,
  title,
  type,
  value,
  ...rest
}: Props.IInputField) => {
  return (
    <label htmlFor={name}>
      {title}
      <Field
        id={name}
        name={name}
        component="input"
        type={type}
        value={value}
        {...rest}
      />
    </label>
  );
};
