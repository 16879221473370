// React
import { useContext, useEffect, useState } from "react";
// Styles
import { CancelFoodPackageStyle } from "./style";
// Components
import {
  Button,
  Pagination,
  RadioButton,
  SelectInput,
  TextInput,
} from "components";
import { CardFoodPackage } from "./components/FoodPackageCard";
// Libs
import { Field, Form } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { default as formatString } from "format-string-by-pattern";
import { LinearProgress } from "@material-ui/core";
import HTTP_STATUS from "http-status-codes";
import { toastError, toastWarning } from "components/Toast";
// Helpers
import {
  composeValidators,
  CPFvalidation,
  noSpecialCharactersAndNumbers,
  required,
} from "helpers/fieldValidators";
import { onlyNumberMask } from "helpers/helpers";
// Validations
import { validateCPF } from "validations-br";
// Services
import { getUnitsGradesSchoolPlaces } from "services/units.service";
import { GetUnitsGradesResponse } from "interfaces/unit";
import { getActiveFoodPackage } from "services/meals.service";
// Interfaces
import { ActiveFoodPackageInterface } from "interfaces/mealInterfaces";
// Img
import loadingGif from "static/img/loading.gif";

// Metodos de busca
const SEARCH_METHODS = [
  { name: "CPF do responsável", value: 1 },
  { name: "Nome do aluno", value: 2 },
];

// Enum dos metodos de busca
const METHODS_ID = {
  CPF_RESPONSAVEL: 1,
  ALUNO: 2,
};

interface ActiveFoodPackagesDataInterface {
  student_name?: string;
  unit?: number;
  educational_level?: number;
  cpf?: number;
  search_method: string;
}

export const CancelFoodPackage = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // Metodo selecionado
  const [method, setMethod] = useState<number>();
  // Desabilita e habilita botao de consulta
  const [disabledButton, setDisabledButton] = useState(false);

  const [units, setUnits] = useState<Array<GetUnitsGradesResponse>>();
  const [selectedUnit, setSelectedUnit] =
    useState<GetUnitsGradesResponse | null>();
  const [activePackages, setActivePackages] =
    useState<ActiveFoodPackageInterface>();

  // Pagination
  const [count, setCount] = useState<number>();
  const [next, setNext] = useState<string>();
  const [previous, setPrevious] = useState<string | null>(null);
  const [page, setPage] = useState<number>(1);
  const [data, setData] = useState<ActiveFoodPackagesDataInterface>();

  useEffect(() => {
    const getUnit = async () => {
      const unitsGrade = await getUnitsGradesSchoolPlaces();
      if (unitsGrade && unitsGrade.status === HTTP_STATUS.OK) {
        setUnits(unitsGrade.data);
        setLoading(false);
      } else {
        setError(true);
        setLoading(false);
      }
    };
    getUnit();
  }, []);

  const checkValidateCPF = (value: any) => {
    const cpfValido = value ? validateCPF(value) : false;
    return cpfValido;
  };

  const onSubmit = async (values: any) => {
    if (method === METHODS_ID.CPF_RESPONSAVEL) {
      const response = await getActiveFoodPackage({
        guardian_cpf: onlyNumberMask(values.cpf),
      });

      if (response && response.status === HTTP_STATUS.OK) {
        setActivePackages(response.data);
        // For Pagination
        setCount(response.data.count);
        setNext(response.data.next);
        setPrevious(response.data.previous);
        setData(values);
      } else {
        toastError(
          "Erro ao buscar pacotes de alimentação pelo CPF do responsável!"
        );
      }
    } else if (method === METHODS_ID.ALUNO) {
      const response = await getActiveFoodPackage({
        student_name: values.name,
        unit: values.unit,
        educational_level: values.educational_level,
      });

      if (response && response.status === HTTP_STATUS.OK) {
        setActivePackages(response.data);
        // For Pagination
        setCount(response.data.count);
        setNext(response.data.next);
        setPrevious(response.data.previous);
        setData(values);
      } else {
        toastError(
          "Erro ao buscar pacote de alimentação pelos dados do aluno!"
        );
      }
    } else {
      toastWarning("Verifique os dados colocados!");
    }
  };

  const Offset = async (direction: string) => {
    if (data) {
      const response = await getActiveFoodPackage({
        page:
          direction === "foward"
            ? page + 1
            : direction === "backwards"
            ? page - 1
            : parseInt(direction),
        guardian_cpf: onlyNumberMask(data.cpf),
      });
      if (response && response.status === 200) {
        setActivePackages(response.data);
        setNext(response.data.next);
        setPrevious(response.data.previous);
        setLoading(false);
      } else {
        toastError(
          "Erro ao buscar dados de pacotes de alimentação da próxima página!"
        );
      }
    }
  };

  return (
    <div>
      {loading && <LinearProgress className="m-3" color="secondary" />}
      {!loading && error && (
        <div>Erro ao carregar dados da página de cancelamento de pacote.</div>
      )}
      {units && !error && !loading && (
        <CancelFoodPackageStyle className="mr-3 ml-3">
          <div className="title mt-3 mb-3">Cancelar Pacote de Alimentação</div>
          <p className="text-dark">
            Consulte um Pacote de Alimentação pelo CPF do responsável ou pelo
            nome do aluno:
          </p>
          <Form onSubmit={onSubmit}>
            {({ form, handleSubmit, submitting, values }) => (
              <form onSubmit={handleSubmit} className="col-sm-6">
                <div className="d-flex col-sm-12 justify-content-around">
                  {SEARCH_METHODS.map((searchMethod) => {
                    return (
                      <div
                        className="d-flex"
                        key={`${searchMethod.name}-${searchMethod.value}`}
                      >
                        <Field
                          component={RadioButton}
                          name="search_method"
                          type="radio"
                          value={searchMethod.value.toString()}
                          required
                        />
                        <p className="mt-3">{searchMethod.name}</p>
                        <OnChange name="search_method">
                          {async (value) => {
                            if (value) {
                              setMethod(parseInt(value));
                            }
                          }}
                        </OnChange>
                      </div>
                    );
                  })}
                </div>
                <div className="w-100">
                  {method === METHODS_ID.CPF_RESPONSAVEL && (
                    <div>
                      <Field
                        component={TextInput}
                        name="cpf"
                        type="text"
                        placeholder=" "
                        label="Digite o CPF do responsável"
                        parse={formatString("999.999.999-99")}
                        validate={composeValidators(required, CPFvalidation)}
                        className="searchInput"
                        setSearch
                        required
                        hideCheck
                      />
                      <OnChange name="cpf">
                        {async (value) => {
                          if (value) {
                            setDisabledButton(checkValidateCPF(value));
                          }
                        }}
                      </OnChange>
                    </div>
                  )}
                </div>
                <div className="w-100">
                  {method === METHODS_ID.ALUNO && (
                    <div>
                      <div>
                        <Field
                          component={TextInput}
                          name="name"
                          type="text"
                          placeholder=" "
                          label="Digite o nome do aluno"
                          validate={composeValidators(
                            required,
                            noSpecialCharactersAndNumbers
                          )}
                          className="searchInput"
                          setSearch
                          required
                          hideCheck
                        />
                        <OnChange name="name">
                          {async (value) => {
                            if (value) {
                              setDisabledButton(values.name ? true : false);
                            }
                          }}
                        </OnChange>
                      </div>
                      <div>
                        <Field component={SelectInput} name="unit" required>
                          <option value="">Selecione unidade...</option>
                          {units.map((units) => {
                            return (
                              <option key={units.unit.id} value={units.unit.id}>
                                {units.unit.name}
                              </option>
                            );
                          })}
                        </Field>
                        <OnChange name="unit">
                          {async (value) => {
                            if (value) {
                              setSelectedUnit(
                                units.find(
                                  (unitSelected) =>
                                    unitSelected.unit.id === parseInt(value)
                                )
                              );
                            } else {
                              setSelectedUnit(null);
                              setDisabledButton(false);
                            }
                          }}
                        </OnChange>
                      </div>
                      {selectedUnit && (
                        <div>
                          <Field
                            component={SelectInput}
                            name="educational_level"
                            required
                          >
                            <option value="">Selecione ciclo...</option>
                            {selectedUnit.unit.educational_level.map(
                              (educationalLevel) => {
                                return (
                                  <option
                                    key={educationalLevel.id}
                                    value={educationalLevel.id}
                                  >
                                    {educationalLevel.name}
                                  </option>
                                );
                              }
                            )}
                          </Field>
                          <OnChange name="educational_level">
                            {async (value) => {
                              if (value) {
                                setDisabledButton(true);
                              } else {
                                setDisabledButton(false);
                              }
                            }}
                          </OnChange>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {method && (
                  <div className="mt-2 mb-3">
                    <Button disabled={!disabledButton}>
                      {submitting ? (
                        <img height="25" src={loadingGif} alt="loading" />
                      ) : (
                        "Consultar"
                      )}
                    </Button>
                  </div>
                )}
              </form>
            )}
          </Form>
          {activePackages && (
            <div className="col-sm-6">
              {activePackages.results.map((studentFoodPackage) => {
                return <CardFoodPackage foodPackage={studentFoodPackage} />;
              })}
            </div>
          )}
          {activePackages && activePackages.results.length > 0 && count && (
            <Pagination
              previous={previous!}
              count={count}
              next={next}
              page={page}
              setPage={setPage}
              Offset={Offset}
            />
          )}
        </CancelFoodPackageStyle>
      )}
    </div>
  );
};
