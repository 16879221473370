import styled, { css } from "styled-components";

export const Container = styled.div`
  padding: ${({ theme }) => theme.space[4]};
  border: 2px solid ${({ theme }) => theme.colors.primaryDefault};

  display: flex;
  flex-direction: column;

  flex: 1 1 328px;

  height: fit-content;

  input[type="file"] {
    display: none;
  }
`;

export const PendingDocument = styled.p`
  color: ${({ theme }) => theme.colors.greyScale40};
  font-size: ${({ theme }) => theme.fontSizes.web.sm};
  text-align: center;
  margin-top: ${({ theme }) => theme.space[4]};
`;

export const LoadingSpin = styled.p`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space[2]};
`;

export const FilesList = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space[1]};

  margin-top: ${({ theme }) => theme.space[4]};
  margin-bottom: ${({ theme }) => theme.space[4]};
`;

export const FilesListItem = styled.span`
  font-size: 10px;
  width: fit-content;
  border-radius: ${({ theme }) => theme.radii.xs};
  padding: ${({ theme }) => theme.space[1]} ${({ theme }) => theme.space[2]};
  border: 2px solid ${({ theme }) => theme.colors.primaryDefault};

  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space[1]};
`;

export const FilesListDeleteButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;

  svg {
    color: ${({ theme }) => theme.colors.primaryDefault};
    width: 15px;
    height: 15px;
  }
`;

export const ActionsButtons = styled.div`
  margin-left: auto;
  margin-top: auto;

  button {
    font-size: ${({ theme }) => theme.fontSizes.web.sm};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    border: none;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.statusErrorDefault};
    padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[4]};

    &:hover {
      background-color: ${({ theme }) => theme.colors.primaryLight90};
      transition: all 0.2s ease-in;
    }
  }

  .approve {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.primaryDefault};

    &:hover {
      background-color: ${({ theme }) => theme.colors.primaryLight50};
      transition: all 0.2s ease-in;
    }
  }
`;

export const UploadDocument = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.web.sm};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.primaryDefault};
  text-transform: uppercase;

  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space[1]};

  padding: ${({ theme }) => theme.space[2]};

  cursor: pointer;

  margin-bottom: 0;
  margin-left: auto;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryLight90};
    transition: all 0.2s ease;
  }
`;
