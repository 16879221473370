import styled from "styled-components";
import * as colors from "styles/colors";

export const ModalHeader = styled.div`
  position: fixed;
  padding: 20px;

  background-color: #ffffff;

  max-height: 100vh;
  width: 300px;
  height: 320px;

  border-radius: 21px;

  svg.close {
    cursor: pointer;
  }
`;

export const ModalDiv = styled.div`
  color: ${colors.GRAY_TWO};

  div.title {
    font-weight: bold;
    font-size: 14px;
  }

  div.text {
    font-size: 12px;
    line-height: 2;
  }

  span.textBolder {
    font-weight: bold;
  }

  button.widthButton {
    width: 45%;
  }
`;
