import styled, { css } from "styled-components";
import * as colors from "styles/colors";

import { IStepStatus } from "./type";

const stepOptions: { [key: string]: any } = {
  success: null,
  progress: null,
};

const stepVariants = {
  progress: css`
    background: transparent;
  `,
  progressBold: css`
    font-weight: bold;
  `,
  success: css`
    color: white;
    font-weight: bold;
    background: ${colors.NEW_GREEN};
    border: 0;
  `,
} as typeof stepOptions;

export const StepStatus = styled.div<IStepStatus>`
  padding: 2px 6px;
  border-radius: 4px;

  border: 2px solid ${colors.NEW_PURPLE};
  color: ${colors.NEW_PURPLE};

  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;

  ${({ type }) => stepVariants[type] || stepVariants.default}

  span {
    font-size: 10px;
    line-height: 15px;
    font-weight: bold;
  }
`;

const stepPaymentVariants = {
  progress: css`
    background: ${colors.NEW_YELLOW};
    color: ${colors.NEW_GRAY_SCALE};
  `,
  success: css`
    color: white;
    font-weight: bold;
    background: ${colors.NEW_GREEN};
    border: 0;
  `,
  canceled: css`
    color: white;
    font-weight: bold;
    background: ${colors.NEW_RED};
    border: 0;
  `,
} as typeof stepOptions;

export const PaymentStatus = styled(StepStatus)`
  border: none;
  padding: 4px 8px;

  span {
    font-size: 12px;
  }

  ${({ type }) => stepPaymentVariants[type] || stepPaymentVariants.default}
`;
