// Styles
import { FormContainer, PlanContainer } from "../../style";
// Components
import { InputDate, MenuBar } from "components";
import { InputText } from "components/atoms/InputText";
import { SelectInput } from "components/atoms/SelectInput";
// Interfaces
import { ITransitionPlanForm } from "./interface";
// Icons
import percent from "static/img/percent.svg";
// Helpers
import {
  formatMoneyInput,
  onlyNumberMask,
  stringToBoolean,
} from "helpers/helpers";
// Libs
import { useTranslation } from "react-i18next";

export const TransitionPlan = ({
  register,
  errors,
  watch,
  index,
  plan,
}: ITransitionPlanForm) => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.paymentPlan.components.prices";

  return (
    <PlanContainer>
      <MenuBar
        title={t(`${TRANSLATE}.titleTransitionPlan`, { number: index + 1 })}
      />
      <FormContainer>
        <h4>{t(`${TRANSLATE}.educationalServices`)}</h4>

        <div className="inLine">
          <InputText
            label={t(`${TRANSLATE}.labelMonthlyPrice`)}
            placeholder="R$00,00"
            id={`transitions.${index}.monthlyPrice`}
            width={228}
            register={register}
            error={errors.transitions?.[index]?.monthlyPrice?.message}
            handleChange={(e) => {
              const { value } = e.target;
              e.target.value = formatMoneyInput(value);
            }}
          />

          <InputText
            label={t(`${TRANSLATE}.labelNumberInstallments`)}
            placeholder={t(`${TRANSLATE}.placeHolderPlanName`)}
            id={`transitions.${index}.numberInstallments`}
            width={228}
            register={register}
            error={errors.transitions?.[index]?.numberInstallments?.message}
            handleChange={(e) => {
              const { value } = e.target;
              e.target.value = onlyNumberMask(value);
            }}
          />

          <SelectInput
            label={t(`${TRANSLATE}.discount`)}
            id={`transitions.${index}.discount`}
            width={228}
            register={register}
          >
            <option value="false">{t(`${TRANSLATE}.noDiscount`)}</option>
            <option value="true">{t(`${TRANSLATE}.hasDiscount`)}</option>
          </SelectInput>

          <InputText
            label={t(`${TRANSLATE}.inputDiscount`)}
            placeholder="0"
            id={`transitions.${index}.discountPercentage`}
            width={147}
            disabled={!stringToBoolean(watch(`transitions.${index}.discount`))}
            icon={
              <img height={21} width={21} src={percent} alt="percentIcon" />
            }
            register={register}
            error={errors.transitions?.[index]?.discountPercentage?.message}
          />
        </div>

        <h4>{t(`${TRANSLATE}.supply`)}</h4>

        <div className="inLine">
          <InputText
            label={t(`${TRANSLATE}.labelMonthlyPrice`)}
            placeholder="R$00,00"
            id={`transitions.${index}.suplyMonthlyPrice`}
            width={228}
            register={register}
            error={errors.transitions?.[index]?.suplyMonthlyPrice?.message}
            handleChange={(e) => {
              const { value } = e.target;
              e.target.value = formatMoneyInput(value);
            }}
          />

          <InputText
            label={t(`${TRANSLATE}.labelNumberInstallments`)}
            placeholder={t(`${TRANSLATE}.placeHolderPlanName`)}
            id={`transitions.${index}.suplyNumberInstallments`}
            width={228}
            register={register}
            error={
              errors.transitions?.[index]?.suplyNumberInstallments?.message
            }
            handleChange={(e) => {
              const { value } = e.target;
              e.target.value = onlyNumberMask(value);
            }}
          />

          <SelectInput
            label={t(`${TRANSLATE}.discount`)}
            id={`transitions.${index}.suplyDiscount`}
            width={228}
            register={register}
          >
            <option value="false">{t(`${TRANSLATE}.noDiscount`)}</option>
            <option value="true">{t(`${TRANSLATE}.hasDiscount`)}</option>
          </SelectInput>

          <InputText
            label={t(`${TRANSLATE}.inputDiscount`)}
            placeholder="0"
            id={`transitions.${index}.suplyDiscountPercentage`}
            width={147}
            disabled={
              !stringToBoolean(watch(`transitions.${index}.suplyDiscount`))
            }
            icon={
              <img height={21} width={21} src={percent} alt="percentIcon" />
            }
            register={register}
            error={
              errors.transitions?.[index]?.suplyDiscountPercentage?.message
            }
          />
        </div>

        <div className="inLine">
          <InputText
            label={t(`${TRANSLATE}.labelTotvsCode`)}
            placeholder={t(`${TRANSLATE}.placeHolderTotvsCode`)}
            id={`transitions.${index}.totvsCode`}
            width={228}
            register={register}
            error={errors.transitions?.[index]?.totvsCode?.message}
          />

          <InputDate
            label={t(`${TRANSLATE}.validityPeriodFinalDate`)}
            boxWidth={228}
            id={`transitions.${index}.validityFinalDate`}
            register={register}
            error={errors.transitions?.[index]?.validityFinalDate?.message}
            value={plan?.transicoes[index].data_final_vigencia}
          />
        </div>
      </FormContainer>
    </PlanContainer>
  );
};
