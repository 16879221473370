// Libs
import formatString from "format-string-by-pattern";
import { Field } from "react-final-form";
import { useTranslation } from "react-i18next";
// Components
import { TextInputFinalForm } from "components";
// Helpers
import {
  composeValidators,
  validateCreditCardIugu,
  noSpecialCharactersAndNumbers,
  required,
} from "helpers/fieldValidators";
// Styles
import * as S from "./styles";

export const CreditCard = () => {
  const { t } = useTranslation();
  const i18_prefix =
    "pages.EDF.selectMeals.components.stepThree.components.creditCard";

  return (
    <S.Container>
      <label>
        {t(`${i18_prefix}.cardNumber`)}
        <Field
          component={TextInputFinalForm}
          name="card_number"
          type="text"
          placeholder="0000 0000 0000 0000"
          parse={formatString("9999 9999 9999 9999")}
          maxLength={20}
          validate={composeValidators(validateCreditCardIugu)}
          required
        />
      </label>
      <label>
        {t(`${i18_prefix}.namePrintedOnCard`)}
        <Field
          component={TextInputFinalForm}
          name="name"
          type="text"
          placeholder={t(`${i18_prefix}.cardName`)}
          validate={composeValidators(required, noSpecialCharactersAndNumbers)}
          required
        />
      </label>
      <div className="inputGroup">
        <label>
          {t(`${i18_prefix}.dueDate`)}
          <Field
            component={TextInputFinalForm}
            name="due_date"
            type="text"
            placeholder={t(`${i18_prefix}.dueDatePlaceHolder`)}
            parse={formatString("99/99")}
            validate={composeValidators(required)}
            required
          />
        </label>
        <label>
          {t(`${i18_prefix}.securityCode`)}
          <Field
            component={TextInputFinalForm}
            name="cvv"
            type="text"
            placeholder={t(`${i18_prefix}.cvv`)}
            maxLength="4"
            validate={composeValidators(required)}
            required
          />
        </label>
      </div>
    </S.Container>
  );
};
