import { useLogin } from "./Login.helper";
import { useTranslation } from "react-i18next";
import { AccountCircleIcon, LockIcon, Logo22Text } from "static/img";
import loadingGif from "static/img/loading.gif";
import {
  Hyperlink,
  FormContainer,
  LoginContainer,
  Image,
  Content,
  LogoImg,
  GreetingText,
  ImageContainer,
  CustomButton,
  SModalStyled,
} from "./styles";
import { LoginField } from "./components";

const TRANSLATION_PREFIX = "pages.CORE.login";

export const Login = () => {
  const { t } = useTranslation();

  const modal = false;

  const { randomImg, loading, onSubmit, register, handleSubmit, errors } =
    useLogin();

  const setModal = () => {};

  return (
    <>
      <LoginContainer>
        <ImageContainer>
          <Image src={randomImg} alt="Logo" />
        </ImageContainer>

        <FormContainer>
          <Content>
            <LogoImg src={Logo22Text} alt="Logo" />
            <GreetingText>
              {t(`${TRANSLATION_PREFIX}.greetingText`)}
            </GreetingText>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                style={{ display: "flex", flexDirection: "column", gap: 16 }}
              >
                <div>
                  <LoginField
                    id="email"
                    type="email"
                    placeholder="nome@email.com"
                    iconImage={AccountCircleIcon}
                    inputLabel="Usuário"
                    register={register}
                  />
                  {errors.email && (
                    <span style={{ color: "red" }}>{errors.email.message}</span>
                  )}
                </div>

                <div>
                  <LoginField
                    id="password"
                    iconImage={LockIcon}
                    inputLabel="Senha"
                    type="password"
                    placeholder="Digite sua senha"
                    register={register}
                  />
                  {errors.password && (
                    <span style={{ color: "red" }}>
                      {errors.password.message}
                    </span>
                  )}
                </div>

                <Hyperlink to="/esqueci-minha-senha">
                  {t(`${TRANSLATION_PREFIX}.forgotPassword`)}
                </Hyperlink>

                <CustomButton type="submit">
                  {!loading && t(`${TRANSLATION_PREFIX}.enter`)}
                  {loading && (
                    <img height="25" src={loadingGif} alt="loading" />
                  )}
                </CustomButton>
              </div>
            </form>
          </Content>
        </FormContainer>
      </LoginContainer>
      {/* {!IsDevelopmentEnvironment() && (
        <Modal isOpen={modal} toggle={setModal} path="test">
          <SModalStyled>
            <LogoImg src={Logo22Text} alt="Logo" />
            <span className="text-center">
              Estamos trabalhando para melhorar nosso sistema. Previsão de
              retorno dia 02/01.
            </span>
          </SModalStyled>
        </Modal>
      )} */}
    </>
  );
};
