import styled from "styled-components";
import * as colors from "styles/colors";

export const ModalHeader = styled.div`
  position: fixed;
  padding: 24px;

  border-radius: 17px;

  svg.close {
    cursor: pointer;
  }
`;

export const ModalDiv = styled.div`
  color: ${colors.GRAY_TWO};

  .title {
    font-size: 16px;
  }

  .text {
    font-size: 14px;
    line-height: 2;
  }

  span.textBolder {
    font-weight: bold;
  }

  button.widthButton {
    width: 45%;
  }
`;
