import { useEffect, useState } from "react";
import { ItinerarieCardLoading } from "./ItinerarieCardLoading";
import { ItinerarieCardReady } from "./ItinerarieCardReady";
import { ItinerarieCardError } from "./ItinerarieCardError";
import { IItinerarieComponent } from "types/study-itinerary";

const enum StateMachineEnum {
  IDLE = "IDLE",
  LOADING = "LOADING",
  READY = "READY",
  ERROR = "ERROR",
}

interface IItinerarieCardProps {
  itinerary: IItinerarieComponent;
  loading: boolean;
  error: boolean;
}
export const ItinerarieCard = ({
  itinerary,
  loading,
  error,
}: IItinerarieCardProps) => {
  const [stateMachine, setStateMachine] = useState<StateMachineEnum>(
    StateMachineEnum.IDLE
  );

  useEffect(() => {
    setStateMachine(StateMachineEnum.LOADING);
    if (error) {
      setStateMachine(StateMachineEnum.ERROR);
    }
    if (!loading && itinerary && !error) {
      setStateMachine(StateMachineEnum.READY);
    }
  }, [itinerary, loading, error]);

  const stateMachineStates = {
    IDLE: <></>,
    LOADING: <ItinerarieCardLoading />,
    READY: <ItinerarieCardReady itinerary={itinerary} />,
    ERROR: <ItinerarieCardError itinerary={itinerary} />,
  };

  return stateMachineStates[stateMachine];
};
