import { pxToRem } from "helpers/style";
import styled, { css } from "styled-components";
import * as colors from "styles/colors";
import { device } from "styles/breakpoints";
import * as I from "./type";

export const Overlay = styled.div<I.OverlayProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${(props) => (props.isOpen ? "rgba(0,0,0,0.2)" : "transparent")};
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  z-index: ${(props) => (props.isOpen ? 2000 : -1)};

  transition: ${(props) =>
    props.isOpen
      ? "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1)"
      : "225ms cubic-bezier(0, 0, 0, 0) 0ms"};
`;

export const Drawer = styled.div<I.OverlayProps>`
  width: ${(props) => (props.width ? props.width : "100%")};
  height: 100%;

  flex: 1 0 auto;

  display: flex;
  outline: 0;
  z-index: 1200;
  position: fixed;
  overflow-y: auto;
  flex-direction: column;

  background-color: #ffffff;
  flex-shrink: 0;

  transform: ${(props) => (props.isOpen ? "none" : "translateX(360px)")};
  transition: ${(props) =>
    props.isOpen
      ? `box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms`
      : `325ms cubic-bezier(0, 0, 0.2, 1) 0ms`};

  visibility: ${(props) => (props.isOpen ? "visible" : "hidden")};
  box-shadow: ${(props) =>
    props.isOpen &&
    `0 8px 10px -5px rgba(0, 0, 0, 0.2),
      0px 16px 24px 2px rgba(0, 0, 0, 0.14),
      0px 6px 30px 5px rgba(0, 0, 0, 0.12)`};

  ${(props) =>
    props.width &&
    css`
      top: 0;
    `}

  ${(props) =>
    props.side === "left" &&
    css`
      left: 0;
      right: auto;
    `}

  ${(props) =>
    props.side === "right" &&
    css`
      right: 0;
      left: auto;
    `}

  ${(props) =>
    props.side === "bottom" &&
    css`
      bottom: 0;
      height: ${props.height};
      transform: ${props.isOpen ? "none" : "translateY(360px)"};
    `}

  @media ${device.md} {
    max-width: 796px;

    ${(props) =>
      props.height &&
      css`
        max-width: 100%;
      `}
  }

  @media (max-width: 360px) {
    width: 100%;
  }
`;

export const HeaderDrawer = styled.div`
  width: 100%;

  background: #b7ff00;
  height: 64px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  position: relative;
`;

export const ButtonClose = styled.button`
  width: 16px;
  height: 16px;

  border: none;
  background: none;

  position: absolute;
  top: calc(50% - 10px);
  right: 16px;

  z-index: 999;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DrawerBody = styled.div<I.DrawerBodyProps>`
  width: 100%;
  height: calc(100vh - 57px);
  min-height: calc(100vh - 57px);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  overflow-y: auto;
  overflow-x: hidden;

  ${(props) =>
    props.height &&
    css`
      height: fit-content;
      min-height: fit-content;
    `}
`;
