import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 0 ${({ theme }) => theme.space[4]};

  display: flex;
  flex-direction: column;

  height: 100vh;

  > div:last-child {
    margin-bottom: ${({ theme }) => theme.space[10]};
    margin-top: auto;
  }
`;

export const TableContent = styled.div`
  display: flex;
  flex-direction: column;

  ul {
    padding: 0;
  }

  strong {
    margin-bottom: ${({ theme }) => theme.space[2]};
  }

  .table-head,
  ul li {
    display: grid;
    grid-template-columns: 232px 195px 130px 179px;

    p {
      margin-bottom: 0;
      font-size: ${({ theme }) => theme.fontSizes.web.sm};
      padding: ${({ theme }) => theme.space[2]};
    }

    p:first-child {
      padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[4]};
    }
  }

  li {
    border-bottom: 1px solid ${({ theme }) => theme.colors.greyScale10};

    p {
      margin-bottom: 0;
    }
  }

  .table-head p {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    border-bottom: 1px solid ${({ theme }) => theme.colors.greyScale40};
  }
`;
