import { RadioButton } from "components";
import { capSentence } from "helpers/helpers";
import { StudentPresenceInterface } from "interfaces/TeacherInterfaces";
import { Field } from "react-final-form";

interface StudentPresenceClassInterface {
  studentPresence: StudentPresenceInterface;
  name: string;
  disabled: boolean;
}

export const StudentPresenceDoubleClass = ({
  studentPresence,
  name,
  disabled,
}: StudentPresenceClassInterface) => {
  return (
    <div
      className="row mb-3"
      style={{
        fontSize: "14px",
      }}
    >
      <div className="col-2">{capSentence(studentPresence.name)}</div>
      <div
        className={`col-2 d-flex ${
          !studentPresence.especializacao &&
          "align-items-center justify-content-center"
        }`}
      >
        <span>{studentPresence.especializacao || "-"}</span>
      </div>
      <div className="col-2">
        <Field
          component={RadioButton}
          name={`${name}.presence`}
          type="radio"
          value="3"
          required
          disabled={!disabled}
        />
        <strong>Presença</strong>
      </div>
      <div className="col-2">
        <Field
          component={RadioButton}
          name={`${name}.presence`}
          type="radio"
          value="2"
          required
          disabled={!disabled}
        />
        <strong>Falta</strong>
      </div>

      <div className="col-2">
        <Field
          component={RadioButton}
          name={`${name}.presence_2`}
          type="radio"
          value="3"
          required
          disabled={!disabled}
        />
        <strong>Presença</strong>
      </div>
      <div className="col-2">
        <Field
          component={RadioButton}
          name={`${name}.presence_2`}
          type="radio"
          value="2"
          required
          disabled={!disabled}
        />
        <strong>Falta</strong>
      </div>
    </div>
  );
};
