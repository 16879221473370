import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@escolamais-ui-v2/react";
import { Drawer } from "components/organisms/Drawer";
import { Loading } from "components/atoms";
import { DiscountOption } from "pages/EDF/VacancyData/style";
import { ScholarshipAndDiscount } from "pages/EDF/VacancyData/components/ScholarshipAndDiscount";
import * as S from "./styles";

interface IBolsasDrawer {
  visible: boolean;
  onClose: () => void;
}

export const DiscountApprovalDrawer = ({ visible, onClose }: IBolsasDrawer) => {
  const { t } = useTranslation();
  const TRANSLATE =
    "pages.EDF.discountApproval.components.discountApprovalDrawer";

  const [isLoading, setIsLoading] = useState(false);

  return (
    <Drawer
      title="Desconto para [Nome do estudante]"
      isOpen={visible}
      onClose={() => onClose()}
      side="right"
      width="469px"
    >
      <S.DiscountApprovalDrawerContainer>
        <S.DiscountApprovalDrawerContent>
          <S.ScolarshipDiscountContainer>
            <S.ScolarshipDiscountContent>
              <h3>{t(`${TRANSLATE}.scolarshipAndDiscount`)}</h3>
              <S.SelectContainerExtended>
                <S.SelectTitleExtended>
                  {t(`${TRANSLATE}.scolarship`)}
                </S.SelectTitleExtended>
                <select name="scholarship" id="scholarship">
                  <option>Sem bolsa</option>
                </select>
              </S.SelectContainerExtended>
              <S.DiscountsSectionExtended>
                <p>{t(`${TRANSLATE}.discounts`)}</p>
                <DiscountOption>
                  <ScholarshipAndDiscount.DiscountOptionCheckbox
                    id="reservation"
                    checked
                    label={t(`${TRANSLATE}.reservation`)}
                  />
                  <ScholarshipAndDiscount.DiscountPercentageInput value="100" />
                </DiscountOption>
                <DiscountOption>
                  <ScholarshipAndDiscount.DiscountOptionCheckbox
                    id="monthlyFee"
                    checked
                    label={t(`${TRANSLATE}.monthlyPayment`)}
                  />
                  <ScholarshipAndDiscount.DiscountPercentageInput value="100" />
                </DiscountOption>
                <DiscountOption>
                  <ScholarshipAndDiscount.DiscountOptionCheckbox
                    id="material"
                    checked
                    label={t(`${TRANSLATE}.material`)}
                  />
                  <ScholarshipAndDiscount.DiscountPercentageInput value="100" />
                </DiscountOption>
              </S.DiscountsSectionExtended>
            </S.ScolarshipDiscountContent>
          </S.ScolarshipDiscountContainer>

          {isLoading ? (
            <Loading />
          ) : (
            <S.ScolarshipDiscountResume>
              <h4>Total</h4>

              <S.ScolarshipDiscountResumeList>
                <li>
                  Mensalidade <span>R$000.000,00</span>
                </li>
                <li>
                  Desconto <span>R$000.000,00</span>
                </li>
                <li>
                  Total: <strong>R$000.000,00</strong>
                </li>
              </S.ScolarshipDiscountResumeList>

              <S.ScolarshipDiscountResumeList>
                <li>
                  Reserva de vaga <span>R$000.000,00</span>
                </li>
                <li>
                  Desconto <span>R$000.000,00</span>
                </li>
                <li>
                  Total: <strong>R$000.000,00</strong>
                </li>
              </S.ScolarshipDiscountResumeList>

              <S.ScolarshipDiscountResumeList>
                <li>
                  Material Escolar <span>R$000.000,00</span>
                </li>
                <li>
                  Desconto <span>R$000.000,00</span>
                </li>
                <li>
                  Total: <strong>R$000.000,00</strong>
                </li>
              </S.ScolarshipDiscountResumeList>
            </S.ScolarshipDiscountResume>
          )}
        </S.DiscountApprovalDrawerContent>

        <S.ButtonGroup>
          <Button
            size="large"
            buttonText={t(`${TRANSLATE}.submitButton`)}
            appearance="primary"
          />
        </S.ButtonGroup>
      </S.DiscountApprovalDrawerContainer>
    </Drawer>
  );
};
