// Style
import * as S from "./style";
// Components
import { StudentHeader } from "components";
// Interfaces
import { IChooseStudent } from "./interface";
// Libs
import { useTranslation } from "react-i18next";

export const ChooseStudent = ({ user, setStudent }: IChooseStudent) => {
  const { t } = useTranslation();
  const prefix = "pages.EDF.exitPermit.components.chooseStudents";

  return (
    <S.ChooseStudentStyled>
      <h1>{t(`${prefix}.title`)}</h1>
      <h3>{t(`${prefix}.selectStudent`)}</h3>
      {user.students.map((student, index) => {
        return (
          <div
            className="studentBox"
            key={index}
            onClick={() => {
              setStudent(student);
            }}
          >
            <StudentHeader student={student} />
          </div>
        );
      })}
    </S.ChooseStudentStyled>
  );
};
