import styled from "styled-components";

export const ModalDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin-top: 1em;
  padding: 1em;

  div.content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: 2px solid ${({ theme }) => theme.colors.primaryDefault};

    width: 471px;
    height: 350px;
  }

  .img {
    width: 100%;
  }

  .take-screenshot {
    margin-top: ${({ theme }) => theme.space[3]};
  }

  div.hasPicture {
    margin-top: ${({ theme }) => theme.space[3]};
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: ${({ theme }) => theme.space[1]};

    button {
      flex: 1;
    }
  }
`;
