/* eslint-disable array-callback-return */
//libs externas
import { ReactElement, useEffect, useState } from "react";
import { LinearProgress } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import HTTP_STATUS from "http-status-codes";
import { Field, Form } from "react-final-form";

//components & style
import { Button, Checkbox, RadioButton, TextInput } from "components";
import SelectInput from "components/Select";
import { toastError, toastSuccess } from "components/Toast";
import loadingGif from "static/img/loading.gif";
import { ModalExportEnrollments } from "components/Modals/ModalExportEnrollments";
import { GerenciarProcessoMatriculasStyled } from "./style";

//contexts & helpers
import { isOperationalDirector } from "helpers/constants";
import {
  composeValidators,
  noSpecialCharactersAndNumbers,
} from "helpers/fieldValidators";
import { deepCopy } from "helpers/helpers";
import {
  formatParamsGerenciarMatriculas,
  GerenciarMatriculasValuesType,
} from "./helper";

//services & interfaces
import {
  EnrollmentResultReturn,
  massiveFreeSchoolPlaces,
  searchEnrollmentsStatus,
} from "services/enrollment.service";
import {
  EducationalLevelResponse,
  getEducationalLevel,
} from "services/grades.service";
import { getUnits, UnitInterface } from "services/units.service";
import { getVendorList, VendorListResponse } from "services/users.service";
import { Storage } from "Storage";
import { useAtomValue } from "jotai";
import { authUserAtom } from "jotai/authUser";

export const GerenciarProcessoMatriculas = (): ReactElement => {
  const [vendorList, setVendorList] = useState<Array<VendorListResponse>>();
  const [unitList, setUnitList] = useState<Array<UnitInterface>>();
  const [gradeList, setGradeList] = useState<Array<EducationalLevelResponse>>();
  const [dataList, setDataList] = useState<Array<EnrollmentResultReturn>>();
  const [loadingData, setLoadingData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [count, setCount] = useState(0);
  const [next, setNext] = useState<string>();
  const [previous, setPrevious] = useState<string>();
  const [page, setPage] = useState(0);
  const [enrollmentsList, setEnrollmentsList] = useState<Array<number>>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isModalExportOpen, setIsModalExportOpen] = useState(false);
  const authUser = useAtomValue(authUserAtom);
  const role = Storage.props.role.get();

  type ShowActionsStuffType = {
    values: GerenciarMatriculasValuesType;
    count: number;
  };

  const showActionsStuff = ({ values, count }: ShowActionsStuffType) => {
    return (
      isOperationalDirector(role) &&
      count > 0 &&
      values.unit_id &&
      values.re_enrollment &&
      !values.enrollment &&
      values.status_id === "1"
    );
  };

  const liberarVagas = async ({ values, count }: ShowActionsStuffType) => {
    if (!selectAll && enrollmentsList.length === 0) {
      toastError(
        "Deve-se selecionar ao menos uma matrícula ou todas as matrículas"
      );
    } else {
      if (
        window.confirm(
          `Deseja derrubar as reservas de vaga de ${
            selectAll ? count : enrollmentsList.length
          } aluno(s)?`
        )
      ) {
        let body;
        if (selectAll) {
          body = values;
          body.all_enrollments = true;
          body.user_id = authUser.id;
          body.user_name = authUser.name;
        } else {
          body = {
            enrollment_list: enrollmentsList,
            user_id: authUser.id,
            user_name: authUser.name,
          };
        }

        const response = await massiveFreeSchoolPlaces({
          body,
        });
        if (response && response.status === HTTP_STATUS.OK) {
          toastSuccess("Vagas liberadas com sucesso");
          setSelectAll(false);
          setEnrollmentsList([]);
          const url = formatParamsGerenciarMatriculas(values);
          setLoadingData(true);
          const response_ = await searchEnrollmentsStatus({
            filterURL: url,
          });
          if (response_ && response_.status === HTTP_STATUS.OK) {
            setDataList(response_.data.results);
            setCount(response_.data.count);
            setPrevious(response_.data.previous);
            setNext(response_.data.next);
            setLoadingData(false);
            setPage(0);
          } else {
            setError(true);
            setErrorMessage([
              ...errorMessage,
              "Ocorreu um erro ao realizar a busca",
            ]);
          }
        } else {
          toastError(
            "Erro ao tentar liberar as vagas. Tente novamente mais tarde."
          );
        }
      }
    }
  };

  const addToEnrollmentsList = (id: number) => {
    let enrollmentsListCopy = deepCopy(enrollmentsList);
    if (enrollmentsList.includes(id)) {
      enrollmentsListCopy = enrollmentsListCopy.filter(
        (item: number) => item !== id
      );
    } else {
      enrollmentsListCopy.push(id);
    }
    setEnrollmentsList(enrollmentsListCopy);
  };

  const getDate = (data: any) => {
    let obj = {
      data: "",
      hora: "",
    };
    if (data) {
      const myArr = data.split("T");
      const arrayData = myArr[0].split("-");

      obj.data = arrayData[2] + "-" + arrayData[1] + "-" + arrayData[0];
      obj.hora = myArr[1] ? myArr[1].substring(0, 5) : "";
    }

    return obj;
  };

  const onSubmit = async (values: GerenciarMatriculasValuesType) => {
    setEnrollmentsList([]);
    setSelectAll(false);
    const url = formatParamsGerenciarMatriculas(values);
    setLoadingData(true);
    const response = await searchEnrollmentsStatus({
      filterURL: url,
    });
    if (response && response.status === HTTP_STATUS.OK) {
      setDataList(response.data.results);
      setCount(response.data.count);
      setPrevious(response.data.previous);
      setNext(response.data.next);
      setLoadingData(false);
      setPage(0);
    } else {
      setError(true);
      setErrorMessage([...errorMessage, "Ocorreu um erro ao realizar a busca"]);
    }
  };

  const Paginar = async (direction: string) => {
    setLoadingData(true);
    const urlDirection = direction === "foward" ? next : previous;
    const response = await searchEnrollmentsStatus({
      filterURL: "",
      paginate: urlDirection,
    });
    if (response && response.status === HTTP_STATUS.OK) {
      setDataList(response.data.results);
      setCount(response.data.count);
      setPrevious(response.data.previous);
      setNext(response.data.next);
      setLoadingData(false);
      if (direction === "foward") {
        setPage(page + 1);
      } else {
        setPage(page - 1);
      }
    } else {
      setError(true);
      setErrorMessage([...errorMessage, "Ocorreu um erro ao realizar a busca"]);
    }
  };

  useEffect(() => {
    setLoading(true);
    const fetchVendors = async () => {
      const response = await getVendorList();
      if (response && response.status === HTTP_STATUS.OK) {
        setVendorList(response.data);
      } else {
        setErrorMessage([
          ...errorMessage,
          "Ocorreu um erro ao carregar a lista de vendedores.",
        ]);
        setError(true);
      }
    };

    const fetchUnidades = async () => {
      const response = await getUnits();
      if (response && response.status === HTTP_STATUS.OK) {
        setUnitList(response.data);
      } else {
        setErrorMessage([
          ...errorMessage,
          "Ocorreu um erro ao carregar a lista de unidades.",
        ]);
        setError(true);
      }
    };

    const fetchGrades = async () => {
      const response = await getEducationalLevel();
      if (response && response.status === HTTP_STATUS.OK) {
        setGradeList(response.data);
      } else {
        setErrorMessage([
          ...errorMessage,
          "Ocorreu um erro ao carregar a lista de níveis educacionais .",
        ]);
        setError(true);
      }
    };

    try {
      fetchVendors();
      fetchUnidades();
      fetchGrades();
    } catch (err) {
      setError(true);
      setErrorMessage([
        ...errorMessage,
        "Ocorreu um erro ao carregar os dados para os filtros",
      ]);
    }

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GerenciarProcessoMatriculasStyled className="container">
      {!error && !loading && vendorList && unitList && gradeList && (
        <>
          {isModalExportOpen && (
            <ModalExportEnrollments
              setToggleModal={setIsModalExportOpen}
              isOpen={isModalExportOpen}
              units={unitList}
            />
          )}
          <div className="title text-center">
            Gerenciar Processo de Matrícula
          </div>
          <div
            className="d-flex"
            onClick={() => {
              setIsModalExportOpen(true);
            }}
          >
            <div style={{ cursor: "pointer" }}>
              <GetAppIcon className="mr-1" />
              <strong>Exportar matrículas</strong>
            </div>
          </div>
          <Form onSubmit={onSubmit} initialValues={{ type_search: "1" }}>
            {({ form, handleSubmit, submitting, values }) => (
              <form onSubmit={handleSubmit}>
                <div className="row mt-5">
                  <div className="col-6">
                    <Field
                      component={RadioButton}
                      name="type_search"
                      type="radio"
                      value="1"
                    />
                    <strong>Pesquisa por Nome</strong>
                    <Field
                      component={TextInput}
                      name="search_name"
                      type="text"
                      placeholder=" "
                      label="Nome do Usuário"
                      validate={composeValidators(
                        noSpecialCharactersAndNumbers
                      )}
                      required={values.type_search === "1"}
                      disabled={values.type_search === "2"}
                    />
                  </div>
                  <div className="col-6">
                    <div>
                      <Field
                        component={RadioButton}
                        name="type_search"
                        type="radio"
                        value="2"
                      />
                      <strong>Pesquisa por Matrícula</strong>
                    </div>
                    <div className="mt-2">
                      <strong>Vendedor</strong>
                      <Field
                        component={SelectInput}
                        name="vendor_id"
                        className="mt-0"
                        disabled={values.type_search === "1"}
                      >
                        <option value="">Selecione um Vendedor</option>
                        {vendorList.map((vendor) => {
                          return (
                            <option value={vendor.id} key={vendor.id}>
                              {vendor.name}
                            </option>
                          );
                        })}
                      </Field>
                    </div>
                    <div className="mt-2">
                      <strong>Status</strong>
                      <Field
                        component={SelectInput}
                        name="status_id"
                        className="mt-0"
                        disabled={values.type_search === "1"}
                      >
                        <option value="">Selecione um Status</option>
                        <option value="1">
                          Aguardando Pagamento da Reserva
                        </option>
                        <option value="2">
                          Pagamento da Reserva em Atraso
                        </option>
                        <option value="3">Lista de Espera</option>
                        <option value="4">
                          Vaga liberada e Espera cancelada
                        </option>
                        <option value="5">
                          Aguardando Compensação do Pagamento
                        </option>
                        <option value="6">Aguardando Análise Financeira</option>
                        <option value="7">Aguardando envio Documentação</option>
                        <option value="8">
                          Aguardando Assinatura do Contrato
                        </option>
                      </Field>
                    </div>
                    <div className="mt-2">
                      <strong>Unidade</strong>
                      <Field
                        component={SelectInput}
                        name="unit_id"
                        className="mt-0"
                        disabled={values.type_search === "1"}
                      >
                        <option value="">Selecione uma Unidade</option>
                        {unitList.map((unit) => {
                          if (unit.actived) {
                            return (
                              <option value={unit.id} key={unit.id}>
                                {unit.name}
                              </option>
                            );
                          }
                        })}
                      </Field>
                    </div>
                    <div className="mt-2">
                      <strong>Ano</strong>
                      <Field
                        component={SelectInput}
                        name="grade_id"
                        className="mt-0"
                        disabled={values.type_search === "1"}
                      >
                        <option value="">Selecione um Ano</option>
                        {gradeList.map((grade) => {
                          return (
                            <option value={grade.id} key={grade.id}>
                              {grade.grade}º {grade.educational_level_name}
                            </option>
                          );
                        })}
                      </Field>
                    </div>
                    <div className="mt-2">
                      <div className="row">
                        <div className="col-6">
                          <Field component={Checkbox} name="enrollment" />
                          Matrículas
                        </div>
                        <div className="col-6">
                          <Field component={Checkbox} name="re_enrollment" />
                          Rematrículas
                        </div>
                      </div>
                    </div>
                    <div className="mt-2">
                      <div className="row">
                        <div className="col-6">
                          <Field component={Checkbox} name="adimplente" />
                          Adimplentes
                        </div>
                        <div className="col-6">
                          <Field component={Checkbox} name="inadimplente" />
                          Inadimplentes
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="row">
                  <div className="col-2">
                    <Button
                      type="button"
                      className="pink outline"
                      onClick={() => {
                        form.reset();
                        setDataList(undefined);
                      }}
                    >
                      Limpar Busca
                    </Button>
                  </div>
                  <div className="col-2">
                    <Button type="submit">
                      {(submitting || loadingData) && (
                        <img height="25" src={loadingGif} alt="loading" />
                      )}
                      {(!submitting || !loadingData) && "Buscar"}
                    </Button>
                  </div>
                </div>
                {/*  */}

                {!loadingData && dataList && count !== 0 && (
                  <div className="mt-5">
                    <div className="mb-2">
                      {count > 10
                        ? `Exibindo 10 de ${count} ite${
                            count > 1 ? "ns" : "m"
                          } `
                        : `Exibindo um total de ${count} ite${
                            count > 1 ? "ns" : "m"
                          }`}
                    </div>
                    {showActionsStuff({ values, count }) && (
                      <div className="row mb-4">
                        <div className="col-6 my-auto">
                          <input
                            type="checkbox"
                            checked={selectAll}
                            onClick={() => setSelectAll(!selectAll)}
                            className="mr-3"
                          />
                          Selecionar todas as {count} matrículas
                        </div>
                        <div className="col-3">
                          <Field<string> component={SelectInput} name="action">
                            <option value="">Selecione uma ação</option>
                            <option value="liberar_vagas">Liberar vagas</option>
                          </Field>
                        </div>
                        <div className="col-3">
                          <Button
                            type="button"
                            onClick={() => {
                              values.action === "liberar_vagas" &&
                                liberarVagas({ values, count });
                            }}
                            disabled={!values.action}
                          >
                            executar
                          </Button>
                        </div>
                      </div>
                    )}
                    <table>
                      <thead>
                        <tr>
                          <th
                            colSpan={
                              showActionsStuff({ values, count }) ? 2 : 1
                            }
                          >
                            Responsável
                          </th>
                          <th>Estudante</th>
                          <th>Unidade</th>
                          <th>Ano</th>
                          <th>Status da Matrícula</th>
                          <th>Data / Hora da reserva</th>
                          <th>Data / Hora do pagamento</th>
                          <th>Vendedores</th>
                          <th>Hubspot ID</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataList.map((user) => {
                          return (
                            <tr key={user.enrollment_id}>
                              {showActionsStuff({ values, count }) && (
                                <td>
                                  <input
                                    type="checkbox"
                                    onClick={() =>
                                      addToEnrollmentsList(user.enrollment_id)
                                    }
                                    checked={
                                      selectAll ||
                                      enrollmentsList.includes(
                                        user.enrollment_id
                                      )
                                    }
                                  />
                                </td>
                              )}
                              <td>{user.guardian_name}</td>
                              <td>{user.student_name}</td>
                              <td>{user.unit_name}</td>
                              <td>
                                {user.grade +
                                  "º Ano " +
                                  user.educational_level_name}
                              </td>
                              <td>{user.current_status}</td>
                              <td>
                                <p>
                                  {getDate(user.date_vacancy_reserved).data}
                                </p>
                                <p>
                                  {getDate(user.date_vacancy_reserved).hora}
                                </p>
                              </td>
                              <td>
                                <p>
                                  {
                                    getDate(
                                      user.payment_date_reservation_confirmed
                                    ).data
                                  }
                                </p>
                                <p>
                                  {
                                    getDate(
                                      user.payment_date_reservation_confirmed
                                    ).hora
                                  }
                                </p>
                              </td>

                              <td>
                                {user.employees.length === 1
                                  ? user.employees[0].name
                                  : user.employees
                                      .map((employee) => {
                                        return employee.name;
                                      })
                                      .join(` | `)}
                              </td>
                              <td>{user.hubspot_id}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <div className="row justify-content-center">
                      <div className="col-5">
                        {previous && (
                          <Button onClick={() => Paginar("backwards")}>
                            ANTERIOR
                          </Button>
                        )}
                      </div>
                      <div className="col-2 text-center mt-4">
                        <strong>Página {page + 1}</strong>
                      </div>
                      <div className="col-5">
                        {next && (
                          <Button onClick={() => Paginar("foward")}>
                            PROXIMA
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </form>
            )}
          </Form>
          {!loadingData && dataList && count === 0 && (
            <div className="mt-5 text-center">
              A pesquisa não retornou nenhum resultado.
            </div>
          )}
        </>
      )}
      {(loading || loadingData) && (
        <LinearProgress className="mt-3 mb-3" color="secondary" />
      )}
      {error && (
        <>
          <div className="row justify-content-center">
            <div className="col-12 text-center mt-3">
              <h2>
                Ocorreu um erro ao carregar os dados da página, tente novamente
                mais tarde
              </h2>
              {errorMessage && (
                <h4>
                  Mensagem de Erro:{" "}
                  {errorMessage.map((errorMessage) => {
                    return (
                      <div style={{ color: "#000000" }}>{errorMessage}</div>
                    );
                  })}
                </h4>
              )}
            </div>
          </div>
        </>
      )}
    </GerenciarProcessoMatriculasStyled>
  );
};
