import { AuthUserInterface, RoleInterface } from "interfaces/constants";

export const CURRENT_YEAR_ENROLLMENT = new Date().getFullYear();
export const NEXT_YEAR_ENROLLMENT = 2022;

export const ROLE = {
  STUDENT: 1,
  TEACHER: 2,
  STUDY_ROOM_EDUCATOR: 3,
  DIRECTOR: 4,
  GUARDIAN: 5,
  CX: 8,
  INTERN_TEACHER: 9,
  TRAINEE: 10,
  SYSTEM_CONSUMER: 11,
  GRADES_MANAGER: 12,
  PEDAGOGIC: 14,
  FINANCIAL: 15,
  MANAGER: 16,
  VENDOR: 17,
  EXTERNAL_EMPLOYEE: 18,
  VENDOR_MANAGER: 19,
  ENROLLMENT_COORDINATOR: 20,
  OPERATIONAL_DIRECTOR: 21,
  CHARGING_MANAGER: 22,
  SECRETARY: 24,
  SYSTEM_ADMIN: 25,
};

export const enum RolesEnum {
  STUDENT = 1,
  TEACHER = 2,
  STUDY_ROOM_EDUCATOR = 3,
  DIRECTOR = 4,
  GUARDIAN = 5,
  CX = 8,
  INTERN_TEACHER = 9,
  TRAINEE = 10,
  SYSTEM_CONSUMER = 11,
  GRADES_MANAGER = 12,
  PEDAGOGIC = 14,
  FINANCIAL = 15,
  MANAGER = 16,
  VENDOR = 17,
  EXTERNAL_EMPLOYEE = 18,
  VENDOR_MANAGER = 19,
  ENROLLMENT_COORDINATOR = 20,
  OPERATIONAL_DIRECTOR = 21,
  CHARGING_MANAGER = 22,
  SECRETARY = 24,
  SYSTEM_ADMIN = 25,
}

export const isFirstAccess = (
  authUser: AuthUserInterface,
  role: RoleInterface
) => {
  return role.id === ROLE.GUARDIAN && !authUser.last_login;
};

export const isGuardian = (role: RoleInterface) => {
  return role.id === ROLE.GUARDIAN;
};

export const isStudent = (role: RoleInterface) => {
  return role.id === ROLE.STUDENT;
};

export const isVendor = (role: RoleInterface) => {
  return role.id === ROLE.VENDOR;
};

export const isVendorManager = (role: RoleInterface) => {
  return role.id === ROLE.VENDOR_MANAGER;
};

export const isEnrollmentCoordinator = (role: RoleInterface) => {
  return role.id === ROLE.ENROLLMENT_COORDINATOR;
};

export const isTeacher = (role: RoleInterface) => {
  return role.id === ROLE.TEACHER;
};

export const isCX = (role: RoleInterface) => {
  return role.id === ROLE.CX;
};

export const isDirector = (role: RoleInterface) => {
  return role.id === ROLE.DIRECTOR;
};

export const isGradesManager = (role: RoleInterface) => {
  return role.id === ROLE.GRADES_MANAGER;
};

export const isStudyRoomEducator = (role: RoleInterface) => {
  return role.id === ROLE.STUDY_ROOM_EDUCATOR;
};

export const isEnrollmentManager = (role: RoleInterface) => {
  return role.id === ROLE.MANAGER;
};

export const isOperationalDirector = (role: RoleInterface) => {
  return role.id === ROLE.OPERATIONAL_DIRECTOR;
};

export const isSecretary = (role: RoleInterface) => {
  return role.id === ROLE.SECRETARY;
};

export const isMonitor = (role: RoleInterface) => {
  return role.id === ROLE.STUDY_ROOM_EDUCATOR;
};

export const isSystemAdmin = (role: RoleInterface) => {
  return role.id === ROLE.SYSTEM_ADMIN;
};

export const ENROLLMENT_YEAR = 2022;

interface UnitsEnumInterface {
  [key: string]: string;
}

export const UNITS_ENUM: UnitsEnumInterface = {
  "Unidade Penha": "1",
  "Unidade Merces": "2",
  "Unidade Mascote": "3",
  Matriz: "4",
  "Unidade Marajoara": "5",
};

export const UNITS_ENUM_BY_ID: UnitsEnumInterface = {
  "1": "Unidade Penha",
  "2": "Unidade Merces",
  "3": "Unidade Mascote",
  "4": "Matriz",
  "5": "Unidade Marajoara",
};

export const SCHOOL_PLACES_STATUS = {
  PRE_CADASTRO: 1,
  LISTA_DE_ESPERA: 2,
  VAGA_RESERVADA: 3,
  VAGA_LIBERADA: 4,
  ESPERA_CANCELADA: 5,
};

export const PAYMENT_STATUS = {
  NAO_INICIADO: 1,
  AGUARDANDO_PAGAMENTO_DA_RESERVA: 2,
  AGUARDANDO_PAGAMENTO_DA_ESPERA: 3,
  PAGAMENTO_DA_ESPERA_EM_ATRASO: 4,
  PAGAMENTO_DA_ESPERA_CONFIRMADO: 5,
  PAGAMENTO_DA_RESERVA_EM_ATRASO: 6,
  PAGAMENTO_DA_RESERVA_CONFIRMADO: 7,
  AGUARDANDO_REEMBOLSO_DA_ESPERA: 8,
  REEMBOLSADO_DA_ESPERA: 9,
  AGUARDANDO_REEMBOLSO_DA_RESERVA: 10,
  REEMBOLSADO_DA_RESERVA: 11,
  AGUARDANDO_COMPENSACAO_PAGAMENTO: 12,
  ISENTO_DE_MATRICULA: 13,
  AGUARDANDO_PAGAMENTO_EM_DOIS_MEIOS: 14,
};

export const FINANCIAL_STATUS = {
  NAO_INICIADO: 1,
  AGUARDANDO_ANALISE_FINANCEIRA: 2,
  AGUARDANDO_PAGAMENTO_ANTECIPADO: 3,
  PAGAMENTO_ANTECIPADO_ATRASADO: 4,
  CARTA_EM_ANALISE: 5,
  EM_ATENDIMENTO_SAF: 6,
  ANALISE_FINANCEIRA_REPROVADA: 7,
  ANALISE_FINANCEIRA_APROVADA: 8,
  AGUARDANDO_PLANO_PAGAMENTO: 9,
};

export const DOCUMENTS_STATUS = {
  NAO_INICIADO: 1,
  AGUARDANDO_ENVIO_DOCUMENTACAO: 2,
  DOCUMENTOS_EM_ANALISE: 3,
  DOCUMENTO_OK: 4,
};

export const DOCUMENT_ANALYSIS_STATUS = {
  APROVADO: 1,
  REPROVADO: 2,
  AGUARDANDO_ANALISE: 3,
};

export const CONTRACT_STATUS = {
  NAO_INICIADO: 1,
  AGUARDANDO_ASSINATURA_CONTRATO: 2,
  CONTRATO_ASSINADO: 3,
  AGUARDANDO_VALIDACAO_ASSINATURA: 4,
};

export const SCHOLARSHIP_TYPE = {
  SMART: 1,
  FILHO_FUNCIONARIO: 2,
  PARCIAL: 3,
  SEM_BOLSA: 4,
};

export const EDUCATIONAL_LEVEL_TYPE = {
  FUNDAMENTAL_UM: 1,
  FUNDAMENTAL_DOIS: 2,
  ENSINO_MEDIO: 3,
};

export const FOOD_PAYMENT_STATUS = {
  PAGAMENTO_PENDENTE: 1,
  PAGAMENTO_CONFIRMADO: 2,
  PAGAMENTO_ATRASADO: 3,
  AGUARDANDO_COMPENSACAO_PAGAMENTO: 4,
  AGUARDANDO_REEMBOLSO_PAGAMENTO: 5,
  PAGAMENTO_REEMBOLSADO: 6,
  ISENTO: 7,
};

interface DomainInterface {
  [key: string]: number;
}

export const DOMAIN: DomainInterface = {
  "http://localhost:3000": 0,
  "https://app-homolog.escolamais.com": 1,
  "https://app.escolamais.com": 2,
};

export const ENROLLMENT_TYPE = {
  ENROLLMENT: 1,
  RE_ENROLLMENT: 2,
};

interface UnitsGradesInterface {
  [key: number]: string;
}

export const UNITS_GRADES: UnitsGradesInterface = {
  4639: "Penha",
  4641: "Mercês",
  4640: "Mascote",
  4642: "Marajoada",
};

interface UnitsGradesKanataToGrades {
  [key: string]: number;
}

export const UNITS_GRADES_KANATA_ID_TO_GRADES_ID: UnitsGradesKanataToGrades = {
  "1": 4639,
  "2": 4641,
  "3": 4640,
  "5": 4642,
};

export const MONTHS = [
  { label: "Janeiro", name: "Janeiro", id: 1, value: "1" },
  { label: "Fevereiro", name: "Fevereiro", id: 2, value: "2" },
  { label: "Março", name: "Março", id: 3, value: "3" },
  { label: "Abril", name: "Abril", id: 4, value: "4" },
  { label: "Maio", name: "Maio", id: 5, value: "5" },
  { label: "Junho", name: "Junho", id: 6, value: "6" },
  { label: "Julho", name: "Julho", id: 7, value: "7" },
  { label: "Agosto", name: "Agosto", id: 8, value: "8" },
  { label: "Setembro", name: "Setembro", id: 9, value: "9" },
  { label: "Outubro", name: "Outubro", id: 10, value: "10" },
  { label: "Novembro", name: "Novembro", id: 11, value: "11" },
  { label: "Dezembro", name: "Dezembro", id: 12, value: "12" },
];

export const ZERO_INDEXED_MONTHS = [
  { name: "Janeiro", id: 0 },
  { name: "Fevereiro", id: 1 },
  { name: "Março", id: 2 },
  { name: "Abril", id: 3 },
  { name: "Maio", id: 4 },
  { name: "Junho", id: 5 },
  { name: "Julho", id: 6 },
  { name: "Agosto", id: 7 },
  { name: "Setembro", id: 8 },
  { name: "Outubro", id: 9 },
  { name: "Novembro", id: 10 },
  { name: "Dezembro", id: 11 },
];

export const PLAN_NAME_DISPLAY = [{ name: "Anual" }, { name: "Mensal" }];

export const YEARS = ["2022", "2023"];

export const YESTERDAY = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);

export const PAYMENT_METHODS = [
  // { name: "Boleto", value: 1 },
  { name: "PIX", value: 2 },
  { name: "Cartão", value: 3 },
];

export const timeCache = 60;

export const UF_HELPER = [
  { value: "AC", id: 1 },
  { value: "AL", id: 2 },
  { value: "AP", id: 3 },
  { value: "AM", id: 4 },
  { value: "BA", id: 5 },
  { value: "CE", id: 6 },
  { value: "DF", id: 7 },
  { value: "ES", id: 8 },
  { value: "GO", id: 9 },
  { value: "MA", id: 10 },
  { value: "MT", id: 11 },
  { value: "MS", id: 12 },
  { value: "MG", id: 13 },
  { value: "PA", id: 14 },
  { value: "PB", id: 15 },
  { value: "PR", id: 16 },
  { value: "PE", id: 17 },
  { value: "PI", id: 18 },
  { value: "RJ", id: 19 },
  { value: "RN", id: 20 },
  { value: "RS", id: 21 },
  { value: "RO", id: 22 },
  { value: "RR", id: 23 },
  { value: "SC", id: 24 },
  { value: "SP", id: 25 },
  { value: "SE", id: 26 },
  { value: "TO", id: 27 },
];

export const KINSHIP_HELPER = [
  { value: 1, label: "Pai" },
  { value: 2, label: "Mãe" },
  { value: 3, label: "Avô" },
  { value: 4, label: "Avó" },
  { value: 5, label: "Bisavô" },
  { value: 6, label: "Bisavó" },
  { value: 7, label: "Irmão" },
  { value: 8, label: "Irmã" },
  { value: 9, label: "Tio" },
  { value: 10, label: "Tia" },
];

export const MARITAL_STATUS = [
  { value: 1, label: "Solteiro(a)" },
  { value: 2, label: "Casado(a)" },
  { value: 3, label: "Divorciado(a)" },
  { value: 4, label: "Viúvo(a)" },
  { value: 5, label: "Separado(a)" },
];

export const ENROLLMENT_STATUS = {
  FILA_DE_ESPERA: 1,
  CONFIRMACAO_DADOS_RESPONSAVEL: 2,
  CONFIRMACAO_DADOS_ALUNO: 3,
  SELECAO_PGTO_RESERVA: 4,
  COMPENSACAO_PGTO_RESERVA: 5,
  SELECAO_RESPONSAVEL_FINANCEIRO: 6,
  DOCUMENTACAO_FINANCEIRA_COMPLEMENTAR: 7,
  ANALISE_FINANCEIRA_MANUAL: 8,
  DOCUMENTACAO_CONTRATO: 9,
  GERACAO_CONTRATO: 10,
  ASSINATURA_CONTRATO: 11,
  DOCUMENTACAO_COMPLEMENTAR: 12,
};

export const OLD_ENROLLMENT_STATUS = {
  MATRICULA_NAO_INICIADA: 1,
  MATRICULA_PENDENTE: 2,
  MATRICULADO: 3,
  INATIVO: 4,
};

export const GETWAY_PAYMENT = [
  { value: 1, label: "IUGU" },
  { value: 2, label: "REDE" },
];
