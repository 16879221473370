import { StudentDataInterface } from "interfaces/constants";
import {
  IGradeGroupFormatedClasses,
  IStudentsByGradegroupResponse,
} from "interfaces/gradeGroupInterfaces";
import {
  GetUserKanataResponse,
  UnitGradesInteface,
} from "services/grades.service";

export const enum StudyItinerariesInternalActionTypes {
  SET_UNIT_LIST = "SET_UNIT_LIST",
  SET_SELECTED_UNIT = "SET_SELECTED_UNIT",
  SET_CLASSES = "SET_CLASSES",
  SET_SELECTED_PERIOD = "SET_SELECTED_PERIOD",
  SET_SELECTED_GRADE = "SET_SELECTED_GRADE",
  SET_SELECTED_CLASS = "SET_SELECTED_CLASS",
  SET_LOADING_CLASSES = "SET_LOADING_CLASSES",
  SET_LOADING_LIST = "SET_LOADING_LIST",
  SET_STUDENT_LIST = "SET_STUDENT_LIST",
  SET_LOADING_SELECTED_STUDENT = "SET_LOADING_SELECTED_STUDENT",
  SET_CURRENT_STUDENT = "SET_CURRENT_STUDENT",
  SET_CURRENT_KANATA_STUDENT = "SET_CURRENT_KANATA_STUDENT",
  SET_DRAWER_IS_OPEN = "SET_DRAWER_IS_OPEN",
  SET_SELECTED_STUDENT = "SET_SELECTED_STUDENT",
}

export type StudyItinerariesInternalActions =
  | {
      type: StudyItinerariesInternalActionTypes.SET_UNIT_LIST;
      payload: UnitGradesInteface[];
    }
  | {
      type: StudyItinerariesInternalActionTypes.SET_SELECTED_UNIT;
      payload: number;
    }
  | {
      type: StudyItinerariesInternalActionTypes.SET_CLASSES;
      payload: IGradeGroupFormatedClasses[] | undefined;
    }
  | {
      type: StudyItinerariesInternalActionTypes.SET_SELECTED_PERIOD;
      payload: number;
    }
  | {
      type: StudyItinerariesInternalActionTypes.SET_SELECTED_GRADE;
      payload: number;
    }
  | {
      type: StudyItinerariesInternalActionTypes.SET_SELECTED_CLASS;
      payload: number;
    }
  | {
      type: StudyItinerariesInternalActionTypes.SET_LOADING_CLASSES;
      payload: boolean;
    }
  | {
      type: StudyItinerariesInternalActionTypes.SET_LOADING_LIST;
      payload: boolean;
    }
  | {
      type: StudyItinerariesInternalActionTypes.SET_STUDENT_LIST;
      payload: IStudentsByGradegroupResponse[] | undefined;
    }
  | {
      type: StudyItinerariesInternalActionTypes.SET_LOADING_SELECTED_STUDENT;
      payload: boolean;
    }
  | {
      type: StudyItinerariesInternalActionTypes.SET_CURRENT_STUDENT;
      payload: StudentDataInterface | undefined;
    }
  | {
      type: StudyItinerariesInternalActionTypes.SET_CURRENT_KANATA_STUDENT;
      payload: GetUserKanataResponse | undefined;
    }
  | {
      type: StudyItinerariesInternalActionTypes.SET_DRAWER_IS_OPEN;
      payload: boolean;
    }
  | {
      type: StudyItinerariesInternalActionTypes.SET_SELECTED_STUDENT;
      payload: IStudentsByGradegroupResponse;
    };

export interface IStudyItinerariesInternalState {
  classes: IGradeGroupFormatedClasses[] | undefined;
  students: IStudentsByGradegroupResponse[] | undefined;
  units: UnitGradesInteface[] | undefined;
  filters: {
    selectedPeriod: number;
    selectedGrade: number;
    selectedClass: number;
    selectedUnit: number;
  };
  loaders: {
    isLoadingSelectedStudent: boolean;
    isLoadingClasses: boolean;
    isLoadingList: boolean;
  };
  drawerIsOpen: boolean;
  selectedStudent: IStudentsByGradegroupResponse | undefined;
  currentStudent: StudentDataInterface | undefined;
  currentKanataStudent: GetUserKanataResponse | undefined;
}
