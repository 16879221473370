import styled from "styled-components";
import * as colors from "styles/colors";

export const PaginationStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;

  color: ${colors.GRAY_TWO};

  div.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  strong.paginationInfos {
    text-transform: uppercase;
    color: ${colors};
  }

  div.pageNumber {
    display: flex;
    flex-direction: row;
  }

  div.selectedPage {
    color: ${colors.BLUE};
    font-size: 14px;
    font-weight: 600;
  }

  div.pages {
    margin-top: 2px;
    &:hover {
      cursor: pointer;
    }
  }

  svg.icon {
    &:hover {
      cursor: pointer;
      color: ${colors.PURPLE};
    }
  }
`;

export const SecondPaginationStyled = styled.div`
  display: flex;
  flex-direction: row;

  margin-left: auto;
  color: ${colors.GRAY_SCALE};

  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  div.icons {
    display: flex;
    margin-left: 2.268em;

    div.secondIcon {
      margin-left: 2em;
    }

    svg.iconBlock {
      cursor: not-allowed;
    }

    svg.icon {
      cursor: pointer;
      color: ${colors.PURPLE};
    }
  }
`;
