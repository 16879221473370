import styled from "styled-components";
import { BLUE } from "styles/colors";

export const RegisterBusinessDayStyle = styled.div`
  div.enrollment-title {
    font-size: 20px;
    font-weight: bold;
    color: #ed008c;

    div.subtitle {
      font-weight: 500;
      font-size: 16px;
      color: black;
    }
  }
  div.subdiv {
    display: flex;
  }
`;

export const CentralizedDiv = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledTable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  table {
    border-radius: 15px;
    border: 2px solid #000;
    thead {
      background-color: ${BLUE};
      color: #fff;
      justify-content: center;
      align-items: center;
      text-align: center;
      border: 2px solid #000;
      border-radius: 15px;
    }
    th {
      border-right: 2px solid #000;
      border-bottom: none;
      padding: 1em;
    }
  }
  tbody {
    tr {
      border: 1px solid #000;

      td {
        text-align: center;
        padding: 0.5em;
        border-right: 1px solid #000;
      }
    }
  }
`;
