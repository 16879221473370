import { useCallback, useEffect, useRef, useState } from "react";
import HTTP_STATUS from "http-status-codes";
import { useTranslation } from "react-i18next";

import { toastError } from "components/Toast";

import { getYear } from "services/multipurpose";
import { getUnits, unitManageVacanciesInfo } from "services/units.service";

import { YearsInterface } from "interfaces/constants";
import { UnitInterface } from "interfaces/unit";
import { IClassesAndVacancies } from "./types";

export const useManageVacancies = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  const [units, setUnits] = useState<[UnitInterface] | []>([]);
  const [years, setYears] = useState<[YearsInterface] | []>([]);

  const [selectedUnit, setSelectedUnit] = useState<string>("");
  const [selectedYear, setSelectedYear] = useState<string>("");
  const [selectedClassesItemIndex, setSelectedClassesItemIndex] =
    useState<number>(0);

  const [isOpenDrawerClassesAndVacancies, setIsOpenDrawerClassesAndVacancies] =
    useState(false);

  const [
    isOpenDrawerVacanciesReenrollment,
    setIsOpenDrawerVacanciesReenrollment,
  ] = useState(false);

  const [isOpenDrawerVacanciesReserved, setIsOpenDrawerVacanciesReserved] =
    useState(false);

  const [classesAndVacancies, setClassesAndVacancies] = useState<
    IClassesAndVacancies[]
  >([]);

  const [selectedClassesAndVacancies, setSelectedClassesAndVacancies] =
    useState<IClassesAndVacancies | null>(null);

  const isFirstRender = useRef(true);

  const { t } = useTranslation();
  const i18n_prefix = "pages.EDF.manageVacancies";

  const handleSelectClassesAndVacancies = (index: number) => {
    setSelectedClassesItemIndex(index);
    setIsOpenDrawerClassesAndVacancies(true);
  };

  const handleSelectVacanciesReenrollment = (index: number) => {
    setSelectedClassesItemIndex(index);
    setIsOpenDrawerVacanciesReenrollment(true);
  };

  const handleSelectVacanciesReserved = (index: number) => {
    setSelectedClassesItemIndex(index);
    setIsOpenDrawerVacanciesReserved(true);
  };

  const getManageVacanciesInfo = useCallback(async () => {
    setIsLoading(true);

    const res = await unitManageVacanciesInfo({
      year: selectedYear,
      unit: selectedUnit,
    });

    if (res?.status !== HTTP_STATUS.OK) {
      toastError(t(`${i18n_prefix}.manageVacanciesGetDataError`));
      setIsLoading(false);
    }

    setClassesAndVacancies(res?.data.results);
    setIsLoading(false);
  }, [selectedUnit, selectedYear, t]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const fetchUnits = getUnits(true);
      const fetchYears = getYear({ more_years: 1 });

      const [units, years] = await Promise.all([fetchUnits, fetchYears]);

      const responseHasError = [units?.status, years?.status].find(
        (item: number) => item !== HTTP_STATUS.OK
      );

      const responseHasData = [units?.data, years?.data].find(
        (item) => item.length === 0
      );

      if (responseHasError || responseHasData) {
        setHasError(true);
        setIsLoading(false);
        return;
      }

      setUnits(units?.data);
      setYears(years?.data.results);

      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    (async () => {
      if (selectedUnit && selectedYear) {
        await getManageVacanciesInfo();
      }
    })();
  }, [selectedUnit, selectedYear, getManageVacanciesInfo]);

  useEffect(() => {
    setSelectedClassesAndVacancies(
      classesAndVacancies[selectedClassesItemIndex]
    );
  }, [classesAndVacancies, selectedClassesItemIndex]);

  return {
    isLoading,
    hasError,
    units,
    years,
    selectedUnit,
    selectedYear,
    selectedClassesItemIndex,
    isOpenDrawerClassesAndVacancies,
    isOpenDrawerVacanciesReenrollment,
    isOpenDrawerVacanciesReserved,
    classesAndVacancies,
    setClassesAndVacancies,
    setSelectedUnit,
    setSelectedYear,
    setIsOpenDrawerClassesAndVacancies,
    setIsOpenDrawerVacanciesReenrollment,
    setIsOpenDrawerVacanciesReserved,
    setSelectedClassesItemIndex,
    handleSelectClassesAndVacancies,
    handleSelectVacanciesReenrollment,
    handleSelectVacanciesReserved,
    selectedClassesAndVacancies,
    setSelectedClassesAndVacancies,
    getManageVacanciesInfo,
  };
};
