import styled from "styled-components";

export const Container = styled.div`
  max-width: 1008px;
  padding: ${({ theme }) => theme.space[6]};
  margin: 0 auto;

  h2 {
    font-size: ${({ theme }) => theme.fontSizes.web.h2};
    color: ${({ theme }) => theme.colors.brandingOrange};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    margin: 0;
  }

  @media (max-width: 576px) {
    h1 {
      font-size: ${({ theme }) => theme.fontSizes.web.h1};
    }

    h2 {
      font-size: ${({ theme }) => theme.fontSizes.mobile.h2};
    }
  }
`;

export const StudentsList = styled.div`
  margin-top: ${({ theme }) => theme.space[4]};

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(309px, 1fr));
  gap: ${({ theme }) => theme.space[4]};
`;

export const StudentCard = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.primaryDefault};
  padding: ${({ theme }) => theme.space[4]};

  display: flex;
  align-items: center;

  min-height: 81px;
  transition: all 0.2s ease;
  cursor: pointer;

  & > div {
    width: 100%;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryLight90};
  }

  @media (max-width: 768px) {
    min-height: 120px;
  }
`;
