import styled from "styled-components";

export const DynamicDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: 5px 0; // TODO: nao tem na lib
`;

export const OldDynamics = styled.div`
  display: flex;
  gap: 0 37px; // TODO: nao tem na lib

  div.dynamicName {
    width: 100%;
  }

  div {
    display: flex;
    flex-direction: column;

    gap: 17px 0; // TODO: nao tem na lib
    width: 144px;

    margin-top: 17px; // TODO: nao tem na lib

    label {
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      font-size: ${({ theme }) => theme.fontSizes.web.sm};
      line-height: ${({ theme }) => theme.lineHeights.normal};

      color: ${({ theme }) => theme.colors.greyScale95};
    }

    span {
      display: flex;
      align-items: center;

      gap: 0 6.62px; // TODO: nao tem na lib

      font-style: italic;
      font-weight: ${({ theme }) => theme.fontWeights.regular};
      font-size: ${({ theme }) => theme.fontSizes.web.sm};
      line-height: ${({ theme }) => theme.lineHeights.normal};

      margin-left: ${({ theme }) => theme.space[2]};
    }
  }
`;
