import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.space[6]} ${({ theme }) => theme.space[4]};
  width: 100%;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    margin-top: ${({ theme }) => theme.space[20]};
  }
`;

export const UnitsListContainer = styled.ul`
  list-style: none;
  margin: 0;

  li {
    display: flex;
    gap: ${({ theme }) => theme.space[1]};
  }
`;
