import { useContext, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HTTP_STATUS from "http-status-codes";

import Icon from "@mdi/react";
import { mdiCashClock } from "@mdi/js";

import { Title } from "components";
import { toastError, toastSuccess } from "components/Toast";

import { regularizerPayment } from "services/payment.service";

import { GETWAY_PAYMENT } from "helpers/constants";
import { ILocationState, IRegularizerData } from "./interface";

import * as S from "./style";

export const PaymentRegularizer = () => {
  const history = useHistory();
  const { state } = useLocation<ILocationState>();
  const { t } = useTranslation();

  const [invoiceId, setInvoiceId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [gatewayPaymentType, setGatewayPaymentType] = useState(1);

  const prefix = "pages.EDF.paymentRegularizer";

  const handleSubmit = async () => {
    try {
      setIsLoading(true);

      const regularizerData: IRegularizerData = {
        gateway_pagamento: gatewayPaymentType,
        enrollment_id: state.student.last_enrollment.id,
      };

      if (gatewayPaymentType === 1) {
        if (invoiceId.replaceAll(" ", "").length === 0) {
          toastError(`${t(`${prefix}.emptyInput`)}`);
          return;
        }

        regularizerData.invoice_id = invoiceId;
      }

      const res = await regularizerPayment(regularizerData);

      if (res?.status === HTTP_STATUS.OK) {
        toastSuccess(`${t(`${prefix}.regularizerSuccess`)}`);
        setTimeout(() => history.push("/home"), 1000);
      } else {
        throw new Error(res?.data?.error);
      }
    } catch (error) {
      toastError(error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <S.Container>
      <div className="pageLogo">
        <Icon path={mdiCashClock} size={4} />
      </div>

      <Title>{t(`${prefix}.title`)}</Title>
      <p>{t(`${prefix}.description`)}</p>

      <div className="studentInfo">
        <p>
          <strong>{t(`${prefix}.name`)}</strong>{" "}
          {state.student.last_enrollment.student_name}
        </p>
        <p>
          <strong>{t(`${prefix}.unit`)}</strong>{" "}
          {state.student.last_enrollment.unit_name}
        </p>
        <p>
          <strong>{t(`${prefix}.year`)}</strong>{" "}
          {state.student.last_enrollment.year}
        </p>
      </div>

      <div className="formValue">
        <div className="paymentOptions">
          <h2>{t(`${prefix}.formTitle`)}</h2>
          <div className="radiosInputs">
            {GETWAY_PAYMENT.map((paymentOption) => (
              <label>
                <input
                  type="radio"
                  id="paymentType"
                  name="paymentType"
                  value={paymentOption.value}
                  checked={gatewayPaymentType === paymentOption.value}
                  onChange={(e) => setGatewayPaymentType(paymentOption.value)}
                />
                {paymentOption.label}
              </label>
            ))}
          </div>
        </div>

        {gatewayPaymentType === 1 ? (
          <>
            <input
              type="text"
              placeholder={t(`${prefix}.iuguId`)}
              value={invoiceId}
              onChange={(e) => setInvoiceId(e.target.value)}
            />
            <button onClick={handleSubmit} disabled={isLoading}>
              {t(`${prefix}.confirmPayment`)}
            </button>
          </>
        ) : (
          <>
            <button onClick={handleSubmit} disabled={isLoading}>
              {t(`${prefix}.confirmPayment`)}
            </button>
          </>
        )}
      </div>
    </S.Container>
  );
};
