// React
import { useCallback, useEffect, useState } from "react";
// Interfaces
import { UnitInterface } from "interfaces/unit";
import {
  IFeedingDynamics,
  IFeedingDynamicsStatus,
  IFoodItems,
} from "interfaces/mealInterfaces";
import { IPagination } from "interfaces/utils";
import { IForm } from "./interface";
// Services
import { getUnits } from "services/units.service";
import {
  getFeedingDynamics,
  getFeedingDynamicsStatus,
  getFoodItemsList,
} from "services/meals.service";
// Libs
import HTTP_STATUS from "http-status-codes";

export const ManageFoodItemsHelper = (form: IForm) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [units, setUnits] = useState<Array<UnitInterface>>();
  const [dynamicsStatus, setDynamicsStatus] =
    useState<IFeedingDynamicsStatus>();
  const [dynamicsList, setDynamicsList] =
    useState<IPagination<IFeedingDynamics> | null>();

  const [items, setItems] = useState<IFoodItems>();
  const [selectedItem, setSelectedItem] = useState<string>("almoco");

  const newResearch = !loading && dynamicsList === null ? true : false;

  const getList = useCallback(async () => {
    setDynamicsList(null);
    const response = await getFeedingDynamics({
      unidade: form.unit,
      status: form.status,
      item: selectedItem,
    });

    if (response && response.status === HTTP_STATUS.OK) {
      setDynamicsList(response.data);
      setLoading(false);
    } else {
      setError(true);
      setLoading(false);
    }
  }, [form, selectedItem]);

  useEffect(() => {
    try {
      (async () => {
        if (!dynamicsList) {
          const unitsWithStudents = true;

          const units = await getUnits(unitsWithStudents);
          if (units && units.status === HTTP_STATUS.OK) {
            setUnits(units.data);
          }

          const items = await getFoodItemsList();
          if (items && items.status === HTTP_STATUS.OK) {
            setItems(items.data);
          }

          const status = await getFeedingDynamicsStatus();
          if (status && status.status === HTTP_STATUS.OK) {
            setDynamicsStatus(status.data);
          }
        }

        getList();
      })();
    } catch (err) {
      setError(true);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getList]);

  return {
    loading,
    error,
    units,
    dynamicsStatus,
    dynamicsList,
    newResearch,
    items,
    selectedItem,
    setSelectedItem,
    getList,
  };
};
