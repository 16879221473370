// Libs
import { useState, useEffect, useContext } from "react";
import { Form, Field } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import HTTP_STATUS from "http-status-codes";

// Components
import { TextInput, Button, SelectInput } from "components";
import { StudentFoodCreditCard } from "./components/StudentFoodCreditCard";
import { toastError } from "components/Toast";

// Helpers
import {
  composeValidators,
  noSpecialCharactersAndNumbers,
  required,
} from "helpers/fieldValidators";
import { StudentFoodCreditInterface } from "interfaces/mealInterfaces";

// Styles
import { StudentFoodCreditStyle } from "./style";
import { LinearProgress } from "@material-ui/core";

// Services
import { getUnitsGradesSchoolPlaces } from "services/units.service";
import { getStudentFoodCredit } from "services/meals.service";

// Img
import loadingGif from "static/img/loading.gif";

// Interfaces
import { GetUnitsGradesResponse } from "interfaces/unit";

type StudentFoodCreditValues = {
  student_name: string;
  unit: string;
  educational_level: string;
};

export const StudentFoodCredit = () => {
  const [loading, setLoading] = useState(true);
  const [units, setUnits] = useState<Array<GetUnitsGradesResponse>>();
  const [selectedUnit, setSelectedUnit] =
    useState<GetUnitsGradesResponse | null>();
  const [studentsResult, setStudentsResult] =
    useState<Array<StudentFoodCreditInterface>>();

  useEffect(() => {
    const getUnit = async () => {
      const unitsGrade = await getUnitsGradesSchoolPlaces();
      if (unitsGrade && unitsGrade.status === HTTP_STATUS.OK) {
        setUnits(unitsGrade.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    };
    getUnit();
  }, []);

  const onSubmit = async ({
    student_name,
    unit,
    educational_level,
  }: StudentFoodCreditValues) => {
    const response = await getStudentFoodCredit({
      student_name,
      unit,
      educational_level,
    });

    if (response && response.status === HTTP_STATUS.OK) {
      response.data.results.length > 0
        ? setStudentsResult(response.data.results)
        : toastError("Não há alunos registrados!");
    } else {
      toastError("Erro ao buscar alunos pelo nome!");
    }
  };

  return (
    <div>
      {loading && <LinearProgress className="m-3" color="secondary" />}
      {units && !loading && (
        <StudentFoodCreditStyle className="mt-4 ml-sm-0 mr-sm-0 ml-xl-4 mr-xl-4">
          <div className="col-xl-12">
            <h1 className="title mb-3">Lançar Crédito por Aluno</h1>
            <p className="subtitle h6">
              Para realizar o lançamento do crédito de alimentação, busque pelo
              nome do aluno, unidade e segmento:
            </p>
          </div>

          <Form onSubmit={onSubmit}>
            {({ form, handleSubmit, submitting, values }) => (
              <form onSubmit={handleSubmit} className="col-12">
                <div className="d-flex flex-column flex-xl-row">
                  <div className="col-xl-6 pl-0 pr-xl-3 pr-0">
                    <Field
                      component={TextInput}
                      name="student_name"
                      type="text"
                      placeholder=" "
                      label="Digite o nome do aluno"
                      validate={composeValidators(
                        required,
                        noSpecialCharactersAndNumbers
                      )}
                      parse={(value) => (value === " " ? null : value)}
                      className="searchInput"
                      setSearch
                      required
                      hideCheck
                    />
                  </div>
                  <div className="col-xl-3 pl-xl-3 pl-0 pr-xl-3 pr-0">
                    <Field
                      component={SelectInput}
                      name="unit"
                      required
                      disabled={loading}
                    >
                      <option value="">Selecione unidade...</option>
                      {units?.map((units) => {
                        return (
                          <option key={units.unit.id} value={units.unit.id}>
                            {units.unit.name}
                          </option>
                        );
                      })}
                    </Field>
                    <OnChange name="unit">
                      {async (value) => {
                        if (value) {
                          setSelectedUnit(
                            units?.find(
                              (unitSelected) =>
                                unitSelected.unit.id === parseInt(value)
                            )
                          );
                        } else {
                          setSelectedUnit(null);
                        }
                      }}
                    </OnChange>
                  </div>
                  {selectedUnit && (
                    <div className="col-xl-3 pl-xl-3 pl-0 pr-xl-3 pr-0">
                      <Field
                        component={SelectInput}
                        name="educational_level"
                        required
                      >
                        <option value="">Selecione ciclo...</option>
                        {selectedUnit.unit.educational_level.map(
                          (educationalLevel) => {
                            return (
                              <option
                                key={educationalLevel.id}
                                value={educationalLevel.id}
                              >
                                {educationalLevel.name}
                              </option>
                            );
                          }
                        )}
                      </Field>
                    </div>
                  )}
                </div>

                <div className="mt-2 mb-2 col-xl-2 col-lg-4 pl-0 pr-0">
                  <Button
                    disabled={
                      !(values.educational_level && values.student_name) ||
                      submitting
                    }
                  >
                    {submitting ? (
                      <img height="25" src={loadingGif} alt="loading" />
                    ) : (
                      "Consultar"
                    )}
                  </Button>
                </div>
              </form>
            )}
          </Form>

          {studentsResult && (
            <div className="container-fluid ">
              <div
                className="row row-cols-xl-3 row-cols-lg-2 row-cols-md-1 
                row-cols-1 justify-content-start"
              >
                {studentsResult?.map((student) => (
                  <div className="col mt-3" key={student.id}>
                    <StudentFoodCreditCard studentFoodCredit={student} />
                  </div>
                ))}
              </div>
            </div>
          )}
        </StudentFoodCreditStyle>
      )}
    </div>
  );
};
