// React
import { useContext, useEffect, useState } from "react";
// Styles
import * as S from "./styles";
// Components
import {
  Text,
  Title,
  Button,
  BorderlessButton,
  Loading,
} from "components/atoms";
import { EnrollmentStepCircle } from "components/molecules/EnrollmentStepCircle";
import { toastError } from "components/Toast";
// Libs
import { useTranslation } from "react-i18next";
import { useLocation, useHistory } from "react-router-dom";
import formatStringByPattern from "format-string-by-pattern";
import HTTP_STATUS from "http-status-codes";
// Interfaces
import { IStudentData } from "./interface";
import { StudentInterface } from "interfaces/students";
// Services
import { getStudentData } from "services/student.service";
import { updateEnrollmentToNextStep } from "services/enrollment.service";
// Helpers
import { formatDate } from "helpers/helpers";
import { ENROLLMENT_STATUS } from "helpers/constants";
import { Storage } from "Storage";

export const StudentData = () => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.studentData";

  const { state } = useLocation<IStudentData>();
  const history = useHistory();

  const [studentData, setStudentData] = useState<StudentInterface>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const token = Storage.token.get();

  useEffect(() => {
    (async () => {
      const response = await getStudentData({
        studentId: state.enrollment.guard.student.id,
      });

      if (response && response.status === HTTP_STATUS.OK) {
        setStudentData(response.data);
        setIsLoading(false);
      } else {
        setError(true);
        setIsLoading(false);
      }
    })();
  }, [state]);

  const nextStep = async () => {
    if (state.enrollment.status === ENROLLMENT_STATUS.CONFIRMACAO_DADOS_ALUNO) {
      const response = await updateEnrollmentToNextStep({
        enrollmentId: state.enrollment.id,
        step: "step2",
        token: token?.access ?? "",
      });

      if (response && response.status === HTTP_STATUS.OK) {
        history.push({
          pathname: "/matricula/pagamento-da-reserva",
          state: {
            user: state.user,
            enrollment: state.enrollment,
          },
        });
      } else {
        toastError(t(`${TRANSLATE}.toastError`));
        setIsLoading(false);
      }
    } else {
      history.push({
        pathname: "/matricula/pagamento-da-reserva",
        state: {
          user: state.user,
          enrollment: state.enrollment,
        },
      });
    }
  };

  if (isLoading && !studentData) {
    return (
      <S.LoadContainer>
        <h4>{t(`${TRANSLATE}.loadingStudent`)}</h4>
        <Loading />
      </S.LoadContainer>
    );
  }

  if (error && !isLoading) {
    return <div className="ml-3">{t(`${TRANSLATE}.errorMessage`)}</div>;
  }

  if (studentData) {
    return (
      <S.Container>
        <EnrollmentStepCircle
          studentName={state.enrollment.guard.student.nome}
          nextStep={state.enrollment.steps[2].name}
          step={2}
          totalSteps={7}
          currentStepName={state.enrollment.steps[1].name}
          subtitle={t(`${TRANSLATE}.enrollment`, {
            year: state.enrollment.year,
          })}
          detail
        />

        <div className="grade">
          <p className="mb-0">{t(`${TRANSLATE}.unitGradeEducationalLevel`)}</p>
          <strong>
            {t(`${TRANSLATE}.unitGradeEducationalLevelInfos`, {
              unitName: state.enrollment.unit.name,
              grade: state.enrollment.grade,
              educationalLevel: state.enrollment.educational_level.name,
            })}
          </strong>
        </div>

        <div className="cardStudent">
          <div className="cardStudentName">
            <img src={studentData.photo_url} alt="Foto de perfil" />
            <Text fontSize="18px">{studentData.nome}</Text>
          </div>

          <div className="cardStudentHeader">
            <Title fontSize="18px" fontWeight="600">
              {t(`${TRANSLATE}.personalData`)}
            </Title>
            {(state.enrollment.status ===
              ENROLLMENT_STATUS.CONFIRMACAO_DADOS_ALUNO ||
              state.editStudent) && (
              <BorderlessButton
                fontSize="14px"
                type="button"
                onClick={() =>
                  history.push({
                    pathname: "/matricula/editar-dados-do-estudante",
                    state: {
                      user: state.user,
                      enrollment: state.enrollment,
                      student: studentData,
                    },
                  })
                }
              >
                {t(`${TRANSLATE}.buttonEditData`)}
              </BorderlessButton>
            )}
          </div>

          <div className="cardStudentContent">
            <div>
              <Text>{t(`${TRANSLATE}.birthDate`)}</Text>
              <div className="data">
                <p>{state.enrollment && formatDate(studentData.birth_date)}</p>
              </div>
            </div>
            <div className="infos">
              <div>
                <Text>{t(`${TRANSLATE}.rg`)}</Text>
                <div className="data">
                  <p>
                    {state.enrollment &&
                      formatStringByPattern("99.999.999-9", studentData.rg)}
                  </p>
                </div>
              </div>
              <div>
                <Text>{t(`${TRANSLATE}.cpf`)}</Text>
                <div className="data">
                  <p>
                    {state.enrollment &&
                      formatStringByPattern("999.999.999-99", studentData.cpf)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="buttons">
          <Button
            outline
            onClick={() => history.goBack()}
            disabled={state.editStudent}
          >
            {t(`${TRANSLATE}.buttonBack`)}
          </Button>
          <Button onClick={() => nextStep()} disabled={isLoading}>
            {t(`${TRANSLATE}.buttonAdvance`)}
          </Button>
        </div>
      </S.Container>
    );
  }
};
