import {
  IQuarterPublicationsByUnitResponse,
  IQuarterPublicationsByUnitRequest,
  IPublishQuarterRequest,
} from "interfaces/boletim";
import { GradeAPI, KanataAPI, RoteirosAPI } from "./api";
import { ErrorHandlerFunction } from "./service-helpers";
import { CURRENT_YEAR_ENROLLMENT, ROLE } from "helpers/constants";

import {
  IGradeAttendanceSheet,
  IGradeGroupUnitClasses,
  IStudentsByGradegroupResponse,
} from "interfaces/gradeGroupInterfaces";

export interface EducationalLevelResponse {
  id: number;
  educational_level: number;
  educational_level_name: string;
  grade: number;
}
// ESSE ENDPOINT NÃO É DO GRADES!
// ele trás os níveis educacionais do Kanata! caso precise para o grades consulte
// a função getGradeGroup (mais abaixo).
export const getEducationalLevel = async () => {
  const response = await KanataAPI.get<Array<EducationalLevelResponse>>(
    `cx/educational_level_grades`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export interface AssignClassUsersResponse {
  id: number;
  user_id: number;
  full_name: string;
  cpf: string;
  unit: number;
  email: string;
  role: string;
  role_id: number;
}

// retorna professores e monitores para a atribuição de disciplinas
export const getAssignClassUsers = async (unit: number) => {
  const response = await GradeAPI.get<Array<AssignClassUsersResponse>>(
    `classes/assign_class_users?unit=${unit}`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export interface AssignClassResponse {
  id: number;
  teacher?: number;
  teacher_name?: string;
  educator?: number;
  educator_name?: string;
  user_id: number;
  user_name: string;
  subject: number;
  subject_name: string;
  subject_short_name: string;
  gradegroup: number;
  grade: number;
  letter: string;
  educational_level_id: number;
  educational_level_name: string;
  actived: boolean;
  created_at: string;
  year: number;
}

interface GetAssignClassInterface {
  unit: number;
  role?: number;
  id?: number;
  user_id?: number;
}

// Retorna as disciplinas associadas a professores.
export const getAssignClass = async ({
  unit,
  role,
  id,
  user_id,
}: GetAssignClassInterface) => {
  const response = await GradeAPI.get<Array<AssignClassResponse>>(
    `classes/assign_class?unit=${unit}${
      role
        ? role === ROLE.TEACHER
          ? `&teacher_id=${id}`
          : `&educator_id=${id}`
        : ``
    }${user_id ? `&user_id=${user_id}` : ``}`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export interface MentoryClassResponse {
  id: number;
  teacher: number;
  gradegroup: number;
  actived: boolean;
  created_at: string;
  year: number;
}

// Esse cara não está mais sendo usado.
// ele trazia as mentorias quando as mesmas não eram associadas as disciplinas.
export const getMentoryClass = async () => {
  const response = await GradeAPI.get<Array<MentoryClassResponse>>(
    `classes/teacher_mentory/`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export interface SubjectList {
  id: number;
  name: string;
  short_name: string;
  partial_exam: boolean;
  quarter_exam: boolean;
  formative_exam: boolean;
  assignment_average: boolean;
  synopsis_average: boolean;
  absences: boolean;
  recovery: boolean;
  actived: boolean;
}
// Trás a lista de Componentes Curriculares (Disciplinas) do grades.
export const getSubjectList = async () => {
  const response = await GradeAPI.get<Array<SubjectList>>(
    `classes/subject`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export interface GradeGroups {
  id: number;
  klass: number;
  klass_grade: number;
  letter: string;
  school_unit: number;
  klass_level: number;
  klass_level_name: string;
}

// Trás a lista de turmas do Grades
export const getGradeGroup = async (unit: number, year: number) => {
  const response = await GradeAPI.get<Array<GradeGroups>>(
    `classes/gradegroup/${unit}?year=${year}`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface AtribuirDisciplina {
  id: number;
  teacher: number;
  teacher_name: string;
  subject: number;
  subject_name: string;
  gradegroup: number;
  grade: number;
  letter: string;
  educational_level_id: number;
  educational_level_name: string;
  actived: boolean;
  created_at: string;
  year: number;
}

export const atribuirDisciplina = async ({
  teacher,
  subject,
  gradegroup,
  year,
  educator,
}: {
  teacher?: number;
  educator?: number;
  subject: number;
  gradegroup: number;
  year: number;
}) => {
  let payload: any = {};
  payload[`${teacher ? `teacher` : `educator`}`] = teacher ? teacher : educator;
  payload.subject = subject;
  payload.gradegroup = gradegroup;
  payload.year = year;
  payload.actived = true;
  const response = await GradeAPI.post<AtribuirDisciplina>(
    `/classes/assign_class/`,
    payload
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface RemoveAtribuicaoInterface {
  id: number;
  teacher: number;
  teacher_name: string;
  subject: number;
  subject_name: string;
  gradegroup: number;
  grade: number;
  letter: string;
  educational_level_id: number;
  educational_level_name: string;
  actived: boolean;
  created_at: string;
  year: number;
}

export const removeAtribuicaoDisciplina = async ({
  teacher,
  assign_id,
  year,
  educator,
}: {
  teacher?: number;
  educator?: number;
  assign_id: number;
  year: number;
}) => {
  let payload: any = {};
  payload[`${teacher ? `teacher` : `educator`}`] = teacher ? teacher : educator;
  payload.year = year;
  payload.actived = false;

  const response = await GradeAPI.put<RemoveAtribuicaoInterface>(
    `/classes/assign_class/${assign_id}`,
    payload
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export interface UnitGradesInteface {
  id: number;
  name: string;
  year: number;
  unit_id_saf: number;
}

export const getUnitsGrades = async () => {
  const response = await GradeAPI.get<UnitGradesInteface[]>(
    `/classes/unit`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const UNIT_ID_TO_DISCONSIDER = 4638;
    const units = response.data.filter(
      (unit: UnitGradesInteface) => unit.id !== UNIT_ID_TO_DISCONSIDER
    );
    const data = {
      data: units,
      status: response.status,
    };
    return data;
  }
};

export interface QuartersResponseInterface {
  id: number;
  name: string;
  actived: boolean;
  begin_date: string;
  end_date: string;
  quarter: number;
  current: boolean;
  year: number;
  quarter_display: string;
}

export const getQuarters = async () => {
  const response = await GradeAPI.get<Array<QuartersResponseInterface>>(
    `classes/quarter`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export interface ReleaseTypesResponse {
  user_id: number;
  evaluations_type: [
    {
      id: number;
      name: string;
      substitutive: boolean;
      comment: boolean;
      subject: boolean;
    }
  ];
}
//Endpoint que trás os botões da tela "lançamentos"
export const getReleaseTypes = async (id: number) => {
  const response = await GradeAPI.get<ReleaseTypesResponse>(
    `classes/user_release_types?user_id=${id}`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface getStudentsForReleaseInterface {
  type: string;
  unit: number;
  quarter: number;
  gradegroup: number;
  subject?: number;
}

export interface StudentsForReleaseResponse {
  class_diary: number;
  enrollment: number;
  gradegroup: number;
  name: string;
  quarter: number;
  subject: number;
  substitutive: boolean;
  value: number;
  comment: string;
  letter: string;
  grade: number;
  deactivate_previous: boolean;
  can_display_comment: boolean;
}

export const getStudentsForRelease = async ({
  type,
  unit,
  quarter,
  gradegroup,
  subject,
}: getStudentsForReleaseInterface) => {
  const response = await GradeAPI.get<Array<StudentsForReleaseResponse>>(
    `classes/students_by_evaluation?type=${type}&unit=${unit}&quarter=${quarter}&gradegroup=${gradegroup}${
      subject ? `&subject=${subject}` : ``
    }`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface LancarNotaPayload {
  enrollment: number;
  subject?: number;
  value: number;
  gradegroup: number;
  quarter: number;
  user_id: number;
  user_name: string;
  type: string;
  substitutive?: boolean;
  comment?: string;
}

export const lancarNota = async (payload: LancarNotaPayload) => {
  const response = await GradeAPI.post(`classes/releases/`, payload).catch(
    ErrorHandlerFunction
  );
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface MultiNotasProps {
  enrollment?: number;
  subject?: number;
  value?: number | string;
  score?: number | string;
  gradegroup?: number;
  quarter?: number;
  user_id?: number;
  type?: string;
  user_name?: string;
  summative_type?: number;
  substitutive?: boolean;
  comment?: string;
  absences?: number;
  release_comment?: string;
}

export const lancarMultiNota = async (body: MultiNotasProps[]) => {
  const response = await GradeAPI.post(`classes/releases/`, body).catch(
    ErrorHandlerFunction
  );
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const getUnitClasses = async ({
  gradeUnitId,
  isTeacher,
  userId,
}: any) => {
  const res = await GradeAPI.get<IGradeGroupUnitClasses[]>(
    `classes/gradeyear/${gradeUnitId}?year=${CURRENT_YEAR_ENROLLMENT}&full=s${
      isTeacher ? `&teacher=${userId}` : ""
    }`
  ).catch(ErrorHandlerFunction);

  if (res) {
    const data = {
      data: res.data,
      status: res.status,
    };

    return data;
  }
};

export const getAttendanceSheet = async (gradegroup_id: number) => {
  const res = await GradeAPI.get<IGradeAttendanceSheet[]>(
    `classes/attendance_sheet?gradegroup_id=${gradegroup_id}`
  ).catch(ErrorHandlerFunction);

  if (res) {
    const data = {
      data: res.data,
      status: res.status,
    };

    return data;
  }
};

export const getSubjectAttendance = async (
  enrollment_id: number,
  quarter_id: number
) => {
  const res = await GradeAPI.get<IGradeAttendanceSheet[]>(
    `classes/subject_attendance/${enrollment_id}/${
      quarter_id ? `?quarter_id=${quarter_id}` : ""
    }`
  ).catch(ErrorHandlerFunction);

  if (res) {
    const data = {
      data: res.data,
      status: res.status,
    };

    return data;
  }
};

export const getUnitLevels = async (unit_id: number) => {
  const res = await KanataAPI.get(
    `cx/enturmamento/${unit_id}?year=${CURRENT_YEAR_ENROLLMENT}`
  ).catch(ErrorHandlerFunction);

  if (res) {
    const data = {
      data: res.data,
      status: res.status,
    };

    return data;
  }
};

export interface Specialization {
  id: number;
  nome: string;
}

export interface Componente {
  nome: string;
  especializacoes: Array<Specialization>;
  selecionado: [
    {
      especializacao__id: number;
      especializacao__nome: string;
    }
  ];
  enrollment_id?: number;
}

export interface GetSpecializationsResponse {
  componentes: Array<Componente>;
}

export const getSpecializations = async (enrollment_id: number) => {
  try {
    const response = await GradeAPI.get<GetSpecializationsResponse>(
      `classes/especializacoes/${enrollment_id}`
    );

    if (!response) return;

    return response.data.componentes[0];
  } catch (error) {
    throw new Error("Um erro ocorreu ao realizar a chamada", error);
  }
};

interface GradeGroup {
  grade: string;
  id: number;
  letter: string;
  level: string;
  school_unit: number;
  year: string;
}

interface Student {
  enrollment_id: number;
  id: number;
  kanata_id: number;
}

export interface GetUserKanataResponse {
  gradegroup: GradeGroup;
  student: Student;
}

// https://roteiros.homolog.escolamais.com/api/roteiros/estudante/193031/roteiro_estudos?id_unidade=4642

export const getUserKanata = async (id: number) => {
  try {
    const response = await GradeAPI.get<GetUserKanataResponse>(
      `/classes/kanata_grades/${id}`
    );

    if (!response) return;

    return response.data;

    // return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(`Falha no serviço: getUserKanata`, error);
  }
};

interface SetChosenProjectResponse {
  especializacao: number;
  matricula: number;
}

export const setChosenProject = async (
  enrollment_id: number,
  specialization_id: number
) => {
  try {
    const payload = {
      matricula: enrollment_id,
      especializacao: specialization_id,
    };

    const response = await GradeAPI.post<SetChosenProjectResponse>(
      `/classes/especializacoes/`,
      payload
    );

    if (!response) throw new Error();

    return response;

    // return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(`Falha no serviço: setChosenProject`, error);
  }
};

export const getQuarterPublicationsYears = async () => {
  try {
    const response = await GradeAPI.get<Number[]>(
      `/classes/anos_school_report_publish/`
    );

    if (!response) throw new Error();

    return response;
  } catch (error) {
    console.log(error);
    throw new Error(`Falha no serviço: getQuarterPublicationsYears`, error);
  }
};

export const getQuarterPublicationsByUnit = async ({
  year,
  unit_id,
}: IQuarterPublicationsByUnitRequest) => {
  try {
    const response = await GradeAPI.get<IQuarterPublicationsByUnitResponse[]>(
      `/classes/quarter_publish/?year=${year}&unidade=${unit_id}`
    );

    if (!response) throw new Error();

    return response;
  } catch (error) {
    console.log(error);
    throw new Error(`Falha no serviço: getQuarterPublicationsByUnit`, error);
  }
};

export const undoQuarterPublicationById = async (publication_id: number) => {
  try {
    const payload = {
      actived: false,
    };

    const response = await GradeAPI.patch(
      `/classes/school_report_publish/${publication_id}/`,
      payload
    );

    if (!response) throw new Error();

    return response;
  } catch (error) {
    console.log(error);
    throw new Error(`Falha no serviço: getQuarterPublicationsByUnit`, error);
  }
};

export const publishQuarter = async (publication: IPublishQuarterRequest) => {
  try {
    const response = await GradeAPI.post(
      `/classes/school_report_publish/`,
      publication
    );

    if (!response) throw new Error();

    return response;
  } catch (error) {
    console.log(error);
    throw new Error(`Falha no serviço: getQuarterPublicationsByUnit`, error);
  }
};

export const getStudentsByGradegroup = async (grade_group: number) => {
  try {
    const response = await GradeAPI.get<IStudentsByGradegroupResponse[]>(
      `/classes/students_by_gradegroup/${grade_group}`
    );

    if (!response) throw new Error();

    return response;
  } catch (error) {
    console.log(error);
    throw new Error(`Falha no serviço: getStudentsByGradegroup`, error);
  }
};

interface IGetAllQuartersResponse {
  id: number;
  name: string;
  actived: boolean;
  begin_date: string;
  end_date: string;
  quarter: number;
  current: boolean;
  year: number;
  quarter_display: string;
}

export const getAllQuarters = async () => {
  try {
    const response = await GradeAPI.get<IGetAllQuartersResponse[]>(
      `/classes/quarter?all=true`
    );

    if (!response) throw new Error();

    return response;
  } catch (error) {
    console.log(error);
    throw new Error(`Falha no serviço: getAllQuarters`, error);
  }
};

interface IGetClassCouncilStudentsRequest {
  year: string;
  unidade_id: number;
}

export interface IGetClassCouncilStudentsResponse {
  matricula_id: string;
  ciclo: string;
  estudante_id: string;
  estudante_nome: string;
  ra: string;
  aprovado: boolean;
}

export const getClassCouncilStudents = async ({
  year,
  unidade_id,
}: IGetClassCouncilStudentsRequest) => {
  try {
    const response = await GradeAPI.get<IGetClassCouncilStudentsResponse[]>(
      `/classes/conselho_classe?year=${year}&unidade_id=${unidade_id}`
    );

    if (!response) throw new Error();

    return response;
  } catch (error) {
    console.log(error);
    throw new Error(`Falha no serviço: getClassCouncilStudents`, error);
  }
};

interface ISaveStudentsApprovedStatusesRequest {
  matricula_id: string;
  aprovado: boolean;
}
export const saveStudentsApprovedStatuses = async (
  studentsList: ISaveStudentsApprovedStatusesRequest[]
) => {
  try {
    const response = await GradeAPI.post<
      ISaveStudentsApprovedStatusesRequest[]
    >(`/classes/conselho_classe/`, studentsList);

    if (!response) throw new Error();

    return response;
  } catch (error) {
    console.log(error);
    throw new Error(`Falha no serviço: saveStudentsApprovedStatuses`, error);
  }
};
