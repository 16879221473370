import { useHistory } from "react-router-dom";
import { SellerContainer } from "./style";
import { ButtonCard } from "components/atoms/ButtonCard";
import { useTranslation } from "react-i18next";
import { IsDevelopmentEnvironment } from "helpers/helpers";

const TRANSLATE_PREFIX = "pages.CORE.home.vendor";

export const VendorHome = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <SellerContainer>
      <ButtonCard
        onClick={() => history.push("/responsavel/busca-por-cpf")}
        text={t(`${TRANSLATE_PREFIX}.bookSpot`)}
      />
      <ButtonCard
        onClick={() => history.push("/responsavel/busca")}
        text={t(`${TRANSLATE_PREFIX}.searchGuardianOrStudent`)}
      />
      <ButtonCard
        onClick={() => history.push("/gerenciar/matriculas")}
        text={t(`${TRANSLATE_PREFIX}.manageEnrollmentProcessQuery`)}
      />
      <ButtonCard
        onClick={() => history.push("/buscar-vagas")}
        text={t(`${TRANSLATE_PREFIX}.searchStudentVacancies`)}
      />
      <ButtonCard
        onClick={() => history.push("/lista-espera")}
        text={t(`${TRANSLATE_PREFIX}.waitingList`)}
      />
      <ButtonCard
        onClick={() => history.push("/gerenciar-vagas")}
        text={t(`${TRANSLATE_PREFIX}.manageVacancies`)}
      />
      {IsDevelopmentEnvironment() && (
        <ButtonCard
          onClick={() => history.push("/cadastro-responsavel")}
          text={t(`${TRANSLATE_PREFIX}.signup`)}
        />
      )}
    </SellerContainer>
  );
};
