import styled from "styled-components";
import { BLUE, PINK, BLACK } from "styles/colors";

export const GuardianDataBox = styled.div`
  border-width: 2px;
  border-radius: 28px;
`;

export const GuardianDataBigBox = styled.div`
  border-radius: 13px;
  border-style: solid;
  border-width: 1px;
  border-color: ${BLUE};
  padding: 1em;
  color: ${BLACK};

  .info {
    font-weight: 600;
  }
`;

export const EditGuardianStyled = styled.div`
  div.collapse-box {
    cursor: pointer;
    padding: 1em;
    border: 1px solid black;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    background-color: ${BLUE};
    color: white;
    // text-transform: uppercase;

    &.collapsed {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  div.collapse-content {
    padding: 1em;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;

    p {
      span {
        font-weight: 700;
      }

      &.link {
        cursor: pointer;
        text-decoration: underline;
        color: #4285f4;
      }
    }
  }
`;

export const BoldBiggerPink = styled.p`
  font-weight: bold;
  font-size: 24px;
  color: ${PINK};
`;

export const BoldBigger = styled.p`
  font-weight: bold;
  font-size: 18px;
  color: ${BLACK};
`;

export const BoldBiggerBlue = styled.p`
  font-weight: bold;
  font-size: 32px;
  text-align: center;
  color: ${BLUE};
`;
