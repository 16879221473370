import { Modal } from "@escolamais-ui-v2/react";
import { VerifiedUserOutlined } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

interface IPublishQuarterModalProps {
  open: boolean;
  onCancel: () => void;
  onAction: () => void;
}

export const PublishQuarterModal = ({
  open,
  onCancel,
  onAction,
}: IPublishQuarterModalProps) => {
  const prefix = "pages.AP.publishBoletim.publishQuarterModal";
  const { t } = useTranslation();
  return (
    <Modal
      open={open}
      handleOnClickOutside={onCancel}
      handleOnClickCancel={onCancel}
      handleOnClickConfirm={onAction}
      buttonConfirmText={t(`${prefix}.confirmText`)}
      buttonCancelText={t(`${prefix}.cancelText`)}
      title={t(`${prefix}.title`)}
      icon={<VerifiedUserOutlined width={24} height={24} />}
    >
      <div>
        <p>{t(`${prefix}.description`)}</p>
      </div>
    </Modal>
  );
};
