import { useTranslation } from "react-i18next";

import { Loading, Title } from "components";

import { Table } from "./components/Table";
import { Filters } from "./components/Filters";
import { ErrorStatus } from "./components/ErrorStatus";
import { ClassesAndVacancies } from "./components/ClassesAndVacancies";
import { VacanciesReenrollment } from "./components/VacanciesReenrollment";
import { VacanciesReserved } from "./components/VacanciesReserved";

import { useManageVacancies } from "./useManageVacancies";

import * as S from "./styles";

export const ManageVacancies = () => {
  const {
    units,
    years,
    isLoading,
    hasError,
    setSelectedUnit,
    setSelectedYear,
    selectedUnit,
    selectedYear,
    classesAndVacancies,
    setClassesAndVacancies,
    isOpenDrawerClassesAndVacancies,
    isOpenDrawerVacanciesReenrollment,
    isOpenDrawerVacanciesReserved,
    setIsOpenDrawerClassesAndVacancies,
    setIsOpenDrawerVacanciesReenrollment,
    setIsOpenDrawerVacanciesReserved,
    selectedClassesItemIndex,
    setSelectedClassesItemIndex,
    handleSelectClassesAndVacancies,
    handleSelectVacanciesReenrollment,
    handleSelectVacanciesReserved,
    selectedClassesAndVacancies,
    getManageVacanciesInfo,
  } = useManageVacancies();

  const { t } = useTranslation();
  const i18n_prefix = "pages.EDF.manageVacancies";

  const hasData = classesAndVacancies.length > 0;

  if (isLoading) {
    return <Loading />;
  }

  if (hasError) {
    return <ErrorStatus />;
  }

  return (
    <S.Container>
      <Title fontSize="36px">{t(`${i18n_prefix}.title`)}</Title>

      <Filters
        units={units}
        years={years}
        onChangeUnit={setSelectedUnit}
        onChangeYear={setSelectedYear}
        selectedUnit={selectedUnit}
        selectedYear={selectedYear}
      />

      {hasData && (
        <>
          <strong>{t(`${i18n_prefix}.results`)}</strong>

          <Table
            data={classesAndVacancies}
            handleSelectClassesAndVacancies={handleSelectClassesAndVacancies}
            handleSelectVacanciesReenrollment={
              handleSelectVacanciesReenrollment
            }
            handleSelectVacanciesReserved={handleSelectVacanciesReserved}
          />

          <ClassesAndVacancies
            visible={isOpenDrawerClassesAndVacancies}
            onClose={setIsOpenDrawerClassesAndVacancies}
            classesData={classesAndVacancies}
            setClassesData={setClassesAndVacancies}
            selectedClassesIndex={selectedClassesItemIndex}
            setSelectedClassesIndex={setSelectedClassesItemIndex}
            selectedClassesData={selectedClassesAndVacancies}
            syncDataAfterSubmit={getManageVacanciesInfo}
          />

          <VacanciesReenrollment
            visible={isOpenDrawerVacanciesReenrollment}
            onClose={setIsOpenDrawerVacanciesReenrollment}
            classesData={classesAndVacancies}
            setClassesData={setClassesAndVacancies}
            selectedClassesIndex={selectedClassesItemIndex}
            setSelectedClassesIndex={setSelectedClassesItemIndex}
            selectedClassesData={selectedClassesAndVacancies}
            syncDataAfterSubmit={getManageVacanciesInfo}
          />

          <VacanciesReserved
            visible={isOpenDrawerVacanciesReserved}
            onClose={setIsOpenDrawerVacanciesReserved}
            classesData={classesAndVacancies}
            setClassesData={setClassesAndVacancies}
            selectedClassesIndex={selectedClassesItemIndex}
            setSelectedClassesIndex={setSelectedClassesItemIndex}
            selectedClassesData={selectedClassesAndVacancies}
          />
        </>
      )}
    </S.Container>
  );
};
