// Style
import * as S from "./style";
// Libs
import { Button } from "@escolamais-ui-v2/react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
// Components
import { Plan } from "./components/Plan";
import { PlanPrices } from "./components/PlanPrices";
import { TransitionPlan } from "./components/TransitionPlan";
import { Icons, LoadingPage } from "components";
// Icons
import plusIcon from "static/img/plusPurple.svg";
// Helpers
import { HPlan } from "./helper";
// Interfaces
import { IEditPlan } from "./interface";

export const RegisterEditPaymentPlan = () => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.paymentPlan";

  const location = useLocation<IEditPlan>();

  const {
    loading,
    error,
    units,
    paymentPlanMonths,
    years,
    plan,
    edit,
    onSubmit,
    register,
    handleSubmit,
    trigger,
    errors,
    isSubmitting,
    watch,
    fields,
    addTransitionPlan,
  } = HPlan({ id: location?.state?.id });

  if (loading) {
    return <LoadingPage text={t(`${TRANSLATE}.loadingData`)} />;
  }

  if (error) {
    return (
      <S.ErrorContainer>
        <div>{t(`${TRANSLATE}.errorMessage`)}</div>
      </S.ErrorContainer>
    );
  }

  return (
    <S.PlaymentPlanContainer>
      <h2>
        {t(`${TRANSLATE}.title`, {
          name: location?.state?.id ? "Editar" : "Cadastrar",
        })}
      </h2>
      {units && paymentPlanMonths && years && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Plan
            register={register}
            errors={errors}
            watch={watch}
            units={units}
            paymentPlanMonths={paymentPlanMonths}
            years={years}
            plan={plan}
            edit={edit}
          />

          <PlanPrices
            register={register}
            errors={errors}
            watch={watch}
            edit={edit}
          />

          {fields.map((field, index) => {
            return (
              <TransitionPlan
                register={register}
                errors={errors}
                watch={watch}
                key={field.id}
                index={index}
                plan={plan}
              />
            );
          })}

          <Button
            className="transitionButton"
            type="button"
            appearance="text"
            buttonText={t(`${TRANSLATE}.addTransitionPlan`)}
            icon={
              <Icons
                icon={plusIcon}
                title={t(`${TRANSLATE}.addTransitionPlan`)}
              />
            }
            onClick={() => {
              addTransitionPlan();
            }}
            data-testid="addTransitionPlan"
          />

          <div className="buttons">
            <Button
              type="button"
              appearance="text"
              buttonText={t(`${TRANSLATE}.buttonBack`)}
              onClick={() => {}}
              size="large"
            />
            <Button
              type="submit"
              appearance="primary"
              buttonText={t(`${TRANSLATE}.buttonSave`)}
              size="large"
              disabled={isSubmitting}
            />
          </div>
        </form>
      )}
    </S.PlaymentPlanContainer>
  );
};
