import { useTranslation } from "react-i18next";
import { ISectionTitle } from "./interface";
import * as S from "./styles";

export const SectionTitle = ({ title, sectionState }: ISectionTitle) => {
  const { t } = useTranslation();

  return (
    <S.Title sectionState={sectionState}>
      {t("components.molecules.sectionTitle.title", {
        title,
      })}
    </S.Title>
  );
};
