import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import { Button, LoadingBox } from "components";
import { formatIpteString, formatMoney } from "helpers/helpers";
import HTTP_STATUS from "http-status-codes";
import { GetCXUserJson, StudentInterface } from "interfaces/constants";
import { ReactElement, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getBoletoIugo, getPaymentCheck } from "services/payment.service";
import { BoletoIugoResponse } from "interfaces/paymentInterfaces";
import { BLACK, BLUE, WHITE } from "styles/colors";
import { LinhaDigitavelBox } from "../../styles";

type StateType = {
  studentId: number;
  guardianId: number;
  enrollmentId: number;
  student: StudentInterface;
  user: GetCXUserJson;
  valorMatricula: number;
  multipay?: boolean;
};

export const Boleto = (): ReactElement => {
  const history = useHistory();
  const location = useLocation<StateType>();
  const [user] = useState(location.state.user);
  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(false);
  const [IugoBoleto, setIugoBoleto] = useState<BoletoIugoResponse>();

  useEffect(() => {
    setLoadingData(true);

    const fetchBoletoIugoData = async () => {
      const res = await getPaymentCheck({
        enrollment_id: location.state.enrollmentId,
        payment_type: 1,
      });

      if (res?.status === HTTP_STATUS.OK) {
        if (res.data.can_proceed) {
          const IugoBoletoData = await getBoletoIugo({
            value: Math.round(location.state.valorMatricula * 100),
            guardian_id: location.state.guardianId,
            student_id: location.state.studentId,
          });
          if (IugoBoletoData) {
            if (IugoBoletoData.status === HTTP_STATUS.OK) {
              setIugoBoleto(IugoBoletoData.data);
              setLoadingData(false);
            } else {
              setError(true);
              setLoadingData(false);
            }
          }
        } else {
          setIugoBoleto({
            pdf: res.data.invoice_pdf,
            bar_code: res.data.bar_code,
          });
          setLoadingData(false);
        }
      }
    };

    try {
      fetchBoletoIugoData();
    } catch (err) {
      setError(true);
      setLoadingData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      {error ? (
        <>
          <div className="row">
            <div className="col-12 text-center mt-5">
              <span style={{ color: BLACK, fontSize: "18px" }}>
                Um erro inesperado aconteceu durante a geração do boleto, tente
                novamente.
              </span>
            </div>
            <div className="col-12">
              <Button
                onClick={() => {
                  history.goBack();
                }}
              >
                Voltar
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          {loadingData ? (
            <>
              <div className="row justify-content-center">
                <div className="col-12 text-center">
                  <LoadingBox height={50} />
                </div>
                <div className="col-8 text-center mt-3">
                  <Typography variant="h5">
                    Carregando os dados do boleto!
                  </Typography>
                </div>
              </div>
            </>
          ) : (
            <>
              {/* {boletoData && boletoDataInfo && !error && ( */}
              {IugoBoleto && !error && (
                <>
                  <div className="row text-center mt-5">
                    <div className="col-12">
                      <span style={{ color: BLACK, fontSize: "18px" }}>
                        Por favor, pague seu boleto até a data de vencimento,{" "}
                        para garantir sua reserva de vaga
                      </span>
                    </div>
                    <div className="col-12 mt-3 text-center">
                      <span
                        style={{ color: BLACK, fontSize: "18px" }}
                      >{`Valor do Boleto: `}</span>
                      <span
                        style={{
                          color: BLACK,
                          fontSize: "18px",
                          fontWeight: "bold",
                        }}
                      >
                        {formatMoney(location.state.valorMatricula)}
                      </span>
                    </div>
                  </div>
                  <div className="row justify-content-center mt-3">
                    <div className="col-lg-6 col-12">
                      <LinhaDigitavelBox
                        className="text-center"
                        onClick={() =>
                          navigator.clipboard.writeText(
                            `${IugoBoleto.bar_code}`
                          )
                        }
                      >
                        <div className="row justify-content-between align-items-center">
                          <div className="col-10 text-center">{`${formatIpteString(
                            IugoBoleto.bar_code
                          )}`}</div>
                          <div className="col-2">
                            <IconButton
                              aria-label="copy"
                              onClick={() =>
                                navigator.clipboard.writeText(
                                  `${IugoBoleto.bar_code}`
                                )
                              }
                            >
                              <FilterNoneIcon />
                            </IconButton>
                          </div>
                        </div>
                      </LinhaDigitavelBox>
                    </div>
                  </div>
                  <div className="row mt-3 justify-content-center">
                    <span style={{ color: BLACK, fontSize: "18px" }}>
                      Após o pagamento, continue a matrícula pelo{" "}
                      <strong>aplicativo</strong>.
                    </span>
                  </div>
                  <div className="row mt-3 justify-content-center">
                    <div className="col-lg-6 col-12">
                      <Button
                        style={{ backgroundColor: BLUE }}
                        onClick={() => {}}
                      >
                        <a
                          style={{ color: WHITE }}
                          href={IugoBoleto.pdf}
                          download
                        >
                          Baixar boleto
                        </a>
                      </Button>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-lg-6 col-12">
                      <Button
                        onClick={() => {
                          if (location.state.multipay) {
                            history.goBack();
                          } else {
                            history.push({
                              pathname: `/responsavel/meus-alunos`,
                              state: { user },
                            });
                          }
                        }}
                      >
                        Voltar
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Boleto;
