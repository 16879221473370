import styled from "styled-components";

export const ChooseProjectContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  gap: 1rem;
  padding: 2rem;

  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 1440px) {
    justify-content: center;
  }
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 36px;
  line-height: 54px;
  color: #6d28d9;
  margin-bottom: 0;

  @media (max-width: 535px) {
    text-align: center;
  }
`;

export const ProjectInfo = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #0d0d0d;
`;
export const ProjectSubInfo = styled(ProjectInfo)`
  color: #999;
`;

export const ProjectInfoContainer = styled.div``;
