// React
import { useCallback, useEffect, useState } from "react";
// Styles
import { Step1Styled } from "./style";
// Libs
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
// Constants
import { EventType, StudentInterface } from "interfaces/constants";
// Helpers
import { formatMoney, nextMonth } from "helpers/helpers";
import { FoodOptionPackage, Text } from "components";
import { SCHOLARSHIP_TYPE } from "helpers/constants";
// Interfaces
import { FoodItemInterface, FoodPackage } from "interfaces/mealInterfaces";
// Components
import { Button } from "components/atoms";
import { toastWarning } from "components/Toast";

type Step1Type = {
  foodItems: Array<FoodItemInterface>;
  smart?: boolean;
  student: StudentInterface;
  foodPackage: FoodPackage;
  packageValue: number;
  setActiveStep: (activeStep: number) => void;
  setPackageItems: (packageItems: Array<FoodItemInterface>) => void;
  setPackageValue: (packageValue: number) => void;
};

export const Step1 = ({
  foodItems,
  smart,
  student,
  foodPackage,
  packageValue,
  setActiveStep,
  setPackageItems,
  setPackageValue,
}: Step1Type) => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.foodOption.components.step1";
  const history = useHistory();

  const [itemsSelected, setItemsSelected] = useState<Array<number>>([]);

  const searchIndexAndRemove = (value: number) => {
    if (itemsSelected) {
      const index = itemsSelected.indexOf(value);
      return itemsSelected.splice(index, 1);
    }
  };

  const onSubmit = () => {
    const food_items: Array<FoodItemInterface> = [];
    if (!smart && itemsSelected) {
      foodItems.forEach((foodItem) => {
        itemsSelected.forEach((itemSelected) => {
          if (foodItem.id === itemSelected) {
            food_items.push(foodItem);
          }
        });
      });
    }

    if (!smart && food_items.length > 0) {
      setPackageItems(food_items);
      setActiveStep(1);
    } else if (smart) {
      setPackageItems(foodItems);
      setActiveStep(1);
    } else {
      toastWarning(t(`${TRANSLATE}.toastWarning`));
    }
  };

  return (
    <Step1Styled>
      {foodPackage && foodItems && (
        <div>
          {smart ? (
            <Text fontSize="14px" fontWeight="400" lineHeight="21px">
              {t(`${TRANSLATE}.scholarshipText`)}
            </Text>
          ) : (
            <div>
              <Text fontSize="14px" fontWeight="400" lineHeight="21px">
                {t(`${TRANSLATE}.noScholarshipText`)}
                <span className="monthName">{foodPackage.month_display}</span>
              </Text>
              <Text fontSize="12px" fontWeight="400" lineHeight="18px">
                {t(`${TRANSLATE}.textForSmart`, {
                  workingDays: foodPackage.working_days,
                })}
              </Text>
            </div>
          )}
          {foodItems.map((foodItem) => {
            return (
              <div className="formContainer" key={foodItem.id}>
                <FoodOptionPackage
                  name={foodItem.name}
                  value={parseInt(foodItem.value_package)}
                  valueMonth={
                    parseInt(foodItem.value_package) * foodPackage.working_days
                  }
                  onChange={(e: EventType) => {
                    if (e.currentTarget.checked) {
                      setPackageValue(
                        packageValue + parseInt(foodItem.value_package)
                      );
                      setItemsSelected([...itemsSelected, foodItem.id]);
                    } else {
                      setPackageValue(
                        packageValue - parseInt(foodItem.value_package)
                      );
                      searchIndexAndRemove(foodItem.id);
                    }
                  }}
                />
              </div>
            );
          })}
          <div className="valueTotal">
            <Text fontSize="18px" fontWeight="700" lineHeight="27px">
              {t(`${TRANSLATE}.totalValue`)}{" "}
            </Text>
            <Text fontSize="18px" fontWeight="700" lineHeight="27px">
              {smart
                ? "R$ 0,00"
                : formatMoney(packageValue * foodPackage.working_days)}
            </Text>
          </div>
          <div className="button">
            <Button
              type="button"
              outline
              width="45%"
              margin
              onClick={() => history.goBack()}
            >
              {t(`${TRANSLATE}.buttonBack`)}
            </Button>
            <Button margin width="45%" type="button" onClick={() => onSubmit()}>
              {t(`${TRANSLATE}.buttonAdvance`)}
            </Button>
          </div>
        </div>
      )}
    </Step1Styled>
  );
};
