import { mdiAccountOutline } from "@mdi/js";
import Icon from "@mdi/react";
import * as Style from "./style";
import { useTranslation } from "react-i18next";

export interface ProfilePictureInterFace {
  canChangePhoto?: boolean;
  hasProfilePicture?: string;
}

export const ProfilePicture = ({
  canChangePhoto,
  hasProfilePicture,
}: ProfilePictureInterFace) => {
  const { t } = useTranslation();
  return (
    <div>
      <Style.ProfilePictureStyled>
        <Icon size={1} path={mdiAccountOutline} />
      </Style.ProfilePictureStyled>
      {canChangePhoto && (
        <Style.ChangePhoto className="mt-1">
          {t("components.atoms.profilePicture.changePhoto")}
        </Style.ChangePhoto>
      )}
    </div>
  );
};
