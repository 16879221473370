import styled from "styled-components";

export const GradesManagerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  gap: 1rem;
  padding: 2rem;

  @media (max-width: 1440px) {
    align-items: center;
    justify-content: center;
  }
`;
