import { NewTbodyTheadStyle } from "./style";

interface INewTheadTBody {
  children: React.ReactNode;
  thead?: boolean;
}

export const NewTheadTbody = ({ children, thead }: INewTheadTBody) => {
  return <NewTbodyTheadStyle thead={thead}>{children}</NewTbodyTheadStyle>;
};
