import { KanataAPI } from "./api";
import { ErrorHandlerFunction } from "./service-helpers";
import { IGuardEditFormValues } from "pages/EDF/GuardianDataEditing/interface";
import { NewGuardianResponse } from "interfaces/constants";
import { ICivilStatus, IKinship } from "interfaces/guardian";

interface IGetGuardianData {
  guardian_id: number;
}

export const getGuardianData = async ({ guardian_id }: IGetGuardianData) => {
  const response = await KanataAPI.get(`cx/new/guardian/${guardian_id}/`).catch(
    ErrorHandlerFunction
  );
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface IUpdateGuardianData extends IGetGuardianData {
  formValues: IGuardEditFormValues;
}

export const updateGuardianData = async ({
  guardian_id,
  formValues,
}: IUpdateGuardianData) => {
  const response = await KanataAPI.put(
    `cx/new/guardian/${guardian_id}/`,
    formValues
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface RegisterNewGuardianType {
  body: {
    bairro: string;
    cep: string;
    cidade: string;
    complemento: string;
    data_nascimento: string;
    endereco: string;
    estado: string;
    nome: string;
    numero: string;
    celular: string;
  };
}

export const registerNewGuardianUser = async ({
  body,
}: RegisterNewGuardianType) => {
  const response = await KanataAPI.post<NewGuardianResponse>(
    `cx/new/guardian/`,
    body
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface IDocumentsGuardianSiognup {
  nome: string;
  arquivo: string;
}
interface INewGuardianSignup {
  body: {
    email: string;
    nome: string;
    telefone?: string;
    celular: string;
    rg: string;
    data_nascimento: string;
    cpf: string;
    codigo_indicador?: string;
    arquivos_documento_identificacao?: IDocumentsGuardianSiognup[];
    arquivos_documento_endereco?: IDocumentsGuardianSiognup[];
    cep: string;
    endereco: string;
    numero: string;
    bairro: string;
    complemento?: string;
    estado: string;
    cidade: string;
  };
}

interface IGrauParentesco {
  id: number;
  grau_parentesco: string;
  estudante_id: number;
}
export interface INewGuardianSignupResponse {
  user_id: number;
  id: number;
  email: string;
  nome: string;
  telefone: string;
  celular: string;
  rg: string;
  cpf: string;
  data_nascimento: string;
  nacionalidade: string | null;
  estado_civil: string | null;
  profissao: string | null;
  endereco: string;
  numero: string;
  complemento: string;
  bairro: string;
  cep: string;
  cidade: string;
  estado: string;
  graus_parentesco: IGrauParentesco[];
  arquivos_documento_identificacao: IDocumentsGuardianSiognup[];
}

export const newGuardianSignup = async ({ body }: INewGuardianSignup) => {
  const response = await KanataAPI.post<INewGuardianSignupResponse>(
    "cx/new/guardian/",
    body
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const getKinship = async () => {
  const response = await KanataAPI.get<IKinship>("cx/graus_parentesco/").catch(
    ErrorHandlerFunction
  );
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const getCivilStatus = async () => {
  const response = await KanataAPI.get<ICivilStatus>("cx/estado_civil/").catch(
    ErrorHandlerFunction
  );
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface IUpdateGuardian<T extends unknown> {
  guardianId: number;
  body: T;
}

export const updateGuardian = async <T extends unknown>({
  guardianId,
  body,
}: IUpdateGuardian<T>) => {
  const response = await KanataAPI.patch(
    `cx/new/guardian/${guardianId}/`,
    body
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};
