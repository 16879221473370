import styled from "styled-components";

export const GuardianExitCardStyled = styled.div`
  margin-bottom: ${({ theme }) => theme.space[2]};
  margin-right: ${({ theme }) => theme.space[4]};
  padding: ${({ theme }) => theme.space[2]};
  width: 309px;
  height: 106px;
  border: 2px solid ${({ theme }) => theme.colors.primaryDefault};

  display: flex;
  flex-direction: column;

  div.infos {
    display: flex;
    justify-content: space-between;
    p.escortName {
      display: flex;
      flex-wrap: wrap;

      height: ${({ theme }) => theme.space[12]};
      margin: 0;
      font-size: ${({ theme }) => theme.space[4]};
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      line-height: ${({ theme }) => theme.lineHeights.taller};
      /* color: puxar da lib */

      p {
        margin: 0;
        margin-top: 2px;
        margin-left: 5px;
      }
    }

    label {
      border: 2px solid ${({ theme }) => theme.colors.primaryDefault};
      border-radius: ${({ theme }) => theme.space[1]};
      padding: 2px 6px;
      height: 23px;
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      font-size: 10px;
      color: ${({ theme }) => theme.colors.primaryDefault};
    }
  }

  div.infosPtTwo {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    div {
      display: flex;
      p {
        margin: 0;
        margin-left: 3px;
        font-size: 12px; // nao tem na lib
        font-weight: ${({ theme }) => theme.fontWeights.regular};

        &.infoName {
          font-weight: ${({ theme }) => theme.fontWeights.bold};
          margin-left: 0;
        }
      }
    }
  }

  @media (max-width: 360px) {
    margin-right: 0;
    div.infosPtTwo {
      margin-top: ${({ theme }) => theme.space[2]};
    }
  }

  @media (max-width: 320px) {
    div.infos {
      p.escortName {
        font-size: ${({ theme }) => theme.fontSizes.web.sm};
      }
    }

    div.infosPtTwo {
      div {
        p {
          font-size: 10px; // nao tem na lib
        }
      }
    }
  }
`;
