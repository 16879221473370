import * as S from "./style";

export const Loading = () => {
  return (
    <S.Container>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </S.Container>
  );
};
