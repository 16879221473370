import styled from "styled-components";
import { BLUE } from "styles/colors";

export const GerenciarProcessoMatriculasStyled = styled.div`
  div.title {
    font-size: 24px;
    font-weight: bold;
    color: ${BLUE};
  }

  table {
    border-radius: 15px;
    border: 2px solid #000;
    thead {
      tr {
        background-color: ${BLUE};
        color: #fff;
        justify-content: center;
        align-items: center;
        text-align: center;
        border: 1px solid #000;
        border-radius: 15px;
      }
      th {
        border-right: 1px solid #000;
        border-bottom: none;
        padding: 1em;
      }
    }
    tbody {
      tr {
        border: 1px solid #000;

        td {
          text-align: center;
          padding: 0.5em;
          border-right: 1px solid #000;
        }
      }
    }
  }
`;
