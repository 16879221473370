import { Link, useHistory } from "react-router-dom";

export const NotFoundPage = () => {
  const history = useHistory();
  return (
    <div className="text-center">
      <div className="error mx-auto" data-text="404">
        404
      </div>
      <p className="lead text-gray-800 mb-5">Página não encontrada</p>
      <Link to="#" onClick={() => history.goBack()}>
        ← Voltar
      </Link>
    </div>
  );
};

export default NotFoundPage;
