import { IStudentsByGradegroupResponse } from "interfaces/gradeGroupInterfaces";
import { useTranslation } from "react-i18next";
import { TableContainer } from "./styles";

interface IStudentsTableProps {
  students: IStudentsByGradegroupResponse[];
  onSelectStudent: (student: IStudentsByGradegroupResponse) => void;
}
export const StudentsTable = ({
  students,
  onSelectStudent,
}: IStudentsTableProps) => {
  const { t } = useTranslation();
  const prefix = "pages.AP.studyItineraryInternal";

  return (
    <TableContainer>
      <table>
        <thead>
          <tr>
            <th>
              <p>{t(`${prefix}.students`)}</p>
            </th>
            <th>{t(`${prefix}.ra`)}</th>
          </tr>
        </thead>
        <tbody>
          {students?.map((student) => (
            <tr
              key={`${student.id}-${student.student.ra}`}
              onClick={() => onSelectStudent(student)}
            >
              <td>{student.student.name}</td>
              <td>{student.student.ra}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </TableContainer>
  );
};
