// React
import { useCallback } from "react";
// Libs
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

const SearchStudentOrGuardianSchema = z.object({
  nome: z.string().nonempty("Digite o nome do aluno/responsável."),
  unidade: z.string().nonempty("Selecione a unidade."),

  ciclo: z.string().optional(),
  serie: z.string().optional(),
  turma: z.string().optional(),
});

export type SearchStudentOrGuardianFormData = z.infer<
  typeof SearchStudentOrGuardianSchema
>;

export const SearchStudentOrGuardianHelper = () => {
  const onSubmit = useCallback(
    async (values: SearchStudentOrGuardianFormData) => {},
    []
  );

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<SearchStudentOrGuardianFormData>({
    resolver: zodResolver(SearchStudentOrGuardianSchema),
  });

  return { onSubmit, register, handleSubmit, watch, errors };
};
