import {
  BusinessDayInterface,
  GetUnitsGradesResponse,
  PutUnitGradeSectionQuantityResponse,
  UnitGradeSectionQuantityResponse,
} from "interfaces/unit";
import { GradeAPI, KanataAPI } from "./api";
import { ErrorHandlerFunction, Headers } from "./service-helpers";

export const getUnitsDetails = async () => {
  const response = await GradeAPI.get(`classes/unit_grade_section/`).catch(
    ErrorHandlerFunction
  );
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const getUnitsGradesSchoolPlaces = async (
  year?: string,
  periodo_vendas?: boolean
) => {
  const response = await KanataAPI.get<Array<GetUnitsGradesResponse>>(
    `cx/units_grades${year ? `?year=${year}` : ""}${
      periodo_vendas ? `&periodo_vendas=${periodo_vendas}` : ""
    }`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export interface GetUnitsGradesSectionsResponse {
  unit: {
    id: number;
    name: string;
    educational_level: [
      {
        id: number;
        name: string;
        grades: [
          {
            grade: number;
            sections: [
              {
                section: string;
              }
            ];
          }
        ];
      }
    ];
  };
}

export const getUnitsGradesSectionsSchoolPlaces = async () => {
  const response = await KanataAPI.get<Array<GetUnitsGradesSectionsResponse>>(
    `school/units_grades_sections`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export interface UnitInterface {
  id: number;
  name: string;
  zip_code: string;
  address: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  actived: boolean;
  created_at: string;
  updated_at: string;
  totvs_code: string;
}

export const getUnits = async (todas_unidades?: boolean) => {
  const response = await KanataAPI.get<Array<UnitInterface>>("cx/unit", {
    params: { todas_unidades },
  }).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface IWorkingDays {
  unit: number;
  year?: number;
}

export const getWorkingDays = async ({ unit, year }: IWorkingDays) => {
  const response = await KanataAPI.get<Array<BusinessDayInterface>>(
    `school/school_consumption_days?unit=${unit}${year ? `&year=${year}` : ""}`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export interface IWorkingDaysRegister {
  days: number;
  month: number;
  year: number;
  unit: number;
}

export const workingDaysRegister = async ({
  days,
  month,
  year,
  unit,
}: IWorkingDaysRegister) => {
  const response = await KanataAPI.post<BusinessDayInterface>(
    `school/school_consumption_days`,
    {
      days,
      month,
      year,
      unit,
    }
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const getUnitGradeSectionQuantity = async ({
  grade,
  educational_level,
  unit,
  year,
  openVacancies,
}: {
  grade?: string;
  educational_level?: string;
  unit?: string;
  year?: string;
  openVacancies?: boolean;
}) => {
  const response = await KanataAPI.get<Array<UnitGradeSectionQuantityResponse>>(
    `/cx/unit_grade_section_quantity${
      openVacancies
        ? `?grade=${grade}&educational_level=${educational_level}&unit=${unit}&year=${year}`
        : ""
    }`,
    Headers({
      timeout: 1000000,
    })
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const putUnitGradeSectionQuantity = async ({
  id,
  quantity,
  user_id,
  user_name,
}: {
  id: string;
  quantity: string;
  user_id: number;
  user_name: string;
}) => {
  const response = await KanataAPI.put<PutUnitGradeSectionQuantityResponse>(
    `/cx/unit_grade_section_quantity/${id}`,
    { quantity, user_id, user_name },
    Headers({
      timeout: 1000000,
    })
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const newUnitGradeSectionQuantity = async ({
  educational_level,
  grade,
  quantity,
  section,
  unit,
  user_id,
  user_name,
  year,
}: {
  educational_level: number;
  grade: number;
  quantity: string;
  section: string;
  unit: string;
  user_id: number;
  user_name: string;
  year: number;
}) => {
  const response = await KanataAPI.post<PutUnitGradeSectionQuantityResponse>(
    `/cx/unit_grade_section_quantity`,
    {
      educational_level,
      grade,
      quantity,
      section,
      unit,
      user_id,
      user_name,
      year,
    },
    Headers({
      timeout: 1000000,
    })
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface IUnitManageVacanciesInfo {
  unit: string;
  year: string;
}

export const unitManageVacanciesInfo = async ({
  unit,
  year,
}: IUnitManageVacanciesInfo) => {
  const response = await KanataAPI.get(
    `/cx/gerenciamento_vagas/?ano=${year}&unidade=${unit}&page_size=20`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface ISaveUnitManageVacanciesInfo {
  unit?: number;
  values: {
    qtd_turmas?: number | string;
    total_vagas?: number | string;
    qtd_vagas_rematriculas_liberadas_para_venda?: number | string;
  };
}

export const saveUnitManageVacanciesInfo = async ({
  unit,
  values,
}: ISaveUnitManageVacanciesInfo) => {
  const response = await KanataAPI.patch(
    `/cx/gerenciamento_vagas/${unit}/`,
    values
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};
