/* eslint-disable array-callback-return */
import { LinearProgress } from "@material-ui/core";
// import { IconButton} from "@material-ui/core";
// import { Schedule } from "@material-ui/icons";
// import { toastSuccess } from "components/Toast";
// import { sendPendingDocumentsNotifications } from "services/documents.service";
import { Button, Checkbox } from "components";
import SelectInput from "components/Select";
import { toastError } from "components/Toast";
import HTTP_STATUS from "http-status-codes";
import { useContext, useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import {
  getdocumentsList,
  GetDocumentsReponse,
} from "services/documents.service";
import { getUnits, UnitInterface } from "services/units.service";
import loadingGif from "static/img/loading.gif";
import { DocumentStatusDisplay } from "./components/DocumentStatusDisplay";
import { Circle, TableStyled } from "./style";

export const SearchDocuments = () => {
  const [loading, setLoading] = useState(false);
  const [quantidadeItems, setQuantidadeItems] = useState<number>();
  const [paginaAnterior, setPaginaAnterior] = useState<string>();
  const [proximaPagina, setProximaPagina] = useState<string>();
  const [pagina, setPagina] = useState(0);
  const [listaDocumentos, setListaDocumentos] = useState<GetDocumentsReponse>();
  const [unitList, setUnitList] = useState<Array<UnitInterface>>();

  useEffect(() => {
    setLoading(true);
    const getUnitList = async () => {
      const response = await getUnits();
      if (response && response.data) {
        setUnitList(response.data);
        setLoading(false);
      }
    };
    getUnitList();
  }, []);

  const onSubmit = async (values: any) => {
    const {
      doc_aprovado,
      doc_em_analise,
      doc_aguardando,
      doc_reprovado,
      doc_concluido,
      tipo_matricula,
      unit,
    } = values;

    const DocumentStatusArray = [];
    const EnrollmentStatusArray = [];

    if (doc_aprovado) {
      DocumentStatusArray.push(1);
    }

    if (doc_em_analise) {
      DocumentStatusArray.push(3);
    }

    if (doc_aguardando) {
      EnrollmentStatusArray.push(2);
    }

    if (doc_reprovado) {
      DocumentStatusArray.push(2);
    }

    if (doc_concluido) {
      EnrollmentStatusArray.push(4);
    }

    //Caso nenhum filtro seja selecionado, não prossegue.
    if (!EnrollmentStatusArray.length && !DocumentStatusArray.length) {
      toastError("É necessário selecionar pelo menos um filtro de status");
      return;
    }

    setLoading(true);
    const response = await getdocumentsList(
      EnrollmentStatusArray,
      DocumentStatusArray,
      tipo_matricula === "1" ? false : true,
      unit !== "0" ? unit : false
    );
    if (response && response.status === HTTP_STATUS.OK) {
      setListaDocumentos(response.data);
      setPaginaAnterior(response.data.previous);
      setProximaPagina(response.data.next);
      setQuantidadeItems(response.data.count);
      setPagina(0);
      setLoading(false);
    }
  };

  const Paginar = async (direction: string) => {
    setLoading(true);
    const urlDirection =
      direction === "foward" ? proximaPagina : paginaAnterior;
    //Manda dois arrays vazios referentes ao
    //EnrollmentStatusArray e DocumentStatusArray.
    //o false e undefined é rematricula e unidade respectivamente.
    //Como se trata de paginação, ele só roda a url que vem de retorno.
    const response = await getdocumentsList(
      [],
      [],
      false,
      undefined,
      urlDirection
    );
    if (response && response.status === HTTP_STATUS.OK) {
      setListaDocumentos(response.data);
      setPaginaAnterior(response.data.previous);
      setProximaPagina(response.data.next);
      setQuantidadeItems(response.data.count);
      setLoading(false);
    } else {
      setLoading(false);
    }

    //Controla qual a página
    if (direction === "foward") {
      setPagina(pagina + 1);
    } else {
      setPagina(pagina - 1);
    }
  };

  return (
    <div className="container">
      {/* Filtros da Busca */}
      {unitList && (
        <>
          <h3>Pesquisa de Documentação</h3>
          <Form
            onSubmit={onSubmit}
            initialValues={{
              doc_aprovado: false,
              doc_em_analise: false,
              doc_aguardando: false,
              doc_reprovado: false,
              doc_concluido: false,
            }}
          >
            {({ form, handleSubmit, submitting, values }) => (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-6">
                    <div className="d-flex flex-column">
                      <div className="d-flex align-items-center">
                        <Field
                          name="doc_aprovado"
                          component={Checkbox}
                          checked={values.doc_aprovado}
                        />
                        <Circle fillColor="#009688" className="mr-2" />
                        Documento aprovado
                      </div>
                      <div className="d-flex align-items-center">
                        <Field
                          name="doc_em_analise"
                          component={Checkbox}
                          checked={values.doc_em_analise}
                        />
                        <Circle fillColor="#ffd000" className="mr-2" />
                        Documento aguardando análise
                      </div>
                      <div className="d-flex align-items-center">
                        <Field
                          name="doc_aguardando"
                          component={Checkbox}
                          checked={values.doc_aguardando}
                        />
                        <Circle fillColor="#aaa" className="mr-2" />
                        Aguardando envio Documentação
                      </div>
                      <div className="d-flex align-items-center">
                        <Field
                          name="doc_reprovado"
                          component={Checkbox}
                          checked={values.doc_reprovado}
                        />
                        <Circle fillColor="#f1233f" className="mr-2" />
                        Documento reprovado
                      </div>
                      <div className="d-flex align-items-center">
                        <Field
                          name="doc_concluido"
                          component={Checkbox}
                          checked={values.doc_concluido}
                        />
                        <Circle fillColor="#00c3ff" className="mr-2" />
                        Documentação Concluída
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="d-flex flex-column">
                      <div className="d-flex align-items-center">
                        <strong className="mt-5 mr-2">Tipo:</strong>
                        <Field
                          name="tipo_matricula"
                          component={SelectInput}
                          defaultValue="1"
                        >
                          <option value={1}> Nova Matrícula </option>
                          <option value={2}> Rematrícula </option>
                        </Field>
                      </div>
                      <div className="d-flex align-items-center">
                        <strong className="mt-5 mr-2">Unidade:</strong>
                        <Field name="unit" component={SelectInput}>
                          <option value={0}>Selecione uma unidade</option>
                          {unitList.map((unit) => {
                            if (unit.actived) {
                              return (
                                <option value={unit.id} key={unit.id}>
                                  {unit.name}
                                </option>
                              );
                            }
                          })}
                        </Field>
                      </div>
                    </div>
                  </div>
                </div>
                <Button
                  type="submit"
                  style={{
                    backgroundColor: submitting ? "#dadada" : "#00aeefff",
                  }}
                >
                  {(submitting || loading) && (
                    <img height="25" src={loadingGif} alt="loading" />
                  )}
                  {(!submitting || !loading) && "Buscar"}
                </Button>
              </form>
            )}
          </Form>
        </>
      )}
      {/* */}
      {/* Tela de Loading */}
      {loading && <LinearProgress className="mt-3 mb-3" color="secondary" />}
      {/* */}
      {/* Retorno Pesquisa Vazia */}
      {!loading && quantidadeItems === 0 && (
        <div>A pesquisa não retornou nenhum item</div>
      )}
      {/* */}
      {/* Tabela de Retorno */}
      {!loading &&
      listaDocumentos &&
      quantidadeItems &&
      quantidadeItems !== 0 ? (
        <TableStyled>
          <div>
            {quantidadeItems > 10
              ? `Exibindo 10 de ${quantidadeItems} ite${
                  quantidadeItems > 1 ? "ns" : ""
                } `
              : `Exibindo um total de ${quantidadeItems} ite${
                  quantidadeItems > 1 ? "ns" : ""
                }`}
          </div>
          <div className="table-container">
            <table className="fixed">
              <thead>
                <tr className="text-center">
                  <th scope="col">Aluno</th>
                  <th scope="col">Responsável</th>
                  <th scope="col">Unidade</th>
                  <th scope="col">Ano</th>
                </tr>
              </thead>
              <tbody>
                {listaDocumentos.results.map((document) => {
                  return (
                    <tr key={document.id}>
                      <td data-label="Nome do Aluno">{document.student}</td>
                      <td data-label="Responsável">{document.guardian}</td>
                      <td data-label="Unidade" className="text-center">
                        {document.unit}
                      </td>
                      <td data-label="Ano" className="text-center">
                        {document.cycle}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="scroll">
              <table className="scrollable">
                <thead>
                  <tr className="text-center">
                    <th scope="col">RG responsável Financeiro</th>
                    <th scope="col">CPF responsável Financeiro</th>
                    <th scope="col">
                      Comp de residência responsável financeiro
                    </th>
                    <th scope="col">Foto responsável Financeiro</th>
                    <th scope="col">RG aluno</th>
                    <th scope="col">CPF aluno</th>
                    <th scope="col">Foto aluno para crachá</th>
                    <th scope="col">Declaração escolaridade</th>
                    <th scope="col">Declaração transferência</th>
                    <th scope="col">Histórico escolar</th>
                    <th scope="col">Declaração adoção</th>
                    <th scope="col">Declaração guarda</th>
                    <th scope="col">Comp de residência do aluno</th>
                    {/* <th scope="col">Notificações documentos pendentes</th> */}
                  </tr>
                </thead>
                <tbody>
                  {listaDocumentos.results.map((document) => {
                    return (
                      <tr key={document.id}>
                        <td data-label="RG do responsável Financeiro">
                          <div className="d-flex justify-content-center align-items-center">
                            <DocumentStatusDisplay
                              enrollment_documents={
                                document.enrollment_documents
                              }
                              document_name="RG do responsável Financeiro"
                            />
                          </div>
                        </td>
                        <td data-label="CPF do responsável Financeiro">
                          <div className="d-flex justify-content-center align-items-center">
                            <DocumentStatusDisplay
                              enrollment_documents={
                                document.enrollment_documents
                              }
                              document_name="CPF do responsável Financeiro"
                            />
                          </div>
                        </td>
                        <td data-label="Comprovante de residência do responsável Financeiro">
                          <div className="d-flex justify-content-center align-items-center">
                            <DocumentStatusDisplay
                              enrollment_documents={
                                document.enrollment_documents
                              }
                              document_name="Comprovante de residência do responsável Financeiro"
                            />
                          </div>
                        </td>
                        <td data-label="Foto do responsável Financeiro">
                          <div className="d-flex justify-content-center align-items-center">
                            <DocumentStatusDisplay
                              enrollment_documents={
                                document.enrollment_documents
                              }
                              document_name="Foto do responsável Financeiro"
                            />
                          </div>
                        </td>
                        <td data-label="RG do aluno">
                          <div className="d-flex justify-content-center align-items-center">
                            <DocumentStatusDisplay
                              enrollment_documents={
                                document.enrollment_documents
                              }
                              document_name="RG do aluno"
                            />
                          </div>
                        </td>
                        <td data-label="CPF do aluno">
                          <div className="d-flex justify-content-center align-items-center">
                            <DocumentStatusDisplay
                              enrollment_documents={
                                document.enrollment_documents
                              }
                              document_name="CPF do aluno"
                            />
                          </div>
                        </td>
                        <td data-label="Foto do aluno para crachá">
                          <div className="d-flex justify-content-center align-items-center">
                            <DocumentStatusDisplay
                              enrollment_documents={
                                document.enrollment_documents
                              }
                              document_name="Foto do aluno para crachá"
                            />
                          </div>
                        </td>
                        <td data-label="Declaração de escolaridade">
                          <div className="d-flex justify-content-center align-items-center">
                            <DocumentStatusDisplay
                              enrollment_documents={
                                document.enrollment_documents
                              }
                              document_name="Declaração de escolaridade"
                            />
                          </div>
                        </td>
                        <td data-label="Declaração de transferência">
                          <div className="d-flex justify-content-center align-items-center">
                            <DocumentStatusDisplay
                              enrollment_documents={
                                document.enrollment_documents
                              }
                              document_name="Declaração de transferência"
                            />
                          </div>
                        </td>
                        <td data-label="Histórico escolar">
                          <div className="d-flex justify-content-center align-items-center">
                            <DocumentStatusDisplay
                              enrollment_documents={
                                document.enrollment_documents
                              }
                              document_name="Histórico escolar"
                            />
                          </div>
                        </td>
                        <td data-label="Declaração de adoção">
                          <div className="d-flex justify-content-center align-items-center">
                            <DocumentStatusDisplay
                              enrollment_documents={
                                document.enrollment_documents
                              }
                              document_name="Declaração de adoção"
                            />
                          </div>
                        </td>
                        <td data-label="Declaração de guarda">
                          <div className="d-flex justify-content-center align-items-center">
                            <DocumentStatusDisplay
                              enrollment_documents={
                                document.enrollment_documents
                              }
                              document_name="Declaração de guarda"
                            />
                          </div>
                        </td>

                        <td
                          data-label="Comprovante de residência do responsável com quem o aluno
                    reside"
                        >
                          <div className="d-flex justify-content-center align-items-center">
                            <DocumentStatusDisplay
                              enrollment_documents={
                                document.enrollment_documents
                              }
                              document_name="Comprovante de residência do responsável com quem o aluno
                            reside"
                            />
                          </div>
                        </td>
                        {/* <td className="text-center">
                          {document.has_pending_required_documents && (
                            <IconButton
                              onClick={() =>
                                sendPendingDocumentsNotificationsCallback(
                                  document.id
                                )
                              }
                              aria-label="notificacao-documentos-pendentes"
                            >
                              <Schedule color="primary" />
                            </IconButton>
                          )}
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-5">
              {paginaAnterior && (
                <Button onClick={() => Paginar("backwards")}>ANTERIOR</Button>
              )}
            </div>
            <div className="col-2 text-center mt-4">
              <strong>Página {pagina + 1}</strong>
            </div>
            <div className="col-5">
              {proximaPagina && (
                <Button onClick={() => Paginar("foward")}>PROXIMA</Button>
              )}
            </div>
          </div>
        </TableStyled>
      ) : (
        <div></div>
      )}
    </div>
  );
};
