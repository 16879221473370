// React
import { useEffect, useState } from "react";
// Styles
import { Step3Styled } from "./style";
// Interfaces
import { FoodPackage } from "interfaces/mealInterfaces";
// Helpers
import { FOOD_PAYMENT_STATUS, PAYMENT_METHODS } from "helpers/constants";
// Components
import { Text } from "components";
import { Button, Radio } from "components/atoms";
// Libs
import { useTranslation } from "react-i18next";

type Step3Type = {
  activeStep: number;
  foodPackage: FoodPackage;
  paymentMethod?: number;
  setActiveStep: (activeStep: number) => void;
  setPaymentMethod: (paymentMethod: number) => void;
};

export const Step3 = ({
  foodPackage,
  paymentMethod,
  setPaymentMethod,
  setActiveStep,
}: Step3Type) => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.foodOption.components.step3";

  const [pendingPayment, setPendingPayment] = useState(true);

  useEffect(() => {
    if (
      [
        FOOD_PAYMENT_STATUS.PAGAMENTO_CONFIRMADO,
        FOOD_PAYMENT_STATUS.ISENTO,
      ].includes(foodPackage.payment_status)
    ) {
      setPendingPayment(false);
      setActiveStep(4);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foodPackage]);

  const onSubmit = () => {
    if (paymentMethod) {
      setActiveStep(3);
    }
  };

  return (
    <Step3Styled>
      {pendingPayment && (
        <div className="step3Container">
          <Text fontSize="14px" fontWeight="400" lineHeight="21px">
            {t(`${TRANSLATE}.selectPaymentMethod`)}
          </Text>
          <form>
            {PAYMENT_METHODS.map((method) => {
              return (
                <div className="radioOption" key={method.value}>
                  <Radio
                    value={method.value.toString()}
                    name="payment_method"
                    required
                    onChange={() => {
                      setPaymentMethod(method.value);
                    }}
                  />
                  <Text
                    fontSize="14px"
                    fontWeight="400"
                    lineHeight="21px"
                    className="radioText"
                  >
                    {method.name}
                  </Text>
                </div>
              );
            })}
          </form>
          <div className="buttons">
            <Button
              width="45%"
              onClick={() => onSubmit()}
              disabled={!paymentMethod}
            >
              {t(`${TRANSLATE}.buttonAdvance`)}
            </Button>
          </div>
        </div>
      )}
    </Step3Styled>
  );
};
