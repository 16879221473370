import { ReactElement, useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";

import { Button, Modal } from "components";
import { ReducedHorizontalStepper } from "components/Timeline";
import { Postite } from "components/atoms";
import {
  ModalConfirmTerms,
  ModalPaymentOverdue,
  ModalPaymentPlan,
} from "components/Modals";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Placeholder from "static/img/placeholder.png";

import {
  StudentInterface,
  EnrollmentInterface,
  GetCXUserJson,
} from "interfaces/constants";

import { DOCUMENTS_STATUS, SCHOOL_PLACES_STATUS } from "helpers/constants";

import {
  StudentFrameBox,
  Avatar,
  StudentExpandContentBox,
  StudentName,
  ContrastedText,
  ListaDeEsperaFrameBox,
  ClickableText,
} from "./styles";

import { EnrollmentTypes } from "enums/enrollment";
import { useAllowedUnits } from "hooks/useAllowedUnits";
import { IsDevelopmentEnvironment } from "helpers/helpers";
import { RedirectWithContract } from "components/Redirect";

interface Props {
  student: StudentInterface;
  guardian?: GetCXUserJson;
}

export const StudentStatusFrame = ({
  student,
  guardian,
}: Props): ReactElement => {
  const [expanded, setExpanded] = useState(true);
  const [enrollment, setEnrollment] = useState<EnrollmentInterface>();
  const history = useHistory();
  const defaultStep = 0;
  const [timelineStep] = useState(defaultStep);
  const [guardianId, setGuardianId] = useState<number>();
  const [environment, setEnvironment] = useState("");
  const [openModalTerms, setOpenModalTerms] = useState(false);
  const [openModalPaymentOverdue, setOpenModalPaymentOverdue] = useState(false);
  const [openModalPaymentPlan, setOpenModalPaymentPlan] = useState(false);

  const [alowEnrollmentFlow, setAlowEnrollmentFlow] = useState(false);

  const { verifyUserPermissionToAccessFeature } =
    useAllowedUnits("fluxo-matricula");

  // verifica se pode continuar o fluxo
  const continueFlow = useCallback(
    async (unit: number) => {
      const isUserAllowed = await verifyUserPermissionToAccessFeature(unit);

      setAlowEnrollmentFlow(isUserAllowed);
    },
    [verifyUserPermissionToAccessFeature]
  );

  useEffect(() => {
    if (enrollment) {
      continueFlow(enrollment.unit_id);
    }
  }, [continueFlow, enrollment]);

  const handleReerollment = () => {
    student.last_enrollment.payment_overdue
      ? setOpenModalTerms(true)
      : history.push({
          pathname: "/pagamento/reserva-de-vaga",
          state: {
            student,
            guardianId,
            enrollment,
            guardian,
          },
        });
  };

  const contractSign = () => {
    if (enrollment) {
      const sign =
        enrollment.current_status === "Aguardando envio Documentação" ||
        (enrollment.current_status === "Contrato Assinado" &&
          enrollment.documents_status === DOCUMENTS_STATUS.DOCUMENTO_OK);

      return sign;
    }
  };

  useEffect(() => {
    if (student.last_enrollment) {
      setEnrollment(student.last_enrollment);
    }

    if (guardian) {
      setGuardianId(guardian.id);
    }

    if (process.env.NODE_ENV !== "development") {
      if (window.location.href.indexOf("homolog") > -1) {
        setEnvironment("homolog");
      } else {
        setEnvironment("prod");
      }
    } else {
      setEnvironment("homolog");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <StudentFrameBox
        className={`${expanded ? `` : `collapsed`}`}
        onClick={() => {
          setExpanded(!expanded);
        }}
      >
        {student.photo_url ? (
          <Avatar src={student.photo_url} />
        ) : (
          <Avatar src={Placeholder} />
        )}

        <div className="d-flex flex-column">
          <StudentName className="ml-3">Aluno: {student.name}</StudentName>
          <StudentName className="ml-3">
            Unidade: {student.last_enrollment?.unit_name}
          </StudentName>
          <StudentName className="ml-3">
            {student.last_enrollment?.grade || "Sem ano"}
            {student.last_enrollment?.grade && "º ano do "}
            {student.last_enrollment?.educational_level_name}
          </StudentName>
          {student.last_enrollment?.get_school_place_status_display ===
            "Sem reserva - desistência" && (
            <StudentName className="ml-3">
              Matricula: <span>Sem reserva - desistência</span>
            </StudentName>
          )}
        </div>
        <FontAwesomeIcon
          icon={
            expanded ? (faChevronUp as IconProp) : (faChevronDown as IconProp)
          }
          size="lg"
        />
      </StudentFrameBox>
      {expanded && enrollment && environment && (
        <StudentExpandContentBox>
          <div className="container">
            {/* Pre Cadastro */}
            {enrollment.school_place_status ===
              SCHOOL_PLACES_STATUS.PRE_CADASTRO && (
              <>
                <div className="mb-3">
                  <p className="text-center">
                    <ContrastedText>
                      Aluno com pré-cadastro para unidade:{" "}
                    </ContrastedText>
                    <strong>
                      {student.last_enrollment?.unit_name || "Sem unidade"}
                    </strong>
                  </p>
                </div>

                <div className="row">
                  <div className="col-12 text-center">
                    Para prosseguir com a rematricula, por favor, entre em
                    contato conosco no número (11) 9 9218-8917.
                  </div>
                </div>
              </>
            )}

            {/* Vaga reservada */}
            {enrollment.school_place_status ===
              SCHOOL_PLACES_STATUS.VAGA_RESERVADA && (
              <>
                {/* Tudo dentro desse cara aparece em PROD E HOMOLOG,
                a ideia é subir aqui oq vai ser deployado e segurar
                oq ainda não foi liberado na validação mais a baixo */}
                {/* Vaga Reservada */}

                {!contractSign() &&
                  enrollment.school_place_status ===
                    SCHOOL_PLACES_STATUS.VAGA_RESERVADA && (
                    <div className="mb-3">
                      <p className="text-center">
                        <ContrastedText>
                          Vaga reservada para Unidade:{" "}
                        </ContrastedText>
                        <strong>{student.last_enrollment?.unit_name}</strong>
                      </p>
                      <p className="text-center">
                        <strong>{student.last_enrollment?.grade}º</strong>
                        <ContrastedText> ano do </ContrastedText>
                        <strong>
                          {student.last_enrollment?.educational_level_name}
                        </strong>
                      </p>
                    </div>
                  )}

                {/* Aguardando Pagamento da Reserva */}
                {(enrollment.current_status ===
                  "Aguardando pagamento da Reserva" ||
                  enrollment.current_status ===
                    "Pagamento da Reserva em atraso") && (
                  <div className="row">
                    <div className="col-12 text-center">
                      Por favor, realize o pagamento o quanto antes para
                      garantir sua reserva
                    </div>
                    <div className="col-12 text-center">
                      <Button onClick={handleReerollment}>
                        Efetuar pagamento da reserva
                      </Button>
                    </div>
                  </div>
                )}
                {enrollment.current_status ===
                  "Aguardando Compensação do Pagamento" && (
                  <div className="row justify-content-center">
                    <div className="col-12 text-center">
                      <ContrastedText>Pagamento Pendente</ContrastedText>
                    </div>
                    <div className="col-12 text-center mt-2">
                      <p>Estamos aguardando a compensação do pagamento.</p>
                      <p>
                        Esse processo que pode demorar até 3 dias úteis no caso
                        do pagamento através do boleto bancário.
                      </p>
                      <p>
                        Caso queria efetuar o pagamento com outro método ou
                        consultar o boleto gerado, acesse o botão abaixo:
                      </p>
                    </div>
                    <div className="col-7 text-center">
                      <Button
                        className="p-1"
                        onClick={() => handleReerollment()}
                      >
                        Outras Opções de Pagamento
                      </Button>
                    </div>
                  </div>
                )}
                {enrollment.current_status ===
                  "Aguardando pagamento em dois meios" && (
                  <div className="row justify-content-center">
                    <div className="col-12 text-center">
                      <ContrastedText>Pagamento Pendente</ContrastedText>
                    </div>
                    <div className="col-12 text-center mt-2">
                      <p>
                        Ainda resta um valor a ser pago para garantir sua
                        reserva de vaga
                      </p>
                    </div>
                    <div className="col-7 text-center">
                      <Button
                        className="p-1"
                        onClick={() =>
                          student.last_enrollment.payment_overdue
                            ? setOpenModalTerms(true)
                            : history.push({
                                pathname:
                                  "/pagamento/reserva-de-vaga/segundo-cartao",
                                state: {
                                  student,
                                  guardianId,
                                  studentId: student.id,
                                  user: guardian,
                                },
                              })
                        }
                      >
                        Continuar pagamento
                      </Button>
                    </div>
                  </div>
                )}
                {/* Aguardando Análise Financeira */}
                {enrollment.current_status ===
                  "Aguardando Análise Financeira" && (
                  <div className="row">
                    <div className="col-12 text-center">
                      <ContrastedText>
                        Pagamento da Reserva Confirmado!
                      </ContrastedText>
                      <p>
                        A sua vaga na Escola Mais está reservada!
                        {/* <br />
                        Em breve você poderá finalizar seu processo de
                        matrícula! */}
                      </p>
                    </div>
                    <div className="col-12 text-center">
                      <Button
                        onClick={() =>
                          history.push({
                            pathname:
                              student.last_enrollment.type ===
                              EnrollmentTypes.Reenrollment
                                ? `/responsavel/meus-alunos/manter-responsavel-financeiro`
                                : `/responsavel/meus-alunos/responsavel-financeiro`,
                            state: { student, guardian },
                          })
                        }
                      >
                        Financeiro
                      </Button>
                    </div>
                  </div>
                )}
                {/* Aguardando Plano De Pagamento */}
                {enrollment.current_status ===
                  "Aguardando Plano De Pagamento" &&
                  student.guard_info.guard_id ===
                    student.last_enrollment.guard_id && (
                    <div className="row">
                      <div className="col-12 text-center">
                        <ContrastedText>Pagamento confirmado!</ContrastedText>
                        <p>
                          Agora escolha um plano de pagamento clicando no botão
                          abaixo:
                        </p>
                      </div>
                      <div className="col-12 text-center">
                        <Button
                          onClick={() =>
                            history.push({
                              pathname: `/financeiro/plano-pagamento`,
                              state: { student, guardian },
                            })
                          }
                        >
                          Financeiro
                        </Button>
                      </div>
                    </div>
                  )}
                {enrollment.current_status ===
                  "Aguardando Plano De Pagamento" &&
                  student.guard_info.guard_id !==
                    student.last_enrollment.guard_id && (
                    <div className="row">
                      <div className="col-12 text-center">
                        <ContrastedText>
                          Seu pagamento foi confirmado!
                        </ContrastedText>
                        <p>
                          Aguarde o responsável financeiro acessar o app e
                          escolher um plano de pagamento. Caso queira alterar o
                          responsável financeiro, clique no botão abaixo:
                        </p>
                      </div>
                      <div className="col-12 text-center">
                        <Button
                          onClick={() =>
                            history.push({
                              pathname: `/responsavel/meus-alunos/responsavel-financeiro`,
                              state: { student, guardian },
                            })
                          }
                        >
                          Trocar responsável financeiro
                        </Button>
                      </div>
                    </div>
                  )}
                {/* Análise Financeira Reprovada */}
                {enrollment.current_status ===
                  "Análise Financeira Reprovada" && (
                  <div className="row">
                    <div className="col-12 text-center">
                      <ContrastedText>
                        Seu pagamento foi confirmado!
                      </ContrastedText>
                      <p>
                        Envie a documentação necessária para gerarmos o contrato
                        da matrícula
                      </p>
                    </div>
                    <div className="col-12 text-center">
                      <Button
                        onClick={() =>
                          history.push({
                            pathname: `/responsavel/meus-alunos/carta`,
                            state: { student, guardian },
                          })
                        }
                      >
                        Financeiro
                      </Button>
                    </div>
                  </div>
                )}
                {enrollment.current_status === "Carta em Análise" && (
                  <div className="row">
                    <div className="col-12 text-center">
                      <p>
                        Seu documento foi enviado para análise de nossa equipe e
                        logo retornaremos com novidades para você
                      </p>
                    </div>
                  </div>
                )}
                {enrollment.current_status === "Em atendimento SAF" && (
                  <div className="row">
                    <div className="col-12 text-center">
                      <p>
                        Aguarde que logo a equipe da Escola Mais entrará em
                        contato com você
                      </p>
                    </div>
                  </div>
                )}
                {/* Aguardando envio documentação */}
                {enrollment.current_status ===
                  "Aguardando envio Documentação" && (
                  <RedirectWithContract />
                  // <div className="row">
                  //   <div className="col-12 text-center">
                  //     <ContrastedText>
                  //       Falta pouco para você concluir a matrícula!
                  //     </ContrastedText>
                  //     <p>
                  //       Envie a documentação necessária para gerarmos o contrato
                  //       da matrícula
                  //     </p>
                  //   </div>
                  //   <div className="col-12 text-center">
                  //     <Button
                  //       onClick={() =>
                  //         history.push({
                  //           pathname: `/responsavel/meus-alunos/documentos-matricula`,
                  //           state: { student, guardian },
                  //         })
                  //       }
                  //     >
                  //       Documentação
                  //     </Button>
                  //   </div>
                  // </div>
                )}
                {enrollment.current_status === "Documentos em análise" && (
                  <div className="row">
                    <div className="col-12 text-center">
                      <ContrastedText>
                        Seus documentos estão sendo analisados
                      </ContrastedText>
                      <p>
                        Clique no botão abaixo e veja a situação de cada
                        documento
                      </p>
                    </div>
                    <div className="col-12 text-center">
                      <Button
                        onClick={() =>
                          history.push({
                            pathname: `/responsavel/meus-alunos/documentos-matricula`,
                            state: { student, guardian },
                          })
                        }
                      >
                        Documentação
                      </Button>
                    </div>
                  </div>
                )}
                {/* Documentação Ok Para Contrato */}
                {enrollment.current_status ===
                  "Aguardando Assinatura do Contrato" &&
                  student.guard_info.guard_id ===
                    student.last_enrollment.guard_id && (
                    <div className="row">
                      <div className="col-12 text-center">
                        <ContrastedText>Plano selecionado!!!</ContrastedText>
                        <p style={{ margin: "10px 0" }}>
                          Agora, precisamos de mais algumas informações para
                          gerarmos seu contrato. Clique abaixo para prosseguir
                        </p>
                      </div>
                      <div className="col-12 text-center">
                        <Button
                          onClick={() => {
                            student.same_address_as_guardian === null ||
                            student.cpf === null
                              ? history.push({
                                  pathname: `/responsavel/meus-alunos/documentacao`,
                                  state: { student, guardian },
                                })
                              : history.push({
                                  pathname: `/contrato/confirmacao-dados`,
                                  state: { student, guardian },
                                });
                          }}
                        >
                          Contrato
                        </Button>
                      </div>
                    </div>
                  )}
                {enrollment.current_status ===
                  "Aguardando Assinatura do Contrato" &&
                  student.guard_info.guard_id !==
                    student.last_enrollment.guard_id && (
                    <div className="row">
                      <div className="col-12 text-center">
                        <p>
                          Aguarde O responsável financeiro assinar o contrato
                        </p>
                      </div>
                    </div>
                  )}
                {enrollment.current_status ===
                  "Aguardando Validação da Assinatura" && (
                  <div className="row">
                    <div className="col-12 text-center">
                      <p>Aguarde que estamos validando seu contrato</p>
                    </div>
                  </div>
                )}
                {/* Contrato Assinado */}
                {enrollment.current_status === "Contrato Assinado" &&
                  enrollment.documents_status !==
                    DOCUMENTS_STATUS.DOCUMENTO_OK && (
                    <div className="row">
                      <div className="col-12 text-center">
                        <ContrastedText>Contrato assinado</ContrastedText>
                        <p>
                          Agora precisamos que nos envie os útimos documentos
                          para finalizarmos a matrícula
                        </p>
                      </div>
                      <div className="col-12 text-center">
                        <Button
                          onClick={() =>
                            history.push({
                              pathname: `/responsavel/meus-alunos/documentos-matricula`,
                              state: { student, guardian },
                            })
                          }
                        >
                          Documentação
                        </Button>
                      </div>
                    </div>
                  )}
                {enrollment.current_status === "Contrato Assinado" &&
                  enrollment.documents_status ===
                    DOCUMENTS_STATUS.DOCUMENTO_OK && (
                    <RedirectWithContract />
                    // <div className="row">
                    //   <div className="col-12 text-center">
                    //     <ContrastedText>Matrícula concluida!!!</ContrastedText>
                    //     <p>
                    //       Agora estamos preparando tudo para o Onboarding do
                    //       aluno na Escola Mais! <br />
                    //       Logo teremos novidades por aqui!!!
                    //     </p>
                    //   </div>
                    // </div>
                  )}
                {/* Documentação Concluída */}
                {enrollment.current_status === "Documentação Concluída" && (
                  <div className="row">
                    <div className="col-12 text-center">
                      <ContrastedText>
                        Sua documentação foi concluída
                      </ContrastedText>
                    </div>
                    <div className="col-12 text-center">
                      <Button
                        onClick={() =>
                          history.push({
                            pathname: `/responsavel/meus-alunos/documentos-matricula`,
                            state: { student, guardian },
                          })
                        }
                      >
                        Visualizar Documentos
                      </Button>
                    </div>
                  </div>
                )}
                {/* Tudo dentro desse cara SÓ aparece em homolog. */}
                {environment === "homolog" && <></>}
                {/* Matrícula Com Plano De Pagamento Selecionado */}

                {(enrollment.current_status !== "Contrato Assinado" &&
                  enrollment.documents_status !==
                    DOCUMENTS_STATUS.DOCUMENTO_OK) ||
                  (enrollment.current_status !==
                    "Aguardando envio Documentação" && (
                    <>
                      {enrollment.get_financial_status_display ===
                        "Aguardando Plano De Pagamento" &&
                        enrollment.get_documents_status_display ===
                          "Aguardando envio Documentação" && (
                          <div className="col-12 text-center text-primary">
                            <ClickableText
                              onClick={() => setOpenModalPaymentPlan(true)}
                            >
                              Consultar Plano de Pagamento
                            </ClickableText>
                          </div>
                        )}
                    </>
                  ))}
                {!contractSign() &&
                  enrollment.school_place_status ===
                    SCHOOL_PLACES_STATUS.VAGA_RESERVADA && (
                    <ReducedHorizontalStepper step={timelineStep} />
                  )}
              </>
            )}
            {/* Lista de Espera */}
            {enrollment.school_place_status ===
              SCHOOL_PLACES_STATUS.LISTA_DE_ESPERA && (
              <>
                <div className="mb-3 text-center">
                  {!enrollment.is_out_of_app && (
                    <>
                      <ContrastedText>
                        Lista de espera para Unidade:{" "}
                      </ContrastedText>
                      <strong>{student.last_enrollment?.unit_name}</strong>
                      <p className="text-center">
                        <strong>{student.last_enrollment?.grade}º</strong>
                        <ContrastedText> ano do </ContrastedText>
                        <strong>
                          {student.last_enrollment?.educational_level_name}
                        </strong>
                      </p>
                    </>
                  )}
                </div>
                <div className="row">
                  <div className="col-12 text-center">
                    <ListaDeEsperaFrameBox>
                      {enrollment.is_out_of_app
                        ? "Aguarde. Logo você poderá dar continuidade à matrícula"
                        : "Assim que uma vaga for disponibilizada, você será notificado para" +
                          " dar andamento na matrícula"}
                    </ListaDeEsperaFrameBox>
                  </div>
                </div>
              </>
            )}
          </div>
        </StudentExpandContentBox>
      )}
      {enrollment && guardian && (
        <>
          <Modal setToggleModal={setOpenModalTerms} isOpen={openModalTerms}>
            <ModalConfirmTerms
              setToggleModal={setOpenModalTerms}
              student={student}
              guardianId={guardianId}
              enrollment={enrollment}
              guardian={guardian}
            />
          </Modal>
          <Modal
            setToggleModal={setOpenModalPaymentOverdue}
            isOpen={openModalPaymentOverdue}
          >
            <ModalPaymentOverdue
              setToggleModal={setOpenModalPaymentOverdue}
              student={student}
            />
          </Modal>
          <Modal
            setToggleModal={setOpenModalPaymentPlan}
            isOpen={openModalPaymentPlan}
          >
            <ModalPaymentPlan
              setToggleModal={setOpenModalPaymentPlan}
              student={student}
            />
          </Modal>
        </>
      )}
    </>
  );
};
