import styled from "styled-components";

interface Props {
  isActive: boolean;
}

export const TabDiv = styled.div<Props>`
  font-size: 0.875rem;
  padding: 0.8em 1em;
  background: #fff;
  border-radius: 10px 10px 0 0;
  display: flex;
  ${(props) => props.isActive && "border-bottom: 3px solid #00aeef"};

  svg {
    @media only screen and (min-width: 415px) {
      margin-right: 0.5em;
    }
  }

  a {
    color: ${(props) => (props.isActive ? "#00aeef" : "#5e5e5e")};
    font-weight: ${(props) => (props.isActive ? "bold" : "normal")};
  }
`;
