import { ModalHeader } from "./style";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Modal as ModalMUI } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

interface ModalProps {
  isOpen: boolean;
  children: any;
  setToggleModal: (isOpened: boolean) => void;
}
const useStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ffffff",
  },
}));

function Modal({ isOpen, setToggleModal, children }: ModalProps) {
  const classes = useStyles();
  return (
    <>
      <ModalMUI className={classes.modalStyle} open={isOpen}>
        <ModalHeader>
          <FontAwesomeIcon
            className="close"
            onClick={() => setToggleModal(false)}
            size="lg"
            icon={faTimes as IconProp}
          />
          <>{children}</>
        </ModalHeader>
      </ModalMUI>
    </>
  );
}

export default Modal;
