import styled from "styled-components";

export const DynamicFirstFormContainer = styled.form`
  display: flex;
  flex-direction: column;

  gap: 5px 0; // TODO: nao tem na lib

  label {
    font-size: ${({ theme }) => theme.fontSizes.web.sm};
    margin-bottom: 2px; // TODO: nao tem na lib
  }

  button {
    width: 122px;
    padding: ${({ theme }) => theme.space[3]} ${({ theme }) => theme.space[4]};
  }
`;

export const InputText = styled.div`
  margin-top: ${({ theme }) => theme.space[1]};
  display: flex;
  flex-direction: column;

  width: 165px;

  input {
    height: 37px; // TODO: nao tem na lib
    padding: ${({ theme }) => theme.space[2]};
    border: 2px solid ${({ theme }) => theme.colors.greyScale40};
  }
`;
