import styled from "styled-components";
import { BLUE, BLACK } from "styles/colors";

export const Avatar = styled.img`
  vertical-align: middle;
  width: 70px;
  height: 70px;
  border-radius: 50%;
`;

export const BoldBigger = styled.p`
  font-weight: bold;
  font-size: 18px;
  color: ${BLACK};
`;

export const BorderContainer = styled.div`
  border-width: 1px;
  border-color: ${BLUE};
  border-style: solid;
  border-radius: 15px;
  color: ${BLACK};
`;

export const AvatarContainer = styled.div`
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  border-width: 1px;
  background-color: ${BLUE};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
  color: white;

  img {
    margin-right: 4em;
  }

  strong {
    font-weight: bold;
    font-size: 18px;
    color: "#FFFFFF";
  }
`;
