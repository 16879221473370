import styled, { css } from "styled-components";
import { IItinerarieStatusProps } from ".";
import * as colors from "styles/colors";

export const Container = styled.div<IItinerarieStatusProps>`
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: bold;
  height: fit-content;

  span {
    font-size: ${({ theme }) => theme.fontSizes.web.sm};
  }

  ${({ type }) =>
    type === "Feito" &&
    css`
      color: white;
      background-color: ${({ theme }) => theme.colors.statusSuccessDefault};
    `}

  ${({ type }) =>
    type === "Pendente" &&
    css`
      color: ${colors.NEW_GREY_95};
      background-color: transparent;
      border: 2px solid ${colors.NEW_YELLOW};
    `}

  ${({ type }) =>
    type === "Atrasado" &&
    css`
      color: white;
      background-color: ${({ theme }) => theme.colors.statusErrorDefault};
    `}

    ${({ type }) =>
    type === "Em Andamento" &&
    css`
      color: ${colors.NEW_GREY_95};
      background-color: ${({ theme }) => theme.colors.statusWarningDefault};
    `}
`;
