import styled from "styled-components";

export const Subheading = styled.h2`
  color: #333333;
  font-size: 20px;
  font-weight: bold;
`;

export const SubheadingPink = styled(Subheading)`
  color: #ec008c;
`;
