/* eslint-disable array-callback-return */
import { useCallback, useState } from "react";

import { KanataAPI } from "services/api";
import { DataProps, HandleLoadDataProps } from "./EditClassesManagement.type";

export const useClassesManagement = () => {
  const [data, setData] = useState<DataProps>();
  const [panel, setPanel] = useState<DataProps>();
  const [loaded, setLoaded] = useState<boolean>(false);

  const handleLoadData = useCallback(
    ({ id, year, unit }: HandleLoadDataProps) => {
      setLoaded(false);
      if (id && year && unit) {
        KanataAPI.get(
          `cx/enturmamento_detail/${id}/?year=${year}&unit_id=${unit}`
        )
          .then((res) => {
            const response = res.data;
            setData(response);
            setLoaded(true);
          })
          .catch((err) => {
            throw err?.response;
          })
          .finally(() => {
            setLoaded(true);
          });
      }
    },
    []
  );

  return {
    handleLoadData,
    data,
    loaded,
    panel,
    setPanel,
  };
};
