import { ROLE } from "helpers/constants";
import { GetCXUserJson } from "interfaces/constants";
import imgPlaceholder from "static/img/placeholder.png";
import { Tabs } from "./components/Tabs";
import { HeaderProfileDiv, Title, ImgPlaceholder } from "./style";

type HeaderProfileType = {
  user?: GetCXUserJson;
  activeTab: string;
};

const HeaderProfile = ({ user, activeTab }: HeaderProfileType) => {
  const IS_STUDENT = user?.role.find(({ id }) => id === ROLE.STUDENT);

  return (
    <HeaderProfileDiv>
      <div className="d-flex">
        <ImgPlaceholder src={imgPlaceholder} alt="imagem placeholder" />
        <Title>
          {user?.personal.name} {IS_STUDENT ? "(Aluno)" : "(Responsável)"}
        </Title>
      </div>
      <Tabs activeTab={activeTab} />
    </HeaderProfileDiv>
  );
};

export default HeaderProfile;
