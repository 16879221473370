// Style
import * as S from "./style";
// Icons
import KeyboardArrowDownLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowDownRight from "@material-ui/icons/KeyboardArrowRight";
// Interfaces
import * as props from "./interface";
import { useCallback, useState } from "react";

// Deixar de utilizar esse carinha (padronização)
export const Pagination = ({
  Offset,
  setPage,
  previous,
  next,
  count,
  page,
}: props.PaginationType) => {
  const pages = Array.from(
    Array(Math.ceil(count / 10)),
    (_, index) => index + 1
  );

  return (
    <S.PaginationStyled className="mt-3 mb-3">
      <div
        onClick={() => {
          previous && Offset("backwards");
          previous && page && setPage(page - 1);
        }}
      >
        <KeyboardArrowDownLeft className={`${previous && "icon"}`} />
      </div>
      <div className="text-center">
        <div className="pageNumber">
          {pages.map((pageNumber, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  setPage(pageNumber);
                  Offset(pageNumber.toString());
                }}
                className={`mr-3 ml-3 ${
                  page && page === pageNumber ? "selectedPage mt-0" : "pages"
                }`}
              >
                {pageNumber}
              </div>
            );
          })}
        </div>
      </div>
      <div
        onClick={() => {
          next && Offset("foward");
          next && page && setPage(page + 1);
        }}
      >
        <KeyboardArrowDownRight className={`${next && "icon"}`} />
      </div>
    </S.PaginationStyled>
  );
};

// Dar preferencia pelo component de paginação dentro da atoms
export const SecondPagination = ({
  Offset,
  setPage,
  previous,
  next,
  count,
  firstStudent,
  lastStudent,
  page,
}: props.SecondPaginationType) => {
  const paginate = useCallback(
    (value: string) => {
      if (setPage && page) {
        if (value === "backwards") {
          setPage(page - 1);
        } else {
          setPage(page + 1);
        }
      }
    },
    [page, setPage]
  );

  return (
    <S.SecondPaginationStyled>
      <div>
        {firstStudent} - {lastStudent} de {count}
      </div>
      <div className="icons">
        <div
          onClick={() => {
            previous && Offset("backwards");
            previous && page && paginate("backwards");
          }}
        >
          <KeyboardArrowDownLeft
            className={`${previous ? "icon" : "iconBlock"}`}
          />
        </div>
        <div
          className="secondIcon"
          onClick={() => {
            next && Offset("foward");
            next && page && paginate("foward");
          }}
        >
          <KeyboardArrowDownRight
            className={`${next ? "icon" : "iconBlock"}`}
          />
        </div>
      </div>
    </S.SecondPaginationStyled>
  );
};
