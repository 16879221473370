/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { Typography } from "@material-ui/core";
import { Button, LoadingBox, TextInput } from "components";
import SelectInput from "components/Select";
import { toastError, toastSuccess } from "components/Toast";
import formatString from "format-string-by-pattern";
import {
  composeValidators,
  noSpecialCharactersAndNumbers,
  required,
  validateCVVIugu,
  validateCreditCardIugu,
  validateExpirationIugu,
} from "helpers/fieldValidators";
import { formatMoney } from "helpers/helpers";
import HTTP_STATUS from "http-status-codes";
import {
  EnrollmentInterface,
  GetCXUserJson,
  StudentInterface,
} from "interfaces/constants";
import { BoldBiggerPink } from "pages/EDF/PagamentoReserva/styles";
import { ReactElement, useContext, useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useHistory, useLocation } from "react-router-dom";
import {
  creditCardPaymentIugu,
  getMatriculaValue,
} from "services/payment.service";
import loadingGif from "static/img/loading.gif";
import { BLUE } from "styles/colors";

interface FormValues {
  payment_value: number;
}

type StateType = {
  studentId: number;
  guardianId: number;
  student: StudentInterface;
  user: GetCXUserJson;
  valorMatricula: number;
  enrollment: EnrollmentInterface;
};

export const MultiplePay = (): ReactElement => {
  const [paymentValue, setPaymentValue] = useState<number>();
  const [valorMatricula, setValorMatricula] = useState<number>();
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation<StateType>();
  const history = useHistory();
  const [failed, setFailed] = useState(false);
  const [errorText, setErrorText] = useState<Array<string>>([]);
  const [fullpay, setFullPay] = useState(false);

  //import iugu thought CDN
  const newWindow = window as any;
  const Iugu = newWindow.Iugu;
  //

  useEffect(() => {
    //Seta o AccountID da IUGU
    Iugu.setAccountID("8EF5BA8442B546CC85103E3D73C1FB55");

    //Verifica o ambiente e seta o modo teste da IUGU de acordo.
    if (process.env.NODE_ENV !== "development") {
      if (window.location.href.indexOf("homolog") > -1) {
        Iugu.setTestMode(true);
      } else {
        Iugu.setTestMode(false);
      }
    } else {
      Iugu.setTestMode(true);
    }
  }, []);

  const getNumberParcelas = () => {
    const today = new Date();
    if (today.getMonth() + 1 === 9 || today.getMonth() + 1 === 8) {
      return 6;
    } else if (today.getMonth() + 1 === 10) {
      return 5;
    } else if (today.getMonth() + 1 === 11) {
      return 4;
    } else if (today.getMonth() + 1 === 12) {
      return 3;
    } else if (today.getMonth() + 1 === 1) {
      return 2;
    } else {
      return 1;
    }
  };

  const onSubmit = (values: FormValues) => {
    if (values.payment_value == 0) {
      toastError(
        "Você não pode realizar um pagamento de R$0.00 , verifique o valor a ser pago e tente novamente."
      );
      setConfirm(false);
    } else {
      if (values.payment_value > (valorMatricula as number)) {
        toastError(
          "O valor a ser pago é maior que o valor da matrícula, verifique o valor digitado."
        );
        setConfirm(false);
      } else if (values.payment_value == (valorMatricula as number)) {
        setConfirm(true);
        setFullPay(true);
        setPaymentValue(values.payment_value);
      } else {
        setConfirm(true);
        setFullPay(false);
        setPaymentValue(values.payment_value);
      }
    }
  };

  const submitPayment = async (data: any) => {
    setLoading(true);

    const nameSplit = data.name.split(" ");
    const firstName = nameSplit[0];
    const lastName = nameSplit.splice(1).join(" ");
    const month = data.due_date.split("/")[0];
    const year = data.due_date.split("/")[1];

    const CreditCard = Iugu.CreditCard(
      data.card_number,
      month,
      year,
      firstName,
      lastName,
      data.cvv
    );

    const cardBrand = Iugu.utils.getBrandByCreditCardNumber(data.card_number);

    //O Objeto CreditCard da IUGU possuí o metodo valid() que retorna
    //True ou false dependendo se o cartão é válido.
    if (!CreditCard.valid()) {
      setFailed(true);
      setErrorText([
        ...errorText,
        "Cartão inválido, verifique os dados do mesmo",
      ]);
      setLoading(false);
    }

    Iugu.createPaymentToken(CreditCard, (response: any) => {
      if (response.errors) {
        Object.keys(response.errors).forEach((key) => {
          switch (key) {
            case "number":
              setErrorText([...errorText, "Número do cartão inválido"]);
              break;
            case "verification_value":
              setErrorText([...errorText, "CVV inválido"]);
              break;
            case "expiration":
              setErrorText([...errorText, "Data de vencimento inválida"]);
              break;
            case "first_name":
              setErrorText([
                ...errorText,
                "Primeiro nome inválido ou não informado",
              ]);
              break;
            case "last_name":
              setErrorText([
                ...errorText,
                "Sobrenome inválido ou não informado",
              ]);
              break;
            case "full_name":
              setErrorText([
                ...errorText,
                "Nome completo inválido ou não informado",
              ]);
              break;
          }
        });
        setFailed(true);
        setLoading(false);
      } else {
        if (!paymentValue) {
          setFailed(true);
          setErrorText([...errorText, "Erro no valor do pagamento."]);
        } else {
          creditCardPaymentIugu({
            charge_token: response.id,
            guardian_id: location.state.guardianId,
            value: Math.round(paymentValue * 100),
            student_id: location.state.studentId,
            brand: cardBrand,
            installments: parseInt(data.parcelas, 10),
          }).then((response) => {
            if (response) {
              if (response.status === HTTP_STATUS.OK) {
                setLoading(false);
                if (fullpay) {
                  toastSuccess("Pagamento concluído com sucesso");
                  history.push({
                    pathname: `/responsavel/meus-alunos`,
                    state: { user: location.state.user },
                  });
                } else {
                  history.push({
                    pathname: "/pagamento/reserva-de-vaga/segundo-cartao",
                    state: {
                      studentId: location.state.student.id,
                      guardianId: location.state.guardianId,
                      student: location.state.student,
                      user: location.state.user,
                      valorMatricula,
                      enrollment: location.state.enrollment,
                    },
                  });
                }
              } else {
                setFailed(true);
                setLoading(false);
                setErrorText([
                  ...errorText,
                  `Erro ${response.status} - ${response.data}`,
                ]);
              }
            }
          });
        }
      }
    });
  };

  useEffect(() => {
    const fetchValorMatricula = async () => {
      const response = await getMatriculaValue({
        student_id: location.state.studentId,
      });
      if (response && response.status === HTTP_STATUS.OK) {
        setValorMatricula(response.data / 100);
      } else {
        setFailed(true);
        setErrorText([
          ...errorText,
          "Erro ao Carregar o valor da matrícula para o pagamento",
        ]);
        setLoading(false);
      }
    };

    try {
      setLoading(true);
      fetchValorMatricula();
      setLoading(false);
    } catch (err) {
      setFailed(true);
      setErrorText([
        ...errorText,
        "Erro ao Carregar o valor da matrícula para o pagamento",
      ]);

      setLoading(false);
    }
  }, [location.state]);

  if (loading) {
    return (
      <div className="row justify-content-center">
        <div className="col-12">
          <LoadingBox height={50} />
        </div>
        <div className="col-8 text-center mt-3">
          <h5>Carregando!</h5>
        </div>
      </div>
    );
  } else if (failed) {
    return (
      <>
        <div className="row justify-content-center p-3">
          <div className="col-12 text-center mt-3">
            <BoldBiggerPink>
              Ocorreu um erro ao realizar o pagamento com o cartão
            </BoldBiggerPink>
            {errorText ? (
              <BoldBiggerPink>
                Mensagem de Erro:{" "}
                {errorText.map((errorMessage) => {
                  return (
                    <div style={{ color: "#000000" }} key={errorMessage}>
                      {errorMessage}
                    </div>
                  );
                })}
              </BoldBiggerPink>
            ) : (
              <BoldBiggerPink>
                Verifique os dados do cartão e tente novamente
              </BoldBiggerPink>
            )}
          </div>
          <div className="col-lg-6 col-12">
            <Button
              style={{ backgroundColor: BLUE }}
              onClick={() => {
                setFailed(false);
              }}
            >
              Voltar
            </Button>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="container">
        <>
          {valorMatricula && (
            <div className="col-lg-6 mx-auto">
              <p> Digite quanto deseja pagar no 1º cartão:</p>
              <p>
                {" "}
                Valor da Matrícula:{" "}
                <strong>{formatMoney(valorMatricula)}</strong>
              </p>
              <Form onSubmit={onSubmit}>
                {({ form, handleSubmit, submitting, values }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="row mt-1 justify-content-center">
                      <div className="col-12">
                        <Field
                          component={TextInput}
                          name="payment_value"
                          type="number"
                          placeholder="0.00"
                          min="0"
                          step="0.01"
                          pattern="^\d*(\.\d{0,2})?$"
                          label="Valor do Pagamento *"
                          onblur="this.parentNode.parentNode.style.backgroundColor=/^\d+(?:\.\d{1,2})?$/.test(this.value)?'inherit':'red'"
                          autoComplete="off"
                          required
                        />
                      </div>
                      <div className="col-8">
                        <Button type="submit">Confirmar Valor</Button>
                      </div>
                    </div>
                  </form>
                )}
              </Form>
              {confirm && paymentValue && (
                <>
                  <div className="col-12 mt-4 blockquote mb-4 text-center border">
                    {fullpay ? (
                      <p className="fs-3">
                        Você está pagando o valor total da matrícula.
                      </p>
                    ) : (
                      <p>
                        Restará{" "}
                        <strong>
                          {formatMoney(valorMatricula - paymentValue)}
                        </strong>{" "}
                        para o 2º Cartão
                      </p>
                    )}
                    <p>
                      Valor do Pagamento no 1º Cartão:{" "}
                      <strong>{formatMoney(paymentValue)}</strong>
                    </p>
                  </div>
                  <Form onSubmit={submitPayment}>
                    {({ form, handleSubmit, submitting, values }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-12 text-center">
                            <Typography variant="h4">
                              Digite os Dados do 1º Cartão
                            </Typography>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            <Field
                              component={TextInput}
                              name="card_number"
                              type="text"
                              placeholder=" "
                              label="Número do cartão"
                              parse={formatString("9999 9999 9999 9999")}
                              maxLength={20}
                              validate={composeValidators(
                                validateCreditCardIugu
                              )}
                              required
                            />
                          </div>
                          <div className="col-12">
                            <Field
                              component={TextInput}
                              name="name"
                              type="text"
                              placeholder=" "
                              label="Nome Titular"
                              validate={composeValidators(
                                required,
                                noSpecialCharactersAndNumbers
                              )}
                              required
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-9">
                            <Field
                              component={TextInput}
                              name="due_date"
                              type="text"
                              placeholder="MM/AA"
                              parse={formatString("99/99")}
                              label="Data de Vencimento"
                              validate={composeValidators(
                                required,
                                validateExpirationIugu
                              )}
                              required
                            />
                          </div>
                          <div className="col-9">
                            <Field
                              component={TextInput}
                              name="cvv"
                              type="text"
                              placeholder=" "
                              label="CVV"
                              maxLength="4"
                              validate={composeValidators(
                                required,
                                validateCVVIugu(values.card_number)
                              )}
                              required
                            />
                          </div>
                          <div className="col-9">
                            <Field
                              component={SelectInput}
                              name="parcelas"
                              required
                              validate={composeValidators(required)}
                              defaultValue="1"
                            >
                              {[...Array(6).keys()].map((parcela) => {
                                return (
                                  <option value={parcela + 1} key={parcela + 1}>
                                    {`${parcela + 1}x de ${formatMoney(
                                      paymentValue / (parcela + 1)
                                    )}`}{" "}
                                  </option>
                                );
                              })}
                            </Field>
                          </div>
                        </div>
                        <div className="row justify-content-center mt-5">
                          <div className="col-12 mt-2">
                            <Button type="submit" disabled={loading}>
                              {!loading && "Confirmar Pagamento"}
                              {loading && (
                                <img
                                  height="25"
                                  src={loadingGif}
                                  alt="loading"
                                />
                              )}
                            </Button>
                          </div>
                          <div className="col-lg-6 col-12">
                            <Button
                              type="button"
                              style={{ backgroundColor: BLUE }}
                              onClick={() => {
                                history.push({
                                  pathname: `/responsavel/meus-alunos`,
                                  state: { user: location.state.user },
                                });
                              }}
                            >
                              Voltar
                            </Button>
                          </div>
                        </div>
                      </form>
                    )}
                  </Form>
                </>
              )}
            </div>
          )}
        </>
      </div>
    );
  }
};
