// React
import { useState, useContext, useEffect } from "react";
// Styles
import { CreditCardStyle } from "./style";
// Interfaces
import { FoodPackage } from "interfaces/mealInterfaces";
// Libs
import { Field, Form } from "react-final-form";
import formatString from "format-string-by-pattern";
import HTTP_STATUS from "http-status-codes";
import { useTranslation } from "react-i18next";
// Components
import {
  LoadingBox,
  Text,
  TextInputFinalForm,
  SelectInputFinalForm,
} from "components";
import { Button, Postite } from "components/atoms";
// Helpers
import {
  composeValidators,
  validateCreditCardIugu,
  noSpecialCharactersAndNumbers,
  required,
} from "helpers/fieldValidators";
// Services
import { foodPackagePayment } from "services/payment.service";

type CreditCardType = {
  foodPackage: FoodPackage;
  failed: boolean;
  setFailed: (failed: boolean) => void;
  setActiveStep: (activeStep: number) => void;
  setNewPackage: (newPackage: boolean) => void;
};

export const CreditCard = ({
  foodPackage,
  failed,
  setFailed,
  setActiveStep,
  setNewPackage,
}: CreditCardType) => {
  const { t } = useTranslation();
  const TRANSLATE =
    "pages.EDF.foodOption.components.step4.components.cardCredit";

  const [errorText, setErrorText] = useState<Array<string>>([]);
  const [loading, setLoading] = useState(false);

  //Importa a Iugu do CDN
  const newWindow = window as any;
  const Iugu = newWindow.Iugu;

  useEffect(() => {
    //Seta o AccountID da IUGU
    Iugu.setAccountID("8EF5BA8442B546CC85103E3D73C1FB55");

    //Verifica o ambiente e seta o modo teste da IUGU de acordo.
    if (process.env.NODE_ENV !== "development") {
      if (window.location.href.indexOf("homolog") > -1) {
        Iugu.setTestMode(true);
      } else {
        Iugu.setTestMode(false);
      }
    } else {
      Iugu.setTestMode(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (values: any) => {
    setLoading(true);

    const nameSplit = values.name.split(" ");
    const firstName = nameSplit[0];
    const lastName = nameSplit.splice(1).join(" ");
    const month = values.due_date.split("/")[0];
    const year = values.due_date.split("/")[1];

    const CreditCard = Iugu.CreditCard(
      values.card_number,
      month,
      year,
      firstName,
      lastName,
      values.cvv
    );

    if (!CreditCard.valid()) {
      setFailed(true);
      setErrorText([...errorText, t(`${TRANSLATE}.cardGenericError`)]);
      setLoading(false);
    }

    Iugu.createPaymentToken(CreditCard, (response: any) => {
      if (response.errors) {
        Object.keys(response.errors).forEach((key) => {
          switch (key) {
            case "number":
              setErrorText([...errorText, t(`${TRANSLATE}.numberError`)]);
              break;
            case "verification_value":
              setErrorText([
                ...errorText,
                t(`${TRANSLATE}.verificationValueError`),
              ]);
              break;
            case "expiration":
              setErrorText([...errorText, t(`${TRANSLATE}.expirationError`)]);
              break;
            case "first_name":
              setErrorText([...errorText, t(`${TRANSLATE}.firstNameError`)]);
              break;
            case "last_name":
              setErrorText([...errorText, t(`${TRANSLATE}.lastNameError`)]);
              break;
            case "full_name":
              setErrorText([...errorText, t(`${TRANSLATE}.fullName`)]);
              break;
          }
        });
        setFailed(true);
        setLoading(false);
      } else if (foodPackage.food_package_value_month_after_balance) {
        foodPackagePayment({
          enrollment_id: foodPackage.enrollment_id,
          value: Math.round(
            foodPackage.food_package_value_month_after_balance * 100
          ),
          package_id: foodPackage.id,
          type_payment: "credit_card",
          charge_token: response.id,
        }).then((response) => {
          if (response) {
            if (response.status === HTTP_STATUS.OK) {
              setLoading(false);
              setNewPackage(true);
              setActiveStep(4);
            } else {
              setFailed(true);
              setLoading(false);
              setErrorText([
                ...errorText,
                `Erro ${response.status} - ${response.data}`,
              ]);
            }
          }
        });
      } else {
        setFailed(true);
        setLoading(false);
        setErrorText([...errorText, t(`${TRANSLATE}.cardGenericError2`)]);
      }
    });
  };

  return (
    <CreditCardStyle>
      {loading && (
        <div className="col-12">
          <LoadingBox height={50} />
        </div>
      )}
      {failed && !loading && (
        <div>
          <Postite
            type="danger"
            title={t(`${TRANSLATE}.paymentRefuseError`)}
            content={
              errorText && errorText.length > 0
                ? errorText[0]
                : t(`${TRANSLATE}.cardGenericError3`)
            }
            size={1}
            titleSize="14px"
            titleWeight="700"
            titleLineHeigth="21px"
            contentSize="14px"
            contentLineHeight="21px"
            contentWeight="400"
          />
        </div>
      )}
      {foodPackage && !loading && (
        <div>
          <div className="title">
            <Text fontSize="14px" fontWeight="400" lineHeight="21px">
              {t(`${TRANSLATE}.titleCardCredit`)}
            </Text>
          </div>
          <div className="formContainer">
            <Form onSubmit={onSubmit}>
              {({ form, handleSubmit, submitting, values }) => (
                <form onSubmit={handleSubmit}>
                  <div className="firstInput">
                    <Field
                      component={TextInputFinalForm}
                      name="card_number"
                      type="text"
                      placeholder="0000 0000 0000 0000"
                      label={t(`${TRANSLATE}.inputCardCreditNumber`)}
                      parse={formatString("9999 9999 9999 9999")}
                      maxLength={20}
                      validate={composeValidators(validateCreditCardIugu)}
                      required
                    />
                  </div>
                  <div className="othersInputs">
                    <Field
                      component={TextInputFinalForm}
                      name="name"
                      type="text"
                      placeholder={t(
                        `${TRANSLATE}.inputCardCreditNamePlaceHolder`
                      )}
                      label={t(`${TRANSLATE}.inputCardCreditName`)}
                      validate={composeValidators(
                        required,
                        noSpecialCharactersAndNumbers
                      )}
                      required
                    />
                  </div>
                  <div className="sameLineInput">
                    <div className="inputInLine">
                      <Field
                        component={TextInputFinalForm}
                        name="due_date"
                        type="text"
                        placeholder="mm/aa"
                        parse={formatString("99/99")}
                        label={t(`${TRANSLATE}.inputCardCreditDueDate`)}
                        validate={composeValidators(required)}
                        required
                      />
                    </div>
                    <div className="inputInLine">
                      <Field
                        component={TextInputFinalForm}
                        name="cvv"
                        type="text"
                        placeholder="CVV"
                        label={t(`${TRANSLATE}.inputCardCreditCvv`)}
                        maxLength="4"
                        validate={composeValidators(required)}
                        required
                      />
                    </div>
                  </div>
                  <div className="othersInputs">
                    <Field
                      component={SelectInputFinalForm}
                      name="installments"
                      validate={composeValidators(required)}
                      required
                      label={t(`${TRANSLATE}.inputCardinstallments`)}
                    >
                      <option value="1">
                        {t(`${TRANSLATE}.inputInstallments`)}
                        {
                          foodPackage.food_package_value_month_after_balance_formatted
                        }
                      </option>
                    </Field>
                  </div>
                  <div className="valueTotal">
                    <Text fontSize="18px" fontWeight="700" lineHeight="27px">
                      {t(`${TRANSLATE}.total`)}
                    </Text>
                    <Text fontSize="18px" fontWeight="700" lineHeight="27px">
                      R$
                      {
                        foodPackage.food_package_value_month_after_balance_formatted
                      }
                    </Text>
                  </div>
                  <div className="buttons">
                    <Button
                      width="45%"
                      type="button"
                      outline
                      onClick={() => setActiveStep(2)}
                    >
                      {t(`${TRANSLATE}.buttonBack`)}
                    </Button>
                    <Button
                      type="submit"
                      width="45%"
                      disabled={loading}
                      onClick={() => onSubmit(values)}
                    >
                      {t(`${TRANSLATE}.buttonAdvance`)}
                    </Button>
                  </div>
                </form>
              )}
            </Form>
          </div>
        </div>
      )}
    </CreditCardStyle>
  );
};
