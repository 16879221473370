import { IconType } from "types/constants";

export const IconClock = ({ isActive }: IconType) => {
  return (
    <svg
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      fill={isActive ? "#00aeef" : "#5e5e5e"}
      height="15"
      width="15"
    >
      <path d="m8 0c-4.41113 0-8 3.58887-8 8s3.58887 8 8 8 8-3.58887 8-8-3.58887-8-8-8zm1.41455 10.8286-2.41455-2.41455v-4.41406h2v3.58594l1.82861 1.82861z" />
    </svg>
  );
};

export default IconClock;
