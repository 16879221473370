import {
  Container,
  Heading,
  PublishCardContainer,
  YearSelector,
} from "./styles";
import { useTranslation } from "react-i18next";
import { FeatureEnabler, Loading } from "components";
import { usePublishBoletim } from "./PublishBoletim.helper";
import { PublishCard } from "./components/PublishCard";
import { UndoQuarterPublicationModal } from "./components/UndoQuarterPublicationModal";
import { ChangeEvent, useEffect, useState } from "react";
import { IQuarterPublicationsByUnitResponse } from "interfaces/boletim";
import { PublishQuarterModal } from "./components/PublishQuarterModal";

export const PublishBoletim = () => {
  const prefix = "pages.AP.publishBoletim";
  const { t } = useTranslation();
  const {
    stateMachine,
    years,
    publications,
    isLoadingPublications,
    onUndoQuarterPublication,
    getQuarterPublications,
    onPublishQuarter,
  } = usePublishBoletim();

  const [undoPublication, setUndoPublication] = useState<
    IQuarterPublicationsByUnitResponse | undefined
  >(undefined);
  const [publishQuarter, setPublishQuarter] = useState<
    IQuarterPublicationsByUnitResponse | undefined
  >(undefined);
  const [selectedYear, setSelectedYear] = useState<string>("");
  const [undoQuarterPublicationModalOpen, setUndoQuarterPublicationModalOpen] =
    useState(false);
  const [publishQuarterModalOpen, setPublishQuarterModalOpen] = useState(false);

  const handleSelectYear = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(e.target.value);
  };

  const handleUndoQuarterPublicationOpenModal = (
    publication: IQuarterPublicationsByUnitResponse
  ) => {
    setUndoPublication(publication);
    setUndoQuarterPublicationModalOpen(true);
  };

  const handleUndoQuarterPublication = async () => {
    if (undoPublication && selectedYear) {
      await onUndoQuarterPublication(undoPublication, selectedYear);
      setUndoQuarterPublicationModalOpen(false);
    }
  };

  const handlePublishQuarterOpenModal = (
    publication: IQuarterPublicationsByUnitResponse
  ) => {
    setPublishQuarter(publication);
    setPublishQuarterModalOpen(true);
  };

  const handlePublishQuarter = async () => {
    if (publishQuarter && selectedYear) {
      await onPublishQuarter(publishQuarter, selectedYear);
      setPublishQuarterModalOpen(false);
    }
  };

  useEffect(() => {
    if (selectedYear) {
      getQuarterPublications(selectedYear);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear]);

  const stateMachineStates = {
    IDLE: <></>,
    LOADING: <Loading />,
    READY: (
      <Container>
        <Heading>{t(`${prefix}.heading`)}</Heading>
        <YearSelector>
          <strong>{t(`${prefix}.yearSelector`)}</strong>
          <select
            onChange={handleSelectYear}
            name="publication-year"
            value={selectedYear}
          >
            <option value="" hidden>
              [ano]
            </option>
            {years?.map((year) => (
              <option key={`year-${year}`} value={String(year)}>
                {year}
              </option>
            ))}
          </select>
        </YearSelector>
        <FeatureEnabler enablerCallback={() => !!selectedYear}>
          {isLoadingPublications ? (
            <Loading />
          ) : (
            <PublishCardContainer>
              {publications?.length ? (
                publications?.map((pbl) => (
                  <PublishCard
                    key={pbl.publicacao.quarter}
                    publication={pbl}
                    onUndoQuarterPublication={
                      handleUndoQuarterPublicationOpenModal
                    }
                    onPublishQuarter={handlePublishQuarterOpenModal}
                  />
                ))
              ) : (
                <div>{t(`${prefix}.emptyMessage`)}</div>
              )}
            </PublishCardContainer>
          )}
        </FeatureEnabler>
      </Container>
    ),
    ERROR: <></>,
  };

  return (
    <>
      <UndoQuarterPublicationModal
        open={undoQuarterPublicationModalOpen}
        onCancel={() => setUndoQuarterPublicationModalOpen(false)}
        onAction={handleUndoQuarterPublication}
      />
      <PublishQuarterModal
        open={publishQuarterModalOpen}
        onCancel={() => setPublishQuarterModalOpen(false)}
        onAction={handlePublishQuarter}
      />
      {stateMachineStates[stateMachine]}
    </>
  );
};
