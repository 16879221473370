import styled, { css } from "styled-components";
import { IStyled } from "./interface";

export const CancelOrderStyled = styled.div<IStyled>`
  footer {
    gap: 0 ${({ theme }) => theme.space[1]};
  }

  button:last-of-type {
    ${({ loading }) =>
      !loading &&
      css`
        background-color: ${({ theme }) => theme.colors.statusErrorDefault};
        border-color: ${({ theme }) => theme.colors.statusErrorDefault};
        :hover {
          background-color: ${({ theme }) => theme.colors.statusErrorLight50};
          border-color: ${({ theme }) => theme.colors.statusErrorLight50};
        }
      `}

    ${({ loading }) =>
      loading &&
      css`
        background-color: ${({ theme }) => theme.colors.greyScale10};
        border-color: ${({ theme }) => theme.colors.greyScale10};
        cursor: not-allowed;

        span {
          color: ${({ theme }) => theme.colors.greyScale40};
        }

        cursor: not-allowed;
        :hover {
          background-color: none;
          border-color: none;
        }
      `}
  }
`;
