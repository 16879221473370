// React
import { useCallback, useRef } from "react";
// Styles
import { ModalDiv } from "./styles";
// Components
import { Button } from "components/atoms";
// Libs
import Webcam from "react-webcam";
import { useTranslation } from "react-i18next";
import { checkDevice } from "helpers/helpers";

interface ModalTakeAPictureInterface {
  setFile: (img: string | null) => void;
  file?: string | null;
  setToggleModal: (toggleModal: boolean) => void;
  onSubmit: () => void;
}

export const ModalTakeAPicture = ({
  setFile,
  file,
  setToggleModal,
  onSubmit,
}: ModalTakeAPictureInterface) => {
  const webcamRef = useRef<Webcam>(null);
  const { t } = useTranslation();
  const TRANSLATE = "components.organisms.sendDocuments.components.modal";

  const handleTakeSS = useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot() as string;
    setFile(imageSrc);
  }, [setFile]);

  const videoConstraints = {
    facingMode: { exact: "environment" },
  };

  return (
    <ModalDiv>
      {!file && (
        <div className="content">
          {checkDevice() ? (
            <Webcam
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              className="img"
              videoConstraints={videoConstraints}
            />
          ) : (
            <Webcam
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              className="img"
            />
          )}
        </div>
      )}
      {file && (
        <div className="content">
          <img src={file} alt="webcam screenshot" className="img" />
        </div>
      )}
      {!file && (
        <Button onClick={handleTakeSS}>{t(`${TRANSLATE}.takeAPicture`)}</Button>
      )}
      {file && (
        <div className="hasPicture">
          <Button width="45%" outline onClick={() => setFile(null)}>
            {t(`${TRANSLATE}.takeOtherPicture`)}
          </Button>
          <Button
            width="45%"
            onClick={() => {
              onSubmit();
              setToggleModal(false);
            }}
          >
            {t(`${TRANSLATE}.submitPicture`)}
          </Button>
        </div>
      )}
    </ModalDiv>
  );
};
