import { ButtonSquared } from "components";
import { GetCXUserJson } from "interfaces/constants";
import { useHistory, useLocation } from "react-router-dom";

interface LocationInterface {
  user: GetCXUserJson;
}

export const MenuAlimentacao = () => {
  const location = useLocation<LocationInterface>();
  const history = useHistory();

  return (
    <div className="container-fluid">
      <div className="row mt-5 justify-content-center">
        <div className="col-md-4 col-12 text-center">
          <ButtonSquared
            onClick={() =>
              history.push({
                pathname: "/responsavel/alimentacao/pacote-alimentacao",
                state: {
                  user: location.state.user,
                },
              })
            }
          >
            Comprar pacote de alimentação
          </ButtonSquared>
        </div>
        {/* <div className="col-12 col-sm-12 col-md-12 col-lg-4 text-center">
          <ButtonSquared
            onClick={() => history.push("/responsavel/consulta-alimentacao")}
          >
            Consultar consumo do aluno
          </ButtonSquared>
        </div> */}
      </div>
    </div>
  );
};
