import { ISelectInput } from "./interface";
import { SelectContainer } from "./style";
import { FieldValues } from "react-hook-form";

// TODO: input criado para utilizar com react-hook-form no primeiro momento
// TODO: melhorar tipagem depois para ser utilizado tambem sem o hook-form
// TODO: enviar para a lib
export const SelectInput = <T extends FieldValues>({
  label,
  width = 228,
  children,
  icon,
  id,
  register,
  error,
  watch,
  ...props
}: ISelectInput<T>) => {
  const hasValue = !watch ? true : watch && watch(id) ? true : false;

  return (
    <SelectContainer width={width} disabled={props.disabled} value={hasValue}>
      <label>
        {label} {icon ? icon : null}
      </label>
      <div>
        <select {...props} {...register(id)}>
          {children}
        </select>
        {error && <span>{error}</span>}
      </div>
    </SelectContainer>
  );
};
