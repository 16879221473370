import styled from "styled-components";

export const Container = styled.div`
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  .spinner {
    display: block;
    width: 23px;
    height: 23px;
    border: 2px dotted ${({ theme }) => theme.colors.primaryDefault};
    border-right-color: ${({ theme }) => theme.colors.primaryLight90};
    border-bottom-color: ${({ theme }) => theme.colors.primaryLight80};
    border-left-color: ${({ theme }) => theme.colors.primaryDark80};
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
`;
