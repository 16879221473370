import {
  SetStudentPresenceInterface,
  StudentPresenceInterface,
  StudentsPresencesInterface,
} from "interfaces/TeacherInterfaces";

export const formatSetEnrollments = (
  enrollments: Array<StudentPresenceInterface>
) => {
  const formatedEnrollments: Array<SetStudentPresenceInterface> = [];
  enrollments.forEach((e) => {
    const newEnrollment = {
      id: e.enrollment_id,
      presence: e.presence!.toString(),
      notes: e.notes || "",
      class_order: 1,
    };
    formatedEnrollments.push(newEnrollment);
  });
  return formatedEnrollments;
};

export const formatSetEnrollmentsSecondClass = (
  enrollments: Array<StudentPresenceInterface>
) => {
  const formatedEnrollments: Array<SetStudentPresenceInterface> = [];
  enrollments.forEach((e) => {
    const newEnrollment = {
      id: e.enrollment_id,
      presence: e.presence_2!.toString(),
      notes: e.notes || "",
      class_order: 2,
    };
    formatedEnrollments.push(newEnrollment);
  });
  return formatedEnrollments;
};

export const formatGetEnrollments = (
  enrollments: StudentsPresencesInterface
) => {
  const formatedEnrollments: StudentsPresencesInterface = { enrollments: [] };
  const IS_DOUBLE_CLASS = !!enrollments.enrollments.find(
    (e) => e.class_order === 2
  );
  if (!IS_DOUBLE_CLASS) {
    enrollments.enrollments.forEach((e) => {
      if (e.presence !== null) {
        e.presence = e.presence?.toString();
      }
      formatedEnrollments.enrollments.push(e);
    });
  } else {
    enrollments.enrollments.forEach((e, i) => {
      if (i % 2 === 0) {
        if (e.presence !== null) {
          const param = e.class_order === 1 ? "presence" : "presence_2";
          e[param] = e.presence?.toString();
        }
        if (enrollments.enrollments[i + 1].presence) {
          const param =
            enrollments.enrollments[i + 1].class_order === 1
              ? "presence"
              : "presence_2";
          // @ts-ignore
          e[param] = enrollments.enrollments[i + 1].presence?.toString();
        }
        formatedEnrollments.enrollments.push(e);
      }
    });
  }
  return formatedEnrollments;
};
