import {
  IItinerary,
  IOverdueStudyItineraryRequest,
  IStudyItinerarieStatusRequest,
  IStudyItinerarieStatusResponse,
  StudyItineraryRequest,
} from "types/study-itinerary";
import { RoteirosAPI } from "./api";

export const getStudyItinerary = async ({
  canvas_student_id,
  unit_id,
  date,
}: StudyItineraryRequest) => {
  try {
    const response = await RoteirosAPI.get<IItinerary>(
      `/roteiros/estudante/${canvas_student_id}/roteiros_estudo?unidade=${unit_id}&data=${date}`
    );

    if (!response) throw new Error();

    return response;
  } catch (error) {
    console.log(error);
    throw new Error(`Falha no serviço: getStudyItinerary`, error);
  }
};

export const getStudyItineraryStatus = async ({
  canvas_student_id,
  course_id,
  assignment_name,
  component_id,
}: IStudyItinerarieStatusRequest) => {
  try {
    const response = await RoteirosAPI.get<IStudyItinerarieStatusResponse>(
      `/roteiros/estudante/${canvas_student_id}/roteiros_estudo/status?componente=${course_id}&roteiro=${assignment_name}&modulo=${component_id}`
    );
    if (!response) throw new Error();

    return response;
  } catch (error) {
    console.log(error);
    throw new Error(`Falha no serviço: getStudyItineraryStatus`, error);
  }
};

export const getOverdueStudyItinerary = async ({
  canvas_student_id,
  unit_id,
}: IOverdueStudyItineraryRequest) => {
  try {
    const response = await RoteirosAPI.get<IItinerary[]>(
      `/roteiros/estudante/${canvas_student_id}/roteiros_atrasados?unidade=${unit_id}`
    );

    if (!response) throw new Error();

    return response;
  } catch (error) {
    console.log(error);
    throw new Error(`Falha no serviço: getStudyItineraryStatus`, error);
  }
};
