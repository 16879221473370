interface ParamsChangeNameProps {
  name: string;
}

export const handleChangeNameProp = ({ name }: ParamsChangeNameProps) => {
  switch (name) {
    case "id":
      return ""; // false
    case "name":
      return "Estudante"; // true
    case "ra":
      return "ra"; // true
    case "educational_level":
      return "Ciclo"; // true
    case "spreadsheet":
      return "Baixar"; // true
  }
};
