// React
import { useCallback, useContext, useEffect, useState } from "react";
// Interfaces
import { FormHelperInterface } from "./interface";
import {
  AvailableSchoolPlacesReturn,
  EnrollmentGuardianResultsInterface,
} from "interfaces/enrollment";
// Services
import {
  availableSchoolPlaces,
  getEnrollmentSteps,
} from "services/enrollment.service";
// Libs
import HTTP_STATUS from "http-status-codes";
import { useTranslation } from "react-i18next";
// Components
import { toastError } from "components/Toast";
import { GetUnitsGradesResponse } from "interfaces/unit";
import { getYear } from "services/multipurpose";
import { YearsInterface } from "interfaces/constants";
import { IPagination } from "interfaces/utils";
import { IEnrollment } from "../Table/interface";

export const WaitingListFormHelper = ({
  units,
  unit,
  educationalLevel,
  grade,
  selectedYear,
  setUnit,
  setEducationalLevel,
  setGrade,
  setSelectedYear,
}: FormHelperInterface) => {
  const { t } = useTranslation();
  const TRANSLATE =
    "pages.EDF.waitingList.components.form.waitingListFormHelper";

  // Response
  const [studentList, setStudentList] =
    useState<IPagination<IEnrollment> | null>();
  const [schoolPlaces, setSchoolPlaces] =
    useState<AvailableSchoolPlacesReturn>();

  const withVacancy =
    schoolPlaces && schoolPlaces?.vagas_disponiveis > 0 ? true : false;

  // Loading
  const loadindTable = selectedYear && !studentList ? true : false;

  // Pagination
  const [page, setPage] = useState<number>(1);
  const [firstStudent, setFirstStudent] = useState<number>(1);
  const [lastStudent, setLastStudent] = useState<number>();

  const [newSearch, setNewSearch] = useState(false);

  const [years, setYears] = useState<YearsInterface[]>([]);

  const getList = useCallback(async () => {
    setStudentList(null);
    setNewSearch(false);
    if (unit && educationalLevel && grade && selectedYear) {
      const [enrollmentSteps, availableSchool] = await Promise.all([
        getEnrollmentSteps({
          waitingList: true,
          unit,
          educational_level: educationalLevel,
          grade,
          selectedYear,
        }),
        availableSchoolPlaces({
          unit,
          educational_level: educationalLevel,
          grade,
          selectedYear,
        }),
      ]);

      if (enrollmentSteps && enrollmentSteps.status === HTTP_STATUS.OK) {
        setStudentList(enrollmentSteps.data);
        setLastStudent(enrollmentSteps.data.results.length);
      }

      if (availableSchool && availableSchool.status === HTTP_STATUS.OK) {
        setSchoolPlaces(availableSchool.data);
      }
    }
  }, [educationalLevel, grade, unit, selectedYear]);

  const getYears = useCallback(async () => {
    const getYears = await getYear({
      more_years: 1,
    });

    if (getYears && getYears.status === HTTP_STATUS.OK) {
      setYears(getYears.data.results);
    }
  }, [setYears]);

  useEffect(() => {
    getYears();
  }, [getYears]);

  useEffect(() => {
    try {
      getList();
    } catch (err) {
      toastError(t(`${TRANSLATE}.responseError`));
    }
  }, [getList, getYears, grade, t, newSearch]);

  // Pagination
  const Offset = async (direction: string) => {
    if (unit && educationalLevel && grade && lastStudent) {
      setStudentList(null);
      const response = await getEnrollmentSteps({
        page: direction === "foward" ? page + 1 : page - 1,
        waitingList: true,
        unit,
        educational_level: educationalLevel,
        grade,
      });
      if (response && response.status === HTTP_STATUS.OK) {
        if (direction === "foward") {
          setFirstStudent(
            page + 1 === 2
              ? firstStudent + response.data.results.length - 1
              : firstStudent + response.data.results.length
          );
          setLastStudent(lastStudent + response.data.results.length);
        } else {
          setFirstStudent(
            page - 1 === 1 ? 1 : firstStudent - response.data.results.length
          );
          setLastStudent(lastStudent - response.data.results.length);
        }
        setStudentList(response.data);
      } else {
        toastError(t(`${TRANSLATE}.responseError`));
      }
    }
  };

  const handleChange = (name: string, event: string) => {
    switch (name) {
      case "unit":
        if (unit) {
          setEducationalLevel("");
          setGrade("");
          setSelectedYear("");
        }
        return setUnit(event);
      case "educationalLevel":
        if (grade) {
          setGrade("");
          setSelectedYear("");
        }
        return setEducationalLevel(event);
      case "grade":
        if (selectedYear) {
          setSelectedYear("");
        }
        return setGrade(event);

      case "years":
        return setSelectedYear(event);
    }
  };

  const findEducationalLevel = (units: Array<GetUnitsGradesResponse>) => {
    const educational_level = units.find((item) => item.unit.id === unit);

    return educational_level;
  };

  const findGrade = () => {
    const grade = findEducationalLevel(units)?.unit.educational_level.find(
      (item) => item.id === educationalLevel
    );

    return grade;
  };

  return {
    studentList,
    schoolPlaces,
    loadindTable,
    page,
    setPage,
    firstStudent,
    lastStudent,
    Offset,
    handleChange,
    findEducationalLevel,
    findGrade,
    withVacancy,
    setNewSearch,
    years,
  };
};
