import styled from "styled-components";

export const ModalStyled = styled.div`
  margin-top: 23px;

  div {
    display: flex;
    justify-content: space-between;

    &.firstLine {
      margin-bottom: ${({ theme }) => theme.space[4]};
    }

    div {
      width: 48%;
      display: flex;
      flex-direction: column;
      label {
        font-weight: ${({ theme }) => theme.fontWeights.regular};
        font-size: ${({ theme }) => theme.fontSizes.web.sm};
        line-height: ${({ theme }) => theme.lineHeights.normal};
      }

      input {
        margin-top: -5px;
        padding-left: ${({ theme }) => theme.space[1]};
        box-sizing: border-box;
        height: ${({ theme }) => theme.space[10]};
        background: ${({ theme }) => theme.colors.white};
        border: 2px solid ${({ theme }) => theme.colors.greyScale40};

        &::placeholder {
          color: ${({ theme }) => theme.colors.greyScale40};
        }
      }
    }
  }

  p {
    margin: 0;
    margin-top: ${({ theme }) => theme.space[2]};
  }
`;
