import { useState } from "react";
import { Button } from "components";
import { ModalDiv } from "components/UseTerms/styles";
import {
  StudentInterface,
  EnrollmentInterface,
  GetCXUserJson,
} from "interfaces/constants";
import { useHistory } from "react-router-dom";

interface ModalPaymentOverdueInterface {
  setToggleModal: (toggleModal: boolean) => void;
  student: StudentInterface;
  guardianId: number | undefined;
  enrollment: EnrollmentInterface;
  guardian: GetCXUserJson;
}

export const ModalPaymentOverdue = ({
  setToggleModal,
  student,
  guardianId,
  enrollment,
  guardian,
}: ModalPaymentOverdueInterface) => {
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false);

  const history = useHistory();

  return (
    <div className="container">
      <ModalDiv>
        <div>
          <div className="text-center">
            <strong className="title">Atenção</strong>
          </div>
          <div className="text-center">
            Não é possível seguir com o processo de rematrícula. Entre em
            contato com nosso time de atendimento. <br />
            Contato: São Paulo (11) 5555-3725 | Joinville (47) 4003-6047
          </div>
          {/* <div>
            A Escola Mais se reserva o direito de cancelar a rematrícula do
            aluno, antes do início do ano letivo de {enrollment.year}, em caso
            de inadimplência no contrato do ano letivo de {enrollment.year - 1}.
          </div> */}
          {/* <div className="row text-left mb-5">
            <div>
              <input
                type="checkbox"
                onClick={() => setAcceptTerms(!acceptTerms)}
              />
            </div>
            <div className="ml-3">Eu li e estou de acordo</div>
          </div> */}
          <div className="d-flex justify-content-center">
            <div className="w-50">
              <Button
                onClick={() => setToggleModal(false)}
                className="blue outline"
              >
                Fechar
              </Button>
            </div>
          </div>
          {/* <div className="col-6">
              <Button
                onClick={() =>
                  history.push({
                    pathname: "/pagamento/reserva-de-vaga",
                    state: { student, guardianId, enrollment, guardian },
                  })
                }
                type="button"
                disabled={!acceptTerms}
              >
                Continuar
              </Button>
            </div> */}
        </div>
      </ModalDiv>
    </div>
  );
};

export default ModalPaymentOverdue;
