import styled from "styled-components";
import * as colors from "styles/colors";

export const FoodRecordStyled = styled.div`
  padding: 1em;

  div.inputContainer {
    display: flex;
    margin-top: 1em;
    flex-wrap: wrap;

    div.inputBeyond {
      margin-right: 1.3em;

      &.column {
        display: flex;
        flex-direction: column;
      }

      &.button {
        margin-top: 1.2em;
      }
      .date {
        height: 37px;
        width: 146px;
        padding-left: 7px;

        border: 2px solid ${colors.GRAY_SCALE};
      }
    }
  }

  div.text {
    margin-top: 1.5em;
  }

  div.table {
    margin-top: 1.5em;
    div.checkboxContainer {
      margin-left: 1.2em;
      height: 10px;
      padding: 0;
      &.check {
        margin-left: 2.2em;
      }
    }
  }
`;

export const LoadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h4 {
    text-align: center;
  }
`;
