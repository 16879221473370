import {
  onlyNumbersFromString,
  stringToMaskCEP,
  stringToMaskCPF,
} from "helpers/helpers";
import { GetCXUserJson, UnitDetailELInterface } from "interfaces/constants";

interface newData {
  name: string;
  cpf?: string;
  birth_date: string;
  rg: string;
  previous_school: string;
  guard_type: string;
  zip_code: string;
  city: string;
  address: string;
  neighborhood: string;
  number: string;
  uf: string;
  complement: string;
  same_address_as_guardian: any;
  gender_type: string;
}

export const formatStudentInitialData = (
  data: GetCXUserJson | undefined,
  units: Array<UnitDetailELInterface>
) => {
  let newData: newData = {
    name: "",
    cpf: "",
    birth_date: "",
    rg: "",
    previous_school: "",
    guard_type: "0",
    zip_code: "",
    city: "",
    address: "",
    neighborhood: "",
    number: "",
    uf: "",
    complement: "",
    same_address_as_guardian: null,
    gender_type: "",
  };
  if (data) {
    //
    if (data.gender_type === "Masculino") {
      newData.gender_type = "1";
    } else if (data.gender_type === "Feminino") {
      newData.gender_type = "2";
    } else {
      newData.gender_type = "";
    }

    newData.name = data.personal.name;
    if (data?.personal.cpf) {
      newData.cpf = data?.personal.cpf
        ? stringToMaskCPF(data.personal.cpf)
        : "";
    }
    newData.birth_date = data?.personal.birth_date || "";
    newData.rg = data?.personal.rg || "";
    newData.previous_school = data?.education.previous_school || "";
    newData.same_address_as_guardian = data?.location.same_address_as_guardian;
    if (newData.same_address_as_guardian) {
      const guardian = data.guardians.find(
        (g) => g.id === newData.same_address_as_guardian
      );
      if (guardian) {
        newData.zip_code = guardian.zip_code
          ? stringToMaskCEP(guardian.zip_code)
          : "";
        newData.city = guardian.city || "";
        newData.address = guardian.address || "";
        newData.complement = guardian.complement || "";
        newData.number = guardian.number || "";
        newData.neighborhood = guardian.neighborhood;
        newData.uf = guardian.uf || "";
      }
    } else {
      newData.zip_code = data?.location.zip_code
        ? stringToMaskCEP(data?.location.zip_code)
        : "";
      newData.city = data?.location.city || "";
      newData.address = data?.location.address || "";
      newData.complement = data?.location.complement || "";
      newData.number = data?.location.number || "";
      newData.neighborhood = data?.location.neighborhood;
      newData.uf = data?.location.uf || "";
    }
  }
  return newData;
};

export interface StudentFormDataInterface {
  name: string;
  birth_date: string;
  cpf?: string;
  rg: string;
  previous_school: string;
  zip_code: string;
  city: string;
  address: string;
  number: string;
  uf: string;
  complement: string;
  neighborhood: string;
  same_address_as_guardian: any;
  unit: string;
  grade: number;
  gender_type: any;
}

export const setStudentAfterUpdate = (
  user: GetCXUserJson | undefined,
  data: StudentFormDataInterface
) => {
  if (user) {
    user.personal.name = data.name;
    user.personal.birth_date = data.birth_date;
    user.personal.cpf = data.cpf!;
    user.personal.rg = data.rg;
    user.education.previous_school = data.previous_school;
    user.location.zip_code = data.zip_code;
    user.location.address = data.address;
    user.location.number = data.number;
    user.location.neighborhood = data.neighborhood;
    user.location.uf = data.uf;
    user.location.city = data.city;
    user.location.same_address_as_guardian = data.same_address_as_guardian;
    user.gender_type = data.gender_type;
  }
  return user;
};

interface formatUpdateStudentsPayload extends StudentFormDataInterface {
  gender_type: number;
}

export const formatUpdateStudentPayload = (
  payload: formatUpdateStudentsPayload
) => {
  if (payload.cpf && payload.cpf !== "") {
    if (payload.cpf === "999.999.999-99") {
      delete payload.cpf;
    } else {
      payload.cpf = onlyNumbersFromString(payload.cpf!);
    }
  } else {
    delete payload.cpf;
  }
  if (payload.zip_code) {
    payload.zip_code = onlyNumbersFromString(payload.zip_code);
  }
  if (payload.same_address_as_guardian) {
    payload.zip_code = "";
    payload.address = "";
    payload.number = "";
    payload.complement = "";
    payload.neighborhood = "";
    payload.city = "";
    payload.uf = "";
  } else {
    payload.same_address_as_guardian = null;
  }
  return payload;
};
