import { useCallback, useEffect, useState } from "react";
import HTTP_STATUS from "http-status-codes";
import { useLocation } from "react-router-dom";

import { toastError } from "components/Toast";

import { getUserDocuments } from "services/documents.service";
import { ILocaltionProps, IUserDocumentsData } from "../UserDocuments/types";

export const useStudentDocuments = () => {
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(true);
  const [studentDocuments, setStudentDocuments] =
    useState<IUserDocumentsData | null>(null);

  const { state } = useLocation<ILocaltionProps>();

  const handleStudentDocuments = useCallback(async () => {
    const userDocumentsData = await getUserDocuments(state.student.id);

    if (userDocumentsData?.status === HTTP_STATUS.OK) {
      setStudentDocuments(userDocumentsData.data);
    } else {
      toastError("Erro ao carregar os documentos do aluno.");
    }
  }, [state.student.id]);

  useEffect(() => {
    (async () => {
      setIsLoadingDocuments(true);

      await handleStudentDocuments();

      setIsLoadingDocuments(false);
    })();
  }, [handleStudentDocuments]);

  return {
    isLoadingDocuments,
    studentDocuments,
    setStudentDocuments,
  };
};
