import styled, { css } from "styled-components";
import { ICalendarDaySubtitle } from "../../interfaces";

interface ICalendarDayProps {
  legenda: keyof typeof ICalendarDaySubtitle;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.space[4]};
  width: 300px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  h1 {
    text-transform: capitalize;
  }
`;

export const Body = styled.table`
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;

  thead th {
    text-align: center;
    font-weight: ${({ theme }) => theme.fontWeights.regular};
  }

  tbody tr td {
    text-align: center;
  }

  td {
    width: 36px;
    height: 36px;
  }
`;

export const CalendarDay = styled.button<ICalendarDayProps>`
  width: 100%;
  height: 100%;
  text-align: center;
  border: none;
  padding: 0;

  ${({ legenda }) =>
    legenda === ICalendarDaySubtitle["Dia letivo"] &&
    css`
      color: ${({ theme }) => theme.colors.greyScale95};
      background-color: ${({ theme }) => theme.colors.white};
    `}

  ${({ legenda }) =>
    legenda === ICalendarDaySubtitle["Dia sem aula"] &&
    css`
      color: ${({ theme }) => theme.colors.greyScale40};
      background-color: ${({ theme }) => theme.colors.greyScale10};
    `}

  ${({ legenda }) =>
    legenda === ICalendarDaySubtitle.Feriado &&
    css`
      color: ${({ theme }) => theme.colors.greyScale95};
      background-color: ${({ theme }) => theme.colors.secondaryDefault};
    `}

  ${({ legenda }) =>
    legenda === ICalendarDaySubtitle["Marco Pedagógico"] &&
    css`
      color: ${({ theme }) => theme.colors.greyScale95};
      background-color: ${({ theme }) => theme.colors.brandingPink};
    `}
`;
