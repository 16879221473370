import { useEffect, useState } from "react";
import HTTP_STATUS from "http-status-codes";

import Icon from "@mdi/react";
import {
  mdiChevronRight,
  mdiChevronLeft,
  mdiMenuDown,
  mdiMenuUp,
} from "@mdi/js";

import { Loading } from "components/atoms/Loading";
import { Drawer } from "components/organisms/Drawer";
import { DrawerRowCollapse } from "../DrawerRowCollapse";
import { SelectStateContainer } from "components/atoms/Select/style";

import {
  GradeQuarters,
  IGradeAttendanceSheet,
  IStudentAttendanceSheet,
  ISubjectAttendance,
} from "interfaces/gradeGroupInterfaces";
import { orderBy, orderByNumber } from "helpers/helpers";
import { getQuarters, getSubjectAttendance } from "services/grades.service";

import * as S from "./styles";
import { t } from "i18next";

interface ClassDiaryDrawerProps {
  title: string;
  open: boolean;
  onClose: () => void;
  selectedStudent: IStudentAttendanceSheet;
  setSelectedStudent: (selectedStudent: IStudentAttendanceSheet) => void;
  studentsListData: IGradeAttendanceSheet[];
  selectedStudentIndex: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
}

export const ClassDiaryDrawer = ({
  title,
  open,
  onClose,
  selectedStudent,
  setSelectedStudent,
  studentsListData,
  selectedStudentIndex,
  setIndex,
}: ClassDiaryDrawerProps) => {
  const [quarters, setQuarters] = useState([] as GradeQuarters[]);
  const [isLoading, setIsLoading] = useState(true);
  const [subjectsAttendance, setSubjectsAttendance] =
    useState<ISubjectAttendance>({} as ISubjectAttendance);

  const [componentsFilter, setComponentsFilter] = useState("asc");
  const [attendanceFilter, setAttendanceFilter] = useState("asc");
  const [selectedQuarter, setSelectedQuarter] = useState(0);

  const iconPath = (filter: string) =>
    filter === "asc" ? mdiMenuDown : mdiMenuUp;

  useEffect(() => {
    (async () => {
      const res = await getQuarters();

      if (res?.status === HTTP_STATUS.OK) {
        setQuarters([
          ...res.data,
          {
            id: 0,
            name: "Total anual",
          },
        ]);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      const res = await getSubjectAttendance(
        selectedStudent?.enrollment_id,
        selectedQuarter
      );
      setSubjectsAttendance(res?.data);
      setIsLoading(false);
    })();
  }, [selectedStudent, selectedQuarter]);

  const handleNextStudent = () => {
    setSelectedQuarter(0);
    setSelectedStudent(
      studentsListData[0]?.enrollments[selectedStudentIndex + 1]
    );
    setIndex((prevState: number) => prevState + 1);
  };

  const handlePrevStudent = () => {
    setSelectedQuarter(0);
    setSelectedStudent(
      studentsListData[0]?.enrollments[selectedStudentIndex - 1]
    );
    setIndex((prevState: number) => prevState - 1);
  };

  const handleFilterByComponents = () => {
    setIsLoading(true);
    setComponentsFilter((prevState) => (prevState === "asc" ? "desc" : "asc"));
    setAttendanceFilter("asc");

    const { orderedData } = orderBy(
      "name",
      subjectsAttendance.subjects,
      componentsFilter
    );

    setSubjectsAttendance((prevState) => ({
      ...prevState,
      subjects: orderedData,
    }));
    setIsLoading(false);
  };

  const handleFilterByAttendance = () => {
    setIsLoading(true);
    setAttendanceFilter((prevState) => (prevState === "asc" ? "desc" : "asc"));
    setComponentsFilter("asc");

    const { orderedData } = orderByNumber(
      "attendance_display",
      subjectsAttendance.subjects,
      attendanceFilter
    );

    setSubjectsAttendance((prevState) => ({
      ...prevState,
      subjects: orderedData,
    }));
    setIsLoading(false);
  };

  const prefix = "pages.AP.researchCall.studentView.diaryDrawer";

  return (
    <S.Container>
      <Drawer isOpen={open} onClose={onClose} title={title} side="right">
        <div className="drawerHeaderInfo">
          <button
            onClick={handlePrevStudent}
            disabled={selectedStudentIndex === 0 || isLoading}
          >
            <Icon path={mdiChevronLeft} size={2} />
          </button>
          <h2>{selectedStudent?.name}</h2>
          <button
            onClick={handleNextStudent}
            disabled={
              selectedStudentIndex + 1 ===
                studentsListData[0]?.enrollments.length || isLoading
            }
          >
            <Icon path={mdiChevronRight} size={2} />
          </button>
        </div>

        {!isLoading ? (
          <>
            <div className="drawerFilter">
              <strong>{t(`${prefix}.detailsAttendance`)}</strong>
              <SelectStateContainer maxWidth="147px">
                <select
                  value={selectedQuarter}
                  onChange={(e) =>
                    setSelectedQuarter(Number(e.currentTarget.value))
                  }
                >
                  {quarters.map((quarter: any) => (
                    <option value={quarter.id} key={quarter.id}>
                      {quarter.name}
                    </option>
                  ))}
                </select>
              </SelectStateContainer>
            </div>

            <div className="listData">
              <div className="listFilter">
                <strong onClick={handleFilterByComponents}>
                  {t(`${prefix}.compsFilter`)}
                  <Icon path={iconPath(componentsFilter)} size={1} />
                </strong>

                <strong onClick={handleFilterByAttendance}>
                  {t(`${prefix}.attendanceFilter`)}
                  <Icon path={iconPath(attendanceFilter)} size={1} />
                </strong>
              </div>

              {subjectsAttendance && !isLoading && (
                <>
                  {subjectsAttendance?.subjects.map((student, index) => (
                    <DrawerRowCollapse key={index} student={student} />
                  ))}

                  <div className="totalAbsences">
                    {selectedQuarter !== 0 && (
                      <div className="quarter">
                        <p>{t(`${prefix}.totalQuarter`)}</p>
                        <strong>
                          {subjectsAttendance.quarter_total === null
                            ? "XXX"
                            : subjectsAttendance.quarter_total}
                        </strong>
                      </div>
                    )}

                    <div className="anual">
                      <p>{t(`${prefix}.totalYear`)}</p>
                      <strong>{subjectsAttendance.total}</strong>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        ) : (
          <div className="loadingContent">
            <Loading />
          </div>
        )}
      </Drawer>
    </S.Container>
  );
};
