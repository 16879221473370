import { Section } from "components/organisms/Section";
import { GetCXUserJson } from "interfaces/constants";
import { EnrollmentInterface, EnrollmentSteps } from "interfaces/enrollment";
import * as S from "./styles";

interface IStepsProgress {
  steps: Array<EnrollmentSteps>;
  user: GetCXUserJson;
  enrollment: EnrollmentInterface;
}

export const StepsProgress = ({ steps, user, enrollment }: IStepsProgress) => {
  return (
    <S.Container>
      {steps?.map((step) => (
        <Section
          key={step.step}
          step={step}
          user={user}
          enrollment={enrollment}
        />
      ))}
    </S.Container>
  );
};
