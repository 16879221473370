// Interfaces
import { ITableHelper } from "./interface";

export const TableHelper = ({ secondForm, setSecondForm }: ITableHelper) => {
  const setEnrollment = (value: number) => {
    let selectedEnrollment = secondForm.enrollments;
    if (secondForm.enrollments.includes(value)) {
      selectedEnrollment = selectedEnrollment.filter(
        (enrollment) => enrollment !== value
      );
    } else {
      selectedEnrollment.push(value);
    }
    setSecondForm({ ...secondForm, enrollments: selectedEnrollment });
  };
  return { setEnrollment };
};
