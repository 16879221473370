import styled from "styled-components";
import * as colors from "styles/colors";

export const Container = styled.div`
  max-width: 1120px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;

  h1 {
    text-align: center;

    @media (max-width: 768px) {
      text-align: left;
    }
  }

  .content {
    width: 100%;
    margin-bottom: 28px;

    h5 {
      font-weight: 700;
    }
  }

  .orderHistory {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;

    @media (max-width: 1024px) {
      grid-template-columns: 1fr;
    }
  }

  div.nofoundDiv {
    width: 100%;
    display: flex;
    justify-content: center;

    strong.nofound {
      text-align: center;
      margin: 64px 0;
      color: ${colors.GRAY_SCALE};

      @media (max-width: 1024px) {
        text-align: center;
      }
    }
  }

  @media (max-width: 1240px) {
    margin: 0 16px;
  }
`;
