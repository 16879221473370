import { IGetClassCouncilStudentsResponse } from "services/grades.service";
import { TableContainer } from "./styles";
import { StudentsRow } from "../StudentsRow";
import { useClassCouncil } from "../../ClassCouncil.helper";
import { Button } from "@escolamais-ui-v2/react";
import SaveOutlinedIcon from "@material-ui/icons/SaveOutlined";
import { ButtonContainer } from "../../styles";
import { useTranslation } from "react-i18next";

interface IStudentsTableProps {
  students: IGetClassCouncilStudentsResponse[] | undefined;
}

export const StudentsTable = ({ students }: IStudentsTableProps) => {
  const { t } = useTranslation();
  const prefix = "pages.AP.classCouncil.studentsTable";

  const {
    onChangeStudentApprovedStatus,
    modifiedStudentsStatuses,
    onSaveStudentsApprovedStatuses,
  } = useClassCouncil();

  const handleSaveStudentsApprovedStatuses = () => {
    onSaveStudentsApprovedStatuses();
  };

  return (
    <>
      <TableContainer>
        <table>
          <thead>
            <tr>
              <th>{t(`${prefix}.cycle`)}</th>
              <th>{t(`${prefix}.students`)}</th>
              <th>{t(`${prefix}.ra`)}</th>
              <td>
                <th>{t(`${prefix}.approved`)}</th>
                <th>{t(`${prefix}.retained`)}</th>
              </td>
            </tr>
          </thead>
          <tbody>
            {students?.map((student) => (
              <StudentsRow
                key={student.ra}
                student={student}
                onChangeStudentApprovedStatus={onChangeStudentApprovedStatus}
              />
            ))}
          </tbody>
        </table>
      </TableContainer>
      <ButtonContainer>
        <Button
          size="large"
          buttonText="Salvar"
          appearance="primary"
          icon={<SaveOutlinedIcon width={24} height={24} />}
          disabled={modifiedStudentsStatuses.length === 0}
          onClick={handleSaveStudentsApprovedStatuses}
        />
      </ButtonContainer>
    </>
  );
};
