import { useCallback, useState, useEffect } from "react";
import { GradeAPI } from "services/api";
import { useBulletin } from "context/Bulletin";
import {
  EvaluationTypesProps,
  GetSchoolEvaluationTypesProps,
  GetStudentEvaluationsProps,
  StudentEvaluationsProps,
} from "./dashboard.type";

export const changeLevelName = (name: string) => {
  switch (name) {
    case "Fundamental 1":
      return "EF 1";
    case "Fundamental 2":
      return "EF 2";
    case "Médio":
      return "EM";
    default:
      return "error";
  }
};

export const useDashboard = () => {
  const { reqTable } = useBulletin();
  const [evaluationLoaded, setEvaluationLoaded] = useState(false);
  const [studentEvaluations, setStudentEvaluations] = useState(false);
  const [evaluationTypes, setEvaluationTypes] =
    useState<EvaluationTypesProps[]>();
  const [studentList, setStudentList] = useState<StudentEvaluationsProps>();
  const [selectedEvaluation, setSelectedEvaluation] = useState<
    number | undefined
  >(undefined);

  const [loadingSchoolEvaluationTypes, setLoadingSchoolEvaluationTypes] =
    useState(false);
  const [loadingStudentEvaluations, setLoadingStudentEvaluations] =
    useState(false);

  const getSchoolEvaluationTypes = useCallback(
    async ({ gradeGroup, quarterId }: GetSchoolEvaluationTypesProps) => {
      try {
        setLoadingSchoolEvaluationTypes(true);
        setEvaluationLoaded(false);
        const response = await GradeAPI.get(
          `classes/school_report_types_evaluation?gradegroup=${gradeGroup}&quarter_id=${quarterId}`
        );

        setEvaluationTypes(response.data);

        setSelectedEvaluation(response.data[0]?.id);
        setEvaluationLoaded(true);
        setLoadingSchoolEvaluationTypes(false);
      } catch (error) {
        throw new Error("error", error);
      }
    },
    []
  );

  const getStudentEvaluations = useCallback(
    async ({
      gradeGroup,
      quarterId,
      evaluationType,
    }: GetStudentEvaluationsProps) => {
      if (gradeGroup && quarterId && evaluationType) {
        try {
          setLoadingStudentEvaluations(true);
          setStudentEvaluations(false);
          const response = await GradeAPI.get(
            `classes/student_evaluations?quarter_id=${quarterId}&gradegroup=${gradeGroup}&evaluation_type=${evaluationType}`
          );
          setStudentList(response.data);
          setStudentEvaluations(true);
          setLoadingStudentEvaluations(false);
        } catch (error) {
          throw new Error("error", error);
        }
      }
    },
    []
  );

  useEffect(() => {
    if (reqTable.gradegroupId && reqTable.quarterId && !selectedEvaluation) {
      getSchoolEvaluationTypes({
        gradeGroup: reqTable.gradegroupId,
        quarterId: reqTable.quarterId,
      });
    }
  }, [
    getSchoolEvaluationTypes,
    reqTable.gradegroupId,
    reqTable.quarterId,
    selectedEvaluation,
  ]);

  return {
    getSchoolEvaluationTypes,
    evaluationLoaded,
    evaluationTypes,
    getStudentEvaluations,
    studentList,
    selectedEvaluation,
    setSelectedEvaluation,
    studentEvaluations,
    loadingSchoolEvaluationTypes,
    loadingStudentEvaluations,
  };
};
