// React
import { useState } from "react";
// Styles
import * as S from "./style";
// Components
import { ButtonIconFontNormal, Loading, SecondPagination } from "components";
import { Table } from "./components/Table";
import { ModalExitReport } from "./components/Modal";
// Context
// Libs
import { TextField } from "@escolamais-ui-v2/react";
import { useTranslation } from "react-i18next";
// Interfaces
import { IForm, IModalForm } from "./interface";
import { StudentInterface } from "interfaces/students";
// Helperx
import { ExitReportHelper } from "./helpers";
// Icon
import DownloadSVG from "static/img/donwloadPurple.svg";
// Gif
import loadingGif from "static/img/loading.gif";
import { useAtomValue } from "jotai";
import { authUserAtom } from "jotai/authUser";

export const ExitReport = () => {
  const { t } = useTranslation();
  const prefix = "pages.EDF.exitReport";

  const authUser = useAtomValue(authUserAtom);

  // Form
  const [form, setForm] = useState<IForm>({
    student_name: "",
    unit: authUser.unit === 4 ? 0 : authUser.unit, // Não seta se for matriz
    exit_authorization: 0,
  });

  // Modal
  const [modal, setModal] = useState(false);
  const [modalOption, setModalOption] = useState<number>();
  const [modalForm, setModalForm] = useState<IModalForm>({
    name: "",
    kinship: "",
    cpf: "",
    phone: "",
    escort: 0,
  });

  // Student
  const [studentSelected, setStudentSelected] =
    useState<StudentInterface | null>(null);

  const {
    pagination,
    loading,
    error,
    units,
    exitPermit,
    submit,
    studentList,
    setModalFormProps,
    studentListResults,
    download,
    handleDeletEscort,
    handleRegisterEscort,
    handleEditEscort,
    Offset,
    setPagination,
  } = ExitReportHelper({
    form,
    modalForm,
    studentSelected,
    setModalForm,
    setModal,
  });

  if (loading) {
    return (
      <S.LoadContainer>
        <h4>{t(`${prefix}.loading`)}</h4>
        <Loading />
      </S.LoadContainer>
    );
  }

  if (error && !loading) {
    return (
      <S.LoadContainer>
        <div>{t(`${prefix}.error`)}</div>
      </S.LoadContainer>
    );
  }

  if (units && exitPermit) {
    return (
      <S.ExitReportStyled>
        <div className="body">
          <h1>{t(`${prefix}.title`)}</h1>
          <form>
            <div className="inputText">
              <TextField
                appearance="default"
                backgroundColor="#FFF"
                borderColor="#999999"
                id="inputPrimary"
                label={t(`${prefix}.inputTextLabel`)}
                labelColor="#0D0D0D"
                leftIconColor="#999999"
                onChange={(e) => {
                  setForm({
                    ...form,
                    student_name: e.target.value,
                  });
                }}
                onChangeText={() => {}}
                placeholder={t(`${prefix}.inputTextPlaceholder`)}
                textColor="#0D0D0D"
                width={400}
                withLeftIcon
              />
            </div>

            <div className="select">
              <label>{t(`${prefix}.selectLabelUnit`)}</label>
              <select
                onChange={(e) => {
                  setForm({
                    ...form,
                    unit: parseInt(e.target.value),
                  });
                }}
                name="enrollmentType"
                className={form.unit ? "hasValue" : ""}
                value={form.unit}
              >
                <option value={0}>{t(`${prefix}.selectDefault`)}</option>
                {units?.map((unit) => {
                  return (
                    <option value={unit.id} key={unit.id}>
                      {unit.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="select">
              <label>{t(`${prefix}.selectLabelExitPermit`)}</label>
              <select
                onChange={(e) => {
                  setForm({
                    ...form,
                    exit_authorization: parseInt(e.target.value),
                  });
                }}
                name="enrollmentType"
                className={form.exit_authorization ? "hasValue" : ""}
              >
                <option value="">{t(`${prefix}.selectDefault`)}</option>
                {exitPermit?.results.map((exit) => {
                  return (
                    <option value={exit.value} key={exit.value}>
                      {exit.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </form>
          {studentList && studentList.count > 0 ? (
            <div className="button">
              <ButtonIconFontNormal
                height={submit ? "25" : ""}
                type="button"
                icon={submit ? loadingGif : DownloadSVG}
                onClick={() => download()}
                disabled={submit}
              >
                <span className="buttonName">
                  {t(`${prefix}.downloadButton`)}
                </span>
              </ButtonIconFontNormal>
            </div>
          ) : null}

          {submit && !studentList ? (
            <div className="newSearch">
              <Loading />
            </div>
          ) : (
            <>
              {studentList && (
                <>
                  <Table
                    exitPermit={exitPermit}
                    studentList={studentList}
                    modal={modal}
                    modalForm={modalForm}
                    studentSelected={studentSelected}
                    setModal={setModal}
                    setModalForm={setModalForm}
                    setModalOption={setModalOption}
                    remove={handleDeletEscort}
                    setStudentSelected={setStudentSelected}
                    studentListResults={studentListResults}
                  />
                  <div className="pagination">
                    <SecondPagination
                      previous={pagination.previous!}
                      count={pagination.count}
                      next={pagination.next!}
                      page={pagination.page}
                      Offset={Offset}
                      firstStudent={pagination.firstStudent}
                      lastStudent={pagination.lastStudent}
                    />
                  </div>
                </>
              )}

              {modal && modalOption && (
                <ModalExitReport
                  modal={modal}
                  modalOption={modalOption}
                  modalForm={modalForm}
                  setModalFormProps={setModalFormProps}
                  setModal={setModal}
                  handleRegisterEscort={handleRegisterEscort}
                  handleEditEscort={handleEditEscort}
                  setModalForm={setModalForm}
                />
              )}
            </>
          )}
        </div>
      </S.ExitReportStyled>
    );
  }
};
