import { SRedirect } from "./style";

export const RedirectWithContract = () => {
  return (
    <SRedirect>
      <div>
        <span>O portal de serviços da Escola Mais mudou.</span>
        <h4>
          <a
            href="http://www.escolamais.com/portal"
            target="_blank"
            rel="noopener noreferrer"
          >
            Conheça o novo Portal da Família:{" "}
            <span>www.escolamais.com/portal.</span>
          </a>
        </h4>
        <div>
          <span>Responsáveis Financeiros cadastrados já podem acessar:</span>
          <span>
            - <strong>O usuário é o seu CPF</strong>, somente números, sem
            pontos ou traços.
          </span>
          <span>
            - <strong>A senha inicial é 123456</strong>, você deverá trocar essa
            senha no primeiro acesso.
          </span>
        </div>

        <button>
          <a
            href="http://www.escolamais.com/portal"
            target="_blank"
            rel="noopener noreferrer"
          >
            Ir para o Portal da Família
          </a>
        </button>
      </div>
    </SRedirect>
  );
};
