import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ButtonCard } from "components/atoms/ButtonCard";
import * as S from "./style";
import { IsDevelopmentEnvironment } from "helpers/helpers";

const TRANSLATION_PREFIX = "pages.CORE.home.systemAdmin";

export const SystemAdminHome = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <S.Container>
      <ButtonCard
        onClick={() => history.push("/responsavel/busca")}
        text={t(`${TRANSLATION_PREFIX}.searchGuardianOrStudent`)}
      />
      <ButtonCard
        onClick={() => history.push("/responsavel/busca-por-cpf")}
        text={t(`${TRANSLATION_PREFIX}.bookSpot`)}
      />
      <ButtonCard
        onClick={() => history.push("/responsavel/Consultar/documentos")}
        text={t(`${TRANSLATION_PREFIX}.consultDocuments`)}
      />
      <ButtonCard
        onClick={() => history.push("/gerenciar/matriculas")}
        text={t(`${TRANSLATION_PREFIX}.manageEnrollmentProcessQuery`)}
      />
      <ButtonCard
        onClick={() => history.push("/lista-espera")}
        text={t(`${TRANSLATION_PREFIX}.waitingList`)}
      />
      <ButtonCard
        onClick={() => history.push("/alimentacao/cancelar-pacote-alimentacao")}
        text={t(`${TRANSLATION_PREFIX}.cancelFoodPackage`)}
      />
      <ButtonCard
        onClick={() => history.push("/alimentacao/lancar-credito-alimentacao")}
        text={t(`${TRANSLATION_PREFIX}.launchFoodCredit`)}
      />
      <ButtonCard
        onClick={() => history.push("/alimentacao/consultar-alimentacao")}
        text={t(`${TRANSLATION_PREFIX}.consultSinglePurchase`)}
      />
      <ButtonCard
        onClick={() => history.push("/dashboard")}
        text={t(`${TRANSLATION_PREFIX}.dashboard`)}
      />
      <ButtonCard
        onClick={() => history.push("/consulta-diario-de-classe")}
        text={t(`${TRANSLATION_PREFIX}.rollSearch`)}
      />
      <ButtonCard
        onClick={() => history.push("/gerenciar/turmas")}
        text={t(`${TRANSLATION_PREFIX}.classAssignment`)}
      />
      <ButtonCard
        onClick={() => history.push("/lancamento/componentes")}
        text={t(`${TRANSLATION_PREFIX}.releases`)}
      />
      <ButtonCard
        onClick={() => history.push("/enturmamento")}
        text={t(`${TRANSLATION_PREFIX}.classesManagement`)}
      />
      <ButtonCard
        onClick={() => history.push("/consulta-plano-pagamento")}
        text={t(`${TRANSLATION_PREFIX}.paymentPlanInquiry`)}
      />
      <ButtonCard
        onClick={() => history.push("/exportar-totvs")}
        text={t(`${TRANSLATION_PREFIX}.exportTotvsData`)}
      />
      <ButtonCard
        onClick={() => history.push("/gerar-arquivo-para-cracha")}
        text={t(`${TRANSLATION_PREFIX}.generateBadgeFile`)}
      />
      <ButtonCard
        onClick={() => history.push("/cadastro-dias-uteis")}
        text={t(`${TRANSLATION_PREFIX}.businessDaysRegistration`)}
      />
      <ButtonCard
        onClick={() => history.push("/cadastro-vigencia-pacote-de-alimentacao")}
        text={t(`${TRANSLATION_PREFIX}.registrationValidityFeedingDays`)}
      />
      <ButtonCard
        onClick={() => history.push("/gestao-itens-alimentacao")}
        text={t(`${TRANSLATION_PREFIX}.manageFoodItems`)}
      />
      <ButtonCard
        onClick={() => history.push("/autorizacao-de-saida")}
        text={t(`${TRANSLATION_PREFIX}.exitReport`)}
      />
      <ButtonCard
        onClick={() => history.push("/registro-de-alimentacao")}
        text={t(`${TRANSLATION_PREFIX}.singleFoodRegistration`)}
      />
      <ButtonCard
        onClick={() => history.push("/relatorio-de-alimentacao")}
        text={t(`${TRANSLATION_PREFIX}.feedReport`)}
      />
      <ButtonCard
        onClick={() => history.push("/historico-dos-boletins")}
        text={t(`${TRANSLATION_PREFIX}.boletinsHistory`)}
      />
      <ButtonCard
        onClick={() => history.push("/diario-de-classe")}
        text={t(`${TRANSLATION_PREFIX}.classDiary`)}
      />
      <ButtonCard
        onClick={() => history.push("/buscar-vagas")}
        text={t(`${TRANSLATION_PREFIX}.searchStudentVacancies`)}
      />
      <ButtonCard
        onClick={() => history.push("/consultar-pedidos-alimentacao")}
        text={t(`${TRANSLATION_PREFIX}.consultFoods`)}
      />
      {IsDevelopmentEnvironment() && (
        <ButtonCard
          onClick={() => history.push("/gerenciar-itens-alimentacao")}
          text={t(`${TRANSLATION_PREFIX}.manageDynamicFoodItems`)}
        />
      )}
      <ButtonCard
        onClick={() => history.push("/gerenciar-vagas")}
        text={t(`${TRANSLATION_PREFIX}.manageVacancies`)}
      />
      <ButtonCard
        onClick={() => history.push("/publicacao-boletim")}
        text={t(`${TRANSLATION_PREFIX}.publishBoletim`)}
      />
      <ButtonCard
        onClick={() => history.push("/roteiros-de-estudo/estudante")}
        text={t(`${TRANSLATION_PREFIX}.studyItinerary`)}
      />
      <ButtonCard
        onClick={() => history.push("/cadastrar-periodo-matricula-rematricula")}
        text={t(`${TRANSLATION_PREFIX}.periodEnrollmentReenrollment`)}
      />
      {IsDevelopmentEnvironment() && (
        <ButtonCard
          onClick={() => history.push("/conselho-classe")}
          text={t(`${TRANSLATION_PREFIX}.classCouncil`)}
        />
      )}
      <ButtonCard
        onClick={() => history.push("/plano-de-pagamento")}
        text={t(`${TRANSLATION_PREFIX}.paymentPlan`)}
      />
      {IsDevelopmentEnvironment() && (
        <ButtonCard
          onClick={() => history.push("/cadastro-responsavel")}
          text={t(`${TRANSLATION_PREFIX}.signup`)}
        />
      )}
    </S.Container>
  );
};
