import styled from "styled-components";
import * as colors from "styles/colors";

export const DiscountApprovalContainer = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: ${({ theme }) => theme.space[4]};

  display: flex;
  flex-direction: column;

  .title {
    font-size: 40px; // TODO: nao tem na lib
    font-weight: 600; // TODO: nao tem na lib
    line-height: normal;
    color: ${({ theme }) => theme.colors.primaryDefault};
    margin-bottom: ${({ theme }) => theme.space[4]};
  }

  .subtitle {
    font-size: ${({ theme }) => theme.fontSizes.web.h3};
    font-weight: 600;
    line-height: normal;
    color: ${({ theme }) => theme.colors.brandingOrange};
    margin: ${({ theme }) => theme.space[6]} 0;
  }
`;

export const FiltersContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${({ theme }) => theme.space[4]};

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const SearchBarInput = styled.div`
  height: 37px;
  border: 2px solid ${({ theme }) => theme.colors.greyScale40};
  display: flex;
  align-items: center;

  gap: ${({ theme }) => theme.space[2]};

  svg {
    margin-left: ${({ theme }) => theme.space[2]};
    color: ${({ theme }) => theme.colors.greyScale40};
  }

  input {
    border: none;
    outline: none;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin: 16px 0.3rem 0 0;
`;

export const VendorSelect = styled.select`
  border: 2px solid ${({ theme }) => theme.colors.greyScale40};
  background: transparent;
  display: flex;

  height: 38px;
`;

export const InputLabel = styled.span`
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: ${({ theme }) => theme.fontSizes.web.sm};
  line-height: ${({ theme }) => theme.lineHeights.normal};
  color: ${({ theme }) => theme.colors.greyScale95};

  text-transform: uppercase;
`;

export const TableContainer = styled.div`
  margin-top: ${({ theme }) => theme.space[5]};

  @media screen and (max-width: 960px) {
    overflow: scroll;
  }

  h2 {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: ${({ theme }) => theme.fontSizes.web.h4};
    color: ${({ theme }) => theme.colors.greyScale95};
  }

  table {
    margin-top: ${({ theme }) => theme.space[5]};
    display: flex;
    flex-direction: column;

    tr,
    td,
    th {
      display: flex;
      flex: 1;
    }

    thead {
      border-bottom: 1px solid ${colors.GRAY_SCALE};
    }

    tr {
      & > th:first-child,
      & > td:first-child {
        padding: ${({ theme }) => theme.space[2]};
        ${({ theme }) => theme.space[4]};
      }
    }

    td,
    th {
      padding: ${({ theme }) => theme.space[2]};
    }
  }
`;

export const ResultsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${({ theme }) => theme.space[8]} 0;

  h3 {
    font-size: ${({ theme }) => theme.fontSizes.web.h4};
    font-weight: 600; // TODO: nao tem na lib
    color: ${({ theme }) => theme.colors.greyScale95};
    margin-top: ${({ theme }) => theme.space[6]};
  }

  button {
    &:disabled {
      color: ${({ theme }) => theme.colors.greyScale40};
    }
  }
`;

export const StudentDiscountRow = styled.div`
  border-bottom: 1px solid ${colors.NEW_GRAY_THREE};

  tr,
  label {
    display: flex;
    align-items: center;
  }
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: ${({ theme }) => theme.space[8]} 0;
`;
