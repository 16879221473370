import styled from "styled-components";
import * as colors from "styles/colors";

export const BankSlipStyled = styled.div`
  div.paymentDiv {
    display: flex;
    align-items: center;

    margin-top: 0.3em;
    padding: 1em;
    width: 100%;
    height: 3.125em;

    color: ${colors.NEW_PURPLE};

    border: 2px solid ${colors.NEW_GRAY_ONE};
    cursor: pointer;
  }
`;
