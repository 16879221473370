/* eslint-disable array-callback-return */
/* eslint-disable no-sequences */
import { useState } from "react";

import * as Style from "./style";
import * as Props from "./type";

export const SelectInput = ({
  children,
  name,
  value,
  label,
  onChange,
  fullBorder,
  gayScale,
}: Props.SelectInterface) => {
  return (
    <div>
      {label && <Style.Label>{label}</Style.Label>}
      <Style.SelectStyled
        name={name}
        value={value}
        onChange={onChange}
        fullBorder={fullBorder}
        gayScale={gayScale}
        label={label ? true : false}
      >
        {children}
      </Style.SelectStyled>
    </div>
  );
};

// Input para o react-final-form
export const SelectInputFinalForm: React.FC<Props.SelectFinalFormType> = ({
  label,
  input,
  meta,
  fullBorder,
  gayScale,
  width,
  height,
  ...rest
}: Props.SelectFinalFormType) => (
  <div>
    {label && <Style.Label>{label}</Style.Label>}
    <Style.SelectStyled
      {...input}
      {...rest}
      label={label}
      gayScale={gayScale}
      fullBorder={fullBorder}
      width={width}
      height={height}
    />
  </div>
);

// Select State
export const SelectBulletin: React.FC<Props.SelectStateProps> = ({
  name,
  options,
  defaultValue,
  placeholder,
  maxWidth,
  onAction,
}) => {
  const [selected, setSelected] = useState<string>(placeholder);

  return (
    <Style.SelectStateContainer maxWidth={maxWidth}>
      <select
        value={defaultValue}
        onChange={(e: any) => (
          onAction(e.currentTarget.value), setSelected(e.currentTarget.value)
        )}
      >
        <Style.SelectStateOption value={defaultValue} hidden>
          {defaultValue}
        </Style.SelectStateOption>
        {options &&
          options.map((option) => (
            <Style.SelectStateOption key={option} value={option}>
              {option}
            </Style.SelectStateOption>
          ))}
      </select>
    </Style.SelectStateContainer>
  );
};
