import styled, { css, keyframes } from "styled-components";
import * as colors from "styles/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

interface CollapseStyleProps {
  active: boolean;
}

const effect = keyframes`
  from {
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
  }

  to {
    max-height: 1000px;
    transition: max-height 0.25s ease-in;
  }
`;

export const CollapseContent = styled.div<CollapseStyleProps>`
  ${(props) =>
    props.active
      ? css`
          animation: ${effect} 2s linear;
          margin-bottom: 16px;
        `
      : css`
          overflow: hidden;
          animation: ${effect} 2s linear;
          margin-bottom: 16px;
        `}
`;

export const CollapseButton = styled.button`
  color: ${colors.NEW_PURPLE};
  text-decoration: underline;
  border: none;
  background: transparent;
  text-transform: uppercase;
  font-weight: 700;
`;
