import { toastError, toastSuccess } from "components/Toast";
import { YearsInterface } from "interfaces/constants";
import { GetUnitsGradesResponse } from "interfaces/unit";
import { useEffect, useReducer } from "react";
import {
  ICreateNewEnrollment,
  createNewEnrollment,
} from "services/enrollment.service";
import { INewGuardianSignupResponse } from "services/guardian.service";
import { getYear } from "services/multipurpose";
import { IGetScholarships, getScholarships } from "services/payment.service";
import {
  GetAvailableStartMonths,
  GetAvailableStartMonthsProps,
  getAvailableStartMonths,
} from "services/plan.service";
import { ICreateStudentResponse } from "services/student.service";
import { getUnitsGradesSchoolPlaces } from "services/units.service";
import { VendorListResponse, getVendorList } from "services/users.service";
import HTTP_STATUS from "http-status-codes";
import { useHistory } from "react-router-dom";

interface ICreateEnrollmentRequest {
  student: ICreateStudentResponse;
  guardian: INewGuardianSignupResponse;
}

interface SelectedYearAndCycle {
  educational_level_id: number;
  gradeId: number;
}

interface DiscountForm {
  scholarship_percentage: string;
  scholarship_percentage_monthly: string;
  scholarship_percentage_material: string;
}

export const enum VacancyDataActionTypesEnum {
  SET_VENDORS = "SET_VENDORS",
  SET_YEARS = "SET_YEARS",
  SET_UNITS_YEAR_AND_CYCLE = "SET_UNITS_YEAR_AND_CYCLE",
  SET_SELECTED_VENDOR = "SET_SELECTED_VENDOR",
  SET_SELECTED_YEAR = "SET_SELECTED_YEAR",
  SET_SELECTED_UNIT = "SET_SELECTED_UNIT",
  SET_SELECTED_YEAR_AND_CYCLE = "SET_SELECTED_YEAR_AND_CYCLE",
  SET_SELECTED_SCHOLARSHIP = "SET_SELECTED_SCHOLARSHIP",
  SET_SELECTED_INITIAL_MONTH = "SET_SELECTED_INITIAL_MONTH",
  SET_FILTERS_LOADING = "SET_FILTERS_LOADING",
  SET_PLAN_CONFIG_LOADING = "SET_PLAN_CONFIG_LOADING",
  SET_PLAN_CONFIG = "SET_PLAN_CONFIG",
  SET_DISCOUNT_FORM = "SET_DISCOUNT_FORM",
  SET_CREATE_ENROLLMENT_REQUEST = "SET_CREATE_ENROLLMENT_REQUEST",
}

export interface IVacancyDataState {
  vendors: VendorListResponse[] | undefined;
  years: YearsInterface[] | undefined;
  unitsYearAndCycle: GetUnitsGradesResponse[] | undefined;
  selectedVendor: VendorListResponse | undefined;
  selectedYear: string | undefined;
  selectedUnit: GetUnitsGradesResponse | undefined;
  selectedYearAndCycle: SelectedYearAndCycle | undefined;
  selectedScholarship: IGetScholarships | undefined;
  selectedInitialMonth: GetAvailableStartMonths | undefined;
  loaders: {
    isFiltersLoading: boolean;
    isPlanConfigLoading: boolean;
  };
  planConfig: {
    availableStartMonths: GetAvailableStartMonths[] | undefined;
    scholarships: IGetScholarships[] | undefined;
  };
  discountForm: DiscountForm;
  createEnrollmentRequest: ICreateEnrollmentRequest | undefined;
}

export type VacancyDataActions =
  | {
      type: VacancyDataActionTypesEnum.SET_VENDORS;
      payload: VendorListResponse[] | undefined;
    }
  | {
      type: VacancyDataActionTypesEnum.SET_YEARS;
      payload: YearsInterface[] | undefined;
    }
  | {
      type: VacancyDataActionTypesEnum.SET_UNITS_YEAR_AND_CYCLE;
      payload: GetUnitsGradesResponse[] | undefined;
    }
  | {
      type: VacancyDataActionTypesEnum.SET_SELECTED_VENDOR;
      payload: VendorListResponse | undefined;
    }
  | {
      type: VacancyDataActionTypesEnum.SET_SELECTED_YEAR;
      payload: string | undefined;
    }
  | {
      type: VacancyDataActionTypesEnum.SET_SELECTED_UNIT;
      payload: GetUnitsGradesResponse | undefined;
    }
  | {
      type: VacancyDataActionTypesEnum.SET_SELECTED_YEAR_AND_CYCLE;
      payload: SelectedYearAndCycle | undefined;
    }
  | {
      type: VacancyDataActionTypesEnum.SET_SELECTED_SCHOLARSHIP;
      payload: IGetScholarships | undefined;
    }
  | {
      type: VacancyDataActionTypesEnum.SET_SELECTED_INITIAL_MONTH;
      payload: GetAvailableStartMonths | undefined;
    }
  | {
      type: VacancyDataActionTypesEnum.SET_FILTERS_LOADING;
      payload: boolean;
    }
  | {
      type: VacancyDataActionTypesEnum.SET_PLAN_CONFIG_LOADING;
      payload: boolean;
    }
  | {
      type: VacancyDataActionTypesEnum.SET_PLAN_CONFIG;
      payload: {
        availableStartMonths: GetAvailableStartMonths[] | undefined;
        scholarships: IGetScholarships[] | undefined;
      };
    }
  | {
      type: VacancyDataActionTypesEnum.SET_DISCOUNT_FORM;
      payload: DiscountForm;
    }
  | {
      type: VacancyDataActionTypesEnum.SET_CREATE_ENROLLMENT_REQUEST;
      payload: ICreateEnrollmentRequest | undefined;
    };

const INITIAL_STATE: IVacancyDataState = {
  vendors: undefined,
  years: undefined,
  unitsYearAndCycle: undefined,
  selectedVendor: undefined,
  selectedYear: undefined,
  selectedUnit: undefined,
  selectedYearAndCycle: undefined,
  selectedScholarship: undefined,
  selectedInitialMonth: undefined,
  loaders: {
    isFiltersLoading: false,
    isPlanConfigLoading: false,
  },
  planConfig: {
    availableStartMonths: undefined,
    scholarships: undefined,
  },
  discountForm: {
    scholarship_percentage: "",
    scholarship_percentage_monthly: "",
    scholarship_percentage_material: "",
  },
  createEnrollmentRequest: undefined,
};

const vacancyDataReducer = (
  state: IVacancyDataState,
  action: VacancyDataActions
): IVacancyDataState => {
  switch (action.type) {
    case VacancyDataActionTypesEnum.SET_VENDORS: {
      return {
        ...state,
        vendors: action.payload,
      };
    }

    case VacancyDataActionTypesEnum.SET_YEARS: {
      return {
        ...state,
        years: action.payload,
      };
    }

    case VacancyDataActionTypesEnum.SET_UNITS_YEAR_AND_CYCLE: {
      return {
        ...state,
        unitsYearAndCycle: action.payload,
      };
    }

    case VacancyDataActionTypesEnum.SET_SELECTED_VENDOR: {
      return {
        ...state,
        selectedVendor: action.payload,
      };
    }

    case VacancyDataActionTypesEnum.SET_SELECTED_YEAR: {
      return {
        ...state,
        selectedYear: action.payload,
      };
    }

    case VacancyDataActionTypesEnum.SET_SELECTED_UNIT: {
      return {
        ...state,
        selectedUnit: action.payload,
      };
    }

    case VacancyDataActionTypesEnum.SET_SELECTED_YEAR_AND_CYCLE: {
      return {
        ...state,
        selectedYearAndCycle: action.payload,
      };
    }

    case VacancyDataActionTypesEnum.SET_SELECTED_SCHOLARSHIP: {
      return {
        ...state,
        selectedScholarship: action.payload,
      };
    }

    case VacancyDataActionTypesEnum.SET_SELECTED_INITIAL_MONTH: {
      return {
        ...state,
        selectedInitialMonth: action.payload,
      };
    }

    case VacancyDataActionTypesEnum.SET_FILTERS_LOADING: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          isFiltersLoading: action.payload,
        },
      };
    }

    case VacancyDataActionTypesEnum.SET_PLAN_CONFIG_LOADING: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          isPlanConfigLoading: action.payload,
        },
      };
    }

    case VacancyDataActionTypesEnum.SET_PLAN_CONFIG: {
      return {
        ...state,
        planConfig: {
          availableStartMonths: action.payload.availableStartMonths,
          scholarships: action.payload.scholarships,
        },
      };
    }

    case VacancyDataActionTypesEnum.SET_DISCOUNT_FORM: {
      return {
        ...state,
        discountForm: action.payload,
      };
    }

    case VacancyDataActionTypesEnum.SET_CREATE_ENROLLMENT_REQUEST: {
      return {
        ...state,
        createEnrollmentRequest: action.payload,
      };
    }
  }
};

export const useVacancyData = () => {
  const history = useHistory();

  const [state, dispatch] = useReducer(vacancyDataReducer, INITIAL_STATE);

  // filters
  const getVendors = async () => {
    try {
      const vendors = await getVendorList();
      dispatch({
        type: VacancyDataActionTypesEnum.SET_VENDORS,
        payload: vendors?.data,
      });

      dispatch({
        type: VacancyDataActionTypesEnum.SET_SELECTED_VENDOR,
        payload: vendors?.data[0],
      });
    } catch (error) {
      toastError("Não foi possível carregar os vendedores");
    }
  };

  const getYears = async () => {
    try {
      const years = await getYear({ more_years: 1 });
      const yearsData = years?.data?.results?.reverse();
      dispatch({
        type: VacancyDataActionTypesEnum.SET_YEARS,
        payload: yearsData,
      });

      dispatch({
        type: VacancyDataActionTypesEnum.SET_SELECTED_YEAR,
        payload: yearsData[0].value.toString(),
      });
    } catch (error) {
      toastError("Não foi possível carregar os anos");
    }
  };

  const getUnitsYearAndCycle = async (year: string) => {
    dispatch({
      type: VacancyDataActionTypesEnum.SET_FILTERS_LOADING,
      payload: true,
    });

    try {
      const units: GetUnitsGradesResponse[] = await (
        await getUnitsGradesSchoolPlaces(year, true)
      )?.data;

      dispatch({
        type: VacancyDataActionTypesEnum.SET_UNITS_YEAR_AND_CYCLE,
        payload: units,
      });

      dispatch({
        type: VacancyDataActionTypesEnum.SET_SELECTED_UNIT,
        payload: units[0],
      });

      dispatch({
        type: VacancyDataActionTypesEnum.SET_SELECTED_YEAR_AND_CYCLE,
        payload: {
          educational_level_id: units[0].unit.educational_level[0].id,
          gradeId: units[0].unit.educational_level[0].grades[0].grade,
        },
      });

      dispatch({
        type: VacancyDataActionTypesEnum.SET_FILTERS_LOADING,
        payload: false,
      });
    } catch (error) {
      toastError("Não foi possível carregar as unidades, ano e ciclo");
    }
  };

  const getInitialFilters = async () => {
    dispatch({
      type: VacancyDataActionTypesEnum.SET_FILTERS_LOADING,
      payload: true,
    });
    await getVendors();
    await getYears();

    dispatch({
      type: VacancyDataActionTypesEnum.SET_FILTERS_LOADING,
      payload: false,
    });
  };

  // plan configs
  const getScholarshipsList = async () => {
    dispatch({
      type: VacancyDataActionTypesEnum.SET_PLAN_CONFIG_LOADING,
      payload: true,
    });

    try {
      const scholarships = await getScholarships();
      dispatch({
        type: VacancyDataActionTypesEnum.SET_PLAN_CONFIG,
        payload: {
          ...state.planConfig,
          scholarships: scholarships?.data,
        },
      });

      dispatch({
        type: VacancyDataActionTypesEnum.SET_SELECTED_SCHOLARSHIP,
        payload: scholarships?.data[0],
      });

      if (scholarships?.data[0]) {
        dispatch({
          type: VacancyDataActionTypesEnum.SET_DISCOUNT_FORM,
          payload: {
            scholarship_percentage: String(
              scholarships?.data[0]?.scholarship_percentage.toString() ?? ""
            ),
            scholarship_percentage_monthly: String(
              scholarships?.data[0]?.scholarship_percentage_monthly.toString() ??
                ""
            ),
            scholarship_percentage_material: String(
              scholarships?.data[0]?.scholarship_percentage_material.toString() ??
                ""
            ),
          },
        });
      }

      dispatch({
        type: VacancyDataActionTypesEnum.SET_PLAN_CONFIG_LOADING,
        payload: false,
      });
    } catch (error) {
      toastError("Não foi possível carregar as bolsas");
    }
  };

  const getInitialMonthForConfigurationPlan = async ({
    educational_level,
    unit,
    year,
  }: GetAvailableStartMonthsProps) => {
    dispatch({
      type: VacancyDataActionTypesEnum.SET_PLAN_CONFIG_LOADING,
      payload: true,
    });

    try {
      const startMonths = await getAvailableStartMonths({
        educational_level,
        unit,
        year,
      });
      dispatch({
        type: VacancyDataActionTypesEnum.SET_PLAN_CONFIG,
        payload: {
          ...state.planConfig,
          availableStartMonths: startMonths?.data,
        },
      });

      dispatch({
        type: VacancyDataActionTypesEnum.SET_SELECTED_INITIAL_MONTH,
        payload: startMonths?.data[0],
      });

      dispatch({
        type: VacancyDataActionTypesEnum.SET_PLAN_CONFIG_LOADING,
        payload: false,
      });
    } catch (error) {
      toastError("Não foi possível carregar os meses disponíveis");
    }
  };

  // enrollment
  const createEnrollment = async (enrollment: ICreateEnrollmentRequest) => {
    if (
      state?.selectedYear &&
      state?.selectedUnit?.unit &&
      state?.selectedYearAndCycle?.gradeId &&
      state?.selectedScholarship?.id &&
      state?.discountForm?.scholarship_percentage &&
      state?.discountForm?.scholarship_percentage_monthly &&
      state?.discountForm?.scholarship_percentage_material &&
      state?.selectedInitialMonth?.start_month &&
      state?.selectedVendor?.id
    ) {
      const enrollmentRequest: ICreateNewEnrollment = {
        ano: Number(state?.selectedYear),
        relacao_responsavel_estudante_id:
          enrollment?.guardian?.graus_parentesco[0]?.id,
        unidade_id: state?.selectedUnit?.unit.id,
        segmento: state?.selectedYearAndCycle?.gradeId,
        ciclo_id: state?.selectedYearAndCycle?.educational_level_id,
        bolsa_id: state?.selectedScholarship?.id,
        desconto_reserva: Number(state?.discountForm?.scholarship_percentage),
        desconto_mensalidade: Number(
          state?.discountForm?.scholarship_percentage_monthly
        ),
        desconto_material: Number(
          state?.discountForm?.scholarship_percentage_material
        ),
        mes_primeira_cobranca: state?.selectedInitialMonth?.start_month,
        vendedor_id: state?.selectedVendor?.user,
        tipo: 1,
      };

      const enrollmentCreated = await createNewEnrollment(enrollmentRequest);

      if (enrollmentCreated?.status === HTTP_STATUS.CREATED) {
        toastSuccess("Matrícula criada com sucesso");
        history.push("/home");
      }
    }
  };

  useEffect(() => {
    getInitialFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (state.selectedYear) {
      getUnitsYearAndCycle(state.selectedYear);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.selectedYear]);

  useEffect(() => {
    if (state?.selectedUnit) {
      dispatch({
        type: VacancyDataActionTypesEnum.SET_SELECTED_YEAR_AND_CYCLE,
        payload: {
          educational_level_id: state.selectedUnit.unit.educational_level[0].id,
          gradeId: state.selectedUnit.unit.educational_level[0].grades[0].grade,
        },
      });
    }
  }, [state?.selectedUnit]);

  useEffect(() => {
    if (
      state?.selectedUnit &&
      state?.selectedYearAndCycle &&
      state?.selectedYear
    ) {
      getInitialMonthForConfigurationPlan({
        educational_level: state?.selectedYearAndCycle.educational_level_id,
        unit: state?.selectedUnit.unit.id,
        year: Number(state?.selectedYear),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.selectedUnit, state?.selectedYearAndCycle, state?.selectedYear]);

  useEffect(() => {
    getScholarshipsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch({
      type: VacancyDataActionTypesEnum.SET_DISCOUNT_FORM,
      payload: {
        scholarship_percentage: String(
          state?.selectedScholarship?.scholarship_percentage ?? ""
        ),
        scholarship_percentage_monthly: String(
          state?.selectedScholarship?.scholarship_percentage_monthly ?? ""
        ),
        scholarship_percentage_material: String(
          state?.selectedScholarship?.scholarship_percentage_material ?? ""
        ),
      },
    });
  }, [state?.selectedScholarship]);

  useEffect(() => {
    if (
      state?.createEnrollmentRequest?.student &&
      state?.createEnrollmentRequest?.guardian
    ) {
      createEnrollment(state?.createEnrollmentRequest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.createEnrollmentRequest]);

  return { state, dispatch };
};
