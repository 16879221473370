import { SectionProps } from "./types";
import * as S from "./style";

export const Section = ({
  maxWidth,
  display,
  direction,
  align,
  justify,
  children,
  padding,
}: SectionProps) => {
  return (
    <S.Container
      maxWidth={maxWidth}
      display={display}
      direction={direction}
      align={align}
      justify={justify}
      padding={padding}
    >
      {children}
    </S.Container>
  );
};
