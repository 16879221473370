import {
  formatCEP,
  formatCPF,
  formatDate,
  stringToMaskCEP,
} from "helpers/helpers";
import { GetCXUserJson } from "interfaces/constants";
import { UserDataDiv } from "../../styles";

interface StudentDataInterface {
  student: GetCXUserJson;
}

export const StudentData = ({ student }: StudentDataInterface) => {
  return (
    <div className="row w-100">
      <UserDataDiv className="col-md-6 col-12">
        <p className="title">Dados pessoais</p>
        <p>
          <span>Nome: </span>
          {student.personal?.name}
        </p>
        <p>
          <span>Escola anterior: </span>
          {student.education?.previous_school || "Não possui escola anterior"}
        </p>
        <p>
          <span>Data de nascimento: </span>
          {student.personal?.birth_date
            ? formatDate(student.personal?.birth_date)
            : "Não possui data de nascimento"}
        </p>
        <div className="d-flex">
          <p>
            <span>RG: </span>
            {student.personal?.rg || "Não possui RG"}
          </p>
          <p className="ml-3">
            <span>CPF: </span>
            {student.personal?.cpf
              ? formatCPF(student.personal?.cpf)
              : "Não possui CPF"}
          </p>
        </div>
        <p>
          <span>Gênero: </span>
          {!student.gender_type && "sem gênero"}
          {student.gender_type && (
            <span>
              {student.gender_type!.toString() === "1"
                ? "Masculino"
                : student.gender_type!.toString() === "2"
                ? "Feminino"
                : student.gender_type}
            </span>
          )}
        </p>
      </UserDataDiv>
      <UserDataDiv className="col-md-6 col-12">
        <p className="title">Localização</p>
        <p>
          <span>
            {student.location?.same_address_as_guardian &&
              `Endereço de ${
                student.guardians.find(
                  (g) => g.id === student.location.same_address_as_guardian
                )!.name
              }`}
          </span>
        </p>
        <p>
          <span>CEP: </span>
          {student.location?.same_address_as_guardian &&
            (student.guardians.find(
              (g) => g.id === student.location.same_address_as_guardian
            )!.zip_code
              ? stringToMaskCEP(
                  student.guardians.find(
                    (g) => g.id === student.location.same_address_as_guardian
                  )!.zip_code
                )
              : "Responsável não possui CEP")}
          {!student.location?.same_address_as_guardian &&
            (student.location?.zip_code
              ? formatCEP(student.location?.zip_code)
              : "Não possui CEP")}
        </p>
        <p>
          <span>Endereço: </span>
          {student.location?.same_address_as_guardian &&
            (student.guardians.find(
              (g) => g.id === student.location.same_address_as_guardian
            )!.address ||
              "Responsável não possui endereço")}
          {!student.location?.same_address_as_guardian &&
            (student.location?.address || "Não possui endereço")}
        </p>
        <p>
          <span>Número: </span>
          {student.location?.same_address_as_guardian &&
            (student.guardians.find(
              (g) => g.id === student.location.same_address_as_guardian
            )!.number ||
              "Responsável não possui número")}
          {!student.location?.same_address_as_guardian &&
            (student.location?.number || "Não possui número")}
        </p>
        <p>
          <span>Complemento: </span>
          {student.location?.same_address_as_guardian &&
            (student.guardians.find(
              (g) => g.id === student.location.same_address_as_guardian
            )!.complement ||
              "Responsável não possui complemento")}
          {!student.location?.same_address_as_guardian &&
            (student.location?.complement || "Não possui complemento")}
        </p>
        <p>
          <span>Bairro: </span>
          {student.location?.same_address_as_guardian &&
            (student.guardians.find(
              (g) => g.id === student.location.same_address_as_guardian
            )!.neighborhood ||
              "Responsável não possui bairro")}
          {!student.location?.same_address_as_guardian &&
            (student.location?.neighborhood || "Não possui bairro")}
        </p>
        <div className="d-flex">
          <p>
            <span>Cidade: </span>
            {student.location?.same_address_as_guardian &&
              (student.guardians.find(
                (g) => g.id === student.location.same_address_as_guardian
              )!.city ||
                "Responsável não possui cidade")}
            {!student.location?.same_address_as_guardian &&
              (student.location?.city || "Não possui cidade")}
          </p>
          <p className="ml-3">
            <span>Estado: </span>
            {student.location?.same_address_as_guardian &&
              (student.guardians.find(
                (g) => g.id === student.location.same_address_as_guardian
              )!.uf ||
                "Responsável não possui UF")}
            {!student.location?.same_address_as_guardian &&
              (student.location?.uf || "Não possui UF")}
          </p>
        </div>
      </UserDataDiv>
      <UserDataDiv className="col-md-6 col-12">
        <p className="title">Ano e Turma - Matrícula 2022</p>
        <p>
          <span>Ano: </span>
          {student.last_enrollment?.grade || "Não possui ano"}
        </p>
        <p>
          <span>Ciclo educacional: </span>
          {student.last_enrollment?.educational_level_name ||
            "Não possui ciclo educacional"}
        </p>
        <p>
          <span>Unidade: </span>
          {student.last_enrollment?.unit_name || "Não possui unidade"}
        </p>
      </UserDataDiv>
    </div>
  );
};
