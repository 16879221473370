import { IPaymentPlanMonths, IPlanRegistration } from "interfaces/plan";
import { KanataAPI } from "./api";
import { ErrorHandlerFunction } from "./service-helpers";
import { IPagination } from "interfaces/utils";

export const disablePaymentPlan = async ({ plan_id }: { plan_id: number }) => {
  const response = await KanataAPI.patch(`/mamon/payment_plans/${plan_id}/`, {
    activated: false,
  }).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

type EnrollmentIdType = {
  enrollmentId: number;
};

export type PaymentDayType = {
  [key: number]: number;
};

export interface PaymentPlanIterface {
  educational_service: string;
  material: string;
  reservation: string;
  reservation_paid: boolean;
  total: string;
}

export interface GetPaymentPlanInterface {
  contract_value: string;
  discount: number;
  expired: boolean;
  final_date: string;
  id: number;
  installments_material: number;
  installments_monthly: number;
  is_transition: boolean;
  material_contract_value: string;
  material_value: string;
  payment_day: Array<number>;
  plan_name: string;
  reservation_value: string;
  resume: PaymentPlanIterface;
  scholarship_material: string;
  scholarship_monthly: string;
  scholarship_percentage: string;
  total_material_value: string;
  total_material_value_discount: string;
  total_value: string;
  total_value_discount: string;
  total_installments_value: string;
}

export const getPaymentPlan = async ({ enrollmentId }: EnrollmentIdType) => {
  const response = await KanataAPI.get<GetPaymentPlanInterface>(
    `/totvs/payment_plans_info/${enrollmentId}`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface PaymentDataInterface {
  payment_day: string;
  plan_id: number;
  enrollment_id: number;
}

export const savePaymentPlan = async ({
  payment_day,
  plan_id,
  enrollment_id,
}: PaymentDataInterface) => {
  const response = await KanataAPI.post(`/totvs/payment_plan/`, {
    payment_day,
    plan_id,
    enrollment_id,
  }).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface getPaymentPlansDataInterface {
  filtro: string | number;
  page?: number;
}

export const getPaymentPlansData = async ({
  filtro,
  page,
}: getPaymentPlansDataInterface) => {
  const response = await KanataAPI.get<IPagination<IPlanRegistration>>(
    `mamon/planos_pagamento?${filtro}${
      page && page !== 1 ? `&page=${page}` : ""
    }&transicao=false`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const paymentPlansData = async (id: number | string) => {
  const response = await KanataAPI.get<IPlanRegistration>(
    `mamon/planos_pagamento/${id}`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export interface GetSelectedPlanInterface {
  id: number;
  payment_date: number;
  material_plan: number;
  courseware_total_price: number;
  courseware_total_price_not_disccount: number;
  courseware_year: number;
  monthly_payment_total_price_not_disccount: number;
  monthly_payment_total_price: number;
  monthly_year: number;
  active_monthly_price: number;
  active_courseware_price: number;
  is_active: boolean;
  created_at: string;
  unit: number;
  unit_educational_level: number;
  enrollment: number;
  plan: number;
  installments_quantity: number;
  installments_quantity_material: number;
  reservation_value: number;
  reservation_value_formated: string;
}

export const getSelectedPlan = async ({ enrollmentId }: EnrollmentIdType) => {
  const response = await KanataAPI.get<GetSelectedPlanInterface>(
    `/totvs/payment_plan/${enrollmentId}`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export interface GetAvailableStartMonths {
  start_month: number;
  start_month_display: string;
  year: number;
}

export interface GetAvailableStartMonthsProps {
  unit?: number;
  educational_level?: number;
  year?: number;
}

export const getAvailableStartMonths = async ({
  unit,
  educational_level,
  year,
}: GetAvailableStartMonthsProps) => {
  const response = await KanataAPI.get<GetAvailableStartMonths>(
    `mamon/payment_plans/available_start_month?${unit ? `unit=${unit}` : ""}${
      educational_level ? `&educational_level=${educational_level}` : ""
    }${year ? `&year=${year}` : ""}
    `
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const getPaymentPlanMonths = async () => {
  const response = await KanataAPI.get<Array<IPaymentPlanMonths>>(
    `mamon/payment_plan/months`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const planRegistration = async ({
  nome_referencia,
  tipo,
  unidade,
  ciclo,
  data_inicio_vigencia,
  data_final_vigencia,
  data_inicio_venda,
  data_final_venda,
  mes_primeira_cobranca,
  ano,
  quantidade_mensalidades,
  valor_mensalidades,
  quantidade_parcelas_material,
  valor_parcela_material,
  porcentagem_desconto_material,
  valor_reserva,
  codigo_plano_totvs,
  possui_desconto,
  porcentagem_desconto_plano,
  ativo,
  tipo_matricula,
  transicoes,
}: IPlanRegistration) => {
  const response = await KanataAPI.post(`/mamon/planos_pagamento/`, {
    nome_referencia,
    tipo,
    unidade,
    ciclo,
    data_inicio_vigencia,
    data_final_vigencia,
    data_inicio_venda,
    data_final_venda,
    mes_primeira_cobranca,
    ano,
    quantidade_mensalidades,
    valor_mensalidades,
    quantidade_parcelas_material,
    valor_parcela_material,
    porcentagem_desconto_material,
    valor_reserva,
    codigo_plano_totvs,
    possui_desconto,
    porcentagem_desconto_plano,
    ativo,
    tipo_matricula,
    transicoes,
  }).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const updatePlan = async ({
  id,
  nome_referencia,
  tipo,
  unidade,
  ciclo,
  data_inicio_vigencia,
  data_final_vigencia,
  data_inicio_venda,
  data_final_venda,
  mes_primeira_cobranca,
  ano,
  quantidade_mensalidades,
  valor_mensalidades,
  quantidade_parcelas_material,
  valor_parcela_material,
  porcentagem_desconto_material,
  valor_reserva,
  codigo_plano_totvs,
  possui_desconto,
  porcentagem_desconto_plano,
  ativo,
  tipo_matricula,
  transicoes,
}: IPlanRegistration) => {
  const response = await KanataAPI.patch(`/mamon/planos_pagamento/${id}/`, {
    nome_referencia,
    tipo,
    unidade,
    ciclo,
    data_inicio_vigencia,
    data_final_vigencia,
    data_inicio_venda,
    data_final_venda,
    mes_primeira_cobranca,
    ano,
    quantidade_mensalidades,
    valor_mensalidades,
    quantidade_parcelas_material,
    valor_parcela_material,
    porcentagem_desconto_material,
    valor_reserva,
    codigo_plano_totvs,
    possui_desconto,
    porcentagem_desconto_plano,
    ativo,
    tipo_matricula,
    transicoes,
  }).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};
