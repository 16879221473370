import { useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";
import { mdiInformationOutline, mdiOpenInNew } from "@mdi/js";
import { Header } from "components/molecules";
import { ItinerariesGroup } from "components/organisms/ItinerariesGroup";
import { ModalInfo } from "components/atoms/Modal";
import placeholderPng from "static/img/placeholder.png";
import * as S from "./styles";
import { useStudyItineraries } from "./StudyItineraries.helper";
import { CALENDAR_2023_DOCS, CALENDAR_GOOGLE_DRIVE } from "lib/external-links";
import { useLocation } from "react-router-dom";
import { StudentInterface, StudentDataInterface } from "interfaces/constants";
import { GetUserKanataResponse } from "services/grades.service";

interface ILocation {
  selectedStudent?: StudentInterface;
  userStudent?: StudentDataInterface;
  userKanata?: GetUserKanataResponse;
  role?: string;
}

export const StudyItineraries = () => {
  const { t } = useTranslation();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { state } = useLocation<ILocation>();

  const {
    studyItinerary,
    overdueItineraries,
    studentEnrollment,
    studentPersonal,
    isToday,
    fetchLastWeek,
    fetchNextWeek,
    fetchCurrentWeek,
    isFetching,
  } = useStudyItineraries(state);

  const prefix = "pages.AP.studyItinerary";

  return (
    <S.Container>
      <ModalInfo
        iconPath={mdiInformationOutline}
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        title={t(`${prefix}.modalTitle`)}
        content={t(`${prefix}.modalContent`)}
      >
        <div className="modal-children">
          <button onClick={() => setModalIsOpen(false)}>
            {t(`${prefix}.closeModalBtn`)}
          </button>
        </div>
      </ModalInfo>

      <div className="page-title">
        <Header title={t(`${prefix}.title`)} fontSize="36px" />
        <button onClick={() => setModalIsOpen(true)}>
          <Icon path={mdiInformationOutline} size={1} />
        </button>
      </div>

      <div className="student-info">
        <img
          src={studentPersonal?.photo_url ?? placeholderPng}
          alt={studentPersonal?.name}
        />

        <div className="details">
          <strong>{studentPersonal?.name}</strong>
          <p className="m-0">
            {studentEnrollment?.grade}º Ano -{" "}
            <span>Turma {studentEnrollment?.section}</span>
          </p>
          <a href={`${CALENDAR_GOOGLE_DRIVE}`} target="_blank" rel="noreferrer">
            <Icon path={mdiOpenInNew} size={1} />
            {t(`${prefix}.subtitle`)}
          </a>
        </div>
      </div>

      <div className="details-note">
        <p>{t(`${prefix}.note`)}</p>
      </div>

      <ItinerariesGroup
        type="current"
        itinerary={studyItinerary}
        isToday={isToday}
        onFetchLastWeek={fetchLastWeek}
        onFetchNextWeek={fetchNextWeek}
        onFetchCurrentWeek={fetchCurrentWeek}
        isFetching={isFetching}
      />

      {overdueItineraries && <h3>{t(`${prefix}.delayedItineraries`)}</h3>}

      {overdueItineraries?.map((ov, index) => {
        return ov?.components && ov?.components?.length ? (
          <ItinerariesGroup
            key={`${index}-${ov.start_weekday}-${ov.end_weekday}`}
            type="past"
            itinerary={ov}
          />
        ) : null;
      })}
    </S.Container>
  );
};
