import styled from "styled-components";

export const Container = styled.form`
  display: flex;
  gap: ${({ theme }) => theme.space[4]};
  margin-top: ${({ theme }) => theme.space[4]};

  label {
    float: left;
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    font-size: ${({ theme }) => theme.fontSizes.web.sm};
    line-height: ${({ theme }) => theme.lineHeights.normal};
    color: ${({ theme }) => theme.colors.greyScale95};

    margin: 0;
  }

  select {
    box-sizing: border-box;
    background: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.greyScale40};

    color: ${({ theme }) => theme.colors.greyScale95};
    height: 37.7px; //TODO: nao tem na lib
    padding-left: ${({ theme }) => theme.space[2]};
    font-size: ${({ theme }) => theme.fontSizes.web.sm};
    width: 309px;
    cursor: pointer;
  }
`;

export const InputSelect = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[1]};
  width: fit-content;
`;
