import { SingleFoodTransactionsInterface } from "interfaces/mealInterfaces";
import { createContext, useCallback, useState } from "react";
import { IFoodProvider, IFoodContextType, IFoodItem } from "./type";

export const FoodContext = createContext<IFoodContextType | null>(null);

export const FoodProvider = ({ children }: IFoodProvider) => {
  const [selectedFoodItems, setSelectedFoodItems] = useState<IFoodItem[]>([]);
  const [paymentMethod, setPaymentMethod] = useState<string>("");
  const [selectedDates, setSelectedDates] = useState<Array<string>>([]);
  const [paymentData, setPaymentData] =
    useState<SingleFoodTransactionsInterface | null>(null);

  const itemsTotalValue = selectedFoodItems.reduce(
    (acc: number, obj) => acc + Number(obj.price) * obj.qtd,
    0
  );

  const clearSelectedFoodItems = useCallback(() => {
    setSelectedFoodItems([]);
  }, []);

  const clearSelectedDates = useCallback(() => {
    setSelectedDates([]);
  }, []);

  return (
    <FoodContext.Provider
      value={{
        selectedFoodItems,
        setSelectedFoodItems,
        itemsTotalValue,
        clearSelectedFoodItems,
        paymentMethod,
        setPaymentMethod,
        selectedDates,
        setSelectedDates,
        clearSelectedDates,
        paymentData,
        setPaymentData,
      }}
    >
      {children}
    </FoodContext.Provider>
  );
};
