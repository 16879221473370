import {
  ICalendar,
  ICalendarDaySubtitle,
  ICreateNewCalendarRequest,
  ICreateNewCalendarResponse,
  IGetUniqueCalendarResponse,
} from "pages/AP/SchoolCalendar/interfaces";
import { CalendarioAPI } from "./api";

export const getCalendars = async () => {
  try {
    const response = await CalendarioAPI.get<ICalendar[]>(`/calendarios/`);

    if (!response) {
      throw new Error();
    }

    return response;
  } catch (error) {
    throw new Error(`Falha no serviço: getCalendars`, error);
  }
};

export const getUniqueCalendar = async (calendar_id: number) => {
  try {
    const response = await CalendarioAPI.get<IGetUniqueCalendarResponse>(
      `/calendarios/${calendar_id}`
    );

    if (!response) {
      throw new Error();
    }

    return response;
  } catch (error) {
    throw new Error(`Falha no serviço: getUniqueCalendar`, error);
  }
};

export const createNewCalendar = async (
  calendar: ICreateNewCalendarRequest
) => {
  try {
    const response = await CalendarioAPI.post<ICreateNewCalendarResponse>(
      `/calendarios/`,
      calendar
    );

    if (!response) {
      throw new Error();
    }

    return response;
  } catch (error) {
    throw new Error(`Falha no serviço: createNewCalendar`, error);
  }
};

export const getExistingUnitCalendars = async () => {
  try {
    const response = await CalendarioAPI.get<number[]>(`/unidades_existentes/`);

    if (!response) {
      throw new Error();
    }

    return response;
  } catch (error) {
    throw new Error(`Falha no serviço: getExistingUnitCalendars`, error);
  }
};

export const getCalendarSubtitles = async () => {
  try {
    const response = await CalendarioAPI.get<ICalendarDaySubtitle[]>(
      `/legendas/`
    );

    if (!response) {
      throw new Error();
    }

    return response;
  } catch (error) {
    throw new Error(`Falha no serviço: getCalendarSubtitles`, error);
  }
};
