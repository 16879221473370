import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { useRef } from "react";
import { FieldRenderProps } from "react-final-form";
import {
  CalendarDisabledDiv,
  ErrorLabel,
  EyeIcon,
  Label,
  LabelFloat,
} from "./style";

type Props = FieldRenderProps<Date, any>;

export const CalendarComponent: React.FC<Props> = ({
  input: { value, ...input },
  meta,
  label,
  hideCheck,
  placeholder,
  min,
  max,
  start,
  showTodayButton,
  disabled,
  inputLabel,
  valueCalendar,
}: Props) => {
  const calendarRef = useRef<any>();

  const showCalendar = () => {
    calendarRef.current.show();
  };

  return (
    <>
      <LabelFloat className={meta.touched && meta.error ? "invalid" : ""}>
        <div onFocus={() => showCalendar()} className="d-flex">
          <DatePickerComponent
            showTodayButton={showTodayButton || false}
            placeholder={placeholder}
            format="dd/MM/yyyy"
            ref={calendarRef}
            start={start || "Decade"}
            value={value}
            disabled={disabled}
            allowEdit={false}
            min={min}
            max={max}
            // className={meta.touched && meta.error ? "invalid" : ""}
            {...input}
          />
          {!hideCheck && meta.touched && !meta.error && (
            <EyeIcon>
              <FontAwesomeIcon icon={faCheck as IconProp} />
            </EyeIcon>
          )}
          {label && (
            <Label
              className={meta.touched && meta.error ? "invalid" : ""}
              htmlFor={input.name}
            >
              {label}
            </Label>
          )}
        </div>
        {meta.touched && meta.error && <ErrorLabel>{meta.error}</ErrorLabel>}
      </LabelFloat>
    </>
  );
};
