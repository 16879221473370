import styled from "styled-components";

export const Header = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  color: white;
  text-transform: uppercase;
  padding: 1.5em;
  background-color: #333333;
  text-align: center;
  z-index: 1;
  svg {
    cursor: pointer;
    position: fixed;
    left: 1em;
  }
`;
