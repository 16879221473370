import { InputHTMLAttributes } from "react";
import { Field } from "react-final-form";
import { IInputRadio } from "./interface";
import { RadioStyled, Container } from "./style";

interface RadioButtonInterface extends InputHTMLAttributes<HTMLInputElement> {
  value: boolean | any;
  name: string;
  required?: boolean;
  onChange?: () => void;
  disabled?: boolean;
  title?: string;
}

export const Radio = ({
  required,
  value,
  name,
  onChange,
  disabled,
  title,
}: RadioButtonInterface) => {
  return (
    <RadioStyled disabled={disabled}>
      <label className="container">
        <input
          disabled={disabled}
          type="radio"
          className="RadioBrowser"
          id={value}
          onChange={onChange}
          value={value}
          name={name}
          required={required}
        />
        <div className="check"></div>
        {title}
      </label>
    </RadioStyled>
  );
};

export const InputRadio = ({ name, value, label }: IInputRadio) => {
  return (
    <Container htmlFor={name}>
      <Field component="input" name={name} type="radio" value={value} />
      {label}
    </Container>
  );
};

export const RadioChecked = ({
  changed,
  id,
  isSelected,
  label,
  value,
}: any) => {
  return (
    <div className="RadioButton">
      <input
        id={id}
        onChange={changed}
        value={value}
        type="radio"
        checked={isSelected}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};
