import { ErrorHandlerFunction } from "./service-helpers";
import { KanataAPI } from "./api";
import { EnrollmentYearsInterface } from "interfaces/constants";

interface IGetYear {
  more_years?: number;
  studentId?: number;
}

export const getYear = async ({ more_years, studentId }: IGetYear) => {
  const response = await KanataAPI.get<EnrollmentYearsInterface>(
    `mamon/active_years`,
    {
      params:
        studentId !== 0
          ? { quantidade_anos_adicionais: more_years, estudante: studentId }
          : { quantidade_anos_adicionais: more_years },
    }
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};
