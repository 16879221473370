import styled from "styled-components";
import { BLUE, PINK, BLACK } from "styles/colors";

export const StudentDataBox = styled.div`
  border-style: solid;
  border-width: 2px;
  border-radius: 28px;
  background-color: #d9d2e9;
  padding: 10px;
`;

export const LinhaDigitavelBox = styled.div`
  border-style: solid;
  border-width: 1px;
  border-radius: 13px;
  border-color: #e8e6e6;
  padding: 0.1em;
  padding-right: 1em;
  padding-left: 1em;
  color: #aaa;
  &:hover {
    cursor: copy;
  }
  svg {
    color: ${BLUE};
  }
`;

export const CreditPaymentBox = styled.div`
  border-style: solid;
  border-width: 1px;
  border-radius: 24px;
  border-color: #e8e6e6;
  background-color: #fdfbfa;
  padding: 2em;
`;

export const BoldBiggerBlue = styled.p`
  font-weight: bold;
  font-size: 24px;
  color: ${BLUE};
`;

export const BoldBiggerPink = styled.p`
  font-weight: bold;
  font-size: 24px;
  color: ${PINK};
`;

export const BoldBigger = styled.p`
  font-weight: bold;
  font-size: 18px;
  color: ${BLACK};
`;

export const TextSpan = styled.span`
  font-weight: 500;
  font-size: 18px;
  color: ${PINK};
`;

export const BorderContainer = styled.div`
  border-width: 1px;
  border-color: ${BLUE};
  border-style: solid;
  border-radius: 15px;
`;

export const AvatarContainer = styled.div`
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  border-width: 1px;
  background-color: ${BLUE};
  width: 100%;
  padding: 0.5rem;
  color: white;

  strong {
    font-weight: bold;
    font-size: 18px;
    color: "#FFFFFF";
  }
`;

export const Avatar = styled.img`
  vertical-align: middle;
  width: 70px;
  height: 70px;
  border-radius: 50%;
`;
