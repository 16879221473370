// React
import { useCallback, useEffect, useState } from "react";
// Libs
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, useFieldArray } from "react-hook-form";
import HTTP_STATUS from "http-status-codes";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import moment from "moment";
// Services
import { getUnitsGradesSchoolPlaces } from "services/units.service";
import {
  getPaymentPlanMonths,
  paymentPlansData,
  planRegistration,
  updatePlan,
} from "services/plan.service";
import { getYear } from "services/multipurpose";
// Interfaces
import {
  IPaymentPlanMonths,
  IPlanRegistration,
  ITransitionPlan,
} from "interfaces/plan";
import { GetUnitsGradesResponse } from "interfaces/unit";
import { EnrollmentYearsInterface } from "interfaces/constants";
import { IEditPlan, ITransitionsFromApi } from "./interface";
// Helpers
import {
  booleanToString,
  formatMoneyInput,
  removeFormatMoney,
  stringToBoolean,
} from "helpers/helpers";
// Components
import { toastError, toastSuccess } from "components/Toast";

const planSchema = z.object({
  //Plan Info
  id: z.number().optional(),
  planName: z.string().nonempty("Insira o nome do plano."),
  displayName: z.string().nonempty("Selecione o nome de exibição."),
  totvsCode: z.string().nonempty("Insira o código totvs."),
  enrollmentType: z.string(),
  unit: z.coerce.number().refine((value) => value !== 0, "Selecione a unidade"),
  educationalLevel: z.coerce
    .number()
    .refine((value) => value !== 0, "Selecione o ciclo"),
  firstInstallment: z.coerce
    .number()
    .refine((value) => value !== 0, "Selecione o mês de cobrança"),
  year: z.coerce.number().refine((value) => value !== 0, "Selecione o ano"),
  validityStartDate: z.string().nonempty("Insira a data."),
  validityFinalDate: z.string().nonempty("Insira a data."),
  salesStartDate: z.string().nonempty("Insira a data."),
  salesFinalDate: z.string().nonempty("Insira a data."),

  //Prices Info
  priceVacancyReservation: z.string().nonempty("Insira o preço da reserva."),
  monthlyPrice: z.string().nonempty("Insira o preço da parcela."),
  numberInstallments: z.coerce
    .number()
    .refine((value) => value !== 0, "Selecione o numero de parcelas"),
  discount: z.coerce.boolean(),
  discountPercentage: z.string(),
  suplyMonthlyPrice: z.string().nonempty("Insira o preço do material."),
  suplyNumberInstallments: z.coerce
    .number()
    .refine((value) => value !== 0, "Selecione o numero de parcelas"),
  suplyDiscount: z.coerce.boolean(),
  suplyDiscountPercentage: z.string(),

  //Transition Plans
  transitions: z
    .array(
      z.object({
        id: z.number().optional(),
        monthlyPrice: z.string().nonempty("Insira o preço da parcela."),
        numberInstallments: z.coerce.number(),
        discount: z.string(),
        discountPercentage: z.string(),
        suplyMonthlyPrice: z.string().nonempty("Insira o preço do material."),
        suplyNumberInstallments: z.coerce.number(),
        suplyDiscount: z.string(),
        suplyDiscountPercentage: z.string(),
        totvsCode: z.string().nonempty("Insira o código totvs."),
        validityFinalDate: z.string().nonempty("Insira a data."),
      })
    )
    .min(1),
});

export type PlanFormData = z.infer<typeof planSchema>;

export const HPlan = ({ id }: IEditPlan) => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.paymentPlan";

  const history = useHistory();

  // Initial state
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  // API
  const [units, setUnits] = useState<GetUnitsGradesResponse[]>();
  const [paymentPlanMonths, setPaymentPlanMonths] =
    useState<IPaymentPlanMonths[]>();
  const [years, setYears] = useState<EnrollmentYearsInterface>();
  const [plan, setPlan] = useState<IPlanRegistration>();

  const edit =
    id && !moment().isBefore(moment(plan?.data_inicio_vigencia)) ? true : false;

  // Busca e seta os valores dos planos de transicao
  const getTransitionPlan = (values: IPlanRegistration) => {
    const transitions: ITransitionsFromApi[] = [];

    values.transicoes.forEach((transition) => {
      transitions.push({
        id: transition.id!,
        monthlyPrice: formatMoneyInput(
          transition.valor_mensalidades.toString()
        ),
        numberInstallments: transition.quantidade_mensalidades,
        discount: booleanToString(transition.possui_desconto),
        discountPercentage: transition.porcentagem_desconto_plano,
        suplyMonthlyPrice: formatMoneyInput(
          transition.valor_parcela_material.toString()
        ),
        suplyNumberInstallments: transition.quantidade_parcelas_material,
        suplyDiscount:
          transition.porcentagem_desconto_material !== "0.00"
            ? booleanToString(true)
            : booleanToString(false),
        suplyDiscountPercentage: transition.porcentagem_desconto_material,
        totvsCode: transition.codigo_plano_totvs,
        validityFinalDate: transition.data_final_vigencia,
      });
    });

    return transitions;
  };

  // Valores iniciais do plano de transicao
  const transitionPlan = {
    monthlyPrice: "",
    numberInstallments: 0,
    discount: "false",
    discountPercentage: "0.00",
    suplyMonthlyPrice: "",
    suplyNumberInstallments: 0,
    suplyDiscount: "false",
    suplyDiscountPercentage: "0.00",
    totvsCode: "",
    validityFinalDate: "",
  };

  const {
    register,
    handleSubmit,
    trigger,
    formState: { errors, isSubmitting },
    control,
    watch,
    reset,
    setValue,
  } = useForm<PlanFormData>({
    resolver: zodResolver(planSchema),
    mode: "all",
    defaultValues: {
      enrollmentType: "ambos",
      discountPercentage: "0.00",
      suplyDiscountPercentage: "0.00",
      discount: false,
      suplyDiscount: false,
      transitions: [transitionPlan],
    },
  });

  // Busca dados do plano que o usuario quer editar
  const planData = async () => {
    if (id) {
      const response = await paymentPlansData(id);
      if (response?.status === HTTP_STATUS.OK) {
        setPlan(response.data);
        reset({
          id: response.data.id,
          planName: response.data.nome_referencia,
          displayName: response.data.tipo,
          totvsCode: response.data.codigo_plano_totvs,
          enrollmentType:
            response.data.tipo_matricula === null
              ? "ambos"
              : response.data.tipo_matricula,
          unit: response.data.unidade,
          educationalLevel: response.data.ciclo,
          firstInstallment: response.data.mes_primeira_cobranca,
          year: response.data.ano,
          validityStartDate: response.data.data_inicio_vigencia,
          validityFinalDate: response.data.data_final_vigencia,
          salesStartDate: response.data.data_inicio_venda,
          salesFinalDate: response.data.data_final_venda,
          priceVacancyReservation: formatMoneyInput(
            response.data.valor_reserva.toString()
          ),
          monthlyPrice: formatMoneyInput(
            response.data.valor_mensalidades.toString()
          ),
          numberInstallments: response.data.quantidade_mensalidades,
          discount: response.data.possui_desconto,
          discountPercentage: response.data.porcentagem_desconto_plano,
          suplyMonthlyPrice: formatMoneyInput(
            response.data.valor_parcela_material.toString()
          ),
          suplyNumberInstallments: response.data.quantidade_parcelas_material,
          suplyDiscount:
            response.data.porcentagem_desconto_material > 0 ? true : false,
          suplyDiscountPercentage: response.data.porcentagem_desconto_material,
          transitions: getTransitionPlan(response.data),
        });

        setLoading(false);
      } else {
        setError(true);
      }
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const unitsGrade = await getUnitsGradesSchoolPlaces();
        if (unitsGrade && unitsGrade.status === HTTP_STATUS.OK) {
          setUnits(unitsGrade.data);
        }

        const paymentPlanMonths = await getPaymentPlanMonths();
        if (paymentPlanMonths && paymentPlanMonths.status === HTTP_STATUS.OK) {
          setPaymentPlanMonths(paymentPlanMonths.data);
        }

        const moreYears = 1;
        const getYears = await getYear({ more_years: moreYears });
        if (getYears && getYears.status === HTTP_STATUS.OK) {
          setYears(getYears.data);

          if (id) {
            planData();
          } else {
            setLoading(false);
          }
        }
      } catch (err) {
        setError(true);
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Identifica os planos de transicao que foram criados
  const createTransitionPlan = (values: PlanFormData) => {
    const transitions: ITransitionPlan[] = [];

    values.transitions.forEach((transition, index) => {
      transitions.push({
        id: values.transitions[index].id,
        quantidade_mensalidades: values.transitions[index].numberInstallments,
        valor_mensalidades: parseInt(
          removeFormatMoney(values.transitions[index].monthlyPrice)
        ),
        possui_desconto: stringToBoolean(values.transitions[index].discount),
        // verifica se tem desconto, se nao, manda 0
        porcentagem_desconto_plano: stringToBoolean(
          values.transitions[index].discount
        )
          ? values.transitions[index].discountPercentage
          : "0.00",
        codigo_plano_totvs: values.transitions[index].totvsCode,
        quantidade_parcelas_material:
          values.transitions[index].suplyNumberInstallments,
        valor_parcela_material: parseInt(
          removeFormatMoney(values.transitions[index].suplyMonthlyPrice)
        ),
        // verifica se tem desconto, se nao, manda 0
        porcentagem_desconto_material: stringToBoolean(
          values.transitions[index].suplyDiscount
        )
          ? values.transitions[index].suplyDiscountPercentage
          : "0.00",
        data_final_vigencia: values.transitions[index].validityFinalDate,
      });
    });

    return transitions;
  };

  const onSubmit = useCallback(
    async (values: PlanFormData) => {
      let body = {
        id,
        nome_referencia: values.planName,
        tipo: values.displayName,
        unidade: values.unit,
        ciclo: values.educationalLevel,
        data_inicio_vigencia: values.validityStartDate,
        data_final_vigencia: values.validityFinalDate,
        data_inicio_venda: values.salesStartDate,
        data_final_venda: values.salesFinalDate,
        mes_primeira_cobranca: values.firstInstallment,
        ano: values.year,
        quantidade_mensalidades: values.numberInstallments,
        valor_mensalidades: parseInt(removeFormatMoney(values.monthlyPrice)),
        quantidade_parcelas_material: values.suplyNumberInstallments,
        valor_parcela_material: parseInt(
          removeFormatMoney(values.suplyMonthlyPrice)
        ),
        // verifica se tem desconto, se nao, manda 0
        porcentagem_desconto_material: values.suplyDiscount
          ? values.suplyDiscountPercentage.replace(/,/, ".")
          : "0.00",
        valor_reserva: parseInt(
          removeFormatMoney(values.priceVacancyReservation)
        ),
        codigo_plano_totvs: values.totvsCode,
        possui_desconto: values.discount,
        // verifica se tem desconto, se nao, manda 0
        porcentagem_desconto_plano: values.discount
          ? values.discountPercentage.replace(/,/, ".")
          : "0.00",
        ativo: true,
        tipo_matricula: values.enrollmentType,
        transicoes: createTransitionPlan(values),
      };

      if (id) {
        const response = await updatePlan(body);
        if (response && response.status === HTTP_STATUS.OK) {
          toastSuccess(
            t(`${TRANSLATE}.toastSuccess`, {
              value: "atualizado",
            })
          );
          history.goBack();
        } else {
          toastError(t(`${TRANSLATE}.toastError`, { value: "atualizar" }));
        }
      } else {
        const response = await planRegistration(body);
        if (response && response.status === HTTP_STATUS.CREATED) {
          toastSuccess(
            t(`${TRANSLATE}.toastSuccess`, {
              value: "criado",
            })
          );
          reset();
        } else {
          toastError(t(`${TRANSLATE}.toastError`, { value: "criar" }));
        }
      }
    },
    [id, t, reset, history]
  );

  const { fields, append } = useFieldArray({
    control,
    name: "transitions",
  });

  const addTransitionPlan = () => {
    append(transitionPlan);
  };

  return {
    loading,
    error,
    units,
    paymentPlanMonths,
    years,
    plan,
    edit,
    onSubmit,
    register,
    handleSubmit,
    trigger,
    errors,
    watch,
    control,
    fields,
    addTransitionPlan,
    isSubmitting,
  };
};
