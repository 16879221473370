import { ErrorHandlerFunction, Headers } from "./service-helpers";
import { KanataAPI } from "./api";
import {
  AvailableSchoolPlacesReturn,
  EnrollmentSteps,
} from "interfaces/enrollment";
import { IPagination } from "interfaces/utils";

export interface EnrollmentResultReturn {
  enrollment_id: number;
  year: number;
  type: string;
  enrollment_status: number;
  enrollment_status_display: string;
  school_place_status: number;
  school_place_status_display: string;
  school_place_position?: any;
  payment_status: number;
  payment_status_display: string;
  financial_status: number;
  financial_status_display: string;
  documents_status: number;
  documents_status_display: string;
  contract_status: number;
  contract_status_display: string;
  scholarship_type?: any;
  scholarship_type_display?: any;
  scholarship_percentage: number;
  student_user_id: number;
  student_id: number;
  student_name: string;
  payment_overdue: boolean;
  unit: number;
  unit_name: string;
  educational_level: number;
  educational_level_name: string;
  grade: number;
  guard_id: number;
  guardian_user_id: number;
  guardian_id: number;
  guardian_name: string;
  guardian_phone: string;
  current_status: string;
  employees: [{ id: number; name: string }];
  date_vacancy_reserved: Date;
  payment_date_reservation_confirmed: string;
  section?: any;
  hubspot_id: string | null;
}

export interface SearchEnrollmentsStatusReturn {
  count: number;
  next?: string;
  previous?: string;
  results: EnrollmentResultReturn[];
}

export const searchEnrollmentsStatus = async ({
  filterURL,
  paginate,
}: {
  filterURL: string;
  paginate?: string;
}) => {
  let url;
  if (paginate) {
    url = paginate;
  } else {
    url = `cx/enrollment_filter?${filterURL}`;
  }
  const response = await KanataAPI.get<SearchEnrollmentsStatusReturn>(
    url
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

//TODO - TIPAR ISSO
export const updateEnrollment = async ({ body, enrollment_id }: any) => {
  const response = await KanataAPI.patch<any>(
    `mamon/enrollment/${enrollment_id}`,
    body
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

// /mamon/enrollment_export/

export const exportMatriculas = async (query: string) => {
  const response = await KanataAPI.get<any>(
    `/mamon/enrollment_export?${query}`,
    Headers({
      timeout: 1000000,
    })
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

//TODO: TIPAR ESSE BODY E A RESPONSE
export const massiveFreeSchoolPlaces = async ({ body }: { body: any }) => {
  const response = await KanataAPI.post<any>(
    `cx/reenrollment_release_school_place`,
    body
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

// Apis do novo fluxo de matriculas tem que estar pra baixo disso aqui

export const EnrollmentsFilter = async ({
  school_place_status,
  grade,
  educational_level,
  unit,
  year,
  openVacancies,
}: {
  school_place_status?: string;
  grade?: string;
  educational_level?: string;
  unit?: string;
  year?: string;
  openVacancies?: boolean;
}) => {
  const response = await KanataAPI.get<SearchEnrollmentsStatusReturn>(
    `/cx/enrollment_filter${
      openVacancies
        ? `?school_place_status=${school_place_status}&grade=${grade}&educational_level=${educational_level}&unit=${unit}&year=${year}`
        : ""
    }
    `,
    Headers({
      timeout: 1000000,
    })
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface IGetEnrollmentSteps {
  enrollment_id?: number;
  guardian?: boolean;
  in_progress?: boolean;
  waitingList?: boolean;
  unit?: number;
  educational_level?: number;
  grade?: number;
  page?: number;
  selectedYear?: number;
}

export const getEnrollmentSteps = async ({
  enrollment_id,
  guardian,
  in_progress,
  waitingList,
  unit,
  educational_level,
  grade,
  page,
  selectedYear,
}: IGetEnrollmentSteps) => {
  const response = await KanataAPI.get<EnrollmentSteps>(
    waitingList
      ? `/enrollment/?unidade=${unit}&ciclo=${educational_level}&segmento=${grade}&lista_espera=${waitingList}${
          selectedYear ? `&ano=${selectedYear}` : ""
        }&ordering=created_at${page ? `&page=${page}` : ""}`
      : `/enrollment/${
          guardian ? `?guardian=${enrollment_id}` : enrollment_id
        }${in_progress ? "&in_progress=true" : ""}`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface EnrollmentToNextStepInterface {
  enrollmentId: number;
  step: string;
  token: string;
}

//Atualmente temos para o step1 - sempre mandar "spte1" ou "step2"
export const updateEnrollmentToNextStep = async ({
  enrollmentId,
  step,
  token,
}: EnrollmentToNextStepInterface) => {
  const response = await KanataAPI.patch(
    `enrollment/${enrollmentId}/confirm/${step}/`,
    {}
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { status: response.status, token: `${token}` };
    return data;
  }
};

interface AvailableSchoolPlacesInterface {
  unit: number;
  educational_level: number;
  grade: number;
  selectedYear: number;
}

export const availableSchoolPlaces = async ({
  unit,
  educational_level,
  grade,
  selectedYear,
}: AvailableSchoolPlacesInterface) => {
  const response = await KanataAPI.get<AvailableSchoolPlacesReturn>(
    `/enrollment/available_school_places/?unit=${unit}&educational_level=${educational_level}&grade=${grade}${
      selectedYear ? `&year=${selectedYear}` : ""
    }`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface ReserveSchoolPlaceInterface {
  enrollmentId: number;
  token: string;
}

export const reserveSchoolPlace = async ({
  enrollmentId,
  token,
}: ReserveSchoolPlaceInterface) => {
  const response = await KanataAPI.patch(
    `enrollment/reserve_school_place/${enrollmentId}`,
    {}
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { status: response.status, token: `${token}` };
    return data;
  }
};

interface IBadgeList {
  unit: number;
  year: number;
  exported: boolean;
  has_photo: boolean;
  student_name?: string;
  page?: number;
  page_size: number;
  type: number;
}

export const badgeList = async ({
  unit,
  year,
  exported,
  has_photo,
  student_name,
  page,
  page_size,
  type,
}: IBadgeList) => {
  const response = await KanataAPI.get<IBadgeList>(
    `enrollment/export_photo_data/?unit=${unit}&year=${year}&exported=${exported}&has_photo=${has_photo}&page_size=${page_size}${
      student_name ? `&student_name=${student_name}` : ""
    }${type !== 0 ? `&type=${type}` : ""}${page ? `&page=${page}` : ""}`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface IBadgeDownload {
  enrollments?: Array<number>;
  selected_all?: boolean;
  unit: number;
  has_photo: boolean;
  exported: boolean;
  student_name: string;
  year: number;
  page: number;
  type: number;
}

export const badgeDataDownload = async ({
  enrollments,
  selected_all,
  unit,
  has_photo,
  exported,
  student_name,
  year,
  page,
  type,
}: IBadgeDownload) => {
  const response = await KanataAPI.post<IBadgeDownload>(
    `enrollment/export_photo_data/?page=${page}&unit=${unit}&year=${year}&exported=${exported}&has_photo=${has_photo}${
      type !== 0 ? `&type=${type}` : ""
    }&student_name=${student_name}`,
    { enrollments, selected_all },
    Headers({
      responseType: "arraybuffer",
    })
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = {
      data: response.data,
      status: response.status,
    };
    return data;
  }
};

export const badgePhotoDownload = async ({
  enrollments,
  selected_all,
  unit,
  has_photo,
  exported,
  student_name,
  year,
  page,
  type,
}: IBadgeDownload) => {
  const response = await KanataAPI.post<IBadgeDownload>(
    `enrollment/export_photo/?page=${page}&unit=${unit}&year=${year}&exported=${exported}&has_photo=${has_photo}${
      type !== 0 ? `&type=${type}` : ""
    }&student_name=${student_name}`,
    { enrollments, selected_all },
    Headers({
      responseType: "arraybuffer",
    })
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = {
      data: response.data,
      status: response.status,
    };
    return data;
  }
};

interface RemoveSchoolPlaceInterface {
  enrollmentId: number;
  token: string;
}

export const removeSchoolPlace = async ({
  enrollmentId,
  token,
}: RemoveSchoolPlaceInterface) => {
  const response = await KanataAPI.post(
    `mamon/deactivate_enrollment/${enrollmentId}/`,
    {}
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { status: response.status, token: `${token}` };
    return data;
  }
};

interface IGetEnrollmentCredit {
  enrollmentId: number;
}

export const getEnrollmentCredit = async ({
  enrollmentId,
}: IGetEnrollmentCredit) => {
  const response = await KanataAPI.get(
    `food/valor_total_credito_matricula/${enrollmentId}`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = {
      data: response.data,
      status: response.status,
    };
    return data;
  }
};

interface ICreateEnrollmentReserve {
  responsavel_id: number;
  estudante_id: number;
  ano: number;
  ciclo: number;
  segmento: number;
  mes_primeira_cobranca: number;
  unidade_id: number;
  hubspot_id: string;
}

export const createEnrollmentReserve = async (
  enrollmentData: ICreateEnrollmentReserve
) => {
  const response = await KanataAPI.post(
    "enrollment/reserva_rematricula/",
    enrollmentData
  ).catch(ErrorHandlerFunction);

  if (response) {
    const data = {
      data: response.data,
      status: response.status,
    };
    return data;
  }
};

interface IReenrollmentList {
  segmento: number;
  ciclo: number;
  unidade: number;
  ano: number;
}

export const getReenrollmentList = async ({
  segmento,
  ciclo,
  unidade,
  ano,
}: IReenrollmentList) => {
  const response = await KanataAPI.get<
    IPagination<{
      nome: string;
      ra: string;
    }>
  >("/enrollment/lista_rematriculas/", {
    params: { segmento, ciclo, unidade, ano, page_size: 100 },
  }).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export interface ICreateNewEnrollment {
  ano: number;
  relacao_responsavel_estudante_id: number;
  unidade_id: number;
  segmento: number;
  ciclo_id: number;
  bolsa_id: number;
  desconto_reserva: number;
  desconto_mensalidade: number;
  desconto_material: number;
  mes_primeira_cobranca: number;
  tipo: number;
  vendedor_id: number;
}
export const createNewEnrollment = async (enrollment: ICreateNewEnrollment) => {
  const response = await KanataAPI.post<void>("enrollment/", enrollment).catch(
    ErrorHandlerFunction
  );
  if (response) {
    const data = {
      data: response.data,
      status: response.status,
    };
    return data;
  }
};
