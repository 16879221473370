// Style
import { SignedContractStyle } from "./style";
// Component
import { Button } from "components";
// Libs
import { useHistory, useLocation } from "react-router-dom";
// Interfaces
import { GetCXUserJson, StudentInterface } from "interfaces/constants";
import { DOCUMENTS_STATUS } from "helpers/constants";

type StateType = {
  student: StudentInterface;
  guardian: GetCXUserJson;
};

export const SignedContract = () => {
  const location = useLocation<StateType>();
  const history = useHistory();
  return (
    <SignedContractStyle>
      <div className="enrollment-title text-center mb-5 p-1">
        Matrícula de {location.state.student.name}
        {location.state.student.last_enrollment && (
          <div className="subtitle">
            {location.state.student.last_enrollment.grade || "Sem ano"}
            {location.state.student.last_enrollment.grade && "º ano do "}
            {location.state.student.last_enrollment.educational_level_name} -
            Unidade {location.state.student.last_enrollment.unit_name}
          </div>
        )}
      </div>
      <p className="text-center mb-0 p-1">
        Contrato assinado com sucesso! <br />
        Estamos validando a sua assinatura. <br />
        Nós enviaremos uma notificação para o seu e-mail assim que concluirmos a
        validação. <br />
        {location.state.student.last_enrollment.documents_status !==
          DOCUMENTS_STATUS.DOCUMENTO_OK &&
          "Você pode prosseguir com a matrícula enviando a documentação complementar."}
      </p>
      <div className="col-6 blue outline">
        <Button
          type="submit"
          className="blue outline"
          onClick={() => {
            location.state.student.last_enrollment.documents_status !==
            DOCUMENTS_STATUS.DOCUMENTO_OK
              ? history.push({
                  pathname: `/responsavel/meus-alunos/documentos-matricula`,
                  state: {
                    student: location.state.student,
                    guardian: location.state.guardian,
                  },
                })
              : history.push({
                  pathname: `/responsavel/meus-alunos`,
                  state: {
                    user: location.state.guardian,
                  },
                });
          }}
        >
          {location.state.student.last_enrollment.documents_status !==
          DOCUMENTS_STATUS.DOCUMENTO_OK
            ? "Enviar documentação"
            : "Voltar"}
        </Button>
      </div>
    </SignedContractStyle>
  );
};
