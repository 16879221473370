import * as I from "./type";
import * as S from "./styles";

export const Title = ({
  fontSize,
  fontWeight,
  darkGray,
  lineHeight,
  children,
  orange,
  scaleGrayTwo,
  left,
  right,
  marginBottom,
  pointer,
  ...rest
}: I.TextInterface) => {
  return (
    <S.Title
      {...rest}
      fontSize={fontSize}
      fontWeight={fontWeight}
      darkGray={darkGray}
      lineHeight={lineHeight}
      orange={orange}
      scaleGrayTwo={scaleGrayTwo}
      pointer={pointer}
      left={left}
      right={right}
      marginBottom={marginBottom}
    >
      {children}
    </S.Title>
  );
};

export const TitleAndSubTitle = ({ title, subtitle }: I.TitleAndSubTitle) => {
  return (
    <S.Container>
      <S.Title>{title}</S.Title>
      <S.SubTitle>{subtitle}</S.SubTitle>
    </S.Container>
  );
};
