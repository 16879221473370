import styled from "styled-components";
import * as colors from "styles/colors";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ButtonDownload = styled.button`
  border: none;
  background-color: transparent;

  display: flex;
  background-image: url("/icons/download.svg");
  background-size: cover;
  background-position: center center;

  width: 1rem;
  height: 1rem;
`;

export const Pagination = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  margin-top: 8px;
`;

export const Button = styled.button`
  outline: ${colors.NEW_PURPLE} solid 1px;
  width: auto;
  background: #fff;
  padding: 4px;
  border: none;
  margin: 0 8px;
`;
