/* eslint-disable array-callback-return */
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { Page, Text, View, Document, Image, Font } from "@react-pdf/renderer";
import {
  IFinalAverages,
  IFinalSchoolReportSubject,
  PdfBulletinPorps,
} from "./interface";
import { styles } from "./styles";

Font.register({
  family: "Montserrat",
  fonts: [
    {
      src: `/fonts/montserrat-v14-latin-regular.ttf`,
    },
    {
      src: `/fonts/montserrat-v14-latin-600.ttf`,
      fontWeight: "bold",
    },
    {
      src: `/fonts/montserrat-v14-latin-regular.ttf`,
      fontStyle: "italic",
    },
  ],
});

export const BoletimPDF = ({
  username,
  bulletin,
  quarter,
}: PdfBulletinPorps) => {
  const boletimFinalSchoolReport = new Map<string, any>(
    Object.entries(bulletin.final_schoolreport)
  );

  const boletimFinalAtividadesCurriculares = new Map<string, any>(
    Object.entries(bulletin.atividades_curriculares)
  );

  const [firstKey] = boletimFinalSchoolReport.keys();
  const [firstKeyAtividades] = boletimFinalAtividadesCurriculares.keys();

  const getFinalSchoolReportByTri = (tri: string, subject: string) => {
    if (!boletimFinalSchoolReport.get(tri)) {
      return {
        quarter_absences: "--",
        quarter_avg: "--",
        final_average: "--",
        final_average_status: "",
      };
    }

    if (tri === "Final Averages") {
      const finalNoteInfo = boletimFinalSchoolReport
        .get(tri)
        .find((finalNote: IFinalAverages) => finalNote.subject === subject);

      if (finalNoteInfo === undefined) {
        return {
          final_average: "--",
          final_average_status: "--",
        };
      }

      return {
        final_average: finalNoteInfo.final_average.toFixed(2),
        final_average_status: finalNoteInfo.final_average_status,
      };
    }

    const finalAvg = boletimFinalSchoolReport
      .get(tri)
      .find(
        (finalNote: IFinalSchoolReportSubject) => finalNote.subject === subject
      );

    return {
      quarter_absences: finalAvg?.quarter_absences
        ? finalAvg?.quarter_absences
        : "--",
      quarter_avg: finalAvg?.quarter_avg
        ? finalAvg?.quarter_avg === "number"
          ? finalAvg?.quarter_avg.toFixed(2)
          : finalAvg?.quarter_avg
        : "--",
    };
  };

  const getFinalAtividadesReport = (quarter: string, subject: string) => {
    if (!boletimFinalAtividadesCurriculares.get(quarter)) {
      return {
        quarter_absences: "--",
      };
    }

    const quarterAbsences = boletimFinalAtividadesCurriculares
      .get(quarter)
      .find(
        (finalNote: IFinalSchoolReportSubject) => finalNote.subject === subject
      );

    const { quarter_absences } = quarterAbsences ? quarterAbsences : "--";

    // const { quarter_absences } = boletimFinalAtividadesCurriculares
    //   .get(quarter)
    //   .find(
    //     (finalNote: IFinalSchoolReportSubject) => finalNote.subject === subject
    //   );

    return { quarter_absences };
  };

  const { t } = useTranslation();
  const prefix = "pages.AP.boletim.boletimPDF";

  return (
    <Document>
      <Page style={styles.page} orientation="landscape">
        <View>
          <Image style={styles.image} source="/logo-escola-mais.png" />
          <Text style={styles.title}>{t(`${prefix}.title`)}</Text>
          <Text style={styles.timestamp}>
            {t(`${prefix}.createdAt`, {
              username,
              created: dayjs(new Date()).format("DD/MM/YYYY HH:MM"),
            })}
          </Text>
        </View>
        <View style={styles.line}>
          <Text style={styles.subtitle}>
            {t(`${prefix}.grade`, {
              grade: bulletin.approval?.date_approved.split("/")[2],
            })}
          </Text>
        </View>
        <View style={styles.name}>
          <Text style={styles.column1}>{t(`${prefix}.studentName`)}</Text>
          <Text>{bulletin.name}</Text>
        </View>
        <View style={styles.name}>
          <Text style={styles.column1}>{t(`${prefix}.section`)}</Text>
          <Text>{bulletin.gradegroup}</Text>
        </View>
        <View style={styles.table}>
          <Text style={{ ...styles.largeColumn, ...styles.bold }}>
            {t(`${prefix}.area`)}
          </Text>
          <Text style={{ ...styles.smallColumn, ...styles.bold }}>
            {t(`${prefix}.absencesFirstQuarter`)}
          </Text>
          <Text style={{ ...styles.smallColumn, ...styles.bold }}>
            {t(`${prefix}.averageFirstQuarter`)}
          </Text>
          <Text style={{ ...styles.smallColumn, ...styles.bold }}>
            {t(`${prefix}.absencesSecondQuarter`)}
          </Text>
          <Text style={{ ...styles.smallColumn, ...styles.bold }}>
            {t(`${prefix}.averageSecondQuarter`)}
          </Text>
          <Text style={{ ...styles.smallColumn, ...styles.bold }}>
            {t(`${prefix}.absencesThirdQuarter`)}
          </Text>
          <Text style={{ ...styles.smallColumn, ...styles.bold }}>
            {t(`${prefix}.averageThirdQuarter`)}
          </Text>
          <Text style={{ ...styles.smallColumn, ...styles.bold }}>
            {t(`${prefix}.finalAverage`)}
          </Text>
          <Text style={{ ...styles.mediumColumn, ...styles.bold }}>
            {t(`${prefix}.finalStatus`)}
          </Text>
        </View>

        {boletimFinalSchoolReport
          .get(firstKey)
          .map(({ subject }: IFinalSchoolReportSubject) => {
            return (
              <View style={styles.line} key={subject}>
                <Text style={{ ...styles.largeColumn }}>{subject}</Text>
                <Text style={styles.smallColumn}>
                  {
                    getFinalSchoolReportByTri("1º Trimestre", subject)
                      .quarter_absences
                  }
                </Text>
                <Text style={styles.smallColumn}>
                  {
                    getFinalSchoolReportByTri("1º Trimestre", subject)
                      ?.quarter_avg
                  }
                </Text>
                <Text style={styles.smallColumn}>
                  {
                    getFinalSchoolReportByTri("2º Trimestre", subject)
                      .quarter_absences
                  }
                </Text>
                <Text style={styles.smallColumn}>
                  {
                    getFinalSchoolReportByTri("2º Trimestre", subject)
                      ?.quarter_avg
                  }
                </Text>
                <Text style={styles.smallColumn}>
                  {
                    getFinalSchoolReportByTri("3º Trimestre", subject)
                      ?.quarter_absences
                  }
                </Text>
                <Text style={styles.smallColumn}>
                  {
                    getFinalSchoolReportByTri("3º Trimestre", subject)
                      ?.quarter_avg
                  }
                </Text>
                <Text style={{ ...styles.smallColumn, ...styles.bold }}>
                  {
                    getFinalSchoolReportByTri("Final Averages", subject)
                      ?.final_average
                  }
                </Text>
                <Text style={styles.mediumColumn}>
                  {
                    getFinalSchoolReportByTri("Final Averages", subject)
                      ?.final_average_status
                  }
                </Text>
              </View>
            );
          })}

        {/* NOVA TABELA */}
        <View style={styles.table}>
          <Text style={{ ...styles.largeColumn, ...styles.bold }}>
            {t(`${prefix}.activities`)}
          </Text>
          <Text style={{ ...styles.smallColumn, ...styles.bold }}>F1</Text>
          <Text style={{ ...styles.smallColumn, ...styles.bold }}>F2</Text>
          <Text style={{ ...styles.smallColumn, ...styles.bold }}>F3</Text>
        </View>

        {boletimFinalAtividadesCurriculares.get(firstKeyAtividades) !==
        undefined ? (
          boletimFinalAtividadesCurriculares
            .get(firstKeyAtividades)
            ?.map(({ subject }: IFinalSchoolReportSubject) => {
              return (
                <View style={styles.line} key={subject}>
                  <Text style={{ ...styles.largeColumn }}>{subject}</Text>
                  <Text style={styles.smallColumn}>
                    {getFinalAtividadesReport("1º Trimestre", subject)
                      .quarter_absences
                      ? getFinalAtividadesReport("1º Trimestre", subject)
                          .quarter_absences
                      : "--"}
                  </Text>
                  <Text style={styles.smallColumn}>
                    {getFinalAtividadesReport("2º Trimestre", subject)
                      .quarter_absences
                      ? getFinalAtividadesReport("2º Trimestre", subject)
                          .quarter_absences
                      : "--"}
                  </Text>
                  <Text style={styles.smallColumn}>
                    {getFinalAtividadesReport("3º Trimestre", subject)
                      .quarter_absences
                      ? getFinalAtividadesReport("3º Trimestre", subject)
                          .quarter_absences
                      : "--"}
                  </Text>
                </View>
              );
            })
        ) : (
          <Text style={styles.noData}>{t(`${prefix}.noRegisters`)}</Text>
        )}

        <View style={styles.info}>
          <Text style={styles.legenda}>{t(`${prefix}.subtitle`)}</Text>
        </View>
      </Page>

      {/* PÁGINA 2 */}
      <Page style={styles.page} orientation="landscape" wrap>
        <View>
          <Image style={styles.image} source="/logo-escola-mais.png" />
          <Text style={styles.title}>{t(`${prefix}.title`)}</Text>
          <Text style={styles.timestamp}>
            {t(`${prefix}.createdAt`, {
              username,
              created: dayjs(new Date()).format("DD/MM/YYYY HH:MM"),
            })}
          </Text>
        </View>
        <View>
          <View>
            <Text style={styles.subtitle}>
              {t(`${prefix}.notesDetails`, { quarter })}
            </Text>
          </View>
        </View>

        {/* TABELA DETALHAMENTO */}
        {bulletin.subjects.map(({ evaluations_type, status, subject }) => {
          return (
            <View style={styles.scoreBox} key={subject}>
              <View style={styles.detailsHeader}>
                <Text style={styles.titleDetails}>{subject}</Text>
                <Text style={styles.titleDetails}>{status}</Text>
              </View>

              {evaluations_type.map(({ name, value }) => {
                return (
                  <View style={styles.detailsBox} key={name}>
                    <Text style={styles.textDetails}>{name}</Text>
                    <Text style={styles.textDetails}>
                      {t(`${prefix}.note`, { quarter })} {value || "--"}
                    </Text>
                  </View>
                );
              })}
            </View>
          );
        })}
      </Page>
    </Document>
  );
};
