import { FieldRenderProps } from "react-final-form";
import { ErrorLabel, EyeIcon, InputField, Label, LabelFloat } from "./style";
import { faCheck, faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import SearchIcon from "@material-ui/icons/Search";

type Props = FieldRenderProps<string, any>;

const TextInput: React.FC<Props> = ({
  input,
  meta,
  label,
  setShowPassword,
  setSearch,
  showPassword,
  hideCheck,
  ...rest
}: Props) => {
  return (
    <LabelFloat className={meta.touched && meta.error ? "invalid" : ""}>
      <div className="d-flex" {...rest}>
        <InputField
          className={meta.touched && meta.error ? "invalid" : ""}
          {...input}
          {...rest}
        />
        {!hideCheck && meta.touched && !meta.error && (
          <EyeIcon>
            <FontAwesomeIcon icon={faCheck as IconProp} />
          </EyeIcon>
        )}
        {label && (
          <Label
            className={meta.touched && meta.error ? "invalid" : ""}
            {...rest}
            htmlFor={input.name}
          >
            {setSearch && (
              <div>
                <SearchIcon style={{ marginRight: "5px" }} />
              </div>
            )}
            {label}
          </Label>
        )}
        {setShowPassword && (
          <EyeIcon onClick={() => setShowPassword(!showPassword)}>
            <FontAwesomeIcon
              icon={
                showPassword ? (faEyeSlash as IconProp) : (faEye as IconProp)
              }
            />
          </EyeIcon>
        )}
      </div>
      {meta.touched && meta.error && <ErrorLabel>{meta.error}</ErrorLabel>}
    </LabelFloat>
  );
};

export default TextInput;
