import { useHistory } from "react-router-dom";
import { CoordinatorContainer } from "./style";
import { ButtonCard } from "components/atoms/ButtonCard";
import { useTranslation } from "react-i18next";
import { IsDevelopmentEnvironment } from "helpers/helpers";

const TRANSLATION_PREFIX = "pages.CORE.home.enrollmentCoordinator";

export const EnrollmentCoordinatorHome = () => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <CoordinatorContainer>
      <ButtonCard
        onClick={() => history.push("/responsavel/busca-por-cpf")}
        text={t(`${TRANSLATION_PREFIX}.bookSpot`)}
      />
      <ButtonCard
        onClick={() => history.push("/responsavel/busca")}
        text={t(`${TRANSLATION_PREFIX}.searchGuardianOrStudent`)}
      />
      <ButtonCard
        onClick={() => history.push("/responsavel/Consultar/documentos")}
        text={t(`${TRANSLATION_PREFIX}.consultDocuments`)}
      />
      <ButtonCard
        onClick={() => history.push("/gerenciar/matriculas")}
        text={t(`${TRANSLATION_PREFIX}.manageEnrollmentProcessQuery`)}
      />
      <ButtonCard
        onClick={() => history.push("/lista-espera")}
        text={t(`${TRANSLATION_PREFIX}.waitingList`)}
      />
      <ButtonCard
        onClick={() => history.push("/gerenciar-vagas")}
        text={t(`${TRANSLATION_PREFIX}.manageVacancies`)}
      />
      <ButtonCard
        onClick={() => history.push("/cadastrar-periodo-matricula-rematricula")}
        text={t(`${TRANSLATION_PREFIX}.periodEnrollmentReenrollment`)}
      />
    </CoordinatorContainer>
  );
};
