import styled from "styled-components";
import { LIGHT_PINK } from "styles/colors";

export const Step1Styled = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 1em;

  span.monthName {
    text-transform: uppercase;
    font-weight: bold;
  }

  div.formContainer {
    margin-top: 2.5em;
  }

  div.valueTotal {
    margin-top: 2.5em;
    display: flex;
    justify-content: space-between;
  }

  div.button {
    display: flex;
    justify-content: space-between;
  }
`;
