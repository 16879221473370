import styled from "styled-components";
import * as colors from "styles/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .student-info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
  }

  .paymentSuccess {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.space[8]};

    strong {
      margin-top: ${({ theme }) => theme.space[4]};
    }

    .how-it-works {
      text-align: center;
      width: 80%;
    }

    & > svg {
      color: ${colors.NEW_GREEN};
    }

    button {
      margin-top: 30px;
    }

    .totalPix {
      width: 100%;
      font-size: 18px; // não possui ds-token
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      display: flex;
      justify-content: space-between;
      margin-top: ${({ theme }) => theme.space[2]};
      margin-bottom: 0;
    }
  }

  @media (max-width: 1024px) {
    margin: 0 ${({ theme }) => theme.space[4]};
  }
`;
