import styled from "styled-components";

export const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 539px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.space[6]} ${({ theme }) => theme.space[4]};
  text-align: center;

  .close-button {
    margin-left: auto;
    border: none;
    background-color: transparent;
    padding: 0 ${({ theme }) => theme.space[2]};
  }

  svg {
    width: 30.83px;
    height: 30.83px;
  }

  strong {
    font-size: ${({ theme }) => theme.fontSizes.web.h3};
    margin-top: ${({ theme }) => theme.space[2]};
  }

  p {
    font-size: ${({ theme }) => theme.fontSizes.web.sm};
    margin-top: ${({ theme }) => theme.space[2]};
  }
`;
