import styled from "styled-components";
import { IContainerProps } from "./type";

export const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

export const Container = styled.div<IContainerProps>`
  width: 100%;
  max-width: ${({ width }) => (width ? width : "539px")}; // não possui ds-token
  background: #fff;
  border-radius: 16px;
  box-shadow: 1px 3px 4px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 16px;
  text-align: center;

  svg {
    width: 30.83px;
    height: 30.83px;
  }

  strong {
    font-size: 24px;
    margin-top: 8px;
  }

  p {
    font-size: 14px;
    margin-top: 8px;
  }
`;
