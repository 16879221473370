import styled from "styled-components";
import * as colors from "styles/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .student-info {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
  }

  .paymentSuccess {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.space[8]};

    strong {
      margin-top: ${({ theme }) => theme.space[4]};
    }

    p {
      margin-bottom: ${({ theme }) => theme.space[4]};
    }

    svg {
      color: ${colors.NEW_GREEN};
    }

    button {
      margin-top: 30px;
    }

    .resetSteps {
      width: 100%; // não possui ds-token
      border: none;
      height: 52px; // não possui ds-token
      border: 2px solid ${({ theme }) => theme.colors.primaryDefault};
      background-color: transparent;
      font-weight: 700;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.primaryDefault};
      margin-top: ${({ theme }) => theme.space[4]};
      font-size: 14px;
    }
  }

  @media (max-width: 1024px) {
    margin: 0 ${({ theme }) => theme.space[4]};
  }
`;
