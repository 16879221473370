import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Title, Button } from "components/atoms";
import { ILocationFoodPage } from "./interface";

import * as S from "./styles";

export const FoodPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const i18_prefix = "pages.EDF.foodPage";
  const { state } = useLocation<ILocationFoodPage>();

  return (
    <S.Container>
      <Title>{t(`${i18_prefix}.title`)}</Title>

      <div className="foodOptions">
        <div className="option">
          <div className="infos">
            <strong>{t(`${i18_prefix}.monthPlan`)}</strong>
            <p className="m-0">{t(`${i18_prefix}.monthPlanContent`)}</p>
          </div>

          <Button
            onClick={() =>
              history.push({
                pathname: "/responsavel/alimentacao/pacote-alimentacao",
                state: {
                  user: state.user,
                },
              })
            }
          >
            {t(`${i18_prefix}.buy`)}
          </Button>
        </div>

        <div className="option">
          <div className="infos">
            <strong>{t(`${i18_prefix}.singlePurchase`)}</strong>
            <p className="m-0">{t(`${i18_prefix}.singlePurchaseContent`)}</p>
          </div>
          <Button
            onClick={() =>
              history.push({
                pathname: "/alimentacao/compra-avulsa",
                state: {
                  user: state.user,
                },
              })
            }
          >
            {t(`${i18_prefix}.buy`)}
          </Button>
        </div>
      </div>
    </S.Container>
  );
};
