import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./styles";

import { IStepStatus } from "./type";

enum InvoiceStatusEnum {
  Reserved = "Reservado",
  Paid = "Pago",
  Canceled = "Cancelado",
  Free = "Isento de pagamento",
  AwaitingPayment = "Aguardando pagamento",
}

export const StepStatus = ({ type }: IStepStatus) => {
  const { t } = useTranslation();
  const stepType = type === "Concluído" ? "success" : "progress";

  return (
    <S.StepStatus type={stepType}>
      <span>
        {t("components.atoms.stepStatus.state", {
          stepType: type === "Concluído" ? "Concluído" : "Em Andamento",
        })}
      </span>
    </S.StepStatus>
  );
};

export const StepPaymentStatus = ({ type, scholarship }: IStepStatus) => {
  const [stepType, setStepType] = useState<string>();

  useEffect(() => {
    const returnType = (type: string) => {
      if (scholarship) {
        switch (type) {
          case "success":
            return setStepType(InvoiceStatusEnum.Reserved);
          default:
            return setStepType(InvoiceStatusEnum.Free);
        }
      } else {
        switch (type) {
          case "success":
            return setStepType(InvoiceStatusEnum.Paid);
          case "canceled":
            return setStepType(InvoiceStatusEnum.Canceled);
          default:
            return setStepType(InvoiceStatusEnum.AwaitingPayment);
        }
      }
    };

    returnType(type);
  }, [scholarship, type]);

  const { t } = useTranslation();

  return (
    <S.PaymentStatus type={type}>
      <span>{t("components.atoms.stepStatus.state", { stepType })}</span>
    </S.PaymentStatus>
  );
};
