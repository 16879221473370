import styled, { css } from "styled-components";
import { IIcons } from "./interface";

export const HeaderPagination = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  padding: 22.1px 26.17px; // TODO: nao tem na lib

  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.web.h4};
  line-height: 30px; // TODO: nao tem na lib
  color: ${({ theme }) => theme.colors.greyScale95};

  button {
    border: none;
    background-color: transparent;
    :disabled {
      cursor: not-allowed;
    }
  }
`;

export const Img = styled.img<IIcons>`
  height: ${({ theme }) => theme.space[5]};
  width: 11.7px; // TODO: nao tem na lib

  ${({ enabled }) =>
    enabled &&
    css`
      filter: invert(16%) sepia(100%) saturate(3797%) hue-rotate(262deg)
        brightness(87%) contrast(95%);
    `}
`;
