import styled from "styled-components";

export const SkeletonCard = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${({ theme }) => theme.space[2]};
  margin-top: ${({ theme }) => theme.space[2]};
  padding-bottom: 9.5px;

  .skeleton-card-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${({ theme }) => theme.space[2]};
  }

  .skeleton-card-score {
    display: flex;
    align-items: center;
    align-self: end;
  }

  .skeleton-card-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .bar {
    color: #d1b9f8;
  }
`;
