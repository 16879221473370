import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.default};
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1360px;
  padding: ${({ theme }) => theme.space[2]};
  margin: ${({ theme }) => theme.space[2]};
`;

export const Header = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  padding: ${({ theme }) => theme.space[2]};
  margin: ${({ theme }) => theme.space[2]};
`;

export const Content = styled.div`
  width: 100%;

  padding: ${({ theme }) => theme.space[2]};
  margin: ${({ theme }) => theme.space[2]};

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Card = styled.div`
  min-width: 300px;

  padding: ${({ theme }) => theme.space[2]};
  margin-right: ${({ theme }) => theme.space[2]};

  border: 2px solid ${({ theme }) => theme.colors.primaryDefault};

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  cursor: pointer;

  img {
    width: 50px;
    height: 50px;

    margin-right: ${({ theme }) => theme.space[2]};

    border-radius: ${({ theme }) => theme.radii.full};
  }

  p {
    margin: 0;
    padding: 0;
  }
`;

export const CardContent = styled.div`
  width: calc(100% - 50px);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const TitleCard = styled.p`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
`;
