import styled from "styled-components";

// FilterBox Styles
export const FilterWrapper = styled.div`
  width: 100%;
`;

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  padding: ${({ theme }) => theme.space[1]};
  border-radius: ${({ theme }) => theme.radii.xs};
  gap: ${({ theme }) => theme.space[2]};
  border: 2px solid #999;
`;

export const Button = styled.button<{ bg: string }>`
  border: none;
  border-radius: ${({ theme }) => theme.radii.xs};
  padding: ${({ theme }) => theme.space[1]};
  font-weight: bold;
  width: 100%;
  background-color: ${({ bg }) => bg};
`;

export const ButtonText = styled.span<{ color: string }>`
  color: ${({ color }) => color};
`;

// End FilterBox Styles

// AprovacaoPreviaFilter Styles

export const FirstLayerContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space[5]};
  margin-top: ${({ theme }) => theme.space[10]};
`;

export const SecondLayerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 28px 0 30px 0;
`;

export const CurrentClassText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.web.h4};
  font-weight: 600;
`;

export const SelectsWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space[4]};
`;

// End AprovacaoPreviaFilter Styles

// InputUnidadeWrapper Styles
export const InputUnidadeWrapper = styled.div`
  display: flex;
  justify-content: end;
  flex-direction: column;
`;

export const Select = styled.select`
  min-height: 42px;
  margin-right: 0.5rem;
  border: 2px solid #999999;
`;
