// Style
import { Table } from "./style";
// Components
import { CheckboxInput } from "components/atoms";
// Helpers
import { standardDate } from "helpers/helpers";
import { TableHelper } from "./helpers";
// Interfaces
import { ITableBadge } from "./interface";
// Libs
import { useTranslation } from "react-i18next";

export const GenerateBadgeFileTable = ({
  studentList,
  secondForm,
  setSecondForm,
  allStudents,
}: ITableBadge) => {
  const { setEnrollment } = TableHelper({ secondForm, setSecondForm });

  const { t } = useTranslation();
  const prefix = "pages.EDF.badgeFiles.components.table";

  return (
    <Table>
      <thead>
        <tr>
          <th></th>
          <th>{t(`${prefix}.student`)}</th>
          <th>{t(`${prefix}.ra`)}</th>
          <th>{t(`${prefix}.unit`)}</th>
          <th>{t(`${prefix}.grade`)}</th>
          <th>{t(`${prefix}.photoUpdate`)}</th>
          <th>{t(`${prefix}.photoGenerated`)}</th>
        </tr>
      </thead>
      <tbody>
        {studentList.results.map((student, index) => {
          return (
            <tr className="body" key={index}>
              <td>
                <CheckboxInput
                  checked={
                    secondForm.enrollments.includes(student.id) || allStudents
                  }
                  value="true"
                  name="student"
                  onChange={(e) => {
                    setEnrollment(student.id);
                  }}
                  disabled={allStudents}
                />
              </td>
              <td>{student.student.nome}</td>
              <td>{student.student.ra}</td>
              <td>{student.unit.name}</td>
              <td>
                {student.grade}º {student.section ? student.section : "Ano"}
              </td>
              <td>
                {student.date_update_photo
                  ? standardDate(student.date_update_photo)
                  : t(`${prefix}.doesNotOwn`)}
              </td>
              <td>
                {student.last_date_export_photo
                  ? standardDate(student.last_date_export_photo)
                  : t(`${prefix}.doesNotOwn`)}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
