import styled from "styled-components";
import * as colors from "styles/colors";

export const ProfilePictureStyled = styled.div`
  // PC
  /* width: 77px;
  height: 77px; */
  // Celular
  width: 56px;
  height: 56px;
  background-color: ${colors.LIGHT_GRAY_ONE};
  border-radius: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ChangePhoto = styled.div`
  color: ${colors.NEW_PURPLE};
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  cursor: pointer;
`;
