// React
import { useContext, useEffect, useState } from "react";
// Libs
import Icon from "@mdi/react";
import { mdiContentCopy } from "@mdi/js";
import { useTranslation } from "react-i18next";
import HTTP_STATUS from "http-status-codes";
import moment from "moment";
// Components
import { toastInfo } from "components/Toast";
// Interfaces
import { FoodPackagePaymentInterface } from "interfaces/paymentInterfaces";
// Styles
import * as S from "./styles";
// Interfaces
import { ILocationSelectMeals, IStep } from "pages/EDF/SelectMeals/type";
import { getConsultSingleFoodTransactions } from "services/meals.service";
import { SingleFoodTransactionsInterface } from "interfaces/mealInterfaces";
import { IsDevelopmentEnvironment } from "helpers/helpers";

interface IPix {
  setTransaction: (transaction: SingleFoodTransactionsInterface) => void;
  next: () => void;
  state: ILocationSelectMeals;
  paymentData: FoodPackagePaymentInterface | null;
}

export const Pix = ({ paymentData, state, next, setTransaction }: IPix) => {
  const { t } = useTranslation();
  const i18_prefix =
    "pages.EDF.selectMeals.components.stepThree.components.pix";

  const messageCopied = () => {
    toastInfo(t(`${i18_prefix}.toastInfo`));
  };

  const [newSearch, setNewSearch] = useState(false);

  const getSingleFoodTransactions = async () => {
    const response = await getConsultSingleFoodTransactions({
      guardianId: state.guardianUser.id,
      date: moment(new Date()).format("YYYY-MM-DD"),
    });

    if (response && response.status === HTTP_STATUS.OK) {
      if (state.payment) {
        if (
          response.data.results.find(
            (order: SingleFoodTransactionsInterface) =>
              order.id === state.purchaseId &&
              order.invoice_status === "Transação Aprovada"
          )
        ) {
          setTransaction(
            response.data.results.find(
              (order: SingleFoodTransactionsInterface) =>
                order.id === state.purchaseId
            )
          );
          next();
        }
      } else if (
        response.data.results[response.data.results.length - 1]
          .invoice_status === "Transação Aprovada"
      ) {
        next();
      }
    }
  };

  useEffect(() => {
    if (newSearch) {
      getSingleFoodTransactions();
      setNewSearch(false);
    } else {
      setTimeout(() => setNewSearch(true), 20000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newSearch]);

  return (
    <S.Container>
      <img src={paymentData?.code} alt="QR Code" />
      <div
        className="pixDigitalBox"
        onClick={() => {
          navigator.clipboard.writeText(`${paymentData?.pay_ticket}`);
          messageCopied();
        }}
      >
        <p className="mb-0">{paymentData?.pay_ticket}</p>

        <Icon path={mdiContentCopy} />
      </div>
    </S.Container>
  );
};
