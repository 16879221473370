import styled, { css } from "styled-components";
import { device } from "styles/breakpoints";
import * as colors from "styles/colors";
import * as I from "./type";

export const Title = styled.h1<I.TextInterface>`
  width: ${(props) => (props.width ? `${props.width}` : "auto")};

  font-size: ${(props) => (props.fontSize ? props.fontSize : "24px")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "700")};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "36px")};
  color: ${colors.NEW_PURPLE};

  margin-bottom: ${(props) => props.marginBottom && props.marginBottom};

  // Colors
  ${(props) => props.darkGray && `color: ${colors.NEW_GRAY_TWO};`}
  ${(props) => props.scaleGray && `color: ${colors.GRAY_SCALE_THREE};`}
  ${(props) => props.orange && `color: ${colors.NEW_ORANGE};`}


  ${(props) => props.center && `text-align: center;`}
  ${(props) => props.left && `text-align: left;`}
  ${(props) => props.right && `text-align: right;`}
  ${(props) => props.scaleGrayTwo && `color: ${colors.GRAY_SCALE};`}

  ${(props) => props.pointer && "cursor: pointer;"}

  ${(props) =>
    props.responsive &&
    css`
      @media ${device.sm} {
        font-size: 14px;
      }

      @media ${device.md} {
        font-size: ${props.fontSize ? props.fontSize : "16px"};
      }

      @media ${device.lg} {
        font-size: ${props.fontSize ? props.fontSize : "32px"};
      }
    `}
`;

export const SubTitle = styled.span``;

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  ${Title} {
    font-weight: bold;
    color: #00aeefff;
  }

  ${SubTitle} {
    font-size: 16px;
    font-weight: bold;
    color: #ec008b;
  }
`;
