import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > div {
    width: 100%;
    max-width: 720px;
  }

  p {
    text-align: left;
  }

  .foodOptions {
    display: flex;
    flex-direction: column;

    div + div {
      margin-top: ${({ theme }) => theme.space[2]};
    }

    .btn-group {
      display: flex;
      gap: ${({ theme }) => theme.space[4]};
      margin-top: 50px; // não possui ds-token
      margin-bottom: ${({ theme }) => theme.space[8]};
    }
  }

  @media (max-width: 1024px) {
    margin: 0 16px;
  }
`;

export const LoadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
