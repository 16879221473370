import styled from "styled-components";
import * as colors from "styles/colors";

export const IndicationStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1em;

  margin: 0 auto;

  max-width: 634px;

  & > div {
    max-width: 634px;
    width: 100%;
  }

  img.logo {
    height: 159px;
  }

  p {
    font-size: 16px;
    line-height: 24px;
  }

  div.discountRules {
    margin-top: 1em;
    font-size: 14px;
    line-height: 21px;
    div.button {
      margin-bottom: 0.5em;

      button {
        display: flex;
        align-items: center;
      }
    }
    ul {
      span {
        color: ${colors.NEW_PURPLE};
        font-weight: 600;
      }
      p {
        margin-left: -25px;
      }
    }
  }

  @media (max-width: 624px) {
    p {
      font-size: 12px;
      line-height: 21px;
    }

    width: 360px;

    img.logo {
      height: 100px;
    }

    div.discountRules {
      font-size: 12px;
      line-height: 18px;
    }
  }

  @media (max-width: 320px) {
    width: 320px;

    img.logo {
      height: 80px;
    }
  }
`;

export const LoadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
