import { useRef, useReducer, useEffect } from "react";
import {
  AprovacaoPreviaActions,
  AprovacaoPreviaState,
  AprovacaoPreviaActionTypes,
  DataItem,
  DataTableProps,
  Section,
  ButtonGroupProps,
  QuarterEnum,
  Student,
} from "./types";
import { Storage } from "Storage";
import {
  getFilterData,
  getQuarterList,
  getTableData,
} from "services/aprovacao-previa.service";
import { toastError } from "components/Toast";
import { isCX } from "helpers/constants";
import { getUnitsGrades } from "services/grades.service";
import HTTP_STATUS from "http-status-codes";
import { UnitDropDown } from "interfaces/unit";
import { SchoolReportResponse } from "components/BoletimV2/types";

const INITIAL_STATE: AprovacaoPreviaState = {
  isCxView: false,
  originalDataTable: {} as DataTableProps,
  dataTable: {} as DataTableProps,
  currentClass: "",
  selectedStudent: {} as Student,
  studentSchoolReport: {} as SchoolReportResponse,
  loadings: {
    loadingDataTable: true,
    loadingUnitDropDownList: true,
    loadingBoletimV2Drawer: false,
  },
  errors: {
    dataTableError: false,
    quarterDropDownListError: false,
    loadingUnitDropDownListError: false,
  },
  filter: {
    levelButtons: null,
    gradeButtons: null,
    sectionButtons: null,
    quarterDropDownList: null,
    statusDropDownList: null,
    unitDropdownList: null,
    quarterActiveId: "",
    levelButtonActiveId: "",
    gradeActiveId: "",
    sectionActiveId: "",
    statusActiveId: "",
    unitDropdownActiveId: "",
    areAllActiveStudents: true,
  },
  drawer: {
    isOpen: false,
  },
};

// obtem os itens unicos da lista na estrutura de dados que o backend retorna.
const getUniqueItems = (
  items: DataItem[],
  field: keyof DataItem & string
): DataItem[] => {
  const uniqueItems: { [key: string]: DataItem } = {};
  items.forEach((item) => {
    const key = item[field];
    if (key && typeof key === "string" && !uniqueItems[key]) {
      uniqueItems[key] = item;
    }
  });
  return Object.values(uniqueItems);
};

// obtem as turmas unicas
const getUniqueSections = (
  items: Section[],
  field: keyof Section & string
): Section[] => {
  const uniqueItems: { [key: string]: Section } = {};
  items.forEach((item) => {
    const key = item[field];
    if (key && typeof key === "string" && !uniqueItems[key]) {
      uniqueItems[key] = item;
    }
  });
  return Object.values(uniqueItems);
};

const filterOnlyActiveStudents = (students: Student[]) => {
  return students.filter((student) => !student.deleted);
};

const filterOnlyInactiveStudents = (students: Student[]) => {
  return students.filter((student) => student.deleted);
};

const aprovacaoPreviaReducer = (
  state: AprovacaoPreviaState,
  action: AprovacaoPreviaActions
): AprovacaoPreviaState => {
  switch (action.type) {
    case AprovacaoPreviaActionTypes.OPEN_DRAWER: {
      return {
        ...state,
        drawer: {
          ...state.drawer,
          isOpen: true,
        },
      };
    }

    case AprovacaoPreviaActionTypes.CLOSE_DRAWER: {
      return {
        ...state,
        drawer: {
          ...state.drawer,
          isOpen: false,
        },
      };
    }

    case AprovacaoPreviaActionTypes.SET_DATA_TABLE_SUCCESS: {
      return {
        ...state,
        dataTable: {
          ...action.payload,
          students: filterOnlyActiveStudents(action.payload.students),
        },
        originalDataTable: action.payload,
        loadings: {
          ...state.loadings,
          loadingDataTable: false,
        },
        errors: {
          ...state.errors,
          dataTableError: false,
        },
      };
    }

    case AprovacaoPreviaActionTypes.SET_DATA_TABLE_FILTERED: {
      return {
        ...state,
        dataTable: {
          ...action.payload,
          students: filterOnlyActiveStudents(action.payload.students),
        },
      };
    }

    case AprovacaoPreviaActionTypes.SET_DATA_TABLE_ERROR: {
      return {
        ...state,
        errors: {
          ...state.errors,
          dataTableError: action.payload,
        },
      };
    }

    case AprovacaoPreviaActionTypes.DATA_TABLE_LOADING: {
      return {
        ...state,
        loadings: {
          ...state.loadings,
          loadingDataTable: action.payload,
        },
      };
    }

    case AprovacaoPreviaActionTypes.SET_FILTER_LEVEL_BUTTONS: {
      return {
        ...state,
        filter: {
          ...state.filter,
          levelButtons: action.payload,
        },
      };
    }

    case AprovacaoPreviaActionTypes.SET_LEVEL_BUTTON_ACTIVE_ID: {
      return {
        ...state,
        filter: {
          ...state.filter,
          levelButtonActiveId: action.payload,
        },
      };
    }

    case AprovacaoPreviaActionTypes.SET_FILTER_GRADE_BUTTONS: {
      return {
        ...state,
        filter: {
          ...state.filter,
          gradeButtons: action.payload,
        },
      };
    }

    case AprovacaoPreviaActionTypes.SET_GRADE_BUTTON_ACTIVE_ID: {
      return {
        ...state,
        filter: {
          ...state.filter,
          gradeActiveId: action.payload,
        },
      };
    }

    case AprovacaoPreviaActionTypes.SET_FILTER_SECTION_BUTTONS: {
      return {
        ...state,
        filter: {
          ...state.filter,
          sectionButtons: action.payload,
        },
      };
    }

    case AprovacaoPreviaActionTypes.SET_SECTION_BUTTON_ACTIVE_ID: {
      return {
        ...state,
        filter: {
          ...state.filter,
          sectionActiveId: action.payload,
        },
      };
    }

    case AprovacaoPreviaActionTypes.SET_CURRENT_CLASS_NAME: {
      return {
        ...state,
        currentClass: action.payload,
      };
    }

    case AprovacaoPreviaActionTypes.SET_QUARTERS_DROPDOWN_ERROR: {
      return {
        ...state,
        filter: {
          ...state.filter,
          quarterDropDownList: null,
        },
        errors: {
          ...state.errors,
          quarterDropDownListError: action.payload,
        },
      };
    }

    case AprovacaoPreviaActionTypes.SET_QUARTERS_DROPDOWN_SUCCESS: {
      return {
        ...state,
        filter: {
          ...state.filter,
          quarterDropDownList: action.payload,
        },
        errors: {
          ...state.errors,
          quarterDropDownListError: false,
        },
      };
    }

    case AprovacaoPreviaActionTypes.SET_QUARTER_ACTIVE_ID: {
      return {
        ...state,
        filter: {
          ...state.filter,
          quarterActiveId: action.payload as string,
        },
      };
    }

    case AprovacaoPreviaActionTypes.SET_STATUS_DROPDOWN_SUCCESS: {
      return {
        ...state,
        filter: {
          ...state.filter,
          statusDropDownList: action.payload,
        },
      };
    }

    case AprovacaoPreviaActionTypes.SET_STATUS_DROPDOWN_ACTIVE_ID: {
      return {
        ...state,
        filter: {
          ...state.filter,
          statusActiveId: action.payload,
        },
      };
    }

    case AprovacaoPreviaActionTypes.SET_CX_VIEW: {
      return {
        ...state,
        isCxView: true,
      };
    }

    case AprovacaoPreviaActionTypes.GET_UNIT_DROPDOWN: {
      return {
        ...state,
        currentClass: "",
        errors: {
          ...state.errors,
          loadingUnitDropDownListError: false,
        },
        loadings: {
          ...state.loadings,
          loadingUnitDropDownList: true,
          loadingDataTable: true,
        },
      };
    }

    case AprovacaoPreviaActionTypes.GET_UNIT_DROPDOWN_SUCCESS: {
      return {
        ...state,
        filter: {
          ...state.filter,
          unitDropdownList: action.payload,
        },
        errors: {
          ...state.errors,
          loadingUnitDropDownListError: false,
        },
        loadings: {
          ...state.loadings,
          loadingUnitDropDownList: true,
        },
      };
    }

    case AprovacaoPreviaActionTypes.GET_UNIT_DROPDOWN_ERROR: {
      return {
        ...state,
        filter: {
          ...state.filter,
          unitDropdownList: null,
        },
        errors: {
          ...state.errors,
          loadingUnitDropDownListError: true,
        },
        loadings: {
          ...state.loadings,
          loadingUnitDropDownList: false,
        },
      };
    }

    case AprovacaoPreviaActionTypes.SET_UNIT_ACTIVE_ID: {
      return {
        ...state,
        filter: {
          ...state.filter,
          unitDropdownActiveId: action.payload,
        },
      };
    }

    case AprovacaoPreviaActionTypes.FILTER_ONLY_ACTIVE_STUDENTS: {
      return {
        ...state,
        filter: {
          ...state.filter,
          areAllActiveStudents: true,
        },
        dataTable: {
          ...state.originalDataTable,
          students: filterOnlyActiveStudents(state.originalDataTable?.students),
        },
      };
    }

    case AprovacaoPreviaActionTypes.FILTER_ONLY_INACTIVE_STUDENTS: {
      return {
        ...state,
        filter: {
          ...state.filter,
          areAllActiveStudents: false,
        },
        dataTable: {
          ...state.originalDataTable,
          students: filterOnlyInactiveStudents(
            state.originalDataTable?.students
          ),
        },
      };
    }

    case AprovacaoPreviaActionTypes.SET_SELECTED_STUDENT: {
      return {
        ...state,
        selectedStudent: action.payload,
      };
    }

    case AprovacaoPreviaActionTypes.BOLETIMV2_DRAWER_LOADING: {
      return {
        ...state,
        loadings: {
          ...state.loadings,
          loadingBoletimV2Drawer: action.payload,
        },
      };
    }

    case AprovacaoPreviaActionTypes.SET_STUDENT_SCHOOL_REPORT: {
      return {
        ...state,
        studentSchoolReport: action.payload,
      };
    }
  }
};

export const useAprovacaoPreviaHelper = () => {
  const dataList = useRef<DataItem[] | null>(null);
  const [state, dispatch] = useReducer(aprovacaoPreviaReducer, INITIAL_STATE);
  const user = Storage.props.authUser.get();
  const role = Storage.props.role.get();
  const UNIT_ID_TO_DISCONSIDER = 4638; // sempre desconsiderar este ID no dropdown

  // Ciclo
  const handleLevelChange = async (level: string) => {
    if (!dataList.current?.length) {
      return;
    }

    dispatch({
      type: AprovacaoPreviaActionTypes.DATA_TABLE_LOADING,
      payload: true,
    });

    dispatch({
      type: AprovacaoPreviaActionTypes.SET_CURRENT_CLASS_NAME,
      payload: "",
    });

    const gradeButtons: Array<ButtonGroupProps> = getUniqueItems(
      dataList.current.filter((item) => item.level_name === level),
      "grade"
    ).map((item) => {
      return {
        id: item.grade,
        onClick: () => handleGradeChange(item.grade, item.level_name),
        buttonText: item.grade,
      };
    });

    const filteredSections = dataList.current
      .filter(
        (item) => item.level_name === level && item.grade === gradeButtons[0].id
      )
      .flatMap((item) => item.sections);
    const sectionButtons: Array<ButtonGroupProps> = getUniqueSections(
      filteredSections,
      "letter"
    ).map((section) => ({
      id: section.id.toString(),
      onClick: () => handleSectionChange(section.id.toString()),
      buttonText: section.letter,
    }));

    dispatch({
      type: AprovacaoPreviaActionTypes.SET_FILTER_GRADE_BUTTONS,
      payload: gradeButtons,
    });

    dispatch({
      type: AprovacaoPreviaActionTypes.SET_FILTER_SECTION_BUTTONS,
      payload: sectionButtons,
    });

    dispatch({
      type: AprovacaoPreviaActionTypes.SET_LEVEL_BUTTON_ACTIVE_ID,
      payload: level,
    });

    dispatch({
      type: AprovacaoPreviaActionTypes.SET_GRADE_BUTTON_ACTIVE_ID,
      payload: gradeButtons[0].id.toString(),
    });

    dispatch({
      type: AprovacaoPreviaActionTypes.SET_SECTION_BUTTON_ACTIVE_ID,
      payload: sectionButtons[0].id.toString(),
    });

    await handleFetchTableData({
      quarterActiveId: QuarterEnum.SEGUNDO_TRIMESTRE.toString(),
      sectionActiveId: sectionButtons[0].id.toString(),
    });
  };

  // Ano
  const handleGradeChange = async (grade: string, level: string) => {
    if (!dataList.current?.length) {
      return;
    }

    dispatch({
      type: AprovacaoPreviaActionTypes.DATA_TABLE_LOADING,
      payload: true,
    });

    dispatch({
      type: AprovacaoPreviaActionTypes.SET_CURRENT_CLASS_NAME,
      payload: "",
    });

    const filteredSections = dataList.current
      .filter((item) => item.level_name === level && item.grade === grade)
      .flatMap((item) => item.sections);
    const sectionButtons: Array<ButtonGroupProps> = getUniqueSections(
      filteredSections,
      "letter"
    ).map((section) => ({
      id: section.id.toString(),
      onClick: () => handleSectionChange(section.id.toString()),
      buttonText: section.letter,
    }));

    dispatch({
      type: AprovacaoPreviaActionTypes.SET_GRADE_BUTTON_ACTIVE_ID,
      payload: grade,
    });

    dispatch({
      type: AprovacaoPreviaActionTypes.SET_SECTION_BUTTON_ACTIVE_ID,
      payload: sectionButtons[0].id.toString(),
    });

    dispatch({
      type: AprovacaoPreviaActionTypes.SET_FILTER_SECTION_BUTTONS,
      payload: sectionButtons,
    });

    await handleFetchTableData({
      quarterActiveId: QuarterEnum.SEGUNDO_TRIMESTRE.toString(),
      sectionActiveId: sectionButtons[0].id.toString(),
    });
  };

  // Turma
  const handleSectionChange = async (section: string) => {
    dispatch({
      type: AprovacaoPreviaActionTypes.SET_SECTION_BUTTON_ACTIVE_ID,
      payload: section,
    });
    dispatch({
      type: AprovacaoPreviaActionTypes.DATA_TABLE_LOADING,
      payload: true,
    });
    dispatch({
      type: AprovacaoPreviaActionTypes.SET_CURRENT_CLASS_NAME,
      payload: "",
    });

    await handleFetchTableData({
      quarterActiveId: QuarterEnum.SEGUNDO_TRIMESTRE.toString(),
      sectionActiveId: section,
    });
  };

  // Status
  const handleChangeStatus = (status: string) => {
    if (!status || status === "todos") {
      dispatch({
        type: AprovacaoPreviaActionTypes.SET_DATA_TABLE_FILTERED,
        payload: state.originalDataTable,
      });
      return;
    }

    const filteredStudentsByStatus = state.originalDataTable.students.filter(
      (student) => student.release_status === status
    );

    const data: DataTableProps = {
      availableReleases: [...state.dataTable.availableReleases],
      students: filteredStudentsByStatus,
    };

    dispatch({
      type: AprovacaoPreviaActionTypes.SET_DATA_TABLE_FILTERED,
      payload: data,
    });
  };

  // Unidade
  const handleChangeUnit = async (unit: string) => {
    if (!unit) {
      return;
    }

    dispatch({
      type: AprovacaoPreviaActionTypes.SET_UNIT_ACTIVE_ID,
      payload: unit,
    });

    await handleFetchFilterData(unit);
  };

  // Trimestre
  const handleChangeQuarter = async (quarterActiveId: string) => {
    dispatch({
      type: AprovacaoPreviaActionTypes.DATA_TABLE_LOADING,
      payload: true,
    });
    dispatch({
      type: AprovacaoPreviaActionTypes.SET_QUARTER_ACTIVE_ID,
      payload: quarterActiveId,
    });

    if (state.filter.sectionButtons) {
      await handleFetchTableData({
        quarterActiveId,
        sectionActiveId: state.filter.sectionButtons[0].id.toString(),
      });
    }
  };

  // GETS
  // Quarter (trimestre)
  const handleFetchQuarters = async () => {
    try {
      const res = await getQuarterList();

      if (!res?.data) {
        toastError("Ocorreu um erro ao buscar os dados do trimestre.");
        return;
      }

      dispatch({
        type: AprovacaoPreviaActionTypes.SET_QUARTERS_DROPDOWN_SUCCESS,
        payload: res.data,
      });
      dispatch({
        type: AprovacaoPreviaActionTypes.SET_QUARTER_ACTIVE_ID,
        payload: QuarterEnum.SEGUNDO_TRIMESTRE.toString(),
      });

      await handleFetchFilterData();
    } catch (err) {
      console.log(err);

      toastError("Ocorreu um erro ao buscar os dados do trimestre.");
      dispatch({
        type: AprovacaoPreviaActionTypes.SET_QUARTERS_DROPDOWN_ERROR,
        payload: true,
      });
    }
  };

  // Filter Data
  const handleFetchFilterData = async (unitId?: string) => {
    try {
      let finalUnits: Array<UnitDropDown> | null;
      if (isCX(role)) {
        dispatch({ type: AprovacaoPreviaActionTypes.GET_UNIT_DROPDOWN });

        const unitsGrade = await getUnitsGrades();

        if (unitsGrade && unitsGrade.status === HTTP_STATUS.OK) {
          const units: Array<UnitDropDown> = unitsGrade.data.map(
            (unit: UnitDropDown) => {
              return { name: unit.name, id: unit.id };
            }
          );

          finalUnits = units.filter(
            (unit) => unit.id !== UNIT_ID_TO_DISCONSIDER
          );

          dispatch({
            type: AprovacaoPreviaActionTypes.GET_UNIT_DROPDOWN_SUCCESS,
            payload: finalUnits,
          });
        } else {
          dispatch({
            type: AprovacaoPreviaActionTypes.GET_UNIT_DROPDOWN_ERROR,
          });
          toastError("Ocorreu um erro ao buscar a lista de unidades.");
          return;
        }
      }

      const res = await getFilterData(unitId ?? user.grade_unit_id);

      if (!res?.data) {
        toastError("Ocorreu um erro ao buscar os dados do filtro.");
        return;
      }

      dataList.current = res.data;

      const levelButtons: Array<ButtonGroupProps> = getUniqueItems(
        res.data,
        "level_name"
      ).map((item) => ({
        id: item.level_name,
        buttonText: item.level_name,
        onClick: () => handleLevelChange(item.level_name),
      }));
      const gradeButtons: Array<ButtonGroupProps> = getUniqueItems(
        res.data.filter((item) => item.level_name === res.data[0].level_name),
        "grade"
      ).map((item) => {
        return {
          id: item.grade, // o id deve ser o item.grade pois o componente faz a verificação de active pelo id
          onClick: () => handleGradeChange(item.grade, item.level_name),
          buttonText: item.grade,
        };
      });
      const filteredSections = res.data
        .filter(
          (item) =>
            item.level_name === res.data[0].level_name &&
            item.grade === res.data[0].grade
        )
        .flatMap((item) => item.sections);
      const sectionButtons: Array<ButtonGroupProps> = getUniqueSections(
        filteredSections,
        "letter"
      ).map((section) => ({
        id: section.id.toString(),
        onClick: () => handleSectionChange(section.id.toString()),
        buttonText: section.letter,
      }));

      dispatch({
        type: AprovacaoPreviaActionTypes.SET_FILTER_LEVEL_BUTTONS,
        payload: levelButtons,
      });
      dispatch({
        type: AprovacaoPreviaActionTypes.SET_FILTER_GRADE_BUTTONS,
        payload: gradeButtons,
      });
      dispatch({
        type: AprovacaoPreviaActionTypes.SET_FILTER_SECTION_BUTTONS,
        payload: sectionButtons,
      }); // TODO: Criar uma única action para setar os botões dos filtros

      // inicializar o filtro com a primeira opção selecionada
      dispatch({
        type: AprovacaoPreviaActionTypes.SET_LEVEL_BUTTON_ACTIVE_ID,
        payload: res.data[0].level_name,
      });

      dispatch({
        type: AprovacaoPreviaActionTypes.SET_GRADE_BUTTON_ACTIVE_ID,
        payload: res.data[0].grade,
      });

      dispatch({
        type: AprovacaoPreviaActionTypes.SET_SECTION_BUTTON_ACTIVE_ID,
        payload: res.data[0].sections[0].id.toString(),
      }); // TODO: Criar uma única action para setar os filtros ativos

      await handleFetchTableData({
        quarterActiveId: QuarterEnum.SEGUNDO_TRIMESTRE.toString(),
        sectionActiveId: res.data[0].sections[0].id.toString(),
      });
    } catch (error) {
      toastError("Ocorreu um erro ao processar os dados do filtro.");
      console.log(error);
    }
  };

  // Table Data
  const handleFetchTableData = async ({
    quarterActiveId,
    sectionActiveId,
  }: {
    quarterActiveId: string;
    sectionActiveId: string;
  }) => {
    try {
      if (!quarterActiveId || !sectionActiveId) {
        return;
      }

      const res = await getTableData(sectionActiveId, quarterActiveId);

      if (!res?.data) {
        toastError("Ocorreu um erro ao buscar os dados da tabela.");
        return;
      }

      const payload = {
        availableReleases: res.data.results[0].available_releases,
        students: res.data.results[0].students,
      };
      dispatch({
        type: AprovacaoPreviaActionTypes.SET_DATA_TABLE_SUCCESS,
        payload,
      });

      dispatch({
        type: AprovacaoPreviaActionTypes.SET_CURRENT_CLASS_NAME,
        payload: res.data.results[0].name,
      });

      const releaseStatuses = new Set(
        res.data.results[0].students.map((student) => student.release_status)
      );

      const releaseStatusOptions = Array.from(releaseStatuses).map((status) => {
        return {
          statusName: status,
          id: status,
        };
      });

      dispatch({
        type: AprovacaoPreviaActionTypes.SET_STATUS_DROPDOWN_SUCCESS,
        payload: releaseStatusOptions,
      });
      dispatch({
        type: AprovacaoPreviaActionTypes.FILTER_ONLY_ACTIVE_STUDENTS,
      });
    } catch (error) {
      console.log(error);
      toastError("Ocorreu um erro ao processar os dados da tabela.");
      dispatch({
        type: AprovacaoPreviaActionTypes.SET_DATA_TABLE_ERROR,
        payload: true,
      });
    }

    dispatch({
      type: AprovacaoPreviaActionTypes.DATA_TABLE_LOADING,
      payload: false,
    });
  };

  const fetchData = async () => {
    await handleFetchQuarters();
  };

  const handleFilterActiveStudents = () => {
    const isEveryStudentActive = state.dataTable?.students?.every(
      (student) => !student.deleted
    );
    const hasFilteredStudents =
      state.dataTable?.students && state.dataTable?.students.length > 0;
    const hasStudentData = state.dataTable?.students?.length > 0;

    if (isEveryStudentActive && hasStudentData && hasFilteredStudents) {
      dispatch({
        type: AprovacaoPreviaActionTypes.FILTER_ONLY_INACTIVE_STUDENTS,
      });
    }

    if (!isEveryStudentActive && hasStudentData && hasFilteredStudents) {
      dispatch({
        type: AprovacaoPreviaActionTypes.FILTER_ONLY_ACTIVE_STUDENTS,
      });
    }

    if (!hasFilteredStudents && hasStudentData) {
      dispatch({
        type: AprovacaoPreviaActionTypes.FILTER_ONLY_ACTIVE_STUDENTS,
      });
    }
  };

  // efeito para buscar os dados no backend ao iniciar a tela
  useEffect(() => {
    if (isCX(role)) {
      dispatch({ type: AprovacaoPreviaActionTypes.SET_CX_VIEW });
      dispatch({
        type: AprovacaoPreviaActionTypes.SET_UNIT_ACTIVE_ID,
        payload: "4639",
      });
    }

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    state,
    dataList,
    dispatch,
    getUniqueItems,
    getUniqueSections,
    handleLevelChange,
    handleGradeChange,
    handleSectionChange,
    handleChangeStatus,
    handleChangeUnit,
    handleChangeQuarter,
    handleFilterActiveStudents,
  };
};
