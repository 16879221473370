import styled from "styled-components";
// import * as colors from "styles/colors";

export const ConfirmGuardianDataStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 0 auto;

  /* remover comentario quando o guardian tiver mais dados */
  /* max-width: 636px; */

  /* remover linha abaixo quando o guardian tiver mais dados */
  max-width: 500px;

  & > div {
    max-width: 636px;
    width: 100%;
  }

  div.cardData {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 16px;

    width: 100%;

    background: rgba(109, 40, 217, 0.05);
    border-radius: 29px;

    div.top {
      display: flex;
      align-items: center;
      padding: 1em;
      margin-top: 0.2em;
      width: 100%;
    }

    div.editData {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    div.wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;

      &.documents {
        justify-content: unset;
      }

      > div {
        width: 150px;
        margin-top: 20px;

        @media (max-width: 565px) {
          width: 50%;
          margin-top: 20px;
        }
      }
    }

    div.data {
      margin: 0.5em 0px 0.5em 1em;
    }
  }

  div.button {
    margin-top: 1.5em;
    max-width: 636px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    button {
      width: 100%;
    }
  }

  @media (max-width: 1024px) {
    margin: 0 16px;
  }
`;

export const LoadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
