import styled from "styled-components";
import { device } from "../../../../../styles/breakpoints";

export const Container = styled.div`
  p {
    font-family: "Poppins", serif;
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
  }
  cursor: pointer;
  @media ${device.xss} {
    width: 100%;
  }
  @media ${device.md} {
    width: 391px;
  }
`;
