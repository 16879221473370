import styled, { css } from "styled-components";

export const Container = styled.ul`
  margin: 0;
  background-color: ${({ theme }) => theme.colors.greyScale5};
  padding: 12px 8px 0 8px;
`;

export const CalendarItem = styled.li<{ active: boolean }>`
  cursor: pointer;

  padding: 5.5px 8px;

  ${({ active }) => css`
    background: ${active ? "#fff" : "transparent"};
  `}

  &:hover {
    background: #00000010; // TODO add to lib
  }
`;
