/* eslint-disable react-hooks/exhaustive-deps */
import Typography from "@material-ui/core/Typography";
import DescriptionIcon from "@material-ui/icons/Description";
import PaymentIcon from "@material-ui/icons/Payment";
import { Button, LoadingBox } from "components";
import { formatCEP, formatMoney } from "helpers/helpers";
import HTTP_STATUS from "http-status-codes";
import {
  EnrollmentInterface,
  GetCXUserJson,
  StudentInterface,
} from "interfaces/constants";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getMatriculaValue } from "services/payment.service";
import PixIcon from "static/img/PixIcon.png";
import Placeholder from "static/img/placeholder.png";
import { BLUE } from "styles/colors";
import { Avatar, AvatarContainer, BorderContainer, TextSpan } from "./styles";

export * from "./components/Boleto";
export * from "./components/CartaoCredito";
export * from "./components/MultiplePay";
export * from "./components/Pix";
export * from "./components/SecondCreditCard";

type StateType = {
  student: StudentInterface;
  guardianId: number;
  enrollment: EnrollmentInterface;
  guardian: GetCXUserJson;
};

export const PagamentoReserva = () => {
  const history = useHistory();
  const location = useLocation<StateType>();
  const [valorMatricula, setValorMatricula] = useState<number>();
  const [student] = useState(location.state.student);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchValorMatricula = async () => {
      const response = await getMatriculaValue({
        student_id: student.id,
      });
      if (response && response.status === HTTP_STATUS.OK) {
        setValorMatricula(response.data / 100);
      }
    };

    try {
      setLoading(true);
      fetchValorMatricula();
      setLoading(false);
    } catch (err) {
      setError(true);
      setLoading(false);
    }
  }, []);

  return (
    <div className="container">
      {error ? (
        <>
          <div className="row justify-content-center">
            <div className="col-8 text-center mt-5">
              <Typography variant="h4">
                Ocorreu um erro ao carregar os dados do pagamento, tente
                novamente mais tarde.
              </Typography>
            </div>
            <div className="col-6 mt-5">
              <Button
                onClick={() => {
                  history.goBack();
                }}
              >
                {" "}
                Voltar{" "}
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          {loading ? (
            <>
              <div className="row justify-content-center">
                <div className="col-12">
                  <LoadingBox height={50} />
                </div>
                <div className="col-8 text-center mt-3">
                  <Typography variant="h5">
                    Carregando as opções de pagamento!
                  </Typography>
                </div>
              </div>
            </>
          ) : (
            <>
              {valorMatricula && (
                <div className="col-lg-6 mx-auto">
                  <div className="row">
                    <div className="col-12 text-center mb-1">
                      Confira os dados abaixo para o pagamento:
                    </div>
                  </div>
                  <BorderContainer className="mb-3 pb-1">
                    <AvatarContainer>
                      <div className="row">
                        <div className="col-4 mb-2 text-center">
                          <Avatar src={Placeholder} />
                        </div>
                        <div className="col-8">
                          <p>
                            <strong>{location.state.student.name} </strong>
                          </p>
                          {location.state.student.last_enrollment && (
                            <p>
                              <strong>
                                {location.state.student.last_enrollment.grade}º
                                ano{" "}
                              </strong>
                              <strong>
                                {
                                  location.state.student.last_enrollment
                                    .unit_name
                                }
                              </strong>
                            </p>
                          )}
                        </div>
                      </div>
                    </AvatarContainer>
                    <div className="row pl-3 pr-3 mt-3">
                      <div className="col-12">
                        <strong>Nome do Responsável: </strong>
                        {location.state.guardian.personal.name}
                      </div>
                    </div>
                    <div className="row pl-3 pr-3">
                      <div className="col-12">
                        <strong>CPF do Responsável: </strong>
                        {location.state.guardian.personal.cpf}
                      </div>
                    </div>
                    <div className="row pl-3 pr-3">
                      <div className="col-12">
                        <strong>CEP: </strong>
                        {location.state.guardian.location.zip_code
                          ? formatCEP(location.state.guardian.location.zip_code)
                          : "Não possui CEP"}
                      </div>
                      <div className="col-12">
                        <strong>Endereço: </strong>
                        {location.state.guardian.location.address
                          ? `${location.state.guardian.location.address}, nº ${location.state.guardian.location.number}`
                          : `Endereço não informado`}
                      </div>
                      <div className="col-12">
                        <strong>Complemento: </strong>
                        {location.state.guardian.location.complement ||
                          "Não possui complemento"}
                      </div>
                      <div className="col-12">
                        <strong>Bairro: </strong>
                        {location.state.guardian.location.neighborhood ||
                          "Não possui bairro"}
                      </div>
                      <div className="col-12">
                        <strong>Cidade: </strong>
                        {location.state.guardian.location.city ||
                          "Não possui cidade"}
                      </div>
                      <div className="col-12">
                        <strong>UF: </strong>
                        {location.state.guardian.location.uf || "Não possui UF"}
                      </div>
                    </div>
                    <div className="row justify-content-center mt-3">
                      <div className="col-12 text-center">
                        <TextSpan>Valor da Reserva de Vaga: </TextSpan>
                        <TextSpan>{formatMoney(valorMatricula)}</TextSpan>
                      </div>
                      <div className="col-12 text-center">
                        <span className="h5">*Valor não reembolsável</span>
                      </div>
                      <div className="col-12 text-center mt-3">
                        <div className="h5">Como você prefere pagar?</div>
                      </div>
                    </div>
                    <div className="row mt-3 justify-content-center mb-5">
                      <div className="col-10 text-center mt-2">
                        <Button
                          style={{ backgroundColor: BLUE }}
                          className="p-2"
                          onClick={() => {
                            history.push({
                              pathname: "/pagamento/reserva-de-vaga/pix",
                              state: {
                                studentId: student.id,
                                enrollmentId: student.last_enrollment.id,
                                guardianId: location.state.guardianId,
                                student,
                                user: location.state.guardian,
                                valorMatricula,
                              },
                            });
                          }}
                        >
                          <img
                            className="mr-4 mb-1"
                            height={24}
                            src={PixIcon}
                            alt="Pix"
                          />
                          <span>Pix</span>
                        </Button>
                      </div>
                      <div className="col-10 text-center mt-2">
                        <Button
                          style={{ backgroundColor: BLUE }}
                          className="p-2"
                          onClick={() => {
                            history.push({
                              pathname: "/pagamento/reserva-de-vaga/cartao",
                              state: {
                                studentId: student.id,
                                guardianId: location.state.guardianId,
                                student,
                                user: location.state.guardian,
                                valorMatricula,
                              },
                            });
                          }}
                        >
                          <PaymentIcon className="mr-4 mb-1" />
                          <span>Cartão de Crédito</span>
                        </Button>
                      </div>
                      {/* <div className="col-10 text-center mt-2">
                        <Button
                          style={{ backgroundColor: BLUE }}
                          className="p-2"
                          onClick={() =>
                            history.push({
                              pathname: "/pagamento/reserva-de-vaga/boleto",
                              state: {
                                studentId: student.id,
                                guardianId: location.state.guardianId,
                                enrollmentId: student.last_enrollment.id,
                                student,
                                user: location.state.guardian,
                                valorMatricula,
                              },
                            })
                          }
                        >
                          <DescriptionIcon className="mr-4 mb-1" />
                          <span>Boleto</span>
                        </Button>
                      </div> */}
                      <div className="col-10 text-center mt-2">
                        <Button
                          style={{ backgroundColor: BLUE }}
                          className="p-2"
                          onClick={() =>
                            history.push({
                              pathname: "/pagamento/reserva-de-vaga/multipay",
                              state: {
                                studentId: student.id,
                                guardianId: location.state.guardianId,
                                student,
                                user: location.state.guardian,
                                valorMatricula,
                                enrollment: location.state.enrollment,
                              },
                            })
                          }
                        >
                          <PaymentIcon className="mr-4 mb-1" />
                          <span>Dois Cartões de Crédito</span>
                        </Button>
                      </div>
                    </div>
                  </BorderContainer>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
