import { SchoolReportResponse } from "components/BoletimV2/types";
import { GradeAPI } from "./api";
import {
  DataItem,
  Quarter,
  ReleaseCheckProps,
} from "pages/AP/AprovacaoPrevia/types";

export const getQuarterList = async () => {
  try {
    const response = await GradeAPI.get<Array<Quarter>>(`classes/quarter`);

    if (!response) {
      throw new Error();
    }

    return response;
  } catch (error) {
    throw new Error(`Falha no serviço: getQuarterList`, error);
  }
};

export const getFilterData = async (unitId: string) => {
  try {
    const response = await GradeAPI.get<DataItem[]>(
      `classes/gradeyear/${unitId}?year=2023&full=s`
    );

    if (!response) {
      throw new Error();
    }

    return response;
  } catch (error) {
    throw new Error(`Falha no serviço: getFilterData`, error);
  }
};

export const getTableData = async (
  sectionActiveId: string,
  quarterActiveId: string
) => {
  try {
    const response = await GradeAPI.get<ReleaseCheckProps>(
      `classes/release_check/?gradegroup_id=${sectionActiveId}&quarter_id=${quarterActiveId}`
    );

    if (!response) {
      throw new Error();
    }

    return response;
  } catch (error) {
    throw new Error(`Falha no serviço: getTableData`, error);
  }
};

interface SchoolReportRequest {
  quarterId: string;
  studentId: number;
}

export const getSchoolReport = async ({
  quarterId,
  studentId,
}: SchoolReportRequest) => {
  try {
    const response = await GradeAPI.get<SchoolReportResponse>(
      `classes/school_report?quarter_id=${quarterId}&student_id=${studentId}`
    );

    if (!response) {
      throw new Error();
    }

    return response;
  } catch (error) {
    throw new Error(`Falha no serviço: getTableData`, error);
  }
};
