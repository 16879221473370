import {
  IExitPermission,
  IExitPermitList,
  IResponseEscort,
  ITransportation,
} from "interfaces/exitPermit";
import { KanataAPI } from "./api";
import { ErrorHandlerFunction, Headers } from "./service-helpers";

interface IGetExitPermit {
  student_id: number;
}

export const getExitPermit = async ({ student_id }: IGetExitPermit) => {
  const response = await KanataAPI.get<IExitPermission>(
    `controle_acesso_fisico/horario_saida/${student_id}/`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const getTransportation = async () => {
  const response = await KanataAPI.get<ITransportation>(
    `controle_acesso_fisico/transportation_type/`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface IRegisterEscort {
  payload: {
    nome_completo: string;
    grau_parentesco: string;
    cpf: string;
    telefone: string;
    estudante: number;
    localizacao: {
      ipv4: string;
      cidade: string;
      estado: string;
      pais: string;
      cep: string;
      latitude: number;
      longitude: number;
      dispositivo: string;
    };
  };
}

export const postRegisterEscort = async ({ payload }: IRegisterEscort) => {
  const response = await KanataAPI.post<IResponseEscort>(
    `controle_acesso_fisico/responsavel_saida/`,
    payload
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface IUpdateEscort {
  payload: {
    nome_completo: string;
    grau_parentesco: string;
    cpf: string;
    telefone: string;
    localizacao: {
      ipv4: string;
      cidade: string;
      estado: string;
      pais: string;
      cep: string;
      latitude: number;
      longitude: number;
      dispositivo: string;
    };
  };
  escort_id: number;
}

export const updateEscort = async ({ payload, escort_id }: IUpdateEscort) => {
  const response = await KanataAPI.put<IResponseEscort>(
    `controle_acesso_fisico/responsavel_saida/${escort_id}/`,
    payload
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface IRemoveEscort {
  escort_id: number;
}

export const removeEscort = async ({ escort_id }: IRemoveEscort) => {
  const response = await KanataAPI.delete<IResponseEscort>(
    `controle_acesso_fisico/responsavel_saida/${escort_id}/`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { status: response.status };
    return data;
  }
};

interface ITransportationUpdate {
  student_id: number;
  payload: {
    transportation: number;
  };
}

export const updateExitPermit = async ({
  student_id,
  payload,
}: ITransportationUpdate) => {
  const response = await KanataAPI.put<IResponseEscort>(
    `controle_acesso_fisico/horario_saida/${student_id}/`,
    payload
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface IExitReport {
  student_name: string;
  unit: number;
  exit_authorization: number;
}

export const exitReportDownload = async ({
  student_name,
  unit,
  exit_authorization,
}: IExitReport) => {
  const response = await KanataAPI.get(
    `controle_acesso_fisico/relatorio_saida_export/?unit=${unit}${
      exit_authorization ? `&autorizacao_saida=${exit_authorization}` : ""
    }${student_name ? `&student_name=${student_name}` : ""}`,
    Headers({
      responseType: "arraybuffer",
    })
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = {
      data: response.data,
      status: response.status,
    };
    return data;
  }
};

interface IExitPermitReport {
  page?: number;
  student_name?: string;
  unit: number;
  exit_permit?: number;
}

export const getExitPermitReport = async ({
  page,
  student_name,
  unit,
  exit_permit,
}: IExitPermitReport) => {
  const response = await KanataAPI.get<IExitPermitList>(
    `controle_acesso_fisico/lista_autorizacao_saida_estudante/?unit=${unit}${
      exit_permit ? `&autorizacao_saida=${exit_permit}` : ""
    }${student_name ? `&student_name=${student_name}` : ""}${
      page && page > 1 ? `&page=${page}` : ""
    }`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};
