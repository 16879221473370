import { useHistory, useLocation } from "react-router-dom";
import { Button, StudentBox } from "components/atoms";
import { IStudentCard } from "./interface";
import { ILocationFoodPage } from "pages/EDF/FoodPage/interface";
import { StudentHeader } from "../StudentHeader";

export const StudentCard = ({ studentInfo }: IStudentCard) => {
  const history = useHistory();
  const location = useLocation<ILocationFoodPage>();

  return (
    <StudentBox>
      <StudentHeader student={studentInfo} />

      <Button
        margin
        onClick={() =>
          history.push({
            pathname: "/alimentacao/compra-avulsa/refeicoes",
            state: {
              studentUser: studentInfo,
              guardianUser: location.state.user,
            },
          })
        }
      >
        Comprar
      </Button>
    </StudentBox>
  );
};
