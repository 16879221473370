import {
  GetCXUserJson,
  NewGuardianResponse,
  SearchGuardianInterface,
} from "interfaces/constants";
import { StudentFormDataInterface } from "pages/EDF/EditStudent/components/StudentForm/helpers";
import { KanataAPI } from "../services/api";
import { ErrorHandlerFunction } from "./service-helpers";

export interface UserFormDataInterface {
  name: string;
  birth_date: string;
  cpf: string;
  rg: string;
  email: string;
  phone: string;
  zip_code: string;
  city: string;
  address: string;
  number: string;
  uf: string;
  complement: string;
  neighborhood: string;
}

/**
 * Get CX User Service - KANATA
 *
 * @param id - number
 * @param token - string
 * @returns GetCXUserJson
 */
export const getCXUser = async ({ id }: { id: number }) => {
  const response = await KanataAPI.get<GetCXUserJson>(`cx/user/${id}`).catch(
    ErrorHandlerFunction
  );
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const getStudentData = async ({ id }: { id: number }) => {
  const response = await KanataAPI.get<GetCXUserJson>(`cx/student/${id}`).catch(
    ErrorHandlerFunction
  );
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

//O body aqui não serve pra nada???
export const updateStudentPedagogicInfo = async ({ id, body }: any) => {
  const response = await KanataAPI.patch<GetCXUserJson>(
    `cx/user/pedagogical/${id}`,
    body
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const sendNotificationsFirstAccessGuardian = async ({ body }: any) => {
  const response = await KanataAPI.post<GetCXUserJson>(
    `cx/guardian/send_email_first_access/`,
    body
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const updateUser = async ({
  id,
  role,
  body,
}: {
  id?: number;
  role: string;
  body:
    | UserFormDataInterface
    | StudentFormDataInterface
    | { guard_type: number };
}) => {
  const response = await KanataAPI.patch<GetCXUserJson>(
    `cx/${role}/${id}`,
    body
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export type RegisterNewGuardianType = {
  body: {
    email: string;
    name: string;
    password: string;
    phone: string;
    rg: string;
    birth_date: string;
    cpf: string;
    send_email_finish?: boolean;
    reference_code?: string;
  };
};

export const registerNewGuardianUserWithoutToken = async ({
  body,
}: RegisterNewGuardianType) => {
  body.send_email_finish = false;
  const response = await KanataAPI.post<NewGuardianResponse>(
    `cx/guardian_no_token`,
    body
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const searchGuardian = async (
  filter_text: string,
  user_role: string,
  page: number
) => {
  const response = await KanataAPI.get<SearchGuardianInterface>(
    `cx/filter?page=${page}&filter_text=${filter_text}${
      user_role ? `&user_role=${user_role}` : ""
    }`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface RegisterNewStudentPreSignUpInterface {
  name: string;
  rg: string;
  birth_date: string;
  cpf: string;
  previous_school?: string;
  gender_type: number;
}

/**
 * Register New Student Pre-SignUp - KANATA
 *
 * @param name - string
 * @param rg - string
 * @param birth_date - string
 * @param cpf - string
 * @param previous_school - string (Optional)
 *
 * @returns
 */
export const registerNewStudentPreSignUp = async ({
  name,
  rg,
  birth_date,
  cpf,
  previous_school,
  gender_type,
}: RegisterNewStudentPreSignUpInterface) => {
  const response = await KanataAPI.post<RegisterNewStudentPreSignUpPayload>(
    "cx/student/pre_signup",
    { name, rg, birth_date, cpf, previous_school, gender_type }
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface BondStudentoToGuardianInterface {
  guardianId: number;
  studentId: number;
  guard_type: number;
}

/**
 *
 */
export const bondStudentToGuardian = async ({
  guardianId,
  studentId,
  guard_type,
}: BondStudentoToGuardianInterface) => {
  const response = await KanataAPI.post<bondStudentToGuardianPayload>(
    "cx/guard",
    { guardian: guardianId, student: studentId, guard_type }
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface bondStudentToGuardianPayload {
  id: number;
  guardian: number;
  student: number;
  guard_type: number;
  kinship: string;
  contract_digitalized: string;
  guard_relation?: number[];
}

export interface RegisterNewStudentPreSignUpPayload {
  ra: string;
  user: string;
  id: number;
  name: string;
  email: string;
  grade: string;
  section: string;
  rg: string;
  rg_digitalized: string;
  cpf: string;
  cpf_digitalized: string;
  date_file_with_photos_generated: string;
  address_check: string;
  address: string;
  birth_certificate: string;
  scholarship_transfer_certificate: string;
  scholarship_historic: string;
  number: string;
  school_name: string;
  neighborhood: string;
  complement: string;
  city: string;
  zip_code: string;
  birth_date: string;
  unit: string;
  blood_type: string;
  allergies: string;
  medicines: string;
  diseases: string;
  surgeries: string;
  health_insurance: string;
  health_insurance_expire_date: string;
  health_insurance_hospital: string;
  health_insurance_number: string;
  is_deleted: boolean;
  deleted_at: string;
  last_modified: string;
  photo_url: string;
  external_uuid: string;
  phone: string;
  previous_school: string;
}

interface UpdateGuardInterface {
  body: any;
  guard_id: string;
}

export const updateGuard = async ({ body, guard_id }: UpdateGuardInterface) => {
  const response = await KanataAPI.patch<SearchGuardianInterface>(
    `cx/guard/${guard_id}`,
    body
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

type ChekingSerasaValuesType = {
  document: string;
  enrollment: number;
};

type ChekingSerasaType = {
  body: ChekingSerasaValuesType;
};

export const checkingSerasa = async ({ body }: ChekingSerasaType) => {
  const response = await KanataAPI.post<ChekingSerasaValuesType>(
    `cx/check_serasa_score`,
    body
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export interface VendorListResponse {
  id: number;
  user: number;
  external_uuid: string;
  unit: number;
  name: string;
  email: string;
  phone: string;
  rg: string;
  cpf: string;
  badge: string;
  last_modified: string;
  is_deleted: boolean;
  deleted_at: string;
  employee_type_id: number;
  employee_type_display: string;
}

export const getVendorList = async () => {
  const response = await KanataAPI.get<Array<VendorListResponse>>(
    `cx/employee?employee_type=[17,19]`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};
