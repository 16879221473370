// React
import { useContext, useEffect, useState } from "react";
// Style
import * as S from "./style";
// Libs
import moment from "moment";
import { Field, Form } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { useTranslation } from "react-i18next";
import HTTP_STATUS from "http-status-codes";
// Components
import { SelectInputFinalForm, TableBulletin, Title } from "components";
import {
  Button,
  CheckboxInput,
  Loading,
  TableColumn,
  TableRow,
  TableWithChildren,
  Text,
} from "components/atoms";
import { toastError } from "components/Toast";
// Interfaces
import { FormValuesInterface } from "./interface";
import {
  FoodItemsByEnrollmentInterface,
  UpdateFoodItemByEnrollment,
} from "interfaces/mealInterfaces";
// Services
import {
  GetUnitsGradesSectionsResponse,
  getUnitsGradesSectionsSchoolPlaces,
} from "services/units.service";
import {
  getFoodItemsByEnrollment,
  updateFoodItemsByEnrollment,
} from "services/meals.service";

export const FoodRecord = () => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.foodRecord";

  // Initial
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [units, setUnits] = useState<Array<GetUnitsGradesSectionsResponse>>();

  // Pega lista de alunos com os filtros selecionados
  const [studentsInfos, setStudentsInfos] =
    useState<FoodItemsByEnrollmentInterface | null>();

  // Salva dados da consulta
  const [formValues, setFormValues] = useState<FormValuesInterface>();

  // Lista de itens
  const food_items = studentsInfos?.results[0].food_items;

  useEffect(() => {
    (async () => {
      const unitsGrade = await getUnitsGradesSectionsSchoolPlaces();
      if (unitsGrade && unitsGrade.status === HTTP_STATUS.OK) {
        setUnits(unitsGrade.data);
        setLoading(false);
      } else {
        setError(true);
        setLoading(false);
      }
    })();
  }, []);

  const onSubmit = async (values: FormValuesInterface) => {
    setFormValues(values);
    setStudentsInfos(null);
    setLoading(true);
    const response = await getFoodItemsByEnrollment({
      unit: values.unit,
      educational_level: values.educational_level,
      grade: values.grade,
      section: values.section,
      date: values.date,
    });

    if (response && response.status === HTTP_STATUS.OK) {
      setStudentsInfos(response.data);
      setLoading(false);
    } else {
      toastError(t(`${TRANSLATE}.toastError`));
      setLoading(false);
    }
  };

  const updateFoodItem = async (
    enrollment: number,
    foodItem: UpdateFoodItemByEnrollment
  ) => {
    setLoading(true);
    const foodItem_: UpdateFoodItemByEnrollment = foodItem;

    if (foodItem_.contracted === true) {
      foodItem_.contracted = false;
    } else {
      foodItem_.contracted = true;
    }

    const food_items: Array<UpdateFoodItemByEnrollment> = [foodItem_];

    if (formValues) {
      const body = {
        food_items,
        date: formValues.date,
      };

      const response = await updateFoodItemsByEnrollment({
        body,
        enrollmentId: enrollment,
      });

      if (response && response.status !== HTTP_STATUS.OK) {
        toastError(t(`${TRANSLATE}.toastError2`));
        onSubmit(formValues);
      }
      setLoading(false);
    }
  };

  if (loading && !units) {
    return (
      <S.LoadContainer>
        <h4>{t(`${TRANSLATE}.loadingUnits`)}</h4>
        <Loading />
      </S.LoadContainer>
    );
  }

  if (error && !loading) {
    return (
      <S.LoadContainer>
        <div className="ml-3">{t(`${TRANSLATE}.errorText`)}</div>
      </S.LoadContainer>
    );
  }

  if (units && !error) {
    return (
      <S.FoodRecordStyled>
        <Title fontSize="32" fontWeight="700" lineHeight="48px">
          {t(`${TRANSLATE}.title`)}
        </Title>
        <Form
          initialValues={{
            birth_date: moment().format("DD/MM/YYYY"),
          }}
          onSubmit={onSubmit}
        >
          {({ form, handleSubmit, submitting, values }) => (
            <form onSubmit={handleSubmit}>
              <div className="inputContainer">
                <div className="inputBeyond">
                  <Field
                    component={SelectInputFinalForm}
                    name="unit"
                    label={t(`${TRANSLATE}.inputUnit`)}
                    disabled={values.educational_level}
                    required
                    fullBorder
                    width="146px"
                    height="37px"
                    gayScale={!values.unit}
                  >
                    <option value="">
                      {t(`${TRANSLATE}.selectInitialValue`)}
                    </option>
                    {units.map((unit) => {
                      return (
                        <option value={unit.unit.id} key={unit.unit.id}>
                          {unit.unit.name}
                        </option>
                      );
                    })}
                  </Field>
                </div>
                {values.unit && (
                  <div className="inputBeyond">
                    <Field
                      component={SelectInputFinalForm}
                      name="educational_level"
                      label={t(`${TRANSLATE}.inputEducationalLevel`)}
                      disabled={values.grade}
                      required
                      fullBorder
                      width="146px"
                      height="37px"
                      gayScale={!values.unit}
                    >
                      <option value="">
                        {t(`${TRANSLATE}.selectInitialValue`)}
                      </option>
                      {units
                        .find((unit) => unit.unit.id === parseInt(values.unit))
                        ?.unit.educational_level.map((educationalLevel) => {
                          return (
                            <option
                              value={educationalLevel.id}
                              key={educationalLevel.id}
                            >
                              {educationalLevel.name}
                            </option>
                          );
                        })}
                    </Field>
                  </div>
                )}
                {values.educational_level && (
                  <div className="inputBeyond">
                    <Field
                      component={SelectInputFinalForm}
                      name="grade"
                      label={t(`${TRANSLATE}.inputGrade`)}
                      disabled={values.section}
                      required
                      fullBorder
                      width="146px"
                      height="37px"
                      gayScale={!values.unit}
                    >
                      <option value="">
                        {t(`${TRANSLATE}.selectInitialValue`)}
                      </option>
                      {units
                        .find((unit) => unit.unit.id === parseInt(values.unit))
                        ?.unit.educational_level.find(
                          (educationalLevel) =>
                            educationalLevel.id ===
                            parseInt(values.educational_level)
                        )
                        ?.grades.map((grade) => {
                          return (
                            <option value={grade.grade} key={grade.grade}>
                              {t(`${TRANSLATE}.grade`, { grade: grade.grade })}
                            </option>
                          );
                        })}
                    </Field>
                  </div>
                )}
                {values.grade && (
                  <div className="inputBeyond">
                    <Field
                      component={SelectInputFinalForm}
                      name="section"
                      label={t(`${TRANSLATE}.inputSection`)}
                      disabled={values.date}
                      required
                      fullBorder
                      width="146px"
                      height="37px"
                      gayScale={!values.unit}
                    >
                      <option value="">
                        {t(`${TRANSLATE}.selectInitialValue`)}
                      </option>
                      {units
                        .find((unit) => unit.unit.id === parseInt(values.unit))
                        ?.unit.educational_level.find(
                          (educationalLevel) =>
                            educationalLevel.id ===
                            parseInt(values.educational_level)
                        )
                        ?.grades.find(
                          (grade) => grade.grade === parseInt(values.grade)
                        )
                        ?.sections.map((section, index) => {
                          return (
                            <option key={index} value={section.section}>
                              {section.section}
                            </option>
                          );
                        })}
                    </Field>
                  </div>
                )}
                {values.section && (
                  <div className="inputBeyond column">
                    <Text fontWeight="400">{t(`${TRANSLATE}.labelData`)}</Text>
                    <Field
                      name="date"
                      component="input"
                      type="date"
                      placeholder="dd/mm/aaaa"
                      className="date"
                    />
                    <OnChange name="date">
                      {async (value) => {
                        if (value) {
                          onSubmit(values);
                        }
                      }}
                    </OnChange>
                  </div>
                )}
                <div className="inputBeyond button">
                  <Button
                    type="button"
                    outline
                    width="146px"
                    height="37px"
                    onClick={() => {
                      form.reset();
                      setStudentsInfos(null);
                    }}
                  >
                    {t(`${TRANSLATE}.clearForm`)}
                  </Button>
                </div>
              </div>
            </form>
          )}
        </Form>
        {loading && (
          <S.LoadContainer className="mt-3">
            <h4>{t(`${TRANSLATE}.loadingData`)}</h4>
            <Loading />
          </S.LoadContainer>
        )}
        {studentsInfos && (
          <>
            <div className="text">
              <Text>{t(`${TRANSLATE}.textMessage`)}</Text>
            </div>
            <div className="table">
              <TableBulletin
                borderLarge
                theadChildren={
                  <>
                    <TableRow
                      item={t(`${TRANSLATE}.tableStudent`)}
                      visible={{ mobile: true, desktop: true }}
                    />
                    {food_items &&
                      food_items.map((foodItem) => {
                        return (
                          <TableRow
                            item={foodItem.name}
                            visible={{ mobile: true, desktop: true }}
                          />
                        );
                      })}
                  </>
                }
                tbodyChildren={
                  <>
                    {studentsInfos.results.map((result, index) => {
                      return (
                        <tr className="bordeBottom" key={index}>
                          <TableColumn
                            className="firstChield"
                            item={result.student.nome}
                            visible={{ mobile: false, desktop: true }}
                          />

                          {result.food_items.map((foodItem) => {
                            return (
                              <TableWithChildren
                                visible={{ mobile: false, desktop: true }}
                              >
                                <div
                                  className="checkboxContainer"
                                  key={foodItem.id}
                                >
                                  <CheckboxInput
                                    value={foodItem.id}
                                    onChange={() =>
                                      updateFoodItem(result.id, foodItem)
                                    }
                                    name={foodItem.name}
                                    defaultChecked={foodItem.contracted}
                                    disabled={!foodItem.available || loading}
                                  />
                                </div>
                              </TableWithChildren>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </>
                }
              />
            </div>
          </>
        )}
      </S.FoodRecordStyled>
    );
  }
};
