import styled from "styled-components";

export const GuardianDataEditingStyled = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.space[4]};

  max-width: 636px;

  > div {
    max-width: 636px;
    width: 100%;
  }

  div.texts {
    h4 {
      font-size: 18px; // Não possui na lib
      line-height: 27px; // Não possui na lib
      color: ${({ theme }) => theme.colors.primaryDefault};
      font-weight: ${({ theme }) => theme.fontWeights.bold};
    }
    p {
      margin: 0;
      font-weight: ${({ theme }) => theme.fontWeights.regular};
      font-size: ${({ theme }) => theme.fontSizes.web.sm};
      line-height: ${({ theme }) => theme.lineHeights.normal};
      color: #0d0d0d; // Mostra no history book mas não esta importando da lib
    }

    margin-bottom: ${({ theme }) => theme.space[2]};
  }

  form {
    width: 100%;
    margin-bottom: ${({ theme }) => theme.space[4]};

    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: ${({ theme }) => theme.space[2]} 0;

    div.form {
      display: flex;
      flex-wrap: wrap;

      gap: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[4]};

      &.bordeBottom {
        border-bottom: 1px solid ${({ theme }) => theme.colors.greyScale10};
        padding-bottom: ${({ theme }) => theme.space[6]};
        margin-bottom: ${({ theme }) => theme.space[2]};
      }

      div.input {
        height: 59px; // Não possui nos spaces na lib
        display: flex;
        flex-direction: column;

        border: none;

        font-size: ${({ theme }) => theme.fontSizes.web.sm};
        line-height: 18px; // Não possui na lib

        //input sizes
        &.extraLarge {
          width: 400px;
        }

        &.large {
          width: 309px;
        }

        &.medium {
          width: 228px;
        }

        &.extraSmall {
          width: 65px;
        }

        &.small {
          width: 146px;
        }

        input {
          border: none;
          font-size: ${({ theme }) => theme.fontSizes.web.sm};
          padding-left: ${({ theme }) => theme.space[2]};
          height: 40px;
          border-bottom: 2px solid ${({ theme }) => theme.colors.greyScale40};
        }

        select {
          background-color: none;
          border: none;
          padding: 5.2px 0;
          border-bottom: 2px solid ${({ theme }) => theme.colors.greyScale40};
        }
      }
    }

    div.checkbox {
      display: flex;
      gap: 0 ${({ theme }) => theme.space[1]};
      p {
        margin: 0;
      }
    }

    div.buttons {
      margin-top: ${({ theme }) => theme.space[6]};
      width: 100%;

      display: flex;
      justify-content: space-between;

      border: none;
    }
  }

  @media (max-width: 360px) {
    form {
      div.form {
        flex-wrap: wrap;
        div.input {
          &.extraLarge {
            width: 328px;
          }

          &.large {
            width: 328px;

            &.address {
              width: 242px;
            }
          }

          &.medium {
            width: 156px;
          }

          &.small {
            width: 156px;
          }
        }
      }

      div.checkbox {
        font-size: ${({ theme }) => theme.fontSizes.web.sm};
      }
    }
  }
`;
