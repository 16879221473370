import { useRef, useLayoutEffect, useState } from "react";

import * as S from "./style";
import * as I from "./type";

export const TableBulletin = ({
  theadChildren,
  tbodyChildren,
  borderLarge,
  border = true,
  scroll,
}: I.TableProps) => {
  const [overflowY, setOverflowY] = useState<number>();
  const containerRef = useRef<HTMLTableElement | null>(null);

  useLayoutEffect(() => {
    if (containerRef.current !== null) {
      const info = containerRef.current.getBoundingClientRect();
      setOverflowY(info.top);
    }
  }, []);

  return (
    <S.Container
      scroll={scroll}
      ref={containerRef}
      overflowY={overflowY ? `${overflowY}px` : "0"}
    >
      <S.ContainerTable scroll={scroll}>
        <S.TableHead borderLarge={borderLarge} border={border} scroll={scroll}>
          <S.TableRow>{theadChildren}</S.TableRow>
        </S.TableHead>
        <S.TableBody>{tbodyChildren}</S.TableBody>
      </S.ContainerTable>
    </S.Container>
  );
};

export const Table = ({
  theadChildren,
  tbodyChildren,
  borderLarge,
}: I.TableProps) => {
  return (
    <S.ContainerTable>
      <S.TableHead borderLarge={borderLarge}>
        <S.TableRow>{theadChildren}</S.TableRow>
      </S.TableHead>
      <S.TableBody>{tbodyChildren}</S.TableBody>
    </S.ContainerTable>
  );
};
