// React
import { useCallback, useEffect, useState } from "react";
// Styles
import { Contract, SigningContractStyle } from "./style";
// Libs
import { useLocation, useHistory } from "react-router-dom";
import { LinearProgress } from "@material-ui/core";
import HTTP_STATUS from "http-status-codes";
// Component
import { toastSuccess } from "components/Toast";
// Constants
import { GetCXUserJson, StudentInterface } from "interfaces/constants";
// Services
import { generateContract } from "services/contract.service";
import { IsDevelopmentEnvironment } from "helpers/helpers";

type StateType = {
  student: StudentInterface;
  guardian: GetCXUserJson;
};

export const SigningContract = () => {
  const location = useLocation<StateType>();
  const history = useHistory();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const newWindow = window as any;
  const Clicksign = newWindow.Clicksign;

  const getContract = useCallback(
    (key: string) => {
      const widget = new Clicksign(key);
      if (widget) {
        if (widget) {
          widget.unmount();
        }

        widget.endpoint = !IsDevelopmentEnvironment()
          ? "https://app.clicksign.com"
          : "https://sandbox.clicksign.com";
        widget.origin = `${window.location.origin}`;
        widget.mount("contract");
        widget.on("signed", function () {
          setTimeout(() => {
            history.push({
              pathname: `/contrato/contrato-assinado`,
              state: {
                guardian: location.state.guardian,
                student: location.state.student,
              },
            });
            toastSuccess("Contrato assinado com sucesso!");
          }, 0);
        });
      }
    },
    [Clicksign, history, location]
  );

  useEffect(() => {
    (async () => {
      try {
        const response = await generateContract({
          enrollmentId: location.state.student.last_enrollment.id,
        });
        if (response && response.status === HTTP_STATUS.OK) {
          getContract(response.data.chave_contrato_assinatura);
        }
      } catch (err) {
        setError(true);
        setLoading(false);
      }
    })();
  }, [getContract, location]);

  if (loading) {
    return <LinearProgress className="mt-3 mb-3" color="secondary" />;
  }

  if (error && !loading) {
    return <div>Erro ao carregar estudante.</div>;
  }

  return (
    <SigningContractStyle className="container">
      <div className="enrollment-title text-center mb-5">
        Matrícula de {location.state.student.name}
        {location.state.student.last_enrollment && (
          <div className="subtitle">
            {location.state.student.last_enrollment.grade || "Sem ano"}
            {location.state.student.last_enrollment.grade && "º ano do "}
            {location.state.student.last_enrollment.educational_level_name} -
            Unidade {location.state.student.last_enrollment.unit_name}
          </div>
        )}
      </div>
      <Contract id="contract" />
    </SigningContractStyle>
  );
};
