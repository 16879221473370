import styled, { css } from "styled-components";
import { IExitPermitScreenStyled } from "./interface";

export const ExitPermitScreenStyled = styled.div<IExitPermitScreenStyled>`
  display: flex;
  flex-direction: column;

  div.body {
    padding: 1.5rem;

    h4 {
      margin-top: 2rem;
      font-size: 24px;
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      color: ${({ theme }) => theme.colors.primaryDefault};
      line-height: ${({ theme }) => theme.lineHeights};
    }

    p {
      margin-top: 1rem;
      font-size: 14px;
      font-weight: ${({ theme }) => theme.fontWeights.regular};
      /* color: nao possui a cor na lib */
      line-height: ${({ theme }) => theme.lineHeights.normal};

      &.guardianDescription {
        margin-top: 1.5rem;
      }
    }

    div.form {
      margin-top: 2rem;

      div {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        label {
          padding-top: 3px;
        }
      }
    }

    div.guardians {
      display: flex;
      flex-wrap: wrap;

      ${({ isSendingData }) =>
        isSendingData &&
        css`
          footer button:nth-child(2) {
            pointer-events: none;
          }
        `}
    }

    div.button {
      display: flex;
      margin-top: 6.938rem;
      justify-content: end;

      button {
        width: 213px;
        height: 53px;
      }
    }
  }

  @media (max-width: 360px) {
    div.body {
      h4 {
        font-size: 20px;
      }

      div.form {
        div {
          gap: 4px;
        }
      }
      div.guardians {
        flex-wrap: wrap;
      }
      div.button {
        margin-top: ${({ theme }) => theme.space[16]};
        button {
          width: 100%;
          height: 53px;
        }
      }
    }
  }
`;

export const ModalStyled = styled.div`
  margin-top: 23px;

  div {
    display: flex;
    justify-content: space-between;

    &.firstLine {
      margin-bottom: 1rem;
    }

    div {
      width: 48%;
      display: flex;
      flex-direction: column;
      label {
        font-weight: ${({ theme }) => theme.fontWeights.regular};
        font-size: 14px;
        line-height: ${({ theme }) => theme.lineHeights.normal};
      }

      input {
        margin-top: -5px;
        padding-left: ${({ theme }) => theme.space[1]};
        box-sizing: border-box;
        height: ${({ theme }) => theme.space[10]};
        background: ${({ theme }) => theme.colors.white};
        border: 2px solid ${({ theme }) => theme.colors.greyScale40};

        &::placeholder {
          color: ${({ theme }) => theme.colors.greyScale40};
        }
      }
    }
  }

  p {
    margin: 0;
    margin-top: 0.5rem;
  }
`;

export const LoadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h4 {
    ${({ theme }) => theme.fontSizes.web.h4}
    text-align: center;
  }

  div {
    margin: ${({ theme }) => theme.space[4]};
  }
`;
