import {
  DiscountsSection,
  SelectContainer,
  SelectTitle,
} from "pages/EDF/VacancyData/style";
import styled from "styled-components";

export const DiscountApprovalDrawerContainer = styled.div`
  padding: ${({ theme }) => theme.space[6]} ${({ theme }) => theme.space[4]};

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[4]};

  width: 100%;
`;

export const DiscountApprovalDrawerContent = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primaryLight90};
  border-radius: ${({ theme }) => theme.radii.lg};

  padding: ${({ theme }) => theme.space[6]} ${({ theme }) => theme.space[4]};
`;

export const ScolarshipDiscountContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.radii.lg};

  padding: ${({ theme }) => theme.space[4]};
  margin-bottom: ${({ theme }) => theme.space[4]};
`;

export const ScolarshipDiscountContent = styled.div`
  max-width: 208px;

  h3 {
    font-size: ${({ theme }) => theme.fontSizes.web.h4};
    font-weight: 600; // TODO: Não tem na lib
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const SelectContainerExtended = styled(SelectContainer)`
  max-width: 208px;
`;

export const ButtonGroup = styled.div`
  margin-left: auto;
`;

export const SelectTitleExtended = styled(SelectTitle)`
  text-transform: none;
`;

export const DiscountsSectionExtended = styled(DiscountsSection)`
  p {
    margin-bottom: ${({ theme }) => theme.space[2]};
  }
`;

export const ScolarshipDiscountResume = styled.div`
  display: flex;
  flex-direction: column;

  h4 {
    font-size: ${({ theme }) => theme.fontSizes.mobile.h3};
    font-weight: 600; // TODO: Não tem na lib
    color: ${({ theme }) => theme.colors.black};
  }
`;

export const ScolarshipDiscountResumeList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[1]};

  margin-bottom: ${({ theme }) => theme.space[2]};

  li {
    display: flex;

    span,
    strong {
      margin-left: auto;
    }

    &:last-child {
      font-weight: bold;
    }
  }

  & + & {
    border-top: 2px solid ${({ theme }) => theme.colors.greyScale10};
    padding-top: ${({ theme }) => theme.space[2]};
  }

  &:last-child {
    margin-bottom: none;
  }
`;
