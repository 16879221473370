import styled from "styled-components";
import * as colors from "styles/colors";

export const Container = styled.div`
  display: flex;
  padding: 0;

  & > div {
    width: 100%;
    flex-direction: column;
  }

  small {
    margin-top: 4px;
  }

  .modal-btn {
    margin-left: auto;

    border: 2px solid ${({ theme }) => theme.colors.primaryDefault};
    background-color: transparent;
    padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[4]};

    font-weight: ${({ theme }) => theme.fontWeights.bold};
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.primaryDefault};
  }

  .foodInfo {
    display: flex;
    flex-direction: column;
    margin-top: 12px;

    p,
    strong {
      margin: 0;
      display: flex;
      justify-content: space-between;
    }

    p + p {
      margin-top: 4px;
    }

    button {
      margin-top: ${({ theme }) => theme.space[1]};
      margin-left: 0;
      max-width: fit-content;
      border: none;
      background-color: transparent;
      font-size: ${({ theme }) => theme.fontSizes.web.sm};
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      text-decoration: underline;
      padding: 0;
      color: ${({ theme }) => theme.colors.primaryDefault};
    }

    strong {
      margin-top: 8px;
      padding-top: 8px;
      border-top: 1px solid ${colors.NEW_GRAY_THREE};
    }
  }

  div.paymentDate {
    margin-top: ${({ theme }) => theme.space[2]};
    color: ${({ theme }) => theme.colors.greyScale40};

    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-size: 12px;
      margin: 0;
    }
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
`;
