// React
import { useCallback, useContext } from "react";
// Interfaces
import { StudentInterface } from "interfaces/students";
import { ITableHelper } from "./interface";
import { toastError, toastSuccess } from "components/Toast";
// Services
import { updateExitPermit } from "services/exitPermit.services";
// Libs
import HTTP_STATUS from "http-status-codes";
import { useTranslation } from "react-i18next";

export const TableHelper = ({
  setStudentSelected,
  studentListResults,
}: ITableHelper) => {
  const { t } = useTranslation();
  const prefix = "pages.EDF.exitReport.components.table.helper";

  const handleChange = useCallback(
    (value: StudentInterface | null) => {
      setStudentSelected(value);
    },
    [setStudentSelected]
  );

  const handleSaveChanges = async (transportation: number, student: number) => {
    const response = await updateExitPermit({
      student_id: student,
      payload: {
        transportation,
      },
    });
    if (response?.status === HTTP_STATUS.OK) {
      toastSuccess(t(`${prefix}.toastSuccess`));
      studentListResults();
    } else {
      toastError(t(`${prefix}.toastError`));
    }
  };

  return { handleChange, handleSaveChanges };
};
