import styled from "styled-components";
import * as colors from "styles/colors";

export const Container = styled.div`
  form {
    display: flex;
    flex-wrap: wrap;

    div.input {
      margin-top: 1em;
      display: flex;
      flex-direction: column;
      margin-right: 2em;
      width: 15%;

      label {
        float: left;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: ${colors.NEW_GRAY_SCALE};
      }

      select {
        margin-top: -5px;
        box-sizing: border-box;
        height: 37px;
        background: ${colors.WHITE};
        border: 2px solid ${colors.GRAY_SCALE};

        &:focus {
          border: 2px solid ${colors.NEW_PURPLE};
          outline-style: solid;
        }

        @media only screen and (max-width: 500px) {
          width: 300px;
        }
      }
    }
  }

  div.data {
    padding-bottom: 2em;
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    width: 100%;

    .dataNumber {
      margin-left: 0.5em;
      color: ${colors.NEW_PURPLE};
      font-weight: 700;
      font-size: 14px;
    }
  }

  div.pagination {
    margin-top: 1.5em;
  }

  div.nofoundDiv {
    width: 100%;
    display: flex;
    justify-content: center;

    strong.nofound {
      text-align: center;
      margin: 64px 0;
      color: ${colors.GRAY_SCALE};
    }
  }

  @media (max-width: 565px) {
    form {
      display: flex;
      flex-direction: column;
    }

    div.data {
      margin-top: 1em;
      justify-content: flex-start;

      .dataNumber {
        margin-left: 0.5em;
        color: ${colors.NEW_PURPLE};
        font-weight: 700;
        font-size: 14px;
      }
    }
  }
`;
