// React
import { useEffect, useState, useContext } from "react";
// Style
import { Step4Styled } from "./style";
// Components
import { Boleto } from "./components/Boleto";
import { Pix } from "./components/Pix";
import { CreditCard } from "./components/Cartao";
// Interfaces
import { FoodPackage } from "interfaces/mealInterfaces";
import { FoodPackagePaymentInterface } from "interfaces/paymentInterfaces";
import { StudentInterface } from "interfaces/constants";
// Libs
import HTTP_STATUS from "http-status-codes";
import { LinearProgress } from "@material-ui/core";
// Services
import { foodPackagePayment } from "services/payment.service";

type Step4Type = {
  paymentMethod: number;
  foodPackage: FoodPackage;
  student: StudentInterface;
  setActiveStep: (activeStep: number) => void;
  setNewPackage: (newPackage: boolean) => void;
};

export const PAYMENT = {
  BOLETO: 1,
  PIX: 2,
  CARTAO: 3,
};

export const Step4 = ({
  paymentMethod,
  foodPackage,
  student,
  setActiveStep,
  setNewPackage,
}: Step4Type) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [paymentValue] = useState<number>(
    foodPackage.food_package_value_month_after_balance * 100
  );
  const [paymentData, setPaymentData] = useState<FoodPackagePaymentInterface>();

  // Erro no dado do cartão
  const [failed, setFailed] = useState(false);

  useEffect(() => {
    if (
      paymentValue &&
      (paymentMethod === PAYMENT.BOLETO || paymentMethod === PAYMENT.PIX)
    ) {
      const getBankSlip = async () => {
        const response = await foodPackagePayment({
          enrollment_id: foodPackage.enrollment_id,
          value: paymentValue,
          package_id: foodPackage.id,
          type_payment: `${
            paymentMethod === PAYMENT.BOLETO ? `bank_slip` : `pix`
          }`,
        });
        if (response && response.status === HTTP_STATUS.OK) {
          setPaymentData(response.data);
          setLoading(false);
        } else {
          setLoading(false);
          setError(true);
        }
      };
      getBankSlip();
    } else {
      setLoading(false);
    }
  }, [paymentValue, foodPackage, paymentMethod]);

  return (
    <>
      {error && !loading && <div>Erro ao buscar dados de pagamento</div>}
      {loading && <LinearProgress className="mt-3 mb-3" color="secondary" />}
      {!loading && !error && (
        <Step4Styled>
          {paymentMethod === PAYMENT.BOLETO && paymentData && (
            <Boleto
              error={error}
              loading={loading}
              paymentData={paymentData}
              foodPackage={foodPackage}
              setActiveStep={setActiveStep}
            />
          )}
          {paymentMethod === PAYMENT.PIX && paymentData && (
            <Pix
              paymentData={paymentData}
              foodPackage={foodPackage}
              setActiveStep={setActiveStep}
              student={student}
              setNewPackage={setNewPackage}
            />
          )}
          {paymentMethod === PAYMENT.CARTAO && (
            <CreditCard
              foodPackage={foodPackage}
              setActiveStep={setActiveStep}
              failed={failed}
              setFailed={setFailed}
              setNewPackage={setNewPackage}
            />
          )}
        </Step4Styled>
      )}
    </>
  );
};
