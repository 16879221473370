import { IconType } from "types/constants";

export const IconInformation = ({ isActive }: IconType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.634"
      height="13.385"
      fill={isActive ? "#00aeef" : "#5e5e5e"}
      viewBox="0 0 16.634 13.385"
    >
      <g transform="translate(0 -50)">
        <path
          className="a"
          d="M67.826,285.18a3.268,3.268,0,0,1-2.183-.833,3.345,3.345,0,0,0-.644,1.97v2.826a.976.976,0,0,0,.975.975h3.7a.976.976,0,0,0,.975-.975v-2.826a3.345,3.345,0,0,0-.644-1.97A3.268,3.268,0,0,1,67.826,285.18Z"
          transform="translate(-62.888 -226.734)"
        />
        <path
          className="a"
          d="M15.172,50H1.462A1.464,1.464,0,0,0,0,51.462v7.407a1.464,1.464,0,0,0,1.137,1.425v-.71a4.317,4.317,0,0,1,.969-2.722l.014-.017a3.281,3.281,0,1,1,5.635,0l.014.017a4.317,4.317,0,0,1,.969,2.722v.747h6.433a1.464,1.464,0,0,0,1.462-1.462V51.462A1.464,1.464,0,0,0,15.172,50ZM9.356,57.732a.487.487,0,1,1,.487-.487A.487.487,0,0,1,9.356,57.732Zm0-2.079a.487.487,0,1,1,.487-.487A.487.487,0,0,1,9.356,55.653Zm0-2.079a.487.487,0,1,1,.487-.487A.487.487,0,0,1,9.356,53.574Zm4.678,4.158h-2.6a.487.487,0,0,1,0-.975h2.6a.487.487,0,0,1,0,.975Zm0-2.079h-2.6a.487.487,0,0,1,0-.975h2.6a.487.487,0,0,1,0,.975Zm0-2.079h-2.6a.487.487,0,0,1,0-.975h2.6a.487.487,0,0,1,0,.975Z"
          transform="translate(0 0)"
        />
        <circle
          className="a"
          cx="2"
          cy="2"
          r="2"
          transform="translate(3.016 53.22)"
        />
      </g>
    </svg>
  );
};

export default IconInformation;
