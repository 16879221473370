import { NewTableRoot } from "./TableRoot";
import { NewTd } from "./Td";
import { NewTh } from "./Th";
import { NewTheadTbody } from "./Thead";

export const NewTable = {
  Root: NewTableRoot,
  Thead: NewTheadTbody,
  Tbody: NewTheadTbody,
  Th: NewTh,
  Td: NewTd,
};
