import Icon from "@mdi/react";
import { mdiInformationOutline } from "@mdi/js";

import * as S from "./styles";

interface ModalLegendProps {
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModalLegend = ({ setModal }: ModalLegendProps) => {
  const handleToggleModal = () => {
    setModal((prevState: boolean) => !prevState);
  };

  return (
    <S.Overlay>
      <S.Container>
        <div className="info">
          <Icon path={mdiInformationOutline} />
          <h2>Legenda</h2>
        </div>

        <div className="content">
          <ul className="p-0">
            <li>M1 - Média do primeiro trimestre</li>
            <li>M2 - Média do segundo trimestre</li>
            <li>M3 - Média do terceiro trimestre</li>
            <li>MF - Média final</li>
            <li>F - Total de faltas</li>
          </ul>
        </div>

        <button onClick={handleToggleModal}>Fechar</button>
      </S.Container>
    </S.Overlay>
  );
};
