import { useHistory } from "react-router-dom";
import { TeacherContainer } from "./style";
import { ButtonCard } from "components/atoms/ButtonCard";
import { useTranslation } from "react-i18next";
import { IsDevelopmentEnvironment } from "helpers/helpers";

const TRANSLATE_PREFIX = "pages.CORE.home.teacher";

export const TeacherHome = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <TeacherContainer>
      <ButtonCard
        onClick={() => history.push("/dashboard")}
        text={t(`${TRANSLATE_PREFIX}.dashboard`)}
      />
      <ButtonCard
        onClick={() => history.push("/diario-de-classe")}
        text={t(`${TRANSLATE_PREFIX}.classDiary`)}
      />
      <ButtonCard
        onClick={() => history.push("/consulta-diario-de-classe")}
        text={t(`${TRANSLATE_PREFIX}.rollSearch`)}
      />
      <ButtonCard
        onClick={() => history.push("/lancamento/componentes")}
        text={t(`${TRANSLATE_PREFIX}.releases`)}
      />
    </TeacherContainer>
  );
};
