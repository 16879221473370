import styled from "styled-components";
import * as colors from "styles/colors";

export const ModalHeader = styled.div`
  position: fixed;
  padding: 24px;

  border-radius: 17px;

  outline: none;

  max-height: 90vh;
  overflow: auto;

  .loadingStatus {
    font-size: 24px;

    strong {
      color: ${colors.PINK};
    }
  }

  svg.close {
    cursor: pointer;
  }
`;

export const ModalDiv = styled.div`
  color: ${colors.GRAY_TWO};

  th {
    font-size: 14px;
  }

  .title {
    color: ${colors.BLUE};
  }

  .text {
    font-size: 14px;
    line-height: 2;
  }

  .release {
    width: 100%;
    line-height: 1;
    background-color: rgba(226, 226, 226, 0.322);

    min-height: 100px;

    span {
      color: ${colors.PINK};
    }

    small {
      font-size: 12px;
    }
  }

  span.textBolder {
    font-weight: bold;
  }

  button.widthButton {
    width: 45%;
  }
`;
