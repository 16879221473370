import { Button } from "components";
import { ModalDiv } from "components/UseTerms/styles";
import { StudentInterface } from "interfaces/constants";

interface ModalConfirmTermsInterface {
  setToggleModal: (toggleModal: boolean) => void;
  student: StudentInterface;
}

export const ModalConfirmTerms = ({
  setToggleModal,
  student,
}: ModalConfirmTermsInterface) => {
  return (
    <div className="container">
      <ModalDiv>
        <div>
          <div className="text-center">
            <strong className="title">Atenção</strong>
          </div>
          <div>
            Para seguir com a rematricula do(a) aluno(a) {student.name}, é
            necessário estar adimplente com as parcelas da Escola Mais. Entre em
            contato com a equipe de cobrança para regularizar sua situação.
          </div>
          <Button onClick={() => setToggleModal(false)} type="button">
            Continuar
          </Button>
        </div>
      </ModalDiv>
    </div>
  );
};

export default ModalConfirmTerms;
