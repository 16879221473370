import { Button, RadioButton } from "components";
import { LookupStudentsResponse } from "services/student.service";
import { Field } from "react-final-form";
import { ModalSimilarStudentsStyled } from "./style";

interface ModalSimilarStudentsInterface {
  setToggleModal: (toggleModal: boolean) => void;
  students: Array<LookupStudentsResponse>;
  setStudentData: (values: any, form: any) => void;
  setKeepGoing: (keepGoing: boolean) => void;
  values: any;
  form: any;
}

function ModalSimilarStudents({
  setToggleModal,
  students,
  setStudentData,
  values,
  form,
  setKeepGoing,
}: ModalSimilarStudentsInterface) {
  return (
    <div className="container">
      <ModalSimilarStudentsStyled className="mt-5 mb-5">
        <strong className="text-center title">
          {students.length > 1 &&
            "Foram encontrados estes alunos com dados parecidos aos do aluno que você está cadastrando. Algum desses é o seu aluno?"}
          {students.length === 1 &&
            "Foi encontrado este aluno com dados parecidos aos do aluno que você está cadastrando. Este é o seu aluno?"}
        </strong>
        {students.map((student) => {
          return (
            <div className="row">
              <div className="col-1 my-auto">
                <Field
                  component={RadioButton}
                  name="student_id"
                  type="radio"
                  value={student.id.toString()}
                  required
                />
              </div>
              <div className="col-11 my-auto">
                <strong>
                  {student.name}, nascido em{" "}
                  {student.birth_date.split("-").reverse().join("/")},
                  {student.guardians.length > 0 &&
                    ` responsável ${student.guardians[0].name}`}
                  {!student.guardians.length && ` sem responsável cadastrado`},
                  {!student.employees.length && " vendedor Vivian Silva"}
                  {student.employees.length === 1 &&
                    ` vendedor ${student.employees[0].employee_name}`}
                  {student.employees.length > 1 &&
                    ` vendedores ${student.employees
                      .map((e) => e.employee_name)
                      .join(", ")}`}
                </strong>
              </div>
            </div>
          );
        })}
        <div className="row">
          <div className="col-sm-6 col-12">
            <Button
              onClick={() => {
                setToggleModal(false);
                setKeepGoing(true);
              }}
              className="blue outline"
            >
              {students.length > 1 &&
                "Nenhum, continuar cadastro do novo aluno"}
              {students.length === 1 && "Não, continuar cadastro do novo aluno"}
            </Button>
          </div>
          <div className="col-sm-6 col-12">
            <Button
              onClick={() => {
                setStudentData(values, form);
              }}
              type="button"
            >
              Este é o meu aluno
            </Button>
          </div>
        </div>
      </ModalSimilarStudentsStyled>
    </div>
  );
}

export default ModalSimilarStudents;
