import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import * as S from "../../styles";
import { ILocationSelectMeals } from "../../../../EDF/SelectMeals/type";
import { formatStringToDate } from "helpers/helpers";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import DownloadIcon from "@material-ui/icons/ArrowDownward";
import IconButton from "@material-ui/core/IconButton";
import { TablePagination } from "@material-ui/core";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { Loading } from "../../../../../components";
import { toastError } from "../../../../../components/Toast";
import { RadioGroupV2 } from "@escolamais-ui-v2/react";
import Spin from "../../../../../static/img/loading2.svg";
import { ArrowBackOutlined } from "@material-ui/icons";
import Icon from "@mdi/react";
import { mdiChevronRight } from "@mdi/js";
import { getStudentData } from "services/student.service";
import {
  IGetBoletosResponse,
  downloadBoleto,
  getBoletoApiToken,
  getBoletos,
} from "services/boletos.service";
import { Alert } from "components/Alert";

dayjs.locale("pt-br");
export const ListaBoletos = () => {
  const { state } = useLocation<ILocationSelectMeals>();
  const student = state.studentUser;
  const [boletos, setBoletos] = useState<IGetBoletosResponse[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedYears, setSelectedYears] = useState<number[]>([]);
  const [selectedYear, setSelectedYear] = useState(0);
  const [emptyBoletosMessage, setEmptyBoletosMessage] = useState("");
  const [boletoSituacao, setBoletoSituacao] = useState("");
  const [boletoOnDownload, setBoletoOnDownload] = useState<
    IGetBoletosResponse | undefined
  >(undefined);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const enum ServiceCodesBoletoEnum {
    MENSALIDADE = 1,
    MATRICULA = 2,
    MATERIAL_DIDATICO = 3,
  }

  const filterBoletosByServiceCode = (boletos: IGetBoletosResponse[]) => {
    return boletos.filter(
      (boleto) => boleto.codservico !== ServiceCodesBoletoEnum.MATERIAL_DIDATICO
    );
  };

  const getBoletosByYear = async (year: number) => {
    setLoading(true);

    try {
      const studentData = await getStudentData({
        studentId: student?.id,
      });

      if (studentData?.data?.ra && year) {
        const getBoletosPayload = {
          codcoligada: 0,
          codfilial: 0,
          codtipocurso: 0,
          email: "",
          data: {
            codcoligada: 1,
            codperlet: year,
            ra: studentData.data.ra,
          },
        };

        const boletoApiToken = await getBoletoApiToken();
        const boletos = await getBoletos(getBoletosPayload, boletoApiToken);

        if (boletos?.data?.data) {
          setBoletos(filterBoletosByServiceCode(boletos.data.data));
          setSelectedYear(year);
        } else {
          setBoletos([]);
          setEmptyBoletosMessage("Sem boletos");
        }
        setLoading(false);
      }
    } catch (error) {
      setEmptyBoletosMessage("Ra não encontrado");
      setLoading(false);
    }
  };

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 5));
    setPage(0);
  };

  useEffect(() => {
    const currentYear = student.enrollment.year;
    const startYear = currentYear - 2;
    const yearsArray = [];
    for (let year = currentYear; year >= startYear; year--) {
      yearsArray.push(year);
    }

    setSelectedYears(yearsArray);
    getBoletosByYear(yearsArray[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDownloadBoleto = async (boleto: IGetBoletosResponse) => {
    setBoletoOnDownload(boleto);
    const downloadBoletoPayload = {
      boleto_id: boleto.idboleto,
      cod_coligada: boleto.codcoligada,
      cod_filial: boleto.filial,
      cod_tipo_curso: boleto.codNivelEnsino,
    };

    try {
      const response = await downloadBoleto(downloadBoletoPayload);
      const file = new Blob([response.data], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      const pdfWindow = window.open();
      if (pdfWindow) {
        pdfWindow.location.href = fileURL;
      }
    } catch (error) {
      toastError("Erro ao baixar o boleto");
    }

    setBoletoOnDownload(undefined);
  };
  function onChangeValue(event: any) {
    setBoletoSituacao(event.value);
  }
  const filteredData = boletos.filter((boleto) => {
    if (boletoSituacao !== "") {
      return boleto.situacao === boletoSituacao;
    } else {
      return true;
    }
  });

  const filteredDataPaginated =
    rowsPerPage > 0
      ? filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : filteredData;

  useEffect(() => {
    setPage(0);
  }, [boletoSituacao]);

  return (
    <S.Container>
      <S.Localizador>
        Home <Icon path={mdiChevronRight} /> <span>2º via boleto</span>
      </S.Localizador>
      <S.TituloContainerBoletos>
        <ArrowBackOutlined />
        <span>2º via boleto</span>
      </S.TituloContainerBoletos>
      <S.ContainerStu>
        {student.photo_url && (
          <img src={student.photo_url} alt={student.name} />
        )}

        <div>
          <div>
            <strong>{student.name}</strong>
            <p className="m-0">
              {student.enrollment.educational_level_name} -{" "}
              {student.enrollment.grade}º Ano{" "}
              <span>Turma {student.enrollment.section}</span>
            </p>
          </div>
        </div>
      </S.ContainerStu>
      {loading && <Loading />}
      {!loading && (
        <S.Card>
          <div style={{ width: "100%" }}>
            <S.AlertContainer>
              <Alert.Root severity="info">
                <Alert.Content text="Para demais valores em aberto não exibidos aqui, consulte o time de relacionamento financeiro." />
              </Alert.Root>
            </S.AlertContainer>
            <S.Header>
              <S.Filtros>
                <span className="flex-1">Lista de boletos</span>
                <div className="flex-4">
                  <S.SelectAnoBoleto>
                    <select
                      className="select"
                      value={selectedYear}
                      onChange={(e) =>
                        getBoletosByYear(parseInt(e.target.value))
                      }
                    >
                      {selectedYears.map((ano) => (
                        <option value={ano} key={ano}>
                          {ano}
                        </option>
                      ))}
                    </select>
                  </S.SelectAnoBoleto>
                </div>
                <div className="flex-2">
                  <div className="radioMobileFiltro">
                    <RadioGroupV2
                      appearance="default"
                      containerStyle={{
                        fontSize: "0.6256rem",
                        fontWeight: "400",
                        lineHeight: "18px",
                      }}
                      defaultCheckedValue={boletoSituacao}
                      id="radioMobileFiltro"
                      labelColorChecked="#000000"
                      labelColorUnchecked="#000000"
                      name="radioMobileFiltro"
                      onChangeOption={function fl(dados) {
                        onChangeValue(dados);
                      }}
                      options={[
                        {
                          label: "Todos",
                          value: "",
                        },
                        {
                          label: "Atrasados",
                          value: "Vencido",
                        },
                        {
                          label: "Em aberto",
                          value: "Em aberto",
                        },
                        {
                          label: "Pagos",
                          value: "Baixado",
                        },
                      ]}
                      radioCircleColorChecked="#6D28D9"
                      radioCircleColorUnchecked="#6D28D9"
                      radioCircleBorderWidth={20}
                      radioCircleBorderHeight={20}
                      radioCircleInnerWidth={10}
                      radioCircleInnerHeight={10}
                      radioCirclePositionLeft={11}
                      radioCirclePositionTop={10}
                    />
                  </div>
                  <div className="radioFiltro">
                    <RadioGroupV2
                      appearance="default"
                      containerStyle={{
                        width: "200px",
                        fontSize: "16px",
                        fontWeight: "normal",
                      }}
                      defaultCheckedValue={boletoSituacao}
                      id="radioFiltro"
                      labelColorChecked="#000000"
                      labelColorUnchecked="#000000"
                      name="radioFiltro"
                      onChangeOption={function fl(dados) {
                        onChangeValue(dados);
                      }}
                      options={[
                        {
                          label: "Todos",
                          value: "",
                        },
                        {
                          label: "Atrasados",
                          value: "Vencido",
                        },
                        {
                          label: "Em aberto",
                          value: "Em aberto",
                        },
                        {
                          label: "Pagos",
                          value: "Baixado",
                        },
                      ]}
                      radioCircleColorChecked="#6D28D9"
                      radioCircleColorUnchecked="#6D28D9"
                      radioCircleBorderWidth={20}
                      radioCircleBorderHeight={20}
                      radioCircleInnerWidth={10}
                      radioCircleInnerHeight={10}
                      radioCirclePositionLeft={11}
                      radioCirclePositionTop={10}
                    />
                  </div>
                </div>
                <div className="flex-3">
                  <S.SelectAnoBoleto>
                    <select
                      className="select"
                      value={selectedYear}
                      onChange={(e) =>
                        getBoletosByYear(parseInt(e.target.value))
                      }
                    >
                      {selectedYears.map((ano) => (
                        <option value={ano} key={ano}>
                          {ano}
                        </option>
                      ))}
                    </select>
                  </S.SelectAnoBoleto>
                </div>
              </S.Filtros>
            </S.Header>
            <div style={{ marginLeft: "-1rem" }}>
              <S.ListaBoleto>
                <S.ListaBoletoItemHead>
                  <span>Referente a</span>
                </S.ListaBoletoItemHead>
                <S.ListaBoletoItemHead>
                  <span>Serviço</span>
                </S.ListaBoletoItemHead>
                <S.ListaBoletoItemHead>
                  <span>Vencimento</span>
                </S.ListaBoletoItemHead>
                {/* <S.ListaBoletoItemHead>
                  <span>Valor</span>
                </S.ListaBoletoItemHead> */}
                <S.ListaBoletoItemHead>
                  <span>Status</span>
                </S.ListaBoletoItemHead>
                <S.ListaBoletoItemHead style={{ flex: 2 }}>
                  <span></span>
                </S.ListaBoletoItemHead>
              </S.ListaBoleto>
              <S.Sparador />
              {boletos.length > 0 && (
                <>
                  {filteredDataPaginated.length > 0 ? (
                    filteredDataPaginated.map((boleto) => {
                      return (
                        <>
                          {boleto && (
                            <S.ListaBoletoItem key={boleto.idboleto}>
                              <S.ListaBoletoItemBody>
                                <span style={{ textTransform: "capitalize" }}>
                                  {dayjs(boleto.dataCompetencia).format("MMMM")}
                                </span>
                              </S.ListaBoletoItemBody>
                              <S.ListaBoletoItemBody>
                                <span>{boleto.descricaoServico}</span>
                              </S.ListaBoletoItemBody>
                              <S.ListaBoletoItemBody>
                                <strong>
                                  {formatStringToDate(boleto.vencimento)}
                                </strong>
                              </S.ListaBoletoItemBody>
                              {/* <S.ListaBoletoItemBody>
                                <span>
                                  <b>{formatMoney(boleto.valor)}</b>
                                </span>
                              </S.ListaBoletoItemBody> */}
                              <S.ListaBoletoItemBody>
                                {boleto.situacao === "Baixado" ? (
                                  <S.Pago>
                                    <span>Pago</span>
                                  </S.Pago>
                                ) : boleto.situacao === "Cancelado" ? (
                                  <S.Cancelado>
                                    <span>Cancelado</span>
                                  </S.Cancelado>
                                ) : boleto.situacao === "Em aberto" ? (
                                  <S.EmAberto>
                                    <span>Em aberto</span>
                                  </S.EmAberto>
                                ) : boleto.situacao === "Vencido" ? (
                                  <S.Atrasado>
                                    <span>Atrasado</span>
                                  </S.Atrasado>
                                ) : (
                                  <S.Cancelado>
                                    <span>Cancelado</span>
                                  </S.Cancelado>
                                )}
                              </S.ListaBoletoItemBody>
                              <S.ListaBoletoItemBody style={{ flex: 2 }}>
                                {boleto.situacao === "Baixado" ? (
                                  <span>
                                    Data do pagamento:{" "}
                                    {boleto.databaixa
                                      ? formatStringToDate(boleto.databaixa)
                                      : ""}
                                  </span>
                                ) : boleto.situacao !== "Cancelado" ? (
                                  <>
                                    {boletoOnDownload &&
                                    boletoOnDownload?.idboleto ===
                                      boleto.idboleto ? (
                                      <S.Copiar className="d-flex align-content-center flex-column">
                                        <img
                                          src={Spin}
                                          alt="Baixando..."
                                          width="30"
                                          height="30"
                                        />
                                      </S.Copiar>
                                    ) : (
                                      <S.Copiar
                                        onClick={() =>
                                          handleDownloadBoleto(boleto)
                                        }
                                        className="d-flex align-content-center flex-column"
                                      >
                                        <IconButton
                                          aria-label="download"
                                          className="d-flex align-content-center flex-column"
                                        >
                                          <div>
                                            <DownloadIcon />
                                          </div>
                                          <span>Baixar boleto</span>
                                        </IconButton>
                                      </S.Copiar>
                                    )}
                                    <S.Copiar
                                      className="d-flex align-content-center flex-column"
                                      onClick={() =>
                                        navigator.clipboard.writeText(
                                          `${boleto.ipte}`
                                        )
                                      }
                                    >
                                      <IconButton
                                        aria-label="copy"
                                        className="d-flex align-content-center flex-column"
                                      >
                                        <div>
                                          <FilterNoneIcon />
                                        </div>
                                        <span>Copiar código</span>
                                      </IconButton>
                                    </S.Copiar>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </S.ListaBoletoItemBody>
                            </S.ListaBoletoItem>
                          )}
                        </>
                      );
                    })
                  ) : (
                    <S.SemDados>Nenhum boleto encontrado</S.SemDados>
                  )}
                </>
              )}
              {boletos.length === 0 && (
                <S.SemDados>{emptyBoletosMessage}</S.SemDados>
              )}
            </div>
            <div>
              {boletos.length > 0 && (
                <>
                  {filteredDataPaginated.length > 0 ? (
                    filteredDataPaginated.map((boleto) => {
                      return (
                        <S.ListaBoletoItemMobile key={boleto.idboleto}>
                          <S.ListaBoletoItemBodyMobile>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "95%",
                              }}
                            >
                              {/* <b>
                                <p>Valor: {formatMoney(boleto.valor)}</p>
                              </b> */}
                              {boleto.situacao === "Baixado" ? (
                                <S.Pago>
                                  <span>Pago</span>
                                </S.Pago>
                              ) : boleto.situacao === "Cancelado" ? (
                                <S.Cancelado>
                                  <span>Cancelado</span>
                                </S.Cancelado>
                              ) : boleto.situacao === "Em aberto" ? (
                                <S.EmAberto>
                                  <span>Em aberto</span>
                                </S.EmAberto>
                              ) : boleto.situacao === "Vencido" ? (
                                <S.Atrasado>
                                  <span>Atrasado</span>
                                </S.Atrasado>
                              ) : (
                                <S.Cancelado>
                                  <span>Cancelado</span>
                                </S.Cancelado>
                              )}
                            </div>
                          </S.ListaBoletoItemBodyMobile>
                          <div>
                            <S.ListaBoletoItemBodyMobile>
                              <b>Serviço: </b>
                              <span> {boleto.descricaoServico}</span>
                            </S.ListaBoletoItemBodyMobile>
                            <S.ListaBoletoItemBodyMobile>
                              <b>Referente a: </b>
                              <span style={{ textTransform: "capitalize" }}>
                                {" "}
                                {dayjs(boleto.dataCompetencia).format("MMMM")}
                              </span>
                            </S.ListaBoletoItemBodyMobile>
                            <S.ListaBoletoItemBodyMobile>
                              <b>Vencimento: </b>
                              <span>
                                {" "}
                                {formatStringToDate(boleto.vencimento)}
                              </span>
                            </S.ListaBoletoItemBodyMobile>
                          </div>

                          <S.ListaBoletoItemBodyMobile>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "95%",
                                alignItems: "center",
                              }}
                            >
                              {boleto.situacao === "Baixado" ? (
                                <b>
                                  <span>
                                    Data do pagamento:{" "}
                                    {boleto.databaixa
                                      ? formatStringToDate(boleto.databaixa)
                                      : ""}
                                  </span>
                                </b>
                              ) : boleto.situacao !== "Cancelado" ? (
                                <>
                                  {boletoOnDownload?.idboleto ===
                                  boleto.idboleto ? (
                                    <S.CopiarMobile>
                                      <img
                                        src={Spin}
                                        alt="Baixando..."
                                        width="30"
                                        height="30"
                                      />
                                    </S.CopiarMobile>
                                  ) : (
                                    <S.CopiarMobile>
                                      <IconButton
                                        aria-label="download"
                                        onClick={() =>
                                          handleDownloadBoleto(boleto)
                                        }
                                      >
                                        <DownloadIcon />
                                        <span>Baixar Boleto</span>
                                      </IconButton>
                                    </S.CopiarMobile>
                                  )}
                                  <S.CopiarMobile>
                                    <IconButton
                                      aria-label="copy"
                                      onClick={() =>
                                        navigator.clipboard.writeText(
                                          `${boleto.ipte}`
                                        )
                                      }
                                    >
                                      <FilterNoneIcon />
                                      <span>Copiar Código</span>
                                    </IconButton>
                                  </S.CopiarMobile>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </S.ListaBoletoItemBodyMobile>
                        </S.ListaBoletoItemMobile>
                      );
                    })
                  ) : (
                    <S.SemDadosMobile>
                      Nenhum boleto encontrado
                    </S.SemDadosMobile>
                  )}
                </>
              )}
              {boletos.length === 0 && (
                <S.SemDadosMobile>{emptyBoletosMessage}</S.SemDadosMobile>
              )}
            </div>
          </div>
          <S.Paginator>
            <TablePagination
              component="div"
              count={
                boletoSituacao !== "" ? filteredData.length : boletos.length
              }
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              rowsPerPageOptions={[5, 10, 25]}
              labelRowsPerPage=""
            />
          </S.Paginator>
        </S.Card>
      )}
    </S.Container>
  );
};
