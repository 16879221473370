import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";

import * as S from "./style";
import * as I from "./type";

const prefix = "components.atoms.collapse";

export const Collapse = ({ title, children, open }: I.CollapseProps) => {
  const [active, setActiveState] = useState(open);
  const [setHeight, setHeightState] = useState("0px");
  const { t } = useTranslation();

  const content: any = useRef();

  const handleToggleAccordion = () => {
    setActiveState((prevState) => !prevState);
    setHeightState(active ? "auto" : `0px`);
  };

  return (
    <S.Container>
      {active && (
        <S.CollapseContent
          ref={content}
          style={
            active
              ? { height: `${content?.current?.scrollHeight}px` }
              : { height: "0px" }
          }
          active={active}
        >
          {children}
        </S.CollapseContent>
      )}
      <S.CollapseButton onClick={handleToggleAccordion}>
        {active ? t(`${prefix}.subtitle`) : title}
      </S.CollapseButton>
    </S.Container>
  );
};
