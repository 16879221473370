import * as S from "./styles";
import * as I from "./type";

export const Text = ({
  fontSize,
  fontWeight,
  gray,
  purple,
  textUpperCase,
  lineHeight,
  marginTop,
  marginBottom,
  children,
  ...rest
}: I.TextInterface) => {
  return (
    <S.Text
      {...rest}
      fontSize={fontSize}
      fontWeight={fontWeight}
      gray={gray}
      purple={purple}
      textUpperCase={textUpperCase}
      lineHeight={lineHeight}
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      {children}
    </S.Text>
  );
};
