import { useTranslation } from "react-i18next";

import { Container } from "./styles";

export const ErrorStatus = () => {
  const { t } = useTranslation();
  const i18n_prefix = "pages.EDF.manageVacancies.components.errorStatus";

  return (
    <Container>
      <h1>{t(`${i18n_prefix}.title`)}</h1>
    </Container>
  );
};
