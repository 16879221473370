import styled from "styled-components";
import * as colors from "styles/colors";

export const Container = styled.div`
  > div {
    h1 {
      color: ${colors.NEW_GRAY_SCALE};
      font-size: 20px;
    }
  }

  .drawerHeaderInfo {
    width: 100%;
    display: flex;
    align-items: center;

    padding: 16px 26px;

    h2 {
      font-size: 20px;
      font-weight: 700;
    }

    svg {
      color: ${colors.NEW_PURPLE};
    }

    button {
      background: none;
      border: none;
      justify-content: flex-start;
    }

    button:last-child {
      justify-content: flex-end;
    }
  }

  .drawerFilter {
    margin-top: 30px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    strong {
      font-size: 20px;
    }
  }

  .listData {
    width: 100%;
    padding: 0px 16px;
  }

  .listFilter {
    display: grid;
    grid-template-columns: 80% 20%;
    border-bottom: 1px solid ${colors.GRAY_SCALE};

    margin-top: 8px;

    strong {
      margin: 0;
      display: flex;
      align-items: center;
      padding: 8px 16px;
      cursor: pointer;
      width: fit-content;
    }
  }

  .totalAbsences {
    display: flex;
    flex-direction: column;
    padding: 8px 0;
    border-bottom: 1px solid ${colors.NEW_GRAY_THREE};

    .quarter,
    .anual {
      display: grid;
      grid-template-columns: 80% 20%;
      align-items: center;

      p {
        text-align: end;
        margin: 0;
      }
    }

    .quarter {
      font-size: 16px;
    }

    strong {
      padding: 0 16px;
    }
  }

  .loadingContent {
    margin: 0 auto;
  }
`;
