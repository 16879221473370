import styled from "styled-components";
import { BLUE } from "styles/colors";

interface CircleProps {
  fillColor: string;
}

export const Circle = styled.div<CircleProps>`
  background-color: ${(p) => p.fillColor};
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;

export const TableStyled = styled.div`
  .table-container {
    display: flex;

    .scroll {
      width: 1000px;
      overflow-x: scroll;
    }

    .fixed {
      max-width: 500px;
      border-spacing: 0;
      margin-bottom: 20px;

      thead {
        tr {
          background-color: ${BLUE};
          color: #fff;
          height: 130px !important;
          th {
            border: 1px solid #999;
            border-bottom: none;
          }
        }
      }
      td {
        font-size: 12px;
        min-width: 130px !important;
        height: 60px !important;
        border: 1px solid #221111;
      }
    }

    .scrollable {
      thead {
        tr {
          background-color: ${BLUE};
          color: #fff;
          height: 130px !important;
          th {
            border: 1px solid #999;
            border-bottom: none;
          }
        }
      }
      td {
        font-size: 12px;
        min-width: 90px !important;
        height: 60px !important;
        border: 1px solid #221111;
      }
    }

    td {
      font-size: 12px;
      min-width: 130px !important;
      height: 50px !important;
      border: 1px solid #221111;
    }
  }
`;
