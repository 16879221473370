import { LinearProgress } from "@material-ui/core";
import { Button, TextInput } from "components";
import SelectInput from "components/Select";
import { toastError, toastSuccess } from "components/Toast";
import {
  composeValidators,
  finalGreaterThanInitial,
  greaterThanZeroLessThan31,
  required,
} from "helpers/fieldValidators";
import { getError } from "helpers/helpers";
import HTTP_STATUS from "http-status-codes";
import { FoodItemAvailableRangeInterface } from "interfaces/mealInterfaces";
import { useContext, useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import {
  createFoodItemsAvailableRanges,
  getFoodItemsAvailableRanges,
  updateFoodItemsAvailableRanges,
} from "services/meals.service";
import { getUnits, UnitInterface } from "services/units.service";
import loadingGif from "static/img/loading.gif";
import { RegisterBusinessDayStyle } from "./style";

export const CadastroVigenciaPacoteDeAlimentacao = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [units, setUnits] = useState<Array<UnitInterface>>();

  const [loadingRangeData, setLoadingRangeData] = useState(false);

  useEffect(() => {
    const fetchUnits = async () => {
      const response = await getUnits();
      if (response && response.status === HTTP_STATUS.OK) {
        setUnits(response.data);
        setLoading(false);
      } else {
        setLoading(false);
        setError(true);
      }
    };
    fetchUnits();
  }, []);

  const onSubmit = async (
    values: FoodItemAvailableRangeInterface,
    form: any
  ) => {
    if (values.id) {
      const response = await updateFoodItemsAvailableRanges({
        id: values.id,
        payload: values,
      });
      if (response) {
        if (response.status === HTTP_STATUS.OK) {
          toastSuccess(
            "Vigência do pacote de alimentação alterado com sucesso"
          );
          form.change("id", response.data.id);
        } else {
          toastError(getError(response.data));
        }
      }
    } else {
      const response = await createFoodItemsAvailableRanges({
        payload: values,
      });
      if (response) {
        if (response.status === HTTP_STATUS.CREATED) {
          toastSuccess("Vigência do pacote de alimentação criado com sucesso");
          form.change("id", response.data.id);
        } else {
          toastError(getError(response.data));
        }
      }
    }
  };

  return (
    <RegisterBusinessDayStyle className="container">
      {loading && <LinearProgress className="mt-3 mb-3" color="secondary" />}
      {!loading && error && (
        <div>Erro ao carregar a página de cadastro de dias úteis.</div>
      )}
      {units && !loading && (
        <div>
          <div className="enrollment-title text-center mb-5">
            Cadastro da vigência do pacote de alimentação
          </div>
          <Form onSubmit={onSubmit}>
            {({ form, handleSubmit, submitting, values }) => (
              <form onSubmit={handleSubmit}>
                <div className="col-sm-6 col-12">
                  <Field hidden component={TextInput} name="id" type="text" />
                  <Field component={SelectInput} name="unit" required>
                    <option value="">Selecione a Unidade...</option>
                    {units.map((units) => {
                      return (
                        <option value={units.id} key={units.id}>
                          {units.name}
                        </option>
                      );
                    })}
                  </Field>
                  <OnChange name="unit">
                    {async (value) => {
                      if (value) {
                        setLoadingRangeData(true);
                        const response = await getFoodItemsAvailableRanges({
                          unit: value,
                        });
                        if (response && response.data.length > 0) {
                          form.change("id", response.data[0].id);
                          form.change(
                            "initial_day",
                            response.data[0].initial_day
                          );
                          form.change("end_day", response.data[0].end_day);
                          setLoadingRangeData(false);
                        } else {
                          form.change("initial_day", undefined);
                          form.change("end_day", undefined);
                          form.change("id", undefined);
                          setLoadingRangeData(false);
                        }
                      }
                    }}
                  </OnChange>
                </div>
                {loadingRangeData && (
                  <div className="col-12 mt-3 mb-3">
                    <img height="25" src={loadingGif} alt="loading" />
                  </div>
                )}
                {!loadingRangeData && (
                  <>
                    <div className="col-sm-6 col-12">
                      <Field
                        component={TextInput}
                        disabled
                        defaultValue={1}
                        name="initial_day"
                        type="number"
                        placeholder=" "
                        label="Dia inicial"
                        required
                        hideCheck
                        maxLength="2"
                        validate={composeValidators(
                          required,
                          greaterThanZeroLessThan31
                        )}
                      />
                    </div>
                    <div className="col-sm-6 col-12">
                      <Field
                        component={TextInput}
                        name="end_day"
                        type="number"
                        placeholder=" "
                        label="Dia final"
                        required
                        hideCheck
                        maxLength="2"
                        validate={composeValidators(
                          required,
                          greaterThanZeroLessThan31,
                          finalGreaterThanInitial(values.initial_day)
                        )}
                      />
                    </div>
                  </>
                )}

                <div className="col-sm-3 offset-sm-9 col-12">
                  <Button disabled={submitting} type="submit">
                    {!submitting && `${values.id ? "Atualizar" : "Salvar"}`}
                    {submitting && (
                      <img height="25" src={loadingGif} alt="loading" />
                    )}
                  </Button>
                </div>
              </form>
            )}
          </Form>
        </div>
      )}
    </RegisterBusinessDayStyle>
  );
};
