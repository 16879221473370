import { LinearProgress } from "@material-ui/core";
import { Button, TextInput } from "components";
import { toastError, toastSuccess } from "components/Toast";
import formatString from "format-string-by-pattern";
import { ENROLLMENT_TYPE, SCHOOL_PLACES_STATUS } from "helpers/constants";
import {
  composeValidators,
  emailValidation,
  phoneValidation,
  required,
} from "helpers/fieldValidators";
import {
  deepCopy,
  formatPhone,
  getDomain,
  onlyNumbersFromString,
} from "helpers/helpers";
import HTTP_STATUS from "http-status-codes";
import { GetCXUserJson, GuardianInterface } from "interfaces/constants";
import { maskBuilder } from "pages/CORE/Profile/components/UserForm/helpers";
import { useContext, useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useHistory } from "react-router-dom";
import {
  getStudentData,
  sendNotificationsFirstAccessGuardian,
  updateUser,
  UserFormDataInterface,
} from "services/users.service";
import loadingGif from "static/img/loading.gif";
import { SignupCompletedStyled } from "./style";

interface SignupCompletedInterface {
  studentId: number;
  guardianId: string;
  guardianAuthId: string;
  enrollmentStatus: number;
  setStep: (step: number) => void;
}

export const SignupCompleted = ({
  studentId,
  guardianId,
  guardianAuthId,
  enrollmentStatus,
  setStep,
}: SignupCompletedInterface) => {
  const [student, setStudent] = useState<GetCXUserJson | undefined>(undefined);
  const [guardian, setGuardian] = useState<GuardianInterface | undefined>(
    undefined
  );
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  useEffect(() => {
    getStudentData({ id: studentId })
      .then((response) => {
        if (response) {
          setStudent(response.data);
          setGuardian(
            response.data.guardians.find(
              (g: GuardianInterface) => g.id.toString() === guardianId
            )
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [studentId, guardianId]);

  const onSubmit = async (values: UserFormDataInterface) => {
    let values_ = deepCopy(values);
    values_.phone = onlyNumbersFromString(values_.phone);
    await updateUser({
      id: parseInt(guardianId),
      role: "guardian",
      body: values_,
    }).then((response) => {
      if (response) {
        if (response.status === HTTP_STATUS.OK) {
          toastSuccess("Dados atualizados com sucesso");
          sendNotificationsFirstAccessGuardian({
            body: { guardian_id: guardianAuthId, domain: getDomain() },
          }).then((response_) => {
            if (response_) {
              if (response_.status === HTTP_STATUS.OK) {
                toastSuccess(
                  "E-mail e SMS de primeiro acesso reenviados com sucesso"
                );
              } else {
                toastError(
                  "Erro ao reenviar e-mail e SMS de primeiro acesso ao responsável"
                );
              }
            }
          });
          setGuardian(response.data);
          setEdit(false);
        } else {
          toastError(
            "Erro ao atualizar seus dados. Tente novamente mais tarde."
          );
        }
      }
    });
  };

  const finish = () => {
    if (window.confirm("Deseja finalizar o atendimento?")) {
      toastSuccess("Atendimento finalizado com sucesso!");
      history.push("/home");
    }
  };

  return (
    <SignupCompletedStyled className="container">
      {loading && <LinearProgress className="mt-3 mb-3" color="secondary" />}
      {student && guardian && (
        <>
          {enrollmentStatus === SCHOOL_PLACES_STATUS.LISTA_DE_ESPERA && (
            <div className="row mt-3 await-list">
              <div className="col-12 text-center">
                <p>Lista de espera</p>
                <p className="position">
                  Posição {student.last_enrollment.school_place_position}
                </p>
              </div>
            </div>
          )}
          <div className="title text-center mt-3 mb-3">
            {enrollmentStatus === SCHOOL_PLACES_STATUS.VAGA_RESERVADA &&
              `O(A) aluno(a) ${
                student.personal.name
              } já está com a vaga reservada
            para o ${student.last_enrollment.grade}º ano do 
            ${" " + student.last_enrollment.educational_level_name}`}
            {enrollmentStatus === SCHOOL_PLACES_STATUS.LISTA_DE_ESPERA &&
              `O(A) aluno(a) ${student.personal.name} já está na lista de espera para o ${student.last_enrollment.grade}º ano do ${student.educational_level.name}`}
          </div>
          <div className="mt-3 mb-3">
            Agora, o responsável precisa{" "}
            {enrollmentStatus === SCHOOL_PLACES_STATUS.VAGA_RESERVADA
              ? "entrar no portal da Escola Mais e efetuar o pagamento em até X dias para garantir a reserva."
              : "acessar o app para acompanhar o andamento da lista de espera."}
          </div>
          <p className="subtitle">Resumo</p>
          <p>
            <span>Aluno(a): </span>
            {student.personal.name}
          </p>
          <p>
            <span>Unidade: </span>
            {student.last_enrollment.unit_name}
          </p>
          <p>
            <span>Ano: </span>
            {student.last_enrollment.grade}º
          </p>
          <p>
            <span>Responsável: </span>
            {guardian.name}
          </p>
          <div className="edit-box">
            {!edit && (
              <>
                <p>
                  <span>E-mail: </span>
                  {guardian.email}
                  <span
                    onClick={() => setEdit(true)}
                    className="link float-right"
                  >
                    Editar
                  </span>
                </p>
                <p>
                  <span>Celular: </span>
                  {formatPhone(guardian.phone)}
                </p>
              </>
            )}
            {edit && (
              <>
                <Form
                  initialValues={{
                    phone: guardian.phone,
                    email: guardian.email,
                  }}
                  onSubmit={onSubmit}
                >
                  {({ handleSubmit, submitting, values }) => (
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12 text-right">
                          <span onClick={() => setEdit(false)} className="link">
                            Cancelar
                          </span>
                        </div>
                      </div>
                      <Field
                        component={TextInput}
                        name="email"
                        type="email"
                        placeholder=" "
                        label="E-mail"
                        maxLength="255"
                        validate={composeValidators(required, emailValidation)}
                        required
                      />
                      <Field
                        component={TextInput}
                        name="phone"
                        type="text"
                        parse={formatString(maskBuilder(values.phone))}
                        placeholder=" "
                        label="Celular"
                        validate={composeValidators(required, phoneValidation)}
                        required
                      />
                      <Button disabled={submitting} type="submit">
                        {!submitting && "Atualizar e reenviar link de acesso"}
                        {submitting && (
                          <img height="25" src={loadingGif} alt="loading" />
                        )}
                      </Button>
                    </form>
                  )}
                </Form>
              </>
            )}
          </div>
          <Button onClick={() => setStep(1)} className="blue outline">
            Incluir mais um(a) aluno(a)
          </Button>
          <Button onClick={() => finish()}>Finalizar atendimento</Button>
        </>
      )}
    </SignupCompletedStyled>
  );
};
