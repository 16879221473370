// Style
import * as S from "./style";
// Libs
import { Button } from "@escolamais-ui-v2/react";
import { useTranslation } from "react-i18next";
// Components
import { InputDate, MenuBar } from "components";
import { InputText } from "components/atoms/InputText";
import { NewInputFile } from "components/atoms/NewInputFile";
// Helpers
import { GuardianSignupHelper } from "./helper";
// Icons
import uploadIcon from "static/img/upload.svg";

// Remover o "New" assim que matar a tela antiga
export const NewGuardianSignup = () => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.guardianSignup";

  const { onSubmit, register, handleSubmit, trigger, watch, errors } =
    GuardianSignupHelper();

  return (
    <S.GuardianSignupContainer>
      <h1>{t(`${TRANSLATE}.pageTitle`)}</h1>
      <MenuBar title={t(`${TRANSLATE}.menuTitle`)} />
      <form onSubmit={handleSubmit(onSubmit)}>
        <h3>{t(`${TRANSLATE}.personalData`)}</h3>

        <NewInputFile.Root>
          <NewInputFile.Label
            label={t(`${TRANSLATE}.documentIdentificationLabel`)}
          />
          <NewInputFile.Input
            id="arquivos_documento_identificacao"
            register={register}
          >
            <NewInputFile.Icon
              icon={uploadIcon}
              alt="document-identification"
            />
            <NewInputFile.Text
              text={t(`${TRANSLATE}.documentIdentificationText`)}
            />
          </NewInputFile.Input>
          {errors.arquivos_documento_identificacao?.message && (
            <NewInputFile.Error
              text={errors.arquivos_documento_identificacao.message}
            />
          )}
          <NewInputFile.TextInformation
            text={t(`${TRANSLATE}.documentTextInformation`)}
          />
        </NewInputFile.Root>

        <div className="inLine">
          <InputText
            label={t(`${TRANSLATE}.fullNameInput`)}
            placeholder={t(`${TRANSLATE}.fullNamePlaceholder`)}
            id="nome"
            register={register}
            error={errors?.nome?.message}
            width={472}
          />

          <InputText
            label={t(`${TRANSLATE}.cpfInput`)}
            placeholder={t(`${TRANSLATE}.cpfPlaceholder`)}
            id="cpf"
            register={register}
            error={errors?.cpf?.message}
            width={228}
            mask="999999999-99"
          />

          <InputText
            label={t(`${TRANSLATE}.guardianDocumentInput`)}
            placeholder={t(`${TRANSLATE}.guardianDocumentPlaceholder`)}
            id="rg"
            register={register}
            error={errors?.rg?.message}
            width={228}
          />
        </div>

        <div className="inLine">
          <InputDate
            label={t(`${TRANSLATE}.birthDateInput`)}
            boxWidth={228}
            id="data_nascimento"
            register={register}
            error={errors?.data_nascimento?.message}
          />

          <InputText
            label={t(`${TRANSLATE}.totvsCodeInput`)}
            placeholder={t(`${TRANSLATE}.totvsCodePlaceholder`)}
            id="codigo_indicador"
            register={register}
            width={228}
          />
        </div>

        <h3>{t(`${TRANSLATE}.contact`)}</h3>

        <div className="inLine">
          <InputText
            label={t(`${TRANSLATE}.cellPhoneInput`)}
            placeholder={t(`${TRANSLATE}.cellPhonePlaceholder`)}
            id="celular"
            register={register}
            error={errors?.celular?.message}
            width={228}
            mask="(99) 99999-9999"
          />

          <InputText
            label={t(`${TRANSLATE}.phoneInput`)}
            placeholder={t(`${TRANSLATE}.phonePlaceholder`)}
            id="telefone"
            register={register}
            width={228}
            mask="(99) 9999-9999"
          />

          <InputText
            label={t(`${TRANSLATE}.emailInput`)}
            placeholder={t(`${TRANSLATE}.emailPlaceholder`)}
            id="email"
            register={register}
            error={errors?.email?.message}
            width={391}
          />
        </div>

        <h3>{t(`${TRANSLATE}.address`)}</h3>

        <div style={{ marginBottom: 8 }}>
          <NewInputFile.Root>
            <NewInputFile.Label
              label={t(`${TRANSLATE}.documentAddressLabel`)}
            />
            <NewInputFile.Input
              id="arquivos_documento_endereco"
              register={register}
              width={232}
            >
              <NewInputFile.Icon icon={uploadIcon} alt="document-address" />
              <NewInputFile.Text text={t(`${TRANSLATE}.documentAddressText`)} />
            </NewInputFile.Input>
            {errors.arquivos_documento_endereco?.message && (
              <NewInputFile.Error
                text={errors.arquivos_documento_endereco.message}
              />
            )}
            <NewInputFile.TextInformation
              text={t(`${TRANSLATE}.documentTextInformation`)}
            />
          </NewInputFile.Root>
        </div>

        <InputText
          label={t(`${TRANSLATE}.zipCodeInput`)}
          placeholder={t(`${TRANSLATE}.zipCodePlaceholder`)}
          id="cep"
          register={register}
          error={errors?.cep?.message}
          width={228}
          mask="99999-999"
        />

        <div className="inLine">
          <InputText
            label={t(`${TRANSLATE}.addressInput`)}
            placeholder={t(`${TRANSLATE}.addressPlaceholder`)}
            id="endereco"
            register={register}
            error={errors?.endereco?.message}
            width={472}
          />

          <InputText
            label={t(`${TRANSLATE}.numberInput`)}
            placeholder={t(`${TRANSLATE}.numberPlaceholder`)}
            id="numero"
            register={register}
            error={errors?.numero?.message}
            width={147}
          />

          <InputText
            label={t(`${TRANSLATE}.neighborhoodInput`)}
            placeholder={t(`${TRANSLATE}.neighborhoodPlaceholder`)}
            id="bairro"
            register={register}
            error={errors?.bairro?.message}
            width={228}
          />
        </div>

        <div className="inLine">
          <InputText
            label={t(`${TRANSLATE}.addressDetailsInput`)}
            placeholder={t(`${TRANSLATE}.addressDetailsPlaceholder`)}
            id="complemento"
            register={register}
            width={228}
          />

          <InputText
            label={t(`${TRANSLATE}.stateInput`)}
            placeholder={t(`${TRANSLATE}.statePlaceholder`)}
            id="estado"
            register={register}
            error={errors?.estado?.message}
            width={228}
          />

          <InputText
            label={t(`${TRANSLATE}.cityInput`)}
            placeholder={t(`${TRANSLATE}.cityPlaceholder`)}
            id="cidade"
            register={register}
            error={errors?.cidade?.message}
            width={228}
          />
        </div>

        <div className="buttons">
          <Button
            type="button"
            appearance="text"
            buttonText={t(`${TRANSLATE}.buttonBack`)}
            onClick={() => {}}
            size="large"
          />
          <Button
            type="submit"
            appearance="primary"
            buttonText={t(`${TRANSLATE}.buttonProgress`)}
            size="large"
          />
        </div>
      </form>
    </S.GuardianSignupContainer>
  );
};
