import styled, { css } from "styled-components";
import { ISectionStyles } from "./interface";
import * as colors from "styles/colors";

export const Container = styled.button<ISectionStyles>`
  max-width: 540px;
  width: 100%;

  padding: 8px 16px;
  border: 2px solid ${colors.NEW_GRAY};
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  cursor: pointer;

  & + button {
    margin-top: 8px;
  }

  &:disabled {
    cursor: no-drop;
  }

  ${({ sectionState }) =>
    sectionState === "Concluído" &&
    css`
      border: 2px solid ${colors.NEW_GREEN};
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.9),
          rgba(255, 255, 255, 0.9)
        ),
        #3ba550;

      h4 {
        color: ${colors.NEW_GRAY_TWO};
      }
    `}

  ${({ sectionState }) =>
    sectionState === "Em Andamento" &&
    css`
      border: 2px solid ${colors.NEW_PURPLE};
      background: rgba(109, 40, 217, 0.05);

      h4 {
        color: ${colors.NEW_GRAY_TWO};
      }
    `}
`;
