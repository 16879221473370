import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import HTTP_STATUS from "http-status-codes";

import Icon from "@mdi/react";
import { mdiPencilOutline } from "@mdi/js";

import { Title } from "components";
import { Loading } from "components/atoms";

import { getUnitLevels } from "services/grades.service";
import { IGradesLevelDetails, IStudentsUnitTotal } from "./interface";

import * as S from "./styles";
import { useHistory } from "react-router-dom";
import { authUserAtom } from "jotai/authUser";
import { useAtomValue } from "jotai";

const prefix = "pages.AP.classesManagement";

export const ClassesManagement = () => {
  const { t } = useTranslation();
  const authUser = useAtomValue(authUserAtom);
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [gradesLevelDetails, setGradesLevelDetails] =
    useState<IGradesLevelDetails[]>();
  const [studentsUnitTotal, setStudentsUnitTotal] = useState<
    IStudentsUnitTotal | undefined
  >(undefined);

  useEffect(() => {
    const loadLevelsByUnit = async () => {
      try {
        const res = await getUnitLevels(authUser.unit);

        setStudentsUnitTotal(res?.data.total_estudantes);
        setGradesLevelDetails(res?.data.turmas);

        if (res?.status !== HTTP_STATUS.OK || res?.data.turmas.length <= 0) {
          throw new Error(t(`${prefix}.notFound`));
        }
      } catch (error) {
        setError(error?.message);
      } finally {
        setIsLoading(false);
      }
    };

    loadLevelsByUnit();
  }, [authUser, t]);

  if (isLoading) {
    return (
      <>
        <Loading />
      </>
    );
  }

  return (
    <S.Container>
      {error ? (
        <>
          <p>{error}</p>
        </>
      ) : (
        <>
          <Title fontWeight="700" lineHeight="1" marginBottom="0" responsive>
            {t(`${prefix}.title`)}
          </Title>

          <div className="statsCards">
            <S.CardData type="success">
              <strong>{t(`${prefix}.studentsWithSection`)}</strong>
              <h2>{studentsUnitTotal?.enturmados}</h2>
            </S.CardData>
            <S.CardData type="warning">
              <strong>{t(`${prefix}.studentsWithoutSection`)}</strong>
              <h2>{studentsUnitTotal?.sem_turma}</h2>
            </S.CardData>
            <S.CardData>
              <strong>{t(`${prefix}.totalStudentsWithSection`)}</strong>
              <h2>{studentsUnitTotal?.total}</h2>
            </S.CardData>
          </div>

          <div className="listTable">
            <div className="listHeader">
              <strong>{t(`${prefix}.tableInfo.grades`)}</strong>
              <strong>{t(`${prefix}.tableInfo.sections`)}</strong>
              <strong>{t(`${prefix}.studentsWithSection`)}</strong>
              <strong>{t(`${prefix}.studentsWithoutSection`)}</strong>
              <strong>{t(`${prefix}.tableInfo.actionButton`)}</strong>
            </div>
            <div className="listData">
              {gradesLevelDetails?.map(
                (level) =>
                  level.qtd_turmas > 0 && (
                    <div className="listItem" key={level.id}>
                      <p>{level.display}</p>
                      <p>{level.qtd_turmas}</p>
                      <p>{level.qtd_enturmados}</p>
                      <p>{level.qtd_sem_turma}</p>
                      <button
                        onClick={() =>
                          history.push({
                            pathname: "/enturmamento/editar",
                            state: { id: level.id },
                          })
                        }
                      >
                        <Icon path={mdiPencilOutline} size={1} />
                        {t(`${prefix}.redirectButton`)}
                      </button>
                    </div>
                  )
              )}
            </div>
          </div>
        </>
      )}
    </S.Container>
  );
};
