import styled from "styled-components";
import * as colors from "styles/colors";

export const Table = styled.table`
  width: 100%;
  margin-right: 1em;

  tr.firstRow {
    font-weight: 700;
    font-size: 14px;
    border-bottom: 1px solid ${colors.GRAY_SCALE};
  }

  th.firstColumn {
    width: 60%;
  }

  tr {
    line-height: 40px;
    border-bottom: 2px solid ${colors.NEW_GRAY_THREE};
  }

  tr.body {
    td {
      p {
        padding-left: 7px;
        margin-bottom: 0;
      }
    }
  }

  border-bottom: 1px solid ${colors.NEW_GRAY_THREE};

  @media only screen and (max-width: 500px) {
    width: 95%;
  }
`;
