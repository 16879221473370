import { useTranslation } from "react-i18next";
import * as S from "./styles";
import { IItinerarieStatus } from "types/study-itinerary";

const TRANSLATION_PREFIX = "components.atoms.itinerarieStatus";

export interface IItinerarieStatusProps {
  type: IItinerarieStatus;
}
export const ItinerarieStatus = ({
  type = "Atrasado",
}: IItinerarieStatusProps) => {
  const { t } = useTranslation();
  return (
    <S.Container type={type}>
      {type === "Feito" && <span>{t(`${TRANSLATION_PREFIX}.done`)}</span>}
      {type === "Pendente" && <span>{t(`${TRANSLATION_PREFIX}.pending`)}</span>}
      {type === "Atrasado" && <span>{t(`${TRANSLATION_PREFIX}.late`)}</span>}
      {type === "Em Andamento" && (
        <span>{t(`${TRANSLATION_PREFIX}.inProgress`)}</span>
      )}
    </S.Container>
  );
};
