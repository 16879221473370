import styled from "styled-components";
import * as colors from "styles/colors";

export const ModalDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  margin-top: 1em;
  padding: 1em;

  div.content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: 2px solid ${colors.NEW_PURPLE};
  }

  .img {
    width: 100%;
  }

  div.hasPicture {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;
