import styled from "styled-components";
import { BLUE, GRAY_DISABLED } from "styles/colors";

export const PesquisaDeChamadaStyled = styled.div`
  div.title {
    font-size: 24px;
    font-weight: bold;
    color: ${BLUE};
  }
  table {
    width: 100%;
    thead {
      tr {
        border: 1px solid ${GRAY_DISABLED};
        border-bottom: none;
        th {
          padding: 1em;
          border-right: 1px solid ${GRAY_DISABLED};
        }
      }
    }
    tbody {
      tr {
        border: 1px solid ${GRAY_DISABLED};
        td {
          padding: 1em;
          border-right: 1px solid ${GRAY_DISABLED};
          span {
            svg {
              fill: ${BLUE};
              cursor: pointer;
            }
          }
        }
      }
    }
  }
`;
