import { useHistory, useLocation } from "react-router-dom";
import { StudentBox } from "components/atoms";
import { IStudentCard } from "./interface";
import { ILocationFoodPage } from "pages/EDF/FoodPage/interface";
import { StudentHeader } from "components";
import { Container } from "./styles";

export const StudentCard = ({ studentInfo }: IStudentCard) => {
  const history = useHistory();
  const location = useLocation<ILocationFoodPage>();
  return (
    <Container
      onClick={() =>
        history.push({
          pathname: "/boletos-liste-estudantes/boleto",
          state: {
            studentUser: studentInfo,
            guardianUser: location.state.user,
          },
        })
      }
    >
      <StudentBox>
        <StudentHeader student={studentInfo} />
      </StudentBox>
    </Container>
  );
};
