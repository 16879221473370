import styled from "styled-components";

export const DrawerDinamycContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  gap: 0 65px; // TODO: nao tem na lib

  padding: ${({ theme }) => theme.space[4]};

  .sameLine {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  form:last-of-type {
    display: flex;
    flex-direction: column;

    gap: 5px 0; // TODO: nao tem na lib

    label {
      font-size: ${({ theme }) => theme.fontSizes.web.sm};
      margin-bottom: 2px; // TODO: nao tem na lib
    }

    font-size: ${({ theme }) => theme.fontSizes.web.sm};

    div {
      display: flex;
      align-items: center;
      gap: 0 ${({ theme }) => theme.space[1]};

      span {
        padding-bottom: 2px; // TODO: nao tem na lib
      }
    }
  }

  @media (max-width: 360px) {
    padding: ${({ theme }) => theme.space[2]};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 0 ${({ theme }) => theme.space[4]};

  position: absolute;
  bottom: ${({ theme }) => theme.space[6]};
  right: ${({ theme }) => theme.space[4]};

  @media (max-width: 360px) {
    left: ${({ theme }) => theme.space[4]};
    gap: 0 6px; // TODO: nao tem na lib
  }
`;
