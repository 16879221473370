import { HTMLAttributes } from "react";

interface IDiscountPercentageProps extends HTMLAttributes<HTMLInputElement> {
  value: string;
  disabled?: boolean;
}
export const DiscountPercentageInput = ({
  value,
  disabled,
  ...rest
}: IDiscountPercentageProps) => (
  <>
    <input
      value={value}
      {...rest}
      disabled={disabled}
      type="text"
      maxLength={3}
    />{" "}
    <span>%</span>
  </>
);
