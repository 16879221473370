import { Loader, Circle } from "./styles";

const Loading = () => {
  return (
    <Loader className="show">
      <Circle className="circle"></Circle>
      <Circle className="circle"></Circle>
      <Circle className="circle"></Circle>
    </Loader>
  );
};

export default Loading;
