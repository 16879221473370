import styled from "styled-components";
import * as colors from "styles/colors";

export const Container = styled.div`
  max-width: 1008px;
  width: 100%;
  margin: 0 auto;
  padding: ${({ theme }) => theme.space[6]};

  h1 {
    color: ${({ theme }) => theme.colors.primaryDefault};
    font-weight: 600; // TODO: Add font weight 600
    font-size: 40px;
  }
`;

export const LoadingContainer = styled.div`
  padding: ${({ theme }) => theme.space[4]};
  width: 100%;

  display: flex;
  justify-content: center;
`;

export const GroupButtonsContainer = styled.div`
  display: flex;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

export const ContentContainer = styled.div`
  min-height: 100vh;
  padding-top: ${({ theme }) => theme.space[10]};
`;
