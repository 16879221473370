// Libs
import { useCallback, useContext, useState, useRef } from "react";
import { Field, Form } from "react-final-form";
import { FormApi } from "final-form";
import HTTP_STATUS from "http-status-codes";

// Components
import { RadioButton, TextInput } from "components";
import { ModalCardCreditFood } from "../Modal";
import { toastError } from "components/Toast";

// Styles
import { Folder } from "@material-ui/icons";
import { Tooltip } from "@material-ui/core";
import { CardFoodCreditBox } from "./style";

// Helpers
import {
  formatIsoStringToDate,
  formatMoney,
  formatMoneyInput,
  formatMoneyToFloat,
} from "helpers/helpers";
import {
  StudentFoodCreditHistory,
  StudentFoodCreditInterface,
} from "interfaces/mealInterfaces";
import ModalFoodCreditHistory from "../ModalFoodCreditHistory";
import { getStudentFoodCreditHistory } from "services/meals.service";

type StudentFoodCreditCardProps = {
  studentFoodCredit: StudentFoodCreditInterface;
};

type FormValues = {
  reason: string;
  credit: string;
};

export type LastCreditType = {
  credit?: number;
  name?: string;
  date?: string;
};

type ModalFoodCreditHistoryRefType = {
  setIsLoadingHistory: () => void;
  setNextPage: (url: string) => void;
  setPreviousPage: (url: string) => void;
};

export const StudentFoodCreditCard = ({
  studentFoodCredit,
}: StudentFoodCreditCardProps) => {
  const modalFoodCreditHistoryRef = useRef<ModalFoodCreditHistoryRefType>(null);

  const [toggleModal, setToggleModal] = useState(false);
  const [lastCredit, setLastCredit] = useState<LastCreditType>({
    credit: studentFoodCredit.last_credit?.credit,
    name: studentFoodCredit.last_credit?.user_name,
    date: studentFoodCredit.last_credit?.data,
  });

  const [toggleModalHistory, setToggleModalHistory] = useState(false);
  const [foodCreditHistory, setFoodCreditHistory] =
    useState<StudentFoodCreditHistory | null>(null);

  const onSubmit = (values: FormValues) => {
    if (parseFloat(formatMoneyToFloat(values.credit)) > 0) {
      setToggleModal(true);
    } else {
      toastError("Insira uma valor válido!");
    }
  };

  const resetForm = useCallback((form: FormApi) => {
    form.reset();
  }, []);

  const handleFoodCreditHistory = async (enrollment: number) => {
    setToggleModalHistory(true);

    const response = await getStudentFoodCreditHistory({
      id: enrollment,
    });

    if (response && response.status === HTTP_STATUS.OK) {
      if (response.data.results.length > 0) {
        setFoodCreditHistory(response.data);

        if (modalFoodCreditHistoryRef.current) {
          modalFoodCreditHistoryRef.current.setNextPage(response.data.next);
          modalFoodCreditHistoryRef.current.setPreviousPage(
            response.data.previous
          );
        }
      } else {
        toastError("O aluno não possui registros de lançamento!");
      }
    } else {
      toastError("Erro ao buscar histórico do alunos!");
    }

    modalFoodCreditHistoryRef.current &&
      modalFoodCreditHistoryRef.current.setIsLoadingHistory();
  };

  return (
    <CardFoodCreditBox className="d-flex flex-column mb-2">
      <div className="d-flex flex-column align-items-start">
        <div className="w-100 d-flex flex justify-content-between">
          <p className="studentName mb-0 font-weight-bold">
            {studentFoodCredit.student_name}
          </p>

          {lastCredit.credit && (
            <Tooltip title="Ver lançamentos">
              <div>
                <button
                  className="downloadIcon text-uppercase border-0 bg-transparent"
                  onClick={() => handleFoodCreditHistory(studentFoodCredit.id)}
                >
                  <Folder />
                </button>
              </div>
            </Tooltip>
          )}
        </div>

        <p className="studentInfo">
          {`
          ${studentFoodCredit.unit_name} - 
          ${studentFoodCredit.grade}º${studentFoodCredit?.section}
        `}
        </p>
      </div>

      <div className="border-bottom">
        <div className="mb-3">
          <h6 className="guardianName mb-0">
            {studentFoodCredit.financial_guardian_name}
          </h6>
          <small className="studentInfo">Responsável Financeiro</small>
        </div>
      </div>

      {lastCredit.credit && (
        <div className="mt-3 border-bottom">
          <div className="d-flex align-items-center justify-content-between">
            <h6 className="latestRelease mb-0 mr-2">Último lançamento:</h6>
            <p className="latestRelease mb-0 mr-2 h6">
              {formatMoney(lastCredit.credit)}
            </p>
          </div>
          <div className="mb-3">
            <small className="latestReleaseDetails">
              Por {lastCredit?.name} em{" "}
              {formatIsoStringToDate(lastCredit?.date)}
            </small>
          </div>
        </div>
      )}

      <div className="d-flex flex-column mt-3">
        <p className="subtitleCredit mb-0">Fazer lançamento de crédito</p>
        <p className="selectTitle h6 mt-1">Selecione o motivo:</p>
      </div>

      <Form onSubmit={onSubmit}>
        {({ form, handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <div className="d-flex flex-column">
              {studentFoodCredit.credit_reasons.map(({ value, label }) => {
                return (
                  <div className="typesCheckbox d-flex" key={value}>
                    <label className="mt-3">
                      <Field
                        component={RadioButton}
                        name="reason"
                        type="radio"
                        value={value.toString()}
                        required
                        className="p-0 pr-1"
                      />
                      {label}
                    </label>
                  </div>
                );
              })}
            </div>
            <div className="creditValue mt-3">
              <h6>Digite o valor a ser lançado</h6>
              <div className="creditValuesFields">
                <div className="mt-2 d-flex w-100 align-items-center justify-content-between">
                  <Field
                    name="credit"
                    component={TextInput}
                    variant="standard"
                    type="text"
                    className="bg-white rounded-lg font-weight-bold"
                    placeholder="R$ 00,00"
                    parse={(value) => formatMoneyInput(value)}
                    required
                    hideCheck
                  />
                  <button
                    className="button border-0 bg-transparent mb-0 py-3 px-3 text-uppercase h6"
                    type="submit"
                  >
                    Confirmar
                  </button>
                </div>
              </div>
            </div>
            <ModalCardCreditFood
              isOpen={toggleModal}
              setToggleModal={setToggleModal}
              enrollment={studentFoodCredit.id}
              creditFoodValues={{
                credit: values?.credit,
                reason: values?.reason,
              }}
              form={form}
              resetForm={resetForm}
              setLastCredit={setLastCredit}
            />

            <ModalFoodCreditHistory
              ref={modalFoodCreditHistoryRef}
              isOpen={toggleModalHistory}
              name={studentFoodCredit.student_name}
              enrollment={studentFoodCredit.id}
              foodCreditHistory={foodCreditHistory}
              setFoodCreditHistory={setFoodCreditHistory}
              setToggleModalHistory={setToggleModalHistory}
            />
          </form>
        )}
      </Form>
    </CardFoodCreditBox>
  );
};
