// React
import React from "react";
// Styles
import * as S from "./style";
// Helpers
import { MONTHS } from "helpers/constants";
import { standardDate } from "helpers/helpers";
// Interfaces
import { ITableWorkingDays } from "./interface";
// Libs
import { useTranslation } from "react-i18next";

export const TableWorkingDays = ({ workingDays }: ITableWorkingDays) => {
  const { t } = useTranslation();
  const prefix = "pages.EDF.workingDaysRegister.components.table";

  return (
    <div>
      <S.Table>
        <thead>
          <tr>
            <th>{t(`${prefix}.unit`)}</th>
            <th>{t(`${prefix}.month`)}</th>
            <th>{t(`${prefix}.year`)}</th>
            <th>{t(`${prefix}.workingDays`)}</th>
            <th>{t(`${prefix}.registerDate`)}</th>
          </tr>
        </thead>
        <tbody>
          {workingDays.map((unit, index) => {
            return (
              <React.Fragment key={index}>
                {MONTHS.map((month) => {
                  return (
                    <React.Fragment key={month.id}>
                      {month.id === unit.month && (
                        <tr className="body" key={index}>
                          <td>{unit.unit_name}</td>
                          <td>{month.name}</td>
                          <td>{unit.year}</td>
                          <td className="latestTypes">{unit.days}</td>
                          <td className="latestTypes">
                            {standardDate(unit.updated_at)}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            );
          })}
        </tbody>
      </S.Table>
    </div>
  );
};
