import { useHistory } from "react-router-dom";
import { GradesManagerContainer } from "./style";
import { ButtonCard } from "components/atoms/ButtonCard";
import { useTranslation } from "react-i18next";

const TRANSLATION_PREFIX = "pages.CORE.home.gradesManager";

export const GradesManagerHome = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <GradesManagerContainer>
      <ButtonCard
        onClick={() => history.push("/dashboard")}
        text={t(`${TRANSLATION_PREFIX}.dashboard`)}
      />
      <ButtonCard
        onClick={() => history.push("/consulta-diario-de-classe")}
        text={t(`${TRANSLATION_PREFIX}.rollSearch`)}
      />
      <ButtonCard
        onClick={() => history.push("/gerenciar/matriculas")}
        text={t(`${TRANSLATION_PREFIX}.manageEnrollmentProcessQuery`)}
      />
      <ButtonCard
        onClick={() => history.push("/gerenciar/turmas")}
        text={t(`${TRANSLATION_PREFIX}.classAssignment`)}
      />
      <ButtonCard
        onClick={() => history.push("/lancamento/componentes")}
        text={t(`${TRANSLATION_PREFIX}.releases`)}
      />
      <ButtonCard
        onClick={() => history.push("/enturmamento")}
        text={t(`${TRANSLATION_PREFIX}.classesManagement`)}
      />
      {/* <ButtonCard
        onClick={() => history.push("/calendario-escolar")}
        text={t(`${TRANSLATION_PREFIX}.calendarioEscolar`)}
      /> */}
    </GradesManagerContainer>
  );
};
