// React
import { useEffect, useState } from "react";

// Libs
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import { privateRoutes, publicRoutes, PERMISSIONS_BY_PAGE } from "router";
// Components
import { Header } from "components";
import { anyDrawerItem, DRAWER_WIDTH } from "components/Drawer/constants";
// Pages
import { NotAllowedPage, NotFoundPage } from "pages/CORE";
import { Storage } from "Storage";
import { NullRoleObject } from "models";
import { useAtomValue } from "jotai";
import { authUserAtom } from "jotai/authUser";
import { refreshToken } from "services/auth.service";
import moment from "moment";
import { compareDates } from "helpers/helpers";

const PublicRouter = ({ component: Component, ...rest }: any) => (
  <Route {...rest} render={(props) => <Component {...props} />} />
);

const PrivateRouter = ({
  component: Component,
  has_permission,
  classes,
  path,
  ...rest
}: any) => {
  // Context
  const authUser = useAtomValue(authUserAtom);
  const role = authUser?.role[0] ?? NullRoleObject;
  const history = useHistory();

  // session validator
  useEffect(() => {
    const tokens = Storage.token.get();
    if (!tokens) {
      history.replace("/");
    }

    if (tokens && tokens.refresh && tokens.expiration) {
      setTimeout(() => {
        (async () => {
          const response = await refreshToken(tokens.refresh!);
          if (response) {
            Storage.token.set(response);
          }
        })();
      }, compareDates(tokens.expiration));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        authUser.id ? (
          has_permission ? (
            <div className={anyDrawerItem(role) && classes.content!}>
              <Header />
              <Component {...props} />
            </div>
          ) : (
            <Redirect
              to={{ pathname: "/403", state: { from: props.location } }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export const Routes = () => {
  const path = window.location.pathname;
  const authUser = useAtomValue(authUserAtom);

  const role = Storage.props.role.get() ?? NullRoleObject;

  if (!role.id && path === "/indicacao") {
    sessionStorage.setItem("redirectToIndication", "true");
  }

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      content: {
        [theme.breakpoints.up("sm")]: {
          fontFamily: `"Poppins", sans-serif`,
          width: `calc(100% - ${DRAWER_WIDTH}px)`,
          marginLeft: DRAWER_WIDTH,
          flexGrow: 1,
          paddingLeft: theme.spacing(0),
          paddingRight: theme.spacing(0),
        },
      },
    })
  );

  const classes = useStyles();

  const PrivateRouterSwitch = () => {
    return (
      <Switch>
        {privateRoutes.map((value, key) => {
          return (
            <PrivateRouter
              key={key}
              path={value.path}
              exact={value.exact}
              component={value.component}
              has_permission={
                value.path === "/home"
                  ? true
                  : PERMISSIONS_BY_PAGE[value.path]?.includes(role.id)
              }
              role={role}
              classes={classes}
            />
          );
        })}
        <Route path="/403" component={NotAllowedPage} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    );
  };

  return (
    <BrowserRouter>
      <Switch>
        {publicRoutes.map((value, key) => {
          return (
            <PublicRouter
              key={key}
              path={value.path}
              exact={value.exact}
              component={value.component}
            />
          );
        })}
        {PrivateRouterSwitch()}
      </Switch>
    </BrowserRouter>
  );
};
