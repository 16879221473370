import Icon from "@mdi/react";
import { ReactNode } from "react";

import * as S from "./style";

interface IModal {
  iconPath?: string;
  isOpen: boolean;
  onClose?: () => void;
  title: string;
  content?: string;
  children?: ReactNode;
  width?: string;
}

export const ModalInfo = ({
  iconPath,
  isOpen,
  onClose,
  title,
  content,
  children,
  width,
}: IModal) => {
  return (
    <>
      {isOpen && (
        <S.Overlay>
          <S.Container width={width}>
            {iconPath && <Icon path={iconPath} />}
            <strong>{title}</strong>
            {content && <p>{content}</p>}
            {children}
          </S.Container>
        </S.Overlay>
      )}
    </>
  );
};
