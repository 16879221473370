// React
import { useContext, useState } from "react";
// Styles
import { StudentFoodCardStyled } from "./style";
// Components
import { Text, Title, Button, StepPaymentStatus } from "components/atoms";
import { toastError, toastSuccess } from "components/Toast";
import { LoadingBox } from "components";
// Helpers
import { formatMoney, standardDate, standardHour } from "helpers/helpers";
import { SCHOLARSHIP_TYPE } from "helpers/constants";
// Services
import { addFoodPackage } from "services/meals.service";
// Interfaces
import {
  FoodItemAvailableRangeInterface,
  FoodPackage,
  Package,
} from "interfaces/mealInterfaces";
import { GetCXUserJson } from "interfaces/constants";
// Libs
import { useHistory } from "react-router-dom";
import HTTP_STATUS from "http-status-codes";
import { useTranslation } from "react-i18next";
// Img
import avatar from "static/img/Avatar.svg";

//Component Interface
export interface FoodCardInterface {
  student: FoodPackage;
  user: GetCXUserJson;
  foodItemsAvailableRanges: Array<FoodItemAvailableRangeInterface>;
  guardianFoodPackage: Array<FoodPackage>;
}

export const StudentFoodCard = ({
  student,
  user,
  foodItemsAvailableRanges,
  guardianFoodPackage,
}: FoodCardInterface) => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.foodPackage.components.StudentFoodCard";

  const history = useHistory();

  const [loadingNewPackage, setLoadingNewPackage] = useState(false);
  const newDate = new Date();
  const currentDay = newDate.getDate();

  // Checa se o aluno é smart
  const checkScholarship = (student: FoodPackage) => {
    return student.scholarship_type === SCHOLARSHIP_TYPE.SMART ||
      student.payment_status_display === "Isento De Pagamento"
      ? true
      : false;
  };

  // Função que alimenta o array de fooItems com os ids dele
  const setFoodItemsArray = (food_items: Array<Package>) => {
    const foodItems: Array<number> = [];
    food_items.forEach((foodItem) => foodItems.push(foodItem.food_item));
    return foodItems;
  };

  const onSubmit = (
    student: FoodPackage,
    goToPayment: boolean,
    reserve?: boolean
  ) => {
    const studentInfo = user.students.find(
      (selectedStudent) =>
        selectedStudent.enrollment?.id === student.enrollment_id
    );
    const foodItems = setFoodItemsArray(student.package);
    if (reserve) {
      setLoadingNewPackage(true);
      const reserveFoodPackage = async () => {
        const response = await addFoodPackage({
          payload: {
            food_items: foodItems,
            enrollment_id: student.enrollment_id,
            saved_as_favorite: false,
            used_available_balance: false,
          },
        });
        if (response && response.status === HTTP_STATUS.CREATED) {
          toastSuccess(t(`${TRANSLATE}.toastSuccess`));
          setLoadingNewPackage(false);
          history.push({
            pathname: "/responsavel/alimentacao/comprar-pacote-alimentacao",
            state: {
              user,
              student: studentInfo,
              foodItemsAvailableRanges,
              guardianFoodPackage,
              step: goToPayment ? true : false,
              smart: checkScholarship(student) ? true : false,
            },
          });
        } else {
          toastError(t(`${TRANSLATE}.toastError`));
          setLoadingNewPackage(false);
        }
      };
      reserveFoodPackage();
    } else {
      history.push({
        pathname: "/responsavel/alimentacao/comprar-pacote-alimentacao",
        state: {
          user,
          student: studentInfo,
          foodItemsAvailableRanges,
          guardianFoodPackage,
          step: goToPayment ? true : false,
          smart: checkScholarship(student) ? true : false,
        },
      });
    }
  };

  return (
    <StudentFoodCardStyled>
      {loadingNewPackage ? (
        <div className="loading">
          <LoadingBox height={50} />
        </div>
      ) : (
        <>
          <div className="studentInfo">
            <div className="picture">
              <img src={avatar} height={49} alt="avatar" />
            </div>
            <div className="info">
              <div className="studentName">
                <Text fontSize="16px" lineHeight="24px" fontWeight="700">
                  {student.student_name}
                </Text>
              </div>
              <div className="subText">
                <Text fontSize="14px" fontWeight="400" lineHeight="21px">
                  {student.grade}
                  {t(`${TRANSLATE}.studentYear`)}
                </Text>
                <Text
                  fontSize="14px"
                  fontWeight="400"
                  lineHeight="21px"
                  className="grade"
                >
                  {t(`${TRANSLATE}.studentSection`)} {student.section}
                </Text>
              </div>
            </div>
          </div>
          {student.package.length === 0 && (
            <div className="noPackage">
              <Text fontWeight="400" fontSize="14px" lineHeight="21px">
                {t(`${TRANSLATE}.noPackage`)}
              </Text>
              {student.end_day >= currentDay && (
                <div className="button">
                  <Button
                    width="104px"
                    height="100%"
                    padding="8px 16px"
                    margin
                    onClick={() => onSubmit(student, false)}
                  >
                    {checkScholarship(student)
                      ? t(`${TRANSLATE}.buttonScholarship`)
                      : t(`${TRANSLATE}.buyButton`)}
                  </Button>
                </div>
              )}
            </div>
          )}
          {student.package.length > 0 && (
            <div className="selectedItems">
              {student.payment_status_display === "Pagamento Confirmado" ||
              student.payment_status_display === "Isento De Pagamento" ? (
                <StepPaymentStatus
                  type="success"
                  scholarship={checkScholarship(student)}
                />
              ) : (
                <StepPaymentStatus
                  type="progress"
                  scholarship={checkScholarship(student)}
                />
              )}
              {student.payment_status_display ===
                "Aguardando Compensação do Pagamento" && (
                <Text
                  fontSize="12px"
                  fontWeight="400"
                  lineHeight="18px"
                  marginBottom="0.5em"
                >
                  {t(`${TRANSLATE}.descriptiveTextToBuy`, {
                    end_day: student.end_day,
                  })}
                </Text>
              )}
              {student.package.map((foodPackage) => {
                return (
                  <div className="packageItems">
                    <Text fontWeight="400" fontSize="14px" lineHeight="21px">
                      {foodPackage.food_item_name}
                    </Text>
                    <Text fontWeight="400" fontSize="14px" lineHeight="21px">
                      {formatMoney(foodPackage.food_item_value)}
                    </Text>
                  </div>
                );
              })}
              <div className="schoolDays">
                <Text fontWeight="400" fontSize="14px" lineHeight="21px">
                  {student.working_days} {t(`${TRANSLATE}.workingDays`)}
                </Text>
              </div>
              <div className="totalValue">
                <Title
                  scaleGray
                  fontSize="18px"
                  fontWeight="700"
                  lineHeight="27px"
                >
                  {t(`${TRANSLATE}.total`)}
                </Title>
                <Title
                  scaleGray
                  fontSize="18px"
                  fontWeight="700"
                  lineHeight="27px"
                >
                  {t(`${TRANSLATE}.value`)}
                  {checkScholarship(student)
                    ? "00.00"
                    : student.food_package_value_month_after_balance_formatted}
                </Title>
              </div>
              {student.date_transaction && (
                <div className="spaceInLine">
                  <Text
                    fontSize="12px"
                    fontWeight="400"
                    lineHeight="18px"
                    className="paymentDate"
                  >
                    {t(`${TRANSLATE}.paymentDate`)}
                  </Text>
                  <Text
                    fontSize="12px"
                    fontWeight="400"
                    lineHeight="18px"
                    className="paymentDate"
                  >
                    {t(`${TRANSLATE}.paymentDay`, {
                      day: standardDate(student.date_transaction),
                      hour: standardHour(student.date_transaction),
                    })}
                  </Text>
                </div>
              )}
              {student.payment_status_display !== "Pagamento Confirmado" &&
                student.end_day >= currentDay && (
                  <div>
                    {!checkScholarship(student) && (
                      <div className="button">
                        {!student.id && (
                          <Button
                            width="85px"
                            height="100%"
                            padding="7px 16px"
                            outline
                            className="editButton"
                            margin
                            onClick={() => onSubmit(student, false)}
                          >
                            {t(`${TRANSLATE}.editButton`)}
                          </Button>
                        )}
                        <Button
                          width="82px"
                          height="100%"
                          padding="8px 16px"
                          margin
                          onClick={() =>
                            onSubmit(student, true, student.id ? false : true)
                          }
                        >
                          {t(`${TRANSLATE}.payButton`)}
                        </Button>
                      </div>
                    )}
                    {checkScholarship(student) && !student.id && (
                      <div className="button">
                        <Button
                          width="110px"
                          height="100%"
                          padding="8px 16px"
                          margin
                          onClick={() => onSubmit(student, false, true)}
                        >
                          {t(`${TRANSLATE}.buttonScholarship`)}
                        </Button>
                      </div>
                    )}
                  </div>
                )}
            </div>
          )}
        </>
      )}
    </StudentFoodCardStyled>
  );
};
