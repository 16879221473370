import styled from "styled-components";
import { device } from "styles/breakpoints";
import * as colors from "styles/colors";

interface WrapperProps {
  maxWidth?: string;
}

interface GradeButtonsProps {
  maxWidth?: string;
  options?: string[];
  quarter?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin: 16px 0.3rem 0 0;
`;

export const Container = styled.div<GradeButtonsProps>`
  width: 100%;

  padding: 4px 8px;

  border: ${(props) => (props.quarter ? "none" : "2px solid #999999")};
  background: transparent;

  display: flex;

  max-height: 38px;
`;

export const Label = styled.div`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  text-transform: uppercase;

  color: #0d0d0d;
`;
