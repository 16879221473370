import * as Interface from "../historySchoolReport.type";
import * as Style from "./style";

import { useTranslation } from "react-i18next";

import { handleChangeNameProp } from "./tableHistorySchollReport.helper";

import { TableRow, TableColumn } from "components/atoms";
import { TableBulletin } from "components/molecules";
import { useBulletin } from "context/Bulletin";

const prefix = "pages.AP.history";

export const TableHistorySchoolReport = ({
  data,
}: Interface.TableHistorySchoolReport) => {
  const { t } = useTranslation();

  const theadItens = data && Object.keys(data.results[0]);
  const tbodyItens = data && data.results;

  const nextPage = data && data.next;
  const prevPage = data && data.previous;

  const { handleDownloadHistorySchoolReport, handleSearchHistorySchoolReport } =
    useBulletin();

  return (
    <Style.Container>
      <TableBulletin
        theadChildren={
          <>
            {theadItens.map(
              (item: string) =>
                handleChangeNameProp({ name: item }) && (
                  <TableRow
                    key={item}
                    item={handleChangeNameProp({ name: item })}
                    visible={{ mobile: true, desktop: true }}
                  />
                )
            )}
          </>
        }
        tbodyChildren={
          <>
            {tbodyItens.map(
              (item) =>
                item && (
                  <tr key={item.name}>
                    <TableColumn
                      item={item.name ? item.name : "-"}
                      visible={{ mobile: true, desktop: true }}
                    />
                    <TableColumn
                      item={item.ra ? item.ra : "-"}
                      visible={{ mobile: true, desktop: true }}
                    />
                    <TableColumn
                      item={
                        item.spreadsheet ? (
                          <Style.ButtonDownload
                            onClick={() =>
                              handleDownloadHistorySchoolReport(
                                item.spreadsheet.substring(4),
                                item.name
                              )
                            }
                          />
                        ) : (
                          "-"
                        )
                      }
                      visible={{ mobile: true, desktop: true }}
                    />
                  </tr>
                )
            )}
          </>
        }
      />
      <Style.Pagination>
        {prevPage && (
          <Style.Button
            onClick={() =>
              handleSearchHistorySchoolReport(prevPage.substring(40))
            }
          >
            {t(`${prefix}.prev`)}
          </Style.Button>
        )}
        {nextPage && (
          <Style.Button
            onClick={() =>
              handleSearchHistorySchoolReport(nextPage.substring(40))
            }
          >
            {t(`${prefix}.next`)}
          </Style.Button>
        )}
      </Style.Pagination>
    </Style.Container>
  );
};
