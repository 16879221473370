import { GradeAPI } from "./api";
import { ErrorHandlerFunction, Headers } from "./service-helpers";
import {
  ClassDiaryInterface,
  SetStudentPresenceInterface,
  StudentsPresencesInterface,
  TeacherInterface,
  TeacherSubjectsGradesInterface,
} from "interfaces/TeacherInterfaces";
import { GradeGroupInterface } from "interfaces/gradeGroupInterfaces";

export const getTeacherSubjects = async (user_id: string) => {
  const response = await GradeAPI.get<Array<TeacherSubjectsGradesInterface>>(
    `classes/teacher_subject/?user_id=${user_id}`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const getTeacherSubjectsByTeacherId = async (teacher_id: string) => {
  const response = await GradeAPI.get<Array<TeacherSubjectsGradesInterface>>(
    `classes/teacher_subject/?teacher_id=${teacher_id}`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const getTeachers = async () => {
  const response = await GradeAPI.get<Array<TeacherInterface>>(
    `classes/teacher/`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const getTeacherStudentsPresences = async ({
  gradegroup,
  subject,
  date,
}: {
  gradegroup: number;
  subject: number;
  date: string;
}) => {
  const response = await GradeAPI.get<StudentsPresencesInterface>(
    `classes/class_diary?gradegroup=${gradegroup}&subject=${subject}&date=${date}`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const setTeacherStudentsPresences = async (payload: {
  user_id: number;
  gradegroup: number;
  subject: number;
  date: string;
  enrollments: Array<SetStudentPresenceInterface>;
  class_order: number;
}) => {
  const response = await GradeAPI.post<StudentsPresencesInterface>(
    `classes/class_diary`,
    payload
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

// TO-DO
// doc this
export const getGradeGroups = async () => {
  const response = await GradeAPI.get<Array<GradeGroupInterface>>(
    `classes/gradegroup/`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const getClassDiaries = async (params: {
  user_id: number;
  gradegroup: number;
  date?: string;
}) => {
  const response = await GradeAPI.get<Array<ClassDiaryInterface>>(
    `classes/list_class_diary`,
    Headers({
      params,
    })
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};
