import * as S from "./style";
import { ITable } from "./interface";

export const Table = ({ children, tableHead, equalSpacing }: ITable) => {
  return (
    <S.TableContainer>
      <S.Thead headEqualSpacing={equalSpacing}>
        <tr>
          {tableHead.map((label, index) => {
            return <th key={index}>{label}</th>;
          })}
        </tr>
      </S.Thead>
      <S.Tbody bodyEqualSpacing={equalSpacing}>{children}</S.Tbody>
    </S.TableContainer>
  );
};
