import styled from "styled-components";
import * as colors from "styles/colors";

export const PixStyled = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 1em;

  div.pixCode {
    display: flex;
    justify-content: center;

    border: 2px solid ${colors.GRAY_SCALE};
    font-size: 12px;
    padding: 8px;
    margin-top: 1em;

    color: ${colors.GRAY_SCALE};

    div.icon {
      width: 5%;
      margin-top: 0.7em;
    }
    cursor: pointer;
  }

  div.pixImg {
    margin-top: 0.5em;
    display: flex;
    justify-content: center;
  }

  div.valueTotal {
    margin-top: 3em;

    display: flex;
    justify-content: space-between;
  }

  div.button {
    margin-top: 5em;
  }
`;
