import styled from "styled-components";
import { Modal } from "@material-ui/core";

export const StyledModal = styled(({ ...otherProps }) => (
  <Modal {...otherProps} />
))`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
`;

export const ModalHeader = styled.div`
  position: fixed;
  width: 100%;
  color: black;
  background-color: #ffffff;
  text-align: center;
  max-height: 100vh;
  overflow-y: scroll;

  svg.close {
    cursor: pointer;
    position: fixed;
    right: 0.65em;
    top: 0.5em;

    @media only screen and (min-width: 415px) {
      right: 15%;
    }
  }
`;
