// React
import { useCallback, useContext, useEffect, useState } from "react";
// Components
import { toastError, toastSuccess, toastWarning } from "components/Toast";
// Interfaces
import { IExitPermission, ITransportation } from "interfaces/exitPermit";
import {
  IHelper,
  ILocation,
  ILocationData,
  ITransportationForm,
} from "./interface";
// Libs
import HTTP_STATUS from "http-status-codes";
import axios from "axios";
import { useTranslation } from "react-i18next";
// Services
import {
  getExitPermit,
  getTransportation,
  postRegisterEscort,
  removeEscort,
  updateEscort,
  updateExitPermit,
} from "services/exitPermit.services";
// Helpers
import { deviceUsed, onlyNumberMask } from "helpers/helpers";

export const HExitPermit = ({ student, form, setModal, setForm }: IHelper) => {
  const { t } = useTranslation();
  const prefix = "pages.EDF.exitPermit.components.exitPermitScreen.helpers";
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [newSearch, setNewSearch] = useState(false);
  const [isSendingData, setIsSendingData] = useState(false);

  // Dados Iniciais
  const [exitPermission, setExitPermission] = useState<IExitPermission>();
  const [transportation, setTransportation] = useState<ITransportation>();
  const [studentExitPermit, setStudentExitPermit] =
    useState<ITransportationForm | null>(
      exitPermission ? exitPermission.transportation : null
    );

  // Dados de localização/dispositivo
  const [userLocation, setUserLocation] = useState<ILocationData>({
    location: null,
    dispositivo: deviceUsed(navigator.userAgent)[0],
  });
  // Nova procura pela localização
  const [locationSearch, setLocationSearch] = useState(true);

  const setFormProps = useCallback(() => {
    setForm({
      ...form,
      name: "",
      kinship: "",
      cpf: "",
      phone: "",
      escort: 0,
    });
  }, [form, setForm]);

  const location = useCallback(() => {
    (async () => {
      const deviceData = await axios.get<ILocation>(
        "https://geolocation-db.com/json/"
      );
      if (deviceData?.status === HTTP_STATUS.OK) {
        setUserLocation({ ...userLocation, location: deviceData.data });
      }
    })();
  }, [userLocation]);

  useEffect(() => {
    if (locationSearch) {
      location();
      setLocationSearch(false);
    } else {
      setTimeout(
        () => !userLocation.location && setLocationSearch(true),
        10000
      );
    }
  }, [location, locationSearch, userLocation.location]);

  const initialProps = useCallback(async () => {
    const exitPermit = await getExitPermit({
      student_id: student.id,
    });
    if (exitPermit?.status === HTTP_STATUS.OK) {
      setExitPermission(exitPermit.data);
    }

    const transportation = await getTransportation();
    if (transportation?.status === HTTP_STATUS.OK) {
      setTransportation(transportation.data);
      setLoading(false);
      setNewSearch(false);
    }

    location();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [student]);

  useEffect(() => {
    (async () => {
      try {
        initialProps();
      } catch (error) {
        setLoading((loading) => !loading);
        setError((error) => !error);
      }
    })();
  }, [initialProps]);

  const handleRegisterEscort = async () => {
    setIsSendingData(true);

    if (userLocation.location) {
      const response = await postRegisterEscort({
        payload: {
          nome_completo: form.name,
          grau_parentesco: form.kinship,
          cpf: onlyNumberMask(form.cpf),
          telefone: onlyNumberMask(form.phone),
          estudante: student.id,
          localizacao: {
            ipv4: userLocation.location.IPv4,
            cidade: userLocation.location.city,
            estado: userLocation.location.state,
            pais: userLocation.location.country_name,
            cep: userLocation.location.postal,
            latitude: userLocation.location.latitude,
            longitude: userLocation.location.longitude,
            dispositivo: userLocation.dispositivo,
          },
        },
      });

      if (response?.status === HTTP_STATUS.CREATED) {
        toastSuccess(t(`${prefix}.createdEscort`));
        setNewSearch((newSearch) => !newSearch);
        setFormProps();
        initialProps();
        setModal(false);
      } else {
        toastError(t(`${prefix}.errorRegisterEscort`));
      }

      setIsSendingData(false);
    }
  };

  const handleEditEscort = async () => {
    if (userLocation.location) {
      const response = await updateEscort({
        payload: {
          nome_completo: form.name,
          grau_parentesco: form.kinship,
          cpf: onlyNumberMask(form.cpf),
          telefone: onlyNumberMask(form.phone),
          localizacao: {
            ipv4: userLocation.location.IPv4,
            cidade: userLocation.location.city,
            estado: userLocation.location.state,
            pais: userLocation.location.country_name,
            cep: userLocation.location.postal,
            latitude: userLocation.location.latitude,
            longitude: userLocation.location.longitude,
            dispositivo: userLocation.dispositivo,
          },
        },
        escort_id: form.escort,
      });
      if (response?.status === HTTP_STATUS.OK) {
        toastSuccess(t(`${prefix}.editSuccess`));
        setNewSearch((newSearch) => !newSearch);
        setFormProps();
        initialProps();
        setModal(false);
      } else {
        toastError(t(`${prefix}.editError`));
      }
    }
  };

  const handleDeletEscort = async (escort_id: number) => {
    const response = await removeEscort({
      escort_id,
    });
    if (response?.status === HTTP_STATUS.OK) {
      toastSuccess(t(`${prefix}.deletEscort`));
      setNewSearch((newSearch) => !newSearch);
      setFormProps();
      initialProps();
    } else {
      toastError(t(`${prefix}.deletEscortError`));
    }
  };

  const handleSaveChanges = async () => {
    if (studentExitPermit) {
      const response = await updateExitPermit({
        student_id: student.id,
        payload: {
          transportation: studentExitPermit.value,
        },
      });
      if (response?.status === HTTP_STATUS.OK) {
        toastSuccess(t(`${prefix}.saveChangesSuccess`));
        setLoading((loading) => !loading);
        initialProps();
      } else {
        toastError(t(`${prefix}.saveChangesError`));
      }
    } else {
      toastWarning(t(`${prefix}.exitOptionWarning`));
    }
  };

  return {
    newSearch,
    error,
    loading,
    exitPermission,
    transportation,
    setStudentExitPermit,
    handleRegisterEscort,
    handleEditEscort,
    handleDeletEscort,
    handleSaveChanges,
    setFormProps,
    isSendingData,
  };
};
