/* eslint-disable no-sequences */
import { LinearProgress } from "@material-ui/core";
import { gradesValidation, IsDevelopmentEnvironment } from "helpers/helpers";
import HTTP_STATUS from "http-status-codes";
import { GetCXUserJson } from "interfaces/constants";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getCXUser } from "services/users.service";
import { validateCPF } from "validations-br";
import * as S from "./styles";
import { FeatureEnabler } from "components";
import { Storage } from "Storage";
import { useAtomValue, useSetAtom } from "jotai";
import { authUserAtom, userInfoAtom } from "jotai/authUser";
import { useBulletin } from "context/Bulletin";
import { EnrollmentTypes } from "enums/enrollment";
import { useAllowedUnits } from "hooks/useAllowedUnits";
import { useTranslation } from "react-i18next";
import { ButtonCard } from "components/atoms/ButtonCard";
import { Modal } from "components/molecules/Modal";
import { RedirectWithContract } from "components/Redirect";

export const GuardianHome = () => {
  const { t } = useTranslation();
  const TRANSLATION_PREFIX = "pages.CORE.home.guardian";

  const [user, setUser] = useState<GetCXUserJson | null>(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [lastModifiedDate, setLastModifiedDate] = useState<string>();
  const [currentDate, setCurrentDate] = useState<string>();
  const [reenrollment, setReenrollment] = useState(false);
  const [totvsSent, setTotvsSent] = useState(false);

  const [studentsInFlow, setStudentsInFlow] = useState(false);

  useEffect(() => {
    if (user) {
      const searchContract = () => {
        const modal = user.students.find(
          (student) => student.last_enrollment.contract_status !== 3
        );

        if (!modal) {
          return setStudentsInFlow(true);
        } else {
          return setStudentsInFlow(false);
        }
      };
      searchContract();
    }
  }, [user, user?.students]);

  const setModal = () => {};

  const setUserInfo = useSetAtom(userInfoAtom);
  const authUser = useAtomValue(authUserAtom);
  const { verifyUserPermissionToAccessFeature } = useAllowedUnits(
    "roteiros-de-estudo-unidades"
  );

  const [isUserAllowedToAccessFeature, setIsUserAllowedToAccessFeature] =
    useState<boolean>(false);
  const role = Storage.props.role.get();

  const filteredStudents =
    user && gradesValidation(user.students, "Fundamental 1", 3);

  const { handleDataBulletin, quarters, handleStudentId, setQuarter, quarter } =
    useBulletin();

  const shouldRenderStudentItineraryFeatureForGuardian = async () => {
    const parentUnitId = user?.students[0]?.enrollment?.student_unit ?? 0;

    const isUserAllowed = await verifyUserPermissionToAccessFeature(
      parentUnitId
    );

    if (isUserAllowed) {
      return setIsUserAllowedToAccessFeature(
        isUserAllowed && !!(filteredStudents && filteredStudents.length > 0)
      );
    }
    return setIsUserAllowedToAccessFeature(isUserAllowed);
  };

  useEffect(() => {
    shouldRenderStudentItineraryFeatureForGuardian();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getUserData = useCallback(async () => {
    try {
      const response = await getCXUser({
        id: authUser.id,
      });
      if (response?.status === HTTP_STATUS.OK && response?.data) {
        setUser(response.data);
        setUserInfo(response.data);
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [authUser, setUserInfo]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  useEffect(() => {
    if (user) {
      const SOME_FIELD_IS_INVALID =
        !user.personal.birth_date ||
        user.personal.birth_date.length !== 10 ||
        !user.location.address ||
        !user.location.neighborhood ||
        !user.location.uf ||
        !user.location.number ||
        !user.location.zip_code ||
        user.location.zip_code.length !== 8 ||
        !user.personal.cpf ||
        user.personal.cpf.length !== 11 ||
        !validateCPF(user.personal.cpf);

      if (SOME_FIELD_IS_INVALID) {
        history.push(
          `/responsavel/editar?guardianAuthId=${user.user_id}&guardianId=${user.id}&dataConfirm=true`
        );
      }

      if (
        user.students.find(
          (student) =>
            student.last_enrollment.type === EnrollmentTypes.Reenrollment
        )
      ) {
        setReenrollment(true);
      }

      if (
        user.students.find((student) => student.last_enrollment?.totvs_sent)
      ) {
        setTotvsSent(true);
      }

      if (user.last_modified) {
        setLastModifiedDate(user.last_modified.substring(0, 10));
      }

      const newDate = new Date();
      setCurrentDate(
        `${newDate.getFullYear()}-${
          newDate.getMonth() - 1
        }-${newDate.getDate()}`
      );
    }
  }, [user, history]);

  useEffect(() => {
    const isRedirectToIndication = sessionStorage.getItem(
      "redirectToIndication"
    );

    if (isRedirectToIndication) {
      history.push("/indicacao");
      sessionStorage.removeItem("redirectToIndication");
    }
  }, [history]);

  const handleKeyPress = (
    event:
      | React.KeyboardEvent<HTMLButtonElement>
      | React.KeyboardEvent<HTMLDivElement>,
    pathName: string
  ) => {
    const key = event.key;
    if (key === "Enter") {
      history.push({
        pathname: `${pathName}`,
        state: {
          user,
        },
      });
    }
  };

  const handleBulletin = async (id: number) => {
    const res = await handleStudentId(id);

    if (quarters) {
      handleDataBulletin({
        id: res.student.id,
        quarter: quarters[0].id,
      }),
        setQuarter({
          values: [...quarter.values],
          selected: quarters[0]?.name,
        }),
        history.push({
          pathname: "/responsavel/boletim",
          state: {
            student: user?.students[0],
          },
        });
    }
  };

  return (
    <div className="container">
      {loading && <LinearProgress className="mt-3 mb-3" color="secondary" />}
      {error && <div>Erro ao carregar responsável.</div>}
      {user && (
        <>
          <S.HomeContainer>
            {/* <ButtonCard
              onClick={() =>
                history.push({
                  pathname: `/boletos-liste-estudantes`,
                  state: {
                    user,
                  },
                })
              }
              text={t(`${TRANSLATION_PREFIX}.reissueBill`)}
            /> */}

            <ButtonCard
              onClick={() =>
                history.push({
                  pathname: `/responsavel/meus-alunos`,
                  state: {
                    user,
                  },
                })
              }
              text={t(`${TRANSLATION_PREFIX}.students`)}
            />

            {/* {user.students.length > 1 ? (
              <ButtonCard
                text={t(`${TRANSLATION_PREFIX}.boletim`)}
                onClick={() =>
                  history.push({
                    pathname: `/selecao_estudante`,
                    state: {
                      student: user,
                      role,
                    },
                  })
                }
              />
            ) : (
              <ButtonCard
                text={t(`${TRANSLATION_PREFIX}.boletim`)}
                onClick={() => handleBulletin(user.students[0].id)}
              />
            )} */}

            {/* <ButtonCard
              text={t(`${TRANSLATION_PREFIX}.myData`)}
              onClick={() => history.push("/meu-perfil")}
              onKeyDown={(event) => handleKeyPress(event, "/meu-perfil")}
            />

            <ButtonCard
              text={t(`${TRANSLATION_PREFIX}.food`)}
              onKeyDown={(event) => handleKeyPress(event, "/alimentacao")}
              onClick={() =>
                history.push({
                  pathname: "/alimentacao",
                  state: {
                    user,
                  },
                })
              }
            /> */}

            {/* {IsDevelopmentEnvironment() && (
              <ButtonCard
                text={t(`${TRANSLATION_PREFIX}.myStudents`)}
                onKeyDown={(event) =>
                  handleKeyPress(event, "/responsavel/alunos")
                }
                onClick={() =>
                  history.push({
                    pathname: "/responsavel/alunos",
                    state: {
                      user,
                    },
                  })
                }
              />
            )} */}
            {/* <FeatureEnabler
              enablerCallback={() => isUserAllowedToAccessFeature}
            >
              <ButtonCard
                text={t(`${TRANSLATION_PREFIX}.studyItinerary`)}
                onKeyDown={(event) =>
                  handleKeyPress(event, "/selecao-de-estudante")
                }
                onClick={() =>
                  history.push({
                    pathname:
                      filteredStudents?.length === 1
                        ? "/roteiros-de-estudos"
                        : "/selecao-de-estudante",
                    state: {
                      ...(filteredStudents?.length === 1
                        ? {
                            selectedStudent: filteredStudents[0],
                            role: user.role[0].label,
                          }
                        : { students: filteredStudents }),
                      title: "Roteiros de estudos",
                      ...(filteredStudents?.length !== 1 && {
                        redirect: "/roteiros-de-estudos",
                      }),
                    },
                  })
                }
              />
            </FeatureEnabler> */}
            {/* <ButtonCard
              text={t(`${TRANSLATION_PREFIX}.studentsExit`)}
              onKeyDown={(event) =>
                handleKeyPress(event, "/saida-do-estudante")
              }
              onClick={() =>
                history.push({
                  pathname: "/saida-do-estudante",
                  state: {
                    user,
                  },
                })
              }
            /> */}
          </S.HomeContainer>
          {/* <S.BannerContainer
            onKeyDown={(event) => handleKeyPress(event, `/indicacao`)}
            onClick={() =>
              history.push({
                pathname: "/indicacao",
                state: {
                  user,
                },
              })
            }
          /> */}
        </>
      )}

      <Modal
        radious="2.5em"
        size="600px"
        isOpen={studentsInFlow}
        toggle={setModal}
        path="test"
      >
        <RedirectWithContract />
      </Modal>
    </div>
  );
};
