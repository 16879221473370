import { SectionProps } from "./types";
import styled from "styled-components";
import { handleJustifyContent } from "helpers/style";
import { device } from "styles/breakpoints";

export const Container = styled.div<SectionProps>`
  width: 100%;
  height: auto;
  max-width: ${(props) => props.maxWidth && props.maxWidth};

  display: ${(props) => (props.display === "true" ? "flex" : "block")};

  @media ${device.xss} {
    padding: ${(props) => (props.padding === true ? "0.5rem" : "0rem")};
    flex-direction: ${(props) =>
      props.direction.mobile === "row"
        ? props.direction.mobile
        : props.direction.mobile === "column" && props.direction.mobile};
    align-items: ${(props) =>
      props.align.mobile === "center"
        ? "center"
        : props.align.mobile === "left"
        ? "flex-start"
        : props.align.mobile === "right" && "flex-end"};
    justify-content: ${(props) =>
      props.justify.mobile
        ? handleJustifyContent({ value: props.justify.mobile })
        : "flex-start"};
  }

  @media ${device.md} {
    padding: ${(props) => (props.padding === true ? "0.5rem" : "0rem")};
    flex-direction: ${(props) =>
      props.direction.desktop === "row"
        ? props.direction.desktop
        : props.direction.desktop === "column" && props.direction.desktop};
    align-items: ${(props) =>
      props.align.desktop === "center"
        ? "center"
        : props.align.desktop === "left"
        ? "flex-start"
        : props.align.desktop === "right" && "flex-end"};
    justify-content: ${(props) =>
      props.justify.desktop
        ? handleJustifyContent({ value: props.justify.desktop })
        : "flex-start"};
  }
`;
