import { Button } from "components";
import { ModalDiv } from "components/UseTerms/styles";

interface ModalInterface {
  setToggleModal: (toggleModal: boolean) => void;
}

export const ModalStatusPayment = ({ setToggleModal }: ModalInterface) => {
  return (
    <div className="container">
      <ModalDiv>
        <div className="text-center">
          <strong className="font-weight-bold">Aguardando pagamento</strong>
          <p>
            O tempo de confirmação do pagamento pode variar dependendo da forma
            de pagamento escolhida. Veja abaixo os prazos estimados:
          </p>
        </div>
        <div>
          <li>Pix - Até 1 dia útil</li>
          <li>Cartão de crédito - Até 1 dia útil</li>
          <li>Boleto - Até 3 dias úteis</li>
        </div>
        <Button
          onClick={() => {
            setToggleModal(false);
          }}
          className="blue outline"
        >
          Fechar
        </Button>
      </ModalDiv>
    </div>
  );
};
