import styled from "styled-components";

export const ConfirmPayment = styled.div`
  button {
    color: ${({ theme }) => theme.colors.brandingBlack};
    font-size: ${({ theme }) => theme.fontSizes.web.default};
    font-weight: ${({ theme }) => theme.fontWeights.bold};

    border: none;
    border-radius: ${({ theme }) => theme.radii.sm};
    background-color: ${({ theme }) => theme.colors.primaryLight90};
    padding: ${({ theme }) => theme.space[2]};

    transition: 0.2s all ease;

    &:hover {
      background-color: ${({ theme }) => theme.colors.primaryDefault};
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;
