import { device } from "styles/breakpoints";
import { pxToRem } from "helpers/style";
import styled, { css } from "styled-components";
import bannerDesktop from "static/img/banner-indicacao-desktop.png";
import bannerMobile from "static/img/banner-indicacao-mobile.png";

export const HomeContainer = styled.div`
  width: 100%;
  min-height: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 24px;

  &:last-child {
    justify-content: flex-start;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media ${device.xss} {
    width: calc((100% / 2) - 1rem);
    height: ${pxToRem(100)};

    background: #f0e9fb;
    border-radius: 20px;

    margin: 1rem 0.5rem;
  }

  @media ${device.md} {
    width: calc((100% / 4) - 2rem);
    height: ${pxToRem(100)};

    background: #f0e9fb;
    border-radius: ${pxToRem(10)};

    margin: 1rem 1rem;
  }
`;

interface TextCardStyleProps {
  type: string;
}

export const TextCard = styled.div<TextCardStyleProps>`
  width: 100%;
  font-weight: 700;
  font-size: ${pxToRem(14)};
  text-transform: uppercase;

  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 1rem;

  transition: 0.3s;

  ${(props) =>
    props.type === "text"
      ? css`
          color: #0d0d0d;

          justify-content: flex-start;
        `
      : props.type === "link" &&
        css`
          color: #6d28d9;

          justify-content: flex-end;

          cursor: pointer;

          &:hover {
            color: #9d62e3;
            text-decoration: underline;
          }
        `}
`;

export const BannerContainer = styled.div`
  height: 100px;
  max-width: auto;
  margin-top: 2.5rem;
  background-image: url(${bannerDesktop});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  cursor: pointer;

  @media only screen and (max-width: 500px) {
    height: 200px;
    margin-bottom: 1.5rem;
    background-image: url(${bannerMobile});
  }
`;
