import styled from "styled-components";

export const EnrollmentPaymentStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 100%;

  div.header {
    width: 20.5em;
  }

  div.container {
    display: flex;
    flex-direction: column;
    padding: 1.5em 1em 1em;

    width: 20.5em;

    background: rgba(109, 40, 217, 0.05);
    border-radius: 29px;

    div.sameLine {
      display: flex;
      width: 100%;

      &.totalValue {
        margin-top: 1.5em;
      }

      div.textPrimary {
        width: 50%;
      }

      div.textSecondary {
        width: 50%;
        display: flex;
        justify-content: end;
      }
    }
  }

  div.paymentMethods {
    display: flex;
    flex-direction: column;
    padding: 1.5em 1em 1em;

    width: 22em;
    margin-top: 0.5em;

    div.radioButtons {
      display: flex;
      align-items: center;

      div.radioText {
        margin-top: 0.6em;
        margin-left: 0.5em;
      }
    }
  }

  div.buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 1em 1em;

    width: 22.5em;
  }
`;
