import { range } from "helpers/helpers";
import { DOMAttributes } from "react";
import { StepsDiv } from "./style";

interface StepsInterface extends DOMAttributes<HTMLDivElement> {
  step: number;
  length: number;
}

const Steps = ({ step, length, children }: StepsInterface) => {
  return (
    <StepsDiv className="mb-4">
      <div className="step-text ml-3">
        Passo {step} de {length}
      </div>
      <div className="progress-segment">
        {[...range(1, length)].map((i) => {
          return (
            <div className={`item ${step <= i ? "" : "active"}`} key={i} />
          );
        })}
      </div>
      {children}
    </StepsDiv>
  );
};

export default Steps;
