import styled from "styled-components";
import * as colors from "styles/colors";

export const ContainerWaitingList = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 1rem;
`;
export const Title = styled.h1`
  color: ${colors.NEW_PURPLE};
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
`;
export const Subtitle = styled.h2`
  color: ${colors.NEW_ORANGE};
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
`;
