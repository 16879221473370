import { DocumentStatusOptions } from "interfaces/documents";
import * as S from "./styles";

enum DocumentStatusEnum {
  analise = "Em análise",
  aprovado = "Aprovado",
  reprovado = "Reprovado",
  pendente = "Pendente",
}

interface IDocumentStatusProps {
  variant: DocumentStatusOptions;
}
export const DocumentStatus = ({ variant }: IDocumentStatusProps) => {
  return (
    <S.Container variant={variant}>{DocumentStatusEnum[variant]}</S.Container>
  );
};
