import Skeleton from "components/Skeleton";
import * as S from "./styles";

export const ItinerarieCardLoading = () => {
  const colorSkeleton = "#D1B9F8";

  return (
    <>
      <S.SkeletonCard>
        <div>
          <Skeleton
            width={43}
            height={43}
            borderRadius={999}
            colorConfig={{ color: colorSkeleton }}
          />
        </div>
        <div className="skeleton-card-content">
          <div className="skeleton-card-title">
            <Skeleton
              width={100}
              height={16}
              colorConfig={{ color: colorSkeleton }}
            />
            <Skeleton
              width={77}
              height={23}
              borderRadius={4}
              colorConfig={{ color: colorSkeleton }}
            />
          </div>
          <Skeleton colorConfig={{ color: colorSkeleton }} />
          <Skeleton colorConfig={{ color: colorSkeleton }} />
          <div className="skeleton-card-score">
            <Skeleton width={22} colorConfig={{ color: colorSkeleton }} />
            <span className="bar">/</span>
            <Skeleton width={22} colorConfig={{ color: colorSkeleton }} />
          </div>
        </div>
      </S.SkeletonCard>
    </>
  );
};
