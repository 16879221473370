export const size = {
  xss: "0px",
  xs: "360px",
  sm: "600px",
  md: "900px",
  lg: "1200px",
  xl: "1536px",
};

export const device = {
  xss: `(min-width: ${size.xss})`,
  xs: `(min-width: ${size.xs})`,
  sm: `(min-width: ${size.sm})`,
  md: `(min-width: ${size.md})`,
  lg: `(min-width: ${size.lg})`,
  xl: `(min-width: ${size.xl})`,
};
