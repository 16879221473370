import { useContext, useEffect, useState } from "react";
import HTTP_STATUS from "http-status-codes";
import { deepCopy, formatCPF } from "helpers/helpers";
import { getStudentData, updateUser } from "services/users.service";
import { StudentDocumentationStyled, LinkStyled } from "./style";
import { LinearProgress } from "@material-ui/core";
import { ReducedHorizontalStepper } from "components/Timeline";
import { Field, Form } from "react-final-form";
import { Button, Modal, RadioButton, TextInput } from "components";
import {
  composeValidators,
  CPFvalidation,
  required,
} from "helpers/fieldValidators";
import formatString from "format-string-by-pattern";
import { useHistory, useLocation } from "react-router-dom";
import { formatUpdateStudentPayload } from "pages/EDF/EditStudent/components/StudentForm/helpers";
import { toastError, toastSuccess } from "components/Toast";
import ModalCPF from "./components/ModalCPF";
import ModalAddress from "./components/ModalAddress";
import {
  StudentInterface,
  GetCXUserJson,
  StudentDataInterface,
} from "interfaces/constants";

type StateType = {
  student: StudentInterface;
  guardian: GetCXUserJson;
  newGuardian?: number;
};

export const StudentDocumentation = () => {
  const location = useLocation<StateType>();
  const [student] = useState(location.state.student);
  const [guardian] = useState(location.state.guardian);
  const [studentInfos, setStudentInfos] = useState<StudentDataInterface>();
  const [newAddress] = useState(true);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [timelineStep] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [openModalAddress, setOpenModalAddress] = useState(false);
  const [selectedGuardian, setSelectedGuardian] = useState<number>();

  const history = useHistory();
  useEffect(() => {
    if (student && guardian) {
      const getTheDocuments = async () => {
        const getStudentLocation = await getStudentData({
          id: student.id,
        });

        if (getStudentLocation) {
          setStudentInfos(getStudentLocation.data);
          setLoading(false);
        }
      };
      getTheDocuments();
    } else {
      setError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (studentInfos) {
      studentInfos.guardians.find(
        (g) =>
          g.id === student.same_address_as_guardian && setSelectedGuardian(g.id)
      );
    }
  }, [student, studentInfos]);

  const onSubmit = async (values: any) => {
    const values_ = deepCopy(values);
    const updateStudent = await updateUser({
      id: student?.id,
      role: "student",
      body: formatUpdateStudentPayload(values_),
    });
    if (updateStudent) {
      if (updateStudent.status === HTTP_STATUS.OK) {
        toastSuccess("Dados atualizados com sucesso");
        history.push({
          pathname: `/contrato/confirmacao-dados`,
          state: { student, guardian },
        });
      } else {
        toastError("Erro ao atualizar seus dados. Tente novamente mais tarde.");
      }
    }
  };

  return (
    <StudentDocumentationStyled className="container">
      {loading && <LinearProgress className="mt-3 mb-3" color="secondary" />}
      {error && <div>Erro ao carregar estudante.</div>}
      {student && studentInfos && (
        <Form
          initialValues={{
            cpf: student.cpf && formatCPF(student.cpf),
            same_address_as_guardian: location.state.newGuardian
              ? location.state.newGuardian.toString()
              : student.same_address_as_guardian
              ? student.same_address_as_guardian?.toString()
              : selectedGuardian,
          }}
          onSubmit={onSubmit}
        >
          {({ form, handleSubmit, submitting, values }) => (
            <form onSubmit={handleSubmit}>
              <div className="enrollment-title text-center">
                Matrícula de {student.name}
                <div className="subtitle">
                  {student.last_enrollment?.grade || "Sem ano"}
                  {student.last_enrollment?.grade && "º ano do "}
                  {student.last_enrollment?.educational_level_name} - Unidade{" "}
                  {student.last_enrollment?.unit_name}
                </div>
              </div>
              <div className="row">
                <div className="offset-sm-4 col-sm-4 col-12">
                  <ReducedHorizontalStepper step={timelineStep} />
                </div>
              </div>
              <p>
                Precisamos de alguns dados de {student.name} para prosseguirmos:
              </p>
              <Field
                component={TextInput}
                name="cpf"
                type="text"
                placeholder=" "
                label="CPF"
                parse={formatString("999.999.999-99")}
                validate={composeValidators(required, CPFvalidation)}
                required
              />
              <p className="mt-3">
                <LinkStyled
                  onClick={() => {
                    setOpenModal(true);
                  }}
                >
                  Não tenho o CPF do aluno
                </LinkStyled>
              </p>
              <p className="mt-3">
                Selecione com qual responsável o aluno mora:
              </p>
              {studentInfos.guardians.map((guardian: any) => {
                return (
                  <div className="row mb-3">
                    <div className="col-sm-1 col-2">
                      <Field
                        component={RadioButton}
                        name="same_address_as_guardian"
                        type="radio"
                        value={guardian.id.toString()}
                        checked={
                          values.same_address_as_guardian ===
                          guardian.id.toString()
                        }
                        defaultValue={
                          values.same_address_as_guardian ===
                          location.state.newGuardian?.toString()
                        }
                        required
                      />
                    </div>
                    <div className="col-sm-11 col-10 pl-0 my-auto">
                      <div className="font-weight-bold">{guardian.name}</div>
                      <div>
                        {guardian.guard_info.guard_id ===
                        studentInfos.last_enrollment?.guard_id
                          ? "Financeiro"
                          : "Didático"}
                      </div>
                      <div>
                        {guardian.address}, nº {guardian.number} -{" "}
                        {guardian.city}, {guardian.uf}
                      </div>
                    </div>
                  </div>
                );
              })}
              <div className="row">
                <div className="col-sm-6 col-12">
                  <Button
                    onClick={() =>
                      history.push({
                        pathname: `/responsavel/cadastro`,
                        state: { student, guardian, newAddress },
                      })
                    }
                    className="blue outline"
                    type="button"
                  >
                    Cadastrar novo responsável, com quem o aluno mora
                  </Button>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-3 offset-sm-9 col-12">
                  <Button type="submit">Avançar</Button>
                </div>
              </div>
              <Modal setToggleModal={setOpenModal} isOpen={openModal}>
                <ModalCPF setToggleModal={setOpenModal} />
              </Modal>
              <Modal
                setToggleModal={setOpenModalAddress}
                isOpen={openModalAddress}
              >
                <ModalAddress setToggleModal={setOpenModalAddress} />
              </Modal>
            </form>
          )}
        </Form>
      )}
    </StudentDocumentationStyled>
  );
};
