import styled, { css } from "styled-components";
import { TH4 } from "./interface";

export const ManageFoodItemsContainer = styled.div`
  padding: 5px 15px; //TODO: nao tem na lib

  h2 {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: ${({ theme }) => theme.fontSizes.web.h2};
    line-height: 48px; // TODO: nao tem na lib
    color: ${({ theme }) => theme.colors.primaryDefault};
  }

  form.filters {
    div {
      margin-top: ${({ theme }) => theme.space[4]};
      display: flex;
      gap: 0 ${({ theme }) => theme.space[4]};
    }

    div:first-child {
      flex-wrap: wrap;
    }
  }

  .dynamics {
    margin-top: ${({ theme }) => theme.space[6]};

    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 30px; // TODO: nao tem na lib
  }

  .dynamicsList {
    margin-top: ${({ theme }) => theme.space[4]};
    display: flex;
    flex-wrap: wrap;

    gap: ${({ theme }) => theme.space[4]};
  }

  .loading {
    display: flex;
    justify-content: center;

    margin-top: ${({ theme }) => theme.space[4]};
  }
`;

export const H4 = styled.h4<TH4>`
  font-weight: 600; // TODO: nao tem na lib
  font-size: ${({ theme }) => theme.fontSizes.web.h4};
  line-height: 30px; // TODO: nao tem na lib

  ${({ textStyle }) =>
    textStyle === "notFound" &&
    css`
      color: #999999;
      text-align: center;

      width: 100%;
      margin-top: 68px; // TODO: nao tem na lib
    `}

  @media (max-width: 360px) {
    font-size: ${({ theme }) => theme.fontSizes.mobile.sm};
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 228px;

  label {
    float: left;
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    font-size: ${({ theme }) => theme.fontSizes.web.sm};
    line-height: ${({ theme }) => theme.lineHeights.normal};
    color: ${({ theme }) => theme.colors.greyScale95};

    margin: 0;
  }

  select {
    box-sizing: border-box;
    background: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.greyScale40};

    color: ${({ theme }) => theme.colors.greyScale95};
    height: 37.7px; //TODO: nao tem na lib
    padding-left: ${({ theme }) => theme.space[2]};
    font-size: ${({ theme }) => theme.fontSizes.web.sm};

    cursor: pointer;
  }

  @media (max-width: 360px) {
    width: 100%;
  }
`;

export const ErrorContainer = styled.div`
  padding: 5px 15px; //TODO: nao tem na lib
`;
