import { useHistory } from "react-router-dom";
import { StudyRoomContainer } from "./style";
import { ButtonCard } from "components/atoms/ButtonCard";
import { useTranslation } from "react-i18next";
import { IsDevelopmentEnvironment } from "helpers/helpers";

const TRANSLATE_PREFIX = "pages.CORE.home.studyRoomEducator";

export const StudyRoomEducatorHome = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <StudyRoomContainer>
      {/* <ButtonCard
        onClick={() => history.push("/dashboard")}
        text={t(`${TRANSLATE_PREFIX}.dashboard`)}
      />
      <ButtonCard
        onClick={() => history.push("/registro-de-alimentacao")}
        text={t(`${TRANSLATE_PREFIX}.singleFoodRegistration`)}
      />
      <ButtonCard
        onClick={() => history.push("/relatorio-de-alimentacao")}
        text={t(`${TRANSLATE_PREFIX}.feedReport`)}
      />
      <ButtonCard
        onClick={() => history.push("/diario-de-classe")}
        text={t(`${TRANSLATE_PREFIX}.classDiary`)}
      />
      <ButtonCard
        onClick={() => history.push("/alimentacao/lancar-credito-alimentacao")}
        text={t(`${TRANSLATE_PREFIX}.launchFoodCredit`)}
      />
      <ButtonCard
        onClick={() => history.push("/alimentacao/consultar-alimentacao")}
        text={t(`${TRANSLATE_PREFIX}.consultSinglePurchase`)}
      /> */}
      <ButtonCard
        onClick={() => history.push("/gerar-arquivo-para-cracha")}
        text={t(`${TRANSLATE_PREFIX}.generateBadgeFile`)}
      />
      {/* <ButtonCard
        onClick={() => history.push("/consultar-pedidos-alimentacao")}
        text={t(`${TRANSLATE_PREFIX}.consultFoods`)}
      />
      <ButtonCard
        onClick={() => history.push("/responsavel/busca")}
        text={t(`${TRANSLATE_PREFIX}.searchGuardianOrStudent`)}
      /> */}
    </StudyRoomContainer>
  );
};
