/* eslint-disable prefer-rest-params */
import { IsDevelopmentEnvironment } from "helpers/helpers";
import { Routes } from "router";
import { GlobalContext } from "context";
import { hotjar } from "react-hotjar";
import { useEffect } from "react";
import { caching } from "helpers/clearCache";
import { clearCache } from "ClearCache";

const hotjarID: number = process.env.REACT_APP_HOTJAR_ID as unknown as number;
const hotjarVersion: number = process.env
  .REACT_APP_HOTJAR_VERSION as unknown as number;

export const App: React.FC = () => {
  useEffect(() => hotjar.initialize(hotjarID, hotjarVersion), []);

  if (IsDevelopmentEnvironment()) {
    caching();
  }

  return (
    <GlobalContext>
      <Routes />
    </GlobalContext>
  );
};

const ClearCacheComponent = clearCache(App);

export const MainApp = () => {
  return <ClearCacheComponent />;
};
