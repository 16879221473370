import { useHistory } from "react-router-dom";
import { SectionTitle } from "components/atoms";
import { SectionContent, SectionHeader } from "components/molecules";
import { SectionProps } from "./interface";

import * as S from "./styles";

export const Section = ({ step, user, enrollment }: SectionProps) => {
  const history = useHistory();

  const takeStep = (step: number) => {
    switch (step) {
      case 1:
        return {
          pathname: "/matricula/confirmar-dados-responsavel",
          state: {
            user,
            enrollment,
          },
        };
      case 2:
        return {
          pathname: "/matricula/confirmar-dados-do-estudante",
          state: {
            user,
            enrollment,
          },
        };
      case 3:
        return "confirmacao-three";
      case 4:
        return "confirmacao-four";
      case 5:
        return "confirmacao-five";
      case 6:
        return "confirmacao-six";
      default:
        return "confirmacao-seven";
    }
  };

  const handleRedirection = (id: number) => {
    history.push(takeStep(id));
  };

  return (
    <S.Container
      sectionState={step.status}
      onClick={() => handleRedirection(step.step)}
      disabled={step.status === "Não Iniciado"}
    >
      <SectionHeader sectionState={step.status} label={step.step} />

      <SectionTitle title={step.name} sectionState={step.status} />

      {step.extra && <SectionContent topics={step.extra} />}
    </S.Container>
  );
};
