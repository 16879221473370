import styled from "styled-components";

export const Layout = styled.div`
  padding: 48px 88px; // TODO: add 48px 88px to theme

  @media only screen and (max-width: 768px) {
    padding: ${({ theme }) => theme.space[8]} ${({ theme }) => theme.space[16]};
  }

  @media only screen and (max-width: 375px) {
    padding: ${({ theme }) => theme.space[4]} ${({ theme }) => theme.space[8]};
  }
`;

export const Header = styled.div``;

export const Title = styled.h1`
  font-size: ${({ theme }) => theme.fontSizes.web.h1};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.primaryDefault};
  line-height: ${({ theme }) => theme.lineHeights.tallest};
`;

export const Year = styled.h2`
  font-size: ${({ theme }) => theme.fontSizes.web.h2};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.brandingOrange};
  line-height: ${({ theme }) => theme.lineHeights.tallest};
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: ${({ theme }) => theme.space[4]};

  @media (max-width: 1024px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const LeftContainer = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
`;
export const RightContainer = styled.div``;

export const TotalDays = styled.div`
  display: flex;

  p {
    color: ${({ theme }) => theme.colors.greyScale95};
    font-size: ${({ theme }) => theme.fontSizes.web.sm};
    font-weight: ${({ theme }) => theme.fontWeights.regular};
  }
`;

export const UnitListContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.greyScale40};
  flex-wrap: wrap;
`;

export const UnitLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const UnitList = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;

  li {
    display: inline-block;
    margin: 0 2px;
  }

  li:first-child::after {
    content: ",";
  }

  li ~ li::after {
    content: ",";
  }

  li:last-child::after {
    content: ".";
  }
`;
