import { HeimdallAPI } from "../services/api";
import { RoleInterface } from "interfaces/constants";
import { ErrorHandlerFunction } from "./service-helpers";

export const changeUserPassword = async ({
  email,
  new_password,
}: {
  email: string;
  new_password: string;
}) => {
  const response = await HeimdallAPI.put<any>("auth/user/forgotten_password/", {
    email,
    new_password,
  }).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const sendEmailChangeUserPasswordToken = async ({
  id,
  domain,
  student_name,
}: {
  id: number;
  domain: number;
  student_name: string;
}) => {
  const response = await HeimdallAPI.post<any>(
    "auth/send_email_reset_password_token",
    { id, domain, student_name }
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface ChangeUserPasswordTokenInterface {
  id: number | null;
  token: string | null;
  new_password: string;
}

export const changeUserPasswordToken = async ({
  id,
  token,
  new_password,
}: ChangeUserPasswordTokenInterface) => {
  const response = await HeimdallAPI.post<any>(
    "auth/reset_password_token",
    { id, token, new_password },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const changePassword = async ({
  email,
  password,
  new_password,
}: {
  email: string;
  password: string;
  new_password: string;
}) => {
  const response = await HeimdallAPI.put<any>("auth/user/change_password/", {
    email,
    password,
    new_password,
  }).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

/**
 * Get Token Service - HEIMDALL -
 * Informa o login e senha e recebe o token
 * Função async
 * @param email - string
 * @param password -string
 *
 * @returns refresh e access
 */
export const getToken = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  const maxAge = 604800;
  const response = await HeimdallAPI.post<{
    refresh: string;
    access: string;
  }>(
    "auth/token/",
    { email, password },
    {
      headers: { "Content-Type": "application/json" },
    }
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = {
      data: response.data,
      maxAge,
      domain: ".escolamais.com",
      status: response.status,
    };
    return data;
  }
};

/**
 * Verify User Service - HEIMDALL -
 *
 * @param email - string
 * @param password - string
 */
export const verifyUser = async ({
  email,
  password,
}: {
  email: string;
  password?: string;
}) => {
  const response = await HeimdallAPI.post<{
    id: number;
    name: string;
    email: string;
    cpf: string;
    unit: number;
    role: Array<RoleInterface>;
    permissions: Array<string>;
    request_pass_change: boolean;
    last_login: string;
  }>(
    "auth/user/basic_auth/",
    { email, password },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

/**
 * Esqueci minha senha.
 * Função que recebe o e-email do usuário e envia pro mesmo um email contendo uma nova senha.
 * @param email string
 * @returns none
 */
export const forgottenPassword = async (email: string) => {
  const response = await HeimdallAPI.post(
    "auth/recovery_password/",
    { email },
    { headers: { "Content-Type": "application/json" } }
  ).catch(ErrorHandlerFunction);
  if (response) {
    return response;
  }
};

/* V2 */

export type GetTokensResponseType = {
  refresh: string;
  access: string;
  expiration: string;
};

type GetTokensInputType = {
  email: string;
  password: string;
};

export const getTokens = async ({ email, password }: GetTokensInputType) => {
  try {
    const tokensResponse = await HeimdallAPI.post<GetTokensResponseType>(
      "auth/token/",
      {
        email,
        password,
      }
    );

    return tokensResponse.data;
  } catch (error) {
    console.log(error);
  }
};

export const refreshToken = async (refresh: string) => {
  try {
    const tokensResponse = await HeimdallAPI.post<GetTokensResponseType>(
      "auth/token/refresh/",
      {
        refresh,
      }
    );

    return tokensResponse.data;
  } catch (error) {
    console.log(error);
  }
};
