import styled from "styled-components";

export const SelectContainer = styled.div`
  width: auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin: 16px 0.3rem 0 0;

  span {
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    font-size: ${({ theme }) => theme.fontSizes.web.sm};
    line-height: ${({ theme }) => theme.lineHeights.normal};

    text-transform: uppercase;

    color: ${({ theme }) => theme.colors.greyScale95};
  }

  select {
    border: 2px solid ${({ theme }) => theme.colors.greyScale40};
    background: transparent;
    display: flex;

    height: 38px;
  }
`;
