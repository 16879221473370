import { useEffect, useState } from "react";
import { toastError } from "components/Toast";
import {
  getQuarterPublicationsByUnit,
  getQuarterPublicationsYears,
  publishQuarter,
  undoQuarterPublicationById,
} from "services/grades.service";
import { IQuarterPublicationsByUnitResponse } from "interfaces/boletim";
import { Storage } from "Storage";

const enum PublishBoletimStateMachineEnum {
  IDLE = "IDLE",
  LOADING = "LOADING",
  READY = "READY",
  ERROR = "ERROR",
}

interface IUsePublishBoletimOutput {
  stateMachine: PublishBoletimStateMachineEnum;
  years: Number[] | undefined;
  publications: IQuarterPublicationsByUnitResponse[] | undefined;
  isLoadingPublications: boolean;
  getQuarterPublications: (year: string) => Promise<void>;
  onUndoQuarterPublication: (
    publication: IQuarterPublicationsByUnitResponse,
    year: string
  ) => Promise<void>;
  onPublishQuarter: (
    publication: IQuarterPublicationsByUnitResponse,
    year: string
  ) => Promise<void>;
}

export const usePublishBoletim = (): IUsePublishBoletimOutput => {
  const [stateMachine, setStateMachine] =
    useState<PublishBoletimStateMachineEnum>(
      PublishBoletimStateMachineEnum.IDLE
    );

  const [years, setYears] = useState<Number[] | undefined>(undefined);
  const [publications, setPublications] = useState<
    IQuarterPublicationsByUnitResponse[] | undefined
  >(undefined);
  const [isLoadingPublications, setIsLoadingPublications] =
    useState<boolean>(false);

  const getYears = async () => {
    setStateMachine(PublishBoletimStateMachineEnum.LOADING);
    try {
      const years = await getQuarterPublicationsYears();
      if (years?.data) {
        setYears(years.data);
        setStateMachine(PublishBoletimStateMachineEnum.READY);
      }
    } catch (error) {
      toastError("Erro ao buscar anos");
    }
  };

  const getQuarterPublications = async (year: string) => {
    try {
      setIsLoadingPublications(true);
      const user = Storage.props.authUser.get();
      if (year && user) {
        const publications = await getQuarterPublicationsByUnit({
          unit_id: user.grade_unit_id,
          year: +year,
        });
        if (publications?.data) {
          setPublications(publications.data);
          setIsLoadingPublications(false);
        }
      }
    } catch (error) {
      toastError("Erro ao buscar trimestres");
    }
  };

  useEffect(() => {
    getYears();
  }, []);

  const onUndoQuarterPublication = async (
    publication: IQuarterPublicationsByUnitResponse,
    year: string
  ) => {
    try {
      await undoQuarterPublicationById(publication.publicacao.id);
      await getQuarterPublications(year);
    } catch (error) {
      toastError("Erro ao desfazer publicação.");
    }
  };

  const onPublishQuarter = async (
    publication: IQuarterPublicationsByUnitResponse,
    year: string
  ) => {
    const user = Storage.props.authUser.get();
    if (user) {
      const payload = {
        quarter: publication.publicacao.quarter,
        user_id: user.id,
        user_name: user.name,
        actived: true,
        unidade: user.grade_unit_id,
      };

      try {
        await publishQuarter(payload);
        await getQuarterPublications(year);
      } catch (error) {
        toastError("Erro ao publicar boletim.");
      }
    }
  };

  return {
    stateMachine,
    years,
    publications,
    isLoadingPublications,
    onPublishQuarter,
    onUndoQuarterPublication,
    getQuarterPublications,
  };
};
