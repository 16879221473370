import styled from "styled-components";
import { IStyleMenuBar } from "./interface";

export const MenuContainer = styled.div<IStyleMenuBar>`
  width: ${({ width }) => (width ? `${width}px` : "100%")};
  height: ${({ height }) => `${height}px`};

  padding: ${({ theme }) => theme.space[1]} ${({ theme }) => theme.space[2]};

  h4 {
    font-size: ${({ theme }) => theme.fontSizes.web.h4};
    font-weight: 600; // TODO: nao tem na lib
    line-height: 30px; // TODO: nao tem na lib
  }

  background-color: ${({ backgroundColor }) => backgroundColor};
`;
