import React from "react";
import { AprovacaoPreviaFilterProps } from "../../types";

import { FilterWrapper, ContentContainer, Button, ButtonText } from "./styles";
import { theme } from "@escolamais-ui-v2/tokens/src";

export const FilterBox: React.FC<AprovacaoPreviaFilterProps> = ({
  buttonsList,
  activeId,
  label,
}) => {
  return (
    <FilterWrapper>
      {label && <label>{label}</label>}
      <ContentContainer>
        {buttonsList.map(({ id, buttonText, onClick }) => (
          <Button
            bg={activeId === id ? theme.colors.primaryDefault : "transparent"}
            onClick={onClick}
            key={id}
          >
            <ButtonText
              color={
                activeId === id ? theme.colors.white : theme.colors.greyScale40
              }
            >
              {buttonText}
            </ButtonText>
          </Button>
        ))}
      </ContentContainer>
    </FilterWrapper>
  );
};
