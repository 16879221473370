import styled from "styled-components";

export const SRedirect = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    width: 550px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    span {
      font-size: 13px;
    }

    h4 {
      a {
        font-size: 16px;
        font-weight: 600;

        margin: 0;
        margin: 0.3em 0;

        color: #6d28d9;

        cursor: pointer;

        text-decoration: none;

        span {
          font-size: 16px;
          font-weight: 600;

          text-decoration: underline;
        }
      }
    }

    div {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    button {
      margin-left: auto;
      margin-right: auto;
      margin-top: 0.5em;
      padding: 8px;

      width: 300px;

      background-color: #b7fe00;

      border: none;
      border-radius: 20px;

      font-weight: 600;
      font-size: 16px;

      a {
        color: #000000;
        text-decoration: none;
      }
    }
  }

  @media (max-width: 425px) {
    flex-wrap: wrap;
    div {
      width: 250px;

      span {
        font-size: 12px;
      }

      h4 {
        a {
          font-size: 14px;

          span {
            font-size: 14px;
          }
        }
      }

      button {
        width: 200px;
        font-size: 14px;
      }
    }
  }
`;
