// React
import { useContext } from "react";
// Styles
import { ModalDiv, ModalHeader } from "./style";
// Components
import { Button } from "components";
import { toastError, toastSuccess } from "components/Toast";
// Libs
import { Modal as ModalMUI } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HTTP_STATUS from "http-status-codes";
// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
// Services
import { cancelFoodPackage } from "services/meals.service";

interface ModalCancelFoodPackageInterface {
  setIsActive: (active: boolean) => void;
  setToggleModal: (toggleModal: boolean) => void;
  isOpen: boolean;
  foodPackage: number;
}

const useStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const ModalCancelFoodPackage = ({
  setIsActive,
  setToggleModal,
  isOpen,
  foodPackage,
}: ModalCancelFoodPackageInterface) => {
  const classes = useStyles();

  const onSubmit = async () => {
    const response = await cancelFoodPackage({
      id: foodPackage,
    });

    if (response && response.status === HTTP_STATUS.OK) {
      toastSuccess("Pacote cancelado com sucesso!");
      setIsActive(false);
      setToggleModal(false);
    } else {
      toastError("Erro ao cancelar pacote! Tente novamente mais tarde.");
    }
  };

  return (
    <ModalMUI className={classes.modalStyle} open={isOpen}>
      <ModalHeader>
        <FontAwesomeIcon
          className="close"
          onClick={() => setToggleModal(false)}
          size="lg"
          icon={faTimes as IconProp}
        />
        <ModalDiv>
          <div className="title mb-3">Cancelar Pacote</div>
          <div className="text mb-3">
            Ao cancelar um Pacote de Alimentação{" "}
            <span className="textBolder">já pago</span>, o valor será retornado
            via crédito, que poderá ser utilizado em uma contratação futura. Ao
            efetuar uma nova compra do Pacote de Alimentação, será necessário
            efetuar um novo pagamento.
          </div>
          <div className="d-flex justify-content-between">
            <Button
              className="pink outline widthButton"
              onClick={() => setToggleModal(false)}
            >
              Voltar
            </Button>
            <Button className="widthButton" onClick={() => onSubmit()}>
              Confirmar
            </Button>
          </div>
        </ModalDiv>
      </ModalHeader>
    </ModalMUI>
  );
};
