enum EDisciplina {
  subject_name_mentoria = 31,
  subject_name_patio = 29,
  subject_name_roda = 28,
  subject_name_mentoria_salao_estudos = 27,
  subject_name_mentoria_carreira = 26,
  subject_name_estacoes_rotativas = 30,
}

export const removeDiscipline = (value: number) => {
  switch (value) {
    case EDisciplina.subject_name_mentoria:
      return false;
    case EDisciplina.subject_name_patio:
      return false;
    case EDisciplina.subject_name_roda:
      return false;
    case EDisciplina.subject_name_mentoria_salao_estudos:
      return false;
    case EDisciplina.subject_name_mentoria_carreira:
      return false;
    case EDisciplina.subject_name_estacoes_rotativas:
      return false;
    default:
      return true;
  }
};

export const formatNote = (value: string) => {
  if (value === "10" || value.length === 1) {
    return value.replace(/\D/g, "");
  } else if (value === "") {
    return null;
  } else {
    return value.replace(/\D/g, "").replace(/(\d{0,2})$/, ".$1");
  }
};
