import { ModalDiv } from "components/UseTerms/styles";

export const PoliticaPrivacidade = () => {
  return (
    <div className="container">
      <ModalDiv>
        <div className="text-center">
          <strong className="title">POLÍTICA DE PRIVACIDADE</strong>
        </div>
        <div>
          <strong>1. INFORMAÇÕES GERAIS E DEFINIÇÕES</strong>
        </div>
        <div>
          A ESCOLA MAIS S/A valoriza a privacidade de seus Alunos e Responsáveis
          Legais e disponibiliza esta Política de Privacidade para demonstrar
          seu compromisso em proteger seus Dados Pessoais, nos termos da Lei
          Geral de Proteção de Dados (LGPD – Lei nº 13.709, de 14/8/2018), e
          demais leis sobre o tema, bem como descrever de que forma sua
          privacidade é protegida pela escola ao coletar, tratar e armazenar
          informações pessoais.
        </div>
        <div>
          <strong>1.1 Definições</strong>
        </div>
        <div>
          <b>Contratante:</b> todas as pessoas físicas/jurídicas que contratarem
          os serviços educacionais prestado pela Escola Mais S/A.
        </div>
        <div>
          <b>Dados Pessoais:</b> significa quaisquer informações fornecidas e/ou
          coletadas pela Escola Mais S/A e/ou suas afiliadas, por qualquer meio,
          ainda que públicos, que: (I) identifiquem, ou que, quando usadas em
          combinação com outras informações tratadas identifiquem um indivíduo;
          ou (II) por meio das quais a identificação ou informações de contato
          de uma pessoa física possam ser derivadas. Os Dados Pessoais podem
          estar em qualquer mídia ou formato, inclusive registros eletrônicos ou
          computadorizados, bem como em arquivos baseados em papel. Os Dados
          Pessoais, no entanto, não incluem telefone comercial, número de
          celular comercial, endereço comercial, e-mail comercial.
        </div>
        <div>
          <b>Necessidade:</b> justificativa pelo qual é estritamente necessário
          coletar dados pessoais, para atingir a finalidade, evitando-se a
          coleta excessiva.
        </div>
        <div>
          <b>Bases legais:</b> fundamentação legal que torna legítimo o
          tratamento de dados pessoais para uma determinada finalidade prévia
          por parte da Escola Mais S/A.
        </div>
        <div>
          <b>Consentimento:</b> autorização expressa e inequívoca dada pelo
          titular do dado pessoal ou por ser Responsável Legal para que a Escola
          Mais S/A trate seus dados pessoais para uma finalidade previamente
          descrita, na qual a base legal necessária para o ato demande a
          autorização expressa do titular.
        </div>
        <div>
          Esta política se aplica, em geral, a todos os Alunos e Contratantes
          dos serviços oferecidos pela Escola Mais S/A, e resume como a escola
          poderá coletar, produzir, receptar, classificar, utilizar, acessar,
          reproduzir, transmitir, distribuir, processar, arquivar, armazenar,
          eliminar, avaliar ou controlar a informação, modificar, comunicar,
          transferir, difundir ou extrair os dados coletados, incluindo as
          informações de identificação pessoal, de acordo com as bases legais
          aplicáveis e todas as leis de privacidade e proteção de dados em
          vigor.
        </div>
        <div>
          Ao utilizar os serviços prestados pela Escola Mais S/A, o Contratante
          declara ter capacidade plena e expressa para a aceitação dos termos e
          condições desta Política de Privacidade para todos os fins de direito.
        </div>
        <strong>2. COLETA E USOS DE INFORMAÇÕES PESSOAIS</strong>
        <div>
          O Contratante está ciente de que fornece informações de forma
          consciente e voluntária, incluindo Dados Pessoais seus e dos Alunos,
          através de Formulários, Matrícula, Contratos, etc.
        </div>
        <div>
          As informações pessoais dos Alunos e Responsáveis Legais que forem
          passadas à escola serão coletadas por meios éticos e legais, podendo
          ter um ou mais propósitos, sobre os quais os Contratantes serão
          informados.
        </div>
        <strong>
          3. COMPARTILHAMENTO E TRATAMENTO DE INFORMAÇÕES PESSOAIS{" "}
        </strong>
        <div>
          A Escola MAIS S/A poderá divulgar os Dados Pessoais coletados a
          terceiros, nas seguintes situações e nos limites exigidos e
          autorizados pela Lei:
        </div>
        <div>
          <b>I.</b> Com as empresas e indivíduos contratados para a execução de
          determinadas atividades e serviços em nome da Escola Mais S/A (como
          transporte, atividades extracurriculares, entre outros).
        </div>
        <div>
          <b>II.</b> Com empresas do grupo.
        </div>
        <div>
          <b>III.</b> Com fornecedores e parceiros para consecução dos serviços
          contratados com a Escola Mais S/A (como tecnologia da informação,
          contabilidade, jurídico, entre outros).
        </div>
        <div>
          <b>IV.</b> Com terceiros responsáveis por eventual necessidade de
          atendimento médico.
        </div>
        <div>
          <b>V.</b> Para propósitos administrativos como: pesquisa,
          planejamento, desenvolvimento de serviços, segurança e gerenciamento
          de risco.
        </div>
        <div>
          <b>VI.</b> Quando necessário em decorrência de obrigação legal,
          determinação de autoridade competente, ou decisão judicial. Nas
          hipóteses de compartilhamento de Dados Pessoais com terceiros, todos
          os sujeitos mencionados nos itens I a VI deverão utilizar os Dados
          Pessoais partilhados de maneira consistente e de acordo com os
          propósitos para os quais foram coletados (ou com os quais o
          Contratante consentiu previamente) e de acordo com o que foi
          determinado por esta Política de Privacidade, e todas as leis de
          privacidade e proteção de dados aplicáveis.
        </div>
        <div>
          <strong>4. MOTIVOS LEGAIS PARA A DIVULGAÇÃO DE SEUS DADOS</strong>
        </div>
        <div>
          Em certas circunstâncias, a Escola Mais S/A poderá divulgar Dados
          Pessoais, na medida necessária ou apropriada, para órgãos
          governamentais, consultores e outros terceiros com o objetivo de
          cumprir com a legislação aplicável ou com uma ordem ou intimação
          judicial ou, ainda, se a escola acreditar de boa-fé que tal ação seja
          necessária para:
        </div>
        <div>
          <b>I. </b>Cumprir com uma legislação que exija tal divulgação.
        </div>
        <div>
          <b>II. </b>Investigar, impedir ou tomar medidas relacionadas a
          atividades ilegais suspeitas ou reais ou para cooperar com órgãos
          públicos ou para proteger a segurança de seus Alunos.
        </div>
        <div>
          <b>III. </b>Execução de seus contratos. IV. Investigar e se defender
          contra quaisquer reivindicações ou alegações de terceiros. V. Proteger
          a segurança ou a integridade dos serviços.
        </div>
        <div>
          <b>VI. </b>Exercer ou proteger os direitos, a propriedade e a
          segurança da Escola Mais S/A e suas empresas coligadas.
        </div>
        <div>
          <b>VII. </b>Proteger os direitos e a segurança pessoal de seus
          funcionários, usuários ou do público.
        </div>
        <div>
          <b>VIII. </b>Em caso de venda, compra, fusão, reorganização,
          liquidação ou dissolução da Escola Mais S/A.
        </div>
        <div>
          A Escola Mais S/A cientificará os respectivos Contratantes sobre
          eventuais demandas legais que resultem na divulgação de informações
          pessoais, nos termos do que foi exposto no item 4, a menos que tal
          cientificação seja vedada por lei ou proibida por mandado judicial ou,
          ainda, se a requisição for emergencial. A Escola Mais S/A poderá
          contestar essas demandas se julgar que as solicitações são excessivas,
          vagas ou feitas por autoridades incompetentes.
        </div>
        <div>
          <strong>5. SEGURANÇA DE INFORMAÇÕES PESSOAIS</strong>
        </div>
        <div>
          Todos os Dados Pessoais serão guardados na base de dados da Escola
          Mais S/A ou em base de dados mantidas pelos fornecedores de serviços
          contratados pela escola, os quais estão devidamente de acordo com a
          legislação de dados vigente.
        </div>
        <div>
          A Escola Mais S/A e seus fornecedores utilizam vários procedimentos de
          segurança para proteger a confidencialidade, segurança e integridade
          de todos os Dados Pessoais coletados, prevenindo a ocorrência de
          eventuais danos em virtude do tratamento desses dados.
        </div>
        <div>
          Embora a Escola Mais S/A utilize medidas de segurança e monitore seu
          sistema para verificar vulnerabilidades e ataques para proteger Dados
          Pessoais contra divulgação não autorizada, mau uso ou alteração, o
          Contratante entende e concorda que não há garantias de que as
          informações não poderão ser acessadas, divulgadas, alteradas ou
          destruídas por violação de qualquer uma das proteções físicas,
          técnicas ou administrativas.
        </div>
        <div>
          <strong>6. RETENÇÃO DE DADOS</strong>
        </div>
        <div>
          A Escola Mais S/A retém todos os dados fornecidos, inclusive os Dados
          Pessoais, a partir da assinatura do Contrato de Prestação de Serviços
          Educacionais e conforme seja necessário para consecução de seus
          serviços. Os Dados serão retidos e armazenados até eventual
          requerimento de exclusão.
        </div>
        <div>
          A Escola Mais S/A poderá vir a manter Dados Pessoais após receber o
          pedido de exclusão caso seja necessário para cumprimento de obrigações
          legais, resolver conflitos, manter a segurança, evitar fraudes e abuso
          e garantir o cumprimento de contratos.
        </div>
        <div>
          <strong>7. BASES LEGAIS PARA PROCESSAMENTO</strong>
        </div>
        <div>
          A Escola Mais S/A apenas trata Dados Pessoais em situações em que está
          autorizada legalmente ou mediante o expresso e inequívoco
          consentimento do Contratante.
        </div>
        <div>
          Conforme descrito na presente Política , a escola tem bases legais
          para coletar, produzir, receptar, classificar, utilizar, acessar,
          reproduzir, transmitir, distribuir, processar, arquivar, armazenar,
          eliminar, avaliar ou controlar a informação, modificar, comunicar,
          transferir, difundir ou extrair dados sobre os Alunos e seus
          Responsáveis Legais, sempre observada a boa-fé e os princípios legais
          para a sua utilização, ou sejam: finalidade, adequação, necessidade,
          livre acesso, qualidade dos dados, transparência, segurança,
          prevenção, não discriminação, responsabilização e prestação de contas.
        </div>
        <div>
          As bases legais incluem o consentimento, contratos, procedimentos
          preliminares contratuais e interesses legítimos, desde que tal
          processamento não viole seus direitos e liberdades.
        </div>
        <div>
          Tais interesses incluem proteger os Alunos e Responsáveis Legais e a
          escola de ameaças, cumprir a legislação aplicável, o exercício regular
          de direitos em processo judicial, administrativo ou arbitral,
          habilitar a realização ou administração dos negócios, incluindo
          controle de qualidade, relatórios e serviços oferecidos, gerenciar
          transações empresariais, entender e melhorar os negócios e
          relacionamentos com os clientes.
        </div>
        <div>
          O Contratante tem o direito de negar ou retirar o consentimento
          fornecido à Escola Mais S/A, quando esta for a base legal para
          tratamento dos dados pessoais, podendo a escola encerrar a consecução
          de seus serviços para este Contratante na hipótese de ocorrência de
          tal solicitação.
        </div>
        <div>
          <strong>8. DIREITO DE ACESSAR E CONTROLAR SEUS DADOS PESSOAIS</strong>
        </div>
        <div>
          A Escola Mais S/A oferece ao Contratante opções do que fazer com os
          Dados Pessoais coletados, tratados e armazenados, incluindo sua
          exclusão e/ou correção. O Contratante poderá:
        </div>
        <div>
          <b>I. </b>Excluir dados: o Contratante pode solicitar a exclusão de
          Dados Pessoais fornecidos (por exemplo, se eles não são mais
          necessários para fornecimento dos serviços).
        </div>
        <div>
          <b>II. </b>Alterar ou corrigir dados: o Contratante pode editar ou
          solicitar a edição de Dados Pessoais. O Contratante também pode
          solicitar atualizações, alterações ou correções, principalmente se
          eles estiverem incorretos.
        </div>
        <div>
          <b>III. </b>Colocar objeções, limites ou restrições ao uso de dados: o
          Contratante pode solicitar a interrupção do uso de todos ou alguns dos
          Dados Pessoais fornecidos, ou limitar a utilização de tais dados (por
          exemplo, se os Dados Pessoais estiverem incorretos ou armazenados
          ilegalmente), destacando-se que a Escola Mais S/A poderá tratar os
          Dados Pessoais de acordo com as bases legais.
        </div>
        <div>
          <b>IV. </b>O Contratante tem direito de acessar ou levar seus dados: o
          Contratante pode solicitar uma cópia dos Dados Pessoais e dos dados
          que o Contratante forneceu em um formato legível sob a forma impressa
          ou por meio eletrônico.
        </div>
        <div>
          <strong>9. REVISÕES À POLÍTICA</strong>
        </div>
        <div>
          Caso a Escola Mais S/A modifique esta Política de Privacidade, tais
          alterações serão disponibilizadas em local de fácil acesso no endereço
          eletrônico da escola ou na Secretaria da escola. Esta Política é
          válida a partir de outubro de 2019. Caso o Contratante tenha quaisquer
          questões a respeito da política de privacidade, por favor, entre em
          contato com a escola, por meio dos canais de atendimento indicados.
        </div>
        <div>
          <strong>10. MEDIAÇÃO E FORO DE ELEIÇÃO</strong>
        </div>
        <div>
          Esta política está sujeita à Lei da República Federativa do Brasil e o
          Foro da Comarca de São Paulo é competente para dirimir qualquer
          controvérsia com relação à mesma.
        </div>
      </ModalDiv>
    </div>
  );
};
