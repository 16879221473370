// React
import { useCallback, useContext, useEffect, useState } from "react";
// Interfaces
import { IHelper, IInitialProps, ISecondForm } from "./interface";
import { EnrollmentYearsInterface } from "interfaces/constants";
import { IBadgeList } from "interfaces/enrollment";
import { UnitInterface } from "interfaces/unit";
// Services
import { getUnits } from "services/units.service";
import { getYear } from "services/multipurpose";
import {
  badgeDataDownload,
  badgeList,
  badgePhotoDownload,
} from "services/enrollment.service";
// Libs
import HTTP_STATUS from "http-status-codes";
import { useTranslation } from "react-i18next";
// Components
import { toastError } from "components/Toast";

export const GenerateBadgeFileHelper = ({ form, setForm }: IHelper) => {
  const { t } = useTranslation();
  const prefix = "pages.EDF.badgeFiles.helpers";

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [loadingFiles, setLoadingFiles] = useState(false);

  // Pega ano e unidade
  const [initialProps, setInitialProps] = useState<IInitialProps>({
    units: null,
    years: null,
  });

  // Volta os dados necessarios para a tabela
  const [studentList, setStudentList] = useState<IBadgeList | null>();

  // Const para selecionar alunos da lista
  const [secondForm, setSecondForm] = useState<ISecondForm>({
    enrollments: [],
    allStudentsOnThePage: false,
    selectedAll: false,
  });

  const reloading =
    initialProps.units && initialProps.years && !studentList ? true : false;

  // Seta ano e unidade assim que renderiza a pag
  const setProps = useCallback(
    (units: Array<UnitInterface>, years: EnrollmentYearsInterface) => {
      if (!initialProps.units && !initialProps.years) {
        setInitialProps({
          ...initialProps,
          units,
          years,
        });
        setLoading((loading) => !loading);
      }
    },
    [initialProps]
  );

  const badge = async () => {
    setStudentList(null);
    const badge = await badgeList({
      unit: form.unit,
      year: form.year,
      exported: form.exported,
      has_photo: form.type === 1 ? true : false,
      student_name: form.studentName,
      page_size: form.pageSize,
      type: form.enrollmentType,
    });
    if (badge && badge.status === HTTP_STATUS.OK) {
      setStudentList(badge.data);
      setForm({
        ...form,
        lastStudent: badge.data.results.length,
      });
    }
  };

  // Chamada inicial da page
  useEffect(() => {
    (async () => {
      try {
        setStudentList(null);
        const units = await getUnits();
        const years = await getYear({});
        if (units && years) {
          setProps(units.data, years.data);
          badge();
        }
      } catch (error) {
        setLoading((loading) => !loading);
        setError((error) => !error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const types = [
    { id: 1, label: "Estudantes com foto" },
    { id: 2, label: "Estudantes sem foto" },
  ];

  const studentsPerPage = [
    { value: 10, label: "10" },
    { value: 20, label: "20" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  const allStudentsOnPage = () => {
    if (studentList) {
      const enrollments: Array<number> = [];

      if (!secondForm.allStudentsOnThePage) {
        studentList.results.forEach((student) => {
          enrollments.push(student.id);
        });
        setSecondForm({
          ...secondForm,
          enrollments,
          allStudentsOnThePage: true,
        });
      } else {
        setSecondForm({
          ...secondForm,
          enrollments,
          allStudentsOnThePage: false,
        });
      }
    }
  };

  const download = useCallback(async () => {
    setLoadingFiles(true);
    const badgeXlsx = await badgeDataDownload({
      enrollments: secondForm.enrollments,
      selected_all: secondForm.selectedAll,
      unit: form.unit,
      has_photo: form.type === 1 ? true : false,
      exported: form.exported,
      student_name: form.studentName,
      year: form.year,
      page: form.page,
      type: form.enrollmentType,
    });

    if (badgeXlsx && badgeXlsx.status === HTTP_STATUS.OK) {
      const url = window.URL.createObjectURL(new Blob([badgeXlsx.data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = `export.xlsx`;
      a.click();
    } else {
      toastError(t(`${prefix}.xlsxError`));
      setLoadingFiles(false);
    }

    const badgePhotos = await badgePhotoDownload({
      enrollments: secondForm.enrollments,
      selected_all: secondForm.selectedAll,
      unit: form.unit,
      has_photo: form.type === 1 ? true : false,
      exported: form.exported,
      student_name: form.studentName,
      year: form.year,
      page: form.page,
      type: form.enrollmentType,
    });

    if (badgePhotos && badgePhotos.status === HTTP_STATUS.OK) {
      const url = window.URL.createObjectURL(new Blob([badgePhotos.data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = `export.zip`;
      a.click();

      setLoadingFiles(false);
    } else {
      toastError(t(`${prefix}.zipError`));
      setLoadingFiles(false);
    }
  }, [form, secondForm, t]);

  // Pagination
  const Offset = async (direction: string) => {
    setStudentList(null);
    const response = await badgeList({
      page: direction === "foward" ? form.page + 1 : form.page - 1,
      unit: form.unit,
      year: form.year,
      exported: form.exported,
      has_photo: form.type === 1 ? true : false,
      student_name: form.studentName,
      page_size: form.pageSize,
      type: form.enrollmentType,
    });
    if (response && response.status === HTTP_STATUS.OK) {
      if (direction === "foward") {
        setForm({
          ...form,
          firstStudent:
            form.page + 1 === 2
              ? form.firstStudent + response.data.results.length - 1
              : form.firstStudent + response.data.results.length,
          lastStudent: form.lastStudent + response.data.results.length,
          page: form.page + 1,
        });
      } else {
        setForm({
          ...form,
          firstStudent:
            form.page - 1 === 1
              ? 1
              : form.firstStudent - response.data.results.length,
          lastStudent: form.lastStudent - response.data.results.length,
          page: form.page - 1,
        });
      }
      setStudentList(response.data);
    }
  };

  return {
    loading,
    error,
    initialProps,
    studentList,
    types,
    studentsPerPage,
    reloading,
    secondForm,
    setSecondForm,
    allStudentsOnPage,
    download,
    Offset,
    loadingFiles,
    badge,
  };
};
