import { useCallback, useState, useEffect } from "react";
import { GradeAPI } from "services/api";
import HTTP_STATUS from "http-status-codes";
import { Storage } from "Storage";
import {
  GetSchoolReportFinalProps,
  SchoolReportFinalDataProps,
} from "./GuardianSchoolReport.types";

export const useSchoolReport = () => {
  const [schoolReportFinal, setSchoolReport] =
    useState<SchoolReportFinalDataProps>();
  const [loaded, setLoaded] = useState<boolean>(false);

  const user = Storage.props.authUser.get();
  const unit = user && user.grade_unit_id;

  const getSchoolReportFinal = useCallback(
    async ({ year, student_id, kanata_id }: GetSchoolReportFinalProps) => {
      setLoaded(false);
      try {
        const response: any = await GradeAPI.get(
          `classes/school_report?year=${year}&kanata_id=${kanata_id}`
        );

        if (response.status === HTTP_STATUS.OK) {
          setSchoolReport(response.data);
          setLoaded(true);
        }
      } catch (error) {
        setSchoolReport(undefined);
        setLoaded(true);
      }
    },
    []
  );

  const handleLoadData = useCallback(async () => {
    try {
      if (unit) {
        const response = await GradeAPI.get(
          `classes/gradeyear/${unit}?&full=s`
        );

        if (response.status === HTTP_STATUS.OK) {
          setSchoolReport(response.data);
          setLoaded(true);
        }
      }
    } catch (error) {
      setSchoolReport(undefined);
      setLoaded(true);
    }
  }, [unit]);

  useEffect(() => {
    handleLoadData();
  }, [handleLoadData]);

  return {
    getSchoolReportFinal,
    schoolReportFinal,
    loaded,
    handleLoadData,
  };
};
