// React
import { useState } from "react";
// Libs
import HTTP_STATUS from "http-status-codes";
import { useTranslation } from "react-i18next";
// Components
import { toastError, toastSuccess, toastWarning } from "components/Toast";
// Services
import { updateSalesPeriod } from "services/sales";
// Interfaces
import { IHelper } from "./interface";

export const HDrawerRegistrationSalesPeriod = ({
  id,
  period,
  setPeriod,
  setDrawer,
}: IHelper) => {
  const { t } = useTranslation();
  const TRANSLATE =
    "pages.EDF.periodEnrollmentReenrollment.components.drawer.helper";

  const [loading, setLoading] = useState(false);

  const handleUpdate = async () => {
    if (period) {
      setLoading(true);
      const response = await updateSalesPeriod({
        id,
        payload: period,
      });

      if (response?.status === HTTP_STATUS.OK) {
        toastSuccess(t(`${TRANSLATE}.toastSuccess`));
        setDrawer(false);
        setLoading(false);
        setPeriod(undefined);
      } else {
        toastError(t(`${TRANSLATE}.toastError`));
        setLoading(false);
      }
    } else {
      toastWarning(t(`${TRANSLATE}.toastWarning`));
    }
  };

  return { loading, handleUpdate };
};
