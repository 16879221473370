import styled from "styled-components";

export const AddGuardianStyled = styled.div`
  margin-top: 1.5rem;
  margin-right: 1rem;
  width: 309px;
  height: 106px;
  border: 2px solid ${({ theme }) => theme.colors.primaryDefault};
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    margin-top: 1.875rem;
  }

  label {
    margin-top: 1.063rem;
    margin-bottom: 1.313;
    font-size: 16px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    /* color: puxar da lib */
  }

  @media (max-width: 360px) {
    margin-right: 0;
    width: 100%;
  }
`;
