import { useLocation, useHistory } from "react-router-dom";
import { Title } from "components";
import { StudentHeader } from "components/molecules/StudentHeader";
import { StudentInterface } from "interfaces/constants";
import * as S from "./styles";
import { useAtomValue } from "jotai";
import { userInfoAtom } from "jotai/authUser";

interface ISelectStudentLocation {
  title: string;
  students: StudentInterface[];
  redirect: string;
}

export const SelectStudent = () => {
  const history = useHistory();
  const { state } = useLocation<ISelectStudentLocation>();
  const userInfo = useAtomValue(userInfoAtom);

  return (
    <S.Container>
      <Title fontSize="36px">{state.title}</Title>
      <h2>Selecione o estudante</h2>

      <S.StudentsList>
        {state?.students?.map((student) => (
          <S.StudentCard
            onClick={() =>
              history.push({
                pathname: state.redirect,
                state: {
                  selectedStudent: student,
                  role: userInfo.role[0].label,
                },
              })
            }
          >
            <StudentHeader student={student} />
          </S.StudentCard>
        ))}
      </S.StudentsList>
    </S.Container>
  );
};
