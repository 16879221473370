import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import * as S from "./styles";
import * as colors from "styles/colors";

interface ICircleProgressBar {
  stepsNumbers: number;
  currentStep: number;
}

export const CircleProgressBar = ({
  stepsNumbers,
  currentStep,
}: ICircleProgressBar) => {
  return (
    <S.Container>
      <CircularProgressbar
        value={(100 / stepsNumbers) * currentStep}
        strokeWidth={15}
        styles={buildStyles({
          pathColor: `${colors.NEW_GREEN}`,
          backgroundColor: `${colors.GRAY_THREE}`,
        })}
      />
    </S.Container>
  );
};
