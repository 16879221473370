// React
import { useCallback, useEffect } from "react";
// Libs
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import HTTP_STATUS from "http-status-codes";
import { useTranslation } from "react-i18next";
// Components
import { toastError, toastSuccess, toastWarning } from "components/Toast";
// Services
import {
  INewGuardianSignupResponse,
  newGuardianSignup,
} from "services/guardian.service";
import { getAddressByCEP } from "services/cep.service";
import { checkValidReferenceCode } from "services/hubspot.service";
// Helpers
import { readFileAsBase64 } from "helpers/helpers";
// Interfaces
import { IDocumentsGuardianSiognup, IErrorSubmit } from "./interface";
import { useSetAtom } from "jotai";
import { newCreatedGuardianSignupAtom } from "jotai/newGuardianSignup/atom";
import { useHistory } from "react-router-dom";

const maxFileSize = 15000000;

const GuardianSignupSchema = z.object({
  arquivos_documento_identificacao: z
    .instanceof(FileList)
    .refine(
      (list) => list?.length <= 2,
      "O número máximo de arquivos (2) foi excedido."
    )
    .refine(
      (list) =>
        Array.prototype.slice
          .call(list)
          .every((file: File) => file.size < maxFileSize),
      "O tamanho máximo do arquivo (15mb) foi excedido."
    )
    .transform(async (list) => {
      const fileList: IDocumentsGuardianSiognup[] = [];

      for (let i = 0; i < list.length; i++) {
        const file = list[i];
        // eslint-disable-next-line no-await-in-loop
        const base64Data = await readFileAsBase64(file);
        fileList.push({
          nome: file.name,
          arquivo: base64Data,
        });
      }

      return fileList;
    })
    .optional(),
  nome: z.string().nonempty("Insira o nome do responsável."),
  cpf: z
    .string()
    .nonempty("Insira o cpf do responsável.")
    .transform((value) => {
      return value.replace(/\D/g, "");
    }),
  rg: z
    .string()
    .nonempty("Insira o documento do responsável.")
    .transform((value) => {
      return value.replace(/\D/g, "");
    }),
  data_nascimento: z.string().nonempty("Insira o documento do responsável."),
  codigo_indicador: z.string().optional(),

  // Contato
  celular: z
    .string()
    .nonempty("Insira o celular do responsável.")
    .transform((value) => {
      return value.replace(/\D/g, "");
    }),
  telefone: z.string().optional(),
  email: z
    .string()
    .email("Formato de e-mail inválido.")
    .nonempty("Insira o endereço de e-mail do responsável.")
    .toLowerCase(),

  // Endereço
  arquivos_documento_endereco: z
    .instanceof(FileList)
    .refine(
      (list) => list?.length <= 2,
      "O número máximo de arquivos (2) foi excedido."
    )
    .refine(
      (list) =>
        Array.prototype.slice
          .call(list)
          .every((file: File) => file.size < maxFileSize),
      "O tamanho máximo do arquivo (15mb) foi excedido."
    )
    .transform(async (list) => {
      const fileList: IDocumentsGuardianSiognup[] = [];

      for (let i = 0; i < list.length; i++) {
        const file = list[i];
        // eslint-disable-next-line no-await-in-loop
        const base64Data = await readFileAsBase64(file);
        fileList.push({
          nome: file.name,
          arquivo: base64Data,
        });
      }

      return fileList;
    })
    .optional(),
  cep: z
    .string()
    .nonempty("Insira o cep.")
    .transform((value) => {
      return value.replace(/\D/g, "");
    }),
  endereco: z.string().nonempty("Insira o endereço."),
  numero: z.string().nonempty("Insira o numero."),
  bairro: z.string().nonempty("Insira o bairro."),
  complemento: z.string().optional(),
  estado: z.string().nonempty("Insira o UF."),
  cidade: z.string().nonempty("Insira a cidade."),
});

export type GuardianSignupFormData = z.infer<typeof GuardianSignupSchema>;

export const GuardianSignupHelper = () => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.guardianSignup";
  const history = useHistory();
  const setNewCreatedGuardian = useSetAtom(newCreatedGuardianSignupAtom);

  const redirectToNewStudentSignupPage = () => {
    history.push("/cadastro-estudante");
  };

  // Controlar os erros que vem do back
  const errorSubmit = useCallback(
    (data?: IErrorSubmit) => {
      if (data?.email?.includes("guardian with this E-mail already exists.")) {
        toastWarning(t(`${TRANSLATE}.toastWarningEmail`));
      } else if (
        data?.cpf?.includes("guardian with this CPF already exists.")
      ) {
        toastWarning(t(`${TRANSLATE}.toastWarningCpf`));
      } else {
        toastError(t(`${TRANSLATE}.toastError`));
      }
    },
    [t]
  );

  const onSubmit = useCallback(
    async (values: GuardianSignupFormData) => {
      const body = values;
      const response = await newGuardianSignup({ body });
      const createdGuardian: INewGuardianSignupResponse = response?.data;
      if (response?.status === HTTP_STATUS.CREATED && createdGuardian) {
        setNewCreatedGuardian(createdGuardian);
        toastSuccess(t(`${TRANSLATE}.toastSuccess`));
        redirectToNewStudentSignupPage();
      } else {
        errorSubmit(response?.data);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorSubmit, t]
  );

  const {
    register,
    handleSubmit,
    trigger,
    reset,
    watch,
    formState: { errors },
  } = useForm<GuardianSignupFormData>({
    resolver: zodResolver(GuardianSignupSchema),
  });

  const zipCode = watch("cep");
  const indicationCode = watch("codigo_indicador");

  const fetchAddressData = useCallback(
    async (value: string) => {
      const response = await getAddressByCEP(value);
      if (response && response.status === HTTP_STATUS.OK) {
        reset({
          endereco: `${response.data.tipo_logradouro} ${response.data.logradouro}`,
          bairro: response.data.bairro,
          cidade: response.data.cidade,
          estado: response.data.uf,
        });
      } else {
        toastError(t(`${TRANSLATE}.toastErrorCep`));
      }
    },
    [reset, t]
  );

  const cepValidation = (value: string) => {
    const mask = /^\d{5}-\d{3}$/;
    if (mask.test(value)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (zipCode) {
      if (cepValidation(zipCode)) {
        fetchAddressData(zipCode);
      }
    }
  }, [fetchAddressData, zipCode]);

  const validateCode = useCallback(
    async (value: string) => {
      const response = await checkValidReferenceCode(value);
      if (response && response.status === HTTP_STATUS.OK) {
        toastSuccess(t(`${TRANSLATE}.toastSuccessReferenceCode`));
      } else {
        toastError(t(`${TRANSLATE}.toastErrorReferenceCode`));
      }
    },
    [t]
  );

  useEffect(() => {
    if (indicationCode) {
      if (indicationCode.length >= 5) {
        validateCode(indicationCode);
      }
    }
  }, [indicationCode, validateCode]);

  return {
    onSubmit,
    register,
    handleSubmit,
    trigger,
    watch,
    errors,
    fetchAddressData,
  };
};
