import { Component } from "react";
import PropTypes from "prop-types";
import LogoEscola from "static/img/logo-escola-mais.png";
import * as Sentry from "@sentry/browser";

export default class ErrorBoundary extends Component {
  state = {
    error: "",
    errorInfo: "",
    eventId: "",
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.log({ error, errorInfo });
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId, errorInfo, error });
    });
  }

  render() {
    const { hasError, errorInfo, error } = this.state;
    if (hasError) {
      return (
        <div className="container">
          <div className="row justify-content-center mt-3">
            <div className="col-12 text-center mt-3">
              <img
                src={LogoEscola}
                alt="Logo tela de erro escola mais"
                width={200}
                height={200}
              />
            </div>
            <div className="col-12 text-center mt-3">
              <h2>Ocorreu um erro ao renderizar a página. </h2>
            </div>

            <div className="col-12 justify-content-center mt-3">
              <h4>Informações do Erro:</h4>
              {new Date().toLocaleString()}
              {error && error.message}
              {error && error.stack}
              {errorInfo && errorInfo.componentStack.toString()}
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};
