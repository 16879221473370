import { MenuBar } from "components";
import { useTranslation } from "react-i18next";
import * as S from "./style";
import { Button } from "@escolamais-ui-v2/react";
import { ScholarshipAndDiscount } from "./components/ScholarshipAndDiscount";
import {
  VacancyDataActionTypesEnum,
  useVacancyData,
} from "./VacancyData.helper";
import { ChangeEvent, FormEvent } from "react";
import { changeLevelName } from "components/templates/Dashboard/dashboard.helper";
import { validateInputBetween0And100 } from "helpers/helpers";
import { toastError } from "components/Toast";
import { Loading } from "components";
import { useAtomValue } from "jotai";
import { newCreatedStudentSignupAtom } from "jotai/newStudentSignup/atom";
import { newCreatedGuardianSignupAtom } from "jotai/newGuardianSignup/atom";

export const VacancyData = () => {
  const newCreatedStudentSignup = useAtomValue(newCreatedStudentSignupAtom);
  const newCreatedGuardianSignup = useAtomValue(newCreatedGuardianSignupAtom);

  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.vacancyData";
  const { state, dispatch } = useVacancyData();

  const handleSelectYear = (e: ChangeEvent<HTMLSelectElement>) => {
    dispatch({
      type: VacancyDataActionTypesEnum.SET_SELECTED_YEAR,
      payload: e.currentTarget.value,
    });
  };

  const handleSelectVendor = (e: ChangeEvent<HTMLSelectElement>) => {
    dispatch({
      type: VacancyDataActionTypesEnum.SET_SELECTED_VENDOR,
      payload: JSON.parse(e.currentTarget.value),
    });
  };

  const handleSelectUnit = (e: ChangeEvent<HTMLSelectElement>) => {
    dispatch({
      type: VacancyDataActionTypesEnum.SET_SELECTED_UNIT,
      payload: {
        unit: JSON.parse(e.currentTarget.value),
      },
    });
  };

  const handleSelectYearAndCycle = (e: ChangeEvent<HTMLSelectElement>) => {
    dispatch({
      type: VacancyDataActionTypesEnum.SET_SELECTED_YEAR_AND_CYCLE,
      payload: JSON.parse(e.currentTarget.value),
    });
  };

  const handleChangeAvailableStartMonth = (
    e: ChangeEvent<HTMLSelectElement>
  ) => {
    dispatch({
      type: VacancyDataActionTypesEnum.SET_SELECTED_INITIAL_MONTH,
      payload: JSON.parse(e.currentTarget.value),
    });
  };

  const handleChangeScholarship = (e: ChangeEvent<HTMLSelectElement>) => {
    dispatch({
      type: VacancyDataActionTypesEnum.SET_SELECTED_SCHOLARSHIP,
      payload: JSON.parse(e.currentTarget.value),
    });
  };

  const handleChangeDiscountPercentageReservation = (
    e: FormEvent<HTMLInputElement>
  ) => {
    if (validateInputBetween0And100(e.currentTarget.value)) {
      dispatch({
        type: VacancyDataActionTypesEnum.SET_DISCOUNT_FORM,
        payload: {
          ...state?.discountForm,
          scholarship_percentage: e.currentTarget.value,
        },
      });
    } else {
      toastError("Apenas números entre 0 e 100");
    }
  };

  const handleChangeDiscountMonthly = (e: FormEvent<HTMLInputElement>) => {
    if (validateInputBetween0And100(e.currentTarget.value)) {
      dispatch({
        type: VacancyDataActionTypesEnum.SET_DISCOUNT_FORM,
        payload: {
          ...state?.discountForm,
          scholarship_percentage_monthly: e.currentTarget.value,
        },
      });
    } else {
      toastError("Apenas números entre 0 e 100");
    }
  };

  const handleChangeDiscountMaterial = (e: FormEvent<HTMLInputElement>) => {
    if (validateInputBetween0And100(e.currentTarget.value)) {
      dispatch({
        type: VacancyDataActionTypesEnum.SET_DISCOUNT_FORM,
        payload: {
          ...state?.discountForm,
          scholarship_percentage_material: e.currentTarget.value,
        },
      });
    } else {
      toastError("Apenas números entre 0 e 100");
    }
  };

  const handleCreateEnrollment = () => {
    if (newCreatedStudentSignup && newCreatedGuardianSignup) {
      dispatch({
        type: VacancyDataActionTypesEnum.SET_CREATE_ENROLLMENT_REQUEST,
        payload: {
          student: newCreatedStudentSignup,
          guardian: newCreatedGuardianSignup,
        },
      });
    }
  };

  return (
    <S.VacancyDataContainer>
      <h1>{t(`${TRANSLATE}.title`)}</h1>
      <MenuBar title={`[${newCreatedStudentSignup?.nome ?? "Estudante"}]`} />
      {!state?.loaders?.isFiltersLoading && (
        <S.SelectGroup>
          <S.SelectContainer>
            <S.SelectTitle>{t(`${TRANSLATE}.selects.vendor`)}*</S.SelectTitle>
            <select
              name="vendor"
              id="vendor"
              onChange={handleSelectVendor}
              value={JSON.stringify(state?.selectedVendor)}
            >
              {state?.vendors?.map((vendor) => (
                <option value={JSON.stringify(vendor)} key={vendor.id}>
                  {vendor.name}
                </option>
              ))}
            </select>
          </S.SelectContainer>
          <S.SelectContainer>
            <S.SelectTitle>
              {t(`${TRANSLATE}.selects.enrollmentYear`)}*
            </S.SelectTitle>
            <select
              name="enrollmentYear"
              id="enrollmentYear"
              value={state?.selectedYear}
              onChange={handleSelectYear}
            >
              {state?.years?.map((year) => (
                <option value={year.value.toString()} key={year.label}>
                  {year.label}
                </option>
              ))}
            </select>
          </S.SelectContainer>
          <S.SelectContainer>
            <S.SelectTitle>{t(`${TRANSLATE}.selects.unit`)}*</S.SelectTitle>
            <select
              name="unit"
              id="unit"
              onChange={handleSelectUnit}
              value={JSON.stringify(state?.selectedUnit?.unit)}
            >
              {state?.unitsYearAndCycle?.map(({ unit }) => (
                <option value={JSON.stringify(unit)} key={unit.id}>
                  {unit.name}
                </option>
              ))}
            </select>
          </S.SelectContainer>
          <S.SelectContainer>
            <S.SelectTitle>
              {t(`${TRANSLATE}.selects.yearAndCycle`)}*
            </S.SelectTitle>
            <select
              name="yearAndCycle"
              id="yearAndCycle"
              onChange={handleSelectYearAndCycle}
              value={JSON.stringify(state?.selectedYearAndCycle)}
            >
              {state?.selectedUnit?.unit?.educational_level.map(
                ({ grades, name, id }) =>
                  grades?.map(({ grade }) => (
                    <option
                      value={JSON.stringify({
                        educational_level_id: id,
                        gradeId: grade,
                      })}
                      key={`${name}-${grade}`}
                    >{`${grade}º ${changeLevelName(name)}`}</option>
                  ))
              )}
            </select>
          </S.SelectContainer>
        </S.SelectGroup>
      )}
      {state?.loaders?.isFiltersLoading && (
        <S.LoadingContainer>
          <Loading />
        </S.LoadingContainer>
      )}
      <h2>{t(`${TRANSLATE}.subtitle`)}</h2>
      {!state?.loaders?.isPlanConfigLoading && (
        <S.PlanConfigurationOptions>
          <S.ScholarshipDiscount>
            <h3>{t(`${TRANSLATE}.scholarship`)}</h3>
            <S.SelectContainer>
              <S.SelectTitle>bolsa*</S.SelectTitle>
              <select
                name="scholarship"
                id="scholarship"
                onChange={handleChangeScholarship}
                value={JSON.stringify(state?.selectedScholarship)}
              >
                {state?.planConfig?.scholarships?.map((scholarship) => (
                  <option
                    value={JSON.stringify(scholarship)}
                    key={scholarship.id}
                  >
                    {scholarship.name}
                  </option>
                ))}
              </select>
            </S.SelectContainer>
            <S.DiscountsSection>
              <p>{t(`${TRANSLATE}.discounts.title`)}</p>
              <S.DiscountOption>
                <ScholarshipAndDiscount.DiscountOptionCheckbox
                  id="scholarship_percentage"
                  checked
                  readonly
                  label={t(`${TRANSLATE}.discounts.reservation`)}
                />
                <S.DiscountPercentageContainer>
                  <ScholarshipAndDiscount.DiscountPercentageInput
                    onChange={handleChangeDiscountPercentageReservation}
                    value={state?.discountForm?.scholarship_percentage}
                    disabled={
                      state?.selectedScholarship?.scholarship_percentage !==
                      null
                    }
                  />
                </S.DiscountPercentageContainer>
              </S.DiscountOption>
              <S.DiscountOption>
                <ScholarshipAndDiscount.DiscountOptionCheckbox
                  id="scholarship_percentage_monthly"
                  checked
                  readonly
                  label={t(`${TRANSLATE}.discounts.monthlyFee`)}
                />
                <S.DiscountPercentageContainer>
                  <ScholarshipAndDiscount.DiscountPercentageInput
                    onChange={handleChangeDiscountMonthly}
                    value={state?.discountForm?.scholarship_percentage_monthly}
                    disabled={
                      state?.selectedScholarship
                        ?.scholarship_percentage_monthly !== null
                    }
                  />
                </S.DiscountPercentageContainer>
              </S.DiscountOption>
              <S.DiscountOption>
                <ScholarshipAndDiscount.DiscountOptionCheckbox
                  id="scholarship_percentage_material"
                  checked
                  readonly
                  label={t(`${TRANSLATE}.discounts.material`)}
                />
                <S.DiscountPercentageContainer>
                  <ScholarshipAndDiscount.DiscountPercentageInput
                    onChange={handleChangeDiscountMaterial}
                    value={state?.discountForm?.scholarship_percentage_material}
                    disabled={
                      state?.selectedScholarship
                        ?.scholarship_percentage_material !== null
                    }
                  />
                </S.DiscountPercentageContainer>
              </S.DiscountOption>
            </S.DiscountsSection>
          </S.ScholarshipDiscount>

          <S.PlanConfiguration>
            <h3>{t(`${TRANSLATE}.planConfiguration.title`)}</h3>
            <S.SelectContainer>
              <S.SelectTitle>
                {t(`${TRANSLATE}.planConfiguration.initialMonth`)}*
              </S.SelectTitle>
              <select
                name="initialMonth"
                id="initialMonth"
                onChange={handleChangeAvailableStartMonth}
                value={JSON.stringify(state?.selectedInitialMonth)}
              >
                {state?.planConfig?.availableStartMonths?.map((month) => (
                  <option
                    value={JSON.stringify(month)}
                    key={`${month.start_month_display}-${month.year}`}
                  >
                    {month.start_month_display}
                  </option>
                ))}
                {!state?.loaders?.isPlanConfigLoading &&
                  !state?.selectedInitialMonth && (
                    <option>Nenhum mês disponível</option>
                  )}
              </select>
            </S.SelectContainer>
            {/* <S.PaymentPlanSection>
            <p>{t(`${TRANSLATE}.planConfiguration.paymentPlan`)}</p>
            <S.PaymentPlanOptions>
              <S.PaymentPlanOption>
                <strong>à vista</strong>
                <p>
                  por R$00.000,00
                  <br /> + R$000,00 da
                  <br /> reserva de vaga
                </p>
              </S.PaymentPlanOption>
              <S.PaymentPlanOption>
                <strong>em 12 parcelas</strong>
                <p>
                  de R$00.000,00
                  <br /> + R$000,00 da
                  <br /> reserva de vaga
                </p>
              </S.PaymentPlanOption>
            </S.PaymentPlanOptions>
          </S.PaymentPlanSection> */}
          </S.PlanConfiguration>

          {/* <S.PlanConfigurationTotal>
          <h3>{t(`${TRANSLATE}.planConfiguration.total`)}</h3>
          <S.PriceTotalSection>
            <S.PriceTotalLine>
              <p>Mensalidade</p>
              <p>R$000.000,00</p>
            </S.PriceTotalLine>
            <S.PriceTotalLine>
              <p>Desconto</p>
              <p>-R$000.000,00</p>
            </S.PriceTotalLine>
            <S.PriceTotalLine>
              <strong>Total</strong>
              <strong>R$000.000,00</strong>
            </S.PriceTotalLine>
          </S.PriceTotalSection>
          <S.PriceTotalSectionDivider />
          <S.PriceTotalSection>
            <S.PriceTotalLine>
              <p>Reserva de vaga:</p>
              <p>R$000.000,00</p>
            </S.PriceTotalLine>
            <S.PriceTotalLine>
              <p>Desconto</p>
              <p>-R$000.000,00</p>
            </S.PriceTotalLine>
            <S.PriceTotalLine>
              <strong>Total</strong>
              <strong>R$000.000,00</strong>
            </S.PriceTotalLine>
          </S.PriceTotalSection>
          <S.PriceTotalSectionDivider />
          <S.PriceTotalSection>
            <S.PriceTotalLine>
              <p>Material Escolar:</p>
              <p>R$000.000,00</p>
            </S.PriceTotalLine>
            <S.PriceTotalLine>
              <p>Desconto</p>
              <p>-R$000.000,00</p>
            </S.PriceTotalLine>
            <S.PriceTotalLine>
              <strong>Total</strong>
              <strong>R$000.000,00</strong>
            </S.PriceTotalLine>
          </S.PriceTotalSection>
        </S.PlanConfigurationTotal> */}
        </S.PlanConfigurationOptions>
      )}
      {state?.loaders?.isPlanConfigLoading && (
        <S.LoadingContainer>
          <Loading />
        </S.LoadingContainer>
      )}
      <S.ButtonGroupContainer>
        <Button size="large" buttonText="Voltar" appearance="text" />
        <Button
          size="large"
          buttonText="Avançar"
          appearance="primary"
          onClick={handleCreateEnrollment}
        />
      </S.ButtonGroupContainer>
    </S.VacancyDataContainer>
  );
};
