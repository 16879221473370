// React
import { useCallback, useState } from "react";
// Styles
import * as S from "./styles";
// Interfaces
import { ILocationSelectMeals } from "./type";
import { SingleFoodTransactionsInterface } from "interfaces/mealInterfaces";
// Libs
import { useLocation } from "react-router-dom";
// Components
import { StepOne } from "./components/StepOne";
import { StepTwo } from "./components/StepTwo";
import { StepThree } from "./components/StepThree";
import { StepFour } from "./components/StepFour";
import { StepFive } from "./components/StepFive";
// Context
import { FoodProvider } from "context/foodContext";

export const SelectMeals = () => {
  const { state } = useLocation<ILocationSelectMeals>();
  const [step, setStep] = useState<number>(state.payment ? 2 : 0);

  const [paymentType, setPaymentType] = useState<string>("PIX");
  const [transaction, setTransaction] =
    useState<SingleFoodTransactionsInterface>();

  const nextStep = useCallback(() => {
    setStep((prevStep) => prevStep + 1);
  }, []);

  const previousStep = useCallback(() => {
    setStep((prevStep) => prevStep - 1);
  }, []);

  return (
    <FoodProvider>
      <S.Container>
        {step === 0 && (
          <StepOne
            next={nextStep}
            previous={previousStep}
            setPaymentType={setPaymentType}
            setTransaction={setTransaction}
          />
        )}
        {step === 1 && (
          <StepTwo
            next={nextStep}
            previous={previousStep}
            setPaymentType={setPaymentType}
            setTransaction={setTransaction}
          />
        )}
        {step === 2 && (
          <StepThree
            setPaymentType={setPaymentType}
            setTransaction={setTransaction!}
            next={nextStep}
            previous={previousStep}
            setStep={setStep}
          />
        )}
        {step === 3 && (
          <StepFour
            transaction={transaction}
            setTransaction={setTransaction!}
            paymentType={paymentType}
            setStep={setStep}
            next={nextStep}
          />
        )}
        {step === 4 && (
          <StepFive
            transaction={transaction}
            paymentType={paymentType}
            setStep={setStep}
          />
        )}
      </S.Container>
    </FoodProvider>
  );
};
