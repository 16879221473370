import {
  CONTRACT_STATUS,
  DOCUMENTS_STATUS,
  FINANCIAL_STATUS,
  PAYMENT_STATUS,
  SCHOOL_PLACES_STATUS,
} from "helpers/constants";

export type GerenciarMatriculasValuesType = {
  type_search: string;
  grade_id: string;
  status_id: string;
  unit_id: string;
  vendor_id: string;
  search_name: string;
  enrollment: boolean;
  re_enrollment: boolean;
  adimplente: boolean;
  inadimplente: boolean;
  action: string;
  enrollment_list?: Array<number>;
  all_enrollments?: boolean;
  user_id?: number;
  user_name?: string;
};

export const formatParamsGerenciarMatriculas = (
  values: GerenciarMatriculasValuesType
) => {
  const {
    grade_id,
    status_id,
    type_search,
    unit_id,
    vendor_id,
    search_name,
    enrollment,
    re_enrollment,
    adimplente,
    inadimplente,
  } = values;

  let url = "";

  //Filtros Pesquisa por Nome
  if (type_search === "1") {
    if (search_name) {
      url = `filter_text=${search_name}`;
    }
  }

  //Filtros Pesquisa pelo Select
  if (type_search === "2") {
    if (grade_id) {
      switch (grade_id) {
        case "1":
        case "2":
        case "3":
        case "4":
        case "5":
          url += `&grade=${grade_id}&educational_level=1`;
          break;
        case "6":
        case "7":
        case "8":
        case "9":
          url += `&grade=${grade_id}&educational_level=2`;
          break;
        case "10":
          url += `&grade=1&educational_level=3`;
          break;
        case "11":
          url += `&grade=2&educational_level=3`;
          break;
        case "12":
          url += `&grade=3&educational_level=3`;
          break;
      }
    }

    if ((enrollment || re_enrollment) && enrollment !== re_enrollment) {
      url += `&filters_enrollment_re_enroll=${
        enrollment ? "enrollment" : "re_enroll"
      }`;
    }

    if ((adimplente || inadimplente) && adimplente !== inadimplente) {
      url += `&filter_overdue_and_not_overdue=${
        adimplente ? "adimplentes" : "inadimplentes"
      }`;
    }

    if (status_id) {
      switch (status_id) {
        case "1":
          url += `&payment_status=${PAYMENT_STATUS.AGUARDANDO_PAGAMENTO_DA_RESERVA}&school_place_status=${SCHOOL_PLACES_STATUS.VAGA_RESERVADA}`;
          break;
        case "2":
          url += `&payment_status=${PAYMENT_STATUS.PAGAMENTO_DA_RESERVA_EM_ATRASO}`;
          break;
        case "3":
          url += `&school_place_status=${SCHOOL_PLACES_STATUS.LISTA_DE_ESPERA}`;
          break;
        case "4":
          url += `&school_place_status=${SCHOOL_PLACES_STATUS.VAGA_LIBERADA}&school_place_status=${SCHOOL_PLACES_STATUS.ESPERA_CANCELADA}`;
          break;
        case "5":
          url += `&payment_status=${PAYMENT_STATUS.AGUARDANDO_COMPENSACAO_PAGAMENTO}`;
          break;
        case "6":
          url += `&financial_status=${FINANCIAL_STATUS.AGUARDANDO_ANALISE_FINANCEIRA}`;
          break;
        case "7":
          url += `&documents_status=${DOCUMENTS_STATUS.AGUARDANDO_ENVIO_DOCUMENTACAO}`;
          break;
        case "8":
          url += `&contract_status=${CONTRACT_STATUS.AGUARDANDO_ASSINATURA_CONTRATO}`;
          break;
        case "9":
          url += `&school_place_status=${SCHOOL_PLACES_STATUS.VAGA_LIBERADA}`;
          break;
      }
    }

    if (unit_id) {
      url += `&unit=${unit_id}`;
    }

    if (vendor_id) {
      url += `&employee=${vendor_id}`;
    }
  }
  return url;
};
