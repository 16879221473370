import styled from "styled-components";
import * as colors from "styles/colors";
import { SendDocumentsStyledInterface } from "./type";

export const SendDocumentsStyle = styled.div<SendDocumentsStyledInterface>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: ${(props) => (props.width ? props.width : "18.6em")};
  height: ${(props) => (props.height ? props.height : "8.75em")};

  //rg
  height: ${(props) => props.rg && "12em"};

  border: 2px solid ${colors.NEW_PURPLE};
  padding: 0 1em 1em 1em;

  h1.title {
    margin-top: 1em;
    margin-bottom: -5px;
  }

  p.subtitle {
    margin-top: 0;
  }

  div.checkbox {
    display: flex;
    margin-top: 1.5em;

    p.checkboxText {
      margin-left: -10px;
    }
  }

  div.buttons {
    display: flex;
  }
`;
