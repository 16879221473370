// Styles
import { TwoCreditCardsStyled } from "./style";
// Components
import { TextInputFinalForm } from "components/atoms/TextInput";
import { SelectInputFinalForm } from "components/atoms/Select";
import { Title } from "components/atoms/Title";
import { LoadingBox } from "components";
import { Button } from "components/atoms/Button";
// Libs
import { Field, Form } from "react-final-form";
import formatString from "format-string-by-pattern";
// Helpers
import * as validator from "helpers/fieldValidators";

type TwoCreditCardType = {
  loadCreditCard: boolean;
  failed: boolean;
  approved: boolean;
  errorText: Array<string>;
  onSubmit: () => void;
  setFailed: (failed: boolean) => void;
  setCardInput: (cardInput: any) => void;
};

export const TwoCreditCards = ({
  loadCreditCard,
  failed,
  approved,
  errorText,
  onSubmit,
  setFailed,
  setCardInput,
}: TwoCreditCardType) => {
  return (
    <TwoCreditCardsStyled>
      {loadCreditCard && <LoadingBox height={50} />}
      {!failed && !approved && !loadCreditCard && (
        <>
          <Form onSubmit={onSubmit}>
            {({ form, handleSubmit, submitting, values }) => (
              <form onSubmit={handleSubmit}>
                <div className="cardOne">
                  <Title fontSize="18px" lineHeight="27px">
                    Cartão 1
                  </Title>
                  <Field
                    component={TextInputFinalForm}
                    name="value_to_pay"
                    type="text"
                    placeholder="R$"
                    label="VALOR A PAGAR"
                    validate={validator.composeValidators(validator.required)}
                    required
                  />
                  <Field
                    component={TextInputFinalForm}
                    name="card_number"
                    type="text"
                    placeholder="0000.0000.0000.0000"
                    label="NÚMERO DO CARTÃO"
                    parse={formatString("9999.9999.9999.9999")}
                    maxLength={20}
                    validate={validator.composeValidators(
                      validator.validateCreditCardIugu
                    )}
                    required
                  />
                  <Field
                    component={TextInputFinalForm}
                    name="name"
                    type="text"
                    placeholder="Nome impresso no cartão"
                    label="NOME IMPRESSO NO CARTÃO"
                    validate={validator.composeValidators(
                      validator.required,
                      validator.noSpecialCharactersAndNumbers
                    )}
                    required
                  />
                  <div className="d-flex">
                    <Field
                      component={TextInputFinalForm}
                      name="due_date"
                      type="text"
                      placeholder="mm/aa"
                      label="DATA DE VENCIMENTO"
                      validate={validator.composeValidators(validator.required)}
                      required
                      width="95%"
                    />
                    <Field
                      component={TextInputFinalForm}
                      name="cvv"
                      type="text"
                      placeholder="CVV"
                      label="CÓDIGO DE SEGURANÇA"
                      validate={validator.composeValidators(validator.required)}
                      required
                      width="100%"
                    />
                  </div>
                  <Field
                    component={SelectInputFinalForm}
                    name="installments"
                    label="PARCELAS"
                    validate={validator.composeValidators(validator.required)}
                    required
                  >
                    <option value="">À vista</option>
                  </Field>
                </div>
              </form>
            )}
          </Form>
          <Form onSubmit={onSubmit}>
            {({ form, handleSubmit, submitting, values }) => (
              <form onSubmit={handleSubmit} className="mt-2">
                <div className="cardTwo">
                  <Title fontSize="18px" lineHeight="27px">
                    Cartão 2
                  </Title>
                  <Field
                    component={TextInputFinalForm}
                    name="second_value_to_pay"
                    type="text"
                    placeholder="R$"
                    label="VALOR A PAGAR"
                    validate={validator.composeValidators(validator.required)}
                    required
                  />
                  <Field
                    component={TextInputFinalForm}
                    name="second_card_number"
                    type="text"
                    placeholder="0000.0000.0000.0000"
                    label="NÚMERO DO CARTÃO"
                    parse={formatString("9999.9999.9999.9999")}
                    maxLength={20}
                    validate={validator.composeValidators(
                      validator.validateCreditCardIugu
                    )}
                    required
                  />
                  <Field
                    component={TextInputFinalForm}
                    name="second_name"
                    type="text"
                    placeholder="Nome impresso no cartão"
                    label="NOME IMPRESSO NO CARTÃO"
                    validate={validator.composeValidators(
                      validator.required,
                      validator.noSpecialCharactersAndNumbers
                    )}
                    required
                  />
                  <div className="d-flex">
                    <Field
                      component={TextInputFinalForm}
                      name="second_due_date"
                      type="text"
                      placeholder="mm/aa"
                      label="DATA DE VENCIMENTO"
                      validate={validator.composeValidators(validator.required)}
                      required
                      width="95%"
                    />
                    <Field
                      component={TextInputFinalForm}
                      name="second_cvv"
                      type="text"
                      placeholder="CVV"
                      label="CÓDIGO DE SEGURANÇA"
                      validate={validator.composeValidators(validator.required)}
                      required
                      width="100%"
                    />
                  </div>
                  <Field
                    component={SelectInputFinalForm}
                    name="installments"
                    label="PARCELAS"
                    validate={validator.composeValidators(validator.required)}
                    required
                  >
                    <option value="">À vista</option>
                  </Field>
                </div>
              </form>
            )}
          </Form>
        </>
      )}
      {failed && !loadCreditCard && (
        <div>
          {errorText ? (
            <div className="text-center">
              Mensagem de Erro:
              {errorText.map((message) => {
                return (
                  <div className="mb-1 mt-2 text-danger" key={message}>
                    {message}
                  </div>
                );
              })}
            </div>
          ) : (
            <div>Verifique os dados do cartão e tente novamente</div>
          )}
          <Button
            onClick={() => {
              setFailed(false);
            }}
          >
            Voltar ao cartão
          </Button>
        </div>
      )}
    </TwoCreditCardsStyled>
  );
};
