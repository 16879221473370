import styled from "styled-components";
import * as colors from "styles/colors";

export const GenerateBadgeFileStyle = styled.div`
  padding: ${({ theme }) => theme.space[4]};

  h1 {
    font-size: 32px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    color: ${({ theme }) => theme.colors.primaryDefault};
  }

  h3 {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: 24px;
    line-height: ${({ theme }) => theme.lineHeights.tallest};
    color: ${({ theme }) => theme.colors.brandingOrange};
  }

  form {
    div.checkbox {
      display: flex;
      margin-top: ${({ theme }) => theme.space[4]};
      margin-right: ${({ theme }) => theme.space[8]};

      label.text {
        font-weight: ${({ theme }) => theme.fontWeights.regular};
        font-size: ${({ theme }) => theme.fontSizes.web.sm};
        line-height: ${({ theme }) => theme.lineHeights.normal};
        margin-left: ${({ theme }) => theme.space[1]};
      }
    }

    div.secondLine {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      padding-bottom: ${({ theme }) => theme.space[4]};

      &.thirdLine {
        border-bottom: 1px solid ${({ theme }) => theme.colors.greyScale40};
      }

      div {
        margin-top: ${({ theme }) => theme.space[4]};
        display: flex;
        flex-direction: column;
        margin-right: ${({ theme }) => theme.space[8]};
        width: 13%;

        &.studentName {
          width: 27%;
        }

        label {
          float: left;
          font-weight: ${({ theme }) => theme.fontWeights.regular};
          font-size: 14px;
          line-height: ${({ theme }) => theme.lineHeights.normal};
          color: ${colors.NEW_GRAY_SCALE}; // Não possui na lib
        }

        select {
          margin-top: -5px;
          box-sizing: border-box;
          height: ${({ theme }) => theme.space[10]};
          background: ${({ theme }) => theme.colors.white};
          border: 2px solid ${({ theme }) => theme.colors.greyScale40};
          color: ${({ theme }) => theme.colors.greyScale40};

          &.hasValue {
            color: ${colors.NEW_GRAY_SCALE}; // Não possui na lib
          }
        }

        input {
          margin-top: -5px;
          padding-left: ${({ theme }) => theme.space[1]};
          box-sizing: border-box;
          height: ${({ theme }) => theme.space[10]};
          background: ${({ theme }) => theme.colors.white};
          border: 2px solid ${({ theme }) => theme.colors.greyScale40};

          &::placeholder {
            color: ${({ theme }) => theme.colors.greyScale40};
          }
        }

        button {
          margin-top: 1.5em;
          height: 35px;
        }
      }
    }
    div.fourthLine {
      display: flex;
      button {
        margin-top: 0.5em;
        margin-left: 5.4em;
        height: 30px;
        width: 30%;
      }
    }
  }

  div.reloading {
    margin-top: 2em;
  }

  div.nofoundDiv {
    width: 100%;
    display: flex;
    justify-content: center;

    strong.nofound {
      text-align: center;
      margin: 64px 0;
      color: ${({ theme }) => theme.colors.greyScale40};
    }
  }

  div.pagination {
    margin-top: 1em;
  }

  @media (max-width: 360px) {
  }
`;

export const LoadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h4 {
    ${({ theme }) => theme.fontSizes.web.h4}
    text-align: center;
  }

  div {
    margin: ${({ theme }) => theme.space[4]};
  }
`;
