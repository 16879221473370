import { useCallback, useEffect, useReducer } from "react";
import {
  IGradeGroupUnitClasses,
  IStudentsByGradegroupResponse,
} from "interfaces/gradeGroupInterfaces";
import { formatClass } from "helpers/helpers";
import {
  getStudentsByGradegroup,
  getUnitClasses,
  getUserKanata,
  getUnitsGrades,
} from "services/grades.service";
import { useAtomValue } from "jotai";
import { authUserAtom } from "jotai/authUser";
import { toastError } from "components/Toast";
import { getStudentData } from "services/users.service";
import {
  IStudyItinerariesInternalState,
  StudyItinerariesInternalActionTypes,
  StudyItinerariesInternalActions,
} from "./types";

const INITIAL_STATE: IStudyItinerariesInternalState = {
  classes: undefined,
  students: undefined,
  units: undefined,
  filters: {
    selectedPeriod: 0,
    selectedGrade: 0,
    selectedClass: 0,
    selectedUnit: 0,
  },
  loaders: {
    isLoadingSelectedStudent: false,
    isLoadingClasses: false,
    isLoadingList: false,
  },
  drawerIsOpen: false,
  selectedStudent: undefined,
  currentStudent: undefined,
  currentKanataStudent: undefined,
};

const studyItinerariesInternalReducer = (
  state: IStudyItinerariesInternalState,
  action: StudyItinerariesInternalActions
): IStudyItinerariesInternalState => {
  switch (action.type) {
    case StudyItinerariesInternalActionTypes.SET_UNIT_LIST: {
      return {
        ...state,
        units: action.payload,
      };
    }

    case StudyItinerariesInternalActionTypes.SET_SELECTED_UNIT: {
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedUnit: action.payload,
        },
      };
    }

    case StudyItinerariesInternalActionTypes.SET_CLASSES: {
      return {
        ...state,
        classes: action.payload,
      };
    }

    case StudyItinerariesInternalActionTypes.SET_SELECTED_PERIOD: {
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedPeriod: action.payload,
        },
      };
    }

    case StudyItinerariesInternalActionTypes.SET_SELECTED_GRADE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedGrade: action.payload,
        },
      };
    }

    case StudyItinerariesInternalActionTypes.SET_SELECTED_CLASS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          selectedClass: action.payload,
        },
      };
    }

    case StudyItinerariesInternalActionTypes.SET_LOADING_CLASSES: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          isLoadingClasses: action.payload,
        },
      };
    }

    case StudyItinerariesInternalActionTypes.SET_LOADING_LIST: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          isLoadingList: action.payload,
        },
      };
    }

    case StudyItinerariesInternalActionTypes.SET_STUDENT_LIST: {
      return {
        ...state,
        students: action.payload,
      };
    }

    case StudyItinerariesInternalActionTypes.SET_LOADING_SELECTED_STUDENT: {
      return {
        ...state,
        loaders: {
          ...state.loaders,
          isLoadingSelectedStudent: action.payload,
        },
      };
    }

    case StudyItinerariesInternalActionTypes.SET_CURRENT_STUDENT: {
      return {
        ...state,
        currentStudent: action.payload,
      };
    }

    case StudyItinerariesInternalActionTypes.SET_CURRENT_KANATA_STUDENT: {
      return {
        ...state,
        currentKanataStudent: action.payload,
      };
    }

    case StudyItinerariesInternalActionTypes.SET_DRAWER_IS_OPEN: {
      return {
        ...state,
        drawerIsOpen: action.payload,
      };
    }

    case StudyItinerariesInternalActionTypes.SET_SELECTED_STUDENT: {
      return {
        ...state,
        selectedStudent: action.payload,
      };
    }
  }
};

export const useStudyItinerariesInternal = () => {
  const authUser = useAtomValue(authUserAtom);

  const [state, dispatch] = useReducer(
    studyItinerariesInternalReducer,
    INITIAL_STATE
  );

  const getUnitsList = async () => {
    try {
      const schoolUnits = await getUnitsGrades();
      if (schoolUnits?.data) {
        dispatch({
          type: StudyItinerariesInternalActionTypes.SET_UNIT_LIST,
          payload: schoolUnits.data,
        });
        dispatch({
          type: StudyItinerariesInternalActionTypes.SET_SELECTED_UNIT,
          payload: schoolUnits.data[0].id,
        });
      }
    } catch (error) {
      toastError("Erro ao buscar unidades");
    }
  };

  const formatClassesAndSetFilters = (classes: IGradeGroupUnitClasses[]) => {
    const formattedClassesWithoutEFI = formatClass(classes).filter(
      (item) => item.levelValue !== 1
    );

    if (formattedClassesWithoutEFI.length > 0) {
      const { levelValue, years } = formattedClassesWithoutEFI[0];

      dispatch({
        type: StudyItinerariesInternalActionTypes.SET_CLASSES,
        payload: formattedClassesWithoutEFI,
      });
      dispatch({
        type: StudyItinerariesInternalActionTypes.SET_SELECTED_PERIOD,
        payload: levelValue,
      });
      dispatch({
        type: StudyItinerariesInternalActionTypes.SET_SELECTED_GRADE,
        payload: years[0].id,
      });
      dispatch({
        type: StudyItinerariesInternalActionTypes.SET_SELECTED_CLASS,
        payload: years[0].sections[0].id,
      });
    } else {
      dispatch({
        type: StudyItinerariesInternalActionTypes.SET_CLASSES,
        payload: undefined,
      });
    }
  };

  const getClasses = useCallback(
    async (unitId: number) => {
      dispatch({
        type: StudyItinerariesInternalActionTypes.SET_LOADING_CLASSES,
        payload: true,
      });
      try {
        const response = await getUnitClasses({
          gradeUnitId: unitId,
          userId: authUser.id,
        });

        if (response?.data) {
          formatClassesAndSetFilters(response.data);
        }
      } catch (error) {
        toastError("Erro ao carregas turmas");
      } finally {
        dispatch({
          type: StudyItinerariesInternalActionTypes.SET_LOADING_CLASSES,
          payload: false,
        });
      }
    },
    [authUser]
  );

  const getStudentList = async (gradeGroupId: number) => {
    try {
      dispatch({
        type: StudyItinerariesInternalActionTypes.SET_LOADING_LIST,
        payload: true,
      });
      const response = await getStudentsByGradegroup(gradeGroupId);

      if (response?.data.length > 0) {
        dispatch({
          type: StudyItinerariesInternalActionTypes.SET_STUDENT_LIST,
          payload: response.data,
        });
      }
    } catch (error) {
      dispatch({
        type: StudyItinerariesInternalActionTypes.SET_STUDENT_LIST,
        payload: undefined,
      });
      toastError("Erro ao buscar estudantes");
    } finally {
      dispatch({
        type: StudyItinerariesInternalActionTypes.SET_LOADING_LIST,
        payload: false,
      });
    }
  };

  const getIndividualKanataStudentData = async (studentId: number) => {
    try {
      const response = await getUserKanata(studentId);
      if (response) {
        return response;
      }
    } catch (error) {
      toastError("Erro ao buscar dados do estudante");
    }
  };

  const getIndividualStudentData = useCallback(
    async (givenStudent: IStudentsByGradegroupResponse) => {
      dispatch({
        type: StudyItinerariesInternalActionTypes.SET_LOADING_SELECTED_STUDENT,
        payload: true,
      });
      try {
        const response = await getStudentData({
          id: givenStudent.student.kanata_id,
        });
        const responseKanata = await getIndividualKanataStudentData(
          givenStudent.student.kanata_id
        );
        if (response?.data && responseKanata) {
          dispatch({
            type: StudyItinerariesInternalActionTypes.SET_CURRENT_STUDENT,
            payload: response.data,
          });
          dispatch({
            type: StudyItinerariesInternalActionTypes.SET_CURRENT_KANATA_STUDENT,
            payload: responseKanata,
          });
        }
      } catch (error) {
        toastError("Erro ao buscar dados do estudante");
      } finally {
        dispatch({
          type: StudyItinerariesInternalActionTypes.SET_LOADING_SELECTED_STUDENT,
          payload: false,
        });
      }
    },
    []
  );

  useEffect(() => {
    getUnitsList();
  }, []);

  useEffect(() => {
    if (state?.units && state?.filters.selectedUnit) {
      getClasses(state?.filters.selectedUnit);
    }

    if (authUser?.grade_unit_id) {
      getClasses(authUser.grade_unit_id);
    }
  }, [authUser, getClasses, state?.filters.selectedUnit, state?.units]);

  useEffect(() => {
    if (state?.filters.selectedClass) {
      getStudentList(state?.filters.selectedClass);
    }
  }, [state?.filters.selectedClass]);

  useEffect(() => {
    if (state?.selectedStudent) {
      getIndividualStudentData(state?.selectedStudent);
    }
  }, [getIndividualStudentData, state?.selectedStudent]);

  return { state, dispatch };
};
