import styled from "styled-components";

export const FoodPackageStyled = styled.div`
  font-family: "Poppins";
  padding: 1em;

  h1.subtitle {
    span {
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  div.cards {
    width: 100%;
    margin-top: 1.5em;

    display: flex;
    flex-direction: row;
    align-items: baseline;
    flex-wrap: wrap;
  }
`;
