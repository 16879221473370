// React
import { useState } from "react";
// Styles
import * as S from "./style";
// Components
import { Button, CheckboxInput, Loading } from "components/atoms";
import { GenerateBadgeFileTable } from "./components/Table";
// Helpers
import { GenerateBadgeFileHelper } from "./helper";
// Interfaces
import { IForm } from "./interface";
// Libs
import { useTranslation } from "react-i18next";
import { SecondPagination } from "components";
// Img
import loadingGif from "static/img/loading.gif";
// Enum
import { EnrollmentTypes } from "enums/enrollment";

export const GenerateBadgeFile = () => {
  const { t } = useTranslation();
  const prefix = "pages.EDF.badgeFiles";

  const [form, setForm] = useState<IForm>({
    exported: true,
    unit: 1,
    year: 2023,
    type: 1,
    studentName: "",
    page: 1,
    pageSize: 10,
    firstStudent: 1,
    lastStudent: 1,
    enrollmentType: 0,
  });

  const {
    loading,
    error,
    initialProps,
    studentList,
    types,
    studentsPerPage,
    reloading,
    secondForm,
    setSecondForm,
    allStudentsOnPage,
    download,
    Offset,
    loadingFiles,
    badge,
  } = GenerateBadgeFileHelper({ form, setForm });

  if (loading) {
    return (
      <S.LoadContainer>
        <h4>{t(`${prefix}.loading`)}</h4>
        <Loading />
      </S.LoadContainer>
    );
  }

  if (error && !loading) {
    return (
      <S.LoadContainer>
        <div>{t(`${prefix}.error`)}</div>
      </S.LoadContainer>
    );
  }

  if (initialProps.units && initialProps.years) {
    return (
      <S.GenerateBadgeFileStyle>
        <h1>{t(`${prefix}.title`)}</h1>
        <h3>{t(`${prefix}.subTitle`)}</h3>
        <form>
          <div className="checkbox">
            <div>
              <CheckboxInput
                value={`${form.exported}`}
                name="exported"
                onChange={(e) => {
                  setForm({
                    ...form,
                    exported: form.exported ? false : true,
                  });
                }}
              />
            </div>
            <label className="text">{t(`${prefix}.exported`)}</label>
          </div>
          <div className="secondLine">
            <div>
              <label>{t(`${prefix}.unit`)}</label>
              <select
                onChange={(e) => {
                  setForm({ ...form, unit: parseInt(e.target.value) });
                }}
                required
                name="unit"
                value={form.unit}
                className="hasValue"
              >
                {initialProps.units.map((unit, index) => {
                  return (
                    <option value={unit.id} key={index}>
                      {unit.name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <label>{t(`${prefix}.year`)}</label>
              <select
                onChange={(e) => {
                  setForm({ ...form, year: parseInt(e.target.value) });
                }}
                required
                name="year"
                value={form.year}
                className="hasValue"
              >
                {initialProps.years.results.map((year, index) => {
                  return (
                    <option value={year.value} key={index}>
                      {year.label}
                    </option>
                  );
                })}
              </select>
            </div>
            <div>
              <label>{t(`${prefix}.type`)}</label>
              <select
                onChange={(e) => {
                  setForm({ ...form, type: parseInt(e.target.value) });
                }}
                required
                name="type"
                value={form.type}
                className="hasValue"
              >
                {types.map((type, index) => {
                  return (
                    <option value={type.id} key={index}>
                      {type.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="secondLine thirdLine">
            <div>
              <label>{t(`${prefix}.enrollmentType`)}</label>
              <select
                onChange={(e) => {
                  setForm({
                    ...form,
                    enrollmentType: parseInt(e.target.value),
                  });
                }}
                required
                name="enrollmentType"
                value={form.enrollmentType}
                className="hasValue"
              >
                <option value={EnrollmentTypes.EnrollmentReenrollment}>
                  {t(`${prefix}.enrollmentAndReenrollment`)}
                </option>
                <option value={EnrollmentTypes.Enrollment}>
                  {t(`${prefix}.enrollment`)}
                </option>
                <option value={EnrollmentTypes.Reenrollment}>
                  {t(`${prefix}.reenrollment`)}
                </option>
              </select>
            </div>
            <div>
              <label>{t(`${prefix}.studentsPerPage`)}</label>
              <select
                onChange={(e) => {
                  setForm({
                    ...form,
                    pageSize: parseInt(e.target.value),
                  });
                }}
                required
                name="perPage"
                value={form.pageSize}
                className="hasValue"
              >
                {studentsPerPage.map((students, index) => {
                  return (
                    <option value={students.value} key={index}>
                      {students.label}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="studentName">
              <label>{t(`${prefix}.studentName`)}</label>
              <input
                onChange={(e) => {
                  setForm({ ...form, studentName: e.target.value });
                }}
                type="text"
                id="name"
                placeholder={t(`${prefix}.studentNamePlaceholder`)}
                name="name"
              />
            </div>
            <div>
              <Button
                disabled={reloading}
                type="button"
                onClick={() => {
                  badge();
                }}
              >
                {t(`${prefix}.firstButton`)}
              </Button>
            </div>
          </div>

          {studentList && studentList.results.length > 0 ? (
            <div className="fourthLine">
              <div className="checkbox">
                <div>
                  <CheckboxInput
                    value={`${secondForm.allStudentsOnThePage}`}
                    name="all_Student"
                    onChange={() => {
                      allStudentsOnPage();
                    }}
                  />
                </div>
                <label className="text">
                  {t(`${prefix}.allStudentsPerPage`)}
                </label>
              </div>
              <div className="checkbox">
                <div>
                  <CheckboxInput
                    value={`${secondForm.selectedAll}`}
                    name="selected_all"
                    checked={secondForm.selectedAll}
                    onChange={(e) => {
                      setSecondForm({
                        ...secondForm,
                        selectedAll: secondForm.selectedAll ? false : true,
                      });
                    }}
                  />
                </div>
                <label className="text">{t(`${prefix}.allStudents`)}</label>
              </div>
              <Button
                disabled={loadingFiles}
                type="button"
                onClick={() => {
                  download();
                }}
              >
                {!loadingFiles && t(`${prefix}.button`)}
                {loadingFiles && (
                  <img height="25" src={loadingGif} alt="loading" />
                )}
              </Button>
            </div>
          ) : null}
        </form>

        {studentList && (
          <>
            {studentList.results.length > 0 ? (
              <>
                <GenerateBadgeFileTable
                  studentList={studentList}
                  secondForm={secondForm}
                  setSecondForm={setSecondForm}
                  allStudents={secondForm.selectedAll}
                />
                <div className="pagination">
                  {studentList.count > 10 ? (
                    <SecondPagination
                      previous={studentList.previous!}
                      count={studentList.count}
                      next={studentList.next!}
                      page={form.page}
                      setPage={setForm}
                      Offset={Offset}
                      firstStudent={form.firstStudent}
                      lastStudent={form.lastStudent}
                    />
                  ) : null}
                </div>
              </>
            ) : (
              <div className="nofoundDiv">
                <strong className="nofound">
                  {t(`${prefix}.noStudentsFound`)}
                </strong>
              </div>
            )}
          </>
        )}

        {reloading && (
          <div className="reloading">
            <Loading />
          </div>
        )}
      </S.GenerateBadgeFileStyle>
    );
  }
};
