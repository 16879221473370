import styled from "styled-components";
import * as colors from "styles/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 636px;
  margin: 0 auto;

  padding: 1em;

  & > div {
    max-width: 636px;
    width: 100%;
  }

  .studentDataHeader {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    span {
      margin-top: 8px;
      margin-bottom: 4px;
    }
  }

  form {
    width: 100%;
    margin-top: 16px;

    label {
      display: flex;
      flex-direction: column;
      text-transform: uppercase;
      font-size: 12px;

      input {
        width: 100%;
        padding: 8px 8px;
        border: none;
        border-bottom: 2px solid ${colors.GRAY_SCALE};
        font-size: 14px;
        line-height: 21px;
        color: ${colors.NEW_GRAY_SCALE};
        outline: none;
      }
    }

    .birthdate {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }
  }

  .fields {
    display: flex;
    gap: 15px;
    label {
      flex: 1;
    }
  }

  .buttons {
    width: 100%;
    margin-top: 24px;
    display: flex;
    gap: 16px;
  }
`;
