import styled from "styled-components";

export const DrawerRegistrationSalesPeriodStyled = styled.div`
  width: 100%;
  padding: 34px ${({ theme }) => theme.space[4]}; // TODO: 34 nao tem na lib
`;

export const PeriodInput = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.space[10]};

  display: flex;
  flex-direction: column;

  h4 {
    font-size: ${({ theme }) => theme.fontSizes.web.h4};
    line-height: 30px; // TODO: nao tem na lib
    font-weight: 600; // TODO: nao tem na lib
  }

  div {
    display: flex;
    gap: 0 ${({ theme }) => theme.space[4]};

    label {
      text-transform: uppercase;
    }
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;

  margin-top: auto;

  display: flex;
  justify-content: end;
  gap: 0 ${({ theme }) => theme.space[4]};

  position: absolute;
  bottom: ${({ theme }) => theme.space[6]};
  right: ${({ theme }) => theme.space[4]};
`;
