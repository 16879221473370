import { Drawer } from "components/organisms/Drawer";
import { CALENDAR_2023_DOCS } from "lib/external-links";
import { Container, Note, StudentInfo } from "./styles";
import { mdiOpenInNew } from "@mdi/js";
import Icon from "@mdi/react";
import { useStudyItineraries } from "pages/AP/StudyItineraries/StudyItineraries.helper";
import { ItinerariesGroup } from "components/organisms/ItinerariesGroup";
import { StudentDataInterface } from "interfaces/constants";
import { GetUserKanataResponse } from "services/grades.service";
import placeholderPng from "static/img/placeholder.png";
import { useTranslation } from "react-i18next";

interface IStudyItinerariesInternalDrawerProps {
  open: boolean;
  onClose: () => void;
  userStudent: StudentDataInterface;
  userKanata: GetUserKanataResponse;
}

export const StudyItinerariesInternalDrawer = ({
  open,
  onClose,
  userStudent,
  userKanata,
}: IStudyItinerariesInternalDrawerProps) => {
  const { t } = useTranslation();
  const prefix =
    "pages.AP.studyItineraryInternal.studyItinerariesInternalDrawer";

  const {
    studyItinerary,
    overdueItineraries,
    isToday,
    fetchLastWeek,
    fetchNextWeek,
    fetchCurrentWeek,
    isFetching,
  } = useStudyItineraries({
    userStudent,
    userKanata,
  });

  const handleCloseDrawer = () => {
    onClose();
  };

  return (
    <Drawer
      isOpen={open}
      onClose={handleCloseDrawer}
      title={userStudent?.personal?.name}
      side="right"
    >
      <Container>
        <>
          <Note>
            <p>{t(`${prefix}.GuardianView`)}</p>
          </Note>
          <StudentInfo>
            <img
              src={userStudent.personal.photo_url ?? placeholderPng}
              alt={userStudent.personal.name}
            />

            <div className="details">
              <strong>{userStudent.personal.name}</strong>
              <p>
                {userStudent.enrollment[0].grade}º Ano -{" "}
                <span>Turma {userStudent.enrollment[0].section}</span>
              </p>
              <a href={`${CALENDAR_2023_DOCS}`}>
                <Icon path={mdiOpenInNew} size={1} />
                {t(`${prefix}.schoolCalendar`)}
              </a>
            </div>
          </StudentInfo>
          <div className="details-note">
            <p>{t(`${prefix}.note`)}</p>
          </div>
          <ItinerariesGroup
            type="current"
            itinerary={studyItinerary}
            isToday={isToday}
            onFetchLastWeek={fetchLastWeek}
            onFetchNextWeek={fetchNextWeek}
            onFetchCurrentWeek={fetchCurrentWeek}
            isFetching={isFetching}
          />
          {overdueItineraries && (
            <div className="details-note">
              <p>{t(`${prefix}.delayedItineraries`)}</p>
            </div>
          )}

          {overdueItineraries?.map((ov, index) => {
            return ov?.components && ov?.components?.length ? (
              <ItinerariesGroup
                key={`${index}-${ov.start_weekday}-${ov.end_weekday}`}
                type="past"
                itinerary={ov}
              />
            ) : null;
          })}
        </>
      </Container>
    </Drawer>
  );
};
