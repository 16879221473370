import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > div {
    width: 100%;
    max-width: 720px;
  }

  .errorWarning {
    width: 100%;

    display: flex;
    justify-content: center;

    margin-bottom: 20px;

    @media (max-width: 720px) {
      width: 100%;
    }
  }

  strong {
    margin-top: ${({ theme }) => theme.space[4]};
  }

  form {
    width: 100%;
    margin-top: 8px;
  }

  .paymentOption {
    display: flex;
    flex-direction: column;
    align-items: left;

    & > div {
      margin-left: 8px;
    }

    span {
      margin-top: 0;
    }
  }

  .btn-group {
    margin-top: 30px;
    display: flex;
    gap: 16px;
    margin-bottom: ${({ theme }) => theme.space[8]};
  }

  @media (max-width: 1024px) {
    margin: 0 16px;
  }
`;

export const LoadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h4 {
    text-align: center;
  }
`;
