import styled from "styled-components";
import { BLUE, PINK, BLACK } from "styles/colors";

export const DataConfirmation = styled.div`
  div.enrollment-title {
    font-size: 20px;
    font-weight: bold;
    color: #ed008c;

    div.subtitle {
      font-weight: 500;
      font-size: 16px;
      color: black;
    }
  }
  div.subdiv {
    display: flex;
  }
`;

export const DataBigBox = styled.div`
  border-radius: 13px;
  border-style: solid;
  border-width: 1px;
  border-color: ${BLUE};
  padding: 1em;
  color: ${BLACK};

  .info {
    font-weight: 600;
  }
`;

export const BoldBiggerBlue = styled.p`
  font-weight: bold;
  font-size: 32px;
  text-align: center;
  color: ${BLUE};
`;

export const DataBox = styled.div`
  border-width: 2px;
  border-radius: 28px;
`;

export const BoldBiggerPink = styled.p`
  font-weight: bold;
  font-size: 24px;
  color: ${PINK};
`;

export const BoldBigger = styled.p`
  font-weight: bold;
  font-size: 18px;
  color: ${BLACK};
`;
