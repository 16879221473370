import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  @media (max-width: 1024px) {
    margin: 0 16px;
  }

  div:last-child {
    max-width: 540px;
  }
`;
