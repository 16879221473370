// Styles
import * as Style from "./style";
// Components
import { EnrollmentStepCircle } from "components";
import { Button, CheckboxInput } from "components/atoms";
// Helpers
import { UF_HELPER } from "helpers/constants";
// Libs
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { default as formatString } from "format-string-by-pattern";
import moment from "moment";
// Interface
import { IConfirmDataType } from "./interface";
import { HelperGuardianEdit } from "./helper";

export const GuardianDataEditing = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { state } = useLocation<IConfirmDataType>();
  const TRANSLATE = "pages.EDF.guardianDataEditing";

  const { form, setForm, handleSubmit } = HelperGuardianEdit({
    state,
  });

  return (
    <Style.GuardianDataEditingStyled>
      <EnrollmentStepCircle
        studentName={state.enrollment.guard.student.nome}
        nextStep={state.enrollment.steps[1].name}
        step={1}
        totalSteps={7}
        currentStepName={state.enrollment.steps[0].name}
        subtitle={t(`${TRANSLATE}.enrollment`, { year: state.enrollment.year })}
      />
      <div className="texts">
        <h4>{t(`${TRANSLATE}.personalData`)}:</h4>
        <p>*{t(`${TRANSLATE}.requiredFields`)}</p>
      </div>

      <form>
        <div className="form">
          <div className="input extraLarge">
            <label>{t(`${TRANSLATE}.inputNameLabel`)}</label>
            <input
              type="text"
              id="name"
              placeholder={t(`${TRANSLATE}.inputNamePlaceholder`)}
              name="name"
              onChange={(e) => {
                setForm({ ...form, guardian_name: e.target.value });
              }}
              value={form.guardian_name}
              required
            />
          </div>
        </div>

        <div className="form">
          <div className="input small">
            <label>{t(`${TRANSLATE}.inputBirthDateLabel`)}</label>
            <input
              type="date"
              id="bith-date"
              placeholder={t(`${TRANSLATE}.inputBirthDatePlaceholder`)}
              name="bith-date"
              onChange={(e) => {
                setForm({
                  ...form,
                  birth_date: moment(e.target.value).format("YYYY-MM-DD"),
                });
              }}
              value={form.birth_date}
              required
            />
          </div>
        </div>

        <div className="form">
          <div className="input small">
            <label>{t(`${TRANSLATE}.inputRgLabel`)}</label>
            <input
              type="text"
              id="rg"
              placeholder="00.000.000-0"
              name="rg"
              onChange={(e) => {
                setForm({
                  ...form,
                  rg: formatString("99.999.999-9", e.target.value),
                });
              }}
              value={form.rg}
              required
            />
          </div>

          <div className="input small">
            <label>{t(`${TRANSLATE}.inputCpfLabel`)}</label>
            <input
              type="text"
              id="cpf"
              placeholder="000.000.000-00"
              name="cpf"
              onChange={(e) => {
                setForm({
                  ...form,
                  cpf: formatString("999.999.999-99", e.target.value),
                });
              }}
              value={form.cpf}
              required
            />
          </div>
        </div>

        <div className="form bordeBottom">
          <div className="input large">
            <label>{t(`${TRANSLATE}.inputEmailLabel`)}</label>
            <input
              type="text"
              id="email"
              placeholder={t(`${TRANSLATE}.inputEmailPlaceholder`)}
              name="email"
              value={form.email}
              onChange={(e) => {
                setForm({
                  ...form,
                  email: e.target.value,
                });
              }}
              required
            />
          </div>

          <div className="input small">
            <label>{t(`${TRANSLATE}.inputPhoneLabel`)}</label>
            <input
              type="text"
              id="phone"
              placeholder="(00) 00000-0000"
              name="phone"
              value={form.phone}
              onChange={(e) => {
                setForm({
                  ...form,
                  phone: e.target.value,
                });
              }}
              required
            />
          </div>
        </div>

        <div className="texts">
          <h4>{t(`${TRANSLATE}.address`)}</h4>
        </div>
        <div className="checkbox">
          <CheckboxInput
            value="lives_with_student"
            name="lives_with_student"
            defaultChecked={form.lives_with_student}
            onChange={(e) => {
              setForm({
                ...form,
                lives_with_student: form.lives_with_student ? false : true,
              });
            }}
          />
          <p>{t(`${TRANSLATE}.livesWithStudent`)}</p>
        </div>

        <div className="form">
          <div className="input small">
            <label>{t(`${TRANSLATE}.inputCepLabel`)}</label>
            <input
              type="text"
              id="cep"
              placeholder="00000-000"
              name="cep"
              value={form.cep}
              onChange={(e) => {
                setForm({
                  ...form,
                  cep: formatString("99999-999", e.target.value),
                });
              }}
              required
            />
          </div>
        </div>

        <div className="form">
          <div className="input large address">
            <label>{t(`${TRANSLATE}.inputAddressLabel`)}</label>
            <input
              type="text"
              id="address"
              placeholder={t(`${TRANSLATE}.inputAddressPlaceholder`)}
              name="address"
              value={form.address}
              onChange={(e) => {
                setForm({
                  ...form,
                  address: e.target.value,
                });
              }}
              required
            />
          </div>

          <div className="input extraSmall">
            <label>{t(`${TRANSLATE}.inputAddressNumberLabel`)}</label>
            <input
              type="text"
              id="address_number"
              placeholder="0000"
              name="address_number"
              value={form.address_number}
              onChange={(e) => {
                setForm({
                  ...form,
                  address_number: e.target.value,
                });
              }}
              required
            />
          </div>

          <div className="input small">
            <label>{t(`${TRANSLATE}.inputNeighborhoodLabel`)}</label>
            <input
              type="text"
              id="neighborhood"
              placeholder={t(`${TRANSLATE}.inputNeighborhoodPlaceholder`)}
              name="neighborhood"
              value={form.neighborhood}
              onChange={(e) => {
                setForm({
                  ...form,
                  neighborhood: e.target.value,
                });
              }}
              required
            />
          </div>
          <div className="input medium">
            <label>{t(`${TRANSLATE}.inputAddressComplementLabel`)}</label>
            <input
              type="text"
              id="address_complement"
              placeholder={t(`${TRANSLATE}.inputAddressComplementPlaceholder`)}
              name="address_complement"
              value={form.address_complement}
              onChange={(e) => {
                setForm({
                  ...form,
                  address_complement: e.target.value,
                });
              }}
            />
          </div>
          <div className="input small">
            <label>{t(`${TRANSLATE}.inputCityLabel`)}</label>
            <input
              type="text"
              id="city"
              placeholder={t(`${TRANSLATE}.inputCityPlaceholder`)}
              name="city"
              value={form.city}
              onChange={(e) => {
                setForm({
                  ...form,
                  city: e.target.value,
                });
              }}
              required
            />
          </div>
          <div className="input small">
            <label>{t(`${TRANSLATE}.inputUfLabel`)}</label>
            <select
              required
              name="uf"
              value={form.uf}
              onChange={(e) => {
                setForm({
                  ...form,
                  uf: e.target.value,
                });
              }}
            >
              {UF_HELPER.map((uf) => {
                return (
                  <option value={uf.value} key={uf.id}>
                    {uf.value}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <div className="buttons">
          <Button
            outline
            type="button"
            onClick={() => history.goBack()}
            width="47%"
          >
            {t(`${TRANSLATE}.buttonGoBack`)}
          </Button>
          <Button type="button" onClick={() => handleSubmit()} width="47%">
            {t(`${TRANSLATE}.buttonAdvance`)}
          </Button>
        </div>
      </form>
    </Style.GuardianDataEditingStyled>
  );
};
