import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Loading } from "components";
import * as S from "./styles";
import { ILocationFoodPage } from "../../EDF/FoodPage/interface";
import { StudentsList } from "./components/StudentsList";

export const BoletoSegundaVia = () => {
  const { state } = useLocation<ILocationFoodPage>();

  const { t } = useTranslation();
  const i18_prefix = "pages.AP.boletoSegundaVia";

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (state.user) {
      setLoading(false);
    } else {
      setLoading(false);
      setError(true);
    }
  }, [state]);

  return (
    <S.Container>
      {loading && <Loading />}
      {error && !loading && <div>{t(`${i18_prefix}.errorText`)}</div>}
      <S.Card className="mb-5">
        {!error && !loading && (
          <div style={{ width: "100%", padding: "1px" }}>
            <S.TituloContainer>{t(`${i18_prefix}.title`)}</S.TituloContainer>
            <S.SubtituloContainer>
              {t(`${i18_prefix}.subtitle`)}
            </S.SubtituloContainer>
            <S.Lista>
              <StudentsList students={state.user.students} />
            </S.Lista>
          </div>
        )}
      </S.Card>
    </S.Container>
  );
};
