import { AuthUserInterface, RoleInterface } from "interfaces/constants";
import { StorageKeysEnum } from "./keys";
import { IStorage } from "./types";
import { NullRoleObject } from "models";

export type TokenType = {
  refresh?: string;
  access: string;
  expiration: string;
};

export const Storage: IStorage = {
  token: {
    set: (tokenData: TokenType) => {
      localStorage.setItem(
        StorageKeysEnum.TOKEN_STORAGE_KEY,
        JSON.stringify(tokenData)
      );
    },
    get: () => {
      return localStorage.getItem(StorageKeysEnum.TOKEN_STORAGE_KEY)
        ? JSON.parse(
            localStorage.getItem(StorageKeysEnum.TOKEN_STORAGE_KEY) as string
          )
        : null;
    },
  },
  props: {
    role: {
      set: (roleData: RoleInterface) => {
        localStorage.setItem(
          StorageKeysEnum.ROLE_STORAGE_KEY,
          JSON.stringify(roleData)
        );
      },
      get: () => {
        return localStorage.getItem(StorageKeysEnum.ROLE_STORAGE_KEY)
          ? JSON.parse(
              localStorage.getItem(StorageKeysEnum.ROLE_STORAGE_KEY) as string
            )
          : NullRoleObject;
      },
    },
    authUser: {
      set: (authData: AuthUserInterface) => {
        localStorage.setItem(
          StorageKeysEnum.AUTH_USER_STORAGE_KEY,
          JSON.stringify(authData)
        );
      },
      get: () => {
        return localStorage.getItem(StorageKeysEnum.AUTH_USER_STORAGE_KEY)
          ? JSON.parse(
              localStorage.getItem(
                StorageKeysEnum.AUTH_USER_STORAGE_KEY
              ) as string
            )
          : null;
      },
    },
  },
  remove: () => {
    localStorage.clear();
  },
};
