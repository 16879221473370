import React from "react";
import { AppVersionContainer, Version } from "./styles";
import packagejs from "../../../package.json";

export const AppVersion: React.FC = () => {
  const dateVersion = new Date(packagejs.buildDate).toLocaleString();
  return (
    <AppVersionContainer>
      <Version title={dateVersion}>v.{packagejs.buildDate}</Version>
    </AppVersionContainer>
  );
};
