import styled from "styled-components";

export const Container = styled.form`
  padding: ${({ theme }) => theme.space[4]};
  margin-top: ${({ theme }) => theme.space[4]};

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[4]};

  div {
    p {
      margin-bottom: ${({ theme }) => theme.space[2]};
    }

    label {
      display: flex;
      flex-direction: column;

      text-transform: uppercase;

      input {
        padding: ${({ theme }) => theme.space[2]};
        font-size: 14px;
        max-width: 248px;
        margin-top: ${({ theme }) => theme.space[1]};
      }
    }
  }

  button {
    width: fit-content;
    padding: ${({ theme }) => theme.space[4]} ${({ theme }) => theme.space[8]};
    margin-top: 100%;
    margin-left: auto;
    border: none;
    font-size: ${({ theme }) => theme.fontSizes.web.sm};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.primaryDefault};

    &:disabled {
      filter: brightness(0.5);
    }
  }
`;
