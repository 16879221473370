import styled, { css } from "styled-components";
import { IIcons } from "./interface";

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;

  gap: 0 32.29px; // nao tem na lib
  margin-left: auto;
  color: ${({ theme }) => theme.colors.greyScale40};

  font-weight: ${({ theme }) => theme.fontWeights.regular};
  font-size: ${({ theme }) => theme.fontSizes.web.sm};
`;

export const Icons = styled.div`
  display: flex;
  gap: 0 40.59px; // nao tem na lib
  color: ${({ theme }) => theme.colors.primaryDefault};

  button {
    border: none;
    background-color: transparent;
    :disabled {
      cursor: not-allowed;
    }
  }
`;

export const Img = styled.img<IIcons>`
  ${({ enabled }) =>
    enabled &&
    css`
      filter: invert(16%) sepia(100%) saturate(3797%) hue-rotate(262deg)
        brightness(87%) contrast(95%);
    `}
`;
