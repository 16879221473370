// React
import { useCallback } from "react";
// Styles
import * as S from "./style";
// Components
import { FloatingMenu } from "components";
// Libs
import { useTranslation } from "react-i18next";
import { Tag } from "@escolamais-ui-v2/react";
// Interfaces
import { IGuardianExitCard } from "./interface";
// Helpers
import { formatCPF, formatPhone } from "helpers/helpers";

export enum ExitPermitTypes {
  edit = 1,
  remove = 2,
}

export const GuardianExitCard = ({
  guardian,
  escort,
  form,
  remove,
  setModal,
  setForm,
  setModalOption,
}: IGuardianExitCard) => {
  const { t } = useTranslation();
  const prefix =
    "pages.EDF.exitPermit.components.exitPermitScreen.components.guardianExitCard";

  const options = [
    { label: "Editar", value: 1 },
    { label: "Remover", value: 2 },
  ];

  const setModalProps = useCallback(() => {
    setModal(true);
    setModalOption(2); // edicao para o modalOptions
  }, [setModal, setModalOption]);

  return (
    <S.GuardianExitCardStyled>
      <div className="infos">
        <p className="escortName">
          {guardian ? guardian.nome_completo : escort?.nome_completo}

          {escort && <p>({escort.grau_parentesco})</p>}
        </p>
        {guardian && (
          // <Tag appearance="primaryOutline" fontSize="xsm" size="medium">
          //   {guardian.tipo}
          // </Tag>
          <label>{guardian.tipo}</label>
        )}
        {escort && (
          <FloatingMenu
            open
            options={options}
            vertical
            marginLeft="-190px"
            onChangeOption={(e) => {
              setForm({
                ...form,
                name: escort.nome_completo,
                kinship: escort.grau_parentesco,
                cpf: escort.cpf,
                phone: escort.telefone,
                escort: escort.id,
              });
              e === ExitPermitTypes.edit ? setModalProps() : remove(escort.id);
            }}
          />
        )}
      </div>
      <div className="infosPtTwo">
        <div>
          <p className="infoName">{t(`${prefix}.cpf`)}</p>
          <p>
            {guardian
              ? formatCPF(guardian.cpf)
              : escort
              ? formatCPF(escort.cpf)
              : ""}
          </p>
        </div>
        <div>
          <p className="infoName">{t(`${prefix}.phone`)}</p>
          <p>
            {guardian
              ? formatPhone(guardian.telefone)
              : escort
              ? formatPhone(escort.telefone)
              : ""}
          </p>
        </div>
      </div>
    </S.GuardianExitCardStyled>
  );
};
