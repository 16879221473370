import styled from "styled-components";
import * as colors from "styles/colors";

export const ExitReportStyled = styled.div`
  div.body {
    padding: 0 ${({ theme }) => theme.space[6]};

    h1 {
      margin-top: ${({ theme }) => theme.space[8]};
      font-size: 32px;
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      color: ${({ theme }) => theme.colors.primaryDefault};
      line-height: ${({ theme }) => theme.lineHeights};
    }

    form {
      display: flex;
      align-items: center;
      gap: ${({ theme }) => theme.space[4]};

      // Component input
      div.inputText {
        margin-top: 1rem;
        div {
          input {
            font-size: ${({ theme }) => theme.fontSizes.web.sm};
            ::placeholder {
              font-size: ${({ theme }) => theme.fontSizes.web.sm};
            }
          }
        }
      }

      div.select {
        margin-top: ${({ theme }) => theme.space[5]};
        display: flex;
        flex-direction: column;

        width: 100%;

        label {
          float: left;
          font-weight: ${({ theme }) => theme.fontWeights.regular};
          font-size: ${({ theme }) => theme.fontSizes.web.sm};
          line-height: ${({ theme }) => theme.lineHeights.normal};
          color: ${colors.NEW_GRAY_SCALE}; // Não possui na lib
          margin-bottom: ${({ theme }) => theme.space[3]};
        }

        select {
          margin-top: -5px;
          box-sizing: border-box;
          background: ${({ theme }) => theme.colors.white};
          border: 2px solid ${({ theme }) => theme.colors.greyScale40};

          color: ${({ theme }) => theme.colors.greyScale40};
          height: 37.7px;
          padding-left: ${({ theme }) => theme.space[2]};
          font-size: ${({ theme }) => theme.fontSizes.web.sm};

          cursor: pointer;

          &.hasValue {
            color: ${colors.NEW_GRAY_SCALE}; // Não possui na lib
          }
        }
      }
    }
    div.button {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      span.buttonName {
        border-bottom: 2px solid;
        height: 20px;
        margin-bottom: 3px;
      }
    }

    div.newSearch {
      margin-top: ${({ theme }) => theme.space[4]};
      display: flex;
      justify-content: center;
    }

    div.pagination {
      display: flex;
      justify-content: flex-end;
      margin-bottom: ${({ theme }) => theme.space[4]};
    }
  }
`;

export const LoadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h4 {
    ${({ theme }) => theme.fontSizes.web.h4}
    text-align: center;
  }

  div {
    margin: ${({ theme }) => theme.space[4]};
  }
`;
