import { IButtonGroup } from "./interface";
import * as S from "./style";

// TODO: enviar para a lib
export const ButtonGroup = <T extends unknown>({
  options,
  value,
  setValue,
  icon,
  buttonColor = "#FFFFFF",
  labelColor = "#6D28D9",
  backgroundColor = "#f2f2f2",
  width = 960,
  buttonWidth = 224,
}: IButtonGroup<T>) => {
  return (
    <S.ButtonGroupContainer width={width} backgroundColor={backgroundColor}>
      {options.map((option, index) => {
        return (
          <S.Button
            key={index}
            activated={option.value === value}
            buttonColor={buttonColor}
            labelColor={labelColor}
            buttonWidth={buttonWidth}
            type="button"
            onClick={() => setValue(option.value)}
          >
            {icon && <S.Icon src={icon} alt="icon" />}
            <label>{option.label && option.label}</label>
          </S.Button>
        );
      })}
    </S.ButtonGroupContainer>
  );
};
