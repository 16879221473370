import { Tag } from "@escolamais-ui-v2/react";
import { concatenateEllipsis } from "helpers/helpers";
import React, { useState } from "react";
import {
  ArrowDropDown as SortDescIcon,
  ArrowDropUp as SortAscIcon,
} from "@escolamais-ui-v2/icons";
import {
  AprovacaoPreviaActions,
  AprovacaoPreviaState,
  EnumReleaseStatusTypes,
  Student,
} from "../../types";
import { Loading } from "components";
import { StudentDataTable } from "context/Bulletin/types";
import CheckIcon from "components/Icons/CheckIcon";
import { theme } from "@escolamais-ui-v2/tokens/src";
import { StyledTableData, StyledTableHeader, StyledTableRow } from "./styles";
import { useTranslation } from "react-i18next";

const sortIconProps = {
  width: 24,
  height: 24,
  fill: "#000",
  viewBox: "0 0 48 48",
  cursor: "pointer",
};

interface AprovacaoPreviaTableProps {
  state: AprovacaoPreviaState;
  handleSelectedStudent: (student: StudentDataTable) => void;
}

const TRANSLATION_PREFIX = "pages.AP.aprovacaoPrevia.aprovacaoPreviaTable";

export const AprovacaoPreviaTable: React.FC<AprovacaoPreviaTableProps> = ({
  handleSelectedStudent,
  state: {
    dataTable,
    loadings: { loadingDataTable },
  },
}) => {
  const { t } = useTranslation();

  const getReleaseValue = (student: Student, release: string): boolean => {
    const found = student.release_check.find((rc) => rc.name === release);
    return found ? found.value : false;
  };

  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  const handleSort = () => {
    if (sortDirection === "asc") {
      setSortDirection("desc");
    } else {
      setSortDirection("asc");
    }

    if (dataTable.students) {
      dataTable.students.reverse();
    }
  };

  if (loadingDataTable) {
    return <Loading />;
  }

  return (
    <>
      <table>
        <thead>
          <tr>
            <StyledTableHeader>
              {t(`${TRANSLATION_PREFIX}.students`)}
              <span onClick={handleSort}>
                {sortDirection === "asc" ? (
                  <SortAscIcon {...sortIconProps} />
                ) : (
                  <SortDescIcon {...sortIconProps} />
                )}
              </span>
            </StyledTableHeader>
            {dataTable.availableReleases?.map((release, index) => (
              <StyledTableHeader textAlign="center" key={index}>
                {release}
              </StyledTableHeader>
            ))}
            <StyledTableHeader textAlign="center">
              {t(`${TRANSLATION_PREFIX}.status`)}
            </StyledTableHeader>
          </tr>
        </thead>
        <tbody>
          {dataTable.students?.map((student, index, list) => (
            <StyledTableRow
              onClick={() => {
                handleSelectedStudent(student);
              }}
              key={index}
            >
              <StyledTableData>
                <span title={student.name}>
                  {concatenateEllipsis(student.name, 15)}
                </span>
              </StyledTableData>
              {dataTable.availableReleases?.map((release, idx) => (
                <StyledTableData textAlign="center" key={idx}>
                  {getReleaseValue(student, release) ? (
                    <CheckIcon fill={theme.colors.statusSuccessDefault} />
                  ) : (
                    "-"
                  )}
                </StyledTableData>
              ))}
              <StyledTableData>
                <Tag
                  appearance={
                    student.release_status === EnumReleaseStatusTypes.Aprovado
                      ? "success"
                      : "warning"
                  }
                >
                  {student.release_status}
                </Tag>
              </StyledTableData>
            </StyledTableRow>
          ))}
        </tbody>
      </table>
    </>
  );
};
