// React
import { useContext, useEffect, useState } from "react";
// Interfaces
import { IForm, IHelper } from "./interface";
// Libs
import moment from "moment";
import { default as formatString } from "format-string-by-pattern";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import HTTP_STATUS from "http-status-codes";
// Components
import { toastError, toastSuccess } from "components/Toast";
// Services
import { updateGuardianData } from "services/guardian.service";

export const HelperGuardianEdit = ({ state }: IHelper) => {
  const history = useHistory();
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.guardianDataEditing.helper";

  const [form, setForm] = useState<IForm>({
    guardian_name: state.user.name,
    birth_date: moment(state.user.birth_date).format("YYYY-MM-DD"),
    rg: formatString("99.999.999-9", state.user.rg),
    cpf: formatString("999.999.999-99", state.user.cpf),
    email: state.user.email,
    phone: formatString("(99) 99999-9999", state.user.phone),
    lives_with_student:
      state.user.id === state.enrollment.guard.student.same_address_as_guardian,
    cep: formatString("99999-999", state.user.zip_code),
    address: state.user.address,
    address_number: state.user.number,
    neighborhood: state.user.neighborhood,
    address_complement: state.user.complement,
    city: state.user.city,
    uf: state.user.uf,
  });

  const handleSubmit = async () => {
    const response = await updateGuardianData({
      guardian_id: state.user.id,
      formValues: {
        name: form.guardian_name,
        birth_date: form.birth_date,
        rg: formatString("999999999", form.rg),
        cpf: formatString("99999999999", form.cpf),
        email: form.email,
        phone: formatString("99999999999", form.phone),
        cep: formatString("99999999", form.cep),
        address: form.address,
        number: form.address_number,
        neighborhood: form.neighborhood,
        complement: form.address_complement,
        city: form.city,
        uf: form.uf,
        same_address: form.lives_with_student,
        enrollment: state.enrollment.id,
      },
    });
    if (response && response.status === HTTP_STATUS.OK) {
      toastSuccess(t(`${TRANSLATE}.toastSuccess`));
      history.goBack();
    } else {
      toastError(t(`${TRANSLATE}.toastError`));
    }
  };
  return {
    form,
    setForm,
    handleSubmit,
  };
};
