import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.default};
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1580px;
  padding: ${({ theme }) => theme.space[2]};
  margin: ${({ theme }) => theme.space[2]};
`;

// ---

export const TableContainer = styled.div`
  width: 100%;
  overflow-x: scroll;
`;

export const Table = styled.table``;

export const THead = styled.thead`
  &:hover tr {
    background: none;
  }
`;

interface ITRowProps {
  isHeader?: boolean;
  inactiveStudent?: boolean;
}

export const TRow = styled.tr<ITRowProps>`
  border-bottom: 1px solid #e5e5e5;

  color: ${({ theme, inactiveStudent }) =>
    inactiveStudent ? theme.colors.greyScale40 : theme.colors.black};

  ${({ isHeader }) =>
    isHeader &&
    css`
      border-bottom: 1px solid #999999;
    `}/* &:nth-child(even) td {
    background: #44444420;
  }

  &:hover {
    background: #44444410;
  } */
`;

export const THeader = styled.th`
  padding: 0.75rem 0.5rem;
  font-size: 12px;
`;

export const TBody = styled.tbody``;

export const TData = styled.td`
  padding: 0.75rem 0.5rem;
  cursor: pointer;
`;
