import { IMenuBar } from "./interface";
import { MenuContainer } from "./style";

export const MenuBar = ({
  title,
  width,
  height = 38,
  backgroundColor = "#b7ff00",
}: IMenuBar) => {
  return (
    <MenuContainer
      width={width}
      height={height}
      backgroundColor={backgroundColor}
    >
      <h4>{title}</h4>
    </MenuContainer>
  );
};
