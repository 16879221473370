import { Switch } from "@escolamais-ui-v2/react";
import { SwitchContainer, SwitchLabel } from "./styles";
import { useTranslation } from "react-i18next";

interface IActiveStudentsSwitchProps {
  onChangeFilterActiveStudents: () => void;
  areAllActiveStudents: boolean;
}
export const ActiveStudentsSwitch = ({
  onChangeFilterActiveStudents,
  areAllActiveStudents,
}: IActiveStudentsSwitchProps) => {
  const { t } = useTranslation();
  const prefix = "pages.AP.boletim.activeStudentsSwitch";

  return (
    <SwitchContainer>
      <div>
        <Switch
          checked={areAllActiveStudents}
          onChange={onChangeFilterActiveStudents}
        />
      </div>
      <div>
        <SwitchLabel onClick={onChangeFilterActiveStudents}>
          {t(`${prefix}.label`)}
        </SwitchLabel>
      </div>
    </SwitchContainer>
  );
};
