import React from "react";
import { FilterBox } from "./FilterBox";
import { AprovacaoPreviaState } from "../../types";
import { Loading } from "components";
import {
  CurrentClassText,
  FirstLayerContainer,
  InputUnidadeWrapper,
  SecondLayerContainer,
  Select,
  SelectsWrapper,
} from "./styles";
import { useTranslation } from "react-i18next";
import { ActiveStudentsSwitch } from "pages/AP/Boletim/components/ActiveStudentsSwitch";

interface AprovacaoPreviaFilterProps {
  state: AprovacaoPreviaState;
  handleChangeStatus: (status: string) => void;
  handleChangeUnit: (unit: string) => void;
  handleChangeQuarter: (quarterId: string) => void;
  handleFilterActiveStudents: () => void;
}

const TRANSLATION_PREFIX = "pages.AP.aprovacaoPrevia.aprovacaoPreviaFilter";

export const AprovacaoPreviaFilter: React.FC<AprovacaoPreviaFilterProps> = ({
  state: {
    isCxView,
    filter,
    currentClass,
    loadings: { loadingDataTable },
  },
  handleChangeUnit,
  handleChangeStatus,
  handleChangeQuarter,
  handleFilterActiveStudents,
}) => {
  const { t } = useTranslation();

  if (!filter.levelButtons || !filter.gradeButtons || !filter.sectionButtons) {
    return <Loading />;
  }

  return (
    <>
      <FirstLayerContainer>
        {isCxView && (
          <InputUnidadeWrapper>
            <span>{t(`${TRANSLATION_PREFIX}.unit`)}</span>
            <Select
              name="unit"
              id="unit"
              onChange={(e) => handleChangeUnit(e.target.value)}
            >
              {filter.unitDropdownList?.map((unit) => (
                <option key={unit.id} value={unit.id}>
                  {unit.name}
                </option>
              ))}
            </Select>
          </InputUnidadeWrapper>
        )}

        <FilterBox
          buttonsList={filter.levelButtons}
          activeId={filter.levelButtonActiveId}
          label={`${t(`${TRANSLATION_PREFIX}.level`)}`}
        />
        <FilterBox
          buttonsList={filter.gradeButtons}
          activeId={filter.gradeActiveId}
          label={`${t(`${TRANSLATION_PREFIX}.year`)}`}
        />
        <FilterBox
          buttonsList={filter.sectionButtons}
          activeId={filter.sectionActiveId}
          label={`${t(`${TRANSLATION_PREFIX}.section`)}`}
        />
      </FirstLayerContainer>
      <SecondLayerContainer>
        <CurrentClassText>{currentClass}</CurrentClassText>

        <SelectsWrapper>
          <ActiveStudentsSwitch
            onChangeFilterActiveStudents={handleFilterActiveStudents}
            areAllActiveStudents={filter.areAllActiveStudents}
          />
          <Select
            name="trimestre"
            id="trimestre"
            onChange={(e) => handleChangeQuarter(e.target.value)}
            value={filter.quarterActiveId}
          >
            <option>{t(`${TRANSLATION_PREFIX}.selectQuarter`)}</option>
            {filter.quarterDropDownList?.map((quarter) => (
              <option key={quarter.id} value={quarter.id}>
                {quarter.quarter_display}
              </option>
            ))}
          </Select>
          <Select
            disabled={loadingDataTable}
            onChange={(e) => handleChangeStatus(e.target.value)}
            name="status"
            id="status"
          >
            <option value="todos">
              {t(`${TRANSLATION_PREFIX}.allOptions`)}
            </option>
            {filter.statusDropDownList?.map((status) => (
              <option key={status.id} value={status.id}>
                {status.statusName}
              </option>
            ))}
          </Select>
        </SelectsWrapper>
      </SecondLayerContainer>
    </>
  );
};
