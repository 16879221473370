import { Container, HistoricoBoletins, Title, TitleContainer } from "./styles";
import React from "react";
import { AprovacaoPreviaTable } from "./components/Table/AprovacaoPreviaTable";
import { AprovacaoPreviaFilter } from "./components/Filter/AprovacaoPreviaFilter";
import { useAprovacaoPreviaHelper } from "./AprovacaoPrevia.helper";
import { useTranslation } from "react-i18next";
import { Drawer } from "components/organisms/Drawer";
import { AprovacaoPreviaActionTypes, Student } from "./types";
import { useBulletin } from "context/Bulletin";
import { getSchoolReport } from "services/aprovacao-previa.service";
import { Bulletin } from "components";
import { useHistory } from "react-router-dom";

const TRANSLATION_PREFIX = "pages.AP.aprovacaoPrevia";

export const AprovacaoPrevia: React.FC = () => {
  const {
    handleChangeStatus,
    handleChangeUnit,
    handleChangeQuarter,
    handleFilterActiveStudents,
    state,
    dispatch,
  } = useAprovacaoPreviaHelper();
  const { t } = useTranslation();
  const history = useHistory();

  const showDrawer = () => {
    dispatch({ type: AprovacaoPreviaActionTypes.OPEN_DRAWER });
  };

  const handleCloseDrawer = () => {
    dispatch({ type: AprovacaoPreviaActionTypes.CLOSE_DRAWER });
    document.body.style.overflow = "unset";
  };

  const { handleDataBulletin } = useBulletin();

  const handleCloseBulletinDrawer = () => {
    handleCloseDrawer();
  };

  // WIP: esta função faz parte da nova drawer
  const handleSelectedStudent = async (student: Student) => {
    try {
      handleDataBulletin({
        id: student.student_id,
        quarter: +state.filter.quarterActiveId,
      });

      dispatch({
        type: AprovacaoPreviaActionTypes.BOLETIMV2_DRAWER_LOADING,
        payload: true,
      });

      showDrawer();

      const response = await getSchoolReport({
        quarterId: state.filter.quarterActiveId,
        studentId: student.student_id,
      });

      // dispatch({
      //   type: AprovacaoPreviaActionTypes.SET_SELECTED_STUDENT,
      //   payload: response.data,
      // });

      dispatch({
        type: AprovacaoPreviaActionTypes.SET_STUDENT_SCHOOL_REPORT,
        payload: response.data,
      });

      dispatch({
        type: AprovacaoPreviaActionTypes.BOLETIMV2_DRAWER_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: AprovacaoPreviaActionTypes.BOLETIMV2_DRAWER_LOADING,
        payload: false,
      });
    }
  };

  // WIP: esta função faz parte da nova drawer
  const handleNextStudent = async () => {
    try {
      const currentStudentIndex = state.dataTable.students.findIndex(
        (x) => x.student_id === state.studentSchoolReport.student_id
      );

      if (currentStudentIndex >= state.dataTable.students.length - 1) return;

      dispatch({
        type: AprovacaoPreviaActionTypes.BOLETIMV2_DRAWER_LOADING,
        payload: true,
      });

      const nextStudent = state.dataTable.students[currentStudentIndex + 1];

      const response = await getSchoolReport({
        quarterId: state.filter.quarterActiveId,
        studentId: nextStudent.student_id,
      });

      // dispatch({
      //   type: AprovacaoPreviaActionTypes.SET_SELECTED_STUDENT,
      //   payload: response.data,
      // });

      dispatch({
        type: AprovacaoPreviaActionTypes.SET_STUDENT_SCHOOL_REPORT,
        payload: response.data,
      });

      dispatch({
        type: AprovacaoPreviaActionTypes.BOLETIMV2_DRAWER_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: AprovacaoPreviaActionTypes.BOLETIMV2_DRAWER_LOADING,
        payload: false,
      });
    }
  };

  // WIP: esta função faz parte da nova drawer
  const handlePreviousStudent = async () => {
    try {
      const currentStudentIndex = state.dataTable.students.findIndex(
        (x) => x.student_id === state.studentSchoolReport.student_id
      );

      if (currentStudentIndex <= 0) return;

      dispatch({
        type: AprovacaoPreviaActionTypes.BOLETIMV2_DRAWER_LOADING,
        payload: true,
      });

      const previousStudent = state.dataTable.students[currentStudentIndex - 1];

      const response = await getSchoolReport({
        quarterId: state.filter.quarterActiveId,
        studentId: previousStudent.student_id,
      });

      // dispatch({
      //   type: AprovacaoPreviaActionTypes.SET_SELECTED_STUDENT,
      //   payload: response.data,
      // });

      dispatch({
        type: AprovacaoPreviaActionTypes.SET_STUDENT_SCHOOL_REPORT,
        payload: response.data,
      });

      dispatch({
        type: AprovacaoPreviaActionTypes.BOLETIMV2_DRAWER_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: AprovacaoPreviaActionTypes.BOLETIMV2_DRAWER_LOADING,
        payload: false,
      });
    }
  };

  return (
    <Container>
      <Drawer
        side="right"
        title={state.currentClass}
        isOpen={state.drawer.isOpen}
        onClose={handleCloseBulletinDrawer}
      >
        {/* WIP: Novo boletim em construção */}
        {/* <BoletimV2
          state={state}
          handleNextStudent={handleNextStudent}
          handlePreviousStudent={handlePreviousStudent}
        /> */}

        <Bulletin />
      </Drawer>

      <TitleContainer>
        <Title>{t(`${TRANSLATION_PREFIX}.title`)}</Title>

        <HistoricoBoletins
          onClick={() => history.push("/historico-dos-boletins")}
        >
          <img src="/icons/history.svg" alt="" />
          {t(`${TRANSLATION_PREFIX}.bulletinHistory`)}
        </HistoricoBoletins>
      </TitleContainer>

      <AprovacaoPreviaFilter
        state={state}
        handleChangeStatus={handleChangeStatus}
        handleChangeUnit={handleChangeUnit}
        handleChangeQuarter={handleChangeQuarter}
        handleFilterActiveStudents={handleFilterActiveStudents}
      />
      <AprovacaoPreviaTable
        state={state}
        handleSelectedStudent={handleSelectedStudent}
      />
    </Container>
  );
};
