import styled, { css } from "styled-components";
import { IButton, IButtonGroupStyle } from "./interface";

export const ButtonGroupContainer = styled.div<IButtonGroupStyle>`
  display: flex;
  width: ${({ width }) => `${width}px`};
  background-color: ${({ backgroundColor }) => backgroundColor};

  padding: ${({ theme }) => theme.space[1]} ${({ theme }) => theme.space[2]};
  border-radius: ${({ theme }) => theme.space[1]};

  @media (max-width: 360px) {
    width: 100%;
  }
`;

export const Button = styled.button<IButton>`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 0 ${({ theme }) => theme.space[2]};

  background-color: transparent;

  padding: ${({ theme }) => theme.space[1]};
  width: ${({ buttonWidth }) => `${buttonWidth}px`};

  text-align: center;
  text-transform: uppercase;

  border: none;
  border-radius: ${({ theme }) => theme.space[1]};

  color: ${({ labelColor }) => labelColor};

  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.web.sm};
  line-height: ${({ theme }) => theme.lineHeights.normal};

  cursor: pointer;

  ${({ activated, buttonColor }) =>
    activated &&
    buttonColor &&
    css`
      background-color: ${buttonColor};
    `}

  label {
    margin: 0;
    cursor: pointer;
  }

  @media (max-width: 360px) {
    width: 74px; // TODO: nao tem na lib
    font-size: ${({ theme }) => theme.fontSizes.mobile.sm};
  }
`;

export const Icon = styled.img`
  width: 12.25px; // TODO: nao tem na lib
  height: 12.25px; // TODO: nao tem na lib
`;
