import styled from "styled-components";

export const Container = styled.form`
  padding: 0 ${({ theme }) => theme.space[4]};
  margin-top: ${({ theme }) => theme.space[4]};

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[4]};
  width: 100%;

  .content {
    .text-description {
      font-size: ${({ theme }) => theme.fontSizes.web.default};
      margin-bottom: ${({ theme }) => theme.space[4]};
    }

    .vacancies {
      display: flex;
      gap: 0 ${({ theme }) => theme.space[6]};

      span {
        margin-top: ${({ theme }) => theme.space[2]};

        font-weight: ${({ theme }) => theme.fontWeights.bold};
        font-size: ${({ theme }) => theme.fontSizes.web.h4};
        line-height: 30px; // TODO: nao tem na lib
      }
    }

    label {
      display: flex;
      flex-direction: column;
      font-size: ${({ theme }) => theme.fontSizes.web.sm};

      input {
        padding: ${({ theme }) => theme.space[2]};
        font-size: ${({ theme }) => theme.fontSizes.web.sm};
        max-width: 248px;
        height: 37px;
        margin-top: ${({ theme }) => theme.space[1]};
      }
    }

    button {
      display: flex;
      padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[4]};
      border: none;
      margin-left: auto;
      font-size: ${({ theme }) => theme.fontSizes.web.sm};
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.white};
      background-color: ${({ theme }) => theme.colors.primaryDefault};

      &:disabled {
        filter: brightness(0.5);
      }
    }
  }
`;

export const TableContent = styled.div`
  display: flex;
  flex-direction: column;

  ul {
    padding: 0;
  }

  strong {
    margin-bottom: ${({ theme }) => theme.space[2]};
  }

  .table-head,
  ul li {
    display: grid;
    grid-template-columns: 68% 32%;

    p {
      margin-bottom: 0;
      font-size: ${({ theme }) => theme.fontSizes.web.sm};
      padding: ${({ theme }) => theme.space[2]};
    }

    p:first-child {
      padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[4]};
    }
  }

  li {
    border-bottom: 1px solid ${({ theme }) => theme.colors.greyScale10};

    p {
      margin-bottom: 0;
    }
  }

  .table-head p {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    border-bottom: 1px solid ${({ theme }) => theme.colors.greyScale40};
  }

  .table-data {
    h4 {
      display: flex;
      align-items: center;
      justify-content: center;

      font-weight: 600; // TODO: nao tem na lib
      font-size: ${({ theme }) => theme.fontSizes.web.h4};
      line-height: 30px; // TODO: nao tem na lib

      margin-top: ${({ theme }) => theme.space[4]};

      color: ${({ theme }) => theme.colors.greyScale40};
    }
  }
`;
