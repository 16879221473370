/* eslint-disable array-callback-return */
import { Container, SelectStyled } from "../style";
import { Button } from "components";
import { ModalLancamentoComentario } from "components/Modals/ModalLancamentoComentario";
import loadingGif from "static/img/loading.gif";
import { LinearProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { DisciplinaProps } from "./types";
import { TitleAndSubTitle } from "components/atoms/Title";

import { useReleases } from "./LancamentoAtribuir.helper";
import { formatNote } from "./helpers";

export const LancamentoAtribuir = () => {
  const {
    loading,
    loadingStudents,
    comment,
    setComment,
    sendListNotasConhecimento,
    studentsListModal,
    studentsList,
    check,
    disciplinas,
    turmas,
    quarters,
    setSelectedQuarter,
    selectedQuarter,
    setSelectedSubject,
    filterDisciplina,
    setSelectedGradeGroup,
    classList,
    fetchStudents,
    selectedGradeGroup,
    verifyObservation,
    handledUpdadeListNotas,
    sendListNotas,
    handleChecked,
    setStudentIndicer,
    setToggleModal,
    handleStudentModal,
    handleSubmit,
    toggleModal,
    studentIndice,
    returnMessage,
    location,
  } = useReleases();

  const { t } = useTranslation();
  const prefix = "pages.AP.releases";

  if (loading) {
    return (
      <Container className="container">
        <LinearProgress className="mt-3 mb-3" color="secondary" />
      </Container>
    );
  }

  return (
    <>
      {disciplinas && turmas && quarters && (
        <Container className="container">
          <div>
            <TitleAndSubTitle
              title={location?.state?.evaluation_type?.name}
              subtitle={t(`${prefix}.selectAQuarter`)}
            />
            <div className="row">
              <div className="col-12">
                <div className="row fz0">
                  <div className="col-3">
                    {/* trimestre */}
                    <SelectStyled
                      onChange={(e) => {
                        setSelectedQuarter(parseInt(e.target.value));
                      }}
                    >
                      <option value="">{t(`${prefix}.selectAQuarter`)}</option>
                      {quarters.map(
                        (quarter, index) =>
                          quarter && (
                            <option
                              key={`${index}-${quarter.name}`}
                              value={quarter?.id}
                            >
                              {quarter?.name}
                            </option>
                          )
                      )}
                    </SelectStyled>
                  </div>
                  {selectedQuarter && typeof selectedQuarter === "number" && (
                    <>
                      {location?.state?.evaluation_type?.subject &&
                        String(location?.state?.evaluation_type.name) !==
                          "Estudo" && (
                          <div className="col-3">
                            {/* Lista de Disciplinas */}
                            <SelectStyled
                              onChange={(e) =>
                                setSelectedSubject(parseInt(e.target.value))
                              }
                            >
                              <option value="">
                                {t(`${prefix}.selectASubject`)}
                              </option>
                              {filterDisciplina?.map(
                                (userClass: DisciplinaProps, index: number) =>
                                  userClass && (
                                    <option
                                      key={`${index}-${userClass.subject_name}`}
                                      value={userClass.subject}
                                    >
                                      {userClass.subject_name}
                                    </option>
                                  )
                              )}
                            </SelectStyled>
                          </div>
                        )}
                      <div className="col-3">
                        <SelectStyled
                          onChange={(e) => {
                            setSelectedGradeGroup(parseInt(e.target.value));
                          }}
                        >
                          <option value="">
                            {t(`${prefix}.selectAClass`)}
                          </option>
                          {classList.map((item, index) => {
                            return (
                              <option
                                value={item.gradegroup}
                                key={`${index}-${item.gradegroup}+${index}`}
                              >
                                {item.value}
                              </option>
                            );
                          })}
                        </SelectStyled>
                      </div>
                      <div className="col-3">
                        <Button
                          className="btnBlue"
                          onClick={() => {
                            fetchStudents();
                          }}
                          disabled={loadingStudents}
                        >
                          {loadingStudents ? (
                            <img height="25" src={loadingGif} alt="loading" />
                          ) : (
                            "Visualizar"
                          )}
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {studentsList && studentsList.length === 0 && returnMessage && (
            <div className="row mt-5 ml-5">
              <strong>{returnMessage}</strong>
            </div>
          )}
          {studentsList && studentsList.length !== 0 && selectedQuarter && (
            <div className="row mt-5">
              <table className="tableStudents mb-5">
                <thead>
                  <tr>
                    <th className="col-6">{t(`${prefix}.students`)}</th>

                    <th className="col-1">{`${
                      location?.state?.evaluation_type?.class_diary
                        ? "Faltas"
                        : "Notas"
                    }`}</th>

                    {location.state.evaluation_type.class_diary && (
                      <th className="col-1">
                        {t(`${prefix}.classDiaryAbsences`)}
                      </th>
                    )}
                    {location?.state?.evaluation_type?.substitutive && (
                      <th className="col-1">{t(`${prefix}.substitute`)}</th>
                    )}
                    {selectedGradeGroup &&
                      verifyObservation(selectedGradeGroup) && (
                        <th className="col-1">{t(`${prefix}.observation`)}</th>
                      )}
                  </tr>
                </thead>
                <tbody>
                  {studentsList?.map((student, index) => {
                    return (
                      <tr key={student?.enrollment}>
                        <td>
                          <span>{student?.name}</span>
                        </td>
                        <td className="text-center tdFlex">
                          <div className="content">
                            <input
                              style={{ width: 50 }}
                              type="text"
                              name={`value-${index}`}
                              onChange={(e) => {
                                handledUpdadeListNotas(
                                  student.enrollment,
                                  formatNote(e.currentTarget.value),
                                  index
                                );
                              }}
                              maxLength={4}
                              value={
                                sendListNotas[index]?.value === null || ""
                                  ? ""
                                  : String(sendListNotas[index]?.value).replace(
                                      ".",
                                      ","
                                    )
                              }
                            />
                          </div>
                        </td>

                        {location?.state?.evaluation_type?.class_diary && (
                          <td className="text-center">{student.class_diary}</td>
                        )}

                        {location?.state?.evaluation_type?.substitutive && (
                          <td className="text-center tdContentSubstitutiva">
                            <div className="content">
                              <input
                                type="checkbox"
                                name="checkbox"
                                className="checkbox"
                                checked={check[index]}
                                onChange={(e) => {
                                  handleChecked(e.currentTarget.checked, index);
                                }}
                              />
                            </div>
                          </td>
                        )}

                        {selectedGradeGroup &&
                          verifyObservation(selectedGradeGroup) && (
                            <td
                              className="blue-bold cursor-add text-center"
                              onClick={() => {
                                setStudentIndicer(index);
                                setToggleModal(true);
                                handleStudentModal(
                                  student,
                                  sendListNotasConhecimento[index].value,
                                  check[index]
                                );
                                setComment({
                                  [student.enrollment]: student.comment,
                                });
                              }}
                            >
                              Editar
                            </td>
                          )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <div className="contentBtn">
                <Button
                  style={{ fontWeight: "bold" }}
                  className="btnRosa"
                  onClick={() => handleSubmit()}
                >
                  Salvar Notas
                </Button>
              </div>
            </div>
          )}

          <ModalLancamentoComentario
            setToggleModal={setToggleModal}
            setComment={setComment}
            comment={comment}
            isOpen={toggleModal}
            studentModal={studentsListModal}
            indice={studentIndice}
          />
        </Container>
      )}
    </>
  );
};
