import styled from "styled-components";

export const InputFileRootContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[1]} 0;
`;

export const Label = styled.label`
  font-size: ${({ theme }) => theme.fontSizes.web.sm};
  font-style: normal;
  font-weight: ${({ theme }) => theme.fontWeights.regular};

  line-height: normal;
  text-transform: uppercase;

  color: ${({ theme }) => theme.colors.greyScale95};
  margin: 0;
`;

interface IInputStyle {
  fontColor: string;
  width: number;
}

export const Input = styled.div<IInputStyle>`
  input {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 ${({ theme }) => theme.space[1]};

    font-size: ${({ theme }) => theme.fontSizes.web.sm};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    line-height: normal;
    text-transform: uppercase;

    margin: 0;
    padding: ${({ theme }) => theme.space[2]};

    width: ${({ width }) => `${width}px`};
    height: 29px; // TODO: nao tem na lib

    color: ${({ fontColor }) => `${fontColor}`};
    background-color: ${({ theme }) => theme.colors.primaryDefault};

    cursor: pointer;
  }

  .error {
    font-size: ${({ theme }) => theme.fontSizes.web.sm};
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    line-height: normal;
    color: ${({ theme }) => theme.colors.statusErrorDefault};
  }
`;

interface ITextStyle {
  color: string;
}

export const TextInformation = styled.span<ITextStyle>`
  font-size: ${({ theme }) => theme.fontSizes.mobile.sm};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  line-height: normal;

  color: ${({ color }) => `${color}`};

  p {
    font-size: ${({ theme }) => theme.fontSizes.web.sm};
    color: ${({ theme }) => theme.colors.black};
    margin-bottom: 0;
    margin-bottom: ${({ theme }) => theme.space[1]};
  }
`;

export const TextError = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.mobile.sm};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  line-height: normal;

  color: ${({ theme }) => theme.colors.statusErrorDefault};
`;
