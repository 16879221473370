/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
import {
  DataProps,
  Estudantes,
} from "pages/AP/EditClassesManagement/EditClassesManagement.type";
import { Org } from "./PainelClassesManagement.type";

const calcularPorcentagem = (n1: number, n2: number) => {
  if (Number(n1) && Number(n2)) {
    const calculo = (n1 * 100) / n2;
    return `${calculo.toFixed()}%`;
  }
};

export const infoPainel = (data: DataProps) => {
  const { opcoes_secao = [], opcoes_genero = [] } = data;
  const info: Org = {};
  info.enturmados = [];
  const estudanteInfo: Org = {};
  const feminoInfo: Org = {};
  const masculinoInfo: Org = {};
  const enturmadoInfo: Org = {};
  const estudanteSemTurmaInfo: Org = {};
  const response = [];

  estudanteInfo.title = "Estudantes";
  feminoInfo.title = "Feminino";
  masculinoInfo.title = "Masculino";

  for (const secao of opcoes_secao) {
    if (secao) {
      const estudantesFiltrados = data.estudantes.filter((estudante) => {
        const valorNovaTurma = estudante.nova_turma;

        if (valorNovaTurma === secao) {
          return estudante;
        }
      });

      info[secao] = estudantesFiltrados;
      info.enturmados.push(info[secao].length);
      estudanteInfo[secao] = info[secao].length;

      if (info[secao].length === 0) {
        masculinoInfo[secao] = "00%";
        feminoInfo[secao] = "00%";
      } else if (masculinoInfo[secao] === 0) {
        masculinoInfo[secao] = "00%";
      } else if (feminoInfo[secao] === 0) {
        feminoInfo[secao] = "00%";
      }
    }
  }

  for (const secao of opcoes_secao) {
    if (secao && info[secao].length > 0) {
      for (const genero of opcoes_genero) {
        if (genero.value) {
          const estudantesFiltrados = info[secao].filter(
            (estudante: Estudantes) => {
              const valorGenero = estudante.genero;
              const valorNovaTurma = estudante.nova_turma;

              if (valorGenero === genero.value && valorNovaTurma === secao) {
                return estudante;
              }
            }
          );

          switch (genero.label) {
            case "Feminino":
              feminoInfo[secao] = calcularPorcentagem(
                estudantesFiltrados.length,
                Number(info[secao].length)
              );
              break;

            case "Masculino":
              masculinoInfo[secao] = calcularPorcentagem(
                estudantesFiltrados.length,
                Number(info[secao].length)
              );
              break;
          }
        }
      }
    }
  }

  const alunosEnturmados = info.enturmados.reduce(
    (value: number, index: number) => {
      return value + index;
    }
  );

  const semTurma = data && data.total_estudantes.total;

  enturmadoInfo.total = alunosEnturmados;
  estudanteSemTurmaInfo.total = semTurma;

  response.push(
    enturmadoInfo,
    estudanteInfo,
    feminoInfo,
    masculinoInfo,
    estudanteSemTurmaInfo
  );

  return response;
};
