import {
  ConsultarAtribuicoes,
  DiarioDeClasse,
  EditarAtribuicoes,
  LancamentoAtribuir,
  SelecaoLancamento,
  BoletimFinal,
  Dashboard,
  HistoricoDosBoletins,
  SearchClassDiary,
  EditClassesManagement,
  ClassesManagement,
  StudyItineraries,
  GuardianSchoolReportPage,
  StudentSelection,
  SelectStudent,
  BoletoSegundaVia,
  SchoolCalendar,
  PublishBoletim,
  ClassCouncil,
} from "pages/AP";

import {
  GuardianFirstLogin,
  GuardianUpdatePassword,
  Home,
  Profile,
  BulletinGuardian,
} from "pages/CORE";

import {
  Boleto,
  SearchForVacancies,
  CadastroVigenciaPacoteDeAlimentacao,
  CartaoCredito,
  CartaoCreditoSegundo,
  ConsultaAlimentacaoResponsavel,
  ConsultaPlanoPagamento,
  DataConfirm,
  EditGuardian,
  EditStudent,
  ExportDeEnviosParaTotvs,
  FinancialResponsible,
  FoodOption,
  FormItemDeAlimentacao,
  GerenciarProcessoMatriculas,
  GestaoItensDeAlimentacao,
  KeepOrChangeFinancialResponsible,
  MenuAlimentacao,
  MultiplePay,
  MyStudents,
  PagamentoReserva,
  PaymentPlan,
  Pix,
  WorkingDaysRegister,
  FoodRecord,
  RelatorioDeAlimentacaoAvulsa,
  SearchDocuments,
  SearchGuardian,
  SearchGuardianByCPF,
  SigningContract,
  StudentDocumentation,
  StudentExitTime,
  StudentSignup,
  StudentSummary,
  UploadDocument,
  UploadLetter,
  StudentsFoodPackage,
  CancelFoodPackage,
  GuardianDataEditing,
  ConfirmGuardianData,
  EnrollmentPayment,
  DocumentsForContract,
  SignedContract,
  EditStudentData,
  StudentData,
  ConsultFood,
  FoodPage,
  SinglePurchase,
  SelectMeals,
  EnrollmentStatus,
  Students,
  Indication,
  WaitingList,
  PaymentRegularizer,
  GenerateBadgeFile,
  ExitPermit,
  ExitReport,
  ConsultFoodOrders,
  ManageFoodItems,
  ManageVacancies,
  PeriodEnrollmentReenrollment,
  RegisterEditPaymentPlan,
  NewGuardianSignup,
  DiscountApproval,
  SearchStudentOrGuardian,
} from "pages/EDF";

import { StudentFoodCredit } from "pages/EDF/StudentFoodCredit";
import { ListaBoletos } from "../pages/AP/BoletoSegundaVia/components/ListaBoletos";
import { ChooseProject } from "pages/AP/ChooseProject/ChooseProject";
import { StudyItinerariesInternal } from "pages/AP/StudyItinerariesInternal";
import { AprovacaoPrevia } from "pages/AP/AprovacaoPrevia";
import { VacancyData } from "pages/EDF/VacancyData";
import { NewStudentSignup } from "pages/EDF/NewStudentSignup";
import { UserDocuments } from "pages/EDF/UserDocuments";
import { StudentDocuments } from "pages/EDF/StudentDocuments";

export interface PrivateRouteType {
  path: string;
  component: React.FC | any;
  exact: boolean;
}

interface PrivateRoutesType extends Array<PrivateRouteType> {}

export const privateRoutes: PrivateRoutesType = [
  {
    path: "/home",
    component: Home,
    exact: true,
  },
  {
    path: "/selecao_estudante",
    component: StudentSelection,
    exact: true,
  },
  {
    path: "/boletim", // APROVAÇÃO PRÉVIA
    component: BoletimFinal,
    exact: true,
  },
  {
    path: "/aprovacao-previa",
    component: AprovacaoPrevia,
    exact: true,
  },
  {
    path: "/publicacao-boletim",
    component: PublishBoletim,
    exact: true,
  },
  {
    path: "/home/boletim",
    component: GuardianSchoolReportPage,
    exact: true,
  },
  {
    path: "/dashboard",
    component: Dashboard,
    exact: true,
  },
  {
    path: "/historico-dos-boletins",
    component: HistoricoDosBoletins,
    exact: true,
  },
  {
    path: "/responsavel/primeiro-acesso",
    component: GuardianFirstLogin,
    exact: true,
  },
  {
    path: "/alterar-senha",
    component: GuardianUpdatePassword,
    exact: true,
  },
  {
    path: "/meu-perfil",
    component: Profile,
    exact: true,
  },
  {
    path: "/pagamento/reserva-de-vaga/segundo-cartao",
    component: CartaoCreditoSegundo,
    exact: true,
  },
  {
    path: "/pagamento/reserva-de-vaga/multipay",
    component: MultiplePay,
    exact: true,
  },
  {
    path: "/pagamento/reserva-de-vaga/boleto",
    component: Boleto,
    exact: true,
  },
  {
    path: "/pagamento/reserva-de-vaga/cartao",
    component: CartaoCredito,
    exact: true,
  },
  {
    path: "/pagamento/reserva-de-vaga/pix",
    component: Pix,
    exact: true,
  },
  {
    path: "/pagamento/reserva-de-vaga",
    component: PagamentoReserva,
    exact: true,
  },
  {
    path: "/responsavel/editar-cadastro",
    component: Profile,
    exact: true,
  },
  {
    path: "/responsavel/editar",
    component: EditGuardian,
    exact: true,
  },
  {
    path: "/responsavel/busca",
    component: SearchGuardian,
    exact: true,
  },
  {
    path: "/responsavel/busca-por-cpf",
    component: SearchGuardianByCPF,
    exact: true,
  },
  {
    path: "/usuarios/servicos",
    component: StudentExitTime,
    exact: true,
  },
  {
    path: "/responsavel/cadastro-aluno",
    component: StudentSignup,
    exact: true,
  },
  {
    path: "/responsavel/meus-alunos/aluno",
    component: EditStudent,
    exact: true,
  },
  {
    path: "/responsavel/meus-alunos",
    component: MyStudents,
    exact: true,
  },
  {
    path: "/responsavel/boletim",
    component: BulletinGuardian,
    exact: true,
  },
  {
    path: "/responsavel/consulta-alimentacao",
    component: ConsultaAlimentacaoResponsavel,
    exact: true,
  },
  {
    path: "/aluno/editar",
    component: StudentSummary,
    exact: true,
  },
  {
    path: "/responsavel/meus-alunos/documentacao",
    component: StudentDocumentation,
    exact: true,
  },
  {
    path: "/responsavel/meus-alunos/responsavel-financeiro",
    component: FinancialResponsible,
    exact: true,
  },
  {
    path: "/responsavel/meus-alunos/manter-responsavel-financeiro",
    component: KeepOrChangeFinancialResponsible,
    exact: true,
  },
  {
    path: "/responsavel/alimentacao",
    component: MenuAlimentacao,
    exact: true,
  },
  {
    path: "/responsavel/meus-alunos/documentos-matricula",
    component: StudentDocuments,
    exact: true,
  },
  {
    path: "/responsavel/meus-alunos/carta",
    component: UploadLetter,
    exact: true,
  },
  {
    path: "/diario-de-classe",
    component: DiarioDeClasse,
    exact: true,
  },
  {
    path: "/consulta-diario-de-classe",
    component: SearchClassDiary,
    exact: true,
  },
  {
    path: "/registro-de-alimentacao",
    component: FoodRecord,
    exact: true,
  },
  {
    path: "/relatorio-de-alimentacao",
    component: RelatorioDeAlimentacaoAvulsa,
    exact: true,
  },
  {
    path: "/contrato/confirmacao-dados",
    component: DataConfirm,
    exact: true,
  },
  {
    path: "/financeiro/plano-pagamento",
    component: PaymentPlan,
    exact: true,
  },
  {
    path: "/contrato/assinatura-contrato",
    component: SigningContract,
    exact: true,
  },
  {
    path: "/contrato/contrato-assinado",
    component: SignedContract,
    exact: true,
  },
  {
    path: "/responsavel/Consultar/documentos",
    component: SearchDocuments,
    exact: true,
  },
  {
    path: "/gerenciar/matriculas",
    component: GerenciarProcessoMatriculas,
    exact: true,
  },
  {
    path: "/gerenciar/turmas",
    component: ConsultarAtribuicoes,
    exact: true,
  },
  {
    path: "/gerenciar/turmas/editar",
    component: EditarAtribuicoes,
    exact: true,
  },
  {
    path: "/buscar-vagas",
    component: SearchForVacancies,
    exact: true,
  },
  {
    path: "/cadastro-dias-uteis",
    component: WorkingDaysRegister,
    exact: true,
  },
  {
    path: "/consulta-plano-pagamento",
    component: ConsultaPlanoPagamento,
    exact: true,
  },
  {
    path: "/exportar-totvs",
    component: ExportDeEnviosParaTotvs,
    exact: true,
  },
  {
    path: "/gestao-itens-alimentacao/form",
    component: FormItemDeAlimentacao,
    exact: true,
  },
  {
    path: "/gestao-itens-alimentacao",
    component: GestaoItensDeAlimentacao,
    exact: true,
  },
  {
    path: "/cadastro-vigencia-pacote-de-alimentacao",
    component: CadastroVigenciaPacoteDeAlimentacao,
    exact: true,
  },
  {
    path: "/lancamento/atribuir",
    component: LancamentoAtribuir,
    exact: true,
  },
  {
    path: "/lancamento/componentes",
    component: SelecaoLancamento,
    exact: true,
  },
  {
    path: "/responsavel/alimentacao/comprar-pacote-alimentacao",
    component: FoodOption,
    exact: true,
  },
  {
    path: "/responsavel/alimentacao/pacote-alimentacao",
    component: StudentsFoodPackage,
    exact: true,
  },
  {
    path: "/alimentacao/cancelar-pacote-alimentacao",
    component: CancelFoodPackage,
    exact: true,
  },
  {
    path: "/alimentacao/lancar-credito-alimentacao",
    component: StudentFoodCredit,
    exact: true,
  },
  {
    path: "/matricula/editar-dados-responsavel",
    component: GuardianDataEditing,
    exact: true,
  },
  {
    path: "/matricula/confirmar-dados-responsavel",
    component: ConfirmGuardianData,
    exact: true,
  },
  {
    path: "/matricula/pagamento-da-reserva",
    component: EnrollmentPayment,
    exact: true,
  },
  {
    path: "/matricula/documentos-para-contrato",
    component: DocumentsForContract,
    exact: true,
  },
  {
    path: "/alimentacao/consultar-alimentacao",
    component: ConsultFood,
    exact: true,
  },
  {
    path: "/alimentacao/",
    component: FoodPage,
    exact: true,
  },
  {
    path: "/alimentacao/compra-avulsa",
    component: SinglePurchase,
    exact: true,
  },
  {
    path: "/alimentacao/compra-avulsa/refeicoes",
    component: SelectMeals,
    exact: true,
  },
  {
    path: "/responsavel/alunos",
    component: Students,
    exact: true,
  },
  {
    path: "/matricula/etapas",
    component: EnrollmentStatus,
    exact: true,
  },
  {
    path: "/matricula/confirmar-dados-do-estudante",
    component: StudentData,
    exact: true,
  },
  {
    path: "/matricula/editar-dados-do-estudante",
    component: EditStudentData,
    exact: true,
  },
  {
    path: "/indicacao",
    component: Indication,
    exact: true,
  },
  {
    path: "/lista-espera",
    component: WaitingList,
    exact: true,
  },
  {
    path: "/regularizar-pagamento",
    component: PaymentRegularizer,
    exact: true,
  },
  {
    path: "/enturmamento",
    component: ClassesManagement,
    exact: true,
  },
  {
    path: "/enturmamento/editar",
    component: EditClassesManagement,
    exact: true,
  },
  {
    path: "/gerar-arquivo-para-cracha",
    component: GenerateBadgeFile,
    exact: true,
  },
  {
    path: "/roteiros-de-estudos",
    component: StudyItineraries,
    exact: true,
  },
  {
    path: "/roteiros-de-estudo/estudante",
    component: StudyItinerariesInternal,
    exact: true,
  },
  {
    path: "/saida-do-estudante",
    component: ExitPermit,
    exact: true,
  },
  {
    path: "/autorizacao-de-saida",
    component: ExitReport,
    exact: true,
  },
  {
    path: "/selecao-de-estudante",
    component: SelectStudent,
    exact: true,
  },
  {
    path: "/boletos-liste-estudantes",
    component: BoletoSegundaVia,
    exact: true,
  },
  {
    path: "/boletos-liste-estudantes/boleto",
    component: ListaBoletos,
    exact: true,
  },
  {
    path: "/escolher-projeto",
    component: ChooseProject,
    exact: true,
  },
  // {
  //   path: "/calendario-escolar",
  //   component: SchoolCalendar,
  //   exact: true,
  // },
  {
    path: "/consultar-pedidos-alimentacao",
    component: ConsultFoodOrders,
    exact: true,
  },
  {
    path: "/gerenciar-itens-alimentacao",
    component: ManageFoodItems,
    exact: true,
  },
  {
    path: "/gerenciar-vagas",
    component: ManageVacancies,
    exact: true,
  },
  {
    path: "/conselho-classe",
    component: ClassCouncil,
    exact: true,
  },
  {
    path: "/cadastrar-periodo-matricula-rematricula",
    component: PeriodEnrollmentReenrollment,
    exact: true,
  },
  {
    path: "/plano-de-pagamento",
    component: RegisterEditPaymentPlan,
    exact: true,
  },
  {
    path: "/cadastro-responsavel",
    component: NewGuardianSignup,
    exact: true,
  },
  {
    path: "/dados-vaga",
    component: VacancyData,
    exact: true,
  },
  {
    path: "/aprovacao-descontos",
    component: DiscountApproval,
    exact: true,
  },
  {
    path: "/buscar-aluno-responsavel",
    component: SearchStudentOrGuardian,
    exact: true,
  },
  {
    path: "/cadastro-estudante",
    component: NewStudentSignup,
    exact: true,
  },
  {
    path: "/usuario/envio-de-documentos",
    component: UserDocuments,
    exact: true,
  },
];
