import styled from "styled-components";

export const Container = styled.div`
  width: 391px;
  padding: ${({ theme }) => theme.space[2]} ${({ theme }) => theme.space[4]};
  gap: ${({ theme }) => theme.space[4]};
  border: 2px solid ${({ theme }) => theme.colors.statusInfoDefault};
  display: flex;

  @media (max-width: 1024px) {
    width: auto;
  }
`;

export const Title = styled.strong`
  font-size: ${({ theme }) => theme.fontSizes.web.sm};
`;
export const Subtitle = styled.p`
  margin: 0;
  font-size: ${({ theme }) => theme.fontSizes.web.sm};
`;
export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
`;
