// React
import { useContext, useEffect, useState } from "react";
// Styles
import { PixStyled } from "./style";
// Interfaces
import { FoodPackage } from "interfaces/mealInterfaces";
import { FoodPackagePaymentInterface } from "interfaces/paymentInterfaces";
import { StudentInterface } from "interfaces/constants";
// Icons
import fileIcon from "static/img/file_Icon.svg";
// Components
import { Text } from "components";
import { Button } from "components/atoms";
// Services
import { getFoodPackage } from "services/meals.service";
// Helpers
import { nextMonth } from "helpers/helpers";
import { FOOD_PAYMENT_STATUS } from "helpers/constants";
// Libs
import HTTP_STATUS from "http-status-codes";
import { useTranslation } from "react-i18next";

type PixType = {
  foodPackage: FoodPackage;
  paymentData: FoodPackagePaymentInterface;
  student: StudentInterface;
  setActiveStep: (activeStep: number) => void;
  setNewPackage: (newPackage: boolean) => void;
};

export const Pix = ({
  foodPackage,
  paymentData,
  student,
  setActiveStep,
  setNewPackage,
}: PixType) => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.foodOption.components.step4.components.pix";
  const newDate = new Date();
  const nextMonthPackage = newDate.getMonth() + 2;

  const [newSearch, setNewSearch] = useState(false);

  const getFoodPackageData = async () => {
    const response = await getFoodPackage({
      params: {
        enrollment: student.enrollment.id.toString(),
      },
    });

    if (response && response.status === HTTP_STATUS.OK) {
      if (
        response.data[0].payment_status ===
        FOOD_PAYMENT_STATUS.PAGAMENTO_CONFIRMADO
      ) {
        setNewPackage(true);
        setActiveStep(4);
      }
    }
  };

  useEffect(() => {
    if (newSearch) {
      getFoodPackageData();
      setNewSearch(false);
    } else {
      setTimeout(() => setNewSearch(true), 20000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newSearch]);

  return (
    <div>
      {paymentData && (
        <PixStyled>
          <div className="countainerPix">
            <div className="qrCodeText">
              <Text
                fontSize="14px"
                fontWeight="400"
                lineHeight="21px"
                className="text-center"
              >
                {t(`${TRANSLATE}.pixTitle`)}
              </Text>
            </div>
            <div
              className="pixCode"
              onClick={() =>
                navigator.clipboard.writeText(`${paymentData.code}`)
              }
            >
              <div>{paymentData.code}</div>
              <div className="icon">
                <img src={fileIcon} height={20} alt="file-icon" />
              </div>
            </div>
            <div className="pixImg">
              <img src={paymentData.pay_ticket} height={175} alt="pix-qrcode" />
            </div>
            <div className="valueTotal">
              <Text fontSize="18px" fontWeight="700" lineHeight="27px">
                {t(`${TRANSLATE}.total`)}
              </Text>
              <Text fontSize="18px" fontWeight="700" lineHeight="27px">
                R${foodPackage.food_package_value_month_after_balance_formatted}
              </Text>
            </div>
            <div className="button">
              <Button
                type="button"
                outline
                width="45%"
                onClick={() => setActiveStep(2)}
              >
                {t(`${TRANSLATE}.buttonBack`)}
              </Button>
            </div>
          </div>
        </PixStyled>
      )}
    </div>
  );
};
