import { ReactNode } from "react";
import styled, { css } from "styled-components";
import * as colors from "styles/colors";
import { pxToRem } from "helpers/style";

interface ContainerProps {
  width?: string;
}

export const Container = styled.div<ContainerProps>`
  width: ${(props) => (props.width ? props.width : "100%")};

  .iconSearch {
    display: flex;
    background: ${(props) => colors.WHITE};
    background-image: url("/icons/search.svg");
    background-size: 1.313rem 1.313rem;
    background-position: 4px;
    background-repeat: no-repeat;

    padding-left: 2rem;
  }
`;

export const Form = styled.div`
  width: 100%;
  appearance: none;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const InputSearch = styled.input`
  width: calc(100% - 2.313rem);
  height: 2.313rem;

  appearance: none;
  background: ${(props) => colors.WHITE};

  border: none;
  outline-style: none;
`;

export const Input = styled.input`
  width: calc(100% - 2.313rem);
  height: 2.313rem;

  appearance: none;

  border: none;
  outline-style: none;
`;

export const Label = styled.label`
  width: 100%;

  text-transform: uppercase;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: ${pxToRem(16)};
`;

export const Error = styled.span`
  font-size: ${pxToRem(11)};
  color: ${colors.NEW_RED};
`;

interface WrapperProps {
  focus: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: row;

  border: ${(props) =>
    props.focus === false
      ? `${pxToRem(2)} solid #999999`
      : `${pxToRem(4)} solid #6D28D9`};
`;

export const ButtonClear = styled.div`
  appearance: none;
  border: none;

  width: 2.313rem;
  height: 2.313rem;

  background-image: url("/icons/clear-input.svg");
  background-repeat: no-repeat;
  background-size: 1.313rem 1.313rem;
  background-position: center center;

  cursor: pointer;
`;
