import {
  Dashboard,
  BookOutlined,
  BuildOutlined,
  EditOutlined,
  FolderSharedOutlined,
  Assessment,
  Edit,
  ExitToApp,
  Home,
  ListAlt,
  Lock,
  Restaurant,
  Search,
  PersonAdd,
  VerifiedUserOutlined,
  SchoolOutlined,
  CalendarTodayOutlined,
  PeopleOutline,
} from "@material-ui/icons";

import { ROLE } from "helpers/constants";
import { RoleInterface } from "interfaces/constants";

export const DRAWER_WIDTH = 200;

export const DRAWER_LIST = [
  {
    text: "Home",
    Icon: Home,
    permissions: [
      ROLE.VENDOR,
      ROLE.VENDOR_MANAGER,
      ROLE.GUARDIAN,
      ROLE.ENROLLMENT_COORDINATOR,
      ROLE.MANAGER,
      ROLE.TEACHER,
      ROLE.CX,
      ROLE.DIRECTOR,
      ROLE.STUDY_ROOM_EDUCATOR,
      ROLE.OPERATIONAL_DIRECTOR,
      ROLE.SECRETARY,
      ROLE.GRADES_MANAGER,
      ROLE.STUDY_ROOM_EDUCATOR,
      ROLE.STUDENT,
      ROLE.SYSTEM_ADMIN,
    ],
    route: "/home",
    isCollapse: false,
    options: [],
  },
  {
    text: "Ferramentas Pedagógicas",
    Icon: BuildOutlined,
    permissions: [
      ROLE.GRADES_MANAGER,
      ROLE.TEACHER,
      ROLE.DIRECTOR,
      ROLE.STUDY_ROOM_EDUCATOR,
      ROLE.ENROLLMENT_COORDINATOR,
      ROLE.SECRETARY,
      ROLE.SYSTEM_ADMIN,
    ],
    route: "/home",
    isCollapse: true,
    open: false,
    options: [
      {
        text: "Aprovação prévia",
        Icon: BookOutlined,
        permissions: [
          ROLE.GRADES_MANAGER,
          ROLE.TEACHER,
          ROLE.DIRECTOR,
          ROLE.STUDY_ROOM_EDUCATOR,
          ROLE.ENROLLMENT_COORDINATOR,
          ROLE.SECRETARY,
          ROLE.SYSTEM_ADMIN,
        ],
        route: "/boletim/",
        isCollapse: false,
        open: false,
        options: [],
      },
      {
        text: "Dashboard - Lançamentos",
        Icon: Dashboard,
        permissions: [
          ROLE.GRADES_MANAGER,
          ROLE.TEACHER,
          ROLE.DIRECTOR,
          ROLE.STUDY_ROOM_EDUCATOR,
          ROLE.ENROLLMENT_COORDINATOR,
          ROLE.SYSTEM_ADMIN,
        ],
        route: "/dashboard",
        isCollapse: false,
        open: false,
        options: [],
      },
      {
        text: "Enturmamento",
        Icon: PersonAdd,
        permissions: [ROLE.GRADES_MANAGER, ROLE.DIRECTOR, ROLE.SYSTEM_ADMIN],
        route: "/enturmamento",
        isCollapse: false,
        open: false,
        options: [],
      },
      {
        text: "Publicação - Boletim",
        Icon: VerifiedUserOutlined,
        permissions: [ROLE.DIRECTOR, ROLE.SYSTEM_ADMIN],
        route: "/publicacao-boletim",
        isCollapse: false,
        open: false,
        options: [],
      },
      {
        text: "Roteiros de Estudos",
        Icon: SchoolOutlined,
        permissions: [
          ROLE.DIRECTOR,
          ROLE.SYSTEM_ADMIN,
          ROLE.SECRETARY,
          ROLE.CX,
        ],
        route: "/roteiros-de-estudo/estudante",
        isCollapse: false,
        open: false,
        options: [],
      },
      {
        text: "Conselho de Classe",
        Icon: PeopleOutline,
        permissions: [ROLE.DIRECTOR, ROLE.SYSTEM_ADMIN],
        route: "/conselho-classe",
        isCollapse: false,
        open: false,
        options: [],
      },
      // {
      //   text: "Calendário Escolar",
      //   Icon: CalendarTodayOutlined,
      //   permissions: [ROLE.GRADES_MANAGER, ROLE.SYSTEM_ADMIN],
      //   route: "/calendario-escolar",
      //   isCollapse: false,
      //   open: false,
      //   options: [],
      // },
    ],
  },
  {
    text: "Boletim",
    Icon: BookOutlined,
    permissions: [],
    route: "/selecao_estudante",
    isCollapse: false,
    open: false,
    options: [],
  },
  {
    text: "Cancelar Pacote de Alimentação",
    Icon: Restaurant,
    permissions: [ROLE.MANAGER, ROLE.CX, ROLE.SYSTEM_ADMIN],
    route: "/alimentacao/cancelar-pacote-alimentacao",
    isCollapse: false,
    options: [],
  },
  {
    text: "Reservar vaga",
    Icon: Edit,
    permissions: [
      ROLE.VENDOR,
      ROLE.VENDOR_MANAGER,
      ROLE.ENROLLMENT_COORDINATOR,
      ROLE.SYSTEM_ADMIN,
    ],
    route: "/responsavel/busca-por-cpf",
    isCollapse: false,
    options: [],
  },
  {
    text: "Buscar responsável / aluno",
    Icon: Search,
    permissions: [
      ROLE.VENDOR,
      ROLE.VENDOR_MANAGER,
      ROLE.ENROLLMENT_COORDINATOR,
      ROLE.SECRETARY,
      ROLE.MANAGER,
      ROLE.SYSTEM_ADMIN,
    ],
    route: "/responsavel/busca",
    isCollapse: false,
    options: [],
  },
  {
    text: "Boletim",
    Icon: ListAlt,
    permissions: [ROLE.STUDENT],
    route: "/responsavel/boletim",
    isCollapse: false,
    options: [],
  },
  // {
  //   text: "Roteiros de Estudo",
  //   Icon: ListAlt,
  //   permissions: [ROLE.GUARDIAN],
  //   route: "/roteiros-de-estudo",
  //   isCollapse: false,
  //   options: [],
  // },
  {
    text: "Diário de classe",
    Icon: ListAlt,
    permissions: [
      ROLE.TEACHER,
      ROLE.STUDY_ROOM_EDUCATOR,
      ROLE.GRADES_MANAGER,
      ROLE.SYSTEM_ADMIN,
    ],
    route: "/diario-de-classe",
    isCollapse: false,
    options: [],
  },
  {
    text: "Consulta - Diário de Classe",
    Icon: Search,
    permissions: [
      ROLE.DIRECTOR,
      ROLE.TEACHER,
      ROLE.GRADES_MANAGER,
      ROLE.SYSTEM_ADMIN,
    ],
    route: "/consulta-diario-de-classe",
    isCollapse: false,
    options: [],
  },
  {
    text: "Atribuição de Turmas",
    Icon: FolderSharedOutlined,
    permissions: [ROLE.DIRECTOR, ROLE.GRADES_MANAGER, ROLE.SYSTEM_ADMIN],
    route: "/gerenciar/turmas",
    isCollapse: false,
    options: [],
  },
  {
    text: "Lançamentos",
    Icon: EditOutlined,
    permissions: [
      ROLE.TEACHER,
      ROLE.STUDY_ROOM_EDUCATOR,
      ROLE.GRADES_MANAGER,
      ROLE.SYSTEM_ADMIN,
    ],
    route: "/lancamento/componentes",
    isCollapse: false,
    options: [],
  },
  {
    text: "Lançar consumo de alimentação para cobrança",
    Icon: Restaurant,
    permissions: [
      ROLE.STUDY_ROOM_EDUCATOR,
      ROLE.SECRETARY,
      ROLE.STUDY_ROOM_EDUCATOR,
      ROLE.SYSTEM_ADMIN,
    ],
    route: "/registro-de-alimentacao",
    isCollapse: false,
    options: [],
  },
  {
    text: "Relatório de cobrança pós-paga",
    Icon: Assessment,
    permissions: [
      ROLE.STUDY_ROOM_EDUCATOR,
      ROLE.SECRETARY,
      ROLE.STUDY_ROOM_EDUCATOR,
      ROLE.SYSTEM_ADMIN,
    ],
    route: "/relatorio-de-alimentacao",
    isCollapse: false,
    options: [],
  },
  {
    text: "Buscar vaga",
    Icon: Search,
    permissions: [
      ROLE.VENDOR,
      ROLE.VENDOR_MANAGER,
      ROLE.ENROLLMENT_COORDINATOR,
      ROLE.SYSTEM_ADMIN,
    ],
    route: "/buscar-vagas",
    isCollapse: false,
    options: [],
  },
  {
    text: "Consultar Documentos",
    Icon: Search,
    permissions: [
      ROLE.SECRETARY,
      ROLE.ENROLLMENT_COORDINATOR,
      ROLE.SYSTEM_ADMIN,
    ],
    route: "/responsavel/Consultar/documentos",
    isCollapse: false,
    options: [],
  },
  {
    text: "Mudar Senha",
    Icon: Lock,
    permissions: [
      ROLE.VENDOR,
      ROLE.VENDOR_MANAGER,
      // ROLE.GUARDIAN,
      ROLE.ENROLLMENT_COORDINATOR,
      ROLE.MANAGER,
      ROLE.TEACHER,
      ROLE.CX,
      ROLE.DIRECTOR,
      ROLE.STUDY_ROOM_EDUCATOR,
      ROLE.OPERATIONAL_DIRECTOR,
      ROLE.SECRETARY,
      ROLE.GRADES_MANAGER,
      ROLE.SYSTEM_ADMIN,
    ],
    route: "/alterar-senha",
    isCollapse: false,
    options: [],
  },
  {
    text: "Consultar alimentação",
    Icon: Search,
    permissions: [
      ROLE.OPERATIONAL_DIRECTOR,
      ROLE.MANAGER,
      ROLE.STUDY_ROOM_EDUCATOR,
      ROLE.CX,
      ROLE.SYSTEM_ADMIN,
    ],
    route: "/alimentacao/consultar-alimentacao",
    isCollapse: false,
    open: false,
    options: [],
  },
  {
    text: "Gerenciar matrículas",
    Icon: Dashboard,
    permissions: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER],
    route: "/gerenciar/matriculas",
    isCollapse: false,
    open: false,
    options: [],
  },
  {
    text: "Lista de espera",
    Icon: Search,
    permissions: [ROLE.SYSTEM_ADMIN],
    route: "/lista-espera",
    isCollapse: false,
    open: false,
    options: [],
  },
  {
    text: "Lança crédito de alimentação",
    Icon: Restaurant,
    permissions: [ROLE.SYSTEM_ADMIN],
    route: "/alimentacao/lancar-credito-alimentacao",
    isCollapse: false,
    open: false,
    options: [],
  },
  {
    text: "Cadastrar plano de pagamento",
    Icon: EditOutlined,
    permissions: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER],
    route: "/cadastro-plano",
    isCollapse: false,
    open: false,
    options: [],
  },
  {
    text: "Consultar plano de pagamento",
    Icon: Edit,
    permissions: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER],
    route: "/consulta-plano-pagamento",
    isCollapse: false,
    open: false,
    options: [],
  },
  {
    text: "Export Totvs",
    Icon: ListAlt,
    permissions: [ROLE.SYSTEM_ADMIN, ROLE.MANAGER],
    route: "/exportar-totvs",
    isCollapse: false,
    open: false,
    options: [],
  },
  {
    text: "Arquivo para crachá",
    Icon: Search,
    permissions: [ROLE.SYSTEM_ADMIN],
    route: "/gerar-arquivo-para-cracha",
    isCollapse: false,
    open: false,
    options: [],
  },
  {
    text: "Cadastro dias úteis",
    Icon: Dashboard,
    permissions: [ROLE.SYSTEM_ADMIN],
    route: "/cadastro-dias-uteis",
    isCollapse: false,
    open: false,
    options: [],
  },
  {
    text: "Vigência pacote de alimentação",
    Icon: Restaurant,
    permissions: [ROLE.SYSTEM_ADMIN],
    route: "/cadastro-vigencia-pacote-de-alimentacao",
    isCollapse: false,
    open: false,
    options: [],
  },
  {
    text: "Autorização de saída",
    Icon: ListAlt,
    permissions: [ROLE.SYSTEM_ADMIN, ROLE.DIRECTOR],
    route: "/autorizacao-de-saida",
    isCollapse: false,
    open: false,
    options: [],
  },
  {
    text: "Histórico de boletins",
    Icon: BookOutlined,
    permissions: [ROLE.SYSTEM_ADMIN],
    route: "/historico-dos-boletins",
    isCollapse: false,
    open: false,
    options: [],
  },
  {
    text: "Período de matrícula e rematrícula",
    Icon: Dashboard,
    permissions: [
      ROLE.MANAGER,
      ROLE.SYSTEM_ADMIN,
      ROLE.OPERATIONAL_DIRECTOR,
      ROLE.ENROLLMENT_COORDINATOR,
    ],
    route: "/cadastrar-periodo-matricula-rematricula",
    isCollapse: false,
    open: false,
    options: [],
  },
  {
    text: "Gerenciar - Vagas",
    Icon: Dashboard,
    permissions: [
      ROLE.VENDOR,
      ROLE.VENDOR_MANAGER,
      ROLE.MANAGER,
      ROLE.ENROLLMENT_COORDINATOR,
      ROLE.OPERATIONAL_DIRECTOR,
      ROLE.CX,
      ROLE.SECRETARY,
      ROLE.SYSTEM_ADMIN,
    ],
    route: "/gerenciar-vagas",
    isCollapse: false,
    open: false,
    options: [],
  },
  {
    text: "Logout",
    Icon: ExitToApp,
    permissions: [
      ROLE.STUDENT,
      ROLE.VENDOR,
      ROLE.VENDOR_MANAGER,
      ROLE.GUARDIAN,
      ROLE.ENROLLMENT_COORDINATOR,
      ROLE.MANAGER,
      ROLE.TEACHER,
      ROLE.CX,
      ROLE.DIRECTOR,
      ROLE.STUDY_ROOM_EDUCATOR,
      ROLE.OPERATIONAL_DIRECTOR,
      ROLE.SECRETARY,
      ROLE.GRADES_MANAGER,
      ROLE.SYSTEM_ADMIN,
    ],
    route: "/logout",
    isCollapse: false,
    open: false,
    options: [],
  },
];

export const anyDrawerItem = (role: RoleInterface) => {
  return (
    role &&
    role.id &&
    DRAWER_LIST.find((item) => item.permissions.includes(role.id))
  );
};
