import { Financeiro, UpsideDown } from "./api";

interface IGetBoletoApiTokenResponse {
  exp: string;
  key: string;
  username: string;
}

export const getBoletoApiToken = async () => {
  try {
    const response = await UpsideDown.post<IGetBoletoApiTokenResponse>(
      `/login`
    );

    if (!response) {
      throw new Error();
    }

    return response.data.key;
  } catch (error) {
    throw new Error(`Falha no serviço: getBoletoApiToken`, error);
  }
};

interface IGetBoletosRequest {
  codcoligada: number;
  codfilial: number;
  codtipocurso: number;
  email: string;
  data: {
    codcoligada: number;
    codperlet: number;
    ra: string;
  };
}

export interface IGetBoletosResponse {
  idboleto: number;
  situacao: string;
  dataCompetencia: string;
  descricaoServico: string;
  vencimento: string;
  valor: number;
  databaixa: string;
  ipte: string;
  codservico: number;
  codcoligada: number;
  filial: number;
  codNivelEnsino: number;
}
export const getBoletos = async (
  studentInformation: IGetBoletosRequest,
  apiKey: string
) => {
  try {
    const response = await UpsideDown.post(
      `/Financeiro/v2/Boletos`,
      studentInformation,
      {
        headers: { Authorization: `${apiKey}` },
      }
    );

    if (!response) {
      throw new Error();
    }

    return response;
  } catch (error) {
    throw new Error(`Falha no serviço: getBoletoApiToken`, error);
  }
};

interface IDownloadBoletoRequest {
  boleto_id: number;
  cod_coligada: number;
  cod_filial: number;
  cod_tipo_curso: number;
}
export const downloadBoleto = async (payload: IDownloadBoletoRequest) => {
  try {
    const response = await Financeiro.post("cob/", payload, {
      maxBodyLength: Infinity,
      responseType: "blob",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response) {
      throw new Error();
    }

    return response;
  } catch (error) {
    throw new Error(`Falha no serviço: downloadBoleto`, error);
  }
};
