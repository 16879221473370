import { AlertPaymentPlanDetails } from "../Alert";
import { IPaymentDetails } from "../interface";
import { Container } from "./style";

export const PaymentDetails = ({
  educationalService,
  scholarMaterial,
  reservedSchoolPlace,
  total,
  isPaid,
}: IPaymentDetails) => {
  return (
    <Container>
      <div className="topInfo">
        <h3>Valor anual</h3>
      </div>
      <div className="content">
        <div className="paymentDetails">
          <div className="nameInfos">
            <p>Serviço educacional: </p>
            <p>Material didático: </p>
            <p>Reserva de vaga {isPaid && "(PAGO)"}:</p>
          </div>
          <div className="valuesInfos">
            <p>R${educationalService}</p>
            <p>R${scholarMaterial} </p>
            <p>
              {isPaid && "-"}R${reservedSchoolPlace}
            </p>
          </div>
        </div>
        <div className="total">
          <p>Total a pagar:</p>
          <p>R${total}</p>
        </div>
        <AlertPaymentPlanDetails />
      </div>
    </Container>
  );
};
