import { EventType } from "interfaces/constants";
import { InputHTMLAttributes } from "react";
import { Field, FieldRenderProps } from "react-final-form";
import { IInputCheckbox } from "./interface";
import * as Style from "./style";

// Formularios dentro do react Final form
type Props = FieldRenderProps<boolean, any>;

interface CheckboxInterface extends InputHTMLAttributes<HTMLInputElement> {
  value: boolean | any;
  name: string;
  required?: boolean;
  onChange?: (e: EventType | "") => void;
  disabled?: boolean;
}

export const Checkbox = ({
  input: { value, ...input },
  disabled,
  ...rest
}: Props) => {
  return (
    <Style.CheckboxLabelStyled>
      <div className="container">
        <input
          className="checkboxBrowser"
          {...input}
          type="checkbox"
          checked={value}
          {...rest}
        />
        <div className="check"></div>
      </div>
    </Style.CheckboxLabelStyled>
  );
};

// Fora do react Final Form
export const CheckboxInput = ({
  value,
  name,
  required,
  onChange,
  disabled,
  checked,
  defaultChecked,
}: CheckboxInterface) => {
  return (
    <Style.CheckboxLabelStyled disabled={disabled}>
      <label className="container">
        <input
          className="checkboxBrowser"
          disabled={disabled}
          type="checkbox"
          id={value}
          onChange={onChange}
          value={value}
          name={name}
          required={required}
          checked={checked}
          defaultChecked={defaultChecked}
        />
        <div className="check"></div>
      </label>
    </Style.CheckboxLabelStyled>
  );
};

export const InputCheckbox = ({
  name,
  value,
  onClick,
  hasPlan,
  ...rest
}: IInputCheckbox) => {
  return (
    <Style.Container hasPlan={hasPlan}>
      <Field
        id={name}
        component="input"
        name={name}
        type="checkbox"
        value={value}
        onClick={onClick}
        disabled={hasPlan}
        {...rest}
      />
    </Style.Container>
  );
};
