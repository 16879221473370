import styled from "styled-components";
import { BLUE } from "styles/colors";

export const ConsultaPlanosContainer = styled.div`
  div.title {
    font-size: 24px;
    font-weight: bold;
    color: ${BLUE};
  }

  div.card {
    padding: 0.7em;
    margin: 0.5em;
    border-style: solid;
    border-width: 1px;
    border-radius: 15px;
    border-color: #c4c4c4;
    box-shadow: 0px 4px 4px 0px #00000040;
  }
`;
