import styled from "styled-components";
import * as colors from "styles/colors";
import * as Props from "./interface";

export const ButtonStyled = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;

  :disabled {
    cursor: not-allowed;
  }
`;

export const FloatingMenuStyled = styled.div<Props.FloatingMenuStyledStyledInterface>`
  max-height: 100vh;
  position: absolute;

  ${(props) => props.left && `left: ${props.left};`}
  ${(props) => props.right && `right: ${props.right};`}
  ${(props) => props.marginLeft && `margin-left: ${props.marginLeft};`}
  

  div {
    ${(props) => props.top && `margin-top: ${props.top};`}

    padding: 0.5em 0;
    width: ${(props) => (props.width ? props.width : "190px")};

    background: ${colors.WHITE};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 12px;

    p {
      cursor: pointer;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;

      padding: 0.5em 1em;

      &:hover {
        background-color: ${colors.NEW_GREEN_TWO};
        font-weight: 700;
        line-height: 24px;
      }
    }
  }
`;
