import styled, { css } from "styled-components";
import { ISelectStyle } from "./interface";

export const SelectContainer = styled.div<ISelectStyle>`
  width: ${({ width }) => `${width}px`};

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  gap: ${({ theme }) => theme.space[1]} 0;

  label {
    display: flex;
    gap: 0 ${({ theme }) => theme.space[1]};

    float: left;
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    font-size: ${({ theme }) => theme.fontSizes.web.sm};
    line-height: ${({ theme }) => theme.lineHeights.normal};
    color: ${({ theme }) => theme.colors.greyScale95};

    ${({ disabled }) =>
      disabled &&
      css`
        color: ${({ theme }) => theme.colors.greyScale40};
      `}

    margin: 0;
    margin-bottom: ${({ theme }) => theme.space[1]};

    text-transform: uppercase;

    img {
      cursor: pointer;
    }
  }
  div {
    display: flex;
    flex-direction: column;

    select {
      background: ${({ theme }) => theme.colors.white};
      border: 2px solid ${({ theme }) => theme.colors.greyScale40};

      color: ${({ theme }) => theme.colors.greyScale40};
      height: 37px; // TODO: nao tem na lib
      padding-left: ${({ theme }) => theme.space[2]};
      font-size: ${({ theme }) => theme.fontSizes.web.sm};

      cursor: pointer;

      &:disabled {
        color: ${({ theme }) => theme.colors.greyScale40};
        background-color: ${({ theme }) => theme.colors.greyScale10};
        border-color: ${({ theme }) => theme.colors.greyScale10};
      }

      ${({ value }) =>
        value &&
        css`
          color: ${({ theme }) => theme.colors.greyScale95};
        `}
    }

    span {
      margin: 0;
      font-size: ${({ theme }) => theme.fontSizes.web.sm};
      font-weight: ${({ theme }) => theme.fontWeights.regular};
      color: ${({ theme }) => theme.colors.statusErrorDefault};
    }
  }

  @media (max-width: 360px) {
    width: 100%;
  }
`;
