import { Button } from "components";
import { ModalDiv } from "components/UseTerms/styles";
import { isGuardian } from "helpers/constants";
import { Storage } from "Storage";

interface ModalTotvsSentInterface {
  setToggleModal: (toggleModal: boolean) => void;
}

export const ModalTotvsSent = ({ setToggleModal }: ModalTotvsSentInterface) => {
  const role = Storage.props.role.get();

  return (
    <div className="container">
      <ModalDiv>
        <div>
          <div className="text-center">
            <strong className="title">Atenção</strong>
          </div>
          <div>
            {isGuardian(role) && (
              <p className="text-center">
                Para alteração de dados entre em contato com a equipe da Escola
                Mais pelo número (11) 5555-3725
              </p>
            )}
            {!isGuardian(role) && (
              <p className="text-center">
                Essa matrícula já foi enviada para Totvs. Necessário abrir um
                chamado para alteração de dados cadastrais
              </p>
            )}
          </div>
          <Button onClick={() => setToggleModal(false)} type="button">
            Voltar
          </Button>
        </div>
      </ModalDiv>
    </div>
  );
};

export default ModalTotvsSent;
