import React, { InputHTMLAttributes, useMemo, useState } from "react";

import {
  InputContainer,
  InputElement,
  IconContainer,
  IconImage,
  InputLabel,
} from "./styles";

import { EyeHiddenIcon, EyeShowIcon } from "static/img";
import { UseFormRegister } from "react-hook-form";
import { LoginFormData } from "../Login.helper";

interface LoginFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  iconImage: string;
  inputLabel: string;
  register: UseFormRegister<LoginFormData>;
}

export const LoginField: React.FC<LoginFieldProps> = ({
  iconImage,
  inputLabel,
  register,
  type,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const registerType = useMemo(() => {
    if (type === "password") {
      return "password";
    }

    return "email";
  }, [type]);

  return (
    <div>
      <InputLabel>{inputLabel}</InputLabel>
      <InputContainer>
        <IconContainer>
          <IconImage src={iconImage} alt="Input Icon" />
        </IconContainer>
        <InputElement
          type={showPassword ? "text" : type}
          {...props}
          {...register(registerType)}
        />
        {type === "password" && (
          <IconContainer>
            <IconImage
              hasAction
              src={showPassword ? EyeHiddenIcon : EyeShowIcon}
              onClick={() => setShowPassword((oldValue) => !oldValue)}
              alt={`${showPassword ? "Esconder senha" : "Mostrar senha"}`}
              title={`${showPassword ? "Esconder senha" : "Mostrar senha"}`}
            />
          </IconContainer>
        )}
      </InputContainer>
    </div>
  );
};
