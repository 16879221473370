import * as Style from "./style";
import * as Props from "./type";
import Icon from "@mdi/react";

export const Button = ({
  children,
  outline,
  width,
  height,
  padding,
  margin,
  red,
  ...rest
}: Props.ButtonProps) => {
  return (
    <Style.ButtonStyled
      outline={outline}
      width={width}
      margin={margin}
      height={height}
      padding={padding}
      red={red} //o botao da lib nao possui essa cor
      {...rest}
    >
      {children}
    </Style.ButtonStyled>
  );
};

export const BorderlessButton = ({
  children,
  fontSize,
  fontWeight,
  ...rest
}: Props.BorderlessButtonProps) => {
  return (
    <Style.BorderlessButtonStyled
      fontSize={fontSize}
      fontWeight={fontWeight}
      {...rest}
    >
      {children}
    </Style.BorderlessButtonStyled>
  );
};

// botao com a lib: materialdesignicons
export const BorderlessButtonIcon = ({
  children,
  fontSize,
  fontWeight,
  icon,
  width,
  ...rest
}: Props.BorderlessButtonIconProps) => {
  return (
    <Style.BorderlessButtonIconStyled
      fontSize={fontSize}
      fontWeight={fontWeight}
      width={width}
      {...rest}
    >
      <div className="iconStyle">
        <Icon path={icon} />
      </div>
      {children}
    </Style.BorderlessButtonIconStyled>
  );
};

export const ButtonFilterBulletin = ({
  children,
  onClick,
  options,
  active,
  quarter,
  note,
}: Props.ButtonFilterBulletinProps) => {
  return (
    <Style.ButtonFilterBulletin
      active={active}
      options={options}
      onClick={() => onClick && onClick()}
      quarter={quarter}
      note={note}
    >
      {children}
    </Style.ButtonFilterBulletin>
  );
};

export const ButtonFilterDashboard = ({
  children,
  onClick,
  options,
  active,
  quarter,
}: Props.ButtonFilterBulletinProps) => {
  return (
    <Style.ButtonFilterDashboard
      active={active}
      options={options}
      onClick={() => onClick && onClick()}
      quarter={quarter}
    >
      {children}
    </Style.ButtonFilterDashboard>
  );
};

export const ButtonModal = ({ children, onClick }: Props.ButtonModalProps) => {
  return (
    <>
      <Style.ButtonModalStyled onClick={() => onClick()}>
        {children}
      </Style.ButtonModalStyled>
    </>
  );
};

export const ButtonShare = ({ children, onClick }: Props.ButtonShareProps) => {
  return (
    <>
      <Style.ButtonShare>{children}</Style.ButtonShare>
    </>
  );
};

export const ButtonIconFontNormal = ({
  children,
  fontSize,
  fontWeight,
  icon,
  width,
  height,
  uppercase,
  ...rest
}: Props.ButtonIconFontNormalProps) => {
  return (
    <Style.ButtonIconFontNormalStyled
      fontSize={fontSize}
      fontWeight={fontWeight}
      width={width}
      height={height}
      uppercase={uppercase}
      {...rest}
    >
      {icon && (
        <div className="icon">
          <img height={height} src={icon} alt="icon" />
        </div>
      )}
      {children}
    </Style.ButtonIconFontNormalStyled>
  );
};
