import {
  IGetClassCouncilStudentsResponse,
  getAllQuarters,
  getClassCouncilStudents,
  saveStudentsApprovedStatuses,
} from "services/grades.service";
import { toastError, toastSuccess } from "components/Toast";
import { ChangeEvent, useEffect, useState } from "react";
import { authUserAtom } from "jotai/authUser";
import { useAtomValue } from "jotai";

export const enum StudentStatusEnum {
  APROVADO = "aprovado",
  RETIDO = "retido",
}
export interface IStudentStatus {
  label: string;
  value: StudentStatusEnum;
}
export interface IChangeStudentApprovedStatus {
  student: IGetClassCouncilStudentsResponse;
  status: IStudentStatus;
}
interface IModifiedStudentsStatuses {
  matricula_id: string;
  aprovado: boolean;
}

export const useClassCouncil = () => {
  const authUser = useAtomValue(authUserAtom);
  const [isloadingPage, setIsLoadingPage] = useState<boolean>(false);
  const [isloadingStudents, setIsloadingStudents] = useState<boolean>(false);

  const [years, setYears] = useState<Number[] | undefined>(undefined);
  const [selectedYear, setSelectedYear] = useState<string>("");

  const [students, setStudents] = useState<
    IGetClassCouncilStudentsResponse[] | undefined
  >(undefined);
  const [modifiedStudentsStatuses, setModifiedStudentsStatuses] = useState<
    IModifiedStudentsStatuses[]
  >([]);

  const handleSelectYear = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(e.target.value);
  };

  const fetchQuarters = async () => {
    setIsLoadingPage(true);
    try {
      const quarters = await getAllQuarters();
      if (quarters?.data) {
        const yearsList = [
          ...new Set(quarters.data.map((quarter) => quarter.year)),
        ];
        const newerYearArrayItem = String(yearsList[yearsList.length - 1]);
        setYears(yearsList.reverse());
        setSelectedYear(newerYearArrayItem);
      }
    } catch (error) {
      toastError("Erro ao buscar anos");
    } finally {
      setIsLoadingPage(false);
    }
  };

  const fetchStudents = async () => {
    setIsloadingStudents(true);
    try {
      if (authUser.grade_unit_id && selectedYear) {
        const payload = {
          year: selectedYear,
          unidade_id: authUser.grade_unit_id,
        };

        const students = await getClassCouncilStudents(payload);
        if (students?.data) {
          setStudents(students.data);
        }
      }
    } catch (error) {
      toastError("Erro ao buscar estudantes");
    } finally {
      setIsloadingStudents(false);
    }
  };

  const onChangeStudentApprovedStatus = (
    student: IChangeStudentApprovedStatus
  ) => {
    const isStudentApproved =
      student.status.value === StudentStatusEnum.APROVADO ?? false;

    const newStudentStatus = {
      matricula_id: student.student.matricula_id,
      aprovado: isStudentApproved,
    };

    const previousModifiedStudentsStatuses = modifiedStudentsStatuses.filter(
      (status) => status.matricula_id !== student.student.matricula_id
    );

    setModifiedStudentsStatuses([
      ...previousModifiedStudentsStatuses,
      newStudentStatus,
    ]);
  };

  const onSaveStudentsApprovedStatuses = async () => {
    if (modifiedStudentsStatuses.length) {
      try {
        const savedStudentsApprovedStatuses =
          await saveStudentsApprovedStatuses(modifiedStudentsStatuses);
        if (savedStudentsApprovedStatuses.status === 200) {
          toastSuccess("Dados salvos com sucesso");
        }
      } catch (error) {
        toastError("Erro ao salvar dados dos estudantes");
      }
    }
  };

  useEffect(() => {
    fetchQuarters();
  }, []);

  useEffect(() => {
    fetchStudents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear, authUser]);

  return {
    isloadingPage,
    isloadingStudents,
    years,
    handleSelectYear,
    selectedYear,
    students,
    onChangeStudentApprovedStatus,
    modifiedStudentsStatuses,
    onSaveStudentsApprovedStatuses,
  };
};
