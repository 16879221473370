import {
  ClasseListProps,
  DisciplinaProps,
  MultiNotasProps,
  StateType,
} from "pages/AP/Lancamentos/Lancamento/types";
import {
  getAssignClass,
  AssignClassResponse,
  getStudentsForRelease,
  StudentsForReleaseResponse,
  lancarMultiNota,
  QuartersResponseInterface,
  getQuarters,
} from "services/grades.service";
import { Storage } from "Storage";
import { useAtomValue } from "jotai";
import { authUserAtom } from "jotai/authUser";
import { toastSuccess, toastError, toastWarning } from "components/Toast";
import { useEffect, useCallback, useState } from "react";
import HTTP_STATUS from "http-status-codes";
import {
  isDirector,
  isGradesManager,
  isStudyRoomEducator,
  isTeacher,
} from "helpers/constants";
import { SortDisciplinas, SortTurmas } from "./rules";
import { useLocation } from "react-router-dom";
import { removeDiscipline } from "./helpers";

function uniqBy<T>(a: Array<any>, key: (a: any) => number | string): T[] {
  let seen: any = {};
  return a.filter(function (item) {
    let k = key(item);
    return seen.hasOwnProperty(k) ? false : (seen[k] = true);
  });
}

export const useReleases = () => {
  const role = Storage.props.role.get();
  const authUser = useAtomValue(authUserAtom);
  const location = useLocation<StateType>();

  const [comment, setComment] = useState({});
  const [sendListNotasConhecimento, setSendListNotasConhecimento] = useState<
    MultiNotasProps[]
  >([]);
  const [studentsListModal, setStudentsListModal] = useState<
    StudentsForReleaseResponse | undefined
  >();

  const [loading, setLoading] = useState(false);
  const [loadingStudents, setLoadingStudents] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [turmas, setTurmas] = useState<AssignClassResponse[]>([]);
  const [disciplinas, setDisciplinas] = useState<AssignClassResponse[]>([]);
  const [studentsList, setStudentsList] =
    useState<StudentsForReleaseResponse[]>();
  const [selectedSubject, setSelectedSubject] = useState<number>(0);
  const [selectedGradeGroup, setSelectedGradeGroup] = useState<number>(0);
  //Valores dos Inputs
  const [lancamentoValue, setLancamentoValue] = useState({});
  const [substitutiva, setSubstitutiva] = useState({});
  // Mensagens de Retorno
  const [returnMessage, setReturnMessage] = useState("");
  const [quarters, setQuarters] = useState<Array<QuartersResponseInterface>>();
  const [selectedQuarter, setSelectedQuarter] = useState<number>(0);
  const [studentIndice, setStudentIndicer] = useState<number>(0);
  const [classList, setClassList] = useState<ClasseListProps[]>([]);
  const [filterDisciplina, setFilterDisciplina] = useState<DisciplinaProps[]>();
  const [check, setCheck] = useState<boolean[]>([]);
  const [sendListNotas, setSendListNotas] = useState<MultiNotasProps[]>([]);
  const [sendApiNotas, setSendApiNotas] = useState<MultiNotasProps[]>([]);

  const fetchPageData = async () => {
    setLoading(true);
    const responseClasses = await getAssignClass({
      unit: authUser?.grade_unit_id,
      user_id: authUser?.id,
    });
    if (responseClasses && responseClasses?.status === HTTP_STATUS.OK) {
      setDisciplinas(
        uniqBy<AssignClassResponse>(
          responseClasses?.data,
          (a: AssignClassResponse) => a.subject
        )
      );

      if (
        String(location?.state?.evaluation_type.name) === "Mentoria" ||
        String(location?.state?.evaluation_type.name) === "Estudo" ||
        String(location?.state?.evaluation_type.name) ===
          "Mentoria e Carreira" ||
        isDirector(role) ||
        isGradesManager(role)
      ) {
        setTurmas(
          uniqBy<AssignClassResponse>(
            responseClasses?.data,
            (a: AssignClassResponse) => a.gradegroup
          )
        );
      } else {
        const filterTest: any[] = [];
        responseClasses?.data.map(
          (item: any) => item.gradegroup && filterTest.push(item)
        );
        setTurmas([...filterTest]);
      }

      const responseQuarters = await getQuarters();
      if (responseQuarters && responseQuarters?.status === HTTP_STATUS.OK) {
        setQuarters(responseQuarters.data);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchStudents = async () => {
    if (selectedGradeGroup && selectedQuarter) {
      setLoadingStudents(true);
      setStudentsList(undefined);
      setSubstitutiva({});
      setLancamentoValue({});
      const responseStudents = await getStudentsForRelease({
        type: location?.state?.evaluation_type.name,
        quarter: selectedQuarter,
        gradegroup: selectedGradeGroup,
        unit: authUser?.grade_unit_id,
        subject: selectedSubject ? selectedSubject : undefined,
      });

      if (responseStudents) {
        if (responseStudents?.status === HTTP_STATUS.OK) {
          setStudentsList(responseStudents.data);
          setLoadingStudents(false);
        }

        if (responseStudents?.status === HTTP_STATUS.PARTIAL_CONTENT) {
          setStudentsList([]);
          setReturnMessage(responseStudents?.data);
          setLoadingStudents(false);
        }
      } else {
        toastError("Ocorreu um erro ao carregar a lista dos estudantes.");
        setLoadingStudents(false);
      }
    } else {
      toastWarning(
        "Você precisa selecionar uma turma e informar o trimestre para visualizar os estudantes e realizar o lançamento."
      );
    }
  };

  useEffect(
    () => {
      const listClass: ClasseListProps[] = [];
      const listCheck: boolean[] = [];
      const listDisciplinas: DisciplinaProps[] = [];
      const listNotas: MultiNotasProps[] = [];

      disciplinas.map(
        (item: AssignClassResponse) =>
          item &&
          removeDiscipline(item.subject) &&
          listDisciplinas.push({
            subject_name: item?.subject_name,
            subject: item?.subject,
          })
      );
      listDisciplinas.sort(SortDisciplinas);
      setFilterDisciplina(listDisciplinas);

      if (
        // eslint-disable-next-line no-constant-condition
        location?.state?.evaluation_type.name ===
          "Avaliação de Habilidades e Atitudes" ||
        location?.state?.evaluation_type.name ===
          "Skills and Attitudes Assessment"
      ) {
        turmas.forEach((item) => {
          if (item.subject && item.educational_level_name === "Fundamental 1") {
            listClass.push({
              value: `${item.educational_level_name} - ${item.grade}º${item.letter}`,
              gradegroup: item.gradegroup,
            });
          }
        });
        const uniqueIds: ClasseListProps[] = [];
        const unique = listClass.filter((element: any) => {
          const isDuplicate = uniqueIds.includes(element.value);
          if (!isDuplicate) {
            uniqueIds.push(element.value);
            return true;
          }
          return false;
        });

        setClassList([...unique]);
      }

      if (
        String(location?.state?.evaluation_type.name) === "Mentoria" ||
        String(location?.state?.evaluation_type.name) === "Estudo" ||
        String(location?.state?.evaluation_type.name) === "Mentoria e Carreira"
      ) {
        turmas.forEach((item) => {
          if (item.subject) {
            listClass.push({
              value: `${item.educational_level_name} - ${item.grade}º${item.letter}`,
              gradegroup: item.gradegroup,
            });
          }
        });
        listClass.sort(SortTurmas);

        setClassList(listClass);
      }

      if (selectedSubject && (isStudyRoomEducator(role) || isTeacher(role))) {
        turmas.forEach((item, index) => {
          if (item.subject === selectedSubject) {
            listClass.push({
              value: `${item.educational_level_name} - ${item.grade}º${item.letter}`,
              gradegroup: item.gradegroup,
            });
          }
        });
        listClass.sort(SortTurmas);

        setClassList(listClass);

        if (listCheck.length === 0) {
          studentsList?.map(
            (item) => item && listCheck.push(item?.substitutive)
          );
          setCheck(listCheck);
        }
      } else if (isDirector(role) || isGradesManager(role)) {
        turmas.map(
          (item) =>
            item.subject &&
            listClass.push({
              value: `${item.educational_level_name} - ${item.grade}º${item.letter}`,
              gradegroup: item.gradegroup,
            })
        );

        const uniqueIds: ClasseListProps[] = [];

        const unique = listClass.filter((element: any) => {
          const isDuplicate = uniqueIds.includes(element.value);

          if (!isDuplicate) {
            uniqueIds.push(element.value);

            return true;
          }

          return false;
        });
        unique.sort(SortTurmas);

        setClassList(unique);

        if (listCheck.length === 0) {
          studentsList?.map(
            (item) => item && listCheck.push(item?.substitutive)
          );
          setCheck(listCheck);
        }
      }

      if (studentsList) {
        studentsList.map(
          (item: StudentsForReleaseResponse) =>
            item &&
            listNotas.push({
              enrollment: item.enrollment,
              subject: item.subject,
              gradegroup: item.gradegroup,
              quarter: item.quarter,
              user_id: authUser.id,
              user_name: authUser.name,
              type: location.state.evaluation_type.name,
              value: item.value,
              deactivate_previous: false,
              comment: comment[item.enrollment as keyof typeof comment]
                ? comment[item.enrollment as keyof typeof comment]
                : item.comment!,
              substitutive: substitutiva[
                item.enrollment as keyof typeof substitutiva
              ]
                ? substitutiva[item.enrollment as keyof typeof substitutiva]
                : item.substitutive!,
            })
        );
        setSendListNotas([...listNotas]);
        setSendListNotasConhecimento([...listNotas]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedSubject,
      turmas,
      substitutiva,
      lancamentoValue,
      comment,
      setSendListNotasConhecimento,
    ]
  );

  useEffect(() => {
    if (studentsList) {
      const info = new Map();
      studentsList.map(
        (students, index) =>
          students &&
          info.set(
            `${studentsList[index]?.enrollment}`,
            `${studentsList[index]?.comment}`
          )
      );
      setComment(info);
    }
  }, [setComment, studentsList]);

  const handleChecked = useCallback(
    async (value: boolean, indexInput: number) => {
      const listChecked = [...check];
      listChecked[indexInput] = value;
      setCheck(listChecked);

      const list = [...sendListNotas];
      list.map((item, index) =>
        index === indexInput
          ? (item.substitutive = listChecked[indexInput])
          : item.substitutive
      );
      setSendApiNotas([...list]);
    },
    [check, sendListNotas]
  );

  const reload = () => window.location.reload();

  const handleSubmit = useCallback(async () => {
    const response = await lancarMultiNota([...sendApiNotas]);

    if (response?.status === HTTP_STATUS.OK) {
      toastSuccess("Notas enviadas com sucesso");
      setTimeout(reload, 3000);
    } else {
      toastError("Erro ao enviar as notas");
    }
  }, [sendApiNotas]);

  const handledUpdadeListNotas = useCallback(
    (enrollment, value, indice) => {
      if (studentsList) {
        const list: MultiNotasProps[] = [];
        sendListNotasConhecimento.forEach((item) => {
          if (item) {
            const newValue = String(item.value).replace(",", ".");
            const numberValue = parseFloat(newValue);
            list.push({
              enrollment: item.enrollment,
              subject: item.subject,
              value: numberValue,
              gradegroup: item.gradegroup,
              quarter: item.quarter,
              user_id: item.user_id,
              type: item.type,
              user_name: item.user_name,
              summative_type: item.summative_type,
              substitutive: item.substitutive,
              comment: item.comment,
              deactivate_previous: false,
            });
          }
        });

        list[indice].value = value;
        sendListNotas[indice].value = value;
        setSendApiNotas([...list]);
      }
    },
    [sendListNotas, sendListNotasConhecimento, studentsList]
  );

  const handleStudentModal = useCallback(
    (student, value, substitutive) => {
      const studentModal = student;
      studentModal.value = value;
      studentModal.substitutive = substitutive;
      setStudentsListModal(studentModal);
    },
    [setStudentsListModal]
  );

  const verifyObservation = (value: number) => {
    if (value) {
      const info = classList.filter((item) => item.gradegroup === value);

      if (
        info[0].value.startsWith("Fundamental 1") &&
        (String(location?.state?.evaluation_type.name) ===
          "Avaliação de Conhecimentos" ||
          String(location?.state?.evaluation_type.name) ===
            "Avaliação de Habilidades e Atitudes")
      ) {
        return true;
      }
    }
  };

  return {
    loading,
    loadingStudents,
    comment,
    setComment,
    sendListNotasConhecimento,
    studentsListModal,
    studentsList,
    check,
    disciplinas,
    turmas,
    quarters,
    setSelectedQuarter,
    selectedQuarter,
    setSelectedSubject,
    filterDisciplina,
    setSelectedGradeGroup,
    classList,
    fetchStudents,
    selectedGradeGroup,
    verifyObservation,
    handledUpdadeListNotas,
    sendListNotas,
    handleChecked,
    setStudentIndicer,
    setToggleModal,
    handleStudentModal,
    handleSubmit,
    toggleModal,
    studentIndice,
    returnMessage,
    location,
  };
};
