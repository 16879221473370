import styled from "styled-components";
import * as colors from "styles/colors";

export const StudentStatus = styled.div`
  max-width: 480px;
  width: 100%;
  height: 130px;

  display: flex;
  align-items: center;
  gap: 8px;

  padding: 16px;

  border: 2px solid ${colors.NEW_PURPLE};

  transition: 0.2s ease-in;
  cursor: pointer;

  &:hover {
    background: rgba(109, 40, 217, 0.05);
  }
`;

export const StudentStatusProgress = styled.div`
  max-width: 80px;
  max-height: 80px;
  width: 100%;
`;

export const StudentStatusInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  strong {
    font-size: 16px;
    line-height: 24px;
    word-break: break-all;
  }

  .details {
    margin-top: 4px;
    display: flex;
    align-items: center;
    gap: 4px;
  }

  p {
    font-size: 10px;
  }

  @media (min-width: 576px) {
    strong {
      font-size: 18px;
      line-height: 24px;
    }

    p {
      font-size: 14px;
    }
  }
`;
