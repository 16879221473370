import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space[1]};

  p {
    margin-top: ${({ theme }) => theme.space[1]};
    margin-bottom: ${({ theme }) => theme.space[1]};
    font-size: ${({ theme }) => theme.fontSizes.web.xsm};
  }

  span {
    color: ${({ theme }) => theme.colors.statusErrorDefault};
  }

  & + & {
    margin-top: ${({ theme }) => theme.space[4]};
  }
`;

export const Label = styled.label`
  margin: 0;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSizes.web.sm};
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 2px solid ${({ theme }) => theme.colors.greyScale40};

  button {
    border: none;
    background: none;
    padding-right: ${({ theme }) => theme.space[2]};

    svg {
      color: ${({ theme }) => theme.colors.greyScale40};
    }
  }
`;

export const Input = styled.input`
  padding: ${({ theme }) => theme.space[2]};
  width: 100%;

  border: none;
  outline: none;
`;
