import styled from "styled-components";
import * as colors from "styles/colors";

export const Container = styled.div`
  max-width: 1008px;
  width: 100%;

  margin: 0 auto;

  padding: 24px;

  h1 {
    color: ${colors.NEW_PURPLE};
    font-weight: 700;
    font-size: 36px;
  }

  button {
    display: flex;
    flex: 1;
    justify-content: center;
  }

  .noData {
    margin: 0 auto;

    h3 {
      text-align: center;
    }
  }
`;
