import styled from "styled-components";
import * as colors from "styles/colors";

export const IndicationFormStyled = styled.div`
  background: rgba(109, 40, 217, 0.05);
  border-radius: 29px;

  padding: ${({ theme }) => theme.space[6]} ${({ theme }) => theme.space[4]};
  margin-bottom: ${({ theme }) => theme.space[4]};

  p {
    font-size: 16px;
    line-height: ${({ theme }) => theme.lineHeights.taller};
  }

  div.form {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    div.input {
      display: flex;
      flex-direction: column;
      width: 49%;
      margin-top: ${({ theme }) => theme.space[4]};

      color: ${colors.GRAY_SCALE_THREE};
      label {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
      }

      input {
        margin-top: ${({ theme }) => theme.space[2]};
        background-color: transparent;
        border: none;
        border-bottom: 2px solid ${colors.GRAY_SCALE};
        outline: none;
      }

      select {
        height: ${({ theme }) => theme.space[8]};
        background-color: transparent;
        border: none;
        border-bottom: 2px solid ${colors.GRAY_SCALE};
        outline: none;
      }
    }
  }

  div.eula {
    display: flex;
    justify-content: flex-start;

    margin-top: ${({ theme }) => theme.space[4]};

    gap: 0 ${({ theme }) => theme.space[2]};

    label {
      margin-top: 1px; // nao tem na lib
    }

    div.arrow {
      img {
        margin-left: ${({ theme }) => theme.space[8]};
        cursor: pointer;
      }
    }
  }

  div.button {
    display: flex;
    justify-content: flex-end;

    margin-top: ${({ theme }) => theme.space[6]};
  }

  @media (max-width: 565px) {
    p {
      font-size: 12px;
      line-height: 18px;
    }
    div.form {
      div.input {
        width: 100%;
      }
    }

    div.eula {
      div.arrow {
        img {
          margin-left: 0;
        }
      }
    }
  }

  @media (max-width: 320px) {
    div.form {
      div.input {
        width: 100%;
      }
    }
  }
`;
