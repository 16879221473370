// react
import { useState } from "react";
// components
import { SecondPagination, Text } from "components";
import { WaitingListTable } from "../Table";
import { Loading } from "components/atoms";
// libs
import { useTranslation } from "react-i18next";
// style
import { Container } from "./style";
// interface
import { FormComponent } from "../../interface";
// helpers
import { YearsInterface } from "interfaces/constants";
import { WaitingListFormHelper } from "./helper";

export const WaitingListForm = ({ units }: FormComponent) => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.waitingList.components.form.waitingListForm";

  // Form
  const [unit, setUnit] = useState<string>("");
  const [educationalLevel, setEducationalLevel] = useState<string>("");
  const [grade, setGrade] = useState<string>("");
  const [selectedYear, setSelectedYear] = useState<string>("");

  // Logicas
  const {
    studentList,
    schoolPlaces,
    loadindTable,
    page,
    setPage,
    firstStudent,
    lastStudent,
    Offset,
    handleChange,
    findEducationalLevel,
    findGrade,
    withVacancy,
    setNewSearch,
    years,
  } = WaitingListFormHelper({
    units,
    selectedYear: parseInt(selectedYear),
    unit: parseInt(unit),
    educationalLevel: parseInt(educationalLevel),
    grade: parseInt(grade),
    setUnit,
    setEducationalLevel,
    setGrade,
    setSelectedYear,
  });

  return (
    <Container>
      <form>
        <div className="input">
          <label>{t(`${TRANSLATE}.labelUnit`)}</label>
          <select
            onChange={(e) => {
              handleChange("unit", e.target.value);
            }}
            required
            name="unit"
            value={unit}
          >
            <option value="">{t(`${TRANSLATE}.select`)}</option>
            {units.map((item) => {
              return (
                <option value={item.unit.id} key={item.unit.id}>
                  {item.unit.name}
                </option>
              );
            })}
          </select>
        </div>

        {unit && (
          <div className="input">
            <label>{t(`${TRANSLATE}.labelEducationalLevel`)}</label>
            <select
              onChange={(e) => handleChange("educationalLevel", e.target.value)}
              required
              name="educationalLevel"
              value={educationalLevel}
            >
              <option value="">{t(`${TRANSLATE}.select`)}</option>
              {findEducationalLevel(units)?.unit.educational_level.map(
                (item) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                  );
                }
              )}
            </select>
          </div>
        )}

        {unit && educationalLevel && (
          <div className="input">
            <label>{t(`${TRANSLATE}.labelGrade`)}</label>
            <select
              onChange={(e) => handleChange("grade", e.target.value)}
              required
              name="grade"
              value={grade}
            >
              <option value="">{t(`${TRANSLATE}.select`)}</option>
              {findGrade()?.grades.map((item) => (
                <option value={item.grade} key={item.grade}>
                  {item.grade}
                </option>
              ))}
            </select>
          </div>
        )}

        {unit && educationalLevel && grade && (
          <div className="input">
            <label>{t(`${TRANSLATE}.labelYear`)}</label>
            <select
              onChange={(e) => handleChange("years", e.target.value)}
              required
              name="years"
              value={selectedYear}
            >
              <option value="">{t(`${TRANSLATE}.select`)}</option>
              {years.map((item: any) => (
                <option value={item.value} key={item.label}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
        )}
      </form>

      {schoolPlaces && studentList && (
        <div className="data">
          <Text className="text">{t(`${TRANSLATE}.schoolPlaces`)}</Text>
          <Text fontWeight="400" className="text dataNumber">
            {schoolPlaces.vagas_disponiveis <= 0
              ? "0"
              : schoolPlaces.vagas_disponiveis}
          </Text>
        </div>
      )}

      {loadindTable && (
        <div className="mt-3">
          <Loading />
        </div>
      )}

      {studentList && studentList.results.length > 0 && (
        <WaitingListTable
          studentList={studentList}
          withVacancy={withVacancy}
          setNewSearch={setNewSearch}
        />
      )}

      {studentList && studentList.results.length <= 0 && (
        <div className="nofoundDiv">
          <strong className="nofound">{t(`${TRANSLATE}.noResults`)}</strong>
        </div>
      )}

      {studentList && lastStudent && studentList.count > 10 ? (
        <div className="pagination">
          <SecondPagination
            previous={studentList.previous!}
            count={studentList.count}
            next={studentList.next!}
            page={page}
            setPage={setPage}
            Offset={Offset}
            firstStudent={firstStudent}
            lastStudent={lastStudent}
          />
        </div>
      ) : null}
    </Container>
  );
};
