import { HeaderProfile } from "components";
import HTTP_STATUS from "http-status-codes";
import { GetCXUserJson } from "interfaces/constants";
import { useEffect, useState } from "react";
import { getCXUser } from "services/users.service";
import { UserData } from "./components/UserData";
import { SubheadingPink } from "./style";
import { useAtomValue } from "jotai";
import { authUserAtom } from "jotai/authUser";

export const StudentExitTime = () => {
  const [user, setUser] = useState<GetCXUserJson | undefined>(undefined);
  const [error, setError] = useState<boolean>(false);
  const authUser = useAtomValue(authUserAtom);

  type GetCXUserJsonType = {
    data: GetCXUserJson;
    status: number;
  };

  useEffect(() => {
    getCXUser({ id: authUser.id }).then(
      ({ data, status }: GetCXUserJsonType) => {
        if (status === HTTP_STATUS.OK) {
          setUser(data);
        } else {
          setError(true);
        }
      }
    );
  }, [authUser]);

  return (
    <>
      <HeaderProfile user={user} activeTab="studentExitTime" />
      <SubheadingPink className="mt-4 mb-4">Serviços</SubheadingPink>
      {!error && <UserData user={user} />}
      {error && <div>Erro ao carregar os dados do usuário.</div>}
    </>
  );
};
