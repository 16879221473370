// Styles
import { ModalStyled } from "./style";
// Libs
import { Modal as ModalMUI } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
// Icons
import successIcon from "static/img/success_icon_two.svg";
import { ButtonIconFontNormal, Title } from "components";
import { useHistory } from "react-router-dom";
import { ModalInterface } from "../../interface";

const useStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const Modal = ({ modal, isOpen }: ModalInterface) => {
  const classes = useStyles();
  const history = useHistory();

  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.indication.components.modal";

  return (
    <ModalMUI className={classes.modalStyle} open={isOpen}>
      <ModalStyled>
        <img height={30.83} src={successIcon} alt="successIcon" />
        <Title scaleGray>
          {t(`${TRANSLATE}.text`)} <br /> {t(`${TRANSLATE}.textPt2`)}
        </Title>
        <div>
          <ButtonIconFontNormal uppercase onClick={() => history.push("/home")}>
            {t(`${TRANSLATE}.buttonHome`)}
          </ButtonIconFontNormal>
          <ButtonIconFontNormal uppercase onClick={() => history.go(0)}>
            {t(`${TRANSLATE}.buttonNewIndication`)}
          </ButtonIconFontNormal>
        </div>
      </ModalStyled>
    </ModalMUI>
  );
};
