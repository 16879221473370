import { UnitGradesInteface } from "services/grades.service";
import { SelectContainer } from "./styles";

interface IUnitsSelectProps {
  onChangeUnit: (id: number) => void;
  units: UnitGradesInteface[];
  selectedUnit: number;
}

export const UnitsSelect = ({
  onChangeUnit,
  units,
  selectedUnit,
}: IUnitsSelectProps) => {
  return (
    <SelectContainer>
      <span>Unidade</span>
      <select
        name="unit"
        id="unit"
        onChange={(e) => onChangeUnit(Number(e.target.value))}
      >
        {units?.map((unit) => (
          <option
            key={unit.id}
            value={unit.id}
            selected={selectedUnit === unit.id}
          >
            {unit.name}
          </option>
        ))}
      </select>
    </SelectContainer>
  );
};
