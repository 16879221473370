// React
import { useCallback, useEffect, useState } from "react";
// Styles
import * as Style from "./style";
// Components
import { Loading } from "components/atoms";
import { CardLink, IndicationForm, Modal } from "./components";
import { ButtonIconFontNormal, Text } from "components";
// Libs
import HTTP_STATUS from "http-status-codes";
import { useTranslation } from "react-i18next";
// Context
// Services
import { getUnitsGradesSchoolPlaces } from "services/units.service";
import { getCXUser } from "services/users.service";
// Interfaces
import { GetUnitsGradesResponse } from "interfaces/unit";
// Img
import indicationLogo from "static/img/indicationLogo.svg";
import dashIcon from "static/img/dashPurple.svg";
import plusIcon from "static/img/plusPurple.svg";
// Helpers
import { indicationLink } from "services/indication.service";
import { useAtomValue } from "jotai";
import { authUserAtom } from "jotai/authUser";

export const Indication = () => {
  const authUser = useAtomValue(authUserAtom);
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.indication";

  // Initial State
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [units, setUnits] = useState<Array<GetUnitsGradesResponse>>();
  const [link, setLink] = useState<string | null>(null);

  // Rules States
  const [rules, setRules] = useState(false);

  // Modal
  const [toggleModal, setToggleModal] = useState(false);

  const modal = useCallback(() => {
    setToggleModal((prevState) => !prevState);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const unitsGrade = await getUnitsGradesSchoolPlaces();
        if (unitsGrade?.status === HTTP_STATUS.OK) {
          setUnits(unitsGrade.data);
        }

        const userSession = await getCXUser({
          id: authUser.id,
        });
        if (userSession?.status === HTTP_STATUS.OK) {
          const linkForIndication = await indicationLink({
            guardian_id: userSession.data.id,
          });
          if (linkForIndication?.status === HTTP_STATUS.OK) {
            setLink(linkForIndication.data.link);
            setLoading(false);
          }
        }
      } catch (error) {
        setError((prevState) => !prevState);
        setLoading((prevState) => !prevState);
      }
    })();
  }, [authUser]);

  if (loading) {
    return (
      <Style.LoadContainer>
        <h4>{t(`${TRANSLATE}.loadingUnits`)}</h4>
        <Loading />
      </Style.LoadContainer>
    );
  }

  if (error && !loading) {
    return <div>{t(`${TRANSLATE}.error`)}</div>;
  }

  if (units && link) {
    return (
      <Style.IndicationStyled>
        <img
          className="logo"
          src={indicationLogo}
          alt="é um logo com a imagem que diz indicou, ganhou"
        />
        <Text
          fontSize="16px"
          fontWeight="400"
          lineHeight="24px"
          marginTop="1.5em"
        >
          <strong>{t(`${TRANSLATE}.indication`)}</strong>{" "}
          {t(`${TRANSLATE}.indicationText`)}
        </Text>
        <div className="discountRules">
          <div className="button">
            <ButtonIconFontNormal
              icon={rules ? dashIcon : plusIcon}
              height={rules ? "5px" : "20px"}
              onClick={() => setRules((prevState) => !prevState)}
            >
              {rules ? t(`${TRANSLATE}.hide`) : t(`${TRANSLATE}.show`)}{" "}
              {t(`${TRANSLATE}.discountRules`)}
            </ButtonIconFontNormal>
          </div>
          {rules && (
            <ul>
              <li>
                {t(`${TRANSLATE}.indicationNumberLi`, { number: "1" })}{" "}
                <span>
                  {t(`${TRANSLATE}.percentageLiSpan`, { percentage: "25%" })}
                </span>{" "}
                {t(`${TRANSLATE}.liFinalPhrase`)}
              </li>
              <li>
                {t(`${TRANSLATE}.indicationNumberLi`, { number: "2" })}{" "}
                <span>
                  {t(`${TRANSLATE}.percentageLiSpan`, { percentage: "50%" })}
                </span>{" "}
                {t(`${TRANSLATE}.liFinalPhrase`)}
              </li>
              <li>
                {t(`${TRANSLATE}.indicationNumberLi`, { number: "3" })}{" "}
                <span>
                  {t(`${TRANSLATE}.percentageLiSpan`, { percentage: "75%" })}
                </span>{" "}
                {t(`${TRANSLATE}.liFinalPhrase`)}
              </li>
              <li>
                {t(`${TRANSLATE}.indicationNumberLi`, { number: "4" })}{" "}
                <span>{t(`${TRANSLATE}.liWinFreeMonthyPayment`)}</span>{" "}
                <span>{t(`${TRANSLATE}.studentAward`)}</span>
              </li>
              <li>{t(`${TRANSLATE}.against`)}</li>
              <div className="discountTest">
                <Text fontWeight="400">{t(`${TRANSLATE}.rebates`)}</Text>
                <Text fontWeight="400">
                  {t(`${TRANSLATE}.nominationsMade`)}
                </Text>
                <Text fontWeight="400">
                  {t(`${TRANSLATE}.moreInformation`)}{" "}
                  <a
                    href="https://www.escolamais.com/indique/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t(`${TRANSLATE}.site`)}
                  </a>
                </Text>
              </div>
            </ul>
          )}
        </div>

        <CardLink link={link} />
        <IndicationForm units={units} modal={modal} />

        <Modal modal={modal} isOpen={toggleModal} />
      </Style.IndicationStyled>
    );
  }
};
