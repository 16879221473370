import styled from "styled-components";
import { PINK, BLUE, BLACK, GRAY_DISABLED } from "styles/colors";

export const Container = styled.div`
  .pink-bold {
    font-size: 16px;
    font-weight: bold;
    color: ${PINK};
  }

  .blue-bold {
    font-size: 16px;
    font-weight: bold;
    color: ${BLUE};
  }

  .title {
    font-size: 26px;
  }

  .black-bold {
    font-size: 16px;
    font-weight: bold;
    color: ${BLACK};
  }

  .btnBlue {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    gap: 4px;

    width: 293px;
    height: 33px;

    background: #00a1ed;
    border-radius: 5px;

    margin-top: 2rem;
  }

  .btnRosa {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 32px;
    gap: 4px;

    width: 293px;
    height: 52px;

    background: #ec008c;
    border-radius: 5px;
  }

  .contentBtn {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    font-weight: bold;
  }

  .tdFlex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .tdContentSubstitutiva {
    line-height: 30px;
  }

  .content {
    width: 100%;
    height: 30px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    label {
      margin: 0;
      padding: 0;

      margin-left: 0.5rem;
      font-weight: bold;
    }

    input[type="text"] {
      width: 35px;
      height: 24px;
      text-align: center;
      border: 1px solid #bfbfbf;
      border-radius: 7px;
    }
  }

  .cursor-add {
    cursor: pointer;
  }
  .fz0 {
    font-size: 0;
  }

  .tableStudents {
    width: 100%;

    thead {
      tr {
        border: 1px solid ${GRAY_DISABLED};
        border-bottom: none;
        th {
          padding: 1em;
          border-right: 1px solid ${GRAY_DISABLED};
        }
      }
    }
    tbody {
      tr {
        border: 1px solid ${GRAY_DISABLED};
        height: auto;
        td {
          border-right: 1px solid ${GRAY_DISABLED};
          margin-right: -1px;
          height: calc(auto + 2px);
          span {
            margin-left: 1rem;
          }
          div {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            margin-right: 1px;
          }
        }
      }
    }
  }

  .checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    border: 1px solid ${GRAY_DISABLED};
    width: 1.15em;
    height: 1.15em;

    transition: 0.3s;
    &:checked {
      background: ${PINK};
      border-color: ${PINK};

      &::after {
        content: "✔";
        width: 1.15em;
        height: 1.15em;

        color: #fff;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;

export const SelectStyled = styled.select`
  font-size: 14px;
  font-weight: normal;
  background-color: white;
  width: 100%;
  height: 38px;
  color: #42474a;
  border: none;
  border-bottom: 1px solid #cccccc;
  padding-left: 0.5em;
  margin-top: 2em;
`;

export const InputField = styled.input`
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  background-color: white;
  width: 60%;
  color: #42474a;
  border: 1px solid #cccccc;
  border-radius: 15px;
  // border-bottom: 1px solid #cccccc;

  :focus {
    outline: none;
    // border-bottom: 2px solid #1a1a1a;
  }
  ::placeholder {
    color: transparent;
  }
  &.invalid {
    border-bottom: 2px solid #b00020;
  }
  &:disabled {
    background-color: ${GRAY_DISABLED};
  }
  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important;
  }

  /* :[type=checkbox]+label  {
    display: block;
    margin: 0.2em;
    cursor: pointer;
    padding: 0.2em;
  } */
`;

export const LoadContainer = styled.div`
  .title {
    font-size: 24px;
    font-weight: bold;
    color: ${BLUE};
  }
`;
