/* eslint-disable no-sequences */
/* eslint-disable array-callback-return */
import { useState, useEffect, useCallback } from "react";
import { Button } from "components";
import { ModalDiv, ModalInput, ModalHeader } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Modal as ModalMUI } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { toastSuccess, toastError } from "components/Toast";

import {
  lancarMultiNota,
  StudentsForReleaseResponse,
} from "services/grades.service";
import { MultiNotasProps } from "pages/AP/Lancamentos/Lancamento/types";
import { useLocation } from "react-router-dom";
import { useAtomValue } from "jotai";
import { authUserAtom } from "jotai/authUser";

interface ModalLancamento {
  setToggleModal: (toggleModal: boolean) => void;
  setComment: (comment: {}) => void;
  comment: {};
  isOpen: boolean;
  studentModal: StudentsForReleaseResponse | undefined;
  indice: number;
  value?: string;
}

export type StateType = {
  evaluation_type: {
    id: number;
    name: string;
    substitutive: boolean;
    comment: boolean;
    subject: boolean;
    class_diary: boolean;
  };
  selectedQuarter: number;
};

const useStyles = makeStyles((theme) => ({
  modalStyle: {
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export const ModalLancamentoComentario = ({
  setToggleModal,
  setComment,
  comment,
  isOpen,
  studentModal,
  indice,
  value,
}: ModalLancamento) => {
  const classes = useStyles();
  const location = useLocation<StateType>();
  const [charactersCount, setCharactersCount] = useState<number>(0);
  const [student, setStudent] = useState<MultiNotasProps[]>([]);
  const [addComment, setAddComment] = useState<string | undefined>("");
  const authUser = useAtomValue(authUserAtom);

  const handleSubmit = useCallback(async () => {
    try {
      if (studentModal) {
        if (student) {
          const response = await lancarMultiNota([...student]);
          if (response?.status === 200) {
            toastSuccess("Observação adicionada com sucesso");
            setToggleModal(false);
            window.location.reload();
          }
        }
      }
    } catch (error) {
      toastError("Erro ao adicionar oberservação no aluno");
      throw new Error("Error", error);
    }
  }, [setToggleModal, student, studentModal]);

  useEffect(() => {
    if (studentModal && studentModal?.comment !== null) {
      setCharactersCount(studentModal?.comment?.length);
    } else {
      setCharactersCount(0);
    }
  }, [studentModal, indice]);

  useEffect(() => {
    if (studentModal) {
      const newList: MultiNotasProps[] = [];
      const studentSelected = {
        enrollment: studentModal?.enrollment,
        value: studentModal.value,
        gradegroup: studentModal.gradegroup,
        quarter: studentModal?.quarter,
        user_id: authUser.id,
        type: location?.state?.evaluation_type.name,
        user_name: authUser?.name,
        comment: addComment,
        subject: studentModal?.subject,
        substitutive: studentModal.substitutive,
        deactivate_previous: false,
        can_display_comment: studentModal?.can_display_comment,
      };
      newList.push(studentSelected);
      setStudent(newList);
    }
  }, [
    addComment,
    indice,
    studentModal,
    authUser,
    location?.state?.evaluation_type.name,
    value,
  ]);

  return (
    <ModalMUI className={classes.modalStyle} open={isOpen}>
      <ModalHeader>
        <FontAwesomeIcon
          className="close"
          onClick={() => setToggleModal(false)}
          size="lg"
          icon={faTimes as IconProp}
        />
        {studentModal && (
          <>
            <ModalDiv>
              <div className="container">
                <div className="row mt-4 ml-2">
                  <div className="text-left">
                    <strong className="title">
                      {String(location?.state?.evaluation_type.name)}
                    </strong>
                  </div>
                </div>
                <div className="mt-3 ml-2">
                  <strong>Estudante</strong>
                  <div>{studentModal?.name}</div>
                  <div>
                    {studentModal?.grade}º ano {studentModal?.letter}
                  </div>
                </div>
                <div className="row mt-3 ml-2">
                  <strong>Observações</strong>
                </div>
                <div className="ml-2">
                  <ModalInput
                    maxLength={1600}
                    onChange={(e) => {
                      setCharactersCount(e.target?.value?.length);
                      setAddComment(e.target?.value);
                    }}
                    defaultValue={studentModal?.comment}
                  />
                </div>
                <div className="text-right mr-4 characterCount">
                  {charactersCount}/1600 caracteres
                </div>

                <div className="row">
                  <div className="col-6">
                    <Button
                      onClick={() => (
                        setToggleModal(false),
                        setAddComment(studentModal?.comment)
                      )}
                      className="pink outline roundedBorder"
                    >
                      Fechar
                    </Button>
                  </div>
                  <div className="col-6">
                    <Button
                      type="button"
                      onClick={() => handleSubmit()}
                      className="roundedBorder"
                    >
                      Salvar
                    </Button>
                  </div>
                </div>
              </div>
            </ModalDiv>
          </>
        )}
      </ModalHeader>
    </ModalMUI>
  );
};
