import * as S from "./styles";
import { ItinerarieGradeIcon, ItinerarieStatus } from "components/atoms";
import { useTranslation } from "react-i18next";
import { IItinerarieComponent } from "types/study-itinerary";

interface IItinerarieCardReadyProps {
  itinerary: IItinerarieComponent;
}
export const ItinerarieCardReady = ({
  itinerary,
}: IItinerarieCardReadyProps) => {
  const { t } = useTranslation();
  const TRANSLATION_PREFIX = "components.organisms.itinerarieCard";

  return (
    <S.Container>
      <ItinerarieGradeIcon course={itinerary.course_name} />
      <div className="itinerarie-info">
        <div className="itinerarie-info_course-name">
          <strong>{itinerary.course_name}</strong>
          <ItinerarieStatus type={itinerary.assignment_status} />
        </div>
        <p>{itinerary.assignment_name}</p>
        <span className="itinerarie-info_grade">
          <strong>
            {itinerary.assignment_grade ? itinerary.assignment_grade : "-"}
          </strong>
          /{t(`${TRANSLATION_PREFIX}.gradeMax`)}
        </span>
      </div>
    </S.Container>
  );
};
