import { Button } from "components";
import { ModalDiv } from "components/UseTerms/styles";
import loadingGif from "static/img/loading.gif";

interface ModalConfirmVacancyInterface {
  setToggleModal: (toggleModal: boolean) => void;
  submitByModal: () => void;
  submitting: boolean;
}

function ModalConfirmVacancy({
  submitByModal,
  setToggleModal,
  submitting,
}: ModalConfirmVacancyInterface) {
  return (
    <div className="container">
      <ModalDiv>
        <div className="text-center">
          <strong className="title">
            Vaga disponível! Deseja efetuar a reserva de vaga?
          </strong>
          <div className="row">
            <div className="col-6">
              <Button
                disabled={submitting}
                onClick={() => setToggleModal(false)}
                className="blue outline"
              >
                Cancelar
              </Button>
            </div>
            <div className="col-6">
              <Button
                disabled={submitting}
                onClick={() => submitByModal()}
                type="submit"
              >
                {!submitting && "Sim"}
                {submitting && (
                  <img height="25" src={loadingGif} alt="loading" />
                )}
              </Button>
            </div>
          </div>
        </div>
      </ModalDiv>
    </div>
  );
}

export default ModalConfirmVacancy;
