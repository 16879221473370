import styled, { keyframes } from "styled-components";

interface ContainerProps {
  visible?: boolean;
  animationDelay?: number;
}

export const ModalZoomIn = keyframes`
  from {
    transform:  scale(0.8, 0.8);
  }

  to {
    transform: scale(1, 1);
  }
`;

export const ModalZoomOut = keyframes`
  from {
    transform: scale(1, 1);
  }

  to {
    transform:  scale(0.8, 0.8);
  }
`;

export const ModalFadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ModalFadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

export const Container = styled.div<ContainerProps>`
  z-index: 9999;
  width: 100%;
  min-height: 100%;
  height: 100%;
`;

interface OverlayProps {
  path: string;
}

export const Overlay = styled.div<OverlayProps>`
  z-index: 9999;
  width: 100%;
  min-height: 100%;
  height: 100%;
  position: ${(props) =>
    props.path === "/home/boletim" ? "fixed" : "absolute"};
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IModal {
  size?: string;
  radious?: string;
}

export const Modal = styled.div<IModal>`
  display: block;
  background: white;
  width: ${(props) => (props.size ? props.size : "80%")};
  height: auto;
  padding: ${(props) => (props.size ? "1.2rem" : "1rem")};
  border-radius: ${(props) => (props.radious ? props.radious : "1rem")};

  @media (max-width: 425px) {
    width: 90%;
    border-radius: 1em;
  }
`;
