// Style
import { BoxDinamycContainer } from "./style";
// Libs
import { Tag } from "@escolamais-ui-v2/react";
import { useTranslation } from "react-i18next";
// Helpers
import { splitPunctuation, standardDate, upperCase } from "helpers/helpers";
import { isOperationalDirector } from "helpers/constants";
// Img
import unitIcon from "static/img/unitIcon.svg";
// Components
import { Icons } from "components";
// Interfaces
import { IBoxDynamic } from "./interface";
// Storage
import { Storage } from "Storage";

enum EStatus {
  active = "ativa",
  inactive = "inativa",
  scheduled = "programada",
}

export const BoxDynamic = ({ dynamic, setDynamic, setDrawer }: IBoxDynamic) => {
  const role = Storage.props.role.get();

  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.manageFoodItems.components.boxDynamic";

  const appearance = (status: string) => {
    switch (status) {
      case EStatus.active:
        return "success";
      case EStatus.inactive:
        return "error";
      default:
        return "primaryOutline";
    }
  };

  return (
    <BoxDinamycContainer
      onClick={() => {
        setDynamic(dynamic);
        isOperationalDirector(role) && setDrawer(true);
      }}
      editPermission={isOperationalDirector(role)}
    >
      <div className="dinamyc">
        <span>{dynamic.nome}</span>
        <Tag
          appearance={appearance(dynamic.status)}
          className={dynamic.status === EStatus.scheduled ? "label" : ""}
        >
          {upperCase(dynamic.status)}
        </Tag>
      </div>
      <div className="data">
        <span>
          <strong>{t(`${TRANSLATE}.value`)}</strong>{" "}
          {dynamic.valor_dinamica
            .map((value) => `R$${splitPunctuation(value.valor_unitario).comma}`)
            .join(" - ")}
        </span>
        <div>
          <span>
            <strong>{t(`${TRANSLATE}.initialDate`)}</strong>{" "}
            {standardDate(dynamic.vigencia_inicio)}
          </span>
          <span>
            <strong>{t(`${TRANSLATE}.expiryDate`)}</strong>{" "}
            {standardDate(dynamic.vigencia_fim)}
          </span>
        </div>
        <div className="units">
          <Icons
            icon={unitIcon}
            width={17.5}
            height={15.75}
            unity="px"
            title="unidade"
          />
          <span>
            {t(`${TRANSLATE}.units`)}{" "}
            {dynamic.todas_unidades
              ? t(`${TRANSLATE}.allUnits`)
              : dynamic.unidades_ids.map((unit) => unit.nome).join(", ")}
          </span>
        </div>
      </div>
    </BoxDinamycContainer>
  );
};
