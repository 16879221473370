import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 175px;
    height: 175px;
    margin-bottom: ${({ theme }) => theme.space[4]};
  }

  .pixDigitalBox {
    border: 1px solid #e8e6e6;
    border-radius: 13px;

    padding: ${({ theme }) => theme.space[2]};
    color: #aaa;

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 10px;
    width: 100%;

    p {
      text-align: center;
    }

    svg {
      width: ${({ theme }) => theme.space[6]};
      height: ${({ theme }) => theme.space[6]};
    }

    &:hover {
      cursor: copy;
    }

    @media (max-width: 540px) {
      flex-direction: column;
    }
  }

  img {
    width: 175px;
    height: 175px;
  }
`;
