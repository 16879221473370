import { Checkbox as MaterialUiCheckBox } from "@material-ui/core";
import { FieldRenderProps } from "react-final-form";

type Props = FieldRenderProps<boolean, any>;

const CheckboxInput: React.FC<Props> = ({
  input: { value, ...input },
  ...rest
}: Props) => <MaterialUiCheckBox {...input} {...rest} checked={!!value} />;

export default CheckboxInput;
