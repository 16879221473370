import { AlignContainer } from "./../../atoms/Container/types";
import { pxToRem } from "helpers/style";
import styled, { css } from "styled-components";
import * as colors from "styles/colors";
import { device } from "styles/breakpoints";

interface ComponentProps {
  role?: string;
}

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  font-family: "Poppins";
`;
interface SectionStyleProps {
  padding: boolean;
  direction: string;
  align?: string;
}
export const Section = styled.div<SectionStyleProps>`
  width: 100%;
  padding: ${(props) => (props.padding ? "8px" : "0px")};
  display: flex;
  flex-direction: ${(props) =>
    props.direction === "row"
      ? "row"
      : props.direction === "column" && "column"};
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.space[4]};

  img {
    width: 16px;
    height: 16px;
    margin: 0 16px;
    cursor: pointer;
  }

  button {
    img {
      width: ${pxToRem(25)};
      height: ${pxToRem(25)};
      filter: brightness(0) saturate(100%) invert(100%) sepia(35%)
        saturate(971%) hue-rotate(205deg) brightness(113%) contrast(95%);
    }
  }

  @media ${device.md} {
    padding: ${(props) => (props.padding ? "16px" : "0px")};
  }
`;
interface ButtonIconStyleProps {
  icon: string;
  value: number;
}
export const ButtonIcon = styled.button<ButtonIconStyleProps>`
  appearance: none;

  border: none;

  width: 16px;
  height: 16px;

  margin: 16px;

  background-color: transparent;
  background-image: ${(props) =>
    props.icon === "prev"
      ? `url("/icons/prev.svg")`
      : props.icon === "next" && `url("/icons/next.svg")`};
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center center;

  display: block;

  ${(props) =>
    props.value === 0
      ? css`
          cursor: not-allowed;
        `
      : css`
          cursor: pointer;
        `};
`;

export const TitleName = styled.div`
  width: calc(100% - 50px);

  text-align: center;
  font-weight: 700;
  font-size: 16px;

  color: #0d0d0d;
`;

export const ContentBulletin = styled.div<ComponentProps>`
  width: 100%;

  background: ${(props) =>
    props.role === "internal"
      ? "rgba(109, 40, 217, 0.05);"
      : props.role === "external" && "#fff"};
  border-radius: 10px;

  display: flex;
  flex-direction: column;
`;

export const ButtonBulletinSaf = styled.button`
  border: none;
  background-color: transparent;
  background-image: url("/icons/view.svg");
  background-size: ${pxToRem(15)} ${pxToRem(15)};
  background-position: left center;
  background-repeat: no-repeat;

  display: block;

  padding-left: ${pxToRem(20)};

  color: ${colors.NEW_PURPLE};
  font-weight: 700;
  font-size: 14px;

  text-decoration: underline;

  cursor: pointer;
`;

export const Content = styled.div`
  @media ${device.xss} {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;

    padding: 0.5rem 0;
  }

  @media ${device.md} {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const TitleSubJects = styled.div<ComponentProps>`
  width: ${(props) =>
    props.role === "internal"
      ? "calc(100% - 80px)"
      : props.role === "external" && "calc(100% - 60%)"};
  font-size: 12px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;

  @media ${device.sm} {
    font-size: 14px;
  }
`;

export const BoxSubjects = styled.div<ComponentProps>`
  width: 100%;

  display: flex;
  flex-direction: column;

  background: ${(props) =>
    props.role === "internal"
      ? "#fff"
      : props.role === "external" && "#F0E9FB"};

  padding: 16px;

  margin-top: 0.5rem;
  border-radius: 10px;
`;

export const ButtonEdit = styled.button`
  width: 20px;
  height: 20px;

  border: none;

  background-color: transparent;
  background-image: url("/icons/edit.svg");
  background-size: ${pxToRem(18)} ${pxToRem(18)};
  background-position: center center;
  background-repeat: no-repeat;

  display: block;
  transition: 0.3s;
`;

export const ItemSubjects = styled.div<ComponentProps>`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;

  background: ${(props) =>
    props.role === "internal"
      ? "transparent"
      : props.role === "external" && "#fff"};

  padding-left: ${(props) =>
    props.role === "internal" ? "0px" : props.role === "external" && "8px"};

  border-radius: ${(props) =>
    props.role === "internal" ? "0px" : props.role === "external" && "20px"};

  margin-bottom: 4px;

  box-sizing: border-box;

  padding: ${(props) => (props.role === "internal" ? "0px" : "4px 16px")};

  &:first-child {
    margin-bottom: 8px;
  }
`;

interface TagSubjectsStyleProps {
  status: string;
}
const handleColorStatus = (status: string) => {
  switch (status) {
    case "Conselho - tag":
      return css`
        background: #f9c339;
        color: #0d0d0d;
      `;
    case "Recuperação - tag":
      return css`
        background: #b50303;
        color: #fff;
      `;
    case "Aprovado - tag":
      return css`
        background: #3ba550;
        color: #fff;
      `;
    case "Arredondado - tag":
      return css`
        border: 2px solid #3ba550;
        color: #3ba550;
      `;
    case "Retido - tag":
      return css`
        border: 2px solid #b50303;
        color: #b50303;
      `;
    case "Aprovado pelo Conselho - tag":
      return css`
        border: 2px solid #3ba550;
        color: #3ba550;
      `;
    case "Não recuperado - tag":
      return css`
        background: #b50303;
        color: #fff;
      `;
    case "Recuperado - tag":
      return css`
        background: #3ba550;
        color: #fff;
      `;
    case "Em Recuperação - tag":
      return css`
        background: #b50303;
        color: #fff;
      `;
    //------------------------//
    case "Em Recuperação - media":
      return css`
        color: #b50303;
      `;
    case "Aprovado - media":
      return css`
        color: #3ba550;
      `;
    case "Arredondado - media":
      return css`
        color: #3ba550;
      `;
    case "Não recuperado - media":
      return css`
        color: #b50303;
      `;
    case "Recuperado - media":
      return css`
        color: #3ba550;
      `;
    case "Conselho - media":
      return css`
        background: #f9c339;
        color: #0d0d0d;
      `;
    case "Recuperação - media":
      return css`
        background: #b50303;
        color: #fff;
      `;
    case "Retido - media":
      return css`
        border: 2px solid #b50303;
        color: #b50303;
      `;
    case "Aprovado pelo Conselho - media":
      return css`
        border: 2px solid #3ba550;
        color: #3ba550;
      `;
    case "Nota acima de 10":
      return css`
        border: 2px solid #b50303;
        color: #b50303;
      `;
  }
};

export const TagSubjects = styled.div<TagSubjectsStyleProps>`
  width: auto;
  padding: 4px 8px;

  ${(props) => props.status && handleColorStatus(props.status)}
  border-radius: 4px;

  font-weight: 700;
  font-size: 14px;

  text-align: center;
`;

export const MediaSubjects = styled.div<ComponentProps>`
  flex: 1;

  height: 35px;
  background: ${(props) =>
    props.role === "internal" ? "#f0e9fb" : "transparent"};
  border-radius: 20px;

  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    props.role === "internal" ? "center" : "right"};
  align-items: center;

  font-weight: 700;
  font-size: 14px;

  span {
    font-weight: 400;
  }
`;

interface AbsenceStyleProps {
  label: string;
  role?: string;
}

export const Absences = styled.div`
  font-weight: 700;
  font-size: 16px;

  padding: 0 4px;
`;

export const Absence = styled.div<AbsenceStyleProps>`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  padding-left: ${(props) =>
    props.role === "internal" ? "0px" : props.role === "external" && "8px"};

  ${(props) =>
    props.role === "internal"
      ? css`
          font-size: 13px;
        `
      : props.role === "external" &&
        css`
          font-size: 12px;
        `};

  ${(props) =>
    props.label &&
    css`
      &::before {
        content: "Faltas";
        display: block;
        font-weight: 400;
        margin-right: 0px;
        ${props.role === "internal"
          ? css`
              font-size: 13px;
            `
          : props.role === "external" &&
            css`
              font-size: 12px;
            `};
      }
    `}

  ${ButtonEdit} {
    margin-right: 4px;
  }
`;
interface NotaStyleProps {
  status: string;
  role?: string;
}

export const Nota = styled.div<NotaStyleProps>`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;

  ${({ role }) =>
    role === "internal" &&
    css`
      width: 70px;
      padding: 4px 0;
    `}

  background: ${(props) =>
    props.role === "internal"
      ? "#f0e9fb"
      : props.role === "external" && "transparent"};

  margin-right: ${(props) => props.role === "internal" && "-8px"};

  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;

  border-radius: 20px;

  ${(props) => props.status && handleColorStatus(props.status)};

  &:hover {
    ${ButtonEdit} {
      opacity: 1;
    }
  }
`;

export const Media = styled.div<AbsenceStyleProps>`
  width: auto;

  font-size: 20px;
  font-weight: 700;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background: ${(props) =>
    props.role === "internal"
      ? "transparent"
      : props.role === "external" && "#fff"};

  padding: ${(props) => (props.role === "internal" ? "4px 10px" : "4px 16px")};

  border-radius: 20px;

  ${(props) =>
    props.label &&
    css`
      &::before {
        content: "Media";
        display: block;
        font-weight: 400;
        font-size: 13px;
        margin-right: 10px;
      }
    `}
`;

export const MediaAlt = styled.div<AbsenceStyleProps>`
  width: auto;

  font-size: 20px;
  font-weight: 700;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background: ${(props) =>
    props.role === "internal"
      ? "transparent"
      : props.role === "external" && "#fff"};

  padding: 4px 8px;

  border-radius: 20px;

  ${(props) =>
    props.label &&
    css`
      &::before {
        content: "Média";
        display: block;
        font-weight: 400;
        font-size: 13px;
        margin-right: 10px;
      }
    `}
`;

export const Text = styled.div`
  width: 100%;

  padding: 16px 0;
  font-weight: 400;
  font-size: 12px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const ButtonDisapprove = styled.button`
  border: none;
  background: transparent;
  color: ${colors.NEW_PURPLE};

  text-decoration: underline;

  font-size: 14px;

  padding: 1rem;

  border-radius: 20px;

  font-weight: 700;

  cursor: pointer;

  img {
    width: 10px !important;
    height: 10px !important;
  }
`;

export const ContainerModal = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
`;

export const MessageModal = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 1rem 0;
`;

export const ActionModal = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const ButtonModal = styled.div`
  width: auto;
  padding: 1rem;
  border: none;
  background: transparent;

  color: ${colors.NEW_PURPLE};
  font-weight: 700;
  text-transform: uppercase;

  cursor: pointer;
`;

export const IconTitle = styled.div`
  width: 100%;
  height: 40px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
interface SectionModalProps {
  direction: string;
}
export const SectionModal = styled.div<SectionModalProps>`
  width: 100%;

  display: flex;
  flex-direction: ${(props) =>
    props.direction === "column"
      ? "column"
      : props.direction === "column" && "row"};
`;

export const TextAreaModal = styled.textarea`
  width: 100%;
`;

export const InputModal = styled.input`
  width: 100%;
  height: 37px;
  background: #ffffff;

  border: 2px solid #999999;
`;

export const SpanBulletin = styled.span`
  font-size: 12px;

  @media ${device.sm} {
    font-size: 14px;
  }
`;

export const BoxTable = styled.div`
  width: 62%;
  margin-top: 16px;
`;

export const BoxTag = styled.div`
  min-width: 88px;
  max-width: 120px;
  height: 29px;
`;

export const ContainerHead = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const SectionHead = styled.div`
  width: 100%;

  padding: 0 8px;

  display: row;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media ${device.md} {
    padding: 0 16px;
  }
`;

export const FilterBulletinQuarter = styled.div`
  width: 100%;
  height: 36px;
  background: #f2f2f2;
  border-radius: 4px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

interface FilterBulletinQuarterItemProps {
  active?: boolean;
}
export const FilterBulletinQuarterItem = styled.li<FilterBulletinQuarterItemProps>`
  width: calc(25% - 4px);
  height: 30px;

  background: ${(props) => (props.active ? "#fff" : "transparent")};
  color: ${(props) => (props.active ? `${colors.NEW_PURPLE}` : "#999999")};

  list-style: none;
  font-weight: 700;
  text-transform: uppercase;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-size: 14px;

  border-radius: 4px;

  transition: 0.3s;

  cursor: pointer;

  margin: 0px 4px;

  &:hover {
    background: #fff;
    color: ${colors.NEW_PURPLE};
  }
`;

export const ContainerResponsaveis = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
`;

export const ResponsavelNota = styled.div`
  width: 70px;
  height: 70px;
  background: #b7ff00;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  padding: 4px;
  margin-right: 8px;

  border-radius: 8px;

  position: relative;
`;

export const TitleNota = styled.div`
  width: 100%;

  font-size: 12px;
  position: absolute;

  top: 4px;
  left: 4px;
`;

export const BoxEntenda = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const EntendaNota = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space[2]};
  text-transform: uppercase;

  margin: 5px 0;

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;

  color: ${colors.NEW_PURPLE};

  text-decoration: underline;
  font-weight: 700;

  cursor: pointer;
`;

export const ComponenteLegenda = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  padding: 10px 0;

  color: #000;

  font-size: 16px;

  font-weight: 700;
`;

export const IconRecovery = styled.div`
  width: 20px;
  height: 20px;
  display: block;
  background-image: url("/icons/recuperacao.svg");
  background-position: left center;
  background-repeat: no-repeat;
  margin-left: 5px;
`;

export const SectionShare = styled.div`
  width: 100%;
  margin-top: 24px;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  a {
    max-width: 306px;
    width: 100%;
  }

  button {
    svg {
      color: ${colors.NEW_PURPLE};
      margin-right: 6px;
    }
  }
`;

export const StudentPhoto = styled.div`
  img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
  }
`;

export const SectionHeadInfo = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
`;

export const Legend = styled.div`
  border: none;
  background: none;
  outline: none;
  color: ${colors.NEW_PURPLE};
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 0px;
  margin-top: 16px;
  cursor: pointer;
  svg {
    color: ${colors.NEW_PURPLE};
  }
`;
