/* eslint-disable no-sequences */
/* eslint-disable react/jsx-curly-brace-presence */
import { useBulletin } from "context/Bulletin";
import { useTranslation } from "react-i18next";
import HTTP_STATUS from "http-status-codes";
import * as Style from "./style";

import { Container } from "components/atoms/Container";
import { Title } from "components/atoms/Title";
import { Section } from "components/atoms/Section";
import { SelectBulletin } from "components/atoms/Select";
import { GroupButtons } from "components/molecules/GroupButtons";
import { useEffect, useState } from "react";
import { ButtonFilterBulletin } from "components/atoms/Button";
import { Loading } from "components/atoms/Loading";
import { Drawer } from "components/organisms/Drawer";
import { Warning } from "components/atoms";

import { Bulletin } from "components/organisms";
import { useHistory } from "react-router-dom";
import { concatenateEllipsis } from "helpers/helpers";
import { Tag } from "@escolamais-ui-v2/react";
import {
  CheckNew as CheckIcon,
  ArrowDropDown as SortDescIcon,
  ArrowDropUp as SortAscIcon,
} from "@escolamais-ui-v2/icons";
import { useAtom, useSetAtom } from "jotai";
import { authUserAtom } from "jotai/authUser";

import { Storage } from "Storage";
import { isCX, isGuardian } from "helpers/constants";
import { UnitDropDown } from "interfaces/unit";
import { getUnitsGrades } from "services/grades.service";
import { ActiveStudentsSwitch } from "./components/ActiveStudentsSwitch";
import { StudentDataTable } from "context/Bulletin/types";
import { selectedStudentAprovacaoPreviaDrawerAtom } from "jotai/selectedStudentAprovacaoPreviaDrawer/atoms";
import { useResetAtom } from "jotai/utils";

const dataAtual = new Date();
const anoAtual = dataAtual.getFullYear();
const sortIconProps = {
  width: 24,
  height: 24,
  fill: "#000",
  viewBox: "0 0 48 48",
  cursor: "pointer",
};

const enum EnumReleaseStatusTypes {
  Pendente = "Pendente",
  Aprovado = "Aprovado",
}

const TRANSLATION_PREFIX = "pages.AP.boletim";

export const BoletimFinal = () => {
  const [authUser] = useAtom(authUserAtom);
  const setSelectedStudentAprovacaoPreviaDrawer = useSetAtom(
    selectedStudentAprovacaoPreviaDrawerAtom
  );

  const resetSelectedStudentAprovacaoPreviaDrawer = useResetAtom(
    selectedStudentAprovacaoPreviaDrawerAtom
  );

  const [visible, setVisible] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const history = useHistory();
  const { t } = useTranslation();
  const role = Storage.props.role.get();
  const [areAllActiveStudents, setAreAllActiveStudents] = useState(true);

  const {
    years,
    grades,
    levelName,
    handleSelectLevelName,
    handleSelectGrades,
    sections,
    handleSelectSections,
    quarter,
    setQuarter,
    setQuarters,
    handleSelectYear,
    handleSelectQuater,
    handleDataGrades,
    handleDataTable,
    reqTable,
    dataTableFilter,
    loadingTable,
    loadingLevelName,
    loadingCatchGrades,
    loadingSections,
    loadingQuaters,
    loadingYear,
    gradeBulletin,
    status,
    setData,
    handleSelectStatus,
    setDataTableFilter,
    handleDataBulletin,
    dataTable,
  } = useBulletin();

  const changeLevelName = (name: string) => {
    switch (name) {
      case "Fundamental 1":
        return "EF 1";
      case "Fundamental 2":
        return "EF 2";
      case "Médio":
        return "EM";
      default:
        return "error";
    }
  };

  const showDrawer = () => {
    setVisible(true);

    if (typeof window !== "undefined" && window.document) {
      document.body.style.overflow = "hidden";
    }
  };

  const getCurrentSelectedStudentInDrawer = (student: StudentDataTable) => {
    if (student) {
      setSelectedStudentAprovacaoPreviaDrawer(student);
    }
  };

  const handleCloseDrawer = () => {
    setVisible(false);
    document.body.style.overflow = "unset";
  };

  useEffect(() => {
    // id 8 = cx
    if (authUser.grade_unit_id && authUser.role[0].id !== 8) {
      handleDataGrades({ unit: authUser.grade_unit_id, year: `${anoAtual}` });
    }
  }, [authUser.grade_unit_id, authUser.role, handleDataGrades]);

  useEffect(() => {
    if (reqTable.gradegroupId !== 0 && reqTable.quarterId !== 0) {
      handleDataTable({
        gradegroupId: reqTable.gradegroupId,
        quarterId: reqTable.quarterId,
      });
      setError(false);
    } else {
      setError(true);
    }
  }, [handleDataTable, reqTable.gradegroupId, reqTable.quarterId]);

  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");

  const handleSort = () => {
    if (sortDirection === "asc") {
      setSortDirection("desc");
    } else {
      setSortDirection("asc");
    }

    if (dataTableFilter?.students) {
      dataTableFilter.students.reverse();
      setDataTableFilter(dataTableFilter);
    }
  };

  const [units, setUnits] = useState<Array<UnitDropDown>>();
  const [loadingUnits, setLoadingUnits] = useState(true);
  const [selectedUnit, setSelectedUnit] = useState("");
  const UNIT_ID_TO_DISCONSIDER = 4638; // sempre desconsiderar este ID no dropdown

  useEffect(() => {
    const getUnit = async () => {
      const unitsGrade = await getUnitsGrades();
      if (unitsGrade && unitsGrade.status === HTTP_STATUS.OK) {
        const units: Array<UnitDropDown> = unitsGrade.data.map(
          (unit: UnitDropDown) => {
            return { name: unit.name, id: unit.id };
          }
        );
        setUnits(units.filter((unit) => unit.id !== UNIT_ID_TO_DISCONSIDER));
        setLoadingUnits(false);
      } else {
        setError(true);
        setLoadingUnits(false);
      }
    };
    getUnit();
  }, []);

  const handleSelectUnit = (unitId: string) => {
    setSelectedUnit(unitId);

    setQuarter({
      values: [""],
      selected: "",
    });
    setData(undefined);
    handleDataGrades({
      unit: +unitId,
      year: `${anoAtual}`,
    });
  };

  const filter = (
    <>
      {/* ciclo */}
      {levelName.values.length > 0 ? (
        <GroupButtons
          label={"Ciclo"}
          options={levelName.values && levelName.values}
          loading={loadingLevelName}
          quarter={false}
        >
          {levelName.values.map(
            (item, index) =>
              item && (
                <ButtonFilterBulletin
                  key={`${item}+${index}`}
                  active={levelName.selected === item ? true : false}
                  options={levelName.values}
                  onClick={() => handleSelectLevelName(item)}
                >
                  {changeLevelName(item)}
                </ButtonFilterBulletin>
              )
          )}
        </GroupButtons>
      ) : (
        <GroupButtons
          label={"Turma"}
          options={[]}
          loading={loadingSections}
          quarter
        >
          <ButtonFilterBulletin active={false} note>
            Nada encontrado
          </ButtonFilterBulletin>
        </GroupButtons>
      )}

      {/* ano */}
      {grades.values.length > 0 ? (
        <GroupButtons
          label={"Ano"}
          options={grades.values && grades.values}
          loading={loadingCatchGrades}
          quarter={false}
        >
          {grades.values.map(
            (item, index) =>
              item && (
                <ButtonFilterBulletin
                  key={`${item}+${index}`}
                  active={grades.selected === item ? true : false}
                  options={grades.values}
                  onClick={() => handleSelectGrades(item)}
                >
                  {item.concat("º")}
                </ButtonFilterBulletin>
              )
          )}
        </GroupButtons>
      ) : (
        <GroupButtons
          label={"Turma"}
          options={[]}
          loading={loadingSections}
          quarter
        >
          <ButtonFilterBulletin active={false} note>
            Nada encontrado
          </ButtonFilterBulletin>
        </GroupButtons>
      )}

      {/* turma */}
      {sections.values.length > 0 ? (
        <GroupButtons
          label={"Turma"}
          options={levelName.values && levelName.values}
          loading={loadingSections}
          quarter={false}
        >
          {sections.values.map(
            (item: any, index) =>
              item && (
                <ButtonFilterBulletin
                  key={`${item}+${index}`}
                  active={
                    sections?.selected?.letter === item.letter ? true : false
                  }
                  options={sections?.values}
                  onClick={() => handleSelectSections(item)}
                >
                  {item.letter}
                </ButtonFilterBulletin>
              )
          )}
        </GroupButtons>
      ) : (
        <GroupButtons
          label={"Turma"}
          options={[]}
          loading={loadingSections}
          quarter
        >
          <ButtonFilterBulletin active={false} note>
            Nada encontrado
          </ButtonFilterBulletin>
        </GroupButtons>
      )}
    </>
  );

  // comportamento para chamar a penha no primeiro carregamento
  useEffect(() => {
    if (isCX(role)) {
      handleSelectUnit("4639");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseBulletinDrawer = () => {
    handleCloseDrawer();
    resetSelectedStudentAprovacaoPreviaDrawer();
  };

  const handleFilterActiveStudents = () => {
    const isEveryStudentActive = dataTableFilter?.students?.every(
      (student) => !student.deleted
    );

    if (isEveryStudentActive && dataTable?.students) {
      setDataTableFilter({
        ...dataTable,
        students: dataTable.students.filter((student) => student.deleted),
      });
    }

    if (!isEveryStudentActive && dataTable?.students) {
      setDataTableFilter({
        ...dataTable,
        students: dataTable.students.filter((student) => !student.deleted),
      });
    }
  };

  const updateSwitchStateBasedOnStudentFilter = () => {
    if (dataTableFilter?.students) {
      setAreAllActiveStudents(
        dataTableFilter?.students.every((student) => !student.deleted)
      );
    }
  };

  useEffect(() => {
    updateSwitchStateBasedOnStudentFilter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTableFilter]);

  return (
    <Style.Container>
      <Style.Wrapper>
        <Drawer
          title={gradeBulletin}
          side="right"
          isOpen={visible}
          onClose={handleCloseBulletinDrawer}
        >
          <Bulletin />
        </Drawer>
        <Container display={"true"} direction={"column"} align={"left"}>
          <Section
            maxWidth={"100%"}
            display={"true"}
            direction={{ mobile: "row", desktop: "row" }}
            align={{ mobile: "center", desktop: "center" }}
            justify={{ mobile: "between", desktop: "between" }}
            padding
          >
            <Title fontWeight="700" lineHeight="1" marginBottom="0" responsive>
              {isGuardian(role)
                ? t(`${TRANSLATION_PREFIX}.title`)
                : t(`${TRANSLATION_PREFIX}.approvalTitle`)}
            </Title>
            <Title
              onClick={() => history.push("/historico-dos-boletins")}
              fontSize="16px"
              fontWeight="700"
              lineHeight="1"
              center
              marginBottom="0"
              responsive
              pointer
            >
              <img src="/icons/history.svg" alt="" />{" "}
              {t(`${TRANSLATION_PREFIX}.history`)}
            </Title>
          </Section>

          {/* seção de filtros */}

          <div
            style={{
              display: "flex",
              alignItems: "end",
              width: "100%",
              gap: "2em",
              marginBottom: "20px",
            }}
          >
            {/* unidade */}
            {isCX(role) &&
              (loadingUnits ? (
                <Loading />
              ) : (
                <>
                  {units && (
                    <select
                      name="unit"
                      onChange={(e) => handleSelectUnit(e.target.value)}
                      defaultValue="4639"
                      style={{
                        height: "2.313rem",
                        background: "#fff",
                        border: "2px solid #999",
                      }}
                    >
                      <option value="">Selecione a unidade...</option>
                      {units.map((unit) => {
                        return (
                          <option key={unit.id} value={unit.id}>
                            {unit.name}
                          </option>
                        );
                      })}
                    </select>
                  )}
                </>
              ))}

            {!isCX(role) ? filter : selectedUnit ? filter : null}
          </div>

          {/* fim filtro */}

          <Section
            maxWidth={"100%"}
            display={"true"}
            direction={{ mobile: "column", desktop: "row" }}
            align={{ mobile: "center", desktop: "center" }}
            justify={{ mobile: "center", desktop: "center" }}
            padding
          >
            <Section
              maxWidth={"100%"}
              display={"true"}
              direction={{ mobile: "column", desktop: "row" }}
              align={{ mobile: "left", desktop: "center" }}
              justify={{ mobile: "left", desktop: "left" }}
              padding={false}
            >
              <Title darkGray fontSize={"20px"}>
                {loadingTable ? "" : gradeBulletin}
              </Title>
            </Section>
            <Section
              maxWidth={"100%"}
              display={"true"}
              direction={{ mobile: "row", desktop: "row" }}
              align={{ mobile: "center", desktop: "center" }}
              justify={{ mobile: "between", desktop: "right" }}
              padding={false}
            >
              <div style={{ marginRight: "0.5rem" }}>
                {!loadingQuaters && (
                  <ActiveStudentsSwitch
                    areAllActiveStudents={areAllActiveStudents}
                    onChangeFilterActiveStudents={handleFilterActiveStudents}
                  />
                )}
              </div>
              <div style={{ marginRight: "0.5rem" }}>
                {loadingQuaters === false && (
                  <SelectBulletin
                    maxWidth="146px"
                    defaultValue={quarter.selected}
                    placeholder="Trimestre"
                    options={quarter?.values}
                    onAction={(e) => handleSelectQuater(e)}
                  />
                )}
              </div>

              <SelectBulletin
                maxWidth="146px"
                defaultValue={status.selected}
                placeholder="Status"
                options={status?.values}
                onAction={(e) => handleSelectStatus(e)}
              />
            </Section>
          </Section>

          {loadingTable ? (
            <Loading />
          ) : dataTableFilter &&
            dataTableFilter.students.length > 0 &&
            !error ? (
            <Style.TableContainer>
              <Style.Table>
                <Style.THead>
                  <Style.TRow isHeader>
                    {dataTableFilter.students[0]?.name?.length > 3 && (
                      <>
                        <Style.THeader style={{ width: "12%" }}>
                          <div
                            style={{
                              display: "flex",
                              gap: 8,
                              alignItems: "center",
                            }}
                          >
                            <span>Estudantes</span>
                            <span onClick={handleSort}>
                              {sortDirection === "asc" ? (
                                <SortAscIcon {...sortIconProps} />
                              ) : (
                                <SortDescIcon {...sortIconProps} />
                              )}
                            </span>
                          </div>
                        </Style.THeader>
                      </>
                    )}

                    {dataTableFilter.students[0]?.ra !== undefined && (
                      <>
                        <Style.THeader style={{ width: "10%" }}>
                          <div>RA</div>
                        </Style.THeader>
                      </>
                    )}

                    {dataTableFilter.available_releases?.map(
                      (available, index) =>
                        available && (
                          <Style.THeader
                            style={{ textAlign: "center" }}
                            key={index}
                          >
                            {available}
                          </Style.THeader>
                        )
                    )}

                    {dataTableFilter.students[0]?.release_status && (
                      <>
                        <Style.THeader style={{ textAlign: "center" }}>
                          Status
                        </Style.THeader>
                      </>
                    )}
                  </Style.TRow>
                </Style.THead>
                <Style.TBody>
                  {dataTableFilter.students?.map((student, index, list) => (
                    <Style.TRow
                      key={`${student}+${student.student_id}`}
                      onClick={() => {
                        handleDataBulletin({
                          id: student.student_id,
                          quarter: reqTable.quarterId,
                        });
                        showDrawer();

                        const previousStudent = list[index - 1];
                        const nextStudent = list[index + 1];
                        getCurrentSelectedStudentInDrawer(student);
                      }}
                      inactiveStudent={student.deleted}
                    >
                      <Style.TData>
                        <span title={student.name}>
                          {concatenateEllipsis(student.name, 15)}
                        </span>
                      </Style.TData>
                      <Style.TData>{student.ra}</Style.TData>

                      {student.release_check.map((item, index) => {
                        return (
                          <Style.TData
                            style={{
                              textAlign: "center",
                            }}
                            key={index}
                          >
                            {item.value ? (
                              <span>
                                <CheckIcon
                                  width={24}
                                  height={24}
                                  fill="#3BA550"
                                />
                              </span>
                            ) : (
                              <span>-</span>
                            )}
                          </Style.TData>
                        );
                      })}
                      <Style.TData style={{ textAlign: "center" }}>
                        <Tag
                          appearance={
                            student.release_status ===
                            EnumReleaseStatusTypes.Aprovado
                              ? "success"
                              : "warning"
                          }
                        >
                          {student.release_status}
                        </Tag>
                      </Style.TData>
                    </Style.TRow>
                  ))}
                </Style.TBody>
              </Style.Table>
            </Style.TableContainer>
          ) : (
            <Warning message={t(`${TRANSLATION_PREFIX}.warning`)} />
          )}
        </Container>
      </Style.Wrapper>
    </Style.Container>
  );
};
