/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import SettingsIcon from "@material-ui/icons/Settings";
import CastForEducationIcon from "@material-ui/icons/CastForEducation";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import BookIcon from "@material-ui/icons/Book";
import VideoLabelIcon from "@material-ui/icons/VideoLabel";
import { StepIconProps } from "@material-ui/core/StepIcon";
import clsx from "clsx";

////////////////////////// Steps ////////////////////////

function getSteps() {
  return [
    { label: "Pagamento", stepType: "smallstep" },
    { label: "Vaga reservada", stepType: "bigstep" },
    { label: "Documentação", stepType: "smallstep" },
    { label: "Contrato", stepType: "smallstep" },
    { label: "Aluno Matriculado", stepType: "bigstep" },
    { label: "Logins", stepType: "smallstep" },
    { label: "Onboarding", stepType: "bigstep" },
  ];
}

function reducedGetSteps() {
  return [
    { label: "Reserva de Vaga", stepType: "bigstep" },
    { label: "Matrícula", stepType: "bigstep" },
    { label: "Onboarding", stepType: "bigstep" },
  ];
}

///////////////// Steps Grandes ////////////////////////

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundColor: "#00AEEF",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundColor: "#ec008c",
  },
});

function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    2: <BookIcon />,
    5: <GroupAddIcon />,
    7: <CastForEducationIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

function ColorlibStepIconReduced(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <BookIcon />,
    2: <GroupAddIcon />,
    3: <CastForEducationIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

const useColorlibStepIconStylesVertical = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundColor: "#00AEEF",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundColor: "#ec008c",
  },
});

function ColorlibStepIconVertical(props: StepIconProps) {
  const classes = useColorlibStepIconStylesVertical();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    2: <BookIcon />,
    5: <GroupAddIcon />,
    7: <CastForEducationIcon />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

////////////// Steps Pequenos //////////////////////////////

const useColorlibStepSmallIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#f2f2f2",
    width: 10,
    height: 10,
    marginTop: 18,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundColor: "#00AEEF",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundColor: "#ec008c",
  },
});

function ColorlibStepSmallIcon(props: StepIconProps) {
  const classes = useColorlibStepSmallIconStyles();
  const { active, completed } = props;
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    ></div>
  );
}

const useColorlibStepSmallIconStylesVertical = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 10,
    height: 10,
    marginLeft: 18,
    marginBottom: 8,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundColor: "#00AEEF",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundColor: "#ec008c",
  },
});

function ColorlibStepSmallIconVertical(props: StepIconProps) {
  const classes = useColorlibStepSmallIconStylesVertical();
  const { active, completed } = props;
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    ></div>
  );
}

/////////////// Conectores ////////////////////

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient(90deg, rgba(236,0,140,1) 0%, rgba(0,205,239,1) 50%, rgba(0,174,239,1) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundColor: "#ec008c",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#B7B7BD",
    borderRadius: 1,
  },
})(StepConnector);

const ColorlibConnectorVertical = withStyles({
  alternativeLabel: {
    right: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient(to bottom, rgba(236,0,140,1) 0%, rgba(0,205,239,1) 50%, rgba(0,174,239,1) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundColor: "#ec008c",
    },
  },
  line: {
    width: 3,
    border: 0,
    backgroundColor: "#B7B7BD",
    borderRadius: 11,
    marginTop: "0.1em",
    marginBottom: "0.1em",
    marginLeft: 10,
  },
})(StepConnector);

//////////////// Styles CORE ///////////////////

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootHorizontal: {
      width: "100%",
    },
    rootVertical: {
      height: "100%",
      width: 250,
    },
    rootReduced: {
      width: "100%",
    },
  })
);

//////////////// Components ///////////////////

interface StepperInterface {
  step: number;
}

export function HorizontalLinearStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(3);
  const steps = getSteps();

  return (
    <div className={classes.rootHorizontal}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<ColorlibConnector />}
      >
        {steps.map((StepContent, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: { optional?: React.ReactNode } = {};
          return (
            <Step key={StepContent.label} {...stepProps}>
              <StepLabel
                StepIconComponent={
                  StepContent.stepType === "bigstep"
                    ? ColorlibStepIcon
                    : ColorlibStepSmallIcon
                }
                {...labelProps}
              >
                {StepContent.label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}

export function VerticalLinearStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(6);
  const steps = getSteps();

  return (
    <div className={classes.rootVertical}>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        connector={<ColorlibConnectorVertical />}
      >
        {steps.map((StepContent, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: { optional?: React.ReactNode } = {};
          return (
            <Step key={StepContent.label} {...stepProps}>
              <StepLabel
                StepIconComponent={
                  StepContent.stepType === "bigstep"
                    ? ColorlibStepIconVertical
                    : ColorlibStepSmallIconVertical
                }
                {...labelProps}
              >
                {StepContent.label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}

export function ReducedHorizontalStepper({ step }: StepperInterface) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(step);
  const steps = reducedGetSteps();

  return (
    <div className={classes.rootHorizontal}>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<ColorlibConnector />}
      >
        {steps.map((StepContent, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: { optional?: React.ReactNode } = {};
          return (
            <Step key={StepContent.label} {...stepProps}>
              <StepLabel
                StepIconComponent={ColorlibStepIconReduced}
                {...labelProps}
              >
                {StepContent.label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}

export function ReducedVerticalStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(6);
  const steps = reducedGetSteps();

  return (
    <div className={classes.rootVertical}>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        connector={<ColorlibConnectorVertical />}
      >
        {steps.map((StepContent, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: { optional?: React.ReactNode } = {};
          return (
            <Step key={StepContent.label} {...stepProps}>
              <StepLabel
                StepIconComponent={
                  StepContent.stepType === "bigstep"
                    ? ColorlibStepIconVertical
                    : ColorlibStepSmallIconVertical
                }
                {...labelProps}
              >
                {StepContent.label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}
