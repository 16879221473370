import styled from "styled-components";
import * as colors from "styles/colors";

export const TableContainer = styled.div`
  margin-top: ${({ theme }) => theme.space[5]};

  h2 {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: ${({ theme }) => theme.fontSizes.web.h4};
    color: ${({ theme }) => theme.colors.greyScale95};
  }

  table {
    margin-top: ${({ theme }) => theme.space[5]};
    display: flex;
    flex-direction: column;

    tr,
    td,
    th {
      display: flex;
      flex: 1;

      p {
        margin: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: ${({ theme }) => theme.space[3]};
      }
    }

    thead {
      border-bottom: 1px solid ${colors.GRAY_SCALE};
    }

    tr {
      & > th:first-child,
      & > td:first-child {
        padding: ${({ theme }) => theme.space[2]};
        ${({ theme }) => theme.space[4]};
      }
    }

    td {
      border-bottom: 1px solid ${colors.NEW_GRAY_THREE};
    }

    td,
    th {
      padding: ${({ theme }) => theme.space[2]};
    }

    tr {
      cursor: pointer;
    }
  }
`;
