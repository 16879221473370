import matematicaSvg from "static/img/curricular-components/matematica.svg";
import portuguesSvg from "static/img/curricular-components/portugues.svg";
import cienciasSvg from "static/img/curricular-components/ciencias-natureza.svg";
import cienciasHumanasSvg from "static/img/curricular-components/ciencias-humanas.svg";
import corpoSvg from "static/img/curricular-components/corpo-movimento.svg";
import filosofiaSvg from "static/img/curricular-components/filosofia.svg";
import fisicaSvg from "static/img/curricular-components/fisica.svg";
import geografiaSvg from "static/img/curricular-components/geografia.svg";
import historiaSvg from "static/img/curricular-components/historia.svg";
import inglesSvg from "static/img/curricular-components/ingles.svg";
import quimicaSvg from "static/img/curricular-components/quimica.svg";
import biologiaSvg from "static/img/curricular-components/biologia.svg";
import codingSvg from "static/img/curricular-components/coding.svg";

export interface IItinerarieGradeIcon {
  course: string;
}

export const ItinerarieGradeIcon = ({ course }: IItinerarieGradeIcon) => {
  const itinerarieGradeOptions = [
    {
      label: "Ciências da Natureza",
      alt: "Componente curricular de Ciências da Natureza",
      src: cienciasSvg,
    },
    {
      label: "Biologia",
      alt: "Componente curricular de Biologia",
      src: biologiaSvg,
    },
    {
      label: "Ciências Humanas",
      alt: "Componente curricular de Ciências Humanas",
      src: cienciasHumanasSvg,
    },
    {
      label: "Filosofia",
      alt: "Componente curricular de Filosofia",
      src: filosofiaSvg,
    },
    {
      label: "Geografia",
      alt: "Componente curricular de Geografia",
      src: geografiaSvg,
    },
    {
      label: "História",
      alt: "Componente curricular de História",
      src: historiaSvg,
    },
    { label: "Física", alt: "Componente curricular de Física", src: fisicaSvg },

    {
      label: "Matemática",
      alt: "Componente curricular de Matemática",
      src: matematicaSvg,
    },
    {
      label: "Química",
      alt: "Componente curricular de Química",
      src: quimicaSvg,
    },
    {
      label: "Coding",
      alt: "Componente curricular de Coding",
      src: codingSvg,
    },
    {
      label: "Língua Portuguesa",
      alt: "Componente curricular de Língua Portuguesa",
      src: portuguesSvg,
    },
    { label: "Inglês", alt: "Componente curricular de Inglês", src: inglesSvg },
    {
      label: "Corpo e Movimento",
      alt: "Componente curricular de Corpo e Movimento",
      src: corpoSvg,
    },
  ];

  const pathIcon = itinerarieGradeOptions.find((item) => item.label === course);

  return (
    <img
      src={pathIcon?.src ?? itinerarieGradeOptions[0].src}
      alt={pathIcon?.alt ?? itinerarieGradeOptions[0].alt}
    />
  );
};
