// Style
import { FormContainer, PlanContainer } from "../../style";
// Libs
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";
// Components
import { InputDate, MenuBar } from "components";
import { SelectInput } from "components/atoms/SelectInput";
import { InputText } from "components/atoms/InputText";
// Interfaces
import { IPlanForm } from "./interface";
// Icon
import infoIcon from "static/img/leading-icon.svg";
// Helpers
import { PLAN_NAME_DISPLAY } from "helpers/constants";

export const Plan = ({
  register,
  errors,
  watch,
  units,
  paymentPlanMonths,
  years,
  plan,
  edit,
}: IPlanForm) => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.paymentPlan.components.plan";

  const EnrollmentTypes = [
    { value: "ambos", label: "Todas" },
    { value: "matricula", label: "Matrícula" },
    { value: "rematricula", label: "Rematrícula" },
  ];

  const selectedUnit = (unitId: string) => {
    if (units) {
      return units.find(
        (unitSelected) => unitSelected.unit.id.toString() === unitId
      );
    }
  };

  return (
    <PlanContainer>
      <MenuBar title={t(`${TRANSLATE}.title`)} />
      <FormContainer>
        <h4>{t(`${TRANSLATE}.planconfig`)}</h4>

        <div className="inLine">
          <InputText
            label={t(`${TRANSLATE}.labelPlanName`)}
            placeholder={t(`${TRANSLATE}.placeHolderPlanName`)}
            id="planName"
            register={register}
            error={errors?.planName?.message}
          />

          <SelectInput
            label={t(`${TRANSLATE}.labelPlanDisplayName`)}
            id="displayName"
            register={register}
            error={errors?.displayName?.message}
            icon={
              <img
                height={20}
                width={20}
                src={infoIcon}
                alt="info"
                data-tooltip-id="displayName"
                data-tooltip-content={t(`${TRANSLATE}.tooltipDisplayName`)}
              />
            }
            disabled={edit}
            watch={watch}
          >
            <option value="">{t(`${TRANSLATE}.selectPlanDisplayName`)}</option>
            {PLAN_NAME_DISPLAY.map((plan) => {
              return (
                <option value={plan.name} key={plan.name}>
                  {plan.name}
                </option>
              );
            })}
          </SelectInput>

          <Tooltip id="displayName" />

          <InputText
            label={t(`${TRANSLATE}.labelTotvsCode`)}
            width={228}
            placeholder={t(`${TRANSLATE}.placeHolderTotvsCode`)}
            id="totvsCode"
            register={register}
            error={errors?.totvsCode?.message}
            disabled={edit}
          />

          <SelectInput
            label={t(`${TRANSLATE}.labelEnrollmentType`)}
            id="enrollmentType"
            register={register}
            error={errors?.enrollmentType?.message}
            width={147}
            watch={watch}
          >
            {EnrollmentTypes.map((enrollmentType, index) => {
              return (
                <option value={enrollmentType.value} key={index}>
                  {enrollmentType.label}
                </option>
              );
            })}
          </SelectInput>
        </div>

        <div className="inLine">
          <SelectInput
            label={t(`${TRANSLATE}.labelUnit`)}
            id="unit"
            register={register}
            error={errors?.unit?.message}
            width={309}
            disabled={edit}
            watch={watch}
          >
            <option value="">{t(`${TRANSLATE}.selectUnit`)}</option>
            {units.map((units) => {
              return (
                <option value={units.unit.id} key={units.unit.id}>
                  {units.unit.name}
                </option>
              );
            })}
          </SelectInput>

          <SelectInput
            label={t(`${TRANSLATE}.labelEducationalLevel`)}
            id="educationalLevel"
            register={register}
            error={errors?.educationalLevel?.message}
            width={228}
            disabled={!watch("unit") || edit}
            watch={watch}
          >
            <option value="">{t(`${TRANSLATE}.selectEducationalLevel`)}</option>
            {watch("unit") &&
              selectedUnit(
                watch("unit").toString()
              )?.unit.educational_level.map((educational_level, index) => {
                return (
                  <option value={educational_level.id} key={index}>
                    {educational_level.name}
                  </option>
                );
              })}
          </SelectInput>

          <SelectInput
            label={t(`${TRANSLATE}.labelFirstInstallment`)}
            id="firstInstallment"
            register={register}
            error={errors?.firstInstallment?.message}
            width={228}
            watch={watch}
          >
            <option value="">{t(`${TRANSLATE}.selectFirstInstallment`)}</option>
            {paymentPlanMonths.map((planMonth, index) => {
              return (
                <option value={planMonth.start_month} key={index}>
                  {planMonth.start_month_display}
                </option>
              );
            })}
          </SelectInput>

          <SelectInput
            label={t(`${TRANSLATE}.labelYear`)}
            id="year"
            register={register}
            error={errors?.year?.message}
            width={147}
            disabled={edit}
            watch={watch}
          >
            <option value="">{t(`${TRANSLATE}.selectYear`)}</option>
            {years.results.map((year, index) => {
              return (
                <option value={year.value} key={index}>
                  {year.label}
                </option>
              );
            })}
          </SelectInput>
        </div>

        <h4>
          {t(`${TRANSLATE}.validityPeriod`)}
          <img
            height={24}
            width={24}
            src={infoIcon}
            alt="info"
            data-tooltip-id="validityPeriod"
            data-tooltip-content={t(`${TRANSLATE}.validityPeriodTooltip`)}
          />
        </h4>

        <Tooltip id="validityPeriod" />

        <div className="inLine">
          <InputDate
            label={t(`${TRANSLATE}.labelStartDate`)}
            boxWidth={228}
            id="validityStartDate"
            register={register}
            error={errors?.validityStartDate?.message}
            value={plan?.data_inicio_vigencia}
          />
          <InputDate
            label={t(`${TRANSLATE}.labelFinalDate`)}
            boxWidth={228}
            id="validityFinalDate"
            register={register}
            error={errors?.validityFinalDate?.message}
            value={plan?.data_final_vigencia}
          />
        </div>

        <h4>
          {t(`${TRANSLATE}.salesPeriod`)}{" "}
          <img
            height={24}
            width={24}
            src={infoIcon}
            alt="info"
            data-tooltip-id="salesPeriod"
            data-tooltip-content={t(`${TRANSLATE}.salesPeriodTooltip`)}
          />
        </h4>

        <Tooltip id="salesPeriod" />

        <div className="inLine">
          <InputDate
            label={t(`${TRANSLATE}.labelStartDate`)}
            boxWidth={228}
            id="salesStartDate"
            register={register}
            error={errors?.salesStartDate?.message}
            value={plan?.data_inicio_venda}
          />
          <InputDate
            label={t(`${TRANSLATE}.labelFinalDate`)}
            boxWidth={228}
            id="salesFinalDate"
            register={register}
            error={errors?.salesFinalDate?.message}
            value={plan?.data_final_venda}
          />
        </div>
      </FormContainer>
    </PlanContainer>
  );
};
