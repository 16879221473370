import {
  ChooseProjectContainer,
  ProjectInfo,
  ProjectInfoContainer,
  ProjectSubInfo,
  Title,
} from "./styles";
import { useChooseProject } from "./ChooseProject.helper";
import { RadioGroup } from "./components";
import {
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
} from "@escolamais-ui-v2/icons";
import { Button } from "@escolamais-ui-v2/react";

const TRANSLATE_PREFIX = "pages.AP.chooseProject";

export const ChooseProject = () => {
  const {
    radioOptions,
    selectedOption,
    handleRadioChange,
    handleSelectProject,
    t,
  } = useChooseProject();

  return (
    <ChooseProjectContainer>
      <Title>{t(`${TRANSLATE_PREFIX}.title`)}</Title>

      <ProjectInfoContainer>
        <ProjectInfo>{t(`${TRANSLATE_PREFIX}.projectInfoTitle`)}</ProjectInfo>
        <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
          <ErrorIcon width={20} height={20} fill="#999" />
          <ProjectSubInfo>
            {t(`${TRANSLATE_PREFIX}.projectInfoSubTitle`)}
          </ProjectSubInfo>
        </div>
      </ProjectInfoContainer>

      <RadioGroup
        options={radioOptions}
        name="options"
        onChange={handleRadioChange}
      />

      <Button
        icon={
          <CheckCircleIcon
            width={20}
            height={20}
            fill={selectedOption ? "#fff" : "#999"}
          />
        }
        size="large"
        buttonText={t(`${TRANSLATE_PREFIX}.concludeButton`)}
        disabled={!selectedOption}
        onClick={handleSelectProject}
        style={{ alignSelf: "end" }}
      />
    </ChooseProjectContainer>
  );
};
