import { ButtonHTMLAttributes } from "react";
import { ArrowCircleRightIcon } from "static/img";
import { ButtonStyled, HoverActionsContainer } from "./styles";

interface ButtonCardProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  actionText?: string;
  icon?: string;
}

export const ButtonCard: React.FC<ButtonCardProps> = ({
  text = "Button",
  actionText = "Acessar",
  icon,
  ...props
}) => {
  return (
    <ButtonStyled {...props}>
      {text}
      <HoverActionsContainer>
        <img src={icon || ArrowCircleRightIcon} alt="Icone de acesso" />
        {actionText}
      </HoverActionsContainer>
    </ButtonStyled>
  );
};
