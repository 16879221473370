import { Checkbox } from "@escolamais-ui-v2/react";
import { HTMLAttributes } from "react";

interface IDiscountOptionProps extends HTMLAttributes<HTMLInputElement> {
  id: string;
  checked: boolean;
  label: string;
  readonly?: boolean;
}
export const DiscountOptionCheckbox = ({
  id,
  checked,
  label,
  readonly,
  ...rest
}: IDiscountOptionProps) => (
  <Checkbox
    id={id}
    checked={checked}
    labelText={label}
    {...rest}
    readOnly={readonly}
  />
);
