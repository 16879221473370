import styled from "styled-components";

export const Container = styled.div`
  max-width: 100%;
  width: 100%;
  padding: 0 ${({ theme }) => theme.space[6]};

  display: flex;
  flex-direction: column;

  // TODO: Add font weight 600
  .details-note {
    margin: ${({ theme }) => theme.space[6]} ${({ theme }) => theme.space[2]};
    font-weight: 600;
    font-size: ${({ theme }) => theme.fontSizes.web.h3};
    color: ${({ theme }) => theme.colors.greyScale95};
  }

  h3 {
    font-size: ${({ theme }) => theme.fontSizes.mobile.h3};
    font-weight: bold;
    margin-bottom: ${({ theme }) => theme.space[6]};
  }
`;

export const Note = styled.div`
  display: block;
  text-align: center;
  margin: ${({ theme }) => theme.space[4]} auto;

  p {
    font-style: italic;
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    font-size: ${({ theme }) => theme.fontSizes.web.sm};
  }
`;

export const StudentInfo = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.space[5]};
  margin-bottom: ${({ theme }) => theme.space[6]};

  img {
    width: 65px;
    height: 65px;
    border-radius: ${({ theme }) => theme.radii.full};
    margin-right: ${({ theme }) => theme.space[4]};
  }

  .details {
    strong {
      font-size: ${({ theme }) => theme.fontSizes.web.h2};
      color: ${({ theme }) => theme.colors.brandingOrange};
    }

    p {
      font-size: ${({ theme }) => theme.fontSizes.web.default};
    }

    a {
      width: fit-content;
      font-size: ${({ theme }) => theme.fontSizes.web.xsm};
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      color: ${({ theme }) => theme.colors.primaryDefault};
      display: flex;
      align-items: center;
      gap: ${({ theme }) => theme.space[2]};
      margin-top: ${({ theme }) => theme.space[2]};
      text-decoration: underline;

      &:hover {
        color: ${({ theme }) => theme.colors.primaryLight80};
      }
    }
  }
`;
