import { NewThStyle } from "./style";

interface INewTable {
  name: string;
  height?: number;
  width?: number;
  icon?: JSX.Element;
}

export const NewTh = ({ name, height = 37, width = 147, icon }: INewTable) => {
  return (
    <NewThStyle height={height} width={width}>
      {name} {icon ? icon : null}
    </NewThStyle>
  );
};
