import styled from "styled-components";

export const StudentDocumentationStyled = styled.div`
  div.enrollment-title {
    font-size: 20px;
    font-weight: bold;
    color: #ed008c;

    div.subtitle {
      font-weight: 500;
      font-size: 16px;
      color: black;
    }
  }
`;

export const LinkStyled = styled.span`
  cursor: pointer;
  color: #00a1ed;
  text-decoration: underline;
`;
