import styled from "styled-components";
import * as colors from "styles/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  max-width: 636px;

  & > div {
    max-width: 636px;
    width: 100%;
  }

  .grade {
    text-align: center;

    p {
      text-transform: uppercase;
      font-size: 12px;
      color: ${colors.GRAY_SCALE};
    }

    strong {
      color: ${colors.NEW_GRAY_SCALE};
    }
  }

  .cardStudent {
    width: 100%;
    margin-top: 16px;
    background: rgba(109, 40, 217, 0.05);
    border-radius: 29px;
    padding: 24px 16px;

    .cardStudentName {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;

      img {
        width: 56px;
        height: 56px;
        border-radius: 50%;
      }
    }

    .cardStudentHeader {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 24px;

      h1 {
        margin-bottom: 0;
      }

      button {
        width: fit-content;
        height: fit-content;
      }
    }

    .cardStudentContent {
      .data {
        p {
          margin-left: 8px;
        }
      }

      .infos {
        display: flex;
        justify-content: space-between;
        margin-right: 22px;

        @media (min-width: 568px) {
          justify-content: flex-start;

          > div {
            width: 50%;
          }
        }
      }
    }
  }

  .buttons {
    width: 100%;
    max-width: 720px;
    width: 100%;
    margin-top: 24px;
    display: flex;
    gap: 16px;
  }

  @media (max-width: 1024px) {
    margin: 0 16px;
  }
`;

export const LoadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h4 {
    text-align: center;
  }
`;
