// react
import { useTranslation } from "react-i18next";
// style
import { ContainerCardLink, Content, InputCopy } from "./style";
// interface
import { LinkInterface } from "../../interface";
import { toastInfo } from "components/Toast";

export const CardLink = ({ link }: LinkInterface) => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.indication.components.card";

  return (
    <ContainerCardLink>
      <Content>
        <strong>{t(`${TRANSLATE}.content`)}</strong>
      </Content>
      <InputCopy
        value={link}
        onClick={() => {
          navigator.clipboard.writeText(link);
          toastInfo(t(`${TRANSLATE}.message`));
        }}
        readOnly
      />
    </ContainerCardLink>
  );
};
