import { IIndicationLink } from "interfaces/indication";
import { KanataAPI } from "./api";
import { ErrorHandlerFunction } from "./service-helpers";

interface IGetIndicationLink {
  guardian_id: number;
}

export const indicationLink = async ({ guardian_id }: IGetIndicationLink) => {
  const response = await KanataAPI.get<IIndicationLink>(
    `/financial/link_indicacao_responsavel/${guardian_id}/`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};
