import styled from "styled-components";
import * as colors from "styles/colors";

export const CancelFoodPackageStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div.title {
    font-size: 24px;
    font-weight: bold;
    color: ${colors.BLUE};
  }
`;
