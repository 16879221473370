import * as Style from "./GuardianSchoolReport.style";
import * as Interface from "./GuardianSchoolReport.types";

import { TableBulletin } from "components/molecules";
import { TableRow, TableColumn } from "components/atoms";
import { IFinalAverages } from "pages/AP/Boletim/components/BoletimPDF/interface";

const dataAtual = new Date();
const anoAtual = dataAtual.getFullYear();

export const GuardianSchoolReportFinal = ({
  year,
  tbody,
}: Interface.GuardianSchoolReportFinalProps) => {
  const finalSchoolReport = new Map<string, any>(Object.entries(tbody));

  const getFinalSchoolReport = (quarter: string, subject: string) => {
    if (!finalSchoolReport.get(quarter)) {
      return { quarter_avg: "-", final_average: "-" };
    }

    if (quarter === Interface.SchoolReportColuns.MF) {
      const finalAverageReport = finalSchoolReport
        .get(quarter)
        .find((note: any) => note.subject === subject);

      if (finalAverageReport === undefined) {
        return {
          final_average: "--",
        };
      }

      return { final_average: finalAverageReport.final_average };
    }

    const finalAvg = finalSchoolReport
      .get(quarter)
      .find((note: any) => note.subject === subject);

    return { quarter_avg: finalAvg?.quarter_avg ? finalAvg.quarter_avg : "--" };
  };

  const thead =
    `${anoAtual}` !== year
      ? ["Componente", "M1", "M2", "M3", "MF"]
      : ["Componente", "M1", "M2", "M3", "MF", "F"];

  return (
    <Style.Container>
      <TableBulletin
        theadChildren={
          <>
            {thead?.map(
              (item: string) =>
                item && (
                  <TableRow
                    key={item}
                    item={item}
                    visible={{ mobile: true, desktop: true }}
                  />
                )
            )}
          </>
        }
        tbodyChildren={
          <>
            {finalSchoolReport.get(Interface.SchoolReportColuns.MF)?.map(
              (componente: any, index: any) =>
                componente && (
                  <tr key={componente.subject}>
                    <TableColumn
                      maxWidth="200px"
                      item={
                        <>
                          {componente.subject}
                          {finalSchoolReport
                            .get(Interface.SchoolReportColuns.MF)
                            ?.map(
                              (item: IFinalAverages) =>
                                item.subject === componente?.subject && (
                                  <Style.BoxTag
                                    key={`${componente.subject}=${item?.final_average_status}`}
                                  >
                                    <Style.TagSubjects
                                      status={`${item?.final_average_status} - tag`}
                                    >
                                      {item?.final_average_status}
                                    </Style.TagSubjects>
                                  </Style.BoxTag>
                                )
                            )}
                        </>
                      }
                      visible={{ mobile: true, desktop: true }}
                    />
                    <TableColumn
                      item={
                        <>
                          {
                            getFinalSchoolReport(
                              Interface.SchoolReportColuns.M1,
                              componente.subject
                            ).quarter_avg
                          }
                        </>
                      }
                      visible={{ mobile: true, desktop: true }}
                    />
                    <TableColumn
                      item={
                        <>
                          {
                            getFinalSchoolReport(
                              Interface.SchoolReportColuns.M2,
                              componente.subject
                            ).quarter_avg
                          }
                        </>
                      }
                      visible={{ mobile: true, desktop: true }}
                    />
                    <TableColumn
                      item={
                        <>
                          {
                            getFinalSchoolReport(
                              Interface.SchoolReportColuns.M3,
                              componente.subject
                            ).quarter_avg
                          }
                        </>
                      }
                      visible={{ mobile: true, desktop: true }}
                    />
                    <TableColumn
                      item={
                        <>
                          {
                            getFinalSchoolReport(
                              Interface.SchoolReportColuns.MF,
                              componente.subject
                            )?.final_average
                          }
                        </>
                      }
                      visible={{ mobile: true, desktop: true }}
                    />
                  </tr>
                )
            )}
          </>
        }
      />
    </Style.Container>
  );
};
