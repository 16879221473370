import styled from "styled-components";
import { BLUE } from "styles/colors";

export const FormItemDeAlimentacaoStyled = styled.div`
  div.title {
    font-size: 24px;
    font-weight: bold;
    color: ${BLUE};
  }
`;
