// Libs
import { useContext, useState, forwardRef, useImperativeHandle } from "react";
import { Modal as ModalMUI } from "@material-ui/core";

// Components
import { Pagination } from "components";
import Loading from "components/Loading";
import { toastError } from "components/Toast";

// Styles
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ModalDiv, ModalHeader } from "./style";

// Helpers
import { StudentFoodCreditHistory } from "interfaces/mealInterfaces";
import { formatMoney } from "helpers/helpers";

// Service
import { getStudentFoodCreditHistory } from "services/meals.service";

type ModalFoodCreditHistoryProps = {
  isOpen: boolean;
  name: string;
  enrollment: number;
  foodCreditHistory: StudentFoodCreditHistory | null;
  setFoodCreditHistory: (foodCreditHistory: StudentFoodCreditHistory) => void;
  setToggleModalHistory: (toggleModalHistory: boolean) => void;
};

const ModalFoodCreditHistory = forwardRef(
  (
    {
      isOpen,
      name,
      enrollment,
      foodCreditHistory,
      setFoodCreditHistory,
      setToggleModalHistory,
    }: ModalFoodCreditHistoryProps,
    ref
  ) => {
    const [isLoadingHistory, setIsLoadingHistory] = useState(true);

    // Pagination
    const [next, setNext] = useState<string>();
    const [previous, setPrevious] = useState<string | null>(null);
    const [page, setPage] = useState<number>(1);

    useImperativeHandle(
      ref,
      () => ({
        setIsLoadingHistory: () => {
          setIsLoadingHistory(false);
        },
        setNextPage: (url: string) => {
          setNext(url);
        },
        setPreviousPage: (url: string) => {
          setPrevious(url);
        },
      }),
      []
    );

    const Offset = async (direction: string) => {
      setIsLoadingHistory(true);

      const response = await getStudentFoodCreditHistory({
        page:
          direction === "foward"
            ? page + 1
            : direction === "backwards"
            ? page - 1
            : parseInt(direction),
        id: enrollment,
      });
      if (response && response.status === 200) {
        setFoodCreditHistory(response.data);
        setNext(response.data.next);
        setPrevious(response.data.previous);
        setIsLoadingHistory(false);
      } else {
        toastError(
          "Erro ao buscar dados de pacotes de alimentação da próxima página!"
        );
        setIsLoadingHistory(false);
      }
    };

    const handleCloseModal = () => {
      setToggleModalHistory(false);
      setIsLoadingHistory(true);
      setPage(1);
    };

    return (
      <ModalMUI
        className="d-flex align-items-center justify-content-center"
        open={isOpen}
        role="dialog"
      >
        <ModalHeader className="col-12 col-lg-8 bg-white">
          <FontAwesomeIcon
            className="close"
            onClick={handleCloseModal}
            size="lg"
            icon={faTimes as IconProp}
            aria-label="Close"
            data-dismiss="modal"
          />
          {isLoadingHistory ? (
            <div className="loadingStatus d-flex flex-column align-items-center justify-content-center py-5">
              <p className="w-50 text-center">
                Aguarde enquanto os dados do aluno(a)
              </p>
              <p>
                <strong>{name}</strong> estão sendo carregados!
              </p>
              <Loading />
            </div>
          ) : (
            <ModalDiv>
              <h1 className="title font-weight-bold">{name}</h1>
              <p>
                Abaixo você encontra todos os lançamentos de crédito do aluno
                selecionado
              </p>

              <div className="row align-items-center">
                {foodCreditHistory?.results.map((release) => (
                  <div
                    className="col-12 col-sm-6 col-xl-4 mt-2 d-flex align-items-center justify-content-center"
                    key={release.id}
                  >
                    <div className="release d-flex flex-column align-items-center border rounded p-3">
                      <div className="w-100 d-flex justify-content-between">
                        <div>
                          <small>Nome:</small>
                          <p className="font-weight-bold mb-0 text-start">
                            <span>{release.user_name}</span>
                          </p>
                        </div>
                        <div className="d-flex flex-column align-items-end">
                          <small>Valor:</small>
                          <p className="font-weight-bold mb-0">
                            {formatMoney(Number(release.credit))}
                          </p>
                        </div>
                      </div>

                      <div className="w-100 d-flex justify-content-between pt-2">
                        <div>
                          <small>Motivo:</small>
                          <p className="font-weight-bold mb-0">
                            {release.reason_label}
                          </p>
                        </div>
                        <div className="d-flex flex-column align-items-end">
                          <small>Data:</small>
                          <p className="font-weight-bold mb-0">
                            {new Date(release.created_at).toLocaleDateString()}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {foodCreditHistory && foodCreditHistory.count > 10 && (
                <Pagination
                  previous={previous!}
                  count={foodCreditHistory.count}
                  next={next}
                  page={page}
                  setPage={setPage}
                  Offset={Offset}
                />
              )}
            </ModalDiv>
          )}
        </ModalHeader>
      </ModalMUI>
    );
  }
);

export default ModalFoodCreditHistory;
