import styled from "styled-components";

export const PeriodEnrollmentReenrollmentStyled = styled.div`
  padding: 48px 97px;

  h2 {
    font-size: ${({ theme }) => theme.fontSizes.web.h2};
    font-weight: 600; // TODO: nao tem na lib
    line-height: 48px; // TODO: nao tem na lib
    color: ${({ theme }) => theme.colors.primaryDefault};
  }
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h4 {
    ${({ theme }) => theme.fontSizes.web.h4}
    text-align: center;
  }

  div {
    margin: ${({ theme }) => theme.space[4]};
  }
`;

export const Form = styled.form`
  display: flex;
  align-items: center;

  gap: 0 ${({ theme }) => theme.space[4]};
  margin-top: ${({ theme }) => theme.space[4]};
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${({ theme }) => theme.space[1]} 0;
  width: 146px;

  label {
    float: left;
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    font-size: ${({ theme }) => theme.fontSizes.web.sm};
    line-height: ${({ theme }) => theme.lineHeights.normal};
    color: ${({ theme }) => theme.colors.greyScale95};

    margin: 0;

    text-transform: uppercase;
  }

  select {
    box-sizing: border-box;
    background: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.greyScale40};

    color: ${({ theme }) => theme.colors.greyScale95};
    height: 37.7px; //nao tem na lib
    padding-left: ${({ theme }) => theme.space[2]};
    font-size: ${({ theme }) => theme.fontSizes.web.sm};

    cursor: pointer;
  }

  :last-of-type {
    width: 228px;
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.space[6]};

  h4 {
    font-size: ${({ theme }) => theme.fontSizes.web.h4};
    font-weight: 600; // TODO: nao tem na lib
    line-height: 30px; // TODO: nao tem na lib
  }
`;

export const TR = styled.tr`
  cursor: pointer;
`;
