import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Icon from "@mdi/react";
import { mdiMenuDown, mdiMenuUp } from "@mdi/js";

import { Loading } from "components";
import { ButtonFilterBulletin } from "components/atoms/Button/style";
import { GroupButtons } from "components/molecules/GroupButtons";
import { ClassDiaryDrawer } from "../ClassDiaryDrawer";

import {
  IGradeAttendanceSheet,
  IGradeGroupFormatedClasses,
  IStudentAttendanceSheet,
} from "interfaces/gradeGroupInterfaces";
import { orderBy } from "helpers/helpers";
import { getAttendanceSheet } from "services/grades.service";

import * as S from "./styles";

interface IStudentView {
  grade: IGradeGroupFormatedClasses[];
}

export const StudentView = ({ grade }: IStudentView) => {
  const { t } = useTranslation();

  const [periodSelected, setPeriodSelected] = useState(grade[0].levelValue);
  const [gradeSelected, setGradeSelected] = useState(grade[0].years[0].id);
  const [turmaSelected, setTurmaSelected] = useState(
    grade[0].years[0].sections[0].id
  );

  const [isLoading, setIsLoading] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState("asc");
  const [studentsListData, setStudentsListData] = useState<
    IGradeAttendanceSheet[]
  >([]);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const [selectedStudent, setSelectedStudent] =
    useState<IStudentAttendanceSheet>();
  const [selectedStudentIndex, setSelectedStudentIndex] = useState<number>();

  const prefix = "pages.AP.researchCall.studentView";

  const iconPath = selectedFilter === "asc" ? mdiMenuDown : mdiMenuUp;

  useEffect(() => {
    const handleGetClasses = async () => {
      setIsLoading(true);

      const res = await getAttendanceSheet(turmaSelected);

      setStudentsListData(res?.data);
      setIsLoading(false);
    };

    handleGetClasses();
  }, [turmaSelected]);

  const handleSelectedFilter = () => {
    setSelectedFilter((prevState) => (prevState === "asc" ? "desc" : "asc"));
  };

  const handleFilterStudentsData = () => {
    setIsLoading(true);
    handleSelectedFilter();

    const { orderedData } = orderBy(
      "name",
      studentsListData[0].enrollments,
      selectedFilter
    );

    setStudentsListData((prevState) => [
      {
        gradegroup_display: prevState[0].gradegroup_display,
        enrollments: orderedData,
      },
    ]);

    setIsLoading(false);
  };

  const handleSelectedStudentToDrawer = (
    student: IStudentAttendanceSheet,
    index: number
  ) => {
    setSelectedStudent(student);
    setSelectedStudentIndex(index);
    setDrawerIsOpen((prevState) => !prevState);
  };

  return (
    <S.Container>
      {selectedStudent && typeof selectedStudentIndex === "number" && (
        <ClassDiaryDrawer
          title={studentsListData[0]?.gradegroup_display}
          open={drawerIsOpen}
          onClose={() => setDrawerIsOpen((prevState) => !prevState)}
          selectedStudent={selectedStudent}
          setSelectedStudent={setSelectedStudent}
          studentsListData={studentsListData}
          selectedStudentIndex={selectedStudentIndex}
          setIndex={setSelectedStudentIndex}
        />
      )}

      <div className="group-buttons">
        <GroupButtons label="Ciclo" quarter={false}>
          {grade.map((item) => (
            <ButtonFilterBulletin
              key={item.levelValue}
              active={item.levelValue === periodSelected}
              onClick={() => {
                setPeriodSelected(item.levelValue);
                setGradeSelected(item.years[0].id);
                setTurmaSelected(item.years[0].sections[0].id);
                setSelectedFilter("asc");
              }}
              disabled={isLoading}
            >
              {item.level}
            </ButtonFilterBulletin>
          ))}
        </GroupButtons>

        <GroupButtons label="Ano" quarter={false}>
          {grade
            .find((item) => item.levelValue === periodSelected)
            ?.years.map((item) => (
              <ButtonFilterBulletin
                key={item.id}
                active={item.id === gradeSelected}
                onClick={() => {
                  setGradeSelected(item.id);
                  setTurmaSelected(item.sections[0].id);
                  setSelectedFilter("asc");
                }}
                disabled={isLoading}
              >
                {item.grade}
              </ButtonFilterBulletin>
            ))}
        </GroupButtons>

        <GroupButtons label="Turma" quarter={false}>
          {grade
            .find((item) => item.levelValue === periodSelected)
            ?.years.find((item) => item.id === gradeSelected)
            ?.sections.map((item) => (
              <ButtonFilterBulletin
                key={item.id}
                active={item.id === turmaSelected}
                onClick={() => {
                  setTurmaSelected(item.id);
                  setSelectedFilter("asc");
                }}
                disabled={isLoading}
              >
                {item.letter}
              </ButtonFilterBulletin>
            ))}
        </GroupButtons>
      </div>

      {!isLoading ? (
        <div className="table-data">
          <h2>{studentsListData[0].gradegroup_display}</h2>

          <table>
            <thead>
              <tr>
                <th>
                  <p onClick={handleFilterStudentsData}>
                    {t(`${prefix}.students`)}
                    <Icon path={iconPath} size={1} />
                  </p>
                </th>
                <th>{t(`${prefix}.ra`)}</th>
                <th>{t(`${prefix}.attendance`)}</th>
              </tr>
            </thead>
            <tbody>
              {studentsListData[0].enrollments.map((student, index) => {
                return (
                  <tr
                    key={student.ra}
                    onClick={() =>
                      handleSelectedStudentToDrawer(student, index)
                    }
                  >
                    <td>{student.name}</td>
                    <td>{student.ra}</td>
                    <S.DataBold
                      bold={!student.attendance_display.startsWith("100%")}
                    >
                      {student.attendance_display}
                    </S.DataBold>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <S.LoadingContent>
          <Loading />
        </S.LoadingContent>
      )}
    </S.Container>
  );
};
