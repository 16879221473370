import { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import HTTP_STATUS from "http-status-codes";
import { createStudentFormData, createStudentSchema } from "./schema";
import { createStudent } from "services/student.service";
import { toastError, toastSuccess } from "components/Toast";
import { useAtomValue, useSetAtom } from "jotai";
import { newCreatedGuardianSignupAtom } from "jotai/newGuardianSignup/atom";
import { newCreatedStudentSignupAtom } from "jotai/newStudentSignup/atom";
import { updateGuardian } from "services/guardian.service";

export const useStudentSignup = () => {
  const newCreatedGuardian = useAtomValue(newCreatedGuardianSignupAtom);
  const setNewCreatedGuardian = useSetAtom(newCreatedGuardianSignupAtom);
  const setNewCreatedStudentSignup = useSetAtom(newCreatedStudentSignupAtom);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<createStudentFormData>({
    resolver: zodResolver(createStudentSchema),
    mode: "all",
    defaultValues: {
      nome: "",
      cpf: "",
      rg: "",
    },
  });

  const history = useHistory();
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.studentSignup";
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmitForm = async (values: createStudentFormData) => {
    setIsSubmitting(true);

    const createdStudent = await createStudent(values);

    if (
      createdStudent?.status === HTTP_STATUS.CREATED &&
      createdStudent?.data &&
      newCreatedGuardian
    ) {
      try {
        const updatedGuardian = await updateGuardian({
          guardianId: newCreatedGuardian.id,
          body: {
            graus_parentesco: [
              {
                estudante_id: createdStudent.data.id,
                grau_parentesco: "",
              },
            ],
          },
        });

        if (updatedGuardian?.data) {
          setNewCreatedGuardian(updatedGuardian.data);
          setNewCreatedStudentSignup(createdStudent.data);
          toastSuccess(t(`${TRANSLATE}.successMessage`));
          return history.push("/dados-vaga");
        }
      } catch (error) {
        toastError("Erro ao vincular o estudante com o responsável");
        return history.push("/");
      }
    }

    if (
      createdStudent?.status !== HTTP_STATUS.CREATED &&
      createdStudent?.data
    ) {
      if (createdStudent?.data.cpf) {
        toastError(t(`${TRANSLATE}.cpfAlreadyExists`));
        setIsSubmitting(false);
        return;
      }

      toastError(t(`${TRANSLATE}.errorMessage`));
      setIsSubmitting(false);
    }
  };

  return {
    handleSubmit,
    handleSubmitForm,
    isSubmitting,
    register,
    errors,
  };
};
