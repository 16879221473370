import { useHistory } from "react-router-dom";
import { CXContainer } from "./style";
import { IsDevelopmentEnvironment } from "helpers/helpers";
import { ButtonCard } from "components/atoms/ButtonCard";
import { useTranslation } from "react-i18next";

const TRANSLATION_PREFIX = "pages.CORE.home.cx";

export const CXHome = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <CXContainer>
      <ButtonCard
        onClick={() => history.push("/responsavel/busca")}
        text={t(`${TRANSLATION_PREFIX}.searchGuardianOrStudent`)}
      />
      <ButtonCard
        onClick={() => history.push("/gerenciar/matriculas")}
        text={t(`${TRANSLATION_PREFIX}.manageEnrollmentProcessQuery`)}
      />
      <ButtonCard
        onClick={() => history.push("/alimentacao/cancelar-pacote-alimentacao")}
        text={t(`${TRANSLATION_PREFIX}.cancelFoodPackage`)}
      />
      <ButtonCard
        onClick={() => history.push("/alimentacao/lancar-credito-alimentacao")}
        text={t(`${TRANSLATION_PREFIX}.launchFoodCredit`)}
      />
      {IsDevelopmentEnvironment() && (
        <ButtonCard
          onClick={() => history.push("/alimentacao/consultar-alimentacao")}
          text={t(`${TRANSLATION_PREFIX}.consultSinglePurchase`)}
        />
      )}
      <ButtonCard
        onClick={() => history.push("/lista-espera")}
        text={t(`${TRANSLATION_PREFIX}.waitingList`)}
      />
      <ButtonCard
        onClick={() => history.push("/consultar-pedidos-alimentacao")}
        text={t(`${TRANSLATION_PREFIX}.consultFoods`)}
      />
      <ButtonCard
        onClick={() => history.push("/boletim")}
        text={t(`${TRANSLATION_PREFIX}.previousApproval`)}
      />
      {IsDevelopmentEnvironment() && (
        <ButtonCard
          onClick={() => history.push("/gerenciar-itens-alimentacao")}
          text={t(`${TRANSLATION_PREFIX}.manageDynamicFoodItems`)}
        />
      )}
      <ButtonCard
        onClick={() => history.push("/gerenciar-vagas")}
        text={t(`${TRANSLATION_PREFIX}.manageVacancies`)}
      />
      <ButtonCard
        onClick={() => history.push("/roteiros-de-estudo/estudante")}
        text={t(`${TRANSLATION_PREFIX}.studyItinerary`)}
      />
    </CXContainer>
  );
};
