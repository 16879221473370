// React
import { useCallback, useEffect, useState } from "react";
// Libs
import HTTP_STATUS from "http-status-codes";
// Services
import { getConsultFoodOrders } from "services/meals.service";
import { getUnits } from "services/units.service";
import { paymentStatusList } from "services/payment.service";
// Interfaces
import { IConsultFoodList } from "interfaces/mealInterfaces";
import { UnitInterface } from "interfaces/unit";
import { IForm, IPaginate } from "./interface";
import { IPaymentStatus } from "interfaces/paymentInterfaces";
// Helpers
import { paginationLogic } from "helpers/helpers";

export const ConsultFoodHelper = (form: IForm) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [newSearch, setNewSearch] = useState(false);

  const [studentList, setStudentList] = useState<IConsultFoodList>();
  const [units, setUnits] = useState<Array<UnitInterface>>();
  const [paymentStatus, setPaymentStatus] = useState<IPaymentStatus>();

  const [page, setPage] = useState<number>(1);

  const [paginate, setPaginate] = useState<IPaginate>({
    initialValue: 1,
    lastValue: 0,
  });

  const offset = (direction?: string) => {
    if (studentList) {
      (async () => {
        setNewSearch(true);
        const response = await getConsultFoodOrders({
          estudante_ou_numero_pedido: form.order_or_name,
          unidade: form.unit,
          status: form.paymentStatus,
          page: direction === "foward" ? page + 1 : page - 1,
        });
        if (response?.status === HTTP_STATUS.OK) {
          setStudentList(response.data);
          paginationLogic({
            direction: direction === "foward" ? true : false,
            page,
            paginate,
            data: response.data,
            oldData: studentList,
            setPage,
            setPaginate,
          });
          setNewSearch(false);
        } else {
          setError(true);
          setNewSearch(false);
        }
      })();
    }
  };

  const getList = async () => {
    setNewSearch(true);

    const response = await getConsultFoodOrders({
      estudante_ou_numero_pedido: form.order_or_name,
      unidade: form.unit,
      status: form.paymentStatus,
      page: 1,
    });

    if (response?.status === HTTP_STATUS.OK) {
      setStudentList(response.data);
      setPage(1);
      setPaginate({
        initialValue: 1,
        lastValue: response.data.results.length,
      });
      setNewSearch(false);
    } else {
      setError(true);
      setNewSearch(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        if (loading) {
          const units = await getUnits();
          if (units && units.status === HTTP_STATUS.OK) {
            setUnits(units.data);
          }

          const status = await paymentStatusList();
          if (status && status.status === HTTP_STATUS.OK) {
            setPaymentStatus(status.data);
            setLoading(false);
          }
        }

        getList();
      } catch (err) {
        setError(true);
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return {
    loading,
    error,
    studentList,
    units,
    paymentStatus,
    paginate,
    offset,
    getList,
    newSearch,
  };
};
