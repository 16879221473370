import styled from "styled-components";
import * as colors from "styles/colors";

export const InputFileStyled = styled.div`
  label.inputName {
    display: flex;
    color: ${colors.NEW_PURPLE};

    font-family: "Poppins";
    font-style: normal;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 700;

    padding-top: 0.8em;
    width: 100%;

    div.iconStyle {
      width: 1.3em;
      margin-right: 0.3em;
    }

    &:hover {
      cursor: pointer;
      color: ${colors.NEW_PURPLE_ONE};
    }
  }
`;
