import styled from "styled-components";

export const HeaderProfileDiv = styled.div`
  background: #f1f1f1;
  padding: 0 1em;
  @media only screen and (min-width: 415px) {
    border-radius: 0.5em;
  }
`;

export const Title = styled.h1`
  margin-top: 1em;
  color: #00aeef;
  font-size: 25px;
  font-weight: bold;

  @media only screen and (max-width: 414px) {
    font-size: 14px;
  }
`;

export const ImgPlaceholder = styled.img`
  border-radius: 50%;
  margin: 1em 1em 1em 0;
  width: 95px;
  height: 95px;
  flex-shrink: 0;

  @media only screen and (max-width: 414px) {
    height: 60px;
    width: 60px;
  }
`;
