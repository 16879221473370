import {
  DataConfirmation,
  DataBox,
  DataBigBox,
  BoldBigger,
  BoldBiggerBlue,
} from "./styles";
import {
  StudentInterface,
  GetCXUserJson,
  StudentDataInterface,
  GuardianInterface,
} from "interfaces/constants";
import { useHistory, useLocation } from "react-router-dom";
import {
  LinearProgress,
  Avatar,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { useContext, useState, useEffect } from "react";
import Placeholder from "static/img/placeholder.png";
import { Button } from "components";
import { BLUE } from "styles/colors";
import { formatCEP, formatDate, formatPhone, formatCPF } from "helpers/helpers";
import { getStudentData } from "services/users.service";
import HTTP_STATUS from "http-status-codes";
import { generateContract } from "services/contract.service";
import { toastError, toastSuccess } from "components/Toast";
import loadingGif from "static/img/loading.gif";
import { ModalGuardianData } from "./ModalGuardianData";

const defaultSpacing = 15;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      width: theme.spacing(defaultSpacing),
      height: theme.spacing(defaultSpacing),
    },
    guardianName: {
      fontWeight: "bold",
      textTransform: "uppercase",
      color: BLUE,
    },
    dataSubtitle: {
      textAlign: "center",
      fontWeight: "bold",
    },
  })
);

type StateType = {
  student: StudentInterface;
  guardian: GetCXUserJson;
};

export const DataConfirm = () => {
  const location = useLocation<StateType>();
  const history = useHistory();
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [submit, setSubmit] = useState(false);

  const [studentData, setStudentData] = useState<StudentDataInterface>();
  const [financialGuardian, setFinancialGuardian] =
    useState<GuardianInterface>();
  const [studentAddress, setStudentAddress] = useState<GuardianInterface>();
  const [planExpiredData, setPlanExpiredData] = useState<boolean | null>();
  const [isExpiratedPlan, setIsExpiratedPlan] = useState<boolean | null>();

  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    const getStudent = async () => {
      const getStudentLocation = await getStudentData({
        id: location.state.student.id,
      });
      if (getStudentLocation && getStudentLocation.status === HTTP_STATUS.OK) {
        setStudentData(getStudentLocation.data);
        setLoading(false);
      } else {
        setError(true);
      }
    };

    try {
      if (location.state.student && location.state.guardian) {
        getStudent();
      } else {
        setError(true);
      }
    } catch (err) {
      setError(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (studentData) {
      studentData.guardians.find(
        (guardian) =>
          guardian.guard_info.guard_id ===
            studentData.last_enrollment.guard_id &&
          setFinancialGuardian(guardian)
      );

      studentData.guardians.find(
        (guardianAddress) =>
          guardianAddress.id ===
            studentData.location.same_address_as_guardian &&
          setStudentAddress(guardianAddress)
      );
    }
  }, [studentData]);

  useEffect(() => {
    if (studentData) {
      setPlanExpiredData(studentData.last_enrollment.plan_expired);
      setIsExpiratedPlan(planExpiredData === true || planExpiredData === null);
    }
  }, [isExpiratedPlan, planExpiredData, studentData]);

  const showTransitionPaymentPlans = () => {
    history.push({
      pathname: `/financeiro/plano-pagamento`,
      state: {
        student: location.state.student,
        guardian: location.state.guardian,
      },
    });
  };

  return (
    <DataConfirmation className="container">
      {loading && <LinearProgress className="mt-3 mb-3" color="secondary" />}
      {error && <div>Erro ao carregar responsável.</div>}
      {!loading &&
        !error &&
        studentData &&
        financialGuardian &&
        location.state.student &&
        location.state.guardian && (
          <>
            <div className="enrollment-title text-center mb-5">
              Confira seus dados para gerarmos o contrato da matrícula de{" "}
              {location.state.student.name}
            </div>
            <div className="text-center mb-3">
              Para alteração de dados entre em contato com a equipe da Escola
              Mais pelo número (11) 5555-3725
            </div>
            <DataBigBox>
              <div className="container">
                <div className="row justify-content-center">
                  <Avatar
                    alt="guardianPicture"
                    src={Placeholder}
                    className={classes.avatar}
                  />
                </div>
                <div className="row justify-content-center mt-2">
                  <BoldBiggerBlue>{financialGuardian.name}</BoldBiggerBlue>
                </div>
                <div className="row justify-content-center mt-3">
                  <DataBox>
                    <div className="col-12 text-center">
                      <BoldBigger>Dados do Responsável Financeiro</BoldBigger>
                    </div>
                    <div className="col-12">
                      <span className="info">Email: </span>
                      {financialGuardian.email || "Não possui e-mail"}
                    </div>
                    <div className="col-12">
                      <span className="info">Data de Nascimento: </span>
                      {financialGuardian.birth_date
                        ? formatDate(financialGuardian.birth_date)
                        : "Não possui data de nascimento"}
                    </div>
                    <div className="col-12">
                      <span className="info">RG: </span>
                      {financialGuardian.rg || "Não possui RG"}
                    </div>
                    <div className="col-12">
                      <span className="info">CPF: </span>
                      {formatCPF(financialGuardian.cpf) || "Não possui CPF"}
                    </div>
                    <div className="col-12">
                      <span className="info">Telefone: </span>
                      {financialGuardian.phone
                        ? formatPhone(financialGuardian.phone)
                        : "Não possui telefone"}
                    </div>
                    <div className="col-12 mt-3 text-center">
                      <BoldBigger>Endereço</BoldBigger>
                    </div>
                    <div className="col-12">
                      <span className="info">CEP: </span>
                      {financialGuardian.zip_code
                        ? formatCEP(financialGuardian.zip_code)
                        : "Não possui CEP"}
                    </div>
                    <div className="col-12">
                      <span className="info">Endereço: </span>
                      {financialGuardian.address}, nº {financialGuardian.number}
                    </div>
                    <div className="col-12">
                      <span className="info">Bairro: </span>
                      {financialGuardian.neighborhood || "Não possui bairro"}
                    </div>
                    <div className="col-12">
                      <span className="info">Cidade: </span>
                      {financialGuardian.city || "Não possui cidade"}{" "}
                    </div>
                    <div className="col-12">
                      <span className="info">UF: </span>
                      {financialGuardian && financialGuardian.uf
                        ? financialGuardian.uf
                        : "Não possui UF"}
                    </div>
                  </DataBox>
                </div>
              </div>
            </DataBigBox>
            <DataBigBox className="mt-5 mb-5">
              <div className="container">
                <div className="row justify-content-center">
                  <Avatar
                    alt="studentPicture"
                    src={Placeholder}
                    className={classes.avatar}
                  />
                </div>
                <div className="row justify-content-center mt-2">
                  <BoldBiggerBlue>{studentData.personal.name}</BoldBiggerBlue>
                </div>
                <div className="row justify-content-center mt-3">
                  <DataBox>
                    <div className="col-12 text-center">
                      <BoldBigger>Dados do Aluno</BoldBigger>
                    </div>
                    <div className="col-12">
                      <span className="info">Data de Nascimento: </span>
                      {studentData.personal.birth_date
                        ? formatDate(studentData.personal.birth_date)
                        : "Não possui data de nascimento"}
                    </div>
                    <div className="col-12">
                      <span className="info">RG: </span>
                      {studentData.personal.rg || "Não possui RG"}
                    </div>
                    <div className="col-12">
                      <span className="info">CPF: </span>
                      {studentData.personal.cpf
                        ? formatCPF(studentData.personal.cpf)
                        : "Não possui CPF"}
                    </div>
                    <div className="col-12">
                      <span className="info">Escola: </span>
                      {location.state.student.last_enrollment.unit_name ||
                        "Não possui dados da escola"}
                    </div>
                    {location.state.student.last_enrollment && (
                      <div className="col-12">
                        <span className="info">Ano: </span>
                        {location.state.student.last_enrollment.grade ||
                          "Sem ano"}
                        {location.state.student.last_enrollment.grade &&
                          "º ano do "}
                        {
                          location.state.student.last_enrollment
                            .educational_level_name
                        }
                      </div>
                    )}
                    <div className="col-12 mt-3 text-center">
                      <BoldBigger>Endereço</BoldBigger>
                    </div>
                    <div className="col-12">
                      <span className="info">CEP: </span>
                      {studentAddress
                        ? formatCEP(studentAddress.zip_code)
                        : "Não possui CEP"}
                    </div>
                    <div className="col-12">
                      <span className="info">Endereço: </span>
                      {studentAddress ? studentAddress.address : "Não possui"},
                      nº {studentAddress ? studentAddress.number : "Não possui"}
                    </div>
                    <div className="col-12">
                      <span className="info">Bairro: </span>
                      {studentAddress
                        ? studentAddress.neighborhood
                        : "Não possui bairro"}
                    </div>
                    <div className="col-12">
                      <span className="info">Cidade: </span>
                      {studentAddress
                        ? studentAddress.city
                        : "Não possui cidade"}{" "}
                    </div>
                    <div className="col-12">
                      <span className="info">UF: </span>
                      {studentAddress && studentAddress?.uf
                        ? studentAddress.uf
                        : "Não possui UF"}
                    </div>
                  </DataBox>
                </div>
              </div>
            </DataBigBox>
            <Button
              className="mb-5"
              type="button"
              onClick={() => {
                // isExpiratedPlan
                //   ? showTransitionPaymentPlans()
                //   :
                setOpenModal(true);
              }}
              disabled={submit}
            >
              {submit ? (
                <img height="25" src={loadingGif} alt="loading" />
              ) : (
                "Confirmar dados e gerar o contrato"
              )}
            </Button>
            <ModalGuardianData
              isOpen={openModal}
              setIsClose={setOpenModal}
              student={location.state.student}
              guardian={location.state.guardian}
            />
          </>
        )}
    </DataConfirmation>
  );
};
