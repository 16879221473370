// Style
import { PixStyled } from "./style";
// Libs
import Icon from "@mdi/react";
import { mdiContentCopy } from "@mdi/js";
// Components
import { toastDefault } from "components/Toast";
import { Text } from "components/atoms/Text";

export const Pix = () => {
  const messageCopied = () => {
    toastDefault("Link Copiado!");
  };

  return (
    <PixStyled>
      <Text gray fontWeight="400">
        Copie o código abaixo ou escaneie o QR Code para efeturar o pagamento
      </Text>
      <div
        className="paymentDiv w-100"
        onClick={() => {
          navigator.clipboard.writeText(
            `http://faturas.iugu.com/iugu_pix/24b3e18c-c3ef-4651-8686-5`
          );
          messageCopied();
        }}
      >
        <div className="paymentUrl">
          <Text gray fontWeight="400">
            http://faturas.iugu.com/iugu_pix/ 24b3e18c-c3ef- 4651-8686-5
          </Text>
        </div>
        <div className="paymentIcon">
          <Icon size={1} path={mdiContentCopy} />
        </div>
      </div>
      <div className="pixImage">
        <img
          src="https://play-lh.googleusercontent.com/ufwUy4SGVTqCs8fcp6Ajxfpae0bNImN1Rq2cXUjWI7jlmNMCsXgQE5C3yUEzBu5Gadkz"
          height={175}
          width={175}
          alt="pix-qrcode"
        />
      </div>
    </PixStyled>
  );
};
