import { pxToRem } from "helpers/style";
import styled, { css } from "styled-components";
import * as colors from "styles/colors";
import { device } from "styles/breakpoints";

interface ButtonInterface {
  outline?: boolean;
  width?: string;
  height?: string;
  padding?: string;
  margin?: boolean;
  red?: boolean;
}

export const ButtonStyled = styled.button<ButtonInterface>`
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  font-family: "Poppins";
  font-style: normal;
  padding: ${(props) => (props.padding ? props.padding : "0")};
  text-transform: uppercase;
  margin-top: ${(props) => (props.margin ? "1rem" : "0px")};
  margin-bottom: ${(props) => (props.margin ? "1rem" : "0px")};
  text-align: center;
  background-color: ${colors.NEW_PURPLE};
  border: none;
  color: #fff;
  justify-content: space-between;

  width: ${(props) => (props.width ? props.width : "100%")};
  height: ${(props) => (props.height ? props.height : "53px")};

  &:hover {
    cursor: pointer;
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.2)
      ),
      #6d28d9;
  }

  &:disabled {
    background-color: ${colors.GRAY_DISABLED};
    color: #a8a8a8;
    border: 1px solid ${colors.GRAY_DISABLED};
    cursor: not-allowed;
  }

  // outLine
  ${(props) =>
    props.outline &&
    `background-color: white;
      border: 1px solid ${colors.NEW_PURPLE};
      color: ${colors.NEW_PURPLE};
      &:hover {
        cursor: pointer;
        background: rgba(109, 40, 217, 0.05);
      }`}

  // red (esse cara vai morrer assim que o da lib estiver atualizado)
  ${(props) =>
    props.red &&
    `background-color: #B50303;
      color: #fff;
      cursor: pointer;
      &:hover {
        background-color: #C43535;
      }`}
`;

interface BorderlessButtonInterface {
  outline?: boolean;
  fontWeight?: string;
  fontSize?: string;
}

export const BorderlessButtonStyled = styled.button<BorderlessButtonInterface>`
  font-family: "Poppins";
  font-style: normal;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "700")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  line-height: 21px;
  text-align: center;
  text-align: right;
  text-decoration-line: underline;

  background-color: transparent;
  color: ${colors.NEW_PURPLE};

  border: none;
  width: 100%;
  height: 50px;
  padding-bottom: 5px;

  margin-left: auto;

  &:hover {
    cursor: pointer;
    color: ${colors.NEW_PURPLE_ONE};
  }
`;

interface BorderlessButtonIconInterface {
  outline?: boolean;
  fontWeight?: string;
  fontSize?: string;
  width?: string;
}

export const BorderlessButtonIconStyled = styled.button<BorderlessButtonIconInterface>`
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Poppins";
  font-style: normal;
  text-transform: uppercase;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "700")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  line-height: 21px;

  background-color: transparent;
  color: ${colors.NEW_PURPLE};

  border: none;
  width: ${(props) => (props.width ? props.width : "100%")};
  height: 50px;
  padding-bottom: 5px;

  &:hover {
    cursor: pointer;
    color: ${colors.NEW_PURPLE_ONE};
  }

  div.iconStyle {
    width: 1.3em;
    margin-right: 0.3em;
  }
`;
interface ButtonFilterBulletinProps {
  active: boolean;
  options?: string[];
  quarter?: boolean;
  note?: boolean;
}

const handleButtonQuarterColor = (isActive: boolean) => {
  if (isActive) {
    return css`
      background: ${colors.WHITE};
      color: ${colors.NEW_PURPLE};
    `;
  }
  return css`
    background: transparent;
    color: #999999;
  `;
};

const handleButtonBulletinColor = (isActive: boolean) => {
  if (isActive) {
    return css`
      background: ${colors.NEW_PURPLE};
      color: ${colors.WHITE};
    `;
  }
  return css`
    background: transparent;
    color: ${colors.BLACK};
  `;
};

export const ButtonFilterBulletin = styled.button<ButtonFilterBulletinProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: calc((100% / ${(props) => props.options?.length} - 1rem));
  appearance: none;
  border: none;
  border-radius: ${pxToRem(4)};

  font-size: ${pxToRem(12)};
  padding: ${pxToRem(8)};
  max-height: 29px;

  ${(props) =>
    props.quarter
      ? handleButtonQuarterColor(props.active)
      : handleButtonBulletinColor(props.active)}

  font-weight: 700;
  text-transform: uppercase;

  margin: 0 0.5rem;

  ${(props) =>
    props.note &&
    css`
      font-style: italic;
      font-weight: 400;
      padding: ${pxToRem(4)};
    `}
`;

export const ButtonFilterDashboard = styled.button<ButtonFilterBulletinProps>`
  width: calc((100% / 3 - 1rem));
  appearance: none;
  border: none;
  border-radius: ${pxToRem(4)};

  font-size: ${pxToRem(14)};
  padding: ${pxToRem(8)};

  ${(props) =>
    props.quarter
      ? handleButtonQuarterColor(props.active)
      : handleButtonBulletinColor(props.active)}

  font-weight: 700;
  text-transform: uppercase;

  margin: 0 0.5rem;
`;

export const ButtonModalStyled = styled.button`
  margin-bottom: 20px;
  width: 56px;
  height: 21px;
  background: none;
  border: none;
  margin-right: 20px;

  color: ${colors.NEW_PURPLE};

  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
`;

export const ButtonShare = styled.button`
  max-width: 306px;
  width: 100%;

  height: 36px;
  background: none;
  color: ${colors.NEW_PURPLE};

  text-transform: uppercase;

  border: 2px solid ${colors.NEW_PURPLE};
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ButtonIconFontNormalInterface {
  outline?: boolean;
  fontWeight?: string;
  fontSize?: string;
  width?: string;
  height?: string;
  uppercase?: boolean;
}

export const ButtonIconFontNormalStyled = styled.button<ButtonIconFontNormalInterface>`
  display: flex;
  /* justify-content: center; */
  align-items: center;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "700")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  line-height: 21px;
  background-color: transparent;
  color: ${colors.NEW_PURPLE};
  border: none;
  ${(props) => props.width && `width: ${props.width}`}
  height: ${(props) => (props.height ? props.height : "50px")};
  padding-bottom: 5px;
  // Uppercase
  ${(props) => props.uppercase && "text-transform: uppercase;"}
  div.icon {
    margin-right: 0.5em;
  }
  &:hover {
    cursor: pointer;
    color: ${colors.NEW_PURPLE_ONE};
  }
  &:disabled {
    margin-top: 0.8em;
    color: #a8a8a8;
    cursor: not-allowed;
  }
  div.iconStyle {
    width: 1.3em;
    margin-right: 0.3em;
  }
`;
