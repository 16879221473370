import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import CreateIcon from "@material-ui/icons/Create";
import {
  AssignClassResponse,
  AssignClassUsersResponse,
  getAssignClass,
  getAssignClassUsers,
} from "services/grades.service";
import { Container, InputField } from "../style";
import { LinearProgress } from "@material-ui/core";
import { LoadContainer } from "./style";

import { useHistory } from "react-router-dom";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import { ROLE } from "helpers/constants";
import { authUserAtom } from "jotai/authUser";
import { useAtomValue } from "jotai";

const useStyles = makeStyles((theme) => ({
  customHoverFocus: {
    color: "#00aeefff",
    "&:hover, &.Mui-focusVisible": {
      backgroundColor: "#c4c4c4",
      borderRadius: "50%",
    },
  },
}));

export const ConsultarAtribuicoes = () => {
  const authUser = useAtomValue(authUserAtom);
  const classes = useStyles();
  const [loadingPage, setLoadingPage] = useState(true);
  const [assignUsers, setAssignUsers] = useState<AssignClassUsersResponse[]>();
  const [assignUsersListed, setAssignUsersListed] =
    useState<AssignClassUsersResponse[]>();
  const [assignUsersClasses, setAssignUsersClasses] =
    useState<[AssignClassResponse]>();

  const history = useHistory();

  const navigateToEdit = (
    assignUser: AssignClassUsersResponse,
    userClasses?: AssignClassResponse[]
  ) => {
    history.push({
      pathname: "/gerenciar/turmas/editar",
      state: { assignUser, userClasses },
    });
  };

  const sortByName = (name: string) => {
    const result = assignUsers?.filter((o) =>
      o.full_name.toUpperCase().includes(name.toUpperCase())
    );
    if (result) {
      setAssignUsersListed(result);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const AssignClassUsersResponse = await getAssignClassUsers(
        authUser.grade_unit_id
      );
      if (AssignClassUsersResponse) {
        setAssignUsers(AssignClassUsersResponse.data);
        setAssignUsersListed(AssignClassUsersResponse.data);
      }
      const AssignClassResponse = await getAssignClass({
        unit: authUser.grade_unit_id,
      });
      if (AssignClassResponse) {
        setAssignUsersClasses(AssignClassResponse.data);
      }
      setLoadingPage(false);
    };
    fetchData();
  }, [authUser.grade_unit_id]);

  return loadingPage ? (
    <div className="mt-3">
      <div className="row">
        <LoadContainer className="container">
          <div className="title">Atribuição de Turmas</div>
          <LinearProgress className="mt-3 mb-3" color="secondary" />
        </LoadContainer>
      </div>
    </div>
  ) : (
    <Container className="container">
      {!loadingPage && assignUsers && assignUsersClasses && assignUsersListed && (
        <div className="mt-3">
          <div className="row mb-5">
            <div className="col-12 text-center title">Atribuição de Turmas</div>
          </div>
          <div className="row mb-5">
            <div className="col-4 ml-2">
              <strong>Pesquise pelo nome:</strong>
              <InputField
                placeholder="Nome do Educador"
                onChange={(e) => sortByName(e.target.value)}
              />
            </div>
          </div>

          {assignUsersListed.map((assignUser) => {
            const userClasses = assignUsersClasses.filter((assignUserClass) =>
              assignUser.role_id === ROLE.TEACHER
                ? assignUserClass.teacher === assignUser.id
                : assignUserClass.educator === assignUser.id
            );
            return (
              <div
                className="row user-card mt-1"
                key={`${assignUser.id}-${assignUser.role}`}
              >
                <div className="col-4 d-flex flex-column">
                  <span className="black-bold">{assignUser.full_name}</span>
                  <span className="user-role">{assignUser.role}</span>

                  {userClasses.length ? (
                    <Tooltip title="Editar Atribuição">
                      <span
                        className="cursor-add"
                        onClick={() => navigateToEdit(assignUser, userClasses)}
                      >
                        <CreateIcon
                          fontSize="large"
                          className={classes.customHoverFocus}
                        />
                        <span className="blue-bold ml-1">
                          Editar atribuição
                        </span>
                      </span>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Atribuir Turma">
                      <span
                        className="cursor-add"
                        onClick={() => navigateToEdit(assignUser, userClasses)}
                      >
                        <AddCircleOutlineOutlinedIcon
                          fontSize="large"
                          className={classes.customHoverFocus}
                        />
                        <span className="blue-bold ml-1">
                          Adicionar atribuição
                        </span>
                      </span>
                    </Tooltip>
                  )}
                </div>
                <div className="col-8">
                  <div className="row feature-border">
                    <div className="col-12">
                      <div className="vertical-divider d-flex flex-wrap">
                        {userClasses.length ? (
                          userClasses.map((userClass) => {
                            return (
                              <div
                                key={`${assignUser.id}-${userClass.id}-classes`}
                              >
                                <Tooltip
                                  title={`${userClass.subject_name} - ${userClass.grade}º 
                                    ${userClass.letter} do Ensino 
                                    ${userClass.educational_level_name}`}
                                  placement="top-start"
                                >
                                  <div className="rounded">
                                    <span className="pink-bold mr-1">
                                      {userClass.subject_short_name}
                                    </span>
                                    <span>
                                      {`${userClass.educational_level_name} - 
                                        ${userClass.grade}º 
                                        ${userClass.letter}`}
                                    </span>
                                  </div>
                                </Tooltip>
                              </div>
                            );
                          })
                        ) : (
                          <>
                            <div className="ml-1">Sem turmas atribuídas</div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </Container>
  );
};
