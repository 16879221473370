import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.space[2]};

  strong {
    width: fit-content;
    margin: 0;
    font-size: ${({ theme }) => theme.space[5]};
    font-weight: 600; //TODO: nao tem na lib
  }

  span {
    padding: ${({ theme }) => theme.space[1]} ${({ theme }) => theme.space[4]};
    background-color: ${({ theme }) => theme.colors.primaryLight90};
    border-radius: ${({ theme }) => theme.space[5]};
    font-size: ${({ theme }) => theme.fontSizes.web.h4};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }

  .dotted-border {
    flex: 1;
    border-bottom: 1px dashed ${({ theme }) => theme.colors.greyScale20};
  }
`;
