import styled from "styled-components";

export const StudentHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  img {
    width: 100%;
    max-width: ${({ theme }) => theme.space[12]};
    height: ${({ theme }) => theme.space[12]};
    border-radius: 50%;
  }

  div {
    strong {
      font-size: 16px;
    }

    p {
      margin: 0;
    }
  }
`;

export const StudentHeaderSecondary = styled.div`
  display: flex;
  align-items: center;

  background: rgba(109, 40, 217, 0.05);
  border-radius: 33px;

  padding: 1.5rem;

  img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
  }

  div {
    margin-left: 1rem;

    h3 {
      font-size: 32px;
      font-weight: ${({ theme }) => theme.fontWeights.bold};
      color: ${({ theme }) => theme.colors.brandingOrange};
    }

    p {
      margin: 0;
      font-size: 16px;
      font-weight: ${({ theme }) => theme.fontWeights.regular};
      line-height: ${({ theme }) => theme.lineHeights.taller};
    }
  }

  @media (max-width: 360px) {
    background: transparent;
  }
`;
