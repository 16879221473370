// Style
import { BankSlipStyled } from "./style";
// Components
import { toastDefault } from "components/Toast";
import { Text } from "components";
// Libs
import { mdiContentCopy, mdiDownload } from "@mdi/js";
import Icon from "@mdi/react";
import { BorderlessButtonIcon } from "components/atoms/Button";
// import { useLocation } from "react-router-dom";
// Interfaces
// import { GetCXUserJson } from "interfaces/constants";

// type BankSlipType = {
//   user: GetCXUserJson;
// };

export const BankSlip = () => {
  // const location = useLocation<BankSlipType>();

  const messageCopied = () => {
    toastDefault("Link Copiado!");
  };

  return (
    <BankSlipStyled>
      <Text gray fontWeight="400">
        Copie o código abaixo ou baixe o boleto para efeturar o pagamento
      </Text>
      <div
        className="paymentDiv"
        onClick={() => {
          navigator.clipboard.writeText(
            `http://faturas.iugu.com/iugu_pix/24b3e18c-c3ef-4651-8686-5`
          );
          messageCopied();
        }}
      >
        <Text gray fontWeight="400">
          http://faturas.iugu.com/iugu_pix/ 24b3e18c-c3ef-4651-8686-5
        </Text>
        <div>
          <Icon size={1} path={mdiContentCopy} />
        </div>
      </div>
      <BorderlessButtonIcon icon={mdiDownload}>
        Baixar Boleto
      </BorderlessButtonIcon>
    </BankSlipStyled>
  );
};
