// React
import React, { useContext, useState } from "react";
// Style
import * as Style from "./style";
// Components
import { toastError, toastWarning } from "components/Toast";
import { Button, CheckboxInput } from "components/atoms";
import { Text } from "components";
// Helpers
import { cellPhoneMask } from "helpers/helpers";
// Services
import { hubspotLead } from "services/hubspot.service";
// Img
import arrowDown from "static/img/arrowDown.svg";
// Libs
import { useForm } from "react-hook-form";
import HTTP_STATUS from "http-status-codes";
import { useTranslation } from "react-i18next";
// Interfaces
import { FormComponent, FormValues } from "../../interface";

export const IndicationForm = ({ units, modal }: FormComponent) => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.indication.components.indicationForm";

  const [eula, setEula] = useState(false);
  const [eulaText, setEulaText] = useState(false);
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, reset, watch } = useForm<FormValues>();

  const selectedUnit = (unitId: string) => {
    if (units) {
      return units.find(
        (unitSelected) => unitSelected.unit.id.toString() === unitId
      );
    }
  };

  const onSubmit = handleSubmit((data) => {
    (async () => {
      setLoading((prevState) => !prevState);
      const unitsGrade = await hubspotLead({
        body: {
          student_name: data.student_name,
          guardian_first_name: data.guardian_first_name,
          guardian_surname: data.guardian_surname,
          phone: data.phone,
          email: data.email,
          unit: parseInt(data.unit),
          grade: parseInt(data.grade && data.grade.slice(1)),
          educational_level: parseInt(data.grade && data.grade[0]),
          eula,
        },
      });
      if (unitsGrade && unitsGrade.status === HTTP_STATUS.CREATED) {
        modal();
        reset();
        setLoading((prevState) => !prevState);
      } else if (
        unitsGrade &&
        unitsGrade.status === HTTP_STATUS.BAD_REQUEST &&
        unitsGrade.data.email
      ) {
        toastWarning(t(`${TRANSLATE}.toastWarning`));
        setLoading((prevState) => !prevState);
      } else {
        toastError(t(`${TRANSLATE}.toastError`));
        setLoading((prevState) => !prevState);
      }
    })();
  });

  return (
    <Style.IndicationFormStyled>
      <Text fontWeight="400">
        <strong>{t(`${TRANSLATE}.textStart`)}</strong>
        {t(`${TRANSLATE}.textContinuation`)}
      </Text>
      <form onSubmit={onSubmit}>
        <div className="form">
          <div className="input">
            <label>{t(`${TRANSLATE}.labelStudentName`)}</label>
            <input type="text" {...register("student_name")} required />
          </div>
          <div className="input">
            <label>{t(`${TRANSLATE}.labelGuardianFirstName`)}</label>
            <input type="text" {...register("guardian_first_name")} required />
          </div>
        </div>
        <div className="form">
          <div className="input">
            <label>{t(`${TRANSLATE}.labelGuardianSurname`)}</label>
            <input type="text" {...register("guardian_surname")} required />
          </div>
          <div className="input">
            <label>{t(`${TRANSLATE}.labelPhone`)}</label>
            <input
              maxLength={15}
              minLength={11}
              {...register("phone")}
              required
              onChange={(e) => {
                const { value } = e.target;
                e.target.value = cellPhoneMask(value);
              }}
            />
          </div>
        </div>
        <div className="form">
          <div className="input">
            <label>{t(`${TRANSLATE}.labelEmail`)}</label>
            <input type="email" {...register("email")} required />
          </div>
          <div className="input">
            <label>{t(`${TRANSLATE}.labelUnit`)}</label>
            <select {...register("unit")}>
              <option value="">{t(`${TRANSLATE}.select`)}</option>
              {units.map((unit, index) => {
                return (
                  <option value={unit.unit.id} key={index}>
                    {unit.unit.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="form">
          <div className="input">
            <label>{t(`${TRANSLATE}.labelGrade`)}</label>
            <select {...register("grade")} disabled={!watch("unit")}>
              <option value="">{t(`${TRANSLATE}.select`)}</option>
              {watch("unit") &&
                selectedUnit(watch("unit"))?.unit.educational_level.map(
                  (educational_level, index) => {
                    return (
                      <React.Fragment key={index}>
                        {educational_level.grades.map((grade) => {
                          return (
                            <option
                              value={`${educational_level.id} ${grade.grade}`}
                              key={grade.grade}
                            >
                              {t(`${TRANSLATE}.educationalLevelGrade`, {
                                educationalLevel: educational_level.name,
                                grade: grade.grade,
                              })}
                            </option>
                          );
                        })}
                      </React.Fragment>
                    );
                  }
                )}
            </select>
          </div>
        </div>
        <div className="eula">
          <CheckboxInput
            value="eula_agreement"
            name="eula_agreement"
            onChange={() => setEula((prevState) => !prevState)}
          />
          <Text fontSize="400" fontWeight="14px" lineHeight="21px">
            {t(`${TRANSLATE}.eulaStartText`)}{" "}
            <strong>{t(`${TRANSLATE}.eulaMidText`)}</strong>
            {eulaText && t(`${TRANSLATE}.eulaHideText`)}
          </Text>
          <div
            className="arrow"
            onClick={() => setEulaText((prevState) => !prevState)}
          >
            <img height="4.38" src={arrowDown} alt="indicationLogo" />
          </div>
        </div>
        <div className="button">
          <Button width="116px" disabled={!eula || loading}>
            {t(`${TRANSLATE}.submitButton`)}
          </Button>
        </div>
      </form>
    </Style.IndicationFormStyled>
  );
};
