import { IArchiveProps } from "interfaces/documents";
import { KanataAPI } from "../services/api";
import { ErrorHandlerFunction } from "./service-helpers";

export interface UploadDocumentsResponse {
  id: number;
  enrollment: number;
  document_type: number;
  document_file: string;
  document_type_name: string;
  user_review: string;
  uploaded_at: string;
  status: number;
  status_display: string;
  kinship: number;
  kinship_display: string;
  reprove_text: string;
  review_at: string;
  contract_required_documents: boolean;
  enrollment_required_documents: boolean;
}

export const uploadDocuments = async ({
  enrollment,
  document_type,
  file_name,
  user_id,
  document_file,
  user_name,
}: {
  enrollment: number;
  document_type?: number;
  file_name: string;
  user_id: number;
  document_file?: string;
  user_name: string;
}) => {
  const response = await KanataAPI.post<UploadDocumentsResponse>(
    `mamon/enrollmentdocuments`,
    {
      enrollment,
      document_type,
      file_name,
      user_id,
      document_file,
      user_name,
    }
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const getHasDocuments = async ({
  enrollment_id,
}: {
  enrollment_id: number;
}) => {
  const response = await KanataAPI.get<Array<UploadDocumentsResponse>>(
    `mamon/enrollmentdocuments?enrollment=${enrollment_id}&document_type`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export interface GetDocumentsResponse {
  id: number;
  name: string;
  required_for_contract: boolean;
  required_for_enrollment: boolean;
  kinship: number;
}

export const getDocumentsTypes = async () => {
  const response = await KanataAPI.get<Array<GetDocumentsResponse>>(
    `mamon/documents`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const getGuardianDocuments = async ({
  guardianId,
}: {
  guardianId: number;
}) => {
  const response = await KanataAPI.get<Array<UploadDocumentsResponse>>(
    `mamon/enrollmentdocuments?guardian_id=${guardianId}`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export interface GetDocumentsReponse {
  count: number;
  next: string;
  previous: string;
  results: [
    {
      id: number;
      guardian: string;
      student: string;
      enrollment_year: number;
      unit_id: number;
      unit: string;
      cycle: string;
      status_name: string;
      has_pending_required_documents: boolean;
      enrollment_documents: [
        {
          document_id: number;
          document_type_name: string;
          status: number;
          status_display: string;
        }
      ];
    }
  ];
}

export const getdocumentsList = async (
  enrollmentArray: Array<number>,
  documentsArray: Array<number>,
  reenrollment?: boolean,
  unit?: number,
  paginacao?: string
) => {
  let url = "";
  if (paginacao) {
    url = paginacao;
  } else {
    url = `/mamon/documents_filter/?`;
    if (enrollmentArray.length) {
      url += `status=[${enrollmentArray.join(",")}]`;
    }
    if (documentsArray.length) {
      url += `&documents_status=[${documentsArray.join(",")}]`;
    }
    if (reenrollment) {
      url += `&re_enrollment=true`;
    }
    if (unit) {
      url += `&unit_id=${unit}`;
    }
  }
  const response = await KanataAPI.get<GetDocumentsReponse>(url).catch(
    ErrorHandlerFunction
  );
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const sendPendingDocumentsNotifications = async (payload: {
  enrollment_id: number;
}) => {
  const response = await KanataAPI.post<Array<UploadDocumentsResponse>>(
    `mamon/send_pending_documents_notifications`,
    payload
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const getUserDocuments = async (
  studentId: number,
  year?: number | null
) => {
  const response = await KanataAPI.get(
    `cx/documentos_estudante_responsavel/${studentId}/${
      year ? `?ano=${year}` : ""
    }`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const createStudentDocument = async (props: any) => {
  const response = await KanataAPI.post(
    `cx/documentos_estudante_responsavel/`,
    props
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

interface IHandleUploadFiles {
  documento_id: number;
  body: {
    tipo: string;
    prefixo: string;
    arquivos: Omit<IArchiveProps, "id">[];
    status: string;
    id: number;
  };
}

export const updateStudentDocument = async ({
  documento_id,
  body,
}: IHandleUploadFiles) => {
  const response = await KanataAPI.patch(
    `cx/documentos_estudante_responsavel/${documento_id}/`,
    body
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const updateStudentDocumentStatus = async (
  documentoId: number,
  status: string,
  motivo_reprovacao?: string
) => {
  const response = await KanataAPI.patch(
    `cx/documentos_estudante_responsavel/${documentoId}/`,
    {
      status,
      motivo_reprovacao,
    }
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};

export const deleteStudentDocument = async (fileId: number) => {
  const response = await KanataAPI.delete(
    `cx/documentos_estudante_responsavel/${fileId}`
  ).catch(ErrorHandlerFunction);
  if (response) {
    const data = { data: response.data, status: response.status };
    return data;
  }
};
