import * as Style from "./StudentSelection.style";
import { useHistory, useLocation } from "react-router-dom";
import {
  BulletinLocationProps,
  GuardianReturn,
} from "components/organisms/Bulletin/GuardianSchoolReport.types";
import { useTranslation } from "react-i18next";

import { Title } from "components/atoms";
import { useBulletin } from "context/Bulletin";

const prefix = "pages.AP.StudentSelection";

export const StudentSelection = () => {
  const location = useLocation<BulletinLocationProps<GuardianReturn>>();
  const { t } = useTranslation();
  const history = useHistory();

  const { handleDataBulletin, quarters, handleStudentId, setQuarter, quarter } =
    useBulletin();

  const handleBulletin = async (student: any) => {
    const res = await handleStudentId(student.id);

    if (quarters) {
      handleDataBulletin({
        id: res.student.id,
        quarter: quarters[0].id,
        // eslint-disable-next-line no-sequences
      }),
        setQuarter({
          values: [...quarter.values],
          selected: quarters[0].name,
        }),
        history.push({
          pathname: `/responsavel/boletim`,
          state: {
            student,
          },
        });
    }
  };

  const studentCard =
    location &&
    location.state.student.students.map(
      (item, index) =>
        item &&
        item.enrollment && (
          <Style.Card onClick={() => handleBulletin(item)} key={item.user_id}>
            {item.photo_url && (
              <img src={`${item.photo_url}`} alt={item.name} />
            )}
            <Style.CardContent>
              <Style.TitleCard>{item.name}</Style.TitleCard>
              <p>
                {item.enrollment?.educational_level_name} -{" "}
                {item.enrollment?.grade} {item.enrollment?.section}
              </p>
            </Style.CardContent>
          </Style.Card>
        )
    );

  return (
    <Style.Container>
      <Style.Wrapper>
        <Style.Header>
          <Title
            width="100%"
            fontSize="36px"
            fontWeight="700"
            left
            marginBottom="0"
            pointer
          >
            {t(`${prefix}.title`)}
          </Title>
          <Title
            width="100%"
            fontSize="32px"
            fontWeight="700"
            left
            marginBottom="0"
            pointer
            orange
          >
            {t(`${prefix}.subtitle`)}
          </Title>
        </Style.Header>
        <Style.Content>{studentCard}</Style.Content>
      </Style.Wrapper>
    </Style.Container>
  );
};
