import React from "react";

interface SVGProps {
  width?: string;
  height?: string;
  viewBox?: string;
  fill?: string;
  fillList?: Array<string>;
  className?: string;
}

export default function CheckIcon({
  className,
  fill,
  fillList,
  width = "24",
  height = "24",
  viewBox = "0 0 48 48",
}: SVGProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      fill={fill}
      height={height}
      width={width}
      viewBox={viewBox}
    >
      <path d="M18.9 35.7 7.7 24.5l2.15-2.15 9.05 9.05 19.2-19.2 2.15 2.15Z" />
    </svg>
  );
}
