import { IDatePlan } from "../interface";
import { ContainerAlert, CardAlert } from "./style";

export const AlertPaymentPlanExpirationMessage = () => {
  return (
    <ContainerAlert>
      <CardAlert>
        <strong>Plano expirado</strong> <br />
        Enquanto essa matrícula estava em andamento, o plano de pagamento,
        anteriormente disponível, expirou. Selecione um novo plano para
        prosseguir com a assinatura do contrato.
      </CardAlert>
    </ContainerAlert>
  );
};

export const AlertPaymentPlanDetails = () => {
  return (
    <CardAlert>
      O valor deverá ser pago via boleto(s) que será(ão) enviado(s) para o seu
      e-mail.
    </CardAlert>
  );
};

export const AlertPaymentPlanDateToExpire = ({ date }: IDatePlan) => {
  return (
    <CardAlert>
      O tempo limite para assinatura do contrato deste plano é até o dia {date}.
      Após essa data, o plano sofrerá alterações.
    </CardAlert>
  );
};
