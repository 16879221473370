// React
import { useCallback } from "react";
// Styles
import * as S from "./style";
// Icons
import leftArrow from "static/img/left_arrow.svg";
import rightArrow from "static/img/right_arrow.svg";
// Interfaces
import { IPaginationByIndex } from "./interface";

export const PaginationByIndex = <T extends unknown>({
  results,
  name,
  index,
  setIndex,
}: IPaginationByIndex<T>) => {
  const pagination = useCallback(
    (offset?: string) => {
      if (offset === "backwards" && index !== 0) {
        setIndex(index - 1);
      } else if (index !== results.length - 1) {
        setIndex(index + 1);
      }
    },
    [index, results, setIndex]
  );

  return (
    <S.HeaderPagination>
      <button
        type="button"
        onClick={() => {
          pagination("backwards");
        }}
        disabled={index === 0}
      >
        <S.Img src={leftArrow} alt="left-arrow" enabled={index !== 0} />
      </button>
      <span>{name}</span>
      <button
        type="button"
        onClick={() => {
          pagination();
        }}
        disabled={index === results.length - 1}
      >
        <S.Img
          src={rightArrow}
          alt="right-arrow"
          enabled={index !== results.length - 1}
        />
      </button>
    </S.HeaderPagination>
  );
};
