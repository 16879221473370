import { useEffect, useState } from "react";
import { getReleaseTypes, ReleaseTypesResponse } from "services/grades.service";
import HTTP_STATUS from "http-status-codes";
import { Button } from "components";
import { Container, LoadContainer } from "../style";
import { useHistory } from "react-router-dom";
import { LinearProgress } from "@material-ui/core";
import { useAtomValue } from "jotai";
import { authUserAtom } from "jotai/authUser";

export const SelecaoLancamento = () => {
  const authUser = useAtomValue(authUserAtom);
  const [releaseTypes, setReleaseTypes] = useState<ReleaseTypesResponse>();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const moveToRelease = (evaluation_type: {
    id: number;
    name: string;
    substitutive: boolean;
  }) => {
    history.push({
      pathname: "/lancamento/atribuir",
      state: { evaluation_type },
    });
  };

  const fetchPageData = async () => {
    setLoading(true);
    const responseReleaseTypes = await getReleaseTypes(authUser.id);
    if (
      responseReleaseTypes &&
      responseReleaseTypes.status === HTTP_STATUS.OK
    ) {
      setReleaseTypes(responseReleaseTypes.data);
    } else {
      setError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPageData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <LoadContainer className="container">
      <div className="title">Lançamentos</div>
      <LinearProgress className="mt-3 mb-3" color="secondary" />
    </LoadContainer>
  ) : (
    <>
      {error && (
        <div className="container">
          Ocorreu um erro ao carregar os dados necessários para a página.
        </div>
      )}
      {releaseTypes && !error && (
        <Container className="container">
          <div className="row">
            <div className="col-12">
              <span className="blue-bold title">Lançamentos</span>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12">
              <span className="pink-bold">Selecione o lançamento</span>
            </div>
          </div>
          <div className="row">
            {releaseTypes.evaluations_type.map((evaluation_type) => {
              return (
                <div className="col-6" key={evaluation_type.id}>
                  <Button
                    className="blue"
                    onClick={() => {
                      moveToRelease(evaluation_type);
                    }}
                  >
                    {evaluation_type.name}
                  </Button>
                </div>
              );
            })}
          </div>
        </Container>
      )}
    </>
  );
};
