import styled from "styled-components";

export const Contract = styled.div`
  div.enrollment-title {
    font-size: 20px;
    font-weight: bold;
    color: #ed008c;

    div.subtitle {
      font-weight: 500;
      font-size: 16px;
      color: black;
    }
  }
  div.subdiv {
    display: flex;
  }
`;
