import styled, { css } from "styled-components";
import { IH4 } from "./interface";

export const ConsultFoodContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 5px 15px; //nao tem na lib

  h2.title {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: ${({ theme }) => theme.fontSizes.web.h2};
    line-height: 48px; //nao tem na lib
    color: ${({ theme }) => theme.colors.primaryDefault};
  }

  button.search {
    width: 111px;
    height: 37px; // nao tem na lib

    margin-left: auto;
    margin-top: ${({ theme }) => theme.space[6]};
  }
`;

export const H4 = styled.h4<IH4>`
  margin-top: ${({ theme }) => theme.space[4]};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  line-height: 30px; //nao tem na lib
  font-size: ${({ theme }) => theme.fontSizes.web.h4};

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: ${({ theme }) => theme.space[5]};
    `}

  ${({ textStyle }) =>
    textStyle === "primary" &&
    css`
      color: ${({ theme }) => theme.colors.greyScale95};
    `}

  ${({ textStyle }) =>
    textStyle === "notFound" &&
    css`
      color: ${({ theme }) => theme.colors.greyScale40};
      text-align: center;
      margin-top: ${({ theme }) => theme.space[16]};
    `}
`;

export const TR = styled.tr`
  cursor: pointer;
`;

export const FormLine = styled.div`
  display: flex;
  align-items: center;

  gap: 0 ${({ theme }) => theme.space[4]};
  margin-top: ${({ theme }) => theme.space[4]};
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 308px;

  label {
    float: left;
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    font-size: ${({ theme }) => theme.fontSizes.web.sm};
    line-height: ${({ theme }) => theme.lineHeights.normal};
    color: ${({ theme }) => theme.colors.greyScale95};

    margin: 0;
  }

  select {
    box-sizing: border-box;
    background: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.greyScale40};

    color: ${({ theme }) => theme.colors.greyScale95};
    height: 37.7px; //nao tem na lib
    padding-left: ${({ theme }) => theme.space[2]};
    font-size: ${({ theme }) => theme.fontSizes.web.sm};

    cursor: pointer;
  }
`;

export const LoadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h4 {
    ${({ theme }) => theme.fontSizes.web.h4}
    text-align: center;
  }

  div {
    margin: ${({ theme }) => theme.space[4]};
  }
`;
