import styled from "styled-components";
import * as Interface from "./icons.type";

export const IconSvg = styled.img.attrs<Interface.IconSvgStyleProps>(
  (props) => ({
    style: {
      display: "block",
      width: `${props.width}${props.unity}`,
      height: `${props.width}${props.unity}`,
      backgroundImage: `${props.onLoad ? (props.src ? props.src : "") : ""}`,
      backgrounRepeat: "no-repeat",
      backgroundPosition: "center center",
      padding: `${props.padding ? "1rem" : "0"}`,
    },
  })
)<Interface.IconSvgStyleProps>``;
