import styled from "styled-components";

export const ModalStyled = styled.div`
  position: fixed;
  background-color: #ffffff;

  max-height: 100vh;
  width: 343px;
  height: 206px;

  border-radius: 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${({ theme }) => theme.space[6]} ${({ theme }) => theme.space[4]};

  text-align: center;

  img {
    margin-top: ${({ theme }) => theme.space[6]};
  }

  h1 {
    font-size: 24px;
    font-weight: 700;
    line-height: ${({ theme }) => theme.lineHeights.modalTitles};

    margin-top: 7px;
  }

  div {
    display: flex;
    justify-content: space-between;

    padding: 0 ${({ theme }) => theme.space[6]};
    margin-bottom: ${({ theme }) => theme.space[8]};

    height: 21px;
  }
`;
