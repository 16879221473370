import styled from "styled-components";
import * as colors from "styles/colors";

export const Step3Styled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  div.step3Container {
    padding: 1em;

    div.radioOption {
      display: flex;

      p.radioText {
        margin-top: 0.9em;
        margin-left: 0.5em;
      }
    }

    div.buttons {
      margin-top: 9.6em;
      display: flex;
      justify-content: flex-end;
    }
  }

  div.savePackage {
    border-radius: 5px;
    background-color: ${colors.LIGHT_GRAY};

    font-size: 12px;
    height: 2em;
  }

  div.radioText {
    margin-top: 9px;
    margin-left: 4px;
  }
`;
