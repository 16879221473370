// style
import { Table } from "./style";
// libs
import { useTranslation } from "react-i18next";
// interfaces
import { TableInterface } from "./interface";
// helpers
import { TableHelpers } from "./helper";
// components
import { FloatingMenu } from "components";
// react
import { useContext } from "react";
// Helpers
import {
  isEnrollmentCoordinator,
  isEnrollmentManager,
  isOperationalDirector,
  isSystemAdmin,
  isVendorManager,
} from "helpers/constants";
import { Storage } from "Storage";

export enum EWaitingListOptions {
  assignVacancy = 1,
  removeFromList = 2,
}

export const WaitingListTable = ({
  studentList,
  withVacancy,
  setNewSearch,
}: TableInterface) => {
  const { t } = useTranslation();
  const TRANSLATE = "pages.EDF.waitingList.components.table.waitingListTable";
  const role = Storage.props.role.get();
  const { changeLevelName, handleAssign, handleRemove } = TableHelpers({
    setNewSearch,
  });

  const options = [
    { label: "Atribuir vaga", value: 1 },
    { label: "Remover da lista", value: 2 },
  ];

  return (
    <Table>
      <thead>
        <tr className="firstRow">
          <th className="firstColum">{t(`${TRANSLATE}.titleStudent`)}</th>
          <th>{t(`${TRANSLATE}.titleUnit`)}</th>
          <th>{t(`${TRANSLATE}.titleEducationalLevel`)}</th>
          <th>{t(`${TRANSLATE}.titleGrade`)}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {studentList &&
          studentList.results.map((data, index) => (
            <tr key={index} className="body">
              <td>{data.relacao_responsavel_estudante.student.nome}</td>
              <td>
                <p>{data.unidade.name}</p>
              </td>
              <td>
                <p>{changeLevelName(data.ciclo.name)}</p>
              </td>
              <td>
                <p>{t(`${TRANSLATE}.tableGrade`, { grade: data.segmento })}</p>
              </td>
              {(isOperationalDirector(role) ||
                isEnrollmentManager(role) ||
                isVendorManager(role) ||
                isEnrollmentCoordinator(role) ||
                isSystemAdmin(role)) && (
                <td>
                  <FloatingMenu
                    open
                    options={
                      withVacancy
                        ? options
                        : [{ label: "Remover da lista", value: 2 }]
                    }
                    left="83%"
                    top="-5px"
                    onChangeOption={(e) =>
                      e === EWaitingListOptions.assignVacancy
                        ? handleAssign(data)
                        : handleRemove(data)
                    }
                  />
                </td>
              )}
            </tr>
          ))}
      </tbody>
    </Table>
  );
};
