import { useEffect, useState } from "react";

import * as Style from "./GuardianSchoolReport.style";

import { useHistory, useLocation } from "react-router-dom";

import { Title, SelectBulletin, Warning, Loading } from "components/atoms";
import { GuardianSchoolReportFinal } from "./GuardianSchoolReportFinal";
import { mdiInformationOutline } from "@mdi/js";
import { ModalLegend } from "pages/AP/Boletim/components/ModalLegend";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";

import {
  BulletinLocationProps,
  GuardianReturn,
  StudentReturn,
} from "./GuardianSchoolReport.types";
import { useSchoolReport } from "./GuardianSchoolReport.helper";

const dataAtual = new Date();
const anoAtual = dataAtual.getFullYear();

const prefix = "pages.AP.boletim";

enum Roles {
  Guardian = "Guardian",
  Student = "Student",
}

export const GuardianSchoolReport = () => {
  const [modalLegend, setModalLegend] = useState<boolean>(false);
  const [yearSelected, setYearSelected] = useState(`${anoAtual}`);
  const { getSchoolReportFinal, schoolReportFinal, loaded } = useSchoolReport();
  const history = useHistory();
  const { t } = useTranslation();

  const location = useLocation<BulletinLocationProps<GuardianReturn>>();
  const studentLocation = useLocation<BulletinLocationProps<StudentReturn>>();
  const role = location.state.role.label;

  const indice = location.state.indice ? location.state.indice : 0;

  const Student =
    role === Roles.Student &&
    studentLocation.state.student.enrollment[indice].student_id;

  const Guardian =
    role === Roles.Guardian &&
    location.state.student.students[indice].enrollment.student_id;

  const years = Guardian
    ? location.state.student.students[indice].enrollment_years.map(
        (item: number) => `${item}`
      )
    : studentLocation &&
      studentLocation.state.student.enrollment_years.map(
        (item: number) => `${item}`
      );

  const studentId = role === Roles.Guardian ? Guardian : Student;

  const photoStudent =
    role === Roles.Guardian
      ? location.state.student.students[indice].photo_url
      : studentLocation && studentLocation.state.student.personal.photo_url;

  const name =
    role === Roles.Guardian
      ? location.state.student.students[indice].name
      : studentLocation.state.student.personal.name;

  useEffect(() => {
    getSchoolReportFinal({
      year: yearSelected,
      kanata_id: Number(studentId),
    });
  }, [getSchoolReportFinal, studentId, yearSelected]);

  return (
    <Style.Container>
      <Style.Wrapper>
        <Style.Header>
          <Style.Section
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <img
              src="/icons/arrow-left.svg"
              alt=""
              onClick={() => history.goBack()}
              style={{ marginRight: "0.5rem", cursor: "pointer" }}
            />
            <Title
              width="100%"
              fontSize="36px"
              fontWeight="700"
              left
              marginBottom="0"
              pointer
            >
              {t(`${prefix}.title`)}
            </Title>
          </Style.Section>
          <SelectBulletin
            maxWidth="146px"
            defaultValue={yearSelected}
            placeholder="Ano"
            options={years}
            onAction={(e) => setYearSelected(`${e}`)}
          />
        </Style.Header>
        <Style.Section
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          {photoStudent && <Style.StudentPhoto src={photoStudent} />}
          <Style.Section
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Style.ItemSection>
              <Title
                width="100%"
                darkGray
                fontSize="20px"
                fontWeight="700"
                left
                marginBottom="0"
                orange
                responsive
              >
                {name}
              </Title>
            </Style.ItemSection>
          </Style.Section>
        </Style.Section>
        {loaded === false ? (
          <Loading />
        ) : schoolReportFinal ? (
          <>
            {yearSelected !== "2023" ? (
              <>
                <GuardianSchoolReportFinal
                  year={yearSelected}
                  tbody={schoolReportFinal}
                />
                <Style.Legend onClick={() => setModalLegend(true)}>
                  <Icon path={mdiInformationOutline} size={1} />
                  {t(`${prefix}.subtitle`)}
                </Style.Legend>
                {modalLegend && <ModalLegend setModal={setModalLegend} />}
              </>
            ) : (
              <>
                <Warning
                  message={t(`${prefix}.error`, { year: anoAtual })}
                  type="Bulettin"
                />
              </>
            )}
          </>
        ) : (
          <Warning
            message={t(`${prefix}.error`, { year: anoAtual })}
            type="Bulettin"
          />
        )}
      </Style.Wrapper>
    </Style.Container>
  );
};
