import styled, { css } from "styled-components";
import { IInputTextStyle } from "./interface";

export const InputTextContainer = styled.div<IInputTextStyle>`
  width: ${({ width }) => `${width}px`};

  display: flex;
  flex-direction: column;

  label {
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    font-size: ${({ theme }) => theme.fontSizes.web.sm};
    line-height: ${({ theme }) => theme.lineHeights.normal};
    text-transform: uppercase;
  }

  div {
    background-color: ${({ theme }) => theme.colors.white};
    padding: ${({ theme }) => theme.space[2]};
    height: 37px; // TODO: nao tem na lib
    border: 2px solid ${({ theme }) => theme.colors.greyScale40};

    display: flex;
    align-items: center;
    input {
      width: 100%;
      border: none;
      background-color: transparent;

      &::placeholder {
        color: ${({ theme }) => theme.colors.greyScale40};
        font-size: ${({ theme }) => theme.fontSizes.web.sm};
      }

      outline: none;
    }
  }

  span {
    font-size: ${({ theme }) => theme.fontSizes.web.sm};
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    line-height: normal;
    color: ${({ theme }) => theme.colors.statusErrorDefault};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${({ theme }) => theme.colors.greyScale40};
      div {
        background-color: ${({ theme }) => theme.colors.greyScale10};
        border-color: ${({ theme }) => theme.colors.greyScale10};

        input {
          color: ${({ theme }) => theme.colors.greyScale40};
        }
      }
    `}

  @media (max-width: 360px) {
    width: 100%;
  }
`;
