import { Tab } from "../Tab";
import { TabsDiv } from "./style";
import IconInfo from "static/img/icon_informacoes";
import IconClock from "static/img/icon_clock";
import IconStudents from "static/img/icon_students";
import IconDocumentation from "static/img/icon_documentation";

type TabsType = {
  activeTab: string;
};

export const Tabs = ({ activeTab }: TabsType) => {
  return (
    <TabsDiv>
      <Tab
        title="Informações"
        href="/usuarios/meu-perfil"
        isActive={activeTab === "information"}
      >
        <IconInfo isActive={activeTab === "information"} />
      </Tab>
      <Tab
        title="Saída do aluno"
        href="/usuarios/servicos"
        isActive={activeTab === "studentExitTime"}
      >
        <IconClock isActive={activeTab === "studentExitTime"} />
      </Tab>
      <Tab
        title="Alunos"
        href="/usuarios/alunos"
        isActive={activeTab === "students"}
      >
        <IconStudents isActive={activeTab === "students"} />
      </Tab>
      <Tab
        title="Documentação"
        href="/usuarios/documentacao"
        isActive={activeTab === "documentation"}
      >
        <IconDocumentation isActive={activeTab === "documentation"} />
      </Tab>
    </TabsDiv>
  );
};
