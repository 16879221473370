import styled from "styled-components";

export const ChooseStudentStyled = styled.div`
  h1 {
    font-size: 36px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    color: ${({ theme }) => theme.colors.primaryDefault};
  }

  h3 {
    margin-top: 1rem;
    font-size: 32px;
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    color: ${({ theme }) => theme.colors.brandingOrange};
  }

  div.studentBox {
    display: flex;
    padding: 1rem;

    width: 391px;
    height: 81px;
    margin-top: 1rem;

    border: 2px solid ${({ theme }) => theme.colors.primaryDefault};

    cursor: pointer;
  }
`;
