import * as Interface from "./PainelClassesManagement.type";
import * as Style from "./PainelClassesManagement.style";
import * as Helper from "./PainelClassesManagement.helper";
import { useTranslation } from "react-i18next";

import { TableRow, TableColumn, Loading } from "components/atoms";
import { TableBulletin } from "components/molecules";
import { useEffect } from "react";

const prefix = "components.organisms.PainelClassesManagement";

export const PainelClassesManagement = ({
  data,
}: Interface.PainelClassesManagementProps) => {
  const { t } = useTranslation();

  const info = data !== undefined && data.name.split(" - ");
  const total = data !== undefined && data.total_estudantes.total;
  const sections = data !== undefined && data.opcoes_secao;

  const infoThead = sections && ["Turma", ...sections];
  const infoBody = data !== undefined && Helper.infoPainel(data);
  const enturmados = infoBody && infoBody[0].total;
  const semTurma = infoBody && infoBody[4].total;

  return (
    <Style.Container>
      <Style.Content variant="outline">
        <Style.TitleCard>
          {t(`${prefix}.titleCard`)} - {info && info[1]}
        </Style.TitleCard>
        <Style.TitleClass>
          <Style.SpanCard variant="enturmados">{enturmados}</Style.SpanCard>
          {t(`${prefix}.enturmados`)}
        </Style.TitleClass>
        <Style.TitleClass>
          <Style.SpanCard variant="semturma">
            {semTurma - enturmados}
          </Style.SpanCard>
          {t(`${prefix}.semturma`)}
        </Style.TitleClass>
        <Style.TitleClass>
          <Style.SpanCard variant="total">{total}</Style.SpanCard>
          {t(`${prefix}.total`)}
        </Style.TitleClass>
      </Style.Content>
      <Style.Content variant="solid">
        <TableBulletin
          border
          theadChildren={
            <>
              {infoThead &&
                infoThead.map(
                  (item, index) =>
                    item && (
                      <TableRow
                        key={item}
                        item={item}
                        visible={{ mobile: true, desktop: true }}
                      />
                    )
                )}
            </>
          }
          tbodyChildren={
            <>
              {infoBody &&
                infoBody.map(
                  (item, index) =>
                    item.title && (
                      <tr key={item.title}>
                        <TableColumn
                          border={false}
                          item={item.title}
                          visible={{ mobile: true, desktop: true }}
                        />
                        {sections &&
                          sections.map((section) => (
                            <>
                              <TableColumn
                                border={false}
                                item={item[section]}
                                visible={{ mobile: true, desktop: true }}
                              />
                            </>
                          ))}
                      </tr>
                    )
                )}
            </>
          }
        />
      </Style.Content>
    </Style.Container>
  );
};
