import styled from "styled-components";

export const GuardianSignupContainer = styled.div`
  padding: ${({ theme }) => theme.space[12]} 97px; // TODO: nao tem na lib

  h1 {
    font-size: 40px; // TODO: nao tem na lib
    font-weight: 600; // TODO: nao tem na lib
    line-height: normal;
    color: ${({ theme }) => theme.colors.primaryDefault};
  }

  h3 {
    margin-top: 21px; // TODO: nao tem na lib

    font-size: ${({ theme }) => theme.fontSizes.web.h3};
    font-weight: 600; // TODO: nao tem na lib
    line-height: normal;
    color: ${({ theme }) => theme.colors.brandingOrange};
  }

  .inLine {
    display: flex;
    align-items: center;

    margin-top: ${({ theme }) => theme.space[4]};
    gap: 0 ${({ theme }) => theme.space[4]};
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: ${({ theme }) => theme.space[12]};

    gap: 0 ${({ theme }) => theme.space[4]};
  }
`;
