// Libs
import { useTranslation } from "react-i18next";
// Styles
import * as S from "./style";
// Components
import { Icons } from "components";
// Helpers
import { formatDate, formatMoneyInput } from "helpers/helpers";
// Icons
import calendarIcon from "static/img/calendar.svg";
// Interface
import { IDynamicDetails } from "./interface";

export const DynamicDetails = ({
  translate,
  form,
  dynamics,
}: IDynamicDetails) => {
  const { t } = useTranslation();

  return (
    <S.DynamicDetailsContainer>
      <S.OldDynamics>
        <div className="dynamicName">
          <label>{t(`${translate}.dynamicName`)}</label>
          <span>{form.name}</span>
        </div>
      </S.OldDynamics>

      <div className="sameLine">
        <S.OldDynamics>
          <div>
            <label>{t(`${translate}.startDate`)}</label>
            <span>
              <Icons icon={calendarIcon} title="calendar" />{" "}
              {formatDate(form.startDate)}
            </span>
          </div>
          <div>
            <label>{t(`${translate}.finalDate`)}</label>
            <span>
              <Icons icon={calendarIcon} title="calendar" />{" "}
              {formatDate(form.finalDate)}
            </span>
          </div>
        </S.OldDynamics>
      </div>

      <div>
        {dynamics.map((dynamic, index) => {
          return (
            <div className="sameLine" key={index}>
              <S.OldDynamics>
                <div>
                  <label>{t(`${translate}.amount`)}</label>
                  <span>{dynamics[index].quantidade_minima}</span>
                </div>
                <div>
                  <label>{t(`${translate}.value`)}</label>
                  <span>
                    {formatMoneyInput(dynamics[index].valor_unitario)}
                  </span>
                </div>
              </S.OldDynamics>
            </div>
          );
        })}
      </div>
    </S.DynamicDetailsContainer>
  );
};
