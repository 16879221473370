import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "@escolamais-ui-v2/react";
import { ILocationProps } from "./types";
import { Loading } from "components";
import { DocumentCard } from "./components/DocumentCard";
import { useStudentDocuments } from "./useStudentDocuments";
import * as S from "./styles";

export const StudentDocuments = () => {
  const {
    state: { student, guardian },
  } = useLocation<ILocationProps>();
  const { t } = useTranslation();
  const i18n_prefix = "pages.EDF.studentDocuments";
  const history = useHistory();

  const { studentDocuments, setStudentDocuments, isLoadingDocuments } =
    useStudentDocuments();

  if (isLoadingDocuments) {
    return <Loading />;
  }

  return (
    <S.Container>
      <S.DocumentsSection>
        <S.Subtitle>{t(`${i18n_prefix}.financialGuard`)}</S.Subtitle>
        <S.Description>{t(`${i18n_prefix}.filesDescription`)}</S.Description>

        <S.DocumentsCards>
          {studentDocuments?.documentos_responsavel.map((document) => (
            <DocumentCard
              documento={document}
              prefixo="responsavel"
              userId={guardian.id}
              setUpdateDocuments={setStudentDocuments}
            />
          ))}
        </S.DocumentsCards>
      </S.DocumentsSection>

      <S.DocumentsSection>
        <S.Subtitle>{t(`${i18n_prefix}.studentDocuments`)}</S.Subtitle>
        <S.Description>{t(`${i18n_prefix}.filesDescription`)}</S.Description>
        <S.DocumentsCards>
          {studentDocuments?.documentos_estudante.map((document) => (
            <DocumentCard
              documento={document}
              prefixo="estudante"
              userId={student.id}
              setUpdateDocuments={setStudentDocuments}
            />
          ))}
        </S.DocumentsCards>
      </S.DocumentsSection>

      <S.ButtonsGroup>
        <S.ButtonsContainer>
          <Button
            buttonText={t(`${i18n_prefix}.returnButton`)}
            appearance="secondary"
            onClick={() => {
              history.push({
                pathname: "/home",
              });
            }}
          />
        </S.ButtonsContainer>
      </S.ButtonsGroup>
    </S.Container>
  );
};
