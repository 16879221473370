import { LinearProgress } from "@material-ui/core";
import { Button, RadioButton } from "components";
import HTTP_STATUS from "http-status-codes";
import { GetCXUserJson, StudentInterface } from "interfaces/constants";
import { useContext, useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useHistory, useLocation } from "react-router-dom";
import {
  getPaymentPlan,
  savePaymentPlan,
  GetPaymentPlanInterface,
  PaymentDayType,
} from "services/plan.service";
import {
  PaymentPlanContainer,
  PaymentBox,
  CentralizedDiv,
  FormBox,
  TextHighlight,
} from "./style";
import { OnChange } from "react-final-form-listeners";
import { updateEnrollment } from "services/enrollment.service";
import {
  CONTRACT_STATUS,
  DOCUMENTS_STATUS,
  SCHOLARSHIP_TYPE,
} from "helpers/constants";
import { toastError, toastSuccess } from "components/Toast";
import loadingGif from "static/img/loading.gif";
import {
  AlertPaymentPlanDateToExpire,
  AlertPaymentPlanExpirationMessage,
} from "./components";
import { PaymentDetails } from "./components/Card";
import moment from "moment";
import { getStudentData } from "services/users.service";
import { ConsultaAlimentacaoResponsavelStyled } from "../ConsultaAlimentacaoResponsavel/style";

type StateType = {
  student: StudentInterface;
  guardian: GetCXUserJson;
};

interface IPaymentPlansData {
  unit: number;
  educational_level: number;
  activated: boolean;
  final_date: string;
}

interface IReferenceDetailsPlan {
  plan_name: string;
  contract_value?: number;
  material_contract_value: number;
  reservation_value: number;
}

export const PaymentPlan = () => {
  const history = useHistory();
  const location = useLocation<StateType>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [paymentPlan, setPaymentPlan] =
    useState<Array<GetPaymentPlanInterface>>();
  const [daysToPay, setDaysToPay] = useState<Array<PaymentDayType>>();
  const [selectedPlan, setSelectedPlan] = useState<number | null>(null);
  const [selectedDay, setSelectedDay] = useState<string>();
  const [selectedPlanInfos, setSelectedPlanInfos] = useState<any>();
  const [unselectedPlan, setUnselectedPlan] = useState(true);
  const [unselectedDay, setUnselectedDay] = useState(false);
  const [planConfirmation, setPlanConfirmation] = useState(false);
  const [freeOfPayment, setFreeOfPayment] = useState(false);
  const [errorReason, setErrorReason] = useState<string>();
  useState<Array<IPaymentPlansData>>();
  const [referenceDetailsPlan, setReferenceDetailsPlan] = useState<
    IReferenceDetailsPlan | any
  >();
  const [planFinalDate, setPlanFinalDate] = useState<string>();
  const [planValues, setPlanValues] = useState<GetPaymentPlanInterface>();

  const takeDefaultPlan = (data: Array<GetPaymentPlanInterface>) => {
    const defaultPlan = data.find((plan) => plan.plan_name === "Mensal");

    return defaultPlan;
  };

  useEffect(() => {
    if (daysToPay) {
      setSelectedDay(daysToPay[0].toString());
    }
  }, [daysToPay]);

  useEffect(() => {
    if (location.state.student && location.state.guardian) {
      const getPayment = async () => {
        const getPay = await getPaymentPlan({
          enrollmentId: location.state.student.last_enrollment.id,
        });
        if (getPay && getPay.status === HTTP_STATUS.OK) {
          setPaymentPlan(getPay.data);
          setDaysToPay(getPay.data[0].payment_day);
          setSelectedPlan(takeDefaultPlan(getPay.data)?.id!);
          setPlanValues(takeDefaultPlan(getPay.data));
          setPlanFinalDate(takeDefaultPlan(getPay.data)?.final_date);
          setLoading(false);
        } else if (getPay && getPay.status === HTTP_STATUS.NOT_FOUND) {
          setErrorReason(getPay.data);
          setError(true);
          setLoading(false);
        } else {
          setError(true);
          setLoading(false);
        }
      };

      getPayment();

      if (
        location.state.student.last_enrollment.scholarship_type ===
          SCHOLARSHIP_TYPE.SMART ||
        (location.state.student.last_enrollment
          .scholarship_percentage_monthly === 100 &&
          location.state.student.last_enrollment
            .scholarship_percentage_material === 100)
      ) {
        setSelectedDay("05"); // dia padrao para quem é isento de pagamento
        setFreeOfPayment(true);
      }
    }
  }, [location.state.guardian, location.state.student]);

  useEffect(() => {
    if (freeOfPayment && paymentPlan) {
      setSelectedPlan(paymentPlan[0].id);
    }
  }, [freeOfPayment, paymentPlan]);

  const findSelectedPlan = () => {
    if (selectedPlan && paymentPlan) {
      paymentPlan.find(
        (enrollmentPlan) =>
          enrollmentPlan.id === selectedPlan &&
          setSelectedPlanInfos(enrollmentPlan)
      );
    }
  };

  const onSubmit = async () => {
    if (selectedPlan && selectedDay) {
      const paymentData = await savePaymentPlan({
        payment_day: selectedDay.toString(),
        plan_id: selectedPlan,
        enrollment_id: location.state.student.last_enrollment.id,
      });
      if (paymentData) {
        if (paymentData.status === HTTP_STATUS.CREATED) {
          toastSuccess("Dados de pagamento salvos com sucesso!");
          const studentJson = await getStudentData({
            id: location.state.student.id,
          });

          if (
            studentJson?.status === HTTP_STATUS.OK &&
            studentJson?.data.last_enrollment.current_status ===
              "Aguardando Assinatura do Contrato"
          ) {
            if (
              studentJson?.data.location.same_address_as_guardian === null ||
              studentJson?.data.personal.cpf === null
            ) {
              history.push({
                pathname: `/responsavel/meus-alunos/documentacao`,
                state: {
                  student: location.state.student,
                  guardian: location.state.guardian,
                },
              });
            } else {
              history.push({
                pathname: `/contrato/confirmacao-dados`,
                state: {
                  student: location.state.student,
                  guardian: location.state.guardian,
                },
              });
            }
          }
        } else {
          toastError("Erro! Tente novamente mais tarde.");
        }
      }
    }
  };

  return (
    <PaymentPlanContainer className="container">
      {loading && <LinearProgress className="mt-3 mb-3" color="secondary" />}
      {error && (
        <div className="text-center">
          {errorReason ? errorReason : "Erro ao buscar planos!"}
        </div>
      )}
      {!error &&
        !loading &&
        location.state.student &&
        location.state.guardian &&
        paymentPlan &&
        daysToPay && (
          <div>
            <div className="enrollment-title text-center mb-5">
              Matrícula de {location.state.student.name}
              {location.state.student.last_enrollment && (
                <div className="subtitle">
                  {location.state.student.last_enrollment.grade || "Sem ano"}
                  {location.state.student.last_enrollment.grade && "º ano do "}
                  {
                    location.state.student.last_enrollment
                      .educational_level_name
                  }{" "}
                  - Unidade {location.state.student.last_enrollment.unit_name}
                </div>
              )}
            </div>
            {location.state.student.last_enrollment.plan_expired && (
              <AlertPaymentPlanExpirationMessage />
            )}
            <PaymentDetails
              referenceYearOrMonth={planValues?.plan_name!}
              educationalService={
                planValues ? planValues?.resume?.educational_service : "0,00"
              }
              isPaid={planValues ? planValues.resume.reservation_paid : false}
              scholarMaterial={planValues ? planValues.resume.material : "0,00"}
              reservedSchoolPlace={
                planValues ? planValues.resume.reservation : "0,00"
              }
              isTotal={referenceDetailsPlan?.plan_name}
              total={planValues ? planValues.resume.total : "0,00"}
            />

            <Form
              initialValues={
                daysToPay && {
                  pay_day: daysToPay[0].toString(),
                }
              }
              onSubmit={onSubmit}
            >
              {({ form, handleSubmit, submitting, values }) => (
                <form onSubmit={handleSubmit}>
                  {!freeOfPayment && (
                    <>
                      {unselectedPlan && (
                        <>
                          <TextHighlight>
                            <p>Escolha um plano de pagamento:</p>
                          </TextHighlight>
                          <CentralizedDiv>
                            {paymentPlan.map((plan) => {
                              return (
                                <PaymentBox
                                  key={plan.id}
                                  className={`${
                                    selectedPlan === plan.id && `selectedPlan`
                                  }`}
                                  onClick={() => {
                                    setSelectedPlan(plan.id);
                                    setReferenceDetailsPlan(plan);
                                    setPlanFinalDate(plan.final_date);
                                    setPlanValues(plan);
                                    form.change("plan", plan.id.toString());
                                  }}
                                  onSelect={() => setReferenceDetailsPlan(plan)}
                                >
                                  <div className="row align-items-center">
                                    <div className="col-2">
                                      <Field
                                        component={RadioButton}
                                        name="plan"
                                        type="radio"
                                        value={plan.id.toString()}
                                        required
                                        defaultValue={planValues?.id.toString()}
                                        onClick={() => setSelectedPlan(plan.id)}
                                      />
                                    </div>
                                    <div className="col-10">
                                      <div className="row">
                                        <h5>
                                          <strong className="col-12">
                                            {plan.plan_name === "Anual"
                                              ? "À vista"
                                              : `Parcelado em ${plan.installments_monthly}x no boleto`}
                                          </strong>
                                        </h5>
                                        <div className="col-12">
                                          <div>
                                            {plan.plan_name === "Anual"
                                              ? "Por: "
                                              : "Valor das parcelas: "}
                                            <strong>
                                              R${plan.total_installments_value}
                                            </strong>
                                            {plan.plan_name === "Anual" &&
                                              ` (${plan.discount?.toFixed()}% de desconto)`}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </PaymentBox>
                              );
                            })}
                            {planFinalDate && (
                              <AlertPaymentPlanDateToExpire
                                date={moment(planFinalDate).format(
                                  "DD/MM/YYYY"
                                )}
                              />
                            )}
                          </CentralizedDiv>

                          <div className="col-sm-3 offset-sm-9 col-12 mt-3">
                            <Button
                              disabled={!selectedPlan}
                              type="button"
                              onClick={() => {
                                setUnselectedPlan(false);
                                setUnselectedDay(true);
                                findSelectedPlan();
                              }}
                            >
                              Avançar
                            </Button>
                          </div>
                        </>
                      )}
                      {unselectedDay && (
                        <>
                          <CentralizedDiv>
                            <h6 className="h6-title">
                              Selecione o dia para o vencimento dos seus
                              boletos:
                            </h6>
                            {daysToPay.map((daysToPay) => {
                              return (
                                <>
                                  <FormBox
                                    key={daysToPay.toString()}
                                    onClick={() => {
                                      setSelectedDay(daysToPay.toString());
                                      form.change(
                                        "pay_day",
                                        daysToPay.toString()
                                      );
                                    }}
                                    className={`${
                                      selectedDay === daysToPay.toString() &&
                                      `selectedDay`
                                    }`}
                                  >
                                    <Field
                                      component={RadioButton}
                                      name="pay_day"
                                      type="radio"
                                      value={daysToPay.toString()}
                                      required
                                    />
                                    <p>
                                      <strong>Dia {daysToPay}</strong>
                                    </p>
                                  </FormBox>
                                  <OnChange name="pay_day">
                                    {async (value) => {
                                      if (value) {
                                        setSelectedDay(value);
                                      }
                                    }}
                                  </OnChange>
                                </>
                              );
                            })}
                          </CentralizedDiv>

                          <div className="col-sm-3 offset-sm-9 col-12 mt-3">
                            <Button
                              type="button"
                              disabled={!selectedDay}
                              onClick={() => {
                                setUnselectedDay(false);
                                setPlanConfirmation(true);
                              }}
                            >
                              Avançar
                            </Button>
                          </div>
                          <div className="col-sm-3 offset-sm-9 col-12">
                            <Button
                              className="blue outline"
                              type="button"
                              onClick={() => {
                                setUnselectedPlan(true);
                                setUnselectedDay(false);
                              }}
                            >
                              Voltar
                            </Button>
                          </div>
                        </>
                      )}
                      {planConfirmation && (
                        <>
                          <CentralizedDiv>
                            <h6 className="h6-title">
                              Você selecionou o plano:
                            </h6>
                            <FormBox>
                              <p className="ml-2">
                                Valor do Contrato de{" "}
                                <strong>
                                  {selectedPlanInfos.installments_monthly}x de{" "}
                                  R$ {selectedPlanInfos.contract_value}
                                </strong>
                              </p>
                            </FormBox>
                            <FormBox>
                              <p className="ml-2">
                                Valor do Material de{" "}
                                <strong>
                                  {selectedPlanInfos.installments_monthly}x de{" "}
                                  R$ {selectedPlanInfos.material_contract_value}
                                </strong>
                              </p>
                            </FormBox>
                            <FormBox>
                              <p className="ml-2">
                                Valor Total de{" "}
                                <strong>
                                  R${" "}
                                  {planValues
                                    ? planValues.resume.total
                                    : "0,00"}
                                </strong>
                              </p>
                            </FormBox>
                            <h6 className="mt-3">
                              Seu pagamento deverá ser efetuado até:
                            </h6>
                            <FormBox>
                              <p className="ml-2">Dia {selectedDay}</p>
                            </FormBox>
                          </CentralizedDiv>
                          <div className="col-sm-3 offset-sm-9 col-12 mt-3">
                            <Button type="submit" disabled={submitting}>
                              {!submitting && "Salvar"}
                              {submitting && (
                                <img
                                  height="25"
                                  src={loadingGif}
                                  alt="loading"
                                />
                              )}
                            </Button>
                          </div>
                          <div className="col-sm-3 offset-sm-9 col-12">
                            <Button
                              className="blue outline"
                              type="button"
                              onClick={() => {
                                setPlanConfirmation(false);
                                setUnselectedDay(true);
                              }}
                            >
                              Voltar
                            </Button>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {freeOfPayment && (
                    <CentralizedDiv>
                      <p className="text-center">
                        Aluno isento de pagamento. <br /> Clique em Prosseguir
                      </p>
                      <div className="col-sm-3 offset-sm-9 col-12 mt-3">
                        <Button
                          type="submit"
                          disabled={!selectedDay || submitting}
                        >
                          {!submitting && "Prosseguir"}
                          {submitting && (
                            <img height="25" src={loadingGif} alt="loading" />
                          )}
                        </Button>
                      </div>
                    </CentralizedDiv>
                  )}
                </form>
              )}
            </Form>
          </div>
        )}
    </PaymentPlanContainer>
  );
};
