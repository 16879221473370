import styled from "styled-components";

export const SearchGuardianDiv = styled.div`
  table {
    width: 100%;
    thead {
      tr {
        border-bottom: 1px solid #333333;
        th {
          padding: 1em;
        }
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid #333333;
        cursor: pointer;
        td {
          padding: 1em;
        }
        &:hover {
          td {
            background-color: #add8e6;
          }
        }
      }
    }
  }
`;
