import Icon from "@mdi/react";
import { mdiCheckCircleOutline } from "@mdi/js";
import { useTranslation } from "react-i18next";

import { ISectionHeader } from "./interface";

import * as S from "./styles";
import { StepStatus } from "components/atoms";

export const SectionHeader = ({ label, sectionState }: ISectionHeader) => {
  const { t } = useTranslation();

  return (
    <S.Container sectionState={sectionState}>
      <div className="content">
        {sectionState !== "Não Iniciado" && <StepStatus type={sectionState} />}

        <span>
          {t("components.molecules.sectionHeader.label", {
            label: `Etapa ${label}`,
          })}
        </span>
      </div>

      {sectionState === "Concluído" && (
        <Icon path={mdiCheckCircleOutline} size={1} />
      )}
    </S.Container>
  );
};
