import { FieldValues } from "react-hook-form";
import { IInputFile } from "./interface";
import { Input } from "./style";

export const InputFile = <T extends FieldValues>({
  id,
  register,
  fontColor = "#FFFFFF",
  width = 214,
  children,
  error,
  ...props
}: IInputFile<T>) => {
  return (
    <Input fontColor={fontColor} width={width}>
      <input
        accept=".pdf, .jpeg, .png, .jpg, .jfif, .heic"
        type="file"
        multiple
        id={id}
        {...props}
        {...register(id)}
      />
      <label htmlFor={id}>{children}</label>
      {error && <span className="error">{error}</span>}
    </Input>
  );
};
