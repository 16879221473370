import styled, { css } from "styled-components";
import { IStyle } from "./interface";

export const InputDateContainer = styled.div<IStyle>`
  display: flex;
  flex-direction: column;

  font-size: ${({ fontSize }) => `${fontSize}px`};
  line-height: ${({ lineHeight }) => `${lineHeight}px`};

  width: ${({ boxWidth }) => `${boxWidth}px`};
  margin-top: ${({ marginTop }) => `${marginTop}px`};

  label {
    text-transform: uppercase;
  }

  div {
    color: ${({ theme }) => theme.colors.greyScale40};

    ${({ value }) =>
      value &&
      css`
        color: ${({ theme }) => theme.colors.greyScale95};
      `}

    display: flex;
    align-items: center;

    border: 2px solid ${({ theme }) => theme.colors.greyScale40};

    height: ${({ boxHeight }) => `${boxHeight}px`};
    width: 100%;

    gap: 0 6.65px; // TODO: nao tem na lib
    padding: ${({ theme }) => theme.space[2]} 9.62px; // TODO: nao tem na lib

    span {
      margin-top: 2px; // TODO: nao tem na lib
    }

    input {
      opacity: 0;
      width: ${({ boxWidth }) => `${boxWidth}px`};
      height: ${({ boxHeight }) => `${boxHeight}px`};
      position: absolute;
    }

    input::-webkit-calendar-picker-indicator {
      cursor: pointer;
      width: ${({ boxWidth }) => `${boxWidth}px`};
      height: ${({ boxHeight }) => `${boxHeight}px`};
      position: absolute;
    }
  }

  @media (max-width: 360px) {
    width: 100%;
  }
`;

export const FormError = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.web.sm};
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  line-height: normal;
  color: ${({ theme }) => theme.colors.statusErrorDefault};
`;
