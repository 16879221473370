import { LinearProgress } from "@material-ui/core";
import { Button } from "components";
import { isFirstAccess } from "helpers/constants";
import HTTP_STATUS from "http-status-codes";
import {
  GetCXUserJson,
  UnitDetailELInterface,
  GuardianInterface,
} from "interfaces/constants";
import { formatUnitsSchoolPlaces } from "pages/EDF/StudentSignup/components/SchoolGradeForm/helper";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getUnitsGradesSchoolPlaces } from "services/units.service";
import { getStudentData } from "services/users.service";
import { StudentData } from "./components/StudentData";
import { StudentForm } from "./components/StudentForm";
import { StyledBorder, LinkStyled } from "./styles";
import { Storage } from "Storage";
import { useAtomValue } from "jotai";
import { authUserAtom } from "jotai/authUser";

export const EditStudent = () => {
  const [student, setStudent] = useState<GetCXUserJson | undefined>(undefined);
  const [guardian, setGuardian] = useState<GuardianInterface>();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingUnits, setLoadingUnits] = useState(true);
  const [edit, setEdit] = useState(true);
  const [units, setUnits] = useState<Array<UnitDetailELInterface>>([]);
  const role = Storage.props.role.get();
  const authUser = useAtomValue(authUserAtom);

  const urlParams = new URLSearchParams(window.location.search);
  const studentId = urlParams.get("studentId");

  const history = useHistory();

  useEffect(() => {
    if (studentId) {
      const getUser = async () => {
        const getStudentInfos = await getStudentData({
          id: parseInt(studentId),
        });
        if (getStudentInfos && getStudentInfos.status === HTTP_STATUS.OK) {
          setStudent(getStudentInfos.data);
          const financialGuardian =
            (getStudentInfos.data.last_enrollment &&
              getStudentInfos.data.guardians.find(
                (g: GuardianInterface) =>
                  g.guard_info.guard_id ===
                  getStudentInfos.data.last_enrollment.guard_id
              )) ||
            getStudentInfos.data.guardians.find((g: GuardianInterface) => g.id);

          if (financialGuardian) {
            setGuardian(financialGuardian);
            setLoading(false);
          } else {
            setError(true);
            setLoading(false);
          }
        } else {
          setError(true);
          setLoading(false);
        }
      };
      getUser();
    }
  }, [studentId, edit]);

  useEffect(() => {
    getUnitsGradesSchoolPlaces()
      .then((response) => {
        if (response) {
          if (response.status === HTTP_STATUS.OK) {
            setUnits(formatUnitsSchoolPlaces(response.data));
          }
        }
      })
      .finally(() => setLoadingUnits(false));
  }, []);

  return (
    <div className="container">
      {(loading || loadingUnits) && (
        <LinearProgress className="mt-3 mb-3" color="secondary" />
      )}
      {student && !edit && (
        <LinkStyled onClick={() => setEdit(!edit)}>Editar</LinkStyled>
      )}
      {!edit && student && units && (
        <StyledBorder>
          <StudentData student={student} />
        </StyledBorder>
      )}
      {edit && student && units && guardian && !loading && (
        <StudentForm
          setStudent={setStudent}
          setEdit={setEdit}
          student={student}
          units={units}
          guardian={guardian}
        />
      )}
      {isFirstAccess(authUser, role) && (
        <Button onClick={() => history.goBack()} type="button">
          Voltar
        </Button>
      )}
      {error && <div>Erro ao carregar os dados do aluno.</div>}
    </div>
  );
};
