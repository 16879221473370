import { Button, TextInput } from "components";
import { toastError, toastSuccess } from "components/Toast";
import {
  composeValidators,
  required,
  samePassword,
} from "helpers/fieldValidators";
import HTTP_STATUS from "http-status-codes";
import { useState } from "react";
import { Field, Form } from "react-final-form";
import { useHistory } from "react-router-dom";
import {
  changeUserPasswordToken,
  getToken,
  verifyUser,
} from "services/auth.service";
import loadingGif from "static/img/loading.gif";
import logoEscolaMaisHorizontal from "static/img/logo-escola-horizontal.png";
import { BLACK } from "styles/colors";
import { Storage } from "Storage";
import { authUserAtom } from "jotai/authUser";
import { useSetAtom } from "jotai";

export const ResetSenhaToken = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const setAuthUser = useSetAtom(authUserAtom);
  const history = useHistory();

  const urlParams = new URLSearchParams(window.location.search);
  const userId = urlParams.get("userId");
  const token = urlParams.get("token");

  const onSubmit = async (values: any) => {
    const response = await changeUserPasswordToken({
      id: userId ? parseInt(userId) : null,
      new_password: values.new_password,
      token,
    });
    if (response && response.status === HTTP_STATUS.OK) {
      toastSuccess("Senha atualizada com sucesso");
      const data = {
        email: response.data.email,
        password: values.new_password,
      };
      const responseToken = await getToken(data);
      if (responseToken && responseToken.status === HTTP_STATUS.OK) {
        Storage.token.set(responseToken.data);
        const userVerify = await verifyUser(data);
        if (
          userVerify &&
          userVerify.data &&
          userVerify.status === HTTP_STATUS.OK
        ) {
          setAuthUser(userVerify.data);
          Storage.props.authUser.set(userVerify.data);
          if (
            userVerify.data &&
            userVerify.data.role &&
            userVerify.data.role.length === 1
          ) {
            Storage.props.role.set(userVerify.data.role[0]);
          }
          window.location.href = "/home";
        } else {
          toastError("Ocorreu um erro ao obter os dados do usuário");
        }
      } else {
        if (
          responseToken &&
          responseToken.status === HTTP_STATUS.UNAUTHORIZED
        ) {
          toastError("E-mail e/ou senha inválidos");
        } else {
          toastError(
            "Ocorreu um erro ao obter seu acesso. Tente novamente mais tarde."
          );
        }
      }
    } else {
      toastError("Token inválido");
    }
  };

  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-12 text-center">
          <img
            style={{ height: "4rem" }}
            src={logoEscolaMaisHorizontal}
            alt="Logo Escola Mais"
          />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-sm-7 col-12 mt-3 mb-3 text-center">
          <div className="h2">Bem vindo a Escola Mais!</div>
        </div>
        <div className="col-sm-7 col-12 mt-3 mb-3 text-justify">
          <span style={{ color: BLACK, fontSize: "18px" }}>
            Queremos te desejar boas-vindas à Escola Mais. Estamos muito felizes
            em saber que você deu esse importante passo para seu filho ter uma
            educação de qualidade e que vai prepará-lo para o futuro!
          </span>
        </div>
        <div className="col-sm-7 col-12 mt-3 mb-3 text-justify">
          <span style={{ color: BLACK, fontSize: "18px" }}>
            Para criar a sua senha, dê preferência para senhas de, no mínimo, 8
            caracteres incluindo letras maiúsculas, minúsculas e números.
          </span>
        </div>
      </div>
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <div className="row justify-content-center">
              <div className="col-sm-7 col-12">
                <Field
                  component={TextInput}
                  name="new_password"
                  placeholder=" "
                  type={showPassword ? "text" : "password"}
                  label="Nova senha"
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                  validate={required}
                  required
                />
              </div>
              <div className="col-sm-7 col-12 mt-3">
                <Field
                  component={TextInput}
                  name="confirm_password"
                  placeholder=" "
                  type={showConfirmPassword ? "text" : "password"}
                  label="Confirmar senha"
                  showPassword={showConfirmPassword}
                  setShowPassword={setShowConfirmPassword}
                  validate={composeValidators(
                    required,
                    samePassword(values.new_password)
                  )}
                  required
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-sm-7 col-12 mt-3">
                <Button type="submit" disabled={submitting}>
                  {!submitting && "Criar Senha"}
                  {submitting && (
                    <img height="25" src={loadingGif} alt="loading" />
                  )}
                </Button>
              </div>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};
