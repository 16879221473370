import styled from "styled-components";
import * as colors from "styles/colors";
import IconCopy from "static/img/icon-copy.png";

export const ContainerCardLink = styled.div`
  width: 634px;
  height: 116px;
  background: rgba(109, 40, 217, 0.05);
  border-radius: 29px;
  padding: ${({ theme }) => theme.space[6]};
  font-size: 12px;
  margin-bottom: ${({ theme }) => theme.space[4]};
  margin-top: ${({ theme }) => theme.space[6]};
`;

export const Content = styled.p`
  color: ${colors.NEW_GRAY_SCALE};

  @media only screen and (max-width: 500px) {
    width: 90%;
  }
`;

export const InputCopy = styled.input`
  width: 99%;
  height: 34px;
  padding: ${({ theme }) => theme.space[2]};
  margin-top: ${({ theme }) => theme.space[2]};
  border: 2px solid ${colors.GRAY_SCALE};
  cursor: pointer;
  background: url(${IconCopy}) no-repeat scroll 99%;
  background-color: ${colors.WHITE};

  @media only screen and (max-width: 624px) {
    margin-top: -1.5em;
    font-size: 9.5px;
  }
`;
