import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Icon from "@mdi/react";

import { TableBulletin } from "components/molecules";
import { TableRow, TableColumn, Title } from "components/atoms";
import { mdiInformationOutline } from "@mdi/js";

import { ModalLegend } from "pages/AP/Boletim/components/ModalLegend";

import * as S from "./style";
import * as I from "./type";
import { IFinalAverages } from "pages/AP/Boletim/components/BoletimPDF/interface";
import {
  getQuarterPublicationsByUnit,
  getUnitsGrades,
} from "services/grades.service";

import HTTP_STATUS from "http-status-codes";
import { toastError } from "components/Toast";
import { UnitDropDown } from "interfaces/unit";
import { IQuarterPublicationsByUnitResponse } from "interfaces/boletim";
import { StudentInterface } from "interfaces/constants";
import { getCXUser } from "services/users.service";
import { StorageKeysEnum } from "Storage/keys";

const prefix = "pages.AP.boletim.bulletinFinal";

export interface BulletinFinalProps {
  "1º Trimestre": [
    {
      subject: string;
      short_name: string;
      quarter_avg: number;
      quarter_absences: number;
    }
  ];
  "2º Trimestre": [
    {
      subject: string;
      short_name: string;
      quarter_avg: number;
      quarter_absences: number;
    }
  ];
  "3º Trimestre": [
    {
      subject: string;
      short_name: string;
      quarter_avg: number;
      quarter_absences: number;
    }
  ];
  Final_averages: [
    {
      subject: string;
      short_name: string;
      final_average: number;
      quarter_absences: number;
      final_average_status: string;
    }
  ];
}

// Criei para separar vis'ao responsavel e visao interna
// é igual aquela coisa ruim de boletim só que com regras para o responsavel
export const ReportCard = ({ data }: I.BulletinFinal) => {
  const { t } = useTranslation();
  const authUser = JSON.parse(
    localStorage.getItem(StorageKeysEnum.AUTH_USER_STORAGE_KEY) as string
  );

  const listColumn = ["Componente", "M1", "M2", "M3", "MF", "F"];
  const listColumnAtividades = ["Componente", "F"];
  const [modalLegend, setModalLegend] = useState<boolean>(false);

  const [quartersPublications, setQuartersPublications] =
    useState<IQuarterPublicationsByUnitResponse[]>();

  useEffect(() => {
    (async () => {
      const responseUser = await getCXUser({
        id: authUser.id,
      });

      const responseUnits = await getUnitsGrades();

      if (
        responseUser &&
        responseUnits &&
        responseUnits.status === HTTP_STATUS.OK
      ) {
        const student = responseUser.data.students.find(
          (student: StudentInterface) => {
            return student.name === data.name;
          }
        );

        const unit = responseUnits.data.find((unit: UnitDropDown) => {
          return unit.unit_id_saf === student?.enrollment.unit_id;
        });
        const responseQuarterPublications = await getQuarterPublicationsByUnit({
          year: 2023,
          unit_id: unit.id,
        });
        if (
          responseQuarterPublications &&
          responseQuarterPublications.status === HTTP_STATUS.OK
        ) {
          setQuartersPublications(responseQuarterPublications.data);
        } else {
          toastError("Erro ao buscar publicações");
        }
      } else {
        toastError("Erro ao buscar unidades");
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser.students, data.name]);

  const isPublished = (value: string) => {
    if (quartersPublications) {
      const quarter = quartersPublications.find(
        (quarter) =>
          quarter.publicacao.quarter_name === value &&
          quarter.status === "Publicado"
      );

      return quarter;
    }

    return false;
  };

  const totalFinalSchoolReportAbsences = new Map<string, any>(
    Object.entries(data.final_schoolreport.total_absences)
  );

  const totalAtividadesAbsences = new Map<string, any>(
    Object.entries(data.atividades_curriculares)
  );

  const [firstKey] = totalAtividadesAbsences.keys();

  const getTotalAtividadesAbsencesBySubject = (subject: string) => {
    return data.atividades_curriculares.total_absences[subject];
  };

  const finalSchoolReport = new Map<string, any>(
    Object.entries(data.final_schoolreport)
  );

  const [finalFirstKey] = finalSchoolReport.keys();

  const getFinalSchoolReport = (quarter: string, subject: string) => {
    if (!finalSchoolReport.get(quarter)) {
      return { quarter_avg: "-", final_average: "-" };
    }

    if (quarter === "Final Averages") {
      const finalAverageReport = finalSchoolReport
        .get(quarter)
        .find((note: any) => note.subject === subject);

      if (finalAverageReport === undefined) {
        return {
          final_average: "--",
        };
      }

      return { final_average: finalAverageReport.final_average };
    }

    const finalAvg = finalSchoolReport
      .get(quarter)
      .find((note: any) => note.subject === subject);

    return { quarter_avg: finalAvg?.quarter_avg ? finalAvg.quarter_avg : "--" };
  };

  return (
    <S.Container>
      <Title darkGray fontSize="18px" center>
        {t(`${prefix}.title`)}
      </Title>
      <TableBulletin
        theadChildren={
          <>
            {listColumn.map(
              (item, index) =>
                item && (
                  <TableRow
                    key={item}
                    item={item}
                    visible={{ mobile: true, desktop: true }}
                  />
                )
            )}
          </>
        }
        tbodyChildren={
          <>
            {finalSchoolReport.get(finalFirstKey)?.map(
              (componente: any, index: any) =>
                componente && (
                  <tr key={componente.subject}>
                    <TableColumn
                      maxWidth="200px"
                      item={
                        <>
                          {componente.subject}
                          <br></br>
                          {finalSchoolReport.get("Final Averages")?.map(
                            (item: IFinalAverages) =>
                              item.subject === componente?.subject && (
                                <S.BoxTag
                                  key={`${componente.subject}=${item?.final_average_status}`}
                                >
                                  <S.TagSubjects
                                    status={`${item?.final_average_status} - tag`}
                                  >
                                    {item?.final_average_status}
                                  </S.TagSubjects>
                                </S.BoxTag>
                              )
                          )}
                        </>
                      }
                      visible={{ mobile: true, desktop: true }}
                    />
                    <TableColumn
                      item={
                        <>
                          {isPublished("1º Trimestre")
                            ? getFinalSchoolReport(
                                "1º Trimestre",
                                componente.subject
                              ).quarter_avg
                            : "-"}
                        </>
                      }
                      visible={{ mobile: true, desktop: true }}
                    />
                    <TableColumn
                      item={
                        <>
                          {isPublished("2º Trimestre")
                            ? getFinalSchoolReport(
                                "2º Trimestre",
                                componente.subject
                              ).quarter_avg
                            : "-"}
                        </>
                      }
                      visible={{ mobile: true, desktop: true }}
                    />
                    <TableColumn
                      item={
                        <>
                          {isPublished("3º Trimestre")
                            ? getFinalSchoolReport(
                                "3º Trimestre",
                                componente.subject
                              ).quarter_avg
                            : "-"}
                        </>
                      }
                      visible={{ mobile: true, desktop: true }}
                    />
                    <TableColumn
                      item={
                        <>
                          {
                            getFinalSchoolReport(
                              "Final Averages",
                              componente.subject
                            )?.final_average
                          }
                        </>
                      }
                      visible={{ mobile: true, desktop: true }}
                    />
                    <TableColumn
                      item={
                        <>
                          {totalFinalSchoolReportAbsences &&
                            totalFinalSchoolReportAbsences.size > 0 &&
                            totalFinalSchoolReportAbsences.get(
                              componente.subject
                            )}
                        </>
                      }
                      visible={{ mobile: true, desktop: true }}
                    />
                  </tr>
                )
            )}
          </>
        }
      />

      <S.BoxTable>
        <TableBulletin
          theadChildren={
            <>
              {listColumnAtividades?.map(
                (item, index) =>
                  item && (
                    <TableRow
                      key={item}
                      item={item}
                      className="w-25"
                      visible={{ mobile: true, desktop: true }}
                    />
                  )
              )}
            </>
          }
          tbodyChildren={
            <>
              {totalAtividadesAbsences.size > 0 ? (
                totalAtividadesAbsences.get(firstKey).map(
                  (componente: I.TotalAtividadesAbsences) =>
                    componente && (
                      <tr key={componente?.subject_id}>
                        <TableColumn
                          item={<>{componente?.subject}</>}
                          visible={{ mobile: true, desktop: true }}
                        />
                        <TableColumn
                          item={
                            <>
                              {getTotalAtividadesAbsencesBySubject(
                                componente.subject
                              )}
                            </>
                          }
                          visible={{ mobile: true, desktop: true }}
                        />
                      </tr>
                    )
                )
              ) : (
                <p className="py-2">{t(`${prefix}.noData`)}</p>
              )}
            </>
          }
        />
      </S.BoxTable>
      <S.Legend onClick={() => setModalLegend(true)}>
        <Icon path={mdiInformationOutline} size={1} />
        {t(`${prefix}.subtitle`)}
      </S.Legend>
      {modalLegend && <ModalLegend setModal={setModalLegend} />}
    </S.Container>
  );
};
