/* eslint-disable array-callback-return */
/* eslint-disable-line no-eval */
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as I from "./types";

import HTTP_STATUS from "http-status-codes";
import fs from "fs";

import { GradeAPI, GradeAPIDownload, KanataAPI } from "services/api";
import { getQuarters, lancarMultiNota } from "services/grades.service";

import {
  isCX,
  isDirector,
  isGradesManager,
  isGuardian,
  isSecretary,
  isStudent,
  isStudyRoomEducator,
  isTeacher,
} from "helpers/constants";
import { MultiNotasProps } from "pages/AP/Lancamentos/Lancamento/types";
import { Headers } from "services/service-helpers";
import { Storage } from "Storage";

const BulletinContext = createContext({} as I.BulletinContextData);

export type ReactProps = {
  children?: React.ReactNode;
};

const dataAtual = new Date();
const anoAtual = dataAtual.getFullYear();

export const BulletinProvider: React.FC<ReactProps> = ({ children }) => {
  const role = Storage.props.role.get();

  // empty commit, fix bug

  //Data
  const user = Storage.props.authUser.get();

  //Error
  const [error, setError] = useState(false);

  //States
  const [unit, setUnit] = useState<number>();
  const [data, setData] = useState<I.DataProps[]>();
  const [quarters, setQuarters] = useState<I.QuartersDataProps[]>();
  const [dataTable, setDataTable] = useState<I.StateDataTable>();
  const [dataTableFilter, setDataTableFilter] = useState<I.StateDataTable>();
  const [bulletin, setBulletin] = useState<I.BulletinDataProps | undefined>();
  const [userConnected, setUserConnected] = useState<number>(0);
  const [userLogon, setUserLogon] = useState<I.UserLogon>();
  const [studentList, setStudentList] = useState<MultiNotasProps[]>();
  const [selectRole, setSelectRole] = useState<string>(
    isGuardian(role) ? "external" : "internal"
  );
  const [edit, setEdit] = useState<boolean>(
    isDirector(role) || isGradesManager(role) ? true : false
  );

  useEffect(() => {
    if (isDirector(role) || isGradesManager(role)) {
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [role]);

  const [studentOn, setStudentOn] = useState<I.HandleStudentIdProps>();
  const [studentInfo, setStudentInfo] = useState<I.HandleKanataStudentId>();
  const [userKatana, setUserKatana] = useState<I.UserKatana>();
  const [historySchoolReport, setHistorySchoolReport] =
    useState<I.HistorySchoolReport>();

  const [years, setYears] = useState<I.StateFilterProps>({
    values: [""],
    selected: "",
  });
  const [levelName, setLevelName] = useState<I.StateFilterProps>({
    values: [""],
    selected: "",
  });
  const [listLevelname, setListLevelname] = useState<string[]>([""]);
  const [grades, setGrades] = useState<I.StateFilterProps>({
    values: [""],
    selected: "",
  });
  const [quarter, setQuarter] = useState<I.StateFilterProps>({
    values: [""],
    selected: "",
  });
  const [sections, setSections] = useState<I.StateSectionsGrades>({
    values: [
      {
        id: 0,
        letter: "",
        school_unit: 0,
      },
    ],
    selected: {
      id: 0,
      letter: "",
      school_unit: 0,
      grade: "",
    },
  });
  const [status, setStatus] = useState<I.StateFilterProps>({
    values: ["Todos"],
    selected: "Todos",
  });
  const [reqTable, setReqTable] = useState<I.DataTable>({
    gradegroupId: 0,
    quarterId: 0,
  });
  const [gradeBulletin, setGradeBulletin] = useState<string>("");

  //loading
  const [loadingGrades, setLoadingGrades] = useState<boolean>(true);
  const [loadingTable, setLoadingTable] = useState<boolean>(true);
  const [loadingTableComplete, setLoadingTableComplete] =
    useState<boolean>(true);
  const [loadingBulletin, setLoadingBulletin] = useState<boolean>(true);
  const [loadingTitle, setLoadingTitle] = useState<boolean>(true);
  const [loadingYear, setLoadingYear] = useState<boolean>(true);
  const [loadingLevelName, setLoadingLevelName] = useState<boolean>(true);
  const [loadingCatchGrades, setLoadingCatchGrades] = useState<boolean>(true);
  const [loadingSections, setLoadingSections] = useState<boolean>(true);
  const [loadingQuaters, setLoadingQuaters] = useState<boolean>(true);
  const [loadingGuardian, setLoadingGuardian] = useState<boolean>(true);
  const [loadingHistorySchoolReport, setloadingHistorySchoolReport] =
    useState<boolean>(false);
  const [loadingGradeBulletin, setLoadingGradeBulletin] =
    useState<boolean>(true);

  //Functions
  const catchQuarter = useCallback(async () => {
    const quarter = await getQuarters();
    const quarters = quarter?.data;
    setQuarters([...quarters]);
  }, []);

  const catchYear = useCallback(
    (arr: I.DataProps[]) => {
      if (years.values.length <= 1) {
        setLoadingYear(true);
        const listYears: string[] = [];

        const info = [
          ...arr
            .reduce(
              (map: any, each: any) => map.set(each.year, each),
              new Map()
            )
            .values(),
        ];

        info.map((item) => item && listYears.push(item.year));

        const stateYear = {
          values: [...listYears],
          selected: `${anoAtual}`,
        };

        setYears(stateYear);
        setLoadingYear(false);
      }
    },
    [years.values.length]
  );

  // ciclo
  const catchLevelName = useCallback(
    (arr: I.DataProps[]) => {
      setLoadingLevelName(true);
      const listLevelName: string[] = [];

      const infoLevelName = [
        ...arr
          .reduce(
            (map: any, each: any) => map.set(each.level_name, each),
            new Map()
          )
          .values(),
      ];

      infoLevelName.map((item) => item && listLevelName.push(item.level_name));

      setListLevelname([...listLevelName]);

      const stateLavelName = {
        values: [...listLevelName],
        selected:
          levelName?.selected === ""
            ? infoLevelName[0]?.level_name
            : levelName?.selected,
      };

      setLevelName(stateLavelName);
      setLoadingLevelName(false);
    },
    [levelName.selected]
  );

  // ano
  const catchGrades = useCallback(
    (arr: I.DataProps[], value: string) => {
      setLoadingCatchGrades(true);
      const listGrade: string[] = [];
      const infoGrade = [...arr];

      infoGrade.map(
        (item) => item.level_name === value && listGrade.push(`${item.grade}`)
      );

      const uniqueIds: string[] = [];

      const unique = infoGrade.filter((element: any) => {
        if (element.level_name === value) {
          const isDuplicate = uniqueIds.push(element.grade);

          if (!isDuplicate) {
            uniqueIds.push(element.letter);

            return true;
          }
        }
        return false;
      });

      setGrades({
        values: [...uniqueIds],
        selected: grades.selected === "" ? listGrade[0] : grades.selected,
      });
      setLoadingCatchGrades(false);
    },
    [grades.selected]
  );

  // turma
  const catchSections = useCallback(
    (arr: I.DataProps[], value: string, levelName: string) => {
      setLoadingSections(true);
      const listSections: I.StateSectionsGrades = {
        values: [
          {
            id: 0,
            letter: "",
            school_unit: 0,
          },
        ],
        selected: {
          id: 0,
          letter: "",
          school_unit: 0,
          grade: "",
        },
      };
      const infoSections = [...arr];

      infoSections.map(
        (item) =>
          item.level_name === levelName &&
          item.grade === value &&
          item.year === years.selected &&
          item.sections.map((section, index) => {
            listSections.values.push({
              id: section.id,
              letter: section.letter,
              school_unit: section.school_unit,
            });
            if (index === 0) {
              listSections.selected = {
                id: section.id,
                letter: section.letter,
                school_unit: section.school_unit,
                grade: value,
              };
            }
          })
      );
      listSections.values.shift();
      listSections.values.sort((a, b) => {
        return a.letter.localeCompare(b.letter);
      });

      const uniqueIds: I.StateSectionsGrades[] = [];

      const unique = listSections.values.filter((element: any) => {
        const isDuplicate = uniqueIds.includes(element.letter);

        if (!isDuplicate) {
          uniqueIds.push(element.letter);

          return true;
        }

        return false;
      });

      if (listSections.values[0]?.id !== 0) {
        setReqTable({
          gradegroupId: unique[0]?.id,
          quarterId: reqTable.quarterId,
        });
      }

      setSections({
        values: [...unique],
        selected: {
          id: listSections.selected.id,
          letter: listSections.selected.letter,
          school_unit: listSections.selected.school_unit,
          grade: listSections.selected.grade,
        },
      });
      setLoadingSections(false);
    },
    [years.selected, reqTable]
  );

  // trimestre
  const catchQuaters = useCallback(
    (arr: I.QuartersDataProps[], value: string) => {
      setLoadingQuaters(true);
      const listQuater: string[] = [""];
      const currentquater: I.QuartersDataProps[] = arr.filter(
        (quarter) => quarter.current === true && quarter
      );

      arr.map((quarter) => quarter && listQuater.push(quarter.name));
      listQuater.shift();

      if (quarter.selected === "") {
        setQuarter({
          values: [...listQuater],
          selected: currentquater[0]?.name || listQuater[0],
        });
      }

      arr.map((quarter: I.QuartersDataProps, index: number) => {
        if (value === quarter.name) {
          setReqTable({
            gradegroupId: sections.selected.id,
            quarterId: quarter.id,
          });
        }
      });

      setLoadingQuaters(false);
    },
    [quarter.selected, sections.selected.id]
  );

  const catchStatus = useCallback((arr: I.StudentDataTable[]) => {
    const listStatus: string[] = ["Todos"];

    const info = [
      ...arr
        .reduce(
          (map: any, each: any) => map.set(each.release_status, each),
          new Map()
        )
        .values(),
    ];

    info.map((item) => item && listStatus.push(item.release_status));

    const stateStatus = {
      values: [...listStatus],
      selected: listStatus[0],
    };

    setStatus(stateStatus);
  }, []);

  const handleDataGrades = useCallback(
    async ({ unit, year, teacher }: I.HandleDataGradesProps) => {
      setLoadingGrades(true);
      try {
        if (isTeacher(role) || isStudyRoomEducator(role)) {
          if (teacher !== undefined) {
            const response = await GradeAPI.get(
              `classes/gradeyear/${unit}?year=${year}&full=s&teacher=${teacher}`
            );

            const newData: I.DataProps[] = [...response.data];
            setData([...newData]);

            if (years.values[0] === "") {
              catchYear(response.data);
            }

            setLoadingGrades(false);
          }
        } else {
          const response = await GradeAPI.get(
            `classes/gradeyear/${unit}?year=${year}&full=s`
          );
          const newData: I.DataProps[] = [...response.data];
          setData([...newData]);

          if (years.values[0] === "") {
            catchYear(response.data);
          }

          setLoadingGrades(false);
        }
      } catch (error) {
        setLoadingGrades(false);

        throw error?.response;
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [years.selected, years.values]
  );

  const handleDataTable = useCallback(
    async ({ gradegroupId, quarterId }: I.DataTable) => {
      setLoadingTable(true);

      try {
        const response = await GradeAPI.get(
          `classes/release_check/?gradegroup_id=${gradegroupId}&quarter_id=${quarterId}`
        );

        if (response.status === HTTP_STATUS.OK) {
          catchStatus(response.data.results[0].students);
          setDataTable(response.data.results[0]);
        }
        setLoadingTable(false);
      } catch (error) {
        setLoadingTable(false);
        throw error?.response;
      }
    },

    [catchStatus]
  );

  const handleDataBulletin = useCallback(
    async ({ id, quarter }: I.DataBulletinProps) => {
      try {
        setBulletin(undefined);
        setLoadingBulletin(true);
        const response = await GradeAPI.get(
          `classes/school_report?quarter_id=${quarter}&student_id=${id}`
        );

        if (response.status === HTTP_STATUS.OK) {
          setBulletin(response.data);
          setLoadingBulletin(false);
        }
      } catch (error) {
        setError(true);
        setLoadingBulletin(false);
      }
    },
    []
  );

  const handleKanataStudentId = useCallback(async (id) => {
    try {
      const response = await KanataAPI.get(`/cx/user/${id}`);
      setStudentOn(response.data);
    } catch (error) {
      setError(true);
      throw error?.response;
    }
  }, []);

  const handleStudentId = useCallback(async (id: number) => {
    try {
      const response = await GradeAPI.get(`/classes/kanata_grades/${id}`);
      setUserKatana(response.data.student);
      return response.data;
    } catch (error) {
      setError(true);
      throw error?.response;
    }
  }, []);

  const handleApproveBulletin = useCallback(
    async (studentId: number, quarterSelected: number) => {
      if (userLogon) {
        try {
          const payload = {
            approved: 1,
            student_id: studentId ? studentId : "",
            quarter: quarterSelected ? quarterSelected : reqTable.quarterId,
            user_id: userConnected,
            user_name: userLogon.name,
          };

          const response = await GradeAPI.post(
            `classes/school_report/`,
            payload
          );
          if (
            response.status === HTTP_STATUS.OK &&
            studentId &&
            reqTable.quarterId
          ) {
            handleDataBulletin({
              id: studentId,
              quarter: reqTable.quarterId,
            });
          }
        } catch (error) {
          throw new Error("error", error);
        }
      }
    },
    [userLogon, reqTable.quarterId, userConnected, handleDataBulletin]
  );

  const handleDisapproveBulletin = useCallback(
    async (studentId: number, quarterSelected: number) => {
      try {
        const payload = {
          student: studentId,
          quarter: quarterSelected,
        };
        const response = await GradeAPI.post(
          `classes/school_report/remove/`,
          payload
        );
        if (response.status === HTTP_STATUS.OK) {
          handleDataBulletin({
            id: studentId,
            quarter: reqTable.quarterId,
          });
        }
      } catch (error) {
        throw new Error("Error", error);
      }
    },
    [handleDataBulletin, reqTable.quarterId]
  );

  const handleReleaseBulletin = useCallback(
    async (data: MultiNotasProps[], studentId: number) => {
      try {
        const response = await lancarMultiNota(data);
        if (response?.status === HTTP_STATUS.OK) {
          handleDataBulletin({
            id: studentId,
            quarter: reqTable.quarterId,
          });
        }
      } catch (error) {
        throw new Error("Error", error);
      }
    },
    [handleDataBulletin, reqTable.quarterId]
  );

  const handleSelectYear = useCallback(
    (value: string) => {
      if (data && quarters && levelName) {
        catchYear(data);
        catchQuarter();
        catchLevelName(data);
        catchGrades(data, value);
        catchQuaters(quarters, quarter.selected);
        catchSections(data, grades.selected, levelName.selected);
      }
      const stateYears = {
        values: [...years.values],
        selected: value,
      };
      setYears(stateYears);
      if (unit && userConnected) {
        handleDataGrades({ unit, year: `${anoAtual}`, teacher: userConnected });
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      data,
      quarters,
      levelName,
      years.values,
      unit,
      userConnected,
      quarter.selected,
      grades.selected,
      handleDataGrades,
    ]
  );

  const handleSelectQuater = useCallback(
    (value: string) => {
      if (data && quarters) {
        catchYear(data);
        catchQuarter();
      }
      const stateQuater = {
        values: [...quarter.values],
        selected: value,
      };

      setQuarter(stateQuater);
    },

    [data, quarters, quarter.values, catchYear, catchQuarter]
  );

  const handleSelectGrades = useCallback(
    (value: string) => {
      if (data) {
        catchGrades(data, value);
        catchSections(data, value, levelName.selected);
      }

      const stateGrades = {
        values: [...grades.values],
        selected: value,
      };

      setGrades(stateGrades);
    },
    [catchGrades, catchSections, data, grades.values, levelName.selected]
  );

  const handleSelectLevelName = useCallback(
    (value: string) => {
      if (data && quarters) {
        catchQuarter();
        catchGrades(data, value);
        catchQuaters(quarters, quarter.selected);
        handleSelectGrades(
          data.filter((item) => item.level_name === value)[0].grade
        );
      }

      const stateLevelName = {
        values: [...listLevelname],
        selected: value,
      };

      setLevelName(stateLevelName);
      setReqTable({
        gradegroupId: reqTable.gradegroupId,
        quarterId: reqTable.quarterId,
      });
    },
    [
      data,
      handleSelectGrades,
      quarters,
      quarter.selected,
      listLevelname,
      reqTable.gradegroupId,
      reqTable.quarterId,
      catchQuarter,
      catchGrades,
      catchQuaters,
    ]
  );

  const handleSelectSections = useCallback(
    (value: I.SectionsDataProps) => {
      const selectedValue = {
        values: [...sections.values],
        selected: {
          id: value.id,
          letter: value.letter,
          school_unit: value.school_unit,
          grade: grades.selected,
        },
      };

      setReqTable({
        gradegroupId: value.id,
        quarterId: reqTable.quarterId,
      });

      setSections(selectedValue);
    },
    [grades.selected, reqTable.quarterId, sections.values]
  );

  const handleFilterStatus = useCallback(
    async (value: string) => {
      if (value === "Todos") {
        if (dataTable) {
          const studentApproveList: I.StateDataTable = {
            available_releases: dataTable.available_releases,
            id: dataTable.id,
            name: dataTable.name,
            students: [],
          };
          dataTable.students.map(
            (student: I.StudentDataTable) =>
              !student.deleted && studentApproveList.students.push(student)
          );

          setDataTableFilter(studentApproveList);
        }
      } else {
        if (dataTable) {
          const studentApproveList: I.StateDataTable = {
            available_releases: dataTable.available_releases,
            id: dataTable.id,
            name: dataTable.name,
            students: [],
          };
          dataTable.students.map(
            (student: I.StudentDataTable) =>
              student.release_status === value &&
              !student.deleted &&
              studentApproveList.students.push(student)
          );

          setDataTableFilter(studentApproveList);
        }
      }
    },
    [dataTable]
  );

  const handleSelectStatus = useCallback(
    (value: string) => {
      switch (value) {
        case "Todos":
          handleFilterStatus("Todos");
          break;
        case "Aprovado":
          handleFilterStatus(value);
          break;
        case "Pendente":
          handleFilterStatus(value);
          break;
        default:
          handleFilterStatus("Todos");
          break;
      }
    },

    [handleFilterStatus]
  );

  const handleSearchHistorySchoolReport = useCallback((url: string) => {
    setloadingHistorySchoolReport(true);
    GradeAPI.get(url)
      .then((res) => {
        const data = res.data;
        setHistorySchoolReport(data);
      })
      .catch((err) => {
        throw err?.response;
      })
      .finally(() => {
        setloadingHistorySchoolReport(false);
      });
  }, []);

  const handleDownloadHistorySchoolReport = useCallback(
    (url: string, name: string) => {
      GradeAPIDownload.get(
        url,
        Headers({
          blob: true,
        })
      )
        .then((res) => {
          const outputFilename = `${name}.xls`;

          const url = URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");

          link.href = url;
          link.setAttribute("download", outputFilename);
          document.body.appendChild(link);
          link.click();

          fs.writeFileSync(outputFilename, res.data); // eslint-disable-line no-eval
        })
        .catch((err) => {
          throw err?.response;
        })
        .finally(() => {});
    },
    []
  );

  //Loading
  useEffect(() => {
    catchQuarter();
    if (unit && userConnected && user.role[0].id !== 8) {
      handleDataGrades({ unit, year: `${anoAtual}`, teacher: userConnected });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catchQuarter, handleDataGrades, unit, userConnected]);

  useEffect(() => {
    if (user) {
      setUnit(user.grade_unit_id);
      setUserLogon(user);
      setUserConnected(user.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //watching
  useEffect(() => {
    if (data) {
      catchLevelName(data);
      if (levelName.selected !== "") {
        catchGrades(data, levelName.selected);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catchLevelName, data, levelName.selected, sections.selected]);

  useEffect(() => {
    if (data && quarters) {
      if (grades.selected !== "") {
        catchSections(data, grades.selected, levelName.selected);
        catchQuaters(quarters, quarter.selected);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    grades.selected,
    years.selected,
    quarters,
    quarter.selected,
    levelName.selected,
  ]);

  useEffect(() => {
    setLoadingGradeBulletin(true);
    if (dataTable && levelName.selected) {
      setGradeBulletin(
        `${levelName.selected} - ${grades.selected}º ${sections.selected.letter}`
      );
      setLoadingGradeBulletin(false);
    }

    if (dataTable) {
      const onlyActiveStudents = dataTable.students.filter(
        (student) => !student.deleted
      );
      setDataTableFilter({
        ...dataTable,
        students: onlyActiveStudents,
      });
    }
  }, [dataTable, levelName.selected, grades.selected, sections.selected]);

  // useEffect(() => {
  //   if (
  //     isTeacher(role) ||
  //     isStudyRoomEducator(role) ||
  //     isDirector(role) ||
  //     isGradesManager(role) ||
  //     isSecretary(role)
  //   ) {
  //     setSelectRole("internal");

  //     if (isDirector(role) || isGradesManager(role)) {
  //       setEdit(true);
  //     }
  //   } else if (isGuardian(role) || isStudent(role)) {
  //     setSelectRole("external");
  //   }
  // }, [role]);

  const handleToggleSelectedRole = () => {
    setSelectRole((prevState) =>
      prevState === "internal" ? "external" : "internal"
    );
  };

  return (
    <BulletinContext.Provider
      value={{
        setData,
        years,
        setQuarters,
        handleSelectYear,
        levelName,
        handleSelectLevelName,
        grades,
        quarters,
        handleSelectGrades,
        sections,
        handleSelectSections,
        quarter,
        reqTable,
        handleSelectQuater,
        handleDataGrades,
        handleDataTable,
        loadingTable,
        loadingGrades,
        dataTable,
        setDataTable,
        loadingBulletin,
        loadingYear,
        loadingLevelName,
        loadingSections,
        loadingQuaters,
        loadingCatchGrades,
        gradeBulletin,
        loadingTitle,
        status,
        bulletin,
        loadingGradeBulletin,
        handleDataBulletin,
        user,
        selectRole,
        setSelectRole,
        edit,
        handleApproveBulletin,
        handleDisapproveBulletin,
        handleSelectStatus,
        setStudentList,
        studentList,
        dataTableFilter,
        userLogon,
        handleReleaseBulletin,
        handleKanataStudentId,
        handleStudentId,
        studentOn,
        studentInfo,
        setQuarter,
        userKatana,
        error,
        handleSearchHistorySchoolReport,
        handleDownloadHistorySchoolReport,
        loadingHistorySchoolReport,
        historySchoolReport,
        setDataTableFilter,
        handleToggleSelectedRole,
      }}
    >
      {children}
    </BulletinContext.Provider>
  );
};

export const useBulletin = () => useContext(BulletinContext);
