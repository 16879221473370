interface handleJustifyContentProps {
  value: string;
}
export const handleJustifyContent = ({ value }: handleJustifyContentProps) => {
  switch (value) {
    case "around":
      return "space-around";
    case "between":
      return "space-between";
    case "evenly":
      return "space-between";
    case "center":
      return " center";
    case "left":
      return "flex-start";
    case "right":
      return "flex-end";
    default:
      return "flex-start";
  }
};

export const pxToRem = (px: number) => `${px / 16}rem`;
