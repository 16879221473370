// Styles
import * as S from "./styles";
// Interfaces
import { IEnrollmentHeader, IStudentHeader } from "./interface";
// Helpers
import { splitString } from "helpers/helpers";
// Libs
import { useTranslation } from "react-i18next";
// Img
import profilePicture from "static/img/profile.svg";

export const StudentHeader = ({ student }: IStudentHeader) => {
  const { t } = useTranslation();
  const prefix = "components.molecules.studentHeader";

  return (
    <S.StudentHeader>
      {student.photo_url ? (
        <img src={student.photo_url} alt={student.name} />
      ) : (
        <img src={profilePicture} alt={student.name} />
      )}

      <div>
        <div>
          <strong>
            {t(`${prefix}.studentName`, { name: student.name.split(" ")[0] })}
          </strong>
          <p>
            {t(`${prefix}.teachingData`, {
              educational_level_name: student.enrollment.educational_level_name,
              grade: student.enrollment.grade,
              section: student.enrollment.section,
            })}
          </p>
        </div>
      </div>
    </S.StudentHeader>
  );
};

export const StudentHeaderSecondary = ({ student }: IStudentHeader) => {
  const { t } = useTranslation();
  const prefix = "components.molecules.studentHeader";

  return (
    <S.StudentHeaderSecondary>
      {/* fazer logica desse cara para quando nao ter foto usar uma default */}
      {student.photo_url ? (
        <img src={student.photo_url} alt={student.name} />
      ) : (
        <img src={profilePicture} alt={student.name} />
      )}
      <div>
        <h3>{splitString(student.name).first}</h3>
        <p>
          {t(`${prefix}.teachingData`, {
            educational_level_name: student.enrollment.educational_level_name,
            grade: student.enrollment.grade,
            section: student.enrollment.section,
          })}
        </p>
      </div>
    </S.StudentHeaderSecondary>
  );
};

// Component criado com itenção de ser o mais generico possivel
// caso tenha alguma att no component é só introduzir aqui
// remover outros components acima quando tiver tempo
export const EnrollmentHeader = ({
  photo,
  name,
  educational_level_name,
  grade,
  section,
}: IEnrollmentHeader) => {
  const { t } = useTranslation();
  const prefix = "components.molecules.studentHeader";

  return (
    <S.StudentHeader>
      <img src={photo ? photo : profilePicture} alt={name} />

      <div>
        <div>
          <strong>{t(`${prefix}.studentName`, { name })}</strong>
          <p>
            {t(`${prefix}.teachingData`, {
              educational_level_name,
              grade,
              section,
            })}
          </p>
        </div>
      </div>
    </S.StudentHeader>
  );
};
